import React from "react";
import { observer } from "mobx-react";

import { StateFlowProgressBar } from "@modules/stateflows/components/core/StateFlowProgressBar";
import { StateFlowMockHeader } from "@modules/stateflows/components/preview/StateFlowMockHeader";
import { StateFlowMockTabList } from "@modules/stateflows/components/preview/StateFlowMockTabList";

export const StateFlowMockPreview = observer(({ store }) => {
    const { manifest, mock, triggers, conditions, state, subState } = store;

    return (
        <>
            <StateFlowMockHeader mock={mock} triggers={triggers} conditions={conditions} onTrigger={(options) => store.onTrigger(options)} />
            <StateFlowProgressBar manifest={manifest} subject={mock} state={state} subState={subState} />
            <StateFlowMockTabList mock={mock} onTrigger={(options) => store.onTrigger(options)} />
        </>
    );
});
