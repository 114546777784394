import React from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";

import { Stack } from "office-ui-fabric-react/lib/Stack";
import { PrimaryButton, ActionButton } from "office-ui-fabric-react/lib/Button";
import { PanelType } from "office-ui-fabric-react/lib/Panel";

import { useStores } from "../../../../hooks/useStores";

import { ObservablePanel } from "../../../base/components/ObservablePanel";
import { RegistryForm } from "../../components/registries/RegistryForm";

export const RegistryNewPanel = withRouter(
    observer((props) => {
        const { onClosePanel, onCreateNewRiskRegistry } = props;
        const { riskStore, principalContext } = useStores();
        const { riskRegistryNewFormStore, registryStore } = riskStore;
        const { riskRegistriesRoles } = registryStore;
        const { visible, formData, isValid, isSaving } = riskRegistryNewFormStore;

        return (
            <ObservablePanel
                hidden={!visible}
                pageType={isSaving ? "Saving ..." : "Create a new Risk Registry"}
                panelType={PanelType.medium}
                onClosePanel={onClosePanel}
                onRenderFooter={() => {
                    return (
                        <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 20 }}>
                            <ActionButton
                                styles={{ root: { height: "32px" } }}
                                onClick={() => {
                                    riskRegistryNewFormStore.reset();
                                    riskRegistryNewFormStore.hide();
                                    if (onClosePanel) onClosePanel();
                                }}
                                text="Cancel"
                            />
                            {riskRegistryNewFormStore.isChanged && (
                                <ActionButton
                                    styles={{ root: { height: "32px" } }}
                                    onClick={() => {
                                        riskRegistryNewFormStore.reset();
                                    }}
                                    text="Revert changes"
                                />
                            )}
                            <PrimaryButton
                                onClick={async () => {
                                    await riskRegistryNewFormStore.save();
                                    if (onCreateNewRiskRegistry) onCreateNewRiskRegistry();
                                }}
                                iconProps={{ iconName: "Save" }}
                                text="Save"
                                disabled={!isValid}
                            />
                        </Stack>
                    );
                }}
            >
                {formData && (
                    <RegistryForm
                        formData={formData}
                        riskRegistriesRoles={riskRegistriesRoles}
                        principalContextRoles={principalContext.roles}
                    />
                )}
            </ObservablePanel>
        );
    })
);
