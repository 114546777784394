import React from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { Stack } from "@ui/elements/Stack";
import { Text } from "@ui/elements/Text";
import { FontIcon } from "@ui/elements/Icon";

import {
    getScopeFromEventGroup,
    getEventIconName,
    getActivityIconName,
} from "@modules/workflows/stores/WorkflowConstants";

const rootClassName = cx(
    "cygraph-ActivityBuilderCardView-root",
    css`
        background: var(--white);
        border-radius: var(--border-radius);
        box-shadow: var(--box-shadow);
        border: 1px solid var(--border-color);
        padding: var(--spacing-s);
        margin-bottom: var(--spacing-xs);

        &:hover {
            background: var(--background-color);
            cursor: pointer;
        }
    `
);

export const ActivityBuilderCardView = observer(({ className, instance, onInstanceClick }) => {
    const scope = getScopeFromEventGroup(instance.trigger.group);
    return (
        <Stack
            className={cx(rootClassName, className)}
            tokens={{ childrenGap: 5 }}
            verticalFill
            verticalAlign="center"
            horizontalAlign="center"
            onClick={() => onInstanceClick(instance)}
        >
            <Stack horizontal tokens={{ childrenGap: 25 }} verticalFill verticalAlign="center" horizontalAlign="center">
                <FontIcon iconName={getEventIconName(instance.trigger.event)} style={{ fontSize: 30 }} />
                <FontIcon iconName="DoubleChevronRight8" style={{ fontSize: 13, color: "var(--grey-light)" }} />
                <FontIcon iconName={getActivityIconName(instance.definition)} style={{ fontSize: 30 }} />
            </Stack>
            <Stack.Item grow className="text--align--center">
                <Text variant="medium" block>
                    {instance.name}
                </Text>
                <Text variant="small" block>
                    {instance.notes}
                </Text>
                <div
                    style={{
                        backgroundColor: scope.color,
                        borderRadius: "var(--border-radius)",
                        padding: "2px var(--spacing-s)",
                        display: "inline-block",
                        marginTop: 15,
                        fontSize: 11,
                        width: 130,
                        color: "#000000",
                    }}
                >
                    {scope.name}
                </div>
            </Stack.Item>
        </Stack>
    );
});
