import React from "react";
import { observer } from "mobx-react";
import { Route, Switch } from "react-router";

import { DemandGeneralTab } from "./DemandGeneralTab";
import { DemandCatalogueTab } from "./DemandCatalogueTab";
import { ModuleWorkflowsTab } from "./ModuleWorkflowsTab";
import { ModuleAutomationsTab } from "./ModuleAutomationsTab";
import { DemandRecycleBinTab } from "./DemandRecycleBinTab";

export const DemandSettingsTabList = observer(({}) => {
    return (
        <Switch>
            <Route exact path="/settings/demand/catalogue" component={() => <DemandCatalogueTab />} />
            <Route
                exact
                path="/settings/demand/automations"
                component={() => (
                    <ModuleAutomationsTab
                        context={{ type: "module", id: "ef2eefe7-82b7-490c-a711-1b3807d27d7c", label: "Demand" }}
                    />
                )}
            />
            <Route exact path="/settings/demand/workflows" component={() => <ModuleWorkflowsTab target="WorkItem" />} />
            <Route exact path="/settings/demand/recycle-bin" component={() => <DemandRecycleBinTab />} />
            <Route path="/settings/demand" component={() => <DemandGeneralTab />} />
        </Switch>
    );
});
