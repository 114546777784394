import React from "react";
import { observer } from "mobx-react";

import { ControlDomainCardView } from "@modules/controls/components/domains/ControlDomainCardView";

export const ControlDomainCardListView = observer(({ domains, onDomainClick }) => {
    return (
        <div>
            <r-grid columns-l={3} columns={2} columns-s="1">
                {domains.map((domain) => (
                    <r-cell span="1" key={domain.id}>
                        <ControlDomainCardView domain={domain} onDomainClick={onDomainClick} />
                    </r-cell>
                ))}
            </r-grid>
        </div>
    );
});
