import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';

import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { Persona, PersonaSize } from 'office-ui-fabric-react/lib/Persona';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';

import { Label } from 'office-ui-fabric-react/lib/Label';

const dropdownStyles = { dropdown: { width: 270} };

export const PrincipalDropDown = observer(
    class PrincipalDropDown extends Component {
        
        onDropDownChange = (e, item) => {
            const { onSelected, principals } = this.props;
            const selectedUser = principals.find(P => P.id === item.key);
            onSelected(selectedUser);
        }

        onRenderOption = (option) => {
            const { principals } = this.props;
            const selectedOption = principals.find(P => P.id === option.key);
            return (
                    <Persona size={PersonaSize.size28} text={selectedOption.name} secondaryText={selectedOption.jobTitle} showSecondaryText={true} />
              );
        }

        onRenderTitle = (options) => {
            const { principals } = this.props;
            const option = options[0];
            const selectedOption = principals.find(P => P.id === option.key);
            return (
                    <Persona size={PersonaSize.size28} text={selectedOption.name} />
              );
        }

        render () {
            const { label, required, disabled, className, selected, multiple, loading, principals } = this.props;
            const items = multiple ? (selected || []) : (selected ? [selected] : []);

            return (
                <div className={cx(
                        'cygraph-PrincipalPicker-container',
                        className,
                    )}>
                    <Stack horizontal={true} verticalAlign={'center'}>
                        <Label required={required} disabled={disabled}>{label}&nbsp;</Label>
                        { loading && <Spinner size={SpinnerSize.xSmall} /> }
                    </Stack>
                    <Dropdown
                        selectedKey={items.map(s => s.id)}
                        onChange={this.onDropDownChange}
                        placeholder="Select a contact"
                        options={principals.map(p => {return { text: p.name, key: p.id}})}
                        styles={dropdownStyles}
                        onRenderOption={this.onRenderOption}
                        onRenderTitle={this.onRenderTitle}
                    />
                </div>
            );
        }
    }
);