import React, { Component } from "react";
import { Route, Switch } from "react-router";
import { observer, inject } from "mobx-react";
import * as microsoftTeams from "@microsoft/teams-js";

import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";

import { TabConfigurePage } from "./containers/pages/core/TabConfigurePage";
import { TriageFormPage } from "./containers/pages/triage/TriageFormPage";
import { AssessmentBrowsePage } from "./containers/pages/assessments/AssessmentBrowsePage";
import { AssessmentViewerPage } from "./containers/pages/assessments/AssessmentViewerPage";
import { AssessmentModulePage } from "./containers/pages/assessments/AssessmentModulePage";
import { RiskBrowsePage } from "./containers/pages/risks/RiskBrowsePage";
import { RiskViewPage } from "./containers/pages/risks/RiskViewPage";
import { WorkItemBrowsePage } from "./containers/pages/workitems/WorkItemBrowsePage";
import { WorkItemViewerPage } from "./containers/pages/workitems/WorkItemViewerPage";
import { ProductCataloguePage } from "./containers/pages/products/ProductCataloguePage";

import "../../css/teams.css";

export const TeamsApp = inject(
    "themeProvider",
    "principalContext",
    "productStore",
    "routing"
)(
    observer(
        class TeamsApp extends Component {
            state = {};

            componentDidMount() {
                try {
                    microsoftTeams.initialize(() => {
                        this.setState({ initialized: true });
                    });
                    microsoftTeams.appInitialization.notifyAppLoaded();
                    microsoftTeams.getContext(async (context) => {
                        this.updateTheme(context.theme);
                        microsoftTeams.registerOnThemeChangeHandler(this.updateTheme);
                        microsoftTeams.appInitialization.notifySuccess();
                    });
                } catch (err) {
                    microsoftTeams.appInitialization.notifyFailure({
                        reason: microsoftTeams.appInitialization.FailedReason.Other,
                        message: err ? err.message : "Unknown",
                    });
                }

                try {
                    this.props.principalContext.loadCurrentPrincipal();
                    this.props.productStore.loadProducts();
                } catch (e) {
                    console.error(e);
                }
            }

            updateTheme = (theme) => {
                this.props.themeProvider.setTheme(theme);
            };

            render() {
                return !this.state.initialized ? (
                    <div>
                        <Spinner size={SpinnerSize.large} label="Attempting to log you in..." />
                    </div>
                ) : (
                    <div style={{ padding: "0 15px 15px 15px" }}>
                        <Switch>
                            <Route path="/teams/tabs/configure" component={() => <TabConfigurePage />} />
                            <Route path="/teams/assurance/triage" component={() => <TriageFormPage />} />
                            <Route
                                path="/teams/assurance/browse/:id/-/:moduleId"
                                component={() => <AssessmentModulePage />}
                            />
                            <Route
                                path="/teams/assurance/browse/:id/:tab?"
                                component={() => <AssessmentViewerPage />}
                            />
                            <Route path="/teams/assurance/browse/:id" component={() => <AssessmentViewerPage />} />
                            <Route path="/teams/assurance/browse" component={() => <AssessmentBrowsePage />} />
                            <Route path="/teams/risks/browse/:id/:tab?" component={() => <RiskViewPage />} />
                            <Route path="/teams/risks/browse/:id" component={() => <RiskViewPage />} />
                            <Route path="/teams/risks/browse" component={() => <RiskBrowsePage />} />
                            <Route path="/teams/demand/catalogue" component={() => <ProductCataloguePage />} />
                            <Route path="/teams/demand/browse/:id/:tab?" component={() => <WorkItemViewerPage />} />
                            <Route path="/teams/demand/browse/:id" component={() => <WorkItemViewerPage />} />
                            <Route path="/teams/demand/browse" component={() => <WorkItemBrowsePage />} />
                        </Switch>
                    </div>
                );
            }
        }
    )
);
