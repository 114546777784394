import React from "react";
import { observer } from "mobx-react";
import Moment from "react-moment";

import { MessageBar } from "@ui/elements/MessageBar";
import { Text } from "@ui/elements/Text";

import { DetailsListLayoutMode, SelectionMode, ConstrainMode } from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { WrappedContent } from "@modules/base/components/WrappedContent";

export const WorkflowHistoryActivityGridView = observer((props) => {
    const { className, activities, loading, disableSelection } = props;

    const columns = [
        {
            key: "action",
            name: "Action",
            minWidth: 150,
            isRowHeader: true,
            isResizable: true,
            data: "string",
            onRender: (item) => {
                return (
                    <WrappedContent>
                        <div>{item.activity.name}</div>
                        <Text variant="small">
                            <Moment utc date={item.started} format="Do MMM YYYY, h:mm:ss a" />
                        </Text>
                    </WrappedContent>
                );
            },
            isPadded: true,
        },
        {
            key: "status",
            name: "Status",
            fieldName: "status",
            minWidth: 190,
            maxWidth: 220,
            isResizable: false,
            data: "string",
            onRender: (item) => {
                return (
                    <WrappedContent>
                        {item.status}
                        <br />
                        {item.message}
                    </WrappedContent>
                );
            },
            isPadded: false,
        },
    ];

    return (
        <BoxPanel shadow className={className}>
            <ShimmeredDetailsList
                items={activities}
                compact={false}
                columns={columns}
                selectionMode={disableSelection ? SelectionMode.none : SelectionMode.none}
                getKey={(item, index) => (item ? item.id : index)}
                setKey="single"
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.horizontalConstrained}
                isHeaderVisible={true}
                selectionPreservedOnEmptyClick={true}
                //onItemContextMenu={this._onItemContextMenu}
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="Row checkbox"
                enableShimmer={loading}
                removeFadingOverlay={true}
                shimmerLines={15}
                ariaLabelForShimmer="Automation actions being fetched"
                ariaLabelForGrid="Automation actions list"
            />

            {!loading && !activities.length && <MessageBar>There are no actions found.</MessageBar>}
        </BoxPanel>
    );
});
