import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';

import { PageHierarchyStore } from '../../stores/PageHierarchyStore';
import { PageHierarchyNav as StatelessPageHierarchyNav } from '../../components/core/PageHierarchyNav';

export const PageHierarchyNav = inject('pageStore', 'routing')(observer(
  class PageHierarchyNav extends Component {

    constructor(props) {
      super(props);

      this.state = {
        hierarchyStore: new PageHierarchyStore(props.pageStore)
      }
    }

    //   componentWillMount() {
    //     const { hierarchyStore } = this.state;
    //     const { libraryId, pageId, maxLevel, isPublished } = this.props;

    //     console.log({ libraryId, pageId, maxLevel, isPublished });

    //     hierarchyStore.loadHierarchy({ libraryId, pageId, maxLevel, isPublished });
    // }

    componentWillReceiveProps(nextProps) {
        const { hierarchyStore } = this.state;
        const { libraryId, pageId, maxLevel, isPublished } = nextProps;

        hierarchyStore.loadHierarchy({ libraryId, pageId, maxLevel, isPublished });
    }

    render () {
      const { hierarchyStore } = this.state;
      const { loading, keywords, sitemap } = hierarchyStore;

      return (
        <div className="sidebar sidebar--left sidebar--nav">
          <div className="sidebar--inner">

            <SearchBox 
              placeholder="Filter pages"
              autoComplete="off"
              styles={{ root: { height: '40px', borderColor: 'var(--border-color-dark)' }}}
              underlined
              value={keywords} 
              onClear={(e) => hierarchyStore.setKeywords(null)}
              onChange={(e, newValue) => hierarchyStore.setKeywords(newValue)} />

            <StatelessPageHierarchyNav 
              loading={loading} 
              sitemap={sitemap}
              onItemClick={this._onLinkClick} />

          </div>
        </div>
      );
    }

    _onLinkClick = (item) => {
      if(item && item.url) {
        this.props.routing.push(item.url);
      }
    };
  }
));