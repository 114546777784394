import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { ReviewGridView } from "@modules/risks/components/reviews/ReviewGridView";

export const RiskReviewsTab = observer(({ risk }) => {
    const { riskStore } = useStores();
    const { loading, reviews } = riskStore.reviewStore;

    const riskId = risk ? risk.id : null;
    const isExceptionTemplate = risk.type && risk.type.template === "zerodai:risks:exception";
    const isControlException = isExceptionTemplate && risk.type.name === "Control Exception";
    useEffect(() => {
        if (riskId) {
            riskStore.reviewStore.loadRiskReviews(riskId);
        }
    }, [riskId, riskStore.reviewStore]);

    return (
        <ReviewGridView
            loading={loading}
            reviews={reviews}
            isExceptionTemplate={isExceptionTemplate}
            isControlException={isControlException}
            onReviewClick={(review) => {}}
        />
    );
});
