import React from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { Icon } from "office-ui-fabric-react/lib/Icon";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";
import { IconButton, ActionButton } from "office-ui-fabric-react/lib/Button";
import { Stack } from "office-ui-fabric-react/lib/Stack";

const rootClassName = cx(
    "cygraph-IconBadgeButton-root",
    css`
        & > span.ms-Button-flexContainer {
            position: relative;
        }

        & .badge-icon {
            font-size: 18px;
            margin: 0px 4px;
            height: 16px;
            line-height: 16px;
            text-align: center;
            vertical-align: middle;
            flex-shrink: 0;
            display: inline-block;
        }
        & .badge {
            position: absolute;
            background-color: transparent;
            color: #ffffff;
            border-radius: 8px;
            min-width: 16px;
            font-size: 11px;
            padding: 1px 5px;
            display: inline-block;
            top: -2px;
            left: -2px;

            &.loading {
                top: 3px;
                left: -5px;
            }

            &.hide {
                display: none;
            }

            &.default {
                background-color: var(--grey-dark);
            }
            &.info {
                background-color: var(--blue-dark);
            }
            &.success {
                background-color: var(--green-dark);
            }
            &.warning {
                background-color: var(--orange-dark);
            }
            &.error {
                background-color: var(--red-dark);
            }
        }
    `
);

export const IconBadgeButton = observer(({ className, iconProps, badgeProps, text, onClick, ...rest }) => {
    return (
        <Stack horizontal verticalAlign="center" verticalFill>
            <IconButton
                {...rest}
                onClick={onClick}
                className={cx(rootClassName, className)}
                onRenderIcon={() => (
                    <span className="badge-icon">
                        {badgeProps && (
                            <span
                                className={cx(
                                    "badge",
                                    badgeProps.loading && "loading",
                                    !badgeProps.loading && badgeProps.variant,
                                    badgeProps.hideZero && badgeProps.value === 0 && "hide"
                                )}
                            >
                                {badgeProps.loading ? <Spinner size={SpinnerSize.xSmall} /> : <span>{badgeProps.value}</span>}
                            </span>
                        )}
                        <Icon {...iconProps} />
                    </span>
                )}
            />
            {text && <ActionButton onClick={onClick}>{text}</ActionButton>}
        </Stack>
    );
});
