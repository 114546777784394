import React from "react";

import { MoreInfoCallout } from "../../../base/components/MoreInfoCallout";
import { MarkdownViewer } from "../../../editors/components/MarkdownViewer";

export function RiskScoreTooltip({ tooltip }) {
    return tooltip ? (
        <MoreInfoCallout label="More Info" iconProps={{ iconName: "Info" }}>
            <MarkdownViewer value={tooltip} />
        </MoreInfoCallout>
    ) : null;
}
