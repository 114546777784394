import React, { Component } from "react";
import { observer, inject } from "mobx-react";

import { AppPage } from "../../../layout/containers/AppPage";
import { ObservablePagination } from "../../../base/components/ObservablePagination";

import { SettingsListNav } from "../../../settings/containers/utilities/SettingsListNav";

import { PatternGridView } from "../../components/core/PatternGridView";
import { PatternCommandBar } from "../../components/core/PatternCommandBar";

const getQueryStringParams = (query) => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query).split("&").reduce((params, param) => {
              let [key, value] = param.split("=");
              params[key] = value ? decodeURIComponent(value.replace(/\+/g, " ")) : "";
              return params;
          }, {})
        : {};
};

export const PatternBrowsePage = inject(
    "pageContext",
    "patternStore",
    "routing"
)(
    observer(
        class PatternBrowsePage extends Component {
            componentWillMount() {
                const { patternStore, routing } = this.props;

                const params = getQueryStringParams(routing.location.search);
                patternStore.browseStore.loadPatterns({ page: params["p"] || 1, keywords: params["k"] });
            }

            componentWillReceiveProps(nextProps) {
                const { patternStore, routing } = nextProps;

                const params = getQueryStringParams(routing.location.search);
                patternStore.browseStore.loadPatterns({ page: params["p"] || 1, keywords: params["k"] });
            }

            render() {
                const { patternStore, routing } = this.props;
                const { loading, error, breadcrumb, patterns, pagination, query } = patternStore.browseStore;

                return (
                    <AppPage error={error} breadcrumb={breadcrumb} loading={loading} hasSidebar={false} className="settings patterns list has--page-nav">
                        <SettingsListNav />
                        <div>
                            <PatternCommandBar
                                keywords={query.keywords}
                                onSearchChange={(keywords) => {
                                    if (keywords) {
                                        routing.push(`/settings/patterns?k=${encodeURIComponent(keywords)}`);
                                    } else {
                                        routing.push(`/settings/patterns`);
                                    }
                                }}
                            />

                            <PatternGridView
                                patterns={patterns}
                                loading={loading}
                                query={{}}
                                onPatternClick={(pattern) => routing.push(`/settings/patterns/${pattern.id}`)}
                            />

                            <ObservablePagination
                                {...pagination}
                                onPageChange={(index) => {
                                    console.log(index);
                                    routing.push(
                                        `/settings/patterns?p=${index + 1}${patternStore.keywords ? `&k=${encodeURIComponent(patternStore.keywords)}` : ""}`
                                    );
                                }}
                            />
                        </div>
                    </AppPage>
                );
            }
        }
    )
);
