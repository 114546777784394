import React, { Component } from "react";
import { observer } from "mobx-react";
import Moment from "react-moment";

import { Text } from "office-ui-fabric-react/lib/Text";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { MessageBar } from "office-ui-fabric-react/lib/MessageBar";
import { DetailsListLayoutMode, Selection, SelectionMode, ConstrainMode } from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { ContextualMenu } from "office-ui-fabric-react/lib/ContextualMenu";
import { TooltipHost } from "office-ui-fabric-react/lib/Tooltip";
import { List } from "office-ui-fabric-react/lib/List";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { RoutingLink } from "@modules/base/components/RoutingLink";
import { WrappedContent } from "@modules/base/components/WrappedContent";

import { AdvisoryCompliancePill } from "./AdvisoryCompliancePill";
import { AdvisoryStatus } from "./AdvisoryStatus";
import { AdvisorySeverity } from "./AdvisorySeverity";

export const AdvisoryGridView = observer(
    class AdvisoryGridView extends Component {
        constructor(props) {
            super(props);

            this.state = {};

            this._selection = new Selection({
                onSelectionChanged: () => {
                    if (this.props.onSelectionChanged) {
                        this.props.onSelectionChanged(this._selection);
                    }
                },
            });
        }

        render() {
            const {
                loading,
                className,
                onAdvisoryClick,
                disableSelection,
                riskMappings,
                evidenceMappings,
                showAssessment,
                onClickAssessment,
                disableAdvisoriesClick,
            } = this.props;
            const { contextualMenuProps } = this.state;

            this._columns = [
                {
                    key: "title",
                    name: "Title",
                    fieldName: "title",
                    minWidth: 310,
                    isRowHeader: true,
                    isResizable: true,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        const riskMapping = riskMappings && riskMappings.find((m) => m.advisoryId == item.id);
                        const evidenceMapping = evidenceMappings && evidenceMappings.find((m) => m.advisoryId == item.id);
                        return (
                            <WrappedContent>
                                {riskMapping && riskMapping.risks.length && (
                                    <div style={{ float: "right", marginLeft: "5px" }}>
                                        <TooltipHost content={this._getRiskMappingTooltip(riskMapping)}>
                                            <Icon iconName="Shield" />
                                            <Text variant="small">&nbsp;{riskMapping.risks.length}</Text>
                                        </TooltipHost>
                                    </div>
                                )}
                                {evidenceMapping && (
                                    <div style={{ float: "right" }}>
                                        <TooltipHost content={`${evidenceMapping.count} ${evidenceMapping.count == 1 ? "evidence" : "evidences"}`}>
                                            {disableAdvisoriesClick ? (
                                                <>
                                                    <Icon iconName="ActivateOrders" />
                                                    <Text variant="small">&nbsp;{evidenceMapping.count}</Text>
                                                </>
                                            ) : (
                                                <>
                                                    <a
                                                        href="#"
                                                        onClick={(ev) => {
                                                            ev.preventDefault();
                                                            ev.stopPropagation();
                                                            this.props.onAdvisoryClick(item);
                                                        }}
                                                    >
                                                        <Icon iconName="ActivateOrders" />
                                                        <Text variant="small">&nbsp;{evidenceMapping.count}</Text>
                                                    </a>
                                                </>
                                            )}
                                        </TooltipHost>
                                    </div>
                                )}
                                {disableAdvisoriesClick ? (
                                    <span>{item.title}</span>
                                ) : (
                                    <a
                                        href="#"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            ev.stopPropagation();
                                            this.props.onAdvisoryClick(item);
                                        }}
                                    >
                                        <span>{item.title}</span>
                                    </a>
                                )}
                                <br />
                                {item.references.find((r) => r.type == "Measure") && (
                                    <Text variant="small">
                                        <span>{item.references.find((r) => r.type == "Module").title}</span> /
                                        <span> {item.references.find((r) => r.type == "Group").title}</span> /
                                        <span> {item.references.find((r) => r.type == "Measure").title}</span>
                                    </Text>
                                )}
                            </WrappedContent>
                        );
                    },
                    isPadded: true,
                },
                {
                    key: "compliance",
                    name: "Compliance",
                    fieldName: "initialCompliance",
                    minWidth: 100,
                    maxWidth: 130,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return <AdvisoryCompliancePill advisory={item} />;
                    },
                    isPadded: false,
                },
                {
                    key: "control",
                    name: "Control",
                    fieldName: "control",
                    minWidth: 230,
                    maxWidth: 250,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return <WrappedContent>{item.control.value}</WrappedContent>;
                    },
                    isPadded: false,
                },
                {
                    key: "level",
                    name: "Severity",
                    fieldName: "level",
                    minWidth: 100,
                    maxWidth: 130,
                    isResizable: false,
                    isSorted: false,
                    isSortedDescending: false,
                    sortAscendingAriaLabel: "Sorted A to Z",
                    sortDescendingAriaLabel: "Sorted Z to A",
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return <AdvisorySeverity advisory={item} />;
                    },
                    isPadded: false,
                },
                {
                    key: "modified",
                    name: "Status",
                    fieldName: "modified",
                    minWidth: 120,
                    maxWidth: 150,
                    isResizable: true,
                    onColumnClick: this._onColumnClick,
                    data: "date",
                    onRender: (item) => {
                        return (
                            <>
                                <AdvisoryStatus advisory={item} />
                                <Moment utc date={item.modified} fromNow />
                            </>
                        );
                    },
                },
            ];

            if (showAssessment) {
                this._columns.splice(1, 0, {
                    key: "assessment",
                    name: "Assessment",
                    fieldName: "assessment",
                    minWidth: 100,
                    maxWidth: 120,
                    isResizable: false,
                    data: "string",
                    onRender: (item) => {
                        return <RoutingLink url={`/assurance/browse/${item.assessment.id}`}>{item.assessment.code}</RoutingLink>;
                    },
                    isPadded: false,
                });
            }

            const filtered = this._getFilteredAdvisories();

            return (
                <BoxPanel shadow className={className}>
                    <ShimmeredDetailsList
                        items={filtered}
                        compact={false}
                        columns={this._columns}
                        selectionMode={disableSelection ? SelectionMode.none : SelectionMode.multiple}
                        getKey={this._getKey}
                        setKey="multiple"
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.horizontalConstrained}
                        isHeaderVisible={true}
                        selection={this._selection}
                        selectionPreservedOnEmptyClick={true}
                        onItemInvoked={onAdvisoryClick}
                        //onItemContextMenu={(item, index, ev) => this._onItemContextMenu(item, index, ev) }
                        enterModalSelectionOnTouch={true}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        checkButtonAriaLabel="Row checkbox"
                        enableShimmer={loading}
                        removeFadingOverlay={true}
                        shimmerLines={25}
                        ariaLabelForShimmer="Advisories are being fetched"
                        ariaLabelForGrid="Advisory list"
                    />

                    {!loading && !filtered.length && <MessageBar>There are no matching advisories in this view.</MessageBar>}

                    {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}
                </BoxPanel>
            );
        }

        _getFilteredAdvisories() {
            const { advisories, view, keywords, disableClientSideFiltering } = this.props;

            var filtered = advisories;
            if (!!!disableClientSideFiltering) {
                switch (view || "all") {
                    case "open":
                        filtered = advisories.filter((a) => !a.finalCompliance);
                        break;
                    case "compliant":
                        filtered = advisories.filter((a) => a.finalCompliance == "Compliant");
                        break;
                    case "nonCompliant":
                        filtered = advisories.filter((a) => a.finalCompliance == "NonCompliant");
                        break;
                }

                if (keywords) {
                    filtered = filtered.filter((a) => a.title.toLowerCase().indexOf(keywords.toLowerCase()) != -1);
                }
            }
            return filtered;
        }

        _getRiskMappingTooltip(riskMapping) {
            return (
                <List
                    items={riskMapping.risks}
                    onRenderCell={(risk) => (
                        <div className="row--xs">
                            <RoutingLink url={`/${risk.type.template === "zerodai:risks:exception" ? "exceptions" : "risks"}/browse/${risk.id}`}>
                                {risk.code}
                            </RoutingLink>
                            <br />
                            {risk.title}
                        </div>
                    )}
                />
            );
        }

        _getKey(item, index) {
            return item ? item.id : index;
        }

        _getSelectionDetails() {
            const selectionCount = this._selection.getSelectedCount();

            switch (selectionCount) {
                case 0:
                    return "No items selected";
                case 1:
                    return "1 item selected: " + this._selection.getSelection()[0].name;
                default:
                    return `${selectionCount} items selected`;
            }
        }

        _onColumnClick(ev, column) {}

        _onItemContextMenu(item, index, ev) {
            const contextualMenuProps = {
                target: ev.target,
                items: [
                    {
                        key: "text",
                        name: `${this._selection.getSelectedCount()} selected`,
                    },
                ],
                onDismiss: () => {
                    this.setState({
                        contextualMenuProps: undefined,
                    });
                },
            };

            if (index > -1) {
                this.setState({
                    contextualMenuProps: contextualMenuProps,
                });
            }

            return false;
        }
    }
);
