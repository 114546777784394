import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

export const AdvisoryStatus = observer(
    class AdvisoryStatus extends Component {
        render () {
            const { advisory } = this.props;
            
            if(advisory.state == 'Open' || !advisory.finalCompliance ) {
              return <div>{advisory.state}</div>;
            }

            return <div>{ advisory.finalCompliance == 'NonCompliant' ? 'Non Compliant' : advisory.finalCompliance }</div>;
        }
    }
);