import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { DueIndicator } from '../../../base/components/DueIndicator';

export const WorkItemDueIndicator = observer(
    class WorkItemDueIndicator extends Component {

        render () {
            const { className, workItem } = this.props;

            if(!workItem || !workItem.dueDate || workItem.status == 'Cancelled') {
                return null;
            }
            
            return (
                <DueIndicator className={className} dueDate={workItem.dueDate} closedDate={workItem.closed} />
            );
        }
    }
);