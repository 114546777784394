import { observable, action, computed, flow} from "mobx";
import { WorkItemService } from '../../../api/workitems';
import { AssessmentService } from '../../../api/assessments';
import { RiskService } from '../../../api/risks';
import { RootStore } from '../RootStore';

export class ActivitiesFeedStore {
    private rootStore: RootStore;

    private selectedEntity: any = null;
    private selectedService : any = null;

    @observable public loading: boolean = false;
    @observable public activities: any[] = [];
    @observable public continuationToken: string = null;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @action async setSelected(type: string, entityId: string) {
        this.selectedEntity = entityId;
        this.setService(type);
         await this.loadInitialData();
    }

    setService(type: string) {
        switch (type) {
            case 'workItem':
                const workItemService = new WorkItemService(this.rootStore.authProvider);
                this.selectedService = workItemService.getWorkItemActivities.bind(workItemService);
            break;
            case 'assessment':
                const assessmentService = new AssessmentService(this.rootStore.authProvider);
                this.selectedService = assessmentService.getAssessmentActivities.bind(assessmentService);
            break;
            case 'risk':
                const riskService = new RiskService(this.rootStore.authProvider);
                this.selectedService = riskService.getRiskActivities.bind(riskService);
            break;
            default:
                this.selectedService = null;
            break;
        }
    }

    loadInitialData = flow(function*() {
        this.loading = true;
        try {
            if (this.selectedEntity && this.selectedService && !this.activities.length) {
                const result = yield this.selectedService(this.selectedEntity, null);
                this.activities = result.items;
                this.continuationToken = result.continuationToken
            }
            this.loading = false;
        } catch (e) {
            this.loading = false;
            throw e;
        }
    })

    loadMoreActivities = flow(function*() {
        this.loading = true;
        try {
            if (this.selectedEntity && this.selectedService && this.continuationToken) {
                const result = yield this.selectedService(this.selectedEntity, this.continuationToken);
                this.activities = this.activities.concat(result.items)
                this.continuationToken = result.continuationToken
            }
            this.loading = false;
        } catch (e) {
            this.loading = false;
            throw e;
        }
    })

}
