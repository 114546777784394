import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';
import { select as d3Select } from 'd3';
import { generate as c3Generate } from 'c3';
import { GaugeStore } from '../stores/GaugeStore';
import { SavePNGButton } from './common/SavePNGButton';
import { generateInlineTitle } from './common/generateInlineTitle';
import { mutationObserver } from './common/mutationObserver';

import 'c3/c3.css';

const chartContainerClassName = cx(css`
    .c3-axis-x text,
    .c3-axis-y text,
    .c3-title,
    .c3-legend-item
        {
            font-family: var(--font-family)
        }

    .c3 svg {
        background-color: white;
    }

    .c3 {
        height: 100%;
    }
`)

export const Gauge = observer((props) => {
 
    const gaugeStore = new GaugeStore();
    const [ data, setData ] = useState();
    const [ theme, setTheme ] = useState();
    const [ svgRef, setSvgRef ] = useState(null);
    const [ rendered, setRendered ] = useState(false);
    const [ mode, setMode ] = useState();
    const chartWrapperRef = useRef();

    if (props.dataSeries.length > 0) {
        if (JSON.stringify(data) !== JSON.stringify(props.dataSeries)) {
            setData(props.dataSeries);
        }
    }

    if (theme !== props.theme) {
        setTheme(props.theme);
    }
    
    gaugeStore.showTitle = props.showTitle;
    gaugeStore.rawData = props.dataSeries;
    gaugeStore.title = props.title;
    gaugeStore.showPNG = props.showPNG ? true : false;
    gaugeStore.setDimensions(props.width, props.height);

    if (mode !== props.mode && props.mode === 'edit') {
        gaugeStore.setDimensions(200, props.height);
        setMode('edit');
    }

    useEffect(() => {
        const element = d3Select(chartWrapperRef.current).node();
        if (!element) return
        c3Generate(gaugeStore.getChartData(element));
        let newSVG = generateInlineTitle(chartWrapperRef, theme);
        const pathsElements = newSVG.getElementsByClassName("c3-chart-arc");
        if (pathsElements) {
            for (let element of pathsElements) {
                const pathsElements = element.getElementsByTagName("path");
                for (let path of pathsElements) {
                    path.style.stroke = props.theme.palette.neutralLight;
                }
            }
        }
        const arcsElements = newSVG.getElementsByClassName("c3-chart-arcs-background");
        if (arcsElements) {
            for (let element of arcsElements) {
                const pathsElements = element.getElementsByTagName("path");
                for (let path of pathsElements) {
                    path.style.fill = props.theme.palette.neutralTertiaryAlt;
                    path.style.stroke = props.theme.palette.white;
                }
            }
        }
        if (!gaugeStore.onClickCallbackExists) {
            newSVG = mutationObserver(newSVG);
        }
        setSvgRef(newSVG);
        setRendered(true);
        props.onSVGChange(newSVG, `${gaugeStore.filename}.png`);
    },[data, theme, mode]);

    useEffect(() => {
        props.onDataChange(gaugeStore.csvData(), `${gaugeStore.filename}.csv`)
    },[data]);

    useEffect(() => {
        props.onRendered()
    },[rendered, data]);

    return <div>
            {props.dataSeries && props.dataSeries.length > 0 && <div ref={chartWrapperRef} className={chartContainerClassName} /> }
        </div>
    ;

});
