import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';

import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction'

import { Text } from 'office-ui-fabric-react/lib/Text';
import { MessageBar } from 'office-ui-fabric-react/lib/MessageBar';
import { PersonaSize } from 'office-ui-fabric-react/lib/Persona';
import { PhotoPersona } from "@identity/containers/utilities/PhotoPersona";

import { BoxPanel } from '@baseComponents/BoxPanel';

const rootClassName = cx(
  'cygraph-AssessmentTimelineView-root',
  css`
    & .user {
      padding-left 5px;
      display: inline-flex;
    }

    & .portfolio {
      margin-left: -13px;
      display: inline-block;
      min-height: 30px;
    }
  `
);

export const AssessmentTimelineView = observer(
    class AssessmentTimelineView extends Component {
      constructor(props) {
        super(props) 
      }

      render () {
          const { className, events, resources, loading, view, date, onDatesSet, onEventClick, onQuarterSet } = this.props;
          return (
            <BoxPanel className={cx(rootClassName, className)} shadow padding>
              <div className="bespokeCalendar"></div>
              <FullCalendar
                schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                height={resources.length == 0 ? 300 : "auto"}
                plugins={[ resourceTimelinePlugin, interactionPlugin ]}
                initialView={`resourceTimeline${view || 'Month'}`}
                weekends={false}
                expandRows={true}
                initialDate={date}
                headerToolbar={{
                  left: 'prev,next today',
                  center: 'title',
                  right: 'resourceTimelineWeek,resourceTimelineMonth,resourceTimelineQuarter'
                }}
                views = {{resourceTimelineQuarter: {
                    type: 'resourceTimeline',
                    buttonText: '3 months',
                    duration:{ months: 3},
                    slotDuration: {days: 7},
                    // visibleRange: function(currentDate) {
                    //   // Generate a new date for manipulating in the next step
                    //   const myDate = new Date(currentDate.valueOf());
                    //   const quarter = Math.floor((myDate.getMonth() + 3) / 3);
                    //   const year = myDate.getFullYear();
                    //   // debugger
                    //   console.log('currentDate',currentDate, year)
                    //   let timeframe = {
                    //     start: `${year}-01-01`,
                    //     end: `${year}-03-31`
                    //   }
                    //   switch (quarter) {
                    //     case 1:
                    //       timeframe = {
                    //         start: `${year}-01-01`,
                    //         end: `${year}-03-31`
                    //       }
                    //     break;
                    //     case 2:
                    //       timeframe = {
                    //         start: `${year}-04-01`,
                    //         end: `${year}-06-30`
                    //       }
                    //     break;
                    //     case 3:
                    //       timeframe = {
                    //         start: `${year}-07-01`,
                    //         end: `${year}-09-31`
                    //       }
                    //     break;
                    //     default:
                    //     case 4:
                    //       timeframe = {
                    //         start: `${year}-10-01`,
                    //         end: `${year}-12-31`
                    //       }
                    //     break;
                    //   }
                    //   return timeframe;
                    // }
                  }
                }}
                eventClick={onEventClick}
                dateClick={(info) => {
                  const calendarApi = info.view.calendar;
                  calendarApi.changeView('resourceTimelineWeek', info.date);
                }}
                datesSet={onDatesSet}
                events={events}
                resources={resources}
                resourceAreaWidth="25%"
                resourceGroupField="groupId"
                resourceOrder="userName,title"
                resourceGroupLabelContent={this.renderResourceGroup}
                resourceLabelContent={this.renderResource}
              />
              { ! loading && !events.length && (
                <MessageBar>
                  There are no matching assessments.
                </MessageBar>
              )}

            </BoxPanel> 
          );
      }

      renderResourceGroup = (args) => {
        const { users } = this.props;
        const user = users.find(u => u.id === args.groupValue).user;

        return (
          <div className="user">
            <PhotoPersona       
              principalId={user ? user.id : null}
              size={PersonaSize.size32}
              text={user ? user.name : 'Unassigned'} 
              showUnknownPersonaCoin={!user}
              showSecondaryText={user} 
              secondaryText={user && user.email ? user.email : 'Unknown'} />
          </div>
        );
      };

      renderResource = (args) => {
        return (
          <div className="portfolio">
            <Text variant="medium">Portfolio: {args.fieldValue}</Text>
          </div>
        );
      };
    }
);