import { observable, computed, action, flow } from "mobx";

import { RiskService } from "../../../api/risks";
import { RiskStore } from "./RiskStore";

export enum ViewTabType {
    Summary = "summary",
    Documents = "documents",
    Reviews = "reviews",
    Treatments = "treatments",
    Assessment = "assessment",
    Properties = "properties",
}

export class ExceptionViewerStore {
    public parentStore: RiskStore;
    public riskService: RiskService;

    @observable public loading: boolean = false;
    @observable public error: any;
    @observable public selectedTab: ViewTabType = ViewTabType.Summary;

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [
            {
                text: "Exception Management",
                key: "exceptions",
                onClick: () => this._goToUrl(`/exceptions/dashboard`),
            },
            {
                text: "Browse Exceptions",
                key: "browse",
                onClick: () => this._goToUrl(`/exceptions/browse`),
            },
        ];

        if (!this.risk) {
            return builder;
        }

        builder.push({
            text: this.risk.code,
            key: this.risk.code,
            isCurrentItem: true,
        });

        return builder;
    }

    @action
    public selectTab(tab: ViewTabType) {
        this.selectedTab = tab || ViewTabType.Summary;
    }

    @computed
    public get risk() {
        return this.parentStore.selectionStore.risk;
    }

    @computed
    public get lifecycle() {
        return this.parentStore.selectionStore.lifecycle;
    }

    @computed
    public get stateFlow() {
        return this.parentStore.stateFlowStore.manifest;
    }

    public loadRisk = flow(function* loadRisk(id: string) {
        this.loading = true;
        this.error = null;

        try {
            yield this.parentStore.selectionStore.loadRisk(id);
            yield this.parentStore.stateFlowStore.loadStateFlowManifest({
                stateFlowVersionId: this.risk.lifecycle.flowVersionId,
            });
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.loading = false;
        }
    });

    _goToUrl = (url) => {
        if (url) {
            this.parentStore.rootStore.routing.push(url);
        }
    };
}
