import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";

import { AppPage } from "../../../layout/containers/AppPage";
import { WorkItemNewPanel } from "../../../workitems/containers/panels/WorkItemNewPanel";

import { CatalogueListView } from "../../components/catalogue/CatalogueListView";
import { CatalogueCommandBar } from "../../components/catalogue/CatalogueCommandBar";
import { ProductDetailPanel } from "../panels/ProductDetailPanel";

export const ProductCataloguePage = inject(
    "pageContext",
    "productStore",
    "workItemStore",
    "routingManager"
)(
    withRouter(
        observer(
            class ProductCataloguePage extends Component {
                componentWillMount() {
                    const { productStore, match } = this.props;
                    productStore.clearProductSelected();
                    productStore.catalogueStore.loadProducts({});
                    productStore.optionsStore.loadProductOptions();
                }

                componentWillReceiveProps(nextProps) {
                    const { productStore, match } = nextProps;
                    productStore.clearProductSelected();
                    productStore.catalogueStore.loadProducts({});
                    productStore.optionsStore.loadProductOptions();
                }

                onHideNewWorkItemPanel = () => {
                    const { workItemStore } = this.props;
                    const { newFormStore } = workItemStore;
                    newFormStore.visible = false;
                    newFormStore.productId = "";
                    newFormStore.resetTheForm();
                };

                render() {
                    const { productStore, workItemStore, routingManager } = this.props;
                    const { catalogueStore, optionsStore } = productStore;
                    const { error, breadcrumb, isSimplifiedView, category } = catalogueStore;
                    const { newFormStore, lifecycleStore } = workItemStore;
                    return (
                        <AppPage
                            loading={catalogueStore.loading || optionsStore.loading}
                            error={error}
                            breadcrumb={breadcrumb}
                            hasSidebar={false}
                            className="products catalogue"
                        >
                            <>
                                <CatalogueCommandBar
                                    className="row--s"
                                    categories={optionsStore.categories}
                                    category={category}
                                    onCategoryChange={(category) => catalogueStore.setCategory(category)}
                                    isSimplifiedView={isSimplifiedView}
                                    onToggleViewChange={(ev, checked) => catalogueStore.setSimplifiedView(checked)}
                                    keywords={catalogueStore.keywords}
                                    onKeywordsChange={(keywords) => catalogueStore.onKeywordsChange(keywords)}
                                />

                                <CatalogueListView
                                    loading={catalogueStore.loading}
                                    products={catalogueStore.filteredProducts}
                                    onCardClick={(product) => productStore.setSelected(product)}
                                    isSimplifiedView={isSimplifiedView}
                                />

                                <ProductDetailPanel
                                    visible={productStore.selectedProduct && !newFormStore.visible}
                                    onClosePanel={() => productStore.clearProductSelected()}
                                    onCreateNewWorkItem={() =>
                                        lifecycleStore.onCreate({}, { workItem: { product: productStore.selectedProduct } }).then(({ success, workItem }) => {
                                            productStore.clearProductSelected();
                                            if (success && workItem) {
                                                routingManager.push(`/demand/browse/${workItem.id}`);
                                            }
                                        })
                                    }
                                    product={productStore.selectedProduct}
                                    isSimplifiedView={isSimplifiedView}
                                />
                            </>

                            {productStore.selectedProduct && newFormStore.visible && (
                                <WorkItemNewPanel store={newFormStore} onHidePanel={this.onHideNewWorkItemPanel} />
                            )}
                        </AppPage>
                    );
                }
            }
        )
    )
);
