import React, { Component } from "react";
import { observer } from "mobx-react";
import * as moment from "moment";

import { Persona, PersonaSize } from "office-ui-fabric-react/lib/Persona";
import { Link } from "office-ui-fabric-react/lib/Link";

import { TooltipHost } from "@ui/elements/Tooltip";

import { RoutingLink } from "@modules/base/components/RoutingLink";
import { PhotoPersona } from "@modules/identity/containers/utilities/PhotoPersona";

export const SidebarProperty = observer(
    class SidebarProperty extends Component {
        render() {
            const { label, tooltip } = this.props;
            return (
                <div className="row--xxs">
                    {label && <label className="label">{label}</label>}
                    {tooltip ? (
                        <div>
                            <TooltipHost content={tooltip}>{this._getValueFormatted()}</TooltipHost>
                        </div>
                    ) : (
                        <div>{this._getValueFormatted()}</div>
                    )}
                </div>
            );
        }

        _getValueFormatted() {
            const { value, type, url } = this.props;

            if (!value) {
                return "Not Set";
            }

            switch (type || "string") {
                case "string":
                    return url ? <RoutingLink url={url}>{value}</RoutingLink> : value;
                case "externalLink":
                    return <Link href={url}>{value}</Link>;
                case "datetime":
                    return moment.utc(value).format("Do MMM YYYY h:mm a");
                case "date":
                    return moment.utc(value).format("Do MMM YYYY");
                case "principal":
                    if (value.type == "User") {
                        return (
                            <PhotoPersona
                                principalId={value.id}
                                size={PersonaSize.size24}
                                text={value.name}
                                showSecondaryText={false}
                                secondaryText={value.jobTitle}
                                tertiaryText={value.email}
                            />
                        );
                    } else {
                        return <Persona size={PersonaSize.size24} text={value.name} />;
                    }
                default:
                    return value;
            }
        }
    }
);
