import React, { Component } from 'react';
import { cx, css} from 'emotion';
import { Checkbox } from 'office-ui-fabric-react';

const checkBoxClassName = cx(css`
  padding: 5px;
`);

export class CalloutDropDown extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showCallout: false,
            optionFilter: '',
        }
    }

    onCheckBoxChange = (element, checked) => {
        const { selectedField, onCheckBoxChange } = this.props;
        if (onCheckBoxChange) onCheckBoxChange(selectedField, element, checked);
    }

    render () {
        const { selectedField, filter, dataTest } = this.props;
        // console.log(`selectedField.items`, selectedField.items);
        return (
            <div data-test={`DropDown${dataTest}`}>
                {selectedField.items
                    .filter(item =>{
                        if (filter) {
                            return item.text.toLowerCase().includes(filter.toLowerCase())
                        } else {
                            return true
                        }
                    })
                    .map(item => {
                        return <Checkbox 
                            key={`k_${item.text}_${item.key}`}
                            className={checkBoxClassName}
                            label={item.text}
                            checked={item.checked}
                            onChange={(ev, checked)=> this.onCheckBoxChange(item.key, checked)}
                        />
                })}
            </div>)
    }
}