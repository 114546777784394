import React from "react";
import { observer } from "mobx-react";

import { Badge } from "@ui/elements/Badge";
import { MessageBar } from "office-ui-fabric-react/lib/MessageBar";
import { PersonaSize } from "office-ui-fabric-react/lib/Persona";
import { DetailsListLayoutMode, SelectionMode, ConstrainMode } from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { RoutingLink } from "@modules/base/components/RoutingLink";
import { WrappedContent } from "@modules/base/components/WrappedContent";
import { PhotoPersona } from "@modules/identity/containers/utilities/PhotoPersona";
import Moment from "react-moment";

export const IdentityGroupGridView = observer(({ loading, groups, onGroupClick }) => {
    const columns = [
        {
            key: "name",
            name: "Name",
            fieldName: "name",
            minWidth: 250,
            data: "string",
            onRender: (item) => {
                return (
                    <WrappedContent>
                        <RoutingLink
                            url={`/settings/identity/groups/${item.id}`}
                            onClick={() => onGroupClick && onGroupClick(item)}
                            onClickEnabled={onGroupClick}
                        >
                            <PhotoPersona size={PersonaSize.size28} principalId={item.id} text={item.name} />
                        </RoutingLink>
                    </WrappedContent>
                );
            },
            isPadded: true,
        },
        {
            key: "email",
            name: "Email",
            fieldName: "email",
            minWidth: 210,
            maxWidth: 250,
            data: "string",
            onRender: (item) => {
                return <WrappedContent>{item.email || "-"}</WrappedContent>;
            },
        },
        {
            key: "created",
            name: "Created",
            fieldName: "created",
            minWidth: 250,
            maxWidth: 290,
            data: "string",
            onRender: (item) => {
                return <Moment utc date={item.created} format="Do MMM YYYY h:mm a" />;
            },
        },
    ];

    return (
        <BoxPanel shadow>
            <ShimmeredDetailsList
                items={groups}
                compact={false}
                columns={columns}
                selectionMode={SelectionMode.none}
                getKey={(item, index) => (item ? item.id : index)}
                setKey="single"
                onItemInvoked={onGroupClick}
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.horizontalConstrained}
                isHeaderVisible={true}
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="Row checkbox"
                enableShimmer={loading}
                removeFadingOverlay={true}
                shimmerLines={25}
                ariaLabelForShimmer="Groups being fetched"
                ariaLabelForGrid="Group list"
            />
            {!groups.length === 0 && !loading && <MessageBar>There are no matching groups.</MessageBar>}
        </BoxPanel>
    );
});
