import React, { PureComponent, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { cx, css} from 'emotion';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { ColorPicker} from 'office-ui-fabric-react/lib/ColorPicker';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Callout } from 'office-ui-fabric-react/lib/Callout';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { Slider } from 'office-ui-fabric-react/lib/Slider';
import moment from 'moment';

import { ObservableSeparator } from '../../../../../base/components/ObservableSeparator';
import RichTextEditor from '../../../../../editors/components/RichTextEditor';
import { SpinButtonSuffix } from '../../../../../base/components/SpinButtonSuffix';
import { PrincipalPicker } from '../../../../../identity/containers/PrincipalPicker';


const colorPickerClassName = cx (css`
    maxWidth: 352px,
    maxHeight: 352px,
    panel: {
        padding: 12
    },
    colorRectangle: {
        height: 268px
    },
`);

export const ProgressIndicatorPartEditor = observer((props) => {

    const [isColorPickerOpen, setIsColorPickerOpen] = useState(null)
    const { formData } = props;

    return (
            <div>
                <r-grid columns="2">
                    <r-cell span="2">
                        <ObservableSeparator>Progress Indicator Setup (HOOK)</ObservableSeparator>
                    </r-cell>
                    <r-cell span="2" style={{color: 'var(--text-color-light)'}}>
                        <Slider
                            label="Value"
                            max={100}
                            value={formData.properties.value}
                            showValue
                            onChange={(val) => formData.properties.value = val}
                        />
                    </r-cell>
                    <r-cell span="1" style={{color: 'var(--text-color-light)', display: 'flex'}}>
                        <TextField 
                            label="Headline text"
                            value={formData.properties.headlineText || `{value}%`}
                            onChange={(ev, val) => formData.properties.headlineText = val}
                            style={{minWidth: 270}}
                        />
                        <IconButton
                            className={`colorPicker`}
                            style={{marginTop: '28px'}}
                            title="Reset"
                            iconProps={{ iconName: 'Refresh' }}
                            onClick={()=>{ formData.properties.headlineText = '{value} of the work completed!' }}
                        />
                    </r-cell>
                    <r-cell span="2">
                        <Text style={{marginBottom:'10px'}} block>Subtext</Text>
                        <div style={{border: '1px grey solid', padding:'10px'}}>
                            <RichTextEditor 
                                className={cx(css` border: '1px grey solid'; min-height:'175px' `)}
                                value={formData.properties.subtext}
                                onChange={(value) => formData.properties.subtext = value} />                        
                        </div>
                    </r-cell>
                    <r-cell span="2" >
                        <Dropdown
                            required
                            label="Style"
                            selectedKey={formData.properties.style}
                            onChange={(ev, op) => {
                                if(op) {
                                    formData.properties.style = op.key;
                                }
                            }}
                            options={[{
                                    key: 'progressbar',
                                    text: "Progress Bar",
                                },{
                                    key: 'gauge',
                                    text: "Gauge Chart",
                                }]}
                            />
                    </r-cell>
                    {formData.properties.style === 'progressbar' && <r-grid columns="2">
                        <r-cell span="2" style={{color: 'var(--text-color-light)'}}>
                            <SpinButtonSuffix 
                                label="Bar thickness"
                                value={formData.properties.barThickness || `10`}
                                suffix="px"
                                min={0}
                                max={100}
                                increment={1}
                                onChange={(ev, val) => {
                                    formData.properties.barThickness = val}
                                }
                            />
                        </r-cell>       
                    </r-grid>}
                    {formData.properties.style === 'gauge' && <r-grid columns="2">
                        <r-cell span="2" style={{color: 'var(--text-color-light)'}}>
                            <TextField 
                                label="Chart label"
                                value={formData.properties.chartLabel || `Count`}
                                onChange={(ev, val) => {
                                    formData.properties.chartLabel = val}
                                }
                            />
                        </r-cell>       
                    </r-grid>}
                    <r-cell span="2" style={{display:'flex'}}>
                        <TextField 
                            label="Color"
                            readOnly
                            style={{
                                color: formData.properties.color || '#3788d8',
                                backgroundColor: formData.properties.color || '#3788d8',
                                cursor: 'context-menu',
                                minWidth: 270,
                            }}
                        />
                        <IconButton
                            className={`colorPicker`}
                            style={{marginTop: '28px'}}
                            title="Color Picker"
                            iconProps={{ iconName: 'Color' }}
                            onClick={()=>setIsColorPickerOpen(true)}
                        />
                        {isColorPickerOpen && <Callout                                        
                            role="alertdialog"
                            gapSpace={0}
                            target={`.colorPicker`}
                            onDismiss={() => setIsColorPickerOpen(true)}
                            setInitialFocus
                        >
                            <div className="inner">
                            <ColorPicker
                                color={formData.properties.color}
                                onChange={(ev, colorObject)=> {
                                    formData.properties.color = colorObject.str
                                }}
                                alphaType='none'
                                alphaSliderHidden={true}
                                showPreview={true}
                                className={colorPickerClassName}
                            />
                            </div>
                            </Callout>}
                    </r-cell>
                    <r-cell span="2">
                        <PrincipalPicker 
                            placeholder={'Pick owner'}
                            onSelected={(user) => formData.properties.owner = user}
                            selected={formData.properties.owner}
                            multiple={false}
                        />
                    </r-cell>
                    <r-cell span="2">
                        <ObservableSeparator>Footer Setup</ObservableSeparator>
                    </r-cell>
                    <r-cell span="1">
                        <Toggle 
                            label="Show link button (will open in a new window)"
                            checked={formData.properties.displayLinkButton}
                            onChange={(ev, checked) => {
                                formData.properties.displayLinkButton = checked;
                            }}  
                        />
                    </r-cell>
                    <r-cell span="1">
                        <TextField 
                            label="Link"
                            value={formData.properties.linkButtonLink}
                            onChange={(ev, val) => formData.properties.linkButtonLink = val}
                        />
                    </r-cell>
                    <r-cell span="1">
                        <Toggle 
                            label="Show Last update"
                            checked={formData.properties.displayLastUpdate}
                            onChange={(ev, checked) => {
                                formData.properties.displayLastUpdate = checked;
                            }}  
                        />
                    </r-cell>
                    <r-cell span="1">
                        <div style={{display: 'flex'}}>
                            <TextField 
                                label="Last update"
                                value={formData.properties.lastUpdateText}
                                onChange={(ev, val) => formData.properties.lastUpdateText = val}
                            />
                            <IconButton
                                className={`colorPicker`}
                                style={{marginTop: '28px'}}
                                title="Pick today"
                                iconProps={{ iconName: 'DateTime' }}
                                onClick={()=>{
                                    formData.properties.lastUpdateText = moment().utc().format('MMM Do YYYY')
                                }}
                            />
                        </div>
                    </r-cell>
            </r-grid>
    </div>
    );
});
