import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { useStores } from "@hooks/useStores";

import { Text } from "@ui/elements/Text";

import { AppPage } from "@modules/layout/containers/AppPage";

import { SettingsListNav } from "@modules/settings/containers/utilities/SettingsListNav";

import { DemandSettingsPivot } from "@modules/settings/containers/tabs/DemandSettingsPivot";
import { DemandSettingsTabList } from "@modules/settings/containers/tabs/DemandSettingsTabList";

export const DemandSettingsPage = withRouter(
    observer(({ match }) => {
        const { settingsStore } = useStores();
        const { demandSettingsStore } = settingsStore;
        const { breadcrumb, loading, error, defaultTab, selectedTab, activeTabs } = demandSettingsStore;

        useEffect(() => {
            if (match) {
                demandSettingsStore.setSelectedTab(match.params.tab);
            }
        }, [match.params.tab]);

        return (
            <AppPage
                loading={loading}
                error={error}
                breadcrumb={breadcrumb}
                hasSidebar={false}
                className="settings demand has--page-nav"
            >
                <SettingsListNav />
                <div>
                    <Text className="row--xxs" variant="xLarge" block>
                        Demand Management
                    </Text>
                    <Text className="row--s" variant="small" block>
                        Demand management module allows you to forecast, plan for and manage the demand for products and
                        services within the InfoSec function.
                    </Text>

                    <DemandSettingsPivot defaultTab={defaultTab} selectedTab={selectedTab} activeTabs={activeTabs} />
                    <DemandSettingsTabList />
                </div>
            </AppPage>
        );
    })
);
