import React from "react";
import { observer } from "mobx-react";

import { CommandBar } from "@ui/elements/CommandBar";

import { BoxPanel } from "@modules/base/components/BoxPanel";

import { AssetFilterBar } from "@modules/assets/components/core/AssetFilterBar";

export const AssetCommandBar = observer(
    ({ hideAgentActions, filterQuery, filterContext, onFilterChange, onNewAsset, onRefresh }) => {
        const items = [];

        if (!hideAgentActions) {
            if (onNewAsset) {
                items.push({
                    key: "newAsset",
                    text: "New Asset",
                    iconProps: { iconName: "Add" },
                    onClick: onNewAsset,
                });
            }

            if (onRefresh) {
                items.push({
                    key: "refresh",
                    text: "Refresh",
                    iconProps: { iconName: "Refresh" },
                    onClick: onRefresh,
                });
            }
        }

        const farItems = [];

        if (filterQuery && onFilterChange && filterContext) {
            farItems.push({
                key: "filterBar",
                text: "Filter Bar",
                onRender: () => {
                    return <AssetFilterBar value={filterQuery} onChange={onFilterChange} context={filterContext} />;
                },
            });
        }

        return (
            <BoxPanel shadow>
                <CommandBar
                    styles={{
                        root: {
                            /* paddingRight: "0px" */
                        },
                    }}
                    items={items}
                    farItems={farItems}
                />
            </BoxPanel>
        );
    }
);
