import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';

import Moment from 'react-moment';

const rootClassName = cx(
  'cygraph-PageMetadata-root',
  css` 
    color: var(--text-color-light);
    font-size: var(--font-size-xs);
    margin-top: var(--spacing-xs);
    
    & .meta {
      display: inline-block;
      margin-right: 20px;
    }
  `
);

export const PageMetadata = observer(
  class PageMetadata extends PureComponent {
    render () {
        const { className, page } = this.props;
        
        return (
            <div className={cx(rootClassName, className)}>             
              { page.content ? (<span className="meta">v{page.content.version}</span>) : (<span className="meta">v{page.currentVersion}</span>) }
              { page.published && <span className="meta">published <Moment utc date={page.published} fromNow /></span> }
              { page.publishedBy && <span className="meta">by {page.publishedBy.name}</span> }
              <span className="meta">{page.language}</span>
            </div>
        );
    }
  }
);