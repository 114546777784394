import React from "react";
import { observer } from "mobx-react";

import { Dropdown } from "office-ui-fabric-react/lib/Dropdown";
import { DatePicker } from "office-ui-fabric-react/lib/DatePicker";
import { Stack } from "office-ui-fabric-react/lib/Stack";

import { ObservableSeparator } from "@modules/base/components/ObservableSeparator";
import { BigTextField } from "@modules/base/components/BigTextField";
import { PrincipalPicker } from "@modules/identity/containers/PrincipalPicker";
import { SecurityTrimmed } from "@modules/identity/containers/utilities/SecurityTrimmed";
import { RoleNames } from "@modules/identity/stores/PrincipalContextUtilities";
import { AssetPicker } from "@modules/assets/containers/pickers/AssetPicker";
import { PortfolioPicker } from "@modules/portfolios/containers/pickers/PortfolioPicker";
import { RichTextField } from "@modules/editors/components/RichTextField";

export const DeploymentEditForm = observer(({ formData, formOptions }) => {
    return (
        <Stack tokens={{ childrenGap: 20 }} data-test="newWorkItemForm">
            <div>
                <r-grid columns="1">
                    <r-cell>
                        <BigTextField
                            label="Title"
                            required
                            placeholder="Please enter a request title"
                            value={formData.title}
                            transparent
                            onChange={(ev, value) => (formData.title = value)}
                        />
                    </r-cell>
                </r-grid>
            </div>
            <div>
                <r-grid columns="1">
                    <r-cell>
                        <ObservableSeparator>Key Information</ObservableSeparator>
                    </r-cell>
                </r-grid>
                <r-grid columns="2">
                    <r-cell>
                        <PortfolioPicker
                            className="cyPortfolioDropDown"
                            placeholder="Select a portfolio"
                            label="Portfolio"
                            required
                            selected={formData.portfolio}
                            onChange={(portfolio) => {
                                formData.portfolio = portfolio;
                            }}
                        />
                    </r-cell>
                    <r-cell>
                        <AssetPicker
                            label="Asset"
                            placeholder="Select an asset"
                            selectedId={formData.assetId}
                            onChange={(asset) => {
                                formData.assetId = asset ? asset.id : null;
                            }}
                        />
                    </r-cell>
                </r-grid>
                <r-grid columns="2">
                    <r-cell>
                        <DatePicker
                            label="Due date"
                            isRequired={true}
                            placeholder="Select a due date..."
                            ariaLabel="Select a due date"
                            value={formData.dueDate ? new Date(formData.dueDate) : null}
                            onSelectDate={(date) => (formData.dueDate = date)}
                        />
                    </r-cell>
                </r-grid>
                <r-grid columns="2">
                    <r-cell>
                        <PrincipalPicker
                            label="Business Contact"
                            selected={formData.businessContact}
                            onSelected={(contact) => (formData.businessContact = contact)}
                        />
                    </r-cell>
                    <r-cell>
                        <PrincipalPicker
                            label="Technical Contact"
                            selected={formData.technicalContact}
                            onSelected={(contact) => (formData.technicalContact = contact)}
                        />
                    </r-cell>
                </r-grid>
                <r-grid columns="1">
                    <r-cell>
                        <RichTextField
                            label="Description"
                            placeholder="Please enter a description of the work."
                            value={formData.description}
                            onChange={(val) => {
                                formData.description = val;
                            }}
                        />
                    </r-cell>
                </r-grid>
            </div>
        </Stack>
    );
});
