import React, { Component } from "react";
import { observer } from "mobx-react";

import { svgAsPngUri } from "save-svg-as-png";
import ContainerDimensions from "react-container-dimensions";
import { ReactRadarChart } from "d3-radarchart";

import { TextField } from "@ui/elements/TextField";
import { ComboBox } from "@ui/elements/ComboBox";

import { BigTextField } from "@baseComponents/BigTextField";
import { ObservableSeparator } from "@baseComponents/ObservableSeparator";

import { ControlMapAxisGridView } from "./ControlMapAxisGridView";
import { ControlMapAxisCommandBar } from "./ControlMapAxisCommandBar";

export const ControlMapEditForm = observer(
    class ControlMapEditForm extends Component {
        render() {
            const {
                loading,
                formData,
                options,
                controls,
                onAxisClick,
                onAxisRemoved,
                onAxisValuesUpdated,
                onSearchChange,
                onAddAxis,
                onAddControlAxis,
            } = this.props;

            const axisConfig = (formData ? formData.axes : []).map((axis) => ({
                label: axis.name,
                axisId: axis.id,
                axisValueMax: 1,
                axisValueMin: 0,
            }));

            const data = [
                {
                    label: "Reference Map",
                    seriesId: "ReferenceMap",
                    dragEnabled: true,
                    showCircle: true,
                    circleHighlight: true,
                    //fill: 'royalblue',
                    data: (formData ? formData.axes : []).map((axis) => ({
                        axis: axis.id,
                        value: axis.targetValue,
                    })),
                },
            ];

            const chartOptions = {
                chartRootName: "map",
                data,
                showLegend: false,
                enableZoom: false,
                rootElementId: "control-map-chart",
                axisConfig,
                options: {
                    axis: {
                        rotateTextWithAxis: false,
                        axisLabelProps: {
                            fontSize: 8,
                        },
                        axisScaleProps: {
                            minTitleSize: 6,
                            maxTitleSize: 18,
                        },
                    },
                    legend: {
                        title: "",
                        scaleTextWithSize: false,
                        iconWidthP: 0.04,
                        iconWidthP: 0.04,
                        textOffsetP: 0.75,
                        iconSpacingP: 0.06,
                    },
                    dims: {
                        translateXp: 0,
                        translateYp: 0,
                        legendSpaceP: 0,
                    },
                    area: {
                        onValueFinishChange: (series) => {
                            if (formData && onAxisValuesUpdated && this.chart && this.chart.chart) {
                                const state = this.chart.chart.state;
                                const newValues = formData.axes.reduce((accumulator, axis) => {
                                    accumulator[axis.id] = state.currentValueFor(series, axis.id);
                                    return accumulator;
                                }, {});

                                onAxisValuesUpdated(newValues);
                                //this.updateMapImage();
                            }
                        },
                    },
                },
            };

            return (
                <>
                    <r-grid columns="6">
                        <r-cell span="6">
                            <div>
                                <BigTextField
                                    label="Name"
                                    required
                                    className="control-map-name"
                                    placeholder="Please enter a name"
                                    value={formData.name}
                                    transparent
                                    onChange={(ev, value) => (formData.name = value)}
                                />
                            </div>
                        </r-cell>
                    </r-grid>
                    <r-grid class="row--l" columns="6">
                        <r-cell span="6">
                            <div>
                                <TextField
                                    label="Description"
                                    maxLength={400}
                                    value={formData.description}
                                    onChange={(ev, value) => (formData.description = value)}
                                    multiline
                                />
                            </div>
                        </r-cell>
                        <r-cell span="3">
                            <div>
                                <ComboBox
                                    required
                                    label="Category"
                                    allowFreeform={true}
                                    autoComplete="on"
                                    options={options.categories.map((o) => ({ key: o, text: o }))}
                                    onChange={(ev, item, index, value) => {
                                        formData.category = item ? item.key : value;
                                        if (!item && value && options.categories.indexOf(value) === -1) {
                                            options.categories.push(value);
                                        }
                                    }}
                                    selectedKey={formData.category}
                                    useComboBoxAsMenuWidth
                                />
                            </div>
                        </r-cell>
                    </r-grid>
                    <r-grid class="row--l" columns="6">
                        <r-cell span="6">
                            <ObservableSeparator>Map Axes</ObservableSeparator>
                            <div
                                ref={(r) => (this.chartContainer = r)}
                                className="row--s"
                                style={{ maxWidth: 600, margin: "auto" }}
                            >
                                <ContainerDimensions>
                                    {({ width }) => (
                                        <ReactRadarChart
                                            ref={(r) => (this.chart = r)}
                                            {...chartOptions}
                                            {...{ width, height: width }}
                                        />
                                    )}
                                </ContainerDimensions>
                            </div>
                            <ControlMapAxisCommandBar
                                axes={formData.axes}
                                controls={controls}
                                onSearchChange={onSearchChange}
                                onAddAxis={onAddAxis}
                                onAddControlAxis={onAddControlAxis}
                            />
                            <ControlMapAxisGridView
                                axes={this._getVisibleMapAxes()}
                                onAxisClick={(axis) => onAxisClick(axis)}
                                onAxisRemoveClick={(axis) => onAxisRemoved(axis)}
                            />
                        </r-cell>
                    </r-grid>
                </>
            );
        }

        updateMapImage = () => {
            const { onImageChanged } = this.props;

            if (this.chartContainer) {
                const svg = this.chartContainer.getElementsByTagName("svg")[0];
                return svgAsPngUri(svg, {}).then(onImageChanged);
            }

            return Promise.resolve(null);
        };

        _getVisibleMapAxes = () => {
            const { formData, formOptions } = this.props;

            if (!formOptions.keywords) {
                return formData.axes;
            }

            const keywords = formOptions.keywords.toLowerCase();

            return formData.axes.filter(
                (a) =>
                    a.name.toLowerCase().indexOf(keywords) !== -1 ||
                    !!a.parts.find((p) => p.name.toLowerCase().indexOf(keywords) !== -1)
            );
        };
    }
);
