import React, { Component } from 'react';
import { observer } from 'mobx-react';

export const TriageWizard = observer(
    class TriageWizard extends Component {

        render () {
            const { className, selectedIndex, children } = this.props;

            return (
                <div className={className}>
                    { React.Children.toArray(children)[selectedIndex] }
                </div>
            );
        }
    }
);