import React from 'react';
import { 
    Image as FabricImage,
    ImageFit as FabricImageFit,
} from 'office-ui-fabric-react/lib/Image';

const Image = (props) => {

    return (
        <FabricImage 
            {...props}
        />
    );
}

const ImageFit = FabricImageFit

export { Image, ImageFit }