import { observable, flow, computed, action } from "mobx";

import { PropertyService } from "../../../api/properties";
import { PropertyStore } from "./PropertyStore";

export class PropertyEditStore {
    public propertyService: PropertyService;
    public parentStore: PropertyStore;

    @observable public visible: boolean = false;
    @observable public formData: any;
    @observable public originalFormData: any;
    @observable public formOptions: any;
    @observable public saving: boolean = false;

    constructor(parentStore: PropertyStore) {
        this.parentStore = parentStore;
        this.propertyService = parentStore.propertyService;
    }

    @computed
    public get isValid() {
        if (!this.formData) {
            return false;
        }

        return this.formData.id && this.formData.name && this.formData.target && this.formData.order >= 0;
    }

    @computed
    public get isDirty() {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
    }

    @action
    public resetFormData() {
        this.formData = JSON.parse(JSON.stringify(this.originalFormData));
    }

    public show = flow(function* (options) {
        const property = Object.assign(
            {
                type: "String",
                order: 100,
                required: false,
                readOnly: false,
                multiValue: false,
                hidden: false,
                default: true,
                searchable: true,
                active: true,
            },
            options.property
        );

        this.visible = true;
        this.formOptions = {};
        this.formData = JSON.parse(JSON.stringify(property));
        this.originalFormData = JSON.parse(JSON.stringify(property));

        const result = yield this.propertyService.searchPropertySections({
            pageSize: 250,
            startIndex: 0,
            query: { target: [property.target] },
        });
        this.formOptions = {
            sections: result.items,
        };
    });

    @action
    public hide(options) {
        this.visible = false;
        this.formData = null;
        this.formOptions = null;
        this.originalFormData = null;
    }

    public updateProperty = flow(function* () {
        this.saving = true;
        this.error = null;

        try {
            if (this.isValid) {
                const result = yield this.propertyService.updateProperty(this.formData);
                this.parentStore.rootStore.layoutStore.displayToastNotification(`Property ${result.name} updated successfully`);
                return result;
            }
        } catch (e) {
            console.error(e);
            this.error = e;
        } finally {
            this.saving = false;
        }
    });
}
