import React, { Component, Fragment } from "react";
import { observer } from "mobx-react";

import { AppNavigation } from "./AppNavigation";
import { AppHeader } from "./AppHeader";
import { css } from "@uifabric/utilities/lib/css";
import { cx, css as emoCss } from "emotion";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const toastClassName = cx(
    "Toastify",
    emoCss`
    .Toastify__progress-bar--default {
        background: var(--nav-color-accent);
    }
    .Toastify__toast {
        border-radius: 5px;
    }
    .Toastify__toast--dark {
        color: var(--black) !important;
        background: var(--border-color) !important;
    }
    .Toastify__toast--default {
        color: #ffffff !important;
        background: var(--blue) !important;
    }
    .Toastify__toast--info {
        color: #ffffff !important;
        background: var(--indigo) !important;
    }
    .Toastify__toast--success {
        color: #ffffff !important;
        background: var(--primary-selection-color) !important;
    }
    .Toastify__toast--warning {
        color: #ffffff !important;
        background: var(--orange) !important;
    }
    .Toastify__toast--error {
        color: #ffffff !important;
        background: var(--pink) !important;
    }

    .Toastify__close-button {
        display: none;
    }
`
);

export const AppLayout = observer(
    class AppLayout extends Component {
        componentDidMount() {
            // TODO: remove product load from here and only load when needed!
            this.props.productStore.loadProducts();
            this.props.principalContext.loadCurrentPrincipal();
        }

        render() {
            const { pageContext, layoutStore, principalContext, authProvider, configProvider, routing } = this.props;
            const current = pageContext.current;

            return (
                <Fragment>
                    <ToastContainer className={toastClassName} />
                    <div className={css("scroll--handler", layoutStore.freezeScroll && "freeze--scroll")}>
                        {!layoutStore.hideNavigation && (
                            <AppNavigation menu={layoutStore.menu} routing={routing} user={principalContext.current} />
                        )}
                        <AppHeader
                            page={current}
                            routing={routing}
                            user={principalContext.current}
                            authProvider={authProvider}
                            configProvider={configProvider}
                            layoutStore={layoutStore}
                        />
                    </div>
                    <div
                        className={css(
                            "app__container",
                            current.className,
                            current.hasPageNav && "has--page-nav",
                            //!current.showPageNav && "hide--page-nav",
                            current.hasSidebar && "app__container--has--sidebar",
                            !current.showSidebar && "hide--sidebar",
                            current.error && "has--error"
                        )}
                    >
                        {/* <button onClick={() => layoutStore.displayToastNotification(
                            <>
                                Your request has been received by the InfoSec team.
                                <br /> We'll be in touch shortly.
                            </>)}>Notify!</button> */}
                        {this.props.children}
                    </div>
                </Fragment>
            );
        }
    }
);
