import { observable, action, computed, flow } from "mobx";

import { RiskService } from "../../../api/risks";
import { RiskStore } from "./RiskStore";

import { isNotNullOrEmpty } from "../../editors/components/RichTextField";

export class RiskCommentStore {
    public riskService: RiskService;
    public parentStore: RiskStore;

    @observable public riskId: string;
    @observable public comments: any[] = [];
    @observable public loading: boolean;
    @observable public comment: any;
    @observable public saving: boolean;
    @observable public error: any;

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
    }

    @computed
    public get risk() {
        return this.parentStore.selectionStore.risk;
    }

    @computed
    public get lifecycle() {
        return this.parentStore.selectionStore.lifecycle;
    }

    @action
    public reset(loading: boolean = false) {
        if (this.riskId !== this.risk.id) {
            this.comments = [];
            this.comment = null;
            this.loading = loading;
        }
    }

    public loadComments = flow(function* () {
        if (this.riskId !== this.risk.id) {
            this.loading = true;
            this.comments = [];
            this.comment = null;
            this.riskId = this.risk.id;
        }

        try {
            this.comments = yield this.riskService.getRiskComments(this.riskId);
        } catch (e) {
            console.error(e);
            this.error = e;
        } finally {
            this.loading = false;
        }
    });

    @action
    public onCommentChanged(comment) {
        this.comment = comment;
    }

    @computed
    public get canAddComment() {
        return this.parentStore.permissionStore.canAddComment;
    }

    @computed
    public get canSave() {
        return (
            this.comment &&
            this.comment.length &&
            isNotNullOrEmpty(this.comment) &&
            !this.loading &&
            !this.saving &&
            this.parentStore.permissionStore.canAddComment
        );
    }

    public onSave = flow(function* () {
        if (!this.canSave) {
            return;
        }

        this.saving = true;
        try {
            const comment = yield this.riskService.createRiskComment({
                riskId: this.risk.id,
                internalOnly: false,
                uniqueId: null,
                content: this.comment,
            });

            this.comments = [comment, ...this.comments];
            this.comment = null;
            this.parentStore.rootStore.layoutStore.displayToastNotification(`Comment added, stakeholders have been notified.`);
        } catch (e) {
            console.error(e);
            this.error = e;
        } finally {
            this.saving = false;
        }
    });
}
