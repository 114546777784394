import React from "react";
import { observer } from "mobx-react";
import { Route, Switch } from "react-router";
import { useStores } from "@hooks/useStores";

import { PivotItem } from "office-ui-fabric-react/lib/Pivot";

import { PivotRouting } from "@modules/base/components/PivotRouting";

import { WorkItemSummaryTab } from "./WorkItemSummaryTab";
import { WorkItemDocumentsTab } from "./WorkItemDocumentsTab";
import { WorkItemLinksTab } from "./WorkItemLinksTab";

export const WorkItemTabList = observer(({ selectedTab, workItem, onUpload }) => {
    const { routing } = useStores();

    const defaultTab = "summary";
    const knownTabs = [defaultTab, "documents", "work-items"];

    const urlPrefix = routing.location.pathname.indexOf("/teams/demand/") === 0 ? "/teams/demand" : "/demand";

    return (
        <>
            <PivotRouting
                data-test="workItemTabs"
                aria-label="Demand properties, related documents and work"
                selectedKey={knownTabs.indexOf(selectedTab) === -1 ? defaultTab : selectedTab}
                onLinkClick={(item, routing) => {
                    if (item.props.itemKey === defaultTab) {
                        routing.push(`${urlPrefix}/browse/${workItem.id}`);
                    } else {
                        routing.push(`${urlPrefix}/browse/${workItem.id}/${item.props.itemKey}`);
                    }
                }}
            >
                <PivotItem itemKey={defaultTab} headerText="Summary" itemIcon="Info" />
                <PivotItem itemKey="documents" headerText="Related Documents" itemIcon="DocLibrary" />
                <PivotItem itemKey="work-items" headerText="Related Work" itemIcon="ExternalTFVC" />
            </PivotRouting>

            <Switch>
                <Route
                    exact
                    path={`${urlPrefix}/browse/:id/documents`}
                    component={() => <WorkItemDocumentsTab workItem={workItem} onUpload={onUpload} />}
                />
                <Route
                    exact
                    path={`${urlPrefix}/browse/:id/work-items`}
                    component={() => <WorkItemLinksTab workItem={workItem} />}
                />
                <Route path={`${urlPrefix}/browse/:id`} component={() => <WorkItemSummaryTab workItem={workItem} />} />
            </Switch>
        </>
    );
});
