import { observable, flow, computed, action } from "mobx";

import { PageService } from '../../../api/pages';
import { PageStore } from "./PageStore";

export class PageHierarchyStore {

    public parentStore: PageStore;
    public pageService: PageService;

    @observable public loading: boolean = false;
    @observable public query: any;
    @observable public hierarchy: any;
    @observable public keywords: string;
    @observable public error: any;

    constructor(parentStore: PageStore) {
        this.parentStore = parentStore;
        this.pageService = parentStore.pageService;
    }

    @computed
    public get sitemap() {
        if(!this.hierarchy) {
            return [];
        }

        return this.getNodes(this.hierarchy.nodes, 1);
    }

    @action
    public setKeywords(keywords) {
        this.keywords = keywords;
    }

    public loadHierarchy = flow(function*(query: any) {
        this.loading = true;
        this.error = null;

        if(this.query && query.libraryId && (this.query.libraryId !== query.libraryId || this.query.pageId !== query.pageId 
            || this.query.maxLevel !== query.maxLevel || this.query.isPublished !== query.isPublished)) {            
            this.hierarchy = null;
            console.log(query);
        }

        try {
            if(query.libraryId) {
                this.query = query;
                this.hierarchy = yield this.pageService.getPageHierarchy(query);
            }
        } catch (e) {
            this.error = e;
            console.error(e)
        } finally {
            this.loading = false;
        }
    });

    public getNodes(nodes, level) {
        return nodes.map(node => (
            {
                name: node.title,
                key: node.id,
                url: node.path,
                links: this.getNodes(node.nodes, level + 1)
            }
        ));
    }
}
