import React, { useState, useRef } from "react";
import { cx, css } from "emotion";

import { ActionButton } from "office-ui-fabric-react/lib/Button";
import { Callout } from "office-ui-fabric-react/lib/Callout";

const triggerClassName = cx(
    "cygraph-MoreInfoCallout-trigger",
    css`
        display: inline-block;
    `
);

const calloutClassName = cx(
    "cygraph-MoreInfoCallout-callout",
    css`
        .content {
            padding: var(--spacing-xxs);
        }
    `
);

export function MoreInfoCallout({ label, disabled, iconProps, children, onCancel }) {
    const [visible, setVisible] = useState(false);
    const triggerElement = useRef(null);

    const toggleVisibility = () => {
        setVisible(!visible);
    };

    const onCalloutDismiss = () => {
        toggleVisibility();
        if (onCancel) {
            onCancel();
        }
    };

    return (
        <div>
            <div className={triggerClassName} ref={triggerElement}>
                <ActionButton iconProps={iconProps} disabled={disabled} onClick={toggleVisibility}>
                    {label}
                </ActionButton>
            </div>
            {visible && !disabled && (
                <Callout
                    className={calloutClassName}
                    role="alertdialog"
                    gapSpace={0}
                    hidden={disabled}
                    doNotLayer={false}
                    target={triggerElement}
                    onDismiss={onCalloutDismiss}
                >
                    <div className="content">{children}</div>
                </Callout>
            )}
        </div>
    );
}
