import React from "react";
import { observer } from "mobx-react";
import { Route, Switch } from "react-router";

import { ControlsGeneralTab } from "./ControlsGeneralTab";
import { ControlsFrameworksTab } from "./ControlsFrameworksTab";
import { ControlsMapsTab } from "./ControlsMapsTab";
import { ControlsRecycleBinTab } from "./ControlsRecycleBinTab";
import { ModuleAutomationsTab } from "./ModuleAutomationsTab";

export const ControlsSettingsTabList = observer(({}) => {
    return (
        <Switch>
            <Route exact path="/settings/controls/frameworks" component={() => <ControlsFrameworksTab />} />
            <Route exact path="/settings/controls/maps" component={() => <ControlsMapsTab />} />
            <Route
                exact
                path="/settings/controls/automations"
                component={() => (
                    <ModuleAutomationsTab
                        context={{ type: "module", id: "1414dc76-5960-4e38-8ba7-89a5df99ebcd", label: "Controls" }}
                    />
                )}
            />{" "}
            <Route exact path="/settings/controls/recycle-bin" component={() => <ControlsRecycleBinTab />} />
            <Route path="/settings/controls" component={() => <ControlsGeneralTab />} />
        </Switch>
    );
});
