import React, { Component } from 'react';
import { cx, css } from 'emotion';

import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { RichTextField } from '../../../editors/components/RichTextField';

export class CancelItemDialog extends Component {
    state = {
        message: ''
    }

    onCommentChange = message => {
        this.setState({
            message: message
        })
    }
    render () {
        const { onCancel, onConfirm } = this.props;
        return (
            <Dialog
                onDismiss={onCancel}
                hidden={false}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Confirm cancel the work item',
                    closeButtonAriaLabel: 'Cancel',
                    subText: 'Are you sure that you want to cancel this work item?',
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}
                >
                    <RichTextField 
                        label="Please add a comment" 
                        placeholder="Please enter an optional comment"
                        value={this.state.message}
                        onChange={(val) => {
                            this.onCommentChange(val);                                        
                        }} />
                    <DialogFooter>
                        <PrimaryButton onClick={onConfirm} onClick={() => {onConfirm(this.state.message)}} text="Cancel the work item" />
                        <DefaultButton onClick={onCancel} text="Dismiss the dialog" />
                    </DialogFooter>
            </Dialog>
        );
    }
};