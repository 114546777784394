import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';
import { cx } from 'emotion';

import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { IconButton } from 'office-ui-fabric-react/lib/Button';

export const PageSectionMenu = observer(
  class PageSectionMenu extends PureComponent {
    render () {
        const { className, instance, section, index, provided } = this.props;

        return (
          <div className={cx(className, 'animated fadeIn fast')}>
            <div className="wrapper">
              <IconButton 
                iconProps={{iconName: 'Add'}} 
                title="Add section after" ariaLabel="Add section after" 
                onClick={() => instance.lifecycleStore.onAddSection(index + 1, section)} />
              <IconButton 
                iconProps={{iconName: 'DoubleColumnEdit'}} 
                title="Edit section" ariaLabel="Edit section" 
                onClick={() => instance.lifecycleStore.onEditSection(section)} />
              <div type="button" title="Move section" className="move ms-Button ms-Button--icon" 
                aria-label="Move section" data-is-focusable="true"
                {...provided.dragHandleProps}>
                <span className="ms-Button-flexContainer" data-automationid="splitbuttonprimary">
                  <Icon 
                    iconName={'Move'} 
                    title="Move section" ariaLabel="Move section"
                    />
                </span>
              </div>
              <IconButton 
                iconProps={{iconName: 'Copy'}} 
                title="Duplicate section" ariaLabel="Duplicate section"
                onClick={() => instance.lifecycleStore.onDuplicateSection(section)} />
              <IconButton 
                iconProps={{iconName: 'Delete'}} 
                title="Delete section" ariaLabel="Delete section"
                onClick={() => instance.lifecycleStore.onDeleteSection(section)} />
            </div>
          </div>
        );
    }
  }
);