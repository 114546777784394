import React from 'react';
import { 
    ShimmeredDetailsList as FabricShimmeredDetailsList,
} from 'office-ui-fabric-react/lib/ShimmeredDetailsList';

const ShimmeredDetailsList = (props) => {
    return (
        <FabricShimmeredDetailsList 
            {...props}
        />
    );
}

export { ShimmeredDetailsList }