import { RootStore } from '../../base/RootStore';
import { PropertyService } from '../../../api/properties';

import { PropertyBrowseStore } from './PropertyBrowseStore';
import { PropertyNewStore } from './PropertyNewStore';
import { PropertyEditStore } from './PropertyEditStore';

import { PropertySectionBrowseStore } from './PropertySectionBrowseStore';
import { PropertySectionNewStore } from './PropertySectionNewStore';
import { PropertySectionEditStore } from './PropertySectionEditStore';

export class PropertyStore {

    public rootStore: RootStore;
    public propertyService: PropertyService;

    public browseStore: PropertyBrowseStore;
    public newStore: PropertyNewStore;
    public editStore: PropertyEditStore;
    
    public sectionBrowseStore: PropertySectionBrowseStore;
    public sectionNewStore: PropertySectionNewStore;
    public sectionEditStore: PropertySectionEditStore;  
    
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.propertyService = new PropertyService(rootStore.authProvider);

        this.browseStore = new PropertyBrowseStore(this);
        this.newStore = new PropertyNewStore(this);
        this.editStore = new PropertyEditStore(this);

        this.sectionBrowseStore = new PropertySectionBrowseStore(this);
        this.sectionNewStore = new PropertySectionNewStore(this);
        this.sectionEditStore = new PropertySectionEditStore(this);
    }
}

