import React, { Component, Fragment } from 'react';
import { cx, css } from 'emotion'

import { MessageBar } from 'office-ui-fabric-react/lib/MessageBar';
import { DetailsListLayoutMode, SelectionMode, ConstrainMode } from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';

import { ObservablePagination } from '@baseComponents/ObservablePagination';
import { BoxPanel } from '@baseComponents/BoxPanel';

export const DataSourceGridView = (
    class DataSourceGridView extends Component {
        constructor(props) {
            super(props);
            this.state = {
              showContextualMenu: null
            };
        }

        get _columns() {
          const { groups, onEditUserClick } = this.props;
          return [
          {
            key: 'name',
            name: 'Name',
            fieldName: 'name',
            minWidth: 210,
            maxWidth: 250,
            data: 'string',
            onRender: (item) => {
              return (
                <div className={cx(
                  'workitem-summary',
                  'grid-view-hover-button',
                  css`
                    display: flex;
                    height: 30px;
                  `
                )}>
                  <a role="button" onClick={() => this.props.onEditUserClick(item.id)}>{item.name}</a>
                    {/* {this.state.showContextualMenu === item.id ? ( <UsersContextualMenu
                          userId={item.id}
                          assignedGroups={item.assignedGroups}
                          groups={groups}
                          onEditUserClick={onEditUserClick}
                      />)
                    : null} */}
                </div>
              );
            },
            isPadded: true
          },
          {
            key: 'description',
            name: 'Description',
            fieldName: 'Description',
            minWidth: 210,
            maxWidth: 250,
            data: 'string',
            onRender: (item) => {
              return (
                <div>
                  {item.description}
                </div>
              );
            },
            isPadded: true
          },
        ];
        }

        render () {
          const { onPageChange, keywords, dataSources, loading, pagination } = this.props;
          return (
            <>
              <BoxPanel shadow >
                { dataSources && <ShimmeredDetailsList
                  items={dataSources}
                  compact={false}
                  columns={this._columns}
                  selectionMode={SelectionMode.none}
                  getKey={(item, index) => item ? item.id : index}
                  setKey="single"
                  layoutMode={DetailsListLayoutMode.justified}
                  constrainMode={ConstrainMode.horizontalConstrained}
                  isHeaderVisible={true}
                  onRenderRow={(props, defaultRender) => <div 
                    onMouseEnter={() => {this.setState({showContextualMenu:props.item.id})}}
                    onMouseLeave={() => {this.setState({showContextualMenu:null})}}
                    >{defaultRender(props)}</div> }
                  enterModalSelectionOnTouch={true}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  checkButtonAriaLabel="Row checkbox"
                  enableShimmer={loading}
                  ariaLabelForShimmer="Users being fetched"
                  ariaLabelForGrid="User list"
                />
                }
                { !dataSources && !loading && (
                  <MessageBar>
                    There are no matching data source.
                  </MessageBar>
                )}
              </BoxPanel>
              <ObservablePagination
                {...pagination}
                onPageChange={(index) => onPageChange(index, keywords)}
              />
            </>
          );
        }
    }
);