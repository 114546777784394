import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Moment from 'react-moment';

import { Text } from 'office-ui-fabric-react/lib/Text';
import { MessageBar } from 'office-ui-fabric-react/lib/MessageBar';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { DetailsListLayoutMode, Selection, SelectionMode, ConstrainMode } from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { ContextualMenu } from 'office-ui-fabric-react/lib/ContextualMenu';

import { BoxPanel } from '../../../base/components/BoxPanel';
import { RoutingLink } from '../../../base/components/RoutingLink';
import { WrappedContent } from '../../../base/components/WrappedContent';

export const PropertySectionGridView = observer(
    class PropertySectionGridView extends Component {

        constructor(props) {
            super(props);

            this.state = {};

            this._selection = new Selection({
                onSelectionChanged: () => {
                  this.setState({
                    selectionDetails: this._getSelectionDetails()
                  });
                }
              });

            this._columns = [
                {
                  key: 'name',
                  name: 'Name',
                  fieldName: 'name',
                  minWidth: 310,
                  isRowHeader: true,
                  isResizable: true,
                  onColumnClick: this._onColumnClick,
                  data: 'string',
                  onRender: (item) => {
                    return (
                      <WrappedContent>
                        <RoutingLink url={`/sections/browse/${item.id}`} onClick={() => this.props.onPropertyClick && this.props.onPropertyClick(item)} onClickEnabled={this.props.onPropertyClick}>{item.name}</RoutingLink><br/>
                      </WrappedContent>
                      );
                  },
                  isPadded: true
                },
                {
                  key: 'order',
                  name: 'Order',
                  fieldName: 'order',
                  minWidth: 80,
                  maxWidth: 110,
                  isResizable: false,
                  onColumnClick: this._onColumnClick,
                  data: 'string',
                  onRender: (item) => {
                    return (<span>{item.order}</span>);
                  },
                  isPadded: false
                },
                {
                  key: 'modified',
                  name: 'Date Modified',
                  fieldName: 'modified',
                  minWidth: 100,
                  maxWidth: 130,
                  isResizable: true,
                  isSorted: true,
                  isSortedDescending: true,
                  sortAscendingAriaLabel: 'Sorted A to Z',
                  sortDescendingAriaLabel: 'Sorted Z to A',
                  onColumnClick: this._onColumnClick,
                  data: 'date',
                  onRender: (item) => {
                    return <Moment date={item.modified} fromNow />;
                  },
                  isPadded: true
                }
            ];
        }

        render () {
            const { className, sections, loading, onPropertyClick } = this.props;
            const { contextualMenuProps } = this.state;
            
            return (
              <BoxPanel shadow>
                <ShimmeredDetailsList
                  items={sections}
                  compact={false}
                  columns={this._columns}
                  selectionMode={SelectionMode.none}
                  getKey={this._getKey}
                  setKey="single"
                  layoutMode={DetailsListLayoutMode.justified}
                  constrainMode={ConstrainMode.horizontalConstrained}
                  isHeaderVisible={true}
                  selection={this._selection}
                  selectionPreservedOnEmptyClick={true}
                  onItemInvoked={onPropertyClick}
                  //onItemContextMenu={(item, index, ev) => this._onItemContextMenu(item, index, ev) }
                  enterModalSelectionOnTouch={true}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  checkButtonAriaLabel="Row checkbox"
                  enableShimmer={loading}
                  removeFadingOverlay={true}
                  shimmerLines={25}
                  ariaLabelForShimmer="Property sections being fetched"
                  ariaLabelForGrid="Property section list"
                />
                
                { ! loading && !sections.length && (
                  <MessageBar>
                    There are no matching sections.
                  </MessageBar>
                )}

                {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}
              </BoxPanel> 
            );
        }

        _getKey(item, index) {
            return item ? item.id : index;
        }

        _getSelectionDetails() {
            const selectionCount = this._selection.getSelectedCount();
        
            switch (selectionCount) {
              case 0:
                return 'No items selected';
              case 1:
                return '1 item selected: ' + (this._selection.getSelection()[0]).name;
              default:
                return `${selectionCount} items selected`;
            }
        }

        _onColumnClick(ev, column) {
            console.log(column);
        }

        _onItemContextMenu(item, index, ev) {
            const contextualMenuProps = {
              target: ev.target,
              items: [
                {
                  key: 'text',
                  name: `${this._selection.getSelectedCount()} selected`
                }
              ],
              onDismiss: () => {
                this.setState({
                  contextualMenuProps: undefined
                });
              }
            };
        
            if (index > -1) {
              this.setState({
                contextualMenuProps: contextualMenuProps
              });
            }
        
            return false;
          };
    }
);