import React, { Component } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

const rootClassName = cx(
    "cygraph-WrappedContent-root",
    css`
        white-space: initial;

        &.right-align {
            text-align: right;
        }
    `
);

export const WrappedContent = observer(
    class WrappedContent extends Component {
        render() {
            const { className, children, rightAlign } = this.props;

            return <div className={cx(rootClassName, className, rightAlign && "right-align")}>{children}</div>;
        }
    }
);
