import { observable, action, computed, flow } from "mobx";
import download from "downloadjs";
import { utc } from "moment";

import { AssessmentService } from "../../../api/assessments";
import { AssessmentStore } from "./AssessmentStore";

export class AssessmentModuleStore {
    public parentStore: AssessmentStore;
    private assessmentService: AssessmentService;

    @observable public moduleId: string;
    @observable public manifest: any;
    @observable public module: any;
    @observable public measure: any;
    @observable public values: any;
    @observable public latestUpdate: Date;
    @observable public loading: boolean = false;
    @observable public saving: boolean = false;
    @observable public error: any;
    @observable public disabled: any = {};

    constructor(parentStore: AssessmentStore) {
        this.parentStore = parentStore;
        this.assessmentService = parentStore.assessmentService;
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [
            { text: "Assurance Management", key: "assurance", onClick: () => this._goToUrl(`/assurance/dashboard`) },
            { text: "Browse Assessments", key: "browse", onClick: () => this._goToUrl(`/assurance/browse`) },
        ];

        if (!this.assessment || !this.manifest || !this.module) {
            return builder;
        }

        let moduleName = `${this.module.title} v${this.manifest.version}`;
        if (this.parentStore.lifecycleStore.isReadOnly) {
            moduleName += " (read-only)";
        }

        builder.push({ text: this.assessment.code, key: this.assessment.code, onClick: () => this._goToUrl(`/assurance/browse/${this.assessment.id}`) });
        builder.push({ text: moduleName, key: moduleName, isCurrentItem: true });

        return builder;
    }

    @computed
    public get assessment() {
        return this.parentStore.selectionStore.assessment;
    }

    @computed
    public get workItem() {
        return this.parentStore.selectionStore.workItem;
    }

    @computed
    public get lastSaved() {
        if (!this.values) {
            return null;
        }

        let last = null;
        Object.values(this.values).forEach((value: any) => {
            if (value.modified) {
                if (!last || utc(last).isBefore(utc(value.modified))) {
                    last = value.modified;
                }
            }
        });

        return last;
    }

    public loadAssessmentModule = flow(function* (id: string, moduleId: string) {
        this.loading = true;
        this.menifest = null;
        this.measure = null;
        this.module = null;
        this.values = null;
        this.latestUpdate = null;
        this.disabled = {};
        this.error = null;

        try {
            this.moduleId = moduleId;
            yield this.parentStore.selectionStore.loadAssessment(id);
            var assessment = this.parentStore.selectionStore.assessment;
            const patternVersion = assessment.patternVersion;
            const modellingStore = this.parentStore.rootStore.modellingStore;
            this.manifest = yield modellingStore.loadPatternManifest(patternVersion.patternId, patternVersion.version);
            this.setSelectedModuleById(moduleId);
            this.values = yield this.assessmentService.getAssessmentValues(id);
            //this.latestUpdate = this.lastSaved;
        } catch (error) {
            this.error = error;
        } finally {
            this.loading = false;
        }
    });

    public updateAssessmentValue = flow(function* (measure: any, value: any) {
        this.saving = true;

        try {
            if (this.values) {
                this.disabled[measure.name] = true;
                this.values[measure.name].value = value.value;
            }
            const result = yield this.assessmentService.updateAssessmentValue({
                assessmentId: this.assessment.id,
                measureName: measure.name,
                measureId: measure.id,
                measureType: measure.type,
                value: value.value,
                completed: value.completed,
                config: value.config,
            });
            this.latestUpdate = result.modified;
            if (this.values) {
                this.disabled[result.measureName] = false;
                this.values[result.measureName] = result;
            }
        } catch (error) {
            this.error = error;
        } finally {
            this.saving = false;
        }
    });

    @action
    public setSelectedModuleById(moduleId: string) {
        const module = this.manifest.modules.find((m) => m.id == moduleId);
        if (module) {
            this.module = module;
            for (let i = 0; i < module.groups.length; i++) {
                let group = module.groups[i];
                for (let j = 0; j < group.measures.length; j++) {
                    this.measure = group.measures[j];
                    return;
                }
            }
        }
    }

    @action
    public setSelectedMeasure(measure: any) {
        if (!this.measure || this.measure.id != measure.id) {
            this.measure = measure;
        }
    }

    @action
    public onModule(module) {
        this._goToUrl(`/assurance/browse/${this.assessment.id}/-/${module.id}`);
    }

    @action
    public onAssessment() {
        this._goToUrl(`/assurance/browse/${this.assessment.id}`);
    }

    @action
    public onDownload(module) {
        return this.assessmentService.downloadAssessmentValues(this.assessment.id, module ? module.id : null).then((blob) => {
            download(blob, `${this.assessment.code}.csv`, "text/csv");
        });
    }

    _goToUrl = (url) => {
        if (url) {
            this.parentStore.rootStore.routing.push(url);
        }
    };
}
