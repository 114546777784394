import moment from 'moment';
import { flow, observable } from 'mobx';
import { RootStore } from '../../base/RootStore';
import { DataSourceLifecycleStore } from './DataSourceLifecycleStore';
import { DataSourcePermissionStore } from './DataSourcePermissionStore';
import { DataSourceSelectionStore } from './DataSourceSelectionStore';
import { DataSourceBrowseStore } from './DataSourceBrowseStore';
import { AssessmentService } from '../../../api/assessments';
import { PortfolioService, ProductService } from '../../../api/graph';
import { IdentityService } from '../../../api/identity';
import { WorkItemService } from '../../../api/workitems';
import { RiskService } from '../../../api/risks';
import { ControlService } from '../../../api/modelling';
import { WorkflowService } from '../../../api/workflows';
import { JsonQueryString } from "@modules/utils/JsonQueryString";

import { 
    demoDataSource,
    getAssessmentsOpenedClosed,
    getWorkItemOpenedClosed,
    getWorkItemGroupByCount,
    getWorkItems,
    getRisks,
    getAssessments,
    getPortfoliosContact,
    getAssessmentGroupByCount,
    getAdvisoriesGroupByCount,
    getAdvisoriesFlatByControlFinalCompliance,
    getAdvisoriesFlatByPortfolio,
    getControlMap,
    getTasks,
    getExceptions,
    getExceptionsGroupByCount,
    getRisksGroupByCount,
 } from './data';

import { 
    generateWorkItemQuery,
    generateRiskQuery,
    generateAssessmentQuery,
} from './queries';

import {
    tasksFilters,
    workItemFilters,
    riskFilters,
    assessmentFilters,
    advisoriesFilters,
    controlMapsFilters,
    exceptionFilters,
    heatmapFilters,
} from './filters'

import { DataSourceStore } from "./DataSourceStore";

const md5 = require('md5');

export class DataSourceDataStore {

    public rootStore: RootStore;
    public parentStore: DataSourceStore;
    private assessmentService: AssessmentService;
    private workItemService: WorkItemService;
    private riskService: RiskService;
    private portfolioService: PortfolioService;
    private productService: ProductService;
    private controlService: ControlService;
    private identityService: IdentityService;
    private workflowService: WorkflowService;

    public selectionStore: DataSourceSelectionStore;
    public permissionStore: DataSourcePermissionStore;
    public lifecycleStore: DataSourceLifecycleStore;
    public browseStore: DataSourceBrowseStore;

    private loadedData: any = {};
    private portfolios: any[] = [];
    private products: any[] = [];
    private groups: any[] = [];
    private controlMaps: any[] = [];
    private controlSets: any[] = [];
    private controls: any[] = [];
    private subStatues: any[] = [];
    private workItemsSubStatues: any[] = []
    private riskTypes: any[] = [];

    constructor(parentStore: DataSourceStore) {
        this.parentStore = parentStore;

        //apis
        this.assessmentService = new AssessmentService(this.parentStore.rootStore.authProvider);
        this.workItemService = new WorkItemService(this.parentStore.rootStore.authProvider);
        this.riskService = new RiskService(this.parentStore.rootStore.authProvider);
        this.portfolioService = new PortfolioService(this.parentStore.rootStore.authProvider);
        this.productService = new ProductService(this.parentStore.rootStore.authProvider);
        this.identityService = new IdentityService(this.parentStore.rootStore.authProvider);
        this.controlService = new ControlService(this.parentStore.rootStore.authProvider);
        this.workflowService = new WorkflowService(this.parentStore.rootStore.authProvider);
        
    }

    getWorkItemGroupByCount = flow(function*(query, groupBy) {
        const queryHash = md5(`getWorkItemGroupByCount${JSON.stringify(query)}${groupBy}`);
        if (!this.loadedData[queryHash]) {
            this.loadedData[queryHash] = yield getWorkItemGroupByCount(query, groupBy, this.workItemService.getWorkItemGroupByCount.bind(this.workItemService))
        }
        return this.loadedData[queryHash]
    });

    getHeatmapData = flow(function*(query, groupBy, heatmap_type, risk_type) {

        const queryHash = md5(`getHeatmapData${JSON.stringify(query)}${groupBy}${heatmap_type}${risk_type}`);
        // console.log(`queryHash`, queryHash, JSON.stringify(query));
        if (!this.loadedData[queryHash]) {
            const qTemplate = (query.risk_type === 'exception') ? "zerodai:risks:exception" : "zerodai:risks:generic";

            const grossResult = yield this.riskService.getRisksGroupByCount({
                groupBy: "grossScoreCalculated",
                query: {
                    ...query,
                    ...{ type: { template: [qTemplate] } },
                },
            });
            const grossRisks = grossResult.items.map((i) => { 
                const parts = i.id.split("-");
                return {
                    text: `${i.count > 99 ? "99+" : i.count}`,
                    score: {
                        impact: parseInt(parts[1]),
                        likelihood: parseInt(parts[2]),
                    },
                };
            });

            const netResult = yield this.riskService.getRisksGroupByCount({
                groupBy: "netScoreCalculated",
                query: {
                    ...query,
                    ...{ type: { template: [qTemplate] } },
                },
            });

            const netRisks = netResult.items.map((i) => {
                const parts = i.id.split("-");
                return {
                    text: `${i.count > 99 ? "99+" : i.count}`,
                    score: {
                        impact: parseInt(parts[1]),
                        likelihood: parseInt(parts[2]),
                    },
                };
            });
            this.loadedData[queryHash] = {
                items: query.heatmap_type === 'net' ? netRisks : grossRisks,
                totalItems: query.heatmap_type === 'net' ? netRisks.length : grossRisks.length,
            }
            // console.log(`new query`)
        }
        return this.loadedData[queryHash]
    });

    getWorkItemOpenedClosed = flow(function*(query, groupBy) {
        const queryHash = md5(`getWorkItemOpenedClosed${JSON.stringify(query)}${groupBy}`);
        if (!this.loadedData[queryHash]) {
            this.loadedData[queryHash] = yield getWorkItemOpenedClosed(query, groupBy, this.workItemService.getWorkItemOpenedClosed.bind(this.workItemService))
        }
        return this.loadedData[queryHash]
    });

    getAssessments = flow(function*(query) {
        const queryHash = md5(`getAssessments${JSON.stringify(query)}`);
        if (!this.loadedData[queryHash]) {
            this.loadedData[queryHash] = yield getAssessments(query, this.assessmentService.searchAssessments.bind(this.assessmentService))
        }
        return this.loadedData[queryHash]
    });

    getAssessmentsOpenedClosed = flow(function*(query, groupBy) {
        const queryHash = md5(`getAssessmentsOpenedClosed${JSON.stringify(query)}${groupBy}`);
        if (!this.loadedData[queryHash]) {
            this.loadedData[queryHash] = yield getAssessmentsOpenedClosed(query, groupBy, this.assessmentService.getAssessmentsOpenedClosed.bind(this.assessmentService))
        }
        return this.loadedData[queryHash]
    });

    getAssessmentGroupByCount = flow(function*(query, groupBy) {
        const queryHash = md5(`getAssessmentGroupByCount${JSON.stringify(query)}${groupBy}`);
        if (!this.loadedData[queryHash]) {
            this.loadedData[queryHash] = yield getAssessmentGroupByCount(query, groupBy, this.assessmentService.getAssessmentsGroupByCount.bind(this.assessmentService))
        }
        return this.loadedData[queryHash]
    });

    getAdvisoriesGroupByCount = flow(function*(query, groupBy) {
        const queryHash = md5(`getAdvisoriesGroupByCount${JSON.stringify(query)}${groupBy}`);
        if (!this.loadedData[queryHash]) {
            this.loadedData[queryHash] = yield getAdvisoriesGroupByCount(query, groupBy, this.assessmentService.getAdvisoriesGroupByCount.bind(this.assessmentService))
        }
        return this.loadedData[queryHash]
    });

    getAdvisoriesFlatByControlFinalCompliance = flow(function*(query, groupBy) {
        const queryHash = md5(`getAdvisoriesFlatByControlFinalCompliance${JSON.stringify(query)}${groupBy}`);
        if (!this.loadedData[queryHash]) {
            this.loadedData[queryHash] = yield getAdvisoriesFlatByControlFinalCompliance(query, groupBy, this.assessmentService.getAdvisoriesFlatByControlFinalCompliance.bind(this.assessmentService))
        }
        return this.loadedData[queryHash]
    });

    getAdvisoriesFlatByPortfolio = flow(function*(query, groupBy) {
        const queryHash = md5(`getAdvisoriesFlatByPortfolio${JSON.stringify(query)}${groupBy}`);
        if (!this.loadedData[queryHash]) {
            this.loadedData[queryHash] = yield getAdvisoriesFlatByPortfolio(query, this.assessmentService.getAdvisoriesFlatByPortfolio.bind(this.assessmentService))
        }
        return this.loadedData[queryHash]
    });

    getPortfoliosContacts = flow(function*(query, groupBy) {
        const queryHash = md5(`getContacts${JSON.stringify(query)}${groupBy}`);
        if (!this.loadedData[queryHash]) {
            this.loadedData[queryHash] = yield getPortfoliosContact(query, groupBy, this.portfolioService.getPortfolios.bind(this.portfolioService));
        }
        return this.loadedData[queryHash];
    });

    getRisks = flow(function*(query) {
        const queryHash = md5(`getRisks${JSON.stringify(query)}`);
        if (!this.loadedData[queryHash]) {
            const result = yield getRisks(query, this.riskService.searchRisks.bind(this.riskService));
            this.loadedData[queryHash] = result
        }
        return this.loadedData[queryHash];
    })

    getWorkItems = flow(function*(query) {
        const queryHash = md5(`getContacts${JSON.stringify(query)}`);
        if (!this.loadedData[queryHash]) {
            const result = yield getWorkItems(query, this.workItemService.searchWorkItems.bind(this.workItemService));
            this.loadedData[queryHash] = result
        }
        return this.loadedData[queryHash];
    })

    getExceptions = flow(function*(query) {
        const queryHash = md5(`getExceptions${JSON.stringify(query)}`);
        if (!this.loadedData[queryHash]) {
            const result = yield getExceptions(query, this.riskService.searchRisks.bind(this.riskService));
            this.loadedData[queryHash] = result
        }
        return this.loadedData[queryHash];
    })

    getExceptionsGroupByCount = flow(function*(query, groupBy) {
        const queryHash = md5(`getExceptionsGroupByCount${JSON.stringify(query)}${groupBy}`);
        if (!this.loadedData[queryHash]) {
            this.loadedData[queryHash] = yield getExceptionsGroupByCount(query, groupBy, this.riskService.getRisksGroupByCount.bind(this.riskService))
        }
        return this.loadedData[queryHash]
    });

    getRisksGroupByCount = flow(function*(query, groupBy) {
        const queryHash = md5(`getRisksGroupByCount${JSON.stringify(query)}${groupBy}`);
        if (!this.loadedData[queryHash]) {
            this.loadedData[queryHash] = yield getRisksGroupByCount(query, groupBy, this.riskService.getRisksGroupByCount.bind(this.riskService))
        }
        return this.loadedData[queryHash]
    });


    getControlMap = flow(function*(query) {
        const queryHash = md5(`getControlMap${JSON.stringify(query)}`);
        if (!this.loadedData[queryHash]) {
            const result = yield getControlMap(query, this.assessmentService.getAdvisoriesControlMap.bind(this.assessmentService));
            this.loadedData[queryHash] = result
        }
        return this.loadedData[queryHash];
    })

    public getControlMapList = flow(function*() {
        try {
            if (this.controlMaps.length > 0) return this.controlMaps;
            const response = yield this.controlService.getControlMaps({
                keywords: null,
                startIndex: 0,
                pageSize: 250
            });
            this.controlMaps = response.items.map(controlMap => {
                return { key: controlMap.id, text: controlMap.name}
            })
            return this.controlMaps    
        } catch (e) {
            return [];
        }
    })

    public getProductsList = flow(function*() {
        try {
            if (this.products.length > 0) return this.products;
            const response = yield this.productService.getProducts({
                keywords: null,
                startIndex: 0,
                pageSize: 250
            });
            this.products = response.items.map(products => {
                return { key: products.id, text: products.name}
            })            
            return this.products    
        } catch (e) {
            return [];
        }
    })

    public getGroupsList = flow(function*() {
        try {
            if (this.groups.length > 0) return this.groups;
            const response = yield this.identityService.searchPrincipalsGroups({pageSize: 500,startIndex: 0,});
            this.groups = response.items.map(group => {
                return { key: group.id, text: group.name}
            })            
            return this.groups    
        } catch (e) {
            return [];
        }
    })

    public getPortfolioList = flow(function*() {
        try {
            if (this.portfolios.length > 0) return this.portfolios;
            const response = yield this.portfolioService.getPortfolios({
                pageSize: 250, 
                startIndex: 0,
                keywords: null,
            });
            this.portfolios = response.items.map(portfolio => {
                return { key: portfolio.id, text: portfolio.name}
            })            
            return this.portfolios    
        } catch (e) {
            return [];
        }
    })

    public getRiskTypes = flow(function*(){
        try {
            if (this.riskTypes.length > 0) return this.riskTypes;
            const response = yield this.riskService.getTypes();
            // console.log("getRiskTypes", response)
            this.riskTypes = response.items
                .map(risk => {
                    return { key: risk.template, text: risk.name}
                })
            return this.riskTypes    
        } catch (e) {
            return [];
        }
    })

    public getSubStatuses = flow(function*() {
        try {
            if (this.subStatues.length > 0) return this.subStatues;
            const response = yield this.riskService.getSubStatuses();
            // console.log("getSubStatuses", response)
            this.subStatues = response
                .filter(status => !!status)
                .map(status => {
                    return { key: status, text: status}
                })
            return this.subStatues    
        } catch (e) {
            return [];
        }
    })

    public getWorkItemsSubStatuses = flow(function*() {
        try {
            if (this.workItemsSubStatues.length > 0) return this.workItemsSubStatues;
            const response = yield this.workItemService.getSubStatuses();
            // console.log("getSubStatuses", response)
            this.workItemsSubStatues = response
                .filter(status => !!status)
                .map(status => {
                    return { key: status, text: status}
                })
            return this.workItemsSubStatues    
        } catch (e) {
            return [];
        }
    })

    public getControlSets = flow(function*() {
        try {
            if (this.controlSets.length > 0) return this.controlSets;
            const response = yield this.controlService.getControlSets({
                pageSize: 250, 
                startIndex: 0,
                keywords: null,
            });
            this.controlSets = response.items.map(control => {
                return { key: control.id, text: control.name}
            })            
            return this.controlSets    
        } catch (e) {
            return [];
        }
    })

    public getControls = flow(function*() {
        try {
            if (this.controls.length > 0) return this.controls;
            const response = yield this.controlService.getControls({
                pageSize: 250, 
                startIndex: 0,
                keywords: null,
            });
            this.controls = response.items.map(control => {
                return { key: control.id, text: control.name}
            })            
            return this.controls    
        } catch (e) {
            return [];
        }
    })

    public getTasks = flow(function*(query, groupBy, noCache) {
        const queryHash = md5(`getTasks${JSON.stringify(query)}`);
    if (!this.loadedData[queryHash] || noCache === true) {
            const result = yield getTasks(query, this.workflowService.searchMyTaskAssignments.bind(this.workflowService), this.workflowService.searchTaskDefinitions.bind(this.workflowService));
            this.loadedData[queryHash] = result
        }
        return this.loadedData[queryHash];
    })

    public coreDataSources: any[] = [
        {
            id: 'coreDataSource:assessments',
            name: 'Assessments',
            searchService: (query, groupBy) => this.getAssessments(query),
            filters: assessmentFilters(this.getPortfolioList.bind(this)),
            groupByFields: [],
            fields: [
                { id: 'id', title: 'Id', type: 'string'},
                { id: 'code', title: 'Code', type: 'string'},
                { id: 'title', title: 'Title', type: 'string'},
                { id: 'priority', title: 'Priority', type: 'string'},
                { id: 'trigger', title: 'Trigger', type: 'string'},
                { id: 'patternVersion', title: 'Pattern Version', type: 'patternVersion'},
                { id: 'state', title: 'State', type: 'string'},

                { id: 'dueDate', title: 'Due Date', type: 'date'},
                { id: 'dueDate', title: 'Due Date', type: 'date'},
                { id: 'started', title: 'Started', type: 'date'},
                { id: 'startedBy', title: 'Started By', type: 'person'},
                { id: 'submitted', title: 'Submitted', type: 'date'},
                { id: 'submittedBy', title: 'Submitted By', type: 'person'},
                { id: 'reviewed', title: 'Reviewed', type: 'date'},
                { id: 'reviewedBy', title: 'Reviewed By', type: 'person'},
                { id: 'closed', title: 'Closed', type: 'date'},
                { id: 'closedBy', title: 'Closed By', type: 'person'},
                { id: 'created', title: 'Created', type: 'date'},
                { id: 'createdBy', title: 'Created By', type: 'person'},
                { id: 'modified', title: 'Modified', type: 'date'},
                { id: 'modifiedBy', title: 'Modified By', type: 'person'},

                { id: 'assignedToUser', title: 'Assigned To User', type: 'person'},
                { id: 'portfolioContact', title: 'Portfolio Contact', type: 'person'},
                { id: 'portfolioTechnical', title: 'Portfolio Technical', type: 'person'},
                
                { id: 'portfolio', title: 'Portfolio', type: 'portfolio'},
                { id: 'target', title: 'Target', type: 'target'},

                { id: 'summary', title: 'Summary', type: 'summary'},
                
              
            ],
            onProcessQuery: (selectedPartDataSource) => {
                const computedQuery = generateAssessmentQuery(selectedPartDataSource.properties.query);
                return computedQuery;
            },
            onDrillDownAction: (query) => {
                var url = `/assurance/browse?query=${JSON.stringify(query)}`;;
                this.parentStore.rootStore.routing.push(url);
            },
        },
        {
            id: 'coreDataSource:assessments:openedClosed',
            name: 'Assessments Opened/Closed',
            searchService: (query, groupBy) => this.getAssessmentsOpenedClosed(query, groupBy),
            filters: assessmentFilters(this.getPortfolioList.bind(this)),
            groupByFields: [],
            defaultFilters: [],
            fields: [
                { id: 'closed', title: 'Closed', type: 'number'},
                { id: 'totalOpen', title: 'Total Open', type: 'number'},
                { id: 'opened', title: 'Open', type: 'number'},
                { id: 'id', title: 'Id', type: 'string'},
                { id: 'label', title: 'Label', type: 'string'},
            ],
            onProcessQuery: (selectedPartDataSource, clickedElement, clickedElementKey) => {
                const computedQuery = generateAssessmentQuery(selectedPartDataSource.properties.query);
                const elementKey = clickedElementKey ? clickedElementKey[0] : null;
                const secondKey = clickedElementKey ? clickedElementKey[1] : null;
                if (elementKey) {
                    const [year, month] = elementKey.split("-");
                    const timeFilter = {
                        after: moment(`${year}-${month}-01`, "YYYY-MM-DD").utc().format(),
                        before: moment(`${year}-${month}-01`, "YYYY-MM-DD").add(1, "month").utc().format(),
                    };
                    switch (secondKey) {
                        case 'opened':
                            computedQuery.started = timeFilter;
                            computedQuery.timeline = null;
                        break;
                        case 'closed':
                            computedQuery.closed = timeFilter;
                            computedQuery.timeline = null;
                        break;
                        default:
                            computedQuery.timeline = timeFilter;
                    }
                }
                return computedQuery;
            },
            onDrillDownAction: (query) => {
                var url = `/assurance/browse?query=${JSON.stringify(query)}`;
                this.parentStore.rootStore.routing.push(url);
            },
        },
        {
            id: 'coreDataSource:assessments:groupByCount',
            name: 'Assessment Group by count',
            searchService: (query, groupBy) => this.getAssessmentGroupByCount(query, groupBy),
            filters: assessmentFilters(this.getPortfolioList.bind(this)),
            defaultFilters: [],
            groupByFields: [
                { key: 'status', text:'Status'},
                { key: 'assignedToUser', text:'assigned To User'},
                { key: 'assignedToGroup', text:'assigned To Group'},
                { key: 'priority', text:'Priority'},
                { key: 'portfolio', text:'Portfolio', isDefault: true},
                { key: 'requestedBy', text:'Requested By'}
            ],
            fields: [
                { id: 'count', title: 'Count', type: 'number'},
                { id: 'id', title: 'Id', type: 'string'},
                { id: 'label', title: 'Label', type: 'string'},
            ],
            onProcessQuery: (selectedPartDataSource, clickedElement, clickedElementKey) => {
                const computedQuery = generateAssessmentQuery(selectedPartDataSource.properties.query);
                const elementKey = clickedElementKey ? clickedElementKey[0] : null;
                if (selectedPartDataSource.properties.groupBy && elementKey) {
                    switch (selectedPartDataSource.properties.groupBy) {
                        case 'status':
                            computedQuery.status = [elementKey]
                        break;
                        case 'portfolio':
                        case 'assignedToGroup':
                        case 'assignedToUser':
                            computedQuery[selectedPartDataSource.properties.groupBy] = [{id: elementKey}]
                        break;
                        case 'product':
                        case 'priority':                        
                        case 'requestedBy':
                        break;
                    }                 
                }
                return computedQuery;
            },
            onDrillDownAction: (query) => {
                var url = `/assurance/browse?query=${JSON.stringify(query)}`;;
                this.parentStore.rootStore.routing.push(url);
            },
        },
        {
            id: 'coreDataSource:advisories:groupByCount',
            name: 'Advisories Group by count',
            searchService: (query, groupBy) => this.getAdvisoriesGroupByCount(query, groupBy),
            filters: advisoriesFilters(this.getPortfolioList.bind(this), this.getControlSets.bind(this), this.getControls.bind(this)),
            groupByFields: [
                { key: 'control', text:'Control', isDefault: true},
                { key: 'initialCompliances', text:'Initial Compliance State'},
                { key: 'finalCompliance', text:'Final Compliance State'},
                { key: 'assessment', text:'Assessment'},
                { key: 'portfolio', text:'Portfolio'},
            ],
            fields: [
                { id: 'count', title: 'Count', type: 'number'},
                { id: 'id', title: 'Id', type: 'string'},
                { id: 'label', title: 'Label', type: 'string'},
            ]
        },
        {
            id: 'coreDataSource:advisories:flat:controlFinalCompliance',
            name: 'Advisories Group by count',
            searchService: (query, groupBy) => this.getAdvisoriesFlatByControlFinalCompliance(query, groupBy),
            filters: advisoriesFilters(this.getPortfolioList.bind(this), this.getControlSets.bind(this), this.getControls.bind(this)),
            groupByFields: [
                { key: 'parentControl', text:'Parent Controls', isDefault: true},
                { key: 'control', text:'All Controls'},
            ],
            fields: [
                { id: 'control', title: 'Control', type: 'string'},
                { id: 'compliant', title: 'Compliant', type: 'number'},
                { id: 'nonCompliant', title: 'Non Compliant', type: 'number'},
                { id: 'pending', title: 'Pending', type: 'number'},
            ]
        },
        {
            id: 'coreDataSource:advisories:flat:portfolio',
            name: 'Advisories Group by portfolio',
            searchService: (query, groupBy) => this.getAdvisoriesFlatByPortfolio(query, groupBy),
            filters: advisoriesFilters(this.getPortfolioList.bind(this), this.getControlSets.bind(this), this.getControls.bind(this)),
            groupByFields: [],
            fields: [
                { id: 'portfolio', title: 'Portfolio', type: 'string'},
                { id: 'finalCompliant', title: 'Final State Compliant', type: 'number'},
                { id: 'finalNonCompliant', title: 'Final State Non Compliant', type: 'number'},
                { id: 'finalPending', title: 'Final State Pending', type: 'number'},
                { id: 'initialCompliant', title: 'Initial State Compliant', type: 'number'},
                { id: 'initialNonCompliant', title: 'Initial State Non Compliant', type: 'number'},
                { id: 'initialPending', title: 'Initial State Pending', type: 'number'},
            ]
        },
        {
            id: 'coreDataSource:risk',
            name: 'Risk',
            searchService: query => this.getRisks(query),
            filters: riskFilters(this.getPortfolioList.bind(this), this.getSubStatuses.bind(this), this.getRiskTypes.bind(this)),
            showSortingOptions: true,
            groupByFields: [],
            fields: [
                { id: 'id', title: 'Id', type: 'string'},
                { id: 'code', title: 'Code', type: 'string'},
                { id: 'created', title: 'Created', type: 'date'},
                { id: 'createdBy', title: 'Created By', type: 'person'},
                { id: 'description', title: 'Description', type: 'description'},
                { id: 'modified', title: 'Modified', type: 'date'},
                { id: 'modifiedBy', title: 'Modified By', type: 'person'},
                { id: 'title', title: 'Title', type: 'string'},
                { id: 'requestedBy', title: 'Resolved By', type: 'person'},
                { id: 'portfolio', title: 'Portfolio', type: 'portfolio'},
                { id: 'portfolioContact', title: 'Portfolio Contact', type: 'person'},
                { id: 'portfolioTechnical', title: 'Portfolio Technical', type: 'person'},
                { id: 'daysSinceRequestRaised', title: 'Days since request raised', type: 'daysSinceRequestRaised', style: 'composit'},
                { id: 'isNew', title: 'Is new', type: 'isNew', style: 'composit'},
                { id: 'riskExpiryDate', title: 'Expiry Date', type: 'riskExpiryDate', style: 'composit'},
                { id: 'overDue', title: 'Over Due', type: 'riskOverDue', style: 'composit'},
                { id: 'status', title: 'Status', type: 'riskStatus', style: 'composit'},
                { id: 'assignedTo', title: 'Assigned To', type: 'riskAssignedTo', style: 'composit'},
                { id: 'netScore', title: 'Net Risk', type: 'riskNetScore', style: 'composit'},
                { id: 'owner', title: 'Owner', type: 'person'},
                { id: 'trigger', title: 'Trigger', type: 'string'},
                { id: 'review', title: 'Review', type: 'review'},
                { id: 'assessment', title: 'Assessment', type: 'assessment'},
                { id: 'registry', title: 'Registry', type: 'registry'},
                { id: 'lifecycle', title: 'Lifecycle', type: 'lifecycle'},
                { id: 'target', title: 'Target', type: 'exceptionTarget'},
                { id: 'type', title: 'Type', type: 'exceptionType'},
                { id: 'context', title: 'Context', type: 'description'},

            ],
            onProcessQuery: (selectedPartDataSource) => {
                return generateRiskQuery(selectedPartDataSource.properties.query);
            },
            onDrillDownAction: (query) => {
                var url = `/exceptions/browse?query=${JSON.stringify(query)}`;
                this.parentStore.rootStore.routing.push(url);
            },
            getCalendarClick: (id) => {
                return `/exceptions/browse/${id}`;
            },
            timelineGrouppingFields: [],
        },
        {
            id: 'coreDataSource:heatmap',
            name: 'HeatMap ready data',
            searchService: (query, groupBy) => {
                // console.log(query, groupBy)
                return this.getHeatmapData(generateRiskQuery(query), groupBy, query.heatmap_type, query.risk_type)
            },
            filters: heatmapFilters(this.getPortfolioList.bind(this), this.getSubStatuses.bind(this), this.getRiskTypes.bind(this)),
            groupByFields: [],

            onCellClick: (cell, query) => {
                const destination = (query.risk_type === 'exception') ? "exceptions" : "risks";
                const fQuery = { 
                    grossImpact: query.heatmap_type === 'gross' ? [cell.impact] : null,
                    grossLikelihood: query.heatmap_type === 'gross' ? [cell.likelihood] : null,
                    netImpact: query.heatmap_type === 'net' ? [cell.impact] : null,
                    netLikelihood: query.heatmap_type === 'net' ? [cell.likelihood] : null,
                };
                
                var url = `/${destination}/browse?query=${JsonQueryString.encode(fQuery)}`;
                this.parentStore.rootStore.routing.push(url);
            },
            fields: [
                { id: 'text', title: 'Value', type: 'string'},
                { id: 'score', title: 'Cell', type: 'scoreCell', style: 'composit'},
            ]
        },
        {
            id: 'coreDataSource:risk:groupByCount',
            name: 'Excepions group by count',
            searchService: (query, groupBy) => this.getRisksGroupByCount(query, groupBy),
            filters: riskFilters(this.getPortfolioList.bind(this), this.getSubStatuses.bind(this), this.getRiskTypes.bind(this)),
            groupByFields: [
                { key: 'portfolio', text:'Portfolio', isDefault: true},
                { key: 'controlLevel0', text:'Control Level 0'},
                { key: 'controlLevel1', text:'Control Level 1'},
                { key: 'controlLevel2', text:'Control Level 2'},
                { key: 'netRisk', text:'Net Risk'},
                { key: 'riskType', text:'Risk Type'},
                { key: 'period', text: 'Created time'},
            ],
            fields: [
                { id: 'count', title: 'Count', type: 'number'},
                { id: 'id', title: 'Id', type: 'string'},
                { id: 'label', title: 'Label', type: 'string'},
            ]
        },
        {
            id: 'coreDataSource:workItem',
            name: 'Work Item',
            searchService: query => this.getWorkItems(query),
            filters: workItemFilters(this.getPortfolioList.bind(this), this.getProductsList.bind(this), this.getGroupsList.bind(this), this.getWorkItemsSubStatuses.bind(this)),
            showSortingOptions: true,
            groupByFields: [],
            fields: [
                { id: 'code', title: 'Code', type: 'string'},
                { id: 'title', title: 'Title', type: 'string'},
                { id: 'schemaVersion', title: 'Schema Version', type: 'string'},
                { id: 'description', title: 'Description', type: 'description'},
                { id: 'status', title: 'Status', type: 'string'},
                { id: 'portfolio', title: 'Portfolio', type: 'portfolio'},
                { id: 'product', title: 'Product', type: 'product'},
                { id: 'dueDate', title: 'Due Date', type: 'date'},
                { id: 'estimatedClose', title: 'Estimated Close', type: 'date'},
                { id: 'assigned', title: 'assigned', type: 'date'},
                { id: 'priority', title: 'Priority', type: 'string'},
                { id: 'started', title: 'Started', type: 'date'},
                { id: 'startedBy', title: 'Started By', type: 'person'},
                { id: 'resolved', title: 'Resolved', type: 'date'},
                { id: 'resolvedBy', title: 'Resolved By', type: 'person'},
                { id: 'closed', title: 'Closed', type: 'date'},
                { id: 'closedBy', title: 'Closed By', type: 'person'},
                { id: 'modified', title: 'Modified', type: 'date'},
                { id: 'actual', title: 'Actual', type: 'date'},
                { id: 'estimation', title: 'Estimation', type: 'date'},
                { id: 'modifiedBy', title: 'Modified By', type: 'person'},
                { id: 'created', title: 'Created', type: 'date'},
                { id: 'createdBy', title: 'Created By', type: 'person'},
                { id: 'id', title: 'Id', type: 'string'},
                { id: 'assignedToGroup', title: 'Assigned To Group', type: 'assignedToGroup'},
                { id: 'assignedToUser', title: 'Assigned To User', type: 'person'},
                { id: 'requestedBy', title: 'Requested By', type: 'person'},
                { id: 'businessContact', title: 'Business Contact', type: 'person'},
                { id: 'technicalContact', title: 'Technical Contact', type: 'person'},
            ],
            onProcessQuery: (selectedPartDataSource) => {
                return generateWorkItemQuery(selectedPartDataSource.properties.query);
            },
            onDrillDownAction: (query) => {
                var url = `/demand/browse?query=${JSON.stringify(query)}`;
                this.parentStore.rootStore.routing.push(url);
            },
            getCalendarClick: (id) => {
                return `/demand/browse/${id}`;
            },
            timelineGrouppingFields: [
                { 
                    id: 'status',
                    title: 'Status',
                    type: 'string',
                },
                { 
                    id: 'priority',
                    title: 'Priority',
                    type: 'string',
                },
                { 
                    id: 'portfolio',
                    title: 'Portfolio',
                    type: 'portfolio',
                    nameField: 'name',
                    idField: 'id',
                },
                { 
                    id: 'product',
                    title: 'Product',
                    type: 'product',
                    nameField: 'name',
                    idField: 'id',
                },
                { 
                    id: 'startedBy',
                    title: 'Started By',
                    type: 'person',
                    nameField: 'name',
                    idField: 'id',
                },
                { 
                    id: 'resolvedBy',
                    title: 'Resolved By',
                    type: 'person',
                    nameField: 'name',
                    idField: 'id',
                },
                { 
                    id: 'closedBy',
                    title: 'Closed By',
                    type: 'person',
                    nameField: 'name',
                    idField: 'id',
                },
                { 
                    id: 'modifiedBy',
                    title: 'Modified By',
                    type: 'person',
                    nameField: 'name',
                    idField: 'id',
                },
                { 
                    id: 'createdBy',
                    title: 'Created By',
                    type: 'person',
                    nameField: 'name',
                    idField: 'id',
                },
                { 
                    id: 'assignedToUser',
                    title: 'Assigned To User',
                    type: 'person',
                    nameField: 'name',
                    idField: 'id',
                },
                { 
                    id: 'businessContact',
                    title: 'Business To User',
                    type: 'person',
                    nameField: 'name',
                    idField: 'id',
                },
                { 
                    id: 'technicalContact',
                    title: 'Technical To User',
                    type: 'person',
                    nameField: 'name',
                    idField: 'id',
                },
                { 
                    id: 'requestedBy',
                    title: 'Requested B',
                    type: 'person',
                    nameField: 'name',
                    idField: 'id',
                },
                { 
                    id: 'assignedToGroup',
                    title: 'Assigned To Group',
                    type: 'person',
                    nameField: 'name',
                    idField: 'id',
                },

                // { id: 'dueDate', title: 'Due Date', type: 'date'},
                // { id: 'estimatedClose', title: 'Estimated Close', type: 'date'},
                // { id: 'assigned', title: 'assigned', type: 'date'},                
                // { id: 'started', title: 'Started', type: 'date'},                
                // { id: 'resolved', title: 'Resolved', type: 'date'},
                // { id: 'closed', title: 'Closed', type: 'date'},
                // { id: 'modified', title: 'Modified', type: 'date'},
                // { id: 'actual', title: 'Actual', type: 'date'},
                // { id: 'estimation', title: 'Estimation', type: 'date'},
                // { id: 'created', title: 'Created', type: 'date'},
            ],
        },
        {
            id: 'coreDataSource:workItem:openedClosed',
            name: 'Work Item Opened/Closed',
            searchService: (query, groupBy) => this.getWorkItemOpenedClosed(query, groupBy),
            defaultFilters: [],
            filters: workItemFilters(this.getPortfolioList.bind(this), this.getProductsList.bind(this), this.getGroupsList.bind(this), this.getWorkItemsSubStatuses.bind(this)),
            onProcessQuery: (selectedPartDataSource, clickedElement, clickedElementKey) => {
                const computedQuery = generateWorkItemQuery(selectedPartDataSource.properties.query);
                const elementKey = clickedElementKey ? clickedElementKey[0] : null;
                const secondKey = clickedElementKey ? clickedElementKey[1] : null;
                if (elementKey) {
                    const [year, month] = elementKey.split("-");
                    const timeFilter = {
                        after: moment(`${year}-${month}-1`, "YYYY-M-D").utc().format(),
                        before: moment(`${year}-${month}-1`, "YYYY-M-D").add(1, "month").utc().format(),
                    };
                    switch (secondKey) {
                        case 'opened':
                            computedQuery.started = timeFilter;
                            computedQuery.timeline = null;
                        break;
                        case 'closed':
                            computedQuery.closed = timeFilter;
                            computedQuery.timeline = null;
                        break;
                        default:
                            computedQuery.timeline = timeFilter;
                    }
                }
                return computedQuery;
            },
            onDrillDownAction: (query) => {
                var url = `/demand/browse?query=${JSON.stringify(query)}`;
                this.parentStore.rootStore.routing.push(url);
            },
            groupByFields: [],
            fields: [
                { id: 'closed', title: 'Closed', type: 'number'},
                { id: 'totalOpen', title: 'Total Open', type: 'number'},
                { id: 'opened', title: 'Open', type: 'number'},
                { id: 'id', title: 'Id', type: 'string'},
                { id: 'label', title: 'Label', type: 'string'},
            ]
        },
        {
            id: 'coreDataSource:workItem:groupByCount',
            name: 'Work Item Group by count',
            searchService: (query, groupBy) => this.getWorkItemGroupByCount(query, groupBy),
            onProcessQuery: (selectedPartDataSource, clickedElement, clickedElementKey) => {
                const computedQuery = generateWorkItemQuery(selectedPartDataSource.properties.query);
                const elementKey = clickedElementKey ? clickedElementKey[0] : null;
                // debugger
                if (selectedPartDataSource.properties.groupBy && elementKey) {
                    switch (selectedPartDataSource.properties.groupBy) {
                        case 'status':
                            computedQuery.status = [elementKey]
                        break;
                        case 'portfolio':
                        case 'product':
                        case 'assignedToGroup':
                        case 'assignedToUser':
                            computedQuery[selectedPartDataSource.properties.groupBy] = [{id: elementKey}]
                        break;

                        case 'priority':
                        case 'requestedBy':
                        break;
                    }
                }
                return computedQuery;
            },
            onDrillDownAction: (query) => {
                var url = `/demand/browse?query=${JSON.stringify(query)}`;
                this.parentStore.rootStore.routing.push(url);
            },
            getCalendarClick: (id) => {
                return `/demand/browse/${id}`;
            },
            filters: workItemFilters(this.getPortfolioList.bind(this), this.getProductsList.bind(this), this.getGroupsList.bind(this), this.getWorkItemsSubStatuses.bind(this)),
            groupByFields: [
                { key: 'status', text:'Status'},
                { key: 'product', text:'Product', isDefault: true},
                { key: 'assignedToUser', text:'Assigned To User'},
                { key: 'assignedToGroup', text:'Assigned To Group'},
                { key: 'priority', text:'Priority'},
                { key: 'portfolio', text:'Portfolio'},
                { key: 'requestedBy', text:'Requested By'}
            ],
            defaultFilters: [],
            fields: [
                { id: 'count', title: 'Count', type: 'number'},
                { id: 'id', title: 'Id', type: 'string'},
                { id: 'label', title: 'Label', type: 'string'},
            ]
        },
        {
            id: 'coreDataSource:portfolios:contacts',
            name: 'Portfolios contacts',
            searchService: (query, groupBy) => this.getPortfoliosContacts(query, groupBy),
            filters: [
                {
                    id:'portfolios',
                    name:'Portfolios',
                    type:'multiselectList',
                    values: [
                        { key: 'LastMonth', text: 'Last Month'},
                        { key: 'Last3Months', text: 'Last 3 Months'},
                        { key: 'Last6Months', text: 'Last 6 Month'},
                        { key: 'LastYear', text: 'Last Year'},
                    ],
                    getValues: () => this.getPortfolioList(),
                },
            ],
            groupByFields: [],
            fields: [                
                { 
                    id: 'badge',
                    title: 'Contact',
                    type: 'personBadge',
                    badgeFields: {
                        text: 'name', 
                        secondaryText: 'jobTitle'
                    }
                },
                { id: 'portfolioName', title: 'Portfolio Name', type: 'string'},
                { id: 'name', title: 'Name', type: 'string'},
                { id: 'jobTitle', title: 'Job Title', type: 'string'},
                { id: 'email', title: 'Email', type: 'string'},
                { id: 'contactType', title: 'Contact Type', type: 'string'},
            ]
        },

        //GROUPED DATA SOURCES
        {
            id: 'coreDataSource:riskLevel:status',
            name: 'Upcoming Activities',
            type: 'dynamicGroup',
            searchService: (query, groupBy) => demoDataSource.ResponseNewStack(query, groupBy),
            filters: [
                {
                    id:'dateRange',
                    name:'Dynamic Date Range',
                    type:'list',
                    values: [
                        { key: 'LastMonth', text: 'Last Month'},
                        { key: 'Last3Months', text: 'Last 3 Months'},
                        { key: 'Last6Months', text: 'Last 6 Month'},
                        { key: 'LastYear', text: 'Last Year'},
                    ]
                },
                { 
                    id: 'staticDateRange',
                    title:'Static Date Range',
                    type:'daterange',
                },
            ],
            groupByFields: [],
            fields: [
                { id: 'riskLevel', title: 'Risk Level', type: 'string'},
                { id: 'status', title: 'Status', type: 'string'},
                { id: 'count', title: 'Value', type: 'number'},
            ]
        },

        //STATIC DATA SOURCES
        {
            id: 'coreDataSource:activities:upcoming',
            name: 'Upcoming Activities',
            searchService: (query, groupBy) => demoDataSource.upcomingActivitiesResponse(query, groupBy),
            filters: [],
            groupByFields: [],
            fields: [
                { id: 'due', title: 'Due', type: 'string'},
                { id: 'asset', title: 'Asset', type: 'string'},
                { id: 'type', title: 'Type', type: 'string'},
            ]
        },
        {
            id: 'coreDataSource:CISO:totalIncomingRequests',
            name: 'CISO Total Incoming Requests',
            searchService: (query, groupBy) => demoDataSource.totalIncomingRequestsResponse(query, groupBy),
            filters: [],
            groupByFields: [],
            fields: [
                { id: 'period', title: 'Period', type: 'string'},
                { id: 'incomingRequests', title: 'Incoming Requests', type: 'number'},
            ]
        },
        {
            id: 'coreDataSource:food:keycontacts',
            name: 'Food Key Contacts',
            searchService: (query, groupBy) => demoDataSource.FoodKeyContactsResponse(query, groupBy),
            filters: [],
            groupByFields: [],
            fields: [
                { id: 'name', title: 'Name', type: 'string'},
                { id: 'jobTitle', title: 'Job Title', type: 'string'},
            ]
        },
        {
            id: 'coreDataSource:CISO:teamActivity',
            name: 'CISO Team Activity',
            searchService: (query, groupBy) => demoDataSource.CISOTeamActivity(query, groupBy),
            filters: [],
            groupByFields: [],
            fields: [
                { id: 'activeUsers', title: 'Active users', type: 'number'},
                { id: 'overdueRequests', title: 'Overdue Requests', type: 'number'},
                { id: 'onTimeRequests', title: 'On Time Requests', type: 'number'},
            ]
        },
        {
            id: 'coreDataSource:CISO:engagement',
            name: 'CISO Engagement',
            searchService: (query, groupBy) => demoDataSource.CISOPortfoliosAtGlanceResponse(query, groupBy),
            filters: [],
            groupByFields: [],
            fields: [
                { id: 'portfolio', title: 'Portfolio', type: 'string'},
                { id: 'closed', title: 'Closed', type: 'number'},
                { id: 'opened', title: 'Open', type: 'number'},
                { id: 'cost', title: 'Cost', type: 'number'},
                { id: 'penTesting', title: 'Pen Testing', type: 'number'},
                { id: 'assurance', title: 'Assurance', type: 'number'},
                { id: 'perimeterSecurity', title: 'Perimeter Security', type: 'number'},
                { id: 'patternsAndBlueprints', title: 'Patterns And Blueprints', type: 'number'},
                { id: 'dataPrivacyImpactAssessments', title: 'Data Privacy Impact Assessments', type: 'number'},
            ]
        },
        {
            id: 'coreDataSource:CISO:risks',
            name: 'CISO Risks',
            searchService: (query, groupBy) => demoDataSource.CISORisksResponse(query, groupBy),
            filters: [],
            groupByFields: [],
            fields: [
                { id: 'riskLevel', title: 'Risk Level', type: 'string'},
                { id: 'count', title: 'Risks', type: 'number'},
            ]
        },
        {
            id: 'coreDataSource:foodportfolio:risks',
            name: 'Food portfolio Risks',
            searchService: (query, groupBy) => demoDataSource.FoodPortfolioRisksResponse(query, groupBy),
            filters: [],
            groupByFields: [],
            fields: [
                { id: 'riskLevel', title: 'Risk Level', type: 'string'},
                { id: 'count', title: 'Risks', type: 'number'},
            ]
        },
        {
            id: 'coreDataSource:CISO:risks:totals',
            name: 'CISO Risks totals',
            searchService: (query, groupBy) => demoDataSource.CISOEngagementTotalResponse(query, groupBy),
            filters: [],
            groupByFields: [],
            fields: [
                { id: 'label', title: 'Period', type: 'string'},
                { id: 'count', title: 'Total Risks', type: 'number'},
            ]
        },
        {
            id: 'coreDataSource:engagement',
            name: 'Engagement',
            searchService: (query, groupBy) => demoDataSource.engagmentResponse(query, groupBy),
            filters: [],
            groupByFields: [],
            fields: [
                { id: 'label', title: 'Period', type: 'date'},
                { id: 'count', title: 'Open demands', type: 'number'},
            ]
        },
        {
            id: 'coreDataSource:demands:open:status',
            name: 'Open demands (Assessment status)',
            searchService: (query, groupBy) => demoDataSource.openDemandsStatusResponse(query, groupBy),
            filters: [],
            groupByFields: [],
            fields: [
                { id: 'label', title: 'Assessment Status', type: 'string'},
                { id: 'count', title: 'Open demands', type: 'number'},
            ]
        },
        {
            id: 'coreDataSource:demands:open:products',
            name: 'Open demands (by type)',
            searchService: (query, groupBy) => demoDataSource.openDemandsResponse(query, groupBy),
            filters: [],
            groupByFields: [],
            fields: [
                { id: 'label', title: 'Type', type: 'string'},
                { id: 'count', title: 'Open demands', type: 'number'},
            ]
        },
        {
            id: 'coreDataSource:demands',
            name: 'Demands ',
            searchService: (query, groupBy) => demoDataSource.demandsResponse(query, groupBy),
            filters: [
                // {
                //     id:'portfolio',
                //     name:'Portfolio',
                //     type:'dataSource',
                //     dataSourceId:'Portfolios',
                //     query: {}
                // },`
                // {
                //     id:'status',
                //     name:'Status',
                //     type:'list',
                //     values: [
                //         { key: 'Open', text: 'Open'},
                //         { key: 'Assigned', text: 'Assigned'},
                //         { key: 'InProgress', text: 'In Progress'},
                //         { key: 'Blocked', text: 'Blocked'},
                //         { key: 'Resolved', text: 'Resolved'},
                //         { key: 'Closed', text: 'Closed'},
                //         { key: 'Cancelled', text: 'Cancelled'},
                //     ]
                // },
                // {
                //     id:'priority',
                //     name:'Priority',
                //     type:'list',
                //     values: [
                //         { key: 'High', text: 'In High'},
                //         { key: 'Medium', text: 'Medium'},
                //         { key: 'Low', text: 'Low'},                        
                //     ]
                // },
                // { 
                //     id: 'assignedToUser',
                //     title:'Assigned To User',
                //     type:'userpicker'},
                // { 
                //     id: 'assignedToGroup',
                //     title:'Assigned To Group',
                //     type:'grouppicker'
                // },
                // { 
                //     id: 'created',
                //     title:'Created',
                //     type:'daterange',
                //     dynamic: true,
                // },
            ],
            groupByFields: [
                // { key: 'status', text:'Status'},
                // { key: 'portfolio', text:'Portfolio'},
                // { key: 'assignedToGroup', text:'Group'},
                // { key: 'priority', text:'Priority'},
            ],
            fields: [
                // { id: 'dueDate', title:'Due Date', type:'datetime'},
                { id: 'time', title:'Time', type:'datetime'},
                // { id: 'assignedToUser', title:'Assigned To User', type:'userpicker'},
                // { id: 'assignedToGroup', title:'Assigned To Group', type:'grouppicker'},
                // { id: 'status', title:'Status', type:'string'},
                // { id: 'workItemCount', title: 'Count', type: 'number'},
                { id: 'closed', title: 'Closed', type: 'number'},
                { id: 'totalOpen', title: 'Total Open', type: 'number'},
                { id: 'opened', title: 'Open', type: 'number'},
                { id: 'period', title:'Period', type:'string'},
                // { id: 'portfolio', title:'Portfolio', type:'string'},
            ]
        },

        {
            id: 'coreDataSource:controlMaps:controls',
            name: 'Controls Map',
            searchService: (query) => this.getControlMap(query),
            filters: controlMapsFilters(this.getPortfolioList.bind(this), this.getControlMapList.bind(this), this.getControlSets.bind(this), this.getControls.bind(this)),
            groupByFields: [],
            fields: [
                { id: 'id', title: 'Id', type: 'string'},
                { id: 'name', title: 'Axis', type: 'string'},
                { id: 'value', title: 'Value', type: 'number', decimalPositions: 2},
            ]
        },
        {
            id: 'coreDataSource:tasks',
            name: 'Tasks',
            searchService: (query, groupBy, noCache) => this.getTasks(query, groupBy, noCache),
            defaultFilters: [],
            filters: tasksFilters(),
            onProcessQuery: (selectedPartDataSource) => {
                const userOnly = selectedPartDataSource.properties.filters.find(F => F.id === "userOnly").value === "true"
                const resourceType = selectedPartDataSource.properties.filters.find(F => F.id === "resourceType");
                const workItemContext = selectedPartDataSource.properties.filters.find(F => F.id === "workItemContext")
                const assessmentContext = selectedPartDataSource.properties.filters.find(F => F.id === "assessmentContext")

                return {
                    userOnly,
                    resourceType,
                    workItemContext,
                    assessmentContext,
                };
            },
            onDrillDownAction: (query) => {
                var url = ``;
                if (query.userOnly) {
                    url = '/account/tasks';
                } else {
                    switch (query.resourceType.value) {
                        case 'workItem':
                          if (query.workItemContext) {
                            url = `/demand/browse/${query.workItemContext.value[0].key}/tasks`;
                          }
                        break;
                        case 'assessment':
                          if (query.assessmentContext) {
                            url = `/assurance/browse/${query.assessmentContext.value[0].key}/tasks`
                          }
                        break;
                      }
                }
                this.parentStore.rootStore.routing.push(url);
            },
            groupByFields: [],
            fields: [
                // { id: 'closed', title: 'Closed', type: 'number'},
                // { id: 'totalOpen', title: 'Total Open', type: 'number'},
                // { id: 'opened', title: 'Open', type: 'number'},
                { id: 'id', title: 'Id', type: 'string'},
                { id: 'status', title: 'Status', type: 'string'},
                { id: 'description', title: 'Description', type: 'string'},
                { id: 'title', title: 'Title', type: 'string'},
            ]
        },
        {
            id: 'coreDataSource:exceptions:groupByCount',
            name: 'Excepions group by count',
            searchService: (query, groupBy) => this.getExceptionsGroupByCount(query, groupBy),
            filters: exceptionFilters(this.getPortfolioList.bind(this), this.getSubStatuses.bind(this), this.getRiskTypes.bind(this)),
            groupByFields: [
                { key: 'portfolio', text:'Portfolio', isDefault: true},
                { key: 'controlLevel0', text:'Control Level 0'},
                { key: 'controlLevel1', text:'Control Level 1'},
                { key: 'controlLevel2', text:'Control Level 2'},
                { key: 'netRisk', text:'Net Risk'},
                { key: 'riskCategory', text:'Risk Category'},
                { key: 'riskType', text:'Risk Type'},
                { key: 'period', text: 'Created time'},
            ],
            fields: [
                { id: 'count', title: 'Count', type: 'number'},
                { id: 'id', title: 'Id', type: 'string'},
                { id: 'label', title: 'Label', type: 'string'},
            ]
        },
        {
            id: 'coreDataSource:exceptions',
            name: 'Exceptions',
            searchService: query => this.getExceptions(query),
            filters: exceptionFilters(this.getPortfolioList.bind(this), this.getSubStatuses.bind(this), this.getRiskTypes.bind(this)),
            showSortingOptions: true,
            groupByFields: [],
            fields: [
                { id: 'code', title: 'Code', type: 'string'},
                { id: 'title', title: 'Title', type: 'string'},
                { id: 'requestedBy', title: 'Requested By', type: 'person'},
                { id: 'created', title: 'Created', type: 'date'},
                { id: 'daysSinceRequestRaised', title: 'Days since request raised', type: 'daysSinceRequestRaised', style: 'composit'},
                { id: 'isNew', title: 'Is new', type: 'isNew', style: 'composit'},

                { id: 'portfolio', title: 'Portfolio', type: 'portfolio'},
                { id: 'riskExpiryDate', title: 'Expiry Date', type: 'riskExpiryDate', style: 'composit'},

                { id: 'overDue', title: 'Over Due', type: 'riskOverDue', style: 'composit'},
                { id: 'status', title: 'Status', type: 'riskStatus', style: 'composit'},
                { id: 'assignedTo', title: 'Assigned To', type: 'riskAssignedTo', style: 'composit'},

                { id: 'netScore', title: 'Net Risk', type: 'riskNetScore', style: 'composit'},

                { id: 'modified', title: 'Modified', type: 'date'},
                { id: 'modifiedBy', title: 'Modified By', type: 'person'},
                


                { id: 'description', title: 'Description', type: 'description'},
                { id: 'portfolioTechnical', title: 'Portfolio Technical', type: 'person'},
                { id: 'portfolioContact', title: 'Portfolio Contact', type: 'person'},
                { id: 'id', title: 'Id', type: 'string'},
                { id: 'createdBy', title: 'Created By', type: 'person'},
                { id: 'owner', title: 'Owner', type: 'person'},
                { id: 'trigger', title: 'Trigger', type: 'string'},
                { id: 'review', title: 'Review', type: 'review'},
                { id: 'assessment', title: 'Assessment', type: 'assessment'},
                { id: 'registry', title: 'Registry', type: 'registry'},
                { id: 'lifecycle', title: 'Lifecycle', type: 'lifecycle'},
                { id: 'target', title: 'Target', type: 'exceptionTarget'},
                { id: 'type', title: 'Type', type: 'exceptionType'},
                { id: 'context', title: 'Context', type: 'description'},
            ],
            onProcessQuery: (selectedPartDataSource) => {
                // return generateWorkItemQuery(selectedPartDataSource.properties.query);
            },
            onDrillDownAction: (query) => {
                // var url = `/demand/browse?query=${JSON.stringify(query)}`;
                // this.parentStore.rootStore.routing.push(url);
            },
            getCalendarClick: (id) => {
                // return `/demand/browse/${id}`;
            },
            timelineGrouppingFields: [
                { 
                    id: 'status',
                    title: 'Status',
                    type: 'string',
                },
                { 
                    id: 'priority',
                    title: 'Priority',
                    type: 'string',
                },
                { 
                    id: 'portfolio',
                    title: 'Portfolio',
                    type: 'portfolio',
                    nameField: 'name',
                    idField: 'id',
                },
                { 
                    id: 'product',
                    title: 'Product',
                    type: 'product',
                    nameField: 'name',
                    idField: 'id',
                },
                { 
                    id: 'startedBy',
                    title: 'Started By',
                    type: 'person',
                    nameField: 'name',
                    idField: 'id',
                },
                { 
                    id: 'resolvedBy',
                    title: 'Resolved By',
                    type: 'person',
                    nameField: 'name',
                    idField: 'id',
                },
                { 
                    id: 'closedBy',
                    title: 'Closed By',
                    type: 'person',
                    nameField: 'name',
                    idField: 'id',
                },
                { 
                    id: 'modifiedBy',
                    title: 'Modified By',
                    type: 'person',
                    nameField: 'name',
                    idField: 'id',
                },
                { 
                    id: 'createdBy',
                    title: 'Created By',
                    type: 'person',
                    nameField: 'name',
                    idField: 'id',
                },
                { 
                    id: 'assignedToUser',
                    title: 'Assigned To User',
                    type: 'person',
                    nameField: 'name',
                    idField: 'id',
                },
                { 
                    id: 'businessContact',
                    title: 'Business To User',
                    type: 'person',
                    nameField: 'name',
                    idField: 'id',
                },
                { 
                    id: 'technicalContact',
                    title: 'Technical To User',
                    type: 'person',
                    nameField: 'name',
                    idField: 'id',
                },
                { 
                    id: 'requestedBy',
                    title: 'Requested B',
                    type: 'person',
                    nameField: 'name',
                    idField: 'id',
                },
                { 
                    id: 'assignedToGroup',
                    title: 'Assigned To Group',
                    type: 'person',
                    nameField: 'name',
                    idField: 'id',
                },

                // { id: 'dueDate', title: 'Due Date', type: 'date'},
                // { id: 'estimatedClose', title: 'Estimated Close', type: 'date'},
                // { id: 'assigned', title: 'assigned', type: 'date'},                
                // { id: 'started', title: 'Started', type: 'date'},                
                // { id: 'resolved', title: 'Resolved', type: 'date'},
                // { id: 'closed', title: 'Closed', type: 'date'},
                // { id: 'modified', title: 'Modified', type: 'date'},
                // { id: 'actual', title: 'Actual', type: 'date'},
                // { id: 'estimation', title: 'Estimation', type: 'date'},
                // { id: 'created', title: 'Created', type: 'date'},
            ],
        },
    ]
}
