import React, { Component } from "react";
import { observer } from "mobx-react";

import moment from "moment";

import { Label } from "office-ui-fabric-react/lib/Label";
import { Dropdown } from "office-ui-fabric-react/lib/Dropdown";
import { Toggle } from "office-ui-fabric-react/lib/Toggle";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { Slider } from "office-ui-fabric-react/lib/Slider";
import { DatePicker } from "office-ui-fabric-react/lib/DatePicker";

import { RichTextField, isNotNullOrEmpty } from "@modules/editors/components/RichTextField";
import RichTextViewer from "@modules/editors/components/RichTextViewer";
import { PortfolioPicker } from "@modules/portfolios/containers/pickers/PortfolioPicker";
import { SimplePatternPicker } from "@modules/patterns/containers/pickers/SimplePatternPicker";
import { SimpleEntityPicker } from "@modules/entities/containers/pickers/SimpleEntityPicker";

import { EntityTypes } from "../../stores/PropertyContants";

export const PropertyField = observer(
    class PropertyField extends Component {
        render() {
            const { property } = this.props;

            switch (property.type) {
                case "Number":
                    return this._renderNumberField();
                case "Boolean":
                    return this._renderBooleanField();
                case "Text":
                    return this._renderTextField();
                case "Date":
                    return this._renderDateField();
                case "Lookup":
                    return this._renderLookupField();
                case "Choice":
                    return this._renderChoiceField();
            }

            return null;
        }

        _renderNumberField() {
            const { property, data, readOnly } = this.props;

            const value = data[property.staticName];
            const schema = property.schema;
            const dp = schema && schema.decimalPlaces ? schema.decimalPlaces : 0;

            /*             if(readOnly) {
                if(typeof(value) === 'undefined' || value == null) {
                    return this._renderReadOnly(property.name, 'N/A');
                }
                return this._renderReadOnly(property.name, value.toFixed(dp));
            } */

            if (schema && schema.slider) {
                return (
                    <Slider
                        label={property.name}
                        value={value}
                        onChange={(ev, value) => (data[property.staticName] = value)}
                        required={property.required}
                        disabled={readOnly || property.readOnly}
                        min={schema ? schema.min : null}
                        max={schema ? schema.max : null}
                        step={schema ? schema.step : null}
                        showValue={schema ? schema.showValue : true}
                        snapToStep={schema ? schema.snapToStep : true}
                    />
                );
            }

            return (
                <TextField
                    label={property.name}
                    placeholder={schema ? schema.placeholder : null}
                    value={value}
                    onChange={(ev, value) => {
                        if (!value) {
                            data[property.staticName] = null;
                        } else if (schema && schema.decimalPlaces === 0) {
                            data[property.staticName] = parseInt(value);
                        } else {
                            data[property.staticName] = parseFloat(value);
                        }
                    }}
                    required={property.required}
                    disabled={readOnly || property.readOnly}
                    type="number"
                    min={schema ? schema.min : null}
                    max={schema ? schema.max : null}
                />
            );
        }

        _renderBooleanField() {
            const { property, data, readOnly } = this.props;

            const value = data[property.staticName];
            const schema = property.schema;
            const onText = schema && schema.onText ? schema.onText : "Yes";
            const offText = schema && schema.offText ? schema.offText : "No";

            /*             if(readOnly) {
                if(typeof(value) === 'undefined') {
                    return this._renderReadOnly(property.name, 'N/A');
                }
                return this._renderReadOnly(property.name, value ? onText : offText);
            } */

            return (
                <Toggle
                    label={property.name}
                    placeholder={schema ? schema.placeholder : null}
                    checked={value}
                    onText={onText}
                    offText={offText}
                    onChange={(ev, val) => (data[property.staticName] = val)}
                    required={property.required}
                    disabled={readOnly || property.readOnly}
                />
            );
        }

        _renderTextField() {
            const { property, data, readOnly } = this.props;

            const value = data[property.staticName];
            const schema = property.schema;

            if (schema && schema.richText) {
                if (readOnly) {
                    if (typeof value === "undefined" || !isNotNullOrEmpty(value)) {
                        return this._renderReadOnly(property.name, "N/A");
                    }
                    return (
                        <div>
                            <Label>{property.name}</Label>
                            <div>
                                <RichTextViewer
                                    className="description"
                                    value={
                                        value || [
                                            {
                                                children: [{ text: "" }],
                                            },
                                        ]
                                    }
                                />
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <RichTextField
                            label={property.name}
                            placeholder={schema ? schema.placeholder : null}
                            value={
                                value || [
                                    {
                                        children: [{ text: "" }],
                                    },
                                ]
                            }
                            onChange={(val) => (data[property.staticName] = val)}
                            required={property.required}
                            disabled={readOnly || property.readOnly}
                        />
                    );
                }
            }

            /*             if(readOnly) {
                if(typeof(value) === 'undefined' || value == null) {
                    return this._renderReadOnly(property.name, 'N/A');
                }
                return this._renderReadOnly(property.name, value);
            }
 */
            return (
                <TextField
                    label={property.name}
                    value={value}
                    onChange={(ev, value) => (data[property.staticName] = value)}
                    required={property.required}
                    disabled={readOnly || property.readOnly}
                    placeholder={schema ? schema.placeholder : null}
                    multiline={schema && (schema.multiline || schema.maxLength > 400)}
                    autoAdjustHeight={schema && (schema.multiline || schema.maxLength > 400)}
                    maxLength={schema && schema.maxLength ? schema.maxLength : 400}
                />
            );
        }

        _renderDateField() {
            const { property, data, readOnly } = this.props;

            const value = data[property.staticName];
            const schema = property.schema;

            /*             if(readOnly) {
                if(typeof(value) === 'undefined' || value == null) {
                    return this._renderReadOnly(property.name, 'N/A');
                } else {
                    return this._renderReadOnly(property.name, value.name);
                }
            } */

            return (
                <DatePicker
                    label={property.name}
                    value={value ? moment.utc(value).toDate() : null}
                    onSelectDate={(value) => (data[property.staticName] = value)}
                    isRequired={property.required}
                    disabled={readOnly || property.readOnly}
                    placeholder={schema ? schema.placeholder : null}
                    minDate={schema ? schema.minDate : null}
                    maxDate={schema ? schema.maxDate : null}
                    allowTextInput={schema ? schema.allowTextInput : false}
                />
            );
        }

        _renderLookupField() {
            const { property, data, readOnly } = this.props;

            const value = data[property.staticName];
            const schema = property.schema;

            if (readOnly) {
                if (typeof value === "undefined" || value == null) {
                    return this._renderReadOnly(property.name, "N/A");
                } else {
                    return this._renderReadOnly(property.name, value.name);
                }
            }

            if (schema && schema.entity) {
                switch (schema.entity) {
                    case EntityTypes.Portfolio:
                        return (
                            <PortfolioPicker
                                label={property.name}
                                required={property.required}
                                multiple={property.multiple}
                                disabled={readOnly || property.readOnly}
                                placeholder={schema ? schema.placeholder : null}
                                selected={value}
                                onChange={(portfolio) => {
                                    data[property.staticName] = portfolio;
                                }}
                            />
                        );
                    case EntityTypes.Pattern:
                        return (
                            <SimplePatternPicker
                                label={property.name}
                                required={property.required}
                                multiple={property.multiple}
                                disabled={readOnly || property.readOnly}
                                placeholder={schema ? schema.placeholder : null}
                                selected={value}
                                onChange={(pattern) => {
                                    data[property.staticName] = pattern;
                                }}
                            />
                        );
                    /*                     case EntityTypes.Entity:
                        return (
                            <SimpleEntityPicker
                                label={property.name}
                                required={property.required}
                                multiple={property.multiple}
                                disabled={readOnly || property.readOnly}
                                placeholder={schema ? schema.placeholder : null}
                                selected={value}
                                onChange={(pattern) => {
                                    data[property.staticName] = pattern;
                                }}
                            />
                        ); */
                }
            }

            return (
                <div>
                    <Label>{property.name}</Label>
                    <div>Not Implemented</div>
                </div>
            );
        }

        _renderChoiceField() {
            const { property, data, readOnly } = this.props;

            const value = data[property.staticName];
            const schema = property.schema;
            /*
            if(readOnly) {
                if(typeof(value) === 'undefined' || value == null) {
                    return this._renderReadOnly(property.name, 'N/A');
                } else {
                    return this._renderReadOnly(property.name, value);
                }
            }
 */
            return (
                <Dropdown
                    label={property.name}
                    placeholder={schema ? schema.placeholder : null}
                    selectedKey={value}
                    onChange={(ev, op) => (data[property.staticName] = op.key)}
                    options={schema && schema.options ? schema.options.map((o) => ({ key: o, text: o })) : []}
                    required={property.required}
                    disabled={readOnly || property.readOnly}
                />
            );
        }

        _renderReadOnly(label, value) {
            return (
                <div>
                    <Label>{label}</Label>
                    <div>{value}</div>
                </div>
            );
        }
    }
);
