import React, { Component } from 'react';
import { cx , css} from 'emotion';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { CalloutContainer } from './CalloutContainer';
import { PrincipalPicker } from '@identity/containers/PrincipalPicker';
import { AsyncPicker} from '@baseComponents/asyncPicker/AsyncPicker';
import { BarContainer } from './BarContainer';
import { RiskHeatmap } from '@modules/risks/components/reviews/RiskHeatmap';
import { Stack } from '@ui/';
import { GroupedList } from '@ui/';
import { SelectionMode } from '@uifabric/utilities';
import { RegistryPicker } from '@modules/risks/containers/pickers/RegistryPicker';

const filterBarClassName = cx(css`
    .ms-FocusZone {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`);


const labelDivContainerClassName = cx(css`
    display: flex;
    justify-content: space-between;
    align-items: center;

    & button.ms-Button--icon {
        margin-top: -3px;
        min-width: 30px;
    }

    & span.ms-Button-flexContainer {
        justify-content: flex-end;
    }

    & div[role="alertdialog"] {
        min-height: 65px;
    }
`);

const GroupedListClassName = cx(css`
    .ms-List[role="grid"] {
        margin-left: 50px;
    }
    r-cell > r-grid {
        font-size: 14px;
    }
    i[role="presentation"] {
        margin-left: 0px;
    }
    .ms-Button.ms-Button--icon {
        padding-left: 0px
    }
    .ms-Button-flexContainer {
        justify-content: flex-start
    }
    .ms-List[role="rowgroup"] {
        background-color: var(--background-color);
        padding: 6px;
    }
`)

const groupHeaderClassName = cx(css`
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    align-items: center;
    margin-top: 15px;
    :hover {
        background-color: var(--background-color);
    }

    div {
        user-select: none;
    }

`);

const groupIconButtonClassName = cx(css`
    padding-left: 4px !important;
    button {
        width: 20px;
    }
`);

export class MiniBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isCalloutVisible: false
        }
        this.debounceTimer = null;
        this.debounceTimeout = 1000;
    }

    componentWillReceiveProps(nextProps) {
        const selectedField = {};
        // console.lo`g`('componentWillReceiveProps', nextProps.fields);
        nextProps.fields.forEach(field => {
            switch (field.fieldType) {
                case 'dropdown':
                    field.items = field && field.items ? field.items.map(I => { return {
                        key: I.key,
                        text: I.text,
                        checked: field.selectedItems ? (field.selectedItems.map(I => I.toString().toLowerCase()).includes(I.key.toString().toLowerCase())) : I.checked
                    }}) : []
                    selectedField[`K-${field.fieldName}`] = field.items.filter(i=>i.checked).map(i=>i.key);
                    // console.log(field.fieldName, selectedField[`K-${field.fieldName}`], field.items)
                break;
            }
        })
        // console.log(`componentWillReceiveProps selectedField`, selectedField);
        this.setState({
            ...selectedField
        })
    }
    
    onFiltersChange(newField) {
        const {onFiltersChange, fields} = this.props
        let selectedfields = [];
        //if there is new field, replace it in the fields(this comes from props) value
        selectedfields = fields.map(F => {
            if (newField && F.fieldName === newField.fieldName) {
                return newField
            } else {
                return F
            }
        })
        // console.log('onFiltersChange', fields, newField, selectedfields)        
        // debugger
        if (onFiltersChange) (onFiltersChange(selectedfields))
    }

    toggleIsCalloutVisible () {
        this.setState({
            isCalloutVisible: !this.state.isCalloutVisible
        })
    }

    debounce(func, args) {
        if (this.debounceTimer) clearTimeout(this.debounceTimer)
        const context = this;
        // console.log('SETTIMEOUT', context, args)
        // debugger
        this.debounceTimer = setTimeout(() => {
            // console.log('CALL', context, args)
            func.call(context, args)
        }, this.debounceTimeout)
    }

    onPersonaChange = (field, elements) => {
        if (elements) {
            field.items = [];
            elements.forEach(E => {
                E.checked = true;
                if  (!E.id) E.id = E.key;
                if (!field.items) field.items = [];
                field.items.push(E);
            })
        }
        this.onFiltersChange();
    }

    onItemPicked = (field, item) => {
        if (item) {
            item.checked = true;
            if  (!item.id) item.id = item.key;
            if (!field.items) field.items = [];
            field.items.push(item);
        }
        this.onFiltersChange();
    }

    onItemsChange = (field, items) => {
        if (items) {
            field.items = items.map(I => {
                return {
                    checked: true,
                    data: I.data,
                    id: I.key,
                    key: I.key,
                    name: I.name,
                }
            })
        } else {
            field.items = []
        }
        this.onFiltersChange();
    }

    getMinibarField(field) {
        const { disabled } = this.props;
        switch (field.fieldType) {
            case 'text':
                return {
                    key: field.fieldName,
                    text: field.fieldName,
                    onRender: () => {
                        return (
                            <SearchBox
                                dataTest={field.fieldName}
                                value={field.value}
                                placeholder={field.placeholder}
                                styles={{ root: { 
                                    height: '32px',
                                    minWidth: '270px',
                                }}}
                                onSearch={(value) => {field.value = value; this.onFiltersChange();}}
                                onClear={() => { field.value = ""; this.onFiltersChange();}}
                            />
                        );
                    }
                }
            case 'personPicker':
                return  {
                    key: field.fieldName,
                    text: field.fieldName,
                    onRender: () => {
                        return (
                            <CalloutContainer
                                dataTest={field.fieldName}
                                selectedField = {field}
                                type = 'personPicker'
                                onFiltersChange = {() => this.onFiltersChange()}
                            />
                        );
                    }
                }
            case 'dropdown':
                return  {
                    key: field.fieldName,
                    text: field.fieldName,
                    onRender: () => {
                        field.items = field && field.items ? field.items.map(I => { return {
                            key: I.key,
                            text: I.text,
                            checked: I.checked || (field.selectedItems ? field.selectedItems.map(I => I.toLowerCase()).includes(I.key.toLowerCase()) : false)
                        }}) : []
                        return (
                            <CalloutContainer
                                dataTest={field.fieldName}
                                selectedField = {field}
                                multiselect = {field.multiselect}
                                type = 'dropdown'
                                onFiltersChange = {() => this.onFiltersChange()}
                            />
                        );
                    }
                }
            case 'asyncPicker':
                return {
                    key: field.fieldName,
                    text: field.fieldName, 
                    onRender: () => {
                        return (
                            <CalloutContainer
                                dataTest={field.fieldName}
                                selectedField = {field}
                                type = 'asyncPicker'
                                onFiltersChange = {() => this.onFiltersChange()}
                                searchApi={field.searchApi}
                                selectedItems={field.selectedItems}
                                idField={field.idField || "id"}
                                textField={field.textField || "name"}
                            />
                        );
                    } 
                }
            default:
                return null
        }
    }

    getCalloutField(field, idx) {
        const { disabled, fields } = this.props;
        switch (field.fieldType) {
            case 'registryPicker':
                // console.log('field', field)
                return <RegistryPicker
                    label="Registry"
                    selectedId={field.selectedItems}
                    onChange={(registry) => {
                        if (registry && registry.id) {
                            field.value = registry.id;
                        } else {
                            field.value = null;
                        }
                        this.onFiltersChange()
                    }}
                />
            case 'riskScorePicker':
                return <Stack>
                        {field.placeholder}
                        <RiskHeatmap 
                            size="tiny"
                            disabled={true}
                            scores={field.items.map(I => { 
                                return {
                                    score: I,
                                    selected: true,
                                    text: 'x',
                                }
                            })}
                            onChange={(cell) => {
                                // field.value = [cell.likelihood, cell.impact]
                                // debugger
                                if (!field.readOnly) {
                                    const index = field.items.findIndex(I => {
                                        return I.likelihood === cell.likelihood && I.impact === cell.impact
                                    })
                                    if (index > -1) {
                                        field.items.splice(index, 1)
                                    } else {
                                        field.items.push({
                                            likelihood: cell.likelihood,
                                            impact :cell.impact
                                        })
                                    }
                                    this.onFiltersChange()    
                                }
                            }}
                        />
                    </Stack>
            case 'text': return <SearchBox
                        key={`K-${idx}`}
                        data-test={`FilterTextfield${field.fieldName}`}
                        value={field.value}
                        placeholder={field.placeholder}
                        styles={{ root: { height: '32px', minWidth: '270px'}}}
                        onSearch={(value) => {field.value = value; this.onFiltersChange()}}
                        onClear={() => { field.value = ""; this.onFiltersChange()}}
                        disabled={disabled}
                    />
            case 'personPicker':
                return <div
                    key={`K-${idx}`}
                    style={{marginTop: '10px' }}
                    data-test={`FilterBarPersonPicker`}
                    >
                    <PrincipalPicker 
                        label={field.placeholder}
                        styles={{ root: { minWidth: '270px'}}}
                        placeholder={''}
                        onSelected={(user) => {
                            // debugger
                            this.onPersonaChange(field, user)
                            } 
                        }
                        onClearFilter={() => this.onFiltersChange()}
                        showClearFilter={true}
                        selected={field.items ? field.items : []}
                        multiple={true}
                        disabled={disabled}
                    />
                </div>
            case 'dropdown':
                // console.log('case `dropdown`', field.fieldName, this.state[`K-${field.fieldName}`]);
                return (
                    <Dropdown
                        data-test={`FilterBarDropdown${field.fieldName}`}
                        disabled={disabled}
                        styles={{ root: { minWidth: '270px'}}}                    
                        key={`K-${idx}`}
                        placeholder=""
                        label={field.placeholder}
                        selectedKeys={this.state[`K-${field.fieldName}`]}
                        multiSelect
                        onRenderLabel={(props) => {
                            const checkedItems = this.state[`K-${field.fieldName}`] && this.state[`K-${field.fieldName}`].length > 1
                            return (
                                <div className={labelDivContainerClassName}>
                                    <Label>{props.label}</Label>
                                    {checkedItems && <IconButton
                                        iconProps={{ iconName: 'ClearFilter' }}
                                        title="Clear Filter"
                                        ariaLabel="Clear Filter"
                                        onClick={() => {
                                            field.items = field.items.map(F => {F.checked = false; return F});
                                            this.setState({
                                                [`K-${field.fieldName}`]: []
                                            })
                                            // console.log('FILTERS BEFORE DEBOUNCE', this.state[`K-${field.fieldName}`])
                                            // this.debounce(this.onFiltersChange)
                                            this.onFiltersChange(field)
                                        }}
                                    />}
                              </div>
                            )
                        }}
                        options={field.items}
                        onChange = {(ev, element) => {
                            // debugger
                            field.items.find(I => I.key === element.key).checked = element.selected;
                            this.setState({
                                [`K-${field.fieldName}`]: field.items.filter(i=>i.checked).map(i=>i.key)
                            })
                            // console.log('onChange: FILTERS BEFORE DEBOUNCE', this.state[`K-${field.fieldName}`])
                            this.debounce(this.onFiltersChange, field)
                            // this.onFiltersChange(field)
                        }}
                    />
                );
            case 'asyncPicker':
                // console.log('field.items', field.items);
                // if (field.items && field.items.length > 0) {
                //     debugger
                // }
                return <div key={`K-${idx}`}>
                        <AsyncPicker 
                            disabled={disabled}
                            label={field.placeholder}
                            showClearFilter={true}
                            placeholder={field ? field.placeholder : ''}
                            onItemPicked={(item) => this.onItemPicked(field, item)}
                            selected={field.items || []}
                            api={field.searchApi}
                            idField={field.idField || 'id'}
                            textField={field.textField || 'name'}
                            pickedField={field.pickedField}
                            preTextField={field.preTextField}
                            onItemsChange={(items) => this.onItemsChange(field, items)}
                            onClearFilter={() => { 
                                field.items = [];
                                this.onFiltersChange();
                            }}
                        />
                </div>
            case 'group':
                const groupStateName = `isGroup${field.fieldName}Collapsed`;
                if (typeof this.state[groupStateName] === 'undefined') {
                    this.setState({[groupStateName]:true});
                }
                return <>
                    <GroupedList 
                        className={GroupedListClassName}
                        items={field.fields}
                        compact={true}
                        selectionMode={SelectionMode.none}
                        onRenderCell={(nestingDepth, item, itemIndex) => {
                            const element = fields.find(F => F.fieldName === item);
                            return this.getCalloutField(element, `${idx}_${itemIndex}`);
                        }}
                        groups={[
                            {
                                count: field.fields.length,
                                key: field.fieldName,
                                name: field.numberOfSelectedFields ? `${field.placeholder} (${field.numberOfSelectedFields})` : field.placeholder,
                                startIndex: 0,
                                isCollapsed: this.state[groupStateName],
                            }
                        ]}
                        groupProps={{
                            onRenderHeader: (props) =>{
                                if (props) {
                                    return (
                                        <>
                                            <div 
                                                className={groupHeaderClassName}
                                                onClick={() => {
                                                    this.setState({[groupStateName]:!this.state[groupStateName]});
                                                }}>
                                                <div>
                                                    <IconButton                                                                 
                                                        iconProps={{iconName: this.state[groupStateName] ? 'FlickLeft' : 'FlickUp' }}
                                                        title="Expand"
                                                        className={groupIconButtonClassName}
                                                    />
                                                </div>
                                                <div>
                                                    {props.group.name}
                                                </div>
                                            </div>
                                      </>
                                    )
                                }
                                return null
                            }
                        }
                        }
                    />
                </>
            default:
                return null
        }
    }

    get items() {
        return [];
    };

    isFilterChanged = (field) => {
        if ((field.fieldType === 'text') && (field.value && field.value !== '')) {
           return true
        }
        if (field.fieldType === 'personPicker' && field.items && field.items.length > 0) {
            return true
        }
        if (field.fieldType === 'dropdown' && ( (field.selectedItems && field.selectedItems.length > 0) || ( field.items && field.items.length > 0 && field.items.filter(I => I.checked).length > 0 ) ) ) {
            return true
        }
        if (field.fieldType === 'asyncPicker' && field.items && field.items.length > 0) {
            return true
        }
        if (field.fieldType === 'registryPicker' && field.selectedItems.length > 0) {
            return true
        }
    }

    getChangedFilters = () => {
        const { fields, calloutFilters } = this.props;
        let count = 0;
        if (fields && calloutFilters) 
        {
            calloutFilters.forEach(F => {
                const field = fields.find(field => field.fieldName === F);
                if (field.fieldType === 'group') {
                    let groupCount = 0
                    field.fields.forEach(groupField => {
                        const gfield = fields.find(field => field.fieldName === groupField);
                        if (this.isFilterChanged(gfield)) {
                            count++;
                            groupCount++;
                        }
                    })
                    field.numberOfSelectedFields = groupCount;
                } else {
                    if (this.isFilterChanged(field)) count++
                }
            })
        }
        return count
    }

    get farItems() {
        const { fields, onHideFilterBar, filters, calloutFilters, onClearAll, hideCloseBarButton, showOpenExtendedPanelButton, onShowExtendedPanelClick, onOpenCalloutPanel } = this.props;
        const items = [];

        if (fields && filters) {
            filters.forEach(F => {
                const element = fields.find(field => field.fieldName === F);
                if (element) {
                    items.push(this.getMinibarField(element));
                }
            })
        }

        if (calloutFilters && fields) {
            items.push({
                showToGuest: true,
                key: 'openCalloutPanel',
                text: 'Open filter search',
                ariaLabel: 'Open filter search',
                onClick: this.onOpenCalloutPanel,
                onRender: (item) => {
                    const filtersNumber = this.getChangedFilters();
                    return <BarContainer
                            filtersNumber={filtersNumber}
                            onClearAll={onClearAll}
                        >
                        {(fields && calloutFilters) && calloutFilters.map(F => {
                            return fields
                                .filter(field => field.fieldName === F)
                                .map((field,idx) => this.getCalloutField(field, idx))
                            })
                        }
                    </BarContainer>
                }
            });
        }

        if (showOpenExtendedPanelButton) {
            items.push({
                showToGuest: true,
                key: 'openPanel',
                text: 'Open extended search',
                ariaLabel: 'Open extended search',
                iconOnly: true,
                iconProps: { iconName: 'DockRight' },
                onClick: onShowExtendedPanelClick,
            });    
        }

        if (!hideCloseBarButton) {
            items.push({
                showToGuest: true,
                key: 'close',
                iconProps: { iconName: 'Cancel' },
                onClick: onHideFilterBar,
            });    
        }

        return items;
    };

    render () {
        const { isGuestUser } = this.props;
        // console.log(this.state);
        return (<CommandBar
            className={filterBarClassName}
            items={isGuestUser ? this.items.filter(I => I.showToGuest) : this.items}
            farItems={this.farItems}
        />)
    }
}