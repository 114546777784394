import { observable, action, computed, flow } from "mobx";

import { ResponseHandle } from "@modules/base/types/ResponseHandle";

import { WorkItemService } from "../../../api/workitems";
import { WorkItemStore } from "./WorkItemStore";

export class WorkItemEditFormStore {
    private workItemService: WorkItemService;
    public parentStore: WorkItemStore;
    private responseHandle?: ResponseHandle;

    @observable public visible: boolean = false;
    @observable public processing: boolean = false;
    @observable public formData: any;
    @observable public formOptions: any;

    constructor(parentStore: WorkItemStore) {
        this.parentStore = parentStore;
        this.workItemService = parentStore.workItemService;
    }

    public show = flow(function* (options) {
        this.visible = true;

        const workItem = options.workItem;
        const productId = workItem.product.id;
        const productService = this.parentStore.rootStore.productStore.productService;
        const schema = yield productService.getProductSchema(productId, workItem.schemaVersion);

        this.formOptions = {
            ...options,
            pageType: options.pageType || `Edit ${workItem.product.name}`,
            schema,
        };

        this.formData = { ...workItem, documents: [] };

        return new Promise<any>((resolve, reject) => {
            this.responseHandle = { resolve, reject };
        });
    });

    @action
    public hide(_) {
        this.visible = false;
        this.processing = false;
        this.formData = null;
        this.formOptions = null;
        this.responseHandle = null;
    }

    @action
    public resolve(success) {
        this.processing = true;
        this.responseHandle.resolve({
            success,
            formData: this.formData,
            formOptions: this.formOptions,
        });
    }

    @action
    public reject(error) {
        this.processing = true;
        this.responseHandle.reject({
            error,
            formData: this.formData,
            formOptions: this.formOptions,
        });
    }

    @computed
    public get isValid() {
        if (!this.formData) {
            return false;
        }

        return (
            this.formData &&
            this.formData.id &&
            this.formData.title &&
            this.formData.portfolio &&
            this.formData.portfolio.id &&
            this.formData.product &&
            this.formData.product.id &&
            this.formData.priority &&
            this.formData.dueDate &&
            this.formData.source &&
            this.formData.requestedBy &&
            this.formData.requestedBy.id &&
            this.formData.documents &&
            this.formData.documents.length >= 0
        );
    }
}
