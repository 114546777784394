import React, { PureComponent } from 'react';
import { cx, css } from 'emotion';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator';
import { PagePartPlaceholder } from  '../../../../../pages/components/elements/PagePartPlaceholder';

import { ViewerPartFooter } from './ViewerPartFooter';
import { ViewerPartHeader } from './ViewerPartHeader';

const rootClassName = cx(
  'cygraph-ImageViewerPart-root',
);

const BodyClassName = cx(css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  & .body-part {
    flex-grow: 2;
  }

  & .part-header {
    margin-bottom : 15px
  }
`)

export class ManualInsightContainer extends PureComponent {
  render () {
    const { className, instance, part, theme, mode, isReady, isConfigured, children  } = this.props;
    return (
      <div className={cx(rootClassName, className, mode)}>
        <PagePartPlaceholder {...this.props} isConfigured={isConfigured}>
          <div style={{
              height: '100%',
              width: '100%'
            }}>
              {!isReady && <div className={cx(css `background: ${theme.themeLight};`)}>
                <Spinner size={SpinnerSize.large} />
              </div>}
              <div id={part.id} className={BodyClassName}>
                <ViewerPartHeader
                  headlineText={part.properties.headlineText}
                  value={part.properties.value}
                  subtext={part.properties.subtext}
                  owner={part.properties.owner}
                  theme={theme}
                />
                <div className={"body-part"}>
                  {children}
                </div>
                <ViewerPartFooter 
                  part={part}
                  instance={instance}
                  theme={theme}
                />
              </div>
          </div>
        </PagePartPlaceholder>
      </div>
   );
  }
}