import React from "react";
import { cx, css } from "emotion";

import {
    PrimaryButton as FabricPrimaryButton,
    IconButton as FabricIconButton,
    DefaultButton as FabricDefaultButton,
    ActionButton as FabricActionButton,
    CommandButton as FabricCommandButton,
} from "office-ui-fabric-react/lib/Button";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";

const rootClassName = cx(
    "cygraph-GhostButton-root",
    css`
        border: 1px solid var(--background-color);
        background-color: transparent;
        padding: 0 10px;
        font-size: 13px;

        & .ms-Button-label {
            font-weight: normal;
        }

        &:hover {
            background-color: var(--white);
            border: 1px solid var(--border-color-dark);
        }
    `
);

const PrimaryButton = (props) => {
    return <FabricPrimaryButton {...props} />;
};

const DefaultButton = (props) => {
    return <FabricDefaultButton {...props} />;
};

const IconButton = (props) => {
    return <FabricIconButton {...props} />;
};

const GhostButton = (props) => {
    return <FabricDefaultButton {...props} className={cx(rootClassName, props.className)} />;
};

const ActionButton = (props) => {
    return <FabricActionButton style={{ height: 32 }} {...props} />;
};

const CommandButton = (props) => {
    return <FabricCommandButton style={{ height: 32 }} {...props} />;
};

const SpinnerButton = (props) => {
    if (props.primary || typeof props.primary === "undefined") {
        return (
            <FabricPrimaryButton
                {...props}
                text={props.loading ? <Spinner size={SpinnerSize.small} {...props.spinnerProps} /> : props.text}
            />
        );
    } else {
        return (
            <FabricDefaultButton
                {...props}
                text={props.loading ? <Spinner size={SpinnerSize.small} {...props.spinnerProps} /> : props.text}
            />
        );
    }
};

export { PrimaryButton, IconButton, DefaultButton, GhostButton, ActionButton, SpinnerButton, CommandButton };
