import React from "react";
import { observer } from "mobx-react";

import { Label } from "@ui/elements/Label";
import { Text } from "@ui/elements/Text";
import { Icon } from "@ui/elements/Icon";

import { ObservableBadge } from "@modules/base/components/ObservableBadge";

export const ProductCardSummary = observer(({ className, product }) => {
    return !product ? null : (
        <div className={className}>
            <r-grid columns="2">
                <r-cell span="2">
                    <Icon
                        iconName={product.icon || "flaticon-005-computer"}
                        styles={{ root: { color: "var(--primary-brand-color)", fontSize: 55 } }}
                    />
                </r-cell>
                <r-cell span="2">
                    <ObservableBadge className="row--xs">About this demand request</ObservableBadge>
                    <Text className="title" variant="xLarge" block>
                        {product.name}
                    </Text>
                </r-cell>
                <r-cell span="2">
                    <div className="row--xs">
                        <div>{product.summary}</div>
                    </div>
                </r-cell>
                <r-cell>
                    <div className="row--xs">
                        <Label>Availability</Label>
                        <div>{product.availability}</div>
                    </div>
                </r-cell>
                <r-cell>
                    <div className="row--xs">
                        <Label>Category</Label>
                        <div>{product.category}</div>
                    </div>
                </r-cell>
                <r-cell>
                    <div className="row--xs">
                        <Label>Provider</Label>
                        <div>{product.provider}</div>
                    </div>
                </r-cell>
            </r-grid>
        </div>
    );
});
