import React, { Component } from "react";
import { observer } from "mobx-react";

import { Text } from "office-ui-fabric-react/lib/Text";
import { MessageBar } from "office-ui-fabric-react/lib/MessageBar";
import { DetailsListLayoutMode, Selection, SelectionMode, ConstrainMode } from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { ContextualMenu } from "office-ui-fabric-react/lib/ContextualMenu";
import { IconButton } from "office-ui-fabric-react/lib/Button";

import { FontIcon } from "@ui/elements/Icon";
import { TooltipHost } from "@ui/elements/Tooltip";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { ListItemReveal } from "@modules/base/components/ListItemReveal";
import { RoutingLink } from "@modules/base/components/RoutingLink";
import { WrappedContent } from "@modules/base/components/WrappedContent";

export const ProductGridView = observer(
    class ProductGridView extends Component {
        constructor(props) {
            super(props);

            this.state = {};

            this._selection = new Selection({
                onSelectionChanged: () => {
                    this.setState({
                        selectionDetails: this._getSelectionDetails(),
                    });
                },
            });

            this._columns = [
                {
                    key: "name",
                    name: "Name",
                    fieldName: "name",
                    minWidth: 200,
                    isRowHeader: true,
                    isResizable: true,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return (
                            <WrappedContent>
                                <RoutingLink
                                    url={`/settings/products/${item.id}`}
                                    onClick={() => this.props.onProductClick && this.props.onProductClick(item)}
                                    onClickEnabled={this.props.onProductClick}
                                >
                                    {item.name}
                                </RoutingLink>
                                <ListItemReveal>
                                    {this.props.onProductMenuClick && (
                                        <IconButton
                                            iconProps={{ iconName: "MoreVertical" }}
                                            onClick={(ev) => this.props.onProductMenuClick(item, ev)}
                                        />
                                    )}
                                </ListItemReveal>
                                <Text variant="small" block>
                                    {item.friendlyName || "No friendly name provided"}
                                </Text>
                            </WrappedContent>
                        );
                    },
                    isPadded: true,
                },
                {
                    key: "stateFlow",
                    name: (
                        <TooltipHost content="Workflow">
                            <FontIcon iconName="VisioDiagram" />
                        </TooltipHost>
                    ),
                    fieldName: "stateFlow",
                    minWidth: 40,
                    maxWidth: 40,
                    isResizable: false,
                    data: "string",
                    onRender: (item) => {
                        return (
                            item.isRequestable &&
                            item.isInternal &&
                            this.props.onStateFlowClick && (
                                <IconButton
                                    iconProps={{ iconName: "VisioDiagram" }}
                                    onClick={(ev) => this.props.onStateFlowClick(item, ev)}
                                />
                            )
                        );
                    },
                    isPadded: false,
                },
                {
                    key: "category",
                    name: "Category",
                    fieldName: "category",
                    minWidth: 200,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return <span>{item.category}</span>;
                    },
                    isPadded: true,
                },
                {
                    key: "provider",
                    name: "Provider",
                    fieldName: "provider",
                    minWidth: 120,
                    maxWidth: 150,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return <span>{item.provider}</span>;
                    },
                    isPadded: true,
                },
                {
                    key: "availability",
                    name: "Availability",
                    fieldName: "availability",
                    minWidth: 130,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return <span>{item.availability}</span>;
                    },
                    isPadded: true,
                },
                {
                    key: "state",
                    name: "Status",
                    fieldName: "state",
                    minWidth: 150,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return <span>{item.state}</span>;
                    },
                    isPadded: true,
                },
            ];
        }

        render() {
            const { products, loading, onPageClick, disableSelection } = this.props;
            const { contextualMenuProps } = this.state;

            return (
                <BoxPanel shadow>
                    <ShimmeredDetailsList
                        items={products}
                        compact={false}
                        columns={this._columns}
                        selectionMode={disableSelection ? SelectionMode.none : SelectionMode.none}
                        getKey={this._getKey}
                        setKey="single"
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.horizontalConstrained}
                        isHeaderVisible={true}
                        selection={this._selection}
                        selectionPreservedOnEmptyClick={true}
                        onItemInvoked={onPageClick}
                        //onItemContextMenu={this._onItemContextMenu}
                        enterModalSelectionOnTouch={true}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        checkButtonAriaLabel="Row checkbox"
                        enableShimmer={loading}
                        removeFadingOverlay={true}
                        shimmerLines={15}
                        ariaLabelForShimmer="Page being fetched"
                        ariaLabelForGrid="Page list"
                    />

                    {!loading && !products.length && <MessageBar>There are no matching products.</MessageBar>}

                    {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}
                </BoxPanel>
            );
        }

        _getKey(item, index) {
            return item ? item.id : index;
        }

        _getSelectionDetails() {
            const selectionCount = this._selection.getSelectedCount();

            switch (selectionCount) {
                case 0:
                    return "No items selected";
                case 1:
                    return "1 item selected: " + this._selection.getSelection()[0].name;
                default:
                    return `${selectionCount} items selected`;
            }
        }

        _onColumnClick(ev, column) {
            console.log(column);
        }

        _onItemContextMenu = (item, index, ev) => {
            const contextualMenuProps = {
                target: ev.target,
                items: [
                    {
                        key: "text",
                        name: `${this._selection.getSelectedCount()} selected`,
                    },
                ],
                onDismiss: () => {
                    this.setState({
                        contextualMenuProps: undefined,
                    });
                },
            };

            if (index > -1) {
                this.setState({
                    contextualMenuProps: contextualMenuProps,
                });
            }

            return false;
        };
    }
);
