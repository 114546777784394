import { observable, flow, action } from "mobx";

import { StateFlowService } from "../../../api/stateflows";
import { StateFlowStore } from "./StateFlowStore";

export class StateFlowSelectionStore {
    public parentStore: StateFlowStore;
    public stateFlowService: StateFlowService;

    @observable public stateFlow: any = null;
    @observable public manifest: any = null;
    @observable public loading: boolean = false;

    constructor(parentStore: StateFlowStore) {
        this.parentStore = parentStore;
        this.stateFlowService = parentStore.stateFlowService;
    }

    @action
    public setStateFlow(stateFlow) {
        this.stateFlow = stateFlow;
    }

    @action
    public setManifest(manifest) {
        this.manifest = manifest;
    }

    public loadStateFlow = flow(function* (id: string, version: string = null) {
        this.loading = true;

        if (!this.stateFlow || this.stateFlow.id !== id || !version || !this.manifest || this.manifest.version !== version) {
            this.stateFlow = null;
            this.manifest = null;
        }

        try {
            this.stateFlow = yield this.stateFlowService.getStateFlow(id);
            this.manifest = yield this.stateFlowService.getStateFlowManifest(this.stateFlow.id, version || this.stateFlow.currentVersion);
        } finally {
            this.loading = false;
        }
    });
}
