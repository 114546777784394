import { netRiskScoresItems } from '@modules/risks/stores/RiskContants';
import { getModuleConfig, ModuleNames } from "../../../../configProvider";

const riskModule = getModuleConfig(ModuleNames.Risks);
const impactArray = Array.from(Array(riskModule.maxImpact || 4).keys()).map(E => E +1);;
const likelihoodArray = Array.from(Array(riskModule.maxLikelihood || 4).keys()).map(E => E +1);;
const impactDropdownOptions = impactArray.map(I => { return {key: I, text: I}});
const likelihoodDropdownOptions = likelihoodArray.map(I => { return {key: I, text: I}});

const riskFilters = (getPortfolios, getSubStatuses, getRiskTypes) => [
    {
        id:'timeLine',
        name:'Date Range',
        type:'dateRangePicker'
    },
    {
        type: 'registryPicker',
        id: 'registry',
    },
    {
        id:'portfolios',
        name:'Portfolios',
        type:'multiselectList',
        getValues: getPortfolios,
    },
    {
        id:'riskTypes',
        name:'Risk Type',
        type:'multiselectList',
        getValues: getRiskTypes,
    },
    {
        id:'subStatus',
        name:'Status',
        type:'multiselectList',
        getValues: getSubStatuses,
    },
    {
        id:'grossScore',
        name:'Gross Score',
        type:'multiselectList',
        values: netRiskScoresItems,
    },
    {
        id:'grossImpact',
        name:'Gross Impact',
        type:'multiselectList',
        values: impactDropdownOptions,
    },
    {
        id:'grossLikelihood',
        name:'Gross Likelihood',
        type:'multiselectList',
        values: likelihoodDropdownOptions,
    },
    {
        id:'netScore',
        name:'Net Score',
        type:'multiselectList',
        values: netRiskScoresItems,
    },
    {
        id:'netImpact',
        name:'Net Impact',
        type:'multiselectList',
        values: impactDropdownOptions,
    },
    {
        id:'netLikelihood',
        name:'Net Likelihood',
        type:'multiselectList',
        values: likelihoodDropdownOptions,
    },
]

export { riskFilters };

