import { observable, flow, computed, action } from "mobx";

import { PageService } from '../../../api/pages';
import { PageInstanceStore } from "./PageInstanceStore";
import { PagePermissionStore } from "./PagePermissionStore";
import { PageSelectionStore } from "./PageSelectionStore";

export class PageSharingStore {

    public pageService: PageService;
    public parentStore: PageInstanceStore;
    public selectionStore: PageSelectionStore;
    public permissionStore: PagePermissionStore;

    @observable public saving: boolean = false;
    @observable public formData: any;
    @observable public formOptions: any;
    @observable public sharingVisible: boolean = false;
    @observable public scopesVisible: boolean = false;
    @observable public scopes: any[] = [];
    @observable public error: any;

    constructor(parentStore: PageInstanceStore) {
        this.parentStore = parentStore;
        this.pageService = parentStore.pageService;
        this.selectionStore = parentStore.selectionStore;
        this.permissionStore = parentStore.permissionStore;
    }

    @computed 
    public get isValid() {
        return this.formData && this.formData.principals && this.formData.principals.length > 0 
            && this.formData.roles && this.formData.roles.length > 0;
    }

    @action 
    public showScopes(options) {
        this.scopesVisible = true;
    }

    @action 
    public hideScopes() {
        this.scopesVisible = false;
    }

    @action 
    public showSharing(options) {
        this.sharingVisible = true;
        this.formData = options.share || { roles: ['Reader'] };
        this.formOptions = { roles: options.roles, pageTitle: options.pageTitle };
    }

    @action 
    public hideSharing() {
        this.sharingVisible = false;
        this.formData = null;
        this.formOptions = null;
        this.error = null;
    }

    @action 
    public reset() {
        this.scopes = [];
    }

    @computed 
    public get principals() {
        const principals = [];
        this.scopes.forEach((scope) => {
            scope.permissions.forEach(permission => {
                if(principals.findIndex(p => p.id === permission.principal.id) === -1) {
                    principals.push(permission.principal);
                }
            });
        });
        return principals;
    }

    public loadSharingScopes = flow(function*() {
        this.saving = true;
        const { page, permission } = this.selectionStore;
        
        try {
            if(permission.canShare) {
                this.scopes = yield this.pageService.getPageSharing(page.id);
            }
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.saving = false;
        }
    });

    public onGrant = flow(function*() {
        this.saving = true;
        const { page, permission } = this.selectionStore;

        try {
            if(permission.canShare && this.isValid) {
                const actions = [];
            
                this.formData.principals.forEach(p => {
                    this.formData.roles.forEach(role => {
                        actions.push({
                            securableId: page.id,
                            principalId: p.id,
                            role: role,
                            message: this.formData.message,
                        });
                    });
                });

                this.scopes = yield this.pageService.grantPageSharing(page.id, actions);
            }
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.saving = false;
        }
    });

    public onRevoke = flow(function*(action) {
        this.saving = true;
        const { page, permission } = this.selectionStore;

        try {
            if(permission.canShare) {
                this.scopes = yield this.pageService.revokePageSharing(page.id, [
                    {
                        securableId: page.id,
                        principalId: action.principal.id,
                    }
                ]);
            }
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.saving = false;
        }
    });
}
