import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { ControlPicker as StatelessControlPicker } from '../../components/core/ControlPicker';

import { ControlPickerStore } from '../../stores/ControlPickerStore';

export const ControlPicker = inject('controlStore')(observer(
    class ControlPicker extends Component {

        constructor(props) {
            super(props);

            this.state = { 
                pickerStore: new ControlPickerStore(this.props.controlStore),
            }
        }

        componentWillMount() {
            const { pickerStore } = this.state;
            const { multiple, selected, selectedId, selectedIds } = this.props;

            if(typeof(selected) !== 'undefined') {
                pickerStore.setSelected(multiple ? (selected || []) : (selected ? [selected] : []));
            } else if(!multiple) {
                pickerStore.setSelectedWithId(selectedId);
            } else {
                pickerStore.setSelectedWithIds(selectedIds);
            }
        }

        componentWillReceiveProps(nextProps) {
            const { pickerStore } = this.state;
            const { multiple, selected, selectedId, selectedIds } = nextProps;

            if(typeof(selected) !== 'undefined') {
                if(selected != this.props.selected) {
                    pickerStore.setSelected(multiple ? (selected || []) : (selected ? [selected] : []));
                }
            } else if(!multiple) {
                if(selectedId != this.props.selectedId) {
                    pickerStore.setSelectedWithId(selectedId);
                }
            } else {
                if(selectedIds != this.props.selectedIds) {
                    pickerStore.setSelectedWithIds(selectedIds);
                }
            }
        }
    
        render () {
            const { pickerStore } = this.state;
            const { className, label, required, placeholder, readOnly, multiple, itemCount, disabled, onChange } = this.props;
            const { selectedControls, loading } = pickerStore;
            
            const selected = multiple ? selectedControls : (selectedControls.length ? selectedControls[0] : null);

            return (
                <StatelessControlPicker
                    {...{ className, label, placeholder, readOnly, required, itemCount, disabled, multiple, loading }}
                    selected={selected}
                    onFilter={(text, current, limit) => {
                        return pickerStore.loadControls({ keywords: text });
                    }}
                    onSelected={(control) => {                       
                        pickerStore.setSelected(multiple ? (control || []) : (control ? [control] : []));
                        if(onChange) {
                            onChange(control);
                        }
                    }} />
            );
        }
    }
));
