import React, { Component } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import * as moment from "moment";
import Moment from "react-moment";

import { TooltipHost, TooltipDelay, DirectionalHint } from "office-ui-fabric-react/lib/Tooltip";

const rootClassName = cx(
    "cygraph-DueIndicator-root",
    css`
        & .container {
            display: inline-block;
            width: 30px;
            text-align: center;
            border-radius: 2px;
            font-size: 10px;
            color: #fff;
            padding: 1px;

            &.past {
                background: var(--red-6);
            }

            &.soon {
                background: var(--orange-6);
            }

            &.future {
                background: var(--green-6);
            }

            &.completed {
                background: var(--grey-6);
            }
        }
    `
);

export const DueIndicator = observer(
    class DueIndicator extends Component {
        render() {
            const { className, children, hideTooltip, dueDate, closedDate } = this.props;

            if (!dueDate) {
                return null;
            }

            const strings = this.props.strings || {
                past: "Overdue since",
                soon: "Due soon",
                future: "Due on",
                closed: "Was due on",
            };

            const refDate = closedDate ? moment.utc(closedDate) : moment.utc();
            const startOfDueDate = moment.utc(dueDate).startOf("day");
            const diffInDays = startOfDueDate.diff(refDate, "days");
            const diffInMonths = startOfDueDate.diff(refDate, "months");
            const precision = Math.abs(diffInMonths) > 0 ? "m" : "d";
            const level = diffInDays < 0 ? "past" : diffInDays < 7 ? "soon" : "future";

            const tooltipProps = {
                onRenderContent: () => (
                    <div>
                        <span>
                            {closedDate ? strings.closed : strings[level]} <Moment utc date={dueDate} format="DD/MM/YYYY" />
                        </span>
                        {closedDate && (
                            <span>
                                , Closed <Moment utc date={closedDate} format="DD/MM/YYYY" />
                            </span>
                        )}
                        <span>
                            {" "}
                            ({precision === "d" ? diffInDays : diffInMonths}
                            {precision})
                        </span>
                    </div>
                ),
            };

            return (
                <div className={cx(rootClassName, className)}>
                    <TooltipHost
                        tooltipProps={tooltipProps}
                        calloutProps={{ hidden: !!hideTooltip }}
                        delay={TooltipDelay.medium}
                        directionalHint={DirectionalHint.bottomLeftEdge}
                    >
                        <div className={cx("container", closedDate && "completed", level, precision)}>
                            {precision === "d" ? diffInDays : diffInMonths}
                            {precision}
                        </div>
                    </TooltipHost>
                    {children}
                </div>
            );
        }
    }
);
