import { observable, action, computed, flow } from "mobx";
import { BaseChartStoreClass } from './BaseChartStoreClass';

export class RadarStore extends BaseChartStoreClass {

/**
 *  Expected format
[
        {
            name: 'Open',
            values: [
                {
                    x: "2020-01-01",
                    value: 30
                },
                {
                    x: "2020-01-02",
                    value: 20
                },
                {
                    x: "2020-01-03",
                    value: 50
                },
                {
                    x: "2020-01-04",
                    value: 30
                },
                {
                    x: "2020-01-05",
                    value: 20
                },
                {
                    x: "2020-01-06",
                    value: 50
                }, 
            ],
            type: 'bar',
        },
        {
            name: 'Closed',
            values: [
                {
                    x: "2020-01-01",
                    value: 30
                },
                {
                    x: "2020-01-03",
                    value: 10
                },
                {
                    x: "2020-01-04",
                    value: 30
                },
                {
                    x: "2020-01-02",
                    value: 18
                },
                {
                    x: "2020-01-05",
                    value: 0
                },
                {
                    x: "2020-01-06",
                    value: 14
                }, 
            ],
            type: 'bar',
        },
        {
            name: 'Total',
            values: [
                {
                    x: "2020-01-01",
                    value: 50
                },
                {
                    x: "2020-01-02",
                    value: 71
                },
                {
                    x: "2020-01-03",
                    value: 92
                },
                {
                    x: "2020-01-04",
                    value: 150
                },
                {
                    x: "2020-01-05",
                    value: 210
                },
                {
                    x: "2020-01-06",
                    value: 240
                }, 
            ],
            type: 'spline',
        }
    ]
 */

    private onClicks = {};
    public onClickCallbackExists = false;

    @computed public get data() {
        this.onClicks = {};
        let data = [];
        if (this.rawData.length > 0) {
            data = this.rawData
                .filter(D => D.dataSource.length > 0)
                .map((D,i) => {
                    // debugger
                    return {
                        label: D.label,
                        seriesId: `serie_${i}`,
                        showCircle: true,
                        circleHighlight: true,
                        fill: 'royalblue',
                        data: D.dataSource.map((DS,j) => {
                            return {axis: `con_${j}`, value: DS[D.valueField]}
                        })
                    }    
                })
        }
        return data || []
    }

    public getLabels():Array<any> {
        let axis = [];
        if (this.rawData[0].dataSource.length > 0) {
            axis = this.rawData[0].dataSource.map((D,i) => {
                return {label: D[this.rawData[0].axisField], axisId: `con_${i}`, axisValueMax: 1, axisValueMin: 0}
            })
        }
        return axis
    }

    public getChartData(element) {
        return {
            chartRootName: 'example',
            data: this.data,
            dims: {
              width: 550,
              height: 500,
            },
            showLegend: true,
            rootElementId: 'chart',
            axisConfig: this.getLabels(),
          }
    }

    public csvData() {
        // const numberOfValues = this.data[0].values.length;
        // const labels = Array.from(this.getXLabels());
        const csvValues = [];
        // const headerRow = [''];
        // this.rawData[0].dataSource.forEach((D,i) => {
        //     headerRow.push(D[this.rawData[0].axisField])
        // })
        // csvValues.push(headerRow);
        // console.log(this.data)
        if (this.data.length > 0) {
            this.data
                .forEach((D, i) => {                    
                    const csvRow = {};
                    csvRow['Series'] = D.label;
                    D.data.forEach((DS, j) => {
                        // debugger
                        const axe = this.getLabels().find(L => L.axisId === DS.axis);
                        csvRow[axe.label] = DS.value;
                    })
                    csvValues.push(csvRow);
                })
        }
        // console.table(csvValues)
        return csvValues;
    }

}