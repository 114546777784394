import React from 'react';

import { MessageBar } from 'office-ui-fabric-react/lib/MessageBar';
import { DetailsListLayoutMode, SelectionMode, ConstrainMode } from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';

import { BoxPanel } from '../../../base/components/BoxPanel';
import { RoutingLink } from '../../../base/components/RoutingLink';
import { WrappedContent } from '../../../base/components/WrappedContent';

import { templates } from '../../stores/RiskContants';

export const RiskTypeGridView = (props) =>  {
  const { riskTypes, loading } = props

  const _columns = [
      {
        key: 'name',
        name: 'Name',
        fieldName: 'name',
        minWidth: 200,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        onRender: (item) => {
          return (
            <WrappedContent>
              <RoutingLink url={`/settings/risk-types/${item.id}`}> {item.name} </RoutingLink>
              {/* <ListItemReveal>
                { this.props.onProductMenuClick && <IconButton iconProps={{ iconName: 'MoreVertical' }} onClick={(ev) => this.props.onProductMenuClick(item, ev)} /> }
              </ListItemReveal> */}
            </WrappedContent>
            );
        },
        isPadded: true
      },
      {
        key: 'template',
        name: 'Template',
        fieldName: 'template',
        minWidth: 180,
        isResizable: false,
        data: 'string',
        onRender: (type) => {
          return (<span>{type.template && (templates.find(T => T.key === type.template)) ? (templates.find(T => T.key === type.template)).text : null}</span>);
        },
        isPadded: true
      },
      {
        key: 'state',
        name: 'State',
        fieldName: 'state',
        minWidth: 120,
        maxWidth: 150,
        isResizable: false,
        data: 'string',
        onRender: (item) => {
          return (<span>{item.state}</span>);
        },
        isPadded: true
      },
      // {
      //   key: 'createdBy',
      //   name: 'Created By',
      //   fieldName: 'createdBy',
      //   minWidth: 150,
      //   isResizable: false,
      //   data: 'string',
      //   onRender: (item) => {
      //     return (<Stack>
      //       <PhotoPersona 
      //         principalId={item.createdBy.id}
      //         text={item.createdBy.name} 
      //         showSecondaryText={true} 
      //         secondaryText={item.createdBy.jobTitle} 
      //       />
      //         <Moment date={item.created}  />
      //       </Stack>);
      //   },
      //   isPadded: true
      // },
  ];

  const _getKey = (item, index) => {
    return item ? item.id : index;
  }

  return (
    <BoxPanel shadow>
      <ShimmeredDetailsList
        items={riskTypes}
        compact={false}
        columns={_columns}
        getKey={_getKey}
        setKey="single"
        selectionMode={SelectionMode.none}
        layoutMode={DetailsListLayoutMode.justified}
        constrainMode={ConstrainMode.horizontalConstrained}
        isHeaderVisible={true}
        enterModalSelectionOnTouch={true}
        ariaLabelForSelectionColumn="Toggle selection"
        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
        checkButtonAriaLabel="Row checkbox"
        enableShimmer={loading}
        removeFadingOverlay={true}
        shimmerLines={15}
        ariaLabelForShimmer="Page being fetched"
        ariaLabelForGrid="Page list"
      />
      
      { ! loading && !riskTypes.length && (
        <MessageBar>
          There are no matching types.
        </MessageBar>
      )}

      {/* {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />} */}
    </BoxPanel> 
  );

}
