import { observable, action, computed, flow } from "mobx";

import { WorkItemService } from "../../../api/workitems";
import { WorkItemStore } from "./WorkItemStore";

import { PaginationStore } from "../../base/PaginationStore";
import { PrincipalContextStore } from "../../identity/stores/PrincipalContextStore";
import { isUserAuthenticated, isUserInAllRoles } from "../../identity/stores/PrincipalContextUtilities";

export class WorkItemDashboardStore {
    private workItemService: WorkItemService;
    public parentStore: WorkItemStore;
    public principalContext: PrincipalContextStore;

    @observable public workItems: any[] = [];
    @observable public pagination: PaginationStore;
    @observable public loading: boolean = false;
    @observable public tab: string;
    @observable public tabs: any = {
        "assigned-to-me": {
            title: "Assigned to me",
            roles: ["agent"],
            templateId: "7D4646F6-B9E7-4B40-B0B7-AEC3B7337DC9",
        },
        "assigned-to-my-team": {
            title: "Assigned to my team",
            roles: ["agent"],
            templateId: "CEBDE6E1-0CC1-4D07-83DD-1D69C35D6A11",
        },
        "requested-by-me": {
            title: "Requested by me",
            roles: [],
            templateId: "C93F91A3-AC51-4F9E-A646-616A6D550F98",
        },
        "im-a-stakeholder": {
            title: "I'm a stakeholder",
            roles: [],
            templateId: "8C3F0BCA-C904-4143-8C2D-F660FDAE3611",
        },
    };
    @observable public error: any;

    @observable public breadcrumb: any[] = [
        { text: "Demand Management", key: "demand", onClick: () => this._goToUrl(`/demand/dashboard`) },
        { text: "Demand Dashboard", key: "dashboard", isCurrentItem: true },
    ];

    constructor(parentStore: WorkItemStore) {
        this.parentStore = parentStore;
        this.workItemService = parentStore.workItemService;
        this.principalContext = parentStore.rootStore.principalContext;
        this.pagination = new PaginationStore();
        this.pagination.itemsPerPage = 10;
    }

    @computed
    public get isAgent() {
        const user = this.principalContext.current;
        return user && isUserInAllRoles(user, ["agent"]);
    }

    @computed
    public get myTabs() {
        const user = this.principalContext.current;
        if (!isUserAuthenticated(user)) {
            return {};
        }

        return Object.entries(this.tabs).reduce((filtered, pair: any) => {
            const [key, value] = pair;
            if (isUserInAllRoles(user, value.roles)) {
                filtered[key] = value;
            }
            return filtered;
        }, {});
    }

    public loadWorkItems = flow(function* (query: any) {
        this.loading = true;
        this.workItems = [];
        const options = Object.assign({ page: 1, keywords: null }, query);

        try {
            const result = yield this.workItemService.searchWorkItems({
                pageSize: this.pagination.itemsPerPage,
                startIndex: (options.page - 1) * this.pagination.itemsPerPage,
                query: {
                    templateId: this.tabs[this.tab].templateId,
                },
            });
            this.workItems = result.items;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
        } catch (e) {
            console.error(e);
            this.error = e;
        } finally {
            this.loading = false;
        }
    });

    @action
    public selectTab(tab: string) {
        if (this.tab !== tab) {
            this.tab = tab;
            this.loadWorkItems({ tab: tab });
        }
    }

    _goToUrl = (url) => {
        if (url) {
            this.parentStore.rootStore.routing.push(url);
        }
    };
}
