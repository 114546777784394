import React from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { Text } from "@ui/elements/Text";
import { Stack } from "@ui/elements/Stack";
import { IconButton } from "@ui/elements/Button";
import { TooltipHost } from "@ui/elements/Tooltip";

import { ObservableBadge } from "@modules/base/components/ObservableBadge";
import { ActionSet } from "@modules/base/components/ActionSet";

const rootClassName = cx(
    "cygraph-StrategicControlHeader-root",
    css`
        margin: var(--spacing-s) 0px;

        .title {
            margin: var(--spacing-xs) 0;
        }

        & .description {
            color: var(--text-color-light);
            font-size: var(--font-size-xs);
        }

        & .back {
            width: 45px;
            & i {
                font-size: 30px;
                height: 30px;
                line-height: 30px;
                margin-right: 10px;
            }
        }
    `
);

export const TacticalControlHeader = observer((props) => {
    const { control, onNavigateBack, canReview, onReview } = props;

    const items = [
        {
            key: "review",
            name: "Test Control",
            primary: true,
            iconProps: { iconName: "ThumbnailView" },
            disabled: !onReview,
            onClick: () => onReview({ control }),
        },
    ];
    const overflowItems = [];

    return (
        <div className={rootClassName}>
            <Stack horizontal>
                {onNavigateBack && (
                    <Stack.Item>
                        <TooltipHost content="Navigate back to all strategic controls">
                            <IconButton
                                className="back"
                                iconProps={{ iconName: "NavigateBack" }}
                                title="Navigate back"
                                ariaLabel="Navigate back"
                                onClick={onNavigateBack}
                            />
                        </TooltipHost>
                    </Stack.Item>
                )}
                <Stack.Item grow>
                    <Stack horizontal horizontalAlign="space-between">
                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                            <ObservableBadge>Tactical Control</ObservableBadge>
                        </Stack>
                        <ActionSet items={items} overflowItems={overflowItems} />
                    </Stack>
                </Stack.Item>
            </Stack>
            <Text className="title" variant="xLarge" block>
                {control.code} - {control.name}
            </Text>
            <Text className="description" block>
                {control.description}
            </Text>
        </div>
    );
});
