import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { ChoiceGroup } from 'office-ui-fabric-react/lib/ChoiceGroup';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';

export const SectionEditForm = observer(
    class SectionEditForm extends Component {
        
        render () {
            const { formData, layouts } = this.props;

            return (
                <div>
                    <r-grid columns="2">
                        <r-cell span="2">
                            <div>
                                <ChoiceGroup
                                    required
                                    label="Layout"
                                    selectedKey={formData.layout}
                                    onChange={(ev, op) => {
                                        if(op) {
                                            formData.layout = op.key;
                                        }
                                    }}
                                    options={Object.keys(layouts).map(layout => (
                                        {
                                            key: layout,
                                            iconProps: { iconName: layouts[layout].icon },
                                            text: layouts[layout].name
                                        }
                                    ))}/>     
                            </div>
                        </r-cell>
                        <r-cell>
                            <div>
                                <Dropdown
                                    required
                                    label="Theme"
                                    selectedKey={formData.theme}
                                    onChange={(ev, op) => {
                                        if(op) {
                                            formData.theme = op.key;
                                        }
                                    }}
                                    options={[
                                        {
                                            key: 'none',
                                            text: 'None'
                                        },
                                        {
                                            key: 'transparent',
                                            text: 'Transparent'
                                        },
                                        {
                                            key: 'neutral',
                                            text: 'Neutral'
                                        },
                                        {
                                            key: 'soft',
                                            text: 'Soft',
                                        },
                                        {
                                            key: 'strong',
                                            text: 'Strong',
                                        }
                                    ]}/>     
                            </div>
                        </r-cell>
                        <r-cell>
                            <span>&nbsp;</span>
                        </r-cell>
                        <r-cell>
                            <div>
                                <Toggle 
                                    label="Hidden"
                                    checked={formData.hidden} 
                                    onChange={(ev, checked) => {
                                        formData.hidden = checked;
                                    }} />
                            </div>
                        </r-cell>
                    </r-grid>
                </div>
            );
        }
    }
);