import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";

import { ObservablePagination } from "@modules/base/components/ObservablePagination";
import { VersionGridView } from "../../components/versions/VersionGridView";

export const PatternVersionsTab = inject(
    "patternStore",
    "modellingStore",
    "routing"
)(
    withRouter(
        observer(
            class PatternVersionsTab extends Component {
                componentWillMount() {
                    const { modellingStore, match } = this.props;
                    const { versionStore } = modellingStore;

                    return versionStore.loadVersions({ id: match.params.id });
                }

                componentWillReceiveProps(nextProps) {
                    const { modellingStore, match } = nextProps;
                    const { versionStore } = modellingStore;

                    return versionStore.loadVersions({ id: match.params.id });
                }

                render() {
                    const { patternStore, modellingStore, match } = this.props;
                    const { versionStore } = modellingStore;

                    return (
                        <div>
                            <VersionGridView
                                pattern={patternStore.pattern}
                                manifest={patternStore.manifest}
                                loading={versionStore.loading}
                                versions={versionStore.versions}
                            />

                            <ObservablePagination
                                {...versionStore.pagination}
                                onPageChange={(index) => {
                                    versionStore.loadVersions({ id: match.params.id, page: index + 1 });
                                }}
                            />
                        </div>
                    );
                }
            }
        )
    )
);
