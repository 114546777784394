import React from "react";
import { observer } from "mobx-react";

import { FontIcon } from "@ui/elements/Icon";

const EntityIcons = {
    "work-item": {
        iconName: "ExternalTFVC",
        color: "var(--green-8)",
        backgroundColor: "var(--green-1)",
    },
    assessment: {
        iconName: "WorkItem",
        color: "var(--purple-8)",
        backgroundColor: "var(--purple-1)",
    },
    risk: {
        iconName: "ReportHacked",
        color: "var(--red-8)",
        backgroundColor: "var(--red-1)",
    },
    exception: {
        iconName: "Shield",
        color: "var(--orange-8)",
        backgroundColor: "var(--orange-1)",
    },
    help: {
        iconName: "Unknown",
        color: "var(--blue-8)",
        backgroundColor: "var(--blue-1)",
    },
};
export const EntityIcon = observer(({ type, size }) => {
    const { iconName, color, backgroundColor } = EntityIcons[type];
    return (
        <div
            style={{
                backgroundColor,
                height: size,
                width: size,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "var(--border-radius)",
                opacity: 0.8,
            }}
        >
            <FontIcon iconName={iconName} style={{ color, fontSize: (30 / 55) * size }} />
        </div>
    );
});
