import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { RiskGridView } from "@modules/risks/components/core/RiskGridView";

export const AssessmentRisksTab = observer(({ assessment }) => {
    const { assessmentStore } = useStores();
    const { riskStore } = assessmentStore;
    const { loading, risks } = riskStore;
    const assessmentId = assessment ? assessment.id : null;

    useEffect(() => {
        if (assessmentId) {
            riskStore.loadAssessmentRisks(assessmentId);
        }
    }, [assessmentId, riskStore]);

    return (
        <div>
            <RiskGridView loading={loading} disableSelection={true} risks={risks} />
        </div>
    );
});
