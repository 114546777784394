import React, { PureComponent, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { cx, css } from 'emotion';

import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { IconButton } from 'office-ui-fabric-react/lib/Button';

import { exportToCSV } from '../../base/exportToCsv';

const calloutProps = { gapSpace: 0 };
const hostStyles = { root: { display: 'inline-block' } };

export const TaskListViewerActions = observer((props) => {
      const { className, instance, part } = props;
      const { showCSV } = part.properties;
      const tooltipCSVId = `tooltip_${part.id}_csv`;
      const partData = instance.partDataStore.data[part.id];

      return (
        <div className={cx(className, 'page--part-action')}>
          {showCSV && <TooltipHost
                content={`Export as CSV`}
                id={tooltipCSVId}
                calloutProps={calloutProps}
                styles={hostStyles}
              ><IconButton 
                iconProps={{iconName: 'AnalyticsView'}}
                aria-describedby={tooltipCSVId}
                onClick={() => {
                  const data = instance.selectionStore.data.find(D => {
                    return D.id === part.properties.dataSourceId
                  });
                  // part.properties.selectedFields
                  const csvData = data && data.data ? data.data.map(D => {
                    return {
                      'Id': D.id,
                      'Title': D.title,
                      'Description': D.description,
                      'Due Date': D.dueDate,
                      'Status': D.status,
                      'Mode': D.mode,
                      'Use Case': D.useCase,
                    }
                  }) : [];
                  exportToCSV(csvData ? csvData : null, partData ? partData.csvFilename : null)}
                }
              /></TooltipHost> }
        </div>
      );
    });