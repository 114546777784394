import React, { Component } from "react";
import { observer } from "mobx-react";

import { Label } from "office-ui-fabric-react/lib/Label";
import { PersonaSize } from "office-ui-fabric-react/lib/Persona";

import { UserHoverCard } from "@modules/identity/containers/utilities/UserHoverCard";
import { PhotoPersona } from "@modules/identity/containers/utilities/PhotoPersona";

export const RiskContactList = observer(
    class RiskContactList extends Component {
        render() {
            const { risk, lifecycle } = this.props;

            const contacts = [];
            contacts.push({
                label: "Project Business Contact",
                contact: lifecycle.businessContact,
            });
            contacts.push({
                label: "Project Technical Contact",
                contact: lifecycle.technicalContact,
            });
            contacts.push({
                label: "Portfolio Business Contact",
                contact: risk.portfolioContact,
            });
            contacts.push({
                label: "Portfolio Technical Contact",
                contact: risk.portfolioTechnical,
            });

            return (
                <div>
                    <r-grid columns-l="2" columns="2" columns-s="1">
                        {/* className={css("margin-left: 22px")} */}
                        {contacts.map((contact, i) => (
                            <r-cell span="1" key={i}>
                                <div className="row--s">
                                    <Label>{contact.label}</Label>
                                    {contact.contact ? (
                                        <UserHoverCard principal={contact.contact}>
                                            <PhotoPersona
                                                principalId={contact.contact ? contact.contact.id : null}
                                                size={PersonaSize.size32}
                                                text={contact.contact.name}
                                                showSecondaryText={true}
                                                secondaryText={contact.contact.jobTitle}
                                                tertiaryText={contact.contact.email}
                                            />
                                        </UserHoverCard>
                                    ) : (
                                        "Not Set"
                                    )}
                                </div>
                            </r-cell>
                        ))}
                    </r-grid>
                </div>
            );
        }
    }
);
