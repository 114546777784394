import { ServiceBase, Constants } from "../graph/serviceBase";

export class RiskService extends ServiceBase {
    public async getRisks(options) {
        return this.get(`${Constants.baseUrl}/risks?pageSize=${options.pageSize}&startIndex=${options.startIndex}&keywords=${options.keywords || ""}`);
    }

    public async searchRisks(options) {
        return this.post(`${Constants.baseUrl}/risks/search`, options);
    }

    public async exportRisks(options) {
        return this.post(`${Constants.baseUrl}/risks/export`, options);
    }

    public async getRisk(id) {
        return this.get(`${Constants.baseUrl}/risks/${id}`);
    }

    public async getRiskLifecycle(id) {
        return this.get(`${Constants.baseUrl}/risks/${id}/lifecycle`);
    }

    public async getRiskLifecycles(id) {
        return this.get(`${Constants.baseUrl}/risks/${id}/lifecycles`);
    }

    public async createRisk(risk) {
        return this.post(`${Constants.baseUrl}/risks`, risk);
    }

    public async updateRisk(risk) {
        return this.put(`${Constants.baseUrl}/risks/${risk.id}`, risk);
    }

    public async assignRisk(risk) {
        return this.post(`${Constants.baseUrl}/risks/${risk.id}/assign`, risk);
    }

    public async submitRisk(data) {
        return this.post(`${Constants.baseUrl}/risks/${data.id}/submit`, data);
    }

    public async monitorRisk(data) {
        return this.post(`${Constants.baseUrl}/risks/${data.id}/monitor`, data);
    }

    public async closeRisk(data) {
        return this.post(`${Constants.baseUrl}/risks/${data.id}/close`, data);
    }

    public async cancelRisk(data) {
        return this.post(`${Constants.baseUrl}/risks/${data.id}/cancel`, data);
    }

    public async transferRisk(risk) {
        return this.post(`${Constants.baseUrl}/risks/${risk.id}/transfer`, risk);
    }

    public async flowRisk(risk) {
        return this.post(`${Constants.baseUrl}/risks/${risk.id}/flow`, risk);
    }

    public async deleteRisk(data) {
        return this.delete(`${Constants.baseUrl}/risks/${data.id}`, data);
    }

    public async getTypes(query) {
        return this.get(`${Constants.baseUrl}/risk-types?${query && query.keywords ? `keywords=${query.keywords}`:``}`);
    }

    public async getType(id) {
        return this.get(`${Constants.baseUrl}/risk-types/${id}`);
    }

    public async createType(type) {
        return this.post(`${Constants.baseUrl}/risk-types`, type);
    }

    public async updateType(type) {
        return this.put(`${Constants.baseUrl}/risk-types/${type.id}`, type);
    }

    public async deleteType(id) {
        return this.delete(`${Constants.baseUrl}/risk-types/${id}`, {});
    }

    public async getCategories() {
        return this.get(`${Constants.baseUrl}/risk-categories`);
    }

    public async getCategory(id) {
        return this.get(`${Constants.baseUrl}/risk-categories/${id}`);
    }

    public async createCategory(type) {
        return this.post(`${Constants.baseUrl}/risk-categories`, type);
    }

    public async updateCategory(type) {
        return this.put(`${Constants.baseUrl}/risk-categories/${type.id}`, type);
    }

    public async deleteCategory(id) {
        return this.delete(`${Constants.baseUrl}/risk-categories/${id}`, {});
    }

    public async getRegistries() {
        return this.get(`${Constants.baseUrl}/risk-registries`);
    }

    public async getRegistrySummary() {
        return this.get(`${Constants.baseUrl}/risk-registries/summary`);
    }

    public async getRegistry(id) {
        return this.get(`${Constants.baseUrl}/risk-registries/${id}`);
    }

    public async createRegistry(risk) {
        return this.post(`${Constants.baseUrl}/risk-registries`, risk);
    }

    public async updateRegistry(risk) {
        return this.put(`${Constants.baseUrl}/risk-registries/${risk.id}`, risk);
    }

    public async deleteRegistry(id) {
        return this.delete(`${Constants.baseUrl}/risk-registries/${id}`, {});
    }

    public async getRiskAssessment(id) {
        return this.get(`${Constants.baseUrl}/risks/${id}/assessment`);
    }

    public async getRiskReviews(id) {
        return this.get(`${Constants.baseUrl}/risks/${id}/lifecycle/reviews`);
    }

    public async createRiskReview(id, review) {
        return this.post(`${Constants.baseUrl}/risks/${id}/lifecycle/reviews`, review);
    }

    public async getRiskTreatments(id) {
        return this.get(`${Constants.baseUrl}/risks/${id}/lifecycle/treatments`);
    }

    public async getRiskControls(id) {
        return this.get(`${Constants.baseUrl}/risks/${id}/lifecycle/controls`);
    }

    public async getRiskActivities(riskId: string, continuationToken: string = null, maxResults: number = 25) {
        const requestContinuationToken = continuationToken ? `&continuationToken=${continuationToken}` : "";
        return this.get(`${Constants.baseUrl}/risks/${riskId}/activities?maxResults=${maxResults}&${requestContinuationToken}`);
    }

    public async getRiskDocuments(riskId) {
        return this.get(`${Constants.baseUrl}/risks/${riskId}/lifecycle/documents`);
    }

    // public async getRiskDocument(workItemId, id) {
    //     return this.get(`${Contants.baseUrl}/risks/${workItemId}/lifecycle/documents/${id}`);
    // }

    public async downloadRiskDocument(riskId, id) {
        return this.getBlob(`${Constants.baseUrl}/risks/${riskId}/lifecycle/documents/${id}/content`);
    }

    //public async createRiskDocument(document) {
    //    return this.post(`${Contants.baseUrl}/risks/${document.sourceId}/lifecycle/documents`, document);
    //}

    // public async updateRiskDocument(riskId, document) {
    //     return this.put(`${Contants.baseUrl}/risks/${riskId}/lifecycle/documents/${document.id}`, document);
    // }

    public async uploadRiskDocument(riskId, document) {
        const formData = new FormData();
        formData.set("internalOnly", document.internalOnly);
        formData.set("mode", document.mode);
        if (document.notes) {
            formData.set("notes", document.notes);
        }

        formData.set("entity", document.file);
        if (document.name) {
            formData.set("name", document.name);
        }

        if (!document.id) {
            return this.postForm(`${Constants.baseUrl}/risks/${riskId}/lifecycle/documents/upload`, formData);
        }

        formData.append("id", document.id);
        return this.putForm(`${Constants.baseUrl}/risks/${riskId}/lifecycle/documents/${document.id}/upload`, formData);
    }

    public async getRiskComments(riskId: string) {
        return this.get(`${Constants.baseUrl}/risks/${riskId}/lifecycle/comments`);
    }

    public async getRiskComment(riskId: string, id: string) {
        return this.get(`${Constants.baseUrl}/risks/${riskId}/lifecycle/comments/${id}`);
    }

    public async createRiskComment(data: any) {
        return this.post(`${Constants.baseUrl}/risks/${data.riskId}/lifecycle/comments`, data);
    }

    public async updateRiskComment(data: any) {
        return this.put(`${Constants.baseUrl}/risks/${data.riskId}/lifecycle/comments/${data.id}`, data);
    }

    public async removeRiskComment(data: any) {
        return this.delete(`${Constants.baseUrl}/risks/${data.riskId}/lifecycle/comments/${data.id}`, {});
    }

    public async getSubStatuses(template) {
        return this.get(`${Constants.baseUrl}/risks/sub-statuses?template=${template || ""}`);
    }

    public async getRisksGroupByCount(query) {
        return this.post(`${Constants.baseUrl}/risks/group-by-count`, query);
    }
}
