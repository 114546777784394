import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import { BoxPanel } from '../../base/components/BoxPanel';

export const SystemCommandBar = observer(
    class SystemCommandBar extends Component {
        
        render () {
            const { keywords, onSearchChange } = this.props;

            const items = [

            ];

            const farItems = [
                {
                    key: 'search',
                    text: 'Search',
                    onRender: () => {
                        return (
                        <SearchBox
                            value={keywords}
                            placeholder="Search by name or alias"
                            styles={{ root: { height: 'auto', minWidth: '270px' }}}
                            onSearch={onSearchChange} 
                            onClear={e=>{onSearchChange("")}}
                            onChange={(e, s)=>{if(s==='') onSearchChange('')}}
                            />
                        );
                    }
                }
            ];

            return (
                <BoxPanel shadow>
                    <CommandBar
                        styles={{ root: { paddingRight: '0px' } }}
                        items={items}
                        farItems={farItems}
                    />
                </BoxPanel>
            );
        }
    }
);