import React from "react";
import { cx, css } from "emotion";

const rootClassName = cx(
    "cygraph-Badge-root",
    css`
        display: inline-block;
        border: 1px solid var(--body-background);
        background-color: var(--body-background);
        padding: var(--spacing-xxxxxs) var(--spacing-xxxxs);
        font-size: var(--font-size-xxs);
        margin-right: var(--spacing-xxxxs);
        border-radius: var(--border-radius);

        &.red {
            border: 1px solid var(--red-2);
            background-color: var(--red-2);
            color: var(--red-16);
        }
        &.orange {
            border: 1px solid var(--orange-2);
            background-color: var(--orange-2);
            color: var(--orange-16);
        }
        &.pink {
            border: 1px solid var(--pink-2);
            background-color: var(--pink-2);
            color: var(--pink-16);
        }
        &.green {
            border: 1px solid var(--green-2);
            background-color: var(--green-2);
            color: var(--green-16);
        }
        &.blue {
            border: 1px solid var(--blue-2);
            background-color: var(--blue-2);
            color: var(--blue-16);
        }
    `
);

export const Badge = ({ children, variant }) => {
    return <div className={cx(rootClassName, variant)}>{children}</div>;
};
