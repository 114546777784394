import React, { useEffect } from "react";
import { css } from "@uifabric/utilities/lib/css";

import { ErrorPanel } from "./ErrorPanel";
import { observer } from "mobx-react";

export const AppPage = observer((props) => {
    useEffect(() => {
        if (props.pageContext) {
            props.pageContext.setCurrent({
                title: props.title,
                onTitleClick: props.onTitleClick,
                subTitle: props.subTitle,
                hasPageNav: props.hasPageNav,
                showPageNav: props.showPageNav,
                hasSidebar: props.hasSidebar,
                breadcrumb: props.breadcrumb,
                loading: props.loading,
                className: props.className,
                error: props.error,
            });
        }
    }, [props]);

    return (
        <div className={css("page")}>
            <div className="page__wrapper">{props.error ? <ErrorPanel error={props.error} /> : props.children}</div>
        </div>
    );
});
