import React from "react";
import { cx } from "emotion";

export const Sidebar = ({ className, children, detached, float, variant, transparent, dataTest }) => {
    return detached ? (
        <div className="sidebar-detached sidebar-detached--right" data-test={dataTest || "sidebarRight"}>
            <div className="sidebar__detached__scroll__wrapper scrollbar--root">{children}</div>
        </div>
    ) : (
        <div
            className={cx(
                className,
                "sidebar sidebar--right",
                variant === "large" && "sidebar--l",
                variant === "medium" && "sidebar--m",
                transparent && "sidebar--transparent",
                float && "sidebar--float"
            )}
            data-test={dataTest || "sidebarRight"}
        >
            <div className="sidebar__scroll__wrapper scrollbar--root">{children}</div>
        </div>
    );
};
