import { WorkflowService } from "../../../api/workflows";
import { RootStore } from "../../base/RootStore";

import { WorkflowSelectionStore } from "./WorkflowSelectionStore";
import { WorkflowPermissionStore } from "./WorkflowPermissionStore";
import { WorkflowLifecycleStore } from "./WorkflowLifecycleStore";
import { WorkflowBuilderStore } from "./WorkflowBuilderStore";

import { WorkflowNewFormStore } from "./WorkflowNewFormStore";
import { WorkflowEditFormStore } from "./WorkflowEditFormStore";
import { WorkflowDeleteFormStore } from "./WorkflowDeleteFormStore";

import { WorkflowRegistryStore } from "./WorkflowRegistryStore";
import { WorkflowSummaryStore } from "./WorkflowSummaryStore";
import { WorkflowBrowseStore } from "./WorkflowBrowseStore";
import { WorkflowHistoryBrowseStore } from "./WorkflowHistoryBrowseStore";
import { WorkflowHistoryViewerStore } from "./WorkflowHistoryViewerStore";

export class WorkflowStore {
    public workflowService: WorkflowService;
    public rootStore: RootStore;

    public selectionStore: WorkflowSelectionStore;
    public permissionStore: WorkflowPermissionStore;
    public lifecycleStore: WorkflowLifecycleStore;
    public builderStore: WorkflowBuilderStore;

    public newFormStore: WorkflowNewFormStore;
    public editFormStore: WorkflowEditFormStore;
    public deleteFormStore: WorkflowDeleteFormStore;

    public registryStore: WorkflowRegistryStore;
    public summaryStore: WorkflowSummaryStore;
    public browseStore: WorkflowBrowseStore;
    public historyBrowseStore: WorkflowHistoryBrowseStore;
    public historyViewerStore: WorkflowHistoryViewerStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.workflowService = new WorkflowService(rootStore.authProvider);

        this.selectionStore = new WorkflowSelectionStore(this);
        this.permissionStore = new WorkflowPermissionStore(this);
        this.lifecycleStore = new WorkflowLifecycleStore(this);
        this.builderStore = new WorkflowBuilderStore(this);

        this.newFormStore = new WorkflowNewFormStore(this);
        this.editFormStore = new WorkflowEditFormStore(this);
        this.deleteFormStore = new WorkflowDeleteFormStore(this);

        this.registryStore = new WorkflowRegistryStore(this);
        this.summaryStore = new WorkflowSummaryStore(this);
        this.browseStore = new WorkflowBrowseStore(this);
        this.historyBrowseStore = new WorkflowHistoryBrowseStore(this);
        this.historyViewerStore = new WorkflowHistoryViewerStore(this);
    }
}
