import React, { Component } from "react";
import { observer } from "mobx-react";

import { Slider } from "office-ui-fabric-react/lib/Slider";

import { BigTextField } from "@baseComponents/BigTextField";
import { ObservableSeparator } from "@baseComponents/ObservableSeparator";

import { ControlMapControlCommandBar } from "./ControlMapControlCommandBar";
import { ControlMapControlGridView } from "./ControlMapControlGridView";

export const ControlMapAxisEditForm = observer(
    class ControlMapAxisEditForm extends Component {
        render() {
            const { formData, controls } = this.props;

            return (
                <>
                    <r-grid columns="6">
                        <r-cell span="6">
                            <div>
                                <BigTextField
                                    label="Name"
                                    required
                                    className="control-map-axis-name"
                                    placeholder="Please enter a name"
                                    value={formData.name}
                                    transparent
                                    onChange={(ev, value) => (formData.name = value)}
                                />
                            </div>
                        </r-cell>
                    </r-grid>
                    <r-grid class="row--l" columns="6">
                        <r-cell span="6">
                            <div>
                                <Slider
                                    label="Target Value"
                                    required
                                    min={0}
                                    max={1}
                                    step={0.01}
                                    defaultValue={0.5}
                                    value={formData.targetValue.toFixed(2)}
                                    onChange={(val) => (formData.targetValue = val)}
                                    showValue
                                    snapToStep
                                />
                            </div>
                        </r-cell>
                        <r-cell span="6">
                            <div>
                                <Slider
                                    label="Absent Value"
                                    required
                                    min={0}
                                    max={1}
                                    step={0.01}
                                    defaultValue={0.5}
                                    value={formData.absentValue.toFixed(2)}
                                    onChange={(val) => (formData.absentValue = val)}
                                    showValue
                                    snapToStep
                                />
                            </div>
                        </r-cell>
                    </r-grid>
                    <r-grid class="row--l" columns="6">
                        <r-cell span="6">
                            <ObservableSeparator>Controls</ObservableSeparator>
                            <ControlMapControlCommandBar
                                axis={formData}
                                controls={controls}
                                onAddControl={(control) => {
                                    const index = formData.parts.findIndex((p) => p.id === control.id);
                                    if (index === -1) {
                                        formData.parts.push({
                                            id: control.id,
                                            name: control.name,
                                            weighting: 1,
                                            absentValue: null,
                                        });
                                        formData.parts = [...formData.parts];
                                    } else {
                                        formData.parts.splice(index, 1);
                                        formData.parts = [...formData.parts];
                                    }
                                }}
                            />
                            <ControlMapControlGridView
                                controls={formData.parts}
                                onControlRemoveClick={(control) => {
                                    formData.parts = [...formData.parts.filter((p) => p.id !== control.id)];
                                }}
                                onWeightingUpdated={(control, value) => {
                                    const part = formData.parts.find((p) => p.id === control.id);
                                    if (part) {
                                        part.weighting = value;
                                        formData.parts = [...formData.parts];
                                    }
                                }}
                                onAbsentValueUpdated={(control, value) => {
                                    const part = formData.parts.find((p) => p.id === control.id);
                                    if (part) {
                                        part.absentValue = value;
                                        formData.parts = [...formData.parts];
                                    }
                                }}
                            />
                        </r-cell>
                    </r-grid>
                </>
            );
        }
    }
);
