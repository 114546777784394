import React, { Component } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from "@fullcalendar/interaction";

import { Text } from "office-ui-fabric-react/lib/Text";
import { MessageBar } from "office-ui-fabric-react/lib/MessageBar";

import { BoxPanel } from "@baseComponents/BoxPanel";

const rootClassName = cx(
    "cygraph-AssessmentPortfolioView-root",
    css`
        & .priority {
            margin-left: -13px;
            display: inline-block;
            min-height: 30px;
        }
    `
);

export const AssessmentPortfolioView = observer(
    class AssessmentPortfolioView extends Component {
        render() {
            const { className, events, resources, loading, view, date, onDatesSet, onEventClick } = this.props;

            return (
                <BoxPanel className={cx(rootClassName, className)} shadow padding>
                    <FullCalendar
                        schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                        height={resources.length == 0 ? 300 : "auto"}
                        plugins={[resourceTimelinePlugin, interactionPlugin]}
                        initialView={`resourceTimeline${view || "Month"}`}
                        weekends={false}
                        expandRows={true}
                        initialDate={date}
                        headerToolbar={{
                            left: "prev,next today",
                            center: "title",
                            right: "resourceTimelineWeek,resourceTimelineMonth,resourceTimelineQuarter",
                        }}
                        eventClick={onEventClick}
                        dateClick={(info) => {
                            const calendarApi = info.view.calendar;
                            calendarApi.changeView("resourceTimelineWeek", info.date);
                        }}
                        views={{
                            resourceTimelineQuarter: {
                                type: "resourceTimeline",
                                buttonText: "3 months",
                                duration: { months: 3 },
                                slotDuration: { days: 7 },
                            },
                        }}
                        datesSet={onDatesSet}
                        events={events}
                        resources={resources}
                        resourceAreaWidth="25%"
                        resourceGroupField="groupId"
                        resourceOrder="groupId,title"
                        resourceLabelContent={this.renderResource}
                    />
                    {!loading && !events.length && <MessageBar>There are no matching assessments.</MessageBar>}
                </BoxPanel>
            );
        }

        renderResource = (args) => {
            return (
                <div className="priority">
                    <Text variant="medium">Priority: {args.fieldValue}</Text>
                </div>
            );
        };
    }
);
