import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { useStores } from "@hooks/useStores";
import { cx } from "emotion";
import Dropzone from "react-dropzone";

import { AppPage } from "@modules/layout/containers/AppPage";

import { AssetHeader } from "@modules/assets/components/core/AssetHeader";
import { AssetSidebar } from "@modules/assets/components/core/AssetSidebar";

import { AssetRoutingPivot } from "@modules/assets/containers/tabs/AssetRoutingPivot";
import { AssetTabList } from "@modules/assets/containers/tabs/AssetTabList";
import { AssetEditPanel } from "@modules/assets/containers/panels/AssetEditPanel";

export const AssetViewerPage = withRouter(
    observer(({ match }) => {
        const { assetStore } = useStores();
        const { lifecycleStore, viewerStore, documentStore } = assetStore;
        const { loading, error, breadcrumb, asset, selectedTab } = viewerStore;

        useEffect(() => {
            if (match.params.id) {
                viewerStore.loadAsset(match.params.id);
            }
        }, [match.params.id]);

        useEffect(() => {
            viewerStore.setSelectedTab(match.params.tab);
        }, [match.params.id, match.params.tab]);

        return (
            <AppPage
                error={error}
                breadcrumb={breadcrumb}
                loading={loading || (documentStore && documentStore.saving)}
                hasSidebar={true}
            >
                {asset && (
                    <>
                        <Dropzone
                            onDrop={(acceptedFiles) => documentStore.uploadDocuments(acceptedFiles, asset.id)}
                            multiple={true}
                            noClick={true}
                        >
                            {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, open }) => (
                                <div
                                    {...getRootProps()}
                                    className={cx(
                                        "dropzone",
                                        isDragActive && "active",
                                        isDragAccept && "accept",
                                        isDragReject && "reject"
                                    )}
                                >
                                    <input {...getInputProps()} />

                                    <AssetHeader
                                        asset={asset}
                                        hideAgentActions={!lifecycleStore.showAgentActions}
                                        canEdit={() => lifecycleStore.canEdit}
                                        onEdit={() => assetStore.editStore.show({ asset })}
                                        canUpload={() => true}
                                        onUpload={open}
                                    />

                                    <AssetRoutingPivot selectedTab={selectedTab} asset={asset} />
                                    <AssetTabList onUpload={open} asset={asset} />

                                    <AssetSidebar asset={asset} />
                                </div>
                            )}
                        </Dropzone>
                    </>
                )}
                <AssetEditPanel onAfterSave={(asset) => viewerStore.setAsset(asset)} />
            </AppPage>
        );
    })
);

// export const AssetViewerPage2 = inject(
//     "pageContext",
//     "assetStore",
//     "routing"
// )(
//     withRouter(
//         observer(
//             class AssetViewerPage extends Component {
//                 componentWillMount() {
//                     const { assetStore, match } = this.props;

//                     assetStore.viewerStore.loadAsset(match.params.id, match.params.tab);
//                 }

//                 componentWillReceiveProps(nextProps) {
//                     const { assetStore, match } = nextProps;

//                     assetStore.viewerStore.loadAsset(match.params.id, match.params.tab);
//                 }

//                 render() {
//                     const { assetStore, routing, match } = this.props;
//                     const { lifecycleStore, viewerStore, documentStore } = assetStore;
//                     const { loading, error, breadcrumb, asset, selectedTab } = viewerStore;

//                     return (
//                         <AppPage
//                             error={error}
//                             breadcrumb={breadcrumb}
//                             loading={loading || (documentStore && documentStore.saving)}
//                             hasSidebar={true}
//                         >
//                             {asset && (
//                                 <>
//                                     <Dropzone
//                                         onDrop={(acceptedFiles) =>
//                                             documentStore.uploadDocuments(acceptedFiles, asset.id)
//                                         }
//                                         multiple={true}
//                                         noClick={true}
//                                     >
//                                         {({
//                                             getRootProps,
//                                             getInputProps,
//                                             isDragActive,
//                                             isDragAccept,
//                                             isDragReject,
//                                             open,
//                                         }) => (
//                                             <div
//                                                 {...getRootProps()}
//                                                 className={cx(
//                                                     "dropzone",
//                                                     isDragActive && "active",
//                                                     isDragAccept && "accept",
//                                                     isDragReject && "reject"
//                                                 )}
//                                             >
//                                                 <input {...getInputProps()} />

//                                                 <AssetHeader
//                                                     asset={asset}
//                                                     hideAgentActions={!lifecycleStore.showAgentActions}
//                                                     canEdit={() => lifecycleStore.canEdit}
//                                                     onEdit={() => assetStore.editStore.show({ asset })}
//                                                     canUpload={() => false}
//                                                     onUpload={open}
//                                                 />

//                                                 <Pivot
//                                                     aria-label="Asset properties, related documents and work"
//                                                     selectedKey={selectedTab}
//                                                     onLinkClick={(item) => {
//                                                         if (item.props.itemKey == "summary") {
//                                                             routing.push(`/assets/browse/${match.params.id}`);
//                                                         } else {
//                                                             routing.push(
//                                                                 `/assets/browse/${match.params.id}/${item.props.itemKey}`
//                                                             );
//                                                         }
//                                                     }}
//                                                     linkFormat={PivotLinkFormat.links}
//                                                 >
//                                                     <PivotItem
//                                                         itemKey="summary"
//                                                         headerText={`Summary`}
//                                                         itemIcon="Info"
//                                                     />
//                                                     <PivotItem
//                                                         itemKey="documents"
//                                                         headerText={`Related Documents`}
//                                                         itemIcon="DocLibrary"
//                                                     />
//                                                     <PivotItem
//                                                         itemKey="work-items"
//                                                         headerText={`Related Work`}
//                                                         itemIcon="ExternalTFVC"
//                                                     />
//                                                     <PivotItem
//                                                         itemKey="assessments"
//                                                         headerText={`Related Assessments`}
//                                                         itemIcon="WorkItem"
//                                                     />
//                                                     <PivotItem
//                                                         itemKey="compliance"
//                                                         headerText={`Policy & Compliance`}
//                                                         itemIcon="Shield"
//                                                     />
//                                                 </Pivot>

//                                                 <Switch>
//                                                     <Route
//                                                         exact
//                                                         path={`/assets/browse/:id`}
//                                                         component={() => <AssetSummaryTab />}
//                                                     />
//                                                     <Route
//                                                         exact
//                                                         path={`/assets/browse/:id/documents`}
//                                                         component={() => (
//                                                             <AssetDocumentsTab asset={asset} onUpload={open} />
//                                                         )}
//                                                     />
//                                                     <Route
//                                                         exact
//                                                         path={`/assets/browse/:id/work-items`}
//                                                         component={() => <AssetWorkItemsTab />}
//                                                     />
//                                                     <Route
//                                                         exact
//                                                         path={`/assets/browse/:id/assessments`}
//                                                         component={() => <AssetAssessmentsTab />}
//                                                     />
//                                                     <Route
//                                                         exact
//                                                         path={`/assets/browse/:id/compliance`}
//                                                         component={() => <AssetComplianceTab />}
//                                                     />
//                                                 </Switch>

//                                                 <AssetSidebar asset={asset} />
//                                             </div>
//                                         )}
//                                     </Dropzone>
//                                 </>
//                             )}
//                             <AssetEditPanel onAfterSave={(asset) => viewerStore.setAsset(asset)} />
//                         </AppPage>
//                     );
//                 }
//             }
//         )
//     )
// );
