import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { Placeholder } from "@modules/base/components/Placeholder";

import { ActivityBuilderCardView } from "@modules/workflows/components/core/ActivityBuilderCardView";

export const ActivityInstanceBuilderTab = observer(({ query, onSelect }) => {
    const { workflowStore } = useStores();
    const { builderStore } = workflowStore;
    const { loading, filteredAutomations } = builderStore;

    useEffect(() => {
        builderStore.loadAutomations({ query });
    }, []);

    return (
        <div style={{ minHeight: "100%" }}>
            <r-grid columns-s="2" columns="4" columns-l="4">
                {filteredAutomations.map((instance, i) => (
                    <r-cell key={i}>
                        <ActivityBuilderCardView
                            className="animated fadeIn"
                            instance={instance}
                            onInstanceClick={onSelect}
                        />
                    </r-cell>
                ))}
            </r-grid>
            {!loading && !filteredAutomations.length && (
                <Placeholder
                    iconName={"ChatBot"}
                    iconText={"Automations"}
                    description={"There are no automations matching your query."}
                    buttonLabel="Reset Filters"
                    onConfigure={() => (builderStore.query = {})}
                />
            )}
        </div>
    );
});
