import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { cx, css } from 'emotion';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { Chart } from '../components/Chart';
import { PagePartPlaceholder } from  '../../pages/components/elements/PagePartPlaceholder';

const rootClassName = cx(
  'cygraph-ImageViewerPart-root'
);

const chartContainerClassName = cx(
  css `
    position: relative
  `
)

const divContainerClassName = cx(
  css `
    z-index: 100;
    background: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
  `
)

export const ChartViewerPart = inject('themeProvider','dataSourceStore')(observer(
  class ChartViewerPart extends Component {

    constructor(props) {
      super(props)
      this.state = {
        rendered : false
      }
    }

    isConfigured() {
      const { part } = this.props;
      return part && part.properties && part.properties.dataSeries && part.properties.dataSeries.length > 0
    }

    onSVGChange(svgRef, svgFilename) {
      const { instance, part } = this.props;
      // debugger
      const partData = instance.partDataStore.data[part.id];
      instance.partDataStore.save(part.id, {
        ...partData,
        svgRef,
        svgFilename
      });
    }

    onCSVDataChange(csvData, csvFilename) {
      const { instance, part } = this.props;
      const partData = instance.partDataStore.data[part.id];
      instance.partDataStore.save(part.id, {
        ...partData,
        csvData,
        csvFilename
      });
    }

    render () {
      const { className, instance, part, themeProvider, dataSourceStore } = this.props;
      const { dataSeries, chartType, xField } = part.properties;
      const mode = instance.lifecycleStore.mode;
      const theme = themeProvider.getTheme();

      const chartData = dataSeries ? dataSeries.map(DS => {
        const data = instance.selectionStore.data.find(D => D.id === DS.dataSourceId);
        const selectedPartDataSource = DS.dataSourceId ? instance.selectionStore.dataSources.find(D => D.id === DS.dataSourceId) : null;
        const selectedCoreDataSource = dataSourceStore.coreDataSourceDataSource(selectedPartDataSource.properties.mainSource);
        const onClickHandler = selectedCoreDataSource && selectedCoreDataSource.onDrillDownAction && selectedCoreDataSource.onProcessQuery ? (clickedElementKey, clickedElement) => {
          // debugger
          selectedCoreDataSource.onDrillDownAction(
            selectedCoreDataSource.onProcessQuery(selectedPartDataSource, clickedElement, clickedElementKey)
          )
        } : null;
        
        const dSFinal = {
          ...DS,
          dataSource: data && data.data ? data.data : [],
          dataCount: data && data.count ? data.count : null,
          xField: xField,
          onClick: onClickHandler,
        }
        return dSFinal
      }) : [];
      
      return (
        <div className={cx(rootClassName, className, mode)}>
          <PagePartPlaceholder {...this.props} isConfigured={this.isConfigured()}>
            <div className={chartContainerClassName} style={{
                height:part.height || '100%',
                width:part.width || '100%'
              }}>
              {!this.state.rendered && this.isConfigured() && <div className={divContainerClassName}>
                <Spinner size={SpinnerSize.large} />
              </div>}
              <Chart
                {...part.properties}
                title={part.title}
                dataSeries={chartData}
                type={chartType}
                height={part.height}
                width={part.width}
                theme={theme}
                mode={mode}
                onDataChange={(csvData, csvFilename) => this.onCSVDataChange(csvData, csvFilename)}
                onSVGChange={(svgRef, svgFilename) => this.onSVGChange(svgRef, svgFilename)}
                onRendered={() => { setTimeout(()=> {
                    this.setState({rendered:true})
                  }, 600)
                }}
              />
            </div>
          </PagePartPlaceholder>
        </div>
      );
    }
  }
));
