import React, { Component } from "react";
import { observer } from "mobx-react";

import { BigTextField } from "../../../base/components/BigTextField";

export const PartTitleEditForm = observer(
    class PartTitleEditForm extends Component {
        render() {
            const { formData } = this.props;

            return (
                <div>
                    <r-grid columns="2">
                        <r-cell span="2">
                            <div>
                                <BigTextField
                                    label="Title"
                                    required
                                    value={formData.title}
                                    transparent
                                    required
                                    onChange={(ev, value) => (formData.title = value)}
                                />
                            </div>
                        </r-cell>
                    </r-grid>
                </div>
            );
        }
    }
);
