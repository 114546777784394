import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Icon } from 'office-ui-fabric-react/lib/Icon';

export const SidebarProperties = observer(
    class SidebarProperties extends Component {
        render () {
            const { title, icon, open, properties, children, dataTest } = this.props
            return (
                <details open={open} data-test={dataTest}>
                    <summary>
                        { icon && <Icon iconName={icon} /> }
                        { title }
                    </summary>
                    <div>
                        {
                            properties && Object.entries(properties).map(([key, value]) => {
                                return (
                                    <div className="row--xxs" key={key}>
                                        <label className="label">{ key }</label>
                                        <div>{ value }</div>
                                    </div>
                                );
                            })
                        }
                        { children }
                    </div>
                </details>
            );
        }
    }
);