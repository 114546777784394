import React from "react";
import { observer } from "mobx-react";
import { cx } from "emotion";

import { Droppable, Draggable } from "react-beautiful-dnd";

import { ModuleIndexGroupMenu } from "./ModuleIndexGroupMenu";
import { ModuleIndexMeasure } from "./ModuleIndexMeasure";

export const ModuleIndexGroup = observer(
    ({
        manifest,
        module,
        group,
        index,
        indexMap,
        isDragDisabled,
        isDropDisabled,
        onMeasureClick,
        onAddMeasure,
        onAddGroup,
        onEditGroup,
        onDuplicateGroup,
        onRemoveGroup,
        onMoveToModule,
    }) => {
        return (
            <div className="group question__category__wrapper">
                <Draggable draggableId={group.id} index={index} isDragDisabled={isDragDisabled} type="group">
                    {(provided, snapshot) => (
                        <div className={cx("draggable", snapshot.isDragging && "dragging")} {...provided.draggableProps} ref={provided.innerRef}>
                            <div className="heading question__category">
                                <ModuleIndexGroupMenu
                                    manifest={manifest}
                                    module={module}
                                    group={group}
                                    dragHandleProps={provided.dragHandleProps}
                                    onAddMeasure={onAddMeasure}
                                    onAddGroup={onAddGroup}
                                    onEditGroup={onEditGroup}
                                    onDuplicateGroup={onDuplicateGroup}
                                    onRemoveGroup={onRemoveGroup}
                                    onMoveToModule={onMoveToModule}
                                />
                            </div>
                            <Droppable droppableId={group.id} isDropDisabled={isDropDisabled} type="measure">
                                {(provided, snapshot) => (
                                    <div
                                        className={cx(
                                            "droppable",
                                            snapshot.isDraggingOver && "dragging-over",
                                            snapshot.draggingFromThisWith && "dragging-from"
                                        )}
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {group.measures.map((measure, mi) => (
                                            <ModuleIndexMeasure
                                                key={measure.id}
                                                measure={measure}
                                                index={mi}
                                                globalIndex={indexMap[measure.id]}
                                                isDragDisabled={isDragDisabled}
                                                onMeasureClick={onMeasureClick}
                                            />
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    )}
                </Draggable>
            </div>
        );
    }
);
