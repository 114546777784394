import React, { PureComponent } from 'react';
import { Persona, PersonaSize } from 'office-ui-fabric-react/lib/Persona';
import moment from 'moment';
import Moment from "react-moment";
import { isNotNullOrEmpty } from '../../../editors/components/RichTextField';
import RichTextViewer from '../../../editors/components/RichTextViewer';
import { PhotoPersona } from '../../../identity/containers/utilities/PhotoPersona';
import { RiskScore } from "../../../risks/components/reviews/RiskScore";

function isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

export class TableField extends PureComponent {

    render () {
        const { field, value, item} = this.props;
        // console.log("TableField", field, value, item);
        if (field) {
            // console.log('field.type', field.type);            
            switch(field.type) {
                case 'number':
                    return <div>{value.toString()}</div>
                case 'string':
                    return <div>{value}</div>
                case 'date':
                    // console.log(field.type, value);
                    if (value) {
                        const val = moment(value).format('MMMM Do YYYY');
                        return <div>{val}</div>
                    } else {
                        return <div>-</div> 
                    }
                case 'summary':
                    return <div>
                        Complaiant: {value? value.compliantCount : ''}<br />
                        Non complaiant: {value? value.nonCompliantCount: ''}<br />
                        Exception: {value? value.exceptionCount: ''}<br />
                        Risks: {value? value.riskCount: ''}<br />
                    </div>
                case 'target':
                    return <div>
                        <Persona
                            text={value? value.name : ''}
                            secondaryText={value? value.type: ''}
                            onRenderPersonaCoin={()=>{}}
                            // size={PersonaSize.size40}
                        />
                    </div>
                case 'person':
                    return <div>
                        <PhotoPersona
                            principalId={value ? value.id : null}
                            text={value? value.name : ''}
                            secondaryText={value? value.jobTitle: ''}
                            size={PersonaSize.size40}
                        />
                    </div>
                case 'assignedToGroup':
                case 'product':
                case 'portfolio':
                    return <div>{value?value.name : ''}</div>
                case 'patternVersion':
                    return <div>{value? `${value.name} (${value.version})` : ''}</div>
                case 'description':
                    return isNotNullOrEmpty(value) ? <RichTextViewer className="description" value={value} /> : '';
                case 'daysSinceRequestRaised':
                    return moment(item.created).fromNow(); 
                case 'isNew':
                    var startDate = moment(item.created);
                    var endDate = moment();                  
                    return (endDate.diff(startDate, 'days') < 30).toString();
                case 'scoreCell':
                    return <>
                        {item.score.likelihood}, {item.score.impact}
                    </>
                case 'riskNetScore':
                    return <RiskScore
                        score={item.review ? item.review.netScore : null}
                        size="mini"
                        maxImpact={4}
                        maxLikelihood={4}
                    />
                case 'riskOverDue':
                    if (item.review && item.review.nextReviewDue) {
                        var startDate = moment(item.review.nextReviewDue);
                        var endDate = moment();  
                        var diff = endDate.diff(startDate, 'days');
                        return (diff > 0) ? 'Overdue' : null;
                    }
                    return null
                case 'riskExpiryDate':
                    return item.review && item.review.nextReviewDue && <Moment date={item.review.nextReviewDue} utc format="Do MMM YYYY" />
                case 'riskStatus':
                    return item.lifecycle.subStatus
                case 'riskAssignedTo':
                    return <PhotoPersona
                        principalId={item.lifecycle.assignedToUser ? item.lifecycle.assignedToUser.id : null}
                        text={item.lifecycle.assignedToUser ? item.lifecycle.assignedToUser.name : "Unassigned"}
                        showUnknownPersonaCoin={!item.lifecycle.assignedToUser}
                        showSecondaryText={true}
                        secondaryText={item.lifecycle.assignedToGroup && item.lifecycle.assignedToGroup.name}
                    />
            }
            return <></>
        }
        return <></>
    }
  };
