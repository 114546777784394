import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';

import { BoxPanel } from '../../../base/components/BoxPanel';

export const PortfolioCommandBar = observer(
    class PortfolioCommandBar extends Component {

        render () {
            const { keywords, onSearchChange, onNewPortfolio } = this.props;

            const items = [
                {
                    key: 'newPortfolio',
                    text: 'New Portfolio',
                    iconProps: { iconName: 'Add' },
                    onClick: () => onNewPortfolio(),
                  },
            ];

            const farItems = [
                {
                    key: 'search',
                    text: 'Search',
                    onRender: () => {
                        return (
                        <SearchBox
                            value={keywords}
                            placeholder="Search by name"
                            styles={{ root: { height: 'auto', minWidth: '270px' }}}
                            onClear={() => onSearchChange(null)}
                            onSearch={onSearchChange}
                        />
                        );
                    }
                }
            ];

            return (
                <BoxPanel shadow>
                    <CommandBar
                        styles={{ root: { paddingRight: '0px' } }}
                        items={items}
                        farItems={farItems}
                    />
                </BoxPanel>
            );
        }
    }
);