import React from "react";
import { observer } from "mobx-react";

import { CommandBar } from "office-ui-fabric-react/lib/CommandBar";

import { BoxPanel } from "@modules/base/components/BoxPanel";

export const StateFlowEditorCommandBar = observer(({ onNew, onPreview, onDuplicate, showHiddenTriggers, onShowHiddenTriggers }) => {
    const items = [
        {
            key: "showHiddenTriggers",
            text: showHiddenTriggers ? "Hide Hidden Triggers" : "Show Hidden Triggers",
            iconProps: { iconName: showHiddenTriggers ? "Hide" : "RedEye" },
            onClick: onShowHiddenTriggers,
        },
        {
            key: "preview",
            text: "Preview",
            iconProps: { iconName: "VisioDiagram" },
            disabled: !onPreview,
            onClick: onPreview,
        },
    ];

    const farItems = [];

    return (
        <BoxPanel>
            <CommandBar styles={{ root: { paddingRight: "0px" } }} items={items} farItems={farItems} />
        </BoxPanel>
    );
});
