import { observable, flow } from "mobx";

import { PaginationStore } from "../../base/PaginationStore";
import { AssetStore } from "./AssetStore";

export class AssetWorkItemStore {
    private parentStore: AssetStore;

    @observable public loading: boolean = false;
    @observable public workItems: any[] = [];
    @observable public query: any;
    @observable public pagination: PaginationStore;
    @observable public error: any;

    constructor(parentStore: AssetStore) {
        this.parentStore = parentStore;
        this.pagination = new PaginationStore();
    }

    public loadWorkItems = flow(function* (options: any) {
        const q = Object.assign({}, options.query, { asset: { id: [options.assetId] } });
        const o = Object.assign({ page: 1 }, options, { query: q });
        const startIndex = (o.page - 1) * this.pagination.itemsPerPage;

        this.loading = true;
        this.workItems = [];
        this.query = o.query;
        this.error = null;

        try {
            const workItemService = this.parentStore.rootStore.workItemStore.workItemService;

            const result = yield workItemService.searchWorkItems({
                pageSize: this.pagination.itemsPerPage,
                startIndex: startIndex,
                query: o.query,
            });
            this.workItems = result.items;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
            this.query = result.query;
        } catch (e) {
            this.error = e;
            console.error(e);
        } finally {
            this.loading = false;
        }
    });

    public exportWorkItems = flow(function* (options) {
        const o = Object.assign({ page: 1, query: this.query }, options);
        try {
            const workItemService = this.parentStore.rootStore.workItemStore.workItemService;

            const result = yield workItemService.exportWorkItems({
                pageSize: this.pagination.itemsPerPage,
                startIndex: (o.page - 1) * this.pagination.itemsPerPage,
                query: o.query,
            });
            if (result) {
                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Demand export request has been queued. You will receive an email shortly when export is ready.`
                );
            }
        } catch (e) {
            console.error(e);
            this.error = e;
        }
    });
}
