import { ServiceBase, Constants } from "../graph/serviceBase";

export class WorkflowService extends ServiceBase {
    public async getWorkflowEntitySummary(entityId) {
        return this.get(`${Constants.baseUrl}/workflow-entities/${entityId}/summary`);
    }

    public async getActivityDefinitions(options) {
        return this.get(
            `${Constants.baseUrl}/activity-definitions?pageSize=${options.pageSize || 25}&startIndex=${
                options.startIndex
            }&keywords=${options.keywords || ""}&query.active=${options.active || ""}&query.category=${
                options.category || ""
            }`
        );
    }

    public async getActivityInstances(options) {
        return this.get(
            `${Constants.baseUrl}/activity-instances?pageSize=${options.pageSize || 25}&startIndex=${
                options.startIndex
            }&scopeId=${options.scope.id}&scopeType=${options.scope.type}&keywords=${
                options.keywords || ""
            }&query.active=${options.active || ""}`
        );
    }

    public async searchActivityInstances(options) {
        return this.post(`${Constants.baseUrl}/activity-instances/search`, options);
    }

    public async getActivityInstance(id) {
        return this.get(`${Constants.baseUrl}/activity-instances/${id}`);
    }

    public async createActivityInstance(data) {
        return this.post(`${Constants.baseUrl}/activity-instances`, data);
    }

    public async updateActivityInstance(data) {
        return this.put(`${Constants.baseUrl}/activity-instances/${data.id}`, data);
    }

    public async deleteActivityInstance(id) {
        return this.delete(`${Constants.baseUrl}/activity-instances/${id}`, {});
    }

    public async reorderActivityInstances(options) {
        return this.post(`${Constants.baseUrl}/activity-instances/reorder`, options);
    }

    public async getWorkflowInstances(options) {
        return this.get(
            `${Constants.baseUrl}/workflow-instances?viewType=${options.viewType}&id=${options.id}&maxResults=${
                options.maxResults || 50
            }&continuationToken=${options.continuationToken || ""}`
        );
    }

    public async searchWorkflowInstances(options) {
        return this.post(`${Constants.baseUrl}/workflow-instances/search`, options);
    }

    public async getWorkflowInstance(id) {
        return this.get(`${Constants.baseUrl}/workflow-instances/${id}`);
    }

    public async getWorkflowActivities(options) {
        return this.get(
            `${Constants.baseUrl}/workflow-instances/${options.id}/activities?maxResults=${
                options.maxResults || 50
            }&continuationToken=${options.continuationToken || ""}`
        );
    }

    public async searchWorkflowActivities(options) {
        return this.post(`${Constants.baseUrl}/workflow-instances/${options.id}/activities/search`, options);
    }

    public async startWorkflowInstance(data) {
        return this.post(`${Constants.baseUrl}/workflow-instances`, data);
    }

    public async terminateWorkflowInstance(options) {
        return this.post(`${Constants.baseUrl}/workflow-instances/${options.id}/terminate`, options);
    }

    public async getMyTaskDefinitions() {
        return this.get(`${Constants.baseUrl}/tasks/my`);
    }

    public async searchMyTaskDefinitions(options) {
        return this.post(`${Constants.baseUrl}/tasks/my/search`, options);
    }

    public async searchTaskDefinitions(options) {
        return this.post(`${Constants.baseUrl}/tasks/search`, options);
    }

    public async getMyTaskSummary() {
        return this.get(`${Constants.baseUrl}/tasks/my/summary`);
    }

    public async getTaskDefinition(id) {
        return this.get(`${Constants.baseUrl}/tasks/${id}`);
    }

    public async createTaskDefinition(options) {
        return this.post(`${Constants.baseUrl}/tasks`, options);
    }

    public async updateTaskDefinition(options) {
        return this.put(`${Constants.baseUrl}/tasks/${options.id}`, options);
    }

    public async updateTaskAssignment(options) {
        return this.put(`${Constants.baseUrl}/tasks/${options.id}/assignments`, options);
    }

    public async getTaskSummary(entityId) {
        return this.get(`${Constants.baseUrl}/workflow-entities/${entityId}/summary`);
    }

    public async bulkRevokeAssignments(options) {
        return this.post(`${Constants.baseUrl}/tasks/${options.definitionId}/assignments/revoke`, options);
    }

    public async bulkCreateAssignments(options) {
        return this.post(`${Constants.baseUrl}/tasks/${options.definitionId}/assignments`, options);
    }

    public async expireTaskSubscriptions(assignedToIds, definitionId, newAssignments = 0, notify = true) {
        return this.post(`${Constants.baseUrl}/tasks/${definitionId}/assignments/revoke`, {
            definitionId: definitionId,
            force: true,
            completeIfRequired: newAssignments > 0 ? false : true,
            assignments: assignedToIds.map((A) => {
                return {
                    assignedTo: {
                        id: A,
                    },
                    notify: notify,
                };
            }),
        });
    }

    public async expireTaskAssignmentAssignee(assignedToId, definitionId, notify = true) {
        return this.post(`${Constants.baseUrl}/tasks/${definitionId}/assignments/revoke`, {
            definitionId: definitionId,
            force: true,
            assignments: [
                {
                    assignedTo: {
                        id: assignedToId,
                    },
                    notify: notify,
                },
            ],
        });
    }

    public async createNewSubscriptions(assignedToIds, definitionId, notify = true) {
        return this.post(`${Constants.baseUrl}/tasks/${definitionId}/assignments`, {
            definitionId: definitionId,
            force: true,
            assignments: assignedToIds.map((A) => {
                return {
                    assignedTo: {
                        id: A,
                    },
                    notify: notify,
                };
            }),
        });
    }

    public async createNewAssigneeSubscription(assignedToId, definitionId, notify = true) {
        return this.post(`${Constants.baseUrl}/tasks/${definitionId}/assignments`, {
            definitionId: definitionId,
            force: true,
            assignments: [
                {
                    assignedTo: {
                        id: assignedToId,
                    },
                    notify: notify,
                },
            ],
        });
    }
}
