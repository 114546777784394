import React, { Component } from "react";
import { observer, inject } from "mobx-react";

import { AppPage } from "../../../layout/containers/AppPage";

import { AssetSideNav } from "../../components/core/AssetSideNav";

import { AssetNewPanel } from "../panels/AssetNewPanel";

export const AssetDashboardPage = inject(
    "assetStore",
    "routing"
)(
    observer(
        class AssetDashboardPage extends Component {
            componentWillMount() {
                const { dashboardStore } = this.props.assetStore;
                dashboardStore.loadDashboard({});
            }

            componentWillReceiveProps(nextProps) {
                const { dashboardStore } = nextProps.assetStore;
                dashboardStore.loadDashboard({});
            }

            render() {
                const { lifecycleStore, dashboardStore } = this.props.assetStore;
                const { loading, breadcrumb, error, assets } = dashboardStore;

                return (
                    <AppPage
                        error={error}
                        breadcrumb={breadcrumb}
                        loading={loading}
                        hasSidebar={false}
                        className="assets dashboard has--page-nav"
                    >
                        <AssetSideNav
                            hideAgentActions={!lifecycleStore.showAgentActions}
                            onLinkClick={(item) => this.props.routing.push(item.url)}
                            onNewAsset={() => this._onNewAsset()}
                        />

                        <AssetNewPanel onAfterSave={(asset) => this.props.routing.push(`/assets/browse/${asset.id}`)} />
                    </AppPage>
                );
            }

            _onNewAsset() {
                const { newStore } = this.props.assetStore;
                newStore.show({});
            }
        }
    )
);
