import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';

import { Text } from 'office-ui-fabric-react/lib/Text';
import { Stack } from 'office-ui-fabric-react/lib/Stack';

import { ObservableBadge } from '../../../base/components/ObservableBadge';
import { ActionSet } from '../../../base/components/ActionSet';

const rootClassName = cx(
  'cygraph-RegistryHeader-root',
  css` 
    margin: var(--spacing-s) 0;

    .title {
      margin: var(--spacing-xs) 0 0 0;
    }

    & .description {
      color: var(--text-color-light);
      font-size: var(--font-size-xs);
    }
  `
);

export const RegistryHeader = observer(
    class RegistryHeader extends PureComponent {
        render () {
            const { registry } = this.props;
            const { items, overflowItems } = this._getActionSetItems();
            
            return (
              <div className={rootClassName}>
                {/* <Stack horizontal horizontalAlign="space-between">
                  <ObservableBadge className="badge">Risk Regisrty</ObservableBadge>
                  <ActionSet items={items} overflowItems={overflowItems} />
                </Stack> */}
                <Text className="title" variant="xLarge" block>{registry.name}</Text>
              </div>
            );
        }

        _getActionSetItems() {
          const { registry } = this.props;

          const items = [];
          const overflowItems = [];

          return { items, overflowItems };
        }
    }
);