import React, { Component, Fragment } from 'react';
import { cx, css } from 'emotion'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Column } from './components/Column';
import { KCard } from './components/KCard';

const columnsContainer = cx(
    'kanban-columns-container',
        css`
        display: flex;
        justify-content: right;
        padding: 5px;
        margin-top: 10px;
        overflow-x: auto;
        `
);

export class KanbanBoard extends Component {

        state = {
            dragging: null,
            draggedCard: null
        }

        onDragEnd = dragEnd => {
            const { destination } = dragEnd;
            const { onDragEnd } = this.props;
            if (destination && onDragEnd) {
                onDragEnd(dragEnd);
            }
            this.setState({
                dragging: null,
                draggedCard: null,
            })
        }

        onDragStart = dragStart => {
            const { data } = this.props;
            let draggedCard = null;
            data.columns.forEach(COLUMN => {
                const card = COLUMN.cards.find(C => C.id === dragStart.draggableId);
                if (card) draggedCard = card;
            });

            this.setState({
                dragging: dragStart,
                draggedCard,
            })
        }

        onDragUpdate = result => {
        }

        isDroppable = (column) => {
            const  {dragging, draggedCard } = this.state;
            // debugger
            if (this.state.dragging) {
                return (column.allowedDrops.indexOf(draggedCard.label) > -1)
            } else {
                return true
            }
        }

        render () {
            const { data, onCardClickDetail, onAssignClick, onEditClick } = this.props;
            // debugger
            return (
                <DragDropContext 
                    onDragEnd={this.onDragEnd}
                    onDragStart={this.onDragStart}
                    onDragUpdate={this.onDragUpdate}
                >
                    <div className={columnsContainer}>
                        {data.columns.map((COLUMN,COLUMN_INDEX) => (
                            <Droppable 
                                droppableId={COLUMN.id}
                                key={COLUMN.id}
                                isDropDisabled={!this.isDroppable(COLUMN)}
                            >
                                {(provided, snapshot) => (
                                    <Column 
                                        provided={provided} 
                                        innerRef={provided.innerRef}
                                        isDraggingOver={snapshot.isDraggingOver}
                                        title={COLUMN.title}
                                        label={COLUMN.label}
                                    >
                                        {COLUMN.cards.slice().sort((a,b)=>a.order>=b.order).map((CARD,CARD_INDEX) => (
                                            <Draggable 
                                                draggableId={CARD.id}
                                                key={`card_${CARD.id}`}
                                                index={CARD_INDEX}
                                            >
                                                {(provided, snapshot) => (
                                                    // <> FAKE KCAZRD</>
                                                    <KCard
                                                        provided={provided}
                                                        innerRef={provided.innerRef}
                                                        title={CARD.title}
                                                        label={CARD.label}
                                                        workItem={CARD.workItem}
                                                        isDragging={snapshot.isDragging}
                                                        onClickDetail={onCardClickDetail}
                                                        onAssignClick={onAssignClick}
                                                        onEditClick={onEditClick}
                                                    />
                                                )}
                                            </Draggable>                                
                                        ))}
                                        {provided.placeholder}
                                    </Column>
                                )}
                            </Droppable>
                        )
                        )}
                    </div>
                </DragDropContext>
            )
        }
    };