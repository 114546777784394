import { observable, flow, computed, action } from "mobx";

import { SettingsStore } from "./SettingsStore";

const DefaultTab = "general";
const KnownTabs = [DefaultTab, "catalogue", "registries", "workflows", "automations", "recycle-bin"];

export class RisksSettingsStore {
    public parentStore: SettingsStore;

    @observable public loading: boolean = false;
    @observable public error: any;
    @observable public defaultTab: string = DefaultTab;
    @observable public selectedTab: string = DefaultTab;

    constructor(parentStore: SettingsStore) {
        this.parentStore = parentStore;
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [];

        builder.push({
            text: "Settings",
            key: "settings",
            onClick: () => this.parentStore.rootStore.routing.push(`/settings`),
        });
        builder.push({
            text: "Risk Management",
            key: "risks-settings",
            onClick: () => this.parentStore.rootStore.routing.push(`/settings/risks`),
        });

        switch (this.selectedTab) {
            case "general":
                builder.push({ text: "General", key: "risks-general", isCurrentItem: true });
                break;
            case "catalogue":
                builder.push({ text: "Catalogue", key: "risks-catalogue", isCurrentItem: true });
                break;
            case "registries":
                builder.push({ text: "Registries", key: "risks-registries", isCurrentItem: true });
                break;
            case "workflows":
                builder.push({ text: "Workflows", key: "risks-workflows", isCurrentItem: true });
                break;
            case "automations":
                builder.push({ text: "Automations", key: "risks-automations", isCurrentItem: true });
                break;
            case "recycle-bin":
                builder.push({ text: "Recycle Bin", key: "risks-recycle-bin", isCurrentItem: true });
                break;
        }

        return builder;
    }

    @computed
    public get activeTabs() {
        return KnownTabs;
    }

    @action
    public setSelectedTab(tab: string) {
        if (KnownTabs.indexOf(tab) !== -1) {
            this.selectedTab = tab;
        } else {
            this.selectedTab = this.defaultTab;
        }
    }
}
