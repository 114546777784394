import React, { useCallback } from "react";
import { observer } from "mobx-react";

import { PanelType } from "office-ui-fabric-react/lib/Panel";

import { ActionButton, SpinnerButton } from "@ui/elements/Button";

import { ObservablePanel } from "@modules/base/components/ObservablePanel";
import { ActionPanel } from "@modules/base/components/ActionPanel";

import { ActivityInstanceEditForm } from "@modules/workflows/components/core/ActivityInstanceEditForm";

export const ActivityInstanceEditPanel = observer(({ store }) => {
    const { visible, formData, formOptions, isValid, processing, registration } = store;

    const onSave = useCallback(() => {
        store.resolve(true);
    }, [store]);

    const onDismiss = useCallback(() => {
        store.resolve(false);
    }, [store]);

    return (
        <ObservablePanel
            hidden={!visible}
            pageType={(formOptions && formOptions.pageType) || "Edit Automation"}
            panelType={PanelType.medium}
            onClosePanel={onDismiss}
            onLightDismissClick={onDismiss}
            onRenderFooter={() => (
                <ActionPanel>
                    <ActionButton onClick={onDismiss} text="Cancel" disabled={processing} />
                    {formOptions && !formOptions.readOnly && (
                        <SpinnerButton
                            onClick={onSave}
                            text="Save"
                            disabled={!isValid || processing}
                            loading={processing}
                        />
                    )}
                </ActionPanel>
            )}
        >
            {formData && (
                <ActivityInstanceEditForm
                    formData={formData}
                    formOptions={formOptions}
                    isValid={isValid}
                    registration={registration}
                />
            )}
        </ObservablePanel>
    );
});
