import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';

import Moment from 'react-moment';

import { Text } from 'office-ui-fabric-react/lib/Text';
import { MessageBar } from 'office-ui-fabric-react/lib/MessageBar';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { DetailsListLayoutMode, Selection, SelectionMode, ConstrainMode } from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { ContextualMenu } from 'office-ui-fabric-react/lib/ContextualMenu';

import { BoxPanel } from '../../base/components/BoxPanel';
import { RoutingLink } from '../../base/components/RoutingLink';
import { WrappedContent } from '../../base/components/WrappedContent';
import { StatusBadge } from '../../base/components/StatusBadge';

export const SystemGridView = observer(
    class SystemGridView extends Component {
        constructor(props) {
            super(props);

            this.state = {};

            this._selection = new Selection({
                onSelectionChanged: () => {
                  this.setState({
                    selectionDetails: this._getSelectionDetails()
                  });
                }
              });

            this._columns = [
                {
                  key: 'name',
                  name: 'Name',
                  fieldName: 'name',
                  minWidth: 310,
                  isRowHeader: true,
                  isResizable: true,
                  onColumnClick: this._onColumnClick,
                  data: 'string',
                  onRender: (item) => {
                    return (
                      <WrappedContent>
                        <RoutingLink url={`/assets/browse/${item.id}`} onClick={() => this.props.onSystemClick && this.props.onSystemClick(item)} onClickEnabled={this.props.onSystemClick}>{item.name}</RoutingLink><br/>
                        <Text variant="small">
                          { item.summary && <span>{item.summary}</span> }
                          { item.alias && <span> Also known as: {item.alias}</span> }
                        </Text>
                        { item.primarySupplier && item.primarySupplier.name != '' && <Text variant="small" block>Primary supplier: {item.primarySupplier.name}</Text> }
                      </WrappedContent>
                      );
                  },
                  isPadded: true
                },
                {
                  key: 'pattern',
                  name: 'Pattern',
                  fieldName: 'pattern',
                  minWidth: 230,
                  maxWidth: 290,
                  isResizable: false,
                  onColumnClick: this._onColumnClick,
                  data: 'string',
                  onRender: (item) => {
                    return (
                      <div className={cx(
                        'system-pattern',
                        css`
                          white-space: initial;
                        `
                      )}>
                        <p>{item.pattern.name}</p>
                        <p>v{item.pattern.version}</p>
                      </div>
                    );
                  },
                  isPadded: true
                },
                {
                  key: 'detailsUrl',
                  name: 'URL',
                  fieldName: 'detailsUrl',
                  minWidth: 40,
                  maxWidth: 40,
                  isResizable: false,
                  onColumnClick: this._onColumnClick,
                  data: 'string',
                  onRender: (item) => {
                    return (item.detailsUrl && <IconButton iconProps={{iconName:'NavigateExternalInline'}} href={item.detailsUrl} target="_blank" /> );
                  },
                  isPadded: false
                },
                {
                    key: 'state',
                    name: 'Status',
                    fieldName: 'state',
                    minWidth: 100,
                    maxWidth: 130,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: 'string',
                    onRender: (item) => {
                      switch(item.state) {
                        case 'Decommissioned':
                          return <StatusBadge variant="red">{item.state}</StatusBadge>;
                        case 'Decommissioning':
                          return <StatusBadge variant="orange">{item.state}</StatusBadge>;
                        case 'Developing':
                          return <StatusBadge variant="yellow">{item.state}</StatusBadge>;
                        case 'Testing':
                          return <StatusBadge variant="yellow">{item.state}</StatusBadge>;
                        case 'Live':
                          return <StatusBadge variant="green">{item.state}</StatusBadge>;
                        case 'Unknown':
                          return <StatusBadge variant="grey">{item.state}</StatusBadge>;
                        default:
                          return <StatusBadge>{item.state}</StatusBadge>;
                      }
                    },
                    isPadded: true
                },
                {
                  key: 'modified',
                  name: 'Date Modified',
                  fieldName: 'modified',
                  minWidth: 100,
                  maxWidth: 130,
                  isResizable: true,
                  isSorted: true,
                  isSortedDescending: true,
                  sortAscendingAriaLabel: 'Sorted A to Z',
                  sortDescendingAriaLabel: 'Sorted Z to A',
                  onColumnClick: this._onColumnClick,
                  data: 'date',
                  onRender: (item) => {
                    return <Moment date={item.modified} fromNow />;
                  },
                  isPadded: true
                }
            ];
        }

        render () {
            const { systems, loading, className, onSystemClick } = this.props;
            const { contextualMenuProps } = this.state;
            
            return (
              <BoxPanel shadow className="ciTestSystemGridView">
                <ShimmeredDetailsList
                  items={systems}
                  compact={false}
                  columns={this._columns}
                  selectionMode={SelectionMode.none}
                  getKey={this._getKey}
                  setKey="single"
                  layoutMode={DetailsListLayoutMode.justified}
                  constrainMode={ConstrainMode.horizontalConstrained}
                  isHeaderVisible={true}
                  selection={this._selection}
                  selectionPreservedOnEmptyClick={true}
                  onItemInvoked={onSystemClick}
                  //onItemContextMenu={(item, index, ev) => this._onItemContextMenu(item, index, ev) }
                  enterModalSelectionOnTouch={true}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  checkButtonAriaLabel="Row checkbox"
                  enableShimmer={loading}
                  removeFadingOverlay={true}
                  shimmerLines={25}
                  ariaLabelForShimmer="Systems being fetched"
                  ariaLabelForGrid="System list"
                />
                
                { ! loading && !systems.length && (
                  <MessageBar>
                    There are no matching apps or systems.
                  </MessageBar>
                )}

                {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}
              </BoxPanel> 
            );
        }

        _getKey(item, index) {
            return item ? item.id : index;
        }

        _getSelectionDetails() {
            const selectionCount = this._selection.getSelectedCount();
        
            switch (selectionCount) {
              case 0:
                return 'No items selected';
              case 1:
                return '1 item selected: ' + (this._selection.getSelection()[0]).name;
              default:
                return `${selectionCount} items selected`;
            }
        }

        _onColumnClick(ev, column) {
            console.log(column);
        }

        _onItemContextMenu(item, index, ev) {
            const contextualMenuProps = {
              target: ev.target,
              items: [
                {
                  key: 'text',
                  name: `${this._selection.getSelectedCount()} selected`
                }
              ],
              onDismiss: () => {
                this.setState({
                  contextualMenuProps: undefined
                });
              }
            };
        
            if (index > -1) {
              this.setState({
                contextualMenuProps: contextualMenuProps
              });
            }
        
            return false;
          };
    }
);