import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router';

import { css } from '@uifabric/utilities/lib/css';
import { Text } from 'office-ui-fabric-react/lib/Text';

import { AppPage } from '../../../layout/containers/AppPage'; 
import { ObservablePagination } from '../../../base/components/ObservablePagination';
import { SettingsListNav } from '../../../settings/containers/utilities/SettingsListNav';

import { PageLibraryGridView } from '../../components/libraries/PageLibraryGridView';
import { PageLibraryCommandBar } from '../../components/libraries/PageLibraryCommandBar';

const getQueryStringParams = query => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
};

export const PageLibraryBrowsePage = inject('pageContext', 'pageStore', 'routing')(withRouter(observer(
    class PageLibraryBrowsePage extends Component {

        componentWillMount() {
            const { match, routing, pageStore } = this.props;

            const params = getQueryStringParams(routing.location.search);
            pageStore.libraryStore.loadLibraries({ page: params["p"] || 1, keywords: params["k"] });
        }

        componentWillReceiveProps(nextProps) {
            const { match, routing, pageStore } = nextProps;

            const params = getQueryStringParams(routing.location.search);
            pageStore.libraryStore.loadLibraries({ page: params["p"] || 1, keywords: params["k"] });
        }

        render () {
            const { routing, pageStore } = this.props;
            const { loading, error, breadcrumb, keywords, pagination, libraries } = pageStore.libraryStore;

            return (
                <AppPage 
                    loading={loading} 
                    error={error} 
                    breadcrumb={breadcrumb}
                    hasSidebar={false}
                    className={css('settings page-library list', !error && 'has--page-nav')}>
                    <SettingsListNav />
                    <div>
                        <Text className="title" variant="xLarge" block>
                            Page Libraries
                        </Text>

                        <PageLibraryCommandBar 
                            keywords={keywords}
                            onSearchChange={(keywords) => { 
                                if(keywords) {
                                    routing.push(`/settings/page-libraries?k=${encodeURIComponent(keywords)}`);
                                } else {
                                    routing.push(`/settings/page-libraries`);
                                };
                            }} />

                        <PageLibraryGridView
                            libraries={libraries}
                            loading={loading}
                            onPagePathClick={(item) => routing.push(item.path)}
                            onPageDrilldownClick={(item) => routing.push(`/settings/pages/${item.id}/${item.rootId}`)}
                            query={{}} />

                        <ObservablePagination 
                            {...pagination}
                            onPageChange={(index) => { 
                                routing.push(`/settings/page-libraries?p=${index + 1}${keywords ? `&k=${encodeURIComponent(keywords)}` : ''}`);
                            }} />
                            
                    </div> 
                </AppPage>
            );
        }

        _onNewLibrary = () => {

        }
    }
)));
