import moment from 'moment';
import { generateAssessmentQuery } from '../queries/generateAssessmentQuery';

async function getAssessmentsOpenedClosed(query, groupBy, api) {
    const computedQuery = generateAssessmentQuery(query);
    // console.log('computedQuery assessment oc', computedQuery);
    // debugger
    // if (!computedQuery.timeline) {
    //     computedQuery.timeline = {
    //         before: moment().utc().startOf('month').add(1, 'month').format(),
    //         after: moment().utc().startOf('month').subtract(6, 'months').format(),
    //     };
    // }
    return await api({
        query: computedQuery
    });
}

export { getAssessmentsOpenedClosed }