import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { ContextualMenuItemType } from 'office-ui-fabric-react/lib/ContextualMenu';
import { Persona, PersonaPresence, PersonaSize } from 'office-ui-fabric-react/lib/Persona';
import { Shimmer, ShimmerElementsGroup, ShimmerElementType } from 'office-ui-fabric-react/lib/Shimmer';

export const PersonalMenu = observer(
    class PersonalMenu extends Component {

        render () {
            const { user, authProvider, routing } = this.props;

            const menuItems = [
                {
                    key: 'myAccount',
                    text: 'My Account',
                    onClick: () => routing.push('/account/overview'),
                    iconProps: { iconName: 'AccountManagement' }
                },
                {
                    key: 'myNotifications',
                    text: 'Notifications',
                    disabled: true,
                    onClick: () => console.log('Notification settings clicked'),
                    iconProps: { iconName: 'CannedChat' }
                },
                {
                    key: 'divider_1',
                    itemType: ContextualMenuItemType.Divider
                },
                {
                    key: 'logout',
                    text: 'Logout',
                    onClick: () => authProvider.logout()
                },
            ];
            
            const menuProps = {
                shouldFocusOnMount: true,
                useTargetAsMinWidth: true,
                items: menuItems,
            };

            return (
                <div className="cygraph-PersonalMenu-container">
                    <Shimmer customElementsGroup={this._getCustomElements()} width={200} isDataLoaded={!!user}>
                        <DefaultButton onClick={() => this._onMenuToggle()} menuProps={menuProps}>                            
                                { user && (
                                    <Persona
                                        text={user.name}
                                        size={PersonaSize.size40}
                                        imageUrl={user.photo}
                                        secondaryText={user.email || user.userPrincipalName}
                                        presence={PersonaPresence.unknown}
                                        imageAlt={user.name}
                                    />
                                ) }
                        </DefaultButton>
                    </Shimmer>
                </div>
            );
        }

        _getCustomElements() {
            return (
              <div style={{ display: 'flex' }}>
                <ShimmerElementsGroup
                  shimmerElements={[
                    { type: ShimmerElementType.circle, height: 40 },
                    { type: ShimmerElementType.gap, width: 16, height: 40 }
                  ]}
                />
                <ShimmerElementsGroup
                  flexWrap={true}
                  width="100%"
                  shimmerElements={[
                    { type: ShimmerElementType.line, width: '100%', height: 10, verticalAlign: 'bottom' },
                    { type: ShimmerElementType.line, width: '90%', height: 8 },
                    { type: ShimmerElementType.gap, width: '10%', height: 20 }
                  ]}
                />
              </div>
            );  
        }
    }
);