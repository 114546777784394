import { observable, action, computed } from "mobx";

import { RootStore } from "../../base/RootStore";
import { IdentitySettingsStore } from "./IdentitySettingsStore";
import { DemandSettingsStore } from "./DemandSettingsStore";
import { AssuranceSettingsStore } from "./AssuranceSettingsStore";
import { RisksSettingsStore } from "./RisksSettingsStore";
import { ControlsSettingsStore } from "./ControlsSettingsStore";
import { settings } from "./settings";

export class SettingsStore {
    public rootStore: RootStore;

    public identitySettingsStore: IdentitySettingsStore;
    public demandSettingsStore: DemandSettingsStore;
    public assuranceSettingsStore: AssuranceSettingsStore;
    public risksSettingsStore: RisksSettingsStore;
    public controlsSettingsStore: ControlsSettingsStore;

    @observable public settings: any[] = settings;
    @observable public loading: boolean = false;
    @observable public keywords: string;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        this.identitySettingsStore = new IdentitySettingsStore(this);
        this.demandSettingsStore = new DemandSettingsStore(this);
        this.assuranceSettingsStore = new AssuranceSettingsStore(this);
        this.risksSettingsStore = new RisksSettingsStore(this);
        this.controlsSettingsStore = new ControlsSettingsStore(this);
    }

    @action
    public setKeywords(keywords) {
        this.keywords = keywords;
    }

    @computed
    public get filteredSettings() {
        if (!this.keywords) {
            return this.settings;
        }
        const keywords = this.keywords.toLowerCase();
        return this.settings
            .map((setting) => ({
                ...setting,
                links: setting.links.filter((link) => link.name.toLowerCase().indexOf(keywords) !== -1),
            }))
            .filter((setting) => setting.links.length > 0);
    }

    // @action
    // public locationChanged(location) {
    //     return;
    //     const links = this.settings.reduce((acc, val) => acc.concat(val.links), []);
    //     const item = links.find(i => ((i.isSelected && i.isSelected(location.pathname)) || i.url === location.pathname));
    //     if(item) {
    //         this.selectedKey = item.key;
    //     } else {
    //         this.selectedKey = null;
    //     }
    // }
}
