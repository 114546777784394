import { observable, flow, computed, action } from "mobx";

import { PaginationStore } from "../../base/PaginationStore";
import { PortfolioService } from '../../../api/graph';
import { PortfolioStore } from "./PortfolioStore";

export class PortfolioBrowseStore {

    public parentStore: PortfolioStore;
    public portfolioService: PortfolioService;

    @observable public loading: boolean = false;
    @observable public portfolios: any[] = [];
    @observable public keywords: string;
    @observable public activeOnly: boolean = false;
    @observable public pagination: PaginationStore;
    @observable public error: any;

    constructor(parentStore: PortfolioStore) {
        this.parentStore = parentStore;
        this.portfolioService = parentStore.portfolioService;
        this.pagination = new PaginationStore();
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [];

        builder.push({ text: 'Settings', key: 'settings', onClick: () => this.parentStore.rootStore.routing.push(`/settings`) });
        builder.push({ text: 'Portfolios', key: 'portfolios', onClick: () => this.parentStore.rootStore.routing.push(`/settings/portfolios`) });

        return builder;
    }

    public loadPortfolios = flow(function*(query: any) {
        this.loading = true;
        const options = Object.assign({ page: 1, keywords: null, activeOnly: false }, query);

        this.portfolios = [];
        this.keywords = null;
        this.error = null;
        
        try {
            const result = yield this.portfolioService.getPortfolios({ 
                pageSize: this.pagination.itemsPerPage, 
                startIndex: (options.page - 1) *  this.pagination.itemsPerPage,
                keywords: options.keywords,
                activeOnly: options.activeOnly,
            });

            this.portfolios = result.items;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
            this.keywords = result.keywords;
            this.activeOnly = result.activeOnly;
        } catch (e) {
            this.error = e;
            console.error(e)
        } finally {
            this.loading = false;
        }
    });

    @action
    public mergePortfolio(portfolio) {
        const updated = [...this.portfolios.filter(p => p.id !== portfolio.id), portfolio];
        updated.sort((a, b) => a.name.localeCompare(b.name));
        this.portfolios = updated;
    }
}
    