import React from "react";
import { observer } from "mobx-react";

import { Sidebar } from "@modules/base/components/Sidebar";

import { StateFlowChangeCard } from "@modules/activities/components/cards/StateFlowChangeCard";
import { ActivityStreamContext } from "@modules/activities/containers/utilities/ActivityStreamContext";

export const AssessmentSidebar = observer(({ assessment, canSave }) => {
    return (
        <Sidebar variant="medium" float>
            <ActivityStreamContext
                showHeader={true}
                context={{
                    id: assessment.id,
                    type: "assessment",
                    name: assessment.code,
                    canSave,
                }}
                onRenderAttachment={(attachment) => {
                    if (attachment.version === "2.0.0") {
                        if (attachment.type === "activity/stateflow-change") {
                            return <StateFlowChangeCard attachment={attachment} />;
                        }
                    }
                    return null;
                }}
            />
        </Sidebar>
    );
});
