import React, { Component, Fragment } from "react";
import { observer } from "mobx-react";

import { WorkItemField } from "./WorkItemField";

export const WorkItemZone = observer(
    class WorkItemZone extends Component {
        render() {
            const { zone, schema, workItem, defaultZone, disabled, readOnly } = this.props;

            if (!schema || !schema.extensions || !workItem) {
                return null;
            }

            return (
                <Fragment>
                    <r-grid columns="1" style={{ margin: 0 }}>
                        {schema.extensions
                            .filter((field) => (defaultZone && !field.zone) || field.zone == zone)
                            .map((field) => {
                                const visible = this._isFieldVisible(schema, field, workItem);
                                return (
                                    visible && (
                                        <r-cell span="1" key={field.name}>
                                            <WorkItemField
                                                disabled={disabled}
                                                readOnly={readOnly}
                                                visible={visible}
                                                required={this._isFieldRequired(schema, field, workItem)}
                                                zone={zone}
                                                schema={schema}
                                                field={field}
                                                workItem={workItem}
                                            />
                                        </r-cell>
                                    )
                                );
                            })}
                    </r-grid>
                </Fragment>
            );
        }

        _isFieldVisible(schema, field, workItem) {
            if (!field.visibleWhen) {
                return true;
            }

            return eval(field.visibleWhen);
        }

        _isFieldRequired(schema, field, workItem) {
            if (field.required == "Yes") {
                return true;
            }

            if (field.required == "No") {
                return false;
            }

            if (!field.requiredWhen) {
                return true;
            }

            return eval(field.requiredWhen);
        }
    }
);
