import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { ObservablePagination } from '@baseComponents/ObservablePagination';

import { PropertySectionNewPanel } from '@properties/containers/panels/PropertySectionNewPanel';
import { PropertySectionEditPanel } from '@properties/containers/panels/PropertySectionEditPanel';
import { PropertySectionGridView } from '@properties/components/sections/PropertySectionGridView';
import { PropertySectionCommandBar } from '@properties/components/sections/PropertySectionCommandBar';

const getQueryStringParams = query => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
};

export const AssetSectionsTab = inject('assetStore', 'propertyStore', 'routing')(observer(
    class AssetSectionsTab extends Component {

        componentWillMount() {
            this._onLoadData(this.props);
        }

/*         componentWillReceiveProps(nextProps) {
            const { assetStore, routing } = nextProps;
            const { settingsStore } = assetStore;

            const params = getQueryStringParams(routing.location.search);
            settingsStore.loadSettings({ page: params["p"] || 1, query: { keywords: params["k"] } });
        } */
    
        render () {
            const { assetStore, propertyStore, routing } = this.props;
            const { loading, sectionsQuery, pagination, sections } = assetStore.settingsStore;

            return (
                <>
                    <>
                        <PropertySectionCommandBar 
                            query={sectionsQuery}
                            onNewSection={() => propertyStore.sectionNewStore.show({ section: { target: 'Asset', order: 100 } }) }
                            onSearchChange={(keywords) => { 
                                if(keywords) {
                                    routing.push(`/settings/assets?k=${encodeURIComponent(keywords)}`);
                                } else {
                                    routing.push(`/settings/assets`);
                                };
                            }} />
                                
                        <PropertySectionGridView 
                            sections={sections}
                            loading={loading}
                            query={sectionsQuery}
                            onPropertyClick={(asset) => routing.push(`/settings/assets/${asset.id}`)} />

                        <ObservablePagination 
                            {...pagination}
                            onPageChange={(index) => { 
                                routing.push(`/settings/assets?p=${index + 1}${sectionsQuery && sectionsQuery.keywords ? `&k=${encodeURIComponent(sectionsQuery.keywords)}` : ''}`);
                            }} />
                    </>
                    <PropertySectionNewPanel onAfterSave={() => this._onLoadData(this.props)} />
                    <PropertySectionEditPanel onAfterSave={() => this._onLoadData(this.props)} />    
                </>
            );
        }

        _onLoadData = (props) => {
            const { assetStore, routing } = props;
            const { settingsStore } = assetStore;

            const params = getQueryStringParams(routing.location.search);
            settingsStore.loadPropertySections({ page: params["p"] || 1, query: { keywords: params["k"] } });
        };
    }
));
