import React, { Component } from "react";
import { observer } from "mobx-react";
import * as moment from "moment";
import Moment from "react-moment";

import { PersonaSize } from "office-ui-fabric-react/lib/Persona";
import { MessageBar } from "office-ui-fabric-react/lib/MessageBar";
import { DetailsListLayoutMode, Selection, SelectionMode, ConstrainMode } from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { ContextualMenu } from "office-ui-fabric-react/lib/ContextualMenu";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { WrappedContent } from "@modules/base/components/WrappedContent";
import { PhotoPersona } from "@modules/identity/containers/utilities/PhotoPersona";
import { isNotNullOrEmpty } from "@modules/editors/components/RichTextField";
import RichTextViewer from "@modules/editors/components/RichTextViewer";

export const TreatmentGridView = observer(
    class TreatmentGridView extends Component {
        constructor(props) {
            super(props);

            this.state = {};

            this._selection = new Selection({
                onSelectionChanged: () => {
                    this.setState({
                        selectionDetails: this._getSelectionDetails(),
                    });
                },
            });

            this._columns = [
                {
                    key: "status",
                    name: "Status",
                    fieldName: "status",
                    minWidth: 150,
                    maxWidth: 150,
                    isRowHeader: true,
                    isResizable: true,
                    isSorted: true,
                    isSortedDescending: true,
                    sortAscendingAriaLabel: "Sorted A to Z",
                    sortDescendingAriaLabel: "Sorted Z to A",
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return (
                            <WrappedContent>
                                <div className="row--xs">
                                    <a
                                        href="#"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            ev.stopPropagation();
                                            if (this.props.onTreatmentClick) {
                                                this.props.onTreatmentClick(item);
                                            }
                                        }}
                                    >
                                        {item.removedInReview ? "Closed" : moment(item.startDate).isAfter() ? "Planned" : "Current"}
                                        <br />
                                        Started <Moment date={item.startDate} format="DD MMM YYYY" />
                                    </a>
                                </div>
                            </WrappedContent>
                        );
                    },
                    isPadded: true,
                },
                {
                    key: "type",
                    name: "Type",
                    fieldName: "type",
                    minWidth: 75,
                    maxWidth: 90,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    isPadded: true,
                },
                {
                    key: "description",
                    name: "Description",
                    fieldName: "description",
                    minWidth: 450,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return (
                            <div>
                                {isNotNullOrEmpty(item.description) ? (
                                    <RichTextViewer className="description" value={item.description} />
                                ) : (
                                    <span>No control description set</span>
                                )}
                            </div>
                        );
                    },
                    isPadded: true,
                },
                {
                    key: "reviewDate",
                    name: "Review Date",
                    fieldName: "reviewDate",
                    minWidth: 85,
                    maxWidth: 100,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return <div>{item.revieweDate && <Moment date={item.revieweDate} format="DD MMM YYYY" />}</div>;
                    },
                    isPadded: true,
                },
                {
                    key: "assignedTo",
                    name: "Assigned To",
                    fieldName: "assignedTo",
                    minWidth: 130,
                    maxWidth: 150,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return item.assigned ? (
                            <div>
                                <div className="row--xs">
                                    Assigned <Moment date={item.assigned} format="DD MMM YYYY" />
                                </div>
                                <PhotoPersona
                                    principalId={item.assignedTo ? item.assignedTo.id : null}
                                    size={PersonaSize.size28}
                                    text={item.assignedTo.name}
                                    showSecondaryText={true}
                                    secondaryText={item.assignedTo.jobTitle}
                                />
                            </div>
                        ) : null;
                    },
                    isPadded: true,
                },
            ];
        }

        render() {
            const { treatments, loading, className, onRiskClick, disableSelection } = this.props;
            const { contextualMenuProps } = this.state;

            return (
                <BoxPanel shadow>
                    <ShimmeredDetailsList
                        items={treatments}
                        compact={false}
                        columns={this._columns}
                        selectionMode={disableSelection ? SelectionMode.none : SelectionMode.none}
                        getKey={this._getKey}
                        setKey="single"
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.horizontalConstrained}
                        isHeaderVisible={true}
                        selection={this._selection}
                        selectionPreservedOnEmptyClick={true}
                        onItemInvoked={onRiskClick}
                        //onItemContextMenu={(item, index, ev) => this._onItemContextMenu(item, index, ev) }
                        enterModalSelectionOnTouch={true}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        checkButtonAriaLabel="Row checkbox"
                        enableShimmer={loading}
                        removeFadingOverlay={true}
                        shimmerLines={5}
                        ariaLabelForShimmer="Risk reviews being fetched"
                        ariaLabelForGrid="Risk reviews list"
                    />

                    {!loading && !treatments.length && <MessageBar>There are no matching treatments.</MessageBar>}

                    {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}
                </BoxPanel>
            );
        }

        _getKey(item, index) {
            return item ? item.id : index;
        }

        _getSelectionDetails() {
            const selectionCount = this._selection.getSelectedCount();

            switch (selectionCount) {
                case 0:
                    return "No items selected";
                case 1:
                    return "1 item selected: " + this._selection.getSelection()[0].name;
                default:
                    return `${selectionCount} items selected`;
            }
        }

        _onColumnClick(ev, column) {
            console.log(column);
        }

        _onItemContextMenu(item, index, ev) {
            const contextualMenuProps = {
                target: ev.target,
                items: [
                    {
                        key: "text",
                        name: `${this._selection.getSelectedCount()} selected`,
                    },
                ],
                onDismiss: () => {
                    this.setState({
                        contextualMenuProps: undefined,
                    });
                },
            };

            if (index > -1) {
                this.setState({
                    contextualMenuProps: contextualMenuProps,
                });
            }

            return false;
        }
    }
);
