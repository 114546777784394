import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';
import { cx } from 'emotion';

import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { IconButton } from 'office-ui-fabric-react/lib/Button';

export const PagePartMenu = observer(
  class PagePartMenu extends PureComponent {
    render () {
        const { className, instance, section, zone, part, provided } = this.props;
        const canBeDeleted = this.props.canBeDeleted ? this.props.canBeDeleted(instance, part) : true;
        return (
          <div className={cx(className, 'animated fadeIn fast')}>
            <div className="wrapper">
              <IconButton 
                iconProps={{iconName: 'Edit'}} 
                title="Edit part" ariaLabel="Edit part" 
                onClick={() => instance.lifecycleStore.onEditPart(section, zone, part)} />
              <div type="button" title="Move part" className="move ms-Button ms-Button--icon" 
                aria-label="Move part" data-is-focusable="true"
                {...provided.dragHandleProps}>
                <span className="ms-Button-flexContainer" data-automationid="splitbuttonprimary">
                  <Icon 
                    iconName={'Move'} 
                    title="Move part" ariaLabel="Move part"
                    />
                </span>
              </div>
              <IconButton 
                iconProps={{iconName: 'Copy'}} 
                title="Duplicate part" ariaLabel="Duplicate part"
                onClick={() => instance.lifecycleStore.onDuplicatePart(section, zone, part)} />
              { canBeDeleted && <IconButton 
                iconProps={{iconName: 'Delete'}} 
                title="Delete part" ariaLabel="Delete part"
                onClick={() => instance.lifecycleStore.onDeletePart(section, zone, part)} />
              }
            </div> 
          </div>
        );
    }
  }
);