import { computed } from "mobx";

import { DataSourceStore } from "./DataSourceStore";

export class  DataSourcePermissionStore {
    public parentStore: DataSourceStore;

    constructor(parentStore: DataSourceStore) {
        this.parentStore = parentStore;
    }

}
