import React, { PureComponent } from "react";
import { observer } from "mobx-react";

import Moment from "react-moment";

import { StatusProgress, StatusProgressStep } from "@modules/base/components/StatusProgress";
import { isTargetEndState } from "@modules/stateflows/stores/TargetMetadata";

export const StateFlowProgressBar = observer(
    class StateFlowProgressBar extends PureComponent {
        render() {
            const { subject, manifest, state, subState, subStateId, className } = this.props;

            if (!manifest || !subject) {
                return <StatusProgress className={className}></StatusProgress>;
            }

            let currentState = manifest.states.find((s) => s.id == subStateId);
            if (!currentState) {
                currentState = manifest.states.find((s) => s.name == subState);
            }

            const indexOfCurrent = currentState ? manifest.stages.findIndex((s) => s.id == currentState.stage) : -1;

            return (
                <StatusProgress className={className}>
                    {manifest.stages.map((stage, stageIndex) => (
                        <StatusProgressStep
                            key={stage.id}
                            title={stage.name}
                            active={stageIndex == indexOfCurrent}
                            done={stageIndex < indexOfCurrent}
                            blocked={stageIndex == indexOfCurrent && currentState.blocked}
                        >
                            {subject.workflowData &&
                            subject.workflowData.activities &&
                            subject.workflowData.activities[stage.id] ? (
                                <>
                                    {stageIndex < indexOfCurrent ? (
                                        <span>
                                            Completed{" "}
                                            <Moment
                                                date={subject.workflowData.activities[stage.id].timestamp}
                                                format="Do MMM YYYY"
                                            />
                                        </span>
                                    ) : stageIndex == indexOfCurrent ? (
                                        <span>
                                            {isTargetEndState(manifest.target, currentState)
                                                ? "Completed "
                                                : "Last activity "}
                                            <Moment
                                                date={subject.workflowData.activities[stage.id].timestamp}
                                                format="Do MMM YYYY"
                                            />
                                        </span>
                                    ) : (
                                        <span>{stage.placeholder}</span>
                                    )}
                                </>
                            ) : (
                                // <>
                                //     {stage.subText ? (
                                //         <span>
                                //             {this._evalStage(stage.subText, subject, {
                                //                 moment,
                                //                 manifest,
                                //                 stage,
                                //                 whenActive: (val, def) => (stageIndex == indexOfCurrent ? val : def),
                                //                 whenDoneOnActive: (val, def) =>
                                //                     stageIndex <= indexOfCurrent ? val : def,
                                //                 whenDone: (val, def) => (stageIndex < indexOfCurrent ? val : def),
                                //                 whenBlocked: (val, def) =>
                                //                     stageIndex == indexOfCurrent && currentState.blocked ? val : def,
                                //                 timestamp: timestamps[stage.id],
                                //             })}
                                //         </span>
                                //     ) : (
                                //         <>
                                //             {stageIndex < indexOfCurrent ? (
                                //                 <span>
                                //                     Completed{" "}
                                //                     <Moment
                                //                         date={subject.workflowData.activities[stage.id].timestamp}
                                //                         format="Do MMM YYYY"
                                //                     />
                                //                 </span>
                                //             ) : stageIndex == indexOfCurrent ? (
                                //                 <span>
                                //                     {isTargetEndState(currentState.mappedTo)
                                //                         ? "Completed "
                                //                         : "Last activity "}
                                //                     <Moment
                                //                         date={subject.workflowData.activities[stage.id].timestamp}
                                //                         format="Do MMM YYYY"
                                //                     />
                                //                 </span>
                                //             ) : (
                                //                 <span>{stage.placeholder}</span>
                                //             )}
                                //         </>
                                //     )}
                                // </>
                                <span>{stage.placeholder}</span>
                            )}
                        </StatusProgressStep>
                    ))}
                </StatusProgress>
            );
        }

        _evalStage(expression, subject, $) {
            if (!expression) {
                return null;
            }
            return eval(expression);
        }
    }
);
