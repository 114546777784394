import React, { Component } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { Label } from "office-ui-fabric-react/lib/Label";
import RichTextEditor from "./RichTextEditor";

export const isNotNullOrEmpty = (content) => {
    return (
        content &&
        content.length > 0 &&
        (content.length != 1 ||
            content[0].type != "paragraph" ||
            content[0].children.length > 1 ||
            content[0].children.length == 0 ||
            content[0].children[0].text)
    );
};

export const toJsonFromString = (content) => {
    return content
        ? content.split("\n").map((s) => ({
              type: "paragraph",
              children: [{ text: s }],
          }))
        : null;
};

export const toStringFromJson = (content) => {
    return content && content.length > 0
        ? content
              .filter((s) => s.type === "paragraph")
              .map((s) => s.children.map((c) => c.text).join(" "))
              .join("\n")
        : null;
};

export const RichTextField = observer(
    class RichTextField extends Component {
        render() {
            const { value, onChange, label, placeholder, required, disabled, onKeyPress, className, dataTest } =
                this.props;

            return (
                <div className="ms-TextField ms-TextField--multiline" data-test={dataTest || "RichTextField"}>
                    <div className="ms-TextField-wrapper">
                        {label && <Label required={required}>{label}</Label>}
                        <div
                            className={cx(
                                className,
                                "ms-TextField-fieldGroup",
                                css`
                                    box-shadow: none;
                                    border-width: 1px;
                                    border-style: solid;
                                    border-color: var(--black);
                                    border-radius: 2px;
                                    background: var(--white);

                                    & div:first-child {
                                        padding: 5px 10px;
                                        border-bottom: 1px solid var(--border-color-darker);
                                    }

                                    & div:last-child {
                                        padding: 6px 11px;
                                        min-height: 80px;
                                    }
                                `
                            )}
                        >
                            <RichTextEditor
                                placeholder={placeholder}
                                required={required}
                                disabled={disabled}
                                value={value}
                                onChange={onChange}
                                onKeyPress={onKeyPress}
                                className="ms-TextField-field"
                            />
                        </div>
                    </div>
                </div>
            );
        }
    }
);
