import React from "react";
import { observer } from "mobx-react";
import Moment from "react-moment";

import { Label } from "office-ui-fabric-react/lib/Label";

import { ObservableSeparator } from "../../../base/components/ObservableSeparator";

import { SchemaFieldGridView } from "./SchemaFieldGridView";

export const SchemaSummary = observer(({ className, loading, schema }) => {
    return (
        <div className={className}>
            <div className="row--l">
                <r-grid columns="2" columns-l="4">
                    <r-cell>
                        <div className="row--s">
                            <Label>Created</Label>
                            <div>{schema && schema.version ? <Moment utc date={schema.created} fromNow /> : "N/A"}</div>
                        </div>
                    </r-cell>
                    <r-cell>
                        <div className="row--s">
                            <Label>Version</Label>
                            <div>{(schema && schema.version) || "N/A"}</div>
                        </div>
                    </r-cell>
                </r-grid>
            </div>
            <div className="row--l">
                <ObservableSeparator>Override Fields</ObservableSeparator>
                <SchemaFieldGridView loading={loading} fields={schema.overrides} />
            </div>
            <div className="row--l">
                <ObservableSeparator>Extension Fields</ObservableSeparator>
                <SchemaFieldGridView loading={loading} fields={schema.extensions} />
            </div>
        </div>
    );
});
