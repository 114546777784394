import React, { Component } from "react";
import { observer, inject } from "mobx-react";

import { PrincipalPickerStore } from "../stores/PrincipalPickerStore";
import { PrincipalPicker as StatelessPrincipalPicker } from "../components/PrincipalPicker";

export const PrincipalPicker = inject(
    "rootStore",
    "principalContext"
)(
    observer(
        class PrincipalPicker extends Component {
            constructor(props) {
                super(props);

                this.state = {
                    pickerStore: new PrincipalPickerStore(this.props.rootStore),
                };
            }

            componentWillMount() {
                const { pickerStore } = this.state;
                const { multiple, selected, selectedId, selectedIds } = this.props;
                if (typeof selected !== "undefined") {
                    pickerStore.setSelected(multiple ? selected || [] : selected ? [selected] : []);
                } else if (!multiple) {
                    pickerStore.setSelectedWithId(selectedId);
                } else {
                    pickerStore.setSelectedWithIds(selectedIds);
                }
            }

            componentWillReceiveProps(nextProps) {
                const { pickerStore } = this.state;
                const { multiple, selected, selectedId, selectedIds } = nextProps;

                if (
                    JSON.stringify(selected) != JSON.stringify(this.props.selected) ||
                    selectedId != this.props.selectedId ||
                    JSON.stringify(selectedIds) != JSON.stringify(this.props.selectedIds)
                ) {
                    if (typeof selected !== "undefined") {
                        pickerStore.setSelected(multiple ? selected || [] : selected ? [selected] : []);
                    } else if (!multiple) {
                        pickerStore.setSelectedWithId(selectedId);
                    } else {
                        pickerStore.setSelectedWithIds(selectedIds);
                    }
                }
            }

            render() {
                const { pickerStore } = this.state;
                const { multiple, onSelected, selectedGroup, onClearFilter, showClearFilter, mode } = this.props;
                const { selectedPrincipals, loading } = pickerStore;

                const selected = multiple
                    ? selectedPrincipals
                    : selectedPrincipals.length
                    ? selectedPrincipals[0]
                    : null;

                return (
                    <StatelessPrincipalPicker
                        {...this.props}
                        loading={loading}
                        selected={selected}
                        showClearFilter={showClearFilter}
                        onClearFilter={() => {
                            pickerStore.setSelected([]);
                            if (onClearFilter) onClearFilter();
                        }}
                        onFilter={(text, current, limit) => {
                            return pickerStore.searchPrincipals({
                                page: 1,
                                keywords: text,
                                selectedGroup: selectedGroup,
                            });
                        }}
                        onSelected={(principals) => {
                            const updated = multiple ? principals || [] : principals ? [principals] : [];

                            if (mode === "external") {
                                if (onSelected) {
                                    onSelected(multiple ? updated : updated.length ? updated[0] : null);
                                }
                                return;
                            }

                            if (updated.length > 0 && mode !== "lookup") {
                                return pickerStore.resolvePrincipals(updated).then((resolved) => {
                                    pickerStore.setSelected(resolved);
                                    if (onSelected) {
                                        onSelected(multiple ? resolved : resolved.length ? resolved[0] : null);
                                    }
                                });
                            } else {
                                pickerStore.setSelected(updated);
                                if (onSelected) {
                                    onSelected(multiple ? updated : updated.length ? updated[0] : null);
                                }
                            }
                        }}
                    />
                );
            }
        }
    )
);
