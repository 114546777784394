import React from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { Stack } from "office-ui-fabric-react/lib/Stack";

import { ContextContainerHost } from "@modules/workflows/containers/utilities/ContextContainerHost";
import { TaskContainerHost } from "@modules/workflows/containers/utilities/TaskContainerHost";
import { AutomationContainerHost } from "@modules/workflows/containers/utilities/AutomationContainerHost";

export const RiskToolBarHost = observer(({ risk }) => {
    const { routingManager, riskStore } = useStores();

    const canManageTasks = riskStore.lifecycleStore.canManageTasks;
    const canManageAutomations = riskStore.lifecycleStore.canManageAutomations;

    const context = { id: risk.id, type: "risk", label: risk.code, resource: risk };

    return (
        <Stack horizontal tokens={{ childrenGap: 0 }}>
            <ContextContainerHost context={context} />
            <TaskContainerHost context={context} routingManager={routingManager} canManage={canManageTasks} />
            <AutomationContainerHost
                context={context}
                routingManager={routingManager}
                canManage={canManageAutomations}
            />
        </Stack>
    );
});
