import React, { Component } from "react";
import { observer } from "mobx-react";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

import { MessageBar } from "office-ui-fabric-react/lib/MessageBar";

import { BoxPanel } from "@modules/base/components/BoxPanel";

export const AssessmentCalendarView = observer(
    class AssessmentCalendarView extends Component {
        render() {
            const { className, events, loading, view, date, onDatesSet, onEventClick } = this.props;

            return (
                <BoxPanel className={className} shadow padding>
                    <FullCalendar
                        plugins={[dayGridPlugin, interactionPlugin]}
                        initialView={`dayGrid${view || "Month"}`}
                        weekends={false}
                        initialDate={date}
                        locale="en-gb"
                        headerToolbar={{
                            left: "prev,next today",
                            center: "title",
                            right: "dayGridDay,dayGridWeek,dayGridMonth",
                        }}
                        eventClick={onEventClick}
                        dateClick={(info) => {
                            const calendarApi = info.view.calendar;
                            calendarApi.changeView("dayGridDay", info.date);
                        }}
                        datesSet={onDatesSet}
                        events={events}
                    />
                    {!loading && !events.length && <MessageBar>There are no matching assessments.</MessageBar>}
                </BoxPanel>
            );
        }
    }
);
