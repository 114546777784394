import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { ObservablePagination } from '../../../base/components/ObservablePagination';

import { PropertyNewPanel } from '../../../properties/containers/panels/PropertyNewPanel';
import { PropertyEditPanel } from '../../../properties/containers/panels/PropertyEditPanel';
import { PropertySectionNewPanel } from '../../../properties/containers/panels/PropertySectionNewPanel';
import { PropertySectionEditPanel } from '../../../properties/containers/panels/PropertySectionEditPanel';
import { PropertyGridView } from '../../../properties/components/core/PropertyGridView';
import { PropertyCommandBar } from '../../../properties/components/core/PropertyCommandBar';

const getQueryStringParams = query => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
};

export const EntityPropertiesTab = inject('entityStore', 'propertyStore', 'routing')(observer(
    class EntityPropertiesTab extends Component {

        componentWillMount() {
            this._onLoadData(this.props);
        }

/*         componentWillReceiveProps(nextProps) {
            const { entityStore, routing } = nextProps;
            const { settingsStore } = entityStore;

            const params = getQueryStringParams(routing.location.search);
            settingsStore.loadProperties({ page: params["p"] || 1, query: { keywords: params["k"] } });
        } */
    
        render () {
            const { entityStore, propertyStore, routing } = this.props;
            const { loading, propertiesQuery, pagination, properties } = entityStore.settingsStore;

            return (
                <>
                    <>
                        <PropertyCommandBar 
                                query={propertiesQuery}
                                onNewProperty={() => propertyStore.newStore.show({ property: { target: 'Entity' } }) }
                                onNewSection={() => propertyStore.sectionNewStore.show({ section: { target: 'Entity' } }) }
                                onSearchChange={(keywords) => { 
                                    if(keywords) {
                                        routing.push(`/settings/entities?k=${encodeURIComponent(keywords)}`);
                                    } else {
                                        routing.push(`/settings/entities`);
                                    };
                                }} />
                                
                        <PropertyGridView 
                            properties={properties}
                            loading={loading}
                            query={propertiesQuery}
                            onPropertyClick={(entity) => routing.push(`/settings/entities/${entity.id}`)} />

                        <ObservablePagination 
                            {...pagination}
                            onPageChange={(index) => { 
                                routing.push(`/settings/entities?p=${index + 1}${propertiesQuery && propertiesQuery.keywords ? `&k=${encodeURIComponent(propertiesQuery.keywords)}` : ''}`);
                            }} />
                    </>
                    <PropertyNewPanel onAfterSave={() => this._onLoadData(this.props)} />
                    <PropertyEditPanel onAfterSave={() => this._onLoadData(this.props)} />
                    <PropertySectionNewPanel />
                    <PropertySectionEditPanel />    
                </>
            );
        }

        _onLoadData = (props) => {
            const { entityStore, routing } = props;
            const { settingsStore } = entityStore;

            const params = getQueryStringParams(routing.location.search);
            settingsStore.loadProperties({ page: params["p"] || 1, query: { keywords: params["k"] } });
        };
    }
));
