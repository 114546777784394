import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { ErrorPanel } from "@modules/layout/components/ErrorPanel";

export const ModulePage = observer(({ children, error, hasPageNav, hasSidebar, breadcrumb, loading, className }) => {
    const { pageContext } = useStores();

    useEffect(() => {
        pageContext.setCurrent({ error, hasPageNav, hasSidebar, breadcrumb, loading, className });
    }, [pageContext, error, hasPageNav, hasSidebar, breadcrumb, loading, className]);

    return (
        <div className="module">
            <div className="module__wrapper">{error ? <ErrorPanel error={error} /> : children}</div>
        </div>
    );
});
