import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { PanelType } from 'office-ui-fabric-react/lib/Panel';
import { Text } from 'office-ui-fabric-react/lib/Text';

import { ObservablePanel } from '../../../base/components/ObservablePanel';

export const TriageHelpPanel = inject('triageStore')(observer(
    class TriageHelpPanel extends Component {
        
        render () {
            const { content, visible } = this.props.triageStore.helpStore;
            
            return (
                <ObservablePanel
                    hidden={!visible}
                    pageTitle={content && content.name ? content.name : 'N/A'}
                    pageType={content && content.type ? content.type : 'Help'}
                    panelType={PanelType.small}
                    onClosePanel={this._onClosePanel}>
                    { content && this._renderCore() }
                </ObservablePanel>
            );
        }

        _renderCore() {
            const { content } = this.props.triageStore.helpStore;

            return (
                <div>
                    <div className="row--xs">
                        <div className="row--xs">
                            <Text variant="large">Description</Text>
                        </div>                        
                        {content.description.map(d => (
                            <p key={d} className="row--xs">
                                <Text variant="small">{d}</Text>
                            </p>
                        ))}
                    </div>

                    <div className="row--xs">
                        <div className="row--xs">
                            <Text variant="large">Business Examples</Text>
                        </div>
                        {content.notes && <Text>{content.notes}</Text>}
                        {content.examples.map(e => (
                            <p key={e} className="row--xs">
                                <Text variant="medium">{e.title}</Text>
                                <ul>
                                    { e.points.map((p) => (
                                        <li key={p}>
                                            <Text variant="small">{p}</Text>    
                                        </li>
                                    )) }
                                </ul>
                            </p>
                        ))}
                    </div>
                </div>
            );
        }

        _onClosePanel = () => {
            const { helpStore } = this.props.triageStore;
            helpStore.hide({});
        };
    }
));