import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { FilterBar } from "@modules/base/components/FilterBar";

export const RiskFilterBar = observer(({ value, onChange, context, disabled }) => {
    const { riskStore } = useStores();
    const [subStatus, setSubStatus] = useState([]);

    useEffect(() => {
        riskStore.riskService.getSubStatuses("zerodai:risks:generic").then((subStatus) => {
            setSubStatus(subStatus.filter((s) => s !== null));
        });
    }, []);

    const filters = [
        {
            name: "keywords",
            label: "Keywords",
            type: "Search",
            keywords: true,
            placeholder: "Search by unique id or title",
        },
        // {
        //     name: "registry",
        //     label: "Registry",
        //     type: "Registry",
        //     multiple: true,
        //     allowClear: true,
        //     query: {},
        // },
        {
            name: "portfolio",
            label: "Portfolio",
            type: "Portfolio",
            multiple: true,
            allowClear: true,
            query: {},
        },
        {
            name: "subStatus",
            label: "Status",
            type: "Dropdown",
            multiple: true,
            allowClear: true,
            options: subStatus.map((s) => ({ key: s, text: s })),
        },
        {
            name: "assignedToUser",
            label: "Assigned To",
            type: "Principal",
            multiple: true,
            allowClear: true,
        },
        {
            name: "grossScore",
            label: "Gross Score",
            type: "Dropdown",
            multiple: true,
            allowClear: true,
            options: [
                { key: "None", text: "None" },
                { key: "Minor", text: "Minor" },
                { key: "Moderate", text: "Moderate" },
                { key: "Major", text: "Major" },
                { key: "Critical", text: "Critical" },
            ],
        },
        {
            name: "netScore",
            label: "Net Score",
            type: "Dropdown",
            multiple: true,
            allowClear: true,
            options: [
                { key: "None", text: "None" },
                { key: "Minor", text: "Minor" },
                { key: "Moderate", text: "Moderate" },
                { key: "Major", text: "Major" },
                { key: "Critical", text: "Critical" },
            ],
        },
    ];
    return <FilterBar value={value} onChange={onChange} context={context} filters={filters} disabled={disabled} />;
});
