import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { cx, css } from "emotion";
import { useStores } from "@hooks/useStores";

import { Label } from "office-ui-fabric-react/lib/Label";
import { Text } from "office-ui-fabric-react/lib/Text";

import { FieldSetView } from "@modules/base/components/FieldSetView";

import { capitalize } from "../../../base/capitalize";
import { AppPage } from "../../../layout/containers/AppPage";
import { SettingsListNav } from "../../../settings/containers/utilities/SettingsListNav";
import { RegistryEditPanel } from "../panels/RegistryEditPanel";
import { PhotoPersona } from "../../../identity/containers/utilities/PhotoPersona";
import { RegistryPolicyAccess } from "../../components/registries/RegistryPolicyAccess";
import { RegistryViewerPageHeader } from "../../components/registries/RegistryViewerPageHeader";
import { ObservableSeparator } from "../../../base/components/ObservableSeparator";
import { BoxPanel } from "@base/components/BoxPanel";

export const RiskRegistriesViewerPage = withRouter(
    observer((props) => {
        const { match } = props;
        const { riskStore } = useStores();
        const { riskRegistryViewerPageStore, riskRegistryEditFormStore, registryStore } = riskStore;
        const { breadcrumb, loading, error, riskRegistry } = riskRegistryViewerPageStore;
        const { riskRegistriesRoles } = registryStore;

        useEffect(() => {
            riskRegistryViewerPageStore.loadRegistry(match.params.id);
        }, []);

        return (
            <AppPage
                loading={loading}
                error={error}
                breadcrumb={breadcrumb}
                hasSidebar={false}
                className={cx("portfolios viewer", !error && "has--page-nav")}
            >
                <SettingsListNav />
                <RegistryViewerPageHeader
                    registry={riskRegistry}
                    onEdit={() => {
                        riskRegistryEditFormStore.show(riskRegistry);
                    }}
                />

                <BoxPanel padding shadow>
                    {riskRegistry && (
                        <>
                            <FieldSetView title="Key Information" description="Basic information about this registry.">
                                <r-grid columns="2">
                                    <r-cell>
                                        <div className="row--xs">
                                            <Label>Code Format</Label>
                                            {riskRegistry.codeFormat}
                                        </div>
                                    </r-cell>
                                    <r-cell>
                                        <div className="row--xs">
                                            <Label>Created By</Label>
                                            <PhotoPersona
                                                principalId={riskRegistry.createdBy.id}
                                                text={riskRegistry.createdBy.name}
                                                showSecondaryText={true}
                                                secondaryText={riskRegistry.createdBy.jobTitle}
                                            />
                                        </div>
                                    </r-cell>
                                    <r-cell>
                                        <div className="row--xs">
                                            <Label>Modified By</Label>
                                            <PhotoPersona
                                                principalId={riskRegistry.modifiedBy.id}
                                                text={riskRegistry.modifiedBy.name}
                                                showSecondaryText={true}
                                                secondaryText={riskRegistry.modifiedBy.jobTitle}
                                            />
                                        </div>
                                    </r-cell>
                                </r-grid>
                            </FieldSetView>
                            <FieldSetView
                                title="Guest Access"
                                description="What can guest users (all users in the system) do in this registry."
                            >
                                <r-grid columns="2">
                                    <r-cell>
                                        <div className="row--xs">
                                            <Label>Guests can</Label>
                                            <li>
                                                <RegistryPolicyAccess
                                                    riskRegistriesRoles={riskRegistriesRoles}
                                                    access={
                                                        riskRegistry.policies
                                                            ? riskRegistry.policies.guestRequestorAccess
                                                            : "-"
                                                    }
                                                />
                                            </li>
                                        </div>
                                    </r-cell>
                                </r-grid>
                            </FieldSetView>
                            <FieldSetView
                                title="Registry Approvers"
                                description="Who can perform approval and other administrative tasks in this registry."
                            >
                                <r-grid columns="2">
                                    <r-cell>
                                        <div className="row--xs">
                                            <Label>Primary approver</Label>
                                            {riskRegistry.primaryApprover ? (
                                                <PhotoPersona
                                                    principalId={riskRegistry.primaryApprover.id}
                                                    text={riskRegistry.primaryApprover.name}
                                                    showSecondaryText={true}
                                                    secondaryText={riskRegistry.primaryApprover.jobTitle}
                                                />
                                            ) : (
                                                <Text className="title" block>
                                                    Not set
                                                </Text>
                                            )}
                                        </div>
                                    </r-cell>
                                    <r-cell>
                                        <div className="row--xs">
                                            <Label>Secondary approver</Label>
                                            {riskRegistry.secondaryApprover ? (
                                                <PhotoPersona
                                                    principalId={riskRegistry.secondaryApprover.id}
                                                    text={riskRegistry.secondaryApprover.name}
                                                    showSecondaryText={true}
                                                    secondaryText={riskRegistry.secondaryApprover.jobTitle}
                                                />
                                            ) : (
                                                <Text className="title" block>
                                                    Not set
                                                </Text>
                                            )}
                                        </div>
                                    </r-cell>
                                </r-grid>
                            </FieldSetView>
                            <FieldSetView
                                title="Item Level Access"
                                description="What can stakeholders set against individual items do on those items."
                            >
                                <r-grid columns="2">
                                    <r-cell>
                                        <div className="row--xs">
                                            <Label>Portfolio contact can</Label>
                                            {riskRegistry.policies && (
                                                <li>
                                                    <RegistryPolicyAccess
                                                        riskRegistriesRoles={riskRegistriesRoles}
                                                        access={riskRegistry.policies.portfolioContactAccess}
                                                    />
                                                </li>
                                            )}
                                        </div>
                                    </r-cell>
                                    <r-cell>
                                        <div className="row--xs">
                                            <Label>Project contact can</Label>
                                            {riskRegistry.policies && (
                                                <li>
                                                    <RegistryPolicyAccess
                                                        riskRegistriesRoles={riskRegistriesRoles}
                                                        access={riskRegistry.policies.lifecycleContactAccess}
                                                    />
                                                </li>
                                            )}
                                        </div>
                                    </r-cell>
                                    <r-cell>
                                        <div className="row--xs">
                                            <Label>Assigned agent can</Label>
                                            {riskRegistry.policies && (
                                                <li>
                                                    <RegistryPolicyAccess
                                                        riskRegistriesRoles={riskRegistriesRoles}
                                                        access={riskRegistry.policies.assignedToUserAccess}
                                                    />
                                                </li>
                                            )}
                                        </div>
                                    </r-cell>
                                    <r-cell>
                                        <div className="row--xs">
                                            <Label>Assigned group can</Label>
                                            {riskRegistry.policies && (
                                                <li>
                                                    {" "}
                                                    <RegistryPolicyAccess
                                                        riskRegistriesRoles={riskRegistriesRoles}
                                                        access={riskRegistry.policies.assignedToGroupAccess}
                                                    />
                                                </li>
                                            )}
                                        </div>
                                    </r-cell>
                                </r-grid>
                            </FieldSetView>
                            <FieldSetView
                                title="Role Based Access"
                                description="What can users do in this registry, based on their system-wide role(s)."
                            >
                                <r-grid columns="2">
                                    <r-cell>
                                        <div className="row--xs">
                                            <Label>People that can raise an item</Label>
                                            {riskRegistry.policies &&
                                                riskRegistry.policies.requestorRoles &&
                                                riskRegistry.policies.requestorRoles.map((role) => {
                                                    return <li key={`req_${role}`}> {capitalize(role)} </li>;
                                                })}
                                        </div>
                                    </r-cell>
                                    <r-cell>
                                        <div className="row--xs">
                                            <Label>People that can read all items​</Label>
                                            {riskRegistry.policies &&
                                                riskRegistry.policies.readerRoles &&
                                                riskRegistry.policies.readerRoles.map((role) => {
                                                    return <li key={`rea_${role}`}> {capitalize(role)} </li>;
                                                })}
                                        </div>
                                    </r-cell>
                                    <r-cell>
                                        <div className="row--xs">
                                            <Label>People that can contribute to all items​</Label>
                                            {riskRegistry.policies &&
                                                riskRegistry.policies.contributorRoles &&
                                                riskRegistry.policies.contributorRoles.map((role) => {
                                                    return <li key={`con_${role}`}> {capitalize(role)} </li>;
                                                })}
                                        </div>
                                    </r-cell>
                                    <r-cell>
                                        <div className="row--xs">
                                            <Label>People that can assign items​</Label>
                                            {riskRegistry.policies &&
                                                riskRegistry.policies.assignRoles &&
                                                riskRegistry.policies.assignRoles.map((role) => {
                                                    return <li key={`con_${role}`}> {capitalize(role)} </li>;
                                                })}
                                        </div>
                                    </r-cell>
                                </r-grid>
                            </FieldSetView>
                        </>
                    )}
                </BoxPanel>

                <RegistryEditPanel
                    onSaveRiskRegistry={() => {
                        riskRegistryViewerPageStore.loadRegistry(match.params.id);
                    }}
                />
            </AppPage>
        );
    })
);
