import React from "react";
import { observer } from "mobx-react";
import { Route, Switch } from "react-router";
import { useStores } from "@hooks/useStores";

import { PivotItem } from "office-ui-fabric-react/lib/Pivot";

import { PivotRouting } from "@modules/base/components/PivotRouting";

import { AssessmentAdvisoriesTab } from "@modules/assessments/containers/tabs/AssessmentAdvisoriesTab";
import { AssessmentDocumentsTab } from "@modules/assessments/containers/tabs/AssessmentDocumentsTab";
import { AssessmentRisksTab } from "@modules/assessments/containers/tabs/AssessmentRisksTab";
import { AssessmentExceptionsTab } from "@modules/assessments/containers/tabs/AssessmentExceptionsTab";
import { AssessmentSummaryTab } from "@modules/assessments/containers/tabs/AssessmentSummaryTab";

export const AssessmentTabList = observer(({ assessment, workItem, selectedTab, onUpload }) => {
    const { assessmentStore, riskStore, routing } = useStores();
    const { lifecycleStore } = assessmentStore;
    const { typeStore } = riskStore;

    const hasRisks = typeStore.assessmentTypes.some((t) => t.template === "zerodai:risks:generic");
    const hasExceptions = typeStore.assessmentTypes.some((t) => t.template === "zerodai:risks:exception");

    const defaultTab = "summary";

    const knownTabs = [defaultTab, "documents"];

    if (lifecycleStore.isAdvisoriesTabAvailable) {
        knownTabs.push("advisories");
    }
    if (lifecycleStore.isRisksTabAvailable) {
        if (hasRisks) {
            knownTabs.push("risks");
        }
        if (hasExceptions) {
            knownTabs.push("exceptions");
        }
    }

    const urlPrefix = routing.location.pathname.indexOf("/teams/assurance/") === 0 ? "/teams/assurance" : "/assurance";
    return (
        <>
            <PivotRouting
                data-test="assessmentTabs"
                aria-label="Assurance properties, related documents and advisories"
                selectedKey={knownTabs.indexOf(selectedTab) === -1 ? defaultTab : selectedTab}
                onLinkClick={(item, routing) => {
                    if (item.props.itemKey === defaultTab) {
                        routing.push(`${urlPrefix}/browse/${assessment.id}`);
                    } else {
                        routing.push(`${urlPrefix}/browse/${assessment.id}/${item.props.itemKey}`);
                    }
                }}
            >
                <PivotItem itemKey={defaultTab} headerText="Summary" itemIcon="Info" />
                <PivotItem itemKey="documents" headerText="Related Documents" itemIcon="DocLibrary" />
                {lifecycleStore.isAdvisoriesTabAvailable && (
                    <PivotItem itemKey="advisories" headerText="Advisories" itemIcon="CRMCustomerInsightsApp" />
                )}
                {lifecycleStore.isRisksTabAvailable && hasRisks && (
                    <PivotItem itemKey="risks" headerText="Related Risks" itemIcon="ReportHacked" />
                )}
                {lifecycleStore.isRisksTabAvailable && hasExceptions && (
                    <PivotItem itemKey="exceptions" headerText="Related Exceptions" itemIcon="Shield" />
                )}
            </PivotRouting>

            <Switch>
                <Route
                    exact
                    path={`${urlPrefix}/browse/:id/documents`}
                    component={() => (
                        <AssessmentDocumentsTab
                            assessment={assessment}
                            workItem={workItem}
                            canUpload={() => lifecycleStore.canUpload}
                            onUpload={onUpload}
                        />
                    )}
                />
                {lifecycleStore.isAdvisoriesTabAvailable && (
                    <Route
                        exact
                        path={`${urlPrefix}/browse/:id/advisories`}
                        component={() => <AssessmentAdvisoriesTab assessment={assessment} workItem={workItem} />}
                    />
                )}
                {lifecycleStore.isRisksTabAvailable && hasRisks && (
                    <Route
                        exact
                        path={`${urlPrefix}/browse/:id/risks`}
                        component={() => <AssessmentRisksTab assessment={assessment} workItem={workItem} />}
                    />
                )}
                {lifecycleStore.isRisksTabAvailable && hasExceptions && (
                    <Route
                        exact
                        path={`${urlPrefix}/browse/:id/exceptions`}
                        component={() => <AssessmentExceptionsTab assessment={assessment} workItem={workItem} />}
                    />
                )}
                <Route
                    path={`${urlPrefix}/browse/:id`}
                    component={() => <AssessmentSummaryTab assessment={assessment} workItem={workItem} />}
                />
            </Switch>
        </>
    );
});
