import React from "react";
import { observer } from "mobx-react";
import { Route, Switch } from "react-router";

import { PivotItem } from "office-ui-fabric-react/lib/Pivot";

import { PivotRouting } from "@modules/base/components/PivotRouting";

import { StateFlowSummaryTab } from "./StateFlowSummaryTab";
import { StateFlowVersionsTab } from "./StateFlowVersionsTab";
import { StateFlowAutomationsTab } from "./StateFlowAutomationsTab";

export const StateFlowTabList = observer(({ selectedTab, stateFlow, manifest }) => {
    const defaultTab = "summary";
    const knownTabs = [defaultTab, "versions", "automations"];

    return (
        <>
            <PivotRouting
                data-test="stateFlowTabs"
                aria-label="Workflow properties, preview, versions and automations"
                selectedKey={knownTabs.indexOf(selectedTab) === -1 ? defaultTab : selectedTab}
                onLinkClick={(item, routing) => {
                    if (item.props.itemKey === defaultTab) {
                        routing.push(`/settings/workflows/${stateFlow.id}`);
                    } else {
                        routing.push(`/settings/workflows/${stateFlow.id}/${item.props.itemKey}`);
                    }
                }}
            >
                <PivotItem itemKey={defaultTab} headerText="Preview" />
                <PivotItem itemKey="versions" headerText="Versions" />
                <PivotItem itemKey="automations" headerText="Automations" />
            </PivotRouting>

            <Switch>
                <Route
                    exact
                    path="/settings/workflows/:id/automations"
                    component={() => <StateFlowAutomationsTab stateFlow={stateFlow} manifest={manifest} />}
                />
                <Route
                    exact
                    path="/settings/workflows/:id/versions"
                    component={() => <StateFlowVersionsTab stateFlow={stateFlow} />}
                />
                <Route
                    path="/settings/workflows/:id"
                    component={() => <StateFlowSummaryTab stateFlow={stateFlow} manifest={manifest} />}
                />
            </Switch>
        </>
    );
});
