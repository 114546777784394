import React, { useCallback, useMemo } from "react";
import { createEditor } from "slate";
import { Slate, Editable, withReact } from "slate-react";
import { Element, Leaf } from "./withBase";
import { withLinks } from "./withLinks";

const RichTextViewer = ({ value, placeholder, className }) => {
    const editor = useMemo(() => withReact(withLinks(createEditor())), []);
    const renderElement = useCallback((props) => <Element {...props} />, []);
    const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

    if (!value) {
        const defaultValue = [
            {
                type: "paragraph",
                children: [{ text: "" }],
            },
        ];

        value = defaultValue;
    }

    return (
        <Slate editor={editor} value={value}>
            <Editable readOnly renderElement={renderElement} renderLeaf={renderLeaf} placeholder={placeholder} className={className} />
        </Slate>
    );
};

export default RichTextViewer;
