import React, { Component } from "react";
import { observer } from "mobx-react";

import { Label } from "office-ui-fabric-react/lib/Label";
import { PersonaSize } from "office-ui-fabric-react/lib/Persona";

import { FieldSetView } from "@modules/base/components/FieldSetView";
import { StateFlowPicker } from "@modules/stateflows/containers/pickers/StateFlowPicker";

import { PhotoPersona } from "@modules/identity/containers/utilities/PhotoPersona";
import RichTextViewer from "@modules/editors/components/RichTextViewer";

export const ProductSummary = observer(({ className, product }) => {
    return !product ? null : (
        <div className={className}>
            <FieldSetView title="Key Information" description="Basic information about this product.">
                <r-grid columns="2">
                    <r-cell span="2">
                        <div className="row--xs">
                            <Label>Summary</Label>
                            <div>{product.summary || "N/A"}</div>
                        </div>
                    </r-cell>
                    <r-cell span="2">
                        <div className="row--xs">
                            <Label>Friendly Name</Label>
                            <div>{product.friendlyName || "N/A"}</div>
                        </div>
                    </r-cell>
                    <r-cell>
                        <div className="row--xs">
                            <Label>Availability</Label>
                            <div>{product.availability}</div>
                        </div>
                    </r-cell>
                    <r-cell>
                        <div className="row--xs">
                            <Label>Category</Label>
                            <div>{product.category}</div>
                        </div>
                    </r-cell>
                    <r-cell>
                        <div className="row--xs">
                            <Label>Provider</Label>
                            <div>{product.provider}</div>
                        </div>
                    </r-cell>
                    <r-cell>
                        <div className="row--xs">
                            <Label>State</Label>
                            <div>{product.state}</div>
                        </div>
                    </r-cell>
                </r-grid>
            </FieldSetView>
            <FieldSetView title="Assignment Settings" description="Auto assignment settings for this product.">
                <r-grid columns="2">
                    <r-cell span="2">
                        <div className="row--xs">
                            <Label>Auto-assigned Group</Label>
                            <div>{product.assignedToGroup ? product.assignedToGroup.name : "N/A"}</div>
                        </div>
                    </r-cell>
                    <r-cell>
                        <div className="row--xs">
                            <Label>Auto Assignment Mode</Label>
                            <div>{product.autoAssignmentMode || "none"}</div>
                        </div>
                    </r-cell>
                    <r-cell>
                        {product.autoAssignmentMode === "workItem.assignedToUser" && (
                            <div className="row--xs">
                                <Label>Auto-assigned User</Label>
                                <div>
                                    <PhotoPersona
                                        principalId={product.assignedToUser ? product.assignedToUser.id : null}
                                        size={PersonaSize.size28}
                                        text={product.assignedToUser ? product.assignedToUser.name : "Unassigned"}
                                        showUnknownPersonaCoin={!product.assignedToUser}
                                        showSecondaryText={true}
                                        secondaryText={
                                            product.assignedToUser
                                                ? product.assignedToUser.jobTitle || product.assignedToUser.email
                                                : ""
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    </r-cell>
                </r-grid>
            </FieldSetView>
            <FieldSetView title="Display Settings" description="Display settings for this product.">
                <r-grid columns="2">
                    <r-cell>
                        <div className="row--xs">
                            <Label>Customer facing</Label>
                            <div>{product.isCustomerFacing ? "Yes" : "No"}</div>
                        </div>
                    </r-cell>
                    <r-cell>
                        <div className="row--xs">
                            <Label>Promoted</Label>
                            <div>{product.isPromoted ? "Yes" : "No"}</div>
                        </div>
                    </r-cell>
                </r-grid>
            </FieldSetView>
            <FieldSetView title="Request Settings" description="Request settings for this product.">
                <r-grid columns="2">
                    <r-cell>
                        <div className="row--xs">
                            <Label>Requestable</Label>
                            <div>{product.isRequestable ? "Yes" : "No"}</div>
                        </div>
                    </r-cell>
                    <r-cell>
                        <div className="row--xs">
                            <Label>Internal</Label>
                            <div>{product.isInternal ? "Yes" : "No"}</div>
                        </div>
                    </r-cell>
                    {product.isRequestable && product.isInternal && (
                        <r-cell>
                            <div className="row--xs">
                                <StateFlowPicker
                                    label="Workflow"
                                    target="WorkItem"
                                    readOnly
                                    selectedKey={product.flowId}
                                />
                            </div>
                        </r-cell>
                    )}
                    <r-cell span="2">
                        <div>
                            {product.isRequestable && !product.isInternal && (
                                <div className="row--xs">
                                    <Label>Request URL</Label>
                                    <div>{product.requestUrl || "N/A"}</div>
                                </div>
                            )}
                            {product.isRequestable && product.isInternal && (
                                <div className="row--xs">
                                    <Label>Description Template</Label>
                                    <div>
                                        <RichTextViewer value={product.descriptionTemplate} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </r-cell>
                </r-grid>
            </FieldSetView>
        </div>
    );
});
