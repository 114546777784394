import React, { Component} from 'react';
import { observer } from 'mobx-react';

import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { ContextualMenuItemType } from 'office-ui-fabric-react/lib/ContextualMenu';

export const UsersContextualMenu = observer(
  class UsersContextualMenu extends Component {
    
    constructor(props) {
      super(props)
     }

    render () {
      const { 
        onGroupDeleteClick,
        onGroupAddClick,
        assignedGroups,
        groups,
        userId,
        onEditUserClick,
      } = this.props;

      const menuProps = {
        shouldFocusOnMount: true,
        items: [{
          key: 'edit',
          iconProps: {
            iconName: 'EditContact',
          },
          text: 'Edit user',
          onClick: () => onEditUserClick(userId),
        }]
      };

      // if (assignedGroups) {
      //   const assignedSet = new Set(assignedGroups.map(G=>G.name));
      //   const menuItems = assignedGroups.map(group => {
      //     return {
      //       key: 'delete',
      //       iconProps: {
      //         iconName: 'RemoveFilter',
      //         style: {
      //           color: 'salmon',
      //         }
      //       },
      //       text: group.name,
      //       title: `Remove user from ${group.name}`,
      //       onClick: () => onGroupDeleteClick(userId, group.id),
      //     }
      //   });
      //   menuItems.unshift(
      //     {
      //       key: 'Groups',
      //       itemType: ContextualMenuItemType.Header,
      //       text: 'Groups',
      //       itemProps: {
      //         lang: 'en-us'
      //       }
      //     });
      //   menuItems.push({
      //       key: 'addToGroup',
      //       iconProps: {
      //         iconName: 'Share'
      //       },
      //       subMenuProps: {
      //         items: groups.filter(G=> {
      //           return (!assignedSet.has(G.name))
      //         }).map(G => {
      //           return {
      //             key: `addToGroup${G.name}`,
      //             text: G.name,
      //             title: `Add user to ${G.name}`,
      //             iconProps: {
      //               iconName: 'Add'
      //             },
      //             onClick: () => onGroupAddClick(userId, G.id),
      //         }})
      //       },
      //       text: 'Add to group'
      //   });
      //   menuProps.items = menuItems;
      // };
      // const menuItems = assignedGroups.map(group => {
      //   return {
      //     key: 'edit',
      //     iconProps: {
      //       iconName: 'EditContact',
      //     },
      //     text: 'Edit user',
      //     onClick: () => onEditUserClick(userId),
      //   }
      // });
      
      return (<IconButton iconProps={{iconName: 'More'}}  menuProps={menuProps} />);
    }
  }
);