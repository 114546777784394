import React, { useCallback, useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { PanelType } from "office-ui-fabric-react/lib/Panel";

import { ActionButton, SpinnerButton, CommandButton } from "@ui/elements/Button";
import { Text } from "@ui/elements/Text";

import { ObservablePanel } from "@modules/base/components/ObservablePanel";
import { ActionPanel } from "@modules/base/components/ActionPanel";
import { ObservableSeparator } from "@modules/base/components/ObservableSeparator";

import { ActivityInstanceListView } from "@modules/workflows/components/core/ActivityInstanceListView";
import { ActivityInstanceNewPanel } from "@modules/workflows/containers/panels/ActivityInstanceNewPanel";
import { ActivityInstanceEditPanel } from "@modules/workflows/containers/panels/ActivityInstanceEditPanel";
import { ActivityInstanceDeleteDialog } from "@modules/workflows/containers/panels/ActivityInstanceDeleteDialog";
import { WorkflowConstants } from "@modules/workflows/stores/WorkflowConstants";

import { StateFlowTriggerEditForm } from "@modules/stateflows/components/editor/StateFlowTriggerEditForm";

export const StateFlowTriggerEditPanel = observer(({ store }) => {
    const { workflowStore } = useStores();

    const { visible, formData, formOptions, isValid, processing, context } = store;
    const { filteredCommands, filteredConditions, filteredStates } = store;
    const { loading, instances } = workflowStore.browseStore;

    const onSave = useCallback(() => {
        store.resolve(true);
    }, [store]);

    const onDismiss = useCallback(() => {
        store.resolve(false);
    }, [store]);

    const onInstances = useCallback(() => {
        if (formData.id) {
            workflowStore.browseStore.loadActivityInstances({
                scopes: [{ id: formData.id, type: "trigger" }],
            });
        }
    }, [formData ? formData.id : null]);

    useEffect(() => {
        if (visible) {
            onInstances();
        }
    }, [visible]);

    const onAddActivity = useCallback(
        (ev, item) => {
            if (item && item.key) {
                workflowStore.lifecycleStore
                    .onAddActivity({
                        instance: {
                            definition: {
                                uniqueId: item.key,
                            },
                            trigger: {
                                event: "work-items.stateflow", // needs to be based on the type of activity
                                group: "work-items",
                                condition: null,
                            },
                            scope: {
                                id: formData.id,
                                type: "trigger",
                                label: `${formData.name} from ${formOptions.state.name}`,
                            },
                        },
                        context,
                    })
                    .then((result) => {
                        if (result && result.success) {
                            onInstances();
                        }
                    });
            }
        },
        [store, formData, formOptions, onInstances]
    );

    return (
        <ObservablePanel
            hidden={!visible}
            pageType={(formOptions && formOptions.pageType) || "Workflow Trigger"}
            panelType={PanelType.medium}
            onClosePanel={onDismiss}
            onLightDismissClick={onDismiss}
            onRenderFooter={() => (
                <ActionPanel>
                    <ActionButton onClick={onDismiss} text="Cancel" disabled={processing} />
                    <SpinnerButton
                        onClick={onSave}
                        text="Apply"
                        disabled={!isValid || processing}
                        loading={processing}
                    />
                </ActionPanel>
            )}
        >
            {formData && (
                <>
                    <StateFlowTriggerEditForm
                        formData={formData}
                        formOptions={formOptions}
                        isValid={isValid}
                        {...{ filteredCommands, filteredConditions, filteredStates }}
                        setCommand={(id) => store.setCommand(id)}
                    />
                    <ObservableSeparator
                        className="row--xxs"
                        onRenderSuffix={() => (
                            <CommandButton
                                iconProps={{ iconName: "Add" }}
                                text="Automation"
                                menuProps={{
                                    items: WorkflowConstants.activities.map((a) => ({
                                        key: a.id,
                                        text: a.name,
                                        iconProps: { iconName: a.icon },
                                        onClick: onAddActivity,
                                    })),
                                }}
                            />
                        )}
                    >
                        Automations
                    </ObservableSeparator>
                    <Text className="row--xs" variant="small" block>
                        Applies to all versions of the workflow which has this trigger.
                    </Text>
                    <ActivityInstanceListView
                        simple
                        className="row--s"
                        context={context}
                        loading={loading}
                        instances={instances}
                        canEdit={true}
                        onEdit={(instance) => {
                            workflowStore.lifecycleStore.onEditActivity({ instance, context }).then((result) => {
                                if (result.success) {
                                    onInstances();
                                }
                            });
                        }}
                        onRemove={(instance) => {
                            workflowStore.lifecycleStore.onDeleteActivity({ instance, context }).then((result) => {
                                if (result.success) {
                                    onInstances();
                                }
                            });
                        }}
                    />
                    <ActivityInstanceNewPanel store={workflowStore.newFormStore} />
                    <ActivityInstanceEditPanel store={workflowStore.editFormStore} />
                    <ActivityInstanceDeleteDialog store={workflowStore.deleteFormStore} />
                </>
            )}
        </ObservablePanel>
    );
});
