import { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

export const ContextContainerHost = observer(({ context }) => {
    const { workflowStore } = useStores();

    useEffect(() => {
        if (context && context.id) {
            workflowStore.summaryStore.loadEntitySummary({ id: context.id });
        }
        return () => workflowStore.summaryStore.reset();
    }, [context]);

    return null;
});
