import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { useStores } from "@hooks/useStores";

import { ObservablePagination } from "@modules/base/components/ObservablePagination";

import { IdentityUserGridView } from "@modules/identity/components/users/IdentityUserGridView";
import { IdentityUserCommandBar } from "@modules/identity/components/users/IdentityUserCommandBar";
import { IdentityUserEditPanel } from "@modules/identity/containers/panels/IdentityUserEditPanel";
import { IdentityUserNewPanel } from "@modules/identity/containers/panels/IdentityUserNewPanel";

export const IdentityUserListTab = withRouter(
    observer(({ match }) => {
        const { principalStore, routing, pageContext } = useStores();
        const { lifecycleStore, userBrowseStore } = principalStore;
        const { users, keywords, loading, pagination } = userBrowseStore;

        useEffect(() => {
            pageContext.updateQueryStringParams(routing.location.search);
            userBrowseStore.loadUsers({
                page: pageContext.queryStringParams.p || 1,
                keywords: pageContext.queryStringParams.k,
            });
        }, [match]);

        return (
            <>
                <IdentityUserCommandBar
                    keywords={keywords}
                    onSearchChange={(keywords) => {
                        routing.push(
                            `/settings/identity/users?p=${1}${keywords ? `&k=${encodeURIComponent(keywords)}` : ""}`
                        );
                    }}
                    onAddUsers={(options) => {
                        lifecycleStore.onAddUsers(options);
                    }}
                />

                <IdentityUserGridView
                    users={users}
                    loading={loading}
                    onUserClick={(user) => {
                        lifecycleStore.onEditUser({ user });
                    }}
                />

                <ObservablePagination
                    {...pagination}
                    onPageChange={(index) => {
                        routing.push(
                            `/settings/identity/users?p=${index + 1}${
                                keywords ? `&k=${encodeURIComponent(keywords)}` : ""
                            }`
                        );
                    }}
                />

                <IdentityUserEditPanel store={principalStore.userEditFormStore} />
                <IdentityUserNewPanel store={principalStore.userNewFormStore} />
            </>
        );
    })
);
