import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { PanelType } from 'office-ui-fabric-react/lib/Panel';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { ObservablePanel } from '../../../base/components/ObservablePanel';
import { PartEditForm } from '../../components/parts/PartEditForm';
import { PartTitleEditForm } from '../../components/parts/PartTitleEditForm';

export const PartEditPanel = observer(
    class PartEditPanel extends Component {
        
        render () {
            const { partEditStore, lifecycleStore, selectionStore } = this.props.instance;
            const { visible, formData, formOptions, isValid } = partEditStore;
            
            const registration = formData ? lifecycleStore.getRegistration(formData) : null;
            // console.log("registration",registration);
            return (
                <ObservablePanel
                    hidden={!visible}
                    // pageTitle={formData ? formOptions.pageTitle : 'Page'}
                    pageType={'Edit Part'}
                    panelType={PanelType.small}
                    onClosePanel={() => this._onHidePanel()}
                    onRenderFooter={() => this._renderFooter()}>
                    { formData && ( 
                        <PartTitleEditForm
                            formData={formData}
                            isValid={isValid} />
                    ) }
                    { formData && registration && registration.editor && (
                        <registration.editor 
                            dataSources={selectionStore.dataSources}
                            instance={this.props.instance}
                            formData={formData}
                            isValid={isValid} />
                    ) }
                    { formData && (registration.displayPartEditForm !== false) && ( 
                        <PartEditForm
                            formData={formData}
                            isValid={isValid} />
                    ) }
                </ObservablePanel>
            );
        }

        _renderFooter() {
            const { partEditStore } = this.props.instance;
            const { isValid } = partEditStore;

            return (
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <PrimaryButton onClick={() => this._onSaveSection()} text="Apply" disabled={!isValid} />
                    <DefaultButton onClick={() => this._onHidePanel()} text="Cancel" />
                </Stack>
            );
        }

        _onSaveSection() {
            const { partEditStore } = this.props.instance;
            const { formOptions, formData } = partEditStore;
            formOptions.resolve(formData);
            partEditStore.hide();
        }

        _onHidePanel() {
            const { partEditStore } = this.props.instance;
            const { formOptions } = partEditStore;
            formOptions.resolve();
            partEditStore.hide();
        }
    }
);