import { observable, computed, flow } from "mobx";
import moment from "moment";

import { AssessmentService } from '../../../api/assessments';
import { AssessmentStore } from "./AssessmentStore";

export class AssessmentCalendarStore {

    public parentStore: AssessmentStore;
    public assessmentService: AssessmentService;

    @observable public assessments: any[] = [];
    @observable public keywords: string = '';
    @observable public view: string = 'Month';
    @observable public loading: boolean = false;
    @observable public error: any;
    @observable public breadcrumb: any[] = [
        { text: 'Assurance Management', key: 'assurance', onClick: () => this._goToUrl(`/assurance/dashboard`) },
        { text: 'Assessment Calendar', key: 'calendar', isCurrentItem: true },
    ];

    constructor(parentStore: AssessmentStore) {
        this.parentStore = parentStore;
        this.assessmentService = parentStore.assessmentService;
    }

    @computed
    public get events() {
        let events = [];

        events = events.concat(this.assessments.filter(a => !!a.started).map(a => {
            return {
                id: `${a.id}-started`,
                title: `${a.code} - Started - ${a.title}`,
                start: a.started,
                allDay: false,
                assessment: {...a},
                backgroundColor: 'var(--blue-9)',
                borderColor: 'var(--blue-9)'
            };
        }));

        events = events.concat(this.assessments.filter(a => !!a.closed).map(a => {
            return {
                id: `${a.id}-closed`,
                title: `${a.code} - Closed - ${a.title}`,
                start: a.closed,
                allDay: false,
                assessment: {...a},
                backgroundColor: 'var(--green-9)',
                borderColor: 'var(--green-9)'
            };
        }));

        events = events.concat(this.assessments.filter(a => !!a.dueDate).map(a => {
            return {
                id: `${a.id}-due`,
                title: `${a.code} - Due - ${a.title}`,
                start: a.dueDate,
                allDay: true,
                assessment: {...a},
                backgroundColor: 'var(--pink-9)',
                borderColor: 'var(--pink-9)'
            };
        }));

        return events;
    }

    public loadAssessments = flow(function*(query) {
        var options = Object.assign({ keywords: null, view: 'Month' }, query);
        this.loading = true;
        this.assessments = [];
        this.view = options.view;
        this.date = moment.utc(options.date).toDate();

        try {
            const result = yield this.assessmentService.searchAssessments({ 
                pageSize: 250, 
                startIndex: 0,
                query: {
                    keywords: options.keywords,
                    status: ['Started', 'Remediation','Reviewing','Closed'],
                    timeline: {
                        after: options.start,
                        before: options.end,
                    }
                }
            });
            this.assessments = result.items;
            this.keywords = result.keywords;
            this.loading = false;
            return result;
        } catch (error) {
            this.error = error;
        } finally {
            this.loading = false;
        }
    })
  
    _goToUrl = (url) => {
        if(url) {
            this.parentStore.rootStore.routing.push(url);
        }
    }
}
