import { observable, action, computed, flow } from "mobx";
import { BaseChartStoreClass } from './BaseChartStoreClass';

export class PieStore extends BaseChartStoreClass {
    /**
     *  Expected format
     * [
        {
            label: 'Completed',
            value: 50
        },
        {
            label: 'Open',
            value: 20
        },
        {
            label: 'Closed',
            value: 60
        },

    ]
    */
    private onClicks = [];
    public onClickCallbackExists = false;

    constructor() {
        super()
    }

    @computed public get data() {
        const DS = this.rawData[0];
        this.onClicks = [];
        if (DS && DS.dataSource && DS.dataSource.length > 0) {
            return DS.dataSource
                .filter(D => {
                    if (this.hideZeros) {
                        return D[DS.dataValue] ? true : false
                    } else {
                        return true
                    }
                })
                .map(D => {
                    // console.log('DS.onClick', DS.onClick);
                    // console.log('D', D.id);
                    this.onClicks.push(this.isFunction(DS.onClick) ? DS.onClick.bind(this, [D.id]) : null)
                    this.onClickCallbackExists = this.isFunction(D.onClick) ? true : false;
                    return {
                        label: D[DS.labelField] || '',
                        value: D[DS.dataValue] || 0,
                    }
                });
        } else {
            this.onClicks.push(null)
            return [{
                label: '',
                value: 0,
            }];
        }
    }

    public getChartData(element) {
        
        let title = {};
        // if (this.title && this.showTitle) {
        //     title = {
        //         position: 'top-left',
        //         padding: this.titlePadding,
        //         text: this.title,
        //     }
        // }
        return {
            size: this.size,
            padding: this.padding,
            bindto: element,
            data: {
                onclick: (d, i) => {
                    if (this.isFunction(this.onClicks[d.index])) this.onClicks[d.index](d)
                },
                columns: this.data.map(D => [D.label, D.value]),
                type: 'pie',
            },
            title,
        }
    }

    public csvData() {
        const labels = this.data.map(D => D.label);
        const values = this.data.map(D => D.value);
        const numberOfValues = this.data.length;
        const csvValues = [];
        for (let i=0; i<numberOfValues; i++) {
            const csvRow = {};
            csvRow['Dimension'] = labels[i];
            csvRow['Value'] = values[i];
            csvValues.push(csvRow);
        }
        return csvValues;
    }

}
