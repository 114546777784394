import React from "react";
import { observer } from "mobx-react";
import filesize from "filesize";

import { Icon } from "@ui/elements/Icon";
import { IconButton } from "@ui/elements/Button";
import { Text } from "@ui/elements/Text";

import { getFileTypeIconProps } from "@uifabric/file-type-icons";
import { DetailsListLayoutMode, SelectionMode, ConstrainMode } from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";

import { WrappedContent } from "@modules/base/components/WrappedContent";

export const FileGridView = observer(({ files, onRemove }) => {
    const columns = [
        {
            key: "type",
            name: "Type",
            fieldName: "type",
            minWidth: 40,
            maxWidth: 40,
            isResizable: false,
            isPadded: false,
            data: "string",
            onRender: (item) => {
                return <Icon {...getFileTypeIconProps({ extension: item.name.split(".").pop(), size: 32 })} />;
            },
        },
        {
            key: "title",
            name: "Title",
            fieldName: "title",
            isRowHeader: true,
            isResizable: true,
            data: "string",
            onRender: (item) => {
                return (
                    <WrappedContent>
                        {item.name}
                        <Text variant="small" block className="text--color-lighter">
                            {filesize(item.size)}
                        </Text>
                    </WrappedContent>
                );
            },
            isPadded: true,
        },
        {
            key: "remove",
            fieldName: "remove",
            minWidth: 30,
            maxWidth: 30,
            isResizable: false,
            data: "string",
            isPadded: false,
            onRender: (item) => {
                return (
                    <WrappedContent>
                        <IconButton
                            title={`Remove ${item.name}`}
                            iconProps={{ iconName: "Cancel" }}
                            onClick={() => {
                                onRemove(item);
                            }}
                        />
                    </WrappedContent>
                );
            },
        },
    ];

    return files.length === 0 ? (
        <span>No files added</span>
    ) : (
        <ShimmeredDetailsList
            items={files}
            compact={true}
            columns={columns}
            selectionMode={SelectionMode.none}
            getKey={(item, index) => index}
            setKey="multiple"
            layoutMode={DetailsListLayoutMode.justified}
            constrainMode={ConstrainMode.horizontalConstrained}
            isHeaderVisible={false}
            enableShimmer={false}
            ariaLabelForGrid="File list"
        />
    );
});
