import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { cx, css } from 'emotion';
import { withRouter } from 'react-router';

import { AppPage } from '../../../layout/containers/AppPage';
import { TimeSeries} from '../../../charting/components/TimeSeries';
import { Gauge } from '../../../charting/components/Gauge';
import { Pie } from '../../../charting/components/Pie'

const chartData = [
    {
        name: 'Open',
        values: [
            {
                x: "2020-01-02",
                value: 20
            },
            {
                x: "2020-01-01",
                value: 10
            },
            {
                x: "2020-01-03",
                value: 30
            },
            {
                x: "2020-01-04",
                value: 40
            },
            {
                x: "2020-01-05",
                value: 50
            },
            {
                x: "2020-01-06",
                value: 60
            }, 
        ],
        type: 'bar',
    },
    {
        name: 'Closed',
        values: [
            {
                x: "2020-01-01",
                value: 15
            },
            {
                x: "2020-01-02",
                value: 25
            },
            {
                x: "2020-01-03",
                value: 35
            },
            {
                x: "2020-01-04",
                value: 45
            },
            {
                x: "2020-01-06",
                value: 65
            }, 
        ],
        type: 'bar',
    },
    {
        name: 'Total',
        values: [
            {
                x: "2020-01-01",
                value: 1
            },
            {
                x: "2020-01-02",
                value: 2
            },
            {
                x: "2020-01-03",
                value: 3
            },
            {
                x: "2020-01-04",
                value: 4
            },
            {
                x: "2020-01-05",
                value: 5
            },
            {
                x: "2020-01-06",
                value: 6
            }, 
        ],
        type: 'spline',
    }
];

const gaugeData = [
    {
        label: 'Completed',
        value: 50
    },
    {
        label: 'Open',
        value: 20
    },
    {
        label: 'Closed',
        value: 60
    },

]

export const InsightsOverview = inject('pageContext', 'insightsStore','routing')(withRouter(observer(
    class InsightsOverview extends Component {
        render () {
            return (
                <AppPage title="Insights" hasSidebar={false} className="insights">
                    {/* <TimeSeries 
                        title="Injected title"
                        data={chartData}
                    />
                    <Gauge
                        title="Gauge title"
                        data={gaugeData}
                    />
                    <Pie
                        title="Pie title"
                        data={gaugeData}
                    /> */}
                </AppPage>
            );
        }
    }
)));