import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';

import { Nav } from 'office-ui-fabric-react/lib/Nav';
import { CommandBarButton } from 'office-ui-fabric-react/lib/Button';

const rootClassName = cx(
    'cygraph-NavigationList-root',
    css` 

    `
);

export const NavigationList = observer(
    class NavigationList extends Component {

        render () {
            const { className } = this.props;

            return (
                <Nav
                    {...this.props}
                    className={cx(rootClassName, className)}
                    onLinkClick={this._onLinkClick}
                    onRenderGroupHeader={this._onRenderGroupHeader}
              />
            );
        }

        _onLinkClick = (ev, item) => {
            const { onLinkClick } = this.props;
            ev.preventDefault();
            if(item && onLinkClick) {
              onLinkClick(item);
            }
        };

        _onHeaderClick = (url) => {
            const { onHeaderClick } = this.props;
            if (url && onHeaderClick) {
                onHeaderClick(url);
            }
        };
    
        _onRenderGroupHeader = (group) => {
            return group.hideGrouping ? null : <CommandBarButton
                iconProps={group.iconProps}
                menuProps={group.menuProps}
                onClick={() => this._onHeaderClick(group.url)}>{group.name}</CommandBarButton>;
        };
    }
);