import { observable, flow, computed } from "mobx";

import { ProductService } from "../../../api/graph";
import { ProductStore } from "./ProductStore";

export class ProductViewerStore {
    public productService: ProductService;
    public parentStore: ProductStore;

    @observable public loading: boolean = false;
    @observable public error: any;
    @observable public selectedTab: string = "summary";

    constructor(parentStore: ProductStore) {
        this.parentStore = parentStore;
        this.productService = parentStore.productService;
    }

    @computed
    public get breadcrumb() {
        const routing = this.parentStore.rootStore.routing;
        const builder: any[] = [];

        builder.push({ text: "Settings", key: "settings", onClick: () => routing.push(`/settings`) });
        builder.push({
            text: "Demand Management",
            key: "settings-demand",
            onClick: () => routing.push(`/settings/demand`),
        });
        builder.push({
            text: "Catalogue",
            key: "catalogue-browse",
            onClick: () => routing.push(`/settings/demand/catalogue`),
        });

        if (!this.product) {
            return builder;
        }

        builder.push({ text: this.product.name, key: this.product.id, isCurrentItem: true });

        return builder;
    }

    @computed
    public get product() {
        return this.parentStore.selectionStore.product;
    }

    @computed
    public get schema() {
        return this.parentStore.selectionStore.schema;
    }

    public loadProduct = flow(function* (id) {
        this.loading = true;
        this.error = null;

        if (this.product && this.product.id !== id) {
            this.parentStore.selectionStore.clear();
            this.selectedTab = "summary";
        }

        try {
            yield this.parentStore.selectionStore.loadProduct(id);
            yield this.parentStore.selectionStore.loadProductSchema(id, this.product.version);
        } catch (e) {
            this.error = e;
            console.error(e);
        } finally {
            this.loading = false;
        }
    });
}
