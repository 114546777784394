import React, { Component } from "react";
import { cx, css } from "emotion";

import { Text } from "office-ui-fabric-react/lib/Text";
import { PrimaryButton } from "office-ui-fabric-react/lib/Button";

import { ObservableSeparator } from "../../../base/components/ObservableSeparator";
import { NavigationList } from "../../../base/components/NavigationList";
import { BigSearchBox } from "../../../base/components/BigSearchBox";

const rootClassName = cx(
    "cygraph-WorkItemSideNav-root",
    css`
        & .container {
            padding: 10px 0;
        }
        & .sidebar--inner {
            display: flex;
            flex-direction: column;
        }
        & .navigation {
            flex-grow: 1;
            margin-bottom: 15px;
            overflow: auto;
        }
        & .launch-pad button {
            width: 100%;
            text-align: left;
            height: 40px;
            font-size: 15px;
        }
    `
);

const bigSearchBoxClassName = cx(
    "cy-searchBar",
    "row--l",
    css`
        margin-bottom: 15px !important;
    `
);

export class WorkItemSideNav extends Component {
    render() {
        const { className, loading, boards, isAgent, onNewRequest } = this.props;

        let navLinkGroups = [
            {
                name: "Demand Management",
                key: "demand",
                url: "/demand",
                hideGrouping: true,
                links: [
                    {
                        name: "Demand Dashboard",
                        key: "/demand/dashboard",
                        url: "/demand/dashboard",
                        iconProps: { iconName: "ViewDashboard" },
                    },
                    {
                        name: "Browse Requests",
                        key: "/demand/browse",
                        url: "/demand/browse",
                        iconProps: { iconName: "ViewList" },
                    },
                ],
            },
        ];

        if (isAgent) {
            navLinkGroups[0].links.push({
                name: "Demand Calendar",
                key: "/demand/calendar",
                url: "/demand/calendar",
                iconProps: { iconName: "Calendar" },
            });
            navLinkGroups[0].links.push({
                name: "Demand Timeline",
                key: "/demand/timeline",
                url: "/demand/timeline",
                iconProps: { iconName: "TimelineDelivery" },
            });
        }

        const boardsGroup = [
            {
                name: "My Boards",
                key: "/demand/boards",
                hideGrouping: true,
                links: boards
                    ? boards.map((board) => ({
                          name: board.name,
                          url: `/demand/boards/${board.id}`,
                          iconProps: { iconName: "Taskboard" },
                          key: board.id,
                          data: board,
                      }))
                    : [],
            },
        ];

        return loading ? null : (
            <div className={cx(rootClassName, className, "sidebar sidebar--left sidebar--nav")}>
                <div className="sidebar--inner scrollbar--root">
                    <div className="container">
                        <Text className="title" variant="xLarge" block>
                            Demand
                            <br />
                            Management
                        </Text>
                    </div>

                    <BigSearchBox
                        className={bigSearchBoxClassName}
                        placeholder="Search for requests"
                        url="/demand/browse"
                    />

                    <div className="navigation scrollbar--root" data-test="demandNavigationBar">
                        <NavigationList
                            onLinkClick={this.props.onLinkClick}
                            ariaLabel="Demand management navigation"
                            groups={navLinkGroups}
                        />

                        {isAgent && boards && boards.length !== 0 && (
                            <>
                                <div className="container">
                                    <ObservableSeparator>My Boards</ObservableSeparator>
                                </div>
                                <NavigationList
                                    onLinkClick={this.props.onLinkClick}
                                    ariaLabel="Demand management navigation"
                                    groups={boardsGroup}
                                />
                            </>
                        )}
                    </div>

                    {onNewRequest && (
                        <div className="launch-pad">
                            <PrimaryButton
                                data-test="newRequestButton"
                                text="New Request"
                                className="newRequestButton"
                                iconProps={{ iconName: "Add" }}
                                onClick={() => onNewRequest()}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
