import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router';

import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { PanelType } from 'office-ui-fabric-react/lib/Panel';
import { PrimaryButton, DefaultButton, IconButton } from 'office-ui-fabric-react/lib/Button';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog'

import { ObservablePanel } from '@baseComponents/ObservablePanel';

import { ControlMapEditForm } from '../../components/maps/ControlMapEditForm';
import { ControlMapAxisEditPanel } from './ControlMapAxisEditPanel';

export const ControlMapNewPanel = inject('controlStore', 'routing')(withRouter(observer(
    class ControlMapNewPanel extends Component {

        componentWillMount() {
            const { controlStore } = this.props;

            controlStore.optionsStore.loadControlMapOptions();
            controlStore.mapNewStore.loadControls({ cache: true });
        }

        componentWillReceiveProps(nextProps) {
            const { controlStore } = nextProps;

            controlStore.optionsStore.loadControlMapOptions();
            controlStore.mapNewStore.loadControls({ cache: false });
        }

        render () {
            const { controlStore } = this.props;
            const { mapNewStore, optionsStore } = controlStore;
            const { formData, formOptions, visible, isCloseDialogOpen, loading, isValid, isDirty } = mapNewStore;
            return (
                <>
                    <ObservablePanel
                        pageType={`New Control Map`}
                        panelType={PanelType.largeFixed}
                        hidden={!visible}
                        onClosePanel={() => this._onCancel(false)}
                        onDismiss={() => this._onCancel(true)}
                        onRenderFooter={this._renderFooter}>
                            { formData && <ControlMapEditForm 
                                ref={(r) => this.editForm = r}
                                formData={formData}
                                formOptions={formOptions}
                                isValid={isValid}
                                isDirty={isDirty}
                                loading={loading}
                                options={optionsStore}
                                controls={mapNewStore.controls}
                                onAxisClick={mapNewStore.setSelectedAxis}
                                onAxisValuesUpdated={mapNewStore.onAxisValuesUpdated}
                                onAxisRemoved={mapNewStore.onAxisRemoved}
                                onImageChanged={mapNewStore.onImageChanged}
                                onSearchChange={mapNewStore.onSearchChange}
                                onAddAxis={(axis) => {
                                    const updated = mapNewStore.onAddAxis(axis);
                                    mapNewStore.setSelectedAxis(updated);
                                }}
                                onAddControlAxis={(control) => {
                                    const axis = mapNewStore.onAddControlAxis(control);
                                    if(axis) {
                                        mapNewStore.setSelectedAxis(axis);
                                    }
                                }}
                            /> }
                            { formData && <ControlMapAxisEditPanel 
                                formData={mapNewStore.selectedAxis}
                                formOptions={formOptions}
                                isValid={mapNewStore.isAxisValid}
                                onDone={mapNewStore.onAxisUpdated}
                            /> }
                    </ObservablePanel>
                    <Dialog
                        hidden={!isCloseDialogOpen}
                        onDismiss={() => this._onKeepEditing()}
                        dialogContentProps={{
                            type: DialogType.largeHeader,
                            title: 'You might lose your changes',
                            subText: 'It looks like you have some unsaved changes, are you sure that you want to lose them?',
                        }}
                        modalProps={{
                            isBlocking: true,
                            styles: { main: { maxWidth: 450 } },
                        }}>
                        <DialogFooter>
                            <PrimaryButton onClick={() => this._onCancel(false)} text="Close" />
                            <DefaultButton onClick={() => this._onKeepEditing()} text="Keep editing" />
                        </DialogFooter>
                    </Dialog>
                </>
            );
        }

        _renderFooter = () => {
            const { controlStore } = this.props;
            const { mapNewStore } = controlStore;
            return (
                <Stack horizontal horizontalAlign="space-between">
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <PrimaryButton onClick={() => this._onSave()} iconProps={{iconName: 'Save'}} text="Save" disabled={!mapNewStore.isValid || !this.editForm} />
                        { mapNewStore.isDirty && <IconButton iconProps={{iconName: 'Undo'}} onClick={() => {mapNewStore.resetFormData()}} text="Undo Changes" /> }
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <DefaultButton onClick={() => this._onCancel(false)} iconProps={{iconName: 'Cancel'}} text="Cancel" />
                    </Stack>
                </Stack>
            );
        }

        _onSave() {
            const { controlStore, onAfterSave } = this.props;
            const { mapNewStore } = controlStore;

            if(this.editForm) {
                return this.editForm.updateMapImage().then(() => {
                    return mapNewStore.createControlMap().then((item) => {
                        if(onAfterSave) {
                            Promise.resolve(onAfterSave(item))
                                .then(() => mapNewStore.hide({}));
                        } else {
                            mapNewStore.hide({});
                        }
                    });
                });
            }
        }

        _onCancel(dismiss) {
            const { controlStore, onCancel } = this.props;
            const { mapNewStore } = controlStore;

            if(dismiss && mapNewStore.isDirty) {
                mapNewStore.isCloseDialogOpen = true;
                return;
            }

            if(onCancel) {
                Promise.resolve(onCancel(dismiss))
                    .then(() => mapNewStore.hide({}));
            } else {
                mapNewStore.hide({});
            }
        }

        _onKeepEditing() {
            const { controlStore } = this.props;
            const { mapNewStore } = controlStore;

            mapNewStore.isCloseDialogOpen = false;
        }
    }
)));