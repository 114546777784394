import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { useStores } from "@hooks/useStores";
import * as microsoftTeams from "@microsoft/teams-js";
import { cx } from "emotion";

import Dropzone from "react-dropzone";

import { AppPage } from "@modules/layout/containers/AppPage";
import { ActionBar } from "@modules/base/components/ActionBar";

import { WorkItemAssignPanel } from "@modules/workitems/containers/panels/WorkItemAssignPanel";

import { AssessmentHeader } from "@modules/assessments/components/core/AssessmentHeader";
import { AssessmentStatusProgress } from "@modules/assessments/components/core/AssessmentStatusProgress";
import { AssessmentModuleList } from "@modules/assessments/components/modules/AssessmentModuleList";
import { AssessmentTabList } from "@modules/assessments/containers/tabs/AssessmentTabList";

export const AssessmentViewerPage = withRouter(
    observer(({ match }) => {
        const { assessmentStore, workItemStore, riskStore, routingManager } = useStores();
        const { documentStore, lifecycleStore, viewStore, advisoryStore } = assessmentStore;
        const { loading, error, assessment, workItem, modules, breadcrumb, selectedTab } = viewStore;
        const loadingPage = loading || lifecycleStore.saving || documentStore.loading || documentStore.saving;

        useEffect(() => {
            if (match.params.id) {
                assessmentStore.viewStore.loadAssessment(match.params.id).then(() => {
                    advisoryStore.loadAssessmentAdvisories(match.params.id);
                    riskStore.typeStore.loadTypes({ cached: true });
                });
            }
        }, [match.params.id]);

        useEffect(() => {
            assessmentStore.viewStore.selectTab(match.params.tab);
        }, [match.params.id, match.params.tab]);

        return (
            <AppPage error={error} breadcrumb={breadcrumb} loading={loadingPage} hasSidebar={true}>
                {!loading && assessment && workItem && (
                    <>
                        <Dropzone
                            disabled={!lifecycleStore.canUpload}
                            onDrop={(acceptedFiles) => viewStore.onUpload(acceptedFiles)}
                            multiple={true}
                            noClick={true}
                        >
                            {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, open }) => (
                                <div
                                    {...getRootProps()}
                                    className={cx(
                                        "dropzone",
                                        isDragActive && "active",
                                        isDragAccept && "accept",
                                        isDragReject && "reject"
                                    )}
                                >
                                    <input {...getInputProps()} />

                                    <AssessmentHeader
                                        assessment={assessment}
                                        workItem={workItem}
                                        canStart={() => lifecycleStore.canStart}
                                        onStart={() => lifecycleStore.onStart()}
                                        canSubmit={() => lifecycleStore.canSubmit}
                                        onSubmit={() => lifecycleStore.onSubmit()}
                                        canReview={() => lifecycleStore.canReview}
                                        onReview={() => lifecycleStore.onReview()}
                                        canClose={() => lifecycleStore.canClose}
                                        onClose={() => lifecycleStore.onClose()}
                                        canCancel={() => lifecycleStore.canCancel}
                                        onCancel={() => lifecycleStore.onCancel()}
                                        canExportCsv={() => lifecycleStore.canExportCsv}
                                        onExportCsv={() => lifecycleStore.onExportCsv()}
                                        canAssign={() => lifecycleStore.canAssign}
                                        onAssign={() => lifecycleStore.onAssign()}
                                        canUpload={() => lifecycleStore.canUpload}
                                        onUpload={open}
                                        onReport={() => lifecycleStore.onReport()}
                                        onNavigateBack={() => routingManager.push("/assurance/browse")}
                                    />
                                    <AssessmentStatusProgress assessment={assessment} workItem={workItem} />

                                    {!loading && lifecycleStore.canSubmit && (
                                        <ActionBar
                                            className="row--s animated pulse fast"
                                            title="Submission Required"
                                            description="Once you are happy with all your responses to questions, you may submit your assessment for review. Please upload any supporting documents via the 'Related Documents' tab below."
                                            variant="info"
                                        ></ActionBar>
                                    )}

                                    {!loading && lifecycleStore.showSubmissionSuccessful && (
                                        <ActionBar
                                            className="row--s animated pulse fast"
                                            title="Assessment Submitted"
                                            description="Thanks for completing the self-assessment. This has now been sent to your portfolio aligned Assurance Lead for review; they will be in touch in due course."
                                            variant="success"
                                        ></ActionBar>
                                    )}

                                    <AssessmentModuleList
                                        className="animated fadeIn fast"
                                        disabled={lifecycleStore.isReadOnly}
                                        modules={modules}
                                        onModuleClick={(module) => {
                                            microsoftTeams.tasks.startTask({
                                                url: `${window.location.origin}/teams/assurance/browse/${assessment.id}/-/${module.id}`,
                                                fallbackUrl: `${window.location.origin}/assurance/browse/${assessment.id}/-/${module.id}`,
                                                width: "large",
                                                height: "large",
                                                title: `${assessment.code} - ${module.title} ${
                                                    lifecycleStore.isReadOnly ? "(read-only)" : ""
                                                }`,
                                            });
                                        }}
                                    />

                                    <AssessmentTabList
                                        assessment={assessment}
                                        workItem={workItem}
                                        selectedTab={selectedTab}
                                        onUpload={open}
                                    />
                                </div>
                            )}
                        </Dropzone>

                        <WorkItemAssignPanel store={workItemStore.assignFormStore} />
                    </>
                )}
            </AppPage>
        );
    })
);
