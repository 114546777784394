import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router';

import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { PanelType } from 'office-ui-fabric-react/lib/Panel';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog'
import { ObservablePanel } from '../../../base/components/ObservablePanel';
import { ProductEditForm } from '../../components/core/ProductEditForm';

export const ProductEditPanel = inject('productStore', 'routing')(withRouter(observer(
    class ProductEditPanel extends Component {

        componentWillMount() {
            const { productStore } = this.props;

            productStore.optionsStore.loadProductOptions();
        }

        componentWillReceiveProps(nextProps) {
            const { productStore } = nextProps;

            productStore.optionsStore.loadProductOptions();
        }

        render () {
            const { productStore } = this.props;
            const { formData, visible, isCloseDialogOpen, isValid, isDirty } = productStore.editFormStore;
            return (
                <>
                    <ObservablePanel
                        pageType={`Edit Product`}
                        panelType={PanelType.medium}
                        hidden={!visible}
                        onClosePanel={() => this._onCancel(false)}
                        onDismiss={() => this._onCancel(true)}
                        onRenderFooter={this._renderFooter}>
                            { formData && <ProductEditForm 
                                formData={formData}
                                isValid={isValid}
                                isDirty={isDirty}
                                options={productStore.optionsStore}
                            />}
                    </ObservablePanel>
                    <Dialog
                        hidden={!isCloseDialogOpen}
                        onDismiss={() => this._onKeepEditing()}
                        dialogContentProps={{
                            type: DialogType.largeHeader,
                            title: 'You might lose your changes',
                            subText: 'It looks like you have some unsaved changes, are you sure that you want to lose them?',
                        }}
                        modalProps={{
                            isBlocking: true,
                            styles: { main: { maxWidth: 450 } },
                        }}>
                        <DialogFooter>
                            <PrimaryButton onClick={() => this._onCancel(false)} text="Close" />
                            <DefaultButton onClick={() => this._onKeepEditing()} text="Keep editing" />
                        </DialogFooter>
                    </Dialog>
                </>
            );
        }

        _renderFooter = () => {
            const { productStore } = this.props;
            const { editFormStore } = productStore;
            return (
                <Stack horizontal horizontalAlign="space-between">
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <PrimaryButton onClick={() => this._onSave()} iconProps={{iconName: 'Save'}} text="Save" disabled={!editFormStore.isValid} />
                        { editFormStore.isDirty && <DefaultButton iconProps={{iconName: 'Undo'}} onClick={() => {editFormStore.resetFormData()}} text="Undo changes" /> }
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <DefaultButton onClick={() => this._onCancel(false)} iconProps={{iconName: 'Cancel'}} text="Cancel" />
                    </Stack>
                </Stack>
            );
        }

        _onSave() {
            const { productStore, onAfterSave } = this.props;
            const { editFormStore } = productStore;

            Promise.resolve(editFormStore.updateProduct()).then((item) => {
                if(onAfterSave) {
                    Promise.resolve(onAfterSave(item))
                        .then(() => editFormStore.hide({}));
                } else {
                    editFormStore.hide({});
                }
            });
        }

        _onCancel(dismiss) {
            const { productStore, onCancel } = this.props;
            const { editFormStore } = productStore;

            if(dismiss && editFormStore.isDirty) {
                editFormStore.isCloseDialogOpen = true;
                return;
            }

            if(onCancel) {
                Promise.resolve(onCancel(dismiss))
                    .then(() => editFormStore.hide({}));
            } else {
                editFormStore.hide({});
            }
        }

        _onKeepEditing() {
            const { productStore } = this.props;
            const { editFormStore } = productStore;

            editFormStore.isCloseDialogOpen = false;
        }
    }
)));