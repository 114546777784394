import React, { PureComponent } from "react";
import { observer, inject } from "mobx-react";
import { v4 as uuidv4 } from "uuid";

import { TextField } from "@ui/elements/TextField";
import { Dropdown } from "@ui/elements/Dropdown";
import { Toggle } from "@ui/elements/Toggle";
import { DefaultButton } from "@ui/elements/Button";
import { ObservableSeparator } from "@baseComponents/ObservableSeparator";

import { TimeSeriesEditor } from "./forms/TimeSeriesEditor";
import { GaugeEditor } from "./forms/GaugeEditor";
import { PieEditor } from "./forms/PieEditor";
import { XyEditor } from "./forms/XyEditor";
import { StackedBarEditor } from "./forms/StackedBarEditor";
import { NumberEditor } from "./forms/NumberEditor";
import { RadarEditor } from "./forms/RadarEditor";

export const ChartViewerPartEditor = inject("dataSourceStore")(
    observer(
        class ChartViewerPartEditor extends PureComponent {
            get showAddSeries() {
                const { formData, audiences, dataSources, dataSourceStore } = this.props;
                const { coreDataSources, selectionStore } = dataSourceStore;

                if (formData.properties.chartType === "number" && formData.properties.dataSeries && formData.properties.dataSeries.length > 0) return false;
                if (
                    formData.properties.chartType === "stackedBar" &&
                    formData.properties.dataSeries &&
                    formData.properties.dataSeries.length === 1 &&
                    formData.properties.dataSeries[0].dataSourceId
                ) {
                    const selectedPartDataSource = formData.properties.dataSeries[0].dataSourceId
                        ? dataSources.find((D) => D.id === formData.properties.dataSeries[0].dataSourceId)
                        : null;
                    const selectedDataSource = selectedPartDataSource
                        ? selectionStore.dataSources.find((D) => D.id === selectedPartDataSource.properties.mainSource)
                        : null;
                    const selectedCoreDataSource = selectedDataSource ? coreDataSources.find((D) => D.id === selectedDataSource.coreDataSourceId) : null;
                    return selectedCoreDataSource.type === "dynamicGroup" ? false : true;
                }
                return true;
            }

            render() {
                const { formData, audiences, dataSources, dataSourceStore, instance } = this.props;
                return (
                    <div>
                        <r-grid columns="2">
                            <r-cell span="2">
                                <ObservableSeparator>Chart Settings</ObservableSeparator>
                            </r-cell>
                            <r-cell span="2">
                                <Dropdown
                                    required
                                    label="Chart Type"
                                    selectedKey={formData.properties.chartType}
                                    onChange={(ev, op) => {
                                        if (op) {
                                            formData.properties.chartType = op.key;
                                        }
                                    }}
                                    options={[
                                        {
                                            key: "xyChart",
                                            text: "Bar",
                                        },

                                        {
                                            key: "radar",
                                            text: "Radar",
                                        },
                                        {
                                            key: "stackedBar",
                                            text: "Stacked Bars",
                                        },
                                        {
                                            key: "pie",
                                            text: "Pie",
                                        },
                                        {
                                            key: "gauge",
                                            text: "Gauge",
                                        },
                                        {
                                            key: "timeSeries",
                                            text: "Time Series",
                                        },
                                        {
                                            key: "number",
                                            text: "Number",
                                        },
                                    ]}
                                />
                            </r-cell>
                            {(formData.properties.chartType === "xyChart" ||
                                formData.properties.chartType === "stackedBar" ||
                                formData.properties.chartType === "timeSeries") && (
                                <r-cell span="2">
                                    <Dropdown
                                        required
                                        label="Chart Orientation"
                                        selectedKey={formData.properties.orientation || "vertical"}
                                        onChange={(ev, op) => {
                                            if (op) {
                                                formData.properties.orientation = op.key;
                                            }
                                        }}
                                        options={[
                                            {
                                                key: "vertical",
                                                text: "Standard (Vertical)",
                                            },
                                            {
                                                key: "horizontal",
                                                text: "Horizontal",
                                            },
                                        ]}
                                    />
                                </r-cell>
                            )}
                            <r-cell span="2">
                                {formData.properties.chartType !== "number" && (
                                    <r-cell span="2">
                                        <r-grid columns="4">
                                            <r-cell span="1">
                                                <Toggle
                                                    label="Show export to PNG"
                                                    checked={formData.properties.showPNG}
                                                    onChange={(ev, checked) => {
                                                        formData.properties.showPNG = checked;
                                                    }}
                                                />
                                            </r-cell>
                                            <r-cell span="1">
                                                <Toggle
                                                    label="Show export to CSV"
                                                    checked={formData.properties.showCSV}
                                                    onChange={(ev, checked) => {
                                                        formData.properties.showCSV = checked;
                                                    }}
                                                />
                                            </r-cell>
                                            <r-cell span="1">
                                                {(formData.properties.chartType === "xyChart" || formData.properties.chartType === "pie") && (
                                                    <Toggle
                                                        label="Hide Empty Values"
                                                        checked={formData.properties.hideZeros}
                                                        onChange={(ev, checked) => {
                                                            formData.properties.hideZeros = checked;
                                                        }}
                                                    />
                                                )}
                                            </r-cell>
                                        </r-grid>
                                    </r-cell>
                                )}
                            </r-cell>
                            {formData.properties.chartType === "number" && <r-cell span="2">
                                <div>
                                    <Toggle 
                                        label="Display a bottom link"
                                        checked={formData.properties.showBottomLink}
                                        onChange={(ev, checked) => {
                                            formData.properties.showBottomLink = checked;
                                        }}
                                    />
                                </div>
                            </r-cell>}
                            {formData.properties.chartType === "number" && formData.properties.showBottomLink && <r-cell span="2">
                                    <div>
                                        <TextField 
                                            label="Bottom link text"
                                            value={formData.properties.bottomLinkText || 'View all'}
                                            onChange={(ev, val) => formData.properties.bottomLinkText = val} 
                                        />
                                    </div>
                            </r-cell>}
                            <r-cell span="2">
                                {formData.properties.chartType === "timeSeries" && (
                                    <TimeSeriesEditor instance={instance} dataSourceStore={dataSourceStore} formData={formData} dataSources={dataSources} />
                                )}
                                {formData.properties.chartType === "xyChart" && (
                                    <XyEditor instance={instance} dataSourceStore={dataSourceStore} formData={formData} dataSources={dataSources} />
                                )}
                                {formData.properties.chartType === "gauge" && (
                                    <GaugeEditor instance={instance} dataSourceStore={dataSourceStore} formData={formData} dataSources={dataSources} />
                                )}
                                {formData.properties.chartType === "number" && (
                                    <NumberEditor instance={instance} dataSourceStore={dataSourceStore} formData={formData} dataSources={dataSources} />
                                )}
                                {formData.properties.chartType === "pie" && (
                                    <PieEditor instance={instance} dataSourceStore={dataSourceStore} formData={formData} dataSources={dataSources} />
                                )}
                                {formData.properties.chartType === "stackedBar" && (
                                    <StackedBarEditor instance={instance} dataSourceStore={dataSourceStore} formData={formData} dataSources={dataSources} />
                                )}
                                {formData.properties.chartType === "radar" && (
                                    <RadarEditor instance={instance} dataSourceStore={dataSourceStore} formData={formData} dataSources={dataSources} />
                                )}
                            </r-cell>
                            {this.showAddSeries && (
                                <r-cell span="2">
                                    <DefaultButton
                                        text="Add data series"
                                        onClick={() => {
                                            if (!formData.properties.dataSeries) formData.properties.dataSeries = [];
                                            formData.properties.dataSeries.push({
                                                dataSourceId: null,
                                                dataX: "x",
                                                dataValue: "value",
                                                type: "bar",
                                                max: 100,
                                                label: "Label",
                                                id: uuidv4(),
                                                isDynamic: false,
                                            });
                                        }}
                                    />
                                </r-cell>
                            )}
                        </r-grid>
                    </div>
                );
            }
        }
    )
);
