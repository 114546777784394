import React from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";
import { Route, Switch, Redirect } from "react-router";

import { ModulePage } from "@modules/layout/containers/ModulePage";

import { ControlSideNav } from "@modules/controls/components/core/ControlSideNav";

import { ControlDashboardPage } from "@modules/controls/containers/pages/ControlDashboardPage";
import { StrategicControlBrowsePage } from "@modules/controls/containers/pages/StrategicControlBrowsePage";
import { StrategicControlViewerPage } from "@modules/controls/containers/pages/StrategicControlViewerPage";
import { TacticalControlBrowsePage } from "@modules/controls/containers/pages/TacticalControlBrowsePage";
import { TacticalControlViewerPage } from "@modules/controls/containers/pages/TacticalControlViewerPage";
import { AuditActivityBrowsePage } from "@modules/controls/containers/pages/AuditActivityBrowsePage";
import { AuditActivityCalendarPage } from "@modules/controls/containers/pages/AuditActivityCalendarPage";

export const ControlModulePage = observer(() => {
    const { pageContext, routing, controlStore } = useStores();
    const { registryBrowseStore } = controlStore;

    return (
        <ModulePage
            loading={false}
            breadcrumb={[]}
            error={null}
            hasPageNav={true}
            hasSidebar={false}
            className="controls"
        >
            <ControlSideNav onLinkClick={(item) => routing.push(item.url)} onNewControl={() => {}} />
            <Switch>
                <Route exact path="/controls/dashboard" component={() => <ControlDashboardPage />} />
                <Route exact path="/controls/calendar" component={() => <AuditActivityCalendarPage />} />
                <Route exact path="/controls/audits" component={() => <AuditActivityBrowsePage />} />
                <Route exact path="/controls/audits/:id/:tab?" component={() => <AuditActivityBrowsePage />} />
                <Route exact path="/controls/browse" component={() => <StrategicControlBrowsePage />} />
                <Route exact path="/controls/browse/:id/:tab?" component={() => <StrategicControlViewerPage />} />
                <Route exact path="/controls/tactical" component={() => <TacticalControlBrowsePage />} />
                <Route exact path="/controls/tactical/:id/:tab?" component={() => <TacticalControlViewerPage />} />
                <Redirect path="/controls/*" from="/controls/*" to="/controls/dashboard" />
            </Switch>
        </ModulePage>
    );
});
