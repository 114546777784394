import React, { Component } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { Pagination } from "@uifabric/experiments/lib/Pagination";

export const ObservablePagination = observer(
    class ObservablePagination extends Component {
        render() {
            const { selectedPageIndex, pageCount, itemsPerPage, totalItemCount, onPageChange, className } = this.props;

            return (
                <div
                    className={cx(
                        className,
                        css`
                            margin: var(--spacing-l) 0;

                            & .ms-Pagination-container {
                                div:first-child {
                                    margin-bottom: var(--spacing-xs);
                                }
                            }
                            & .ms-Pagination-pageNumber {
                                width: 32px;
                                height: 32px;
                                text-align: center;

                                &[aria-selected="true"] {
                                    background-color: var(--primary-brand-color);
                                    color: var(--white);
                                    border-radius: 16px;
                                    text-decoration: none;
                                }
                            }
                        `
                    )}
                >
                    {totalItemCount > 0 && (
                        <Pagination
                            selectedPageIndex={selectedPageIndex}
                            pageCount={pageCount}
                            itemsPerPage={itemsPerPage}
                            totalItemCount={totalItemCount}
                            format={"buttons"}
                            previousPageAriaLabel={"Previous page"}
                            nextPageAriaLabel={"Next page"}
                            firstPageAriaLabel={"First page"}
                            lastPageAriaLabel={"Last page"}
                            pageAriaLabel={"Page"}
                            selectedAriaLabel={"Selected page"}
                            onPageChange={onPageChange}
                        />
                    )}
                </div>
            );
        }
    }
);
