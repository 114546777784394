import React from "react";
import { observer } from "mobx-react";
import Moment from "react-moment";

import { Text } from "office-ui-fabric-react/lib/Text";
import { MessageBar } from "office-ui-fabric-react/lib/MessageBar";
import { DetailsListLayoutMode, Selection, SelectionMode, ConstrainMode } from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { PersonaSize } from "office-ui-fabric-react/lib/Persona";
import { Icon } from "office-ui-fabric-react/lib/Icon";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { RoutingLink } from "@modules/base/components/RoutingLink";
import { WrappedContent } from "@modules/base/components/WrappedContent";
import { PriorityScore } from "@modules/base/components/kanbanboard/components/PriorityScore";
import { PhotoPersona } from "@modules/identity/containers/utilities/PhotoPersona";

import { WorkItemDueIndicator } from "@modules/workitems/components/core/WorkItemDueIndicator";
import { WorkItemAgeIndicator } from "@modules/workitems/components/core/WorkItemAgeIndicator";

export const AuditActivityGridView = observer((props) => {
    const { loading, audits, onAuditClick } = props;
    const columns = [
        {
            key: "title",
            name: "Audit Control",
            fieldName: "title",
            isRowHeader: true,
            isResizable: false,
            data: "string",
            onRender: (item) => {
                return (
                    <WrappedContent>
                        <RoutingLink
                            url={`/controls/audits/${item.id}`}
                            onClick={() => onAuditClick && onAuditClick(item)}
                            onClickEnabled={onAuditClick}
                        >
                            {item.code} - {item.title}
                        </RoutingLink>
                        <Text variant="small" data-test="requestedBy" block>
                            {item.product.name} requested by {item.requestedBy.name}{" "}
                            <Moment date={item.created} fromNow />
                        </Text>
                    </WrappedContent>
                );
            },
            isPadded: true,
        },
        {
            key: "age",
            name: "Age",
            fieldName: "Age",
            minWidth: 35,
            maxWidth: 50,
            isResizable: false,
            data: "string",
            onRender: (item) => {
                return <WorkItemAgeIndicator workItem={item} />;
            },
            isPadded: false,
        },
        {
            key: "due",
            name: "Due",
            fieldName: "due",
            minWidth: 35,
            maxWidth: 50,
            isResizable: false,
            data: "string",
            onRender: (item) => {
                return <WorkItemDueIndicator workItem={item} />;
            },
            isPadded: false,
        },
        {
            key: "threatSurface",
            name: "Threat Surface",
            fieldName: "threatSurface",
            minWidth: 120,
            maxWidth: 140,
            isResizable: false,
            data: "string",
            onRender: (item) => {
                return <span>Wellcome General</span>;
            },
            isPadded: false,
        },
        {
            key: "priority",
            name: "Priority",
            fieldName: "priority",
            minWidth: 65,
            maxWidth: 70,
            isResizable: false,
            data: "string",
            isPadded: false,
            onRender: (item) => {
                return <PriorityScore priority={item.priority} size="mini" hideName={true} />;
            },
        },
        {
            key: "assigned",
            name: "Assigned",
            fieldName: "assigned",
            minWidth: 200,
            maxWidth: 230,
            isResizable: false,
            data: "string",
            onRender: (item) => {
                return (
                    <PhotoPersona
                        principalId={item.assignedToUser ? item.assignedToUser.id : null}
                        size={PersonaSize.size28}
                        text={item.assignedToUser ? item.assignedToUser.name : "Unassigned"}
                        showUnknownPersonaCoin={!item.assignedToUser}
                        showSecondaryText={true}
                        secondaryText={item.assignedToGroup.name}
                    />
                );
            },
            isPadded: false,
        },
        {
            key: "subStatus",
            name: "Status",
            fieldName: "subStatus",
            minWidth: 90,
            maxWidth: 110,
            isResizable: false,
            data: "string",
            isPadded: false,
        },
        {
            key: "modified",
            name: "Date Modified",
            fieldName: "modified",
            minWidth: 100,
            maxWidth: 130,
            isResizable: true,
            isSorted: true,
            isSortedDescending: true,
            sortAscendingAriaLabel: "Sorted A to Z",
            sortDescendingAriaLabel: "Sorted Z to A",
            data: "date",
            onRender: (item) => {
                return <Moment utc date={item.modified} fromNow />;
            },
            isPadded: true,
        },
    ];

    return (
        <BoxPanel shadow>
            <ShimmeredDetailsList
                items={audits}
                columns={columns}
                selectionMode={SelectionMode.none}
                getKey={(item, index) => (item ? item.id : index)}
                setKey="single"
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.horizontalConstrained}
                isHeaderVisible={true}
                selectionPreservedOnEmptyClick={true}
                onItemInvoked={onAuditClick}
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="Row checkbox"
                enableShimmer={loading}
                removeFadingOverlay={true}
                shimmerLines={25}
                ariaLabelForShimmer="Audit activities being fetched"
                ariaLabelForGrid="Audit activity list"
            />

            {!loading && !audits.length && <MessageBar>There are no matching audit activities.</MessageBar>}
        </BoxPanel>
    );
});
