import React, { Component } from "react";
import { observer } from "mobx-react";

import { SearchBox } from "office-ui-fabric-react/lib/SearchBox";
import { CommandBar } from "office-ui-fabric-react/lib/CommandBar";
import { BoxPanel } from "@modules/base/components/BoxPanel";

export const AdvisoryCommandBar = observer(
    class AdvisoryCommandBar extends Component {
        render() {
            const {
                assessment,
                keywords,
                onSearchChange,
                view,
                onViewChanged,
                canAddEvidence,
                canUpdateAdvisories,
                canAddRisk,
                selectedAdvisories,
                onMarkAs,
                onAddEvidence,
                onAddRisk,
                riskTypes,
            } = this.props;

            const items = [];
            if (assessment.state == "Remediation") {
                items.push({
                    key: "addEvidence",
                    text: "Add Evidence",
                    ariaLabel: "Add Evidence",
                    disabled: !canAddEvidence || !canAddEvidence(), // assessment.state != 'Remediation' || !selectedAdvisories || selectedAdvisories.count == 0,
                    iconProps: { iconName: "Add" },
                    onClick: () => onAddEvidence(),
                });
                items.push({
                    key: "markAs",
                    text: "Mark As",
                    ariaLabel: "Mark As",
                    disabled: !canUpdateAdvisories || !canUpdateAdvisories(), // assessment.state != 'Remediation' || !selectedAdvisories || selectedAdvisories.count == 0,
                    subMenuProps: {
                        items: [
                            {
                                key: "compliant",
                                text: "Compliant",
                                onClick: () => onMarkAs("Compliant", selectedAdvisories),
                            },
                            {
                                key: "nonCompliant",
                                text: "Non Compliant",
                                onClick: () => onMarkAs("NonCompliant", selectedAdvisories),
                            },
                            {
                                key: "Open",
                                text: "Open",
                                onClick: () => onMarkAs(null, selectedAdvisories),
                            },
                        ],
                    },
                });
            } else if (assessment.state == "Reviewing") {
                riskTypes.forEach((type) => {
                    items.push({
                        key: type.id,
                        text: `Add ${type.name}`,
                        ariaLabel: `Add ${type.name}`,
                        disabled: !canAddRisk || !canAddRisk(), // assessment.state != 'Reviewing' || !selectedAdvisories || selectedAdvisories.count == 0 || !allAdvisoriesClosed,
                        iconProps: { iconName: "Add" },
                        onClick: () => onAddRisk({ type }),
                    });
                });
            }

            const farItems = [
                {
                    key: "showAll",
                    text: this._getViewTitle("all"),
                    checked: view == "all",
                    onClick: () => onViewChanged("all"),
                },
                {
                    key: "open",
                    text: this._getViewTitle("open"),
                    checked: view == "open",
                    onClick: () => onViewChanged("open"),
                },
                {
                    key: "compliant",
                    text: this._getViewTitle("compliant"),
                    checked: view == "compliant",
                    onClick: () => onViewChanged("compliant"),
                },
                {
                    key: "nonCompliant",
                    text: this._getViewTitle("nonCompliant"),
                    checked: view == "nonCompliant",
                    onClick: () => onViewChanged("nonCompliant"),
                },
                {
                    key: "search",
                    text: "Search",
                    onRender: () => {
                        return (
                            <SearchBox
                                value={keywords}
                                placeholder="Search by title, question or control"
                                styles={{ root: { height: "auto", minWidth: "270px" } }}
                                onClear={() => onSearchChange && onSearchChange(null)}
                                onSearch={onSearchChange}
                            />
                        );
                    },
                },
            ];

            return (
                <BoxPanel shadow attached>
                    <CommandBar styles={{ root: { paddingRight: "0px" } }} items={items} farItems={farItems} />
                </BoxPanel>
            );
        }

        _getViewTitle(view) {
            const { advisories } = this.props;

            switch (view || "all") {
                case "all":
                    return `All (${advisories.length})`;
                case "open":
                    return `Open (${advisories.filter((a) => !a.finalCompliance).length})`;
                case "compliant":
                    return `Compliant (${advisories.filter((a) => a.finalCompliance == "Compliant").length})`;
                case "nonCompliant":
                    return `Non Compliant (${advisories.filter((a) => a.finalCompliance == "NonCompliant").length})`;
            }
        }
    }
);
