import React from "react";
import { observer } from "mobx-react";

import { FilterBar } from "@modules/base/components/FilterBar";

export const WorkflowHistoryFilterBar = observer(({ value, onChange, context, disabled }) => {
    const filters = [
        {
            name: "showHidden",
            label: "Show Hidden",
            type: "Toggle",
            query: {},
        },
    ];
    return <FilterBar value={value} onChange={onChange} context={context} filters={filters} disabled={disabled} />;
});
