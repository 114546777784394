import React, { useEffect, useCallback } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { Stack } from "@ui/elements/Stack";
import { ActionButton, PrimaryButton } from "@ui/elements/Button";

import { ObservablePagination } from "@modules/base/components/ObservablePagination";
import { BoxPanel } from "@modules/base/components/BoxPanel";

import { RoleNames, isUserInAllRoles } from "@modules/identity/stores/PrincipalContextUtilities";

import { StateFlowGridView } from "@modules/stateflows/components/core/StateFlowGridView";
import { StateFlowCommandBar } from "@modules/stateflows/components/core/StateFlowCommandBar";

import { StateFlowEditPanel } from "@modules/stateflows/containers/panels/StateFlowEditPanel";
import { StateFlowContextMenu } from "@modules/stateflows/containers/utilities/StateFlowContextMenu";

export const ModuleWorkflowsTab = observer(({ target }) => {
    const { stateFlowStore, routing } = useStores();
    const { browseStore, lifecycleStore, editFormStore, contextMenuStore } = stateFlowStore;
    const { breadcrumb, stateFlows, query, loading, error } = browseStore;

    useEffect(() => {
        browseStore.loadStateFlows({ query: { target: [target] } });
    }, []);

    const urlPrefix = `/settings/${target === "WorkItem" ? "demand" : "risks"}/workflows`;
    return (
        <>
            <StateFlowCommandBar
                keywords={query.keywords}
                onSearchChange={(keywords) => {
                    if (keywords) {
                        routing.push(`${urlPrefix}?k=${encodeURIComponent(keywords)}`);
                    } else {
                        routing.push(urlPrefix);
                    }
                }}
            />

            <StateFlowGridView
                stateFlows={stateFlows}
                loading={loading}
                query={query}
                onStateFlowClick={(stateFlow) => lifecycleStore.onView({ stateFlow })}
                onStateFlowMenuClick={(stateFlow, ev) => lifecycleStore.onContextMenu({ stateFlow, target: ev.target })}
            />

            <ObservablePagination
                {...browseStore.pagination}
                onPageChange={(index) => {
                    routing.push(
                        `${urlPrefix}?p=${index + 1}${
                            browseStore.keywords ? `&k=${encodeURIComponent(browseStore.keywords)}` : ""
                        }`
                    );
                }}
            />

            <StateFlowEditPanel store={editFormStore} />
            <StateFlowContextMenu store={contextMenuStore} />
        </>
    );
});
