import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { useStores } from "@hooks/useStores";

import { Text } from "office-ui-fabric-react/lib/Text";

import { AppPage } from "@modules/layout/containers/AppPage";

import { SettingsListNav } from "@modules/settings/containers/utilities/SettingsListNav";

import { RisksSettingsPivot } from "@modules/settings/containers/tabs/RisksSettingsPivot";
import { RisksSettingsTabList } from "@modules/settings/containers/tabs/RisksSettingsTabList";

export const RisksSettingsPage = withRouter(
    observer(({ match }) => {
        const { settingsStore } = useStores();
        const { risksSettingsStore } = settingsStore;
        const { breadcrumb, loading, error, defaultTab, selectedTab, activeTabs } = risksSettingsStore;

        useEffect(() => {
            if (match) {
                risksSettingsStore.setSelectedTab(match.params.tab);
            }
        }, [match.params.tab]);

        return (
            <AppPage
                loading={loading}
                error={error}
                breadcrumb={breadcrumb}
                hasSidebar={false}
                className="settings risks has--page-nav"
            >
                <SettingsListNav />
                <div>
                    <Text className="row--xxs" variant="xLarge" block>
                        Risk Management
                    </Text>
                    <Text className="row--s" variant="small" block>
                        Risk &amp; exception management module allows you to forecast, plan for and manage the risk for
                        products and services within the InfoSec function.
                    </Text>

                    <RisksSettingsPivot defaultTab={defaultTab} selectedTab={selectedTab} activeTabs={activeTabs} />
                    <RisksSettingsTabList />
                </div>
            </AppPage>
        );
    })
);
