import { observable, action, computed } from "mobx";
import { toast } from 'react-toastify';

import { LayoutStore } from "./LayoutStore";

export class NotificationStore {

    readonly layoutStore: LayoutStore;
    @observable public notificationMessages: any[] = [];

    constructor(layoutStore: LayoutStore) {
        this.layoutStore = layoutStore;
    }

    @computed public get unreadMessages() {
        let unreadMessages = false;
        this.notificationMessages.forEach(N => {
            if (!N.read) unreadMessages = true;
        })
        return unreadMessages;
    }

    @computed public get notifications() {
        return this.notificationMessages.sort((a,b) => a.time - b.time)
    }

    @action public markAllNotificationsRead = () => {
        this.notificationMessages.forEach(N => {
            N.read = true;
        })
    }

    @action public displayToastNotification = (message: any) => {
        this.notificationMessages.push({
            message,
            time: new Date().getTime(),
            read: false,
        });
        toast(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
        });
    }

    @action 
    public error = (message: any) => {
        this.toast('error', message);
    }

    @action 
    public warn = (message: any) => {
        this.toast('warn', message);
    }

    @action 
    public success = (message: any) => {
        this.toast('success', message);
    }

    @action 
    public info = (message: any) => {
        this.toast('info', message);
    }

    @action 
    public toast = (type: string, message: any) => {
        this.notificationMessages.push({
            message,
            time: new Date().getTime(),
            read: false,
            type: type
        });
        toast[type](message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
        });
    }
}