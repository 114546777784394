import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Sidebar } from '@baseComponents/Sidebar';
import { SidebarProperties } from '@baseComponents/SidebarProperties';
import { SidebarProperty } from '@baseComponents/SidebarProperty';

export const EntitySidebar = observer(
    class EntitySidebar extends Component {

        render () {
            const { entity } = this.props;
            return (
                <Sidebar>
                    <SidebarProperties title="Details" icon="List" open>
                            <SidebarProperty label="Portfolio" value={entity.portfolio ? entity.portfolio.name : ''} />
                            <SidebarProperty label="Business Criticality" value={entity.businessCriticality} />
                            <SidebarProperty label="URL" type="externalLink" value="Link" url={entity.detailsUrl} />
                            <SidebarProperty label="Domain" value={entity.domain} />
                            <SidebarProperty label="Industry" value={entity.industry ? entity.industry.name : ''} />
                            <SidebarProperty label="State" value={entity.state} />
                        </SidebarProperties>
                        <SidebarProperties title="About" icon="Info" open>
                            <SidebarProperty label="Created" value={entity.created} type="datetime" />
                            <SidebarProperty value={entity.createdBy} type="principal" />
                            <SidebarProperty label="Last Modified" value={entity.modified} type="datetime" />
                            <SidebarProperty value={entity.modifiedBy} type="principal" />
                        </SidebarProperties>
                </Sidebar>
            );
        }
    }
);