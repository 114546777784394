import React from "react";
import { observer } from "mobx-react";

import { Stack } from "@ui/elements/Stack";
import { FontIcon } from "@ui/elements/Icon";

import { MarkdownViewer } from "@modules/editors/components/MarkdownViewer";

export const StateFlowActionBar = observer(({ routingManager, subState }) => {
    return subState && subState.description ? (
        <Stack
            className="animated faster fadeIn row--s card message--white"
            horizontal
            tokens={{ childrenGap: 30 }}
            verticalAlign="center"
            verticalFill
        >
            <FontIcon iconName={subState.blocked ? "Warning" : "Info"} style={{ fontSize: 30 }} />
            <div>
                <div>
                    Current status is <strong>{subState.name}</strong>.
                </div>
                <MarkdownViewer value={subState.description} />
            </div>
        </Stack>
    ) : null;
});
