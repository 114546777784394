import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { useStores } from "@hooks/useStores";

import { Text } from "office-ui-fabric-react/lib/Text";

import { AppPage } from "@modules/layout/containers/AppPage";

import { SettingsListNav } from "@modules/settings/containers/utilities/SettingsListNav";

import { ControlsSettingsPivot } from "@modules/settings/containers/tabs/ControlsSettingsPivot";
import { ControlsSettingsTabList } from "@modules/settings/containers/tabs/ControlsSettingsTabList";

export const ControlsSettingsPage = withRouter(
    observer(({ match }) => {
        const { settingsStore } = useStores();
        const { controlsSettingsStore } = settingsStore;
        const { breadcrumb, loading, error, defaultTab, selectedTab, activeTabs } = controlsSettingsStore;

        useEffect(() => {
            if (match) {
                controlsSettingsStore.setSelectedTab(match.params.tab);
            }
        }, [match.params.tab]);

        return (
            <AppPage
                loading={loading}
                error={error}
                breadcrumb={breadcrumb}
                hasSidebar={false}
                className="settings demand has--page-nav"
            >
                <SettingsListNav />
                <div>
                    <Text className="row--xxs" variant="xLarge" block>
                        Control Management
                    </Text>
                    <Text className="row--s" variant="small" block>
                        Control management module allows you to forecast, plan for and manage the demand for products
                        and services within the InfoSec function.
                    </Text>

                    <ControlsSettingsPivot defaultTab={defaultTab} selectedTab={selectedTab} activeTabs={activeTabs} />
                    <ControlsSettingsTabList />
                </div>
            </AppPage>
        );
    })
);
