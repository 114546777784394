import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router';

import { css } from '@uifabric/utilities/lib/css';
import { Text } from 'office-ui-fabric-react/lib/Text';

import { AppPage } from '@layout/containers/AppPage'; 
import { ObservablePagination } from '@baseComponents/ObservablePagination';
import { SettingsListNav } from '@modules/settings/containers/utilities/SettingsListNav';

import { ControlMapCardView } from '../../components/maps/ControlMapCardView';
import { ControlMapCommandBar } from '../../components/maps/ControlMapCommandBar';

import { ControlMapNewPanel } from '../panels/ControlMapNewPanel';

const getQueryStringParams = query => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
};

export const ControlMapBrowsePage = inject('pageContext', 'controlStore', 'routing')(withRouter(observer(
    class ControlMapBrowsePage extends Component {

        componentWillMount() {
            const { match, routing, controlStore } = this.props;

            const params = getQueryStringParams(routing.location.search);
            controlStore.mapBrowseStore.loadControlMaps({ page: params["p"] || 1, keywords: params["k"] });
        }

        componentWillReceiveProps(nextProps) {
            const { match, routing, controlStore } = nextProps;

            const params = getQueryStringParams(routing.location.search);
            controlStore.mapBrowseStore.loadControlMaps({ page: params["p"] || 1, keywords: params["k"] });
        }

        render () {
            const { routing, controlStore } = this.props;
            const { mapBrowseStore, mapNewStore } = controlStore;
            const { loading, error, breadcrumb, keywords, pagination, maps } = mapBrowseStore;

            return (
                <AppPage 
                    loading={loading} 
                    error={error} 
                    breadcrumb={breadcrumb}
                    hasSidebar={false}
                    className={css('settings control-map list', !error && 'has--page-nav')}>
                    <SettingsListNav />
                    <div>
                        <Text className="title" variant="xLarge" block>
                            Control Maps
                        </Text>

                        <ControlMapCommandBar
                            className="row--l"
                            keywords={keywords}
                            onAddMap={() => mapNewStore.show({})}
                            onSearchChange={(keywords) => { 
                                if(keywords) {
                                    routing.push(`/settings/control-maps?k=${encodeURIComponent(keywords)}`);
                                } else {
                                    routing.push(`/settings/control-maps`);
                                };
                            }} />

                        <ControlMapCardView
                            maps={maps}
                            loading={loading}
                            onControlMapClick={(item) => routing.push(`/settings/control-maps/${item.id}`)}
                            query={{}} />

                        <ObservablePagination 
                            {...pagination}
                            onPageChange={(index) => { 
                                routing.push(`/settings/control-maps?p=${index + 1}${keywords ? `&k=${encodeURIComponent(keywords)}` : ''}`);
                            }} />
                    </div>

                    <ControlMapNewPanel onAfterSave={(map) => {
                        routing.push(`/settings/control-maps/${map.id}`);
                    }} />
                </AppPage>
            );
        }
    }
)));
