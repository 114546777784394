import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';

import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Card } from '@uifabric/react-cards';

import { RoutingLink } from '../../../base/components/RoutingLink';

const rootClassName = cx(
    'cygraph-RegistryCardView-root',
    css` 
        max-width: 100%;
        padding: 0 0 var(--spacing-xs) 0;
        user-select: none;
        background: var(--white);
        
        & > .ms-CardItem {
            margin-top: 3px;
            padding: 0 var(--spacing-xs);
        }

        & > .headerItem {
            margin-top: 0;
            padding: var(--spacing-xs);
        }

        & > .scoreItem {
            padding: var(--spacing-xs);
        }

        &.selected {
            & > .headerItem {
                /*background: #e7e4f1;*/
            }
        }

        & .counter {
            & .count {
                font-size: var(--font-size-xl);
                border-radius: 70px;
                width: 70px;
                height: 70px;
                line-height: 60px;
                text-align: center;
                padding: 0;
                border: 4px solid var(--nav-color-accent);
                font-weight: 600;
            }
            & .text {
                font-size: var(--font-size-xs);
                margin-top: var(--spacing-xxs);
            }
        }
    `
);


export const RegistryCardView = observer(
    class RegistryCardView extends PureComponent {
        
        render () {
            const { registry, className, onRegistryClick, selected } = this.props;
            return (
                <Card
                    aria-label="Clickable vertical card with image bleeding at the center of the card" 
                    className={cx(className, rootClassName, selected && 'selected')}
                    onClick={() => {onRegistryClick && onRegistryClick(registry)}}>
                    <Card.Item className="headerItem">
                        <div className="header">
                            <Stack horizontal tokens={{ childrenGap: 16 }}>
                                <Text variant="mediumPlus" className="name">
                                    <RoutingLink url={`/risks/boards/${registry.id}`} onClick={(ev, url, routing) => {
                                        ev.stopPropagation();
                                        routing.push(url);
                                    }}>
                                    {registry.name}
                                    </RoutingLink>
                                </Text>
                                <Stack.Item grow={1}>
                                    <span />
                                </Stack.Item>
                                <RoutingLink url={`/risks/boards/${registry.id}`}>
                                    <Icon iconName="GridViewSmall" className="icon" />
                                </RoutingLink>
                            </Stack>
                        </div>
                    </Card.Item>
                    <Card.Item className="scoreItem">
                        <r-grid columns="2">
                            <r-cell>
                                <div className="counter flex--align--center flex--column">
                                    <div className="count"><div>{registry.openCount}</div></div>
                                    <div className="text">Total Open</div>
                                </div>
                            </r-cell>
                            <r-cell>
                                <div className="counter flex--align--center flex--column">
                                    <div className="count"><div>{registry.pendingReviewCount}</div></div>
                                    <div className="text">Pending Review</div>
                                </div>
                            </r-cell>
                        </r-grid>
                    </Card.Item>
                    <Card.Item className="footerItem">
                        <div className="footer">
                            <Stack horizontal tokens={{ childrenGap: 16 }}>                                
                                <Stack.Item grow={1}>
                                    <span />
                                </Stack.Item>
                                <Icon iconName="MoreVertical" className="icon" />
                            </Stack>
                        </div>
                    </Card.Item>
                </Card>
            );
        }
    }
);