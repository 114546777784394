import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Sidebar } from '@baseComponents/Sidebar';
import { SidebarProperties } from '@baseComponents/SidebarProperties';
import { SidebarProperty } from '@baseComponents/SidebarProperty';

export const AssetSidebar = observer(
    class AssetSidebar extends Component {

        render () {
            const { asset } = this.props;
            return (
                <div data-test="AssetSideBar">
                    <Sidebar>
                        <SidebarProperties title="Details" icon="List" open>
                                <SidebarProperty label="Portfolio" value={asset.portfolio ? asset.portfolio.name : ''} />
                                <SidebarProperty label="Type" value={asset.pattern ? asset.pattern.name : ''} />
                                <SidebarProperty label="Alias" value={asset.alias} />
                                <SidebarProperty label="Business Criticality" value={asset.businessCriticality} />
                                <SidebarProperty label="URL" type="externalLink" value="Link" url={asset.detailsUrl} />
                                <SidebarProperty label="State" value={asset.state} />
                            </SidebarProperties>
                            {asset.primarySupplier && (
                                <SidebarProperties title="Primary Supplier" icon="Ferry" open>
                                    <SidebarProperty label="Name" value={asset.primarySupplier.name} />
                                    <SidebarProperty label="Created" value={asset.primarySupplier.created} type="datetime" />
                                    <SidebarProperty label="State" value={asset.primarySupplier.state} />
                                </SidebarProperties>
                            )}
                            <SidebarProperties title="About" icon="Info" open>
                                <SidebarProperty label="Created" value={asset.created} type="datetime" />
                                <SidebarProperty value={asset.createdBy} type="principal" />
                                <SidebarProperty label="Last Modified" value={asset.modified} type="datetime" />
                                <SidebarProperty value={asset.modifiedBy} type="principal" />
                            </SidebarProperties>
                    </Sidebar>
                </div>
            );
        }
    }
);