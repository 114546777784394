import React, { Component } from "react";
import { observer } from "mobx-react";
import Moment from "react-moment";

import { Label } from "office-ui-fabric-react/lib/Label";
import { Text } from "office-ui-fabric-react/lib/Text";

import { AdaptiveField } from "@modules/base/components/AdaptiveField";
import { isNotNullOrEmpty } from "@modules/editors/components/RichTextField";
import RichTextViewer from "@modules/editors/components/RichTextViewer";
import { AssetPicker } from "@modules/assets/containers/pickers/AssetPicker";

import { WorkItemPriority } from "@modules/workitems/components/core/WorkItemPriority";

export const WorkItemDetailsSection = observer(
    class WorkItemDetailsSection extends Component {
        render() {
            const { workItem, schema } = this.props;

            let hidden = [];
            if (schema && schema.overrides && schema && schema.overrides.length > 0) {
                hidden = schema.overrides.filter((o) => o.hidden).map((o) => o.name);
            }

            return (
                <div>
                    <r-grid columns-l="2" columns="1" class="no--margin">
                        <r-cell span-l="2" span="1">
                            <div className="row--xs">
                                <Label>Description</Label>
                                {isNotNullOrEmpty(workItem.description) ? (
                                    <RichTextViewer className="description" value={workItem.description} />
                                ) : (
                                    "Not Set"
                                )}
                            </div>
                        </r-cell>
                        {hidden.indexOf("portfolio") === -1 && (
                            <r-cell span-l="1" span="1">
                                <div className="row--xs">
                                    <Label>Portfolio</Label>
                                    <Text block nowrap>
                                        {workItem.portfolio && workItem.portfolio.name}
                                    </Text>
                                </div>
                            </r-cell>
                        )}
                        {hidden.indexOf("assetId") === -1 && (
                            <r-cell span-l="1" span="1">
                                <div className="row--xs">
                                    <AssetPicker readOnly label="Asset" selectedId={workItem.assetId} />
                                </div>
                            </r-cell>
                        )}
                        {hidden.indexOf("priority") === -1 && (
                            <r-cell span-l="1" span="1">
                                <div className="row--xs">
                                    <Label>Priority</Label>
                                    <WorkItemPriority value={workItem.priority} />
                                </div>
                            </r-cell>
                        )}

                        {hidden.indexOf("dueDate") === -1 && (
                            <r-cell span-l="1" span="1">
                                <div className="row--xs">
                                    <Label>Due date</Label>
                                    <Text block nowrap>
                                        {workItem.dueDate ? (
                                            <Moment utc date={workItem.dueDate} format="ddd Do MMM YYYY" />
                                        ) : (
                                            "Not set"
                                        )}
                                    </Text>
                                </div>
                            </r-cell>
                        )}

                        <r-cell span-l="1" span="1">
                            <div className="row--xs">
                                <AdaptiveField
                                    type="Principal"
                                    mode="view"
                                    label="Requester"
                                    value={workItem.requestedBy}
                                />
                            </div>
                        </r-cell>
                        <r-cell span-l="1" span="1">
                            <Label>Source</Label>
                            <Text block nowrap>
                                {workItem.source ? workItem.source : "Not set"}
                            </Text>
                        </r-cell>
                    </r-grid>
                </div>
            );
        }
    }
);
