import { netRiskScoresItems } from '@modules/risks/stores/RiskContants';

import { ModuleNames, getModuleConfig } from "../../../../configProvider";
const riskModule = getModuleConfig(ModuleNames.Risks);
const impactArray = Array.from(Array(riskModule.maxImpact || 4).keys()).map(E => E +1);;
const likelihoodArray = Array.from(Array(riskModule.maxLikelihood || 4).keys()).map(E => E +1);;
const impactDropdownOptions = impactArray.map(I => { return {key: I, text: I}});
const likelihoodDropdownOptions = likelihoodArray.map(I => { return {key: I, text: I}});

const exceptionFilters = (getPortfolios, getSubStatuses, getRiskTypes) => [
    {
        type: 'registryPicker',
        id: 'registry',
    },
    {
        id:'portfolios',
        name:'Portfolios',
        type:'multiselectList',
        getValues: getPortfolios,
    },
    { 
        id: 'assignedToUser',
        title:'Assigned To User',
        type:'userpicker'
    },
    {
        id:'netScore',
        name:'Net Score',
        type:'multiselectList',
        values: netRiskScoresItems,
    },
    {
        id:'netImpact',
        name:'Net Impact',
        type:'multiselectList',
        values: impactDropdownOptions,
    },
    {
        id:'netLikelihood',
        name:'Net Likelihood',
        type:'multiselectList',
        values: likelihoodDropdownOptions,
    },
    {
        id:'subStatus',
        name:'Status',
        type:'multiselectList',
        getValues: getSubStatuses,
    },
    {
        id:'created',
        name:'Created date range',
        type:'dateRangePicker'
    },
    {
        id:'closed',
        name:'Closed date range',
        type:'dateRangePicker'
    },
    {
        id:'expiry',
        name:'Expiry date range',
        type:'dateRangePicker'
    },
]

export { exceptionFilters }