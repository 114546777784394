import { observable, action, computed, flow, toJS } from "mobx";

import { isNotNullOrEmpty } from "../../editors/components/RichTextField";

import { RiskService } from "../../../api/risks";
import { RiskStore } from "./RiskStore";

interface ResponseHandle {
    resolve: (value: any) => void;
    reject: (value?: any) => void;
}

export class RiskReviewFormStore {
    private parentStore: RiskStore;
    private riskService: RiskService;
    private responseHandle?: ResponseHandle;

    @observable public visible: boolean = false;
    @observable public formData: any;
    @observable public formOptions: any;

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
    }

    @action
    public show(options) {
        this.visible = true;

        this.formOptions = {
            pageTitle: options.pageTitle || `${options.risk.code} - ${options.risk.title}`,
            pageType: options.pageType || `${options.risk.type.name} Review`,
            typeTemplate: options.risk.type.template,
            typeName: options.risk.type.name,
            typeId: options.risk.type.id,
        };

        let current = options.review;
        if (current) {
            current = toJS(current);
            current.nextReviewDue = null;
        }

        this.formData = current || {
            lifecycleId: options.lifecycleId,
            grossScore: {},
            netScore: {},
            treatments: [
                {
                    type: "Mitigate",
                    description: null,
                },
            ],
            controls: [],
        };

        this.formData.nextReviewDue = options.nextReviewDue;
        this.formData.monitor = options.monitor;

        return new Promise<any>((resolve, reject) => {
            this.responseHandle = { resolve, reject };
        });
    }

    @action
    public hide(_) {
        this.visible = false;
        this.formData = null;
        this.formOptions = null;
        this.responseHandle = null;
    }

    @computed
    public get isValid() {
        if (!this.formData) {
            return false;
        }

        const isExceptionTemplate = this.formOptions.typeTemplate === "zerodai:risks:exception";
        const isControlException = isExceptionTemplate && this.formOptions.typeName === "Control Exception";

        const review = this.formData;

        if (isControlException) {
            return (
                review.businessAppetite &&
                review.category &&
                review.category.id &&
                review.nextReviewDue &&
                review.grossScore.score &&
                review.netScore.score &&
                review.controls &&
                review.controls.length > 0 &&
                (isExceptionTemplate ||
                    (review.treatments &&
                        review.treatments.length == 1 &&
                        review.treatments[0].type &&
                        isNotNullOrEmpty(review.treatments[0].description))) &&
                isNotNullOrEmpty(review.grossScore.description) &&
                isNotNullOrEmpty(review.netScore.description)
            );
        }

        return (
            review.nextReviewDue &&
            review.grossScore.score &&
            review.netScore.score &&
            (isExceptionTemplate ||
                (review.treatments &&
                    review.treatments.length == 1 &&
                    review.treatments[0].type &&
                    isNotNullOrEmpty(review.treatments[0].description))) &&
            isNotNullOrEmpty(review.grossScore.description) &&
            isNotNullOrEmpty(review.netScore.description)
        );
    }

    @action
    public resolve(formData) {
        this.responseHandle.resolve(toJS(formData));
    }

    @action
    public reject(error) {
        this.responseHandle.reject(toJS(error));
    }
}
