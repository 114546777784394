import { observable, action, flow } from "mobx";

import { RootStore } from "../../base/RootStore";

import { IdentityService } from "../../../api/identity";
import { PaginationStore } from "../../base/PaginationStore";
import { PrincipaGrouplPickerStore } from "./PrincipalGroupPickerStore";

import { resultContent } from "office-ui-fabric-react/lib/components/FloatingPicker/PeoplePicker/PeoplePicker.scss";

export class PrincipalPickerStore {
    private rootStore: RootStore;
    private identityService: IdentityService;
    public principaGrouplPickerStore: PrincipaGrouplPickerStore;
    public groupPickerStore: PrincipaGrouplPickerStore;
    @observable public selectedPrincipals: any[] = [];
    @observable public principals: any[] = [];
    @observable public usersList: any[] = [];
    @observable public pagination: PaginationStore;
    @observable public loading: boolean = false;
    private keywords: string = "";
    private page: number = 1;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.pagination = new PaginationStore();
        this.identityService = new IdentityService(rootStore.authProvider);
        this.groupPickerStore = new PrincipaGrouplPickerStore(this, this.identityService);
    }

    public searchPrincipals = flow(function* (query) {
        this.loading = true;
        var options = Object.assign({ page: 1, keywords: "" }, query);
        try {
            const result = yield this.identityService.searchPrincipals({
                pageSize: this.pagination.itemsPerPage,
                startIndex: (options.page - 1) * this.pagination.itemsPerPage,
                keywords: options.keywords,
            });
            this.principals = result.items;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
            return result;
        } catch (e) {
            console.error(e);
        } finally {
            this.loading = false;
        }
    });

    public resolvePrincipals = flow(function* (principals) {
        this.loading = true;
        try {
            const result = yield this.identityService.resolvePrincipals(principals);
            return result;
        } catch (e) {
            console.error(e);
        } finally {
            this.loading = false;
        }
    });

    @action public setSelected(principals: any[]) {
        this.selectedPrincipals = principals;
    }

    @action public setSelectedWithId(id: string | null) {
        if (!id) {
            this.selectedPrincipals = [];
        } else {
            if (this.selectedPrincipals.length == 1 && this.selectedPrincipals[0].id == id) {
                return;
            }
            this.loadPrincipals([id]);
        }
    }

    @action public setSelectedWithIds(ids: string[] | null) {
        if (ids == null || ids.length == 0) {
            this.selectedPrincipals = [];
        } else {
            this.loadPrincipals(ids);
        }
    }

    public loadPrincipals = flow(function* (ids: string[]) {
        this.loading = true;
        try {
            this.selectedPrincipals = yield this.identityService.getPrincipalsByIds(ids);
            return this.selectedPrincipals;
        } catch (e) {
            console.error(e);
        } finally {
            this.loading = false;
        }
    });

    searchUsers = flow(function* (query, noLoading: boolean = false) {
        if (!noLoading) this.loading = true;
        var options = Object.assign({ page: 1, keywords: null }, query);
        this.keywords = query && query.keywords ? query.keywords : "";
        this.page = query && query.page ? query.page : 1;
        try {
            // debugger
            let result;
            if (query && query.selectedGroup) {
                result = yield this.identityService.searchPrincipalsByGroup({
                    pageSize: this.pagination.itemsPerPage,
                    startIndex: (options.page - 1) * this.pagination.itemsPerPage,
                    keywords: options.keywords,
                    groupId: query.selectedGroup.id,
                });
            } else {
                result = yield this.identityService.searchPrincipalsUsers({
                    pageSize: this.pagination.itemsPerPage,
                    startIndex: (options.page - 1) * this.pagination.itemsPerPage,
                    keywords: options.keywords,
                });
            }
            // debugger
            this.usersList = result.items;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
        } catch (e) {
            console.error(e);
        } finally {
            this.loading = false;
        }
        return this.identityService
            .searchPrincipalsUsers({
                pageSize: this.pagination.itemsPerPage,
                startIndex: (options.page - 1) * this.pagination.itemsPerPage,
                keywords: options.keywords,
            })
            .then(this.searchUsersOnSuccess, this.searchPrincipalsOnError);
    });

    // getUserGroups = flow(function*(userId) {
    //     this.loading = true;
    //     try {
    //         const result = yield this.identityService.getUserGroups({
    //             userId,
    //             pageSize: 50,
    //             startIndex: 0,
    //             keywords: '',
    //         });
    //         return result;
    //     } catch (e) {
    //         console.error(e);
    //     } finally {
    //         this.loading = false;
    //     }
    // });

    // removeUserFromGroup = flow(function*(userId, groupId) {
    //     try {
    //         yield this.identityService.removeUserFromGroup(userId, groupId);
    //         return this.searchUsers({
    //             keywords: this.keywords,
    //             page: this.page,
    //         }, true);
    //     } catch (e) {
    //         console.error(e);
    //     }
    // })

    // addUserToGroup = flow(function*(userId, groupId) {
    //     try {
    //         yield this.identityService.addUserToGroup(userId, groupId);
    //         return this.searchUsers({
    //             keywords: this.keywords,
    //             page: this.page,
    //         }, true);
    //     } catch (e) {
    //         console.error(e);
    //     }
    // })
}
