import React from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import ace from "brace";
import "brace/mode/json";
import "brace/theme/github";

import { JsonEditor as Editor } from "jsoneditor-react";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { PanelType } from "office-ui-fabric-react/lib/Panel";
import { PrimaryButton, ActionButton } from "office-ui-fabric-react/lib/Button";

import { ObservablePanel } from "../../../base/components/ObservablePanel";

const rootClassName = cx(
    "cygraph-PatternJsonEditorPanel-root",
    css`
        height: 100%;

        & > div {
            height: 100%;
        }

        .jsoneditor {
            border: 1px solid var(--border-color-dark);
        }
        .jsoneditor-menu {
            background-color: var(--black);
            border-bottom: 1px solid var(--border-color-dark);
        }
    `
);

export const PatternJsonEditorPanel = observer(({ visible, title, value, isDirty, onChange, onApply, onCancel }) => {
    return (
        <ObservablePanel
            hidden={!visible}
            pageTitle={title}
            pageType="Editor"
            panelType={PanelType.medium}
            styles={{ content: { height: "100%" } }}
            onClosePanel={() => onCancel()}
            onRenderFooter={() => (
                <Stack horizontal horizontalAlign="space-between">
                    <Stack horizontal tokens={{ childrenGap: 15 }}></Stack>
                    <Stack horizontal tokens={{ childrenGap: 15 }}>
                        <ActionButton styles={{ root: { height: "32px" } }} onClick={() => onCancel()} text="Cancel" />
                        <PrimaryButton onClick={() => onApply(value)} text="Apply" disabled={!isDirty} />
                    </Stack>
                </Stack>
            )}
        >
            <div className={rootClassName}>
                <Editor
                    mainMenuBar={false}
                    navigationBar={true}
                    statusBar={true}
                    value={value}
                    mode="code"
                    onChange={(json) => onChange(json)}
                    ace={ace}
                    theme="ace/theme/github"
                />
            </div>
        </ObservablePanel>
    );
});
