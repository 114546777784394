import React from "react";
import { observer } from "mobx-react";

import { ModuleFieldMeasure } from "./ModuleFieldMeasure";

export const ModuleFieldGroup = observer(
    ({
        module,
        group,
        indexMap,
        advisoryMap,
        disabled,
        onListAdvisories,
        onEditMeasure,
        onUpdateMeasure,
        onRemoveMeasure,
        onAddOption,
        onUpdateOption,
        onRemoveOption,
    }) => {
        return (
            <div>
                {group.measures.map((measure) => (
                    <ModuleFieldMeasure
                        key={measure.id}
                        module={module}
                        group={group}
                        measure={measure}
                        indexMap={indexMap}
                        advisoryMap={advisoryMap}
                        disabled={disabled}
                        onListAdvisories={onListAdvisories}
                        onAddOption={onAddOption}
                        onEditMeasure={onEditMeasure}
                        onUpdateMeasure={onUpdateMeasure}
                        onRemoveMeasure={onRemoveMeasure}
                        onUpdateOption={onUpdateOption}
                        onRemoveOption={onRemoveOption}
                    />
                ))}
            </div>
        );
    }
);
