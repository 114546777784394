import React from "react";
import { observer } from "mobx-react";

import { PivotRouting, PivotRoutingItem } from "@modules/base/components/PivotRouting";

export const EntityRoutingPivot = observer(({ entity, selectedTab }) => {
    const defaultTab = "summary";
    const activeTabs = [defaultTab, "assets", "documents", "work-items", "assessments", "compliance"];
    return (
        <PivotRouting
            data-test="entityRoutingPivot"
            aria-label="Entity properties, related documents and work"
            selectedKey={activeTabs.indexOf(selectedTab) === -1 ? defaultTab : selectedTab}
            onLinkClick={(item, routing) => {
                if (item.props.itemKey === defaultTab) {
                    routing.push(`/entities/browse/${entity.id}`);
                } else {
                    routing.push(`/entities/browse/${entity.id}/${item.props.itemKey}`);
                }
            }}
        >
            <PivotRoutingItem itemKey="summary" headerText="Summary" itemIcon="Info" />
            <PivotRoutingItem itemKey="assets" headerText="Related Assets" itemIcon="WebAppBuilderFragment" />
            <PivotRoutingItem itemKey="documents" headerText="Related Documents" itemIcon="DocLibrary" />
            <PivotRoutingItem itemKey="work-items" headerText="Related Work" itemIcon="ExternalTFVC" />
            <PivotRoutingItem itemKey="assessments" headerText="Related Assessments" itemIcon="WorkItem" />
            <PivotRoutingItem itemKey="compliance" headerText="Policy & Compliance" itemIcon="Shield" />
        </PivotRouting>
    );
});
