import React from "react";
import { observer } from "mobx-react";
import { Route, Switch } from "react-router";

import { AssetSummaryTab } from "@modules/assets/containers/tabs/AssetSummaryTab";
import { AssetDocumentsTab } from "@modules/assets/containers/tabs/AssetDocumentsTab";
import { AssetWorkItemsTab } from "@modules/assets/containers/tabs/AssetWorkItemsTab";
import { AssetAssessmentsTab } from "@modules/assets/containers/tabs/AssetAssessmentsTab";
import { AssetComplianceTab } from "@modules/assets/containers/tabs/AssetComplianceTab";

export const AssetTabList = observer(({ asset, onUpload }) => {
    return (
        <Switch>
            <Route
                exact
                path={`/assets/browse/:id/documents`}
                component={() => <AssetDocumentsTab asset={asset} onUpload={onUpload} />}
            />
            <Route exact path={`/assets/browse/:id/work-items`} component={() => <AssetWorkItemsTab asset={asset} />} />
            <Route
                exact
                path={`/assets/browse/:id/assessments`}
                component={() => <AssetAssessmentsTab asset={asset} />}
            />
            <Route
                exact
                path={`/assets/browse/:id/compliance`}
                component={() => <AssetComplianceTab asset={asset} />}
            />
            <Route exact path={`/assets/browse/:id`} component={() => <AssetSummaryTab asset={asset} />} />
        </Switch>
    );
});
