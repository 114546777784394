import React from "react";

import { PropertyTable } from "@ui/pdf/Table";

export const TimelinePropertyTable = ({ assessment }) => {
    const properties = [
        { name: "Triage", value: assessment.created, type: "date" },
        { name: "Self assessment", value: assessment.started, type: "date" },
        { name: "Submitted on", value: assessment.submitted, type: "date" },
        { name: "Review started on", value: assessment.reviewed, type: "date" },
        { name: "Closed on", value: assessment.closed, type: "date" },
    ];

    return <PropertyTable data={properties} />;
};
