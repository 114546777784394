import { observable, action, flow } from "mobx";

import { IdentityService } from '../../../api/identity';
import { PrincipalStore } from './PrincipalStore';

export class GroupPickerStore {

    private parentStore: PrincipalStore;
    private identityService: IdentityService;
    
    @observable public selectedGroupIds: string[] = [];
    @observable public groups: any[] = [];
    @observable public loading: boolean = false;

    constructor(parentStore: PrincipalStore) {
        this.parentStore = parentStore;
        this.identityService = parentStore.identityService;
    }

    public loadGroups = flow(function*(query) {
        this.loading = true;
        var options = Object.assign({ page: 1, keywords: '' }, query);
        try {
            const result = yield this.identityService.searchPrincipalsGroups({ 
                pageSize: 250, 
                startIndex: (options.page - 1) * 250,
                keywords: options.keywords
            });         
            this.groups = result.items;
            return result;
        } catch (e) {
            console.error(e);
        } finally {
            this.loading = false;
        }
    });

    @action
    public setSelected(groups: any[]) {
        this.selectedGroupIds = groups.map(i => i.id);
    }

    @action
    public setSelectedWithId(id: string | null) {
        if(!id) {
            this.selectedGroupIds = [];
        } else {
            if(this.selectedGroupIds.length == 1 && this.selectedGroupIds[0] == id) {
                return;
            }
            this.selectedGroupIds = [id];
        }
    }

    @action
    public setSelectedWithIds(ids: string[] | null) {
        if(ids == null || ids.length == 0) {
            this.selectedGroupIds = [];
        } else {
            this.selectedGroupIds = ids;
        }
    }
}
