import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { ExceptionGridView } from "@modules/risks/components/exceptions/ExceptionGridView";

export const AssessmentExceptionsTab = observer(({ assessment }) => {
    const { assessmentStore } = useStores();
    const { exceptionStore } = assessmentStore;
    const { loading, risks } = exceptionStore;
    const assessmentId = assessment ? assessment.id : null;

    useEffect(() => {
        if (assessmentId) {
            exceptionStore.loadAssessmentRisks(assessmentId);
        }
    }, [assessmentId, exceptionStore]);

    return (
        <div>
            <ExceptionGridView loading={loading} disableSelection={true} risks={risks} />
        </div>
    );
});
