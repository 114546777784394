import React, { useRef, useCallback } from "react";
import { observer } from "mobx-react";

import { Dropdown } from "office-ui-fabric-react/lib/Dropdown";
import { DatePicker } from "office-ui-fabric-react/lib/DatePicker";
import { Stack } from "@ui/elements/Stack";
import { DropZone } from "@ui/elements/DropZone";
import { CommandButton } from "@ui/elements/Button";

import { FileGridView } from "@modules/base/components/FileGridView";
import { ObservableSeparator } from "@modules/base/components/ObservableSeparator";
import { BigTextField } from "@modules/base/components/BigTextField";
import { PrincipalPicker } from "@modules/identity/containers/PrincipalPicker";
import { SecurityTrimmed } from "@modules/identity/containers/utilities/SecurityTrimmed";
import { RoleNames } from "@modules/identity/stores/PrincipalContextUtilities";
import { AssetPicker } from "@modules/assets/containers/pickers/AssetPicker";
import { PortfolioPicker } from "@modules/portfolios/containers/pickers/PortfolioPicker";
import { RichTextField } from "@modules/editors/components/RichTextField";

import { WorkItemZone } from "@modules/workitems/components/core/WorkItemZone";
import { WorkItemPriorityPicker } from "@modules/workitems/components/core/WorkItemPriorityPicker";

export const WorkItemEditForm = observer(({ formData, formOptions }) => {
    const openTriggerRef = useRef();

    const onOpen = useCallback(() => {
        if (openTriggerRef.current) {
            openTriggerRef.current();
        }
    }, [openTriggerRef]);

    const schema = formOptions.schema;
    let hidden = [];
    if (schema && schema.overrides && schema && schema.overrides.length > 0) {
        hidden = schema.overrides.filter((o) => o.hidden).map((o) => o.name);
    }
    const documents = formData.documents;
    return (
        <DropZone
            onDrop={(ds, rd, ev) => {
                formData.documents = [...ds, ...documents];
                ev.stopPropagation();
            }}
            openTriggerRef={openTriggerRef}
        >
            <r-grid columns="12" class="column-gap-l">
                <r-cell span="8">
                    <Stack tokens={{ childrenGap: 20 }}>
                        <div>
                            <r-grid columns="1">
                                <r-cell>
                                    <BigTextField
                                        label="Title"
                                        required
                                        placeholder="Please enter a request title"
                                        value={formData.title}
                                        transparent
                                        onChange={(ev, value) => (formData.title = value)}
                                    />
                                </r-cell>
                            </r-grid>
                        </div>
                        <div>
                            <r-grid columns="1">
                                <r-cell>
                                    <ObservableSeparator>Key Information</ObservableSeparator>
                                </r-cell>
                            </r-grid>
                            <r-grid columns="2">
                                {hidden.indexOf("portfolio") === -1 && (
                                    <r-cell>
                                        <PortfolioPicker
                                            className="cyPortfolioDropDown"
                                            placeholder="Select a portfolio"
                                            label="Portfolio"
                                            required
                                            selected={formData.portfolio}
                                            onChange={(portfolio) => {
                                                formData.portfolio = portfolio;
                                            }}
                                        />
                                    </r-cell>
                                )}
                                {hidden.indexOf("assetId") === -1 && (
                                    <r-cell>
                                        <AssetPicker
                                            label="Asset"
                                            placeholder="Select an asset"
                                            selectedId={formData.assetId}
                                            onChange={(asset) => {
                                                formData.assetId = asset ? asset.id : null;
                                            }}
                                        />
                                    </r-cell>
                                )}
                            </r-grid>
                            <r-grid columns="2">
                                {hidden.indexOf("priority") === -1 && (
                                    <r-cell>
                                        <WorkItemPriorityPicker
                                            placeholder="Select a priority"
                                            label="Priority"
                                            required
                                            selectedKey={formData.priority}
                                            onChange={(ev, op) => (formData.priority = op.key)}
                                        />
                                    </r-cell>
                                )}
                                {hidden.indexOf("dueDate") === -1 && (
                                    <r-cell>
                                        <DatePicker
                                            label="Due date"
                                            isRequired={true}
                                            placeholder="Select a due date..."
                                            ariaLabel="Select a due date"
                                            value={formData.dueDate ? new Date(formData.dueDate) : null}
                                            onSelectDate={(date) => (formData.dueDate = date)}
                                        />
                                    </r-cell>
                                )}
                            </r-grid>
                            <r-grid columns="2">
                                {hidden.indexOf("businessContact") === -1 && (
                                    <r-cell>
                                        <PrincipalPicker
                                            label="Business Contact"
                                            selected={formData.businessContact}
                                            onSelected={(contact) => (formData.businessContact = contact)}
                                        />
                                    </r-cell>
                                )}
                                {hidden.indexOf("technicalContact") === -1 && (
                                    <r-cell>
                                        <PrincipalPicker
                                            label="Technical Contact"
                                            selected={formData.technicalContact}
                                            onSelected={(contact) => (formData.technicalContact = contact)}
                                        />
                                    </r-cell>
                                )}
                            </r-grid>
                            <r-grid columns="1">
                                <r-cell>
                                    <RichTextField
                                        label="Description"
                                        placeholder="Please enter a description of the work."
                                        value={formData.description}
                                        onChange={(val) => {
                                            formData.description = val;
                                        }}
                                    />
                                </r-cell>
                            </r-grid>
                        </div>
                        <div>
                            {schema && schema.extensions && schema.extensions.length > 0 && (
                                <r-grid columns="1">
                                    <r-cell>
                                        <ObservableSeparator>Additional Fields</ObservableSeparator>
                                    </r-cell>
                                    <r-cell>
                                        <WorkItemZone zone="main" defaultZone schema={schema} workItem={formData} />
                                    </r-cell>
                                    <r-cell>
                                        <WorkItemZone zone="bottomRight" schema={schema} workItem={formData} />
                                    </r-cell>
                                    <r-cell>
                                        <WorkItemZone zone="topRight" schema={schema} workItem={formData} />
                                    </r-cell>
                                    <r-cell>
                                        <WorkItemZone zone="topLeft" schema={schema} workItem={formData} />
                                    </r-cell>
                                    <r-cell>
                                        <WorkItemZone zone="bottomLeft" schema={schema} workItem={formData} />
                                    </r-cell>
                                </r-grid>
                            )}
                        </div>
                    </Stack>
                </r-cell>
                <r-cell span="4">
                    <Stack tokens={{ childrenGap: 20 }} style={{ marginTop: 25 }}>
                        <r-grid columns="1">
                            <r-cell>
                                <ObservableSeparator
                                    onRenderSuffix={() => (
                                        <CommandButton
                                            iconProps={{ iconName: "Add" }}
                                            text="Add"
                                            disabled={!onOpen}
                                            onClick={onOpen}
                                        />
                                    )}
                                >
                                    Documents
                                </ObservableSeparator>
                            </r-cell>
                            <r-cell>
                                <FileGridView
                                    files={documents}
                                    onRemove={(file, i) => (formData.documents = documents.filter((d) => d !== file))}
                                />
                            </r-cell>
                        </r-grid>
                        <SecurityTrimmed any={[RoleNames.Admin, RoleNames.WorkItems, RoleNames.Assessments]}>
                            <r-grid columns="1">
                                <r-cell>
                                    <ObservableSeparator>Request Source</ObservableSeparator>
                                </r-cell>
                                <r-cell>
                                    <Dropdown
                                        label="Source"
                                        autoComplete="on"
                                        selectedKey={formData.source}
                                        onChange={(ev, op) => (formData.source = op.key)}
                                        options={[
                                            "Direct",
                                            "Programme",
                                            "Objective",
                                            "Planning",
                                            "Project",
                                            "Compliance",
                                        ].map((p) => ({ key: p, text: p }))}
                                    />
                                </r-cell>
                                <r-cell>
                                    <PrincipalPicker
                                        label="Requester"
                                        selected={formData.requestedBy}
                                        onSelected={(contact) => (formData.requestedBy = contact)}
                                    />
                                </r-cell>
                            </r-grid>
                        </SecurityTrimmed>
                    </Stack>
                </r-cell>
            </r-grid>
        </DropZone>
    );
});
