import React, { Component } from "react";
import { observer } from "mobx-react";

import { CommandBar } from "office-ui-fabric-react/lib/CommandBar";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { Text } from "office-ui-fabric-react/lib/Text";
import { Toggle } from "office-ui-fabric-react/lib/Toggle";
import { SearchBox } from "office-ui-fabric-react/lib/SearchBox";

import { BoxPanel } from "../../../base/components/BoxPanel";

export const CatalogueCommandBar = observer(
    class CatalogueCommandBar extends Component {
        render() {
            const { className, categories, onCategoryChange, category, isSimplifiedView, onToggleViewChange, keywords, onKeywordsChange, pickerMode } =
                this.props;

            const categoriesList = ["All"].concat(categories);
            const farItems = [];
            const items = [
                {
                    key: "categories",
                    text: pickerMode ? "Category" : `Category: ${category}`,
                    className: "cy-categories",
                    split: !pickerMode && categoriesList && categoriesList.length > 0,
                    subMenuProps: categoriesList
                        ? {
                              onItemClick: (ev, item) => {
                                  if (item && item.key) {
                                      onCategoryChange(item.key);
                                  }
                              },
                              items: (categoriesList || []).map((p) => {
                                  return { key: p, text: p, checked: p === category };
                              }),
                          }
                        : null,
                },
            ];

            (pickerMode ? farItems : items).push({
                key: "keywords",
                text: "Keywords",
                onRender: () => {
                    return (
                        <SearchBox
                            value={keywords}
                            placeholder="Filter by keywords"
                            styles={{
                                root: {
                                    height: "32px",
                                    minWidth: "270px",
                                    margin: "auto",
                                },
                            }}
                            onSearch={(value) => onKeywordsChange(value)}
                            onChange={(ev, value) => onKeywordsChange(value)}
                            onClear={() => onKeywordsChange(null)}
                        />
                    );
                },
            });

            if (!pickerMode) {
                farItems.push({
                    key: "search",
                    text: "Search",
                    onRender: () => {
                        return (
                            <Stack
                                data-test="viewToggle"
                                disableShrink
                                horizontal
                                tokens={{
                                    childrenGap: 10,
                                }}
                                styles={{
                                    root: {
                                        margin: "auto",
                                    },
                                }}
                            >
                                <Text block>Simplified view</Text>
                                <Toggle checked={isSimplifiedView} onChange={onToggleViewChange} styles={{ root: { marginBottom: 0 } }} />
                            </Stack>
                        );
                    },
                });
            }

            return (
                <BoxPanel shadow className={className}>
                    <CommandBar data-test="topBar" items={items} farItems={farItems} />
                </BoxPanel>
            );
        }
    }
);
