import { observable, flow, computed, action } from "mobx";

import { ControlService } from "../../../api/modelling";
import { ControlStore } from "./ControlStore";

export class ControlMapViewerStore {
    public controlService: ControlService;
    public parentStore: ControlStore;

    @observable public loading: boolean = false;
    @observable public map: any = null;
    @observable public model: any = null;
    @observable public controlMaps: any[] = [];
    @observable public selectedAxis: any[] = [];
    @observable public error: any;

    constructor(parentStore: ControlStore) {
        this.parentStore = parentStore;
        this.controlService = parentStore.controlService;
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [];

        builder.push({ text: "Settings", key: "settings", onClick: () => this.parentStore.rootStore.routing.push(`/settings`) });
        builder.push({ text: "Control Maps", key: "control-maps", onClick: () => this.parentStore.rootStore.routing.push(`/settings/control-maps`) });

        if (this.map) {
            builder.push({ text: this.map.name, key: this.map.id, isCurrentItem: true });
        }

        return builder;
    }

    @computed public get csvData() {
        const csvValues = [];
        // console.table(this.controlMaps)
        // console.table(this.model)
        // console.table(this.model.axes)
        const csvRow = {};
        csvRow["Series"] = "Reference Map";
        this.model.axes
            .filter((axe) => this.selectedAxis.includes(axe.id))
            .forEach((a) => {
                csvRow[a.name] = a.targetValue;
            });
        csvValues.push(csvRow);
        if (this.controlMaps.length > 0) {
            this.controlMaps.forEach((D, i) => {
                // console.log(D)
                // debugger
                const csvRow = {};
                csvRow["Series"] = D.name;
                D.axes
                    .filter((axe) => this.selectedAxis.includes(axe.id))
                    .forEach((axe) => {
                        // debugger
                        // const axe = this.model.axes.find(A => A.id === DS.axis);
                        csvRow[axe.name] = axe.value;
                    });
                // console.table(csvRow);
                csvValues.push(csvRow);
            });
        }
        // console.table(csvValues)
        return csvValues;
    }

    public loadControlMap = flow(function* (query: any) {
        const options = Object.assign({ cache: true }, query);

        this.loading = true;
        if ((this.map !== null && this.map.id !== options.mapId) || !options.cache) {
            this.map = null;
            this.model = null;
            this.controlMaps = [];
        }
        this.error = null;

        try {
            this.map = yield this.controlService.getControlMap(options.mapId);
            this.model = yield this.controlService.getControlMapModel(options.mapId);

            const defaultQuery = { status: ["Remediation", "Reviewing", "Closed"] };
            const assessmentService = this.parentStore.rootStore.assessmentStore.assessmentService;

            const historicalControlMap = yield assessmentService.getAdvisoriesControlMap({
                query: {
                    ...defaultQuery,
                    status: ["Closed"],
                    mapId: options.mapId,
                },
            });

            const ongoingControlMap = yield assessmentService.getAdvisoriesControlMap({
                query: {
                    ...defaultQuery,
                    status: ["Remediation", "Reviewing"],
                    mapId: options.mapId,
                },
            });

            this.controlMaps = [
                {
                    name: "Historical Assessments",
                    axes: historicalControlMap.items,
                },
                {
                    name: "Ongoing Assessments",
                    axes: ongoingControlMap.items,
                },
            ];
        } catch (e) {
            this.error = e;
            console.error(e);
        } finally {
            this.loading = false;
        }
    });
}
