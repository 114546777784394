import React, { Component } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { Panel, PanelType } from "office-ui-fabric-react/lib/Panel";
import { IconButton } from "office-ui-fabric-react/lib/Button";
import { ObservableBadge } from "@baseComponents/ObservableBadge";

const rootClassName = cx(
    "cygraph-ObservablePanel-root",
    css`
        & .ms-Panel-main {
            /*background-color: var(--background-color);*/
        }

        & .ms-Panel-header {
            margin-top: 5px;
            margin-bottom: 25px;
        }

        & .ms-Panel-footer {
            border-top: 1px solid var(--border-color-dark);
        }

        & .navigation {
            flex-direction: row;
            width: 100%;
            display: flex;

            & > .item {
                display: flex;
                align-items: center;
                justify-content: center;
                &.push {
                    margin-left: auto;
                }
                &:first-child {
                    margin-left: 25px;
                    margin-top: 5px;
                }
            }

            & > .item button {
                font-size: 20px;
                font-weight: 400;
                box-sizing: border-box;
                display: inline-block;
                text-align: center;
                cursor: pointer;
                vertical-align: top;
                padding: 0px 4px 0px 4px;
                width: 44px;
                height: 44px;
                background-color: transparent;
                color: rgb(96, 94, 92);
                &:hover {
                    color: rgb(50, 49, 48);
                    background-color: rgb(243, 242, 241);
                }
            }
        }
    `
);

export const ObservablePanel = observer(
    class ObservablePanel extends Component {
        render() {
            const {
                className,
                styles,
                pageTitle,
                pageType,
                panelType,
                hidden,
                children,
                onClosePanel,
                onRenderFooter,
                eventBubblingDisabled,
                onLightDismissClick,
                onOpened,
                onRenderNavigation,
            } = this.props;

            return (
                <Panel
                    isLightDismiss
                    className={cx(rootClassName, className)}
                    isOpen={!hidden}
                    onOpened={onOpened}
                    onDismiss={onClosePanel}
                    type={panelType || PanelType.medium}
                    isFooterAtBottom={true}
                    closeButtonAriaLabel="Close"
                    styles={styles}
                    headerText={pageTitle}
                    onLightDismissClick={onLightDismissClick}
                    onRenderNavigationContent={
                        pageType || onRenderNavigation ? () => this._renderNavigation() : undefined
                    }
                    onRenderFooterContent={onRenderFooter}
                    layerProps={{ eventBubblingEnabled: eventBubblingDisabled ? false : true }}
                >
                    {children}
                </Panel>
            );
        }

        _renderNavigation() {
            const { pageType, onClosePanel, onRenderNavigation } = this.props;

            return (
                <div className="navigation">
                    {onRenderNavigation ? (
                        onRenderNavigation()
                    ) : (
                        <>
                            <div className="item">
                                <ObservableBadge>{pageType}</ObservableBadge>
                            </div>
                            <div className="item push">
                                <IconButton
                                    iconProps={{ iconName: "Cancel" }}
                                    tabIndex={-1}
                                    onClick={onClosePanel}
                                    className="ms-Panel-closeButton ms-PanelAction-close"
                                    title="Close"
                                    ariaLabel="Close"
                                    disabled={false}
                                />
                            </div>
                        </>
                    )}
                </div>
            );
        }
    }
);
