import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { DragDropContext, Droppable } from "react-beautiful-dnd";

import { ModuleIndexGroup } from "./ModuleIndexGroup";

const rootClassName = cx(
    "cygraph-ModuleIndexListView-root",
    css`
        & .group .droppable {
            min-height: 50px;

            &.dragging-over {
                background-color: rgb(250, 250, 220);
            }
        }
        & .group .draggable {
            border-radius: 4px;
            &.dragging {
                background: var(--body-background);
            }
        }
    `
);

export const ModuleIndexListView = observer(
    ({
        manifest,
        module,
        indexMap,
        isDragDisabled,
        isDropDisabled,
        onMeasureClick,
        onAddMeasure,
        onMoveMeasure,
        onAddGroup,
        onEditGroup,
        onMoveGroup,
        onDuplicateGroup,
        onRemoveGroup,
        onMoveToModule,
    }) => {
        const onDragStart = useCallback(
            (result) => {
                console.log("on drag start", result);
            },
            [module]
        );

        const onDragEnd = useCallback(
            (result) => {
                if (!result.destination || result.reason != "DROP") {
                    return;
                }

                if (result.type == "group") {
                    onMoveGroup({
                        module,
                        group: module.groups[result.source.index],
                        destination: result.destination,
                    });
                } else if (result.type == "measure") {
                    const source = module.groups.find((g) => g.id == result.source.droppableId);
                    onMoveMeasure({
                        module,
                        group: source,
                        measure: source.measures[result.source.index],
                        destination: {
                            group: module.groups.find((g) => g.id == result.destination.droppableId),
                            index: result.destination.index,
                        },
                    });
                }
            },
            [module, onMoveGroup, onMoveMeasure]
        );

        return (
            <div className={cx(rootClassName, "toc__col", "no-print")}>
                <div className="questions__wrapper">
                    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                        <div className="questions__scroll__wrapper scrollbar--root">
                            <Droppable droppableId={module.id} isDropDisabled={isDropDisabled} type="group">
                                {(provided, snapshot) => (
                                    <div
                                        className={cx(
                                            "droppable",
                                            snapshot.isDraggingOver && "dragging-over",
                                            snapshot.draggingFromThisWith && "dragging-from"
                                        )}
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {module.groups.map((group, gi) => (
                                            <ModuleIndexGroup
                                                key={group.id}
                                                manifest={manifest}
                                                module={module}
                                                group={group}
                                                index={gi}
                                                indexMap={indexMap}
                                                isDragDisabled={isDragDisabled}
                                                isDropDisabled={isDropDisabled}
                                                onMeasureClick={onMeasureClick}
                                                onAddMeasure={onAddMeasure}
                                                onAddGroup={onAddGroup}
                                                onEditGroup={onEditGroup}
                                                onDuplicateGroup={onDuplicateGroup}
                                                onRemoveGroup={onRemoveGroup}
                                                onMoveToModule={onMoveToModule}
                                            />
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    </DragDropContext>
                </div>
            </div>
        );
    }
);
