import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';

import { Draggable } from 'react-beautiful-dnd';

import { ObservableSeparator } from '../../../base/components/ObservableSeparator';

import { PagePartMenu } from './PagePartMenu';

const rootClassName = cx(
  'cygraph-PagePart-root',
  css`
    position: relative;

    & .page--part {
      display: flex;
      flex-direction: column;

      &.none {
        background-color: var(--white);
        color: var(--text-color);
      }
      &.transparent {
        background-color: transparent;
        color: var(--text-color);
      }
      &.neutral {
        background-color: var(--body-background);
        color: var(--text-color-dark);
      }
      &.soft {
        background: var(--green-1);
        color: var(--text-color-dark);
        border: 1px solid var(--border-color-dark);
      }
      &.strong {
        background: var(--green-3);
        color: var(--text-color);
        border: 1px solid var(--green-7);
      }
      &.dragging {
        background-color: var(--white);
      }
      &.chrome-border-only,
      &.chrome-title-and-border {
        padding: var(--spacing-s);
        border: 1px solid var(--border-color);
      }
    }
    & .page--part-header {
      position: relative;
    }
    & .page--part-title {
      padding-left: var(--spacing-xs);
      margin-bottom: var(--spacing-xs);
    }
    & .page--part-content {
      flex-grow: 1;
      display: flex;
      & > div {
        flex-grow: 1;
      }
    }
    & .page--part-action {
      position: absolute;
      top: 4px;
      right: 4px;
    }
    & .page--part-menu {
      display: none;
      position: absolute;
      top: 4px;
      right: 4px;

      & .move {
        padding: 3px 8px 2px 8px;
        font-size: 16px;
        color: var(--primary-brand-color);
        &:hover {
          background-color: rgb(243, 242, 241);
        }
      }

      & .wrapper {
        height: 32px;
        background-color: var(--nav-background);
        border-radius: var(--border-radius);
        display: flex;
      }
    }

    &.edit {
      & .page--part {
        min-height: 200px;
        border: 1px dashed var(--border-color);

        &.dragging {
          border: 1px solid var(--border-color);
          background-color: var(--white);
          padding-bottom: 1px;
        }
      }
    }

    &:hover {
      &.edit {
        & .page--part-menu {
          display: block;
        }
        & .page--part-action {
          right: 130px;
        }
      }
    }
  `
);

export const PagePart = observer(
  class PagePart extends PureComponent {
    render () {
        const { className, instance, section, zone, part, index } = this.props;
        const mode = instance.lifecycleStore.mode;

        const registration = instance.lifecycleStore.getRegistration(part);
        const showTitle = mode === 'edit' || part.chrome == 'title-only' || part.chrome == 'title-and-border'

        return (
          <div className={cx(rootClassName, className, mode)}>
            {(!registration.invisiblePart || (mode === 'edit')) ? <Draggable draggableId={part.id} index={index} type="part" isDragDisabled={mode !== 'edit'}>
                {(provided, snapshot) => (
                  <div className={cx("page--part", `chrome-${part.chrome}`, part.theme, snapshot.isDragging && 'dragging')} 
                    {...provided.draggableProps} ref={provided.innerRef}>
                    { showTitle && (
                      <div className="page--part-header" onDoubleClick={() => mode === 'edit' && instance.lifecycleStore.onEditPart(section, zone, part)}>
                          <ObservableSeparator className="page--part-title"> {part.title || part.type} </ObservableSeparator>
                          {registration.actions && <registration.actions 
                            {...this.props}
                            registration={registration}
                            part={part}
                            instance={instance}
                          /> }
                          <PagePartMenu 
                            className="page--part-menu"
                            instance={instance}
                            section={section}
                            zone={zone}
                            part={part}
                            provided={provided}
                            canBeDeleted={registration.canBeDeleted}
                          />
                      </div>
                    ) }            
                    <div className="page--part-content" style={{ width: part.width || '100%',  height: part.height || '100%' }}>
                      <registration.control 
                        {...this.props}
                        registration={registration}
                      />
                    </div>
                  </div>
                )}
            </Draggable> :<registration.control 
                        {...this.props}
                        registration={registration}
                      />}
          </div>
        );
    }
  }
);