import { observable, computed, action } from "mobx";

export class PaginationStore {
    @observable public selectedPageIndex: number = 0;
    @observable public itemsPerPage: number = 25;
    @observable public totalItemCount: number = 0;
    @observable public pageCount: number = 0;

    @computed
    public get startIndex(): number {
        return this.selectedPageIndex * this.itemsPerPage;
    }

    @action
    public setPaging(pageSize: number, totalCount: number, startIndex: number) {
        this.itemsPerPage = pageSize;
        this.totalItemCount = totalCount;
        this.pageCount = Math.ceil(this.totalItemCount / this.itemsPerPage);
        this.selectedPageIndex = Math.floor(startIndex / this.itemsPerPage);
    }

    @action
    public reset() {
        this.selectedPageIndex = 0;
        this.totalItemCount = 0;
    }

    @computed
    public get hasMorePages(): boolean {
        return this.totalItemCount > this.itemsPerPage * (this.selectedPageIndex + 1);
    }
}
