import React from "react";

import { PropertyTable } from "@ui/pdf/Table";

export const MetadataPropertyTable = ({ workItem, assessment }) => {
    const properties = [
        { name: "Id", value: { src: `/assurance/browse/${assessment.id}`, label: assessment.code }, type: "internal-link" },
        { name: "Title", value: assessment.title },
        { name: assessment.target.type, value: assessment.target, type: "target" },
        { name: "Type", value: `${assessment.patternVersion.name} v${assessment.patternVersion.version}` },
        { name: "Priority", value: workItem.priority },
        { name: "Demand Request", value: workItem, type: "work-item" },
        { name: "Portfolio", value: workItem.portfolio.name },
        { name: "Assurance Lead", value: workItem.assignedToUser, type: "principal" },
    ];

    return <PropertyTable data={properties} />;
};
