import React from "react";
import { observer } from "mobx-react";

import { StatusBadge } from "@modules/base/components/StatusBadge";

export const AssessmentStatusBadge = observer(({ assessment }) => {
    switch (assessment.state) {
        case "Draft":
            return <StatusBadge variant="grey">Draft</StatusBadge>;
        case "Started":
            return <StatusBadge variant="grey">Self Assessment</StatusBadge>;
        case "Remediation":
            return <StatusBadge variant="orange">Remediation</StatusBadge>;
        case "Reviewing":
            return <StatusBadge variant="orange">Risk Review</StatusBadge>;
        case "Closed":
            return <StatusBadge variant="green">Closed</StatusBadge>;
        case "Cancelled":
            return <StatusBadge variant="green">Cancelled</StatusBadge>;
        default:
            return <StatusBadge>{assessment.state}</StatusBadge>;
    }
});
