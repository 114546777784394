import React from "react";
import { observer } from "mobx-react";

import { PageViewer as StatelessPageViewer } from "@modules/pages/components/core/PageViewer";

import { SectionEditPanel } from "../panels/SectionEditPanel";
import { PartEditPanel } from "../panels/PartEditPanel";
import { PagePartPicker } from "../utilities/PagePartPicker";

export const PageViewer = observer((props) => {
    return (
        <>
            <StatelessPageViewer {...props} />
            <SectionEditPanel instance={props.instance} />
            <PagePartPicker instance={props.instance} />
            <PartEditPanel instance={props.instance} />
        </>
    );
});
