import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router';

import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';
import { AppPage } from '../../../layout/containers/AppPage';
import { SettingsListNav } from '../../../settings/containers/utilities/SettingsListNav';
import { UserListCommandBar } from '../../components/users/UserListCommandBar';
import { UserGridView } from '../../components/users/UserGridView';
import { EditUserPanel } from '../panels/EditUserPanel';

export const UserListPage = inject('pageContext', 'principalContext', 'pickerStore', 'settingsStore', 'routing')(withRouter(observer(
    class UserListPage extends Component {

        constructor(props) {
            super(props);
            this.state = {
                showSuccessTicker: false,
                page: 1,
                keywords: '',
            }
        }

        componentWillMount() {
            const { match, routing, settingsStore, pickerStore, principalContext, pageContext} = this.props;
            const { editUserStore } = principalContext;
            const { groupPickerStore } = pickerStore;
            //settingsStore.selectItem(match.params.selectedItem);
            if (match.params.userId) {
                editUserStore.userId = match.params.userId  
                editUserStore.visible = true
            }
            pageContext.updateQueryStringParams(routing.location.search);
            const page = pageContext.queryStringParams.p || 1;
            const keywords = pageContext.queryStringParams.k || '';
            pickerStore.searchUsers({page, keywords}, true);
            groupPickerStore.searchPrincipals({page:1, keywords:''});
            this.setState({
                page,
                keywords,
            })

        }

        componentWillReceiveProps(nextProps) {
            const { match, routing, settingsStore, principalContext, pickerStore, pageContext } = this.props;
            const { editUserStore } = principalContext;
            if (nextProps.match.params.selectedItem !== match.params.selectedItem) {
                //settingsStore.selectItem(nextProps.match.params.selectedItem);
            }
            if (match.params.userId) {
                editUserStore.userId = match.params.userId
                editUserStore.visible = true
            }
            pageContext.updateQueryStringParams(routing.location.search);
            const params = pageContext.queryStringParams;
            if (params.p !== this.state.page || params.k !== this.state.keywords) {
                pickerStore.searchUsers({page: params.p, keywords: params.k}, true);
                this.setState({
                    page: params.p,
                    keywords: params.k,
                })
            }
        }

        render () {
            const { showSuccessTicker, page, keywords } = this.state;
            const { routing, principalContext, pickerStore, pageContext } = this.props;
            const { editUserStore } = principalContext;
            const params = pageContext.queryStringParams;
            return (
                <AppPage 
                    title={"Settings"} 
                    onTitleClick={() => {routing.push(`/settings`)}}
                    subTitle="Users"
                    // loading={boardsStore.isLoading || workItemStore.loading || productStore.loading || !boardsStore.selectedBoard}
                    hasSidebar={false}
                    className="work-items list has--page-nav">
                    {showSuccessTicker && (<MessageBar
                        messageBarType={MessageBarType.success}
                        isMultiline={false}
                        onDismiss={()=> { this.setState({showSuccessTicker:false})}}
                        dismissButtonAriaLabel="Close"
                        style={{marginBottom:'5px'}}>
                        User saved successfully
                    </MessageBar>)}
                    <SettingsListNav />
                    <div>
                        <UserListCommandBar
                            onSearchChange={(keywords) => {
                                routing.push(`${routing.location.pathname}?p=1&k=${keywords || ''}`);
                            }}
                            keywords={params.k || ''}
                        />
                        {pickerStore.usersList && <UserGridView
                            page={page || 1}
                            keywords={keywords || ''}
                            users={pickerStore.usersList}
                            groups={pickerStore.groupPickerStore.principals}
                            loading={pickerStore.loading}
                            pagination={pickerStore.pagination}
                            onPageChange={(index, keywords) => {
                                routing.push(`/settings/users?p=${index + 1}&k=${keywords}`);
                            }}
                            onEditUserClick={(userId) => {
                                editUserStore.userId = userId
                                editUserStore.visible = true
                            }}
                        />}
                        {editUserStore.visible && editUserStore.userId && (<EditUserPanel 
                            page={page || 1}
                            keywords={keywords || ''}
                            onSaveSuccess={() => {
                                editUserStore.visible = false;
                                pickerStore.searchUsers({page, keywords}, true);
                                this.setState({showSuccessTicker: true});
                            }}
                        />)}
                    </div>
                </AppPage>
            );
        }
    }
)));
