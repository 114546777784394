import React from "react";
import moment from "moment";

import { Text, View } from "@react-pdf/renderer";

import { Table, TableBody, DataTableCell } from "@ui/pdf/Table";
import { InternalLink } from "@ui/pdf/Link";
import { RichTextViewer } from "@ui/pdf/RichTextViewer";

export const PropertyTable = ({ data }) => {
    const getContentCore = (r) => {
        if (!r.type || !r.value) {
            return <Text>{r.value || "(not set)"}</Text>;
        }

        switch (r.type) {
            case "work-item":
                return <InternalLink src={`/demand/browse/${r.value.id}`}>{r.value.product.name}</InternalLink>;
            case "internal-link":
                return <InternalLink src={r.value.src}>{r.value.label}</InternalLink>;
            case "principal":
                return r.value ? (
                    <View>
                        <Text>{r.value.name}</Text>
                        <Text>{r.value.email}</Text>
                    </View>
                ) : null;
            case "target":
                switch (r.value.type) {
                    case "Asset":
                        return <InternalLink src={`/assets/browse/${r.value.id}`}>{r.value.name}</InternalLink>;
                    case "Entity":
                        return <InternalLink src={`/entities/browse/${r.value.id}`}>{r.value.name}</InternalLink>;
                    case "Process":
                        return <InternalLink src={`/processes/browse/${r.value.id}`}>{r.value.name}</InternalLink>;
                }
            case "rich-text":
                return <RichTextViewer value={r.value}></RichTextViewer>;
            case "date":
                return <Text>{moment.utc(r.value).format("Do MMM YYYY")}</Text>;
            default:
                return <Text>{r.value}</Text>;
        }
    };

    return (
        <Table data={data}>
            <TableBody>
                <DataTableCell isHeader isProperty weighting={0.4} getContent={(r) => <Text>{r.name}</Text>} />
                <DataTableCell isProperty weighting={0.6} getContent={(r) => <View>{getContentCore(r)}</View>} />
            </TableBody>
        </Table>
    );
};
