import { observable, action } from "mobx";

import { RiskService } from "../../../api/risks";
import { RiskStore } from "./RiskStore";

export class RiskTreatmentStore {
    private parentStore: RiskStore;
    private riskService: RiskService;

    @observable public riskId: string | null;
    @observable public treatments: any[] = [];
    @observable public loading: boolean = false;
    @observable public error: any;

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
    }

    @action
    public loadRiskTreatments(id) {
        this.loading = true;
        this.riskId = id;
        this.treatments = [];
        return this.riskService
            .getRiskTreatments(id)
            .then(this.loadRiskTreatmentsOnSuccess, this.loadRiskTreatmentsOnError);
    }

    @action.bound
    public loadRiskTreatmentsOnSuccess(result) {
        this.treatments = result;
        this.loading = false;
        return result;
    }

    @action.bound
    public loadRiskTreatmentsOnError(error) {
        this.error = error;
        this.loading = false;
        throw error;
    }
}
