import React from "react";
import { observer } from "mobx-react";

import { PivotRouting, PivotRoutingItem } from "@modules/base/components/PivotRouting";

export const RisksSettingsPivot = observer(({ activeTabs, selectedTab, defaultTab }) => {
    return (
        <PivotRouting
            data-test="risksSettingsTabList"
            aria-label="Risk settings, workflows and automations"
            selectedKey={activeTabs.indexOf(selectedTab) === -1 ? defaultTab : selectedTab}
            onLinkClick={(item, routing) => {
                if (item.props.itemKey === defaultTab) {
                    routing.push(`/settings/risks`);
                } else {
                    routing.push(`/settings/risks/${item.props.itemKey}`);
                }
            }}
        >
            <PivotRoutingItem itemKey="general" headerText="General" itemIcon="Settings" />
            <PivotRoutingItem itemKey="registries" headerText="Registries" itemIcon="ReportWarning" />
            <PivotRoutingItem itemKey="catalogue" headerText="Catalogue" itemIcon="ProductCatalog" />
            <PivotRoutingItem itemKey="workflows" headerText="Workflows" itemIcon="VisioDiagram" />
            <PivotRoutingItem itemKey="automations" headerText="Automations" itemIcon="ChatBot" />
            <PivotRoutingItem itemKey="recycle-bin" headerText="Recycle Bin" itemIcon="RecycleBin" itemCount={0} />
        </PivotRouting>
    );
});
