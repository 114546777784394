import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { MessageBar } from 'office-ui-fabric-react/lib/MessageBar';
import { DetailsListLayoutMode, SelectionMode, ConstrainMode } from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { Persona, PersonaSize } from 'office-ui-fabric-react/lib/Persona';
import { IconButton } from 'office-ui-fabric-react/lib/Button';

import { BoxPanel } from '../../base/components/BoxPanel';
import { PhotoPersona } from '../../identity/containers/utilities/PhotoPersona';

export const PermissionSetGridView = observer(
    class PermissionSetGridView extends Component {
        constructor(props) {
            super(props);

            this._columns = [
                {
                  key: 'principal',
                  name: 'User',
                  fieldName: 'principal',
                  minWidth: 170,
                  isResizable: true,
                  onRender: (item) => {
                    return <PhotoPersona
                      principalId={item.principal ? item.principal.id : null}
                      size={PersonaSize.size28} 
                      text={item.principal.name} 
                      showSecondaryText={true} 
                      secondaryText={item.principal.jobTitle || item.principal.email} />;
                  },
                  isPadded: true
                },
                {
                  key: 'type',
                  name: 'Roles',
                  fieldName: 'type',
                  minWidth: this.props.showAllRoles ? 400 : 100,
                  maxWidth: this.props.showAllRoles ? 600 : 150,
                  isRowHeader: true,
                  isResizable: false,
                  data: 'string',
                  onRender: (item) => {
                    return (this.props.showAllRoles ? item.roles.join(', ') :
                      <TooltipHost
                          content={item.roles.join(', ')}>
                          <div>
                            <span>{item.highestLevel || 'Custom'}</span>
                            {(!item.highestLevel && item.roles.length > 0) || item.roles.length > 1 && (<span>+{item.roles.length-1}</span>)}
                          </div>
                      </TooltipHost>
                    );
                  },
                  isPadded: true
                },
                {
                  key: 'remove',
                  fieldName: 'remove',
                  minWidth: 40,
                  maxWidth: 40,
                  isResizable: false,
                  data: 'string',
                  onRender: (item) => {
                    return (this.props.onRevoke && this.props.canShare && this.props.canShare() 
                      ? <IconButton iconProps={{iconName:'StatusCircleErrorX'}} onClick={() => this.props.onRevoke(item)} /> :  null);
                  },
                  isPadded: false
                },
            ];
        }

        render () {
            const { className, permissions, loading, isHeaderVisible } = this.props;
            
            return (
              <BoxPanel shadow>
                <ShimmeredDetailsList
                  items={permissions}
                  compact={false}
                  columns={this._columns}
                  getKey={this._getKey}
                  setKey="single"
                  layoutMode={DetailsListLayoutMode.justified}
                  constrainMode={ConstrainMode.horizontalConstrained}
                  selectionMode={SelectionMode.none}
                  isHeaderVisible={isHeaderVisible}
                  selectionPreservedOnEmptyClick={true}
                  enterModalSelectionOnTouch={true}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  checkButtonAriaLabel="Row checkbox"
                  enableShimmer={loading}
                  removeFadingOverlay={true}
                  shimmerLines={15}
                  ariaLabelForShimmer="Page being fetched"
                  ariaLabelForGrid="Sharing list"
                />
                
                { ! loading && !permissions.length && (
                  <MessageBar>
                    There are no permissions at this scope.
                  </MessageBar>
                )}

              </BoxPanel> 
            );
        }

        _getKey(item, index) {
            return item ? item.id : index;
        }
    }
);