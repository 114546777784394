import React from 'react';
import { GroupedList as FabricGroupedList} from 'office-ui-fabric-react/lib/GroupedList';

const GroupedList = (props) => {

    return (
        <FabricGroupedList 
            {...props}
        />
    );
}

export { GroupedList }