import React from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { Text } from "@ui/elements/Text";

import { tenantInfo } from "../../../configProvider";

import { AppPage } from "@modules/layout/containers/AppPage";

import { PageInstanceViewer } from "@modules/pages/containers/utilities/PageInstanceViewer";

import { KeyStatisticListView } from "@modules/home/components/KeyStatisticListView";
import { QuickLinkListView } from "@modules/home/components/QuickLinkListView";
import { SimpleTaskListView } from "@modules/home/components/SimpleTaskListView";
import { SimpleProfileView } from "@modules/home/components/SimpleProfileView";
import { RecentActivitiesView } from "@modules/home/components/RecentActivitiesView";
import { CombinedSearchField } from "@modules/home/components/CombinedSearchField";

const rootClassName = cx(
    "cygraph-HomeOverview-root",
    css`
        & .header,
        & .search {
            color: #ffffff;
            height: 40px;
            margin-bottom: var(--spacing-s);
        }
        & .bg {
            background-color: var(--nav-background);
            height: 215px;
            position: absolute;
            width: 100%;
            top: 0;
            left: 0px;
            z-index: 9;
            border-radius: var(--border-radius);
        }
        & .parts {
            z-index: 99;
        }

        & .left,
        & .right {
            z-index: 99;
        }
    `
);

export const HomeOverview = observer(() => {
    return (
        <AppPage title="Home" hasSidebar={false} className={cx(rootClassName, "home")} dynamicContent={false}>
            <div className="bg"></div>
            <div className="parts">
                <r-grid columns="12" columns-s="12" style={{ margin: 0, gridColumnGap: "var(--spacing-l)" }}>
                    <r-cell class="left" span="8" span-s="12">
                        <div className="header">
                            <Text variant="xLarge">{tenantInfo.name}</Text>
                        </div>
                    </r-cell>
                    <r-cell class="right" span="4" span-s="12">
                        <div className="search">
                            <CombinedSearchField tenantInfo={tenantInfo} />
                        </div>
                    </r-cell>
                </r-grid>
                <r-grid columns="12" columns-s="12" style={{ gridColumnGap: "var(--spacing-l)" }}>
                    <r-cell class="left" span="8" span-s="12">
                        <SimpleProfileView className="row--l" />
                        <PageInstanceViewer className="row--l" path="/_/home/main" optional={true} />
                        <KeyStatisticListView className="row--l" />
                        <RecentActivitiesView className="row--l" />
                        <QuickLinkListView className="row--l" />
                    </r-cell>
                    <r-cell class="right" span="4" span-s="12">
                        <SimpleTaskListView className="row--l" />
                    </r-cell>
                </r-grid>
            </div>
        </AppPage>
    );
});
