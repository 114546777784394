import React from "react";
import { observer } from "mobx-react";

import { TextField } from "@ui/elements/TextField";

import { BigTextField } from "@modules/base/components/BigTextField";
import { MarkdownField } from "@modules/editors/components/MarkdownField";

export const StateFlowStageEditForm = observer(({ formData }) => {
    return (
        <div>
            <r-grid columns="1">
                <r-span>
                    <BigTextField
                        label="Name"
                        required
                        placeholder="Please enter a name for the stage"
                        transparent
                        value={formData.name}
                        onChange={(ev, val) => {
                            formData.name = val;
                        }}
                    />
                </r-span>
                <r-span>
                    <MarkdownField
                        label="Description"
                        value={formData.description || ""}
                        onChange={(val) => {
                            formData.description = val;
                        }}
                    />
                </r-span>
                <r-span>
                    <TextField
                        label="Default description"
                        placeholder="Please enter the text to display when stage is not active or completed"
                        transparent
                        value={formData.placeholder}
                        onChange={(ev, val) => {
                            formData.placeholder = val;
                        }}
                    />
                </r-span>
                <r-span>
                    <TextField
                        label="Description"
                        placeholder="Please enter the text to display when stage is active or completed"
                        transparent
                        value={formData.subText}
                        onChange={(ev, val) => {
                            formData.subText = val;
                        }}
                    />
                </r-span>
            </r-grid>
        </div>
    );
});
