import { observable, action, computed, flow } from "mobx";

import { WorkItemService } from '../../../api/workitems';
import { IdentityService } from '../../../api/identity';
import { WorkItemStore } from "./WorkItemStore";

export class WorkItemReAssignFormStore {
    private workItemService: WorkItemService;
    private identityService: IdentityService;
    public parentStore: WorkItemStore;

    @observable public selectedContact: any;
    @observable public selectedGroup: any;
    @observable public loading: boolean = false;
    @observable public saving: boolean = false;
    private loadedGroups: any[] = [];

    constructor(parentStore: WorkItemStore) {
        this.parentStore = parentStore;
        this.workItemService = this.parentStore.workItemService;
        this.identityService = this.parentStore.identityService;
        // this.loadGroups();
    }

    @action public selectGroup(principal) {
        this.selectedGroup = principal;
    }

    @computed public get isValid() {
        return (this.selectedContact && this.selectedGroup)  ? true : false;
    }

    @computed public get groups() {
        return this.loadedGroups;
    }

    loadGroups = flow(function*() {
        try {
            const results = yield this.identityService.searchPrincipalsGroups({pageSize: 500,startIndex: 0,})
            this.loadedGroups = results.items;
        } catch (e) {
            console.error(e)
            throw e;
        }
    })

    updateAssignedPrincipal = flow(function*(){
        const { selectedWorkItem } = this.parentStore.selectionStore;
        this.saving = true;
        try {
            const result = yield this.workItemService.assignWorkItem({
                id: selectedWorkItem.id,
                assignedToGroup: {
                    id: this.selectedGroup.id,
                },
                assignedToUser: {
                    id: this.selectedContact.id,
                }
            });
            this.selectedWorkItem = result;
            this.saving = false;
            this.parentStore.rootStore.layoutStore.displayToastNotification(`Request ${selectedWorkItem.code} assigned to ${this.selectedContact.name} from ${this.selectedGroup.name}`);;
            this.parentStore.selectionStore.loadWorkItem(selectedWorkItem.id)
        } catch (e) {
            this.saving = false;
            throw e;
        }
    });
}
