import React, { Component } from 'react';
import { observer } from 'mobx-react';

export const SystemViewAdvisories = observer(
    class SystemViewAdvisories extends Component {
        render () {
            const { system } = this.props;
            return (
                <React.Fragment>
                    <div className="title row--l">Advisories</div>
                    <r-grid class="row--l" columns="3">
                        <r-cell>
                            <div className="task__count__decorator">
                                <div className="task__count task__count--open">2</div>
                                <div className="task__label">Open</div>
                            </div>
                        </r-cell>
                        <r-cell>
                            <div className="task__count__decorator">
                                <div className="task__count task__count--in--progress">4</div>
                                <div className="task__label">In Progress</div>
                            </div>
                        </r-cell>
                        <r-cell>
                            <div className="task__count__decorator">
                                <div className="task__count task__count--closed">10</div>
                                <div className="task__label">Closed</div>
                            </div>
                        </r-cell>
                    </r-grid>
                    <button className="btn btn--secondary" onClick={()=>{this.props.routing.push(`/systems/${this.props.systemId}/advisories`)}}>View Advisories</button>
                </React.Fragment>
            );
        }
    }
);