import React, { useCallback, useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { Text } from "office-ui-fabric-react/lib/Text";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { PrimaryButton } from "office-ui-fabric-react/lib/Button";
import { TooltipHost } from "office-ui-fabric-react/lib/Tooltip";

import { GhostButton } from "@ui/elements/Button";

import { BigModal } from "@modules/base/components/BigModal";
import { ObservablePagination } from "@modules/base/components/ObservablePagination";

import { TaskDefinitionGridView } from "@modules/workflows/components/tasks/TaskDefinitionGridView";
import { TaskFilterBar } from "@modules/workflows/components/tasks/TaskFilterBar";
import { TaskResponseEditDialog } from "@modules/workflows/containers/dialogs/TaskResponseEditDialog";
import { TaskDefinitionNewPanel } from "@modules/workflows/containers/panels/TaskDefinitionNewPanel";
import { TaskDefinitionEditPanel } from "@modules/workflows/containers/panels/TaskDefinitionEditPanel";

export const TaskContainerHost = observer(({ context, canManage }) => {
    const { workflowStore, taskStore, principalContext, routingManager } = useStores();
    const { browseStore, lifecycleStore } = taskStore;
    const { query, taskDefinitions, loading, pagination } = browseStore;
    const { summaryStore } = workflowStore;

    const visible = routingManager.isTasksActive;
    const user = principalContext.current;

    const searchTasks = useCallback(
        (options) => {
            if (context) {
                browseStore.searchTasks({
                    page: options.page || 1,
                    query: {
                        ...options.query,
                        context: [{ ...context }],
                    },
                });
            }
        },
        [context]
    );

    const filterTasks = useCallback(
        (filters) => {
            if (context) {
                const kf = filters.find((f) => f.fieldName == "keywords");
                const sf = filters.find((f) => f.fieldName == "status");

                searchTasks({
                    query: {
                        keywords: kf ? kf.value : null,
                        status: sf ? sf.items.filter((s) => s.checked).map((s) => s.key) : null,
                    },
                });
            }
        },
        [context]
    );

    useEffect(() => {
        if (visible) {
            searchTasks({ query: { status: ["Pending"] } });
        }
    }, [context, visible]);

    const onDismiss = useCallback(
        (options) => {
            routingManager.setTasksActive(false);
            if (context) {
                summaryStore.loadEntitySummary({ id: context.id });
            }
        },
        [context]
    );

    return (
        <Stack horizontal verticalAlign="center" verticalFill>
            <TooltipHost content={summaryStore.totalTaskCountTooltip}>
                <GhostButton
                    iconProps={{ iconName: "EventToDoLogo", title: "Tasks" }}
                    onClick={() => routingManager.setTasksActive(!visible)}
                >
                    Tasks / {summaryStore.totalTaskCount}
                </GhostButton>
            </TooltipHost>
            {visible && (
                <BigModal
                    className="animated fadeIn fast"
                    isOpen={visible}
                    onDismiss={onDismiss}
                    isBlocking={false}
                    dragOptions={false}
                >
                    <Stack horizontal horizontalAlign="space-between">
                        <Text variant="xLarge">{context.label} - Tasks</Text>
                        <Stack horizontal tokens={{ childrenGap: 5 }}>
                            {canManage && (
                                <PrimaryButton
                                    className="newTaskButton"
                                    iconProps={{ iconName: "Add" }}
                                    onClick={() =>
                                        lifecycleStore.onCreate({}, { task: { context } }).then((result) => {
                                            if (result.success) {
                                                searchTasks({ query, page: 1 });
                                            }
                                        })
                                    }
                                    text="Task"
                                />
                            )}
                            <TaskFilterBar query={query} onChange={filterTasks} />
                        </Stack>
                    </Stack>
                    <TaskDefinitionGridView
                        tasks={taskDefinitions}
                        loading={loading}
                        query={query}
                        onEdit={(task) => {
                            lifecycleStore.onEdit({}, { task }).then((result) => {
                                if (result.success) {
                                    searchTasks({ query, page: 1 });
                                }
                            });
                        }}
                        onRespond={(task) => {
                            lifecycleStore.onRespond({}, { task }).then((result) => {
                                if (result.success) {
                                    searchTasks({ query, page: 1 });
                                }
                            });
                        }}
                        canRespond={(item) => {
                            return (
                                item.status === "Pending" &&
                                !!item.assignments.find((A) => A.assignedTo.id === user.id && A.status === "Pending")
                            );
                        }}
                        canEdit={(item) => {
                            return (
                                item.status === "Pending" &&
                                (user.id === item.createdBy.id ||
                                    user.roles.findIndex((R) => R == "admin") > -1 ||
                                    canManage)
                            );
                        }}
                    />

                    {pagination.pageCount > 1 && (
                        <ObservablePagination
                            {...pagination}
                            onPageChange={(index) => searchTasks({ query, page: index + 1 })}
                        />
                    )}
                </BigModal>
            )}
            <TaskDefinitionNewPanel />
            <TaskDefinitionEditPanel />
            <TaskResponseEditDialog
                onEdit={(task) => {
                    lifecycleStore.onEdit({}, { task }).then((result) => {
                        if (result.success) {
                            searchTasks({ query, page: 1 });
                        }
                    });
                }}
            />
        </Stack>
    );
});
