import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { Breadcrumb } from "office-ui-fabric-react/lib/Breadcrumb";
import { IconButton } from "office-ui-fabric-react/lib/Button";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { ProgressIndicator } from "office-ui-fabric-react/lib/ProgressIndicator";
import { Image, ImageFit } from "office-ui-fabric-react/lib/Image";

import { ConfigModules } from "../../config/stores/ConfigModules";

import { TaskSummaryBadge } from "../../workflows/containers/utilities/TaskSummaryBadge";

import { PersonalMenu } from "./PersonalMenu";
import { AppHeaderNotification } from "./AppHeaderNotification";

import "./AppHeader.css";

const progressClassName = cx(
    "cygraph-Progress-bar",
    css`
        height: 2px;
        width: 100%;
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        z-index: 11;

        & > div {
            padding: 0;
        }

        & .ms-ProgressIndicator-progressTrack {
            background-color: transparent;
        }
    `
);

const breadcrumbClassName = cx(
    "cygraph-AppHeader-breadcrumb",
    css`
        margin: 0;

        & .ms-Breadcrumb-itemLink,
        & .ms-Breadcrumb-item {
            font-size: 14px;
            border-radius: 5px;
        }
    `
);

export const AppHeader = observer(({ page, routing, user, authProvider, layoutStore, configProvider }) => {
    const config = configProvider.get(ConfigModules.Core);
    const showAnimation = false;

    const getCustomDivider = useCallback((_) => {
        return (
            <span aria-hidden="true" style={{ cursor: "pointer", padding: 5 }}>
                /
            </span>
        );
    }, []);

    return (
        <header className="app__header no-print">
            {page.loading && <ProgressIndicator className={progressClassName} />}

            <div className="app__titles__wrapper">
                <div className="app__titles__tenant">
                    <Image src={config.logoUrl} alt={config.name} imageFit={ImageFit.contain} width={240} height={35} />
                </div>

                {page.breadcrumb ? (
                    <Breadcrumb
                        className={breadcrumbClassName}
                        items={page.breadcrumb}
                        overflowIndex={page.breadcrumb.length > 1 ? 1 : 0}
                        maxDisplayedItems={5}
                        dividerAs={getCustomDivider}
                        ariaLabel="Page level breadcrumb"
                        overflowAriaLabel="More links"
                    />
                ) : (
                    <div className="app__sub__title__wrapper">
                        <div
                            className={cx(
                                "app__sub__title__animation__wrapper",
                                showAnimation && "app__sub__title__animation__wrapper--active"
                            )}
                        >
                            <div className="app__sub__title">{!showAnimation && page.subTitle}</div>
                            {page.status && <div className="app__save__status">{page.status}</div>}
                        </div>
                    </div>
                )}
            </div>
            <div className="app__buttons__wrapper">
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    {page.externalLink && page.externalLink.url && page.externalLink.title && (
                        <a href={page.externalLink.url} className="btn btn--primary" target="_blank">
                            {page.externalLink.title} <i className="fas fa-external-link-alt"></i>
                        </a>
                    )}

                    <AppHeaderNotification layoutStore={layoutStore} hide={true} />

                    <PersonalMenu user={user} authProvider={authProvider} routing={routing} />

                    <TaskSummaryBadge className="toggle__sidebar__btn btn--icon--only" />

                    {page.hasSidebar && (
                        <IconButton
                            className={cx(
                                "toggle__sidebar__btn indent btn--icon--only",
                                !page.showSidebar && "toggle__sidebar__btn--active"
                            )}
                            iconProps={{ iconName: "ChevronRightSmall", title: "Toggle Sidebar" }}
                            ariaLabel="Toggle Sidebar"
                            onClick={() => page.toggleSidebar()}
                            disabled={false}
                        />
                    )}
                </Stack>
            </div>
        </header>
    );
});

// export const AppHeader2 = observer(
//     class AppHeader extends Component {
//         constructor(props) {
//             super(props);
//             this.state = { showAnimation: false, subTitle: null };
//         }

//         componentWillMount() {
//             this.setState({ subTitle: this.props.subTitle });
//         }

//         componentWillReceiveProps(nextProps) {
//             if (this.props.page.subTitle != nextProps.page.subTitle) {
//                 this.setState({ showAnimation: true, subTitle: null }, () => {
//                     setTimeout(() => {
//                         this.setState({ showAnimation: false, subTitle: nextProps.page.subTitle });
//                     }, 500);
//                 });
//             }
//         }

//         menuProps = () => {
//             const { layoutStore } = this.props;
//             return {
//                 items: layoutStore.notifications.map((N) => {
//                     return {
//                         key: N,
//                         text: N,
//                         onClick: () => {},
//                     };
//                 }),
//             };
//         };

//         render() {
//             const { page, routing, user, authProvider, layoutStore, configProvider } = this.props;

//             const config = configProvider.get(ConfigModules.Core);

//             return (
//                 <header className="app__header no-print">
//                     {page.loading && <ProgressIndicator className={progressClassName} />}

//                     <div className="app__titles__wrapper">
//                         <div className="app__titles__tenant">
//                             <Image
//                                 src={config.logoUrl}
//                                 alt={config.name}
//                                 imageFit={ImageFit.contain}
//                                 width={240}
//                                 height={35}
//                             />
//                         </div>
//                         {false && (
//                             <>
//                                 <a role="button" onClick={() => page.onTitleClick()} className="app__title">
//                                     {page.subTitle && <Icon iconName="ChromeBack" className="app__title__back__btn" />}
//                                     <span className="app__title__label">{page.title || "InfoSec Hub"}</span>
//                                 </a>
//                                 <div className="app__seperator"></div>
//                             </>
//                         )}

//                         {page.breadcrumb ? (
//                             <Breadcrumb
//                                 className={breadcrumbClassName}
//                                 items={page.breadcrumb}
//                                 overflowIndex={page.breadcrumb.length > 1 ? 1 : 0}
//                                 maxDisplayedItems={5}
//                                 dividerAs={this._getCustomDivider}
//                                 ariaLabel="Page level breadcrumb"
//                                 overflowAriaLabel="More links"
//                             />
//                         ) : (
//                             <div className="app__sub__title__wrapper">
//                                 <div
//                                     className={cx(
//                                         "app__sub__title__animation__wrapper",
//                                         this.state.showAnimation && "app__sub__title__animation__wrapper--active"
//                                     )}
//                                 >
//                                     <div className="app__sub__title">{!this.state.showAnimation && page.subTitle}</div>
//                                     {page.status && <div className="app__save__status">{page.status}</div>}
//                                 </div>
//                             </div>
//                         )}
//                     </div>
//                     <div className="app__buttons__wrapper">
//                         <Stack horizontal tokens={{ childrenGap: 10 }}>
//                             {page.externalLink && page.externalLink.url && page.externalLink.title && (
//                                 <a href={page.externalLink.url} className="btn btn--primary" target="_blank">
//                                     {page.externalLink.title} <i className="fas fa-external-link-alt"></i>
//                                 </a>
//                             )}

//                             <AppHeaderNotification layoutStore={layoutStore} hide={true} />

//                             <PersonalMenu user={user} authProvider={authProvider} routing={routing} />

//                             <TaskSummaryBadge className="toggle__sidebar__btn btn--icon--only" />

//                             {page.hasSidebar && (
//                                 <IconButton
//                                     className={cx(
//                                         "toggle__sidebar__btn indent btn--icon--only",
//                                         !page.showSidebar && "toggle__sidebar__btn--active"
//                                     )}
//                                     iconProps={{ iconName: "ChevronRightSmall", title: "Toggle Sidebar" }}
//                                     ariaLabel="Toggle Sidebar"
//                                     onClick={() => page.toggleSidebar()}
//                                     disabled={false}
//                                 />
//                             )}
//                         </Stack>
//                     </div>
//                 </header>
//             );
//         }

//         _getCustomDivider = (dividerProps) => {
//             //const tooltipText = dividerProps.item ? dividerProps.item.text : "";

//             return (
//                 <span aria-hidden="true" style={{ cursor: "pointer", padding: 5 }}>
//                     /
//                 </span>
//             );
//             // return (
//             //     <TooltipHost content={`Show ${tooltipText} contents`} calloutProps={{ gapSpace: 0 }}>
//             //         <span aria-hidden="true" style={{ cursor: "pointer", padding: 5 }}>
//             //             /
//             //         </span>
//             //     </TooltipHost>
//             // );
//         };
//     }
// );
