import React from "react";
import { observer } from "mobx-react";

export const ModuleFieldBoolean = observer(({ module, group, measure, disabled, onUpdateMeasure }) => {
    return (
        <div className="form__group form__group--flex--row">
            {measure.label && <label className="form__label form__label--large">{measure.label}</label>}
            <ul className="radio__input__btns__list">
                <li className="radio__input__btns__list__item">
                    <input
                        type="radio"
                        id={measure.name + "Yes"}
                        name={measure.name}
                        className="radio__input__btn"
                        disabled={true}
                        //checked={values[measure.name].value === true}
                        onChange={(e) => {
                            //onMeasureChange(measure, { value: true, completed: true });
                        }}
                    />
                    <label htmlFor={measure.name + "Yes"} className="radio__input__btn__label">
                        Yes
                    </label>
                </li>
                <li className="radio__input__btns__list__item">
                    <input
                        type="radio"
                        id={measure.name + "No"}
                        name={measure.name}
                        className="radio__input__btn"
                        disabled={true}
                        //checked={values[measure.name].value === false}
                        onChange={(e) => {
                            //onMeasureChange(measure, { value: false, completed: true });
                        }}
                    />
                    <label htmlFor={measure.name + "No"} className="radio__input__btn__label">
                        No
                    </label>
                </li>
            </ul>
        </div>
    );
});
