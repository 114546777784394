import { computed } from "mobx";

import { WorkItemService } from "../../../api/workitems";
import { WorkItemStore } from "./WorkItemStore";
import { PrincipalContextStore } from "../../identity/stores/PrincipalContextStore";
import { RoleNames, isUserInAllRoles, isUserInAnyGroups } from "../../identity/stores/PrincipalContextUtilities";

export class WorkItemPermissionStore {
    private workItemService: WorkItemService;
    public parentStore: WorkItemStore;
    public principalContext: PrincipalContextStore;

    constructor(parentStore: WorkItemStore) {
        this.parentStore = parentStore;
        this.workItemService = parentStore.workItemService;
        this.principalContext = parentStore.rootStore.principalContext;
    }

    @computed
    public get isAdmin() {
        const user = this.principalContext.current;
        if (!user) {
            return false;
        }
        return isUserInAllRoles(user, [RoleNames.Admin]);
    }

    @computed
    public get isReader() {
        const workItem = this.parentStore.selectionStore.workItem;
        const user = this.principalContext.current;
        if (!workItem || !user) {
            return false;
        }
        return (
            (workItem.createdBy && workItem.createdBy.id === user.id) ||
            (workItem.requestedBy && workItem.requestedBy.id === user.id) ||
            (workItem.businessContact && workItem.businessContact.id === user.id) ||
            (workItem.technicalContact && workItem.technicalContact.id === user.id)
        );
    }

    @computed
    public get isContributor() {
        const workItem = this.parentStore.selectionStore.workItem;
        const user = this.principalContext.current;

        if (!workItem || !user) {
            return false;
        }
        return (
            (workItem.createdBy && workItem.createdBy.id === user.id) ||
            (workItem.requestedBy && workItem.requestedBy.id === user.id) ||
            (workItem.businessContact && workItem.businessContact.id === user.id) ||
            (workItem.technicalContact && workItem.technicalContact.id === user.id)
        );
    }

    @computed
    public get isOwner() {
        const workItem = this.parentStore.selectionStore.workItem;
        const user = this.principalContext.current;
        if (!workItem || !user) {
            return false;
        }

        return workItem.assignedToUser && workItem.assignedToUser.id === user.id;
    }

    @computed
    public get isAgent() {
        const workItem = this.parentStore.selectionStore.workItem;
        const user = this.principalContext.current;
        if (!workItem || !user) {
            return false;
        }

        return (
            isUserInAllRoles(user, [RoleNames.Agent, RoleNames.WorkItems]) ||
            isUserInAllRoles(user, [RoleNames.Agent, RoleNames.Assessments])
        );
    }

    @computed
    public get isAssignedGroupAgent() {
        const workItem = this.parentStore.selectionStore.workItem;
        const user = this.principalContext.current;
        if (!workItem || !user) {
            return false;
        }

        return workItem.assignedToGroup && isUserInAnyGroups(user, [workItem.assignedToGroup.id]) && this.isAgent;
    }

    @computed
    public get canEdit() {
        return this.isOwner || this.isAdmin;
    }

    @computed
    public get canAssign() {
        const workItem = this.parentStore.selectionStore.workItem;
        const user = this.principalContext.current;
        if (!workItem || !user) {
            return false;
        }

        return this.isAssignedGroupAgent || this.isOwner || this.isAdmin;
    }

    @computed
    public get canStart() {
        const workItem = this.parentStore.selectionStore.workItem;
        const user = this.principalContext.current;
        if (!workItem || !user) {
            return false;
        }

        return this.isAssignedGroupAgent || this.isOwner || this.isAdmin;
    }

    @computed
    public get canResolve() {
        return this.isOwner || this.isAdmin;
    }

    @computed
    public get canReopen() {
        return this.isOwner || this.isAdmin;
    }

    @computed
    public get canBlock() {
        return this.isOwner || this.isAdmin;
    }

    @computed
    public get canUnblock() {
        return this.isOwner || this.isAdmin;
    }

    @computed
    public get canClose() {
        return this.isOwner || this.isAdmin;
    }

    @computed
    public get canCancel() {
        return this.isOwner || this.isAdmin;
    }

    @computed
    public get canDelete() {
        return this.isOwner;
    }

    @computed
    public get canFlow() {
        return this.isOwner || this.isAdmin;
    }

    @computed
    public get canAddComment() {
        return this.isContributor || this.isOwner || this.isAdmin;
    }

    @computed
    public get canManageTasks() {
        return this.isOwner || this.isAdmin;
    }

    @computed
    public get canManageAutomations() {
        return this.isOwner || this.isAdmin;
    }

    /** older */
    @computed
    public get isAssignedTo() {
        const workItem = this.parentStore.selectionStore.workItem;
        const user = this.principalContext.current;
        if (!workItem || !workItem.assignedToUser || !user) {
            return false;
        }
        return workItem.assignedToUser.id === user.id;
    }

    @computed
    public get canViewResourcePages() {
        return this.isAdmin;
    }
}
