import { observable, action, computed, flow } from "mobx";

import { DialogStore } from "../../base/stores/DialogStore";
import { PaginationStore } from "../../base/PaginationStore";

import { RiskService } from "../../../api/risks";
import { RiskStore } from "./RiskStore";

const lifecycleNextDueSorter = (r1, r2) => {
    if (r1.lifecycle.nextReviewDue < r2.lifecycle.nextReviewDue) {
        return 1;
    }
    if (r1.lifecycle.nextReviewDue > r2.lifecycle.nextReviewDue) {
        return -1;
    }
    return 0;
};

const reviewReviewedSorter = (r1, r2) => {
    if (r1.review.reviewed < r2.review.reviewed) {
        return 1;
    }
    if (r1.review.reviewed > r2.review.reviewed) {
        return -1;
    }
    return 0;
};

export class RiskReviewBoardStore {
    private parentStore: RiskStore;
    private riskService: RiskService;

    @observable public registry: any;
    @observable public registryId: string;
    @observable public keywords: string = null;
    @observable public pendingRisks: any[] = [];
    @observable public reviewedRisks: any[] = [];
    @observable public selectedRisk: any = null;
    @observable public board: any = {
        pending: {
            loading: false,
            items: [],
            sorter: lifecycleNextDueSorter,
            pagination: new PaginationStore(),
            title: "Pending Review",
            allowedDrops: ["monitoring", "closed", "transferred"],
            allowDrag: true,
        },
        monitoring: {
            loading: false,
            items: [],
            sorter: reviewReviewedSorter,
            title: "Monitoring",
            allowedDrops: ["closed", "transferred"],
            allowDrag: true,
        },
        closed: {
            loading: false,
            items: [],
            sorter: reviewReviewedSorter,
            title: "Closed",
            allowedDrops: [],
            allowDrag: false,
        },
        transferred: {
            loading: false,
            items: [],
            sorter: reviewReviewedSorter,
            title: "Transferred",
            allowedDrops: [],
            allowDrag: false,
        },
    };
    @observable public pagination: PaginationStore;
    @observable public dialog: DialogStore;
    @observable public loading: boolean = false;
    @observable public saving: boolean = false;
    @observable public error: any;

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
        this.pagination = new PaginationStore();
        this.dialog = new DialogStore();
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [
            {
                text: "Risk Management",
                key: "risks",
                onClick: () => this._goToUrl(`/risks/dashboard`),
            },
        ];

        if (!this.registry) {
            return builder;
        }

        builder.push({
            text: this.registry.name,
            key: this.registry.id,
            onClick: () => this._goToUrl(`/risks/boards/${this.registry.id}`),
        });

        builder.push({
            text: "Review Board",
            key: "review-board",
            isCurrentItem: true,
        });

        return builder;
    }

    @action
    public setSelectedRisk(risk) {
        this.selectedRisk = risk;
    }

    @action
    public move(move) {
        this.selectedRisk = this.board[move.fromId].items.find((r) => r.id === move.itemId);
        this.board[move.fromId].items = this.board[move.fromId].items.filter((r) => r.id !== this.selectedRisk.id);
        this.board[move.toId].items = [this.selectedRisk, ...this.board[move.toId].items].sort(this.board[move.toId].sorter);
        this.selectedRisk.move = move;
    }

    @action
    public reverse() {
        if (this.selectedRisk && this.selectedRisk.move) {
            const move = this.selectedRisk.move;
            this.board[move.toId].items = this.board[move.toId].items.filter((r) => r.id !== this.selectedRisk.id);
            this.board[move.fromId].items = [this.selectedRisk, ...this.board[move.fromId].items].sort(this.board[move.fromId].sorter);
            this.selectedRisk.move = undefined;
        }
    }

    @action
    public update(risk) {
        for (let [id, column] of Object.entries<any>(this.board)) {
            const index = column.items.findIndex((r) => r.id === risk.id);
            if (index !== -1) {
                column.items = [risk, ...column.items.filter((r) => r.id !== risk.id)].sort(column.sorter);
            }
        }
    }

    @action
    public insert(risk) {
        if (risk.lifecycle.status === "Submitted" && this.registryId === risk.registry.id) {
            this.board.pending.items = [risk, ...this.board.pending.items].sort(this.board.pending.sorter);
        }
    }

    public loadBoard = flow(function* (options) {
        this.loading = true;
        this.keywords = options.keywords;
        this.registryId = options.registryId;

        for (let [id, column] of Object.entries<any>(this.board)) {
            column.loading = true;
            column.items = [];
        }

        try {
            this.registry = yield this.riskService.getRegistry(options.registryId);

            const pendingRisks = yield this.riskService.searchRisks({
                query: {
                    keywords: this.keywords,
                    templateId: "e2df7b29-09a2-410c-b3b5-7880c43555ea",
                    registry: [this.registryId],
                },
            });

            this.board.pending.loading = false;
            this.board.pending.items = pendingRisks.items;
            this.board.pending.pagination.setPaging(pendingRisks.pageSize, pendingRisks.totalItems, pendingRisks.startIndex);

            const reviewedRisks = yield this.riskService.searchRisks({
                query: {
                    keywords: this.keywords,
                    templateId: "f71a9bf4-ad2e-4dac-bda6-fdb6bf63381e",
                    registry: [this.registryId],
                    excludePending: true,
                },
            });

            this.board.monitoring.loading = false;
            this.board.monitoring.items = reviewedRisks.items.filter((r) => r.lifecycle.status == "Monitoring" && this.registryId === r.lifecycle.registryId);

            this.board.closed.loading = false;
            this.board.closed.items = reviewedRisks.items.filter((r) => r.lifecycle.status == "Closed" && this.registryId === r.lifecycle.registryId);

            this.board.transferred.loading = false;
            this.board.transferred.items = reviewedRisks.items.filter((r) => this.registryId !== r.lifecycle.registryId);
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.loading = false;
        }
    });

    /* @action
    public loadRisks(options) {
        this.loading = true;
        this.keywords = options.keywords;
        this.registryIds = options.registryIds;

        for (let [id, column] of Object.entries<any>(this.board)) {
            column.loading = true;
            column.items = [];
        }

        this.pendingRisks = [];
        this.reviewedRisks = [];
        Promise.all([
            this.riskService.searchRisks({
                query: {
                    keywords: this.keywords,
                    templateId: "e2df7b29-09a2-410c-b3b5-7880c43555ea",
                    registry: this.registryIds
                }
            }),

            this.riskService.searchRisks({
                query: {
                    keywords: this.keywords,
                    templateId: "f71a9bf4-ad2e-4dac-bda6-fdb6bf63381e",
                    registry: this.registryIds,
                    excludePending: true
                }
            })
        ]).then(this.loadRisksOnSuccess, this.loadRisksOnError);
    }

    @action.bound
    public loadRisksOnSuccess(result) {
        this.pendingRisks = result[0].items;
        this.reviewedRisks = result[1].items;

        this.keywords = result[0].keywords;
        this.pagination.setPaging(result[0].pageSize, result[0].totalItems, result[0].startIndex);


        this.board.pending.loading = false;
        this.board.pending.items = result[0].items;
        this.board.pending.pagination.setPaging(result[0].pageSize, result[0].totalItems, result[0].startIndex);

        this.board.monitoring.loading = false;
        this.board.monitoring.items = result[1].items.filter(r => r.lifecycle.status == 'Monitoring' && this.registryIds.indexOf(r.lifecycle.registryId) !== -1);
        this.board.monitoring.pagination.setPaging(result[1].pageSize, result[1].totalItems, result[1].startIndex);

        this.board.closed.loading = false;
        this.board.closed.items = result[1].items.filter(r => r.lifecycle.status == 'Closed' && this.registryIds.indexOf(r.lifecycle.registryId) !== -1);
        this.board.closed.pagination.setPaging(result[1].pageSize, result[1].totalItems, result[1].startIndex);

        this.board.transferred.loading = false;
        this.board.transferred.items = result[1].items.filter(r => this.registryIds.indexOf(r.lifecycle.registryId) === -1);
        this.board.transferred.pagination.setPaging(result[1].pageSize, result[1].totalItems, result[1].startIndex);

        this.loading = false;
        return result;
    }

    @action.bound
    public loadRisksOnError(error) {
        this.error = error;
        this.loading = false;
        throw error;
    }
 */
    @action
    public loadRisk(id) {
        this.loading = true;
        if (!this.selectedRisk || this.selectedRisk.id != id) {
            this.selectedRisk = null;
        }
        return this.riskService.getRisk(id).then(this.loadRiskOnSuccess, this.loadRiskOnError);
    }

    @action.bound
    public loadRiskOnSuccess(result) {
        this.selectedRisk = result;
        this.loading = false;
        return result;
    }

    @action.bound
    public loadRiskOnError(error) {
        this.error = error;
        this.loading = false;
        throw error;
    }

    _goToUrl = (url) => {
        if (url) {
            this.parentStore.rootStore.routing.push(url);
        }
    };
}
