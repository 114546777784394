import React from 'react';
import { observer, inject } from 'mobx-react';

import { isUserAuthenticated, isUserInAnyRoles, isUserInAllRoles } from '../../stores/PrincipalContextUtilities';

export const SecurityTrimmed = inject('rootStore', 'principalContext')(observer(
    class SecurityTrimmed extends React.Component {

        render () {
            const { principalContext, any, all, allowGuest, predicate } = this.props;
            
            if((allowGuest && isUserAuthenticated(principalContext.current)) 
                || (any && isUserInAnyRoles(principalContext.current, any)) 
                || (all && isUserInAllRoles(principalContext.current, all))
                || (predicate && predicate(principalContext.current))) {
                return this.props.children;
            }

            return null;
        }
    }
));
