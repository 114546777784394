import React from 'react';
import { Dropdown as FabricDropDown } from 'office-ui-fabric-react/lib/Dropdown';

const Dropdown = (props) => {

    return (
        <FabricDropDown 
            {...props}
        />
    );
}

export { Dropdown }