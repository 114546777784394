import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";

import { PanelType } from "office-ui-fabric-react/lib/Panel";
import { PrimaryButton, ActionButton } from "office-ui-fabric-react/lib/Button";

import { ObservablePanel } from "@modules/base/components/ObservablePanel";
import { ActionPanel } from "@modules/base/components/ActionPanel";

import { AdvisoryDetails } from "@modules/assessments/components/advisories/AdvisoryDetails";
import { EvidenceGridView } from "@modules/assessments/components/evidences/EvidenceGridView";

export const AdvisoryViewPanel = inject(
    "assessmentStore",
    "routing"
)(
    withRouter(
        observer(
            class AdvisoryViewPanel extends Component {
                componentWillMount() {
                    const { evidenceStore, advisoryStore } = this.props.assessmentStore;
                    const { selectedAdvisory } = advisoryStore;

                    evidenceStore.loadAdvisoryEvidences(selectedAdvisory.id).then((result) => {});
                }

                render() {
                    const { advisoryStore } = this.props.assessmentStore;
                    const { selectedAdvisory, visible } = advisoryStore;

                    return (
                        <ObservablePanel
                            hidden={!visible}
                            pageTitle={(selectedAdvisory && selectedAdvisory.title) || "New Advisory"}
                            pageType="Advisory"
                            panelType={PanelType.medium}
                            onClosePanel={() => this._onClosePanel()}
                            onRenderFooter={() => this._renderFooter()}
                        >
                            {selectedAdvisory && this._renderCore()}
                        </ObservablePanel>
                    );
                }

                _renderFooter() {
                    const { lifecycleStore } = this.props.assessmentStore;
                    const disabled = !lifecycleStore.canUpdateAdvisories; //selectedAssessment.state != 'Remediation';

                    const menuProps = {
                        items: [
                            {
                                key: "compliant",
                                text: "Compliant",
                                onClick: () => this._onMarkAsInvoked("Compliant"),
                            },
                            {
                                key: "nonCompliant",
                                text: "Non Compliant",
                                onClick: () => this._onMarkAsInvoked("NonCompliant"),
                            },
                            {
                                key: "Open",
                                text: "Open",
                                onClick: () => this._onMarkAsInvoked(null),
                            },
                        ],
                    };

                    return (
                        <ActionPanel>
                            <ActionButton style={{ height: 32 }} onClick={() => this._onClosePanel()} text="Cancel" />
                            <PrimaryButton menuProps={menuProps} text="Mark As" disabled={disabled} />
                        </ActionPanel>
                    );
                }

                _renderCore() {
                    const { lifecycleStore, advisoryStore, evidenceStore } = this.props.assessmentStore;
                    const { evidences, loading } = evidenceStore;
                    const { selectedAdvisory } = advisoryStore;
                    const disabled = !lifecycleStore.canAddEvidence; // selectedAssessment.state != 'Remediation';

                    return (
                        <r-grid columns="8" class="row-xs">
                            <r-cell span="8">
                                <AdvisoryDetails advisory={selectedAdvisory} />
                            </r-cell>
                            <r-cell span="8" class="evidence__col">
                                <div className="flex row--xxs">
                                    <ActionButton
                                        iconProps={{ iconName: "Add" }}
                                        allowDisabledFocus
                                        disabled={disabled}
                                        onClick={() => this._onAddEvidenceInvoked()}
                                    >
                                        Add Evidence
                                    </ActionButton>
                                </div>
                                <div className="no--margin no--padding">
                                    <EvidenceGridView
                                        loading={loading}
                                        evidences={evidences}
                                        onWorkItemClick={(evidence) => this._onWorkItemClick(evidence)}
                                        onDocumentClick={(evidence) => this._onDocumentClick(evidence)}
                                    />
                                </div>
                            </r-cell>
                        </r-grid>
                    );
                }

                _onDocumentClick(evidence) {
                    const { evidenceStore } = this.props.assessmentStore;
                    evidenceStore.downloadEvidence(evidence);
                }

                _onWorkItemClick(evidence) {
                    const { routing } = this.props;
                    routing.push(`/demand/browse/${evidence.workItem.id}`);
                }

                _onAddEvidenceInvoked() {
                    const { advisoryStore, evidenceStore } = this.props.assessmentStore;

                    evidenceStore.show({
                        selectedAdvisories: {
                            selected: [advisoryStore.selectedAdvisory],
                        },
                    });
                }

                _onMarkAsInvoked(final) {
                    const { advisoryStore } = this.props.assessmentStore;
                    advisoryStore.markAs(final).then(() => advisoryStore.hide());
                }

                _onClosePanel() {
                    const { advisoryStore } = this.props.assessmentStore;
                    advisoryStore.hide();
                }
            }
        )
    )
);
