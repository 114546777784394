import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import * as moment from 'moment';

import { AppPage } from '@layout/containers/AppPage';

import { AssessmentTimelineView } from '../../components/core/AssessmentTimelineView';
import { AssessmentSideNav} from '../../components/core/AssessmentSideNav';

export const AssessmentTimelinePage = inject('pageContext', 'assessmentStore', 'routing')(observer(
    class AssessmentTimelinePage extends Component {

        constructor(props) {
            super(props)
            this.state = {
                qs : null
            }
        }

        componentWillMount() {
            const { assessmentStore, routing, pageContext } = this.props;
            pageContext.updateQueryStringParams(routing.location.search);
            if(pageContext.queryStringParams.start && pageContext.queryStringParams.end) {
                assessmentStore.timelineStore.loadAssessments({
                    view: pageContext.queryStringParams.view,
                    date: pageContext.queryStringParams.date,
                    start: pageContext.queryStringParams.start,
                    end: pageContext.queryStringParams.end
                });
                this.setState({
                    qs: routing.location.search
                })
            }
        }

        componentWillReceiveProps(nextProps) {
            const { assessmentStore, routing, pageContext } = nextProps;
            if (this.state.qs !== routing.location.search) {
                pageContext.updateQueryStringParams(routing.location.search);
                if(pageContext.queryStringParams.start && pageContext.queryStringParams.end) {
                    assessmentStore.timelineStore.loadAssessments({
                        view: pageContext.queryStringParams.view,
                        date: pageContext.queryStringParams.date,
                        start: pageContext.queryStringParams.start,
                        end: pageContext.queryStringParams.end
                    });
                }
                this.setState({
                    qs: routing.location.search
                })
            }
        }
    
        render () {
            const { assessmentStore, routing } = this.props;
            const { lifecycleStore } = assessmentStore;
            const { breadcrumb, error, loading, date, events, resources, users, view } = assessmentStore.timelineStore;

            return (
                <AppPage error={!loading && !lifecycleStore.canViewResourcePages ? { status: 403 } : error} 
                    breadcrumb={breadcrumb}
                    loading={loading || !events} 
                    hasSidebar={false} 
                    className="assessments list has--page-nav">
                        
                        <AssessmentSideNav
                            onLinkClick={(item) => routing.push(item.url)}
                            onNewTriage={() => routing.push('/assurance/triage')}
                            canViewResourcePages={lifecycleStore.canViewResourcePages}
                            canViewCalendarPages={lifecycleStore.canViewCalendarPages} 
                            canViewAdvisoryPages={lifecycleStore.canViewAdvisoryPages}
                            />
                            
                        <AssessmentTimelineView
                            events={events}
                            resources={resources}
                            users={users}
                            view={view}
                            loading={loading}
                            date={date}
                            query={{}}
                            onDatesSet={(rangeInfo) => {
                                const date = moment(rangeInfo.view.calendar.getDate()).utc().format();
                                const start = moment(rangeInfo.start).utc().format();
                                const end = moment(rangeInfo.end).utc().format();
                                routing.push(`/assurance/timeline?view=${rangeInfo.view.type.substr(16)}&date=${date}&start=${start}&end=${end}`); 
                            }}
                            onQuarterSet={(rangeInfo) => {
                                debugger
                                // const date = moment(rangeInfo.view.calendar.getDate()).utc().format();
                                // const start = moment(rangeInfo.start).utc().format();
                                // const end = moment(rangeInfo.end).utc().format();
                                // view=Quarter&date=2021-03-05T17:25:47Z&start=2021-01-01T00:00:00Z&end=2021-03-30T23:00:00Z
                                //routing.push(`/assurance/timeline?view=Quarter&date=${date}&start=${start}&end=${end}`); 
                                routing.push(`/assurance/timeline?view=Quarter&date=2020-12-12T17:25:47Z&start=2020-10-01T00:00:00Z&end=2020-12-31T23:00:00Z`); 
                            }}
                            onEventClick={(info) => {
                                routing.push(`/assurance/browse/${info.event.extendedProps.assessment.id}`);
                            }} />
                           
                </AppPage>
            );
        }
    }
));
