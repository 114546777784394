import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router";

import { AppLayout } from "@modules/layout/containers/AppLayout";

import { HomeOverview } from "@modules/home/containers/HomeOverview";

import { AccountOverviewPage } from "@modules/accounts/containers/pages/AccountOverviewPage";
import { AccountTaskBrowsePage } from "@modules/accounts/containers/pages/AccountTaskBrowsePage";

import { SystemBrowsePage } from "@modules/systems/containers/pages/SystemBrowsePage";
import { SystemViewPage } from "@modules/systems/containers/pages/SystemViewPage";

import { AssetSettingsPage } from "@modules/assets/containers/pages/AssetSettingsPage";
import { AssetDashboardPage } from "@modules/assets/containers/pages/AssetDashboardPage";
import { AssetBrowsePage } from "@modules/assets/containers/pages/AssetBrowsePage";
import { AssetWatchlistPage } from "@modules/assets/containers/pages/AssetWatchlistPage";
import { AssetViewerPage } from "@modules/assets/containers/pages/AssetViewerPage";

import { EntitySettingsPage } from "@modules/entities/containers/pages/EntitySettingsPage";
import { EntityDashboardPage } from "@modules/entities/containers/pages/EntityDashboardPage";
import { EntityBrowsePage } from "@modules/entities/containers/pages/EntityBrowsePage";
import { EntityWatchlistPage } from "@modules/entities/containers/pages/EntityWatchlistPage";
import { EntityViewerPage } from "@modules/entities/containers/pages/EntityViewerPage";

import { CalendarOverview } from "@modules/calendar/containers/CalendarOverview";

import { RiskDashboardPage } from "@modules/risks/containers/pages/RiskDashboardPage";
import { RiskBrowsePage } from "@modules/risks/containers/pages/RiskBrowsePage";
import { RiskHeatmapPage } from "@modules/risks/containers/pages/RiskHeatmapPage";
import { RiskPriorityPage } from "@modules/risks/containers/pages/RiskPriorityPage";
import { RiskViewPage } from "@modules/risks/containers/pages/RiskViewPage";
import { RiskBoardPage } from "@modules/risks/containers/pages/RiskBoardPage";
import { ExceptionDashboardPage } from "@modules/risks/containers/pages/ExceptionDashboardPage";
import { ExceptionBrowsePage } from "@modules/risks/containers/pages/ExceptionBrowsePage";
import { ExceptionViewerPage } from "@modules/risks/containers/pages/ExceptionViewerPage";
import { ExceptionCataloguePage } from "@modules/risks/containers/pages/ExceptionCataloguePage";
import { ExceptionNewPage } from "@modules/risks/containers/pages/ExceptionNewPage";
import { RiskTypesSettingsPage } from "@modules/risks/containers/pages/RiskTypesSettingsPage";
import { RiskTypeViewerPage } from "@modules/risks/containers/pages/RiskTypeViewerPage";
import { RiskRegistriesSettingsPage } from "@modules/risks/containers/pages/RiskRegistriesSettingsPage";
import { RiskRegistriesViewerPage } from "@modules/risks/containers/pages/RiskRegistriesViewerPage";
import { RiskCataloguePage } from "@modules/risks/containers/pages/RiskCataloguePage";

import { AssessmentDashboardPage } from "@modules/assessments/containers/pages/AssessmentDashboardPage";
import { AssessmentBrowsePage } from "@modules/assessments/containers/pages/AssessmentBrowsePage";
import { AssessmentCalendarPage } from "@modules/assessments/containers/pages/AssessmentCalendarPage";
import { AssessmentTimelinePage } from "@modules/assessments/containers/pages/AssessmentTimelinePage";
import { AssessmentWorkloadPage } from "@modules/assessments/containers/pages/AssessmentWorkloadPage";
import { AssessmentPortfolioPage } from "@modules/assessments/containers/pages/AssessmentPortfolioPage";

import { AdvisoriesBrowsePage } from "@modules/assessments/containers/pages/AdvisoriesBrowsePage";

import { AssessmentReportPage } from "@modules/assessments/containers/pages/AssessmentReportPage";
import { AssessmentViewerPage } from "@modules/assessments/containers/pages/AssessmentViewerPage";
import { AssessmentModulePage } from "@modules/assessments/containers/pages/AssessmentModulePage";

// import { PatternListPage } from "@modules/modelling/containers/pages/PatternListPage";
// import { PatternViewPage } from "@modules/modelling/containers/pages/PatternViewPage";
import { PatternBrowsePage } from "@modules/patterns/containers/pages/PatternBrowsePage";
import { PatternViewerPage } from "@modules/patterns/containers/pages/PatternViewerPage";
import { PatternModulePage } from "@modules/patterns/containers/pages/PatternModulePage";

import { StateFlowBrowsePage } from "@modules/stateflows/containers/pages/StateFlowBrowsePage";
import { StateFlowViewerPage } from "@modules/stateflows/containers/pages/StateFlowViewerPage";
import { StateFlowEditorPage } from "@modules/stateflows/containers/pages/StateFlowEditorPage";

import { WorkItemBrowsePage } from "@modules/workitems/containers/pages/WorkItemBrowsePage";
import { WorkItemDashboardPage } from "@modules/workitems/containers/pages/WorkItemDashboardPage";
import { WorkItemViewerPage } from "@modules/workitems/containers/pages/WorkItemViewerPage";
import { WorkItemReviewPage } from "@modules/workitems/containers/pages/WorkItemReviewPage";
import { WorkItemCalendarPage } from "@modules/workitems/containers/pages/WorkItemCalendarPage";
import { WorkItemTimelinePage } from "@modules/workitems/containers/pages/WorkItemTimelinePage";
import { WorkItemRequestPage } from "@modules/workitems/containers/pages/WorkItemRequestPage";

import { BoardPage } from "@modules/boards/containers/pages/BoardPage";
import { BoardsListPage } from "@modules/boards/containers/pages/BoardsListPage";

import { ProductCataloguePage } from "@modules/products/containers/pages/ProductCataloguePage";
import { ProductBrowsePage } from "@modules/products/containers/pages/ProductBrowsePage";
import { ProductViewerPage } from "@modules/products/containers/pages/ProductViewerPage";

import { TriageFormPage } from "@modules/triage/containers/pages/TriageFormPage";

import { InsightsOverview } from "@modules/insights/containers/pages/InsightsOverview";
import { InsightsPortfolios } from "@modules/insights/containers/pages/InsightsPortfolios";

import { SettingsDashboardPage } from "@modules/settings/containers/pages/SettingsDashboardPage";

import { GroupListPage } from "@modules/identity/containers/pages/GroupListPage";

import { UserListPage } from "@modules/identity/containers/pages/UserListPage";

import { PortfolioBrowsePage } from "@modules/portfolios/containers/pages/PortfolioBrowsePage";
import { PortfolioViewerPage } from "@modules/portfolios/containers/pages/PortfolioViewerPage";

import { PageBrowsePage } from "@modules/pages/containers/pages/PageBrowsePage";
import { PageViewerPage } from "@modules/pages/containers/pages/PageViewerPage";
import { PageLibraryBrowsePage } from "@modules/pages/containers/pages/PageLibraryBrowsePage";
import { PageLibraryViewerPage } from "@modules/pages/containers/pages/PageLibraryViewerPage";

import { DataSourceBrowsePage } from "@modules/datasources/containers/pages/DataSourceBrowsePage";

import { ControlSetBrowsePage } from "@modules/controls/containers/pages/ControlSetBrowsePage";
import { ControlSetViewerPage } from "@modules/controls/containers/pages/ControlSetViewerPage";
import { ControlMapBrowsePage } from "@modules/controls/containers/pages/ControlMapBrowsePage";
import { ControlMapViewerPage } from "@modules/controls/containers/pages/ControlMapViewerPage";
import { ControlViewerPage } from "@modules/controls/containers/pages/ControlViewerPage";

import { IdentitySettingsPage } from "@modules/settings/containers/pages/IdentitySettingsPage";
import { DemandSettingsPage } from "@modules/settings/containers/pages/DemandSettingsPage";
import { AssuranceSettingsPage } from "@modules/settings/containers/pages/AssuranceSettingsPage";
import { RisksSettingsPage } from "@modules/settings/containers/pages/RisksSettingsPage";
import { ControlsSettingsPage } from "@modules/settings/containers/pages/ControlsSettingsPage";

import { ControlModulePage } from "@modules/controls/containers/pages/ControlModulePage";

import "./css/styles.css";

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <AppLayout>
                <Switch>
                    <Route exact path="/" component={() => <HomeOverview />} />
                    {/* <Route exact path="/" component={() => (
                        <PageViewerPage
                            hidePageHierarchyNav={true}
                            pageProps={{
                                metadata: "bottom",
                                className: "cygraph-InsightsPage-root",
                            }}
                        />
                    )} /> */}

                    <Route path="/controls/" component={() => <ControlModulePage />} />

                    <Route exact path="/assurance/dashboard" component={() => <AssessmentDashboardPage />} />
                    <Route exact path="/assurance/browse" component={() => <AssessmentBrowsePage />} />
                    <Route exact path="/assurance/calendar" component={() => <AssessmentCalendarPage />} />
                    <Route exact path="/assurance/timeline" component={() => <AssessmentTimelinePage />} />
                    <Route exact path="/assurance/workload" component={() => <AssessmentWorkloadPage />} />
                    <Route exact path="/assurance/portfolios" component={() => <AssessmentPortfolioPage />} />
                    <Route exact path="/assurance/browse/:id/report" component={() => <AssessmentReportPage />} />

                    <Route exact path="/assurance/browse/:id/:tab?" component={() => <AssessmentViewerPage />} />
                    <Route exact path="/assurance/browse/:id/-/:moduleId" component={() => <AssessmentModulePage />} />
                    <Route exact path="/assurance/browse/:id" component={() => <AssessmentViewerPage />} />
                    <Route exact path="/assurance/triage" component={() => <TriageFormPage />} />

                    <Redirect exact from="/assessments/dashboard" to="/assurance/dashboard" />
                    <Redirect exact from="/assessments/browse" to="/assurance/browse" />
                    <Redirect exact from="/assessments/browse/:id/:moduleId" to="/assurance/browse/:id/-/:moduleId" />
                    <Redirect exact from="/assessments/browse/:id" to="/assurance/browse/:id" />
                    <Redirect exact path="/assessments/browse/:id/tasks" to="/assurance/browse/:id/tasks" />
                    <Redirect exact from="/assessments/triage" to="/assurance/triage" />

                    <Redirect exact from="/assessments" to="/assurance/browse" />
                    <Redirect exact from="/assessments/:id/:moduleId" to="/assurance/browse/:id/:moduleId" />
                    <Redirect exact from="/assessments/:id" to="/assurance/browse/:id" />

                    <Redirect exact from="/assets" to="/assets/browse" />
                    <Route exact path="/assets/dashboard" component={() => <AssetDashboardPage />} />
                    <Route exact path="/assets/browse" component={() => <AssetBrowsePage />} />
                    <Route exact path="/assets/watchlist" component={() => <AssetWatchlistPage />} />
                    <Route exact path="/assets/browse/:id/:tab?" component={() => <AssetViewerPage />} />
                    <Route exact path="/assets/browse/:id/history" component={() => <SystemViewPage tab="history" />} />
                    <Route
                        exact
                        path="/assets/browse/:id/history/:state"
                        component={() => <SystemViewPage tab="history" />}
                    />
                    <Route
                        exact
                        path="/assets/browse/:id/advisories"
                        component={() => <SystemViewPage tab="advisories" />}
                    />

                    <Redirect exact from="/entities" to="/entities/browse" />
                    <Route exact path="/entities/dashboard" component={() => <EntityDashboardPage />} />
                    <Route exact path="/entities/browse" component={() => <EntityBrowsePage />} />
                    <Route exact path="/entities/watchlist" component={() => <EntityWatchlistPage />} />
                    <Route exact path="/entities/browse/:id/:tab?" component={() => <EntityViewerPage />} />

                    <Redirect exact from="/advisories" to="/advisories/browse" />
                    <Route exact path="/advisories/browse" component={() => <AdvisoriesBrowsePage />} />

                    <Redirect exact from="/systems" to="/assets/browse" />
                    <Redirect exact from="/systems/:id" to="/assets/browse/:id" />
                    <Redirect exact from="/systems/:id/history" to="/assets/browse/:id/history" />
                    <Redirect exact from="/systems/:id/history/:state" to="/assets/browse/:id/history/:state" />
                    <Redirect exact from="/systems/:id/advisories" to="/assets/browse/:id/advisories" />

                    <Route exact path="/calendar" component={() => <CalendarOverview />} />

                    <Route exact path="/risks/dashboard" component={() => <RiskDashboardPage />} />
                    <Route exact path="/risks/browse" component={() => <RiskBrowsePage />} />
                    <Route exact path="/risks/heatmap" component={() => <RiskHeatmapPage />} />
                    <Route exact path="/risks/priority" component={() => <RiskPriorityPage />} />
                    <Route exact path="/risks/boards/:id" component={() => <RiskBoardPage />} />
                    <Route exact path="/risks/browse/:id/:tab?" component={() => <RiskViewPage />} />
                    <Route exact path="/risks/browse/:id" component={() => <RiskViewPage />} />
                    <Route exact path="/risks/new" component={() => <RiskCataloguePage />} />
                    <Route exact path="/risks/:id" component={() => <RiskViewPage />} />

                    <Route exact path="/exceptions/dashboard" component={() => <ExceptionDashboardPage />} />
                    <Route exact path="/exceptions/browse" component={() => <ExceptionBrowsePage />} />
                    <Route exact path="/exceptions/browse/:id/:tab?" component={() => <ExceptionViewerPage />} />
                    <Route exact path="/exceptions/browse/:id" component={() => <ExceptionViewerPage />} />
                    <Route exact path="/exceptions/new" component={() => <ExceptionCataloguePage />} />
                    <Route exact path="/exceptions/new/:id" component={() => <ExceptionNewPage />} />

                    <Redirect exact from="/boards/:boardId/new/:productId" to="/demand/catalogue" />
                    <Redirect exact from="/boards/:boardId" to="/demand/boards/:boardId" />
                    <Redirect exact from="/boards" to="/demand/boards" />
                    <Redirect exact from="/work-items/dashboard" to="/demand/dashboard" />
                    <Redirect exact from="/work-items/browse" to="/demand/browse" />
                    <Redirect exact from="/work-items/browse/:id" to="/demand/:id" />
                    <Redirect exact path="/work-items/browse/:id/tasks" to="/demand/browse/:id/tasks" />
                    <Redirect exact from="/work-items" to="/demand/browse" />
                    <Redirect exact from="/work-items/my" to="/demand/browse/my" />
                    <Redirect exact from="/work-items/new/:productId" to="/demand/catalogue" />
                    <Redirect exact from="/work-items/:id/review" to="/demand/:id/review" />
                    <Redirect exact from="/work-items/:id" to="/demand/:id" />
                    <Redirect exact from="/products" to="/demand/catalogue" />
                    <Redirect exact from="/demand" to="/demand/browse" />

                    <Route exact path="/demand/catalogue" component={() => <ProductCataloguePage />} />
                    <Route exact path="/demand/catalogue/:id" component={() => <WorkItemRequestPage />} />
                    <Route exact path="/demand/calendar" component={() => <WorkItemCalendarPage />} />
                    <Route exact path="/demand/timeline" component={() => <WorkItemTimelinePage />} />
                    <Route exact path="/demand/browse/my" component={() => <WorkItemBrowsePage />} />
                    <Route exact path="/demand/browse" component={() => <WorkItemBrowsePage />} />
                    <Route exact path="/demand/browse/:id/review" component={() => <WorkItemReviewPage />} />
                    <Route exact path="/demand/browse/:id/tasks" component={() => <WorkItemViewerPage />} />
                    <Route exact path="/demand/browse/:id/:tab?" component={() => <WorkItemViewerPage />} />
                    <Route exact path="/demand/browse/:id" component={() => <WorkItemViewerPage />} />
                    <Route exact path="/demand/dashboard" component={() => <WorkItemDashboardPage />} />
                    <Route exact path="/demand/boards/:boardId" component={() => <BoardPage />} />
                    <Route exact path="/demand/boards" component={() => <WorkItemDashboardPage />} />
                    <Route exact path="/demand/:id/review" component={() => <WorkItemReviewPage />} />
                    <Route exact path="/demand/:id" component={() => <WorkItemViewerPage />} />

                    {/*
          <Route exact path='/insights' component={() => <InsightsOverview /> } />
          <Route exact path='/insights/portfolios' component={() => <InsightsPortfolios /> } /> */}
                    <Route
                        path="/insights*"
                        component={() => (
                            <PageViewerPage
                                pageProps={{
                                    metadata: "bottom",
                                    className: "cygraph-InsightsPage-root",
                                }}
                            />
                        )}
                    />

                    <Route exact path="/settings" component={() => <SettingsDashboardPage />} />

                    <Route exact path="/settings/identity/:tab?" component={() => <IdentitySettingsPage />} />
                    <Route exact path="/settings/demand/:tab?" component={() => <DemandSettingsPage />} />
                    <Route exact path="/settings/assurance/:tab?" component={() => <AssuranceSettingsPage />} />
                    <Route exact path="/settings/risks/:tab?" component={() => <RisksSettingsPage />} />
                    <Route exact path="/settings/controls/:tab?" component={() => <ControlsSettingsPage />} />

                    <Route exact path="/settings/page-libraries" component={() => <PageLibraryBrowsePage />} />
                    <Route exact path="/settings/page-libraries/:id" component={() => <PageLibraryViewerPage />} />
                    <Route exact path="/settings/pages/:libraryId/:pageId" component={() => <PageBrowsePage />} />
                    <Route exact path="/settings/groups" component={() => <GroupListPage />} />
                    <Route exact path="/settings/groups/:groupId" component={() => <GroupListPage />} />
                    <Route exact path="/settings/users" component={() => <UserListPage />} />
                    <Route exact path="/settings/users/:userId" component={() => <UserListPage />} />
                    <Route exact path="/settings/portfolios" component={() => <PortfolioBrowsePage />} />
                    <Route exact path="/settings/portfolios/:id" component={() => <PortfolioViewerPage />} />
                    {/* <Route exact path='/settings/portfolios/:portfolioId' component={() => <PortfolioListPage /> } /> */}
                    <Route exact path="/settings/products" component={() => <ProductBrowsePage />} />
                    {/* <Route exact path='/settings/products/:productId' component={() => <ProductBrowsePage /> } /> */}
                    <Route exact path="/settings/products/:id" component={() => <ProductViewerPage />} />

                    <Route exact path="/settings/patterns" component={() => <PatternBrowsePage />} />
                    <Route exact path="/settings/patterns/:id/:tab?" component={() => <PatternViewerPage />} />
                    <Route exact path="/settings/patterns/:id/-/:moduleId" component={() => <PatternModulePage />} />
                    <Route exact path="/settings/patterns/:id" component={() => <PatternViewerPage />} />

                    <Route exact path="/settings/workflows" component={() => <StateFlowBrowsePage />} />
                    <Route exact path="/settings/workflows/:id/editor" component={() => <StateFlowEditorPage />} />
                    <Route exact path="/settings/workflows/:id/:tab?" component={() => <StateFlowViewerPage />} />

                    <Route exact path="/settings/data-sources" component={() => <DataSourceBrowsePage />} />
                    <Route exact path="/settings/data-sources/:sourceId" component={() => <DataSourceBrowsePage />} />
                    <Route exact path="/settings/control-sets" component={() => <ControlSetBrowsePage />} />
                    <Route exact path="/settings/control-sets/:id" component={() => <ControlSetViewerPage />} />
                    <Route exact path="/settings/control-sets/:setId/:id" component={() => <ControlViewerPage />} />
                    <Route exact path="/settings/control-maps" component={() => <ControlMapBrowsePage />} />
                    <Route exact path="/settings/control-maps/:id" component={() => <ControlMapViewerPage />} />
                    <Route exact path="/settings/assets" component={() => <AssetSettingsPage />} />
                    <Route exact path="/settings/entities" component={() => <EntitySettingsPage />} />

                    <Route exact path="/settings/risk-registries" component={() => <RiskRegistriesSettingsPage />} />
                    <Route exact path="/settings/risk-registries/:id" component={() => <RiskRegistriesViewerPage />} />
                    <Route exact path="/settings/risk-types" component={() => <RiskTypesSettingsPage />} />
                    <Route exact path="/settings/risk-types/:id" component={() => <RiskTypeViewerPage />} />

                    <Route exact path="/account/overview" component={() => <AccountOverviewPage />} />
                    <Route exact path="/account/tasks" component={() => <AccountTaskBrowsePage />} />

                    <Route exact path="*" component={() => <PageViewerPage />} />
                </Switch>
            </AppLayout>
        );
    }
}
