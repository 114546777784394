import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { cx, css } from "emotion";

import { Label } from "office-ui-fabric-react/lib/Label";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { PrimaryButton, ActionButton, DefaultButton } from "office-ui-fabric-react/lib/Button";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { Text } from "office-ui-fabric-react/lib/Text";
import { Callout } from "office-ui-fabric-react/lib/Callout";

import { RichDropdown } from "../../../base/richdropdown/RichDropdown";

const calloutClassName = cx(css``);

const controlPickerContainerClassName = cx(css`
    display: flex;
`);

export const ControlHierarchyPicker = inject("controlStore")(
    observer(
        class ControlHierarchyPicker extends Component {
            constructor(props) {
                super(props);
                this.state = {
                    isControlsCalloutOpen: false,
                    selectedMainControl: null,
                    selectedLevel1Control: null,
                    selectedLevel2Control: null,
                    textSearch: "",
                };
                props.controlStore.mapEditStore.loadControls({ cache: true });
            }
            componentWillReceiveProps(props) {
                this.preSelectOptions(props);
            }

            preSelectOptions(props) {
                const { selected, controlStore } = props;
                const { mapEditStore } = controlStore;
                const { controls } = mapEditStore;
                if (controls && selected && selected.length === 1) {
                    // console.log(selected[0].id)
                    controls.forEach((mainControl) => {
                        mainControl.children.forEach((level1Control) => {
                            level1Control.children.forEach((level2Control) => {
                                if (level2Control.id === selected[0].id) {
                                    // console.log('Found', level2Control, level1Control, mainControl);
                                    this.setState({
                                        selectedMainControl: {
                                            title: mainControl.name,
                                            key: mainControl.id,
                                            description: mainControl.description ? <Text>{mainControl.Description}</Text> : null,
                                        },
                                        selectedLevel1Control: {
                                            title: level1Control.name,
                                            key: level1Control.id,
                                            description: level1Control.description ? <Text>{level1Control.Description}</Text> : null,
                                        },
                                        selectedLevel2Control: {
                                            title: level2Control.name,
                                            key: level2Control.id,
                                            description: level2Control.description ? <Text>{level2Control.Description}</Text> : null,
                                        },
                                    });
                                }
                            });
                        });
                    });
                }
            }

            getMainControls() {
                const { controlStore } = this.props;
                const { mapEditStore } = controlStore;
                const controls = mapEditStore.controls || [];
                return controls.map((C) => {
                    return {
                        title: C.name,
                        key: C.id,
                        description: C.description ? <Text>{C.Description}</Text> : null,
                    };
                });
            }

            getLevel1Controls() {
                const { controlStore } = this.props;
                const { selectedMainControl } = this.state;
                const { mapEditStore } = controlStore;
                const controls = mapEditStore.controls || [];
                if (selectedMainControl) {
                    const mainControl = controls.find((C) => C.id === selectedMainControl.key);
                    if (mainControl) {
                        return mainControl.children.map((C) => {
                            return {
                                title: C.name,
                                key: C.id,
                                description: C.description ? <Text>{C.description}</Text> : null,
                            };
                        });
                    }
                }
                return [];
            }

            getLevel2Controls() {
                const { controlStore } = this.props;
                const { mapEditStore } = controlStore;
                const controls = mapEditStore.controls || [];
                const { selectedMainControl, selectedLevel1Control } = this.state;
                if (selectedMainControl && selectedLevel1Control) {
                    const mainControl = controls.find((C) => C.id === selectedMainControl.key);
                    if (mainControl) {
                        const level1Control = mainControl.children.find((C) => C.id === selectedLevel1Control.key);
                        if (level1Control.children) {
                            return level1Control.children.map((C) => {
                                return {
                                    title: C.name,
                                    key: C.id,
                                    description: C.description ? <Text>{C.description}</Text> : null,
                                };
                            });
                        }
                    }
                }
                return [];
            }

            onCloseDialog() {
                this.setState({
                    isControlsCalloutOpen: false,
                    selectedMainControl: null,
                    selectedLevel1Control: null,
                    selectedLevel2Control: null,
                });
            }

            onControlPicked() {
                const { onControlPicked, controlStore } = this.props;
                const { mapEditStore } = controlStore;
                const controls = mapEditStore.controls || [];
                if (onControlPicked && this.state.selectedMainControl && this.state.selectedLevel1Control && this.state.selectedLevel2Control) {
                    const selectedControl = controls
                        .find((C) => C.id === this.state.selectedMainControl.key)
                        .children.find((C) => C.id === this.state.selectedLevel1Control.key)
                        .children.find((C) => C.id === this.state.selectedLevel2Control.key);
                    onControlPicked(selectedControl);
                }
                this.onCloseDialog();
            }

            render() {
                const { required, disabled, selected, label } = this.props;
                return (
                    <>
                        <Label required={required} disabled={disabled}>
                            {label || "Controls"}
                        </Label>
                        <div className={controlPickerContainerClassName}>
                            <TextField
                                readonly
                                // required
                                className="controlPicker-target"
                                styles={{ root: { width: "100%" }, suffix: { width: "32px", background: "transparent", padding: 0 } }}
                                value={selected.map((C) => C.name).join(", ")}
                                onRenderSuffix={() => (
                                    <DefaultButton
                                        styles={{
                                            root: { width: "32px", height: "30px", padding: 0, margin: 0, minWidth: "32px", border: 0 },
                                            icon: { fontSize: "12px" },
                                        }}
                                        iconProps={{ iconName: "ChevronDown" }}
                                        onClick={() => {
                                            this.preSelectOptions(this.props);
                                            this.setState({
                                                isControlsCalloutOpen: true,
                                            });
                                        }}
                                    />
                                )}
                            />
                        </div>
                        {this.state.isControlsCalloutOpen && (
                            <Callout
                                styles={{ root: { minWidth: "500px", padding: "15px", background: "var(--white)" } }}
                                // calloutWidth={calloutWidth}
                                target={`.controlPicker-target`}
                                onDismiss={() => this.onCloseDialog()}
                                isBeakVisible={false}
                                setInitialFocus
                            >
                                {/* <div className="textSearchContainer">
                        <TextField
                            style={{width: "100%"}}
                            label="Standard"
                            onChange={(e, value) => {
                                this.setState({
                                    textSearch: value
                                })
                            }}
                            value={this.state.textSearch}
                        />
                    </div> */}

                                <div className="dropdownContainer">
                                    <RichDropdown
                                        disabled={false}
                                        placeholder="Pick a control"
                                        label="Control Domain (Level 0)"
                                        selectedKey={this.state.selectedMainControl ? this.state.selectedMainControl.key : null}
                                        required
                                        options={this.getMainControls()}
                                        onOptionChanged={(item) => {
                                            this.setState({
                                                selectedMainControl: item,
                                                selectedLevel1Control: null,
                                                selectedLevel2Control: null,
                                            });
                                        }}
                                    />
                                    <RichDropdown
                                        disabled={this.state.selectedMainControl ? false : true}
                                        calloutWidth={500}
                                        placeholder="Pick a control"
                                        label="Control (Level 1)"
                                        selectedKey={this.state.selectedLevel1Control ? this.state.selectedLevel1Control.key : null}
                                        required
                                        options={this.getLevel1Controls()}
                                        onOptionChanged={(item) =>
                                            this.setState({
                                                selectedLevel1Control: item,
                                                selectedLevel2Control: null,
                                            })
                                        }
                                    />
                                    <RichDropdown
                                        disabled={this.state.selectedMainControl && this.state.selectedLevel1Control ? false : true}
                                        calloutWidth={500}
                                        placeholder="Pick a control"
                                        label="Control (Level 2)"
                                        selectedKey={this.state.selectedLevel2Control ? this.state.selectedLevel2Control.key : null}
                                        required
                                        options={this.getLevel2Controls()}
                                        onOptionChanged={(item) =>
                                            this.setState({
                                                selectedLevel2Control: item,
                                            })
                                        }
                                    />
                                </div>
                                <div className="dropdownFooter" style={{ marginTop: "15px" }}>
                                    <Stack horizontal horizontalAlign="space-between">
                                        <Stack horizontal tokens={{ childrenGap: 15 }}></Stack>
                                        <Stack horizontal tokens={{ childrenGap: 15 }}>
                                            <ActionButton
                                                styles={{ root: { height: "32px" } }}
                                                onClick={() => this.onCloseDialog()}
                                                text="Close"
                                                disabled={this.props.saving}
                                            />
                                            <PrimaryButton
                                                disabled={this.state.selectedLevel2Control ? false : true}
                                                onClick={() => this.onControlPicked()}
                                                text="Done"
                                            />
                                        </Stack>
                                    </Stack>
                                </div>
                            </Callout>
                        )}
                    </>
                );
            }
        }
    )
);
