//import 'bootstrap/dist/css/bootstrap.css';
//import 'core-js';
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "mobx-react";
import { createBrowserHistory } from "history";
import { syncHistoryWithStore } from "mobx-react-router";
import { Fabric } from "office-ui-fabric-react";
import { Router, Route, Switch } from "react-router";
import { AzureAD } from "react-aad-msal";
//import { configure } from "mobx";

import App from "./App";
import { RootStore } from "@base/RootStore";
import { ConfigStore } from "./modules/config/stores/ConfigStore";
import { ConfigModules } from "./modules/config/stores/ConfigModules";
import registerServiceWorker from "./registerServiceWorker";
import { authProvider } from "./authProvider";
import { themeProvider } from "./themeProvider";
import { tenantInfo } from "./configProvider";

import { SignInPage } from "./modules/auth/containers/pages/SignInPage";
import { SignedOutPage } from "./modules/auth/containers/pages/SignedOutPage";
import { TeamsApp } from "./modules/teams/TeamsApp";

import "./custom.css";

// configure({ enforceActions: 'observed' });

const browserHistory = createBrowserHistory();
const rootStore = new RootStore(authProvider);

const configProvider = new ConfigStore(rootStore);
configProvider.add(ConfigModules.Core, tenantInfo);

const stores = {
    ...rootStore,
    rootStore,
    themeProvider,
    configProvider,
    authProvider,
};

const history = syncHistoryWithStore(browserHistory, rootStore.routing);
history.subscribe((location, action) => rootStore.layoutStore.menu.locationChanged(location, action));
//history.subscribe((location, action) => rootStore.settingsStore.locationChanged(location, action));

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

const isCypress = !!localStorage.getItem("zerodai:cypress");

ReactDOM.render(
    <Provider {...stores}>
        <Router basename={baseUrl} history={history}>
            <Fabric>
                <Switch>
                    <Route exact path="/sign-in" component={() => <SignInPage />} />
                    <Route exact path="/signed-out" component={() => <SignedOutPage />} />
                    <Route path="/teams">
                        <TeamsApp />
                    </Route>
                    <Route path="/">
                        {isCypress ? (
                            <App />
                        ) : (
                            <AzureAD provider={authProvider} forceLogin={true}>
                                <App />
                            </AzureAD>
                        )}
                    </Route>
                </Switch>
            </Fabric>
        </Router>
    </Provider>,
    rootElement
);

registerServiceWorker();
