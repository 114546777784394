import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { cx, css } from 'emotion';
import { ReactRadarChart } from 'd3-radarchart';
import ContainerDimensions from 'react-container-dimensions';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { PagePartPlaceholder } from  '../../../pages/components/elements/PagePartPlaceholder';

const rootClassName = cx(
  'cygraph-ImageViewerPart-root'
);

const chartContainerClassName = cx(
  css `
    position: relative
  `
)

const divContainerClassName = cx(
  css `
    z-index: 100;    
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
  `
)

export const ControlMapViewerPart = inject('themeProvider','dataSourceStore', 'controlStore')(observer(
  class ControlMapViewerPart extends Component {

    constructor(props) {
      super(props)
      this.state = {
        rendered : false
      }
    }

    componentWillMount() {
      this.loadData(this.props)
    }

    componentWillReceiveProps(nextProps) {
        this.loadData(nextProps)
    }

    loadData(props) {
        const { controlStore, part } = props;
        if (part.properties.controlMapId) {
          controlStore.mapViewerStore.loadControlMap({ mapId: part.properties.controlMapId }).then(()=>{
            this.setState({rendered: true});
          });
        } else {
          this.setState({rendered: true})
        }
    }

    isConfigured() {
      const { part } = this.props;
      return part && part.properties && part.properties.controlMapId 
    }

    render () {
      const { className, instance, part, themeProvider, dataSourceStore, controlStore } = this.props;
      const { loading, saving, error, breadcrumb, map, model, controlMaps } = controlStore.mapViewerStore;
      const { selectedAxes, dataSeries } = part.properties;
      const mode = instance.lifecycleStore.mode;
      const theme = themeProvider.getTheme();
      
      const axisConfig = (model ? model.axes : [])
        .filter(a => selectedAxes ? selectedAxes.includes(a.id) : true)
        .map((axis) => ({
          label: axis.name, axisId: axis.id, axisValueMax: 1, axisValueMin: 0
        }));
      const dataSeriesData = dataSeries ? dataSeries.map((DS,i) => {
        const data = instance.selectionStore.data.find(D => D.id === DS.dataSourceId);
        if (data) {
          const selectedPartDataSource = DS.dataSourceId ? instance.selectionStore.dataSources.find(D => D.id === DS.dataSourceId) : null;
          const selectedCoreDataSource = dataSourceStore.coreDataSourceDataSource(selectedPartDataSource.properties.mainSource);
          const dSFinal = {
            label: DS.label || `RMap_${i}`,
            seriesId: `RMap_${i}`,
            dragEnabled: false,
            showCircle: true,
            circleHighlight: true,
            //fill: 'royalblue',
            data: (model ? model.axes : []).filter(a => selectedAxes ? selectedAxes.includes(a.id) : true).map((axis) => {
              let value = 0;
              if (data) {
                const dataPoint = data.data.find(D => axis.id === D.id);
                if (dataPoint) {
                  value = dataPoint.value
                }
              }
              return {
                axis: axis.id,
                value: value,
            }}),
          }
          return dSFinal
        }
      }) : [];

      const data = [
        {
          label: 'Reference Map',
          seriesId: 'ReferenceMap',
          dragEnabled: false,
          showCircle: true,
          circleHighlight: true,
          //fill: 'royalblue',
          data: (model ? model.axes : []).filter(a => selectedAxes ? selectedAxes.includes(a.id) : true).map((axis) => ({
            axis: axis.id,
            value: axis.targetValue
          })),
        },
        ...dataSeriesData.filter(DS => {
          if (DS === undefined) return false
          else return true
        })
    ];
    
    const options = {
        chartRootName: 'map',
        data,
        showLegend: true,
        enableZoom: false,
        rootElementId: 'chart',
        axisConfig,
        options: {
            axis: { 
              rotateTextWithAxis: false,
              axisLabelProps: {
                fontSize: 8,
              },
              axisScaleProps: {
                minTitleSize: 6,
                maxTitleSize: 18,
              },
              lineProps: {
                // fill: theme.palette.neutralSecondary,
                'hover-fill': theme.palette.neutralSecondary,
              },
              ticks: {
                fill: theme.palette.neutralSecondary,
                minZoomFont: 10,
                maxZoomFont: 1,
                'font-family': 'var(--font-family)'
              },
              axisLabelProps: {
                'font-family': 'var(--font-family)',
                fontSize: 11,
                // 'fill': theme.palette.neutralSecondary,
                // 'value-fill': 'yellow',
                'hover-fill': theme.palette.neutralSecondary,
              },
            },
            area: {
              labelProps: {
                'font-family': 'var(--font-family)',
              },
            },
            legend: {
                title: '',
                scaleTextWithSize: false,
                iconWidthP: 0.04,
                iconWidthP: 0.04,
                textOffsetP: 0.75,
                iconSpacingP: 0.06,
                titleProperties: {
                  fontSize: 12,
                  fontScaleMin: 5,
                  fontScaleMax: 20,
                  'font-family': 'var(--font-family)',
                  'fill': theme.palette.neutralSecondary,
                },
                labelTextProperties: {
                  fontSize: 11,
                  fontScaleMin: 5,
                  fontScaleMax: 20,
                  'font-family': 'var(--font-family)',
                  'fill': theme.palette.neutralSecondary,
                }
            }
        }
    };

    return (
      <div className={cx(rootClassName, className, mode)}>
        <PagePartPlaceholder {...this.props} isConfigured={this.isConfigured()}>
          <div className={chartContainerClassName} style={{
              height:part.height || '100%',
              width:part.width || '100%'
            }}>
            {!this.state.rendered && this.isConfigured() && <div className={cx(divContainerClassName,css `background: ${theme.themeLight};`)}>
              <Spinner size={SpinnerSize.large} />
            </div>}
            <div id={part.id} style={{maxWidth: 800, margin: 'auto', height: '100%'}}>
              <ContainerDimensions>
                  { ({ width }) => 
                    <ReactRadarChart {...options} {...{width}} />
                  }
              </ContainerDimensions>
            </div>
          </div>
        </PagePartPlaceholder>
      </div>
    );
    }
  }
));
