import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router';
import Moment from 'react-moment';
import ContainerDimensions from 'react-container-dimensions';
import { ReactRadarChart } from 'd3-radarchart';

import { css } from '@uifabric/utilities/lib/css';
import { Label } from 'office-ui-fabric-react/lib/Label';

import { AppPage } from '@layout/containers/AppPage'; 
import { ObservableSeparator } from '@baseComponents/ObservableSeparator';
import { BoxPanel } from '@baseComponents/BoxPanel';
import { SettingsListNav } from '@modules/settings/containers/utilities/SettingsListNav';

import { ControlMapHeader } from '../../components/maps/ControlMapHeader';

import { ControlMapEditPanel } from '../panels/ControlMapEditPanel';

const getQueryStringParams = query => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
};

export const ControlMapViewerPage = inject('pageContext', 'routing', 'controlStore')(withRouter(observer(
    class ControlMapViewerPage extends Component {

        componentWillMount() {
            const { match, routing, controlStore } = this.props;
            const params = getQueryStringParams(routing.location.search);
            controlStore.mapViewerStore.loadControlMap({ mapId: match.params.id });
        }

        componentWillReceiveProps(nextProps) {
            const { match, routing, controlStore } = nextProps;
            const params = getQueryStringParams(routing.location.search);
            controlStore.mapViewerStore.loadControlMap({ mapId: match.params.id });
        }

        render () {
            const { routing, controlStore } = this.props;
            const { loading, saving, error, breadcrumb, map, model, controlMaps } = controlStore.mapViewerStore;

            const axisConfig = (model ? model.axes : []).map((axis) => ({
                    label: axis.name, axisId: axis.id, axisValueMax: 1, axisValueMin: 0
            }));
            
            const data = [
                {
                  label: 'Reference Map',
                  seriesId: 'ReferenceMap',
                  dragEnabled: false,
                  showCircle: true,
                  circleHighlight: true,
                  //fill: 'royalblue',
                  data: (model ? model.axes : []).map((axis) => ({
                    axis: axis.id, value: axis.targetValue
                  })),
                },
                ...controlMaps.map(controlMap => ({
                    label: controlMap.name,
                    seriesId: controlMap.name,
                    dragEnabled: false,
                    showCircle: true,
                    circleHighlight: true,
                    data: controlMap.axes.map((axis) => ({
                        axis: axis.id, value: axis.value
                    })),
                }))
            ];
            
            const options = {
                chartRootName: 'map',
                data,
                showLegend: true,
                enableZoom: false,
                rootElementId: 'control-map-chart',
                axisConfig,
                options: {
                    axis: { 
                        rotateTextWithAxis: false,
                        axisLabelProps: {
                            fontSize: 8,
                        },
                        axisScaleProps: {
                            minTitleSize: 6,
                            maxTitleSize: 18,
                          },
                    },
                    legend: {
                        title: '',
                        scaleTextWithSize: false,
                        iconWidthP: 0.04,
                        iconWidthP: 0.04,
                        textOffsetP: 0.75,
                        iconSpacingP: 0.06
                    }
                }
            };

            return (
                <AppPage 
                    loading={loading || saving} 
                    error={error} 
                    breadcrumb={breadcrumb}
                    hasSidebar={false}
                    className={css('control-map viewer', !error && 'has--page-nav')}>
                    <SettingsListNav />
                    <div>
                        { map && (
                            <ControlMapHeader 
                                mode={'view'} map={map} 
                                canEdit={() => true}
                                onEdit={() => controlStore.mapEditStore.show({
                                    map: model
                                })} />
                        ) }

                        { map && (
                            <div className="row--s">
                                <ObservableSeparator>Summary</ObservableSeparator>
                                <BoxPanel shadow padding>
                                    <div className="row--s">
                                        <r-grid columns="4">
                                            <r-cell span="4">
                                                <div>
                                                    <Label>Description</Label>
                                                    <div>{map.description || 'N/A'}</div>
                                                </div>
                                            </r-cell>
                                            <r-cell>
                                                <div>
                                                    <Label>Category</Label>
                                                    <div>{map.category}</div>
                                                </div>
                                            </r-cell>
                                            <r-cell>
                                                <div>
                                                    <Label>Tags</Label>
                                                    <div>{map.tags.length > 0 ? map.tags.join(', ') : 'None'}</div>
                                                </div>
                                            </r-cell>
                                            <r-cell>
                                                <div>
                                                    <Label>Version</Label>
                                                    <div>{map.version}</div>
                                                </div>
                                            </r-cell>
                                            <r-cell>
                                                <div>
                                                    <Label>Published</Label>
                                                    <div><Moment utc date={map.modified} fromNow /></div>
                                                </div>
                                            </r-cell>
                                        </r-grid>
                                    </div>
                                </BoxPanel>
                            </div>
                        ) }

                        { map && (
                            <div className="row--s">
                                <ObservableSeparator>Map</ObservableSeparator>
                                <BoxPanel shadow padding>
                                    <div className="row--s" style={{maxWidth: 800, margin: 'auto'}}>
                                        <ContainerDimensions>
                                            { ({ width }) => 
                                                <ReactRadarChart {...options} {...{width, height: width-100}} />
                                            }
                                        </ContainerDimensions>
                                    </div>
                                </BoxPanel>
                            </div>
                        ) }
                    </div>

                    <ControlMapEditPanel onAfterSave={(map) => {
                        controlStore.mapViewerStore.loadControlMap({ mapId: map.id, cache: false });
                    }} />
                </AppPage>
            );
        }
    }
)));
