import React, { useEffect } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import { useStores } from "@hooks/useStores";
import { cx } from "emotion";

import Dropzone from "react-dropzone";

import { AppPage } from "@modules/layout/containers/AppPage";
import { StateFlowProgressBar } from "@modules/stateflows/components/core/StateFlowProgressBar";

import { RiskHeader } from "@modules/risks/components/core/RiskHeader";
import { RiskSidebar } from "@modules/risks/components/core/RiskSidebar";

import { RiskTabList } from "@modules/risks/containers/tabs/RiskTabList";
import { RiskEditPanel } from "@modules/risks/containers/panels/RiskEditPanel";
import { RiskReviewPanel } from "@modules/risks/containers/panels/RiskReviewPanel";
import { RiskAssignPanel } from "@modules/risks/containers/panels/RiskAssignPanel";
import { RiskTransferPanel } from "@modules/risks/containers/panels/RiskTransferPanel";

export const ExceptionViewerPage = withRouter(
    observer(({ match }) => {
        const { riskStore } = useStores();

        const { exceptionViewerStore, documentStore, lifecycleStore, reviewStore, transferStore, stateFlowStore } =
            riskStore;
        const { loading, error, breadcrumb, risk, lifecycle, selectedTab } = exceptionViewerStore;
        const { manifest, subject, triggers, conditions, state, subState } = stateFlowStore;

        useEffect(() => {
            if (match.params.id) {
                riskStore.exceptionViewerStore.loadRisk(match.params.id);
            }
        }, [match.params.id]);

        useEffect(() => {
            riskStore.exceptionViewerStore.selectTab(match.params.tab);
        }, [match.params.id, match.params.tab]);

        return (
            <AppPage
                error={error}
                breadcrumb={breadcrumb}
                loading={loading || documentStore.saving}
                hasSidebar={true}
                className="has--sidebar-m"
            >
                {risk && risk.id == match.params.id && lifecycle && (
                    <>
                        <Dropzone
                            onDrop={(acceptedFiles) => riskStore.documentStore.onUpload(acceptedFiles)}
                            disabled={!riskStore.documentStore.canUpload}
                            multiple={true}
                            noClick={true}
                        >
                            {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, open }) => (
                                <div
                                    {...getRootProps()}
                                    className={cx(
                                        "dropzone",
                                        isDragActive && "active",
                                        isDragAccept && "accept",
                                        isDragReject && "reject"
                                    )}
                                >
                                    <input {...getInputProps()} />

                                    <RiskHeader
                                        risk={risk}
                                        lifecycle={lifecycle}
                                        triggers={triggers}
                                        conditions={conditions}
                                        onTrigger={(trigger, options) => stateFlowStore.onTrigger(trigger, options)}
                                        onSubmit={() => lifecycleStore.onSubmit()}
                                        onMonitor={() => reviewStore.onMonitor()}
                                        onClose={() => lifecycleStore.onClose()}
                                        onTransfer={() => transferStore.onTransfer()}
                                        onDelete={() => lifecycleStore.onDelete()}
                                        onShare={() => lifecycleStore.onShare()}
                                        onReview={() => reviewStore.onReview()}
                                        canUpload={() => documentStore.canUpload}
                                        onUpload={open}
                                    />

                                    {/*
                                    <RiskStatusProgress risk={risk} lifecycle={lifecycle} />
                                     */}

                                    <StateFlowProgressBar
                                        manifest={manifest}
                                        subject={subject}
                                        state={state}
                                        subState={subState}
                                    />

                                    <RiskTabList
                                        risk={risk}
                                        lifecycle={lifecycle}
                                        selectedTab={selectedTab}
                                        onUpload={open}
                                    />

                                    <RiskSidebar
                                        risk={risk}
                                        lifecycle={lifecycle}
                                        canSave={lifecycleStore.canAddComment}
                                    />

                                    <RiskEditPanel saving={lifecycleStore.saving} store={riskStore.editFormStore} />

                                    <RiskReviewPanel saving={lifecycleStore.saving} store={riskStore.reviewFormStore} />

                                    <RiskTransferPanel
                                        saving={lifecycleStore.saving}
                                        store={riskStore.transferFormStore}
                                    />

                                    <RiskAssignPanel saving={lifecycleStore.saving} store={riskStore.assignFormStore} />
                                </div>
                            )}
                        </Dropzone>
                    </>
                )}
            </AppPage>
        );
    })
);
