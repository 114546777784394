import { observable, flow } from "mobx";

import { PaginationStore } from "../../base/PaginationStore";
import { RiskService } from "../../../api/risks";
import { RiskStore } from "./RiskStore";

export class RiskHeatmapStore {
    public parentStore: RiskStore;
    public riskService: RiskService;

    @observable public grossRisks: any[] = [];
    @observable public netRisks: any[] = [];
    @observable public statusList: any[] = [];
    @observable public portfolios: any[] = [];
    @observable public assignedToObjects: any[] = [];
    @observable public pagination: PaginationStore;
    @observable public keywords: string;
    @observable public query: any = {};
    @observable public loading: boolean = false;
    @observable public error: any;
    @observable public breadcrumb: any[] = [
        {
            text: "Risk Management",
            key: "risks",
            onClick: () => this._goToUrl(`/risks/dashboard`),
        },
        { text: "Risk Heatmap", key: "heatmap", isCurrentItem: true },
    ];

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
        this.pagination = new PaginationStore();
    }

    public loadSubStatuses = flow(function* () {
        try {
            this.statusList = yield this.parentStore.riskFilterDataStore.loadSubStatuses(false);
        } catch (e) {
            console.error(e);
            this.error = e;
            this.statusList = [];
        }
    });

    public loadPortfolios = flow(function* () {
        try {
            this.portfolios = yield this.parentStore.riskFilterDataStore.loadPortfolios();
        } catch (e) {
            console.error(e);
            this.error = e;
            this.portfolios = [];
        }
    });

    loadAssignedUser = flow(function* (ids: any = null) {
        try {
            const principals = ids ? ids : [];
            if (principals.length > 0) {
                this.assignedToObjects = yield this.parentStore.rootStore.pickerStore.loadPrincipals(principals.map((U) => U.id));
            } else {
                this.assignedToObjects = [];
            }
        } catch (e) {
            this.assignedTo = [];
            console.error(e);
        }
    });

    public loadRisks = flow(function* (query: any) {
        this.loading = true;
        const options = Object.assign({ page: 1, keywords: null }, query);

        this.query = {
            ...options.query,
        };

        try {
            const grossResult = yield this.riskService.getRisksGroupByCount({
                groupBy: "grossScoreCalculated",
                query: {
                    ...this.query,
                    ...{ type: { template: ["zerodai:risks:generic"] } },
                },
            });

            this.grossRisks = grossResult.items.map((i) => {
                const parts = i.id.split("-");
                return {
                    text: `${i.count > 99 ? "99+" : i.count}`,
                    score: {
                        impact: parseInt(parts[1]),
                        likelihood: parseInt(parts[2]),
                    },
                };
            });

            const netResult = yield this.riskService.getRisksGroupByCount({
                groupBy: "netScoreCalculated",
                query: {
                    ...this.query,
                    ...{ type: { template: ["zerodai:risks:generic"] } },
                },
            });

            this.netRisks = netResult.items.map((i) => {
                const parts = i.id.split("-");
                return {
                    text: `${i.count > 99 ? "99+" : i.count}`,
                    score: {
                        impact: parseInt(parts[1]),
                        likelihood: parseInt(parts[2]),
                    },
                };
            });

            const { type, ...rest } = grossResult.query;
            this.query = rest;
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.loading = false;
        }
    });

    _goToUrl = (url) => {
        if (url) {
            this.parentStore.rootStore.routing.push(url);
        }
    };
}
