import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { SearchBox } from '@ui/elements/SearchBox';
import { CommandBar } from '@ui/elements/CommandBar';

import { BoxPanel } from '@baseComponents/BoxPanel';

export const ControlMapCommandBar = observer(
    class ControlMapCommandBar extends Component {
        
        render () {
            const { className, keywords, onSearchChange, onAddMap } = this.props;

            const items = [{
                key: 'addMap',
                text: 'Add Map',
                ariaLabel: 'Add Map',
                iconProps: { iconName: 'Add' },
                onClick: () => onAddMap()
            }];

            const farItems = [
                {
                    key: 'search',
                    text: 'Search',
                    onRender: () => {
                        return (
                        <SearchBox
                            value={keywords}
                            placeholder="Search by name"
                            styles={{ root: { height: 'auto', minWidth: '270px' }}}
                            //onEscape={() => this._onUpdate(null)}
                            onClear={() => onSearchChange(null)}
                            onSearch={onSearchChange} />
                        );
                    }
                }
            ];

            return (
                <BoxPanel className={className} shadow>
                    <CommandBar
                        styles={{ root: { paddingRight: '0px' } }}
                        items={items}
                        farItems={farItems}
                    />
                </BoxPanel>
            );
        }
    }
);