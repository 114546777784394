import React, { Component, Fragment } from 'react';
import { cx, css } from 'emotion'
import { FontWeights } from '@uifabric/styling';
import { Icon, Stack, Text } from 'office-ui-fabric-react';

const headerClassName = cx(
    'ms-Stack',
        css`
        border-bottom: 1px solid var(--border-color);
        margin-bottom: 15px;
        `
);

const titleClassName = cx(
    css`
        font-size:14px;
        font-weight:600;
    `
);

export class ColumnHeader extends Component {
    render () {
        const { title, label } = this.props;
        return (
            <>
                <Stack horizontal tokens={{childrenGap: 40, padding: 10}} className={headerClassName}>
                    <Stack.Item align="start" grow={1}>
                        <Text variant="medium" block className={titleClassName}>{title}</Text>
                    </Stack.Item>
                    {/* <Stack.Item align="end">
                        <Text variant="medium" block>{label}</Text>
                    </Stack.Item> */}
                </Stack>
            </>
        );
    }
};