import React, { Component, Fragment } from "react";
import { cx, css } from "emotion";

import { Stack } from "office-ui-fabric-react";
import { TooltipHost, TooltipDelay, DirectionalHint } from "office-ui-fabric-react/lib/Tooltip";
import { Callout } from "office-ui-fabric-react/lib/Callout";
import { Text } from "office-ui-fabric-react/lib/Text";
import { MessageBar } from "office-ui-fabric-react/lib/MessageBar";
import { PersonaSize } from "office-ui-fabric-react/lib/Persona";
import { DetailsListLayoutMode, SelectionMode, ConstrainMode } from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { ContextualMenuItemType } from "office-ui-fabric-react/lib/ContextualMenu";
import { DefaultButton } from "office-ui-fabric-react/lib/Button";
import { Label } from "office-ui-fabric-react/lib/Label";
import { UsersContextualMenu } from "./UsersContextualMenu";
import { mergeStyleSets } from "office-ui-fabric-react/lib/Styling";
import { ObservablePagination } from "../../../base/components/ObservablePagination";
import { BoxPanel } from "../../../base/components/BoxPanel";
import { PhotoPersona } from "../../containers/utilities/PhotoPersona";

const groupsListClassName = cx(
    "wrapper",
    css`
        cursor: pointer;
    `
);
const calloutTargetClassName = cx(
    "calloutTarget",
    css`
        cursor: pointer;
    `
);
const calloutClassName = cx(
    "",
    css`
        max-width: 300px;
        .inner {
            height: 100%;
            padding: 15px 24px 15px;
        }
    `
);

export const UserGridView = class UserGridView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showContextualMenu: null,
            showCallouts: [],
        };
    }

    get _columns() {
        const { groups, onEditUserClick } = this.props;
        return [
            {
                key: "name",
                name: "Name",
                fieldName: "name",
                minWidth: 210,
                maxWidth: 250,
                data: "string",
                onRender: (item) => {
                    return (
                        <div
                            className={cx(
                                "workitem-summary",
                                "grid-view-hover-button",
                                css`
                                    display: flex;
                                    height: 30px;
                                `
                            )}
                        >
                            <a role="button" onClick={() => this.props.onEditUserClick(item.id)}>
                                {/* {item.name} */}
                                <PhotoPersona
                                    size={PersonaSize.size28} 
                                    principalId={item.id} 
                                    text={item.name} 
                                     />
                            </a>
                            {this.state.showContextualMenu === item.id ? (
                                <UsersContextualMenu userId={item.id} assignedGroups={item.assignedGroups} groups={groups} onEditUserClick={onEditUserClick} />
                            ) : null}
                        </div>
                    );
                },
                isPadded: true,
            },
            /* {
            key: 'groups',
            name: 'Groups',
            fieldName: 'groups',
            minWidth: 140,
            maxWidth: 200,
            data: 'string',
            onRender: (item) => {
              if (item.groups.length > 1) {
                const menuProps = {
                  items: item.groups.map(G => {
                  return  {
                    key: G.name,
                    text: G.name,
                  }
                })
              };
                return (
                  <Stack horizontal verticalAlign="middle" horizontalAlign="space-between" tokens={{padding: 5}}>
                    <TooltipHost
                      tooltipProps={{
                        onRenderContent: () => (
                          <ul style={{ margin: 10, padding: 0 }}>
                            {item.groups.map((g,i) => <li key={`i-${i}`}>{g.name}</li>)}
                          </ul>
                        )
                      }}
                      // calloutProps={{ hidden: index == -1 || !!hideTooltip }}
                      delay={TooltipDelay.medium}
                      directionalHint={DirectionalHint.bottomLeftEdge}>
                      <div className={groupsListClassName}>
                        Member of {item.groups.length} groups
                      </div>
                    </TooltipHost>
                  </Stack>
                );
              } else {
                return (
                  <Stack horizontal verticalAlign="middle" horizontalAlign="space-between" tokens={{padding: 5}}>
                    {item.groups.length > 0 ? item.groups[0].name : 'None'}
                  </Stack>
                );
              }
            },
          }, */
            {
                key: "jobTitle",
                name: "Job Title",
                fieldName: "jobTitle",
                minWidth: 140,
                maxWidth: 200,
                data: "string",
                onRender: (item) => {
                    return (
                        <Stack horizontal verticalAlign="middle" horizontalAlign="space-between" tokens={{ padding: 5 }}>
                            {item.jobTitle}
                        </Stack>
                    );
                },
            },
            {
                key: "email",
                name: "Email",
                fieldName: "email",
                minWidth: 210,
                maxWidth: 250,
                data: "string",
                onRender: (item) => {
                    return (
                        <Stack horizontal verticalAlign="middle" horizontalAlign="space-between" tokens={{ padding: 5 }}>
                            {item.email}
                        </Stack>
                    );
                },
            },
            {
                key: "roles",
                name: "Roles",
                fieldName: "roles",
                minWidth: 120,
                data: "string",
                onRender: (item) => {
                    return (
                        <Stack horizontal verticalAlign="middle" horizontalAlign="space-between" tokens={{ padding: 5 }}>
                            {item.roles.length > 1 ? (
                                <div>
                                    <span
                                        className={[calloutTargetClassName, `calloutTarget${item.id}`].join(" ")}
                                        onClick={() => {
                                            const co = this.state.showCallouts;
                                            co.forEach((element, index, theWholeArray) => {
                                                element = false;
                                            });
                                            co[item.id] = true;
                                            this.setState({
                                                showCallouts: co,
                                            });
                                        }}
                                    >
                                        {" "}
                                        {item.roles[0]} + {item.roles.length - 1}
                                    </span>
                                    {this.state.showCallouts[item.id] && (
                                        <Callout
                                            className={calloutClassName}
                                            role="alertdialog"
                                            gapSpace={0}
                                            target={`.calloutTarget${item.id}`}
                                            onDismiss={() => {
                                                const co = this.state.showCallouts;
                                                co[item.id] = false;
                                                this.setState({
                                                    showCallouts: co,
                                                });
                                            }}
                                            setInitialFocus
                                        >
                                            <div className="inner">
                                                {item.roles.map((role, i) => (
                                                    <div key={`role${i}`}>
                                                        <Text>{role}</Text>
                                                    </div>
                                                ))}
                                            </div>
                                        </Callout>
                                    )}
                                </div>
                            ) : (
                                <>{item.roles.join(",")}</>
                            )}
                        </Stack>
                    );
                },
            },
        ];
    }

    render() {
        const { onPageChange, keywords, users, loading, pagination } = this.props;
        return (
            <>
                <BoxPanel shadow>
                    {users && (
                        <ShimmeredDetailsList
                            items={users}
                            compact={false}
                            columns={this._columns}
                            selectionMode={SelectionMode.none}
                            getKey={(item, index) => (item ? item.id : index)}
                            setKey="single"
                            layoutMode={DetailsListLayoutMode.justified}
                            constrainMode={ConstrainMode.horizontalConstrained}
                            isHeaderVisible={true}
                            onRenderRow={(props, defaultRender) => (
                                <div
                                    onMouseEnter={() => {
                                        this.setState({ showContextualMenu: props.item.id });
                                    }}
                                    onMouseLeave={() => {
                                        this.setState({ showContextualMenu: null });
                                    }}
                                >
                                    {defaultRender(props)}
                                </div>
                            )}
                            enterModalSelectionOnTouch={true}
                            ariaLabelForSelectionColumn="Toggle selection"
                            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                            checkButtonAriaLabel="Row checkbox"
                            enableShimmer={loading}
                            removeFadingOverlay={true}
                            shimmerLines={25}
                            ariaLabelForShimmer="Users being fetched"
                            ariaLabelForGrid="User list"
                        />
                    )}
                    {!users && !loading && <MessageBar>There are no matching users.</MessageBar>}
                </BoxPanel>
                <ObservablePagination {...pagination} onPageChange={(index) => onPageChange(index, keywords)} />
            </>
        );
    }
};
