import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { ComboBox } from 'office-ui-fabric-react/lib/ComboBox';

import { PrincipalPicker } from '../../identity/containers/PrincipalPicker';

export const PermissionShareForm = observer(
    class PermissionShareForm extends Component {
        
        render () {
            const { formData, formOptions } = this.props;
            return (
                <div>
                    <r-grid columns-s="1" columns="6">
                        <r-cell span="6">
                            <div>
                                <PrincipalPicker 
                                    label="Select users"
                                    required={true}
                                    selected={formData.principals}
                                    multiple
                                    onSelected={(principals) => {
                                        formData.principals = principals;
                                    }} />
                            </div>
                        </r-cell>
                        <r-cell span="6">
                            <div>
                                <ComboBox
                                    label="Select roles"
                                    required={true}
                                    multiSelect={true}
                                    selectedKey={formData.roles}
                                    useComboBoxAsMenuWidth={true}
                                    autoComplete="on"
                                    options={formOptions.roles}
                                    onChange={(_, option) => {
                                        if(option.selected) {
                                            formData.roles = [...(formData.roles || []), option.key];
                                        } else {
                                            formData.roles = formData.roles.filter(key => key !== option.key);
                                        }
                                    }} />
                            </div>
                        </r-cell>
                        <r-cell span="6">
                            <div>
                                <TextField 
                                    label="Add a message (optional)"
                                    multiline autoAdjustHeight
                                    rows={7}
                                    value={formData.message}
                                    onChange={(_, val) => {
                                        formData.message = val;
                                    }} />
                            </div>
                        </r-cell>
                    </r-grid>
                </div>
            );
        }
    }
);