import React from "react";
import Moment from "react-moment";

import { Statistic } from "@modules/base/components/Statistic";

export const StateFlowSummary = ({ summary, stateFlow }) => {
    let targetPlural;
    let targetIconName;
    let targetSourcePlural;
    switch (stateFlow.target) {
        case "WorkItem":
            targetPlural = "demands";
            targetIconName = "ExternalTFVC";
            targetSourcePlural = "Products";
            break;
        case "RiskLifecycle":
            targetPlural = "risks";
            targetIconName = "ReportHacked";
            targetSourcePlural = "Risk types";
            break;
    }

    return summary ? (
        <r-grid columns="2" columns-l="4">
            <r-span span="1">
                <Statistic className="card card-s" title={`Usage in ${targetPlural} (all versions)`} value={summary.targetCount} iconName={targetIconName} />
            </r-span>
            <r-span span="1">
                <Statistic
                    className="card card-s"
                    title={`Usage in ${targetPlural} (v${stateFlow.publishedVersion || stateFlow.currentVersion} only)`}
                    value={summary.versionCount}
                    iconName={targetIconName}
                />
            </r-span>
            <r-span span="1">
                <Statistic
                    className="card card-s"
                    title={`${targetSourcePlural} tagged with this workflow`}
                    value={summary.productCount}
                    iconName="ProductCatalog"
                />
            </r-span>
            <r-span span="1">
                <Statistic className="card card-s" title={`Last used in ${targetPlural}`}>
                    {summary.lastUsed ? <Moment utc date={summary.lastUsed} fromNow /> : "Never"}
                </Statistic>
            </r-span>
        </r-grid>
    ) : null;
};
