import React, { Component } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { TooltipHost, TooltipDelay, DirectionalHint } from "office-ui-fabric-react/lib/Tooltip";
import { Label } from "office-ui-fabric-react/lib/Label";

import { ModuleNames, getModuleConfig } from "../../../../configProvider";

const rootClassName = cx(
    "cygraph-RiskScore-root",
    css`
        & .wrapper {
            display: flex;
            flex-direction: row;
        }

        & .text {
            margin-left: 3px;
            color: var(--text-color-dark);
        }

        & .score {
            width: 20px;
            height: 20px;
            background: var(--grey-2);
            margin-right: 2px;

            &.mini {
                width: 10px;
                margin-top: 4px;
                height: 10px;
            }

            &.medium {
                width: 14px;
                margin-top: 2px;
                height: 14px;
            }

            &.Minor {
                background: var(--green-6);
            }
            &.Moderate {
                background: var(--yellow-6);
            }
            &.Major {
                background: var(--orange-6);
            }
            &.Critical {
                background: var(--red-6);
            }
        }
    `
);

const scores = ["Minor", "Moderate", "Major", "Critical"];

export const RiskScore = observer(
    class RiskScore extends Component {
        render() {
            const { hideTooltip, score, size } = this.props;
            const index = scores.indexOf(score && score.score ? score.score : "None");
            const riskModule = getModuleConfig(ModuleNames.Risks);
            const maxImpact = riskModule.maxImpact || 4;
            const maxLikelihood = riskModule.maxLikelihood || 4;

            const tooltipProps = {
                onRenderContent: () =>
                    score && (
                        <div>
                            <div>
                                <Label>Likelihood</Label> {score.likelihood} out of {maxLikelihood}
                            </div>
                            <div>
                                <Label>Impact</Label> {score.impact} out of {maxImpact}
                            </div>
                        </div>
                    ),
            };

            return (
                <div className={rootClassName}>
                    <TooltipHost
                        tooltipProps={tooltipProps}
                        calloutProps={{ hidden: index == -1 || !!hideTooltip }}
                        delay={TooltipDelay.medium}
                        directionalHint={DirectionalHint.bottomLeftEdge}
                    >
                        <div className="wrapper">
                            {scores.map((s, i) => (
                                <div key={i} className={cx("score", size, i <= index && score.score)}></div>
                            ))}
                            <div className="text">{score && score.score ? score.score : "None"}</div>
                        </div>
                    </TooltipHost>
                </div>
            );
        }
    }
);
