import React from "react";

import { TableRow } from "./TableRow";
import { getDefaultBorderIncludes } from "./Utils";

export const TableHeader = (props) => {
    let { includeLeftBorder, includeBottomBorder, includeRightBorder, includeTopBorder } = getDefaultBorderIncludes(props);

    const rowCells = React.Children.toArray(props.children);

    return (
        <TableRow
            {...props}
            key={"header"}
            includeLeftBorder={includeLeftBorder}
            includeBottomBorder={includeBottomBorder}
            includeRightBorder={includeRightBorder}
            includeTopBorder={includeTopBorder}
        >
            {rowCells.map((rc, columnIndex) =>
                React.cloneElement(rc, {
                    key: columnIndex,
                    isHeader: true,
                    fontSize: props.fontSize,
                    textAlign: props.textAlign,
                    includeLeftBorder: columnIndex === 0,
                    includeRightBorder: columnIndex !== rowCells.length - 1,
                })
            )}
        </TableRow>
    );
};
