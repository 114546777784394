import React from "react";
import { observer } from "mobx-react";

import { Toggle } from "office-ui-fabric-react/lib/Toggle";
import { BigTextField } from "../../../base/components/BigTextField";

export const ModuleEditForm = observer(({ formData }) => {
    return (
        <div>
            <r-grid columns="1">
                <r-span>
                    <BigTextField
                        label="Name"
                        required
                        placeholder="Please enter a name for the module"
                        transparent
                        value={formData.title}
                        onChange={(ev, val) => {
                            formData.title = val;
                        }}
                    />
                </r-span>
                <r-span>
                    <Toggle
                        label="Enabled"
                        checked={formData.condition === "true"}
                        onChange={(ev, checked) => {
                            formData.condition = checked ? "true" : "false";
                        }}
                    />
                </r-span>
            </r-grid>
        </div>
    );
});
