import { Component } from 'react';
import { observer } from 'mobx-react';

export const WorkItemFieldValue = observer(
    class WorkItemFieldValue extends Component {

        render () {
            const { field, visible } = this.props;

            if(!visible) {
                return null;
            }

            switch(field.type) {
                case 'String': return this._renderStringField();
                case 'Boolean': return this._renderBooleanField();
            }

            return null;
        }

        _renderStringField() {
            const { field, workItem } = this.props;
            const value = workItem[field.name];

            if(field.options && field.options.length) {
                let options = field.options;
                const found = options.find(o => o.key == value);
                return found ? found.text : value;
            }

            return value;
        }

        _renderBooleanField() {
            const { field, workItem } = this.props;
            const value = workItem[field.name];

            return value ? field.onText || 'Yes' : field.offText || 'No';
        }
    }
);