import { observable, flow, action, computed } from "mobx";

import { PaginationStore } from "../../base/PaginationStore";
import { AssetService } from "../../../api/assets";
import { AssetStore } from "./AssetStore";

export class AssetBrowseStore {
    private parentStore: AssetStore;
    private assetService: AssetService;

    @observable public loading: boolean = false;
    @observable public assets: any[] = [];
    @observable public query: any = {};
    @observable public pagination: PaginationStore;
    @observable public error: any;

    constructor(parentStore: AssetStore) {
        this.parentStore = parentStore;
        this.assetService = parentStore.assetService;
        this.pagination = new PaginationStore();
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [];

        builder.push({
            text: "Asset Management",
            key: "assets",
            onClick: () => this.parentStore.rootStore.routing.push(`/assets/dashboard`),
        });
        builder.push({ text: "Browse Assets", key: "assets-browse", isCurrentItem: true });

        return builder;
    }

    public loadAssets = flow(function* (options: any) {
        const o = Object.assign({ page: 1, query: {} }, options);
        this.query = o.query;
        this.loading = true;
        this.error = null;

        try {
            const result = yield this.assetService.searchAssets({
                pageSize: this.pagination.itemsPerPage,
                startIndex: (options.page - 1) * this.pagination.itemsPerPage,
                query: o.query,
            });
            this.assets = result.items;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
            this.query = result.query;
            return result;
        } catch (e) {
            this.error = e;
            console.error(e);
            return e;
        } finally {
            this.loading = false;
        }
    });

    public toggleWatchlist = flow(function* (item: any, add: boolean) {
        try {
            item.inWatchlist = add;
            if (add) {
                yield this.assetService.addToAssetWatchlist({ assetId: item.id });
            } else {
                yield this.assetService.removeFromAssetWatchlist({ assetId: item.id });
            }
        } catch {
            item.inWatchlist = !add;
        }
        this.assets = [...this.assets];
    });

    @action
    public replace(asset) {
        const index = this.assets.findIndex((p) => p.id === asset.id);

        if (index !== -1) {
            this.assets = [...this.assets.splice(index, 1, asset)];
        }
    }

    @action
    public clear() {
        this.loading = false;
        this.assets = [];
        this.query = {};
        this.pagination = new PaginationStore();
        this.error = null;
    }
}
