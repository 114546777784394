import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";

import { Stack } from "office-ui-fabric-react/lib/Stack";
import { PanelType } from "office-ui-fabric-react/lib/Panel";
import { PrimaryButton, ActionButton } from "office-ui-fabric-react/lib/Button";

import { ObservablePanel } from "../../../base/components/ObservablePanel";
import { RiskAssignForm } from "../../components/core/RiskAssignForm";

export const RiskAssignPanel = inject("routing")(
    withRouter(
        observer(
            class RiskAssignPanel extends Component {
                render() {
                    const { visible, formData, formOptions, isValid } = this.props.store;

                    return (
                        <ObservablePanel
                            hidden={!visible}
                            pageType="Assign Group &amp; User"
                            pageTitle={formOptions ? formOptions.pageTitle : "Risk"}
                            panelType={PanelType.smallFixedFar}
                            onClosePanel={() => this.props.store.resolve({ dismiss: false })}
                            onRenderFooter={() => this._renderFooter()}
                            onLightDismissClick={() => this.props.store.resolve({ dismiss: true })}
                        >
                            {formData && <RiskAssignForm formData={formData} formOptions={formOptions} isValid={isValid} />}
                        </ObservablePanel>
                    );
                }

                _renderFooter() {
                    const { isValid, formData } = this.props.store;

                    return (
                        <Stack horizontal horizontalAlign="space-between">
                            <Stack horizontal tokens={{ childrenGap: 15 }}></Stack>
                            <Stack horizontal tokens={{ childrenGap: 15 }}>
                                <ActionButton
                                    styles={{ root: { height: "32px" } }}
                                    onClick={() => this.props.store.resolve({ dismiss: true })}
                                    text="Cancel"
                                    disabled={this.props.saving}
                                />
                                <PrimaryButton
                                    onClick={() => this.props.store.resolve({ formData })}
                                    iconProps={{ iconName: "AddFriend" }}
                                    text="Assign"
                                    disabled={!isValid || this.props.saving}
                                />
                            </Stack>
                        </Stack>
                    );
                }
            }
        )
    )
);
