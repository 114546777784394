import React from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";
import { useStores } from "@hooks/useStores";

import { ModuleNames, isModuleEnabled } from "../../../configProvider";
import { isUserInAllRoles } from "@modules/identity/stores/PrincipalContextUtilities";

import { BoxPanel } from "@modules/base/components/BoxPanel";

import { QuickLinkItemView } from "@modules/home/components/QuickLinkItemView";

const rootClassName = cx(
    "cygraph-QuickLinkListView-root",
    css`
        & .quickLink {
            min-height: 200px;
            height: 100%;
            padding: 25px;
        }
        & > r-grid {
            grid-column-gap: 0;
            grid-row-gap: 0;
            margin-bottom: 0;
        }
        & > r-grid > r-cell {
            cursor: pointer;
            border-bottom: 1px solid var(--border-color);
        }
        & > r-grid > r-cell:nth-child(odd) {
            border-right: 1px solid var(--border-color);
        }
        & > r-grid > r-cell.lastRow {
            border-bottom: 0;
        }
        & > r-grid > r-cell:hover {
            box-shadow: rgb(0 0 0 / 11%) 0px 3.2px 15px 0px, rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px;
        }
    `
);

export const QuickLinkListView = observer(({ className }) => {
    const { principalContext, routing } = useStores();
    const user = principalContext.current;

    const showRisks = isModuleEnabled(ModuleNames.Risks) && isUserInAllRoles(user, ["admin", "agent"]);
    const isAgent = isUserInAllRoles(user, ["agent"]);
    const quickLinks = buildQuickLinks(isAgent, showRisks);
    const oddLength = quickLinks.length % 2 === 1;

    return (
        <BoxPanel className={cx(rootClassName, className)} shadow>
            <r-grid columns="12" columns-s="6">
                {quickLinks.map((ql, i) => (
                    <r-cell
                        span="6"
                        key={i}
                        class={
                            i === quickLinks.length - 1 || (!oddLength && i === quickLinks.length - 2) ? "lastRow" : ""
                        }
                        onClick={() => {
                            if (ql.url) {
                                routing.push(ql.url);
                            }
                        }}
                    >
                        <QuickLinkItemView
                            entityType={ql.entityType}
                            title={ql.title}
                            description={ql.description}
                            url={ql.url}
                        />
                    </r-cell>
                ))}
            </r-grid>
        </BoxPanel>
    );
});

const buildQuickLinks = (isAgent, showRisks) => {
    const quickLinks = [
        {
            entityType: "work-item",
            title: "Request a Service",
            url: "/demand/catalogue",
            description: "Browse the list of services offered by the Cyber Security team and get help.",
        },
        {
            entityType: "assessment",
            title: "New Assurance Assessment",
            url: "/assurance/triage",
            description:
                "Register your project with the assurance team and get help to make sure your project is secure and successful.",
        },
    ];

    if (showRisks) {
        quickLinks.push({
            entityType: "risk",
            title: "New Risk",
            url: "/risks/new",
            description: "Raise a risk against projects, assets or vendors which doesn't align to our standards.",
        });
    }
    quickLinks.push({
        entityType: "exception",
        title: "New Exception",
        url: "/exceptions/new",
        description: "Request an exception if a part of your project isn't aligned to our standards.",
    });

    quickLinks.push({
        entityType: "help",
        title: "Help & FAQs",
        url: "/help",
        description:
            "Learn more about the Zerodai platform and get help using it through instructional videos and user guides.",
    });

    return quickLinks;
};
