import React, { useState } from "react";
import { observer } from "mobx-react";

import { Dialog, DialogType, DialogFooter } from "office-ui-fabric-react/lib/Dialog";
import { PrimaryButton, ActionButton } from "office-ui-fabric-react/lib/Button";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { ChoiceGroup } from "office-ui-fabric-react/lib/ChoiceGroup";

export const StateFlowPublishDialog = observer(({ visible, title, subText, initialMode, onSave, onCancel }) => {
    const [mode, setMode] = useState(initialMode || "Minor");
    const [notes, setNotes] = useState(null);

    const dialogContentProps = {
        type: DialogType.normal,
        title: title || "Publish Workflow",
        closeButtonAriaLabel: "Close",
        subText:
            subText ||
            "Are you sure you want to publish the current workflow? A major or minor version update will enable the updated workflow to be used in future instances only.",
    };

    return (
        <Dialog hidden={!visible} onDismiss={() => onCancel()} dialogContentProps={dialogContentProps}>
            <div className="row--s"></div>
            <div className="row--s">
                <ChoiceGroup
                    required
                    options={[
                        { key: "Major", text: "Publish as major version" },
                        { key: "Minor", text: "Publish as minor version" },
                        { key: "Build", text: "Save without publishing" },
                    ]}
                    selectedKey={mode}
                    onChange={(ev, option) => {
                        setMode(option.key);
                    }}
                />
            </div>
            <div className="row--l">
                <TextField
                    label="Notes"
                    value={notes}
                    onChange={(ev, val) => setNotes(val)}
                    multiline
                    autoAdjustHeight
                />
            </div>
            <DialogFooter>
                <ActionButton styles={{ root: { height: "32px" } }} onClick={() => onCancel()} text="Cancel" />
                <PrimaryButton onClick={() => onSave({ mode, notes })} text="Publish" />
            </DialogFooter>
        </Dialog>
    );
});
