import { observable, action, computed, flow } from "mobx";
import { PaginationStore } from "../../base/PaginationStore";
import { AssessmentService } from "../../../api/assessments";
import { AssessmentStore } from "./AssessmentStore";

export type AvisoryGridView = "all" | "open" | "compliant" | "nonCompliant";

export class AssessmentAdvisoryStore {
    private assessmentService: AssessmentService;
    public parentStore: AssessmentStore;

    @observable public levels: any[] = [
        {
            key: "critical",
            text: "Critical",
        },
        {
            key: "high",
            text: "High",
        },
        {
            key: "medium",
            text: "Medium",
        },
        {
            key: "low",
            text: "Low",
        },
        {
            key: "note",
            text: "Note",
        },
    ];
    @observable public initialCompliantStatus: any[] = [
        {
            key: "Compliant",
            text: "Compliant",
        },
        {
            key: "NonCompliant",
            text: "Non Compliant",
        },
        {
            key: "Pending",
            text: "Pending",
        },
    ];
    @observable public finalCompliantStatus: any[] = [
        {
            key: "Compliant",
            text: "Compliant",
        },
        {
            key: "NonCompliant",
            text: "Non Compliant",
        },
        {
            key: "Pending",
            text: "Pending",
        },
    ];
    @observable public portfolios: any[] = [];
    @observable public displayFilterBar: boolean = true;
    @observable public visible: boolean = false;
    @observable public pagination: PaginationStore;
    @observable public advisories: any[] = [];
    @observable public riskMappings: any[] = [];
    @observable public evidenceMappings: any[] = [];
    @observable public selectedAdvisories: any;
    @observable public selectedAdvisory: any;
    @observable public gridView: AvisoryGridView = "open";
    @observable public searchKeywords: string;
    @observable public formData: any;
    @observable public loading: boolean = false;
    @observable public loadingRiskMappings: boolean = false;
    @observable public loadingEvidenceMappings: boolean = false;
    @observable public saving: boolean = false;
    @observable public error: any;
    @observable public mandatoryEvidence: boolean = false;
    @observable public selectedPortfolio: any[];
    @observable public selectedAssessments: any[];
    @observable public selectedControls: any[];
    @observable public advisoryLevel: any[];
    @observable public finalComplianceLevel: any[];
    @observable public initialComplianceLevel: any[];
    @observable public controls: any[];
    @observable public assessments: any[];
    @observable public query;

    constructor(parentStore: AssessmentStore) {
        this.parentStore = parentStore;
        this.assessmentService = parentStore.assessmentService;
        this.pagination = new PaginationStore();
    }

    @computed
    public get isValid() {
        if (!this.formData || !this.formData.evidenceType) {
            return false;
        }

        switch (this.formData.evidenceType) {
            case "Note":
                return !!this.formData.notes;
            case "Document":
                return !!this.formData.file;
            case "WorkItem":
                return !!this.formData.workItemId && !!this.formData.linkTypeId;
            case "Link":
                return !!this.formData.link;
        }
    }

    @action
    public show(options) {
        this.visible = true;
        this.selectedAdvisory = options.advisory;
        this.formData = {};
    }

    @action
    public hide(options) {
        this.visible = false;
        this.selectedAdvisory = null;
        this.formData = null;
    }

    @action
    public setSelectedAdvisories(selection) {
        this.selectedAdvisories = selection;
    }

    @computed
    public get allRequiredRisksRaised() {
        if (this.loadingRiskMappings) {
            return false;
        }
        const nonCompliantIds = this.advisories
            .filter((advisory) => {
                return advisory.state == "Closed" && advisory.finalCompliance == "NonCompliant";
            })
            .map((advisory) => advisory.id);

        const withRisks = this.riskMappings.filter((riskMapping) => {
            return nonCompliantIds.indexOf(riskMapping.advisoryId) != -1 && riskMapping.risks.length > 0;
        });

        return nonCompliantIds.length == withRisks.length;
    }

    @computed
    public get requiredRisksRaisedCount() {
        if (this.loadingRiskMappings) {
            return 0;
        }

        const nonCompliantIds = this.advisories
            .filter((advisory) => {
                return advisory.state == "Closed" && advisory.finalCompliance == "NonCompliant";
            })
            .map((advisory) => advisory.id);

        const withRisks = this.riskMappings.filter((riskMapping) => {
            return nonCompliantIds.indexOf(riskMapping.advisoryId) != -1 && riskMapping.risks.length > 0;
        });

        return withRisks.length;
    }

    @computed
    public get allAdvisoriesClosed() {
        if (this.loading) {
            return false;
        }

        const notClosed = this.advisories.find((advisory) => {
            return advisory.state != "Closed";
        });

        return !notClosed;
    }

    @computed
    public get allRequiredEvidencesProvided() {
        if (!this.mandatoryEvidence) {
            return true;
        }

        if (this.loading || this.loadingEvidenceMappings) {
            return false;
        }

        const nonEvidence = this.advisories.filter((advisory) => {
            let summary = this.evidenceMappings.find((a) => a.advisoryId === advisory.id);
            return !summary || summary.count === 0;
        });

        return nonEvidence.length === 0;
    }

    @computed
    public get requiredEvidencesProvided() {
        if (!this.mandatoryEvidence) {
            return true;
        }

        if (this.loading || this.loadingEvidenceMappings || !this.selectedAdvisories) {
            return false;
        }

        const nonEvidence = this.selectedAdvisories.selected.filter((advisory) => {
            let summary = this.evidenceMappings.find((a) => a.advisoryId === advisory.id);
            return !summary || summary.count === 0;
        });

        return nonEvidence.length === 0;
    }

    @action
    public setGridView(view: AvisoryGridView) {
        this.gridView = view;
    }

    @action
    public setSearchKeywords(keywords: string) {
        this.searchKeywords = keywords;
    }

    @action
    public markAs(final) {
        const selection = this.selectedAdvisories;

        const advisories = selection.selected.map((a) => {
            return {
                finalCompliance: final,
                id: a.id,
                assessmentId: a.assessmentId,
            };
        });

        return this.closeAssessmentAdvisories(advisories).then(() => {
            selection.reset();
        });
    }

    loadPortfolios = flow(function* () {
        try {
            const retrievedPortfolios = yield this.parentStore.rootStore.portfolioStore.loadPortfolios("", 1, 250);
            this.portfolios = retrievedPortfolios.map((P) => {
                return {
                    key: P.id,
                    text: P.name,
                    data: P,
                };
            });
        } catch (e) {
            console.error(e);
        }
    });

    loadSelectedControls = flow(function* (selectedItems) {
        try {
            const controlsPortfolios = yield this.parentStore.rootStore.controlStore.selectionStore.advancedSearchControls({ id: selectedItems }, true);
            this.selectedControls = controlsPortfolios.map((P) => {
                return {
                    key: P.id,
                    id: P.id,
                    name: P.name,
                    data: P,
                    checked: true,
                };
            });
        } catch (e) {
            console.error(e);
        }
    });

    loadSelectedAssessments = flow(function* (selectedItems) {
        try {
            const retrievedAssessments = yield this.parentStore.rootStore.assessmentStore.browseStore.searchAssessments({ id: selectedItems }, {}, true);
            this.selectedAssessments = retrievedAssessments.map((P) => {
                return {
                    key: P.id,
                    id: P.id,
                    name: P.code,
                    data: P,
                    checked: true,
                };
            });
        } catch (e) {
            console.error(e);
        }
    });

    @action searchAdvisories(page: number = 1, queryString: string = "") {
        this.loading = true;
        this.advisories = [];
        let qsQuery = {};
        try {
            const qsObject = JSON.parse(queryString);
            const assessmentFilter = {
                portfolio:
                    qsObject.assessment && qsObject.assessment.portfolio && qsObject.assessment.portfolio.length > 0 ? qsObject.assessment.portfolio : null,
                id: qsObject.assessment && qsObject.assessment.id && qsObject.assessment.id.length > 0 ? qsObject.assessment.id : null,
            };
            qsQuery = {
                keywords: qsObject.keywords,
                assessment: assessmentFilter ? assessmentFilter : null,
                level: qsObject.level && qsObject.level.length > 0 ? qsObject.level.map((v) => v) : null,
                finalCompliances: qsObject.finalCompliances && qsObject.finalCompliances.length > 0 ? qsObject.finalCompliances.map((v) => v) : null,
                initialCompliances: qsObject.initialCompliances && qsObject.initialCompliances.length > 0 ? qsObject.initialCompliances.map((v) => v) : null,
                control:
                    qsObject.control && qsObject.control.length > 0
                        ? qsObject.control.map((v) => {
                              return { id: v.id };
                          })
                        : null,
            };
            this.query = qsQuery;
        } catch (e) {
            qsQuery = {};
        }
        const options = {
            pageSize: this.pagination.itemsPerPage,
            startIndex: (page - 1) * this.pagination.itemsPerPage,
            query: qsQuery,
        };
        return this.assessmentService.searchAdvisories(options).then(this.loadAdvisoriesOnSuccess, this.loadAssessmentAdvisoriesOnError);
    }

    @action
    public loadAssessmentAdvisories(id: string) {
        this.loading = true;
        this.advisories = [];
        return this.assessmentService.getAssessmentAdvisories(id).then(this.loadAssessmentAdvisoriesOnSuccess, this.loadAssessmentAdvisoriesOnError);
    }

    @action.bound
    public loadAdvisoriesOnSuccess(result) {
        this.advisories = result.items;
        this.query = result.query;
        this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
        this.loading = false;
        return result;
    }

    @action.bound
    public loadAssessmentAdvisoriesOnSuccess(result) {
        this.advisories = result;
        this.loading = false;
        return result;
    }

    @action.bound
    public loadAssessmentAdvisoriesOnError(error) {
        console.error(error);
        this.loading = false;
        throw error;
    }

    @action
    public closeAssessmentAdvisories(advisories) {
        this.saving = true;
        return this.assessmentService
            .closeAssessmentAdvisories(advisories[0].assessmentId, advisories)
            .then(this.closeAssessmentAdvisoriesOnSuccess, this.closeAssessmentAdvisoriesOnError);
    }

    @action.bound
    public closeAssessmentAdvisoriesOnSuccess(result) {
        result.forEach((advisory) => {
            const index = this.advisories.findIndex((a) => a.id == advisory.id);
            if (index != -1) {
                this.advisories.splice(index, 1, advisory);
            }
        });

        this.saving = false;
        return result;
    }

    @action.bound
    public closeAssessmentAdvisoriesOnError(error) {
        console.error(error);
        this.saving = false;
        throw error;
    }

    public loadAdvisoryRisks = flow(function* (id: string) {
        this.loadingRiskMappings = true;
        this.riskMappings = [];

        try {
            this.riskMappings = yield this.assessmentService.getAdvisoryRiskMapping(id);
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.loadingRiskMappings = false;
        }
    });

    public loadAdvisoryEvidences = flow(function* (id: string) {
        this.loadingEvidenceMappings = true;
        this.evidenceMappings = [];

        try {
            this.evidenceMappings = yield this.assessmentService.getAdvisoryEvidenceMapping(id);
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.loadingEvidenceMappings = false;
        }
    });

    public loadAssessmentData = flow(function* (id: string) {
        yield this.loadAdvisoryRisks(id);
        yield this.loadAdvisoryEvidences(id);
    });
}
