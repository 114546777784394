import { observable, flow, action } from "mobx";

import { AssetService } from '../../../api/assets';
import { AssetStore } from "./AssetStore";

export class AssetSelectionStore {

    public parentStore: AssetStore;
    public assetService: AssetService;

    @observable public asset: any = null;   
    @observable public loading: boolean = false;

    constructor(parentStore: AssetStore) {
        this.parentStore = parentStore;
        this.assetService = parentStore.assetService;
    }

    @action
    public setAsset(asset) {
        this.asset = asset
    }

    public loadAsset = flow(function*(id: string) {
        this.loading = true;

        if(!this.asset || this.asset.id !== id) {
            this.asset = null;
        }

        try {
            this.asset = yield this.assetService.getAsset(id);
        } finally {
            this.loading = false;
        }
    });
}
