import React, { useState, useCallback, useMemo } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { cx, css } from "emotion";
import debounce from "lodash.debounce";

import { SearchBox } from "@ui/elements/SearchBox";
import { Text } from "@ui/elements/Text";
import { Callout, DirectionalHint } from "@ui/elements/Callout";
import { Stack } from "@ui/elements/Stack";

import { ModuleNames, isModuleEnabled } from "../../../configProvider";
import { isUserInAllRoles } from "@modules/identity/stores/PrincipalContextUtilities";

import { CombinedSearchListView } from "@modules/home/components/CombinedSearchListView";

import { runCombinedSearch } from "./combinedSearch";

const rootClassName = cx(
    "cygraph-CombinedSearchField-root",
    css`
        height: auto;
        margin: auto;
        background: var(--nav-background-light);
        border: 1px solid var(--nav-background-light);

        & input {
            padding: 10px;
            background: var(--nav-background-light);
            color: #ffffff;
        }

        & input::-webkit-input-placeholder {
            color: var(--border-color-darker);
        }
        & input::-ms-input-placeholder {
            color: var(--border-color-darker);
        }
        & input::placeholder {
            color: var(--border-color-darker);
        }
    `
);

const calloutClassName = cx(
    "cygraph-CombinedSearchField-callout",
    css`
        background-color: var(--white);
        width: 100%;
        max-width: 600px;

        & .wrapper {
            padding: var(--spacing-s);
        }

        & .item {
            min-height: 40px;
            margin-bottom: var(--spacing-xs);

            & .description {
                color: var(--text-color-lightest);
            }
        }

        & .ms-Callout-main {
            min-height: 300px;
            scrollbar-color: var(--border-color-darker) transparent;
            scrollbar-width: thin;
        }
    `
);

export const CombinedSearchField = observer(({ className, tenantInfo, delay }) => {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [isCalloutVisible, { toggle: toggleCallout, setTrue: openCallout }] = useBoolean(false);
    const targetId = useId("calloutTarget");
    const labelId = useId("calloutLabel");
    const descriptionId = useId("calloutDescription");

    const { principalContext, workItemStore, riskStore, assessmentStore } = useStores();
    const user = principalContext.current;

    const showRisks = isModuleEnabled(ModuleNames.Risks) && isUserInAllRoles(user, ["admin", "agent"]);

    const onChange = useCallback(
        (ev, keywords) => {
            setLoading(true);
            openCallout(true);
            if (user) {
                runCombinedSearch({
                    showRisks,
                    user,
                    keywords,
                    workItemStore,
                    assessmentStore,
                    riskStore,
                }).then((items) => {
                    setItems(items);
                    setLoading(false);
                });
            }
        },
        [user]
    );

    const debouncedOnChange = useMemo(() => debounce(onChange, delay || 300), [onChange, delay]);

    return (
        <>
            <div id={targetId}>
                <SearchBox
                    autoComplete="off"
                    className={cx(rootClassName, className)}
                    placeholder={`Search contents from ${tenantInfo.name}`}
                    //onFocus={openCallout}
                    onClick={openCallout}
                    onChange={debouncedOnChange}
                />
            </div>
            {isCalloutVisible && (
                <Callout
                    className={calloutClassName}
                    ariaLabelledBy={labelId}
                    ariaDescribedBy={descriptionId}
                    role="dialog"
                    target={`#${targetId}`}
                    onDismiss={toggleCallout}
                    isBeakVisible={false}
                    gapSpace={0}
                    setInitialFocus
                    directionalHint={DirectionalHint.bottomRight}
                    calloutMaxHeight={500}
                >
                    <div className="wrapper">
                        <Stack horizontal className="row--xs">
                            <Text block variant="mediumPlus" id={labelId}>
                                Search results
                            </Text>
                        </Stack>

                        {!loading && items.length === 0 && (
                            <Text block variant="small" id={descriptionId} className="row--xs">
                                Type keywords into the search box to start searching.
                            </Text>
                        )}

                        <CombinedSearchListView loading={loading} items={items} includeRole={false} />
                    </div>
                </Callout>
            )}
        </>
    );
});
