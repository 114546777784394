import React, { Component, Fragment } from "react";
import { observer, inject } from "mobx-react";

import { ComingSoon } from "../../../base/components/ComingSoon";
import { BoxPanel } from "../../../base/components/BoxPanel";
import { AdvisoryGridView } from "../../components/advisories/AdvisoryGridView";

export const PatternAdvisoriesTab = inject(
    "patternStore",
    "routing"
)(
    observer(
        class PatternAdvisoriesTab extends Component {
            componentWillMount() {
                const { patternStore, routing } = this.props;
            }

            componentWillReceiveProps(nextProps) {
                const { patternStore, routing } = nextProps;
            }

            render() {
                const { patternStore, routing } = this.props;
                const { pattern, manifest } = patternStore.selectionStore;

                return (
                    <div>
                        <AdvisoryGridView pattern={pattern} manifest={manifest} />
                    </div>
                );
            }
        }
    )
);
