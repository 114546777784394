import { observable, action, computed, flow, toJS } from "mobx";

import { isNotNullOrEmpty } from "../../editors/components/RichTextField";

import { RiskService } from "../../../api/risks";
import { RiskStore } from "./RiskStore";

export class RiskReviewStore {
    private parentStore: RiskStore;
    private riskService: RiskService;

    @observable public riskId: string | null;
    @observable public reviews: any[] = [];
    @observable public formData: any;
    @observable public formOptions: any;
    @observable public visible: boolean = false;
    @observable public loading: boolean = false;
    @observable public saving: boolean = false;
    @observable public error: any;

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
    }

    @computed
    public get isValid() {
        if (!this.formData) {
            return false;
        }

        const review = this.formData;

        return (
            review.grossScore.score &&
            review.netScore.score &&
            review.category &&
            review.category.id &&
            review.treatments &&
            review.treatments.length == 1 &&
            review.treatments[0].type &&
            isNotNullOrEmpty(review.treatments[0].description) &&
            isNotNullOrEmpty(review.grossScore.description) &&
            isNotNullOrEmpty(review.netScore.description)
        );
    }

    @action
    public show(options) {
        this.visible = true;

        if (this.formData && !options.clearData) {
            return;
        }

        this.riskId = options.risk ? options.risk.id : null;

        this.formOptions = {
            title: options.risk ? `${options.risk.code} - ${options.risk.title}` : null,
        };

        let current = options.review;
        if (current) {
            current = toJS(current);
            current.nextReviewDue = null;
            //current = JSON.parse(JSON.stringify(current));
            //if(current.nextReviewDue) {
            //    current.nextReviewDue = moment.utc(current.nextReviewDue).toDate();
            //}
        }

        this.formData = current || {
            lifecycleId: options.lifecycleId,
            grossScore: {},
            netScore: {},
            treatments: [
                {
                    type: "Mitigate",
                    description: null,
                },
            ],
        };

        this.formData.monitor = options.monitor;
    }

    @action
    public hide(options) {
        this.visible = false;

        if (options && options.clearData) {
            this.formData = null;
            this.formOptions = null;
        }
    }

    public onMonitor = flow(function* () {
        const { risk } = this.parentStore.selectionStore;

        this.show({
            review: risk.review,
            risk: risk,
            monitor: true,
            clearData: true,
        });
    });

    public onReview = flow(function* () {
        const { risk } = this.parentStore.selectionStore;

        this.show({
            review: risk.review,
            risk: risk,
            monitor: false,
            clearData: true,
        });
    });

    @action
    public loadRiskReviews(id) {
        this.loading = true;
        this.riskId = id;
        this.reviews = [];
        return this.riskService.getRiskReviews(id).then(this.loadRiskReviewsOnSuccess, this.loadRiskReviewsOnError);
    }

    @action.bound
    public loadRiskReviewsOnSuccess(result) {
        this.reviews = result;
        this.loading = false;
        return result;
    }

    @action.bound
    public loadRiskReviewsOnError(error) {
        this.error = error;
        this.loading = false;
        throw error;
    }

    @action
    public createRiskReview(id, review) {
        this.saving = true;
        if (id != this.riskId) {
            this.reviews = [];
        }
        this.riskId = id;
        return this.riskService.createRiskReview(id, review).then(this.createRiskReviewOnSuccess, this.createRiskReviewOnError);
    }

    @action.bound
    public createRiskReviewOnSuccess(result) {
        this.loadRiskReviews(this.riskId);
        this.saving = false;
        return result;
    }

    @action.bound
    public createRiskReviewOnError(error) {
        this.error = error;
        this.saving = false;
        throw error;
    }
}
