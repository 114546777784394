import { observable, flow } from "mobx";

import { IdentityService } from '../../../api/identity';
import { PrincipalStore } from './PrincipalStore';

export class UserPickerStore {

    private parentStore: PrincipalStore;
    private identityService: IdentityService;
    
    @observable public selectedUserIds: string[] = [];
    @observable public users: any[] = [];
    @observable public otherUsers: any[] = [];
    @observable public groupId: string;
    @observable public loading: boolean = false;

    constructor(parentStore: PrincipalStore) {
        this.parentStore = parentStore;
        this.identityService = parentStore.identityService;
    }

    public loadGroupUsers = flow(function*(query) {
        this.loading = true;
        var options = Object.assign({ page: 1, keywords: '', otherUsers: [] }, query);
        try {
            const result = yield this.identityService.searchPrincipalsByGroup({ 
                pageSize: 250, 
                startIndex: (options.page - 1) *  250,
                keywords: options.keywords,
                groupId: options.groupId
            });         
            this.users = result.items;
            this.groupId = options.groupId;
            return result;
        } catch (e) {
            console.error(e);
        } finally {
            this.loading = false;
        }
    });

    public setSelected = flow(function*(users) {
        this.selectedUserIds = users.map(i => i.id);

        if(this.selectedUserIds.length > 0) {
            this.otherUsers = yield this.identityService.getPrincipalsByIds(this.selectedUserIds); 
        }
    });

    public setSelectedWithId = flow(function*(id: string | null) {
        if(!id) {
            this.selectedUserIds = [];
        } else {
            if(this.selectedUserIds.length == 1 && this.selectedUserIds[0] == id) {
                return;
            }
            this.selectedUserIds = [id];
        }

        if(this.selectedUserIds.length > 0) {
            this.otherUsers = yield this.identityService.getPrincipalsByIds(this.selectedUserIds); 
        }
    });

    public setSelectedWithIds = flow(function*(ids: string[] | null) {
        if(ids == null || ids.length == 0) {
            this.selectedUserIds = [];
        } else {
            this.selectedUserIds = ids;
        }

        if(this.selectedUserIds.length > 0) {
            this.otherUsers = yield this.identityService.getPrincipalsByIds(this.selectedUserIds); 
        }
    });
}
