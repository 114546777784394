import React from "react";
import { observer } from "mobx-react";

import { Pivot, PivotItem, PivotLinkFormat } from "office-ui-fabric-react/lib/Pivot";
import { Text } from "@ui/elements/Text";

import { BoxPanel } from "@modules/base/components/BoxPanel";

export const StateFlowMockTabList = observer(({ mock, onTrigger }) => {
    return (
        <Pivot onLinkClick={(item) => onTrigger(item.props.itemKey)} linkFormat={PivotLinkFormat.links}>
            {mock.tabs.map((tab) => (
                <PivotItem key={tab} itemKey={tab} headerText={tab}>
                    <BoxPanel shadow style={{ minHeight: 440 }}>
                        <Text
                            variant="xLarge"
                            style={{
                                width: 550,
                                margin: "150px auto",
                                display: "block",
                                fontSize: "4rem",
                                color: "var(--background-color)",
                                textAlign: "center",
                            }}
                        >
                            Preview
                        </Text>
                    </BoxPanel>
                </PivotItem>
            ))}
        </Pivot>
    );
});
