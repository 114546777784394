import React, { Component } from "react";
import { observer } from "mobx-react";

import Moment from "react-moment";

import { Text } from "office-ui-fabric-react/lib/Text";
import { MessageBar } from "office-ui-fabric-react/lib/MessageBar";
import { DetailsListLayoutMode, Selection, SelectionMode, ConstrainMode } from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { ContextualMenu } from "office-ui-fabric-react/lib/ContextualMenu";

import { BoxPanel } from "../../../base/components/BoxPanel";
import { RoutingLink } from "../../../base/components/RoutingLink";
import { WrappedContent } from "../../../base/components/WrappedContent";

export const PropertyGridView = observer(
    class PropertyGridView extends Component {
        constructor(props) {
            super(props);

            this.state = {};

            this._selection = new Selection({
                onSelectionChanged: () => {
                    this.setState({
                        selectionDetails: this._getSelectionDetails(),
                    });
                },
            });

            this._columns = [
                {
                    key: "name",
                    name: "Name",
                    fieldName: "name",
                    minWidth: 310,
                    isRowHeader: true,
                    isResizable: true,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return (
                            <WrappedContent>
                                <RoutingLink
                                    url={`/properties/browse/${item.id}`}
                                    onClick={() => this.props.onPropertyClick && this.props.onPropertyClick(item)}
                                    onClickEnabled={this.props.onPropertyClick}
                                >
                                    {item.name} ({item.staticName})
                                </RoutingLink>
                                <br />
                                <Text variant="small" block>
                                    {item.description && <span>{item.description}</span>}
                                </Text>
                            </WrappedContent>
                        );
                    },
                    isPadded: true,
                },
                {
                    key: "type",
                    name: "Type",
                    fieldName: "type",
                    minWidth: 80,
                    maxWidth: 110,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return <span>{item.type}</span>;
                    },
                    isPadded: false,
                },
                {
                    key: "section",
                    name: "Section",
                    fieldName: "section",
                    minWidth: 180,
                    maxWidth: 220,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return <span>{item.section && item.section.name}</span>;
                    },
                    isPadded: false,
                },
                {
                    key: "multiValue",
                    name: "Multi Value",
                    fieldName: "multiValue",
                    minWidth: 80,
                    maxWidth: 110,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return <span>{item.multiValue ? "Yes" : "No"}</span>;
                    },
                    isPadded: false,
                },
                {
                    key: "required",
                    name: "Required",
                    fieldName: "required",
                    minWidth: 80,
                    maxWidth: 110,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return <span>{item.required ? "Yes" : "No"}</span>;
                    },
                    isPadded: false,
                },
                {
                    key: "readOnly",
                    name: "Read Only",
                    fieldName: "readOnly",
                    minWidth: 80,
                    maxWidth: 110,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return <span>{item.readOnly ? "Yes" : "No"}</span>;
                    },
                    isPadded: false,
                },
                {
                    key: "searchable",
                    name: "Searchable",
                    fieldName: "searchable",
                    minWidth: 80,
                    maxWidth: 110,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return <span>{item.searchable ? "Yes" : "No"}</span>;
                    },
                    isPadded: false,
                },
                {
                    key: "order",
                    name: "Order",
                    fieldName: "order",
                    minWidth: 80,
                    maxWidth: 110,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return <span>{item.order}</span>;
                    },
                    isPadded: false,
                },
                {
                    key: "modified",
                    name: "Date Modified",
                    fieldName: "modified",
                    minWidth: 100,
                    maxWidth: 130,
                    isResizable: true,
                    isSorted: true,
                    isSortedDescending: true,
                    sortAscendingAriaLabel: "Sorted A to Z",
                    sortDescendingAriaLabel: "Sorted Z to A",
                    onColumnClick: this._onColumnClick,
                    data: "date",
                    onRender: (item) => {
                        return <Moment date={item.modified} fromNow />;
                    },
                    isPadded: true,
                },
            ];
        }

        render() {
            const { properties, loading, onPropertyClick } = this.props;
            const { contextualMenuProps } = this.state;

            return (
                <BoxPanel shadow>
                    <ShimmeredDetailsList
                        items={properties}
                        compact={false}
                        columns={this._columns}
                        selectionMode={SelectionMode.none}
                        getKey={this._getKey}
                        setKey="single"
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.horizontalConstrained}
                        isHeaderVisible={true}
                        selection={this._selection}
                        selectionPreservedOnEmptyClick={true}
                        onItemInvoked={onPropertyClick}
                        //onItemContextMenu={(item, index, ev) => this._onItemContextMenu(item, index, ev) }
                        enterModalSelectionOnTouch={true}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        checkButtonAriaLabel="Row checkbox"
                        enableShimmer={loading}
                        removeFadingOverlay={true}
                        shimmerLines={25}
                        ariaLabelForShimmer="Properties being fetched"
                        ariaLabelForGrid="Property list"
                    />

                    {!loading && !properties.length && <MessageBar>There are no matching properties.</MessageBar>}

                    {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}
                </BoxPanel>
            );
        }

        _getKey(item, index) {
            return item ? item.id : index;
        }

        _getSelectionDetails() {
            const selectionCount = this._selection.getSelectedCount();

            switch (selectionCount) {
                case 0:
                    return "No items selected";
                case 1:
                    return "1 item selected: " + this._selection.getSelection()[0].name;
                default:
                    return `${selectionCount} items selected`;
            }
        }

        _onColumnClick(ev, column) {
            console.log(column);
        }

        _onItemContextMenu(item, index, ev) {
            const contextualMenuProps = {
                target: ev.target,
                items: [
                    {
                        key: "text",
                        name: `${this._selection.getSelectedCount()} selected`,
                    },
                ],
                onDismiss: () => {
                    this.setState({
                        contextualMenuProps: undefined,
                    });
                },
            };

            if (index > -1) {
                this.setState({
                    contextualMenuProps: contextualMenuProps,
                });
            }

            return false;
        }
    }
);
