import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { useStores } from "@hooks/useStores";
import { cx } from "emotion";
import Dropzone from "react-dropzone";

import { AppPage } from "@modules/layout/containers/AppPage";

import { EntityHeader } from "@modules/entities/components/core/EntityHeader";
import { EntitySidebar } from "@modules/entities/components/core/EntitySidebar";

import { EntityRoutingPivot } from "@modules/entities/containers/tabs/EntityRoutingPivot";
import { EntityTabList } from "@modules/entities/containers/tabs/EntityTabList";
import { EntityEditPanel } from "@modules/entities/containers/panels/EntityEditPanel";

export const EntityViewerPage = withRouter(
    observer(({ match }) => {
        const { entityStore } = useStores();
        const { lifecycleStore, viewerStore, documentStore } = entityStore;
        const { loading, error, breadcrumb, entity, selectedTab } = viewerStore;

        useEffect(() => {
            if (match.params.id) {
                viewerStore.loadEntity(match.params.id);
            }
        }, [match.params.id]);

        useEffect(() => {
            viewerStore.setSelectedTab(match.params.tab);
        }, [match.params.id, match.params.tab]);

        return (
            <AppPage
                error={error}
                breadcrumb={breadcrumb}
                loading={loading || (documentStore && documentStore.saving)}
                hasSidebar={true}
            >
                {entity && (
                    <>
                        <Dropzone
                            onDrop={(acceptedFiles) => documentStore.uploadDocuments(acceptedFiles, entity.id)}
                            multiple={true}
                            noClick={true}
                        >
                            {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, open }) => (
                                <div
                                    {...getRootProps()}
                                    className={cx(
                                        "dropzone",
                                        isDragActive && "active",
                                        isDragAccept && "accept",
                                        isDragReject && "reject"
                                    )}
                                >
                                    <input {...getInputProps()} />

                                    <EntityHeader
                                        entity={entity}
                                        hideAgentActions={!lifecycleStore.showAgentActions}
                                        canEdit={() => lifecycleStore.canEdit}
                                        onEdit={() => entityStore.editStore.show({ entity })}
                                        canUpload={() => true}
                                        onUpload={open}
                                    />

                                    <EntityRoutingPivot selectedTab={selectedTab} entity={entity} />
                                    <EntityTabList onUpload={open} entity={entity} />

                                    <EntitySidebar entity={entity} />
                                </div>
                            )}
                        </Dropzone>
                    </>
                )}

                <EntityEditPanel onAfterSave={(entity) => viewerStore.setEntity(entity)} />
            </AppPage>
        );
    })
);
