import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { css } from '@uifabric/utilities/lib/css';

import { SharingScopesPanel } from '../../../securables/containers/panels/SharingScopesPanel';
import { SharingFormPanel } from '../../../securables/containers/panels/SharingFormPanel';
import { buildRoles } from '../../../securables/stores/utilities';

import { AppPage } from '../../../layout/containers/AppPage'; 
import { PageHeader } from '../../components/core/PageHeader';
import { PageMetadata } from '../../components/core/PageMetadata';
import { PageViewer } from '../utilities/PageViewer';

import { PageHierarchyNav } from '../utilities/PageHierarchyNav';

export const PageViewerPage = inject('pageContext', 'routing', 'pageStore')(observer(
    class PageViewerPage extends Component {

        componentWillMount() {
            const { routing, pageStore } = this.props;
            pageStore.viewerStore.loadPageContentByPath(routing.location.pathname);
        }

        componentWillReceiveProps(nextProps) {
            const { routing, pageStore } = nextProps;
            pageStore.viewerStore.loadPageContentByPath(routing.location.pathname);
        }

        render () {
            const { hidePageHierarchyNav } = this.props
            const pageProps = Object.assign({
                className: 'card',
                metadata: 'header'
            }, this.props.pageProps);
            const { loading, error, breadcrumb, page } = this.props.pageStore.viewerStore;
            const { lifecycleStore, permissionStore, sharingStore } = this.props.pageStore.instanceStore;
            const { mode, saving } = lifecycleStore;
            return (
                <AppPage 
                    loading={loading || saving} 
                    error={error} 
                    breadcrumb={breadcrumb}
                    hasSidebar={false}
                    className={css('pages viewer', !error && !hidePageHierarchyNav && 'has--page-nav')}>
                    <div>
                        { page && !hidePageHierarchyNav && <PageHierarchyNav mode={mode} libraryId={page.library ? page.library.id : null} maxLevel={3} /> }
                        { page && <PageHeader className="row--l" mode={mode} page={page}
                            hideMetadata={pageProps.metadata != 'header'}
                            isEditMode={lifecycleStore.isEditMode}
                            hideActions={!permissionStore.canEdit}
                            sharedWith={sharingStore.principals}
                            canEdit={() => lifecycleStore.canEdit}
                            onEdit={() => lifecycleStore.onEdit()}
                            canCancel={() => lifecycleStore.canCancel}
                            onCancel={() => lifecycleStore.onCancel()}
                            canSave={() => lifecycleStore.canSave}
                            onSave={(mode) => lifecycleStore.onSave(mode)}
                            canPublish={() => lifecycleStore.canPublish}
                            canShare={() => permissionStore.canShare}
                            onShare={() => sharingStore.showSharing({
                                pageTitle: page.title,
                                roles: buildRoles()
                            })}
                            onScopes={() => sharingStore.showScopes({})} /> 
                        }
                        { page && <PageViewer className={pageProps.className} instance={this.props.pageStore.instanceStore} /> }
                        { page && pageProps.metadata == 'bottom' && (<PageMetadata page={page} />) }
                    </div>

                    <SharingScopesPanel 
                        visible={sharingStore.scopesVisible} 
                        scopes={sharingStore.scopes} 
                        onClose={() => sharingStore.hideScopes()}
                        canShare={() => permissionStore.canShare}
                        onShare={() => sharingStore.showSharing({
                            pageTitle: page.title,
                            roles: buildRoles()
                        })}
                        onRevoke={(permission) => sharingStore.onRevoke(permission)} />

                    <SharingFormPanel
                        visible={sharingStore.sharingVisible} 
                        formData={sharingStore.formData}
                        formOptions={sharingStore.formOptions} 
                        isValid={sharingStore.isValid} 
                        onCancel={() => sharingStore.hideSharing()}
                        onGrant={() => sharingStore.onGrant().then(() => sharingStore.hideSharing())} />
                        
                </AppPage>
            );
        }
    }
));
