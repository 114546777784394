import React, {Component} from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from 'react-router';
import { Pivot, PivotItem } from 'office-ui-fabric-react/lib/Pivot';

import { AppPage } from '../../../layout/containers/AppPage';

import { SystemViewHeader } from '../../components/systemView/SystemViewHeader';

import { SystemViewSideBar } from '../core/SystemViewSideBar';
import { SystemViewOverviewTab } from '../tabs/OverviewTab';
import { AssessmentHistoryTab} from '../tabs/AssessmentHistoryTab';
import { AdvisoriesTab } from '../tabs/AdvisoriesTab';

export const SystemViewPage = inject('systemStore', 'routing')(withRouter(observer(
    class SystemViewPage extends Component {
       
        constructor(props) {
            super(props);
            this.handleAssessmentsFilterStatusChange = this.handleAssessmentsFilterStatusChange.bind(this);
            this.handleAssessmentsFilterKeywordsChange = this.handleAssessmentsFilterKeywordsChange.bind(this);
            this.handleAssessmentsPageChange = this.handleAssessmentsPageChange.bind(this);
            this.handleClickAssessmentLink = this.handleClickAssessmentLink.bind(this);
            this.onAdvisorySearchChange = this.onAdvisorySearchChange.bind(this);
            this.onAdvisoryChangeStatus = this.onAdvisoryChangeStatus.bind(this);
            this.onAdvisoryChangeSeverity = this.onAdvisoryChangeSeverity.bind(this);
            this.onAdvisoryChangePage = this.onAdvisoryChangePage.bind(this);
            
            this.state = {
                tabs: [
                    {
                        key: 'main',
                        name: 'Overview',
                        href: '',
                    },
                    {
                        key: 'history',
                        href: '/history',
                        name: 'Assessments History',
                    },
                    {
                        key: 'advisories',
                        href: '/advisories',
                        name: 'Advisories',
                    },
                ],
                assessmentsStates: [
                    {
                        key: 'All',
                        text: 'All',
                    },
                    {
                        key: 'NotStarted',
                        text: 'Not Started',
                    },
                    {
                        key: 'Started',
                        text: 'Started',
                    },
                    {
                        key: 'Remediation',
                        text: 'Remediation',
                    },
                    {
                        key: 'Closed',
                        text: 'Closed',
                    },
                    {
                        key: 'Cancelled',
                        text: 'Cancelled',
                    }
                ],
                advisoriesSeverities: [
                    {
                        key: 'All',
                        text: 'All',
                    },
                    {
                        key: 'Critical',
                        text: 'Critical',
                    },
                    {
                        key: 'High',
                        text: 'High',
                    },
                    {
                        key: 'Medium',
                        text: 'Medium',
                    },
                    {
                        key: 'Low',
                        text: 'Low',
                    },
                    {
                        key: 'Note',
                        text: 'Note',
                    },
                ],
                advisoriesStatus: [
                    {
                        key: 'All',
                        text: 'All',
                    },
                    {
                        key: 'NonCompliant',
                        text: 'Non compliant',
                    },
                    {
                        key: 'Compliant',
                        text: 'Compliant',
                    },
                    {
                        key: 'Pending',
                        text: 'Pending',
                    },
                ],
            };
        }

        get isSystemIdChanged() {
            const { systemStore, match } = this.props;
            return match.params.id !== systemStore.selectedSystem.id
        }

        async componentWillMount() {
            const { systemStore, match, tab } = this.props;
            if (!systemStore.selectedSystem || this.isSystemIdChanged) await systemStore.loadSystem(match.params.id);
        }

        async componentWillReceiveProps(nextProps) {
            const { systemStore, match, tab } = nextProps;
            if (!systemStore.selectedSystem || this.isSystemIdChanged) await systemStore.loadSystem(match.params.id);
        }

        handleAssessmentsFilterStatusChange(stateFilter) {
            const { systemStore } = this.props;
            this.setState({
                assessmentHistorySelectedState : stateFilter,
            });
            systemStore.changeAssessmentsStatusFilter(stateFilter);
        }

        handleAssessmentsFilterKeywordsChange(keywords) {
            const { systemStore } = this.props;
            this.setState({
                assessmentHistoryKeywords : keywords,
            });
            systemStore.changeAssessmentsKeywordsFilter(keywords);
        }

        handleAssessmentsPageChange(page) {
            const { systemStore } = this.props;
            systemStore.changeAssessmentsPage(page+1);
        }

        onAdvisoryChangePage(page) {
            const { systemStore } = this.props;
            systemStore.changeAdvisoriesPage(page+1);
        }

        onAdvisorySearchChange(keywords) {
            const { systemStore } = this.props;
            systemStore.changeAdvisoriesKeywordFilter(keywords);
        }

        onAdvisoryChangeStatus(status) {
            const { systemStore } = this.props;
            systemStore.changeAdvisoriesStatusFilter(status);
        }

        onAdvisoryChangeSeverity(severity) {
            const { systemStore } = this.props;
            systemStore.changeAdvisoriesSeverityFilter(severity);
        }

        onLinkClick(e) {
            const { systemStore } = this.props;
            const { tabs } = this.state;
            const selectedTab = tabs.filter(T => e.props.headerText === T.name);
            if (selectedTab.length === 1) {
                this.props.routing.push(`/assets/browse/${systemStore.selectedSystem.id}${selectedTab[0].href}`);
            }
        }

        handleClickAssessmentLink(assessmentId) {
            this.props.routing.push(`/assessments/${assessmentId}`);
        }
    
        render () {
            const { systemStore, routing, match, tab } = this.props;
            const { viewPageStore } = systemStore;
            const { breadcrumb } = viewPageStore;
            const {
                assessmentsStates,
                advisoriesSeverities,
                advisoriesStatus,
            } = this.state;
            const systemName = systemStore.selectedSystem ? systemStore.selectedSystem.name : '';
            const assessments = systemStore.selectedSystem && systemStore.selectedSystem.assessments ? systemStore.selectedSystem.assessments : [];
            const advisories = systemStore.selectedSystem && systemStore.selectedSystem.advisories ? systemStore.selectedSystem.advisories : [];
            const selectedAssessmentsState = systemStore.selectedSystem ? systemStore.selectedSystem.assessmentsStateFilter : 0;
            const advisoriesSelectedSeverity = systemStore.selectedSystem && systemStore.selectedSystem.advisoriesFilters ? systemStore.selectedSystem.advisoriesFilters.level : 'All';
            const advisoriesSelectedState = systemStore.selectedSystem && systemStore.selectedSystem.advisoriesFilters? systemStore.selectedSystem.advisoriesFilters.finalCompliance : 'All';
            const advisoriesKeywords = systemStore.selectedSystem && systemStore.selectedSystem.advisoriesFilters ? systemStore.selectedSystem.advisoriesFilters.keywords : '';
            const assessmentHistorySelectedState = systemStore.selectedSystem && systemStore.selectedSystem.assessmentsFilters ? systemStore.selectedSystem.assessmentsFilters.state : 'All';
            const assessmentHistoryKeywords = systemStore.selectedSystem && systemStore.selectedSystem.assessmentsFilters ? systemStore.selectedSystem.assessmentsFilters.keywords : '';

            return (
                <AppPage 
                    title="Assets"
                    breadcrumb={breadcrumb}
                    hasSidebar={true}
                    className="systems view"
                    onTitleClick={()=>{ routing.push('/assets/browse')}}
                >
                    <SystemViewHeader
                        system={systemStore.selectedSystem}
                    /> 
                    <Pivot 
                        aria-label="System details"
                        selectedKey={viewPageStore.selectedTab}
                        onLinkClick={(tab)=> {viewPageStore.selectedTab = tab.props.itemKey}}
                    >
                        <PivotItem headerText="Summary" itemKey="Summary" key="Summary">
                            <SystemViewOverviewTab
                                assetId={match.params.id}
                                routing={routing}
                                system={systemStore.selectedSystem}
                            />
                        </PivotItem>
                        <PivotItem headerText="Assessment History" itemKey="AssessmentHistory" key="AssessmentHistory">
                            <AssessmentHistoryTab 
                                selectedAssessmentsState = {selectedAssessmentsState}
                                assessments = {assessments}
                                pagination = {systemStore.pagination}
                                handleAssessmentsFilterStatusChange = {this.handleAssessmentsFilterStatusChange}
                                handleAssessmentsFilterKeywordsChange = {this.handleAssessmentsFilterKeywordsChange} 
                                handlePageChange = {this.handleAssessmentsPageChange}
                                handleClickAssessmentLink = {this.handleClickAssessmentLink}
                                keywords = {assessmentHistoryKeywords}
                                selectedState = {assessmentHistorySelectedState}
                                assessmentsStates={assessmentsStates}
                            />
                        </PivotItem>
                        <PivotItem headerText="Advisories"  itemKey="Advisories" key="Advisories">
                            <AdvisoriesTab 
                                advisories = {advisories}
                                advisoriesSeverities={advisoriesSeverities}
                                advisoriesStatus={advisoriesStatus}
                                selectedSeverity={advisoriesSelectedSeverity}
                                selectedState={advisoriesSelectedState}
                                pagination = {systemStore.pagination}
                                keywords={advisoriesKeywords}
                                onSearchChange={this.onAdvisorySearchChange}
                                onStatusChange={this.onAdvisoryChangeStatus}
                                onSeverityChange={this.onAdvisoryChangeSeverity}
                                onPageChange={this.onAdvisoryChangePage}
                            />
                        </PivotItem>
                    </Pivot>
                    {systemStore.selectedSystem ?
                    <SystemViewSideBar 
                        system={systemStore.selectedSystem}
                    />
                    : null }
                </AppPage>
            );
        }
    }
)));