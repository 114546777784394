import React from "react";

import MDEditor from "@uiw/react-md-editor";

export const MarkdownEditor = ({ value, onChange, mode, required, disabled, placeholder, dragBar, height }) => {
    return (
        <div className="container">
            <MDEditor
                value={value}
                onChange={onChange}
                fullscreen={false}
                height={height || 120}
                preview={mode || "edit"}
                visiableDragbar={dragBar}
                textareaProps={{
                    required,
                    disabled,
                    placeholder,
                }}
            />
        </div>
    );
};
