import React from "react";
import { observer } from "mobx-react";
import { Route, Switch } from "react-router";
import { useStores } from "@hooks/useStores";

import { PivotItem } from "office-ui-fabric-react/lib/Pivot";

import { PivotRouting } from "@modules/base/components/PivotRouting";

import { RiskSummaryTab } from "@modules/risks/containers/tabs/RiskSummaryTab";
import { RiskReviewsTab } from "@modules/risks/containers/tabs/RiskReviewsTab";
import { RiskTreatmentsTab } from "@modules/risks/containers/tabs/RiskTreatmentsTab";
import { RiskAssessmentTab } from "@modules/risks/containers/tabs/RiskAssessmentTab";
import { RiskDocumentsTab } from "@modules/risks/containers/tabs/RiskDocumentsTab";

export const RiskTabList = observer(({ selectedTab, risk, onUpload }) => {
    const { routing } = useStores();

    const isExceptionTemplate = risk.type && risk.type.template === "zerodai:risks:exception";
    const module = isExceptionTemplate ? "exceptions" : "risks";

    const defaultTab = "summary";
    const knownTabs = [defaultTab, "reviews", "treatments", "documents"];
    if (risk.assessment) {
        knownTabs.push("assessment");
    }

    const urlPrefix = routing.location.pathname.indexOf(`/teams/${module}/`) === 0 ? `/teams/${module}` : `/${module}`;

    return (
        <>
            <PivotRouting
                data-test="riskTabs"
                aria-label={`${
                    isExceptionTemplate ? "Exception" : "Risk"
                } reviews, life cycles, related documents and work`}
                selectedKey={knownTabs.indexOf(selectedTab) === -1 ? defaultTab : selectedTab}
                onLinkClick={(item, routing) => {
                    if (item.props.itemKey === defaultTab) {
                        routing.push(`${urlPrefix}/browse/${risk.id}`);
                    } else {
                        routing.push(`${urlPrefix}/browse/${risk.id}/${item.props.itemKey}`);
                    }
                }}
            >
                <PivotItem itemKey={defaultTab} headerText="Summary" itemIcon="Info" />
                <PivotItem itemKey="reviews" headerText="Review History" itemIcon="History" />
                <PivotItem itemKey="treatments" headerText="Treatment History" itemIcon="Hospital" />
                <PivotItem itemKey="documents" headerText="Related Documents" itemIcon="DocLibrary" />
                {risk.assessment && (
                    <PivotItem itemKey="assessment" headerText="Related Assessment" itemIcon="WorkItem" />
                )}
            </PivotRouting>

            <Switch>
                <Route path={`${urlPrefix}/browse/:id/reviews`} component={() => <RiskReviewsTab risk={risk} />} />
                <Route
                    path={`${urlPrefix}/browse/:id/treatments`}
                    component={() => <RiskTreatmentsTab risk={risk} />}
                />
                <Route
                    path={`${urlPrefix}/browse/:id/documents`}
                    component={() => <RiskDocumentsTab risk={risk} onUpload={onUpload} />}
                />
                {risk.assessment && (
                    <Route
                        path={`${urlPrefix}/browse/:id/assessment`}
                        component={() => <RiskAssessmentTab risk={risk} />}
                    />
                )}
                <Route path={`${urlPrefix}/browse/:id`} component={() => <RiskSummaryTab risk={risk} />} />
            </Switch>
        </>
    );
});
