import React, { Component } from "react";
import { cx, css } from "emotion";

import { Text } from "office-ui-fabric-react/lib/Text";
import { PrimaryButton } from "office-ui-fabric-react/lib/Button";

import { ObservableSeparator } from "@baseComponents/ObservableSeparator";
import { NavigationList } from "@baseComponents/NavigationList";
import { BigSearchBox } from "@baseComponents/BigSearchBox";

const rootClassName = cx(
    "cygraph-AssetSideNav-root",
    css`
        & .container {
            padding: 10px 0;
        }
        & .sidebar--inner {
            display: flex;
            flex-direction: column;
        }
        & .navigation {
            flex-grow: 1;
            margin-bottom: 15px;
            overflow: auto;
        }
        & .launch-pad button {
            width: 100%;
            text-align: left;
            height: 40px;
            font-size: 15px;
        }
    `
);

const bigSearchBoxClassName = cx(
    "row--l",
    css`
        margin-bottom: 15px !important;
    `
);

export class AssetSideNav extends Component {
    render() {
        const { className, hideAgentActions, loading, query, savedQueries, onNewAsset } =
            this.props;

        const navLinkGroups = [
            {
                name: "Asset Management",
                key: "/assets",
                url: "/assets/dashboard",
                hideGrouping: true,
                links: [
                    /* {
                      name: 'Asset Dashboard',
                      key: '/assets/dashboard',
                      url: '/assets/dashboard',
                      iconProps: { iconName: 'ViewDashboard' }
                    }, */
                    {
                        name: "Browse Assets",
                        key: "/assets/browse",
                        url: "/assets/browse",
                        iconProps: { iconName: "ViewList" },
                    },
                    {
                        name: "My Watchlist",
                        key: "/assets/watchlist",
                        url: "/assets/watchlist",
                        iconProps: { iconName: "FavoriteList" },
                    },
                ],
            },
        ];

        const savedQueriesGroup = [
            {
                name: "Risk Registries",
                key: "/risks/boards",
                hideGrouping: true,
                links: (savedQueries || []).map((savedQuery) => ({
                    name: savedQuery.name,
                    url: `/assets/queries/${savedQuery.id}`,
                    iconProps: { iconName: "Taskboard" },
                    key: savedQuery.id,
                    data: savedQuery,
                })),
            },
        ];

        return loading ? null : (
            <div className={cx(rootClassName, className, "sidebar sidebar--left sidebar--nav")}>
                <div className="sidebar--inner scrollbar--root">
                    <div className="container">
                        <Text className="title" variant="xLarge" block>
                            Asset
                            <br />
                            Management
                        </Text>
                    </div>

                    <BigSearchBox
                        value={query ? query.keywords : null}
                        className={bigSearchBoxClassName}
                        placeholder="Search for assets"
                        url="/assets/browse"
                    />

                    <div className="navigation scrollbar--root">
                        <NavigationList
                            onLinkClick={this.props.onLinkClick}
                            ariaLabel="Asset management navigation"
                            groups={navLinkGroups}
                        />

                        {savedQueries && savedQueries.length !== 0 && (
                            <>
                                <div className="container">
                                    <ObservableSeparator>Saved Queries</ObservableSeparator>
                                </div>
                                <NavigationList
                                    onLinkClick={this.props.onLinkClick}
                                    ariaLabel="Saved queries navigation"
                                    groups={savedQueriesGroup}
                                />
                            </>
                        )}
                    </div>

                    {!hideAgentActions && onNewAsset && (
                        <div className="launch-pad">
                            <PrimaryButton
                                text="New Asset"
                                iconProps={{ iconName: "Add" }}
                                onClick={onNewAsset}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
