import React from 'react';
import { Toggle as FabricToggle } from 'office-ui-fabric-react/lib/Toggle';

const Toggle = (props) => {

    return (
        <FabricToggle 
            {...props}
        />
    );
}

export { Toggle }