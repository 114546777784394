import { flow } from "mobx";
import { RootStore } from "../../base/RootStore";

import { IdentityService } from "../../../api/identity";

export class PrincipalStoreUtilities {
    private identityService: IdentityService;

    constructor(rootStore: RootStore) {
        this.identityService = new IdentityService(rootStore.authProvider);
    }

    public loadPrincipalPhoto = flow(function* (id, options) {
        if (!id) {
            return null;
        }
        try {
            return yield this.identityService.getPrincipalPhoto(id, options);
        } catch (e) {
            console.warn("user photo is not found", e);
            return null;
        }
    });
}
