import React from "react";
import { observer } from "mobx-react";

import { FilterBar } from "@modules/base/components/FilterBar";

export const AssetFilterBar = observer(({ value, onChange, context, disabled }) => {
    const filters = [
        {
            name: "keywords",
            label: "Keywords",
            type: "Search",
            keywords: true,
            placeholder: "Search by unique id or name",
        },
        {
            name: "portfolio",
            label: "Portfolio",
            type: "Portfolio",
            multiple: true,
            allowClear: true,
            query: {},
        },
        {
            name: "state",
            label: "Status",
            type: "Dropdown",
            multiple: true,
            allowClear: true,
            options: [
                { key: "Unknown", text: "Unknown" },
                { key: "Conceptual", text: "Conceptual" },
                { key: "Developing", text: "Developing" },
                { key: "Testing", text: "Testing" },
                { key: "Live", text: "Live" },
                { key: "Decommissioning", text: "Decommissioning" },
                { key: "Decommissioned", text: "Decommissioned" },
            ],
        },
        {
            name: "businessCriticality",
            label: "Business Criticality",
            type: "Dropdown",
            multiple: true,
            allowClear: true,
            options: [
                { key: "Unknown", text: "Unknown" },
                { key: "Critical", text: "Critical" },
                { key: "High", text: "High" },
                { key: "Medium", text: "Medium" },
                { key: "Low", text: "Low" },
            ],
        },
        {
            name: "primarySupplier",
            label: "Primary Supplier",
            type: "Entity",
            multiple: true,
            allowClear: true,
        },
        {
            name: "pattern",
            label: "Pattern",
            type: "Pattern",
            multiple: true,
            allowClear: true,
        },
    ];
    return <FilterBar value={value} onChange={onChange} context={context} filters={filters} disabled={disabled} />;
});
