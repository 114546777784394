import React, { Component } from "react";
import { observer } from "mobx-react";
import moment from "moment";
import filesize from "filesize";

import { getFileTypeIconProps } from "@uifabric/file-type-icons";
import { Text } from "office-ui-fabric-react/lib/Text";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { MessageBar } from "office-ui-fabric-react/lib/MessageBar";
import { DetailsListLayoutMode, Selection, SelectionMode, ConstrainMode } from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { ContextualMenu } from "office-ui-fabric-react/lib/ContextualMenu";
import { PersonaSize } from "office-ui-fabric-react/lib/Persona";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { WrappedContent } from "@modules/base/components/WrappedContent";
import { PhotoPersona } from "@modules/identity/containers/utilities/PhotoPersona";

export const DocumentGridView = observer(
    class DocumentGridView extends Component {
        constructor(props) {
            super(props);

            this.state = {};

            this._selection = new Selection({
                onSelectionChanged: () => this.props.onSelectionChanged(this._selection),
            });
        }

        render() {
            const { loading, saving, className, onAdvisoryClick } = this.props;
            const { contextualMenuProps } = this.state;

            this._columns = [
                {
                    key: "type",
                    name: "Type",
                    fieldName: "type",
                    minWidth: 50,
                    maxWidth: 50,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return <Icon {...getFileTypeIconProps({ extension: item.document.extension, size: 32 })} />;
                    },
                },
                {
                    key: "title",
                    name: "Title",
                    fieldName: "title",
                    minWidth: 310,
                    isRowHeader: true,
                    isResizable: true,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return (
                            <WrappedContent>
                                <a
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                        ev.stopPropagation();
                                        this.props.onDocumentClick(item);
                                    }}
                                >
                                    {item.document.name}
                                </a>
                                <br />
                                {item.notes && <Text variant="small">{item.notes}</Text>}
                            </WrappedContent>
                        );
                    },
                    isPadded: true,
                },
                {
                    key: "size",
                    name: "Size",
                    fieldName: "size",
                    minWidth: 120,
                    maxWidth: 150,
                    isResizable: true,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return <span>{filesize(item.document.size)}</span>;
                    },
                },
                {
                    key: "version",
                    name: "Version",
                    fieldName: "version",
                    minWidth: 90,
                    maxWidth: 120,
                    isResizable: true,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return <span>{item.document.version}</span>;
                    },
                },
                {
                    key: "modified",
                    name: "Modified",
                    fieldName: "modified",
                    minWidth: 200,
                    maxWidth: 240,
                    isResizable: true,
                    isSorted: true,
                    isSortedDescending: true,
                    sortAscendingAriaLabel: "Sorted A to Z",
                    sortDescendingAriaLabel: "Sorted Z to A",
                    onColumnClick: this._onColumnClick,
                    data: "date",
                    onRender: (item) => {
                        return (
                            <>
                                <PhotoPersona
                                    principalId={item && item.modifiedBy ? item.modifiedBy.id : null}
                                    size={PersonaSize.size28}
                                    text={item.modifiedBy.name}
                                    showSecondaryText={true}
                                    secondaryText={moment.utc(item.modified).fromNow()}
                                />
                            </>
                        );
                    },
                },
            ];

            const filtered = this._getFilteredDocuments();

            return (
                <BoxPanel shadow className={className}>
                    <ShimmeredDetailsList
                        items={filtered}
                        compact={false}
                        columns={this._columns}
                        selectionMode={SelectionMode.multiple}
                        getKey={this._getKey}
                        setKey="multiple"
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.horizontalConstrained}
                        isHeaderVisible={true}
                        selection={this._selection}
                        selectionPreservedOnEmptyClick={true}
                        onItemInvoked={onAdvisoryClick}
                        //onItemContextMenu={(item, index, ev) => this._onItemContextMenu(item, index, ev) }
                        enterModalSelectionOnTouch={true}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        checkButtonAriaLabel="Row checkbox"
                        enableShimmer={loading}
                        removeFadingOverlay={true}
                        shimmerLines={10}
                        ariaLabelForShimmer="Documents are being fetched"
                        ariaLabelForGrid="Document list"
                    />

                    {!loading && !filtered.length && <MessageBar>There are no related documents.</MessageBar>}

                    {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}
                </BoxPanel>
            );
        }

        _getFilteredDocuments() {
            const { documents, view, keywords } = this.props;

            var filtered = documents;
            switch (view || "all") {
                case "internalOnly":
                    filtered = documents.filter((a) => a.internalOnly);
                    break;
            }

            if (keywords) {
                filtered = filtered.filter((a) => a.document.name.toLowerCase().indexOf(keywords.toLowerCase()) != -1);
            }

            return filtered;
        }

        _getKey(item, index) {
            return item ? item.id : index;
        }

        _getSelectionDetails() {
            const selectionCount = this._selection.getSelectedCount();

            switch (selectionCount) {
                case 0:
                    return "No items selected";
                case 1:
                    return "1 item selected: " + this._selection.getSelection()[0].name;
                default:
                    return `${selectionCount} items selected`;
            }
        }

        _onColumnClick(ev, column) {}

        _onItemContextMenu(item, index, ev) {
            const contextualMenuProps = {
                target: ev.target,
                items: [
                    {
                        key: "text",
                        name: `${this._selection.getSelectedCount()} selected`,
                    },
                ],
                onDismiss: () => {
                    this.setState({
                        contextualMenuProps: undefined,
                    });
                },
            };

            if (index > -1) {
                this.setState({
                    contextualMenuProps: contextualMenuProps,
                });
            }

            return false;
        }
    }
);
