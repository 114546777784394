import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";

import { css } from "@uifabric/utilities/lib/css";
import { Pivot, PivotItem } from "office-ui-fabric-react/lib/Pivot";
import { Label } from "office-ui-fabric-react/lib/Label";

import { AppPage } from "@modules/layout/containers/AppPage";
import { FieldSetView } from "@modules/base/components/FieldSetView";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { SettingsListNav } from "@modules/settings/containers/utilities/SettingsListNav";
import { SharingScopesPanel } from "@modules/securables/containers/panels/SharingScopesPanel";
import { SharingFormPanel } from "@modules/securables/containers/panels/SharingFormPanel";

import { buildRoles } from "../../../securables/stores/utilities";
import { PortfolioHeader } from "../../components/core/PortfolioHeader";
import { PortfolioContactList } from "../../components/core/PortfolioContactList";
import { PortfolioEditPanel } from "../../containers/panels/PortfolioEditPanel";

const roles = buildRoles({
    "Portfolio Content Roles": [
        { key: "Portfolio.Assessments.All", text: "Access all Assurance Assessments" },
        { key: "Portfolio.WorkItems.All", text: "Access all Demand Requests" },
    ],
});

export const PortfolioViewerPage = inject(
    "pageContext",
    "routing",
    "portfolioStore"
)(
    withRouter(
        observer(
            class PortfolioViewerPage extends Component {
                componentWillMount() {
                    const { match, portfolioStore } = this.props;
                    portfolioStore.viewerStore.loadPortfolio(match.params.id);
                }

                componentWillReceiveProps(nextProps) {
                    const { match, portfolioStore } = nextProps;
                    portfolioStore.viewerStore.loadPortfolio(match.params.id);
                }

                render() {
                    const { viewerStore, editFormStore, sharingStore } = this.props.portfolioStore;
                    const { loading, error, breadcrumb, portfolio, permission, selectedTab } = viewerStore;

                    return (
                        <AppPage
                            loading={loading}
                            error={error}
                            breadcrumb={breadcrumb}
                            hasSidebar={false}
                            className={css("portfolios viewer", !error && "has--page-nav")}
                        >
                            <SettingsListNav />
                            <div>
                                {portfolio && (
                                    <PortfolioHeader
                                        portfolio={portfolio}
                                        canEdit={() => true}
                                        onEdit={() => editFormStore.show({ portfolio })}
                                        canShare={() => permission && permission.canShare}
                                        onShare={() =>
                                            sharingStore.showSharing({
                                                pageTitle: portfolio.title,
                                                roles: roles,
                                            })
                                        }
                                        onScopes={() => sharingStore.showScopes({})}
                                        sharedWith={sharingStore.principals}
                                    />
                                )}

                                <Pivot
                                    aria-label="Portfolio details and related content"
                                    selectedKey={selectedTab}
                                    onLinkClick={(tab) => {
                                        viewerStore.selectedTab = tab.props.itemKey;
                                    }}
                                >
                                    <PivotItem headerText="Summary" itemKey="summary" key="summary">
                                        <BoxPanel padding>
                                            {portfolio && (
                                                <>
                                                    <FieldSetView
                                                        title="Key Information"
                                                        description="Basic information about this portfolio."
                                                    >
                                                        <div className="row--s">
                                                            <Label>Status</Label>
                                                            <div>{portfolio.isActive ? "Enabled" : "Disabled"}</div>
                                                        </div>
                                                        <PortfolioContactList
                                                            portfolio={portfolio}
                                                            groups={["portfolio"]}
                                                        />
                                                    </FieldSetView>
                                                    <FieldSetView
                                                        title="Stakeholders"
                                                        description="Key contacts for this portfolio."
                                                    >
                                                        <PortfolioContactList
                                                            portfolio={portfolio}
                                                            groups={["stakeholders"]}
                                                        />
                                                    </FieldSetView>
                                                </>
                                            )}
                                        </BoxPanel>
                                    </PivotItem>
                                </Pivot>
                            </div>

                            <PortfolioEditPanel store={editFormStore} />

                            <SharingScopesPanel
                                visible={sharingStore.scopesVisible}
                                scopes={sharingStore.scopes}
                                onClose={() => sharingStore.hideScopes()}
                                canShare={() => permission && permission.canShare}
                                onShare={() =>
                                    sharingStore.showSharing({
                                        pageTitle: portfolio.name,
                                        roles: roles,
                                    })
                                }
                                onRevoke={(permission) => sharingStore.onRevoke(permission)}
                            />

                            <SharingFormPanel
                                visible={sharingStore.sharingVisible}
                                formData={sharingStore.formData}
                                formOptions={sharingStore.formOptions}
                                isValid={sharingStore.isValid}
                                onCancel={() => sharingStore.hideSharing()}
                                onGrant={() => sharingStore.onGrant().then(() => sharingStore.hideSharing())}
                            />
                        </AppPage>
                    );
                }
            }
        )
    )
);
