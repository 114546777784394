import React from "react";
import { observer } from "mobx-react";
import { Route, Switch } from "react-router";

import { PivotItem } from "office-ui-fabric-react/lib/Pivot";

import { PivotRouting } from "@modules/base/components/PivotRouting";

import { TacticalControlSummaryTab } from "./TacticalControlSummaryTab";
import { TacticalControlActivitiesTab } from "./TacticalControlActivitiesTab";

export const TacticalControlTabList = observer(({ selectedTab, control, onUpload }) => {
    const defaultTab = "summary";
    const knownTabs = [defaultTab, "activities"];

    return (
        <>
            <PivotRouting
                data-test="controlTabs"
                aria-label="Tactical control properties, acceptance criteria, evidence and activities"
                selectedKey={knownTabs.indexOf(selectedTab) === -1 ? defaultTab : selectedTab}
                onLinkClick={(item, routing) => {
                    if (item.props.itemKey === defaultTab) {
                        routing.push(`/controls/tactical/${control.id}`);
                    } else {
                        routing.push(`/controls/tactical/${control.id}/${item.props.itemKey}`);
                    }
                }}
            >
                <PivotItem itemKey={defaultTab} headerText="Summary" />
                <PivotItem itemKey="activities" headerText="Activities &amp; Evidence" />
            </PivotRouting>

            <Switch>
                <Route
                    exact
                    path="/controls/tactical/:id/activities"
                    component={() => <TacticalControlActivitiesTab control={control} />}
                />
                <Route
                    path="/controls/tactical/:id"
                    component={() => <TacticalControlSummaryTab control={control} />}
                />
            </Switch>
        </>
    );
});
