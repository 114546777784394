import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import { BoxPanel } from '../../../base/components/BoxPanel';

export const PatternCommandBar = observer(
    class PatternCommandBar extends Component {
        
        render () {
            const { keywords, onSearchChange, onNewTriage } = this.props;

            const items = [
                {
                  key: 'new',
                  text: 'New',
                  iconProps: { iconName: 'Add' },
                  disabled: true,
                  onClick: onNewTriage
                },
                {
                    key: 'import',
                    text: 'Import',
                    iconProps: { iconName: 'Upload' },
                    disabled: true,
                    onClick: onNewTriage
                  },
                {
                    key: 'clone',
                    text: 'Clone',
                    iconProps: { iconName: 'DependencyAdd' },
                    disabled: true,
                    onClick: onNewTriage
                  },
            ];

            const farItems = [
                {
                    key: 'search',
                    text: 'Search',
                    onRender: () => {
                        return (
                        <SearchBox
                            value={keywords}
                            placeholder="Search by name or alias"
                            styles={{ root: { height: 'auto', minWidth: '270px' }}}
                            onSearch={onSearchChange} />
                        );
                    }
                }
            ];

            return (
                <BoxPanel shadow>
                    <CommandBar
                        styles={{ root: { paddingRight: '0px' } }}
                        items={items}
                        farItems={farItems}
                    />
                </BoxPanel>
            );
        }
    }
);