import React from "react";
import { cx, css } from "emotion";
import { observer } from "mobx-react";

import { Text } from "@ui/elements/Text";
import { PrimaryButton } from "@ui/elements/Button";
import { Stack } from "@ui/elements/Stack";

import { NavigationList } from "@baseComponents/NavigationList";
import { BigSearchBox } from "@baseComponents/BigSearchBox";

export const ControlSideNav = observer(({ className, hideAgentActions, loading, query, onLinkClick, onNewControl }) => {
    const navLinkGroups = [
        {
            name: "Control Registry",
            key: "/controls",
            url: "/controls/dashboard",
            hideGrouping: true,
            links: [
                {
                    name: "Control Dashboard",
                    key: "/controls/dashboard",
                    url: "/controls/dashboard",
                    iconProps: { iconName: "ViewDashboard" },
                    isSelected: (url) => url.indexOf("/controls/dashboard") === 0,
                },
                {
                    name: "Browse Strategic Controls",
                    key: "/controls/browse",
                    url: "/controls/browse",
                    iconProps: { iconName: "ViewList" },
                    isSelected: (url) => url.indexOf("/controls/browse") === 0,
                },
                {
                    name: "Browse Tactical Controls",
                    key: "/controls/tactical",
                    url: "/controls/tactical",
                    iconProps: { iconName: "ViewList" },
                    isSelected: (url) => url.indexOf("/controls/tactical") === 0,
                },
                {
                    name: "Browse Audit Activities",
                    key: "/controls/audits",
                    url: "/controls/audits",
                    iconProps: { iconName: "RecurringTask" },
                    isSelected: (url) => url.indexOf("/controls/audits") === 0,
                },
                {
                    name: "Audit Activity Calendar",
                    key: "/controls/calendar",
                    url: "/controls/calendar",
                    iconProps: { iconName: "Calendar" },
                    isSelected: (url) => url.indexOf("/controls/calendar") === 0,
                },
                {
                    name: "Manage Experiments",
                    key: "/controls/experiments",
                    url: "/controls/experiments",
                    iconProps: { iconName: "TestExploreSolid" },
                    isSelected: (url) => url.indexOf("/controls/experiments") === 0,
                },
            ],
        },
    ];

    return loading ? null : (
        <div className={cx(className, "sidebar sidebar--left sidebar--nav")}>
            <div className="sidebar--inner scrollbar--root">
                <Stack verticalFill verticalAlign="space-between">
                    <Stack.Item grow>
                        <div style={{ padding: "10px 0px" }}>
                            <Text style={{ marginBottom: "var(--spacing-s)" }} variant="xLarge" block>
                                Control
                                <br />
                                Registry
                            </Text>
                        </div>

                        <BigSearchBox
                            value={query ? query.keywords : null}
                            style={{ marginBottom: "15px" }}
                            placeholder="Search for controls"
                            url="/controls/browse"
                        />

                        <div className="navigation scrollbar--root">
                            <NavigationList
                                onLinkClick={onLinkClick}
                                ariaLabel="Control registry navigation"
                                groups={navLinkGroups}
                            />
                        </div>
                    </Stack.Item>
                    <Stack.Item>
                        {!hideAgentActions && onNewControl && (
                            <div className="launch-pad">
                                <PrimaryButton
                                    style={{ width: "100%", textAlign: "left", height: "40px", fontSize: "15px" }}
                                    text="New Control"
                                    iconProps={{ iconName: "Add" }}
                                    onClick={onNewControl}
                                />
                            </div>
                        )}
                    </Stack.Item>
                </Stack>
            </div>
        </div>
    );
});
