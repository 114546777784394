import React from "react";
import { observer } from "mobx-react";

import { Text } from "@ui/elements/Text";
import { PersonaSize } from "@ui/elements/Persona";
import { MessageBar } from "@ui/elements/MessageBar";

import { DetailsListLayoutMode, SelectionMode, ConstrainMode } from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { RoutingLink } from "@modules/base/components/RoutingLink";
import { WrappedContent } from "@modules/base/components/WrappedContent";
import { UserHoverCard } from "@modules/identity/containers/utilities/UserHoverCard";
import { PhotoPersona } from "@modules/identity/containers/utilities/PhotoPersona";

export const AcceptanceCriteriaGridView = observer((props) => {
    const { criteria, loading, disableSelection, onDeploymentClick } = props;

    const columns = [
        {
            key: "criteria",
            name: "Criteria",
            fieldName: "criteria",
            isRowHeader: true,
            isResizable: true,
            data: "string",
            onRender: (item) => {
                return (
                    <WrappedContent>
                        {item.title}
                        <br />
                        <Text variant="small" block>
                            {item.description}
                        </Text>
                    </WrappedContent>
                );
            },
            isPadded: true,
        },
        {
            key: "trigger",
            name: "Trigger",
            fieldName: "trigger",
            minWidth: 140,
            isResizable: false,
            data: "string",
            onRender: (item) => {
                return <WrappedContent>{item.trigger}</WrappedContent>;
            },
            isPadded: false,
        },
        {
            key: "status",
            name: "Status",
            fieldName: "status",
            minWidth: 140,
            isResizable: false,
            data: "string",
            onRender: (item) => {
                return <WrappedContent>{item.status}</WrappedContent>;
            },
            isPadded: false,
        },
    ];

    return (
        <BoxPanel shadow>
            <ShimmeredDetailsList
                items={criteria}
                compact={false}
                columns={columns}
                selectionMode={disableSelection ? SelectionMode.none : SelectionMode.none}
                getKey={(item, index) => (item ? item.id : index)}
                setKey="single"
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.horizontalConstrained}
                isHeaderVisible={true}
                selectionPreservedOnEmptyClick={true}
                onItemInvoked={onDeploymentClick}
                //onItemContextMenu={this._onItemContextMenu}
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="Row checkbox"
                enableShimmer={loading}
                removeFadingOverlay={true}
                shimmerLines={15}
                ariaLabelForShimmer="Acceptance criteria being fetched"
                ariaLabelForGrid="Acceptance criteria list"
            />

            {!loading && !criteria.length && <MessageBar>There are no matching acceptance criteria.</MessageBar>}
        </BoxPanel>
    );
});
