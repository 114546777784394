import React from 'react';
import { 
    CommandBar as FabricCommandBar
} from 'office-ui-fabric-react/lib/CommandBar';

const CommandBar = (props) => {

    return (
        <FabricCommandBar 
            {...props}
        />
    );
}

export { CommandBar }