import React from "react";
import { observer } from "mobx-react";

import { Stack } from "office-ui-fabric-react/lib/Stack";
import { Dropdown } from "office-ui-fabric-react/lib/Dropdown";
import { TextField } from "office-ui-fabric-react/lib/TextField";

import { WorkItemPicker } from "@modules/workitems/containers/pickers/WorkItemPicker";

export const WorkItemLinkEditForm = observer(({ formData, formOptions }) => {
    return (
        <Stack tokens={{ childrenGap: 20 }}>
            <Dropdown
                placeholder="Please select a link type"
                label="Link type"
                disabled={formOptions.disabled}
                required
                options={formOptions.linkTypes.map((l) => ({ key: l.id, text: l.name }))}
                selectedKey={formData.linkType ? formData.linkType.id : null}
                onChange={(ev, option) => {
                    formData.linkType = { id: option.key };
                }}
            />
            <WorkItemPicker
                label="Target demand request"
                placeholder="Please select a demand request"
                disabled={formOptions.disabled}
                required
                selected={formData.target}
                onChange={(target) => {
                    formData.target = target;
                }}
            />
            <TextField
                label="Notes"
                placeholder="Please specify any optional notes"
                multiline
                disabled={formOptions.disabled}
                value={formData.notes}
                onChange={(ev, val) => {
                    formData.notes = val;
                }}
            />
        </Stack>
    );
});
