import React from "react";
import Moment from "react-moment";

import { Statistic } from "@modules/base/components/Statistic";

export const ControlDashboardSummary = ({ summary }) => {
    return (
        <r-grid columns="2" columns-l="4">
            <r-span span="1">
                <Statistic
                    className="card card-s"
                    title={`Strategic controls deployed`}
                    value={24}
                    iconName={"IconSetsFlag"}
                />
            </r-span>
            <r-span span="1">
                <Statistic
                    className="card card-s"
                    title={`Tactical controls deployed`}
                    value={187}
                    iconName={"MergeDuplicate"}
                />
            </r-span>
            <r-span span="1">
                <Statistic className="card card-s" title={"Pending control tests"} value={3} iconName="TestPlan" />
            </r-span>
            <r-span span="1">
                <Statistic className="card card-s" title={`Next control test due`}>
                    <Moment date={new Date(2022, 2, 20, 0, 0, 0)} fromNow />
                </Statistic>
            </r-span>
        </r-grid>
    );
};
