import React from "react";
import { observer } from "mobx-react";

import { Text } from "@ui/elements/Text";
import { MessageBar } from "@ui/elements/MessageBar";
import { FontIcon } from "@ui/elements/Icon";

import { DetailsListLayoutMode, SelectionMode, ConstrainMode } from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { WrappedContent } from "@modules/base/components/WrappedContent";
import { AdaptiveField } from "@modules/base/components/AdaptiveField";

import { ControlMaturity } from "@modules/controls/components/core/ControlMaturity";

function hashCode(s) {
    for (var i = 0, h = 0; i < s.length; i++) h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
    return h;
}

export const ControlDomainGridView = observer((props) => {
    const { domains, loading, disableSelection } = props;

    const columns = [
        {
            key: "domain",
            name: "Control Domain",
            fieldName: "domain",
            isResizable: true,
            minWidth: 200,
            maxWidth: 300,
            data: "string",
            onRender: (item) => {
                return (
                    <WrappedContent>
                        {item.code} - {item.name}
                    </WrappedContent>
                );
            },
            isPadded: true,
        },
        {
            key: "scoreGraph",
            name: "Capability Score",
            fieldName: "scoreGraph",
            minWidth: 350,
            isResizable: true,
            data: "string",
            onRender: (item) => {
                return (
                    <div>
                        <div
                            style={{
                                borderRadius: "var(--border-radius)",
                                height: 12,
                                width: `${item.targetScore * 100}%`,
                                background: "var(--grey-2)",
                            }}
                        ></div>
                        <div
                            style={{
                                borderRadius: "var(--border-radius)",
                                opacity: 0.5,
                                marginTop: -12,
                                height: 12,
                                width: `${item.actualScore * 100}%`,
                                background:
                                    item.actualScore >= item.targetScore
                                        ? "var(--green-7)"
                                        : item.actualScore <= item.targetScore - 0.25
                                        ? "var(--red-7)"
                                        : "var(--yellow-7)",
                            }}
                        ></div>
                        <Text variant="xSmall">
                            {(item.actualScore * 5).toFixed(1)} / {(item.targetScore * 5).toFixed(1)}
                        </Text>
                    </div>
                );
            },
            isPadded: true,
        },
        {
            key: "maturityLevel",
            name: "Maturity Level",
            fieldName: "maturityLevel",
            minWidth: 170,
            maxWidth: 190,
            isResizable: false,
            data: "string",
            onRender: (item) => {
                return (
                    <ControlMaturity
                        size="mini"
                        score={["Initial", "Repeatable", "Defined", "Managed", "Optimised"][hashCode(item.id) % 4]}
                    />
                );
            },
            isPadded: false,
        },
    ];

    return (
        <BoxPanel shadow>
            <ShimmeredDetailsList
                items={domains}
                compact={false}
                columns={columns}
                selectionMode={disableSelection ? SelectionMode.none : SelectionMode.none}
                getKey={(item, index) => (item ? item.id : index)}
                setKey="single"
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.horizontalConstrained}
                isHeaderVisible={true}
                selectionPreservedOnEmptyClick={true}
                //onItemContextMenu={this._onItemContextMenu}
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="Row checkbox"
                enableShimmer={loading}
                removeFadingOverlay={true}
                shimmerLines={15}
                ariaLabelForShimmer="Control domains being fetched"
                ariaLabelForGrid="Control domain list"
            />

            {!loading && !domains.length && <MessageBar>There are no matching control domains.</MessageBar>}
        </BoxPanel>
    );
});
