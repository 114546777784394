import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { IconBadgeButton } from "@modules/base/components/IconBadgeButton";

export const TaskSummaryBadge = observer(({ className }) => {
    const { taskStore, routing } = useStores();
    const { loading, summary } = taskStore.summaryStore;

    useEffect(() => {
        taskStore.summaryStore.loadTaskSummary({ cached: true });
    }, []);

    return (
        <IconBadgeButton
            className={className}
            iconProps={{ iconName: "EventToDoLogo", title: "My Tasks" }}
            badgeProps={{
                value: summary ? summary.totalPending : 0,
                variant: summary && summary.overduePending > 0 ? "error" : "info",
                loading: loading,
                hideZero: true,
            }}
            ariaLabel="Show my tasks"
            onClick={() => routing.push("/account/tasks")}
            disabled={false}
        />
    );
});

// export const TaskSummaryBadge2 = inject(
//     "taskStore",
//     "routing"
// )(
//     observer(
//         class TaskSummaryBadge extends Component {
//             componentWillMount() {
//                 const { taskStore } = this.props;
//                 taskStore.summaryStore.loadTaskSummary({ cached: true });
//             }

//             render() {
//                 const { className, taskStore, routing } = this.props;
//                 const { loading, summary } = taskStore.summaryStore;

//                 return (
//                     <IconBadgeButton
//                         className={className}
//                         iconProps={{ iconName: "EventToDoLogo", title: "My Tasks" }}
//                         badgeProps={{
//                             value: summary ? summary.totalPending : 0,
//                             variant: summary && summary.overduePending > 0 ? "error" : "info",
//                             loading: loading,
//                             hideZero: true,
//                         }}
//                         ariaLabel="Show my tasks"
//                         onClick={() => routing.push("/account/tasks")}
//                         disabled={false}
//                     />
//                 );
//             }
//         }
//     )
// );
