import React from "react";
import { cx, css } from "emotion";
import { observer } from "mobx-react";

import { PersonaSize } from "office-ui-fabric-react/lib/Persona";

import { UserHoverCard } from "@modules/identity/containers/utilities/UserHoverCard";
import { PhotoPersona } from "@modules/identity/containers/utilities/PhotoPersona";

const rootClassName = cx(
    css(`
        display: flex;
        height: 100%;
        align-items: center;

        & .ms-HoverCard-host {
            margin-right: -15px;
        }

        & .ms-Persona {
            margin-right: 10px;

            &.not-pending {
                opacity: 0.65;
            }
        }

        & .ms-Persona-details {
            display: none;
        }
    `)
);

export const PhotoPersonaStrip = observer(({ className, personas }) => {
    return (
        <div className={cx(rootClassName, className)}>
            {personas.map((persona, index) => (
                <UserHoverCard key={index} principal={persona.assignedTo}>
                    <PhotoPersona
                        principalId={persona.assignedTo ? persona.assignedTo.id : null}
                        className={cx(persona.status !== "Pending" && "not-pending")}
                        size={PersonaSize.size32}
                        initialsColor={persona.status === "Pending" ? 12 : 21}
                        text={persona.assignedTo.name}
                    />
                </UserHoverCard>
            ))}
        </div>
    );
});
