import React from "react";
import { observer } from "mobx-react";
import moment from "moment";
import Moment from "react-moment";

import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { DetailsListLayoutMode, SelectionMode, ConstrainMode } from "office-ui-fabric-react/lib/DetailsList";
import { MessageBar } from "office-ui-fabric-react/lib/MessageBar";
import { PersonaSize } from "office-ui-fabric-react/lib/Persona";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { Text } from "office-ui-fabric-react/lib/Text";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { RoutingLink } from "@modules/base/components/RoutingLink";
import { WrappedContent } from "@modules/base/components/WrappedContent";
import { PhotoPersona } from "@modules/identity/containers/utilities/PhotoPersona";
import { ListItemReveal } from "@modules/base/components/ListItemReveal";
import { PriorityScore } from "@modules/base/components/kanbanboard/components/PriorityScore";

import { WorkItemDueIndicator } from "@modules/workitems/components/core/WorkItemDueIndicator";
import { WorkItemAgeIndicator } from "@modules/workitems/components/core/WorkItemAgeIndicator";
import { WorkItemLinkDirection } from "@modules/workitems/components/links/WorkItemLinkDirection";

export const WorkItemLinkGridView = observer(({ loading, links, onLinkClick }) => {
    const columns = [
        {
            key: "title",
            name: "Title",
            fieldName: "title",
            isRowHeader: true,
            isResizable: false,
            data: "string",
            onRender: (link) => {
                const item = link.workItem;
                return (
                    <WrappedContent>
                        <RoutingLink url={`/demand/browse/${item.id}`} onClick={() => onLinkClick && onLinkClick(item)} onClickEnabled={onLinkClick}>
                            {item.code} - {item.title}
                        </RoutingLink>
                        <ListItemReveal>
                            {item.assessmentId && (
                                <RoutingLink primary url={`/assurance/browse/${item.assessmentId}`}>
                                    <Icon iconName="WorkItem" />
                                </RoutingLink>
                            )}
                        </ListItemReveal>
                        <Text variant="small" data-test="requestedBy" block>
                            {item.product.name} requested by {item.requestedBy.name} <Moment date={item.created} fromNow />
                        </Text>
                        <Text variant="small" data-test="linkType" block>
                            <WorkItemLinkDirection link={link} />
                        </Text>
                    </WrappedContent>
                );
            },
            isPadded: true,
        },
        {
            key: "age",
            name: "Age",
            fieldName: "Age",
            minWidth: 35,
            maxWidth: 50,
            isResizable: false,
            data: "string",
            onRender: (link) => {
                return <WorkItemAgeIndicator workItem={link.workItem} />;
            },
            isPadded: false,
        },
        {
            key: "due",
            name: "Due",
            fieldName: "due",
            minWidth: 35,
            maxWidth: 50,
            isResizable: false,
            data: "string",
            onRender: (link) => {
                return <WorkItemDueIndicator workItem={link.workItem} />;
            },
            isPadded: false,
        },
        {
            key: "portfolio",
            name: "Portfolio",
            fieldName: "portfolio",
            minWidth: 80,
            maxWidth: 110,
            isResizable: false,
            data: "string",
            onRender: (link) => {
                return <span>{link.workItem.portfolio.name}</span>;
            },
            isPadded: false,
        },
        {
            key: "priority",
            name: "Priority",
            fieldName: "priority",
            minWidth: 65,
            maxWidth: 70,
            isResizable: false,
            data: "string",
            isPadded: false,
            onRender: (link) => {
                return <PriorityScore priority={link.workItem.priority} size="mini" hideName={true} />;
            },
        },
        {
            key: "assigned",
            name: "Assigned",
            fieldName: "assigned",
            minWidth: 180,
            maxWidth: 210,
            isResizable: false,
            data: "string",
            onRender: (link) => {
                const item = link.workItem;

                return (
                    <PhotoPersona
                        principalId={item.assignedToUser ? item.assignedToUser.id : null}
                        size={PersonaSize.size28}
                        text={item.assignedToUser ? item.assignedToUser.name : "Unassigned"}
                        showUnknownPersonaCoin={!item.assignedToUser}
                        showSecondaryText={true}
                        secondaryText={item.assignedToGroup.name}
                    />
                );
            },
            isPadded: false,
        },
        {
            key: "subStatus",
            name: "Status",
            fieldName: "subStatus",
            minWidth: 90,
            maxWidth: 110,
            isResizable: false,
            data: "string",
            onRender: (link) => {
                return <WrappedContent>{link.workItem.subStatus}</WrappedContent>;
            },
            isPadded: false,
        },
        {
            key: "modified",
            name: "Last Modified",
            fieldName: "modified",
            minWidth: 180,
            maxWidth: 210,
            isResizable: false,
            data: "string",
            onRender: (link) => {
                const item = link.workItem;

                return (
                    <PhotoPersona
                        principalId={item.modifiedBy ? item.modifiedBy.id : null}
                        size={PersonaSize.size28}
                        text={item.modifiedBy.name}
                        showSecondaryText={true}
                        secondaryText={moment.utc(item.modified).fromNow()}
                    />
                );
            },
            isPadded: false,
        },
    ];

    return (
        <BoxPanel shadow dataTest="relatedWorkItemsBox">
            <ShimmeredDetailsList
                items={links}
                columns={columns}
                selectionMode={SelectionMode.none}
                getKey={(item, index) => (item ? item.id : index)}
                setKey="single"
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.horizontalConstrained}
                isHeaderVisible={true}
                onItemInvoked={onLinkClick}
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="Row checkbox"
                enableShimmer={loading}
                removeFadingOverlay={true}
                shimmerLines={5}
                ariaLabelForShimmer="Related work item being fetched"
                ariaLabelForGrid="Related work item list"
            />

            {!loading && !links.length && <MessageBar>There are no related work items.</MessageBar>}
        </BoxPanel>
    );
});
