import React from "react";
import { observer } from "mobx-react";

import { ContentEditor } from "../../../editors/components/ContentEditor";

import { sanitize } from "../../stores/PatternUtilities";

import { ModuleFieldChoice } from "./ModuleFieldChoice";
import { ModuleFieldMultiChoice } from "./ModuleFieldMultiChoice";
import { ModuleFieldBoolean } from "./ModuleFieldBoolean";
import { ModuleFieldText } from "./ModuleFieldText";
import { ModuleFieldMenu } from "./ModuleFieldMenu";

export const ModuleFieldMeasure = observer(
    ({
        module,
        group,
        measure,
        indexMap,
        advisoryMap,
        disabled,
        onListAdvisories,
        onEditMeasure,
        onUpdateMeasure,
        onRemoveMeasure,
        onAddOption,
        onUpdateOption,
        onRemoveOption,
    }) => {
        const onRenderField = () => {
            switch (measure.type) {
                case "Choice":
                    return <ModuleFieldChoice {...{ module, group, measure, disabled, onUpdateMeasure, onAddOption, onUpdateOption, onRemoveOption }} />;
                case "MultiChoice":
                    return <ModuleFieldMultiChoice {...{ module, group, measure, disabled, onUpdateMeasure, onAddOption, onUpdateOption, onRemoveOption }} />;
                case "Boolean":
                    return <ModuleFieldBoolean {...{ module, group, measure, disabled, onUpdateMeasure, onAddOption, onUpdateOption, onRemoveOption }} />;
                case "Text":
                    return <ModuleFieldText {...{ module, group, measure, disabled, onUpdateMeasure, onAddOption, onUpdateOption, onRemoveOption }} />;
                default:
                    return null;
            }
        };

        return (
            <div id={measure.id} className="form__question form__question--disabled">
                <div className="form__question__details">
                    <span className="form__question__count">{String(indexMap[measure.id]).padStart(2, "0")}</span>
                    <div className="form__question__decorator">
                        <ContentEditor
                            className="form__question__label"
                            html={measure.title || ""}
                            disabled={disabled}
                            onSubmit={(e, val) => {
                                onUpdateMeasure({
                                    module,
                                    group,
                                    measure: {
                                        id: measure.id,
                                        title: sanitize(val),
                                    },
                                });
                            }}
                        />
                        <ContentEditor
                            className="form__question__description"
                            html={measure.description || ""}
                            disabled={disabled}
                            onSubmit={(e, val) => {
                                onUpdateMeasure({
                                    module,
                                    group,
                                    measure: {
                                        id: measure.id,
                                        description: sanitize(val),
                                    },
                                });
                            }}
                        />
                        <div className="form__response__wrapper">
                            <div className="row--xl">{onRenderField()}</div>
                            <ModuleFieldMenu
                                {...{
                                    module,
                                    group,
                                    measure,
                                    advisoryMap,
                                    disabled,
                                    onListAdvisories,
                                    onEditMeasure,
                                    onUpdateMeasure,
                                    onRemoveMeasure,
                                    onAddOption,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);
