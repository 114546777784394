import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';

import { ComingSoon } from '../../../base/components/ComingSoon';
import { BoxPanel } from '../../../base/components/BoxPanel';

export const EntityComplianceTab = inject('entityStore', 'routing')(observer(
    class EntityComplianceTab extends Component {

        componentWillMount() {
            const { entityStore, routing } = this.props;
        }

        componentWillReceiveProps(nextProps) {
            const { entityStore, routing } = nextProps;
        }
    
        render () {
            const { entityStore, routing } = this.props;

            return (
                <BoxPanel shadow padding>
                    <ComingSoon />
                </BoxPanel>
            );
        }
    }
));
