import { observable, action } from "mobx";

import { AssetService } from '../../../api/assets';
import { AssetStore } from "./AssetStore";

export class AssetPickerStore {

    private parentStore: AssetStore;
    private assetService: AssetService;

    @observable public selectedAssets: any[] = [];
    @observable public assets: any[] = [];
    @observable public loading: boolean = false;

    constructor(parentStore: AssetStore) {
        this.parentStore = parentStore;
        this.assetService = parentStore.assetService;
    }

    @action
    public loadAssets(options) {
        this.loading = true;

        const o = Object.assign({ pageSize: 25, startIndex: 0 }, options);
        return this.assetService.getAssets(o)
            .then(this.loadAssetsOnSuccess, this.loadAssetsOnError);
    }

    @action.bound
    public loadAssetsOnSuccess(result) {
        this.assets = result.items;
        this.loading = false;
        return result;
    }

    @action.bound
    public loadAssetsOnError(error) {
        console.error(error);
        this.loading = false;
        throw error;
    }

    @action
    public loadAssetsByIds(ids) {
        this.loading = true;

        return Promise.all(ids.map(id => {
            return this.assetService.getAsset(id);
        }))
        .then(this.loadAssetsByIdsOnSuccess, this.loadAssetsByIdsOnError);
    }

    @action.bound
    public loadAssetsByIdsOnSuccess(assets) {
        this.selectedAssets = assets;
        this.loading = false;
        return assets;
    }

    @action.bound
    public loadAssetsByIdsOnError(error) {
        console.error(error);
        this.loading = false;
        throw error;
    }

    @action
    public setSelected(assets: any[]) {
        this.selectedAssets = assets;
    }

    @action
    public setSelectedWithId(id: string | null) {
        if(!id) {
            this.selectedAssets = [];
        } else {
            if(this.selectedAssets.length == 1 && this.selectedAssets[0].id == id) {
                return;
            }
            return this.loadAssetsByIds([id]);
        }
    }

    @action
    public setSelectedWithIds(ids: string[] | null) {
        if(ids == null || ids.length == 0) {
            this.selectedAssets = [];
        } else {
            return this.loadAssetsByIds(ids);
        }
    }
}
