import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { ObservablePagination } from "@modules/base/components/ObservablePagination";

import { ControlMapCardView } from "@modules/controls/components/maps/ControlMapCardView";
import { ControlMapCommandBar } from "@modules/controls/components/maps/ControlMapCommandBar";

import { ControlMapNewPanel } from "@modules/controls/containers/panels/ControlMapNewPanel";

export const ControlsMapsTab = observer(() => {
    const { routing, controlStore, pageContext } = useStores();
    const { mapBrowseStore, mapNewStore } = controlStore;
    const { loading, keywords, pagination, maps } = mapBrowseStore;

    useEffect(() => {
        pageContext.updateQueryStringParams(routing.location.search);
        mapBrowseStore.loadControlMaps({
            page: pageContext.queryStringParams.p || 1,
            keywords: pageContext.queryStringParams.k,
        });
    }, []);

    return (
        <>
            <ControlMapCommandBar
                keywords={keywords}
                onAddMap={() => mapNewStore.show({})}
                onSearchChange={(keywords) => {
                    if (keywords) {
                        routing.push(`/settings/control-maps?k=${encodeURIComponent(keywords)}`);
                    } else {
                        routing.push(`/settings/control-maps`);
                    }
                }}
            />
            <BoxPanel shadow padding small style={{ minHeight: 300 }}>
                <ControlMapCardView
                    maps={maps}
                    loading={loading}
                    onControlMapClick={(item) => routing.push(`/settings/control-maps/${item.id}`)}
                    query={{}}
                />
            </BoxPanel>
            <ObservablePagination
                {...pagination}
                onPageChange={(index) => {
                    routing.push(
                        `/settings/control-maps?p=${index + 1}${keywords ? `&k=${encodeURIComponent(keywords)}` : ""}`
                    );
                }}
            />

            <ControlMapNewPanel
                onAfterSave={(map) => {
                    routing.push(`/settings/control-maps/${map.id}`);
                }}
            />
        </>
    );
});
