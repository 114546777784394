import React from "react";
import { observer } from "mobx-react";

import { Stack } from "@ui/elements/Stack";
import { TextField } from "@ui/elements/TextField";
import { IconButton } from "@ui/elements/Button";

import { RichTextField, toStringFromJson, toJsonFromString } from "@modules/editors/components/RichTextField";

export const ActivityStreamFooter = observer(({ store, canSave }) => {
    const { content, contentType, saving } = store;
    return (
        <Stack tokens={{ childrenGap: 5 }} className="activity--stream-footer">
            {canSave && (
                <>
                    {contentType === "text/plain" ? (
                        <TextField
                            placeholder="Share a message here"
                            value={content || ""}
                            multiline
                            onChange={(ev, val) => {
                                store.content = val;
                            }}
                            onKeyPress={(ev) => {
                                if (!ev.shiftKey && ev.key === "Enter") {
                                    ev.preventDefault();
                                    if (canSave || content || !saving) {
                                        store.onSave();
                                    }
                                }
                            }}
                        />
                    ) : (
                        <RichTextField
                            placeholder="Share a message here"
                            value={content}
                            onChange={(val) => {
                                store.content = val;
                            }}
                            onKeyPress={(ev) => {
                                if (!ev.shiftKey && ev.key === "Enter") {
                                    ev.preventDefault();
                                    if (canSave || content || !saving) {
                                        store.onSave();
                                    }
                                }
                            }}
                        />
                    )}
                </>
            )}
            <Stack horizontal tokens={{ childrenGap: 5 }} horizontalAlign="space-between">
                {canSave && (
                    <>
                        <div className="tools">
                            <IconButton
                                styles={{
                                    root: { height: 20, width: 20 },
                                    icon: { fontSize: 12, height: 12, width: 12, lineHeight: 12 },
                                }}
                                iconProps={{ iconName: "EditStyle" }}
                                onClick={() => {
                                    if (contentType === "text/plain") {
                                        store.contentType = "text/json+rich-text";
                                        store.content = toJsonFromString(store.content);
                                    } else {
                                        store.contentType = "text/plain";
                                        store.content = toStringFromJson(store.content);
                                    }
                                }}
                            />
                        </div>
                        <div className="send">
                            <IconButton
                                styles={{
                                    root: { height: 20, width: 20 },
                                    icon: { fontSize: 12, height: 12, width: 12, lineHeight: 12 },
                                }}
                                iconProps={{ iconName: "Send" }}
                                disabled={!canSave || !content || saving}
                                onClick={() => store.onSave()}
                            />
                        </div>
                    </>
                )}
            </Stack>
        </Stack>
    );
});
