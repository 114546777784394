import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";

import { MessageBar, MessageBarType } from "office-ui-fabric-react/lib/MessageBar";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { Text } from "office-ui-fabric-react/lib/Text";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { PanelType } from "office-ui-fabric-react/lib/Panel";
import { PrimaryButton, IconButton, Button } from "office-ui-fabric-react/lib/Button";
import { DetailsListLayoutMode, SelectionMode, ConstrainMode } from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { PersonaSize } from "office-ui-fabric-react/lib/Persona";
import { ObservableSeparator } from "../../../base/components/ObservableSeparator";
import { ObservablePanel } from "../../../base/components/ObservablePanel";
import { PrincipalPicker } from "../../../identity/containers/PrincipalPicker";
import { PhotoPersona } from "../utilities/PhotoPersona";

export const EditGroupPanel = inject(
    "principalContext",
    "routing"
)(
    withRouter(
        observer(
            class EditGroupPanel extends Component {
                constructor(props) {
                    super(props);
                    this.state = {
                        showErrorDialog: false,
                        showContextualMenu: null,
                    };
                }

                componentWillMount() {
                    this.loadData(this.props);
                }

                componentWillReceiveProps(nextProps) {
                    this.loadData(nextProps);
                }

                async loadData(data) {
                    const { principalContext } = data;
                    const { editGroupStore } = principalContext;
                    editGroupStore.loadFormData();
                }

                onNameClick(userId) {
                    const { routing } = this.props;
                    routing.push(`/users/${userId}`);
                }

                render() {
                    const { showErrorDialog, errorDialogMessage } = this.state;
                    const { principalContext } = this.props;
                    const { editGroupStore } = principalContext;
                    return (
                        <>
                            <ObservablePanel
                                pageType={`Edit Group`}
                                panelType={PanelType.medium}
                                onClosePanel={() => this._onHidePanel()}
                                onRenderFooter={() => this._renderFooter()}
                            >
                                <r-grid columns="1">
                                    <r-cell>
                                        <ObservableSeparator className="row--xs">Key Information</ObservableSeparator>
                                    </r-cell>
                                    <r-cell>
                                        <TextField
                                            label="Name"
                                            value={editGroupStore.formData.name}
                                            onChange={(ev, value) => (editGroupStore.formData.name = value)}
                                        />
                                    </r-cell>
                                    <r-cell>
                                        <TextField
                                            label="Email"
                                            value={editGroupStore.formData.email}
                                            onChange={(ev, value) => (editGroupStore.formData.email = value)}
                                        />
                                    </r-cell>
                                    <r-cell>
                                        <ObservableSeparator className="row--xs">Group Members</ObservableSeparator>
                                    </r-cell>
                                    <r-cell>
                                        {showErrorDialog && (
                                            <MessageBar
                                                messageBarType={MessageBarType.error}
                                                isMultiline={false}
                                                onDismiss={() => this._onDismissErrorDialog}
                                                dismissButtonAriaLabel="Close"
                                            >
                                                {errorDialogMessage}
                                            </MessageBar>
                                        )}
                                        <PrincipalPicker
                                            label={`Add user to ${editGroupStore.formData.name}`}
                                            selectedGroup={null}
                                            selected={null}
                                            onSelected={(user) => editGroupStore.addUserToAGroup(user)}
                                        />
                                    </r-cell>
                                    <r-cell>
                                        {editGroupStore.formData.members && (
                                            <ShimmeredDetailsList
                                                isHeaderVisible={false}
                                                items={editGroupStore.formData.members}
                                                compact={true}
                                                columns={[
                                                    {
                                                        key: "name",
                                                        name: "Members",
                                                        fieldName: "name",
                                                        minWidth: 210,
                                                        data: "string",
                                                        onRender: (item) => {
                                                            return (
                                                                <Stack
                                                                    horizontal
                                                                    verticalAlign="middle"
                                                                    horizontalAlign="space-between"
                                                                    tokens={{ padding: 5 }}
                                                                >
                                                                    {/* <Text style={{display:'block', height:'32px'}}>
                                                    <a href={`/user/${item.id}`} onClick={(e) => {e.preventDefault(); this.onNameClick(item.id)}}>{item.name}</a>
                                                </Text> */}
                                                                    <PhotoPersona
                                                                        size={PersonaSize.size28}
                                                                        principalId={item.id}
                                                                        text={item.name}
                                                                        showSecondaryText={true}
                                                                        secondaryText={item.jobTitle}
                                                                    />
                                                                    {this.state.showContextualMenu === item.id ? (
                                                                        <IconButton
                                                                            iconProps={{
                                                                                iconName: "RemoveFilter",
                                                                                style: { color: "salmon" },
                                                                            }}
                                                                            onClick={() =>
                                                                                editGroupStore.removeAUserFromAGroup(
                                                                                    item.id
                                                                                )
                                                                            }
                                                                        />
                                                                    ) : null}
                                                                </Stack>
                                                            );
                                                        },
                                                        isPadded: true,
                                                    },
                                                ]}
                                                selectionMode={SelectionMode.none}
                                                getKey={(item, index) => (item ? item.id : index)}
                                                setKey="single"
                                                layoutMode={DetailsListLayoutMode.justified}
                                                constrainMode={ConstrainMode.horizontalConstrained}
                                                onRenderRow={(props, defaultRender) => (
                                                    <div
                                                        onMouseEnter={() => {
                                                            this.setState({ showContextualMenu: props.item.id });
                                                        }}
                                                        onMouseLeave={() => {
                                                            this.setState({ showContextualMenu: null });
                                                        }}
                                                    >
                                                        {defaultRender(props)}
                                                    </div>
                                                )}
                                                enterModalSelectionOnTouch={true}
                                                ariaLabelForSelectionColumn="Toggle selection"
                                                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                                checkButtonAriaLabel="Row checkbox"
                                                enableShimmer={editGroupStore.loading}
                                                ariaLabelForShimmer="Users being fetched"
                                                ariaLabelForGrid="User list"
                                            />
                                        )}
                                    </r-cell>
                                </r-grid>
                            </ObservablePanel>
                        </>
                    );
                }

                _renderFooter() {
                    return (
                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                            <PrimaryButton
                                onClick={() => this._onSaveChanges()}
                                iconProps={{ iconName: "Save" }}
                                text="Save"
                            />
                            <Button
                                onClick={() => this._onHidePanel()}
                                iconProps={{ iconName: "Cancel" }}
                                text="Cancel"
                            />
                        </Stack>
                    );
                }

                _onDismissErrorDialog() {
                    this.setState({
                        showErrorDialog: false,
                    });
                }

                async _onSaveChanges() {
                    const { principalContext, onSave } = this.props;
                    const { editGroupStore } = principalContext;
                    await editGroupStore.saveChanges();
                    if (onSave) onSave();
                }

                _onHidePanel() {
                    const { principalContext } = this.props;
                    const { editGroupStore } = principalContext;
                    editGroupStore.closeForm();
                }
            }
        )
    )
);
