import React, { Component } from "react";
import { observer } from "mobx-react";

import { Text } from "office-ui-fabric-react/lib/Text";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { Label } from "office-ui-fabric-react/lib/Label";
import { Toggle } from "office-ui-fabric-react/lib/Toggle";
import { Dropdown } from "office-ui-fabric-react/lib/Dropdown";

import { RichDropdown } from "../../../base/richdropdown/RichDropdown";
import { MarkdownField } from "../../../editors/components/MarkdownField";
import { ControlHierarchyPicker } from "../../../controls/containers/pickers/ControlHierarchyPicker";

export const AdvisoryEditForm = observer(
    class AdvisoryEditForm extends Component {
        render() {
            const { formData, measure } = this.props;

            return (
                <div>
                    <div className="row--xs">
                        <TextField
                            label="Title"
                            required
                            value={formData.title || ""}
                            onChange={(ev, val) => {
                                formData.title = val;
                            }}
                        />
                    </div>
                    <div className="row--xs">
                        <MarkdownField
                            label="Description"
                            required
                            value={formData.description || ""}
                            onChange={(val) => {
                                formData.description = val;
                            }}
                        />
                    </div>
                    <div className="row--xs">
                        <MarkdownField
                            label="Mitigations"
                            required
                            value={formData.mitigations || ""}
                            onChange={(val) => {
                                formData.mitigations = val;
                            }}
                        />
                    </div>

                    <div className="row--xs">
                        <ControlHierarchyPicker
                            label="Controls"
                            required
                            selected={
                                formData.control
                                    ? [
                                          {
                                              id: formData.control.id,
                                              name: formData.control.title,
                                          },
                                      ]
                                    : []
                            }
                            onControlPicked={(control) => {
                                formData.control = control
                                    ? {
                                          id: control.id,
                                          title: control.name,
                                      }
                                    : null;
                            }}
                        />
                    </div>

                    <div className="row--xs">
                        <Dropdown
                            required
                            label="Severity"
                            autoComplete="on"
                            selectedKey={formData.level}
                            onChange={(ev, op) => (formData.level = op.key)}
                            options={["Critical", "High", "Medium", "Low", "Note"].map((p) => {
                                return { key: p, text: p };
                            })}
                        />
                    </div>

                    {measure && (
                        <>
                            <div className="row--xs">
                                <Label required>Related Measure</Label>
                                <div>
                                    <Text block variant="medium">
                                        <span>{measure.title}</span>
                                    </Text>
                                </div>
                            </div>
                            {measure.type == "Boolean" && (
                                <div className="row--xs">
                                    <Toggle
                                        label="Compliant when"
                                        onText="Yes"
                                        offText="No"
                                        checked={formData.compliantWhen}
                                        onChange={(ev, checked) => {
                                            formData.compliantWhen = checked;
                                        }}
                                    />
                                </div>
                            )}
                            {false && measure.type == "Choice" && (
                                <div className="row--xs">
                                    <Dropdown
                                        label="Compliant when one of"
                                        autoComplete="on"
                                        selectedKey={formData.compliantWhen}
                                        onChange={(ev, op) => (formData.compliantWhen = op.key)}
                                        options={measure.options}
                                    />
                                </div>
                            )}
                            {measure.type == "Choice" && (
                                <div className="row--xs">
                                    <Dropdown
                                        label={`Compliant when ${formData.compliantWhenAll ? "all" : "any"} of`}
                                        autoComplete="on"
                                        selectedKeys={formData.compliantWhen}
                                        multiSelect
                                        onChange={(ev, op) => {
                                            const newSelectedItems = formData.compliantWhen ? [...formData.compliantWhen] : [];
                                            if (op.selected) {
                                                newSelectedItems.push(op.key);
                                            } else {
                                                const currIndex = newSelectedItems.indexOf(op.key);
                                                if (currIndex > -1) {
                                                    newSelectedItems.splice(currIndex, 1);
                                                }
                                            }
                                            formData.compliantWhen = newSelectedItems;
                                        }}
                                        options={measure.options}
                                    />
                                </div>
                            )}
                            {measure.type == "MultiChoice" && (
                                <>
                                    <div className="row--xs">
                                        <RichDropdown
                                            label="Selection model"
                                            selectedKey={formData.compliantWhenAll ? "All" : formData.compliantWhenAnyMatch ? "AnyNonSubSet" : "AnySubSet"}
                                            required
                                            onOptionChanged={(option) => {
                                                if (option) {
                                                    switch (option.key) {
                                                        case "All":
                                                            formData.compliantWhenAll = true;
                                                            formData.compliantWhenAnyMatch = null;
                                                            formData.compliantWhenMatchCount = null;
                                                            break;
                                                        case "AnySubSet":
                                                            formData.compliantWhenAll = false;
                                                            formData.compliantWhenAnyMatch = false;
                                                            formData.compliantWhenMatchCount = null;
                                                            break;
                                                        case "AnyNonSubSet":
                                                            formData.compliantWhenAll = false;
                                                            formData.compliantWhenAnyMatch = true;
                                                            formData.compliantWhenMatchCount = 1;
                                                            break;
                                                    }
                                                } else {
                                                    formData.compliantWhenAnyMatch = null;
                                                    formData.compliantWhenMatchCount = null;
                                                }
                                            }}
                                            options={[
                                                {
                                                    key: "All",
                                                    title: "Exact match",
                                                    description: <Text>All answers must match all the options selected below</Text>,
                                                },
                                                {
                                                    key: "AnySubSet",
                                                    title: "Partial match (Subset only)",
                                                    description: <Text>All of the answers must be from the selected options below</Text>,
                                                },
                                                {
                                                    key: "AnyNonSubSet",
                                                    title: "Partial match",
                                                    description: <Text>At least 1 of the answers must be from the selected options below</Text>,
                                                },
                                            ]}
                                        />
                                    </div>
                                    {/* <div className="row--xs">
                                        <Toggle
                                            label="Compliant when all matches"
                                            onText="All"
                                            offText="Any"
                                            checked={formData.compliantWhenAll}
                                            onChange={(ev, checked) => {
                                                formData.compliantWhenAll = checked;
                                            }}
                                        />
                                    </div>
                                    {!formData.compliantWhenAll && (
                                        <div className="row--xs">
                                            <Toggle
                                                label="Compliant when match count"
                                                onText="All"
                                                offText="Any"
                                                checked={formData.compliantWhenAnyMatch}
                                                onChange={(ev, checked) => {
                                                    formData.compliantWhenAnyMatch = checked;
                                                }}
                                            />
                                        </div>
                                    )} */}
                                    <div className="row--xs">
                                        <Dropdown
                                            label={`Compliant when ${formData.compliantWhenAll ? "all" : "any"} of`}
                                            autoComplete="on"
                                            selectedKeys={formData.compliantWhen}
                                            multiSelect
                                            onChange={(ev, op) => {
                                                const newSelectedItems = formData.compliantWhen ? [...formData.compliantWhen] : [];
                                                if (op.selected) {
                                                    newSelectedItems.push(op.key);
                                                } else {
                                                    const currIndex = newSelectedItems.indexOf(op.key);
                                                    if (currIndex > -1) {
                                                        newSelectedItems.splice(currIndex, 1);
                                                    }
                                                }
                                                formData.compliantWhen = newSelectedItems;
                                            }}
                                            options={measure.options}
                                        />
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
            );
        }
    }
);
