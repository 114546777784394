import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { ObservablePagination } from "@modules/base/components/ObservablePagination";

import { RegistryCommandBar } from "@modules/risks/components/registries/RegistryCommandBar";
import { RegistryGridView } from "@modules/risks/components/registries/RegistryGridView";
import { RegistryNewPanel } from "@modules/risks/containers/panels/RegistryNewPanel";

export const RisksRegistriesTab = observer(() => {
    const { routing, pageContext, riskStore } = useStores();
    const { registryBrowseStore, registryStore, riskRegistryNewFormStore } = riskStore;
    const { loading, pagination, keywords, registries } = registryBrowseStore;
    const { riskRegistriesRoles } = registryStore;

    useEffect(() => {
        pageContext.updateQueryStringParams(routing.location.search);

        registryBrowseStore.loadRegistries({
            page: pageContext.queryStringParams.p || 1,
            keywords: pageContext.queryStringParams.k,
        });
    }, []);

    return (
        <>
            <RegistryCommandBar
                onNewRiskRegistry={() => {
                    riskRegistryNewFormStore.show();
                }}
                keywords={keywords}
                onSearchChange={(keywords) => {
                    routing.push(`/settings/risk-registries${keywords ? `?k=${encodeURIComponent(keywords)}` : ""}`);
                }}
            />

            <RegistryGridView riskRegistriesRoles={riskRegistriesRoles} registries={registries} loading={loading} />

            <ObservablePagination
                {...pagination}
                onPageChange={(index) => {
                    routing.push(
                        `/settings/risk-registries?p=${index + 1}${
                            pageContext.queryStringParams.k
                                ? `&k=${encodeURIComponent(pageContext.queryStringParams.k)}`
                                : ""
                        }`
                    );
                }}
            />
            <RegistryNewPanel
                onCreateNewRiskRegistry={() => {
                    registryBrowseStore.loadRegistries({
                        page: pageContext.queryStringParams.p || 1,
                        keywords: pageContext.queryStringParams.k,
                    });
                }}
            />
        </>
    );
});
