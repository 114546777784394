import React, { Component } from "react";
import { observer } from "mobx-react";

import { Toggle } from "office-ui-fabric-react/lib/Toggle";

import { BigTextField } from "../../../base/components/BigTextField";
import { PrincipalPicker } from "../../../identity/containers/PrincipalPicker";

export const PortfolioEditForm = observer(
    class PortfolioEditForm extends Component {
        render() {
            const { formData } = this.props;
            return (
                <div>
                    <r-grid columns="2">
                        <r-cell span="2">
                            <BigTextField
                                label="Name"
                                required
                                placeholder="Please enter a portfolio name"
                                value={formData.name}
                                transparent
                                onChange={(ev, value) => (formData.name = value)}
                            />
                        </r-cell>
                        <r-cell span="2">
                            <Toggle
                                label="Status"
                                onText="Enabled"
                                offText="Disabled"
                                checked={formData.isActive}
                                onChange={(_, checked) => {
                                    formData.isActive = checked;
                                }}
                            />
                        </r-cell>
                        <r-cell span="1">
                            <PrincipalPicker
                                label={`Portfolio Lead`}
                                selected={formData.portfolioLead}
                                onSelected={(user) => {
                                    formData.portfolioLead = user;
                                }}
                            />
                        </r-cell>
                        <r-cell span="1">
                            <PrincipalPicker
                                label={`Portfolio Champion`}
                                selected={formData.portfolioChampion}
                                onSelected={(user) => {
                                    formData.portfolioChampion = user;
                                }}
                            />
                        </r-cell>
                        <r-cell span="1">
                            <PrincipalPicker
                                label={`Assurance Primary`}
                                selected={formData.assurancePrimary}
                                onSelected={(user) => {
                                    formData.assurancePrimary = user;
                                }}
                            />
                        </r-cell>
                        <r-cell span="1">
                            <PrincipalPicker
                                label={`Assurance Secondary`}
                                selected={formData.assuranceSecondary}
                                onSelected={(user) => {
                                    formData.assuranceSecondary = user;
                                }}
                            />
                        </r-cell>
                    </r-grid>
                    <r-grid columns="2">
                        <r-cell span="1">
                            <PrincipalPicker
                                label={`Data Privacy Lead`}
                                selected={formData.dataPrivacyLead}
                                onSelected={(user) => {
                                    formData.dataPrivacyLead = user;
                                }}
                            />
                        </r-cell>
                    </r-grid>
                    <r-grid columns="2">
                        <r-cell span="1">
                            <PrincipalPicker
                                label={`Security Architect`}
                                selected={formData.securityArchitect}
                                onSelected={(user) => {
                                    formData.securityArchitect = user;
                                }}
                            />
                        </r-cell>
                    </r-grid>
                    <r-grid columns="2">
                        <r-cell span="1">
                            <PrincipalPicker
                                label={`Risk Manager`}
                                selected={formData.riskManager}
                                onSelected={(user) => {
                                    formData.riskManager = user;
                                }}
                            />
                        </r-cell>
                    </r-grid>
                </div>
            );
        }
    }
);
