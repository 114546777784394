import React from "react";

import { View } from "@react-pdf/renderer";

import { Paragraph } from "@ui/pdf/Paragraph";
import { Table, TableHeader, TableBody, TableCell, DataTableCell } from "@ui/pdf/Table";

import { AdvisorySeverity } from "@modules/assessments/components/pdf/AdvisorySeverity";
import { AdvisoryCompliance } from "@modules/assessments/components/pdf/AdvisoryCompliance";

export const AdvisoryTable = ({ advisories, evidences }) => {
    return (
        <Table data={advisories}>
            <TableHeader>
                <TableCell weighting={0.7}>Advisory</TableCell>
                <TableCell weighting={0.08}>Severity</TableCell>
                <TableCell weighting={0.12}>Initial Compliance</TableCell>
                <TableCell weighting={0.12}>Final Compliance</TableCell>
            </TableHeader>
            <TableBody>
                <DataTableCell
                    weighting={0.7}
                    getContent={(r) => {
                        const ae = evidences.filter((e) => e.advisoryId === r.id);

                        return (
                            <View>
                                <Paragraph style={{ fontSize: 11, marginBottom: 12 }}>{r.title}</Paragraph>
                                {/* <Paragraph style={{ color: "#444444" }}>
                                    {r.references
                                        .map((r) => r.title)
                                        .reverse()
                                        .join(" / ")}
                                </Paragraph> */}
                                <Paragraph style={{ color: "#444444" }}>{r.description}</Paragraph>

                                {ae.length > 0 ? (
                                    <Paragraph style={{ color: "#444444" }}>Evidence ({ae.length})</Paragraph>
                                ) : (
                                    <Paragraph style={{ color: "#444444" }}>No evidence provided</Paragraph>
                                )}

                                {ae.map((e) => (
                                    <Paragraph key={e.id} style={{ color: "#444444" }}>
                                        {e.type} - {e.type == "Document" && `${e.document.name}${e.document.extension} `}
                                        {e.notes}
                                    </Paragraph>
                                ))}
                            </View>
                        );
                    }}
                />
                <DataTableCell weighting={0.08} getContent={(r) => <AdvisorySeverity advisory={r} />} />
                <DataTableCell weighting={0.12} getContent={(r) => <AdvisoryCompliance compliance={r.initialCompliance} />} />
                <DataTableCell weighting={0.12} getContent={(r) => <AdvisoryCompliance compliance={r.finalCompliance} />} />
            </TableBody>
        </Table>
    );
};
