import React from "react";
import { observer } from "mobx-react";

import { TextField } from "@ui/elements/TextField";

export const LogOutputActivityEditor = observer(({ formData }) => {
    return (
        <div>
            <r-grid columns="2">
                <r-cell span="2">
                    <TextField
                        label="Message"
                        required
                        multiline
                        style={{ minHeight: 150 }}
                        value={formData.settings.message || ""}
                        onChange={(ev, val) => {
                            formData.settings.message = val;
                        }}
                    />
                </r-cell>
            </r-grid>
        </div>
    );
});
