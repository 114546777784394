import { observable, action, computed, toJS } from "mobx";

import { RiskService } from "../../../api/risks";

import { isNotNullOrEmpty } from "../../editors/components/RichTextField";
import { RiskStore } from "./RiskStore";

interface ResponseHandle {
    resolve: (value: any) => void;
    reject: (value?: any) => void;
}

export class RiskEditFormStore {
    private riskService: RiskService;
    public parentStore: RiskStore;
    private responseHandle?: ResponseHandle;

    @observable public visible: boolean = false;
    @observable public formData: any;
    @observable public formOptions: any;

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
    }

    @action
    public show(options) {
        this.visible = true;

        this.formOptions = {
            pageTitle: options.pageTitle || `${options.risk.code} - ${options.risk.title}`,
            pageType: options.pageType || options.risk.type.name,
            typeName: options.typeName || `${options.risk.type.name}`,
            disableRegistry: true,
            disableTargetType: true,
            disableTarget: true,
            disableTrigger: !!options.risk.assessment,
            disableAssessment: !!options.risk.assessment,
            disablePortfolio: !!options.risk.assessment,
        };

        this.formData = { ...toJS(options.risk) };

        return new Promise<any>((resolve, reject) => {
            this.responseHandle = { resolve, reject };
        });
    }

    @action
    public hide(_) {
        this.visible = false;
        this.formData = null;
        this.formOptions = null;
        this.responseHandle = null;
    }

    @action
    public resolve(formData) {
        this.responseHandle.resolve(toJS(formData));
    }

    @action
    public reject(error) {
        this.responseHandle.reject(toJS(error));
    }

    @computed
    public get isValid() {
        if (!this.formData) {
            return false;
        }

        return (
            this.formData.id &&
            this.formData.title &&
            this.formData.registry &&
            this.formData.registry.id &&
            this.formData.lifecycle &&
            this.formData.lifecycle.businessContact &&
            this.formData.lifecycle.businessContact.id &&
            this.formData.lifecycle.technicalContact &&
            this.formData.lifecycle.technicalContact.id &&
            isNotNullOrEmpty(this.formData.description) &&
            isNotNullOrEmpty(this.formData.context)
        );
    }
}
