import React, { Component } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { Icon } from "office-ui-fabric-react/lib/Icon";
import { Text } from "office-ui-fabric-react/lib/Text";

import { BoxPanel } from "../../../base/components/BoxPanel";

import { TriageFormField } from "../core/TriageFormField";

const propTableClassName = cx(
    "cygraph-TriageSummaryItem-table",
    css`
        margin: 0;
        width: 100%;
        border-collapse: collapse;

        & th {
            text-align: left;
            padding: 7px 15px 7px 0;
            font-weight: normal;
            width: 225px;
            min-width: 150px;
            font-size: var(--font-size-xxs);
            border-bottom: 1px solid var(--border-color);
            color: var(--text-color-light);
        }
        & td {
            text-align: left;
            padding: 6px 0;
            border-bottom: 1px solid var(--border-color);
        }
    `
);

const moduleTableClassName = cx(
    "cygraph-TriageSummaryItem-module",
    css`
        background: var(--background-color) !important;

        & strong {
            color: var(--primary-selection-color);
        }
    `
);

export const TriageSummaryItem = observer(
    class TriageSummaryItem extends Component {
        render() {
            const { configuration, formData, isVisible } = this.props;

            const modules = configuration.requirements.filter((m) => m.type === "Module");

            return (
                <div className="ms-u-slideRightIn40">
                    <r-grid columns="6" columns-s="1">
                        <r-cell span="4" span-s="1">
                            <Text variant="large" className="row--xs" block>
                                What you told us...
                            </Text>
                            {configuration.pages.map((page, pi) => (
                                <table key={pi} className={propTableClassName} border="0">
                                    {page.sections.map((section, si) => (
                                        <tbody key={si}>
                                            {section.fields.map((field, fi) => {
                                                const visible = isVisible(formData, field, configuration);
                                                return (
                                                    visible && (
                                                        <tr key={fi}>
                                                            <th>{field.summaryLabel || field.label}</th>
                                                            <td>
                                                                <TriageFormField readOnly={true} {...{ field, section, page, ...this.props }} />
                                                            </td>
                                                        </tr>
                                                    )
                                                );
                                            })}
                                        </tbody>
                                    ))}
                                </table>
                            ))}
                        </r-cell>
                        <r-cell span="2" span-s="1">
                            <BoxPanel className={moduleTableClassName} padding>
                                <Text variant="large" block>
                                    Next Steps!
                                </Text>
                                <br />
                                <p>
                                    {(!configuration.summary || !configuration.summary.hideTier) && (
                                        <span>
                                            Based on your responses, we have categorised your system to be in the security <strong>{formData.tier}</strong>
                                            .&nbsp;
                                        </span>
                                    )}
                                    <span>You must complete the following modules in the full assessment.</span>
                                </p>
                                <br />
                                <table className={propTableClassName} border="0">
                                    <tbody>
                                        {modules.map((m, mi) => (
                                            <tr key={mi}>
                                                <th>{m.name}</th>
                                                <td>
                                                    <Icon iconName={this._isModuleRequired(formData, m, configuration) ? "CheckboxComposite" : "Checkbox"} />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </BoxPanel>
                        </r-cell>
                    </r-grid>
                </div>
            );
        }

        _isModuleRequired = ($, module, configuration) => {
            if (!module.condition) {
                return true;
            }

            return eval(module.condition);
        };
    }
);
