import React, { useRef, useState, useCallback, useEffect } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";
import { useId } from "@fluentui/react-hooks";

import { Stack } from "@ui/elements/Stack";
import { ExtendedLabel } from "@ui/elements/ExtendedLabel";
import { IconButton } from "@ui/elements/Button";
import { TextField } from "@ui/elements/TextField";
import { Callout } from "@ui/elements/Callout";
import { DurationField, decodeDuration } from "@ui/elements/DurationField";

const rootClassName = cx(
    "cygraph-DurationRangeField-root",
    css`
        & .trigger input {
            cursor: pointer;
        }
    `
);

const calloutClassName = cx(
    "cygraph-DurationRangeField-callout",
    css`
        background-color: var(--white);
        padding: var(--spacing-xs);
        width: 320px;
        min-width: 300px;
    `
);

export const DurationRangeField = observer(
    ({
        className,
        required,
        disabled,
        label,
        description,
        value,
        onChange,
        multiple,
        allowClear,
        moreThanProps,
        lessThanProps,
    }) => {
        const buttonRef = useRef();
        const [visible, setVisible] = useState(false);
        const [text, setText] = useState(null);
        const pickerId = useId("durationRangeField");

        const onUpdateDurationRange = useCallback(
            (valUpdate) => {
                onChange({ moreThan: null, lessThan: null, ...value, ...valUpdate });
            },
            [value, onChange]
        );

        const onInputClick = useCallback(() => {
            setVisible(true);
        }, []);

        const onDismiss = useCallback(() => {
            setVisible(false);
        }, []);

        useEffect(() => {
            if (value) {
                const parts = [];
                if (value.moreThan) {
                    const mt = decodeDuration(value.moreThan);
                    if (mt && mt.text) {
                        parts.push(`more than ${mt.text}`);
                    }
                }
                if (value.lessThan) {
                    const lt = decodeDuration(value.lessThan);
                    if (lt && lt.text) {
                        parts.push(`less than ${lt.text}`);
                    }
                }
                setText(parts.join(" and "));
            } else {
                setText(null);
            }
        }, [value]);

        return (
            <Stack className={cx(rootClassName, className)}>
                <ExtendedLabel
                    label={label}
                    required={required}
                    disabled={disabled}
                    description={description}
                    htmlFor={pickerId}
                >
                    {allowClear && (multiple ? value && value.length > 0 : !!value) && (
                        <IconButton
                            styles={{ root: { width: 24, height: 24 }, icon: { fontSize: 13, lineHeight: 13 } }}
                            iconProps={{ iconName: "ClearFilter" }}
                            onClick={() => onChange(multiple ? [] : null)}
                        />
                    )}
                </ExtendedLabel>
                <div className="trigger" ref={buttonRef} disabled={disabled} role="button">
                    <TextField
                        id={pickerId}
                        readOnly
                        value={text || ""}
                        onClick={onInputClick}
                        iconProps={{ iconName: "ChevronDown" }}
                    />
                </div>
                {buttonRef.current && (
                    <Callout
                        isBeakVisible={false}
                        gapSpace={0}
                        hidden={!visible}
                        className={calloutClassName}
                        target={buttonRef.current}
                        onDismiss={onDismiss}
                    >
                        <Stack tokens={{ childrenGap: 5 }}>
                            {moreThanProps && moreThanProps.enabled !== false && (
                                <DurationField
                                    label="More than"
                                    value={value ? value.moreThan : null}
                                    onChange={(val, text) =>
                                        onUpdateDurationRange({ moreThan: val }, { moreThan: text })
                                    }
                                    {...moreThanProps}
                                />
                            )}
                            {lessThanProps && lessThanProps.enabled !== false && (
                                <DurationField
                                    label="Less than"
                                    value={value ? value.lessThan : null}
                                    onChange={(val, text) =>
                                        onUpdateDurationRange({ lessThan: val }, { lessThan: text })
                                    }
                                    {...lessThanProps}
                                />
                            )}
                        </Stack>
                    </Callout>
                )}
            </Stack>
        );
    }
);
