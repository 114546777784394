import React, { Component } from "react";
import { observer } from "mobx-react";
import Moment from "react-moment";

import { Text } from "office-ui-fabric-react/lib/Text";
import { MessageBar } from "office-ui-fabric-react/lib/MessageBar";
import { DetailsListLayoutMode, Selection, SelectionMode, ConstrainMode } from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { ContextualMenu } from "office-ui-fabric-react/lib/ContextualMenu";
import { Icon } from "office-ui-fabric-react/lib/Icon";

import { BoxPanel } from "../../../base/components/BoxPanel";
import { RoutingLink } from "../../../base/components/RoutingLink";
import { WrappedContent } from "../../../base/components/WrappedContent";

export const PatternGridView = observer(
    class PatternGridView extends Component {
        constructor(props) {
            super(props);

            this.state = {};

            this._selection = new Selection({
                onSelectionChanged: () => {
                    this.setState({
                        selectionDetails: this._getSelectionDetails(),
                    });
                },
            });

            this._columns = [
                {
                    key: "name",
                    name: "Name",
                    fieldName: "name",
                    minWidth: 310,
                    isRowHeader: true,
                    isResizable: true,
                    isSorted: true,
                    isSortedDescending: false,
                    sortAscendingAriaLabel: "Sorted A to Z",
                    sortDescendingAriaLabel: "Sorted Z to A",
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return (
                            <WrappedContent>
                                <RoutingLink
                                    url={`/settings/patterns/browse/${item.id}`}
                                    onClick={() => this.props.onPatternClick && this.props.onPatternClick(item)}
                                    onClickEnabled={this.props.onPatternClick}
                                >
                                    {item.name}
                                </RoutingLink>
                                <br />
                                {item.tags && <Text variant="small">{item.tags}</Text>}
                            </WrappedContent>
                        );
                    },
                    isPadded: true,
                },
                {
                    key: "version",
                    name: "Version",
                    fieldName: "version",
                    minWidth: 100,
                    maxWidth: 150,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return (
                            <div>
                                <span>{item.currentVersion}</span>
                                <br />
                                {item.publishedVersion && item.currentVersion !== item.publishedVersion && (
                                    <span title={`Published version is ${item.publishedVersion}`}>
                                        <Icon iconName="ReadingMode" />
                                    </span>
                                )}
                            </div>
                        );
                    },
                    isPadded: true,
                },
                {
                    key: "author",
                    name: "Provider",
                    fieldName: "author",
                    minWidth: 150,
                    maxWidth: 180,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return <span>{item.author}</span>;
                    },
                    isPadded: true,
                },
                {
                    key: "category",
                    name: "Category",
                    fieldName: "category",
                    minWidth: 130,
                    maxWidth: 160,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return <span>{item.category}</span>;
                    },
                    isPadded: true,
                },
                {
                    key: "modified",
                    name: "Date Modified",
                    fieldName: "modified",
                    minWidth: 100,
                    maxWidth: 130,
                    isResizable: true,
                    onColumnClick: this._onColumnClick,
                    data: "date",
                    onRender: (item) => {
                        return <Moment utc date={item.modified} fromNow />;
                    },
                    isPadded: true,
                },
            ];
        }

        render() {
            const { patterns, loading, className, onPatternClick } = this.props;
            const { contextualMenuProps } = this.state;

            return (
                <BoxPanel shadow>
                    <ShimmeredDetailsList
                        items={patterns}
                        compact={false}
                        columns={this._columns}
                        selectionMode={SelectionMode.none}
                        getKey={this._getKey}
                        setKey="single"
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.horizontalConstrained}
                        isHeaderVisible={true}
                        selection={this._selection}
                        selectionPreservedOnEmptyClick={true}
                        onItemInvoked={onPatternClick}
                        //onItemContextMenu={(item, index, ev) => this._onItemContextMenu(item, index, ev) }
                        enterModalSelectionOnTouch={true}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        checkButtonAriaLabel="Row checkbox"
                        enableShimmer={loading}
                        removeFadingOverlay={true}
                        shimmerLines={10}
                        ariaLabelForShimmer="Patterns being fetched"
                        ariaLabelForGrid="Pattern list"
                    />

                    {!loading && !patterns.length && <MessageBar>There are no matching patterns.</MessageBar>}

                    {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}
                </BoxPanel>
            );
        }

        _getKey(item, index) {
            return item ? item.id : index;
        }

        _getSelectionDetails() {
            const selectionCount = this._selection.getSelectedCount();

            switch (selectionCount) {
                case 0:
                    return "No items selected";
                case 1:
                    return "1 item selected: " + this._selection.getSelection()[0].name;
                default:
                    return `${selectionCount} items selected`;
            }
        }

        _onColumnClick(ev, column) {
            console.log(column);
        }

        _onItemContextMenu(item, index, ev) {
            const contextualMenuProps = {
                target: ev.target,
                items: [
                    {
                        key: "text",
                        name: `${this._selection.getSelectedCount()} selected`,
                    },
                ],
                onDismiss: () => {
                    this.setState({
                        contextualMenuProps: undefined,
                    });
                },
            };

            if (index > -1) {
                this.setState({
                    contextualMenuProps: contextualMenuProps,
                });
            }

            return false;
        }
    }
);
