import React from "react";
import { observer } from "mobx-react";
import { cx } from "emotion";

import { Draggable } from "react-beautiful-dnd";

export const ModuleIndexMeasure = observer(({ measure, index, globalIndex, isDragDisabled, onMeasureClick }) => {
    return (
        <div>
            <Draggable draggableId={measure.id} index={index} isDragDisabled={isDragDisabled} type="measure">
                {(provided, snapshot) => (
                    <div className={cx("draggable", snapshot.isDragging && "dragging")} {...provided.draggableProps} ref={provided.innerRef}>
                        <a role="button" className="question question--success">
                            <div className="question__count__wrapper" {...provided.dragHandleProps}>
                                <div className="question__count">{String(globalIndex).padStart(2, "0")}</div>
                            </div>
                            <div>
                                <div className="question__label" onClick={() => onMeasureClick({ measure })}>
                                    {measure.title}
                                </div>
                            </div>
                        </a>
                    </div>
                )}
            </Draggable>
        </div>
    );
});
