import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";
import { withRouter } from "react-router";

import { JsonQueryString } from "@modules/utils/JsonQueryString";
import { ObservablePagination } from "@baseComponents/ObservablePagination";

import { AssessmentGridView } from "@assessments/components/core/AssessmentGridView";
import { AssessmentCommandBar } from "@modules/assessments/components/core/AssessmentCommandBar";

export const AssetAssessmentsTab = withRouter(
    observer(({ match, asset }) => {
        const { assetStore, routing, pageContext } = useStores();
        const { loading, assessments, pagination, query } = assetStore.assessmentStore;

        useEffect(() => {
            if (asset) {
                pageContext.updateQueryStringParams(routing.location.search);
                assetStore.assessmentStore.loadAssessments({
                    page: pageContext.queryStringParams.p || 1,
                    query: JsonQueryString.decode(pageContext.queryStringParams.query),
                    assetId: asset.id,
                });
            }
        }, [asset, match]);

        return (
            <>
                <AssessmentCommandBar
                    loading={loading}
                    filterQuery={query}
                    filterContext={{
                        processing: loading,
                        resultCount: pagination.totalItemCount,
                    }}
                    onFilterChange={(q, f) => {
                        routing.push(JsonQueryString.build(`/assets/browse/${asset.id}/assessments`, q));
                    }}
                    onNewTriage={() => routing.push(`/assurance/triage?assetId=${asset.id}`)}
                    onExport={() => {
                        assetStore.assessmentStore.exportAssessments({});
                    }}
                />

                <AssessmentGridView
                    assessments={assessments}
                    loading={loading}
                    onAssessmentClick={(assessment) => routing.push(`/assurance/browse/${assessment.id}`)}
                />

                <ObservablePagination
                    {...pagination}
                    onPageChange={(index) => {
                        routing.push(JsonQueryString.build(match.url, query, index + 1));
                    }}
                />
            </>
        );
    })
);
