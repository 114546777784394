import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import moment from "moment";
import { useStores } from "../../../../hooks/useStores";

import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";

import { PDFViewer, Document, View } from "@react-pdf/renderer";

import { Paragraph } from "@ui/pdf/Paragraph";
import { Heading2 } from "@ui/pdf/Heading";
import { StandardPage } from "@ui/pdf/Page";

import { AppPage } from "@modules/layout/containers/AppPage";

import { MetadataPropertyTable } from "@modules/assessments/components/pdf/MetadataPropertyTable";
import { RequestPropertyTable } from "@modules/assessments/components/pdf/RequestPropertyTable";
import { ContactPropertyTable } from "@modules/assessments/components/pdf/ContactPropertyTable";
import { TimelinePropertyTable } from "@modules/assessments/components/pdf/TimelinePropertyTable";
import { AssessmentSummary } from "@modules/assessments/components/pdf/AssessmentSummary";
import { RiskTable } from "@modules/assessments/components/pdf/RiskTable";
import { ExceptionTable } from "@modules/assessments/components/pdf/ExceptionTable";
import { AdvisoryTable } from "@modules/assessments/components/pdf/AdvisoryTable";
import { DocumentTable } from "@modules/assessments/components/pdf/DocumentTable";
import { AssessmentCoverPage } from "@modules/assessments/components/pdf/AssessmentCoverPage";

export const AssessmentReportPage = withRouter(
    observer(({ match }) => {
        const [rendered, setRendered] = useState(false);
        const { assessmentStore } = useStores();
        const { reportStore } = assessmentStore;
        const {
            loading,
            error,
            breadcrumb,
            assessment,
            workItem,
            schema,
            modules,
            evidences,
            advisories,
            risks,
            exceptions,
            documents,
            hasRisks,
            hasExceptions,
        } = reportStore;

        useEffect(() => {
            reportStore.loadAssessment(match.params.id);
        }, [match.params.id, reportStore]);

        const header = assessment ? `${assessment.code} - Confidential` : null;

        return (
            <AppPage error={error} breadcrumb={breadcrumb} loading={loading} hasSidebar={false} className="full-width">
                {!rendered && (
                    <div style={{ height: "100%", width: "100%", padding: "150px" }}>
                        <Spinner size={SpinnerSize.large} label="Fetching and rendering Security Assurance Report, this may take a while ..." />
                    </div>
                )}
                {!loading && assessment && (
                    <PDFViewer style={{ height: "100%", width: "100%", border: 0, visibility: rendered ? "visible" : "hidden" }}>
                        <Document
                            title={`${assessment.code} - ${assessment.title}`}
                            author={assessment.closedBy ? assessment.closedBy.name : null}
                            subject="End of Security Assurance Report"
                            creator="Zerodai"
                            producer="Zerodai"
                            onRender={() => setRendered(true)}
                        >
                            <AssessmentCoverPage workItem={workItem} assessment={assessment} />

                            <StandardPage header={header}>
                                <Heading2>Summary</Heading2>
                                <Paragraph primary style={{ marginBottom: 20 }}>
                                    A {workItem.product.name} was requested by {workItem.requestedBy.name} for the asset {assessment.target.name} on{" "}
                                    {moment.utc(workItem.created).format("Do MMM YYYY")}. An assurance assessment has been carried out to help ensure project
                                    has appropriate level of information security and that the level of risk is articulated and understood. This report
                                    summarises the work undertaken as part of the {workItem.product.name} and any related artifacts produced.
                                </Paragraph>
                                <MetadataPropertyTable workItem={workItem} assessment={assessment} />
                                <AssessmentSummary
                                    assessment={assessment}
                                    showException={hasExceptions || exceptions.length > 0}
                                    showRisk={hasRisks || risks.length > 0}
                                />
                            </StandardPage>

                            <StandardPage header={header}>
                                <Heading2>Scope</Heading2>
                                <Paragraph secondary>Following details were captured from the requester during initial triage phase.</Paragraph>
                                <RequestPropertyTable workItem={workItem} schema={schema} />
                                <View wrap={false}>
                                    <Paragraph secondary>Following key contacts were identified from the assessment.</Paragraph>
                                    <ContactPropertyTable workItem={workItem} assessment={assessment} />
                                </View>
                                <View wrap={false}>
                                    <Paragraph secondary>Timeline of major activities within the assessment.</Paragraph>
                                    <TimelinePropertyTable workItem={workItem} assessment={assessment} />
                                </View>
                            </StandardPage>

                            {(hasRisks || risks.length > 0) && (
                                <StandardPage header={header} orientation="landscape">
                                    <Heading2>Risks</Heading2>
                                    <Paragraph secondary>
                                        A risk is a condition that could cause exposure, loss of critical assets and sensitive information, reputation or
                                        regulatory harm to the organisation as a result of a cyber attack or breach. Risks must be fully understood by the
                                        accountable business stakeholders, who are responsible for making the decision on how to address the risk.
                                    </Paragraph>
                                    {risks.length ? <RiskTable risks={risks} /> : <Paragraph>No risks raised as part of this assessment.</Paragraph>}
                                </StandardPage>
                            )}
                            {(hasExceptions || exceptions.length > 0) && (
                                <StandardPage header={header} orientation="landscape">
                                    <Heading2>Exceptions</Heading2>
                                    <Paragraph secondary>
                                        An exception is a condition that is not aligned with formal security expectations as defined by company policy, which
                                        has the potential to become a risk. These are generally time-bound and there is an expectation that exceptions will be
                                        addressed and closed down before the agreed expiration date.
                                    </Paragraph>
                                    {exceptions.length ? (
                                        <ExceptionTable exceptions={exceptions} />
                                    ) : (
                                        <Paragraph>No exceptions were raised as part of this assessment.</Paragraph>
                                    )}
                                </StandardPage>
                            )}
                            <StandardPage header={header} orientation="landscape">
                                <Heading2>Advisories</Heading2>
                                <Paragraph secondary>
                                    Advisories are our key set of standards that provide the minimum baseline of security for your project. Initial compliance
                                    ratings are scored based on your response to the assurance questionnaire, while the Final Compliance score is marked by the
                                    assurance team following any remediation work. Any advisories marked non-compliant in the final compliance rating require an
                                    exception, risk or further remediation work to be agreed.
                                </Paragraph>
                                {advisories.length ? (
                                    <AdvisoryTable advisories={advisories} evidences={evidences} />
                                ) : (
                                    <Paragraph>No advisories were generated as part of this assessment.</Paragraph>
                                )}
                            </StandardPage>
                            <StandardPage header={header} orientation="landscape">
                                <Heading2>Documents</Heading2>
                                <Paragraph secondary>
                                    Set of documents uploaded as part of evidence for work undertaken or evidence of compliance to advisories.
                                </Paragraph>
                                {documents.length ? (
                                    <DocumentTable documents={documents} />
                                ) : (
                                    <Paragraph>No documents were found in this assessment.</Paragraph>
                                )}
                            </StandardPage>
                        </Document>
                    </PDFViewer>
                )}
            </AppPage>
        );
    })
);
