import React, { Component } from "react";
import { observer } from "mobx-react";

import { Sidebar } from "../../../base/components/Sidebar";
import { SidebarProperties } from "../../../base/components/SidebarProperties";
import { SidebarProperty } from "../../../base/components/SidebarProperty";

export const PatternSidebar = observer(
    class PatternSidebar extends Component {
        render() {
            const { pattern, manifest } = this.props;
            return (
                <Sidebar>
                    <SidebarProperties title="Details" icon="List" open>
                        <SidebarProperty label="Target" value={pattern.target} />
                        <SidebarProperty label="Version" value={manifest.version} />
                        <SidebarProperty label="Category" value={manifest.category} />
                        <SidebarProperty label="Provider" value={manifest.author} />
                        <SidebarProperty label="Snippet" value={manifest.isSnippet ? "Yes" : "No"} />
                        <SidebarProperty label="URL" value="Website" url={manifest.url} />
                    </SidebarProperties>
                    <SidebarProperties title="About" icon="Info" open>
                        <SidebarProperty label="Created" value={pattern.created} type="datetime" />
                        <SidebarProperty value={pattern.createdBy} type="principal" />
                        <SidebarProperty label="Last Modified" value={pattern.modified} type="datetime" />
                        <SidebarProperty value={pattern.modifiedBy} type="principal" />
                    </SidebarProperties>
                </Sidebar>
            );
        }
    }
);
