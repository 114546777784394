import React from "react";
import { observer } from "mobx-react";
import Moment from "react-moment";
import moment from "moment";

import { Text } from "@ui/elements/Text";
import { PersonaSize } from "@ui/elements/Persona";
import { MessageBar } from "@ui/elements/MessageBar";
import { FontIcon } from "@ui/elements/Icon";
import { Label } from "@ui/elements/Label";

import { WrappedContent } from "@modules/base/components/WrappedContent";
import { UserHoverCard } from "@modules/identity/containers/utilities/UserHoverCard";
import { PhotoPersona } from "@modules/identity/containers/utilities/PhotoPersona";
import { ObservableSeparator } from "@base/components/ObservableSeparator";

import { WorkflowHistoryActivityGridView } from "@modules/workflows/components/core/WorkflowHistoryActivityGridView";

export const WorkflowHistoryViewer = observer((props) => {
    const { className, history, loading, activities } = props;

    return (
        <div>
            <br />
            <r-grid columns="2">
                <r-cell>
                    <div className="row--xs">
                        <Label>Started at</Label>
                        <Moment utc date={history.started} format="Do MMM YYYY, h:mm:ss a" />
                    </div>
                </r-cell>
                <r-cell>
                    <div className="row--xs">
                        <Label>Completed at</Label>
                        <Moment utc date={history.completed} format="Do MMM YYYY, h:mm:ss a" />
                    </div>
                </r-cell>
                <r-cell>
                    <div className="row--xs">
                        <Label>Event</Label>
                        <FontIcon iconName="LightningBolt" /> {history.triggerEvent}
                    </div>
                </r-cell>
                <r-cell>
                    <div className="row--xs">
                        <Label>Triggered</Label>
                        <UserHoverCard principal={history.actor}>
                            <PhotoPersona
                                principalId={history.actor ? history.actor.id : null}
                                size={PersonaSize.size28}
                                text={history.actor ? history.actor.name : "Unassigned"}
                                showUnknownPersonaCoin={!history.actor}
                                showSecondaryText={history.actor}
                                secondaryText={moment.utc(history.created).format("Do MMM YYYY, h:mm:ss a")}
                            />
                        </UserHoverCard>
                    </div>
                </r-cell>
                <r-cell>
                    <div className="row--xs">
                        <Label>Status</Label>
                        {history.status}
                    </div>
                </r-cell>
                <r-cell span="2">
                    <div className="row--xs">
                        <Label>Message</Label>
                        <WrappedContent>
                            <span>{history.message}.</span>
                        </WrappedContent>
                    </div>
                </r-cell>
                <r-cell span="2">
                    <ObservableSeparator className="row--s">Actions</ObservableSeparator>
                    <WorkflowHistoryActivityGridView activities={activities} />
                </r-cell>
            </r-grid>
        </div>
    );
});
