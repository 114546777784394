import React from "react";
import moment from "moment";

import { Text, View } from "@react-pdf/renderer";

import { Table, TableHeader, TableBody, TableCell, DataTableCell } from "@ui/pdf/Table";
import { InternalLink } from "@ui/pdf/Link";
import { Paragraph } from "@ui/pdf/Paragraph";
import { RichTextViewer } from "@ui/pdf/RichTextViewer";

export const ExceptionTable = ({ exceptions }) => {
    return (
        <Table data={exceptions}>
            <TableHeader>
                <TableCell weighting={0.08}>Id</TableCell>
                <TableCell weighting={0.64}>Title</TableCell>
                <TableCell weighting={0.1}>Net Risk</TableCell>
                <TableCell weighting={0.18}>Status</TableCell>
            </TableHeader>
            <TableBody>
                <DataTableCell weighting={0.08} getContent={(r) => <InternalLink src={`/exceptions/browse/${r.id}`}>{r.code}</InternalLink>} />
                <DataTableCell
                    weighting={0.64}
                    getContent={(r) => (
                        <View>
                            <Paragraph style={{ fontSize: 11, marginBottom: 12 }}>{r.title}</Paragraph>
                            {r.description && <RichTextViewer value={r.description} style={{ color: "#444444" }} />}
                            {r.context && <RichTextViewer value={r.context} style={{ color: "#444444" }} />}
                        </View>
                    )}
                />
                <DataTableCell weighting={0.1} getContent={(r) => <Text>{r.review ? r.review.netScore.score : "None"}</Text>} />
                <DataTableCell
                    weighting={0.18}
                    getContent={(r) => (
                        <View>
                            <Paragraph style={{ marginBottom: 12 }}>{r.lifecycle.subStatus}</Paragraph>
                            {r.review && <Paragraph>Next review {moment.utc(r.review.nextReviewDue).format("Do MMM YYYY")}</Paragraph>}
                        </View>
                    )}
                />
            </TableBody>
        </Table>
    );
};
