import React, { useState, useRef, useCallback } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { Callout, DirectionalHint } from "office-ui-fabric-react/lib/Callout";
import { Label } from "office-ui-fabric-react/lib/Label";
import { FontIcon } from "office-ui-fabric-react/lib/Icon";

import { IconButton } from "@ui/elements/Button";
import { SearchBox } from "@ui/elements/SearchBox";

import { IconDescriptors } from "./IconDescriptors";
import { ObservableSeparator } from "./ObservableSeparator";

const rootClassName = cx(
    "cygraph-IconPicker-root",
    css`
        & .input {
            width: 80px;
            height: 80px;
            box-shadow: none;
            border-width: 1px;
            border-style: solid;
            border-color: var(--black);
            border-radius: 2px;
            background: var(--white);
            cursor: pointer;
            position: relative;

            &.disabled {
                cursor: initial;
                background: var(--background-color);
            }

            & .icon {
                width: 80px;
                height: 80px;
                line-height: 80px;
                text-align: center;
                font-size: 45px;
            }

            & button {
                position: absolute;
                bottom: 0;
                right: 0;
                height: 20px;
                width: 20px;
            }
        }
    `
);

const calloutClassName = cx(
    "cygraph-IconPicker-callout",
    css`
        & .wrapper {
            width: 240px;
            min-height: 200px;
            height: 240px;
        }

        & .group .icons {
            flex-direction: row;
            flex-wrap: wrap;
            display: flex;
            align-items: center;
        }

        & .icon {
            width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            font-size: 30px;
            &:hover {
                background-color: var(--background-color);
                cursor: pointer;
            }
        }
    `
);

export const IconPicker = observer(({ required, label, disabled, groups, value, onChange }) => {
    const buttonRef = useRef();
    const [visible, setVisible] = useState(false);
    const [keywords, setKeywords] = useState(false);

    const onInputClick = useCallback(
        (ev) => {
            if (!disabled) {
                setVisible(true);
            }
        },
        [disabled]
    );

    const onSelect = useCallback(
        (ev, iconName) => {
            if (!disabled) {
                ev.preventDefault();
                ev.stopPropagation();
                setVisible(false);
                onChange(ev, iconName);
            }
        },
        [disabled]
    );

    const onDismiss = useCallback((ev) => {
        setVisible(false);
    }, []);

    const iconGroups = IconDescriptors.filter((g) => !groups || groups.length === 0 || groups.indexOf(g.id) !== -1);
    const searchableKeywords = keywords ? keywords.toLowerCase().split(" ") : [];

    return (
        <div className={rootClassName}>
            <div className="wrapper">
                <Label required={required}>{label}</Label>
                <div className={cx("input", disabled && "disabled")} onClick={onInputClick} ref={buttonRef}>
                    {value && (
                        <>
                            <div className="icon">
                                <FontIcon iconName={value} />
                            </div>
                            {!disabled && (
                                <IconButton iconProps={{ iconName: "Cancel" }} onClick={(ev) => onSelect(ev, null)} />
                            )}
                        </>
                    )}
                </div>
                {!disabled && visible && buttonRef.current && (
                    <Callout
                        isBeakVisible={false}
                        className={calloutClassName}
                        gapSpace={0}
                        doNotLayer={false}
                        target={buttonRef.current}
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        onDismiss={onDismiss}
                        setInitialFocus={true}
                    >
                        <div className="wrapper">
                            <SearchBox
                                placeholder="Filter icons"
                                underlined={true}
                                value={keywords}
                                onChange={(ev, val) => setKeywords(val)}
                                onClear={() => setKeywords(null)}
                            />

                            {iconGroups.map((group) => (
                                <div className="group" key={group.id}>
                                    <ObservableSeparator alignContent="center">{group.name}</ObservableSeparator>
                                    <div className="icons">
                                        {group.icons
                                            .filter(
                                                (i) =>
                                                    searchableKeywords.length === 0 ||
                                                    searchableKeywords.findIndex(
                                                        (k) => i.iconName.toLowerCase().indexOf(k) !== -1
                                                    ) !== -1
                                            )
                                            .map((icon) => (
                                                <div
                                                    className="icon"
                                                    key={icon.iconName}
                                                    onClick={(ev) => onSelect(ev, icon.iconName)}
                                                >
                                                    <FontIcon iconName={icon.iconName} />
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Callout>
                )}
            </div>
        </div>
    );
});
