import React, { Component } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

export const BoxPanel = observer(
    class BoxPanel extends Component {
        render() {
            const {
                children,
                className,
                shadow,
                padding,
                stretch,
                attached,
                invert,
                small,
                mini,
                dataTest,
                onClick,
                ...rest
            } = this.props;

            return (
                <div
                    data-test={dataTest || "BoxPanel"}
                    onClick={onClick}
                    className={cx(
                        className,
                        css`
                            max-width: 100%;
                            background: var(--white);
                            border-radius: var(--border-radius);
                            border: 1px solid var(--border-color);
                        `,
                        shadow &&
                            css`
                                box-shadow: var(--box-shadow);
                            `,
                        padding &&
                            !small &&
                            css`
                                padding: var(--spacing-l);
                            `,
                        padding &&
                            small &&
                            css`
                                padding: var(--spacing);
                            `,
                        padding &&
                            mini &&
                            css`
                                padding: var(--spacing-xxs);
                            `,
                        stretch &&
                            css`
                                height: 100%;
                            `,
                        attached &&
                            css`
                                border-bottom: 0;
                                margin-bottom: 0;
                            `,
                        !!onClick &&
                            css`
                                cursor: pointer;
                            `,
                        invert &&
                            css`
                                background-color: var(--body-background);
                                border-radius: 0;
                            `,
                        invert &&
                            padding &&
                            css`
                                padding: var(--spacing-xs) var(--spacing-l);
                            `
                    )}
                    {...rest}
                >
                    {children}
                </div>
            );
        }
    }
);
