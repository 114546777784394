import React, { Component } from 'react';
import { cx, css } from 'emotion'

import { TooltipHost, TooltipDelay, DirectionalHint } from 'office-ui-fabric-react/lib/Tooltip';
import { Label } from 'office-ui-fabric-react/lib/Label';

const rootClassName = cx(
  'cygraph-PriorityScore-root',
  css`
    & .wrapper {
      display: flex;
      flex-direction: row;
    }

    & .text {
      margin-left: 3px;
      color: var(--text-color-dark);
    }

    & .score {
      width: 20px;
      height: 20px;
      background: var(--grey-2);
      margin-right: 2px;

      &.mini {
        width: 10px;
        margin-top: 4px;
        height: 10px;
      }

      &.medium {
        width: 14px;
        margin-top: 2px;
        height: 14px;
      }

      &.Low {
        background: var(--green-6);
      }
      &.Medium {
        background: var(--orange-6);
      }
      &.High {
        background: var(--red-6);
      }
    }
  `
);

const scores = ['Low', 'Medium', 'High'];

export class PriorityScore extends Component {
        render () {
            const { hideName, hideTooltip, size, priority } = this.props;    
            const index = scores.indexOf(priority ? priority : 'None');

            const tooltipProps = {
              onRenderContent: () => (
                <div>
                  <Label>Priority</Label> {index+1} out of {scores.length}
                </div>
              )
            };

            return (
              <div className={rootClassName}>
                <TooltipHost
                  tooltipProps={tooltipProps}
                  calloutProps={{ hidden: index == -1 || !!hideTooltip }}
                  delay={TooltipDelay.medium}
                  directionalHint={DirectionalHint.bottomLeftEdge}>
                  <div className="wrapper">
                    {
                      scores.map((s, i) => (
                        <div key={i} className={cx('score', size, i <= index && priority)}></div>
                      ))
                    }
                    {!hideName && <div className="text">{priority ? priority : 'None'}</div>}
                  </div>
                </TooltipHost>
              </div>
            );
        }
    };