import { observable, flow, computed, action } from "mobx";

import { PropertyService } from '../../../api/properties';
import { PropertyStore } from "./PropertyStore";

export class PropertySectionNewStore {

    public propertyService: PropertyService;
    public parentStore: PropertyStore;

    @observable public visible: boolean = false;
    @observable public formData: any;
    @observable public originalFormData: any;
    @observable public formOptions: any;
    @observable public saving: boolean = false;

    constructor(parentStore: PropertyStore) {
        this.parentStore = parentStore;
        this.propertyService = parentStore.propertyService;
    }

    @computed
    public get isValid() {
        if(!this.formData){
            return false;
        }

        return this.formData.name && this.formData.target && this.formData.order >= 0;
    }

    @computed
    public get isDirty() {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
    }

    @action
    public resetFormData() {
        this.formData = JSON.parse(JSON.stringify(this.originalFormData));
    }

    @action
    public show(options) {
        const section = Object.assign({
            order: 100,
        }, options.section);

        this.visible = true;
        this.formOptions = { };
        this.formData = JSON.parse(JSON.stringify(section));
        this.originalFormData = JSON.parse(JSON.stringify(section));
    }

    @action
    public hide(options) {
        this.visible = false;
        this.formData = null;
        this.formOptions = null;
        this.originalFormData = null;
    }

    public createPropertySection = flow(function*() {
        this.saving = true;
        this.error = null;

        try {
            if(this.isValid) {
                const result = yield this.propertyService.createPropertySection(this.formData);
                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Property section ${result.name} created successfully`);
                return result;
            }
        } catch (e) {
            console.error(e);
            this.error = e;
        } finally {
            this.saving = false;
        }
    });
}