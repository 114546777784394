import { observable, action, computed, flow } from "mobx";

import { PrincipalContextStore } from "./PrincipalContextStore";
import { IdentityService } from "../../../api/identity";

export class PrincipalEditGroupStore {
    public parentStore: PrincipalContextStore;
    private identityService: IdentityService;

    @observable public visible: boolean = false;
    @observable public loading: boolean = false;
    @observable public saving: boolean = false;
    @observable public formData: any = {};
    @observable public roles: string[] = [
        'super-admin', 'admin', 'Risk Manager', 'Board manager'
    ]
    @observable public usersToAdd: any[] = [];
    @observable public usersToRemove: any[] = [];
    @observable public groupId: string = '';

    constructor(parentStore: PrincipalContextStore, identityService: IdentityService) {
        this.parentStore = parentStore;
        this.identityService = identityService;
    }

    @computed public get isValid() {
        return (this.groupId && (this.formData.groupsToAdd || this.formData.groupsToRemove || this.formData.roles)) ? true : false;
    }

    @action public addUserToAGroup(user) {
        this.formData.members.push(user);
        this.usersToAdd.push(user.id);
        const indexToRemove = this.usersToRemove.indexOf(user.id);
        if (indexToRemove > -1) this.usersToRemove.splice(indexToRemove, 1);
    }

    @action public removeAUserFromAGroup(userId) {
        this.usersToRemove.push(userId);
        const indexToRemove = this.usersToAdd.indexOf(userId);
        if (indexToRemove > -1) this.usersToAdd.splice(indexToRemove, 1);
        const indexToRemoveFromMember = this.formData.members.findIndex(E => E.id === userId);
        if (indexToRemoveFromMember > -1) this.formData.members.splice(indexToRemoveFromMember, 1);
    }

    @action public closeForm() {
        this.visible = false;
        this.groupId = '';
        this.usersToRemove = [];
        this.usersToAdd = [];
    }

    loadFormData = flow(function*() {
        this.loading = true;
        this.usersToRemove = [];
        this.usersToAdd = [];
        try {
            const group = yield this.identityService.getPrincipal(this.groupId);
            const members = yield this.identityService.getUserInAGroups(this.groupId, { 
                pageSize: 500, 
                startIndex: 0,
                keywords: '',
            });
            this.formData = group;
            this.formData.members = members.items;
            this.loading = false;
        } catch (e) {
            this.loading = false;
            console.error(e);
            throw e;
        }
    })

    saveChanges = flow(function*(){
        for (let i = 0; this.usersToRemove.length > i ; i++) {
            yield this.identityService.removeUserFromGroup(this.usersToRemove[i], this.groupId);
        }
        for (let i = 0; this.usersToAdd.length > i ; i++) {
            yield this.identityService.addUserToGroup(this.usersToAdd[i], this.groupId);
        }
        const groupToUpdate = {
            id: this.groupId,
            type: "Group",
            name: this.formData.name,
            email: this.formData.email,
            // provider: null,
            // externalId: null,
            // email: null
        }
        yield this.identityService.updateGroup(groupToUpdate);
        this.visible = false;
        this.usersToRemove = [];
        this.usersToAdd = [];
        this.groupId = '';
        this.parentStore.loadCurrentPrincipal();
    })
}
