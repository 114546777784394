import React, { Component } from "react";

import { CommandBar } from "office-ui-fabric-react/lib/CommandBar";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { ObservablePagination } from "@modules/base/components/ObservablePagination";
import { WorkItemGridView } from "@workitems/components/core/WorkItemGridView";

export class Box extends Component {
    constructor(props) {
        super(props);
    }

    get commandBarItems() {
        const { isGuestUser, products, onNewRequest } = this.props;
        const items = [
            {
                showToGuest: false,
                key: "newRequest",
                text: "New Request",
                iconProps: { iconName: "Add" },
                onClick: () => onNewRequest(null),
                split: products && products.length > 0,
                subMenuProps: products
                    ? {
                          onItemClick: (ev, item) => {
                              if (item && item.key) {
                                  onNewRequest(item.data);
                              }
                          },
                          items: (products || []).map((p) => {
                              return { key: p.id, text: p.name, data: p };
                          }),
                      }
                    : null,
            },
        ];
        return isGuestUser ? items.filter((I) => I.showToGuest) : items;
    }

    render() {
        const { loading, data, onProductClick, onWorkItemClick, onPageChange, dataTest } = this.props;
        return (
            <div data-test={dataTest}>
                <BoxPanel shadow>
                    <CommandBar styles={{ root: { paddingRight: "0px" } }} items={this.commandBarItems} farItems={[]} />
                </BoxPanel>
                <WorkItemGridView
                    workItems={data.items}
                    compact={true}
                    loading={loading || !data.items}
                    query={{}}
                    onProductClick={onProductClick}
                    onWorkItemClick={onWorkItemClick}
                />

                <ObservablePagination
                    selectedPageIndex={data.selectedPageIndex}
                    pageCount={data.pageCount}
                    itemsPerPage={data.itemsPerPage}
                    totalItemCount={data.totalItemCount}
                    onPageChange={(index) => {
                        onPageChange(index);
                    }}
                />
            </div>
        );
    }
}
