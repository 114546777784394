import React, { Component } from "react";
import { observer } from "mobx-react";
import Moment from "react-moment";

import { StatusProgress, StatusProgressStep } from "@modules/base/components/StatusProgress";

export const AssessmentStatusProgress = observer(
    class AssessmentStatusProgress extends Component {
        render() {
            const { assessment, className } = this.props;
            return (
                <StatusProgress className={className}>
                    <StatusProgressStep
                        title="Triage"
                        active={this._isStatusOneOf(["Draft"])}
                        done={!this._isStatusOneOf(["Draft"])}
                    >
                        <span>
                            {assessment.trigger == "Manual" ? "Manually created on " : "Automatically created on "}
                        </span>
                        <Moment date={assessment.created} format="Do MMM YYYY" />
                    </StatusProgressStep>
                    <StatusProgressStep
                        title="Self Assessment"
                        active={this._isStatusOneOf(["Started"])}
                        done={!this._isStatusOneOf(["Draft", "Started"])}
                    >
                        {assessment.started ? (
                            <span>
                                Started on <Moment date={assessment.started} format="Do MMM YYYY" />
                            </span>
                        ) : (
                            <span>Self assessment has not started</span>
                        )}
                    </StatusProgressStep>
                    <StatusProgressStep
                        title="Remediation"
                        active={this._isStatusOneOf(["Remediation"])}
                        done={this._isStatusOneOf(["Reviewing", "Closed", "Cancelled"])}
                    >
                        {assessment.submitted ? (
                            <span>
                                Submitted on <Moment date={assessment.submitted} format="Do MMM YYYY" />
                            </span>
                        ) : (
                            <span>Assessment has not been submitted yet</span>
                        )}
                    </StatusProgressStep>
                    <StatusProgressStep
                        title="Risk Review"
                        active={this._isStatusOneOf(["Reviewing"])}
                        done={this._isStatusOneOf(["Closed", "Cancelled"])}
                    >
                        {assessment.reviewed ? (
                            <span>
                                Review started on <Moment date={assessment.reviewed} format="Do MMM YYYY" />
                            </span>
                        ) : this._isStatusOneOf(["Closed", "Cancelled"]) ? (
                            <span>Risk review was not required</span>
                        ) : (
                            <span>Assessment has not been submitted yet</span>
                        )}
                    </StatusProgressStep>
                    <StatusProgressStep
                        title={assessment.closed ? assessment.state : "Closed"}
                        active={this._isStatusOneOf(["Closed", "Cancelled"])}
                    >
                        {assessment.closed ? (
                            <span>
                                {assessment.state} on <Moment date={assessment.closed} format="Do MMM YYYY" />
                            </span>
                        ) : (
                            <span>Assessment has not been closed yet</span>
                        )}
                    </StatusProgressStep>
                </StatusProgress>
            );
        }

        _isStatusOneOf(status) {
            const { assessment } = this.props;
            return status.indexOf(assessment.state) != -1;
        }
    }
);
