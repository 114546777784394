import { observable, flow, computed, action } from "mobx";

import { StateFlowService } from "../../../api/stateflows";
import { StateFlowStore } from "./StateFlowStore";

export class StateFlowViewerStore {
    private parentStore: StateFlowStore;
    private stateFlowService: StateFlowService;

    @observable public loading: boolean = false;
    @observable public summary: any;
    @observable public error: any;
    @observable public selectedTab: string;

    constructor(parentStore: StateFlowStore) {
        this.parentStore = parentStore;
        this.stateFlowService = parentStore.stateFlowService;
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [];
        const routing = this.parentStore.rootStore.routing;

        builder.push({ text: "Settings", key: "settings", onClick: () => routing.push(`/settings`) });

        if (!this.stateFlow) {
            return builder;
        }

        switch (this.stateFlow.target) {
            case "WorkItem":
                builder.push({
                    text: "Demand Management",
                    key: "settings-demand",
                    onClick: () => routing.push(`/settings/demand`),
                });
                builder.push({
                    text: "Workflows",
                    key: "workflows-browse",
                    onClick: () => routing.push(`/settings/demand/workflows`),
                });
                break;
            case "RiskLifecycle":
                builder.push({
                    text: "Risk Management",
                    key: "settings-risks",
                    onClick: () => routing.push(`/settings/risks`),
                });
                builder.push({
                    text: "Workflows",
                    key: "workflows-browse",
                    onClick: () => routing.push(`/settings/risks/workflows`),
                });
                break;
            default:
                builder.push({
                    text: "Workflows",
                    key: "workflows-browse",
                    onClick: () => routing.push(`/settings/workflows`),
                });
                break;
        }

        builder.push({
            text: this.stateFlow.name,
            key: this.stateFlow.id,
            isCurrentItem: true,
        });

        return builder;
    }

    @computed
    public get stateFlow() {
        return this.parentStore.selectionStore.stateFlow;
    }

    @computed
    public get manifest() {
        return this.parentStore.editorStore.manifest;
    }

    @action
    public setSelectedTab(tab) {
        this.selectedTab = tab || "summary";
    }

    public loadStateFlow = flow(function* (id: string, version: string = null) {
        this.error = null;
        this.summary = null;
        this.loading = true;

        try {
            yield this.parentStore.editorStore.setEditMode(false);
            yield this.parentStore.selectionStore.loadStateFlow(id, version);
            this.summary = yield this.stateFlowService.getStateFlowSummary(id, version);
            const manifest = this.parentStore.selectionStore.manifest;
            yield this.parentStore.editorStore.prepare(manifest);
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.loading = false;
        }
    });
}
