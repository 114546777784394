import React, { Component } from "react";
import { observer } from "mobx-react";

import Moment from "react-moment";
import { Text } from "office-ui-fabric-react/lib/Text";
import { Label } from "office-ui-fabric-react/lib/Label";

import { MarkdownViewer } from "@modules/editors/components/MarkdownViewer";

import { AdvisorySeverity } from "./AdvisorySeverity";
import { AdvisoryCompliancePill } from "./AdvisoryCompliancePill";
import { AdvisoryStatus } from "./AdvisoryStatus";

export const AdvisoryDetails = observer(
    class AdvisoryDetails extends Component {
        render() {
            const { advisory } = this.props;

            if (!advisory) {
                return null;
            }

            const measure = advisory.references.find((r) => r.type == "Measure");

            return (
                <div>
                    <div className="row--xs">
                        <Label>Description</Label>
                        <div>
                            <MarkdownViewer value={advisory.description} />
                        </div>
                    </div>

                    <div className="row--xs">
                        <Label>Mitigations</Label>
                        <div>
                            <MarkdownViewer value={advisory.mitigations} />
                        </div>
                    </div>

                    <div className="row--xs">
                        <Label>Control</Label>
                        <div>{advisory.control.value}</div>
                    </div>

                    {measure && (
                        <div className="row--xs">
                            <Label>Related Question</Label>
                            <div>
                                <Text variant="medium">
                                    <span>{advisory.references.find((r) => r.type == "Module").title}</span> /
                                    <span> {advisory.references.find((r) => r.type == "Group").title}</span> /
                                    <span> {advisory.references.find((r) => r.type == "Measure").title}</span>
                                </Text>
                            </div>
                        </div>
                    )}

                    <div className="row--xs">
                        <r-grid columns="4">
                            <r-cell>
                                <Label>Severity</Label>
                                <div>
                                    <AdvisorySeverity advisory={advisory} />
                                </div>
                            </r-cell>
                            <r-cell>
                                <Label>Status</Label>
                                <AdvisoryStatus advisory={advisory} />
                            </r-cell>
                            <r-cell>
                                <Label>Compliance</Label>
                                <div>
                                    <AdvisoryCompliancePill advisory={advisory} />
                                </div>
                            </r-cell>
                            <r-cell>
                                <Label>Modified</Label>
                                <div>
                                    <Moment date={advisory.modified} fromNow />
                                </div>
                            </r-cell>
                        </r-grid>
                    </div>
                </div>
            );
        }
    }
);
