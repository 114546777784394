import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';

import { AppPage } from '../../../layout/containers/AppPage';
import { ObservablePagination } from '../../../base/components/ObservablePagination';

import { SystemGridView } from '../../components/SystemGridView';
import { SystemCommandBar } from '../../components/SystemCommandBar';

export const SystemBrowsePage = inject('pageContext', 'portfolioStore', 'systemStore', 'routing')(observer(
    class SystemBrowsePage extends Component {

        componentWillMount() {
            const { pageContext, portfolioStore, systemStore, routing } = this.props;

            pageContext.updateQueryStringParams(routing.location.search);
            const page = pageContext.queryStringParams.p || 1;
            const keywords = pageContext.queryStringParams.k || '';
            portfolioStore.loadPortfolios({});
            systemStore.loadSystems({ page, keywords });
        }

        componentWillReceiveProps(nextProps) {
            const { systemStore, pageContext, routing } = nextProps;

            pageContext.updateQueryStringParams(routing.location.search);
            const page = pageContext.queryStringParams.p || 1;
            const keywords = pageContext.queryStringParams.k || '';
            systemStore.loadSystems({ page, keywords });
        }
    
        render () {
            const { portfolioStore, systemStore, routing } = this.props;
            const { browsePageStore } = systemStore;
            const { breadcrumb } = browsePageStore;

            return (
                <AppPage 
                    title="Assets"
                    breadcrumb={breadcrumb}
                    loading={portfolioStore.loading || systemStore.loading}
                    hasSidebar={false} className="systems list">
                    <Fragment>
                        <SystemCommandBar 
                            keywords={systemStore.keywords} 
                            onSearchChange={(keywords) => { 
                                if(keywords) {
                                    routing.push(`/assets/browse?k=${encodeURIComponent(keywords)}`);
                                } else {
                                    routing.push(`/assets/browse`);
                                };
                            }} />
                        <SystemGridView 
                            portfolios={portfolioStore.activePortfolios}
                            systems={systemStore.systems}
                            loading={systemStore.loading}
                            query={{}}
                            onSystemClick={(system) => routing.push(`/assets/browse/${system.id}`)} />

                        <ObservablePagination 
                            {...systemStore.pagination}
                            onPageChange={(index) => { 
                                routing.push(`/assets/browse?p=${index + 1}${systemStore.keywords ? `&k=${encodeURIComponent(systemStore.keywords)}` : ''}`);
                            }} />
                    </Fragment>                 
                </AppPage>
            );
        }
    }
));
