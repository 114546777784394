import React from "react";
import { observer } from "mobx-react";

import { CommandBar } from "@ui/elements/CommandBar";

import { BoxPanel } from "@modules/base/components/BoxPanel";

import { AssessmentFilterBar } from "@modules/assessments/components/core/AssessmentFilterBar";

export const AssessmentCommandBar = observer(
    ({ isGuestUser, filterQuery, filterContext, onFilterChange, onNewTriage, onExport }) => {
        const items = [
            {
                key: "newAssessmentTriage",
                text: "New Assessment Triage",
                iconProps: { iconName: "Add" },
                onClick: onNewTriage,
            },
        ];

        const farItems = [];

        if (filterQuery && onFilterChange && filterContext) {
            farItems.push({
                key: "filterBar",
                text: "Filter Bar",
                onRender: () => {
                    return (
                        <AssessmentFilterBar value={filterQuery} onChange={onFilterChange} context={filterContext} />
                    );
                },
            });
        }

        if (onExport) {
            farItems.push({
                className: "test-ExportToCSV",
                key: "export",
                name: "Export to CSV",
                iconOnly: true,
                iconProps: { iconName: "ExcelDocument" },
                onClick: onExport,
            });
        }

        return (
            <BoxPanel shadow className="cyAssessmentCommandBar">
                <CommandBar
                    styles={{ root: { paddingRight: "0px" } }}
                    items={isGuestUser ? items.filter((I) => I.showToGuest) : items}
                    farItems={farItems}
                />
            </BoxPanel>
        );
    }
);
