import React from "react";
import { observer } from "mobx-react";
import Moment from "react-moment";
import moment from "moment";

import { Text } from "@ui/elements/Text";
import { PersonaSize } from "@ui/elements/Persona";
import { MessageBar } from "@ui/elements/MessageBar";
import { FontIcon } from "@ui/elements/Icon";

import { DetailsListLayoutMode, SelectionMode, ConstrainMode } from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { WrappedContent } from "@modules/base/components/WrappedContent";
import { RoutingLink } from "@modules/base/components/RoutingLink";
import { UserHoverCard } from "@modules/identity/containers/utilities/UserHoverCard";
import { PhotoPersona } from "@modules/identity/containers/utilities/PhotoPersona";

import { getEventDisplayName, getEventIconName } from "@modules/workflows/stores/WorkflowConstants";

export const WorkflowHistoryGridView = observer((props) => {
    const { className, history, loading, disableSelection, onHistoryClick } = props;

    const columns = [
        {
            key: "started",
            name: "Ran at",
            fieldName: "started",
            minWidth: 180,
            maxWidth: 250,
            isRowHeader: true,
            isResizable: true,
            data: "string",
            onRender: (item) => {
                return (
                    <WrappedContent>
                        <RoutingLink onClick={() => onHistoryClick(item)} onClickEnabled={true}>
                            <Moment utc date={item.started} format="Do MMM YYYY, h:mm:ss a" />
                        </RoutingLink>
                        <Text variant="small" block>
                            <FontIcon iconName={getEventIconName(item.triggerEvent)} />
                            &nbsp;{getEventDisplayName(item.triggerEvent)}
                        </Text>
                    </WrappedContent>
                );
            },
            isPadded: true,
        },
        {
            key: "message",
            name: "Message",
            fieldName: "message",
            minWidth: 200,
            isResizable: false,
            data: "string",
            onRender: (item) => {
                return (
                    <WrappedContent>
                        <span>{item.message}.</span>
                        {item.activityCount !== null && (
                            <span>
                                &nbsp;{item.activityCount} action{item.activityCount === 1 ? "" : "s"} found.
                            </span>
                        )}
                    </WrappedContent>
                );
            },
            isPadded: false,
        },
        {
            key: "status",
            name: "Status",
            fieldName: "status",
            minWidth: 100,
            maxWidth: 120,
            isResizable: false,
            data: "string",
            onRender: (item) => {
                return <WrappedContent>{item.status}</WrappedContent>;
            },
            isPadded: false,
        },
        {
            key: "triggered",
            name: "Triggered",
            fieldName: "triggered",
            minWidth: 190,
            maxWidth: 210,
            isResizable: false,
            data: "string",
            onRender: (item) => {
                return (
                    <UserHoverCard principal={item.actor}>
                        <PhotoPersona
                            principalId={item.actor ? item.actor.id : null}
                            size={PersonaSize.size28}
                            text={item.actor ? item.actor.name : "Unassigned"}
                            showUnknownPersonaCoin={!item.actor}
                            showSecondaryText={item.actor}
                            secondaryText={moment.utc(item.created).format("Do MMM YYYY, h:mm:ss a")}
                        />
                    </UserHoverCard>
                );
            },
            isPadded: true,
        },
    ];

    return (
        <BoxPanel shadow className={className}>
            <ShimmeredDetailsList
                items={history}
                compact={false}
                columns={columns}
                selectionMode={disableSelection ? SelectionMode.none : SelectionMode.none}
                getKey={(item, index) => (item ? item.id : index)}
                setKey="single"
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.horizontalConstrained}
                isHeaderVisible={true}
                selectionPreservedOnEmptyClick={true}
                onItemInvoked={onHistoryClick}
                //onItemContextMenu={this._onItemContextMenu}
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="Row checkbox"
                enableShimmer={loading}
                removeFadingOverlay={true}
                shimmerLines={7}
                ariaLabelForShimmer="Automation history being fetched"
                ariaLabelForGrid="Automation history list"
            />

            {!loading && !history.length && <MessageBar>There are no history of automations being run.</MessageBar>}
        </BoxPanel>
    );
});
