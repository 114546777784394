import React, { Component, Fragment } from 'react';
import { cx, css} from 'emotion';
import { Checkbox } from 'office-ui-fabric-react';
import { AsyncPicker } from '@baseComponents/asyncPicker/AsyncPicker';

const checkBoxClassName = cx(css`
  padding: 5px;
`);

export class CalloutAsyncPicker extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showCallout: false,
            optionFilter: '',
        }
        if (props.selectedItems) {
            props.selectedField.items = props.selectedItems;
        }
    }

    onCheckBoxChange = (element, checked) => {
        const { selectedField, onCheckBoxChange } = this.props;
        if (onCheckBoxChange) onCheckBoxChange(selectedField, element, checked);
    }

    onItemPicked = (item) => {
        const { selectedField, onAddItem } = this.props;
        if (onAddItem) onAddItem(item, selectedField);
    }

    render () {
        const { selectedField, filter, dataTest, api, idField, textField, pickedField, labelField, preTextField, selectedItems } = this.props;
        return (
            <div data-test={`AsyncPicker${dataTest}`}>
                    {selectedItems && selectedItems
                        .filter(item =>{
                            if (filter) {
                                return (item.text || item.name).toLowerCase().includes(filter.toLowerCase())
                            } else {
                                return true
                            }
                        })
                        .map(item => {
                            return <Checkbox 
                                key={`k_${item.key}`}
                                className={checkBoxClassName}
                                label={item.text || item.name}
                                checked={true}
                                onChange={(ev, checked)=> {this.onCheckBoxChange(item, checked)}}
                            />
                    })}
                    <AsyncPicker 
                        placeholder={selectedField ? selectedField.placeholder : ''}
                        onItemPicked={(item) => this.onItemPicked(item)}
                        selected={[]}
                        api={api}
                        idField={idField}
                        textField={textField}
                        pickedField={pickedField}
                        preTextField={preTextField}
                    />
            </div>)
    }
}