import React from "react";
import { observer } from "mobx-react";
import { Route, Switch } from "react-router";

import { AssuranceGeneralTab } from "./AssuranceGeneralTab";
import { AssuranceTriageTab } from "./AssuranceTriageTab";
import { AssurancePatternsTab } from "./AssurancePatternsTab";
import { AssuranceRecycleBinTab } from "./AssuranceRecycleBinTab";
import { ModuleAutomationsTab } from "./ModuleAutomationsTab";

export const AssuranceSettingsTabList = observer(({}) => {
    return (
        <Switch>
            <Route exact path="/settings/assurance/triage" component={() => <AssuranceTriageTab />} />
            <Route exact path="/settings/assurance/patterns" component={() => <AssurancePatternsTab />} />
            <Route
                exact
                path="/settings/assurance/automations"
                component={() => (
                    <ModuleAutomationsTab
                        context={{ type: "module", id: "5cd3204f-dcad-41d4-9519-d9398f744d67", label: "Assurance" }}
                    />
                )}
            />{" "}
            <Route exact path="/settings/assurance/recycle-bin" component={() => <AssuranceRecycleBinTab />} />
            <Route path="/settings/assurance" component={() => <AssuranceGeneralTab />} />
        </Switch>
    );
});
