import React, { PureComponent } from 'react';
import { cx, css } from 'emotion';
import { getInitials } from '@uifabric/utilities/lib/initials';
import { Persona, PersonaSize } from 'office-ui-fabric-react/lib/Persona';
import { Stack } from 'office-ui-fabric-react/lib/Stack';

import RichTextViewer from '../../../../../editors/components/RichTextViewer';
import { UserHoverCard } from '../../../../../identity/containers/utilities/UserHoverCard';
import { PhotoPersona }  from '../../../../../identity/containers/utilities/PhotoPersona';

export class ViewerPartHeader extends PureComponent {
  render () {
    const { className, headlineText, value, subtext, theme, owner } = this.props;
    return (
      <div className={cx(className, "part-header")}>
        <Stack horizontal horizontalAlign="space-between">
          {headlineText && <div className="headline">
            <h2>{headlineText.replace('{value}', `${value}%`)}</h2>
          </div>}
          {owner && <UserHoverCard principal={owner}>
              <PhotoPersona
                principalId={owner.id}
                imageInitials={owner ? getInitials(owner.name) : '-'}
              />
            </UserHoverCard>}
        </Stack>
        {subtext && <div className="subtext">
          <RichTextViewer value={subtext} />
        </div>}
      </div>
    );
  }
}