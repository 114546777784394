import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";
import { Constants } from "@api/graph/serviceBase";
import { cx, css } from "emotion";

import { Stack } from "@ui/elements/Stack";
import { Image, ImageFit } from "@ui/elements/Image";
import { Text } from "@ui/elements/Text";
import { Shimmer, ShimmerElementType } from "@ui/elements/Shimmer";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { RoutingButton } from "@modules/base/components/RoutingButton";

const rootClassName = cx(
    "cygraph-SimpleProfileView-root",
    css`
        padding: var(--spacing-s) var(--spacing) !important;

        & .avatar {
            border-radius: 50%;
        }

        & .edit {
            border: 1px solid var(--border-color);
        }

        & .intro,
        & .jobTitle {
            color: var(--text-color-lighter);
        }
    `
);

export const SimpleProfileView = observer(({ className }) => {
    const { authProvider, principalContext } = useStores();
    const [photo, setPhoto] = useState(null);
    const user = principalContext.current;

    useEffect(() => {
        if (user) {
            (async () => {
                const token = await authProvider.getAccessToken();
                setPhoto(`${Constants.baseUrl}/principals/${user.id}/photo/$value?token=${token.accessToken}`);
            })();
        }
    }, [authProvider, user]);

    if (!user) {
        return (
            <BoxPanel className={cx(rootClassName, className)} padding shadow>
                <Stack horizontal verticalFull verticalAlign="center" tokens={{ childrenGap: 20 }}>
                    <Stack.Item>
                        <Shimmer
                            className="avatar"
                            width="60px"
                            shimmerElements={[{ type: ShimmerElementType.circle, height: 60 }]}
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <Stack grow tokens={{ childrenGap: 5 }} verticalFill verticalAlign="center">
                            <Shimmer width="25%" shimmerElements={[{ type: ShimmerElementType.line, height: 12 }]} />
                            <Shimmer width="35%" shimmerElements={[{ type: ShimmerElementType.line, height: 16 }]} />
                            <Shimmer width="30%" shimmerElements={[{ type: ShimmerElementType.line, height: 12 }]} />
                        </Stack>
                    </Stack.Item>
                    <Stack.Item shrink>
                        <Shimmer width="30px" shimmerElements={[{ type: ShimmerElementType.line, height: 12 }]} />
                    </Stack.Item>
                </Stack>
            </BoxPanel>
        );
    }

    return (
        <BoxPanel className={cx(rootClassName, className)} padding shadow>
            <Stack horizontal verticalFull verticalAlign="center" tokens={{ childrenGap: 20 }}>
                <Stack.Item>
                    {photo && (
                        <Image className="avatar" src={photo} imageFit={ImageFit.contain} width={60} height={60} />
                    )}
                    {!photo && (
                        <Image
                            className="avatar"
                            src="/images/account.png"
                            imageFit={ImageFit.contain}
                            width={60}
                            height={60}
                        />
                    )}
                </Stack.Item>
                <Stack.Item grow>
                    <Text className="intro" variant="small" block>
                        Welcome back,
                    </Text>
                    <Text className="name" variant="xLarge" block>
                        {user.name}
                    </Text>
                    <Text className="jobTitle" variant="small" block>
                        {user.jobTitle}
                    </Text>
                </Stack.Item>
                <Stack.Item shrink>
                    <RoutingButton className="edit" url="/account/overview">
                        Edit profile
                    </RoutingButton>
                </Stack.Item>
            </Stack>
        </BoxPanel>
    );
});
