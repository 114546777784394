import React from "react";
import { cx, css } from "emotion";

import { FilterBar } from "@modules/base/filterBar/FilterBar";

const taskFilterBarClassName = cx(
    "cygraph-TaskFilterBar-root",
    css`
        & .ms-FocusZone.ms-CommandBar {
            padding: 0;
            align-items: flex-start;
        }
    `
);

export function TaskFilterBar({ className, query, onChange, standalone }) {
    return (
        <FilterBar
            className={cx(!standalone && taskFilterBarClassName, className)}
            visible={true}
            onFiltersChange={(filters) => {
                onChange(filters);
                //if (onFiltersChange) onFiltersChange(filters);
            }}
            hideCloseBarButton
            hideOpenPanelIcon
            minibarFilters={["keywords"]}
            calloutFilters={["status"]}
            fields={[
                {
                    fieldType: "text",
                    fieldName: "keywords",
                    placeholder: "Search by name or alias",
                    value: query ? query.keywords || null : null,
                },
                {
                    fieldType: "dropdown",
                    fieldName: "status",
                    placeholder: "Status",
                    multiselect: true,
                    defaultSelection: ["Pending"],
                    items: [
                        {
                            key: "Pending",
                            text: "Pending",
                            checked: query && query.status ? query.status.includes("Pending") : false,
                        },
                        {
                            key: "Completed",
                            text: "Completed",
                            checked: query && query.status ? query.status.includes("Completed") : false,
                        },
                        {
                            key: "Cancelled",
                            text: "Cancelled",
                            checked: query && query.status ? query.status.includes("Cancelled") : false,
                        },
                        {
                            key: "Expired",
                            text: "Expired",
                            checked: query && query.status ? query.status.includes("Expired") : false,
                        },
                    ],
                },
            ]}
            onClearAll={() => {
                onChange([]);
                //if (onClearAll) onClearAll();
            }}
        />
    );
}
