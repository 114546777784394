import moment from 'moment';
import { computeTimelineFilter } from '../data/computeTimelineFilter';

function generateWorkItemQuery(query) {
    // let timeLineFilterObject = {
    //     before: moment().utc().startOf('month').add(1, 'month').format(),
    //     after: moment().utc().startOf('month').subtract(6, 'months').format(),
    // };
    let timeLineFilterObject = null;
    let createdFilterObject = null;
    let startedFilterObject = null;
    let dueDateFilterObject = null;
    let resolvedFilterObject = null;
    let closedFilterObject = null;
    if (query.timeLine) {
        timeLineFilterObject = computeTimelineFilter(query.timeLine[0]?query.timeLine[0]:query.timeLine);
    }
    if (query.created) {
        createdFilterObject = computeTimelineFilter(query.created[0]?query.created[0]:query.created);
    }
    if (query.started) {
        startedFilterObject = computeTimelineFilter(query.started[0]?query.started[0]:query.started);
    }
    if (query.dueDate) {
        dueDateFilterObject = computeTimelineFilter(query.dueDate[0]?query.dueDate[0]:query.dueDate);
    }
    if (query.resolved) {
        resolvedFilterObject = computeTimelineFilter(query.resolved[0]?query.resolved[0]:query.resolved);
    }
    if (query.closed) {
        closedFilterObject = computeTimelineFilter(query.closed[0]?query.closed[0]:query.closed);
    }

    // portfolio
    let portfolioFilter = null;
    if (query.portfolios) {
        portfolioFilter = query.portfolios.map(P => { return {
            id: P
        }})
    }
    // products
    let productsFilter = null;
    if (query.products) {
        productsFilter = query.products.map(P => { return {
            id: P
        }})
    }
    // groups
    let groupsFilter = null;
    if (query.assignedToGroups) {
        groupsFilter = query.assignedToGroups.map(P => { return {
            id: P
        }})
    }
    // user
    let userFilter = null;
    if (query.assignedToUser) {
        const selectedId = query.assignedToUser[0] ? query.assignedToUser[0].id : query.assignedToUser.id;
        if (selectedId) userFilter = [{id: selectedId}];
    }
    // requestedBy
    let requestedByFilter = null;
    if (query.requestedBy) {
        const selectedId = query.requestedBy[0] ? query.requestedBy[0].id : query.requestedBy.id;
        if (selectedId) requestedByFilter = [{id: selectedId}];
    }
    // priority
    let priorityFilter = null;
    if (query.priority) {
        priorityFilter = query.priority.map(P=>P);
    }
    
    // console.log('query', query);

    const finalQuery = {
        isActiveOnly: (query.isActiveOnly == 'true') ? true : false,
        portfolio: portfolioFilter,
        product: productsFilter,
        timeline: timeLineFilterObject,
        created: createdFilterObject,
        started: startedFilterObject,
        dueDate: dueDateFilterObject,
        resolved: resolvedFilterObject,
        closed: closedFilterObject,
        assignedToGroup: groupsFilter,
        assignedToUser: userFilter,
        requestedBy: requestedByFilter,
        priority: priorityFilter,
        status: (query.status) ? query.status : null,
        keywords: null,
        templateId: null,
        sort: null,
        targetType: null,
        targetId: null,
        assignedTo: null,
    };
    // console.log('finalQuery', finalQuery);
    return finalQuery;
}

export { generateWorkItemQuery }