import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { MessageBar } from 'office-ui-fabric-react/lib/MessageBar';
import { DetailsListLayoutMode, Selection, SelectionMode, ConstrainMode } from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { ContextualMenu } from 'office-ui-fabric-react/lib/ContextualMenu';
import { Persona, PersonaSize } from 'office-ui-fabric-react/lib/Persona';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { IconButton } from 'office-ui-fabric-react/lib/Button';

import { BoxPanel } from '../../../base/components/BoxPanel';
import { ListItemReveal } from '../../../base/components/ListItemReveal';
import { RoutingLink } from '../../../base/components/RoutingLink';
import { WrappedContent } from '../../../base/components/WrappedContent';
import { PhotoPersona } from '../../../identity/containers/utilities/PhotoPersona';

export const PortfolioGridView = observer(
    class PortfolioGridView extends Component {
        constructor(props) {
            super(props);

            this.state = {};

            this._selection = new Selection({
                onSelectionChanged: () => {
                  this.setState({
                    selectionDetails: this._getSelectionDetails()
                  });
                }
              });

            this._columns = [
              {
                key: 'name',
                name: 'Name',
                fieldName: 'name',
                minWidth: 210,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                onRender: (item) => {
                  return (
                      <WrappedContent>
                        <RoutingLink url={`/settings/portfolios/${item.id}`} 
                          onClick={() => this.props.onPortfolioClick && this.props.onPortfolioClick(item)} 
                          onClickEnabled={this.props.onPortfolioClick}>{item.name}
                        </RoutingLink>
                      </WrappedContent>
                  );
                },
                isPadded: true
              },
              {
                key: 'portfolioLead',
                name: 'Portfolio Lead',
                fieldName: 'portfolioLead',
                data: 'string',
                minWidth: 150,
                onRender: (item) => {
                  if (item.portfolioLead)
                    return (
                      <PhotoPersona
                        principalId={item.portfolioLead ? item.portfolioLead.id : null} 
                        size={PersonaSize.size28} 
                        text={item.portfolioLead.name} 
                        showSecondaryText={true} 
                        secondaryText={item.portfolioLead.jobTitle} />
                    );
                },
                isPadded: true
              },
              {
                key: 'dataPrivacyLead',
                name: 'Data Privacy Lead',
                fieldName: 'dataPrivacyLead',
                data: 'string',
                minWidth: 150,
                onRender: (item) => {
                  if (item.dataPrivacyLead)
                    return (
                      <PhotoPersona
                        principalId={item.dataPrivacyLead ? item.dataPrivacyLead.id : null} 
                        size={PersonaSize.size28} 
                        text={item.dataPrivacyLead.name} 
                        showSecondaryText={true} 
                        secondaryText={item.dataPrivacyLead.jobTitle} />
                    );
                },
                isPadded: true
              },
              {
                key: 'assurancePrimary',
                name: 'Assurance Primary',
                fieldName: 'assurancePrimary',
                data: 'string',
                minWidth: 150,
                onRender: (item) => {
                  if (item.assurancePrimary)
                    return (
                      <PhotoPersona
                        size={PersonaSize.size28} 
                        principalId={item.assurancePrimary ? item.assurancePrimary.id : null} 
                        text={item.assurancePrimary.name} 
                        showSecondaryText={true} 
                        secondaryText={item.assurancePrimary.jobTitle} />
                    );
                },
    
                isPadded: true
              },
              {
                key: 'assuranceSecondary',
                name: 'Assurance Secondary',
                fieldName: 'assuranceSecondary',
                data: 'string',
                minWidth: 150,
                onRender: (item) => {
                  if (item.assuranceSecondary)
                    return (
                      <PhotoPersona
                        principalId={item.assuranceSecondary ? item.assuranceSecondary.id : null} 
                        size={PersonaSize.size28} 
                        text={item.assuranceSecondary.name} 
                        showSecondaryText={true} 
                        secondaryText={item.assuranceSecondary.jobTitle} />
                    );
                },
    
                isPadded: true
              },
              {
                key: 'status',
                name: 'Status',
                fieldName: 'status',
                maxWidth: 130,
                isResizable: false,
                onColumnClick: this._onColumnClick,
                data: 'string',
                onRender: (item) => {
                  return (item.isActive ? 'Enabled' : 'Disabled' );
                },
                isPadded: false
              },
          ];
        }

        render () {
            const { className, portfolios, loading, onPageClick, disableSelection } = this.props;
            const { contextualMenuProps } = this.state;
            
            return (
              <BoxPanel shadow>
                <ShimmeredDetailsList
                  items={portfolios}
                  compact={false}
                  columns={this._columns}
                  selectionMode={disableSelection ? SelectionMode.none : SelectionMode.none}
                  getKey={this._getKey}
                  setKey="single"
                  layoutMode={DetailsListLayoutMode.justified}
                  constrainMode={ConstrainMode.horizontalConstrained}
                  isHeaderVisible={true}
                  selection={this._selection}
                  selectionPreservedOnEmptyClick={true}
                  onItemInvoked={onPageClick}
                  //onItemContextMenu={this._onItemContextMenu}
                  enterModalSelectionOnTouch={true}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  checkButtonAriaLabel="Row checkbox"
                  enableShimmer={loading}
                  removeFadingOverlay={true}
                  shimmerLines={15}
                  ariaLabelForShimmer="Portfolios being fetched"
                  ariaLabelForGrid="Portfolio list"
                />
                
                { ! loading && !portfolios.length && (
                  <MessageBar>
                    There are no matching portfolios.
                  </MessageBar>
                )}

                {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}
              </BoxPanel> 
            );
        }

        _getKey(item, index) {
            return item ? item.id : index;
        }

        _getSelectionDetails() {
            const selectionCount = this._selection.getSelectedCount();
        
            switch (selectionCount) {
              case 0:
                return 'No items selected';
              case 1:
                return '1 item selected: ' + (this._selection.getSelection()[0]).name;
              default:
                return `${selectionCount} items selected`;
            }
        }

        _onColumnClick(ev, column) {
            console.log(column);
        }

        _onItemContextMenu = (item, index, ev) => {
            const contextualMenuProps = {
              target: ev.target,
              items: [
                {
                  key: 'text',
                  name: `${this._selection.getSelectedCount()} selected`
                }
              ],
              onDismiss: () => {
                this.setState({
                  contextualMenuProps: undefined
                });
              }
            };
        
            if (index > -1) {
              this.setState({
                contextualMenuProps: contextualMenuProps
              });
            }
        
            return false;
        };
    }
);