import React from "react";
import { Text, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    paragraph: { fontSize: 10, marginTop: 0, marginBottom: 6, lineHeight: 1.4 },
    primary: { fontSize: 11 },
    secondary: { color: "#444444" },
    invert: { color: "#ffffff" },
    center: { textAlign: "center" },
});

export const Paragraph = ({ style, children, primary, secondary, center, invert }) => {
    return (
        <Text style={[styles.paragraph, primary && styles.primary, secondary && styles.secondary, center && styles.center, invert && styles.invert, style]}>
            {children}
        </Text>
    );
};
