import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import { BoxPanel } from '../../../base/components/BoxPanel';

export const PageCommandBar = observer(
    class PageCommandBar extends Component {
        
        render () {
            const { keywords, onSearchChange, onNewLibrary, onRefresh, canAddPage, onAddPage } = this.props;

            const items = [];

            items.push({
                key: 'newPage',
                text: 'New Page',
                iconProps: { iconName: 'Add' },
                disabled: !canAddPage || !canAddPage(),
                onClick: onAddPage
              });

            if(onNewLibrary) {
                items.push({
                  key: 'newPageLibrary',
                  text: 'New Page Library',
                  iconProps: { iconName: 'Add' },
                  onClick: onNewLibrary
                });
            }
            
            if(onRefresh) {
                items.push({
                    key: 'refresh',
                    text: 'Refresh',
                    iconProps: { iconName: 'Refresh' },
                    onClick: onRefresh
                });
            }

            const farItems = [
                {
                    key: 'search',
                    text: 'Search',
                    onRender: () => {
                        return (
                        <SearchBox
                            value={keywords}
                            placeholder="Search by title or path"
                            styles={{ root: { height: 'auto', minWidth: '270px' }}}
                            //onEscape={() => this._onUpdate(null)}
                            onClear={() => onSearchChange(null)}
                            onSearch={onSearchChange} />
                        );
                    }
                }
            ];

            return (
                <BoxPanel shadow>
                    <CommandBar
                        styles={{ root: { paddingRight: '0px' } }}
                        items={items}
                        farItems={farItems}
                    />
                </BoxPanel>
            );
        }
    }
);