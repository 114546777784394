import React, { Component } from "react";
import { observer, inject } from "mobx-react";

import { Text } from "office-ui-fabric-react/lib/Text";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { PanelType } from "office-ui-fabric-react/lib/Panel";
import { Label } from "office-ui-fabric-react/lib/Label";
import { PrimaryButton, ActionButton } from "office-ui-fabric-react/lib/Button";

import { ObservableSeparator } from "@modules/base/components/ObservableSeparator";
import { ObservablePanel } from "@modules/base/components/ObservablePanel";
import { RoutingButton } from "@modules/base/components/RoutingButton";

export const ProductDetailPanel = inject(
    "workItemStore",
    "routing"
)(
    observer(
        class ProductDetailPanel extends Component {
            render() {
                const { visible, onClosePanel, product } = this.props;
                return (
                    <>
                        <ObservablePanel
                            hidden={!visible}
                            pageType={`Products & Services`}
                            panelType={PanelType.medium}
                            onClosePanel={onClosePanel}
                            onRenderFooter={() => this._renderFooter()}
                        >
                            {product && this._renderCore()}
                        </ObservablePanel>
                    </>
                );
            }

            _renderCore() {
                const { product, isSimplifiedView } = this.props;

                return (
                    <>
                        <r-grid columns="1">
                            <r-cell>
                                <div className="row--xxs" style={{ marginTop: "10px" }}>
                                    <Text variant="large">{isSimplifiedView ? product.friendlyName || product.name : product.name}</Text>
                                </div>
                            </r-cell>
                        </r-grid>

                        <r-grid class="row--s" columns="2">
                            <r-cell span="2">
                                <ObservableSeparator>Key Information</ObservableSeparator>
                            </r-cell>
                            <r-cell span="2">
                                <Label>Summary</Label>
                                <Text block>{product.summary}</Text>
                            </r-cell>
                            <r-cell span="2">
                                <Label>Category</Label>
                                <Text block>{product.category}</Text>
                            </r-cell>
                        </r-grid>

                        <r-grid class="row--s" columns="2">
                            <r-cell span="2">
                                <ObservableSeparator>Provider &amp; Availability</ObservableSeparator>
                            </r-cell>
                            <r-cell span="2">
                                <Label>Provider</Label>
                                <Text block>{product.provider}</Text>
                            </r-cell>
                            <r-cell span="2">
                                <Label>Availability</Label>
                                <Text block>{product.availability}</Text>
                            </r-cell>
                        </r-grid>
                    </>
                );
            }

            _renderFooter() {
                const { onClosePanel, product } = this.props;
                return (
                    <Stack horizontal horizontalAlign="space-between">
                        <Stack horizontal tokens={{ childrenGap: 15 }}></Stack>
                        <Stack horizontal tokens={{ childrenGap: 15 }}>
                            <ActionButton styles={{ root: { height: "32px" } }} onClick={() => onClosePanel()} text="Close" />
                            {product && product.isRequestable && this._renderAction()}
                        </Stack>
                    </Stack>
                );
            }

            _renderAction() {
                const { onCreateNewWorkItem, product } = this.props;

                if (product.isInternal) {
                    return <PrimaryButton data-test="requestProduct" onClick={onCreateNewWorkItem} text="Request this product or service" />;
                }

                if (product.requestUrl && product.requestUrl.indexOf("/") === 0) {
                    return (
                        <RoutingButton data-test="requestProduct" primary url={product.requestUrl}>
                            Request this product or service
                        </RoutingButton>
                    );
                }

                if (product.requestUrl && (product.requestUrl.indexOf("http://") === 0 || product.requestUrl.indexOf("https://") === 0)) {
                    return (
                        <PrimaryButton data-test="requestProduct" target="_blank" href={product.requestUrl} title="Navigate to external product page">
                            Request this product or service
                        </PrimaryButton>
                    );
                }

                return null;
            }
        }
    )
);
