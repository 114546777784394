import React, { Component } from 'react';
import { Stack } from 'office-ui-fabric-react';
import { DefaultButton } from 'office-ui-fabric-react';
import { cx, css } from 'emotion';

const loadMoreButtonClassName = cx(
    css`
        color: var(--white);
        background-color: var(--primary-brand-color);
        border: 0px;
        border-radius: 20px;
        margin-top: 5px;
        margin-bottom: 5px;
    `
);

export class ContinuationButton extends Component {
    render () {
        const { onButtonClick } = this.props;
        return (
            <Stack>
                <Stack.Item align="center">
                    <DefaultButton text="Load more activities" onClick={onButtonClick} className={loadMoreButtonClassName} data-test="ActivitiesContinuationToken" />
                </Stack.Item>
            </Stack>
        );
    }
};
