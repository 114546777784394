import React from "react";
import { observer } from "mobx-react";

import { Text } from "@ui/elements/Text";
import { PersonaSize } from "@ui/elements/Persona";
import { MessageBar } from "@ui/elements/MessageBar";

import { DetailsListLayoutMode, SelectionMode, ConstrainMode } from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { RoutingLink } from "@modules/base/components/RoutingLink";
import { WrappedContent } from "@modules/base/components/WrappedContent";
import { UserHoverCard } from "@modules/identity/containers/utilities/UserHoverCard";
import { PhotoPersona } from "@modules/identity/containers/utilities/PhotoPersona";

import { ControlMaturity } from "@modules/controls/components/core/ControlMaturity";

function hashCode(s) {
    for (var i = 0, h = 0; i < s.length; i++) h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
    return h;
}

export const TacticalControlGridView = observer((props) => {
    const { controls, loading, disableSelection, onControlClick } = props;

    const columns = [
        {
            key: "name",
            name: "Control",
            fieldName: "name",
            minWidth: 200,
            isRowHeader: true,
            isResizable: true,
            data: "string",
            onRender: (item) => {
                return (
                    <WrappedContent>
                        <RoutingLink
                            url={`/controls/tactical/${item.id}`}
                            onClick={() => onControlClick && onControlClick(item)}
                            onClickEnabled={onControlClick}
                        >
                            {item.code} - {item.name}
                        </RoutingLink>
                        <br />
                        <Text variant="small" block>
                            {item.description}
                        </Text>
                    </WrappedContent>
                );
            },
            isPadded: true,
        },
        {
            key: "targetScore",
            name: "Target Score",
            fieldName: "targetScore",
            minWidth: 120,
            maxWidth: 150,
            isResizable: false,
            data: "string",
            onRender: (item) => {
                return <span>{(Math.random() * 5).toFixed(1)}</span>;
            },
            isPadded: false,
        },
        {
            key: "actualScore",
            name: "Actual Score",
            fieldName: "actualScore",
            minWidth: 120,
            maxWidth: 150,
            isResizable: false,
            data: "string",
            onRender: (item) => {
                return <span>{(Math.random() * 5).toFixed(1)}</span>;
            },
            isPadded: false,
        },
        {
            key: "maturityLevel",
            name: "Maturity Level",
            fieldName: "maturityLevel",
            minWidth: 130,
            maxWidth: 150,
            isResizable: false,
            data: "string",
            onRender: (item) => {
                return (
                    <ControlMaturity
                        size="mini"
                        score={["Initial", "Repeatable", "Defined", "Managed", "Optimised"][hashCode(item.id) % 4]}
                    />
                );
            },
            isPadded: false,
        },
        {
            key: "owner",
            name: "Owner",
            fieldName: "owner",
            minWidth: 170,
            maxWidth: 190,
            isResizable: false,
            data: "string",
            onRender: (item) => {
                return (
                    <UserHoverCard principal={item.modifiedBy}>
                        <PhotoPersona
                            principalId={item.modifiedBy ? item.modifiedBy.id : null}
                            size={PersonaSize.size28}
                            text={item.modifiedBy ? item.modifiedBy.name : "Unassigned"}
                            showUnknownPersonaCoin={!item.modifiedBy}
                            showSecondaryText={item.modifiedBy}
                            secondaryText={item.modifiedBy ? item.modifiedBy.jobTitle : "None"}
                        />
                    </UserHoverCard>
                );
            },
            isPadded: true,
        },
        {
            key: "scope",
            name: "Scope",
            fieldName: "scope",
            minWidth: 90,
            maxWidth: 110,
            isResizable: false,
            data: "string",
            onRender: (item) => {
                return <div>{Math.abs(hashCode(item.id) % 100)} assets</div>;
            },
            isPadded: true,
        },
    ];

    return (
        <BoxPanel shadow>
            <ShimmeredDetailsList
                items={controls}
                compact={false}
                columns={columns}
                selectionMode={disableSelection ? SelectionMode.none : SelectionMode.none}
                getKey={(item, index) => (item ? item.id : index)}
                setKey="single"
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.horizontalConstrained}
                isHeaderVisible={true}
                selectionPreservedOnEmptyClick={true}
                onItemInvoked={onControlClick}
                //onItemContextMenu={this._onItemContextMenu}
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="Row checkbox"
                enableShimmer={loading}
                removeFadingOverlay={true}
                shimmerLines={15}
                ariaLabelForShimmer="Controls being fetched"
                ariaLabelForGrid="Control list"
            />

            {!loading && !controls.length && <MessageBar>There are no matching controls.</MessageBar>}
        </BoxPanel>
    );
});
