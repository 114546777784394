import React from "react";

import { Stack } from "@ui/elements/Stack";
import { Label } from "@ui/elements/Label";
import { TooltipHost } from "@ui/elements/Tooltip";
import { FontIcon } from "@ui/elements/Icon";

export const ExtendedLabel = ({ label, required, disabled, children, htmlFor, description }) => {
    return (
        <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
            <Stack.Item grow verticalFill>
                <Label required={required} disabled={disabled} htmlFor={htmlFor}>
                    {label}{" "}
                    {description && (
                        <TooltipHost content={description}>
                            <FontIcon iconName="Info" />
                        </TooltipHost>
                    )}
                </Label>
            </Stack.Item>
            <Stack horizontal tokens={{ childrenGap: 5 }}>
                {children}
            </Stack>
        </Stack>
    );
};
