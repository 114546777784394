import React, { useRef, useState, useCallback } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { TextField } from "@ui/elements/TextField";
import { Stack } from "@ui/elements/Stack";
import { Callout } from "@ui/elements/Callout";
import { Calendar } from "@ui/elements/Calendar";

const rootClassName = cx("cygraph-DateRange-root", css``);

const calloutClassName = cx(
    "cygraph-DateRange-callout",
    css`
        background-color: var(--white);
        padding: var(--spacing-xs);
        min-width: 440px;
        max-width: 540px;

        & .ms-Callout-main {
            padding-bottom: var(--spacing-xxs);
        }
    `
);

const DEFAULT_STRINGS = {
    months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ],
    shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    shortDays: ["S", "M", "T", "W", "T", "F", "S"],
    goToToday: "Go to today",
    prevMonthAriaLabel: "Go to previous month",
    nextMonthAriaLabel: "Go to next month",
    prevYearAriaLabel: "Go to previous year",
    nextYearAriaLabel: "Go to next year",
    prevYearRangeAriaLabel: "Previous year range",
    nextYearRangeAriaLabel: "Next year range",
    closeButtonAriaLabel: "Close date picker",
    weekNumberFormatString: "Week number {0}",
};

export const DateRange = observer(({ className, value, required, disabled, placeholder, label, ...rest }) => {
    const buttonRef = useRef();
    const [visible, setVisible] = useState(false);
    const [textValue, setTextValue] = useState();
    const [beforeDate, setBeforeDate] = useState(new Date());

    const onInputClick = useCallback(() => {
        setVisible(true);
    }, []);

    const onDismiss = useCallback(() => {
        setVisible(false);
    }, []);

    return (
        <div className={cx(rootClassName, className)}>
            <div ref={buttonRef}>
                <TextField
                    readOnly
                    required={required}
                    disabled={disabled}
                    placeholder={placeholder}
                    label={label}
                    value={textValue || ""}
                    onClick={onInputClick}
                    iconProps={{ iconName: "Calendar" }}
                />
            </div>
            {buttonRef.current && (
                <Callout
                    isBeakVisible={false}
                    gapSpace={0}
                    hidden={!visible}
                    className={calloutClassName}
                    target={buttonRef.current}
                    onDismiss={onDismiss}
                >
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <Calendar
                            showGoToToday={false}
                            onSelectDate={setBeforeDate}
                            value={beforeDate}
                            strings={DEFAULT_STRINGS}
                            isMonthPickerVisible={true}
                        />
                    </Stack>
                </Callout>
            )}
        </div>
    );
});
