import React, { PureComponent } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { Text } from "@ui/elements/Text";
import { Stack } from "@ui/elements/Stack";
import { IconButton } from "@ui/elements/Button";
import { TooltipHost } from "@ui/elements/Tooltip";

import { ObservableBadge } from "@baseComponents/ObservableBadge";
import { ActionSet } from "@baseComponents/ActionSet";

const rootClassName = cx(
    "cygraph-EntityHeader-root",
    css`
        margin: var(--spacing-s) 0px;

        .title {
            margin: var(--spacing-xs) 0;
        }

        & .description {
            color: var(--text-color-light);
            font-size: var(--font-size-xs);
        }

        & .back {
            width: 45px;
            & i {
                font-size: 30px;
                height: 30px;
                line-height: 30px;
                margin-right: 10px;
            }
        }
    `
);

export const EntityHeader = observer(
    class EntityHeader extends PureComponent {
        render() {
            const { entity, onNavigateBack } = this.props;
            const { items, overflowItems } = this._getActionSetItems();

            return (
                <div className={rootClassName}>
                    <Stack horizontal>
                        {onNavigateBack && (
                            <Stack.Item>
                                <TooltipHost content="Navigate back to all entities">
                                    <IconButton
                                        className="back"
                                        iconProps={{ iconName: "NavigateBack" }}
                                        title="Navigate back"
                                        ariaLabel="Navigate back"
                                        onClick={onNavigateBack}
                                    />
                                </TooltipHost>
                            </Stack.Item>
                        )}
                        <Stack.Item grow>
                            <Stack horizontal horizontalAlign="space-between">
                                <ObservableBadge className="badge">Entity</ObservableBadge>
                                <ActionSet items={items} overflowItems={overflowItems} />
                            </Stack>
                        </Stack.Item>
                    </Stack>
                    <Text className="title" variant="xLarge" block>
                        {entity.code ? (
                            <>
                                {entity.code} - {entity.name}
                            </>
                        ) : (
                            entity.name
                        )}
                    </Text>
                </div>
            );
        }

        _getActionSetItems() {
            const { hideAllActions, hideAgentActions, canEdit, onEdit, canUpload, onUpload } = this.props;

            const items = [];
            const overflowItems = [];

            if (hideAllActions) {
                return { items, overflowItems };
            }

            if (!hideAgentActions) {
                items.push({
                    key: "edit",
                    name: "Edit",
                    iconOnly: true,
                    iconProps: { iconName: "Edit" },
                    disabled: !canEdit || !canEdit(),
                    ariaLabel: "Edit",
                    onClick: onEdit,
                });
            }

            items.push({
                key: "upload",
                name: "Upload",
                iconOnly: true,
                iconProps: { iconName: "Upload" },
                disabled: !canUpload || !canUpload(),
                ariaLabel: "Upload",
                onClick: () => onUpload({ internalOnly: false }),
            });

            return { items, overflowItems };
        }
    }
);
