import React, { Component } from 'react';
import { cx, css} from 'emotion';
import {
    Checkbox,
} from 'office-ui-fabric-react';

import { PrincipalPicker } from '../../../../identity/containers/PrincipalPicker';

const checkBoxClassName = cx(css`
  padding: 5px;
`);


export class CalloutPersonPicker extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showCallout: false,
            optionFilter: '',
        }
    }

    onCheckBoxChange = (element, checked) => {
        const { selectedField, onCheckBoxChange } = this.props;
        if (onCheckBoxChange) onCheckBoxChange(selectedField, element.id, checked);
    }

    onPersonPicked = (person) => {
        const { selectedField, onAddPerson } = this.props;
        if (onAddPerson) onAddPerson(person, selectedField);
    }

    render () {
        const { selectedField, filter, dataTest } = this.props;
        return (
            <div data-test={`PersonPicker${dataTest}`}>
                    {selectedField.items
                        .filter(item =>{
                            if (filter) {
                                return item.name.toLowerCase().includes(filter.toLowerCase())
                            } else {
                                return true
                            }
                        })
                        .map(item => {
                            return <Checkbox 
                                key={`k_${item.id}`}
                                className={checkBoxClassName}
                                label={item.name}
                                checked={true}
                                onChange={(ev, checked)=> {this.onCheckBoxChange(item, checked)}}
                            />
                    })}
                    <PrincipalPicker 
                        placeholder={selectedField.placeholder}
                        onSelected={(user) => this.onPersonPicked(user)}
                        selected={null}
                    />
            </div>)
    }
}