import React from "react";
import { observer } from "mobx-react";

import { WorkItemZone } from "./WorkItemZone";

export const WorkItemCustomSection = observer(({ workItem, schema }) => {
    return (
        <div>
            <WorkItemZone readOnly={true} zone="topLeft" schema={schema} workItem={workItem} />
            <WorkItemZone readOnly={true} zone="topRight" schema={schema} workItem={workItem} />
            <WorkItemZone readOnly={true} zone="middle" schema={schema} workItem={workItem} />
            <WorkItemZone readOnly={true} zone="bottomLeft" schema={schema} workItem={workItem} />
            <WorkItemZone readOnly={true} zone="bottomRight" schema={schema} workItem={workItem} />
            <WorkItemZone readOnly={true} zone="main" defaultZone schema={schema} workItem={workItem} />
        </div>
    );
});
