import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import { BoxPanel } from '../../../base/components/BoxPanel';

export const DocumentCommandBar = observer(
    class DocumentCommandBar extends Component {
        
        render () {
            const { risk, selectedDocuments, keywords, onSearchChange, view, onViewChanged, canUpload, onUploadInvoked, onDownloadInvoked } = this.props;

            const items = [
                {
                    key: 'upload',
                    text: 'Upload',
                    iconProps: { iconName: 'Upload' },
                    disabled: !canUpload || !canUpload(),
                    ariaLabel: 'Upload',
                    onClick: () => onUploadInvoked({ internalOnly: false }),
                    subMenuProps: {
                        items: [
                            {
                                key: 'uploadAsPublic',
                                text: 'Upload as Public',
                                onClick: () => onUploadInvoked({ internalOnly: false })
                            },
                            {
                                key: 'uploadAsInternal',
                                text: 'Upload as Internal',
                                disabled: true,
                                onClick: () => onUploadInvoked({ internalOnly: true })
                            },
                        ]
                    }
                },
                {
                    key: 'download',
                    text: 'Download',
                    iconProps: { iconName: 'Download' },
                    ariaLabel: 'Download',
                    disabled: !selectedDocuments || selectedDocuments.count != 1,
                    onClick: () => onDownloadInvoked(selectedDocuments.selected[0]),
                },
            ];

            const farItems = [
                {
                    key: 'showAll',
                    text: this._getViewTitle('all'),
                    checked: view == 'all',
                    onClick: () => onViewChanged('all')
                },
                {
                    key: 'internalOnly',
                    text: this._getViewTitle('internalOnly'),
                    checked: view == 'internalOnly',
                    onClick: () => onViewChanged('internalOnly')
                },       
                {
                    key: 'search',
                    text: 'Search',
                    onRender: () => {
                        return (
                        <SearchBox
                            value={keywords}
                            placeholder="Search by title"
                            styles={{ root: { height: 'auto', minWidth: '270px' }}}
                            onSearch={onSearchChange} />
                        );
                    }
                }
            ];

            return (
                <BoxPanel shadow attached>
                    <CommandBar
                        styles={{ root: { paddingRight: '0px' } }}
                        items={items}
                        farItems={farItems}
                    />
                </BoxPanel>
            );
        }

        _getViewTitle(view) {
            switch(view || 'all') {
                case 'all':
                    return `All`;
                case 'internalOnly':
                    return `Internal Only`;
            }
        }
    }
);