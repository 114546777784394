import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import { cx } from "emotion";

import { DefaultButton } from "office-ui-fabric-react/lib/Button";

import { AppPage } from "../../../layout/containers/AppPage";
import { ActionBar } from "../../../base/components/ActionBar";

import { ModuleIndexListView } from "../../components/modules/ModuleIndexListView";
import { ModuleFieldListView } from "../../components/modules/ModuleFieldListView";
import { ModuleCommandBar } from "../../components/modules/ModuleCommandBar";

import { PatternJsonEditorPanel } from "../panels/PatternJsonEditorPanel";
import { AdvisoryListPanel } from "../panels/AdvisoryListPanel";
import { AdvisoryEditPanel } from "../panels/AdvisoryEditPanel";

export const PatternModulePage = inject(
    "pageContext",
    "patternStore",
    "routing"
)(
    withRouter(
        observer(
            class PatternModulePage extends Component {
                componentWillMount() {
                    const { patternStore, match } = this.props;
                    return patternStore.moduleStore.loadPattern(match.params.id, match.params.version, match.params.moduleId);
                }

                componentWillReceiveProps(nextProps) {
                    const { patternStore, match } = nextProps;
                    return patternStore.moduleStore.loadPattern(match.params.id, match.params.version, match.params.moduleId);
                }

                render() {
                    const routing = this.props.routing;
                    const { moduleStore, editorStore } = this.props.patternStore;
                    const { loading, error, breadcrumb, pattern, manifest, isDirty, module, indexMap } = moduleStore;

                    const fullView = module && (module.groups.length > 0 || isDirty);

                    return (
                        <AppPage
                            error={error}
                            breadcrumb={breadcrumb}
                            loading={loading}
                            hasSidebar={false}
                            className={cx("settings patterns module page--nav-white", fullView && "has--page-nav has--page-nav-l")}
                        >
                            {module && !fullView && (
                                <ActionBar
                                    className="row--s animated fast"
                                    title="Empty module"
                                    description="Looks like there are no groups or questions added to the module, get started by adding your first group."
                                    variant="info"
                                    center
                                    mini
                                >
                                    <DefaultButton onClick={() => editorStore.onAddGroup({ module })}>Add a group</DefaultButton>
                                </ActionBar>
                            )}
                            {pattern && manifest && module && fullView && (
                                <>
                                    <div className="sidebar sidebar--left sidebar--l">
                                        <ModuleIndexListView
                                            pattern={pattern}
                                            manifest={manifest}
                                            module={module}
                                            isDirty={isDirty}
                                            indexMap={indexMap}
                                            onMeasureClick={(options) => this.onScrollToMeasure(options)}
                                            onAddOption={(options) => editorStore.onAddOption(options)}
                                            onUpdateOption={(options) => editorStore.onUpdateOption(options)}
                                            onRemoveOption={(options) => editorStore.onRemoveOption(options)}
                                            onAddMeasure={(options) => {
                                                const measure = editorStore.onAddMeasure(options);
                                                setTimeout(() => this.onScrollToMeasure({ measure }), 200);
                                            }}
                                            onMoveMeasure={(options) => editorStore.onMoveMeasure(options)}
                                            onUpdateMeasure={(options) => editorStore.onUpdateMeasure(options)}
                                            onRemoveMeasure={(options) => editorStore.onRemoveMeasure(options)}
                                            onMoveGroup={(options) => editorStore.onMoveGroup(options)}
                                            onAddGroup={(options) => editorStore.onAddGroup(options)}
                                            onEditGroup={(options) => editorStore.onEditGroup(options)}
                                            onDuplicateGroup={(options) => editorStore.onDuplicateGroup(options)}
                                            onRemoveGroup={(options) => editorStore.onRemoveGroup(options)}
                                            onMoveToModule={(options) => editorStore.onMoveToModule(options)}
                                        />
                                    </div>
                                    <div>
                                        <ModuleFieldListView
                                            pattern={pattern}
                                            manifest={manifest}
                                            module={module}
                                            isDirty={isDirty}
                                            indexMap={indexMap}
                                            advisoryMap={editorStore.advisoryMap}
                                            onListAdvisories={(options) => editorStore.onListAdvisories(options)}
                                            onAddOption={(options) => editorStore.onAddOption(options)}
                                            onUpdateOption={(options) => editorStore.onUpdateOption(options)}
                                            onRemoveOption={(options) => editorStore.onRemoveOption(options)}
                                            onAddMeasure={(options) => {
                                                const measure = editorStore.onAddMeasure(options);
                                                setTimeout(() => this.onScrollToMeasure({ measure }), 200);
                                            }}
                                            onEditMeasure={(options) => editorStore.onEditMeasure(options)}
                                            onMoveMeasure={(options) => editorStore.onMoveMeasure(options)}
                                            onUpdateMeasure={(options) => editorStore.onUpdateMeasure(options)}
                                            onRemoveMeasure={(options) => editorStore.onRemoveMeasure(options)}
                                            onMoveGroup={(options) => editorStore.onMoveGroup(options)}
                                            onAddGroup={(options) => editorStore.onAddGroup(options)}
                                            onDuplicateGroup={(options) => editorStore.onDuplicateGroup(options)}
                                            onRemoveGroup={(options) => editorStore.onRemoveGroup(options)}
                                        />
                                    </div>

                                    <ModuleCommandBar
                                        pattern={pattern}
                                        manifest={manifest}
                                        module={module}
                                        isDirty={isDirty}
                                        onSave={() => editorStore.savePattern("Build", null)}
                                        onReset={() => editorStore.onResetManifest()}
                                        onPattern={() => routing.push(`/settings/patterns/${pattern.id}`)}
                                        onModule={(module) => routing.push(`/settings/patterns/${pattern.id}/-/${module.id}`)}
                                        onDownload={(module) => moduleStore.onDownload(module)}
                                    />

                                    <PatternJsonEditorPanel
                                        title={editorStore.jsonEditorStore.title}
                                        visible={editorStore.jsonEditorStore.visible}
                                        value={editorStore.jsonEditorStore.value}
                                        isDirty={editorStore.jsonEditorStore.isDirty}
                                        onApply={() => editorStore.jsonEditorStore.onApply()}
                                        onCancel={() => editorStore.jsonEditorStore.onCancel()}
                                        onChange={(value) => editorStore.jsonEditorStore.onChange(value)}
                                    />

                                    <AdvisoryListPanel
                                        pattern={pattern}
                                        manifest={manifest}
                                        title={editorStore.advisoryListStore.title}
                                        visible={editorStore.advisoryListStore.visible}
                                        measure={editorStore.advisoryListStore.measure}
                                        onAdd={(options) => editorStore.onAddAdvisory(options)}
                                        onEdit={(options) => editorStore.onEditAdvisory(options)}
                                        onRemove={(options) => editorStore.onRemoveAdvisory(options)}
                                        onCancel={() => editorStore.advisoryListStore.onCancel()}
                                    />

                                    <AdvisoryEditPanel
                                        title={editorStore.advisoryEditStore.title}
                                        visible={editorStore.advisoryEditStore.visible}
                                        advisory={editorStore.advisoryEditStore.advisory}
                                        measure={editorStore.advisoryEditStore.measure}
                                        isDirty={editorStore.advisoryEditStore.isDirty}
                                        isValid={editorStore.advisoryEditStore.isValid}
                                        onApply={() => editorStore.advisoryEditStore.onApply()}
                                        onCancel={() => editorStore.advisoryEditStore.onCancel()}
                                    />
                                </>
                            )}
                        </AppPage>
                    );
                }

                onScrollToMeasure = ({ measure }) => {
                    if (measure && measure.id) {
                        const el = document.getElementById(measure.id);
                        if (el) {
                            window.scrollTo({ behavior: "smooth", top: el.offsetTop });
                        }
                    }
                };
            }
        )
    )
);
