import React from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { useStores } from "@hooks/useStores";

import { Stack } from "office-ui-fabric-react/lib/Stack";
import { PrimaryButton, ActionButton } from "office-ui-fabric-react/lib/Button";
import { PanelType } from "office-ui-fabric-react/lib/Panel";

import { ObservablePanel } from "../../../base/components/ObservablePanel";
import { RiskTypeForm } from "../../components/core/RiskTypeForm";

export const RiskTypeNewPanel = withRouter(
    observer((props) => {
        const { onClosePanel, onSaveNewRiskType } = props;
        const { riskStore, principalContext } = useStores();
        const { riskTypeNewFormStore, registryStore } = riskStore;
        const { riskRegistriesRoles } = registryStore;
        const { visible, formData, isValid, isSaving } = riskTypeNewFormStore;

        return (
            <ObservablePanel
                hidden={!visible}
                pageType={isSaving ? "Saving ..." : "Create Risk Type"}
                panelType={PanelType.medium}
                onClosePanel={onClosePanel}
                onRenderFooter={() => {
                    return (
                        <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 20 }}>
                            <ActionButton
                                styles={{ root: { height: "32px" } }}
                                onClick={() => {
                                    riskTypeNewFormStore.reset();
                                    riskTypeNewFormStore.hide();
                                    if (onClosePanel) onClosePanel();
                                }}
                                text="Cancel"
                            />
                            {riskTypeNewFormStore.isChanged && (
                                <ActionButton
                                    styles={{ root: { height: "32px" } }}
                                    onClick={() => {
                                        riskTypeNewFormStore.reset();
                                    }}
                                    text="Revert changes"
                                />
                            )}
                            <PrimaryButton
                                onClick={async () => {
                                    await riskTypeNewFormStore.save();
                                    if (onSaveNewRiskType) onSaveNewRiskType();
                                }}
                                iconProps={{ iconName: "Save" }}
                                text="Save"
                                disabled={!isValid}
                            />
                        </Stack>
                    );
                }}
            >
                {formData && <RiskTypeForm formData={formData} />}
            </ObservablePanel>
        );
    })
);
