import { observable, flow } from "mobx";

import { RiskService } from "../../../api/risks";
import { PaginationStore } from "../../base/PaginationStore";
import { RiskStore } from "./RiskStore";

export class RiskDashboardStore {
    private parentStore: RiskStore;
    private riskService: RiskService;

    @observable public loading: boolean = false;
    @observable public risks: any = {
        myRecent: {
            loading: false,
            items: [],
            pagination: new PaginationStore(),
            title: "Requested by me",
            templateId: "f4f60d5a-1ca6-4db0-b12f-510c290f32e8",
        },
        assignedToMe: {
            loading: false,
            items: [],
            pagination: new PaginationStore(),
            title: "Assigned to me",
            templateId: "6b37793c-3fca-420c-aec7-0948691af20a",
        },
    };
    @observable public error: any;
    @observable public breadcrumb: any[] = [
        {
            text: "Risk Management",
            key: "risks",
            onClick: () => this._goToUrl(`/risks/dashboard`),
        },
        { text: "Risk Dashboard", key: "dashboard", isCurrentItem: true },
    ];

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
    }

    public loadDashboard = flow(function* (query: any) {
        this.loading = true;
        for (let [id, column] of Object.entries<any>(this.risks)) {
            column.loading = true;
            column.items = [];
        }

        try {
            for (let [id, column] of Object.entries<any>(this.risks)) {
                const result = yield this.riskService.searchRisks({
                    pageSize: 10,
                    query: {
                        templateId: column.templateId,
                        ...{ type: { template: ["zerodai:risks:generic"] } },
                    },
                });

                column.loading = false;
                column.items = result.items;
                column.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
            }
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.loading = false;
        }
    });

    _goToUrl = (url) => {
        if (url) {
            this.parentStore.rootStore.routing.push(url);
        }
    };
}
