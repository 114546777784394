import React, { useCallback } from "react";
import { observer } from "mobx-react";

import { PanelType } from "office-ui-fabric-react/lib/Panel";
import { Text } from "office-ui-fabric-react/lib/Text";

import { ActionButton } from "@ui/elements/Button";

import { ObservablePanel } from "@modules/base/components/ObservablePanel";
import { ActionPanel } from "@modules/base/components/ActionPanel";

import { CatalogueListView } from "@modules/products/components/catalogue/CatalogueListView";
import { CatalogueCommandBar } from "@modules/products/components/catalogue/CatalogueCommandBar";

export const ProductPickerPanel = observer(({ store }) => {
    const { visible, loading, query, filteredProducts, categories, processing } = store;

    const onSelect = useCallback((product) => store.resolve(true, product), [store]);

    const onDismiss = useCallback(() => store.resolve(false), [store]);

    const onKeywordsChange = useCallback((keywords) => (query.keywords = keywords), [store, query]);

    const onCategoryChange = useCallback((category) => (query.category = category), [store, query]);

    return (
        <ObservablePanel
            hidden={!visible}
            pageType={"Products & Services"}
            panelType={PanelType.medium}
            onClosePanel={onDismiss}
            onLightDismissClick={onDismiss}
            onRenderFooter={() => (
                <ActionPanel>
                    <ActionButton onClick={onDismiss} text="Cancel" disabled={processing} />
                </ActionPanel>
            )}
        >
            <div style={{ marginTop: 10 }}>
                <CatalogueCommandBar
                    className="row--s"
                    categories={categories}
                    category={query ? query.category : "All"}
                    onCategoryChange={onCategoryChange}
                    keywords={query ? query.keywords : null}
                    onKeywordsChange={onKeywordsChange}
                    pickerMode
                />

                {query && query.category && query.category !== "All" && (
                    <Text className="row--s" variant="large" block>
                        {query.category}
                    </Text>
                )}

                <CatalogueListView
                    pickerMode
                    loading={loading}
                    products={filteredProducts}
                    isSimplifiedView={query ? query.isSimplifiedView : false}
                    onCardClick={onSelect}
                />
            </div>
        </ObservablePanel>
    );
});
