import React, { Component } from "react";
import { observer } from "mobx-react";

import Moment from "react-moment";

import { Text } from "@ui/elements/Text";

import { MessageBar } from "office-ui-fabric-react/lib/MessageBar";
import { DetailsListLayoutMode, Selection, SelectionMode, ConstrainMode } from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { ContextualMenu } from "office-ui-fabric-react/lib/ContextualMenu";
import { Icon } from "office-ui-fabric-react/lib/Icon";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { RoutingLink } from "@modules/base/components/RoutingLink";
import { WrappedContent } from "@modules/base/components/WrappedContent";

export const ControlGridView = observer(
    class ControlGridView extends Component {
        constructor(props) {
            super(props);

            this.state = {};

            this._selection = new Selection({
                onSelectionChanged: () => {
                    this.setState({
                        selectionDetails: this._getSelectionDetails(),
                    });
                },
            });

            this._columns = [
                {
                    key: "name",
                    name: "Control",
                    fieldName: "name",
                    minWidth: 200,
                    isRowHeader: true,
                    isResizable: true,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return (
                            <WrappedContent>
                                <RoutingLink
                                    url={`/settings/control-sets/${item.setId}/${item.id}`}
                                    onClick={() => this.props.onPageClick && this.props.onPageClick(item)}
                                    onClickEnabled={this.props.onPageClick}
                                >
                                    {item.name}
                                </RoutingLink>
                                <br />
                                <Text variant="small" block>
                                    {item.description}
                                </Text>
                            </WrappedContent>
                        );
                    },
                    isPadded: true,
                },
                {
                    key: "code",
                    name: "Code",
                    fieldName: "code",
                    minWidth: 120,
                    maxWidth: 150,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return <div>{item.code}</div>;
                    },
                    isPadded: true,
                },
                {
                    key: "level",
                    name: "Level",
                    fieldName: "level",
                    minWidth: 60,
                    maxWidth: 80,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return <div>{item.level}</div>;
                    },
                    isPadded: true,
                },
                {
                    key: "order",
                    name: "Order",
                    fieldName: "order",
                    minWidth: 60,
                    maxWidth: 80,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return <div>{item.order}</div>;
                    },
                    isPadded: true,
                },
                {
                    key: "active",
                    name: "Active?",
                    fieldName: "active",
                    minWidth: 60,
                    maxWidth: 80,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return (
                            <div>
                                {item.active && (
                                    <div title={`Is active: ${item.active ? "yes" : "no"}`}>
                                        <Icon iconName="Completed" />
                                    </div>
                                )}
                            </div>
                        );
                    },
                    isPadded: true,
                },
                {
                    key: "published",
                    name: "Published",
                    fieldName: "published",
                    minWidth: 120,
                    maxWidth: 180,
                    isResizable: true,
                    sortAscendingAriaLabel: "Sorted A to Z",
                    sortDescendingAriaLabel: "Sorted Z to A",
                    onColumnClick: this._onColumnClick,
                    data: "date",
                    onRender: (item) => {
                        return <Moment utc date={item.modified} fromNow />;
                    },
                    isPadded: true,
                },
            ];
        }

        render() {
            const { className, controls, loading, onPageClick, disableSelection } = this.props;
            const { contextualMenuProps } = this.state;

            return (
                <BoxPanel shadow>
                    <ShimmeredDetailsList
                        items={controls}
                        compact={false}
                        columns={this._columns}
                        selectionMode={disableSelection ? SelectionMode.none : SelectionMode.none}
                        getKey={this._getKey}
                        setKey="single"
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.horizontalConstrained}
                        isHeaderVisible={true}
                        selection={this._selection}
                        selectionPreservedOnEmptyClick={true}
                        onItemInvoked={onPageClick}
                        //onItemContextMenu={this._onItemContextMenu}
                        enterModalSelectionOnTouch={true}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        checkButtonAriaLabel="Row checkbox"
                        enableShimmer={loading}
                        removeFadingOverlay={true}
                        shimmerLines={15}
                        ariaLabelForShimmer="Page being fetched"
                        ariaLabelForGrid="Page list"
                    />

                    {!loading && !controls.length && <MessageBar>There are no matching controls.</MessageBar>}

                    {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}
                </BoxPanel>
            );
        }

        _getKey(item, index) {
            return item ? item.id : index;
        }

        _getSelectionDetails() {
            const selectionCount = this._selection.getSelectedCount();

            switch (selectionCount) {
                case 0:
                    return "No items selected";
                case 1:
                    return "1 item selected: " + this._selection.getSelection()[0].name;
                default:
                    return `${selectionCount} items selected`;
            }
        }

        _onColumnClick(ev, column) {
            console.log(column);
        }

        _onItemContextMenu = (item, index, ev) => {
            const contextualMenuProps = {
                target: ev.target,
                items: [
                    {
                        key: "text",
                        name: `${this._selection.getSelectedCount()} selected`,
                    },
                ],
                onDismiss: () => {
                    this.setState({
                        contextualMenuProps: undefined,
                    });
                },
            };

            if (index > -1) {
                this.setState({
                    contextualMenuProps: contextualMenuProps,
                });
            }

            return false;
        };
    }
);
