import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { ObservablePagination } from "@modules/base/components/ObservablePagination";

import { PatternGridView } from "@modules/patterns/components/core/PatternGridView";
import { PatternCommandBar } from "@modules/patterns/components/core/PatternCommandBar";

const getQueryStringParams = (query) => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query).split("&").reduce((params, param) => {
              let [key, value] = param.split("=");
              params[key] = value ? decodeURIComponent(value.replace(/\+/g, " ")) : "";
              return params;
          }, {})
        : {};
};

export const AssurancePatternsTab = observer(({}) => {
    const { patternStore, routing } = useStores();
    const { loading, patterns, pagination, query } = patternStore.browseStore;

    useEffect(() => {
        const params = getQueryStringParams(routing.location.search);
        patternStore.browseStore.loadPatterns({ page: params["p"] || 1, keywords: params["k"] });
    }, []);

    return (
        <>
            <PatternCommandBar
                query={query}
                onSearchChange={(keywords) => {
                    if (keywords) {
                        routing.push(`/settings/patterns?k=${encodeURIComponent(keywords)}`);
                    } else {
                        routing.push(`/settings/patterns`);
                    }
                }}
            />

            <PatternGridView
                patterns={patterns}
                loading={loading}
                query={{}}
                onPatternClick={(pattern) => routing.push(`/settings/patterns/${pattern.id}`)}
            />

            <ObservablePagination
                {...pagination}
                onPageChange={(index) => {
                    console.log(index);
                    routing.push(
                        `/settings/patterns?p=${index + 1}${
                            patternStore.keywords ? `&k=${encodeURIComponent(patternStore.keywords)}` : ""
                        }`
                    );
                }}
            />
        </>
    );
});
