import React from "react";
import { observer } from "mobx-react";

import { FilterBar } from "@modules/base/components/FilterBar";

export const WorkItemFilterBar = observer(({ value, onChange, context, disabled }) => {
    const filters = [
        {
            name: "keywords",
            label: "Keywords",
            type: "Search",
            keywords: true,
            placeholder: "Search by unique id or title",
        },
        {
            name: "product",
            label: "Product",
            type: "Product",
            multiple: true,
            allowClear: true,
            query: {},
        },
        {
            name: "portfolio",
            label: "Portfolio",
            type: "Portfolio",
            multiple: true,
            allowClear: true,
            query: {},
        },
        {
            name: "status",
            label: "Status",
            type: "Dropdown",
            multiple: true,
            allowClear: true,
            options: [
                { key: "Open", text: "Open" },
                { key: "Assigned", text: "Assigned" },
                { key: "InProgress", text: "In Progress" },
                { key: "Blocked", text: "Blocked" },
                { key: "Resolved", text: "Resolved" },
                { key: "Closed", text: "Closed" },
                { key: "Cancelled", text: "Cancelled" },
            ],
        },
        {
            name: "age",
            label: "Age",
            description: "Additionally filters out demand requests which have not started or has been closed",
            type: "DurationRange",
            allowClear: true,
            moreThanProps: {
                time: false,
                allowClear: true,
            },
            lessThanProps: {
                time: false,
                allowClear: true,
            },
        },
        {
            name: "assignedToUser",
            label: "Assigned To",
            type: "Principal",
            multiple: true,
            allowClear: true,
        },
    ];
    return <FilterBar value={value} onChange={onChange} context={context} filters={filters} disabled={disabled} />;
});
