import React from "react";
import { observer } from "mobx-react";

import { Stack } from "@ui/elements/Stack";
import { Shimmer, ShimmerElementType } from "@ui/elements/Shimmer";

import { BoxPanel } from "@modules/base/components/BoxPanel";

export const KeyStatisticShimmer = observer(() => {
    return (
        <BoxPanel shadow>
            <div style={{ padding: "10px 25px" }}>
                <Stack tokens={{ childrenGap: 15 }} horizontal verticalFill verticalAlign="center">
                    <Stack.Item>
                        <Shimmer width="50px" shimmerElements={[{ type: ShimmerElementType.line, height: 50 }]} />
                    </Stack.Item>
                    <Stack grow tokens={{ childrenGap: 5 }} verticalFill verticalAlign="center">
                        <Shimmer width="65%" shimmerElements={[{ type: ShimmerElementType.line, height: 12 }]} />
                        <Shimmer width="20%" shimmerElements={[{ type: ShimmerElementType.line, height: 16 }]} />
                    </Stack>
                </Stack>
            </div>
            <div style={{ padding: "7px 25px", backgroundColor: "var(--background-color)" }}>
                <Shimmer width="25%" shimmerElements={[{ type: ShimmerElementType.line, height: 10 }]} />
            </div>
        </BoxPanel>
    );
});
