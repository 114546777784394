import React, { useCallback } from "react";
import { observer } from "mobx-react";

import { PanelType } from "office-ui-fabric-react/lib/Panel";

import { ActionButton, SpinnerButton } from "@ui/elements/Button";

import { ObservablePanel } from "@modules/base/components/ObservablePanel";
import { ActionPanel } from "@modules/base/components/ActionPanel";

import { StateFlowStateEditForm } from "@modules/stateflows/components/editor/StateFlowStateEditForm";

export const StateFlowStateEditPanel = observer(({ store }) => {
    const { visible, formData, formOptions, isValid, processing } = store;

    const onSave = useCallback(() => {
        store.resolve(true);
    }, [store]);

    const onDismiss = useCallback(() => {
        store.resolve(false);
    }, [store]);

    const onEditTrigger = useCallback(
        (options) => {
            store.resolve(false, { ...options, action: "editTrigger" });
        },
        [store]
    );

    const onAddTrigger = useCallback(
        (options) => {
            store.resolve(false, { ...options, action: "addTrigger" });
        },
        [store]
    );

    return (
        <ObservablePanel
            hidden={!visible}
            pageType={(formOptions && formOptions.pageType) || "Workflow State"}
            panelType={PanelType.medium}
            onClosePanel={onDismiss}
            onLightDismissClick={onDismiss}
            onRenderFooter={() => (
                <ActionPanel>
                    <ActionButton onClick={onDismiss} text="Cancel" disabled={processing} />
                    <SpinnerButton onClick={onSave} text="Apply" disabled={!isValid || processing} loading={processing} />
                </ActionPanel>
            )}
        >
            {formData && (
                <StateFlowStateEditForm
                    formData={formData}
                    formOptions={formOptions}
                    isValid={isValid}
                    onEditTrigger={onEditTrigger}
                    onAddTrigger={onAddTrigger}
                />
            )}
        </ObservablePanel>
    );
});
