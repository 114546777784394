import React, { PureComponent } from 'react';
import { observer, inject } from 'mobx-react';

import { Dropdown } from '@ui/elements/Dropdown';
import { TextField } from '@ui/elements/TextField';
import { Toggle } from '@ui/elements/Toggle';
import { ObservableSeparator } from '@baseComponents/ObservableSeparator';

export const TablePartEditor = inject('dataSourceStore')(observer(
  class TablePartEditor extends PureComponent {

    getCoreDataSource(dataSourceId) {
        const { dataSources, dataSourceStore } = this.props;
        const { coreDataSources, selectionStore } = dataSourceStore;
        const selectedPartDataSource = dataSourceId ? dataSources.find(D => D.id === dataSourceId) : null;
        const selectedDataSource = selectedPartDataSource ? selectionStore.dataSources.find(D => D.id === selectedPartDataSource.properties.mainSource) : null;
        return selectedDataSource ? coreDataSources.find(D => D.id === selectedDataSource.coreDataSourceId) : null;
    }

    onChangeSelectedFields = (event, item) => {
        const { formData } = this.props;
        if (item) {
            if (!formData.properties.selectedFields) formData.properties.selectedFields = [];
            formData.properties.selectedFields = item.selected ? [...formData.properties.selectedFields, item.key] : formData.properties.selectedFields.filter(key => key !== item.key);
        }        
    };

    onChangeOrderBy = (event, item) => {
        const { formData } = this.props;
        if (item) {
            formData.properties.orderBy = item ? item.key : null;
        }        
    };

    onChangeOrderType = (event, item) => {
        const { formData } = this.props;
        if (item) {
            formData.properties.orderType = item ? item.key : null;
        }        
    };

    render () {
        const { formData, dataSources} = this.props;
        const coreDataSource = this.getCoreDataSource(formData.properties.dataSourceId);
        
        return (
            <div>
                <r-grid columns="2">
                    <r-cell span="2">
                        <ObservableSeparator>Table Configuration</ObservableSeparator>
                    </r-cell>
                    <r-cell span="2">
                        <div>
                            <Dropdown
                                required
                                label="Data source"
                                selectedKey={formData.properties.dataSourceId}
                                onChange={(ev, op) => {
                                    if(op) {
                                        formData.properties.dataSourceId = op.key;
                                    }
                                }}
                                options={dataSources.map(DSource => {
                                    return {
                                        key: DSource.id,
                                        text: DSource.title,
                                    }
                                })}
                            />
                        </div>
                    </r-cell>
                    {coreDataSource &&  <r-cell span="2">
                        <r-grid columns="2">
                            <r-cell span="1">
                                <div>
                                <Toggle label="Show Table Header" checked={formData.properties.showHeader} onChange={(ev, checked) => {
                                    formData.properties.showHeader = checked;
                                }} />
                                </div>
                            </r-cell>
                            <r-cell span="1">
                                <div>
                                    <Toggle label="Display style" 
                                        disabled={!coreDataSource} 
                                        checked={formData.properties.displayAsTable} 
                                        onText="Table"
                                        offText="Badges"
                                        onChange={(ev, checked) => {
                                            formData.properties.displayAsTable = checked;
                                        }}
                                    />
                                </div>
                            </r-cell>
                            {/* <r-cell span="1">
                                <div>
                                <Toggle label="Show Tooltip" disabled={!formData.properties.showTitle} checked={formData.properties.showTooltip} onChange={(ev, checked) => {
                                    formData.properties.showTooltip = checked;
                                }}  />
                                </div>
                            </r-cell> */}
                            {/* <r-cell span="1">
                                <div>
                                <Toggle label="Show export to PNG" checked={formData.properties.showPNG} onChange={(ev, checked) => {
                                    formData.properties.showPNG = checked;
                                }} />
                                </div>
                            </r-cell>
                            <r-cell span="1">
                                <div>
                                <Toggle label="Show export to CSV" checked={formData.properties.showCSV} onChange={(ev, checked) => {
                                    formData.properties.showCSV = checked;
                                }}  />
                                </div>
                            </r-cell>*/}
                        </r-grid>
                    </r-cell> }
                    {coreDataSource && formData.properties.displayAsTable && <r-cell span="2">
                        <div>
                            <Dropdown
                                required
                                multiSelect
                                label="Displayed fields"
                                selectedKeys={formData.properties.selectedFields}
                                onChange={this.onChangeSelectedFields}
                                options={coreDataSource.fields.map(F =>{
                                    return {
                                        text: F.title,
                                        key: F.id,
                                    }
                                })}
                            />
                        </div>
                    </r-cell> }
                    {/* {coreDataSource && formData.properties.displayAsTable && <r-cell span="1">
                        <div>
                            <Dropdown
                                required
                                label="Order by"
                                selectedKey={formData.properties.orderBy}
                                onChange={this.onChangeOrderBy}
                                options={coreDataSource.fields.map(F =>{
                                    return {
                                        text: F.title,
                                        key: F.id,
                                    }
                                })}
                            />
                        </div>
                    </r-cell> }
                    {coreDataSource && formData.properties.displayAsTable && <r-cell span="1">
                        <div>
                            <Dropdown
                                required
                                label="Order type"
                                selectedKey={formData.properties.orderType}
                                onChange={this.onChangeOrderType}
                                options={[{
                                        text: "Ascending",
                                        key: 'ascending',
                                    },{
                                        text: "Descending",
                                        key: 'descending',
                                    }]
                                }
                            />
                        </div>
                    </r-cell> } */}
                    {coreDataSource && formData.properties.displayAsTable && <r-cell span="2">
                        <div>
                            <TextField 
                                label="Displayed results"
                                value={formData.properties.numberOfResults}
                                onChange={(ev, val) => {
                                    formData.properties.numberOfResults = val.length > 0 ? isNaN(parseInt(val)) ? "" : parseInt(val)  : "";                                        
                            }} />
                        </div>
                    </r-cell> }
                </r-grid>
            </div>
      );
    }
  }
));
