import { observable, action } from "mobx";

import { PaginationStore } from "../../base/PaginationStore";
import { ModellingService } from "../../../api/modelling";

import { RootStore } from "../../base/RootStore";
import { ModellingAdvisoryStore } from "./ModellingAdvisoryStore";
import { ModellingControlStore } from "./ModellingControlStore";
import { ModellingVersionStore } from "./ModellingVersionStore";
export class ModellingStore {
    public modellingService: ModellingService;
    public rootStore: RootStore;

    public advisoryStore: ModellingAdvisoryStore;
    public controlStore: ModellingControlStore;
    public versionStore: ModellingVersionStore;

    @observable public selectedPattern: any;
    @observable public selectedManifest: any;
    @observable public selectedModule: any;
    @observable public selectedMeasure: any;
    @observable public patterns: any[] = [];
    @observable public pagination: PaginationStore;
    @observable public keywords: string;
    @observable public loading: boolean = false;
    @observable public saving: boolean = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.pagination = new PaginationStore();
        this.modellingService = new ModellingService(rootStore.authProvider);

        this.advisoryStore = new ModellingAdvisoryStore(this, this.modellingService);
        this.controlStore = new ModellingControlStore(this, this.modellingService);
        this.versionStore = new ModellingVersionStore(this, this.modellingService);
    }

    @action
    public setSelectedModuleById(moduleId: string) {
        const module = this.selectedManifest.modules.find((m) => m.id == moduleId);
        if (module) {
            this.selectedModule = module;
            for (let i = 0; i < module.groups.length; i++) {
                let group = module.groups[i];
                for (let j = 0; j < group.measures.length; j++) {
                    this.selectedMeasure = group.measures[j];
                    return;
                }
            }
        }
    }

    @action
    public setSelected(pattern) {
        this.selectedPattern = pattern;
    }

    @action
    public setSelectedWithId(id: string) {
        this.selectedPattern = this.patterns.find((p) => p.id == id);
    }

    @action
    public addAdvisory(advisory) {
        this.selectedManifest.advisories.push(advisory);
    }

    @action
    public removeAdvisory(advisory) {
        const advisories = this.selectedManifest.advisories;
        const index = advisories.findIndex((a) => a.id == advisory.id);
        if (index != -1) {
            advisories.splice(index, 1);
        }
    }

    @action
    public loadPatterns(query) {
        this.loading = true;
        var options = Object.assign({ page: 1, keywords: null }, query);
        return this.modellingService
            .getPatterns({
                pageSize: this.pagination.itemsPerPage,
                startIndex: (options.page - 1) * this.pagination.itemsPerPage,
                keywords: options.keywords,
            })
            .then(this.loadPatternsOnSuccess, this.loadPatternsOnError);
    }

    @action.bound
    public loadPatternsOnSuccess(result) {
        this.patterns = result.items;
        this.keywords = result.keywords;
        this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
        this.loading = false;
        return result;
    }

    @action.bound
    public loadPatternsOnError(error) {
        console.error(error);
        this.loading = false;
        throw error;
    }

    @action
    public createPattern(pattern) {
        this.saving = true;
        return this.modellingService.createPattern(pattern).then(this.createPatternOnSuccess, this.createPatternOnError);
    }

    @action.bound
    public createPatternOnSuccess(result) {
        this.selectedPattern = result;
        this.saving = false;
        return result;
    }

    @action.bound
    public createPatternOnError(error) {
        console.error(error);
        this.saving = false;
        throw error;
    }

    @action
    public updatePattern(pattern) {
        this.saving = true;
        return this.modellingService.updatePattern(pattern).then(this.updatePatternOnSuccess, this.updatePatternOnError);
    }

    @action.bound
    public updatePatternOnSuccess(result) {
        this.selectedPattern = result;
        this.saving = false;
        return result;
    }

    @action.bound
    public updatePatternOnError(error) {
        console.error(error);
        this.saving = false;
        throw error;
    }

    @action
    public loadPattern(id: string) {
        this.loading = true;
        this.selectedPattern = null;
        return this.modellingService.getPattern(id).then(this.loadPatternOnSuccess, this.loadPatternOnError);
    }

    @action.bound
    public loadPatternOnSuccess(result) {
        this.selectedPattern = result;
        this.loading = false;
        return result;
    }

    @action.bound
    public loadPatternOnError(error) {
        console.error(error);
        this.loading = false;
        throw error;
    }

    @action
    public loadPatternManifest(id: string, version: string) {
        this.loading = true;
        this.selectedManifest = null;
        return this.modellingService.getPatternManifest(id, version).then(this.loadPatternManifestOnSuccess, this.loadPatternManifestOnError);
    }

    @action.bound
    public loadPatternManifestOnSuccess(result) {
        this.selectedManifest = result;
        this.loading = false;
        return result;
    }

    @action.bound
    public loadPatternManifestOnError(error) {
        console.error(error);
        this.loading = false;
        throw error;
    }
}
