import { observable, computed, flow } from "mobx";

import { PortfolioService } from '../../../api/graph';

import { RootStore } from "../../base/RootStore";
import { PaginationStore } from '../../base/PaginationStore';
import { PortfolioEditFormStore } from './PortfolioEditFormStore';
import { PortfolioNewFormStore } from './PortfolioNewFormStore';

import { PortfolioSelectionStore } from './PortfolioSelectionStore';
import { PortfolioSharingStore } from './PortfolioSharingStore';
import { PortfolioViewerStore } from './PortfolioViewerStore';
import { PortfolioBrowseStore } from './PortfolioBrowseStore';

export class PortfolioStore {
    public portfolioService: PortfolioService;
    public rootStore: RootStore;
    public editFormStore: PortfolioEditFormStore;
    public newFormStore: PortfolioNewFormStore;

    public selectionStore: PortfolioSelectionStore;
    public sharingStore: PortfolioSharingStore;
    public browseStore: PortfolioBrowseStore;
    public viewerStore: PortfolioViewerStore;


    @observable public pagination: PaginationStore;
    @observable public selectedPortfolio: any;
    @observable public portfolios: any[] = [];
    @observable public loading: boolean = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.pagination = new PaginationStore();
        this.portfolioService = new PortfolioService(rootStore.authProvider);

        this.selectionStore = new PortfolioSelectionStore(this);
        this.sharingStore = new PortfolioSharingStore(this);
        
        this.browseStore = new PortfolioBrowseStore(this);
        this.editFormStore = new PortfolioEditFormStore(this);
        this.newFormStore = new PortfolioNewFormStore(this);
        this.viewerStore = new PortfolioViewerStore(this);
    }

    @computed
    public get topLevelPortfolios() {
        return this.browseStore.portfolios.filter(p => p.parentId == null);
    }

    @computed
    public get activePortfolios() {
        return this.browseStore.portfolios.filter(p => p.isActive);
    }

    public loadPortfolios = flow(function*(options) {
        yield this.browseStore.loadPortfolios(options);
        return this.browseStore.portfolios;
    });
}
