import React from 'react';
import { cx, css } from 'emotion';
import { RiskHeatmap } from '@modules/risks/components/reviews/RiskHeatmap';
import { useStores } from '../../../../../hooks/useStores';
import { observer } from 'mobx-react';


function getLink(action, actionType) {
    if (actionType && actionType.key === 'internalUrl') {
        return action.action
    }
    if (actionType && actionType.key === 'externalUrl') {
        return action.action
    }
    return actionType.link
}

function onClick(action, actionType, routing) {
    if (actionType && actionType.key === 'externalUrl') {
        window.location.href = action.action
    } else {
        routing.push(getLink(action, actionType))
    }
}

export const HeatmapViewerPart = observer((props) => {
    const { part, instance } = props;
    const { routing, themeProvider, dataSourceStore } = useStores();
    const theme = themeProvider.getTheme();
    const data = instance.selectionStore.data.find(D => D.id === part.properties.dataSourceId);
    const selectedPartDataSource = part.properties.dataSourceId ? instance.selectionStore.dataSources.find(D => D.id === part.properties.dataSourceId) : null;
    const coreDs = selectedPartDataSource ? dataSourceStore.coreDataSourceDataSource(selectedPartDataSource.properties.mainSource) : null;
    
    return (
        <RiskHeatmap 
            size={part.properties.hm_size}
            scores={data && data.data ? data.data : []}
            onChange={(cell) => {
                coreDs.onCellClick(cell, selectedPartDataSource.properties.query)
            }}
        />
    );
})