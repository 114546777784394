import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router';

import { Label } from 'office-ui-fabric-react/lib/Label';
import { BoxPanel } from '../../../base/components/BoxPanel';

import { AdvisoryGridView } from '../../../assessments/components/advisories/AdvisoryGridView';
import { RoutingLink } from '../../../base/components/RoutingLink';

export const RiskAssessmentTab = inject('riskStore')(withRouter(observer(
    class RiskAssessmentTab extends Component {

        componentWillMount() {
            const { riskStore, match } = this.props;
            const { assessmentStore } = riskStore;
            
            if(!assessmentStore.assessment || match.params.id !=  assessmentStore.assessment.riskId) {
                assessmentStore.loadAssessment(match.params.id).then((result) => {

                });
            }
        }

        componentWillReceiveProps(nextProps) {
            const { riskStore, match } = nextProps;
            const { assessmentStore } = riskStore;

            if(!assessmentStore.assessment || match.params.id !=  assessmentStore.assessment.riskId) {
                assessmentStore.loadAssessment(match.params.id).then((result) => {

                });
            }
        }
        
        render () {
            const { riskStore, match } = this.props;
            const { loading, assessment, error } = riskStore.assessmentStore

            return (
                <div>
                    <BoxPanel padding>
                        { assessment && (
                            <div>
                                <div className="row--xs">
                                    <Label>Assessment</Label>
                                    <p><RoutingLink url={`/assurance/browse/${assessment.assessment.id}`}>{assessment.assessment.title}</RoutingLink></p>
                                </div>
                                <div className="row--xs">
                                    <Label>Type</Label>
                                    <p>{assessment.assessment.patternVersion.name} (v{assessment.assessment.patternVersion.version})</p>
                                </div>
                                <div className="row--xs">
                                    <Label>State</Label>
                                    <p>{assessment.assessment.state}</p>
                                </div>
                            </div>
                        ) }
                        <div>
                            <div>
                                <Label>Related Advisories</Label>
                                <AdvisoryGridView 
                                    attached
                                    loading={loading}
                                    disableSelection={true}
                                    assessment={assessment ? assessment.assessment : null}
                                    advisories={assessment ? assessment.advisories : null}
                                    onAdvisoryClick={(advisory) => this._onAdvisoryClick(advisory)} />
                            </div>
                        </div>
                    </BoxPanel>

                </div>
            );
        }

        _onAdvisoryClick(advisory) {

        }
    }
)));
