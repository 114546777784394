import React from "react";
import { View } from "@react-pdf/renderer";

import { TableHeader } from "./TableHeader";
import { TableBody } from "./TableBody";

export const Table = (props) => {
    let tableHeader = null;
    let tableBody = null;

    React.Children.forEach(props.children, (c) => {
        if (c.type === TableHeader) {
            tableHeader = c;
        } else if (c.type === TableBody) {
            tableBody = React.cloneElement(c, {
                data: c.props.data ?? props.data ?? [],
            });
        }
    });

    return (
        <View
            style={{
                marginBottom: 10,
                width: "100%",
            }}
        >
            {tableHeader}
            {tableBody}
        </View>
    );
};
