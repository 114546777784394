import { observable, computed, flow } from "mobx";

import { RiskService } from "../../../api/risks";
import { RiskStore } from "./RiskStore";

export class ExceptionNewStore {
    public parentStore: RiskStore;
    public riskService: RiskService;

    @observable public typeId: string;

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [
            {
                text: "Exception Management",
                key: "exceptions",
                onClick: () => this._goToUrl(`/exceptions/dashboard`),
            },
        ];

        if (!this.exceptionType) {
            return builder;
        }

        builder.push({
            text: `New ${this.exceptionType.name}`,
            key: this.exceptionType.id,
            isCurrentItem: true,
        });

        return builder;
    }

    @computed
    public get exceptionType() {
        if (!this.typeId || !this.parentStore.typeStore.exceptionTypes) {
            return null;
        }

        return this.parentStore.typeStore.exceptionTypes.find((t) => t.id === this.typeId);
    }

    public loadNewForm = flow(function* loadNewForm(options: any) {
        this.typeId = options.typeId;
        yield this.parentStore.typeStore.loadTypes({ cached: true });

        const o = Object.assign(
            {
                allowReview: false,
                allowSubmit: false,
                disableTargetType: true,
                target: {
                    type: "Asset",
                },
                type: { ...this.exceptionType },
            },
            options
        );

        this.parentStore.editStore.load(o);
    });

    _goToUrl = (url) => {
        if (url) {
            this.parentStore.rootStore.routing.push(url);
        }
    };
}
