import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import { MeasureIndexItem } from './MeasureIndexItem';

export const MeasureIndexList = observer(
    class MeasureIndexList extends Component {
        
        render () {
            const { assessment, module, values, selectedMeasure, onMeasureClick } = this.props;
            let index = 1;

            return (
                <div className="questions__wrapper">
                    <div className="questions__scroll__wrapper scrollbar--root">
                        { module && values && module.groups.map((group) => {
                            return (
                                <div className="question__category__wrapper" key={group.id}>
                                    <div className="question__category">{group.title}</div>
                                        {
                                            group.measures && group.measures.map((measure) => {
                                                return (
                                                    this._isEnabled(assessment, measure, {values}) && values[measure.name] ?
                                                    <MeasureIndexItem 
                                                        key={measure.id}
                                                        measure={measure} 
                                                        index={index++} 
                                                        active={selectedMeasure && selectedMeasure.id == measure.id}
                                                        completed={values[measure.name].completed}
                                                        onMeasureClick={(measure) => onMeasureClick(measure)} /> : null
                                                );
                                            })
                                        }
                                </div>
                            );
                        }) }
                    </div>
                </div>
            );
        }

        _isEnabled(assessment, measure, context) {
            if(!measure.enabled) {
                return true;
            }

            return eval(measure.enabled);
        }
    }
);