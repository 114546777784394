import { PageService } from '../../../api/pages';
import { PageStore } from "./PageStore";
import { PageSelectionStore } from "./PageSelectionStore";
import { PagePermissionStore } from "./PagePermissionStore";
import { PageLifecycleStore } from "./PageLifecycleStore";
import { PageSectionEditStore } from './PageSectionEditStore';
import { PagePartEditStore } from './PagePartEditStore';
import { PagePartPickerStore } from './PagePartPickerStore';
import { PageSharingStore } from './PageSharingStore';
import { PagePartDataStore } from './PagePartDataStore';

export class PageInstanceStore {

    public pageService: PageService;
    public parentStore: PageStore;
    public selectionStore: PageSelectionStore;
    public permissionStore: PagePermissionStore
    public lifecycleStore: PageLifecycleStore;
    public sectionEditStore: PageSectionEditStore;
    public partEditStore: PagePartEditStore;
    public partPickerStore: PagePartPickerStore;
    public sharingStore: PageSharingStore;
    public partDataStore: PagePartDataStore;

    constructor(parentStore: PageStore) {
        this.parentStore = parentStore;
        this.pageService = parentStore.pageService;
        this.selectionStore = new PageSelectionStore(this);
        this.permissionStore = new PagePermissionStore(this);
        this.lifecycleStore = new PageLifecycleStore(this);
        this.sectionEditStore = new PageSectionEditStore(this);
        this.partEditStore = new PagePartEditStore(this);
        this.partPickerStore = new PagePartPickerStore(this);
        this.sharingStore = new PageSharingStore(this);
        this.partDataStore = new PagePartDataStore(this);
    }
}
