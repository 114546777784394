import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";
import { cx, css } from "emotion";

import { PageViewer } from "@modules/pages/containers/utilities/PageViewer";
import { PageInstanceStore } from "@modules/pages/stores/PageInstanceStore";

const rootClassName = cx(
    "cygraph-PageInstanceViewer-root",
    css`
        & .cygraph-PageZone-root {
            background: var(--white);
            border-radius: var(--border-radius);
            border: 1px solid var(--border-color);
            box-shadow: var(--box-shadow);
            padding: var(--spacing-s);
        }
    `
);

export const PageInstanceViewer = observer(({ path, optional }) => {
    const { pageStore } = useStores();
    const instanceStore = useMemo(() => new PageInstanceStore(pageStore), []);

    useEffect(() => {
        instanceStore.selectionStore.loadPageContentByPath(path, optional);
    }, [path]);

    return <PageViewer className={rootClassName} instance={instanceStore} />;
});
