import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { SystemViewAssessmentsStatus } from '../../components/systemView/overviewTab/SystemViewAssessmentsStatus';
import { SystemViewAdvisories } from '../../components/systemView/overviewTab/SystemViewAdvisories';

export const SystemViewOverviewTab = observer(
    class SystemViewOverviewTab extends Component {
        render () {
            const { system } = this.props;
            return (
                <React.Fragment>
                    <r-grid columns="2" columns-s="1">
                        <r-cell class="card">
                            <div className="description">{system ? system.summary : ''}</div>
                        </r-cell>                        
                    </r-grid>
                    {/* <r-grid columns="2" columns-s="1">
                        <r-cell class="card">
                            <SystemViewAssessmentsStatus assetId={this.props.assetId} routing={this.props.routing} />
                        </r-cell>
                        <r-cell class="card">
                            <SystemViewAdvisories assetId={this.props.assetId} routing={this.props.routing} />
                        </r-cell>
                    </r-grid> */}
                </React.Fragment>
            );
        }
    }
);