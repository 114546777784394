import moment from 'moment';
import { generateWorkItemQuery } from '../queries/generateWorkItemQuery';
/**
 * 
 * @param query 
 * @param groupBy 
 * @param api 
 * 
    {
        "id": "24a6e7c9-039e-4dda-93c9-e9407b0b7b38",
        "label": "App Sec Support",
        "count": 0
    },
 */
async function getWorkItemGroupByCount(query, groupBy, api) {
    const computedQuery = generateWorkItemQuery(query);
    // if (!computedQuery.timeline) {
    //     computedQuery.timeline = {
    //         before: moment().utc().startOf('month').add(1, 'month').format(),
    //         after: moment().utc().startOf('month').subtract(6, 'months').format(),
    //     };
    // }
    const results = await api({
        query: computedQuery,
        groupBy: groupBy ? groupBy : "product",
    });
    return results;
}

export { getWorkItemGroupByCount }