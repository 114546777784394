import { observable, flow, action } from "mobx";

import { ModellingService } from "../../../api/modelling";
import { PatternStore } from "./PatternStore";

export class PatternSelectionStore {
    public parentStore: PatternStore;
    public modellingService: ModellingService;

    @observable public pattern: any = null;
    @observable public manifest: any = null;
    @observable public loading: boolean = false;

    constructor(parentStore: PatternStore) {
        this.parentStore = parentStore;
        this.modellingService = parentStore.modellingService;
    }

    @action
    public setPattern(pattern) {
        this.pattern = pattern;
    }

    public loadPattern = flow(function* (id: string, version: string = null) {
        this.loading = true;

        if (!this.pattern || this.pattern.id !== id || !version || !this.manifest || this.manifest.version !== version) {
            this.pattern = null;
            this.manifest = null;
        }

        try {
            this.pattern = yield this.modellingService.getPattern(id);
            this.manifest = yield this.modellingService.getPatternManifest(this.pattern.id, version || this.pattern.currentVersion);
        } finally {
            this.loading = false;
        }
    });
}
