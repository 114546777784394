export function mutationObserver(SVG) {
  const c3ChartBarConatiner = SVG.getElementsByClassName("c3-event-rects");
  if (c3ChartBarConatiner) {
      for (let item of c3ChartBarConatiner) {
          const eventContainers = item.getElementsByClassName("c3-event-rect");
          for (let eventContainer of eventContainers) {
              // Options for the observer (which mutations to observe)
              const config = { attributes: true, childList: false, subtree: false };
              // Callback function to execute when mutations are observed
              const callback = function(mutationsList, observer) {
                  // Use traditional 'for loops' for IE 11
                  for(const mutation of mutationsList) {
                      if (mutation.type === 'attributes' && mutation.attributeName == 'style') {
                          // console.log('The ' + mutation.attributeName + ' attribute was modified.', mutation, observer);
                          mutation.target.style.removeProperty("cursor");
                      }
                  }
              };
              // Create an observer instance linked to the callback function
              const observer = new MutationObserver(callback);
              // Start observing the target node for configured mutations
              observer.observe(eventContainer, config);
          }
      }
  }
  return SVG;
}