import React, { Component } from "react";
import { observer } from "mobx-react";

import { Text } from "office-ui-fabric-react/lib/Text";
import { ObservableBadge } from "../../../base/components/ObservableBadge";
import { MarkdownViewer } from "@modules/editors/components/MarkdownViewer";

export const TriageFormHeader = observer(
    class TriageFormHeader extends Component {
        render() {
            const { configuration, hideHeader } = this.props;

            return hideHeader ? null : (
                <div className="header">
                    <ObservableBadge className="row--xxs">{configuration.title}</ObservableBadge>
                    <Text variant="medium" block className="row--s">
                        {configuration.description && <MarkdownViewer value={configuration.description} />}
                    </Text>
                </div>
            );
        }
    }
);
