import React from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { Text } from "office-ui-fabric-react/lib/Text";
import { Stack } from "office-ui-fabric-react/lib/Stack";

import { ObservableBadge } from "@modules/base/components/ObservableBadge";
import { ActionSet } from "@modules/base/components/ActionSet";

import { StateFlowTarget } from "@modules/stateflows/components/core/StateFlowTarget";

const rootClassName = cx(
    "cygraph-StateFlowHeader-root",
    css`
        margin: var(--spacing-s) 0px;

        .title {
            margin: var(--spacing-xs) 0;
        }

        & .description {
            color: var(--text-color-light);
            font-size: var(--font-size-xs);
        }

        & .tags span {
            color: var(--text-color-light);
            font-size: 12px;
            padding: 0 var(--spacing-xs);
            display: inline-block;
            margin-right: var(--spacing-xxxxs);
            border-radius: var(--border-radius);
            border: 1px solid var(--border-color-dark);
        }
    `
);

export const StateFlowHeader = observer(({ stateFlow, manifest, ...rest }) => {
    const { hideActions, canEdit, onEdit, canPublish, onPublish } = rest;

    const items = [];
    const overflowItems = [];

    if (hideActions) {
        return { items, overflowItems };
    }

    items.push({
        key: "edit",
        name: "Edit",
        iconProps: { iconName: "Edit" },
        disabled: !canEdit || !canEdit(),
        ariaLabel: "Edit",
        onClick: () => onEdit({ mode: "editor" }),
    });

    items.push({
        key: "publish",
        name: "Publish",
        primary: true,
        className: "publishButton",
        iconProps: { iconName: "ReadingMode" },
        disabled: !canPublish || !canPublish(),
        ariaLabel: "Publish",
        onClick: () => onPublish(),
    });

    return (
        <div className={rootClassName}>
            <Stack horizontal horizontalAlign="space-between">
                <ObservableBadge className="badge">
                    <StateFlowTarget value={manifest.target} /> Workflow
                </ObservableBadge>
                <ActionSet items={items} overflowItems={overflowItems} />
            </Stack>

            <Text className="title" variant="xLarge" block>
                {manifest.name} (v{manifest.version})
            </Text>
            <div className="description">{manifest.description}</div>
            {manifest.tags && (
                <div className="tags">
                    {manifest.tags.map((t, i) => (
                        <span key={i}>{t}</span>
                    ))}
                </div>
            )}
        </div>
    );
});
