import React, { Component } from 'react';
import { cx, css } from 'emotion';

import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { RichTextField } from '../../../editors/components/RichTextField';

export class ReOpenItemDialog extends Component {
    state = {
        message: ''
    }

    onCommentChange = message => {
        this.setState({
            message: message
        })
    }

    render () {
        const { onCancel, onConfirm } = this.props;
        return (
            <Dialog
                onDismiss={onCancel}
                hidden={false}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Re-open work item',
                    closeButtonAriaLabel: 'Re Open',
                    subText: 'Are you sure you want to reopen the work item?',
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}
                >
                    <RichTextField 
                            label="Please add a comment" 
                            placeholder="Please enter an optional reason to re open"
                            value={this.state.message}
                            onChange={(val) => {
                                this.onCommentChange(val);                                        
                            }} />
                    <DialogFooter>
                        <PrimaryButton 
                            onClick={() => {onConfirm(this.state.message)}}
                            text="Re Open work item" 
                        />
                        <DefaultButton
                            onClick={onCancel}
                            text="Cancel"
                        />
                    </DialogFooter>
            </Dialog>
        );
    }
};