import React, { useEffect, useCallback, useState } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { Stack } from "office-ui-fabric-react/lib/Stack";
import { TooltipHost } from "office-ui-fabric-react/lib/Tooltip";
import { Text } from "office-ui-fabric-react/lib/Text";
import { Pivot, PivotItem, PivotLinkFormat } from "office-ui-fabric-react/lib/Pivot";

import { GhostButton, DefaultButton, SpinnerButton, PrimaryButton } from "@ui/elements/Button";

import { BigModal } from "@modules/base/components/BigModal";
import { ObservablePagination } from "@modules/base/components/ObservablePagination";

import { WorkflowHistoryFilterBar } from "@modules/workflows/components/core/WorkflowHistoryFilterBar";
import { ActivityBuilderFilterBar } from "@modules/workflows/components/core/ActivityBuilderFilterBar";
import { WorkflowHistoryGridView } from "@modules/workflows/components/core/WorkflowHistoryGridView";
import { ActivityInstanceListView } from "@modules/workflows/components/core/ActivityInstanceListView";

import { WorkflowHistoryViewerPanel } from "@modules/workflows/containers/panels/WorkflowHistoryViewerPanel";
import { ActivityInstanceNewPanel } from "@modules/workflows/containers/panels/ActivityInstanceNewPanel";
import { ActivityInstanceEditPanel } from "@modules/workflows/containers/panels/ActivityInstanceEditPanel";
import { ActivityInstanceDeleteDialog } from "@modules/workflows/containers/panels/ActivityInstanceDeleteDialog";

import { ActivityInstanceBuilderTab } from "@modules/workflows/containers/tabs/ActivityInstanceBuilderTab";

export const AutomationContainerHost = observer(({ context, canManage }) => {
    const [selectedTab, setSelectedTab] = useState("automations");
    const { workflowStore, routingManager } = useStores();
    const { historyBrowseStore, browseStore, lifecycleStore, builderStore, summaryStore } = workflowStore;
    const { filteredHistory, loading, options } = historyBrowseStore;

    const visible = routingManager.isAutomationsActive;

    const searchHistory = useCallback(
        (options) => {
            if (context) {
                historyBrowseStore.loadWorkflowHistory({
                    ...options,
                    id: context.id,
                });
            }
        },
        [context]
    );

    const searchInstances = useCallback(
        (options) => {
            if (context) {
                browseStore.loadActivityInstances({
                    ...options,
                    context,
                });
            }
        },
        [context]
    );

    useEffect(() => {
        if (visible && selectedTab === "history") {
            searchHistory({});
        }
    }, [context, visible, selectedTab]);

    useEffect(() => {
        if (visible && selectedTab === "automations") {
            searchInstances({});
        }
    }, [context, visible, selectedTab]);

    const onDismiss = useCallback(
        (options) => {
            routingManager.setAutomationActive(false);
            if (context) {
                summaryStore.loadEntitySummary({ id: context.id });
            }
        },
        [context]
    );

    return (
        <Stack horizontal verticalAlign="center" verticalFill>
            <TooltipHost content={summaryStore.totalActivityCountTooltip}>
                <GhostButton
                    iconProps={{ iconName: "ChatBot", title: "Automations" }}
                    onClick={() => routingManager.setAutomationActive(!visible)}
                >
                    Automations / {summaryStore.totalActivityCount}
                </GhostButton>
            </TooltipHost>
            {visible && (
                <BigModal
                    className="animated fadeIn fast"
                    isOpen={visible}
                    onDismiss={onDismiss}
                    isBlocking={false}
                    dragOptions={false}
                >
                    <Stack horizontal horizontalAlign="space-between">
                        <Text variant="xLarge">{context.label} - Automations</Text>
                        <Stack horizontal tokens={{ childrenGap: 5 }}>
                            {selectedTab === "builder" && (
                                <>
                                    <ActivityBuilderFilterBar value={builderStore.query} onChange={(val, fs) => {}} />
                                </>
                            )}
                            {selectedTab === "automations" && (
                                <>
                                    {canManage && (
                                        <PrimaryButton
                                            iconProps={{ iconName: "Add" }}
                                            onClick={() => setSelectedTab("builder")}
                                            text="Automation"
                                        />
                                    )}

                                    <DefaultButton
                                        iconProps={{ iconName: "Refresh" }}
                                        onClick={() => searchInstances({})}
                                        text="Refresh"
                                    />
                                </>
                            )}
                            {selectedTab === "history" && (
                                <>
                                    <DefaultButton
                                        iconProps={{ iconName: "Refresh" }}
                                        onClick={() => searchHistory({})}
                                        text="Refresh"
                                    />
                                    <WorkflowHistoryFilterBar value={options} onChange={(val, fs) => {}} />
                                </>
                            )}
                        </Stack>
                    </Stack>
                    <Pivot
                        data-test="workflowTabs"
                        aria-label="Automations and run history"
                        selectedKey={selectedTab}
                        onLinkClick={(item) => {
                            setSelectedTab(item.props.itemKey);
                        }}
                        linkFormat={PivotLinkFormat.links}
                    >
                        {canManage && (
                            <PivotItem itemKey="builder" headerText="Builder">
                                <ActivityInstanceBuilderTab
                                    query={{ scope: [context.type] }}
                                    onSelect={(instance) => {
                                        lifecycleStore.onAddActivity({
                                            instance: {
                                                definition: {
                                                    uniqueId: instance.definition.uniqueId,
                                                },
                                                trigger: {
                                                    event: instance.trigger.event,
                                                    group: instance.trigger.group,
                                                    condition: instance.trigger.condition,
                                                },
                                                scope: context,
                                            },
                                            context,
                                        });
                                    }}
                                />
                            </PivotItem>
                        )}

                        <PivotItem itemKey="automations" headerText="Automations">
                            <ActivityInstanceListView
                                className="row--s"
                                context={context}
                                loading={browseStore.loading}
                                instances={browseStore.instances}
                                canCreate={canManage}
                                onCreate={() => setSelectedTab("builder")}
                                canEdit={canManage}
                                onEdit={(instance) => {
                                    lifecycleStore.onEditActivity({ instance, context }).then((result) => {
                                        if (result.success) {
                                            searchInstances({});
                                        }
                                    });
                                }}
                                onRemove={(instance) => {
                                    lifecycleStore.onDeleteActivity({ instance }).then((result) => {
                                        if (result.success) {
                                            searchInstances({});
                                        }
                                    });
                                }}
                            />
                            {!browseStore.error && (
                                <ObservablePagination
                                    {...browseStore.pagination}
                                    onPageChange={(index) => {
                                        searchInstances({ page: index + 1, query: browseStore.query });
                                    }}
                                />
                            )}
                        </PivotItem>
                        <PivotItem itemKey="history" headerText="History">
                            <WorkflowHistoryGridView
                                className="row--s"
                                loading={!options.continuationToken && loading}
                                history={filteredHistory}
                                onHistoryClick={(history) => {
                                    lifecycleStore.onViewHistory({ history });
                                }}
                            />
                            <div className="text--align--center">
                                {options.continuationToken && (
                                    <SpinnerButton
                                        disabled={loading}
                                        loading={loading}
                                        primary={false}
                                        iconProps={{ iconName: "Refresh", title: "Load more" }}
                                        onClick={() => searchHistory({ continuationToken: options.continuationToken })}
                                    >
                                        Load more
                                    </SpinnerButton>
                                )}
                            </div>
                            <WorkflowHistoryViewerPanel store={workflowStore.historyViewerStore} />
                        </PivotItem>
                    </Pivot>
                    <ActivityInstanceNewPanel store={workflowStore.newFormStore} />
                    <ActivityInstanceEditPanel store={workflowStore.editFormStore} />
                    <ActivityInstanceDeleteDialog store={workflowStore.deleteFormStore} />
                </BigModal>
            )}
        </Stack>
    );
});
