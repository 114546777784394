import React from "react";
import { StyleSheet, Page } from "@react-pdf/renderer";

import { PageHeader } from "./PageHeader";
import { PageFooter } from "./PageFooter";

const styles = StyleSheet.create({
    body: {
        paddingTop: 20,
        paddingBottom: 35,
        paddingHorizontal: 50,
    },
    header: {
        fontSize: 9,
        marginBottom: 20,
        textAlign: "center",
        color: "grey",
    },
    footer: {
        position: "absolute",
        fontSize: 9,
        bottom: 15,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
    },
});

export const StandardPage = ({ header, children, ...rest }) => {
    return (
        <Page {...rest} style={styles.body}>
            <PageHeader style={styles.header} header={header} />
            {children}
            <PageFooter style={styles.footer} />
        </Page>
    );
};
