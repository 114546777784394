import React from "react";
import { observer } from "mobx-react";

import { Stack } from "office-ui-fabric-react/lib/Stack";
import { PanelType } from "office-ui-fabric-react/lib/Panel";
import { ActionButton } from "office-ui-fabric-react/lib/Button";

import { ObservablePanel } from "../../../base/components/ObservablePanel";
import { AdvisoryGridView } from "../../components/advisories/AdvisoryGridView";

export const AdvisoryListPanel = observer(({ visible, title, pattern, manifest, measure, onAdd, onEdit, onRemove, onCancel }) => {
    return (
        <ObservablePanel
            hidden={!visible}
            pageTitle={title}
            pageType="Advisories"
            panelType={PanelType.medium}
            styles={{ content: { height: "100%" } }}
            onClosePanel={() => onCancel()}
            onRenderFooter={() => (
                <Stack horizontal horizontalAlign="space-between">
                    <Stack horizontal tokens={{ childrenGap: 15 }}></Stack>
                    <Stack horizontal tokens={{ childrenGap: 15 }}>
                        <ActionButton styles={{ root: { height: "32px" } }} onClick={() => onCancel()} text="Close" />
                    </Stack>
                </Stack>
            )}
        >
            {measure && (
                <Stack tokens={{ childrenGap: 10 }}>
                    <ActionButton iconProps={{ iconName: "Add" }} onClick={() => onAdd({ measure })}>
                        Add Advisory
                    </ActionButton>
                    <AdvisoryGridView
                        pattern={pattern}
                        manifest={manifest}
                        filter={(advisory) => advisory.measureId === measure.id}
                        onAdvisoryClick={(advisory) => onEdit({ advisory })}
                        onEditAdvisory={onEdit}
                        onRemoveAdvisory={onRemove}
                    />
                </Stack>
            )}
        </ObservablePanel>
    );
});
