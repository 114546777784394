import React, { Component } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { Text } from "office-ui-fabric-react/lib/Text";
import { MessageBar } from "office-ui-fabric-react/lib/MessageBar";
import { IconButton } from "office-ui-fabric-react/lib/Button";
import { DetailsListLayoutMode, SelectionMode, ConstrainMode } from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { ContextualMenu } from "office-ui-fabric-react/lib/ContextualMenu";

import { BoxPanel } from "../../../base/components/BoxPanel";
import { WrappedContent } from "../../../base/components/WrappedContent";
import { ListItemReveal } from "../../../base/components/ListItemReveal";
import { AdvisorySeverity } from "../../../assessments/components/advisories/AdvisorySeverity";

export const AdvisoryGridView = observer(
    class AdvisoryGridView extends Component {
        constructor(props) {
            super(props);

            this.state = {};
        }

        render() {
            const { loading, className, onAdvisoryClick, onEditAdvisory, onRemoveAdvisory } = this.props;
            const { contextualMenuProps } = this.state;

            this._columns = [
                {
                    key: "title",
                    name: "Title",
                    fieldName: "title",
                    minWidth: 310,
                    isRowHeader: true,
                    isResizable: true,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return (
                            <WrappedContent>
                                <a href="javascript:" onClick={() => this.props.onAdvisoryClick(item)}>
                                    {item.title}
                                </a>
                                {(onEditAdvisory || onRemoveAdvisory) && (
                                    <ListItemReveal active={true}>
                                        <IconButton
                                            styles={{ menuIcon: { fontWeight: "bold", fontSize: "16px" } }}
                                            menuIconProps={{ iconName: "MoreVertical" }}
                                            menuProps={{
                                                items: [
                                                    {
                                                        key: "editAdvisory",
                                                        text: "Edit Advisory",
                                                        iconProps: { iconName: "Edit" },
                                                        disabled: !onEditAdvisory,
                                                        onClick: (e) => {
                                                            e.preventDefault();
                                                            onEditAdvisory({ advisory: item });
                                                        },
                                                    },
                                                    {
                                                        key: "removeAdvisory",
                                                        text: "Remove Advisory",
                                                        iconProps: { iconName: "Delete" },
                                                        disabled: !onRemoveAdvisory,
                                                        onClick: (e) => {
                                                            e.preventDefault();
                                                            onRemoveAdvisory({ advisory: item });
                                                        },
                                                    },
                                                ],
                                            }}
                                        />
                                    </ListItemReveal>
                                )}
                                {item.description && (
                                    <Text variant="small" block>
                                        {item.description}
                                    </Text>
                                )}
                                <br />
                                {item.control.title && (
                                    <Text variant="small" block>
                                        {item.control.title}
                                    </Text>
                                )}
                            </WrappedContent>
                        );
                    },
                    isPadded: true,
                },
                {
                    key: "level",
                    name: "Severity",
                    fieldName: "level",
                    minWidth: 100,
                    maxWidth: 130,
                    isResizable: false,
                    isSorted: true,
                    isSortedDescending: false,
                    sortAscendingAriaLabel: "Sorted A to Z",
                    sortDescendingAriaLabel: "Sorted Z to A",
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return <AdvisorySeverity advisory={item} />;
                    },
                    isPadded: false,
                },
            ];

            const filtered = this._getFilteredAdvisories();

            return (
                <BoxPanel shadow className={className}>
                    <ShimmeredDetailsList
                        items={filtered}
                        compact={false}
                        columns={this._columns}
                        selectionMode={SelectionMode.none}
                        getKey={this._getKey}
                        setKey="multiple"
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.horizontalConstrained}
                        isHeaderVisible={false}
                        onItemInvoked={onAdvisoryClick}
                        //onItemContextMenu={(item, index, ev) => this._onItemContextMenu(item, index, ev) }
                        enterModalSelectionOnTouch={true}
                        checkButtonAriaLabel="Row checkbox"
                        enableShimmer={loading}
                        removeFadingOverlay={true}
                        shimmerLines={25}
                        ariaLabelForShimmer="Advisories are being fetched"
                        ariaLabelForGrid="Advisory list"
                    />

                    {!loading && !filtered.length && <MessageBar>There are no matching advisories.</MessageBar>}

                    {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}
                </BoxPanel>
            );
        }

        _getFilteredAdvisories() {
            const { manifest, keywords, filter } = this.props;

            var filtered = manifest.advisories;

            if (filter) {
                filtered = filtered.filter(filter);
            }

            if (keywords) {
                filtered = filtered.filter((a) => a.title.toLowerCase().indexOf(keywords.toLowerCase()) != -1);
            }

            return filtered;
        }

        _getKey(item, index) {
            return item ? item.id : index;
        }

        _onColumnClick(ev, column) {
            //console.log(column);
        }

        _onItemContextMenu(item, index, ev) {
            const contextualMenuProps = {
                target: ev.target,
                items: [
                    {
                        key: "text",
                        name: `${this._selection.getSelectedCount()} selected`,
                    },
                ],
                onDismiss: () => {
                    this.setState({
                        contextualMenuProps: undefined,
                    });
                },
            };

            if (index > -1) {
                this.setState({
                    contextualMenuProps: contextualMenuProps,
                });
            }

            return false;
        }
    }
);
