import React, { useRef } from "react";

import ContentEditable from "react-contenteditable";

export const ContentEditor = (props) => {
    const editorRef = useRef(props.html);

    return (
        <ContentEditable
            {...props}
            onPaste={(e) => {
                const val = e.clipboardData.getData("text/plain");
                editorRef.current = val;
            }}
            onBlur={(e) => {
                if (props.onSubmit) {
                    props.onSubmit(e, editorRef.current);
                }
            }}
            onKeyPress={(e) => {
                if (e.key == "Enter") {
                    if (!props.multiline) {
                        e.preventDefault();
                    }
                    if (props.onSubmit) {
                        props.onSubmit(e, editorRef.current);
                    }
                }
            }}
            onChange={(evt) => {
                editorRef.current = evt.target.value;
            }}
        />
    );
};
