import { observable, flow } from "mobx";

import { PortfolioService } from '../../../api/graph';
import { PortfolioStore } from "./PortfolioStore";

export class PortfolioSelectionStore {

    public portfolioService: PortfolioService;
    public parentStore: PortfolioStore;

    @observable public loading: boolean = false;
    @observable public portfolio: any;
    @observable public permission: any;

    constructor(parentStore: PortfolioStore) {
        this.parentStore = parentStore;
        this.portfolioService = parentStore.portfolioService;
    }

    public loadPortfolio = flow(function*(id) {
        this.loading = true;

        if(this.portfolio && this.portfolio.id !== id) {
            this.portfolio = null;
            this.permission = null;
            this.parentStore.sharingStore.reset();
        }
        try {
            this.portfolio = yield this.portfolioService.getPortfolio(id);
            this.permission = yield this.portfolioService.getPortfolioPermission(this.portfolio.id);
            yield this.parentStore.sharingStore.loadSharingScopes();
        } finally {
            this.loading = false;
        }
    });
}
