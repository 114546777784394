import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router';

import { AppPage } from '../../../layout/containers/AppPage';
import { ObservablePagination } from '../../../base/components/ObservablePagination';
import { SettingsListNav } from '../../../settings/containers/utilities/SettingsListNav';

import { PortfolioGridView } from '../../components/core/PortfolioGridView';
import { PortfolioCommandBar } from '../../components/core/PortfolioCommandBar';
import { PortfolioNewPanel } from '../../containers/panels/PortfolioNewPanel';
 
const getQueryStringParams = query => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
};

export const PortfolioBrowsePage = inject('pageContext', 'routing', 'portfolioStore')(withRouter(observer(
    class PortfolioBrowsePage extends Component {

        componentWillMount() {
            const { routing, portfolioStore } = this.props;

            const params = getQueryStringParams(routing.location.search);
            portfolioStore.browseStore.loadPortfolios({ page: params["p"] || 1, keywords: params["k"] });
        }

        componentWillReceiveProps(nextProps) {
            const { routing, portfolioStore } = nextProps;

            const params = getQueryStringParams(routing.location.search);
            portfolioStore.browseStore.loadPortfolios({ page: params["p"] || 1, keywords: params["k"] });
        }

        render () {
            const { routing, portfolioStore } = this.props;
            const { editFormStore, newFormStore, browseStore } = portfolioStore;
            const { breadcrumb, portfolios, keywords, loading, pagination, error } = browseStore;

            return (
                <AppPage 
                    loading={loading} 
                    error={error} 
                    breadcrumb={breadcrumb}
                    hasSidebar={false}
                    className="portfolios list has--page-nav">
                    <SettingsListNav />
                    <div>
                        <PortfolioCommandBar 
                            onNewPortfolio={() => {
                                newFormStore.show({});
                            }}
                            keywords={keywords}
                            onSearchChange={(keywords) => { 
                                routing.push(`/settings/portfolios${keywords ? `?k=${encodeURIComponent(keywords)}` : ''}`);
                            }} />

                        <PortfolioGridView
                            portfolios={portfolios}
                            loading={loading} />

                        <ObservablePagination 
                            {...pagination}
                            onPageChange={(index) => { 
                                routing.push(`/settings/portfolios?p=${index + 1}${keywords ? `&k=${encodeURIComponent(keywords)}` : ''}`);
                            }} />

                        <PortfolioNewPanel store={newFormStore} />
                    </div> 
                </AppPage>
            );
        }
    }
)));
