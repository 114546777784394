import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";

import { Stack } from "office-ui-fabric-react/lib/Stack";
import { Text } from "office-ui-fabric-react/lib/Text";
import { PanelType } from "office-ui-fabric-react/lib/Panel";
import { PrimaryButton, ActionButton } from "office-ui-fabric-react/lib/Button";

import { ObservablePanel } from "../../../base/components/ObservablePanel";
import { ReviewEditForm } from "../../components/reviews/ReviewEditForm";
import { RiskTransferForm } from "../../components/core/RiskTransferForm";

export const RiskTransferPanel = inject("routing")(
    withRouter(
        observer(
            class RiskTransferPanel extends Component {
                render() {
                    const { visible, formData, formOptions } = this.props.store;

                    return (
                        <ObservablePanel
                            hidden={!visible}
                            pageType={(formOptions && formOptions.pageType) || "Risk Transfer"}
                            panelType={PanelType.small}
                            onClosePanel={() => this.props.store.resolve({ dismiss: false })}
                            onRenderFooter={() => this._renderFooter()}
                            onLightDismissClick={() => this.props.store.resolve({ dismiss: true })}
                        >
                            {formData && formOptions && this._renderSections()}
                        </ObservablePanel>
                    );
                }

                _renderSections() {
                    const { formData, formOptions, isValid, isSectionValid, section } =
                        this.props.store;

                    return (
                        <div>
                            {section == "Risk" && (
                                <div>
                                    {formOptions.allowReview && (
                                        <div className="row--xxs" style={{ marginTop: "10px" }}>
                                            <Text variant="large">
                                                Step 1 of 2 - {formOptions.typeName} Details
                                            </Text>
                                        </div>
                                    )}
                                    <RiskTransferForm
                                        formData={formData}
                                        formOptions={formOptions}
                                        isSectionValid={isSectionValid}
                                        isValid={isValid}
                                    />
                                </div>
                            )}
                            {section == "Review" && (
                                <div>
                                    {formOptions.allowReview && (
                                        <div className="row--xxs" style={{ marginTop: "10px" }}>
                                            <Text variant="large">
                                                Step 2 of 2 - {formOptions.typeName} Review
                                            </Text>
                                        </div>
                                    )}
                                    <ReviewEditForm
                                        formData={formData.review}
                                        formOptions={formOptions}
                                        isSectionValid={isSectionValid}
                                        isValid={isValid}
                                    />
                                </div>
                            )}
                        </div>
                    );
                }

                _renderFooter() {
                    const { formData, formOptions, isValid, isSectionValid, section } =
                        this.props.store;

                    if (!formData || !formOptions) {
                        return null;
                    }

                    return (
                        <Stack horizontal horizontalAlign="space-between">
                            <Stack horizontal tokens={{ childrenGap: 15 }}>
                                {formOptions.allowReview && section != "Risk" && (
                                    <ActionButton
                                        styles={{ root: { height: "32px" } }}
                                        onClick={() => this.props.store.previousSection()}
                                        text="Back"
                                        disabled={this.props.saving}
                                    />
                                )}
                            </Stack>
                            <Stack horizontal tokens={{ childrenGap: 15 }}>
                                <ActionButton
                                    styles={{ root: { height: "32px" } }}
                                    onClick={() => this.props.store.resolve({ dismiss: false })}
                                    text="Cancel"
                                    disabled={this.props.saving}
                                />
                                {formOptions.allowReview && section == "Risk" && (
                                    <PrimaryButton
                                        onClick={() => this.props.store.nextSection()}
                                        text="Next"
                                        disabled={!isSectionValid || this.props.saving}
                                    />
                                )}
                                {!formOptions.allowReview || section == "Review" ? (
                                    <PrimaryButton
                                        onClick={() => this.props.store.resolve({ formData })}
                                        iconProps={{ iconName: "Save" }}
                                        text={formOptions.actionName}
                                        disabled={!isValid || this.props.saving}
                                    />
                                ) : null}
                            </Stack>
                        </Stack>
                    );
                }
            }
        )
    )
);
