import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { AgeIndicator } from '@baseComponents/AgeIndicator';

export const AssessmentAgeIndicator = observer(
    class AssessmentAgeIndicator extends Component {

        render () {
            const { className, assessment } = this.props;

            // if(!assessment || !assessment.dueDate || assessment.status == 'Cancelled') {
            //     return null;
            // }
            
            // console.log(assessment);
            return (
                <AgeIndicator className={className} creationDate={assessment.created} startDate={assessment.started} closedDate={assessment.closed} />
            );
        }
    }
);