import React from "react";
import { observer } from "mobx-react";
import Moment from "react-moment";
import moment from "moment";
import sanitizeHtml from "sanitize-html";

import { Text } from "office-ui-fabric-react/lib/Text";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { Label } from "office-ui-fabric-react/lib/Label";

import { TaskDueDateIndicator } from "@modules/workflows/components/tasks/TaskDueDateIndicator";
import { TaskAssignmentGridView } from "@modules/workflows/components/tasks/TaskAssignmentGridView";

export const TaskDefinitionSummary = observer(({ definition }) => {
    const assignments = definition.assignments.filter((assignment) => assignment.status !== "Revoked");
    const assignmentsWithNotes = assignments.filter((assignment) => assignment.notes != null);

    return (
        <div>
            <Text variant="large" block className="row--xxxxs">
                {definition.title}
            </Text>

            {definition.description && (
                <Text variant="medium" block className="row--xxs">
                    <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(definition.description) }} />
                </Text>
            )}

            <div className="row--xs">
                <Label>Due date</Label>
                <TaskDueDateIndicator definition={definition} defaultValue="No due date set">
                    <Moment utc date={definition.dueDate} format="Do MMM YYYY" />
                </TaskDueDateIndicator>
            </div>

            <div className="row--xs">
                <Label>Assigned to</Label>
                <TaskAssignmentGridView assignments={assignments} />
            </div>

            {assignmentsWithNotes.length ? (
                <div>
                    <Label>Notes</Label>
                    {assignmentsWithNotes.map((assignment) => {
                        const closedDate = moment.utc(assignment.closed);
                        return (
                            <TextField
                                key={assignment.id}
                                label={`${assignment.assignedTo.name} ${closedDate.format("DD-MM-YYYY")}`}
                                multiline
                                autoAdjustHeight
                                resizable={false}
                                value={assignment.notes}
                                disabled={true}
                            />
                        );
                    })}
                </div>
            ) : null}
        </div>
    );
});
