import { observable, flow, action } from "mobx";

import { PaginationStore } from "../../base/PaginationStore";
import { PropertyService } from '../../../api/properties';
import { PropertyStore } from "./PropertyStore";

export class PropertySectionBrowseStore {

    public propertyService: PropertyService;
    public parentStore: PropertyStore;

    @observable public loading: boolean = false;
    @observable public sections: any[] = [];
    @observable public query: any;
    @observable public pagination: PaginationStore;
    @observable public error: any;

    constructor(parentStore: PropertyStore) {
        this.parentStore = parentStore;
        this.propertyService = parentStore.propertyService;
        this.pagination = new PaginationStore();
    }

    public loadPropertySections = flow(function*(options: any) {
        const o = Object.assign({ page: 1, pageSize: 250, query: { keywords: null, target: null } }, options);

        this.loading = true;
        this.sections = [];
        this.query = o.query;
        this.error = null;
        
        try {
            const result = yield this.propertyService.searchPropertySections({ 
                pageSize: o.pageSize, 
                startIndex: (options.page - 1) *  o.pageSize,
                query: o.query,
            });
            this.sections = result.items;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
            this.query = result.query;
        } catch (e) {
            this.error = e;
            console.error(e)
        } finally {
            this.loading = false;
        }
    });

    @action
    public replace(section) {
        const index = this.sections.findIndex(p => p.id === section.id);

        if(index !== -1) {            
            this.sections = [...this.sections.splice(index, 1, section)];
        }
    }
}
