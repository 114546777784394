import { observable, flow, action, computed } from "mobx";

import { ModellingService } from "../../../api/modelling";
import { PatternStore } from "./PatternStore";
import { PatternEditorStore } from "./PatternEditorStore";

export class PatternModuleStore {
    private parentStore: PatternStore;
    private modellingService: ModellingService;

    @observable public loading: boolean = false;
    @observable public summary: any;
    @observable public error: any;
    @observable public moduleId: string;

    constructor(parentStore: PatternStore) {
        this.parentStore = parentStore;
        this.modellingService = parentStore.modellingService;
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [];

        builder.push({ text: "Settings", key: "settings", onClick: () => this.parentStore.rootStore.routing.push(`/settings`) });
        builder.push({ text: "Patterns Library", key: "patterns-browse", onClick: () => this.parentStore.rootStore.routing.push(`/settings/patterns`) });

        if (!this.pattern) {
            return builder;
        }

        builder.push({
            text: this.pattern.name,
            key: this.pattern.id,
            onClick: () => this.parentStore.rootStore.routing.push(`/settings/patterns/${this.pattern.id}`),
        });

        if (!this.module) {
            return builder;
        }

        builder.push({
            text: this.module.title,
            key: this.module.id,
            isCurrentItem: true,
        });

        return builder;
    }

    @computed
    public get pattern() {
        return this.parentStore.selectionStore.pattern;
    }

    @computed
    public get manifest() {
        return this.parentStore.editorStore.manifest;
    }

    @computed
    public get isDirty() {
        return this.parentStore.editorStore.isDirty;
    }

    @computed
    public get module() {
        if (this.parentStore.editorStore.manifest) {
            const manifest = this.parentStore.editorStore.manifest;
            return manifest.modules.find((m) => m.id == this.moduleId);
        }
        return null;
    }

    @computed
    public get indexMap() {
        let index = 0;
        const mapping = {};
        if (this.module && this.module.groups) {
            this.module.groups.forEach((group) => {
                group.measures.forEach((measure) => {
                    mapping[measure.id] = ++index;
                });
            });
        }
        return mapping;
    }

    public loadPattern = flow(function* (id: string, version: string = null, moduleId: string = null) {
        this.error = null;
        this.summary = null;
        this.moduleId = moduleId;

        try {
            yield this.parentStore.selectionStore.loadPattern(id, version);
            const manifest = this.parentStore.selectionStore.manifest;
            yield this.parentStore.editorStore.prepare(manifest);
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.loading = false;
        }
    });
}
