import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { TreatmentGridView } from "@modules/risks/components/treatments/TreatmentGridView";

export const RiskTreatmentsTab = observer(({ risk }) => {
    const { riskStore } = useStores();
    const { loading, treatments } = riskStore.treatmentStore;
    const riskId = risk ? risk.id : null;

    useEffect(() => {
        if (riskId) {
            riskStore.treatmentStore.loadRiskTreatments(riskId);
        }
    }, [riskId, riskStore.treatmentStore]);

    return <TreatmentGridView loading={loading} treatments={treatments} onTreatmentClick={(treatment) => {}} />;
});
