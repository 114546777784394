import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { getId } from '@uifabric/utilities/lib/getId';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { ComboBox } from 'office-ui-fabric-react/lib/ComboBox';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';

import { GroupPickerStore } from '../../stores/GroupPickerStore';

export const GroupDropDown = inject('principalStore')(observer(
    class GroupDropDown extends Component {

        constructor(props) {
            super(props);

            this.state = { 
                pickerStore: new GroupPickerStore(this.props.principalStore),
            }
        }

        componentWillMount() {
            const { pickerStore } = this.state;
            const { multiple, selected, selectedId, selectedIds } = this.props;

            if(!pickerStore.loading && pickerStore.groups.length == 0) {
                pickerStore.loadGroups();
            }

            if(typeof(selected) !== 'undefined') {
                pickerStore.setSelected(multiple ? (selected || []) : (selected ? [selected] : []));
            } else if(!multiple) {
                pickerStore.setSelectedWithId(selectedId);
            } else {
                pickerStore.setSelectedWithIds(selectedIds);
            }
        }

        componentWillReceiveProps(nextProps) {
            const { pickerStore } = this.state;
            const { multiple, selected, selectedId, selectedIds } = nextProps;

            if(!pickerStore.loading && pickerStore.groups.length == 0) {
                pickerStore.loadGroups();
            }

            if(typeof(selected) !== 'undefined') {
                pickerStore.setSelected(multiple ? (selected || []) : (selected ? [selected] : []));
            } else if(!multiple) {
                pickerStore.setSelectedWithId(selectedId);
            } else {
                pickerStore.setSelectedWithIds(selectedIds);
            }
        }
    
        render () {
            const { pickerStore } = this.state;
            const { label, required, placeholder, multiple, disabled, className, onChange, except } = this.props;
            const { groups, selectedGroupIds, loading } = pickerStore;
            
            const selected = multiple ? selectedGroupIds : (selectedGroupIds.length ? selectedGroupIds[0] : null);
            const pickerId = getId('groupDropDown');

            return (
                <div className={className}>
                    <Stack horizontal={true} verticalAlign={'center'}>
                        <Label required={required} disabled={disabled} htmlFor={pickerId}>{label}</Label>
                        { loading && <Spinner size={SpinnerSize.xSmall} /> }
                    </Stack>
                    <ComboBox
                        id={pickerId}
                        placeholder={placeholder}
                        disabled={disabled}
                        required={required}
                        multiSelect={multiple}
                        selectedKey={selected}
                        allowFreeform={true}
                        useComboBoxAsMenuWidth={true}
                        autoComplete="on"
                        options={groups.filter(r => (except || []).indexOf(r.id) === -1).map(p => ({ key: p.id, text: p.name, data: p }))}
                        onChange={(event, option, index, value) => {
                            if(multiple) {
                                if(option) {
                                    const newSelected = [...selectedGroupIds];
                                    const index = newSelected.indexOf(option.key);
                                    if(index == -1) {
                                        newSelected.push(option.key);
                                    } else {
                                        newSelected.splice(index, 1);
                                    }
                                    pickerStore.setSelectedWithIds(newSelected);

                                    if(onChange) {
                                        onChange(groups.filter(r => newSelected.indexOf(r.id) != -1));
                                    }
                                }
                            } else {
                                pickerStore.setSelectedWithId(option ? option.key : null);
                                if(onChange) {
                                    onChange(option ? option.data : null);
                                }
                            }
                        }} />
                </div>
            );
        }
    }
));
