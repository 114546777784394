import { observable, action, computed, flow } from "mobx";
import { BaseChartStoreClass } from './BaseChartStoreClass';

export class TimeSeriesStore extends BaseChartStoreClass {

/**
 *  Expected format
[
        {
            name: 'Open',
            values: [
                {
                    x: "2020-01-01",
                    value: 30
                },
                {
                    x: "2020-01-02",
                    value: 20
                },
                {
                    x: "2020-01-03",
                    value: 50
                },
                {
                    x: "2020-01-04",
                    value: 30
                },
                {
                    x: "2020-01-05",
                    value: 20
                },
                {
                    x: "2020-01-06",
                    value: 50
                }, 
            ],
            type: 'bar',
        },
        {
            name: 'Closed',
            values: [
                {
                    x: "2020-01-01",
                    value: 30
                },
                {
                    x: "2020-01-03",
                    value: 10
                },
                {
                    x: "2020-01-04",
                    value: 30
                },
                {
                    x: "2020-01-02",
                    value: 18
                },
                {
                    x: "2020-01-05",
                    value: 0
                },
                {
                    x: "2020-01-06",
                    value: 14
                }, 
            ],
            type: 'bar',
        },
        {
            name: 'Total',
            values: [
                {
                    x: "2020-01-01",
                    value: 50
                },
                {
                    x: "2020-01-02",
                    value: 71
                },
                {
                    x: "2020-01-03",
                    value: 92
                },
                {
                    x: "2020-01-04",
                    value: 150
                },
                {
                    x: "2020-01-05",
                    value: 210
                },
                {
                    x: "2020-01-06",
                    value: 240
                }, 
            ],
            type: 'spline',
        }
    ]
 */
    private onClicks = {};
    public onClickCallbackExists = false;

    @computed public get data() {
        return this.rawData.map((D) => {          
            return {
                name: D.label,
                values: D.dataSource.map((DS, i) => {                        
                    this.onClicks[`${i}${D.label}`] = this.isFunction(D.onClick) ? D.onClick.bind(this, [DS.id, D.dataValue]) : null;
                    this.onClickCallbackExists = this.isFunction(D.onClick) ? true : false;
                    return {
                        x: DS[D.dataX] || '',
                        value: DS[D.dataValue] || 0,
                    }
                }),
                type: D.type,
            }
        });
    }

    private sortByDate(a:string, b:string) {
        return new Date(a).getTime() - new Date(b).getTime()
    }

    private sortObjectByDate(a:any, b:any) {
        return new Date(a.x).getTime() - new Date(b.x).getTime()
    }

    public getXLabels() {
        const xLabels = new Set();
        if (!this.data) return xLabels;
        this.data.forEach(dataSet => {
            //extract all the x values
            dataSet.values.forEach(value => {
                xLabels.add(value.x)
            });
        });
        return new Set(Array.from(xLabels))//.sort(this.sortByDate))
    }

    public fillAndSort() {
        const xLabels = this.getXLabels();
        //fill missing values
        xLabels.forEach(label => {
            //for every dataset
            this.data.forEach(dataSet => {
                //check if there is any value with the proper label in the values array
                if (!dataSet.values.find(V => V.x === label )) {
                    dataSet.values.push({
                        x: label,
                        value: 0
                    })
                }
            })
        })
        //sort the values
        this.data.forEach(dataSet => {
            //check if there is any value with the proper label in the values array
            dataSet.values = dataSet.values.slice().sort(this.sortObjectByDate)
        })
    }


    public getChartData(element) {
        const types = {};
        const xLabels = this.getXLabels();
        // debugger
        this.fillAndSort();
        this.data.forEach(dataSet => {
            types[dataSet.name] = dataSet.type
        });
        //pushing values to columns
        const columns = this.data.map(dataSet => [dataSet.name, ...dataSet.values.map(V=>V.value)]);
        //pushing x-axis values
        // columns.push(["x", ...Array.from(xLabels).sort(this.sortByDate)])
        columns.push(["x", ...Array.from(xLabels)])
        const axis = {
            x: {
                rotated: (this.orientation =='horizontal') ? true : false,
                type: 'timeseries',
                tick: {
                    format: '%Y-%m-%d'
                }
            }
        }
        let title = {};
        // if (this.title && this.showTitle) {
        //     title = {
        //         position: 'top-left',
        //         padding: this.titlePadding,
        //         text: this.title,
        //     }
        // }
        return {
            // size: this.size,
            // padding: this.padding,
            bindto: element,
            data: {
                x: 'x',
                columns,
                types,
                onclick: (d, i) => {
                    const key = `${d.x}${d.id}`;
                    if (this.isFunction(this.onClicks[key])) this.onClicks[key](d)
                },
            },
            title,
            axis,
        }
    }

    public csvData() {
        this.fillAndSort();
        const numberOfValues = this.data[0].values.length;
        const labels = Array.from(this.getXLabels());
        const csvValues = [];
        for (let i=0; i<numberOfValues; i++) {
            const csvRow = {};
            csvRow['Date'] = labels[i];
            this.data.forEach(H => {
                csvRow[H.name] = H.values[i].value;
            });
            csvValues.push(csvRow);
        }
        return csvValues;
    }

}
