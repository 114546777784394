import React from "react";

import { IconButton } from "office-ui-fabric-react/lib/Button";
import { Text } from "office-ui-fabric-react/lib/Text";
import { Stack } from "office-ui-fabric-react/lib/Stack";

import { TaskResponseStatus } from "./TaskResponseStatus";

export const TaskDefinitionHeader = ({ task, canEdit, onEdit }) => {
    const getTitle = () => {
        if (!task) {
            return "Task";
        }

        if (task.useCase == "Approval") {
            return "Approve / Reject";
        } else if (task.useCase == "Confirmation") {
            return "Confirm";
        } else if (task.useCase == "Completion") {
            return "To Do";
        } else {
            return "Task";
        }
    };

    return (
        <Stack horizontal horizontalAlign="space-between">
            <Text variant="large" block>
                {getTitle()}
            </Text>
            <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="center">
                {task && <TaskResponseStatus definition={task} />}
                {canEdit && onEdit && <IconButton onClick={() => onEdit(task)} iconProps={{ iconName: "edit" }} />}
            </Stack>
        </Stack>
    );
};
