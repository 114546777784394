export const DataSourcePartCanBeDeleted = (instance, part) => {
  const chartParts = instance.selectionStore.chartsComponent;
  let isDeletable = true;
  if (chartParts) {
    chartParts.forEach(CP => {
      if (CP.properties && CP.properties.dataSeries) {
        CP.properties.dataSeries.forEach(DS => {
          if (part.id === DS.dataSourceId) {
            isDeletable = false;  
          }
        });
      }
    });
  }
  return isDeletable;
};
