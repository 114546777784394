import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { cx, css} from 'emotion';
import {
    Callout,
    CommandBarButton,
    ActionButton,
    Stack,
    DirectionalHint,
    Separator,
    SearchBox,
  } from 'office-ui-fabric-react';
import { CalloutDropDown } from '../components/callouts/CalloutDropDown';
import { CalloutPersonPicker } from '../components/callouts/CalloutPersonPicker';
import { CalloutAsyncPicker } from '../components/callouts/CalloutAsyncPicker';


const calloutClassName = cx(
    'ms-Callout-main',
    css `
        width: 255px;
        padding: 10px;
        background-color: var(--white);
    `
);

const upperPartClassName = cx(
    css `
        max-height: 150px;
        overflow-y: auto;
        overflow-x: hidden;
    `
);

export class CalloutContainer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showCallout: false,
            optionFilter: '',
        }
    }

    renderTitle = (targerClassName) => {
        const { selectedField, labelField } = this.props;
        let selectedItems = [];
        if (selectedField.fieldType === 'asyncPicker') {
            selectedItems = selectedField.selectedItems;
        } else {
            selectedItems = selectedField.items ? selectedField.items.filter(I => I.checked): [];        
        }
        if (selectedItems && selectedItems.length > 0) {
            const label = labelField ? (selectedItems[0].data ? selectedItems[0].data[labelField] : selectedItems[0][labelField]) : (selectedItems[0].text || selectedItems[0].name);
            return <CommandBarButton
                    iconProps={{ iconName: 'ChevronDown' }}
                    text={`${selectedField.placeholder || selectedField.name} : ${label} ${selectedItems.length > 1 ? `+ ${selectedItems.length-1}` : ''}`}
                    onClick={()=> this.toogleCallout()}
                    className={targerClassName}
                />
        } else {
            return <CommandBarButton
                iconProps={{ iconName: 'ChevronDown' }}
                text={`${selectedField.placeholder || selectedField.name}`}
                onClick={()=> this.toogleCallout()}
                className={targerClassName}
            />
        }     
    }

    displayCallout = () => {
        this.setState({
            showCallout: true
        })
    }

    hideCallout = () => {
        this.setState({
            showCallout: false
        })
    }

    toogleCallout = () => {
        this.setState({
            showCallout: !this.state.showCallout
        })
    }

    updateFilter = (newValue) => {
        const { onFilterOptions } = this.props;
        this.setState({
            optionFilter: newValue
        })
        if (onFilterOptions) onFilterOptions(newValue)
    }

    onCheckBoxChange = (field, element, checked) => {
        const { onFiltersChange } = this.props;
        field.items.find(I => I.key === element).checked = checked;
        if (onFiltersChange) onFiltersChange()
    }

    onPersonaCheckBoxChange = (field, element, checked) => {
        const { onFiltersChange } = this.props;
        field.items.find(I => I.id === element).checked = checked;
        if (onFiltersChange) onFiltersChange()
    }

    
    onClearAll = (field) => {
        const { onFiltersChange } = this.props;
        field.items.forEach(I => I.checked = false);
        if (onFiltersChange) onFiltersChange()
    }

    onAddPerson = (user, field) => {
        const { onFiltersChange } = this.props;
        if (user) {
            user.checked = true;
            if  (!user.id) user.id = user.key;
            if (!field.items) field.items = [];
            field.items.push(user);
        }
        if (onFiltersChange) onFiltersChange();
    }

    render () {
        const { selectedField, type, dataTest, api, searchApi, idField, textField, pickedField, labelField, preTextField, selectedItems } = this.props;
        const targerClassName = `CalloutDropDown-${selectedField.fieldName}`;
        return (
            <>
                {this.renderTitle(targerClassName)}
                {this.state.showCallout && <Callout                    
                    target={`.${targerClassName}`}
                    onDismiss={this.hideCallout}
                    isBeakVisible={false}
                    alignTargetEdge={true}
                    gapSpace={0}
                    setInitialFocus
                    directionalHint={DirectionalHint.bottomRight}
                    directionalHintFixed={true}
                    className={calloutClassName}
                >
                    <Stack align="right" data-test={`callout-${dataTest}`} className={targerClassName}>
                        {selectedField.items && selectedField.items.length > 6 && <SearchBox placeholder="Filter" value={this.state.optionFilter} onChange={(e,newVale) => this.updateFilter(newVale) } />}
                        {selectedField.items && selectedField.items.length > 6 && <Separator />}
                        <Stack.Item className={cx('scrollbar--root', upperPartClassName)}>
                            {type === 'dropdown' && <CalloutDropDown 
                                dataTest = {dataTest}
                                selectedField = {selectedField}
                                onCheckBoxChange = {this.onCheckBoxChange}
                                filter = {this.state.optionFilter}
                            />}
                            {type === 'personPicker' && <CalloutPersonPicker 
                                dataTest = {dataTest}
                                selectedField = {selectedField}
                                onCheckBoxChange = {this.onPersonaCheckBoxChange}
                                onAddPerson = {this.onAddPerson}
                                filter = {this.state.optionFilter}
                            />}
                            {type === 'asyncPicker' && <CalloutAsyncPicker 
                                dataTest = {dataTest}
                                selectedField = {selectedField}
                                onCheckBoxChange = {this.onPersonaCheckBoxChange}
                                onAddItem = {this.onAddPerson}
                                filter = {this.state.optionFilter}
                                api = {searchApi}
                                selectedItems = {selectedItems}
                                idField={idField}
                                textField={textField}
                                labelField={labelField}
                                pickedField={pickedField}
                                preTextField={preTextField}
                            />}
                        </Stack.Item>
                        <Separator />
                        <Stack horizontal> 
                            <Stack.Item align="start">
                                <ActionButton 
                                    data-test="CalloutContainerClearSelection"
                                    iconProps={{ iconName: 'ClearSelection' }}
                                    title="Clear"
                                onClick={() => this.onClearAll(selectedField)}
                                >Clear All</ActionButton>
                            </Stack.Item>
                            <Stack.Item align="end">
                                <ActionButton 
                                    data-test="CalloutContainerClose"
                                    iconProps={{ iconName: 'Cancel' }}
                                    title="Close"
                                    onClick={this.hideCallout}
                                >Close</ActionButton>
                            </Stack.Item>
                        </Stack>                        
                    </Stack>
                    </Callout>}
            </>)
    }
}