import React from "react";
import { observer } from "mobx-react";

import { BigTextField } from "@modules/base/components/BigTextField";
import { ObservableSeparator } from "@modules/base/components/ObservableSeparator";
import { IconPicker } from "@modules/base/components/IconPicker";
import { MarkdownField } from "@modules/editors/components/MarkdownField";

import { TextField } from "@ui/elements/TextField";
import { Toggle } from "@ui/elements/Toggle";
import { Dropdown } from "@ui/elements/Dropdown";

export const StateFlowTriggerEditForm = observer(
    ({ formData, formOptions, setCommand, filteredCommands, filteredConditions, filteredStates }) => {
        return (
            <div>
                <r-grid columns="2">
                    <r-cell span="2">
                        <BigTextField
                            label="Name"
                            required
                            placeholder="Please enter a name for the trigger"
                            transparent
                            value={formData.name}
                            onChange={(ev, val) => {
                                formData.name = val;
                            }}
                        />
                    </r-cell>
                    <r-cell span="2">
                        <MarkdownField
                            label="Description"
                            value={formData.description || ""}
                            onChange={(val) => {
                                formData.description = val;
                            }}
                        />
                    </r-cell>
                    <r-cell span="2">
                        <TextField
                            label="Message"
                            placeholder="Describe the message to display when action is performed"
                            transparent
                            value={formData.message}
                            onChange={(ev, val) => {
                                formData.message = val;
                            }}
                        />
                    </r-cell>
                    <r-cell>
                        <Toggle
                            label="Is this a primary trigger?"
                            checked={formData.variant === "Primary"}
                            onChange={(ev, val) => {
                                formData.variant = val ? "Primary" : "Default";
                                if (val) {
                                    formData.location = "Default";
                                }
                                if (formData.location !== "Default" && formData.iconProps) {
                                    formData.iconProps.iconOnly = false;
                                }
                            }}
                            onText="Yes"
                            offText="No"
                        />
                    </r-cell>
                    <r-cell>
                        <Toggle
                            label="Is the trigger on the overflow menu?"
                            disabled={formData.variant === "Primary"}
                            checked={formData.location === "Overflow"}
                            onChange={(ev, val) => {
                                formData.location = val ? "Overflow" : "Default";
                                if (val && formData.iconProps) {
                                    formData.iconProps.iconOnly = false;
                                }
                            }}
                            onText="Yes"
                            offText="No"
                        />
                    </r-cell>
                    <r-cell>
                        <IconPicker
                            label="Icon"
                            value={formData.iconProps ? formData.iconProps.iconName : null}
                            onChange={(ev, val) => {
                                if (val) {
                                    formData.iconProps = formData.iconProps || {};
                                    formData.iconProps.iconName = val;
                                } else {
                                    formData.iconProps = null;
                                }
                            }}
                        />
                    </r-cell>
                    <r-cell>
                        <Toggle
                            label="Show icon only?"
                            disabled={
                                !formData.iconProps || !formData.iconProps.iconName || formData.location !== "Default"
                            }
                            checked={formData.iconProps && formData.iconProps.iconOnly}
                            onChange={(ev, val) => {
                                if (formData.iconProps) {
                                    formData.iconProps.iconOnly = val;
                                }
                            }}
                            onText="Yes"
                            offText="No"
                        />
                    </r-cell>
                    <r-cell span="2">
                        <ObservableSeparator className="row--xxs">Confirmation</ObservableSeparator>
                    </r-cell>
                    <r-cell span="2">
                        <Toggle
                            label="Enable confirm dialog?"
                            checked={formData.confirmProps !== null}
                            onChange={(ev, val) => {
                                if (val) {
                                    formData.confirmProps = {
                                        title: "Are you sure you want to perform this action?",
                                        subText: null,
                                    };
                                } else {
                                    formData.confirmProps = null;
                                }
                            }}
                            onText="Yes"
                            offText="No"
                        />
                    </r-cell>
                    {formData.confirmProps !== null && (
                        <>
                            <r-cell span="2">
                                <TextField
                                    label="Confirm dialog title"
                                    disabled={formData.confirmProps === null}
                                    value={formData.confirmProps ? formData.confirmProps.title || "" : ""}
                                    onChange={(ev, val) => {
                                        formData.confirmProps.title = val;
                                    }}
                                />
                            </r-cell>
                            <r-cell span="2">
                                <TextField
                                    label="Confirm dialog sub text"
                                    disabled={formData.confirmProps === null}
                                    multiline
                                    value={formData.confirmProps ? formData.confirmProps.subText || "" : ""}
                                    onChange={(ev, val) => {
                                        formData.confirmProps.subText = val;
                                    }}
                                />
                            </r-cell>
                        </>
                    )}
                    <r-cell span="2">
                        <ObservableSeparator className="row--xxs">Actions</ObservableSeparator>
                    </r-cell>
                    <r-cell span="1">
                        <Dropdown
                            label="Run action"
                            autoComplete="on"
                            selectedKey={formData.command ? formData.command.type : null}
                            //disabled={!formOptions.mappedToEditable}
                            onChange={(ev, op) => {
                                setCommand(op.key);
                            }}
                            options={filteredCommands.map((s) => ({ key: s.id, text: s.name }))}
                        />
                    </r-cell>
                    <r-cell span="1">
                        <Dropdown
                            label="When logged in user"
                            autoComplete="on"
                            selectedKey={formData.command ? formData.command.condition : null}
                            disabled={filteredConditions.length <= 1}
                            onChange={(ev, op) => {
                                formData.command.condition = op.key;
                            }}
                            options={filteredConditions.map((s) => ({ key: s.id, text: s.name }))}
                        />
                    </r-cell>
                    <r-cell span="1">
                        <Dropdown
                            label="Then transition to state"
                            autoComplete="on"
                            selectedKey={
                                formData.command ? formData.command.to || formOptions.state.id : formOptions.state.id
                            }
                            disabled={filteredStates.length <= 1}
                            onChange={(ev, op) => {
                                const to = op.key === formOptions.state.id ? null : op.key;
                                formData.command.to = to;
                            }}
                            options={filteredStates.map((s) => ({
                                key: s.id,
                                text: s.id === formOptions.state.id ? `${s.name} (No transition)` : s.name,
                            }))}
                        />
                    </r-cell>
                </r-grid>
            </div>
        );
    }
);
