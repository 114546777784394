import { observable, computed } from "mobx";

import { AssetService } from "../../../api/assets";
import { AssetStore } from "./AssetStore";
import { AssetSelectionStore } from "./AssetSelectionStore";
import { AssetPermissionStore } from "./AssetPermissionStore";

export class AssetLifecycleStore {
    public parentStore: AssetStore;
    public assetService: AssetService;
    public selectionStore: AssetSelectionStore;
    public permissionStore: AssetPermissionStore;

    @observable public saving: boolean = false;

    constructor(parentStore: AssetStore) {
        this.parentStore = parentStore;
        this.assetService = parentStore.assetService;
        this.selectionStore = parentStore.selectionStore;
        this.permissionStore = parentStore.permissionStore;
    }

    @computed
    public get showAgentActions() {
        return this.permissionStore.isAgent;
    }

    @computed
    public get canEdit() {
        const asset = this.selectionStore.asset;

        if (!asset) {
            return false;
        }

        return this.permissionStore.canEdit;
    }

    @computed
    public get canUpload() {
        const asset = this.selectionStore.asset;

        if (!asset) {
            return false;
        }

        return this.permissionStore.canEdit;
    }
}
