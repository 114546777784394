import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import { BoxPanel } from '../../../base/components/BoxPanel';

export const PageLibraryCommandBar = observer(
    class PageLibraryCommandBar extends Component {
        
        render () {
            const { keywords, onSearchChange, onNewLibrary, onRefresh } = this.props;

            const items = [];

            if(onNewLibrary) {
                items.push({
                  key: 'newPageLibrary',
                  text: 'New Page Library',
                  iconProps: { iconName: 'Add' },
                  onClick: onNewLibrary
                });
            }
            
            if(onRefresh) {
                items.push({
                    key: 'refresh',
                    text: 'Refresh',
                    iconProps: { iconName: 'Refresh' },
                    onClick: onRefresh
                });
            }

            const farItems = [
                {
                    key: 'search',
                    text: 'Search',
                    onRender: () => {
                        return (
                        <SearchBox
                            value={keywords}
                            placeholder="Search by name"
                            styles={{ root: { height: 'auto', minWidth: '270px' }}}
                            //onEscape={() => this._onUpdate(null)}
                            onClear={() => onSearchChange(null)}
                            onSearch={onSearchChange} />
                        );
                    }
                }
            ];

            return (
                <BoxPanel shadow>
                    <CommandBar
                        styles={{ root: { paddingRight: '0px' } }}
                        items={items}
                        farItems={farItems}
                    />
                </BoxPanel>
            );
        }
    }
);