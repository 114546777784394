import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { getDefaultBorderIncludes, transformToArray } from "./Utils";

export const TableCell = (props) => {
    let content;

    if (typeof props.children === "string") {
        content = <Text>{props.children}</Text>;
    } else if (typeof props.children === "number") {
        content = <Text>{props.children.toString()}</Text>;
    } else {
        content = props.children;
    }

    const { includeRightBorder } = getDefaultBorderIncludes(props);
    const defaultStyle = {
        flex: props.weighting ?? 1,
        justifyContent: "stretch",
        textAlign: props.textAlign ?? "left",
        fontSize: props.fontSize ?? (props.isHeader === true ? 10 : 10),
        padding: props.padding ?? props.isProperty ? "12px 8px" : props.isHeader === true ? "12px 7px" : 7,
        backgroundColor: props.backgroundColor ?? (props.isHeader === true ? "#f1f1f1" : "#ffffff"),
        borderRight: includeRightBorder && "1pt solid #e6e6e6",
        lineHeight: props.lineHeight ?? props.isProperty ? 1.3 : 1,
        wordWrap: "break-word",
        whiteSpace: "pre-wrap",
    };

    const mergedStyles = [defaultStyle, ...transformToArray(props.style)];

    return (
        <View style={mergedStyles} wrap={true}>
            {content}
        </View>
    );
};
