import { observable, action, computed, flow } from "mobx";
import { BaseChartStoreClass } from './BaseChartStoreClass';

export class NumberStore extends BaseChartStoreClass {

/**
 *  Expected format
 * [
    {
        label: 'Completed',
        value: 50
    },
    {
        label: 'Open',
        value: 20
    },
    {
        label: 'Closed',
        value: 60
    },

]
*/
    public onClick = null;
    public onClickCallbackExists = false;
    
    @computed public get data() {
        // debugger
        this.onClick = this.rawData[0].onClick;
        if (this.rawData[0].dataDisplayType === "count") {
            return {
                label: this.rawData[0].label || '',
                value: this.rawData[0].dataCount ? this.rawData[0].dataCount : 0,
                unit: this.rawData[0].unit,
                unitDisplay: this.rawData[0].unitDisplay,
                selectedIcon: this.rawData[0].selectedIcon,
                isOrdered: false,
            }
        } else if (this.rawData[0].orderField === "comparison") {
            const value = (this.rawData[0].dataSource[0]) ? this.rawData[0].dataSource[0][this.rawData[0].dataValue] : 0;
            const prevValue = (this.rawData[0].dataSource[1]) ? this.rawData[0].dataSource[1][this.rawData[0].dataValue] : 0;
            const change = prevValue > 0 ? (value / prevValue) * 100 : 0;
            const changePrecent = change !== 0 ? (change > 0 ? change - 100 : 100 - change) : 0;
            return {
                label: this.rawData[0].label || '',
                value, 
                prevValue, 
                change: Math.round(changePrecent).toFixed(0),
                increase: change >= 0 ? true : false,
                unit: this.rawData[0].unit,
                unitDisplay: this.rawData[0].unitDisplay,
                selectedIcon: this.rawData[0].selectedIcon,
                isOrdered: true,
            }
        } else {
            return {
                label: this.rawData[0].label || '',
                value: this.rawData[0].dataSource ? parseFloat((this.rawData[0].dataSource.map(v => v[this.rawData[0].dataValue] || 0).reduce((acc, value) => acc + value, 0)).toFixed(2)) : 0,
                unit: this.rawData[0].unit,
                unitDisplay: this.rawData[0].unitDisplay,
                selectedIcon: this.rawData[0].selectedIcon,
                isOrdered: false,
            }
        }
    }
}
