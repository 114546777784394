import React from "react";
import { observer } from "mobx-react";

import { Text } from "@ui/elements/Text";
import { PersonaSize } from "@ui/elements/Persona";
import { MessageBar } from "@ui/elements/MessageBar";

import { DetailsListLayoutMode, SelectionMode, ConstrainMode } from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { RoutingLink } from "@modules/base/components/RoutingLink";
import { WrappedContent } from "@modules/base/components/WrappedContent";
import { UserHoverCard } from "@modules/identity/containers/utilities/UserHoverCard";
import { PhotoPersona } from "@modules/identity/containers/utilities/PhotoPersona";
import moment from "moment";

export const DeploymentGridView = observer((props) => {
    const { deployments, loading, disableSelection, onDeploymentClick } = props;

    const columns = [
        {
            key: "threatSurface",
            name: "Threat Surface",
            fieldName: "threatSurface",
            isRowHeader: true,
            isResizable: true,
            data: "string",
            onRender: (item) => {
                return (
                    <WrappedContent>
                        <RoutingLink
                            url={`/deployments/tactical/${item.id}`}
                            onClick={() => onDeploymentClick && onDeploymentClick(item)}
                            onClickEnabled={onDeploymentClick}
                        >
                            {item.name}
                        </RoutingLink>
                        <br />
                        <Text variant="small" block>
                            {item.description}
                        </Text>
                    </WrappedContent>
                );
            },
            isPadded: true,
        },
        {
            key: "targetCapabilityLevel",
            name: "Target Capability Level",
            fieldName: "targetCapabilityLevel",
            minWidth: 170,
            isResizable: false,
            data: "string",
            onRender: (item) => {
                return <span>Organised Crime</span>;
            },
            isPadded: false,
        },
        {
            key: "complianceScore",
            name: "Compliance Score",
            fieldName: "complianceScore",
            minWidth: 140,
            isResizable: false,
            data: "string",
            onRender: (item) => {
                return <span>4.8</span>;
            },
            isPadded: false,
        },
        {
            key: "owner",
            name: "Completed",
            fieldName: "owner",
            minWidth: 170,
            maxWidth: 190,
            isResizable: false,
            data: "string",
            onRender: (item) => {
                return (
                    <UserHoverCard principal={item.modifiedBy}>
                        <PhotoPersona
                            principalId={item.modifiedBy ? item.modifiedBy.id : null}
                            size={PersonaSize.size28}
                            text={item.modifiedBy ? item.modifiedBy.name : "Unassigned"}
                            showUnknownPersonaCoin={!item.modifiedBy}
                            showSecondaryText={true}
                            secondaryText={moment.utc(item.modified).format("Do MMM YYYY")}
                        />
                    </UserHoverCard>
                );
            },
        },
    ];

    return (
        <BoxPanel shadow>
            <ShimmeredDetailsList
                items={deployments}
                compact={false}
                columns={columns}
                selectionMode={disableSelection ? SelectionMode.none : SelectionMode.none}
                getKey={(item, index) => (item ? item.id : index)}
                setKey="single"
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.horizontalConstrained}
                isHeaderVisible={true}
                selectionPreservedOnEmptyClick={true}
                onItemInvoked={onDeploymentClick}
                //onItemContextMenu={this._onItemContextMenu}
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="Row checkbox"
                enableShimmer={loading}
                removeFadingOverlay={true}
                shimmerLines={15}
                ariaLabelForShimmer="Deployments being fetched"
                ariaLabelForGrid="Deployment list"
            />

            {!loading && !deployments.length && <MessageBar>There are no matching deployments.</MessageBar>}
        </BoxPanel>
    );
});
