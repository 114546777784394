import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";

import { AppPage } from "../../../layout/containers/AppPage";
import { ObservablePagination } from "../../../base/components/ObservablePagination";
import { SettingsListNav } from "../../../settings/containers/utilities/SettingsListNav";

import { ProductGridView } from "../../components/core/ProductGridView";
import { ProductCommandBar } from "../../components/core/ProductCommandBar";
import { ProductNewPanel } from "../../containers/panels/ProductNewPanel";

const getQueryStringParams = (query) => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query).split("&").reduce((params, param) => {
              let [key, value] = param.split("=");
              params[key] = value ? decodeURIComponent(value.replace(/\+/g, " ")) : "";
              return params;
          }, {})
        : {};
};

export const ProductBrowsePage = inject(
    "pageContext",
    "routing",
    "productStore"
)(
    withRouter(
        observer(
            class ProductBrowsePage extends Component {
                componentWillMount() {
                    const { routing, productStore } = this.props;

                    const params = getQueryStringParams(routing.location.search);
                    productStore.browseStore.loadProducts({ page: params["p"] || 1, keywords: params["k"] });
                }

                componentWillReceiveProps(nextProps) {
                    const { routing, productStore } = nextProps;

                    const params = getQueryStringParams(routing.location.search);
                    productStore.browseStore.loadProducts({ page: params["p"] || 1, keywords: params["k"] });
                }

                render() {
                    const { routing, productStore } = this.props;
                    const { newFormStore, browseStore } = productStore;
                    const { breadcrumb, products, keywords, loading, pagination, error } = browseStore;

                    return (
                        <AppPage
                            loading={loading}
                            error={error}
                            breadcrumb={breadcrumb}
                            hasSidebar={false}
                            className="products list has--page-nav"
                        >
                            <SettingsListNav />
                            <div>
                                <ProductCommandBar
                                    onNewProduct={() => {
                                        newFormStore.show();
                                    }}
                                    keywords={keywords}
                                    onSearchChange={(keywords) => {
                                        routing.push(
                                            `/settings/products${keywords ? `?k=${encodeURIComponent(keywords)}` : ""}`
                                        );
                                    }}
                                />

                                <ProductGridView
                                    products={products}
                                    loading={loading}
                                    onStateFlowClick={(product) => {
                                        if (product && product.flowId) {
                                            routing.push(`/settings/workflows/${product.flowId}`);
                                        }
                                    }}
                                />

                                <ObservablePagination
                                    {...pagination}
                                    onPageChange={(index) => {
                                        routing.push(
                                            `/settings/products?p=${index + 1}${
                                                keywords ? `&k=${encodeURIComponent(keywords)}` : ""
                                            }`
                                        );
                                    }}
                                />

                                <ProductNewPanel
                                    onAfterSave={(item) => {
                                        routing.push(`/settings/products/${item.id}`);
                                    }}
                                />
                            </div>
                        </AppPage>
                    );
                }
            }
        )
    )
);
