import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { useStores } from "@hooks/useStores";

import { Text } from "@ui/elements/Text";

import { AppPage } from "@modules/layout/containers/AppPage";

import { SettingsListNav } from "@modules/settings/containers/utilities/SettingsListNav";

import { IdentitySettingsPivot } from "@modules/settings/containers/tabs/IdentitySettingsPivot";
import { IdentitySettingsTabList } from "@modules/settings/containers/tabs/IdentitySettingsTabList";

export const IdentitySettingsPage = withRouter(
    observer(({ match }) => {
        const { settingsStore } = useStores();
        const { identitySettingsStore } = settingsStore;
        const { breadcrumb, loading, error, defaultTab, selectedTab, activeTabs } = identitySettingsStore;

        useEffect(() => {
            if (match) {
                identitySettingsStore.setSelectedTab(match.params.tab);
            }
        }, [match.params.tab]);

        return (
            <AppPage
                loading={loading}
                error={error}
                breadcrumb={breadcrumb}
                hasSidebar={false}
                className="settings identity has--page-nav"
            >
                <SettingsListNav />
                <div>
                    <Text className="row--xxs" variant="xLarge" block>
                        Users &amp; Groups
                    </Text>
                    <Text className="row--s" variant="small" block>
                        Manage users and groups within your organisation.
                    </Text>

                    <IdentitySettingsPivot defaultTab={defaultTab} selectedTab={selectedTab} activeTabs={activeTabs} />
                    <IdentitySettingsTabList />
                </div>
            </AppPage>
        );
    })
);
