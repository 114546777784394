import { observable, flow, action } from "mobx";

import { RiskService } from "../../../api/risks";
import { RiskStore } from "./RiskStore";
export class RegistryPickerStore {
    public parentStore: RiskStore;
    public riskService: RiskService;

    @observable public selectedRegistryIds: string[] = [];
    @observable public registries: any[] = [];
    @observable public loading: boolean = false;
    @observable public error: any;

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
    }

    public loadRegistries = flow(function* (query: any) {
        const options = { ...{ cached: false }, ...query };
        if (options.cached && this.registries.length > 0) {
            return;
        }

        this.loading = true;
        this.registries = [];

        try {
            this.registries = yield this.riskService.getRegistries();
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.loading = false;
        }
    });

    @action
    public setSelected(registries: any[]) {
        this.selectedRegistryIds = registries.map((i) => i.id);
    }

    @action
    public setSelectedWithId(id: string | null) {
        if (!id) {
            this.selectedRegistryIds = [];
        } else {
            if (this.selectedRegistryIds.length == 1 && this.selectedRegistryIds[0] == id) {
                return;
            }
            this.selectedRegistryIds = [id];
        }
    }

    @action
    public setSelectedWithIds(ids: string[] | null) {
        if (ids == null || ids.length == 0) {
            this.selectedRegistryIds = [];
        } else {
            this.selectedRegistryIds = ids;
        }
    }
}
