import { observable, action, computed } from "mobx";

import { PaginationStore } from '../../base/PaginationStore';
import { ModellingService } from '../../../api/modelling';
import { ModellingStore } from "./ModellingStore";

export class ModellingVersionStore {
    private modellingService: ModellingService;
    public parentStore: ModellingStore;

    @observable public versions: any[] = [];
    @observable public keywords: string;
    @observable public loading: boolean = false;
    @observable public pagination: PaginationStore;

    constructor(parentStore: ModellingStore, modellingService: ModellingService) {
        this.parentStore = parentStore;
        this.modellingService = modellingService;
        this.pagination = new PaginationStore();
    }

    @action
    public loadVersions(query) {
        this.loading = true;
        var options = Object.assign({ page: 1, keywords: null }, query);
        return this.modellingService.getPatternVersions({
                id: query.id,
                parentId: query.id,
                pageSize: this.pagination.itemsPerPage, 
                startIndex: (options.page - 1) *  this.pagination.itemsPerPage,
                keywords: options.keywords
            })
            .then(this.loadVersionsOnSuccess, this.loadVersionsOnError);
    }
    
    @action.bound
    public loadVersionsOnSuccess(result) {
        this.versions = result.items;
        this.keywords = result.keywords;
        this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
        this.loading = false;
        return result;
    }

    @action.bound
    public loadVersionsOnError(error) {
        console.error(error);
        this.loading = false;
        throw error;
    }
}
