import { observable, action, computed } from "mobx";

import { StateFlowEditorStore } from "./StateFlowEditorStore";

export class StateFlowStageEditStore {
    public parentStore: StateFlowEditorStore;

    @observable public formData: any = null;
    @observable public snapshot: any = null;
    @observable public title: string = null;
    @observable public visible: boolean = false;
    @observable public processing: boolean = false;

    public options: any;

    constructor(parentStore: StateFlowEditorStore) {
        this.parentStore = parentStore;
    }

    @computed
    public get isDirty() {
        return JSON.stringify(this.snapshot) != JSON.stringify(this.formData);
    }

    @computed
    public get isValid() {
        return this.formData && this.formData.id && this.formData.name;
    }

    @action
    public show(options) {
        const promise = new Promise((resolve, reject) => {
            this.options = {
                resolve,
                reject,
            };
        });

        this.title = options.title;
        this.snapshot = options.value;
        this.formData = JSON.parse(JSON.stringify(options.value));
        this.visible = true;
        this.processing = false;

        return promise;
    }

    @action
    public hide(options) {
        this.options = null;
        this.title = null;
        this.formData = null;
        this.visible = false;
        this.processing = false;
    }

    @action
    public onChange(state) {
        this.formData = state;
    }

    @action
    public resolve(success) {
        this.processing = success;
        this.options.resolve({ success, value: this.formData });
    }

    @action
    public reject(error) {
        this.options.reject({ success: false, error });
    }
}
