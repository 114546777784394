import React, { Component } from "react";
import { observer } from "mobx-react";

import { DueIndicator } from "../../../base/components/DueIndicator";

export const RiskExpiryIndicator = observer(
    class RiskExpiryIndicator extends Component {
        render() {
            const { className, risk } = this.props;

            if (!risk || !risk.review || !risk.lifecycle) {
                return null;
            }

            return (
                <DueIndicator
                    className={className}
                    dueDate={risk.review.nextReviewDue}
                    closedDate={risk.lifecycle.closed}
                    strings={{
                        past: "Expired on",
                        soon: "Expires soon on",
                        future: "Will expire on",
                        closed: "Expiry set to",
                    }}
                />
            );
        }
    }
);
