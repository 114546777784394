import { observable, flow } from "mobx";

import { WorkflowService } from '../../../api/workflows';
import { TaskStore } from "./TaskStore";

export class TaskSummaryStore {
    public parentStore: TaskStore;
    public workflowService: WorkflowService;

    @observable public summary: any = null;
    @observable public loading: boolean = false;
    @observable public error: any = null;

    constructor(parentStore: TaskStore) {
        this.parentStore = parentStore;
        this.workflowService = parentStore.workflowService;
    }

    public loadTaskSummary = flow(function*(options: any) {
        this.loading = true;
        const o = Object.assign({ }, options);

        this.summary = null;
        this.error = null;
        
        try {
            this.summary = yield this.workflowService.getMyTaskSummary();
        } catch (e) {
            this.error = e;
            console.error(e)
        } finally {
            this.loading = false;
        }
    });
}
