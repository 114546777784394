import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react";

import * as joint from "jointjs";

import { isTargetEndState } from "../../stores/TargetMetadata";
import { StateFlowTriggerView } from "./StateFlowTriggerView";

var portsIn = {
    position: {
        name: "left",
    },
    attrs: {
        portBody: {
            magnet: "passive",
            r: 5,
            fill: "var(--text-color-dark)",
            stroke: "var(--text-color-dark)",
        },
    },
    label: {
        position: {
            name: "right",
            args: { y: 6 },
        },
        markup: [
            {
                tagName: "text",
                selector: "label",
                className: "label-text",
            },
        ],
    },
    markup: [
        {
            tagName: "circle",
            selector: "portBody",
        },
    ],
};

const portsOut = {
    position: {
        name: "right",
    },
    attrs: {
        portBody: {
            magnet: true,
            r: 8,
            fill: "#E6A502",
            stroke: "var(--text-color-dark)",
            strokeWidth: 2,
        },
    },
    label: {
        position: {
            name: "left",
            args: { y: 6 },
        },
        markup: [
            {
                tagName: "text",
                selector: "label",
                className: "label-text",
            },
        ],
    },
    markup: [
        {
            tagName: "circle",
            selector: "portBody",
        },
    ],
};

export const StateFlowStateToolItem = observer(({ state, index, manifest, parent, graph }) => {
    const rect = useMemo(() => {
        const el = new joint.shapes.standard.Rectangle({
            attrs: {
                body: {
                    fill: "var(--white)",
                    rx: 3,
                    ry: 3,
                    strokeWidth: 2,
                    stroke: "var(--text-color-dark)",
                },
                label: {
                    fill: "var(--text-color)",
                    fontSize: 14,
                },
            },
            ports: {
                groups: {
                    in: portsIn,
                    out: portsOut,
                },
            },
        });

        el.set("id", state.id);
        parent.embed(el);
        el.set("z", 60);
        graph.addCell(el);

        return el;
    }, [graph]);

    useEffect(() => {
        if (state) {
            rect.size(155, 50);
            rect.set("id", state.id);
            parent.embed(rect);

            rect.prop("data", {
                id: state.id,
                type: "state-tool-item",
                locked: false,
            });
            //rect.fitEmbeds();

            rect.attr("label/text", state.name);

            if (state.design && state.design.position) {
                rect.position(state.design.position.x, state.design.position.y);
            } else {
                rect.position(175 * index, 5, { parentRelative: true });
            }

            const initialState = manifest.initialStateId === state.id;
            if (!initialState) {
                if (!rect.hasPort({ group: "in", id: "in" })) {
                    rect.addPort({ group: "in", id: "in" });
                }
            } else {
                rect.removePort({ group: "in", id: "in" });
            }

            if (!isTargetEndState(manifest.target, state)) {
                if (!rect.hasPort({ group: "out", id: "out" })) {
                    rect.addPort({ group: "out", id: "out" });
                }
            } else {
                rect.removePort({ group: "out", id: "out" });
            }
        }

        return () => {};
    }, [rect, parent, state, index]);

    return null;
});
