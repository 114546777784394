import React from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";
import moment from "moment";

import { Label } from "@ui/elements/Label";
import { Persona, PersonaSize } from "@ui/elements/Persona";
import { TooltipHost } from "@ui/elements/Tooltip";

import { AdaptiveField } from "@modules/base/components/AdaptiveField";
import { RoutingLink } from "@modules/base/components/RoutingLink";

export const AssessmentWorkflowSection = observer(({ workItem, assessment }) => {
    const { routing } = useStores();

    if (!workItem) {
        return null;
    }

    const urlPrefix = routing.location.pathname.indexOf("/teams/assurance/") === 0 ? "/teams/demand" : "/demand";

    return (
        <r-grid columns="2" class="no--margin">
            <r-cell>
                <div className="row--xs">
                    <Label>Demand Request</Label>
                    <div>
                        <RoutingLink url={`${urlPrefix}/browse/${workItem.id}`}>{workItem.product.name}</RoutingLink>
                    </div>
                </div>
            </r-cell>
            {assessment && (
                <r-cell>
                    <div className="row--xs">
                        <Label>Status</Label>
                        <div>
                            <TooltipHost
                                content={`Internal status of ${
                                    workItem.status == "InProgress" ? '"In Progress"' : workItem.status
                                }`}
                            >
                                <span>{assessment.state}</span>
                            </TooltipHost>
                        </div>
                    </div>
                </r-cell>
            )}
            <r-cell>
                <div className="row--xs">
                    <Label>Assigned Group</Label>
                    {workItem.assignedToGroup ? (
                        <Persona size={PersonaSize.size28} text={workItem.assignedToGroup.name} />
                    ) : (
                        "Not Set"
                    )}
                </div>
            </r-cell>
            <r-cell>
                <div className="row--xs">
                    <AdaptiveField type="Principal" mode="view" label="Assigned User" value={workItem.assignedToUser} />
                </div>
            </r-cell>
            <r-cell>
                <div className="row--xs">
                    <AdaptiveField
                        type="Principal"
                        mode="view"
                        label="Created"
                        value={assessment.createdBy}
                        secondaryText={moment.utc(assessment.created).format("Do MMM YYYY h:mm a")}
                    />
                </div>
            </r-cell>
            <r-cell>
                <div className="row--xs">
                    <AdaptiveField
                        type="Principal"
                        mode="view"
                        label="Last Modified"
                        value={assessment.modifiedBy}
                        secondaryText={moment.utc(assessment.modified).format("Do MMM YYYY h:mm a")}
                    />
                </div>
            </r-cell>
        </r-grid>
    );
});
