import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { cx } from "emotion";

import { useStores } from "@hooks/useStores";

import { Label } from "office-ui-fabric-react/lib/Label";

import { AppPage } from "@modules/layout/containers/AppPage";
import { FieldSetView } from "@modules/base/components/FieldSetView";
import { BoxPanel } from "@modules/base/components/BoxPanel";
import RichTextViewer from "@modules/editors/components/RichTextViewer";
import { PhotoPersona } from "@modules/identity/containers/utilities/PhotoPersona";
import { StateFlowPicker } from "@modules/stateflows/containers/pickers/StateFlowPicker";
import { MarkdownViewer } from "@modules/editors/components/MarkdownViewer";

import { SettingsListNav } from "@modules/settings/containers/utilities/SettingsListNav";
import { RiskTypeViewerPageHeader } from "@modules/risks/components/core/RiskTypeViewerPageHeader";
import { RiskTypeEditPanel } from "@modules/risks/containers/panels/RiskTypeEditPanel";
import { templates } from "../../stores/RiskContants";
import { RegistryPicker } from "../pickers/RegistryPicker";

export const RiskTypeViewerPage = withRouter(
    observer((props) => {
        const { match } = props;
        const { riskStore } = useStores();
        const { typeViewerPageStore, riskTypeEditFormStore } = riskStore;
        const { breadcrumb, loading, error, type, canEdit } = typeViewerPageStore;

        useEffect(() => {
            typeViewerPageStore.resetType();
            typeViewerPageStore.loadType(match.params.id);
        }, []);
        // console.log(type)
        return (
            <AppPage
                loading={loading}
                error={error}
                breadcrumb={breadcrumb}
                hasSidebar={false}
                className={cx("portfolios viewer", !error && "has--page-nav")}
            >
                <SettingsListNav />
                <RiskTypeViewerPageHeader
                    riskType={type}
                    onEdit={() => {
                        riskTypeEditFormStore.show(type);
                    }}
                    canEdit={typeViewerPageStore.canEdit}
                />

                <BoxPanel padding shadow>
                    {type && (
                        <>
                            <FieldSetView title="Key Information" description="Basic information about this risk type.">
                                <r-grid columns="2">
                                    <r-cell span="2">
                                        <div className="row--xs">
                                            <Label>Summary</Label>
                                            {type.summary || "N/A"}
                                        </div>
                                    </r-cell>
                                    <r-cell>
                                        <div className="row--xs">
                                            <Label>CodeFormat</Label>
                                            {type.codeFormat === null
                                                ? "Inherited from risk registry"
                                                : type.codeFormat}
                                        </div>
                                    </r-cell>
                                    <r-cell>
                                        <div className="row--xs">
                                            <Label>Template</Label>
                                            {type.template && templates.find((T) => T.key === type.template)
                                                ? templates.find((T) => T.key === type.template).text
                                                : null}
                                        </div>
                                    </r-cell>
                                    <r-cell>
                                        <div className="row--xs">
                                            <Label>Created By</Label>
                                            <PhotoPersona
                                                principalId={type.createdBy.id}
                                                text={type.createdBy ? type.createdBy.name : "-"}
                                                showSecondaryText={true}
                                                secondaryText={type.createdBy.jobTitle}
                                            />
                                        </div>
                                    </r-cell>
                                    <r-cell>
                                        <div className="row--xs">
                                            <Label>Modified By</Label>
                                            <PhotoPersona
                                                principalId={type.modifiedBy.id}
                                                text={type.modifiedBy ? type.modifiedBy.name : "-"}
                                                showSecondaryText={true}
                                                secondaryText={type.modifiedBy.jobTitle}
                                            />
                                        </div>
                                    </r-cell>
                                    <r-cell>
                                        <div className="row--xs">
                                            <Label>State</Label>
                                            {type.state}
                                        </div>
                                    </r-cell>
                                </r-grid>
                            </FieldSetView>
                            <FieldSetView
                                title="Extended Settings"
                                description="Information about default settings for this risk type."
                            >
                                <r-grid columns="2">
                                    <r-cell>
                                        <div className="row--xs">
                                            <Label>Owner</Label>
                                            {type.owner ? (
                                                <PhotoPersona
                                                    principalId={type.owner ? type.owner.id : null}
                                                    text={type.owner ? type.owner.name : "-"}
                                                    showSecondaryText={true}
                                                    secondaryText={type.owner ? type.owner.jobTitle : ""}
                                                />
                                            ) : (
                                                "Not defined"
                                            )}
                                        </div>
                                    </r-cell>
                                    <r-cell>
                                        <div className="row--xs">
                                            <RegistryPicker
                                                readOnly
                                                label="Default Registry"
                                                selectedId={type.defaultRegistryId}
                                            />
                                        </div>
                                    </r-cell>
                                    <r-cell>
                                        <div className="row--xs">
                                            <StateFlowPicker
                                                label="Workflow"
                                                target="RiskLifecycle"
                                                readOnly
                                                selectedKey={type.flowId}
                                            />
                                        </div>
                                    </r-cell>
                                    <r-cell>
                                        <div className="row--xs">
                                            <Label>Locked</Label>
                                            {type.locked ? "True" : "False"}
                                        </div>
                                    </r-cell>
                                </r-grid>
                            </FieldSetView>
                            <FieldSetView
                                title="Display Settings"
                                description="Define where and how this risk type is going to be displayed across the platform."
                            >
                                <r-grid columns="2">
                                    <r-cell>
                                        <div className="row--xs">
                                            <Label>Promoted</Label>
                                            {type.promoted ? "Yes" : "No"}
                                        </div>
                                    </r-cell>
                                    <r-cell>
                                        <div className="row--xs">
                                            <Label>Order</Label>
                                            {type.order}
                                        </div>
                                    </r-cell>
                                    <r-cell span="2">
                                        <div className="row--xs">
                                            <Label>Score Tooltip</Label>
                                            <MarkdownViewer value={type.scoreTooltip || ""} />
                                        </div>
                                    </r-cell>
                                </r-grid>
                            </FieldSetView>
                            <FieldSetView
                                title="Request Settings"
                                description="Define default request setting when this risk type is raised."
                            >
                                <r-grid columns="2">
                                    <r-cell>
                                        <div className="row--xs">
                                            <Label>Context Template</Label>
                                            <RichTextViewer
                                                className="description"
                                                value={type.contextTemplate ? type.contextTemplate : null}
                                            />
                                        </div>
                                    </r-cell>
                                    <r-cell>
                                        <div className="row--xs">
                                            <Label>Description Template</Label>
                                            <RichTextViewer
                                                className="description"
                                                value={type.descriptionTemplate ? type.descriptionTemplate : null}
                                            />
                                        </div>
                                    </r-cell>
                                    <r-cell>
                                        <div className="row--xs">
                                            <Label>Requestable</Label>
                                            {type.requestable ? "Yes" : "No"}
                                        </div>
                                    </r-cell>
                                    <r-cell>
                                        <div className="row--xs">
                                            <Label>Internal</Label>
                                            {type.internal ? "Yes" : "No"}
                                        </div>
                                    </r-cell>
                                    <r-cell>
                                        <div className="row--xs">
                                            <Label>Link To Assessment</Label>
                                            {type.linkToAssessment ? "Yes" : "No"}
                                        </div>
                                    </r-cell>
                                </r-grid>
                            </FieldSetView>
                            <FieldSetView
                                title="Assignment Settings"
                                description="How are risk of this type assigned by default."
                            >
                                <r-grid columns="2">
                                    <r-cell>
                                        <div className="row--xs">
                                            <Label>Assigned To Group</Label>
                                            {type.assignedToGroup ? type.assignedToGroup.name : "N/A"}
                                        </div>
                                    </r-cell>
                                    <r-cell>
                                        <div className="row--xs">
                                            <Label>Assigned To User</Label>
                                            {type.assignedToUser ? (
                                                <PhotoPersona
                                                    principalId={type.assignedToUser.id}
                                                    text={type.assignedToUser ? type.assignedToUser.name : "-"}
                                                    showSecondaryText={true}
                                                    secondaryText={type.assignedToUser.jobTitle}
                                                />
                                            ) : (
                                                "N/A"
                                            )}
                                        </div>
                                    </r-cell>
                                    <r-cell>
                                        <div className="row--xs">
                                            <Label>Auto Assignment Mode</Label>
                                            {type.autoAssignmentMode || "N/A"}
                                        </div>
                                    </r-cell>
                                </r-grid>
                            </FieldSetView>
                        </>
                    )}
                </BoxPanel>
                <RiskTypeEditPanel
                    onSaveRiskType={() => {
                        typeViewerPageStore.loadType(match.params.id);
                    }}
                    isEdit={true}
                />
            </AppPage>
        );
    })
);
