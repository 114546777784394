import React from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { MessageBar } from "@ui/elements/MessageBar";

import { BoxPanel } from "@modules/base/components/BoxPanel";

import { RiskTypeListItem } from "@modules/risks/components/core/RiskTypeListItem";

const rootClassName = cx(
    "cygraph-RiskTypeListView-root",
    css`
        & .type {
            max-width: 100%;
            height: 100%;
            min-height: 200px;
            padding: var(--spacing-s) var(--spacing-s);
            margin-bottom: var(--spacing-s);
            background-color: var(--app-header-background);
            text-align: center;
            position: relative;
            cursor: pointer;

            &:hover {
                box-shadow: rgb(0 0 0 / 11%) 0px 3.2px 15px 0px, rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px;
            }

            & .description {
                color: var(--text-color-lighter);
            }
        }
    `
);

export const RiskTypeListView = observer(({ className, loading, types, onClick, pickerMode }) => {
    if (!loading && !types.length) {
        return (
            <BoxPanel shadow>
                <MessageBar>There are no matching risk types.</MessageBar>
            </BoxPanel>
        );
    }

    return (
        <div className={cx(rootClassName, className)} data-test="type-list">
            <r-grid columns-l={pickerMode ? 2 : 4} columns={pickerMode ? 2 : 2} columns-s="1">
                {types.map((type) => (
                    <r-cell span="1" key={type.id}>
                        <RiskTypeListItem className="type card" type={type} onClick={onClick} />
                    </r-cell>
                ))}
            </r-grid>
        </div>
    );
});
