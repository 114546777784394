import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';
import Moment from 'react-moment';

import { StatusProgress, StatusProgressStep } from '../../../base/components/StatusProgress';

export const RiskStatusProgress = observer(
    class RiskStatusProgress extends PureComponent {

        render () {
            const { risk, lifecycle, className } = this.props;
            return (
                <StatusProgress className={className}>
                    <StatusProgressStep title="Draft" active={this._isStatusOneOf(['Draft'])} done={!this._isStatusOneOf(['Draft'])}>
                        <span>{ risk.trigger == 'Assessment' ? 'Created from assessment on ' : (risk.trigger == 'Manual' ? 'Manually created on ' : 'Automatically created on ') }</span>                                   
                        <Moment date={lifecycle.created} format="Do MMM YYYY" />
                    </StatusProgressStep>
                    <StatusProgressStep title="Submitted" active={this._isStatusOneOf(['Submitted'])} done={!this._isStatusOneOf(['Draft', 'Submitted'])}>
                        { lifecycle.submitted ? (
                            <span>Submitted on <Moment date={lifecycle.submitted} format="Do MMM YYYY" /></span>
                        ) : (
                            <span>Risk has not been submitted yet</span>
                        ) }
                    </StatusProgressStep>
                    <StatusProgressStep title="Monitoring" active={this._isStatusOneOf(['Monitoring'])} done={this._isStatusOneOf(['Closed', 'Cancelled', 'Transferred'])}>
                        { lifecycle.reviewed ? (
                            <span>Reviewed on <Moment date={lifecycle.reviewed} format="Do MMM YYYY" /></span>
                        ) : (
                            <span>{ lifecycle.closed ? 'Risk was closed before monitoring started' : 'Risk has not been reviewed yet' }</span>
                        ) }
                    </StatusProgressStep>
                    <StatusProgressStep title={ lifecycle.closed ? lifecycle.status : 'Closed' } active={this._isStatusOneOf(['Closed', 'Cancelled', 'Transferred'])}>
                        { lifecycle.closed ? (
                            <span>{lifecycle.status} on <Moment date={lifecycle.closed} format="Do MMM YYYY" /></span>
                        ) : (
                            <span>Risk has not been closed yet</span>
                        ) }
                    </StatusProgressStep>
                </StatusProgress>
            );
        }

        _isStatusOneOf(status) {
            const { lifecycle } = this.props;
            return status.indexOf(lifecycle.status) != -1;
        }
    }
);