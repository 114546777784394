import React, { Component } from "react";
import { cx, css } from "emotion";

import { Callout } from "office-ui-fabric-react/lib/Callout";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { FontIcon } from "office-ui-fabric-react/lib/Icon";

const calloutClassName = cx(
    "ms-Callout-main",
    css`
        width: 555px;
        padding: 10px;
        background-color: white;
    `
);

// const upperPartClassName = cx(
//     css`
//         max-height: 150px;
//         overflow-y: auto;
//         overflow-x: hidden;
//     `
// );

const icons = [
    { iconName: "PageLink" },
    { iconName: "CommentSolid" },
    { iconName: "ChangeEntitlements" },
    { iconName: "Installation" },
    { iconName: "WebAppBuilderModule" },
    { iconName: "WebAppBuilderFragment" },
    { iconName: "WebAppBuilderSlot" },
    { iconName: "BullseyeTargetEdit" },
    { iconName: "WebAppBuilderFragmentCreate" },
    { iconName: "PageData" },
    { iconName: "PageHeaderEdit" },
    { iconName: "ProductList" },
    { iconName: "UnpublishContent" },
    { iconName: "DependencyAdd" },
    { iconName: "DependencyRemove" },
    { iconName: "EntitlementPolicy" },
    { iconName: "EntitlementRedemption" },
    { iconName: "SchoolDataSyncLogo" },
    { iconName: "PinSolid12" },
    { iconName: "PinSolidOff12" },
    { iconName: "AddLink" },
    { iconName: "SharepointAppIcon16" },
    { iconName: "DataflowsLink" },
    { iconName: "TimePicker" },
    { iconName: "UserWarning" },
    { iconName: "ComplianceAudit" },
    { iconName: "GlobalNavButton" },
    { iconName: "InternetSharing" },
    { iconName: "Brightness" },
    { iconName: "MapPin" },
    { iconName: "Airplane" },
    { iconName: "Tablet" },
    { iconName: "QuickNote" },
    { iconName: "ChevronDown" },
    { iconName: "ChevronUp" },
    { iconName: "Edit" },
    { iconName: "Add" },
    { iconName: "Cancel" },
    { iconName: "More" },
    { iconName: "Settings" },
    { iconName: "Video" },
    { iconName: "Mail" },
    { iconName: "People" },
    { iconName: "Phone" },
    { iconName: "Pin" },
    { iconName: "Shop" },
    { iconName: "Stop" },
    { iconName: "Link" },
    { iconName: "Filter" },
    { iconName: "AllApps" },
    { iconName: "Zoom" },
    { iconName: "ZoomOut" },
    { iconName: "Microphone" },
    { iconName: "Search" },
    { iconName: "Camera" },
    { iconName: "Attach" },
    { iconName: "Send" },
    { iconName: "FavoriteList" },
    { iconName: "PageSolid" },
    { iconName: "Forward" },
    { iconName: "Back" },
    { iconName: "Refresh" },
    { iconName: "Share" },
    { iconName: "Lock" },
    { iconName: "BlockedSite" },
    { iconName: "ReportHacked" },
    { iconName: "EMI" },
    { iconName: "MiniLink" },
    { iconName: "Blocked" },
    { iconName: "FavoriteStar" },
    { iconName: "FavoriteStarFill" },
    { iconName: "ReadingMode" },
    { iconName: "Favicon" },
    { iconName: "Remove" },
    { iconName: "Checkbox" },
    { iconName: "CheckboxComposite" },
    { iconName: "CheckboxFill" },
    { iconName: "CheckboxIndeterminate" },
    { iconName: "CheckboxCompositeReversed" },
    { iconName: "CheckMark" },
    { iconName: "BackToWindow" },
    { iconName: "FullScreen" },
    { iconName: "Print" },
    { iconName: "Up" },
    { iconName: "Down" },
    { iconName: "OEM" },
    { iconName: "Delete" },
    { iconName: "Save" },
    { iconName: "ReturnKey" },
    { iconName: "Cloud" },
    { iconName: "Flashlight" },
    { iconName: "CommandPrompt" },
    { iconName: "Sad" },
    { iconName: "RealEstate" },
    { iconName: "SIPMove" },
    { iconName: "EraseTool" },
    { iconName: "GripperTool" },
    { iconName: "Dialpad" },
    { iconName: "PageLeft" },
    { iconName: "PageRight" },
    { iconName: "MultiSelect" },
    { iconName: "KeyboardClassic" },
    { iconName: "Play" },
    { iconName: "Pause" },
    { iconName: "ChevronLeft" },
    { iconName: "ChevronRight" },
    { iconName: "InkingTool" },
    { iconName: "Emoji2" },
    { iconName: "GripperBarHorizontal" },
    { iconName: "System" },
    { iconName: "Personalize" },
    { iconName: "SearchAndApps" },
    { iconName: "Globe" },
    { iconName: "EaseOfAccess" },
    { iconName: "ContactInfo" },
    { iconName: "Unpin" },
    { iconName: "Contact" },
    { iconName: "Memo" },
    { iconName: "IncomingCall" },
    { iconName: "Paste" },
    { iconName: "WindowsLogo" },
    { iconName: "Error" },
    { iconName: "GripperBarVertical" },
    { iconName: "Unlock" },
    { iconName: "Slideshow" },
    { iconName: "Calendar" },
    { iconName: "Megaphone" },
    { iconName: "Trim" },
    { iconName: "AutoEnhanceOn" },
    { iconName: "AutoEnhanceOff" },
    { iconName: "Color" },
    { iconName: "SaveAs" },
    { iconName: "Light" },
    { iconName: "Filters" },
    { iconName: "AspectRatio" },
    { iconName: "Contrast" },
    { iconName: "Redo" },
    { iconName: "Undo" },
    { iconName: "Crop" },
    { iconName: "PhotoCollection" },
    { iconName: "Album" },
    { iconName: "Rotate" },
    { iconName: "PanoIndicator" },
    { iconName: "Translate" },
    { iconName: "RedEye" },
    { iconName: "ViewOriginal" },
    { iconName: "ThumbnailView" },
    { iconName: "Package" },
    { iconName: "Telemarketer" },
    { iconName: "Warning" },
    { iconName: "Financial" },
    { iconName: "Education" },
    { iconName: "ShoppingCart" },
    { iconName: "Train" },
    { iconName: "Flag" },
    { iconName: "Move" },
    { iconName: "Page" },
    { iconName: "TouchPointer" },
    { iconName: "Merge" },
    { iconName: "TurnRight" },
    { iconName: "Ferry" },
    { iconName: "Highlight" },
    { iconName: "PowerButton" },
    { iconName: "Tab" },
    { iconName: "Admin" },
    { iconName: "TVMonitor" },
    { iconName: "Speakers" },
    { iconName: "Game" },
    { iconName: "HorizontalTabKey" },
    { iconName: "UnstackSelected" },
    { iconName: "StackIndicator" },
    { iconName: "Nav2DMapView" },
    { iconName: "StreetsideSplitMinimize" },
    { iconName: "Car" },
    { iconName: "Bus" },
    { iconName: "EatDrink" },
    { iconName: "SeeDo" },
    { iconName: "LocationCircle" },
    { iconName: "Home" },
    { iconName: "SwitcherStartEnd" },
    { iconName: "ParkingLocation" },
    { iconName: "IncidentTriangle" },
    { iconName: "Touch" },
    { iconName: "MapDirections" },
    { iconName: "CaretHollow" },
    { iconName: "CaretSolid" },
    { iconName: "History" },
    { iconName: "Location" },
    { iconName: "MapLayers" },
    { iconName: "SearchNearby" },
    { iconName: "Work" },
    { iconName: "Recent" },
    { iconName: "Hotel" },
    { iconName: "Bank" },
    { iconName: "LocationDot" },
    { iconName: "Dictionary" },
    { iconName: "ChromeBack" },
    { iconName: "FolderOpen" },
    { iconName: "Pinned" },
    { iconName: "PinnedFill" },
    { iconName: "RevToggleKey" },
    { iconName: "USB" },
    { iconName: "View" },
    { iconName: "Previous" },
    { iconName: "Next" },
    { iconName: "Clear" },
    { iconName: "Sync" },
    { iconName: "Download" },
    { iconName: "Help" },
    { iconName: "Upload" },
    { iconName: "Emoji" },
    { iconName: "MailForward" },
    { iconName: "ClosePane" },
    { iconName: "OpenPane" },
    { iconName: "PreviewLink" },
    { iconName: "ZoomIn" },
    { iconName: "Bookmarks" },
    { iconName: "Document" },
    { iconName: "ProtectedDocument" },
    { iconName: "OpenInNewWindow" },
    { iconName: "MailFill" },
    { iconName: "ViewAll" },
    { iconName: "Switch" },
    { iconName: "Rename" },
    { iconName: "Go" },
    { iconName: "Remote" },
    { iconName: "SelectAll" },
    { iconName: "Orientation" },
    { iconName: "Import" },
    { iconName: "Folder" },
    { iconName: "Picture" },
    { iconName: "ChromeClose" },
    { iconName: "ShowResults" },
    { iconName: "Message" },
    { iconName: "CalendarDay" },
    { iconName: "CalendarWeek" },
    { iconName: "MailReplyAll" },
    { iconName: "Read" },
    { iconName: "Cut" },
    { iconName: "PaymentCard" },
    { iconName: "Copy" },
    { iconName: "Important" },
    { iconName: "MailReply" },
    { iconName: "Sort" },
    { iconName: "GotoToday" },
    { iconName: "Font" },
    { iconName: "FontColor" },
    { iconName: "FolderFill" },
    { iconName: "Permissions" },
    { iconName: "DisableUpdates" },
    { iconName: "Unfavorite" },
    { iconName: "Italic" },
    { iconName: "Underline" },
    { iconName: "Bold" },
    { iconName: "MoveToFolder" },
    { iconName: "Dislike" },
    { iconName: "Like" },
    { iconName: "AlignRight" },
    { iconName: "AlignCenter" },
    { iconName: "AlignLeft" },
    { iconName: "OpenFile" },
    { iconName: "ClearSelection" },
    { iconName: "FontDecrease" },
    { iconName: "FontIncrease" },
    { iconName: "FontSize" },
    { iconName: "CellPhone" },
    { iconName: "Tag" },
    { iconName: "RepeatOne" },
    { iconName: "RepeatAll" },
    { iconName: "Calculator" },
    { iconName: "Library" },
    { iconName: "PostUpdate" },
    { iconName: "NewFolder" },
    { iconName: "CalendarReply" },
    { iconName: "UnsyncFolder" },
    { iconName: "SyncFolder" },
    { iconName: "BlockContact" },
    { iconName: "AddFriend" },
    { iconName: "Accept" },
    { iconName: "BulletedList" },
    { iconName: "Preview" },
    { iconName: "News" },
    { iconName: "Chat" },
    { iconName: "Group" },
    { iconName: "World" },
    { iconName: "Comment" },
    { iconName: "DockLeft" },
    { iconName: "DockRight" },
    { iconName: "Repair" },
    { iconName: "Accounts" },
    { iconName: "Street" },
    { iconName: "RadioBullet" },
    { iconName: "Stopwatch" },
    { iconName: "Clock" },
    { iconName: "WorldClock" },
    { iconName: "AlarmClock" },
    { iconName: "Photo" },
    { iconName: "ActionCenter" },
    { iconName: "Hospital" },
    { iconName: "Timer" },
    { iconName: "FullCircleMask" },
    { iconName: "LocationFill" },
    { iconName: "ChromeMinimize" },
    { iconName: "ChromeRestore" },
    { iconName: "Annotation" },
    { iconName: "Fingerprint" },
    { iconName: "Handwriting" },
    { iconName: "ChromeFullScreen" },
    { iconName: "Completed" },
    { iconName: "Label" },
    { iconName: "FlickDown" },
    { iconName: "FlickUp" },
    { iconName: "FlickLeft" },
    { iconName: "FlickRight" },
    { iconName: "MiniExpand" },
    { iconName: "MiniContract" },
    { iconName: "Streaming" },
    { iconName: "MusicInCollection" },
    { iconName: "OneDriveLogo" },
    { iconName: "CompassNW" },
    { iconName: "Code" },
    { iconName: "LightningBolt" },
    { iconName: "Info" },
    { iconName: "CalculatorMultiply" },
    { iconName: "CalculatorAddition" },
    { iconName: "CalculatorSubtract" },
    { iconName: "CalculatorPercentage" },
    { iconName: "CalculatorEqualTo" },
    { iconName: "PrintfaxPrinterFile" },
    { iconName: "StorageOptical" },
    { iconName: "Communications" },
    { iconName: "Headset" },
    { iconName: "Health" },
    { iconName: "Webcam2" },
    { iconName: "FrontCamera" },
    { iconName: "ChevronUpSmall" },
    { iconName: "ChevronDownSmall" },
    { iconName: "ChevronLeftSmall" },
    { iconName: "ChevronRightSmall" },
    { iconName: "ChevronUpMed" },
    { iconName: "ChevronDownMed" },
    { iconName: "ChevronLeftMed" },
    { iconName: "ChevronRightMed" },
    { iconName: "Devices2" },
    { iconName: "PC1" },
    { iconName: "PresenceChickletVideo" },
    { iconName: "Reply" },
    { iconName: "HalfAlpha" },
    { iconName: "ConstructionCone" },
    { iconName: "DoubleChevronLeftMed" },
    { iconName: "Volume0" },
    { iconName: "Volume1" },
    { iconName: "Volume2" },
    { iconName: "Volume3" },
    { iconName: "Chart" },
    { iconName: "Robot" },
    { iconName: "Manufacturing" },
    { iconName: "LockSolid" },
    { iconName: "FitPage" },
    { iconName: "FitWidth" },
    { iconName: "BidiLtr" },
    { iconName: "BidiRtl" },
    { iconName: "RightDoubleQuote" },
    { iconName: "Sunny" },
    { iconName: "CloudWeather" },
    { iconName: "Cloudy" },
    { iconName: "PartlyCloudyDay" },
    { iconName: "PartlyCloudyNight" },
    { iconName: "ClearNight" },
    { iconName: "RainShowersDay" },
    { iconName: "Rain" },
    { iconName: "Thunderstorms" },
    { iconName: "RainSnow" },
    { iconName: "Snow" },
    { iconName: "BlowingSnow" },
    { iconName: "Frigid" },
    { iconName: "Fog" },
    { iconName: "Squalls" },
    { iconName: "Duststorm" },
    { iconName: "Unknown" },
    { iconName: "Precipitation" },
    { iconName: "SortLines" },
    { iconName: "Ribbon" },
    { iconName: "AreaChart" },
    { iconName: "Assign" },
    { iconName: "FlowChart" },
    { iconName: "CheckList" },
    { iconName: "Diagnostic" },
    { iconName: "Generate" },
    { iconName: "LineChart" },
    { iconName: "Equalizer" },
    { iconName: "BarChartHorizontal" },
    { iconName: "BarChartVertical" },
    { iconName: "Freezing" },
    { iconName: "FunnelChart" },
    { iconName: "Processing" },
    { iconName: "Quantity" },
    { iconName: "ReportDocument" },
    { iconName: "StackColumnChart" },
    { iconName: "SnowShowerDay" },
    { iconName: "HailDay" },
    { iconName: "WorkFlow" },
    { iconName: "HourGlass" },
    { iconName: "StoreLogoMed20" },
    { iconName: "TimeSheet" },
    { iconName: "TriangleSolid" },
    { iconName: "UpgradeAnalysis" },
    { iconName: "VideoSolid" },
    { iconName: "RainShowersNight" },
    { iconName: "SnowShowerNight" },
    { iconName: "Teamwork" },
    { iconName: "HailNight" },
    { iconName: "PeopleAdd" },
    { iconName: "Glasses" },
    { iconName: "DateTime2" },
    { iconName: "Shield" },
    { iconName: "Header1" },
    { iconName: "PageAdd" },
    { iconName: "NumberedList" },
    { iconName: "PowerBILogo" },
    { iconName: "Info2" },
    { iconName: "MusicInCollectionFill" },
    { iconName: "List" },
    { iconName: "Asterisk" },
    { iconName: "ErrorBadge" },
    { iconName: "CircleRing" },
    { iconName: "CircleFill" },
    { iconName: "Record2" },
    { iconName: "AllAppsMirrored" },
    { iconName: "BookmarksMirrored" },
    { iconName: "BulletedListMirrored" },
    { iconName: "CaretHollowMirrored" },
    { iconName: "CaretSolidMirrored" },
    { iconName: "ChromeBackMirrored" },
    { iconName: "ClearSelectionMirrored" },
    { iconName: "ClosePaneMirrored" },
    { iconName: "DockLeftMirrored" },
    { iconName: "DoubleChevronLeftMedMirrored" },
    { iconName: "GoMirrored" },
    { iconName: "HelpMirrored" },
    { iconName: "ImportMirrored" },
    { iconName: "ImportAllMirrored" },
    { iconName: "ListMirrored" },
    { iconName: "MailForwardMirrored" },
    { iconName: "MailReplyMirrored" },
    { iconName: "MailReplyAllMirrored" },
    { iconName: "MiniContractMirrored" },
    { iconName: "MiniExpandMirrored" },
    { iconName: "OpenPaneMirrored" },
    { iconName: "ParkingLocationMirrored" },
    { iconName: "SendMirrored" },
    { iconName: "ShowResultsMirrored" },
    { iconName: "ThumbnailViewMirrored" },
    { iconName: "Media" },
    { iconName: "Devices3" },
    { iconName: "Focus" },
    { iconName: "VideoLightOff" },
    { iconName: "Lightbulb" },
    { iconName: "StatusTriangle" },
    { iconName: "VolumeDisabled" },
    { iconName: "Puzzle" },
    { iconName: "EmojiNeutral" },
    { iconName: "EmojiDisappointed" },
    { iconName: "HomeSolid" },
    { iconName: "Ringer" },
    { iconName: "PDF" },
    { iconName: "HeartBroken" },
    { iconName: "StoreLogo16" },
    { iconName: "MultiSelectMirrored" },
    { iconName: "Broom" },
    { iconName: "AddToShoppingList" },
    { iconName: "Cocktails" },
    { iconName: "Wines" },
    { iconName: "Articles" },
    { iconName: "Cycling" },
    { iconName: "DietPlanNotebook" },
    { iconName: "Pill" },
    { iconName: "ExerciseTracker" },
    { iconName: "HandsFree" },
    { iconName: "Medical" },
    { iconName: "Running" },
    { iconName: "Weights" },
    { iconName: "Trackers" },
    { iconName: "AddNotes" },
    { iconName: "AllCurrency" },
    { iconName: "BarChart4" },
    { iconName: "CirclePlus" },
    { iconName: "Coffee" },
    { iconName: "Cotton" },
    { iconName: "Market" },
    { iconName: "Money" },
    { iconName: "PieDouble" },
    { iconName: "PieSingle" },
    { iconName: "RemoveFilter" },
    { iconName: "Savings" },
    { iconName: "Sell" },
    { iconName: "StockDown" },
    { iconName: "StockUp" },
    { iconName: "Lamp" },
    { iconName: "Source" },
    { iconName: "MSNVideos" },
    { iconName: "Cricket" },
    { iconName: "Golf" },
    { iconName: "Baseball" },
    { iconName: "Soccer" },
    { iconName: "MoreSports" },
    { iconName: "AutoRacing" },
    { iconName: "CollegeHoops" },
    { iconName: "CollegeFootball" },
    { iconName: "ProFootball" },
    { iconName: "ProHockey" },
    { iconName: "Rugby" },
    { iconName: "SubstitutionsIn" },
    { iconName: "Tennis" },
    { iconName: "Arrivals" },
    { iconName: "Design" },
    { iconName: "Website" },
    { iconName: "Drop" },
    { iconName: "HistoricalWeather" },
    { iconName: "SkiResorts" },
    { iconName: "Snowflake" },
    { iconName: "BusSolid" },
    { iconName: "FerrySolid" },
    { iconName: "AirplaneSolid" },
    { iconName: "TrainSolid" },
    { iconName: "Heart" },
    { iconName: "HeartFill" },
    { iconName: "Ticket" },
    { iconName: "WifiWarning4" },
    { iconName: "Devices4" },
    { iconName: "AzureLogo" },
    { iconName: "BingLogo" },
    { iconName: "MSNLogo" },
    { iconName: "OutlookLogoInverse" },
    { iconName: "OfficeLogo" },
    { iconName: "SkypeLogo" },
    { iconName: "Door" },
    { iconName: "EditMirrored" },
    { iconName: "GiftCard" },
    { iconName: "DoubleBookmark" },
    { iconName: "StatusErrorFull" },
    { iconName: "Certificate" },
    { iconName: "FastForward" },
    { iconName: "Rewind" },
    { iconName: "Photo2" },
    { iconName: "OpenSource" },
    { iconName: "Movers" },
    { iconName: "CloudDownload" },
    { iconName: "Family" },
    { iconName: "WindDirection" },
    { iconName: "Bug" },
    { iconName: "SiteScan" },
    { iconName: "BrowserScreenShot" },
    { iconName: "F12DevTools" },
    { iconName: "CSS" },
    { iconName: "JS" },
    { iconName: "DeliveryTruck" },
    { iconName: "ReminderPerson" },
    { iconName: "ReminderGroup" },
    { iconName: "ReminderTime" },
    { iconName: "TabletMode" },
    { iconName: "Umbrella" },
    { iconName: "NetworkTower" },
    { iconName: "CityNext" },
    { iconName: "CityNext2" },
    { iconName: "Section" },
    { iconName: "OneNoteLogoInverse" },
    { iconName: "ToggleFilled" },
    { iconName: "ToggleBorder" },
    { iconName: "SliderThumb" },
    { iconName: "ToggleThumb" },
    { iconName: "Documentation" },
    { iconName: "Badge" },
    { iconName: "Giftbox" },
    { iconName: "VisualStudioLogo" },
    { iconName: "HomeGroup" },
    { iconName: "ExcelLogoInverse" },
    { iconName: "WordLogoInverse" },
    { iconName: "PowerPointLogoInverse" },
    { iconName: "Cafe" },
    { iconName: "SpeedHigh" },
    { iconName: "Commitments" },
    { iconName: "ThisPC" },
    { iconName: "MusicNote" },
    { iconName: "MicOff" },
    { iconName: "PlaybackRate1x" },
    { iconName: "EdgeLogo" },
    { iconName: "CompletedSolid" },
    { iconName: "AlbumRemove" },
    { iconName: "MessageFill" },
    { iconName: "TabletSelected" },
    { iconName: "MobileSelected" },
    { iconName: "LaptopSelected" },
    { iconName: "TVMonitorSelected" },
    { iconName: "DeveloperTools" },
    { iconName: "Shapes" },
    { iconName: "InsertTextBox" },
    { iconName: "LowerBrightness" },
    { iconName: "WebComponents" },
    { iconName: "OfflineStorage" },
    { iconName: "DOM" },
    { iconName: "CloudUpload" },
    { iconName: "ScrollUpDown" },
    { iconName: "DateTime" },
    { iconName: "Event" },
    { iconName: "Cake" },
    { iconName: "Tiles" },
    { iconName: "Org" },
    { iconName: "PartyLeader" },
    { iconName: "DRM" },
    { iconName: "CloudAdd" },
    { iconName: "AppIconDefault" },
    { iconName: "Photo2Add" },
    { iconName: "Photo2Remove" },
    { iconName: "Calories" },
    { iconName: "POI" },
    { iconName: "AddTo" },
    { iconName: "RadioBtnOff" },
    { iconName: "RadioBtnOn" },
    { iconName: "ExploreContent" },
    { iconName: "Embed" },
    { iconName: "Product" },
    { iconName: "ProgressLoopInner" },
    { iconName: "ProgressLoopOuter" },
    { iconName: "Blocked2" },
    { iconName: "FangBody" },
    { iconName: "Toolbox" },
    { iconName: "PageHeader" },
    { iconName: "Glimmer" },
    { iconName: "ChatInviteFriend" },
    { iconName: "Brush" },
    { iconName: "Shirt" },
    { iconName: "Crown" },
    { iconName: "Diamond" },
    { iconName: "ScaleUp" },
    { iconName: "QRCode" },
    { iconName: "Feedback" },
    { iconName: "SharepointLogoInverse" },
    { iconName: "YammerLogo" },
    { iconName: "Hide" },
    { iconName: "Uneditable" },
    { iconName: "ReturnToSession" },
    { iconName: "OpenFolderHorizontal" },
    { iconName: "CalendarMirrored" },
    { iconName: "SwayLogoInverse" },
    { iconName: "OutOfOffice" },
    { iconName: "Trophy" },
    { iconName: "ReopenPages" },
    { iconName: "EmojiTabSymbols" },
    { iconName: "AADLogo" },
    { iconName: "AccessLogo" },
    { iconName: "AdminALogoInverse32" },
    { iconName: "AdminCLogoInverse32" },
    { iconName: "AdminDLogoInverse32" },
    { iconName: "AdminELogoInverse32" },
    { iconName: "AdminLLogoInverse32" },
    { iconName: "AdminMLogoInverse32" },
    { iconName: "AdminOLogoInverse32" },
    { iconName: "AdminPLogoInverse32" },
    { iconName: "AdminSLogoInverse32" },
    { iconName: "AdminYLogoInverse32" },
    { iconName: "DelveLogoInverse" },
    { iconName: "ExchangeLogoInverse" },
    { iconName: "LyncLogo" },
    { iconName: "OfficeVideoLogoInverse" },
    { iconName: "SocialListeningLogo" },
    { iconName: "VisioLogoInverse" },
    { iconName: "Balloons" },
    { iconName: "Cat" },
    { iconName: "MailAlert" },
    { iconName: "MailCheck" },
    { iconName: "MailLowImportance" },
    { iconName: "MailPause" },
    { iconName: "MailRepeat" },
    { iconName: "SecurityGroup" },
    { iconName: "Table" },
    { iconName: "VoicemailForward" },
    { iconName: "VoicemailReply" },
    { iconName: "Waffle" },
    { iconName: "RemoveEvent" },
    { iconName: "EventInfo" },
    { iconName: "ForwardEvent" },
    { iconName: "WipePhone" },
    { iconName: "AddOnlineMeeting" },
    { iconName: "JoinOnlineMeeting" },
    { iconName: "RemoveLink" },
    { iconName: "PeopleBlock" },
    { iconName: "PeopleRepeat" },
    { iconName: "PeopleAlert" },
    { iconName: "PeoplePause" },
    { iconName: "TransferCall" },
    { iconName: "AddPhone" },
    { iconName: "UnknownCall" },
    { iconName: "NoteReply" },
    { iconName: "NoteForward" },
    { iconName: "NotePinned" },
    { iconName: "RemoveOccurrence" },
    { iconName: "Timeline" },
    { iconName: "EditNote" },
    { iconName: "CircleHalfFull" },
    { iconName: "Room" },
    { iconName: "Unsubscribe" },
    { iconName: "Subscribe" },
    { iconName: "HardDrive" },
    { iconName: "RecurringTask" },
    { iconName: "TaskManager" },
    { iconName: "TaskManagerMirrored" },
    { iconName: "Combine" },
    { iconName: "Split" },
    { iconName: "DoubleChevronUp" },
    { iconName: "DoubleChevronLeft" },
    { iconName: "DoubleChevronRight" },
    { iconName: "Ascending" },
    { iconName: "Descending" },
    { iconName: "TextBox" },
    { iconName: "TextField" },
    { iconName: "NumberField" },
    { iconName: "Dropdown" },
    { iconName: "PenWorkspace" },
    { iconName: "BookingsLogo" },
    { iconName: "ClassNotebookLogoInverse" },
    { iconName: "DelveAnalyticsLogo" },
    { iconName: "DocsLogoInverse" },
    { iconName: "Dynamics365Logo" },
    { iconName: "DynamicSMBLogo" },
    { iconName: "OfficeAssistantLogo" },
    { iconName: "OfficeStoreLogo" },
    { iconName: "OneNoteEduLogoInverse" },
    { iconName: "PlannerLogo" },
    { iconName: "PowerApps" },
    { iconName: "Suitcase" },
    { iconName: "ProjectLogoInverse" },
    { iconName: "CaretLeft8" },
    { iconName: "CaretRight8" },
    { iconName: "CaretUp8" },
    { iconName: "CaretDown8" },
    { iconName: "CaretLeftSolid8" },
    { iconName: "CaretRightSolid8" },
    { iconName: "CaretUpSolid8" },
    { iconName: "CaretDownSolid8" },
    { iconName: "ClearFormatting" },
    { iconName: "Superscript" },
    { iconName: "Subscript" },
    { iconName: "Strikethrough" },
    { iconName: "Export" },
    { iconName: "ExportMirrored" },
    { iconName: "SingleBookmark" },
    { iconName: "SingleBookmarkSolid" },
    { iconName: "DoubleChevronDown" },
    { iconName: "FollowUser" },
    { iconName: "ReplyAll" },
    { iconName: "WorkforceManagement" },
    { iconName: "RecruitmentManagement" },
    { iconName: "Questionnaire" },
    { iconName: "ManagerSelfService" },
    { iconName: "ProductionFloorManagement" },
    { iconName: "ProductRelease" },
    { iconName: "ProductVariant" },
    { iconName: "ReplyMirrored" },
    { iconName: "ReplyAllMirrored" },
    { iconName: "Medal" },
    { iconName: "AddGroup" },
    { iconName: "QuestionnaireMirrored" },
    { iconName: "CloudImportExport" },
    { iconName: "TemporaryUser" },
    { iconName: "CaretSolid16" },
    { iconName: "GroupedDescending" },
    { iconName: "GroupedAscending" },
    { iconName: "SortUp" },
    { iconName: "SortDown" },
    { iconName: "AwayStatus" },
    { iconName: "MyMoviesTV" },
    { iconName: "SyncToPC" },
    { iconName: "GenericScan" },
    { iconName: "AustralianRules" },
    { iconName: "WifiEthernet" },
    { iconName: "TrackersMirrored" },
    { iconName: "DateTimeMirrored" },
    { iconName: "StopSolid" },
    { iconName: "DoubleChevronUp12" },
    { iconName: "DoubleChevronDown12" },
    { iconName: "DoubleChevronLeft12" },
    { iconName: "DoubleChevronRight12" },
    { iconName: "CalendarAgenda" },
    { iconName: "ConnectVirtualMachine" },
    { iconName: "AddEvent" },
    { iconName: "AssetLibrary" },
    { iconName: "DataConnectionLibrary" },
    { iconName: "DocLibrary" },
    { iconName: "FormLibrary" },
    { iconName: "FormLibraryMirrored" },
    { iconName: "ReportLibrary" },
    { iconName: "ReportLibraryMirrored" },
    { iconName: "ContactCard" },
    { iconName: "CustomList" },
    { iconName: "CustomListMirrored" },
    { iconName: "IssueTracking" },
    { iconName: "IssueTrackingMirrored" },
    { iconName: "PictureLibrary" },
    { iconName: "OfficeAddinsLogo" },
    { iconName: "OfflineOneDriveParachute" },
    { iconName: "OfflineOneDriveParachuteDisabled" },
    { iconName: "LargeGrid" },
    { iconName: "TriangleSolidUp12" },
    { iconName: "TriangleSolidDown12" },
    { iconName: "TriangleSolidLeft12" },
    { iconName: "TriangleSolidRight12" },
    { iconName: "TriangleUp12" },
    { iconName: "TriangleDown12" },
    { iconName: "TriangleLeft12" },
    { iconName: "TriangleRight12" },
    { iconName: "ArrowUpRight8" },
    { iconName: "ArrowDownRight8" },
    { iconName: "DocumentSet" },
    { iconName: "GoToDashboard" },
    { iconName: "DelveAnalytics" },
    { iconName: "ArrowUpRightMirrored8" },
    { iconName: "ArrowDownRightMirrored8" },
    { iconName: "CompanyDirectory" },
    { iconName: "OpenEnrollment" },
    { iconName: "CompanyDirectoryMirrored" },
    { iconName: "OneDriveAdd" },
    { iconName: "ProfileSearch" },
    { iconName: "Header2" },
    { iconName: "Header3" },
    { iconName: "Header4" },
    { iconName: "RingerSolid" },
    { iconName: "Eyedropper" },
    { iconName: "MarketDown" },
    { iconName: "CalendarWorkWeek" },
    { iconName: "SidePanel" },
    { iconName: "GlobeFavorite" },
    { iconName: "CaretTopLeftSolid8" },
    { iconName: "CaretTopRightSolid8" },
    { iconName: "ViewAll2" },
    { iconName: "DocumentReply" },
    { iconName: "PlayerSettings" },
    { iconName: "ReceiptForward" },
    { iconName: "ReceiptReply" },
    { iconName: "ReceiptCheck" },
    { iconName: "Fax" },
    { iconName: "RecurringEvent" },
    { iconName: "ReplyAlt" },
    { iconName: "ReplyAllAlt" },
    { iconName: "EditStyle" },
    { iconName: "EditMail" },
    { iconName: "Lifesaver" },
    { iconName: "LifesaverLock" },
    { iconName: "InboxCheck" },
    { iconName: "FolderSearch" },
    { iconName: "CollapseMenu" },
    { iconName: "ExpandMenu" },
    { iconName: "Boards" },
    { iconName: "SunAdd" },
    { iconName: "SunQuestionMark" },
    { iconName: "LandscapeOrientation" },
    { iconName: "DocumentSearch" },
    { iconName: "PublicCalendar" },
    { iconName: "PublicContactCard" },
    { iconName: "PublicEmail" },
    { iconName: "PublicFolder" },
    { iconName: "WordDocument" },
    { iconName: "PowerPointDocument" },
    { iconName: "ExcelDocument" },
    { iconName: "GroupedList" },
    { iconName: "ClassroomLogo" },
    { iconName: "Sections" },
    { iconName: "EditPhoto" },
    { iconName: "Starburst" },
    { iconName: "ShareiOS" },
    { iconName: "AirTickets" },
    { iconName: "PencilReply" },
    { iconName: "Tiles2" },
    { iconName: "SkypeCircleCheck" },
    { iconName: "SkypeCircleClock" },
    { iconName: "SkypeCircleMinus" },
    { iconName: "SkypeCheck" },
    { iconName: "SkypeClock" },
    { iconName: "SkypeMinus" },
    { iconName: "SkypeMessage" },
    { iconName: "ClosedCaption" },
    { iconName: "ATPLogo" },
    { iconName: "OfficeFormsLogoInverse" },
    { iconName: "RecycleBin" },
    { iconName: "EmptyRecycleBin" },
    { iconName: "Hide2" },
    { iconName: "Breadcrumb" },
    { iconName: "BirthdayCake" },
    { iconName: "ClearFilter" },
    { iconName: "Flow" },
    { iconName: "TimeEntry" },
    { iconName: "CRMProcesses" },
    { iconName: "PageEdit" },
    { iconName: "PageArrowRight" },
    { iconName: "PageRemove" },
    { iconName: "Database" },
    { iconName: "DataManagementSettings" },
    { iconName: "CRMServices" },
    { iconName: "EditContact" },
    { iconName: "ConnectContacts" },
    { iconName: "AppIconDefaultAdd" },
    { iconName: "AppIconDefaultList" },
    { iconName: "ActivateOrders" },
    { iconName: "DeactivateOrders" },
    { iconName: "ProductCatalog" },
    { iconName: "ScatterChart" },
    { iconName: "AccountActivity" },
    { iconName: "DocumentManagement" },
    { iconName: "CRMReport" },
    { iconName: "KnowledgeArticle" },
    { iconName: "Relationship" },
    { iconName: "HomeVerify" },
    { iconName: "ZipFolder" },
    { iconName: "SurveyQuestions" },
    { iconName: "TextDocument" },
    { iconName: "TextDocumentShared" },
    { iconName: "PageCheckedOut" },
    { iconName: "PageShared" },
    { iconName: "SaveAndClose" },
    { iconName: "Script" },
    { iconName: "Archive" },
    { iconName: "ActivityFeed" },
    { iconName: "Compare" },
    { iconName: "EventDate" },
    { iconName: "ArrowUpRight" },
    { iconName: "CaretRight" },
    { iconName: "SetAction" },
    { iconName: "ChatBot" },
    { iconName: "CaretSolidLeft" },
    { iconName: "CaretSolidDown" },
    { iconName: "CaretSolidRight" },
    { iconName: "CaretSolidUp" },
    { iconName: "PowerAppsLogo" },
    { iconName: "PowerApps2Logo" },
    { iconName: "SearchIssue" },
    { iconName: "SearchIssueMirrored" },
    { iconName: "FabricAssetLibrary" },
    { iconName: "FabricDataConnectionLibrary" },
    { iconName: "FabricDocLibrary" },
    { iconName: "FabricFormLibrary" },
    { iconName: "FabricFormLibraryMirrored" },
    { iconName: "FabricReportLibrary" },
    { iconName: "FabricReportLibraryMirrored" },
    { iconName: "FabricPublicFolder" },
    { iconName: "FabricFolderSearch" },
    { iconName: "FabricMovetoFolder" },
    { iconName: "FabricUnsyncFolder" },
    { iconName: "FabricSyncFolder" },
    { iconName: "FabricOpenFolderHorizontal" },
    { iconName: "FabricFolder" },
    { iconName: "FabricFolderFill" },
    { iconName: "FabricNewFolder" },
    { iconName: "FabricPictureLibrary" },
    { iconName: "PhotoVideoMedia" },
    { iconName: "AddFavorite" },
    { iconName: "AddFavoriteFill" },
    { iconName: "BufferTimeBefore" },
    { iconName: "BufferTimeAfter" },
    { iconName: "BufferTimeBoth" },
    { iconName: "PublishContent" },
    { iconName: "ClipboardList" },
    { iconName: "ClipboardListMirrored" },
    { iconName: "CannedChat" },
    { iconName: "SkypeForBusinessLogo" },
    { iconName: "TabCenter" },
    { iconName: "PageCheckedin" },
    { iconName: "PageList" },
    { iconName: "ReadOutLoud" },
    { iconName: "CaretBottomLeftSolid8" },
    { iconName: "CaretBottomRightSolid8" },
    { iconName: "FolderHorizontal" },
    { iconName: "MicrosoftStaffhubLogo" },
    { iconName: "GiftboxOpen" },
    { iconName: "StatusCircleOuter" },
    { iconName: "StatusCircleInner" },
    { iconName: "StatusCircleRing" },
    { iconName: "StatusTriangleOuter" },
    { iconName: "StatusTriangleInner" },
    { iconName: "StatusTriangleExclamation" },
    { iconName: "StatusCircleExclamation" },
    { iconName: "StatusCircleErrorX" },
    { iconName: "StatusCircleCheckmark" },
    { iconName: "StatusCircleInfo" },
    { iconName: "StatusCircleBlock" },
    { iconName: "StatusCircleBlock2" },
    { iconName: "StatusCircleQuestionMark" },
    { iconName: "StatusCircleSync" },
    { iconName: "Toll" },
    { iconName: "ExploreContentSingle" },
    { iconName: "CollapseContent" },
    { iconName: "CollapseContentSingle" },
    { iconName: "InfoSolid" },
    { iconName: "GroupList" },
    { iconName: "ProgressRingDots" },
    { iconName: "CaloriesAdd" },
    { iconName: "BranchFork" },
    { iconName: "MuteChat" },
    { iconName: "AddHome" },
    { iconName: "AddWork" },
    { iconName: "MobileReport" },
    { iconName: "ScaleVolume" },
    { iconName: "HardDriveGroup" },
    { iconName: "FastMode" },
    { iconName: "ToggleLeft" },
    { iconName: "ToggleRight" },
    { iconName: "TriangleShape" },
    { iconName: "RectangleShape" },
    { iconName: "CubeShape" },
    { iconName: "Trophy2" },
    { iconName: "BucketColor" },
    { iconName: "BucketColorFill" },
    { iconName: "Taskboard" },
    { iconName: "SingleColumn" },
    { iconName: "DoubleColumn" },
    { iconName: "TripleColumn" },
    { iconName: "ColumnLeftTwoThirds" },
    { iconName: "ColumnRightTwoThirds" },
    { iconName: "AccessLogoFill" },
    { iconName: "AnalyticsLogo" },
    { iconName: "AnalyticsQuery" },
    { iconName: "NewAnalyticsQuery" },
    { iconName: "AnalyticsReport" },
    { iconName: "WordLogo" },
    { iconName: "WordLogoFill" },
    { iconName: "ExcelLogo" },
    { iconName: "ExcelLogoFill" },
    { iconName: "OneNoteLogo" },
    { iconName: "OneNoteLogoFill" },
    { iconName: "OutlookLogo" },
    { iconName: "OutlookLogoFill" },
    { iconName: "PowerPointLogo" },
    { iconName: "PowerPointLogoFill" },
    { iconName: "PublisherLogo" },
    { iconName: "PublisherLogoFill" },
    { iconName: "ScheduleEventAction" },
    { iconName: "FlameSolid" },
    { iconName: "ServerProcesses" },
    { iconName: "Server" },
    { iconName: "SaveAll" },
    { iconName: "LinkedInLogo" },
    { iconName: "Decimals" },
    { iconName: "SidePanelMirrored" },
    { iconName: "ProtectRestrict" },
    { iconName: "Blog" },
    { iconName: "UnknownMirrored" },
    { iconName: "PublicContactCardMirrored" },
    { iconName: "GridViewSmall" },
    { iconName: "GridViewMedium" },
    { iconName: "GridViewLarge" },
    { iconName: "Step" },
    { iconName: "StepInsert" },
    { iconName: "StepShared" },
    { iconName: "StepSharedAdd" },
    { iconName: "StepSharedInsert" },
    { iconName: "ViewDashboard" },
    { iconName: "ViewList" },
    { iconName: "ViewListGroup" },
    { iconName: "ViewListTree" },
    { iconName: "TriggerAuto" },
    { iconName: "TriggerUser" },
    { iconName: "PivotChart" },
    { iconName: "StackedBarChart" },
    { iconName: "StackedLineChart" },
    { iconName: "BuildQueue" },
    { iconName: "BuildQueueNew" },
    { iconName: "UserFollowed" },
    { iconName: "ContactLink" },
    { iconName: "Stack" },
    { iconName: "Bullseye" },
    { iconName: "VennDiagram" },
    { iconName: "FiveTileGrid" },
    { iconName: "FocalPoint" },
    { iconName: "Insert" },
    { iconName: "RingerRemove" },
    { iconName: "TeamsLogoInverse" },
    { iconName: "TeamsLogo" },
    { iconName: "TeamsLogoFill" },
    { iconName: "SkypeForBusinessLogoFill" },
    { iconName: "SharepointLogo" },
    { iconName: "SharepointLogoFill" },
    { iconName: "DelveLogo" },
    { iconName: "DelveLogoFill" },
    { iconName: "OfficeVideoLogo" },
    { iconName: "OfficeVideoLogoFill" },
    { iconName: "ExchangeLogo" },
    { iconName: "ExchangeLogoFill" },
    { iconName: "Signin" },
    { iconName: "DocumentApproval" },
    { iconName: "CloneToDesktop" },
    { iconName: "InstallToDrive" },
    { iconName: "Blur" },
    { iconName: "Build" },
    { iconName: "ProcessMetaTask" },
    { iconName: "BranchFork2" },
    { iconName: "BranchLocked" },
    { iconName: "BranchCommit" },
    { iconName: "BranchCompare" },
    { iconName: "BranchMerge" },
    { iconName: "BranchPullRequest" },
    { iconName: "BranchSearch" },
    { iconName: "BranchShelveset" },
    { iconName: "RawSource" },
    { iconName: "MergeDuplicate" },
    { iconName: "RowsGroup" },
    { iconName: "RowsChild" },
    { iconName: "Deploy" },
    { iconName: "Redeploy" },
    { iconName: "ServerEnviroment" },
    { iconName: "VisioDiagram" },
    { iconName: "HighlightMappedShapes" },
    { iconName: "TextCallout" },
    { iconName: "IconSetsFlag" },
    { iconName: "VisioLogo" },
    { iconName: "VisioLogoFill" },
    { iconName: "VisioDocument" },
    { iconName: "TimelineProgress" },
    { iconName: "TimelineDelivery" },
    { iconName: "Backlog" },
    { iconName: "TeamFavorite" },
    { iconName: "TaskGroup" },
    { iconName: "TaskGroupMirrored" },
    { iconName: "ScopeTemplate" },
    { iconName: "AssessmentGroupTemplate" },
    { iconName: "NewTeamProject" },
    { iconName: "CommentAdd" },
    { iconName: "CommentNext" },
    { iconName: "CommentPrevious" },
    { iconName: "ShopServer" },
    { iconName: "LocaleLanguage" },
    { iconName: "QueryList" },
    { iconName: "UserSync" },
    { iconName: "UserPause" },
    { iconName: "StreamingOff" },
    { iconName: "MoreVertical" },
    { iconName: "ArrowTallUpLeft" },
    { iconName: "ArrowTallUpRight" },
    { iconName: "ArrowTallDownLeft" },
    { iconName: "ArrowTallDownRight" },
    { iconName: "FieldEmpty" },
    { iconName: "FieldFilled" },
    { iconName: "FieldChanged" },
    { iconName: "FieldNotChanged" },
    { iconName: "RingerOff" },
    { iconName: "PlayResume" },
    { iconName: "BulletedList2" },
    { iconName: "BulletedList2Mirrored" },
    { iconName: "ImageCrosshair" },
    { iconName: "GitGraph" },
    { iconName: "Repo" },
    { iconName: "RepoSolid" },
    { iconName: "FolderQuery" },
    { iconName: "FolderList" },
    { iconName: "FolderListMirrored" },
    { iconName: "LocationOutline" },
    { iconName: "POISolid" },
    { iconName: "CalculatorNotEqualTo" },
    { iconName: "BoxSubtractSolid" },
    { iconName: "BoxAdditionSolid" },
    { iconName: "BoxMultiplySolid" },
    { iconName: "BoxPlaySolid" },
    { iconName: "BoxCheckmarkSolid" },
    { iconName: "CirclePauseSolid" },
    { iconName: "CirclePause" },
    { iconName: "MSNVideosSolid" },
    { iconName: "CircleStopSolid" },
    { iconName: "CircleStop" },
    { iconName: "NavigateBack" },
    { iconName: "NavigateBackMirrored" },
    { iconName: "NavigateForward" },
    { iconName: "NavigateForwardMirrored" },
    { iconName: "UnknownSolid" },
    { iconName: "UnknownMirroredSolid" },
    { iconName: "CircleAddition" },
    { iconName: "CircleAdditionSolid" },
    { iconName: "FilePDB" },
    { iconName: "FileTemplate" },
    { iconName: "FileSQL" },
    { iconName: "FileJAVA" },
    { iconName: "FileASPX" },
    { iconName: "FileCSS" },
    { iconName: "FileSass" },
    { iconName: "FileLess" },
    { iconName: "FileHTML" },
    { iconName: "JavaScriptLanguage" },
    { iconName: "CSharpLanguage" },
    { iconName: "CSharp" },
    { iconName: "VisualBasicLanguage" },
    { iconName: "VB" },
    { iconName: "CPlusPlusLanguage" },
    { iconName: "CPlusPlus" },
    { iconName: "FSharpLanguage" },
    { iconName: "FSharp" },
    { iconName: "TypeScriptLanguage" },
    { iconName: "PythonLanguage" },
    { iconName: "PY" },
    { iconName: "CoffeeScript" },
    { iconName: "MarkDownLanguage" },
    { iconName: "FullWidth" },
    { iconName: "FullWidthEdit" },
    { iconName: "Plug" },
    { iconName: "PlugSolid" },
    { iconName: "PlugConnected" },
    { iconName: "PlugDisconnected" },
    { iconName: "UnlockSolid" },
    { iconName: "Variable" },
    { iconName: "Parameter" },
    { iconName: "CommentUrgent" },
    { iconName: "Storyboard" },
    { iconName: "DiffInline" },
    { iconName: "DiffSideBySide" },
    { iconName: "ImageDiff" },
    { iconName: "ImagePixel" },
    { iconName: "FileBug" },
    { iconName: "FileCode" },
    { iconName: "FileComment" },
    { iconName: "BusinessHoursSign" },
    { iconName: "FileImage" },
    { iconName: "FileSymlink" },
    { iconName: "AutoFillTemplate" },
    { iconName: "WorkItem" },
    { iconName: "WorkItemBug" },
    { iconName: "LogRemove" },
    { iconName: "ColumnOptions" },
    { iconName: "Packages" },
    { iconName: "BuildIssue" },
    { iconName: "AssessmentGroup" },
    { iconName: "VariableGroup" },
    { iconName: "FullHistory" },
    { iconName: "Wheelchair" },
    { iconName: "SingleColumnEdit" },
    { iconName: "DoubleColumnEdit" },
    { iconName: "TripleColumnEdit" },
    { iconName: "ColumnLeftTwoThirdsEdit" },
    { iconName: "ColumnRightTwoThirdsEdit" },
    { iconName: "StreamLogo" },
    { iconName: "PassiveAuthentication" },
    { iconName: "AlertSolid" },
    { iconName: "MegaphoneSolid" },
    { iconName: "TaskSolid" },
    { iconName: "ConfigurationSolid" },
    { iconName: "BugSolid" },
    { iconName: "CrownSolid" },
    { iconName: "Trophy2Solid" },
    { iconName: "QuickNoteSolid" },
    { iconName: "ConstructionConeSolid" },
    { iconName: "PageListSolid" },
    { iconName: "PageListMirroredSolid" },
    { iconName: "StarburstSolid" },
    { iconName: "ReadingModeSolid" },
    { iconName: "SadSolid" },
    { iconName: "HealthSolid" },
    { iconName: "ShieldSolid" },
    { iconName: "GiftBoxSolid" },
    { iconName: "ShoppingCartSolid" },
    { iconName: "MailSolid" },
    { iconName: "ChatSolid" },
    { iconName: "RibbonSolid" },
    { iconName: "FinancialSolid" },
    { iconName: "FinancialMirroredSolid" },
    { iconName: "HeadsetSolid" },
    { iconName: "PermissionsSolid" },
    { iconName: "ParkingSolid" },
    { iconName: "ParkingMirroredSolid" },
    { iconName: "DiamondSolid" },
    { iconName: "AsteriskSolid" },
    { iconName: "OfflineStorageSolid" },
    { iconName: "BankSolid" },
    { iconName: "DecisionSolid" },
    { iconName: "Parachute" },
    { iconName: "ParachuteSolid" },
    { iconName: "FiltersSolid" },
    { iconName: "ColorSolid" },
    { iconName: "ReviewSolid" },
    { iconName: "ReviewRequestSolid" },
    { iconName: "ReviewRequestMirroredSolid" },
    { iconName: "ReviewResponseSolid" },
    { iconName: "FeedbackRequestSolid" },
    { iconName: "FeedbackRequestMirroredSolid" },
    { iconName: "FeedbackResponseSolid" },
    { iconName: "WorkItemBar" },
    { iconName: "WorkItemBarSolid" },
    { iconName: "Separator" },
    { iconName: "NavigateExternalInline" },
    { iconName: "PlanView" },
    { iconName: "TimelineMatrixView" },
    { iconName: "EngineeringGroup" },
    { iconName: "ProjectCollection" },
    { iconName: "CaretBottomRightCenter8" },
    { iconName: "CaretBottomLeftCenter8" },
    { iconName: "CaretTopRightCenter8" },
    { iconName: "CaretTopLeftCenter8" },
    { iconName: "DonutChart" },
    { iconName: "ChevronUnfold10" },
    { iconName: "ChevronFold10" },
    { iconName: "DoubleChevronDown8" },
    { iconName: "DoubleChevronUp8" },
    { iconName: "DoubleChevronLeft8" },
    { iconName: "DoubleChevronRight8" },
    { iconName: "ChevronDownEnd6" },
    { iconName: "ChevronUpEnd6" },
    { iconName: "ChevronLeftEnd6" },
    { iconName: "ChevronRightEnd6" },
    { iconName: "ContextMenu" },
    { iconName: "AzureAPIManagement" },
    { iconName: "AzureServiceEndpoint" },
    { iconName: "VSTSLogo" },
    { iconName: "VSTSAltLogo1" },
    { iconName: "VSTSAltLogo2" },
    { iconName: "FileTypeSolution" },
    { iconName: "WordLogoInverse16" },
    { iconName: "WordLogo16" },
    { iconName: "WordLogoFill16" },
    { iconName: "PowerPointLogoInverse16" },
    { iconName: "PowerPointLogo16" },
    { iconName: "PowerPointLogoFill16" },
    { iconName: "ExcelLogoInverse16" },
    { iconName: "ExcelLogo16" },
    { iconName: "ExcelLogoFill16" },
    { iconName: "OneNoteLogoInverse16" },
    { iconName: "OneNoteLogo16" },
    { iconName: "OneNoteLogoFill16" },
    { iconName: "OutlookLogoInverse16" },
    { iconName: "OutlookLogo16" },
    { iconName: "OutlookLogoFill16" },
    { iconName: "PublisherLogoInverse16" },
    { iconName: "PublisherLogo16" },
    { iconName: "PublisherLogoFill16" },
    { iconName: "VisioLogoInverse16" },
    { iconName: "VisioLogo16" },
    { iconName: "VisioLogoFill16" },
    { iconName: "TestBeaker" },
    { iconName: "TestBeakerSolid" },
    { iconName: "TestExploreSolid" },
    { iconName: "TestAutoSolid" },
    { iconName: "TestUserSolid" },
    { iconName: "TestImpactSolid" },
    { iconName: "TestPlan" },
    { iconName: "TestStep" },
    { iconName: "TestParameter" },
    { iconName: "TestSuite" },
    { iconName: "TestCase" },
    { iconName: "Sprint" },
    { iconName: "SignOut" },
    { iconName: "TriggerApproval" },
    { iconName: "Rocket" },
    { iconName: "AzureKeyVault" },
    { iconName: "Onboarding" },
    { iconName: "Transition" },
    { iconName: "LikeSolid" },
    { iconName: "DislikeSolid" },
    { iconName: "CRMCustomerInsightsApp" },
    { iconName: "EditCreate" },
    { iconName: "PlayReverseResume" },
    { iconName: "PlayReverse" },
    { iconName: "SearchData" },
    { iconName: "UnSetColor" },
    { iconName: "DeclineCall" },
    { iconName: "RectangularClipping" },
    { iconName: "TeamsLogo16" },
    { iconName: "TeamsLogoFill16" },
    { iconName: "Spacer" },
    { iconName: "SkypeLogo16" },
    { iconName: "SkypeForBusinessLogo16" },
    { iconName: "SkypeForBusinessLogoFill16" },
    { iconName: "FilterSolid" },
    { iconName: "MailUndelivered" },
    { iconName: "MailTentative" },
    { iconName: "MailTentativeMirrored" },
    { iconName: "MailReminder" },
    { iconName: "ReceiptUndelivered" },
    { iconName: "ReceiptTentative" },
    { iconName: "ReceiptTentativeMirrored" },
    { iconName: "Inbox" },
    { iconName: "IRMReply" },
    { iconName: "IRMReplyMirrored" },
    { iconName: "IRMForward" },
    { iconName: "IRMForwardMirrored" },
    { iconName: "VoicemailIRM" },
    { iconName: "EventAccepted" },
    { iconName: "EventTentative" },
    { iconName: "EventTentativeMirrored" },
    { iconName: "EventDeclined" },
    { iconName: "IDBadge" },
    { iconName: "BackgroundColor" },
    { iconName: "OfficeFormsLogoInverse16" },
    { iconName: "OfficeFormsLogo" },
    { iconName: "OfficeFormsLogoFill" },
    { iconName: "OfficeFormsLogo16" },
    { iconName: "OfficeFormsLogoFill16" },
    { iconName: "OfficeFormsLogoInverse24" },
    { iconName: "OfficeFormsLogo24" },
    { iconName: "OfficeFormsLogoFill24" },
    { iconName: "PageLock" },
    { iconName: "NotExecuted" },
    { iconName: "NotImpactedSolid" },
    { iconName: "FieldReadOnly" },
    { iconName: "FieldRequired" },
    { iconName: "BacklogBoard" },
    { iconName: "ExternalBuild" },
    { iconName: "ExternalTFVC" },
    { iconName: "ExternalXAML" },
    { iconName: "IssueSolid" },
    { iconName: "DefectSolid" },
    { iconName: "LadybugSolid" },
    { iconName: "NugetLogo" },
    { iconName: "TFVCLogo" },
    { iconName: "ProjectLogo32" },
    { iconName: "ProjectLogoFill32" },
    { iconName: "ProjectLogo16" },
    { iconName: "ProjectLogoFill16" },
    { iconName: "SwayLogo32" },
    { iconName: "SwayLogoFill32" },
    { iconName: "SwayLogo16" },
    { iconName: "SwayLogoFill16" },
    { iconName: "ClassNotebookLogo32" },
    { iconName: "ClassNotebookLogoFill32" },
    { iconName: "ClassNotebookLogo16" },
    { iconName: "ClassNotebookLogoFill16" },
    { iconName: "ClassNotebookLogoInverse32" },
    { iconName: "ClassNotebookLogoInverse16" },
    { iconName: "StaffNotebookLogo32" },
    { iconName: "StaffNotebookLogoFill32" },
    { iconName: "StaffNotebookLogo16" },
    { iconName: "StaffNotebookLogoFill16" },
    { iconName: "StaffNotebookLogoInverted32" },
    { iconName: "StaffNotebookLogoInverted16" },
    { iconName: "KaizalaLogo" },
    { iconName: "TaskLogo" },
    { iconName: "ProtectionCenterLogo32" },
    { iconName: "GallatinLogo" },
    { iconName: "Globe2" },
    { iconName: "Guitar" },
    { iconName: "Breakfast" },
    { iconName: "Brunch" },
    { iconName: "BeerMug" },
    { iconName: "Vacation" },
    { iconName: "Teeth" },
    { iconName: "Taxi" },
    { iconName: "Chopsticks" },
    { iconName: "SyncOccurence" },
    { iconName: "UnsyncOccurence" },
    { iconName: "GIF" },
    { iconName: "PrimaryCalendar" },
    { iconName: "SearchCalendar" },
    { iconName: "VideoOff" },
    { iconName: "MicrosoftFlowLogo" },
    { iconName: "BusinessCenterLogo" },
    { iconName: "ToDoLogoBottom" },
    { iconName: "ToDoLogoTop" },
    { iconName: "EditSolid12" },
    { iconName: "EditSolidMirrored12" },
    { iconName: "UneditableSolid12" },
    { iconName: "UneditableSolidMirrored12" },
    { iconName: "UneditableMirrored" },
    { iconName: "AdminALogo32" },
    { iconName: "AdminALogoFill32" },
    { iconName: "ToDoLogoInverse" },
    { iconName: "Snooze" },
    { iconName: "WaffleOffice365" },
    { iconName: "ImageSearch" },
    { iconName: "NewsSearch" },
    { iconName: "VideoSearch" },
    { iconName: "R" },
    { iconName: "FontColorA" },
    { iconName: "FontColorSwatch" },
    { iconName: "LightWeight" },
    { iconName: "NormalWeight" },
    { iconName: "SemiboldWeight" },
    { iconName: "GroupObject" },
    { iconName: "UngroupObject" },
    { iconName: "AlignHorizontalLeft" },
    { iconName: "AlignHorizontalCenter" },
    { iconName: "AlignHorizontalRight" },
    { iconName: "AlignVerticalTop" },
    { iconName: "AlignVerticalCenter" },
    { iconName: "AlignVerticalBottom" },
    { iconName: "HorizontalDistributeCenter" },
    { iconName: "VerticalDistributeCenter" },
    { iconName: "Ellipse" },
    { iconName: "Line" },
    { iconName: "Octagon" },
    { iconName: "Hexagon" },
    { iconName: "Pentagon" },
    { iconName: "RightTriangle" },
    { iconName: "HalfCircle" },
    { iconName: "QuarterCircle" },
    { iconName: "ThreeQuarterCircle" },
    { iconName: "6PointStar" },
    { iconName: "12PointStar" },
    { iconName: "ArrangeBringToFront" },
    { iconName: "ArrangeSendToBack" },
    { iconName: "ArrangeSendBackward" },
    { iconName: "ArrangeBringForward" },
    { iconName: "BorderDash" },
    { iconName: "BorderDot" },
    { iconName: "LineStyle" },
    { iconName: "LineThickness" },
    { iconName: "WindowEdit" },
    { iconName: "HintText" },
    { iconName: "MediaAdd" },
    { iconName: "AnchorLock" },
    { iconName: "AutoHeight" },
    { iconName: "ChartSeries" },
    { iconName: "ChartXAngle" },
    { iconName: "ChartYAngle" },
    { iconName: "Combobox" },
    { iconName: "LineSpacing" },
    { iconName: "Padding" },
    { iconName: "PaddingTop" },
    { iconName: "PaddingBottom" },
    { iconName: "PaddingLeft" },
    { iconName: "PaddingRight" },
    { iconName: "NavigationFlipper" },
    { iconName: "AlignJustify" },
    { iconName: "TextOverflow" },
    { iconName: "VisualsFolder" },
    { iconName: "VisualsStore" },
    { iconName: "PictureCenter" },
    { iconName: "PictureFill" },
    { iconName: "PicturePosition" },
    { iconName: "PictureStretch" },
    { iconName: "PictureTile" },
    { iconName: "Slider" },
    { iconName: "SliderHandleSize" },
    { iconName: "DefaultRatio" },
    { iconName: "NumberSequence" },
    { iconName: "GUID" },
    { iconName: "ReportAdd" },
    { iconName: "DashboardAdd" },
    { iconName: "MapPinSolid" },
    { iconName: "WebPublish" },
    { iconName: "PieSingleSolid" },
    { iconName: "BlockedSolid" },
    { iconName: "DrillDown" },
    { iconName: "DrillDownSolid" },
    { iconName: "DrillExpand" },
    { iconName: "DrillShow" },
    { iconName: "SpecialEvent" },
    { iconName: "OneDriveFolder16" },
    { iconName: "FunctionalManagerDashboard" },
    { iconName: "BIDashboard" },
    { iconName: "CodeEdit" },
    { iconName: "RenewalCurrent" },
    { iconName: "RenewalFuture" },
    { iconName: "SplitObject" },
    { iconName: "BulkUpload" },
    { iconName: "DownloadDocument" },
    { iconName: "GreetingCard" },
    { iconName: "Flower" },
    { iconName: "WaitlistConfirm" },
    { iconName: "WaitlistConfirmMirrored" },
    { iconName: "LaptopSecure" },
    { iconName: "DragObject" },
    { iconName: "EntryView" },
    { iconName: "EntryDecline" },
    { iconName: "ContactCardSettings" },
    { iconName: "ContactCardSettingsMirrored" },
    { iconName: "CalendarSettings" },
    { iconName: "CalendarSettingsMirrored" },
    { iconName: "HardDriveLock" },
    { iconName: "HardDriveUnlock" },
    { iconName: "AccountManagement" },
    { iconName: "ReportWarning" },
    { iconName: "TransitionPop" },
    { iconName: "TransitionPush" },
    { iconName: "TransitionEffect" },
    { iconName: "LookupEntities" },
    { iconName: "ExploreData" },
    { iconName: "AddBookmark" },
    { iconName: "SearchBookmark" },
    { iconName: "DrillThrough" },
    { iconName: "MasterDatabase" },
    { iconName: "CertifiedDatabase" },
    { iconName: "MaximumValue" },
    { iconName: "MinimumValue" },
    { iconName: "VisualStudioIDELogo32" },
    { iconName: "PasteAsText" },
    { iconName: "PasteAsCode" },
    { iconName: "BrowserTab" },
    { iconName: "BrowserTabScreenshot" },
    { iconName: "DesktopScreenshot" },
    { iconName: "FileYML" },
    { iconName: "ClipboardSolid" },
    { iconName: "FabricUserFolder" },
    { iconName: "FabricNetworkFolder" },
    { iconName: "BullseyeTarget" },
    { iconName: "AnalyticsView" },
    { iconName: "Video360Generic" },
    { iconName: "Untag" },
    { iconName: "Leave" },
    { iconName: "Trending12" },
    { iconName: "Blocked12" },
    { iconName: "Warning12" },
    { iconName: "CheckedOutByOther12" },
    { iconName: "CheckedOutByYou12" },
    { iconName: "CircleShapeSolid" },
    { iconName: "SquareShapeSolid" },
    { iconName: "TriangleShapeSolid" },
    { iconName: "DropShapeSolid" },
    { iconName: "RectangleShapeSolid" },
    { iconName: "ZoomToFit" },
    { iconName: "InsertColumnsLeft" },
    { iconName: "InsertColumnsRight" },
    { iconName: "InsertRowsAbove" },
    { iconName: "InsertRowsBelow" },
    { iconName: "DeleteColumns" },
    { iconName: "DeleteRows" },
    { iconName: "DeleteRowsMirrored" },
    { iconName: "DeleteTable" },
    { iconName: "AccountBrowser" },
    { iconName: "VersionControlPush" },
    { iconName: "StackedColumnChart2" },
    { iconName: "TripleColumnWide" },
    { iconName: "QuadColumn" },
    { iconName: "WhiteBoardApp16" },
    { iconName: "WhiteBoardApp32" },
    { iconName: "PinnedSolid" },
    { iconName: "InsertSignatureLine" },
    { iconName: "ArrangeByFrom" },
    { iconName: "Phishing" },
    { iconName: "CreateMailRule" },
    { iconName: "PublishCourse" },
    { iconName: "DictionaryRemove" },
    { iconName: "UserRemove" },
    { iconName: "UserEvent" },
    { iconName: "Encryption" },
    { iconName: "PasswordField" },
    { iconName: "OpenInNewTab" },
    { iconName: "Hide3" },
    { iconName: "VerifiedBrandSolid" },
    { iconName: "MarkAsProtected" },
    { iconName: "AuthenticatorApp" },
    { iconName: "WebTemplate" },
    { iconName: "DefenderTVM" },
    { iconName: "MedalSolid" },
    { iconName: "D365TalentLearn" },
    { iconName: "D365TalentInsight" },
    { iconName: "D365TalentHRCore" },
    { iconName: "BacklogList" },
    { iconName: "ButtonControl" },
    { iconName: "TableGroup" },
    { iconName: "MountainClimbing" },
    { iconName: "TagUnknown" },
    { iconName: "TagUnknownMirror" },
    { iconName: "TagUnknown12" },
    { iconName: "TagUnknown12Mirror" },
    { iconName: "Link12" },
    { iconName: "Presentation" },
    { iconName: "Presentation12" },
    { iconName: "Lock12" },
    { iconName: "BuildDefinition" },
    { iconName: "ReleaseDefinition" },
    { iconName: "SaveTemplate" },
    { iconName: "UserGauge" },
    { iconName: "BlockedSiteSolid12" },
    { iconName: "TagSolid" },
    { iconName: "OfficeChat" },
    { iconName: "OfficeChatSolid" },
    { iconName: "MailSchedule" },
    { iconName: "WarningSolid" },
    { iconName: "Blocked2Solid" },
    { iconName: "SkypeCircleArrow" },
    { iconName: "SkypeArrow" },
    { iconName: "SyncStatus" },
    { iconName: "SyncStatusSolid" },
    { iconName: "ProjectDocument" },
    { iconName: "ToDoLogoOutline" },
    { iconName: "VisioOnlineLogoFill32" },
    { iconName: "VisioOnlineLogo32" },
    { iconName: "VisioOnlineLogoCloud32" },
    { iconName: "VisioDiagramSync" },
    { iconName: "Event12" },
    { iconName: "EventDateMissed12" },
    { iconName: "UserOptional" },
    { iconName: "ResponsesMenu" },
    { iconName: "DoubleDownArrow" },
    { iconName: "DistributeDown" },
    { iconName: "BookmarkReport" },
    { iconName: "FilterSettings" },
    { iconName: "GripperDotsVertical" },
    { iconName: "MailAttached" },
    { iconName: "AddIn" },
    { iconName: "LinkedDatabase" },
    { iconName: "TableLink" },
    { iconName: "PromotedDatabase" },
    { iconName: "BarChartVerticalFilter" },
    { iconName: "BarChartVerticalFilterSolid" },
    { iconName: "MicOff2" },
    { iconName: "MicrosoftTranslatorLogo" },
    { iconName: "ShowTimeAs" },
    { iconName: "FileRequest" },
    { iconName: "WorkItemAlert" },
    { iconName: "PowerBILogo16" },
    { iconName: "PowerBILogoBackplate16" },
    { iconName: "BulletedListText" },
    { iconName: "BulletedListBullet" },
    { iconName: "BulletedListTextMirrored" },
    { iconName: "BulletedListBulletMirrored" },
    { iconName: "NumberedListText" },
    { iconName: "NumberedListNumber" },
    { iconName: "NumberedListTextMirrored" },
    { iconName: "NumberedListNumberMirrored" },
    { iconName: "RemoveLinkChain" },
    { iconName: "RemoveLinkX" },
    { iconName: "FabricTextHighlight" },
    { iconName: "ClearFormattingA" },
    { iconName: "ClearFormattingEraser" },
    { iconName: "Photo2Fill" },
    { iconName: "IncreaseIndentText" },
    { iconName: "IncreaseIndentArrow" },
    { iconName: "DecreaseIndentText" },
    { iconName: "DecreaseIndentArrow" },
    { iconName: "IncreaseIndentTextMirrored" },
    { iconName: "IncreaseIndentArrowMirrored" },
    { iconName: "DecreaseIndentTextMirrored" },
    { iconName: "DecreaseIndentArrowMirrored" },
    { iconName: "CheckListText" },
    { iconName: "CheckListCheck" },
    { iconName: "CheckListTextMirrored" },
    { iconName: "CheckListCheckMirrored" },
    { iconName: "NumberSymbol" },
    { iconName: "Coupon" },
    { iconName: "VerifiedBrand" },
    { iconName: "ReleaseGate" },
    { iconName: "ReleaseGateCheck" },
    { iconName: "ReleaseGateError" },
    { iconName: "M365InvoicingLogo" },
    { iconName: "RemoveFromShoppingList" },
    { iconName: "ShieldAlert" },
    { iconName: "FabricTextHighlightComposite" },
    { iconName: "Dataflows" },
    { iconName: "GenericScanFilled" },
    { iconName: "DiagnosticDataBarTooltip" },
    { iconName: "SaveToMobile" },
    { iconName: "Orientation2" },
    { iconName: "ScreenCast" },
    { iconName: "ShowGrid" },
    { iconName: "SnapToGrid" },
    { iconName: "ContactList" },
    { iconName: "NewMail" },
    { iconName: "EyeShadow" },
    { iconName: "FabricFolderConfirm" },
    { iconName: "InformationBarriers" },
    { iconName: "CommentActive" },
    { iconName: "ColumnVerticalSectionEdit" },
    { iconName: "WavingHand" },
    { iconName: "ShakeDevice" },
    { iconName: "SmartGlassRemote" },
    { iconName: "Rotate90Clockwise" },
    { iconName: "Rotate90CounterClockwise" },
    { iconName: "CampaignTemplate" },
    { iconName: "ChartTemplate" },
    { iconName: "PageListFilter" },
    { iconName: "SecondaryNav" },
    { iconName: "ColumnVerticalSection" },
    { iconName: "SkypeCircleSlash" },
    { iconName: "SkypeSlash" },
    { iconName: "CustomizeToolbar" },
    { iconName: "DuplicateRow" },
    { iconName: "RemoveFromTrash" },
    { iconName: "MailOptions" },
    { iconName: "Childof" },
    { iconName: "Footer" },
    { iconName: "Header" },
    { iconName: "BarChartVerticalFill" },
    { iconName: "StackedColumnChart2Fill" },
    { iconName: "PlainText" },
    { iconName: "AccessibiltyChecker" },
    { iconName: "DatabaseSync" },
    { iconName: "ReservationOrders" },
    { iconName: "TabOneColumn" },
    { iconName: "TabTwoColumn" },
    { iconName: "TabThreeColumn" },
    { iconName: "BulletedTreeList" },
    { iconName: "MicrosoftTranslatorLogoGreen" },
    { iconName: "MicrosoftTranslatorLogoBlue" },
    { iconName: "InternalInvestigation" },
    { iconName: "AddReaction" },
    { iconName: "ContactHeart" },
    { iconName: "VisuallyImpaired" },
    { iconName: "EventToDoLogo" },
    { iconName: "Variable2" },
    { iconName: "ModelingView" },
    { iconName: "DisconnectVirtualMachine" },
    { iconName: "ReportLock" },
    { iconName: "Uneditable2" },
    { iconName: "Uneditable2Mirrored" },
    { iconName: "BarChartVerticalEdit" },
    { iconName: "GlobalNavButtonActive" },
    { iconName: "PollResults" },
    { iconName: "Rerun" },
    { iconName: "QandA" },
    { iconName: "QandAMirror" },
    { iconName: "BookAnswers" },
    { iconName: "AlertSettings" },
    { iconName: "TrimStart" },
    { iconName: "TrimEnd" },
    { iconName: "TableComputed" },
    { iconName: "DecreaseIndentLegacy" },
    { iconName: "IncreaseIndentLegacy" },
    { iconName: "SizeLegacy" },
];

// const formContainerClass = cx(css`
//     padding: 5px;
// `);

const iconContainerClass = cx(css`
    height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 5px;
    border: 1px solid;
    border-top: 0px;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
`);

const iconClass = cx(css`
    font-size: 40px;
    height: 40px;
    width: 40px;
`);

const iconDivClass = cx(css`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    height: 85px;
`);

const iconName = cx(css`
    font-size: 10px;
    text-overflow: ellipsis;
`);

export class IconPickerCallout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCallout: false,
            optionFilter: "",
        };
    }

    displayCallout = () => {
        this.setState({
            showCallout: true,
        });
    };

    hideCallout = () => {
        this.setState({
            showCallout: false,
        });
    };

    toogleCallout = () => {
        this.setState({
            showCallout: !this.state.showCallout,
        });
    };

    render() {
        const { target, isCalloutOpen, onIconPick } = this.props;
        return (
            <>
                {isCalloutOpen && (
                    <Callout
                        target={`.${target}`}
                        onDismiss={this.hideCallout}
                        isBeakVisible={false}
                        alignTargetEdge={true}
                        gapSpace={0}
                        setInitialFocus
                        // directionalHint={DirectionalHint.bottomLeft}
                        // directionalHintFixed={true}
                        className={calloutClassName}
                    >
                        <r-cell span="2">
                            <TextField placeholder="Filter icons" value={this.state.iconFilter} onChange={(ev, val) => this.setState({ iconFilter: val })} />
                        </r-cell>
                        <r-cell span="2">
                            <div className={iconContainerClass}>
                                {icons
                                    .filter((I) => {
                                        if (this.state.iconFilter) return I.iconName.toLowerCase().includes(this.state.iconFilter.toLocaleLowerCase());
                                        else return true;
                                    })
                                    .map((icon, idx) => {
                                        return (
                                            <div
                                                key={`icon_${idx}`}
                                                className={iconDivClass}
                                                onClick={() => {
                                                    onIconPick(icon.iconName);
                                                }}
                                            >
                                                <FontIcon iconName={icon.iconName} className={iconClass} />
                                                <br />
                                                <div className={iconName}>{icon.iconName}</div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </r-cell>
                    </Callout>
                )}
            </>
        );
    }
}
