import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";

import { DocumentCommandBar } from "../../components/documents/DocumentCommandBar";
import { DocumentGridView } from "../../components/documents/DocumentGridView";

export const RiskDocumentsTab = inject("riskStore")(
    withRouter(
        observer(
            class RiskDocumentsTab extends Component {
                componentWillMount() {
                    const { riskStore, match } = this.props;
                    const { documentStore, viewStore } = riskStore;
                    documentStore.loadDocuments(viewStore.risk.id);
                }

                render() {
                    const { viewStore, documentStore } = this.props.riskStore;
                    const { risk, lifecycle } = viewStore;
                    const { loading, saving, documents, selectedDocuments, gridView, keywords } = documentStore;

                    return (
                        <>
                            <DocumentCommandBar
                                risk={risk}
                                lifecycle={lifecycle}
                                documents={documents}
                                selectedDocuments={selectedDocuments}
                                view={gridView}
                                keywords={keywords}
                                canUpload={() => documentStore.canUpload}
                                onViewChanged={(view) => this._onGridViewChanged(view)}
                                onUploadInvoked={(options) => this._onUploadInvoked(options)}
                                onDownloadInvoked={(document) => this._onDownloadInvoked(document)}
                                onSearchChange={(keywords) => this._onSearchKeywordsChange(keywords)}
                            />

                            <DocumentGridView
                                loading={loading}
                                saving={saving}
                                risk={risk}
                                lifecycle={lifecycle}
                                documents={documents}
                                view={gridView}
                                keywords={keywords}
                                onDocumentClick={(advisory) => this._onDocumentClick(advisory)}
                                onSelectionChanged={(selection) => this._onSelectionChanged(selection)}
                            />
                        </>
                    );
                }

                _onUploadInvoked(options) {
                    this.props.onUpload();
                }

                _onDownloadInvoked(document) {
                    const { documentStore } = this.props.riskStore;

                    documentStore.downloadDocument(document);
                }

                _onSelectionChanged(selection) {
                    const { documentStore } = this.props.riskStore;

                    documentStore.setSelectedDocuments({
                        count: selection.getSelectedCount(),
                        selected: selection.getSelection(),
                        reset: () => selection.setAllSelected(false),
                    });
                }

                _onGridViewChanged(view) {
                    const { documentStore } = this.props.riskStore;

                    documentStore.setGridView(view);
                    if (documentStore.selectedDocuments) {
                        documentStore.selectedDocuments.reset();
                    }
                }

                _onSearchKeywordsChange(keywords) {
                    const { documentStore } = this.props.riskStore;
                    documentStore.setSearchKeywords(keywords);
                }

                _onDocumentClick(document) {
                    const { documentStore } = this.props.riskStore;

                    documentStore.downloadDocument(document);
                }
            }
        )
    )
);
