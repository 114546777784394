import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { AdaptiveField } from "@modules/base/components/AdaptiveField";
import { FieldSetView } from "@modules/base/components/FieldSetView";

import { WorkItemDetailsSection } from "@modules/workitems/components/core/WorkItemDetailsSection";
import { WorkItemWorkflowSection } from "@modules/workitems/components/core/WorkItemWorkflowSection";
import { WorkItemCustomSection } from "@modules/workitems/components/core/WorkItemCustomSection";

export const WorkItemSummaryTab = observer(({ workItem, etag }) => {
    const { principalContext, productStore, workItemStore } = useStores();
    const { commentStore } = workItemStore;
    const { schema } = productStore;
    const current = schema && workItem && schema.productId === workItem.product.id;

    useEffect(() => {
        workItemStore.summaryStore.loadWorkItemSummary(workItem);
        productStore.loadProductSchema(workItem.product.id, workItem.schemaVersion);
        return () => workItemStore.summaryStore.unloadWorkItemSummary(workItem);
    }, [workItem, etag]);

    return (
        <BoxPanel shadow padding style={{ minHeight: 500 }}>
            <FieldSetView title="Key Information" description="Basic information about this demand request.">
                <WorkItemDetailsSection workItem={workItem} schema={schema} />
            </FieldSetView>
            <FieldSetView title="Key Contacts" description="Key contacts for the demand request.">
                <r-grid columns="2" class="no--margin">
                    <r-cell>
                        <div className="row--xs">
                            <AdaptiveField
                                type="Principal"
                                mode="view"
                                label="Business Contact"
                                value={workItem.businessContact}
                            />
                        </div>
                    </r-cell>
                    <r-cell>
                        <div className="row--xs">
                            <AdaptiveField
                                type="Principal"
                                mode="view"
                                label="Technical Contact"
                                value={workItem.technicalContact}
                            />
                        </div>
                    </r-cell>
                </r-grid>
            </FieldSetView>
            <FieldSetView
                title="Workflow Progress"
                description="Information about the progress of the demand request and assigned user."
            >
                <WorkItemWorkflowSection workItem={workItem} />
            </FieldSetView>
            {current && schema && schema.extensions && schema.extensions.length ? (
                <FieldSetView title="Additional Fields" description="Custom fields for this demand request.">
                    <WorkItemCustomSection workItem={workItem} schema={schema} />
                </FieldSetView>
            ) : null}

            {/* <r-grid columns-l="7" columns="7" columns-s="1">
                <r-cell span-l="3" span="3" span-s="1">
                    <ObservableSeparator className="row--xs">Discussion</ObservableSeparator>
                    <WorkItemCommentFeed commentStore={commentStore} user={principalContext.current} />
                </r-cell>
            </r-grid> */}
        </BoxPanel>
    );
});
