import { ServiceBase, Constants } from "../graph/serviceBase";

export class ConfigService extends ServiceBase {
    public async getModuleConfig(module: string, group: string = "") {
        return this.get(`${Constants.baseUrl}/config/modules/${module}?group=${group || ""}`);
    }

    public async getModuleConfigData(module: string, group: string = "") {
        return this.get(`${Constants.baseUrl}/config/modules/${module}/data?group=${group || ""}`);
    }
}
