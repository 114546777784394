import React, { Component } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { Card } from "@uifabric/react-cards";
import { Persona } from "office-ui-fabric-react/lib/Persona";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { Text } from "office-ui-fabric-react/lib/Text";

export const ProductCardView = observer(
    class ProductCardView extends Component {
        render() {
            const { product, className, onCardClick, isSimplifiedView, showPersona } = this.props;

            return (
                <Card
                    aria-label="Clickable vertical card with image bleeding at the center of the card"
                    className={cx(
                        className,
                        css`
                            max-width: 100%;
                            height: 100%;
                            min-height: 200px;
                            padding: var(--spacing-s) var(--spacing-s);
                            margin-bottom: var(--spacing-s);
                            background-color: var(--app-header-background);
                            text-align: center;
                            position: relative;
                        `
                    )}
                    onClick={() => {
                        onCardClick(product);
                    }}
                    tokens={{ childrenMargin: 0, childrenGap: 15 }}
                >
                    <Card.Item styles={{ root: { position: "absolute", top: 5, right: 10 } }}>
                        <Text
                            variant="small"
                            styles={{
                                root: {
                                    backgroundColor: "var(--background-color)",
                                    display: "inline-block",
                                    padding: "var(--spacing-xxxxxs) var(--spacing-xxxs)",
                                    borderRadius: 5,
                                },
                            }}
                        >
                            {product.availability}
                        </Text>
                    </Card.Item>
                    <Card.Item fill styles={{ root: { textAlign: "center" } }}>
                        <Icon iconName={product.icon || "flaticon-005-computer"} styles={{ root: { color: "var(--primary-brand-color)", fontSize: 55 } }} />
                    </Card.Item>
                    <Card.Section styles={{ root: { minHeight: "110px" } }} grow={1}>
                        <Text variant="medium" styles={{ root: { fontWeight: "600" } }}>
                            {isSimplifiedView ? product.friendlyName || product.name : product.name}
                        </Text>
                        <Stack.Item grow={1}>
                            <Text variant="small">{product.summary}</Text>
                        </Stack.Item>
                        {showPersona && <Persona text={product.provider} secondaryText={product.availability} />}
                    </Card.Section>
                </Card>
            );
        }
    }
);
