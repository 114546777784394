import { observable, action, computed } from "mobx";

import { ModellingService } from '../../../api/modelling';
import { ModellingStore } from "./ModellingStore";

export class ModellingAdvisoryStore {
    private modellingService: ModellingService;
    public parentStore: ModellingStore;

    @observable public selectedMeasure: any;
    @observable public loading: boolean = false;
    @observable public saving: boolean = false;
    @observable public formData: any;
    @observable public visible: boolean = false;

    constructor(parentStore: ModellingStore, modellingService: ModellingService) {
        this.parentStore = parentStore;
        this.modellingService = modellingService;
    }

    @computed
    public get isValid() {
        if(!this.formData){
            return false;
        }

        return this.formData.title && this.formData.description && 
            this.formData.mitigations && this.formData.control.id && this.formData.level;
    }

    @action
    public show(options) {
        this.visible = true;
        this.selectedMeasure = options.measure;

        let config = {
            name: '-',
            control: { id: null, title: null },
            level: 'Medium',
            measureId: options.measure ? options.measure.id : null,
            compliantWhen: options.measure && options.measure.type == 'Boolean' ? true : null,
            compliantWhenAll: options.measure.type == 'MultiChoice' ? false : undefined,
        };

        this.formData = config;
    }

    @action
    public hide(options) {
        this.visible = false;
        this.selectedMeasure = null;
        this.formData = null;
    }
}
