import React, { Component } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { TextField } from "office-ui-fabric-react/lib/TextField";

const rootClassName = cx(
    "cygraph-BigTextField-root",
    css`
        margin: var(--spacing-xxs) 0 0 0;

        & .ms-TextField-fieldGroup {
            height: 40px;
            line-height: 40px;
        }
        & input {
            font-size: var(--font-size-l);
            height: 40px;
            line-height: 40px;
            /* background-color: var(--body-background); */

            ::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                font-size: var(--font-size-l);
            }
            ::-moz-placeholder {
                /* Firefox 19+ */
                font-size: var(--font-size-l);
            }
            :-ms-input-placeholder {
                /* IE 10+ */
                font-size: var(--font-size-l);
            }
            :-moz-placeholder {
                /* Firefox 18- */
                font-size: var(--font-size-l);
            }
        }

        &.transparent {
            & input {
                /* background-color: var(--body-background); */
            }
        }
    `
);

export const BigTextField = observer(
    ({ label, required, placeholder, className, value, onChange, transparent, componentRef, dataTest }) => {
        return (
            <TextField
                label={label}
                required={required}
                className={cx(className, rootClassName, transparent && "transparent")}
                data-test={dataTest || "BigTextField"}
                placeholder={placeholder}
                componentRef={componentRef}
                autoComplete="off"
                focus
                value={value}
                onChange={onChange}
            />
        );
    }
);
