import React, { useEffect, useCallback } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { WorkItemLinkCommandBar } from "@modules/workitems/components/links/WorkItemLinkCommandBar";
import { WorkItemLinkGridView } from "@modules/workitems/components/links/WorkItemLinkGridView";

export const WorkItemLinksTab = observer(({ workItem, onLinkClick }) => {
    const { workItemStore } = useStores();
    const { lifecycleStore, linkStore } = workItemStore;
    const { loading, links } = linkStore;

    useEffect(() => {
        if (workItem) {
            linkStore.loadWorkItemLinks({ workItem });
        }
    }, [workItem, linkStore]);

    const onAddLink = useCallback(
        (options) => {
            lifecycleStore.onAddLink({}, options).then(({ success }) => {
                if (success) {
                    linkStore.loadWorkItemLinks({ workItem });
                }
            });
        },
        [workItem, lifecycleStore, linkStore]
    );

    return (
        <>
            <WorkItemLinkCommandBar canAddLink={lifecycleStore.canAddLink} onAddLink={onAddLink} />
            <WorkItemLinkGridView loading={loading} links={links} onLinkClick={onLinkClick} />
        </>
    );
});
