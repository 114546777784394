import React, { PureComponent } from "react";
import { observer, inject } from "mobx-react";
import { v4 as uuidv4 } from "uuid";
import { cx, css } from "emotion";

import { TextField } from "office-ui-fabric-react/lib/TextField";
import { Dropdown } from "office-ui-fabric-react/lib/Dropdown";
import { ColorPicker } from "office-ui-fabric-react/lib/ColorPicker";
import { DefaultButton, IconButton } from "office-ui-fabric-react/lib/Button";
import { FontIcon } from "office-ui-fabric-react/lib/Icon";
import { TooltipHost } from "office-ui-fabric-react/lib/Tooltip";
import { Callout } from "office-ui-fabric-react/lib/Callout";

import { ObservableSeparator } from "../../base/components/ObservableSeparator";
import { IconPickerCallout } from "../../base/components/IconPickerCallout";

const iconClass = cx(css`
    font-size: 40px;
    height: 40px;
    width: 40px;
`);

const colorPickerClassName = cx(css`
    maxWidth: 352px,
    maxHeight: 352px,
    panel: {
        padding: 12
    },
    colorRectangle: {
        height: 268px
    },
`);

const formContainerClass = cx(css`
    padding: 5px;
    .ragColorPickerContainerClassName {
        input:not([type="number"]) {
            color: white;
            cursor: pointer;
            user-select: none;
            text-shadow: 0.05em 0 black, 0 0.05em black, -0.05em 0 black, 0 -0.05em black, -0.05em -0.05em black, -0.05em 0.05em black, 0.05em -0.05em black,
                0.05em 0.05em black;
        }
    }
    .values {
        display: flex;
        flex-direction: column;
        width: 45px;
        justify-content: center;
    }
    .values input {
        padding: 0;
        margin: 0;
        text-align: center;
    }
`);

const dataSourceDropDownContainer = cx(css`
    display: flex;
    align-items: flex-end;
    .ms-Dropdown-containerr {
        flex-grow: 10;
    }
`);

export const RagStatusPartEditor = inject(
    "dataSourceStore",
    "controlStore"
)(
    observer(
        class RagStatusPartEditor extends PureComponent {
            constructor(props) {
                super(props);
                this.state = {
                    isColorPickerOpen: null,
                    isCalloutOpen: null,
                    bandSelected: "",
                };
            }
            render() {
                const { formData, controlStore, dataSourceStore, dataSources, instance } = this.props;
                const { coreDataSources, selectionStore } = dataSourceStore;
                return (
                    <div>
                        <r-grid columns="2">
                            <r-cell span="2">
                                <ObservableSeparator>Rag Setup</ObservableSeparator>
                            </r-cell>
                            <r-cell span="2" style={{ color: "var(--text-color-light)" }}>
                                <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        this.setState({ isCalloutOpen: !this.state.isCalloutOpen });
                                    }}
                                >
                                    <span className="calloutTarget">Selected Icon</span>
                                    <IconPickerCallout
                                        target={`calloutTarget`}
                                        isCalloutOpen={this.state.isCalloutOpen}
                                        onIconPick={(iconName) => {
                                            formData.properties.selectedIcon = iconName;
                                            this.setState({ isCalloutOpen: false });
                                        }}
                                    />
                                </div>
                            </r-cell>
                            <r-cell span="2">
                                <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        this.setState({ isCalloutOpen: !this.state.isCalloutOpen });
                                    }}
                                >
                                    {formData.properties.selectedIcon ? (
                                        <FontIcon iconName={formData.properties.selectedIcon} className={iconClass} />
                                    ) : (
                                        <span>None</span>
                                    )}
                                </div>
                            </r-cell>
                            <r-cell span="2">
                                <DefaultButton
                                    text="Add source"
                                    onClick={() => {
                                        if (!formData.properties.dataSeries) formData.properties.dataSeries = [];
                                        formData.properties.dataSeries.push({
                                            dataSourceId: null,
                                            label: "Add source",
                                            id: uuidv4(),
                                        });
                                    }}
                                />
                            </r-cell>
                            <r-grid columns="1">
                                {formData.properties.dataSeries &&
                                    formData.properties.dataSeries.length > 0 &&
                                    formData.properties.dataSeries.map((DS, i) => {
                                        const selectedPartDataSource = DS.dataSourceId ? dataSources.find((D) => D.id === DS.dataSourceId) : null;
                                        const selectedDataSource = selectedPartDataSource
                                            ? selectionStore.dataSources.find((D) => D.id === selectedPartDataSource.properties.mainSource)
                                            : null;
                                        const selectedCoreDataSource = selectedDataSource
                                            ? coreDataSources.find((D) => D.id === selectedDataSource.coreDataSourceId)
                                            : null;
                                        return (
                                            <div className={formContainerClass} key={`DS_${i}`}>
                                                <r-cell span="2">
                                                    <div className={dataSourceDropDownContainer}>
                                                        <Dropdown
                                                            required
                                                            label="Data source"
                                                            selectedKey={DS.dataSourceId}
                                                            onChange={(ev, op) => {
                                                                if (op) {
                                                                    DS.dataSourceId = op.key;
                                                                }
                                                            }}
                                                            options={dataSources.map((DSource) => {
                                                                return {
                                                                    key: DSource.id,
                                                                    text: DSource.title,
                                                                };
                                                            })}
                                                        />
                                                        {DS.dataSourceId && (
                                                            <TooltipHost
                                                                content="Edit data source"
                                                                id={`editTooltipId`}
                                                                calloutProps={{ gapSpace: 0 }}
                                                                styles={{ display: "inline-block", maginLeft: "5px" }}
                                                            >
                                                                <IconButton
                                                                    iconProps={{ iconName: "Edit" }}
                                                                    title="Edit"
                                                                    ariaLabel="Edit"
                                                                    onClick={() => {
                                                                        const idx = formData.properties.dataSeries.findIndex((D) => D.id === DS.id);
                                                                        if (idx > -1) {
                                                                            const pagePart = instance.selectionStore.getPagePart(DS.dataSourceId);
                                                                            instance.lifecycleStore.onEditPart(pagePart.section, pagePart.zone, pagePart.part);
                                                                        }
                                                                    }}
                                                                />
                                                            </TooltipHost>
                                                        )}
                                                    </div>
                                                </r-cell>
                                                {selectedCoreDataSource && (
                                                    <r-cell span="2">
                                                        <r-cell span="2">
                                                            <div>
                                                                <Dropdown
                                                                    required
                                                                    label="Source Field"
                                                                    selectedKey={DS.dataDisplayType || "field"}
                                                                    onChange={(ev, op) => {
                                                                        if (op) {
                                                                            DS.dataDisplayType = op.key;
                                                                        }
                                                                    }}
                                                                    options={[
                                                                        {
                                                                            text: "Specific field",
                                                                            key: "field",
                                                                        },
                                                                        {
                                                                            text: "Count of records",
                                                                            key: "count",
                                                                        },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </r-cell>
                                                        {DS.dataDisplayType !== "count" && (
                                                            <r-cell span="2">
                                                                <div>
                                                                    <Dropdown
                                                                        required
                                                                        label="Value Field"
                                                                        selectedKey={DS.dataValue}
                                                                        onChange={(ev, op) => {
                                                                            if (op) {
                                                                                DS.dataValue = op.key;
                                                                            }
                                                                        }}
                                                                        options={selectedCoreDataSource.fields.map((F) => {
                                                                            return {
                                                                                key: F.id,
                                                                                text: F.title,
                                                                            };
                                                                        })}
                                                                    />
                                                                </div>
                                                            </r-cell>
                                                        )}
                                                        {DS.dataDisplayType !== "count" && (
                                                            <r-cell span="2">
                                                                <div>
                                                                    <Dropdown
                                                                        required
                                                                        label="Label Field"
                                                                        selectedKey={DS.labelField}
                                                                        onChange={(ev, op) => {
                                                                            if (op) {
                                                                                DS.labelField = op.key;
                                                                            }
                                                                        }}
                                                                        options={selectedCoreDataSource.fields.map((F) => {
                                                                            return {
                                                                                key: F.id,
                                                                                text: F.title,
                                                                            };
                                                                        })}
                                                                    />
                                                                </div>
                                                            </r-cell>
                                                        )}
                                                        {/* {DS.dataDisplayType !== 'count' && <r-cell span="2">
                            <div>
                                <Dropdown
                                    required
                                    label="Chart type"
                                    selectedKey={DS.orderField || 'singleValue'}
                                    onChange={(ev, op) => {
                                        if(op) {
                                            DS.orderField = op.key;
                                        }
                                    }}
                                    options={[{key: 'singleValue', text: 'Single Value'},{key: 'comparison', text: 'Comparison'}]}
                                />
                            </div>
                        </r-cell> } */}
                                                        {selectedCoreDataSource && DS.dataDisplayType === "count" && (
                                                            <r-cell span="2">
                                                                <div>
                                                                    <TextField label="Description" value={DS.unit} onChange={(ev, val) => (DS.unit = val)} />
                                                                </div>
                                                            </r-cell>
                                                        )}
                                                        {selectedCoreDataSource && (
                                                            <r-cell span="2">
                                                                <div>
                                                                    <Dropdown
                                                                        label="Show description"
                                                                        selectedKey={DS.unitDisplay}
                                                                        onChange={(ev, op) => {
                                                                            if (op) {
                                                                                DS.unitDisplay = op.key || "pre";
                                                                            }
                                                                        }}
                                                                        options={[
                                                                            {
                                                                                key: "hide",
                                                                                text: "Hide",
                                                                            },
                                                                            {
                                                                                key: "pre",
                                                                                text: "Show before",
                                                                            },
                                                                            {
                                                                                key: "after",
                                                                                text: "Show after",
                                                                            },
                                                                        ]}
                                                                    />
                                                                </div>
                                                            </r-cell>
                                                        )}
                                                        {selectedCoreDataSource && (
                                                            <r-cell span="2">
                                                                <div>
                                                                    <TextField
                                                                        label="Upper limit"
                                                                        type="number"
                                                                        required
                                                                        value={DS.upperLimit}
                                                                        onChange={(ev, val) => (DS.upperLimit = parseFloat(val))}
                                                                    />
                                                                </div>
                                                            </r-cell>
                                                        )}
                                                        {selectedCoreDataSource && (
                                                            <r-cell span="2">
                                                                <div>
                                                                    <TextField
                                                                        label="Lower limit"
                                                                        type="number"
                                                                        required
                                                                        value={DS.lowerLimit}
                                                                        onChange={(ev, val) => (DS.lowerLimit = parseFloat(val))}
                                                                    />
                                                                </div>
                                                            </r-cell>
                                                        )}
                                                        {selectedCoreDataSource && (
                                                            <r-cell span="2">
                                                                <ObservableSeparator>
                                                                    Rag Color Setup (
                                                                    <a
                                                                        className="restoreDefaultColor"
                                                                        href="#"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            DS.upperBandColor = "green";
                                                                            DS.middleBandColor = "#FFBF00";
                                                                            DS.lowerBandColor = "red";
                                                                        }}
                                                                    >
                                                                        Restore default color
                                                                    </a>
                                                                    )
                                                                </ObservableSeparator>
                                                            </r-cell>
                                                        )}
                                                        {selectedCoreDataSource && (
                                                            <div className="ragColorPickerContainerClassName" style={{ display: "flex", marginTop: "15px" }}>
                                                                <div className="pickers" style={{ width: "100%" }}>
                                                                    <TextField
                                                                        readOnly
                                                                        value={DS.upperLimit ? `Values greater than ${DS.upperLimit}` : ""}
                                                                        className={`colorPicker_upperBandColor_${DS.id}`}
                                                                        style={{
                                                                            backgroundColor: DS.upperBandColor || "green",
                                                                        }}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                isColorPickerOpen: DS.id,
                                                                                bandSelected: `upperBandColor`,
                                                                            });
                                                                        }}
                                                                    />
                                                                    <TextField
                                                                        readOnly
                                                                        value={
                                                                            DS.upperLimit && (DS.lowerLimit || DS.lowerLimit == 0)
                                                                                ? `Values between ${DS.lowerLimit} and ${DS.upperLimit} incl.`
                                                                                : ""
                                                                        }
                                                                        className={`colorPicker_middleBandColor_${DS.id}`}
                                                                        style={{
                                                                            backgroundColor: DS.middleBandColor || "#FFBF00",
                                                                        }}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                isColorPickerOpen: DS.id,
                                                                                bandSelected: `middleBandColor`,
                                                                            });
                                                                        }}
                                                                    />
                                                                    <TextField
                                                                        readOnly
                                                                        value={DS.lowerLimit || DS.lowerLimit == 0 ? `Values lower than ${DS.lowerLimit}` : ""}
                                                                        className={`colorPicker_lowerBandColor_${DS.id}`}
                                                                        style={{
                                                                            backgroundColor: DS.lowerBandColor || "red",
                                                                        }}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                isColorPickerOpen: DS.id,
                                                                                bandSelected: `lowerBandColor`,
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="values">
                                                                    <TextField
                                                                        value={DS.upperLimit}
                                                                        onChange={(ev, val) => (DS.upperLimit = parseFloat(val))}
                                                                        type="number"
                                                                    />
                                                                    <TextField
                                                                        value={DS.lowerLimit}
                                                                        onChange={(ev, val) => (DS.lowerLimit = parseFloat(val))}
                                                                        type="number"
                                                                    />
                                                                </div>
                                                                {this.state.isColorPickerOpen == DS.id && (
                                                                    <Callout
                                                                        role="alertdialog"
                                                                        gapSpace={0}
                                                                        target={`.colorPicker_${this.state.bandSelected}_${this.state.isColorPickerOpen}`}
                                                                        onDismiss={() => {
                                                                            this.setState({
                                                                                isColorPickerOpen: null,
                                                                            });
                                                                        }}
                                                                        setInitialFocus
                                                                    >
                                                                        <div className="inner">
                                                                            <ColorPicker
                                                                                color={DS[this.state.bandSelected]}
                                                                                onChange={(ev, colorObject) => {
                                                                                    DS[this.state.bandSelected] = colorObject.str;
                                                                                }}
                                                                                alphaType="none"
                                                                                alphaSliderHidden={true}
                                                                                showPreview={true}
                                                                                className={colorPickerClassName}
                                                                            />
                                                                        </div>
                                                                    </Callout>
                                                                )}
                                                            </div>
                                                        )}
                                                    </r-cell>
                                                )}
                                                {DS.dataSourceId && (
                                                    <r-cell span="2">
                                                        <DefaultButton
                                                            text="Remove data series"
                                                            style={{ marginTop: "10px", width: "238px" }}
                                                            onClick={() => {
                                                                const idx = formData.properties.dataSeries.findIndex((D) => D.id === DS.id);
                                                                if (idx > -1) {
                                                                    formData.properties.dataSeries.splice(idx, 1);
                                                                }
                                                            }}
                                                        />
                                                    </r-cell>
                                                )}
                                            </div>
                                        );
                                    })}
                            </r-grid>
                        </r-grid>
                    </div>
                );
            }
        }
    )
);
