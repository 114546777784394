import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { useStores } from "@hooks/useStores";
import * as microsoftTeams from "@microsoft/teams-js";

import { JsonQueryString } from "@modules/utils/JsonQueryString";
import { ObservablePagination } from "@modules/base/components/ObservablePagination";

import { WorkItemGridView } from "@modules/workitems/components/core/WorkItemGridView";
import { WorkItemCommandBar } from "@modules/workitems/components/core/WorkItemCommandBar";
import { WorkItemSideNav } from "@modules/workitems/components/core/WorkItemSideNav";

import { WorkItemNewPanel } from "@modules/workitems/containers/panels/WorkItemNewPanel";

export const WorkItemBrowsePage = withRouter(
    observer(({ match }) => {
        const { workItemStore, boardsStore, pageContext, routing } = useStores();
        const { browseStore, lifecycleStore } = workItemStore;
        const { breadcrumb, error, isGuestUser, workItems, query, loading, pagination } = browseStore;

        useEffect(() => {
            pageContext.updateQueryStringParams(routing.location.search);
            browseStore.loadWorkItems({
                page: pageContext.queryStringParams.p || 1,
                query: JsonQueryString.decode(pageContext.queryStringParams.query),
            });
        }, [match, routing, pageContext]);

        return (
            <>
                <WorkItemCommandBar
                    loading={loading}
                    isGuestUser={isGuestUser}
                    filterQuery={query}
                    filterContext={{
                        processing: loading,
                        resultCount: pagination.totalItemCount,
                    }}
                    onFilterChange={(q, f) => {
                        routing.push(JsonQueryString.build("/teams/demand/browse", q));
                    }}
                    onNewRequest={(product) => {
                        if (!product) {
                            microsoftTeams.tasks.startTask(
                                {
                                    url: `${window.location.origin}/teams/demand/catalogue`,
                                    fallbackUrl: `${window.location.origin}/demand/catalogue`,
                                    width: "large",
                                    height: "large",
                                    title: `Raise Demand Request`,
                                },
                                (err, result) => {
                                    if (!err) {
                                        routing.push(`/teams/demand/browse/${result.data.id}`);
                                    }
                                }
                            );
                        } else {
                            lifecycleStore.onCreate({}, { workItem: { product } });
                        }
                    }}
                />

                <WorkItemGridView
                    workItems={workItems}
                    loading={loading}
                    query={query}
                    onProductClick={(workItem) => routing.push(`/teams/demand/catalogue/${workItem.id}`)}
                    onWorkItemClick={(workItem) => routing.push(`/teams/demand/browse/${workItem.id}`)}
                />

                <ObservablePagination
                    {...pagination}
                    onPageChange={(index) => {
                        routing.push(JsonQueryString.build("/teams/demand/browse", query, index + 1));
                    }}
                />

                <WorkItemNewPanel store={workItemStore.newFormStore} />
            </>
        );
    })
);
