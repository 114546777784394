import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';

import { ComboBox } from 'office-ui-fabric-react/lib/ComboBox';

import { AssetPicker } from '../../../assets/containers/pickers/AssetPicker';
import { RiskTargetPickerStore } from '../../stores/RiskTargetPickerStore';

export const RiskTargetPicker = inject('riskStore')(observer(
    class RiskTargetPicker extends Component {

        constructor(props) {
            super(props);

            this.state = { 
                pickerStore: new RiskTargetPickerStore(this.props.riskStore),
            }
        }

        componentWillMount() {
            const { pickerStore } = this.state;
            const { selected } = this.props;

            if(typeof(selected) !== 'undefined') {
                pickerStore.setTargetType(selected ? selected.type : null);
                if(selected) {
                    pickerStore.setTargetData(selected);
                }
            }
        }

        componentWillReceiveProps(nextProps) {
            const { pickerStore } = this.state;
            const { selected } = nextProps;

            if(typeof(selected) !== 'undefined') {
                pickerStore.setTargetType(selected ? selected.type : null);
                if(selected) {
                    pickerStore.setTargetData(selected);
                }
            }
        }
    
        render () {
            const { pickerStore } = this.state;
            const { label, required, placeholder, onChange, disableType, disabled, className } = this.props;
            const { targetType, targetId } = pickerStore;
            
            return (
                <div className={className}>
                    <r-grid columns="2">
                        <r-cell span="1">
                            <ComboBox
                                {...{ label, required }}
                                selectedKey={targetType}
                                disabled={disableType || disabled}
                                useComboBoxAsMenuWidth={true}
                                options={['Asset', 'Entity', 'Process'].map(p => ({ key: p, text: p }))}
                                onChange={(event, option) => {
                                    pickerStore.setTargetType(option.key);
                                    if(onChange) {
                                        onChange({
                                            type: option.key,
                                        }, null);
                                    }
                                }} />
                        </r-cell>
                        <r-cell span="1">
                            { targetType == 'Asset' && (
                                <AssetPicker
                                    {...{ required, placeholder, disabled }}
                                    label={`Target ${targetType}`}
                                    selectedId={targetId}
                                    onChange={(target) => {
                                        pickerStore.setTargetData(target);
                                        if(onChange) {
                                            if(target) {
                                                onChange({
                                                    type: targetType,
                                                    id: target.id,
                                                    name: target.name
                                                }, target);
                                            } else {
                                                onChange({
                                                    type: targetType,
                                                }, null);
                                            }
                                        }
                                    }} />
                            ) }
                            { targetType == 'Entity' && (
                                <div style={{paddingTop: '33px'}}>Supplier is not yet supported</div>
                            )}     
                            { targetType == 'Process' && (
                                <div style={{paddingTop: '33px'}}>Process is not yet supported</div>
                            )}       
                        </r-cell>
                    </r-grid>
                </div>
            );
        }
    }
));
