import React, { Component } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { Icon } from "office-ui-fabric-react/lib/Icon";
import { Text } from "office-ui-fabric-react/lib/Text";
import { Stack } from "office-ui-fabric-react/lib/Stack";

const rootClassName = cx(
    "cygraph-ActionBar-root",
    css`
        &.center {
            margin: 100px auto;
        }

        &.mini {
            min-width: 400px;
            max-width: 700px;
        }

        &.no-margin {
            margin: 0;
        }
    `
);

const iconClassName = cx(
    "cygraph-ActionBar-icon",
    css`
        font-size: 30px;
    `
);

const buttonsClassName = cx(
    "cygraph-ActionBar-buttons",
    css`
        margin-left: auto;
        text-align: right;

        &.vertical {
            text-align: left;
            margin-top: 30px;
            margin-left: 45px;
        }

        & button {
            white-space: nowrap;
        }
    `
);

export const ActionBar = observer(
    class ActionBar extends Component {
        render() {
            const { children, className, title, description, vertical, compact, iconName, center, mini, margin } =
                this.props;

            return (
                <div
                    className={cx(
                        rootClassName,
                        "card",
                        margin === false && "no-margin",
                        compact && "pad--s pad--left pad--right",
                        className,
                        this._getMainClassName(),
                        center && "center",
                        mini && "mini"
                    )}
                >
                    <div className="flex flex--align--center">
                        <div className="pad--right">
                            <Icon iconName={iconName || this._getIconName()} className={iconClassName} />
                        </div>
                        <div className="pad--right">
                            <div className="title row--xs">
                                <Text variant="mediumPlus">{title}</Text>
                            </div>
                            <div className="title--xs">{description}</div>
                        </div>
                        {!vertical && (
                            <div className={buttonsClassName}>
                                <Stack horizontal tokens={{ childrenGap: 20 }}>
                                    {children}
                                </Stack>
                            </div>
                        )}
                    </div>
                    {vertical && children && (
                        <div className={cx(buttonsClassName, "vertical")}>
                            <Stack horizontal tokens={{ childrenGap: 20 }}>
                                {children}
                            </Stack>
                        </div>
                    )}
                </div>
            );
        }

        _getMainClassName() {
            switch (this.props.variant) {
                case "info":
                    return "message--info";
                case "primary":
                    return "message--primary";
                case "success":
                    return "message--success";
                case "secondary":
                    return "message--secondary";
                case "danger":
                    return "message--danger";
                case "warning":
                    return "message--warning";
                case "dark":
                    return "message--dark";
                case "white":
                    return "message--white";
                default:
                    return "message--primary";
            }
        }

        _getIconName() {
            switch (this.props.variant) {
                case "info":
                    return "InfoSolid";
                case "primary":
                    return "InfoSolid";
                case "success":
                    return "CompletedSolid";
                case "secondary":
                    return "InfoSolid";
                case "danger":
                    return "Error";
                case "warning":
                    return "Warning";
                case "dark":
                    return "InfoSolid";
                default:
                    return "InfoSolid";
            }
        }
    }
);
