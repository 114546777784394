import React from "react";
import { View, Text } from "@react-pdf/renderer";

export const StatusBadge = ({ variant, children }) => {
    const variants = {
        red: "#ff3b30",
        orange: "#ff9500",
        green: "#00bd71",
        blue: "#007aff",
        pink: "#ff2d55",
        yellow: "#ffcc00",
        purple: "#af52de",
        grey: "#808080",
    };
    return (
        <View style={{ display: "flex", flexDirection: "row" }}>
            <View style={{ backgroundColor: variants[variant] || variants.gray, borderRadius: "50%", width: 8, height: 8, margin: "auto" }}></View>
            <View style={{ flexGrow: 1, marginLeft: 5 }}>
                <Text style={{ fontSize: 10 }}>{children}</Text>
            </View>
        </View>
    );
};
