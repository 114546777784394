import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router';

import { css } from '@uifabric/utilities/lib/css';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Facepile, OverflowButtonType } from 'office-ui-fabric-react/lib/Facepile';
import { PersonaSize } from 'office-ui-fabric-react/lib/Persona';

import { AppPage } from '../../../layout/containers/AppPage'; 
import { ObservableSeparator } from '../../../base/components/ObservableSeparator';
import { BoxPanel } from '../../../base/components/BoxPanel';
import { SharingFormPanel } from '../../../securables/containers/panels/SharingFormPanel';
import { SettingsListNav } from '../../../settings/containers/utilities/SettingsListNav';
import { SharingScopeListView } from '../../../securables/components/SharingScopeListView';

import { PageLibraryHeader } from '../../components/libraries/PageLibraryHeader';
import { PageGridView } from '../../components/core/PageGridView';


export const PageLibraryViewerPage = inject('pageContext', 'routing', 'pageStore')(withRouter(observer(
    class PageLibraryViewerPage extends Component {

        componentWillMount() {
            const { match, pageStore } = this.props;
            pageStore.libraryStore.loadLibrary(match.params.id);
        }

        componentWillReceiveProps(nextProps) {
            const { match, pageStore } = nextProps;
            pageStore.libraryStore.loadLibrary(match.params.id);
        }

        render () {
            const { routing, pageStore } = this.props;
            const { loading, saving, error, breadcrumb, library, root, scopes, canShare } = pageStore.libraryStore;

            return (
                <AppPage 
                    loading={loading || saving} 
                    error={error} 
                    breadcrumb={breadcrumb}
                    hasSidebar={false}
                    className={css('page-libraries viewer', !error && 'has--page-nav')}>
                    <SettingsListNav />
                    <div>
                        { library && <PageLibraryHeader mode={'view'} library={library} hideActions /> }

                        { root && (
                            <div className="row--s">
                                <ObservableSeparator>Root Page</ObservableSeparator>
                                <PageGridView
                                    pages={[root]}
                                    loading={loading}
                                    onPagePathClick={(item, _) => routing.push(item.path) }
                                    query={{}} />
                            </div>
                        ) }
                        

                        { library && canShare && (
                            <>
                                <ObservableSeparator>Permissions</ObservableSeparator>
                                <BoxPanel shadow padding>
                                    <div className="row--s">
                                        <r-grid columns="3">
                                            <r-cell>
                                                <div>
                                                    <Label>Allow Guest Access</Label>
                                                    <div>{library.allowGuestAccess ? 'Yes' : 'No'}</div>
                                                </div>
                                            </r-cell>
                                            <r-cell>
                                                <div>
                                                    <Label>Viewer Roles</Label>
                                                    <div>{library.viewerRoles && library.viewerRoles.length > 0 ? library.viewerRoles.join(', ') : 'none'}</div>
                                                </div>
                                            </r-cell>
                                            <r-cell>
                                                <div>
                                                    <Label>Contributor Roles</Label>
                                                    <div>{library.contributorRoles && library.contributorRoles.length > 0 ? library.contributorRoles.join(', ') : 'none'}</div>
                                                </div>
                                            </r-cell>
                                        </r-grid>
                                    </div>
                                    { scopes && ( <SharingScopeListView 
                                        scopes={scopes}
                                        canShare={() => canShare}
                                        onShare={() => pageStore.libraryStore.show({})}
                                        onRevoke={(item) => pageStore.libraryStore.onRevoke(item)} />
                                    ) }
                                </BoxPanel>
                            </>
                        ) }

                    </div>

                    <SharingFormPanel
                        visible={pageStore.libraryStore.visible} 
                        formData={pageStore.libraryStore.formData}
                        formOptions={pageStore.libraryStore.formOptions} 
                        isValid={pageStore.libraryStore.isValid} 
                        onCancel={() => pageStore.libraryStore.hide()}
                        onGrant={() => pageStore.libraryStore.onGrant().then(() => pageStore.libraryStore.hide())} />
                </AppPage>
            );
        }
    }
)));
