import { observable, flow, action } from "mobx";

import { PortfolioService } from '../../../api/graph';
import { PortfolioStore } from "./PortfolioStore";

export class PortfolioPickerStore {

    private parentStore: PortfolioStore;
    private portfolioService: PortfolioService;

    @observable public selectedPortfolioIds: string[] = [];
    @observable public portfolios: any[] = [];
    @observable public loading: boolean = false;

    constructor(parentStore: PortfolioStore) {
        this.parentStore = parentStore;
        this.portfolioService = parentStore.portfolioService;
    }

    public loadPortfolios = flow(function*(query: any) {
        this.loading = true;
        this.error = null;

        const options = Object.assign({ 
            pageSize: 100, 
            startIndex: 0,
            keywords: null,
            activeOnly: true,
        }, query);

        try {
            const result = yield this.portfolioService.getPortfolios(options);
            this.portfolios = result.items;
        } catch (e) {
            this.error = e;
            console.error(e)
        } finally {
            this.loading = false;
        }
    });

    @action
    public setSelected(portfolios: any[]) {
        this.selectedPortfolioIds = portfolios.map(i => i.id);
    }

    @action
    public setSelectedWithId(id: string | null) {
        if(!id) {
            this.selectedPortfolioIds = [];
        } else {
            if(this.selectedPortfolioIds.length == 1 && this.selectedPortfolioIds[0] == id) {
                return;
            }
            this.selectedPortfolioIds = [id];
        }
    }

    @action
    public setSelectedWithIds(ids: string[] | null) {
        if(ids == null || ids.length == 0) {
            this.selectedPortfolioIds = [];
        } else {
            this.selectedPortfolioIds = ids;
        }
    }
}
