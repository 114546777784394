import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { ObservablePagination } from "@modules/base/components/ObservablePagination";

import { RiskTypesCommandBar } from "@modules/risks/components/core/RiskTypesCommandBar";
import { RiskTypeGridView } from "@modules/risks/components/core/RiskTypeGridView";

import { RiskTypeNewPanel } from "@modules/risks/containers/panels/RiskTypeNewPanel";

export const RisksCatalogueTab = observer(() => {
    const { routing, pageContext, riskStore } = useStores();
    const { typeStore, riskTypeNewFormStore } = riskStore;
    const { loading, pagination, keywords } = typeStore;

    useEffect(() => {
        pageContext.updateQueryStringParams(routing.location.search);

        typeStore.loadTypes({
            page: pageContext.queryStringParams.p || 1,
            keywords: pageContext.queryStringParams.k,
        });
    }, []);

    return (
        <>
            <RiskTypesCommandBar
                onNewRiskType={() => {
                    riskTypeNewFormStore.show();
                }}
                keywords={keywords}
                onSearchChange={(keywords) => {
                    routing.push(`/settings/risk-types${keywords ? `?k=${encodeURIComponent(keywords)}` : ""}`);
                }}
            />

            <RiskTypeGridView riskTypes={riskStore.typeStore.types} loading={loading} />

            <ObservablePagination
                {...pagination}
                onPageChange={(index) => {
                    routing.push(
                        `/settings/risk-types?p=${index + 1}${
                            pageContext.queryStringParams.k
                                ? `&k=${encodeURIComponent(pageContext.queryStringParams.k)}`
                                : ""
                        }`
                    );
                }}
            />
            <RiskTypeNewPanel
                onSaveNewRiskType={() => {
                    typeStore.loadTypes({
                        page: pageContext.queryStringParams.p || 1,
                        keywords: pageContext.queryStringParams.k,
                    });
                }}
            />
        </>
    );
});
