import React from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

const rootClassName = cx(
    "cygraph-WorkItemLinkDirection-root",
    css`
        display: inline-block;
        padding: 1px var(--spacing-xs);
        margin-top: 5px;
        position: relative;
        height: 20px;

        &.outgoing {
            background-color: var(--primary-selection-color) !important;
            color: #ffffff !important;
            border-radius: 2px 0 0 2px;
        }
        &.outgoing:after {
            content: " ";
            display: block;
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 6px solid var(--primary-selection-color) !important;
            position: absolute;
            top: 50%;
            margin-top: -10px;
            left: 100%;
            z-index: 2;
        }

        &.incoming {
            background-color: var(--border-color) !important;
            color: var(--text-color-dark) !important;
            margin-left: 6px;
            border-radius: 0 2px 2px 0;
        }
        &.incoming:after {
            content: " ";
            display: block;
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-right: 6px solid var(--border-color) !important;
            position: absolute;
            top: 50%;
            margin-top: -10px;
            right: 100%;
            z-index: 2;
        }
    `
);

export const WorkItemLinkDirection = observer(({ link, className }) => {
    return (
        <span className={cx(rootClassName, className, !link.incoming && "outgoing", link.incoming && "incoming")}>
            <span>{link.linkType.name}</span>
        </span>
    );
});
