import React, { PureComponent } from "react";
import { observer } from "mobx-react";

import { Pivot, PivotItem, PivotLinkFormat } from "office-ui-fabric-react/lib/Pivot";

import { RiskSummaryTab } from "./RiskSummaryTab";
import { RiskReviewsTab } from "./RiskReviewsTab";
import { RiskTreatmentsTab } from "./RiskTreatmentsTab";
import { RiskAssessmentTab } from "./RiskAssessmentTab";
import { RiskActivitiesTab } from "./RiskActivitiesTab";
import { RiskDocumentsTab } from "./RiskDocumentsTab";

export const RiskViewTabList = observer(
    class RiskViewTabList extends PureComponent {
        render() {
            const { risk, onUpload } = this.props;

            const isExceptionTemplate = risk.type && risk.type.template === "zerodai:risks:exception";

            return (
                <Pivot
                    aria-label="Risk reviews, life cycles, related documents and work"
                    linkFormat={PivotLinkFormat.links}
                >
                    <PivotItem headerText={`Summary`} itemIcon="Info">
                        <RiskSummaryTab />
                    </PivotItem>
                    <PivotItem headerText={`Review History`} itemIcon="History">
                        <RiskReviewsTab isExceptionTemplate={isExceptionTemplate} />
                    </PivotItem>
                    {!isExceptionTemplate && (
                        <PivotItem headerText={`Treatment History`} itemIcon="Hospital">
                            <RiskTreatmentsTab />
                        </PivotItem>
                    )}
                    <PivotItem headerText={`Related Documents`} itemIcon="DocLibrary">
                        <RiskDocumentsTab onUpload={onUpload} />
                    </PivotItem>
                    {risk.assessment && (
                        <PivotItem headerText={`Related Assessment`} itemIcon="WorkItem">
                            <RiskAssessmentTab />
                        </PivotItem>
                    )}
                    {/*  <PivotItem headerText={`Activities`}>
                        <RiskActivitiesTab />
                    </PivotItem> */}
                </Pivot>
            );
        }
    }
);
