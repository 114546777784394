import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';

import { Text } from '@ui/elements/Text';
import { Stack } from '@ui/elements/Stack';
import { Image, ImageFit } from '@ui/elements/Image';

import { ObservableBadge } from '@baseComponents/ObservableBadge';
import { ActionSet } from '@baseComponents/ActionSet';

const rootClassName = cx(
  'cygraph-ControlSetHeader-root',
  css` 
    & .badge {
      & > div {
        outline: none;
      }
    } 

    & .title {
      margin: var(--spacing-s) 0;
      & > div {
        outline: none;
      }
    }

    & .description {
      color: var(--text-color-light);
      font-size: var(--font-size-xs);
      margin-top: var(--spacing-xs);
      & > div {
        outline: none;
      }
    }

    & .meta {
      display: inline-block;
      margin-right: 20px;
    }

    & .image {
      min-width: 200px;
    }
  `
);

export const ControlSetHeader = observer(
  class ControlSetHeader extends PureComponent {

    render () {
        const { className, set, hideMetadata, isEditMode, sharedWith, canShare, onShare, onScopes } = this.props;
        const { items, overflowItems } = this._getActionSetItems();

        return (
          <div className={cx(rootClassName, className)}>
            <Stack horizontal horizontalAlign="space-between">
              <ObservableBadge className="badge">Control Set</ObservableBadge>
              <ActionSet items={items} overflowItems={overflowItems} />
            </Stack>
            <Stack horizontal horizontalAlign="space-between">
              <Stack.Item grow>
                <Text className="title" variant="xLarge" block>
                  {set.name}
                </Text>
              </Stack.Item>
              <Stack.Item>
                  <div className="image">
                    <Image src={set.imageUrl || '/images/logos/zerodai.png'} 
                      width="100%" height={65} imageFit={ImageFit.centerContain} alt={set.name} />
                  </div>
                </Stack.Item>              
            </Stack>
          </div>
        );
    }

    _getActionSetItems() {
      const { hideActions, isEditMode, canEdit, onEdit, canCancel, onCancel, canSave, onSave, canPublish } = this.props;

      const items = [];
      const overflowItems = [];

      if(hideActions) {
        return { items, overflowItems };
      }

      if(!isEditMode) {
        items.push({
          key: 'edit',
          name: 'Edit',
          iconProps: { iconName: 'Edit' },
          disabled: !canEdit || !canEdit(),
          ariaLabel: 'Edit',
          onClick: onEdit,
        });
      } else {
        items.push({
          key: 'cancel',
          name: 'Discard',
          iconProps: { iconName: 'Uneditable' },
          disabled: !canCancel || !canCancel(),
          ariaLabel: 'Discard',
          onClick: onCancel,
        });

        items.push({
          key: 'save',
          name: 'Save',
          iconProps: { iconName: 'Save' },
          disabled: !canSave || !canSave(),
          ariaLabel: 'Save',
          onClick: () => onSave('Minor'),
        });
      }      

      items.push({
        key: 'publish',
        name: 'Publish',
        primary: true,
        iconProps: { iconName: 'ReadingMode' },
        disabled: !canPublish || !canPublish(),
        confirmProps: { title: 'Are you sure you want to publish this page?' },
        ariaLabel: 'Publish',
        onClick: () => onSave('Major'),
      });

      return { items, overflowItems };
    }
  }
);