import { observable, computed, flow } from "mobx";
import moment from "moment";

import { WorkItemService } from "../../../api/workitems";
import { WorkItemStore } from "./WorkItemStore";
import { PrincipalContextStore } from "../../identity/stores/PrincipalContextStore";
import { isUserAuthenticated, isUserInAllRoles } from "../../identity/stores/PrincipalContextUtilities";
export class WorkItemCalendarStore {
    public parentStore: WorkItemStore;
    public workItemService: WorkItemService;
    public principalContext: PrincipalContextStore;

    @observable public workItems: any[] = [];
    @observable public keywords: string = "";
    @observable public view: string = "Month";
    @observable public loading: boolean = false;
    @observable public error: any;
    @observable public breadcrumb: any[] = [
        { text: "Demand Management", key: "demand", onClick: () => this._goToUrl(`/demand/dashboard`) },
        { text: "Demand Calendar", key: "calendar", isCurrentItem: true },
    ];

    constructor(parentStore: WorkItemStore) {
        this.parentStore = parentStore;
        this.workItemService = parentStore.workItemService;
        this.principalContext = parentStore.rootStore.principalContext;
    }

    @computed
    public get isAgent() {
        const user = this.principalContext.current;
        return user && isUserInAllRoles(user, ["agent"]);
    }

    @computed
    public get events() {
        let events = [];

        events = events.concat(
            this.workItems
                .filter((a) => !!a.created)
                .map((a) => {
                    return {
                        id: `${a.id}-raised`,
                        title: `${a.code} - Raised - ${a.title}`,
                        start: a.created,
                        allDay: false,
                        assessment: { ...a },
                        backgroundColor: "var(--grey-4)",
                        borderColor: "var(--grey-4)",
                    };
                })
        );

        events = events.concat(
            this.workItems
                .filter((a) => !!a.started)
                .map((a) => {
                    return {
                        id: `${a.id}-started`,
                        title: `${a.code} - Started - ${a.title}`,
                        start: a.started,
                        allDay: false,
                        assessment: { ...a },
                        backgroundColor: "var(--blue-9)",
                        borderColor: "var(--blue-9)",
                    };
                })
        );

        events = events.concat(
            this.workItems
                .filter((a) => !!a.closed)
                .map((a) => {
                    return {
                        id: `${a.id}-closed`,
                        title: `${a.code} - Closed - ${a.title}`,
                        start: a.closed,
                        allDay: false,
                        assessment: { ...a },
                        backgroundColor: "var(--green-9)",
                        borderColor: "var(--green-9)",
                    };
                })
        );

        events = events.concat(
            this.workItems
                .filter((a) => !!a.dueDate)
                .map((a) => {
                    return {
                        id: `${a.id}-due`,
                        title: `${a.code} - Due - ${a.title}`,
                        start: a.dueDate,
                        allDay: true,
                        assessment: { ...a },
                        backgroundColor: "var(--pink-9)",
                        borderColor: "var(--pink-9)",
                    };
                })
        );

        return events;
    }

    public loadAssessments = flow(function* (query) {
        var options = Object.assign({ keywords: null, view: "Month" }, query);
        this.loading = true;
        this.workItems = [];
        this.view = options.view;
        this.date = moment.utc(options.date).toDate();

        try {
            const result = yield this.workItemService.searchWorkItems({
                pageSize: 250,
                startIndex: 0,
                query: {
                    keywords: options.keywords,
                    timeline: {
                        after: options.start,
                        before: options.end,
                    },
                },
            });
            this.workItems = result.items;
            this.keywords = result.keywords;
            this.loading = false;
            return result;
        } catch (error) {
            this.error = error;
        } finally {
            this.loading = false;
        }
    });

    _goToUrl = (url) => {
        if (url) {
            this.parentStore.rootStore.routing.push(url);
        }
    };
}
