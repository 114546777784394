import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Card } from '@uifabric/react-cards';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { Text } from 'office-ui-fabric-react/lib/Text';

import { ObservableSeparator} from '../../../base/components/ObservableSeparator';

export const SettingsCardView = observer(
    class SettingsCardView extends Component {

        render () {
          const { loading, settings } = this.props;

          return loading ? null : (
             <div>
               {settings.map(section => (
                 <div key={section.key} className="row--l">
                   <ObservableSeparator className="row--xs">{section.name}</ObservableSeparator>
                   <div className="row--l">{section.description}</div>
                   <r-grid columns="3" columns-l="4">
                      { section.links.map(link => (
                        <r-cell key={link.key}>
                          <Card
                            className="card"
                            aria-label="Clickable settings link" 
                            onClick={(ev) => this._onLinkClick(ev, link)}>
                            <Card.Item className="setting">
                              <Stack horizontal tokens={{ childrenGap: 16 }}>
                                <Icon iconName={link.icon} className="icon" />                                
                                <Stack.Item grow={1}>
                                  <Text variant="mediumPlus" className="name">{link.name}</Text>
                                </Stack.Item>
                              </Stack>
                            </Card.Item>
                        </Card>
                        </r-cell>
                      )) }
                   </r-grid>
                 </div>
               ))}
             </div>
          );
        }

        _onLinkClick = (ev, item) => {
          const { onItemClick } = this.props;
          ev.preventDefault();
          if(item && onItemClick) {
            onItemClick(item);
          }
        };
    }
);