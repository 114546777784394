import React, { Component } from "react";
import { observer } from "mobx-react";
import Moment from "react-moment";
import * as moment from "moment";

import { PersonaSize } from "office-ui-fabric-react/lib/Persona";
import { MessageBar } from "office-ui-fabric-react/lib/MessageBar";
import { DetailsListLayoutMode, Selection, SelectionMode, ConstrainMode } from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { ContextualMenu } from "office-ui-fabric-react/lib/ContextualMenu";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { WrappedContent } from "@modules/base/components/WrappedContent";
import { PhotoPersona } from "@modules/identity/containers/utilities/PhotoPersona";
import { isNotNullOrEmpty } from "@modules/editors/components/RichTextField";
import RichTextViewer from "@modules/editors/components/RichTextViewer";

import { TreatmentEffectiveness } from "@modules/risks/components/treatments/TreatmentEffectiveness";
import { RiskScore } from "@modules/risks/components/reviews/RiskScore";

export const ReviewGridView = observer(
    class ReviewGridView extends Component {
        constructor(props) {
            super(props);

            this.state = {};

            this._selection = new Selection({
                onSelectionChanged: () => {
                    this.setState({
                        selectionDetails: this._getSelectionDetails(),
                    });
                },
            });

            this._columns = [
                {
                    key: "reviewed",
                    name: "Reviewed",
                    fieldName: "reviewed",
                    minWidth: 150,
                    maxWidth: 150,
                    isRowHeader: true,
                    isResizable: true,
                    isSorted: true,
                    isSortedDescending: true,
                    sortAscendingAriaLabel: "Sorted A to Z",
                    sortDescendingAriaLabel: "Sorted Z to A",
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return (
                            <WrappedContent>
                                <a
                                    href="#"
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                        ev.stopPropagation();
                                        if (this.props.onReviewClick) {
                                            this.props.onReviewClick(item);
                                        }
                                    }}
                                >
                                    <PhotoPersona
                                        principalId={item.reviewedBy ? item.reviewedBy.id : null}
                                        size={PersonaSize.size28}
                                        text={item.reviewedBy.name}
                                        showSecondaryText={true}
                                        secondaryText={moment(item.reviewed).format("DD MMM YYYY")}
                                    />
                                </a>
                            </WrappedContent>
                        );
                    },
                    isPadded: true,
                },
            ];

            if (!props.isExceptionTemplate) {
                this._columns.push({
                    key: "grossScore",
                    name: "Gross Risk",
                    fieldName: "grossScore",
                    minWidth: 420,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return (
                            <div>
                                <div className="row--xxs">
                                    <RiskScore score={item.grossScore} maxImpact={4} maxLikelihood={4} />
                                </div>
                                {isNotNullOrEmpty(item.grossScore.description) ? (
                                    <RichTextViewer className="description" value={item.grossScore.description} />
                                ) : (
                                    <span>No gross risk justification set</span>
                                )}
                            </div>
                        );
                    },
                    isPadded: true,
                });
            }

            this._columns.push({
                key: "netScore",
                name: "Net Risk",
                fieldName: "netScore",
                minWidth: 420,
                isResizable: false,
                onColumnClick: this._onColumnClick,
                data: "string",
                onRender: (item) => {
                    return (
                        <div>
                            <div className="row--xxs">
                                <RiskScore score={item.netScore} maxImpact={4} maxLikelihood={4} />
                            </div>
                            {isNotNullOrEmpty(item.netScore.description) ? (
                                <RichTextViewer className="description" value={item.netScore.description} />
                            ) : (
                                <span>No net risk justification set</span>
                            )}
                        </div>
                    );
                },
                isPadded: true,
            });

            if (props.isExceptionTemplate && props.isControlException) {
                this._columns.push({
                    key: "category",
                    name: "Risk Event Type",
                    fieldName: "category",
                    minWidth: 155,
                    maxWidth: 190,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return item.category ? item.category.name : null;
                    },
                    isPadded: true,
                });

                this._columns.push({
                    key: "businessAppetite",
                    name: "Business Appetite",
                    fieldName: "businessAppetite",
                    minWidth: 125,
                    maxWidth: 170,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return item.businessAppetite;
                    },
                    isPadded: true,
                });

                this._columns.push({
                    key: "nextReviewDue",
                    name: "Expiry Date",
                    fieldName: "nextReviewDue",
                    minWidth: 115,
                    maxWidth: 150,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return <Moment date={item.nextReviewDue} utc format="Do MMM YYYY" />;
                    },
                    isPadded: true,
                });
            }

            if (!props.isExceptionTemplate) {
                this._columns.push({
                    key: "treatments",
                    name: "Treatments",
                    fieldName: "treatments",
                    minWidth: 75,
                    maxWidth: 130,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return (
                            <div>
                                <span>{item.treatmentsCount} </span>
                                {item.treatmentEffectiveness != "Unknown" && item.treatmentEffectiveness != 0 && (
                                    <TreatmentEffectiveness effectiveness={item.treatmentEffectiveness} />
                                )}
                            </div>
                        );
                    },
                    isPadded: true,
                });
            }

            this._columns.push({
                key: "controls",
                name: "Controls",
                fieldName: "controls",
                minWidth: 45,
                maxWidth: 50,
                isResizable: false,
                onColumnClick: this._onColumnClick,
                data: "string",
                onRender: (item) => {
                    return (
                        <div>
                            <span>{item.controlsCount} </span>
                        </div>
                    );
                },
                isPadded: true,
            });
        }

        render() {
            const { reviews, loading, className, onRiskClick, disableSelection } = this.props;
            const { contextualMenuProps } = this.state;

            return (
                <BoxPanel shadow>
                    <ShimmeredDetailsList
                        items={reviews}
                        compact={false}
                        columns={this._columns}
                        selectionMode={disableSelection ? SelectionMode.none : SelectionMode.none}
                        getKey={this._getKey}
                        setKey="single"
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.horizontalConstrained}
                        isHeaderVisible={true}
                        selection={this._selection}
                        selectionPreservedOnEmptyClick={true}
                        onItemInvoked={onRiskClick}
                        //onItemContextMenu={(item, index, ev) => this._onItemContextMenu(item, index, ev) }
                        enterModalSelectionOnTouch={true}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        checkButtonAriaLabel="Row checkbox"
                        enableShimmer={loading}
                        removeFadingOverlay={true}
                        shimmerLines={5}
                        ariaLabelForShimmer="Risk reviews being fetched"
                        ariaLabelForGrid="Risk reviews list"
                    />

                    {!loading && !reviews.length && <MessageBar>There are no matching reviews.</MessageBar>}

                    {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}
                </BoxPanel>
            );
        }

        _getKey(item, index) {
            return item ? item.id : index;
        }

        _getSelectionDetails() {
            const selectionCount = this._selection.getSelectedCount();

            switch (selectionCount) {
                case 0:
                    return "No items selected";
                case 1:
                    return "1 item selected: " + this._selection.getSelection()[0].name;
                default:
                    return `${selectionCount} items selected`;
            }
        }

        _onColumnClick(ev, column) {
            console.log(column);
        }

        _onItemContextMenu(item, index, ev) {
            const contextualMenuProps = {
                target: ev.target,
                items: [
                    {
                        key: "text",
                        name: `${this._selection.getSelectedCount()} selected`,
                    },
                ],
                onDismiss: () => {
                    this.setState({
                        contextualMenuProps: undefined,
                    });
                },
            };

            if (index > -1) {
                this.setState({
                    contextualMenuProps: contextualMenuProps,
                });
            }

            return false;
        }
    }
);
