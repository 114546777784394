import React, { useCallback, useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { useStores } from "@hooks/useStores";
import { UrlUtility } from "@lib/UrlUtility";

import { Text } from "@ui/elements/Text";
import { Stack } from "@ui/elements/Stack";
import { FontIcon } from "@ui/elements/Icon";
import { ActionButton, SpinnerButton, DefaultButton } from "@ui/elements/Button";
import { Spinner, SpinnerSize } from "@ui/elements/Spinner";
import { MessageBar, MessageBarType } from "@ui/elements/MessageBar";

import { AppPage } from "@modules/layout/containers/AppPage";
import { ActionPanel } from "@modules/base/components/ActionPanel";
import { RoutingButton } from "@modules/base/components/RoutingButton";

import { ProductCardSummary } from "@modules/products/components/core/ProductCardSummary";
import { WorkItemEditForm } from "@modules/workitems/components/core/WorkItemEditForm";

export const WorkItemRequestPage = withRouter(
    observer(({ match }) => {
        const { workItemStore } = useStores();
        const store = workItemStore.requestStore;

        const { loading, breadcrumb, formData, formOptions, isValid, processing, error } = store;

        const onSave = useCallback(() => {
            store.onSave({}, {});
        }, [store]);

        const onDismiss = useCallback(() => {
            store.onCancel();
        }, [store]);

        useEffect(() => {
            store.onInit({
                workItem: {
                    product: { id: match.params.id },
                },
            });
            return () => store.cleanup();
        }, [store, match]);

        return (
            <AppPage
                loading={loading}
                breadcrumb={breadcrumb}
                error={error}
                hasSidebar={false}
                className="has--page-nav has--page-nav-m page--nav-white"
            >
                <div className="sidebar sidebar--left sidebar--m">
                    <div className="toc__col no-print">
                        {formOptions && <ProductCardSummary className="pad" product={formOptions.product} />}
                    </div>
                </div>
                {formData && formOptions ? (
                    <div>
                        {formOptions.product.isRequestable ? (
                            <>
                                {formOptions.product.isInternal ? (
                                    <>
                                        <div>
                                            <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="center">
                                                <FontIcon iconName="Emoji" style={{ fontSize: 16 }} />
                                                <Text variant="medium" block>
                                                    Fill in the form below and save to create a new{" "}
                                                    <strong>{formOptions.product.name}</strong> request.
                                                </Text>
                                            </Stack>

                                            <WorkItemEditForm
                                                formData={formData}
                                                formOptions={formOptions}
                                                isValid={isValid}
                                            />
                                        </div>
                                        <hr />
                                        <ActionPanel>
                                            <ActionButton
                                                style={{ height: 32 }}
                                                onClick={onDismiss}
                                                text="Cancel"
                                                disabled={processing}
                                            />
                                            <SpinnerButton
                                                onClick={onSave}
                                                iconProps={{ iconName: "Save" }}
                                                text="Save"
                                                disabled={!isValid || processing}
                                                loading={processing}
                                            />
                                        </ActionPanel>
                                    </>
                                ) : (
                                    <>
                                        <MessageBar
                                            isMultiline={true}
                                            actions={
                                                <div>
                                                    {UrlUtility.isRelative(formOptions.product.requestUrl) ? (
                                                        <RoutingButton url={formOptions.product.requestUrl}>
                                                            Request this service
                                                        </RoutingButton>
                                                    ) : (
                                                        <DefaultButton
                                                            target="_blank"
                                                            href={formOptions.product.requestUrl}
                                                            title="Request"
                                                        >
                                                            Request this service
                                                        </DefaultButton>
                                                    )}
                                                </div>
                                            }
                                        >
                                            Demand type <strong>{formOptions.product.name}</strong> is managed outside
                                            the demand management service. You can still request this service by
                                            clicking on the <strong>Request this service</strong> button.
                                        </MessageBar>
                                    </>
                                )}
                            </>
                        ) : (
                            <MessageBar messageBarType={MessageBarType.warning}>
                                Demand type <strong>{formOptions.product.name}</strong> is not requestable.
                            </MessageBar>
                        )}
                    </div>
                ) : (
                    <Spinner label="Loading..." size={SpinnerSize.large} />
                )}
            </AppPage>
        );
    })
);
