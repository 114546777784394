import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { StatusBadge } from '@baseComponents/StatusBadge';

export const AdvisorySeverity = observer(
    class AdvisorySeverity extends Component {
        
        render () {
            const { advisory } = this.props;
            
            switch(advisory.level) {
                case 'Critical':
                  return <StatusBadge variant="red">{advisory.level}</StatusBadge>;
                case 'High':
                  return <StatusBadge variant="orange">{advisory.level}</StatusBadge>;
                case 'Medium':
                  return <StatusBadge variant="yellow">{advisory.level}</StatusBadge>;
                case 'Low':
                  return <StatusBadge variant="green">{advisory.level}</StatusBadge>;
                case 'Note':
                  return <StatusBadge variant="grey">{advisory.level}</StatusBadge>;
                default:
                  return <StatusBadge>{advisory.level}</StatusBadge>;
            }
        }
    }
);