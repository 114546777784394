import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { GroupDropDown } from '../../../identity/containers/pickers/GroupDropDown';
import { UserDropDown } from '../../../identity/containers/pickers/UserDropDown';

export const WorkItemAssignForm = observer(
    class WorkItemAssignForm extends Component {
        
        render () {
            const { formData, formOptions } = this.props;

            return (
                <div>
                    <div className="row--xs">
                        <r-grid columns="1">
                            <r-cell span="1">
                                <GroupDropDown
                                    placeholder="Please select a group"
                                    label="Assigned to group" 
                                    disabled={formOptions.disableGroup}
                                    required
                                    selected={formData.assignedToGroup}
                                    onChange={(group) => {
                                        formData.assignedToGroup = group;
                                        //formData.assignedToUser = null;
                                    }} />
                            </r-cell>
                            <r-cell span="1">
                                <UserDropDown
                                    placeholder="Please select a user"
                                    label="Assigned to user" 
                                    disabled={formOptions.disableUser}
                                    groupId={formData.assignedToGroup ? formData.assignedToGroup.id : null}
                                    required
                                    selected={formData.assignedToUser}
                                    onChange={(user) => {
                                        formData.assignedToUser = user;
                                    }} />
                            </r-cell>
                        </r-grid>
                    </div>
                </div>
            );
        }
    }
);