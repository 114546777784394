import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Facepile, OverflowButtonType } from 'office-ui-fabric-react/lib/Facepile';
import { PersonaSize } from 'office-ui-fabric-react/lib/Persona';

import { ObservableSeparator } from '../../base/components/ObservableSeparator';
import { PermissionSetGridView } from '../../securables/components/PermissionSetGridView';

export const SharingScopeListView = observer(
    class SharingScopeListView extends Component {
        
        render () {
            const { scopes, canShare, onShare, onRevoke } = this.props;
            return (
                <div>
                    { scopes.map((scope, index) => (
                        <div key={scope.id} className="row--s">
                            { index === 0 ? (
                                <Stack horizontal horizontalAlign="space-between">
                                    <Stack.Item grow>
                                        <ObservableSeparator>Direct access</ObservableSeparator>
                                    </Stack.Item>
                                    { canShare && canShare() && (
                                        <Stack.Item>
                                            <Facepile
                                                className="facepile"
                                                personas={[]}
                                                maxDisplayablePersonas={5}
                                                personaSize={PersonaSize.size24}
                                                overflowButtonType={OverflowButtonType.descriptive}
                                                showAddButton
                                                addButtonProps={{
                                                    ariaLabel: 'Share with',
                                                    onClick: () => onShare()
                                                }}
                                            />
                                        </Stack.Item>
                                    ) }                                            
                            </Stack>
                            ) : (
                                <ObservableSeparator>{`Inherted from : ${scope.label}`}</ObservableSeparator>
                            ) }
                            <PermissionSetGridView permissions={scope.permissions}
                                showAllRoles={index === 0}
                                isHeaderVisible={true}
                                canShare={() => index === 0 && canShare} 
                                onRevoke={(item) => onRevoke(item)} />
                        </div>
                    )) }
                </div>
            );
        }
    }
);
