import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';
import * as moment from 'moment';

import { Persona, PersonaSize } from 'office-ui-fabric-react/lib/Persona';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { ContextualMenu } from 'office-ui-fabric-react/lib/ContextualMenu';
import { DefaultButton, IconButton } from 'office-ui-fabric-react/lib/Button';
import { Card } from '@uifabric/react-cards';
import { FontWeights } from '@uifabric/styling';

import { PriorityScore } from './PriorityScore';
import { RoutingLink } from '../../../components/RoutingLink';
import { PhotoPersona } from '../../../../identity/containers/utilities/PhotoPersona';

const rootClassName = cx(
    'cygraph-RiskCardView-root',
    css` 
        max-width: 100%;
        min-width: 0px;
        margin-bottom: var(--spacing-s);
        background-color: var(--app-header-background);
        padding: 0 0 var(--spacing-xs) 0;
        user-select: none;

        & > .ms-CardItem {
            margin-top: 3px;
            padding: 0 var(--spacing-xs);
        }

        & > .headerItem {
            margin-top: 0;
            padding: var(--spacing-xs);
            background: #fbfbfb;
            border-bottom: 2px solid var(--border-color);
            background-color: var(--border-color);
        }

        & > .scoreItem {
            padding: var(--spacing-xs);
            border-bottom: 1px solid var(--body-background);
        }

        & > .footerItem {
            margin-top: 10px;
        }

        &.selected {
            & > .headerItem {
                background: #e7e4f1;
            }
        }
    `
);

const iconStyles = {
    root: {
        color: '#0078D4',
        fontSize: 16,
        fontWeight: FontWeights.regular,
        cursor: 'pointer',
    },
};

export class KCard extends Component {
        
    constructor(props) {
        super(props)
        this.state = {
            openMenu: null,
        }
    }

    onEditClick = () => {
        const { onEditClick, workItem } = this.props;
        if (onEditClick && workItem) {
            onEditClick(workItem)
        }
    }

    onDetailClick = () => {
        const { onClickDetail, workItem } = this.props;
        if (onClickDetail && workItem) {
            onClickDetail(workItem)
        }
    }

    onAssignClick = () => {
        const { onAssignClick, workItem } = this.props;
        if (onAssignClick && workItem) {
            onAssignClick(workItem)
        }
    }

    onToogleMenuClick = () => {
        this.setState({
            openMenu: !this.state.openMenu
        })
    }

    hideMenu = () => {
        this.setState({
            openMenu: false
        }) 
    }

    menuItems = [
        {
          key: 'details',
          text: 'Details',
          iconProps: {
            iconName: 'RedEye',
          },
          onClick: () => this.onDetailClick(),
        },
        {
            key: 'edit',
            text: 'Edit',
          iconProps: {
            iconName: 'Edit',
          },
          onClick: () => this.onEditClick(),
        },
        {
          key: 'assign',
          text: 'Assign',
          iconProps: {
            iconName: 'PeopleRepeat',
          },
          onClick: () => this.onAssignClick(),
        },
      ];

    render () {
        const { workItem, className, selected, provided, innerRef } = this.props;
        return (
            <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={innerRef}
        >
            <Card
                aria-label="WorkItem Card" 
                className={cx(className, rootClassName, selected && 'selected')}
                // onMouseLeave={this.hideMenu}
                >
                <Card.Item className="headerItem">
                    <div className="header">
                        <Stack horizontal tokens={{ childrenGap: 16 }}>
                            <Text variant="medium" className="code">
                                <RoutingLink url={`/demand/${workItem.id}`} onClick={(ev, url, routing) => {
                                    ev.stopPropagation();
                                    routing.push(url);
                                }}>
                                { workItem.code }
                                </RoutingLink>
                            </Text>
                            <Stack.Item grow={1}>
                                <span />
                            </Stack.Item>
                            <Text variant="medium" className="portfolio">{workItem.portfolio.name}</Text>
                        </Stack>
                    </div>
                </Card.Item>
                <Card.Item>
                    <Text className="title">{workItem.title}</Text>
                </Card.Item>
                <Card.Item className="scoreItem">
                    <Stack horizontal horizontalAlign="space-between">
                        <Text variant="small">
                            <PriorityScore 
                                maxImpact={3}
                                priority={workItem.priority}
                                size="mini"
                            />
                        </Text>
                    </Stack>
                </Card.Item>
                <Card.Item className="footerItem">
                    <div className="footer">
                        <Stack horizontal tokens={{ childrenGap: 16 }}>
                            <PhotoPersona
                                principalId={workItem.assignedToUser ? workItem.assignedToUser.id : null}
                                size={PersonaSize.size28}
                                text={workItem.assignedToUser ? workItem.assignedToUser.name : 'Unassigned'} 
                                showUnknownPersonaCoin={!workItem.assignedToUser}
                                showSecondaryText
                                secondaryText={workItem.assignedToGroup.name} />
                            <Stack.Item grow={1}>
                                <span />
                            </Stack.Item>
                                <IconButton
                                    styles={{ menuIcon: { fontSize: '18px' }}}
                                    menuIconProps={{ iconName: 'MoreVertical' }}
                                    menuProps={{items: this.menuItems}} />
                        </Stack>
                    </div>
                </Card.Item>
            </Card>
            </div>
        );
    }
};