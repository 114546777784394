import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router';

import { css } from '@uifabric/utilities/lib/css';
import { Text } from 'office-ui-fabric-react/lib/Text';

import { AppPage } from '@layout/containers/AppPage'; 
import { ObservablePagination } from '@baseComponents/ObservablePagination';
import { SettingsListNav } from '@modules/settings/containers/utilities/SettingsListNav';

import { ControlSetCardView } from '../../components/sets/ControlSetCardView';
import { ControlSetCommandBar } from '../../components/sets/ControlSetCommandBar';

const getQueryStringParams = query => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
};

export const ControlSetBrowsePage = inject('pageContext', 'controlStore', 'routing')(withRouter(observer(
    class ControlSetBrowsePage extends Component {

        componentWillMount() {
            const { match, routing, controlStore } = this.props;

            const params = getQueryStringParams(routing.location.search);
            controlStore.setBrowseStore.loadControlSets({ page: params["p"] || 1, keywords: params["k"] });
        }

        componentWillReceiveProps(nextProps) {
            const { match, routing, controlStore } = nextProps;

            const params = getQueryStringParams(routing.location.search);
            controlStore.setBrowseStore.loadControlSets({ page: params["p"] || 1, keywords: params["k"] });
        }

        render () {
            const { routing, controlStore } = this.props;
            const { loading, error, breadcrumb, keywords, pagination, sets } = controlStore.setBrowseStore;

            return (
                <AppPage 
                    loading={loading} 
                    error={error} 
                    breadcrumb={breadcrumb}
                    hasSidebar={false}
                    className={css('settings control-set list', !error && 'has--page-nav')}>
                    <SettingsListNav />
                    <div>
                        <Text className="title" variant="xLarge" block>
                            Control Sets
                        </Text>

                        <ControlSetCommandBar
                            className="row--l"
                            keywords={keywords}
                            onSearchChange={(keywords) => { 
                                if(keywords) {
                                    routing.push(`/settings/control-sets?k=${encodeURIComponent(keywords)}`);
                                } else {
                                    routing.push(`/settings/control-sets`);
                                };
                            }} />

                        <ControlSetCardView
                            sets={sets}
                            loading={loading}
                            onControlSetClick={(item) => routing.push(`/settings/control-sets/${item.id}`)}
                            query={{}} />

                        <ObservablePagination 
                            {...pagination}
                            onPageChange={(index) => { 
                                routing.push(`/settings/control-sets?p=${index + 1}${keywords ? `&k=${encodeURIComponent(keywords)}` : ''}`);
                            }} />
                            
                    </div> 
                </AppPage>
            );
        }

        _onImportControlSet = () => {

        }
    }
)));
