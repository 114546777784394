import React, { Component } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { Label } from "office-ui-fabric-react/lib/Label";
import { TagPicker, ValidationState } from "office-ui-fabric-react/lib/Pickers";

const rootClassName = cx(
    "cygraph-ControlPicker-container",
    css`
        & .ms-BasePicker-text {
            background-color: var(--white);
            min-height: 32px;
        }
        & .ms-Persona-coin {
            width: 20px;
        }
        & .ms-Persona-initials {
            display: none;
        }
        & .ms-Persona-presence {
            top: 6px;
            right: 0;
        }
    `
);

const calloutClassName = cx(
    "cygraph-ControlPicker-callout",
    css`
        & .ms-Persona {
            width: 300px;
        }
        & .ms-Persona-coin {
            width: 10px;
        }
        & .ms-Persona-initials {
            display: none;
        }
        & .ms-Persona-presence {
            top: -4px;
            right: 14px;
        }
    `
);

const personaClassName = cx(
    "cygraph-ControlPicker-persona",
    css`
        height: 20px;
        & .ms-Persona {
            width: 300px;
        }
        & .ms-Persona-coin {
            width: 10px;
        }
        & .ms-Persona-initials {
            display: none;
        }
        & .ms-Persona-presence {
            top: 7px;
            right: 18px;
        }
    `
);

export const ControlPicker = observer(
    class ControlPicker extends Component {
        render() {
            const {
                label,
                placeholder,
                readOnly,
                required,
                itemLimit,
                disabled,
                className,
                onSelected,
                onFilter,
                selected,
                multiple,
                loading,
            } = this.props;

            const suggestionProps = {
                suggestionsHeaderText: "Suggested controls",
                mostRecentlyUsedHeaderText: "Suggested controls",
                noResultsFoundText: "No controls found",
                loadingText: "Loading...",
                showRemoveButtons: false,
                suggestionsAvailableAlertText: "Control picker suggestions available",
                suggestionsContainerAriaLabel: "Suggested controls",
            };

            const items = multiple ? selected || [] : selected ? [selected] : [];

            return (
                <div className={cx(className, rootClassName)}>
                    <Label required={required} disabled={disabled}>
                        {label}
                    </Label>

                    <TagPicker
                        readOnly={readOnly}
                        required={required}
                        getTextFromItem={(item) => item.name}
                        selectedItems={items.map((s) => this._getOptionFromControl(s))}
                        onResolveSuggestions={(text, current, limit) => {
                            return onFilter(text, current, limit).then((result) => {
                                return result.map((s) => this._getOptionFromControl(s));
                            });
                        }}
                        //onEmptyInputFocus={returnMostRecentlyUsed}
                        pickerSuggestionsProps={suggestionProps}
                        className={"cygraph-ControlPicker"}
                        onValidateInput={this._validateInput}
                        removeButtonAriaLabel={"Remove"}
                        itemLimit={multiple ? itemLimit : 1}
                        inputProps={{
                            placeholder: placeholder,
                        }}
                        pickerCalloutProps={{
                            className: calloutClassName,
                        }}
                        onChange={(items) => {
                            if (onSelected) {
                                const current = items.map((i) => i.data);
                                onSelected(multiple ? current : current.length ? current[0] : null);
                            }
                            return items;
                        }}
                        resolveDelay={300}
                        disabled={disabled}
                    />
                </div>
            );
        }

        _getOptionFromControl(control) {
            return {
                key: control.id,
                name: control.name,
                data: control,
            };
        }

        _validateInput(input) {
            if (input.indexOf("@") !== -1) {
                return ValidationState.valid;
            } else if (input.length > 1) {
                return ValidationState.warning;
            } else {
                return ValidationState.invalid;
            }
        }
    }
);
