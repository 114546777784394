import React from "react";
import { observer } from "mobx-react";
import { Route, Switch } from "react-router";

import { IdentityGeneralTab } from "./IdentityGeneralTab";
import { IdentityUserListTab } from "./IdentityUserListTab";
import { IdentityGroupListTab } from "./IdentityGroupListTab";
import { ModuleAutomationsTab } from "./ModuleAutomationsTab";
import { IdentityRecycleBinTab } from "./IdentityRecycleBinTab";

export const IdentitySettingsTabList = observer(({}) => {
    return (
        <Switch>
            <Route
                exact
                path="/settings/identity/automations"
                component={() => (
                    <ModuleAutomationsTab
                        context={{ type: "module", id: "4338f066-8eee-4daa-b298-c7ad18e61317", label: "Demand" }}
                    />
                )}
            />
            <Route exact path="/settings/identity/users" component={() => <IdentityUserListTab />} />
            <Route exact path="/settings/identity/groups" component={() => <IdentityGroupListTab />} />
            <Route exact path="/settings/identity/recycle-bin" component={() => <IdentityRecycleBinTab />} />
            <Route path="/settings/identity" component={() => <IdentityGeneralTab />} />
        </Switch>
    );
});
