import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router';

import { ActivitiesFeed } from '../../../base/activitiesfeed/ActivitiesFeed';

export const RiskActivitiesTab = inject('riskStore')(withRouter(observer(
    class RiskActivitiesTab extends Component {

        componentWillMount() {
            // const { riskStore, match } = this.props;
            
            // if(match.params.id !=  treatmentStore.riskId) {
            //     treatmentStore.loadRiskTreatments(match.params.id).then((result) => {

            //     });
            // }
        }

        componentWillReceiveProps(nextProps) {
        }

        render () {

            return (
                <ActivitiesFeed
                    feedType="risk"
                    entityId={`13ff5177-1319-4542-a041-fe378b124030`}
                 />
            );
        }
    }
)));
