import React, { Component } from "react";
import { observer } from "mobx-react";

import { DatePicker } from "office-ui-fabric-react/lib/DatePicker";
import { Dropdown } from "office-ui-fabric-react/lib/Dropdown";
import { TextField } from "office-ui-fabric-react/lib/TextField";

import { RichTextField } from "../../../editors/components/RichTextField";
import { ControlPicker } from "../../../controls/containers/pickers/ControlPicker";
import { ControlHierarchyPicker } from "../../../controls/containers/pickers/ControlHierarchyPicker";

import { RiskCategoryPicker } from "../../containers/pickers/RiskCategoryPicker";
import { RiskScoreTooltip } from "./RiskScoreTooltip";
import { RiskScoreField } from "./RiskScoreField";

export const ReviewEditForm = observer(
    class ReviewEditForm extends Component {
        render() {
            const { formData, formOptions } = this.props;
            const isExceptionTemplate = formOptions.typeTemplate === "zerodai:risks:exception";
            const isControlException = isExceptionTemplate && formOptions.typeName === "Control Exception";

            return (
                <div>
                    <r-grid columns-s="1" columns="6">
                        {isExceptionTemplate ? null : (
                            <>
                                <r-cell span="2">
                                    <div>
                                        <RiskScoreField
                                            required
                                            label="Gross Risk"
                                            score={formData.grossScore}
                                            onChange={(score) => {
                                                formData.grossScore = Object.assign({}, formData.grossScore, score);
                                            }}
                                        />
                                    </div>
                                </r-cell>
                                <r-cell span="2">
                                    <div style={{ paddingTop: "28px" }}>
                                        <RiskScoreTooltip tooltip={formOptions.scoreTooltip} />
                                    </div>
                                </r-cell>
                                <r-cell span="6">
                                    <div>
                                        <RichTextField
                                            label="Gross Risk Justification"
                                            required
                                            placeholder="Please enter gross risk justification"
                                            value={formData.grossScore.description}
                                            onChange={(val) => {
                                                formData.grossScore.description = val;
                                            }}
                                        />
                                    </div>
                                </r-cell>
                                <r-cell span="6">
                                    <div>
                                        <RichTextField
                                            label="Risk Treatment"
                                            required
                                            placeholder="Please enter description of risk treatment"
                                            value={
                                                formData.treatments && formData.treatments.length
                                                    ? formData.treatments[0].description
                                                    : null
                                            }
                                            onChange={(val) => {
                                                formData.treatments = [
                                                    {
                                                        type: "Mitigate",
                                                        description: val,
                                                    },
                                                ];
                                            }}
                                        />
                                    </div>
                                </r-cell>
                            </>
                        )}
                        <r-cell span="2">
                            <div>
                                <RiskScoreField
                                    required
                                    label="Net Risk"
                                    score={formData.netScore}
                                    onChange={(score) => {
                                        formData.netScore = Object.assign({}, formData.netScore, score);
                                        if (isExceptionTemplate) {
                                            formData.grossScore = Object.assign({}, formData.grossScore, score);
                                        }
                                    }}
                                />
                            </div>
                        </r-cell>
                        <r-cell span="2">
                            <div style={{ paddingTop: "28px" }}>
                                <RiskScoreTooltip tooltip={formOptions.scoreTooltip} />
                            </div>
                        </r-cell>
                        <r-cell span="6">
                            <div>
                                <RichTextField
                                    label="Net Risk Justification"
                                    required
                                    placeholder="Please enter net risk justification"
                                    value={formData.netScore.description}
                                    onChange={(val) => {
                                        formData.netScore.description = val;
                                        if (isExceptionTemplate) {
                                            formData.grossScore.description = val;
                                        }
                                    }}
                                />
                            </div>
                        </r-cell>
                        {isControlException && (
                            <r-cell span="6">
                                <RiskCategoryPicker
                                    placeholder="Please select a risk event type"
                                    label="Risk Event Type"
                                    required
                                    selected={formData.category}
                                    onChange={(category) => {
                                        formData.category = category;
                                    }}
                                />
                            </r-cell>
                        )}

                        {isControlException && (
                            <r-cell span="6">
                                <ControlHierarchyPicker
                                    label="Controls"
                                    required
                                    selected={formData.controls}
                                    onControlPicked={(controls) => {
                                        formData.controls = [controls];
                                    }}
                                />
                                {/* <ControlPicker
                                label="Controls"
                                multiple
                                placeholder="Please select any related controls"
                                selected={formData.controls}
                                onChange={(controls) => {
                                    formData.controls = controls;
                                }}
                            /> */}
                            </r-cell>
                        )}
                        {isControlException && (
                            <r-cell span="6">
                                <TextField
                                    label="Related Policies / Standards"
                                    multiline
                                    value={formData.policyDescription}
                                    onChange={(ev, val) => {
                                        formData.policyDescription = val;
                                    }}
                                />
                            </r-cell>
                        )}

                        {isControlException && (
                            <r-cell span="2">
                                <Dropdown
                                    label="Business Risk Appetite"
                                    autoComplete="on"
                                    required
                                    selectedKey={formData.businessAppetite}
                                    onChange={(ev, op) => (formData.businessAppetite = op.key)}
                                    options={["Minor", "Moderate", "Major", "Critical"].map((p) => ({
                                        key: p,
                                        text: p,
                                    }))}
                                />
                            </r-cell>
                        )}

                        <r-cell span="6"></r-cell>
                        <r-cell span="2">
                            <div>
                                <DatePicker
                                    label="Expiry Date"
                                    isRequired={true}
                                    placeholder="Select expiry date"
                                    ariaLabel="Select a date of expiry"
                                    value={formData.nextReviewDue}
                                    onSelectDate={(val) => {
                                        formData.nextReviewDue = val;
                                    }}
                                />
                            </div>
                        </r-cell>
                    </r-grid>
                </div>
            );
        }
    }
);
