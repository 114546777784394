import React from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { List } from "office-ui-fabric-react/lib/List";
import { MessageBar } from "office-ui-fabric-react/lib/MessageBar";

import { BigTextField } from "@modules/base/components/BigTextField";
import { ObservableSeparator } from "@modules/base/components/ObservableSeparator";
import { MarkdownField } from "@modules/editors/components/MarkdownField";

import { Toggle } from "@ui/elements/Toggle";
import { Dropdown } from "@ui/elements/Dropdown";
import { Stack } from "@ui/elements/Stack";
import { ActionButton, CommandButton } from "@ui/elements/Button";
import { Text } from "@ui/elements/Text";

const triggersClassName = cx(
    "cygraph-StateFlowStateEditForm-triggers",
    css`
        & .trigger {
            padding: var(--spacing-xxxs);
            margin-bottom: var(--spacing-xxxxxs);
            background-color: var(--white);
            border-radius: var(--border-radius);
            border: 1px solid var(--border-color);
            border-left: 5px solid var(--text-color-dark);

            &.primary {
                border-left: 5px solid var(--nav-color-accent);
            }

            & .tags span {
                display: inline-block;
                padding: var(--spacing-xxxxxs) var(--spacing-xxxs);
                margin-left: var(--spacing-xxxxxs);
                border-radius: var(--border-radius);
                background-color: var(--background-color);
                font-size: var(--font-size-xxxs);
            }
        }
    `
);

export const StateFlowStateEditForm = observer(({ formData, formOptions, onEditTrigger, onAddTrigger }) => {
    return (
        <div>
            <r-grid columns="2">
                <r-cell span="2">
                    <BigTextField
                        label="Name"
                        required
                        placeholder="Please enter a name for the state"
                        transparent
                        value={formData.name}
                        onChange={(ev, val) => {
                            formData.name = val;
                        }}
                    />
                </r-cell>
                <r-cell>
                    <Dropdown
                        label="Inherit from state"
                        autoComplete="on"
                        selectedKey={formData.mappedTo}
                        disabled={!formOptions.mappedToEditable}
                        onChange={(ev, op) => {
                            formData.mappedTo = op.key;
                            if (formData.mappedTo === "Blocked") {
                                formData.blocked = true;
                            } else {
                                formData.blocked = undefined;
                            }
                        }}
                        options={formOptions.states.map((s) => ({ key: s.id, text: s.name })) || []}
                    />
                </r-cell>
                <r-cell span="2">
                    <MarkdownField
                        label="Description"
                        value={formData.description || ""}
                        onChange={(val) => {
                            formData.description = val;
                        }}
                    />
                </r-cell>
                <r-cell span="2">
                    <Toggle
                        label="Enable editing properties in this state?"
                        checked={formData.location === "Overflow"}
                        onChange={(ev, val) => {
                            formData.location = val ? "Overflow" : "Default";
                        }}
                        onText="Yes"
                        offText="No"
                    />
                </r-cell>
                <r-cell span="2">
                    <Toggle
                        label="Enable cancel from this state?"
                        checked={formData.location === "Overflow"}
                        onChange={(ev, val) => {
                            formData.location = val ? "Overflow" : "Default";
                        }}
                        onText="Yes"
                        offText="No"
                    />
                </r-cell>
                {formOptions.enableTrigger && (
                    <>
                        <r-cell span="2">
                            <ObservableSeparator
                                className="row--xxs"
                                onRenderSuffix={() => (
                                    <CommandButton
                                        iconProps={{ iconName: "Add" }}
                                        text="Add Trigger"
                                        disabled={!onAddTrigger}
                                        onClick={() => onAddTrigger({ source: { id: formData.id } })}
                                    />
                                )}
                            >
                                Triggers
                            </ObservableSeparator>
                        </r-cell>
                        <r-cell span="2">
                            <div className={triggersClassName}>
                                {(!formData.triggers || formData.triggers.length === 0) && (
                                    <MessageBar>There are no matching triggers.</MessageBar>
                                )}
                                {formData.triggers && (
                                    <DragDropContext
                                        onDragEnd={(result) => {
                                            if (
                                                !result.destination ||
                                                result.reason !== "DROP" ||
                                                result.source.droppableId !== result.destination.droppableId
                                            ) {
                                                return;
                                            }

                                            const items = Array.from(formData.triggers);
                                            const [reorderedItem] = items.splice(result.source.index, 1);
                                            items.splice(result.destination.index, 0, reorderedItem);
                                            formData.triggers = items;
                                        }}
                                    >
                                        <Droppable droppableId="triggers">
                                            {(provided, snapshot) => (
                                                <div
                                                    className={cx(
                                                        "droppable",
                                                        snapshot.isDraggingOver && "dragging-over",
                                                        snapshot.draggingFromThisWith && "dragging-from"
                                                    )}
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    <List
                                                        items={formData.triggers}
                                                        onRenderCell={(trigger, index, isScrolling) => {
                                                            return (
                                                                <div key={trigger.id}>
                                                                    <Draggable draggableId={trigger.id} index={index}>
                                                                        {(provided, snapshot) => (
                                                                            <div
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                ref={provided.innerRef}
                                                                            >
                                                                                <Stack
                                                                                    className={cx(
                                                                                        "trigger",
                                                                                        trigger.variant === "Primary" &&
                                                                                            "primary"
                                                                                    )}
                                                                                    horizontal
                                                                                    horizontalAlign="space-between"
                                                                                    verticalAlign="center"
                                                                                    verticalFill
                                                                                >
                                                                                    <Text>{trigger.name}</Text>
                                                                                    <Stack
                                                                                        horizontal
                                                                                        verticalAlign="center"
                                                                                        verticalFill
                                                                                    >
                                                                                        <div className="tags">
                                                                                            {trigger.command &&
                                                                                                trigger.command.to && (
                                                                                                    <span>
                                                                                                        State Transition
                                                                                                    </span>
                                                                                                )}
                                                                                            {trigger.iconProps &&
                                                                                                trigger.iconProps
                                                                                                    .iconOnly && (
                                                                                                    <span>
                                                                                                        Icon Only
                                                                                                    </span>
                                                                                                )}
                                                                                            {trigger.location ===
                                                                                                "Overflow" && (
                                                                                                <span>Overflow</span>
                                                                                            )}
                                                                                        </div>
                                                                                        <ActionButton
                                                                                            iconProps={{
                                                                                                iconName: "Edit",
                                                                                            }}
                                                                                            style={{ height: 24 }}
                                                                                            disabled={!onEditTrigger}
                                                                                            onClick={() =>
                                                                                                onEditTrigger({
                                                                                                    trigger,
                                                                                                    state: {
                                                                                                        id: formData.id,
                                                                                                    },
                                                                                                })
                                                                                            }
                                                                                        ></ActionButton>
                                                                                        <ActionButton
                                                                                            iconProps={{
                                                                                                iconName: "Delete",
                                                                                                style: {
                                                                                                    color: "var(--red-10)",
                                                                                                },
                                                                                            }}
                                                                                            style={{ height: 24 }}
                                                                                            onClick={() =>
                                                                                                (formData.triggers =
                                                                                                    formData.triggers.filter(
                                                                                                        (t) =>
                                                                                                            t.id !==
                                                                                                            trigger.id
                                                                                                    ))
                                                                                            }
                                                                                        ></ActionButton>
                                                                                    </Stack>
                                                                                </Stack>
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                </div>
                                                            );
                                                        }}
                                                    />
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                )}
                            </div>
                        </r-cell>
                    </>
                )}
            </r-grid>
        </div>
    );
});
