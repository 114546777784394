import React from "react";
import { observer } from "mobx-react";

import { Label } from "office-ui-fabric-react/lib/Label";
import { PersonaSize } from "office-ui-fabric-react/lib/Persona";

import { UserHoverCard } from "@modules/identity/containers/utilities/UserHoverCard";
import { PhotoPersona } from "@modules/identity/containers/utilities/PhotoPersona";

export const PortfolioContactList = observer(({ portfolio, groups }) => {
    const renderContact = (item) => {
        return (
            <div className="row--s" key={item.label}>
                <Label>{item.label}</Label>
                {item.contact ? (
                    <UserHoverCard principal={item.contact}>
                        <PhotoPersona
                            principalId={item.contact ? item.contact.id : null}
                            size={PersonaSize.size32}
                            text={item.contact.name}
                            showSecondaryText={true}
                            secondaryText={item.contact.jobTitle}
                            tertiaryText={item.contact.email}
                        />
                    </UserHoverCard>
                ) : (
                    "Not Set"
                )}
            </div>
        );
    };

    return (
        <div>
            {portfolio && (
                <div>
                    {(!groups || groups.indexOf("portfolio") !== -1) && (
                        <r-grid columns="2">
                            <r-cell span="1">
                                {renderContact({
                                    label: "Portfolio Lead",
                                    contact: portfolio.portfolioLead,
                                })}
                            </r-cell>
                            <r-cell span="1">
                                {renderContact({
                                    label: "Portfolio Champion",
                                    contact: portfolio.portfolioChampion,
                                })}
                            </r-cell>
                        </r-grid>
                    )}
                    {(!groups || groups.indexOf("stakeholders") !== -1) && (
                        <>
                            <r-grid columns="2">
                                <r-cell span="1">
                                    {renderContact({
                                        label: "Assurance Primary",
                                        contact: portfolio.assurancePrimary,
                                    })}
                                </r-cell>
                                <r-cell span="1">
                                    {renderContact({
                                        label: "Assurance Secondary",
                                        contact: portfolio.assuranceSecondary,
                                    })}
                                </r-cell>
                            </r-grid>
                            <r-grid columns="2">
                                <r-cell span="1">
                                    {renderContact({
                                        label: "Data Privacy Lead",
                                        contact: portfolio.dataPrivacyLead,
                                    })}
                                </r-cell>
                            </r-grid>
                            <r-grid columns="2">
                                <r-cell span="1">
                                    {renderContact({
                                        label: "Security Architect",
                                        contact: portfolio.securityArchitect,
                                    })}
                                </r-cell>
                            </r-grid>
                            <r-grid columns="2">
                                <r-cell span="1">
                                    {renderContact({
                                        label: "Risk Manager",
                                        contact: portfolio.riskManager,
                                    })}
                                </r-cell>
                            </r-grid>
                        </>
                    )}
                </div>
            )}
        </div>
    );
});
