import React from "react";
import { observer } from "mobx-react";

import { Stack } from "@ui/elements/Stack";
import { Text } from "@ui/elements/Text";
import { FontIcon } from "@ui/elements/Icon";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { RoutingLink } from "@modules/base/components/RoutingLink";

export const KeyStatisticItemView = observer(({ title, iconName, color, value, children, url }) => {
    return (
        <BoxPanel shadow>
            <div style={{ padding: "10px 25px" }}>
                <Stack tokens={{ childrenGap: 15 }} horizontal verticalFill verticalAlign="center">
                    <Stack.Item>
                        <FontIcon iconName={iconName} style={{ color, fontSize: 40 }} />
                    </Stack.Item>
                    <Stack tokens={{ childrenGap: 0 }} verticalFill verticalAlign="center">
                        <Text variant="medium" block style={{ color: "var(--text-color-lighter)" }}>
                            {title}
                        </Text>
                        {children ? (
                            <Text variant="medium">{children}</Text>
                        ) : (
                            <Text variant="mediumPlus" block>
                                {value}
                            </Text>
                        )}
                    </Stack>
                </Stack>
            </div>
            <div style={{ padding: "7px 25px", backgroundColor: "var(--background-color)" }}>
                <Text variant="small" style={{ color: "var(--text-color-lightest)" }}>
                    <RoutingLink url={url}>View all</RoutingLink>
                </Text>
            </div>
        </BoxPanel>
    );
});
