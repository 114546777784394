import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { css } from "emotion";

import { Stack } from "office-ui-fabric-react/lib/Stack";
import { ComboBox } from "office-ui-fabric-react/lib/ComboBox";
import { Label } from "office-ui-fabric-react/lib/Label";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";
import { Persona, PersonaSize } from "office-ui-fabric-react/lib/Persona";
import { getId } from "@uifabric/utilities/lib/getId";

import { UserPickerStore } from "../../stores/UserPickerStore";

const personaClassName = css`
    padding: var(--spacing-xxxxs) var(--spacing-xxxxs);
`;

export const UserDropDown = inject("principalStore")(
    observer(
        class UserDropDown extends Component {
            constructor(props) {
                super(props);

                this.state = {
                    pickerStore: new UserPickerStore(this.props.principalStore),
                };
            }

            componentWillMount() {
                const { pickerStore } = this.state;
                const { multiple, selected, selectedId, selectedIds, groupId } = this.props;

                if (
                    !pickerStore.loading &&
                    groupId &&
                    (pickerStore.users.length == 0 || groupId !== pickerStore.groupId)
                ) {
                    pickerStore.loadGroupUsers({ groupId });
                }

                if (typeof selected !== "undefined") {
                    pickerStore.setSelected(multiple ? selected || [] : selected ? [selected] : []);
                } else if (!multiple) {
                    pickerStore.setSelectedWithId(selectedId);
                } else {
                    pickerStore.setSelectedWithIds(selectedIds);
                }
            }

            componentWillReceiveProps(nextProps) {
                const { pickerStore } = this.state;
                const { multiple, selected, selectedId, selectedIds, groupId } = nextProps;

                if (
                    !pickerStore.loading &&
                    groupId &&
                    (pickerStore.users.length == 0 || groupId !== pickerStore.groupId)
                ) {
                    pickerStore.loadGroupUsers({ groupId });
                }

                if (typeof selected !== "undefined") {
                    pickerStore.setSelected(multiple ? selected || [] : selected ? [selected] : []);
                } else if (!multiple) {
                    pickerStore.setSelectedWithId(selectedId);
                } else {
                    pickerStore.setSelectedWithIds(selectedIds);
                }
            }

            render() {
                const { pickerStore } = this.state;
                const { label, required, placeholder, multiple, disabled, className, onChange, except } = this.props;
                const { users, otherUsers, selectedUserIds, loading } = pickerStore;

                const selected = multiple ? selectedUserIds : selectedUserIds.length ? selectedUserIds[0] : null;

                const inGroup = users.filter((r) => (except || []).indexOf(r.id) === -1);
                const others = otherUsers.filter((u) => users.findIndex((x) => x.id == u.id) === -1);

                const options = [];
                if (inGroup.length > 0 && others.length > 0) {
                    options.push({ key: "in-group", text: "Users in group", itemType: 2 });
                }
                inGroup.forEach((user) => {
                    options.push({ key: user.id, text: user.name, data: user });
                });
                if (others.length > 0) {
                    options.push({ key: "not-in-group", text: "Users not in group", itemType: 2 });
                }
                others.forEach((user) => {
                    options.push({ key: user.id, text: user.name, data: user });
                });

                const pickerId = getId("userDropDown");

                return (
                    <div className={className}>
                        <Stack horizontal={true} verticalAlign={"center"}>
                            <Label required={required} disabled={disabled} htmlFor={pickerId}>
                                {label}
                            </Label>
                            {loading && <Spinner size={SpinnerSize.xSmall} />}
                        </Stack>
                        <ComboBox
                            id={pickerId}
                            placeholder={placeholder}
                            disabled={disabled}
                            required={required}
                            multiSelect={multiple}
                            selectedKey={selected}
                            allowFreeform={true}
                            useComboBoxAsMenuWidth={true}
                            autoComplete="on"
                            onRenderOption={(option) =>
                                !option.data ? (
                                    option.text
                                ) : (
                                    <div className={personaClassName}>
                                        <Persona
                                            size={PersonaSize.size28}
                                            text={option.data.name}
                                            secondaryText={option.data.jobTitle}
                                            showSecondaryText={true}
                                        />
                                    </div>
                                )
                            }
                            onRenderTitle={(options) => {
                                return options.map((option) => (
                                    <Persona key={option.key} size={PersonaSize.size28} text={option.data.name} />
                                ));
                            }}
                            options={options}
                            onChange={(event, option, index, value) => {
                                if (multiple) {
                                    if (option) {
                                        const newSelected = [...selectedUserIds];
                                        const index = newSelected.indexOf(option.key);
                                        if (index == -1) {
                                            newSelected.push(option.key);
                                        } else {
                                            newSelected.splice(index, 1);
                                        }
                                        pickerStore.setSelectedWithIds(newSelected);

                                        if (onChange) {
                                            onChange(users.filter((r) => newSelected.indexOf(r.id) != -1));
                                        }
                                    }
                                } else {
                                    pickerStore.setSelectedWithId(option ? option.key : null);
                                    if (onChange) {
                                        onChange(option ? option.data : null);
                                    }
                                }
                            }}
                        />
                    </div>
                );
            }
        }
    )
);
