import React from "react";
import { observer } from "mobx-react";

import { CommandBar } from "@ui/elements/CommandBar";

import { BoxPanel } from "@modules/base/components/BoxPanel";

import { EntityFilterBar } from "@modules/entities/components/core/EntityFilterBar";

export const EntityCommandBar = observer(
    ({ hideAgentActions, filterQuery, filterContext, onFilterChange, onNewEntity, onRefresh }) => {
        const items = [];

        if (!hideAgentActions) {
            if (onNewEntity) {
                items.push({
                    key: "newEntity",
                    text: "New Entity",
                    iconProps: { iconName: "Add" },
                    onClick: onNewEntity,
                });
            }

            if (onRefresh) {
                items.push({
                    key: "refresh",
                    text: "Refresh",
                    iconProps: { iconName: "Refresh" },
                    onClick: onRefresh,
                });
            }
        }

        const farItems = [];

        if (filterQuery && onFilterChange && filterContext) {
            farItems.push({
                key: "filterBar",
                text: "Filter Bar",
                onRender: () => {
                    return <EntityFilterBar value={filterQuery} onChange={onFilterChange} context={filterContext} />;
                },
            });
        }

        return (
            <BoxPanel shadow>
                <CommandBar
                    styles={{
                        root: {
                            /* paddingRight: "0px" */
                        },
                    }}
                    items={items}
                    farItems={farItems}
                />
            </BoxPanel>
        );
    }
);
