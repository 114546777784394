import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";
import { cx, css } from "emotion";

import { Stack } from "@ui/elements/Stack";

import { ModuleNames, isModuleEnabled } from "../../../configProvider";
import { isUserInAllRoles } from "@modules/identity/stores/PrincipalContextUtilities";
import { ObservableSeparator } from "@modules/base/components/ObservableSeparator";

import { CombinedSearchListView } from "@modules/home/components/CombinedSearchListView";
import { runRecentSearch } from "./recentActivities";

const rootClassName = cx(
    "cygraph-RecentActivitiesView-root",
    "card",
    css`
        padding: 15px 25px 20px !important;
    `
);

export const RecentActivitiesView = observer(({ className }) => {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);

    const { principalContext, workItemStore, assessmentStore, riskStore } = useStores();
    const user = principalContext.current;

    const showRisks = isModuleEnabled(ModuleNames.Risks) && isUserInAllRoles(user, ["admin", "agent"]);

    useEffect(() => {
        setLoading(true);
        if (user) {
            runRecentSearch({ showRisks, user, workItemStore, assessmentStore, riskStore }).then((result) => {
                setItems(result);
                setLoading(false);
            });
        }
    }, [user]);

    return (
        <Stack className={cx(rootClassName, className)} tokens={{ childrenGap: 20 }}>
            <ObservableSeparator className="row--xxs">Recent Activities</ObservableSeparator>
            <CombinedSearchListView loading={!user || loading} items={items} show={5} includeRole={true} />
        </Stack>
    );
});
