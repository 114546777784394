import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { cx, css } from 'emotion';
import { withRouter } from 'react-router';
import { Stack } from 'office-ui-fabric-react/lib/Stack';

import { AppPage } from '@layout/containers/AppPage';
import { SettingsListNav } from '@modules/settings/containers/utilities/SettingsListNav';
import { DataSourceGridView } from '../../components/DataSourceGridView';

export const DataSourceBrowsePage = inject('pageContext', 'dataSourceStore', 'routing')(withRouter(observer(
    class DataSourceBrowsePage extends Component {

        constructor(props) {
            super(props);
            this.state = {};
        }

        componentWillMount() {

        }

        componentWillReceiveProps(nextProps) {

        }

        render () {
            const { routing, dataSourceStore } = this.props;
            const { browseStore, lifecycleStore } = dataSourceStore;
            const { breadcrumb } = browseStore;

            return (
                <AppPage 
                    loading={false}
                    breadcrumb={breadcrumb}
                    // error={error}
                    hasSidebar={false}
                    className="list has--page-nav">

                        <SettingsListNav />
                        <DataSourceGridView 
                            dataSources={browseStore.dataSources}
                        />
                    
                </AppPage>
            );
        }
    }
)));
