import React from "react";
import { observer } from "mobx-react";

import { FilterBar } from "@modules/base/components/FilterBar";

export const ExceptionFilterBar = observer(({ value, onChange, context, disabled }) => {
    const filters = [
        {
            name: "keywords",
            label: "Keywords",
            type: "Search",
            keywords: true,
            placeholder: "Search by unique id or title",
        },
        // {
        //     name: "registry",
        //     label: "Registry",
        //     type: "Registry",
        //     multiple: true,
        //     allowClear: true,
        //     query: {},
        // },
        {
            name: "portfolio",
            label: "Portfolio",
            type: "Portfolio",
            multiple: true,
            allowClear: true,
            query: {},
        },
        {
            name: "status",
            label: "Status",
            type: "Dropdown",
            multiple: true,
            allowClear: true,
            options: [
                { key: "Draft", text: "Draft" },
                { key: "Submitted", text: "Submitted" },
                { key: "Monitoring", text: "Monitoring" },
                { key: "Closed", text: "Closed" },
                { key: "Cancelled", text: "Cancelled" },
                { key: "Transferred", text: "Transferred" },
            ],
        },
        {
            name: "age",
            label: "Age",
            description: "Additionally filters out demand requests which have not started or has been closed",
            type: "DurationRange",
            allowClear: true,
            moreThanProps: {
                time: false,
                allowClear: true,
            },
            lessThanProps: {
                time: false,
                allowClear: true,
            },
        },
        {
            name: "assignedToUser",
            label: "Assigned To",
            type: "Principal",
            multiple: true,
            allowClear: true,
        },
        {
            name: "netScore",
            label: "Net Score",
            type: "Dropdown",
            multiple: true,
            allowClear: true,
            options: [
                { key: "None", text: "None" },
                { key: "Minor", text: "Minor" },
                { key: "Moderate", text: "Moderate" },
                { key: "Major", text: "Major" },
                { key: "Critical", text: "Critical" },
            ],
        },
    ];
    return <FilterBar value={value} onChange={onChange} context={context} filters={filters} disabled={disabled} />;
});
