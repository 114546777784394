import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { cx, css } from 'emotion';

import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { Callout, DirectionalHint } from 'office-ui-fabric-react/lib/Callout';
import { FocusTrapZone } from 'office-ui-fabric-react/lib/FocusTrapZone';
import { CompoundButton } from 'office-ui-fabric-react/lib/Button';

const calloutClassName = cx(
    'cygraph-PagePartPicker-callout',
    css`
      & .root {
        width: 320.5px;
        height: 303px;
        overflow: hidden auto;      
        padding: var(--spacing-xxxs);
        padding-bottom: 0;
      }
      & r-grid {
        grid-column-gap: var(--spacing-xxxs);
        grid-row-gap: var(--spacing-xxxs);
      }
      & .part {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        & .button {
            display: flex;
            flex-direction: column;
            text-align: center;
            align-items: center;
            width: 93px;
            & span {
                display: block;
            }
            & i {
                width: 82px;
                height: 28px;
                min-height: 28px;
                font-size: 28px;
                line-height: 28px;
            }
        }
        & .part-name {
            font-size: 12px;
            max-width: 100%;
            color: var(--text-color);
            font-weight: 400;
            line-height: 17px;
            height: 25px;
        }
      }
    `
  );

export const PagePartPicker = inject('pageStore')(observer(
  class PagePartPicker extends Component {
    render () {
        const partPickerStore = this.props.instance.partPickerStore;

        return (
          <>
            { partPickerStore.visible && (
                <Callout
                    isBeakVisible={true}
                    className={calloutClassName}
                    gapSpace={0}
                    doNotLayer={false}
                    target={partPickerStore.targetElement}
                    directionalHint={DirectionalHint.bottomCenter}
                    onDismiss={() => partPickerStore.hide({}) }
                    setInitialFocus={true}>                    
                    <FocusTrapZone firstFocusableSelector="root" isClickableOutsideFocusTrap={true}>
                        <div className="root scrollbar--root">
                            <r-grid columns="3">                            
                                {partPickerStore.all.map(registration => (
                                    <r-cell key={`${registration.type}@${registration.version}`}>
                                    <span className="part">
                                        <CompoundButton className="button" title={registration.description}
                                            onClick={() => {
                                                partPickerStore.hide({ registration });
                                            }}>
                                            <span><Icon iconName={registration.icon} /></span>
                                            <span className="part-name">{registration.name}</span>
                                        </CompoundButton>
                                    </span>
                                    </r-cell>
                                ))}                            
                            </r-grid>                        
                        </div>
                    </FocusTrapZone>
                </Callout>)
            }
          </>
        );
    }
  }
));