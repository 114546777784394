import { observable, flow, computed, action } from "mobx";

import { validateTargetData } from "../../properties/stores/PropertyValidators";

import { AssetService } from "../../../api/assets";
import { AssetStore } from "./AssetStore";

export class AssetNewStore {
    public parentStore: AssetStore;
    public assetService: AssetService;

    @observable public visible: boolean = false;
    @observable public formData: any;
    @observable public originalFormData: any;
    @observable public formOptions: any;
    @observable public saving: boolean = false;
    @observable public isCloseDialogOpen: boolean = false;
    @observable public controls: any[];
    @observable public messages: any;

    constructor(parentStore: AssetStore) {
        this.parentStore = parentStore;
        this.assetService = parentStore.assetService;
    }

    @computed
    public get isValid() {
        if (!this.formOptions || !this.formOptions.properties) {
            return false;
        }
        const result = validateTargetData(this.formOptions.properties, this.formData);
        this.messages = result.messages;

        return result.isValid;
    }

    @computed
    public get isDirty() {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
    }

    @action
    public resetFormData() {
        this.formData = JSON.parse(JSON.stringify(this.originalFormData));
    }

    public show = flow(function* (options) {
        this.visible = true;
        const propertyService = this.parentStore.rootStore.propertyStore.propertyService;

        const properties = yield propertyService.searchProperties({
            pageSize: 250,
            startIndex: 0,
            query: { keywords: null, target: ["Asset"], readOnly: false, activeOnly: true },
        });

        const sections = yield propertyService.searchPropertySections({
            pageSize: 250,
            startIndex: 0,
            query: { keywords: null, target: ["Asset"], activeOnly: true },
        });

        this.formOptions = { properties: properties.items, sections: sections.items };
        this.formData = JSON.parse(JSON.stringify(options.asset || {}));
        this.originalFormData = JSON.parse(JSON.stringify(options.asset || {}));
    });

    @action
    public hide(options) {
        this.visible = false;
        this.formData = null;
        this.formOptions = null;
    }

    public createAsset = flow(function* () {
        this.saving = true;
        this.error = null;

        try {
            if (this.isValid) {
                const result = yield this.assetService.createAsset(this.formData);
                //              this.parentStore.mapBrowseStore.replace(result);
                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Asset ${result.name} created successfully`
                );
                return result;
            }
        } catch (e) {
            console.error(e);
            this.error = e;
        } finally {
            this.saving = false;
        }
    });
}
