import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';

import { BoxPanel } from '../../../base/components/BoxPanel';

import { TriageWizardLayout } from '../layouts/TriageWizardLayout';
import { TriageWizardIndicator } from './TriageWizardIndicator';
import { TriageWizardActions } from './TriageWizardActions';
import { TriageFormHeader } from './TriageFormHeader';

const rootClassName = cx(
    'cygraph-TriageFormViewer-root',
        css`
        max-width: 1000px;
        margin: 3vh auto;
        
        & .ms-Label {
            font-weight: normal;
            font-size: var(--font-size-xxs);
        }
        & .ms-TextField-errorMessage,
        & .errorMessage {
            color: var(--red-13);
            font-size: var(--font-size-xxs);
        }
        & .ms-Link {
            font-size: var(--font-size-xxs);
        }

        & .actions button {
            height: 20px;
            padding-left: var(--spacing-xxs);
            color: var(--text-color-lighter);
        }
        & .actions.pd button {
            padding-left: 0;
        }
        `
    );
 
export const TriageFormViewer = observer(
    class TriageFormViewer extends Component {

        render () {
            const { configuration, selectedIndex, formData, formOptions, canFullAssessment, hideHeader, onHelp } = this.props;
            const steps = configuration.pages.length + 1;

            return (
                <div className={cx(rootClassName, "ms-u-slideRightIn40")}>
                    <TriageFormHeader configuration={configuration} hideHeader={hideHeader} />

                    <div className="wrapper">
                        <BoxPanel padding shadow>
                            <TriageWizardIndicator steps={steps} selectedIndex={selectedIndex} />

                            <TriageWizardLayout configuration={configuration} 
                                selectedIndex={selectedIndex} 
                                formData={formData} 
                                formOptions={formOptions}
                                isVisible={this._isVisible}
                                onHelp={onHelp} />

                            <TriageWizardActions steps={steps} selectedIndex={selectedIndex}
                                onSelectedIndexChanged={(index) => this.props.onSelectedIndexChanged(index)}
                                isValid={() => this._isValid()}
                                canSubmit={() => canFullAssessment() }
                                onSubmit={() => this._onFullAssessment()} />

                        </BoxPanel>
                    </div>
                </div>
            );
        }

        _onFullAssessment() {
            const { formData, onFullAssessment } = this.props; 

            if(onFullAssessment) {
                onFullAssessment(formData);
            }
        }

        _isValid() {
            const { selectedIndex, configuration, formData } = this.props;

            const sections = configuration.pages[selectedIndex].sections;
            const completed = sections.filter(section => section.fields.length == section.fields.filter(field => 
                !field.required || !!formData[field.name] || !this._isVisible(formData, field, configuration)).length)

            return sections.length == completed.length;
        }

        _isVisible($, field, configuration) {
            if(!field.visible) {
                return true;
            }

            return eval(field.visible);
        }
    }
);