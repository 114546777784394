import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { AppPage } from '@layout/containers/AppPage';
import { ObservablePagination } from '@baseComponents/ObservablePagination';

import { AdvisoryGridView } from '../../components/advisories/AdvisoryGridView';
import { AssessmentSideNav} from '../../components/core/AssessmentSideNav';
import { AdvisoryBroswerPageCommandBar } from '../../components/advisories/AdvisoryBroswerPageCommandBar';

export const AdvisoriesBrowsePage = inject('pageContext', 'assessmentStore', 'riskStore', 'routing', 'filterBarStore', 'controlStore')(observer(
    class AdvisoriesBrowsePage extends Component {

        componentWillMount() {
            this.loadData(this.props);
        }

        componentWillReceiveProps(nextProps) {
            this.loadData(nextProps);
        }

        loadData(props) {
            const { assessmentStore, routing, pageContext, filterBarStore } = props;
            const { advisoryStore } = assessmentStore;

            pageContext.updateQueryStringParams(routing.location.search);
            advisoryStore.searchAdvisories(pageContext.queryStringParams.p, pageContext.queryStringParams.query);
            advisoryStore.loadPortfolios();
            // debugger
            if (pageContext.queryStringParams.query) {
                //load controls
                const query  = JSON.parse(pageContext.queryStringParams.query);
                if (query.control) {
                    advisoryStore.loadSelectedControls(query.control.map(C => C.id))                
                }
                //load assessments
                if (query.assessment && query.assessment.id) {
                    advisoryStore.loadSelectedAssessments(query.assessment.id)
                }
            }
            
        }

        onFiltersChange = (filters) => {
            const { routing } = this.props;
            const levelFilter = filters.find(F => F.fieldName ==='level');
            const initialCompliances = filters.find(F => F.fieldName ==='initialCompliances');
            const finalCompliances = filters.find(F => F.fieldName ==='finalCompliances');
            const portfolios = filters.find(F => F.fieldName ==='portfolios');
            const keywords = filters.find(F => F.fieldName ==='keywords');
            const controls = filters.find(F => F.fieldName ==='controls');
            const assessments = filters.find(F => F.fieldName ==='assessments');
            // debugger
            const queryString = {
                keywords: keywords ? keywords.value: null,
                assessment: {
                    portfolio: portfolios ? portfolios.getValues(portfolios.items) : null,
                    id: assessments? assessments.getValues(assessments.items): null,
                },
                level: levelFilter ? levelFilter.getValues(levelFilter.items) : null,
                finalCompliances: finalCompliances ? finalCompliances.getValues(finalCompliances.items) : null,
                initialCompliances: initialCompliances ? initialCompliances.getValues(initialCompliances.items) : null,
                control: controls ? controls.getValues(controls.items) : null,
            };
            for (let key in queryString.assessment) {
                if (queryString.assessment[key] === null) {
                    delete queryString.assessment[key];
                }
            }
            for (let key in queryString) {
                if (queryString[key] === null) {
                    delete queryString[key];
                }
            }
            routing.push(`${window.location.pathname}?query=${JSON.stringify(queryString)}`);
        }

        onClearAllFilters = () => {
            const { routing } = this.props;
            const queryString = {
                keywords: null,
                assessment: {
                    portfolio: [],
                    id: null,
                },
                level: [],
                finalCompliances: [],
                initialCompliances: [],
                control: [],
            };
            routing.push(`${window.location.pathname}?query=${JSON.stringify(queryString)}`);
        }
    
        render () {
            const { filterBarStore, routing, controlStore, assessmentStore, pageContext } = this.props;
            const { advisoryStore, lifecycleStore } = assessmentStore;
            const { pagination, advisories, gridView, searchKeywords, riskMappings, loading } = advisoryStore;
            // console.log('advisoryStore', advisoryStore);
            return (
                <AppPage 
                    // error={error} 
                    // breadcrumb={breadcrumb}
                    loading={loading || false} 
                    hasSidebar={false} 
                    className="assessments list has--page-nav">
                        <AssessmentSideNav
                            onLinkClick={(item) => routing.push(item.url)}
                            onNewTriage={() => routing.push('/assurance/triage')}
                            canViewResourcePages={lifecycleStore.canViewResourcePages}
                            canViewCalendarPages={lifecycleStore.canViewCalendarPages} 
                            canViewAdvisoryPages={lifecycleStore.canViewAdvisoryPages}
                        />
                        <>
                            <AdvisoryBroswerPageCommandBar
                                onToggleFilterBar={() => filterBarStore.showFilterBar = !filterBarStore.showFilterBar}
                                isFilterChanged={filterBarStore.isFilterChanged}
                                onExport={() => {}}
                                advisoryStore={advisoryStore}
                                filterBarStore={filterBarStore}
                                controlStore={controlStore}
                                assessmentStore={assessmentStore}
                                onFiltersChange = {this.onFiltersChange}
                                onClearAll={this.onClearAllFilters}
                                fields={[
                                    {
                                        fieldType: 'dropdown',
                                        fieldName: 'level',
                                        placeholder: 'Severity',
                                        multiselect: true,
                                        items: advisoryStore.levels,
                                        defaultSelection: [],
                                        selectedItems: advisoryStore.query && advisoryStore.query.level ? advisoryStore.query.level : [],
                                        getValues: (items) => {
                                            if (items) return items.filter(I => I.checked).map(I => I.key)
                                            else return null
                                        }
                                    },
                                    {
                                        fieldType: 'dropdown',
                                        fieldName: 'initialCompliances',
                                        placeholder: 'Initial Compliance',
                                        multiselect: true,
                                        items: advisoryStore.initialCompliantStatus,
                                        defaultSelection: [],
                                        selectedItems: advisoryStore.query && advisoryStore.query.initialCompliances ? advisoryStore.query.initialCompliances : [],
                                        getValues: (items) => {
                                            if (items) return items.filter(I => I.checked).map(I => I.key)
                                            else return null
                                        }
                                    },
                                    {
                                        fieldType: 'dropdown',
                                        fieldName: 'finalCompliances',
                                        placeholder: 'Final Compliance',
                                        multiselect: true,
                                        items: advisoryStore.finalCompliantStatus,
                                        defaultSelection: [],
                                        selectedItems: advisoryStore.query && advisoryStore.query.finalCompliances ? advisoryStore.query.finalCompliances : [],
                                        getValues: (items) => {
                                            if (items) return items.filter(I => I.checked).map(I => I.key)
                                            else return null
                                        }
                                    },
                                    {
                                        fieldType: 'dropdown',
                                        fieldName: 'portfolios',
                                        placeholder: 'Portfolios',
                                        multiselect: true,
                                        items: advisoryStore.portfolios,
                                        defaultSelection: [],
                                        selectedItems: advisoryStore.query && advisoryStore.query.assessment && advisoryStore.query.assessment.portfolio ? advisoryStore.query.assessment.portfolio.map(P => P.id) : [],
                                        getValues: (items) => {
                                            if (items) return items.filter(I => I.checked).map(I => {return {id: I.key}})
                                            else return null
                                        }
                                    },
                                    {
                                        fieldType: 'text',
                                        fieldName: 'keywords',
                                        placeholder: 'Search by name or alias',
                                        value: advisoryStore.query && advisoryStore.query.keywords ? advisoryStore.query.keywords : '',
                                        getValues: (items) => {
                                            if (items) return items.value;
                                            else return null
                                        }
                                    },
                                    {
                                        fieldType: 'asyncPicker',
                                        fieldName: 'controls',
                                        placeholder: 'Controls',
                                        multiselect: true,
                                        defaultSelection: [],
                                        searchApi: async (keywords) => await controlStore.selectionStore.searchControls({keywords}, true),
                                        items: advisoryStore.query && advisoryStore.query.control ? advisoryStore.selectedControls : [],
                                        getValues: (items) => {
                                            if (items) return items.filter(I => I.checked).map(I => {return {id: I.key}})
                                            else return null
                                        }
                                    },
                                    {
                                        fieldType: 'asyncPicker',
                                        fieldName: 'assessments',
                                        placeholder: 'Assessment',
                                        multiselect: true,
                                        textField: 'code',
                                        searchApi: async (keywords) => await assessmentStore.browseStore.searchAssessments({keywords}, {}, true),
                                        items: advisoryStore.query && advisoryStore.query.assessment && advisoryStore.query.assessment.id ? advisoryStore.selectedAssessments : [],
                                        getValues: (items) => {
                                            if (items) return items.filter(I => I.checked).map(I => I.key)
                                            else return null
                                        }
                                    },
                                ]}
                            />

                            <AdvisoryGridView 
                                loading={loading || false}
                                showAssessment
                                advisories={advisories}
                                riskMappings={riskMappings}
                                view={gridView}
                                keywords={searchKeywords || ''}
                                disableSelection
                                disableClientSideFiltering
                                disableAdvisoriesClick
                                onClickAssessment={(item => {
                                    if (item.assessmentId) {
                                        routing.push(`/assurance/browse/${item.assessmentId}`);
                                    }
                                })}
                                // onAdvisoryClick={(advisory) => this._onAdvisoryClick(advisory)}
                                // onSelectionChanged={(selection) => this._onSelectionChanged(selection)} 
                            />

                            <ObservablePagination 
                                {...pagination}
                                onPageChange={(index) => {
                                    routing.push(`/advisories/browse?p=${index + 1}${pageContext.queryStringParams.query ? `&query=${pageContext.queryStringParams.query}` : ''}`);
                                }} />
                         </>
                </AppPage>
            );
        }
    }
));
