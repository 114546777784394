import React from "react";
import { observer } from "mobx-react";

import { ActionButton } from "office-ui-fabric-react/lib/Button";

import { ContentEditor } from "../../../editors/components/ContentEditor";

import { sanitize } from "../../stores/PatternUtilities";

export const ModuleFieldMultiChoice = observer(({ module, group, measure, disabled, onUpdateOption, onRemoveOption }) => {
    return (
        <>
            {measure.options.map((option) => {
                return (
                    <div className="form__group form__group--flex--row" key={option.key}>
                        <ContentEditor
                            className="form__label form__label--large"
                            html={option.text || ""}
                            disabled={disabled}
                            onSubmit={(evt, val) => {
                                onUpdateOption({
                                    module,
                                    group,
                                    measure,
                                    option: { key: option.key, text: sanitize(val) },
                                });
                            }}
                        />
                        {!disabled && (
                            <ActionButton
                                styles={{ icon: { fontSize: "12px" } }}
                                iconProps={{ iconName: "Delete" }}
                                onClick={() =>
                                    onRemoveOption({
                                        module,
                                        group,
                                        measure,
                                        option,
                                    })
                                }
                            ></ActionButton>
                        )}
                        <ul className="radio__input__btns__list">
                            <li className="radio__input__btns__list__item">
                                <input
                                    type="radio"
                                    id={measure.name + option.key + "Yes"}
                                    name={measure.name + option.key}
                                    className="radio__input__btn"
                                    disabled={true}
                                    //checked={value && value.indexOf(option.key) != -1}
                                    onChange={(e) => {
                                        // if(!value) {
                                        //     onMeasureChange(measure, { value: [option.key], completed: true });
                                        // } else if(value.indexOf(option.key) == -1) {
                                        //     value.push(option.key);
                                        //     onMeasureChange(measure, { value: value, completed: true });
                                        // }
                                    }}
                                />
                                <label htmlFor={measure.name + option.key + "Yes"} className="radio__input__btn__label">
                                    Yes
                                </label>
                            </li>
                            <li className="radio__input__btns__list__item">
                                <input
                                    type="radio"
                                    id={measure.name + option.key + "No"}
                                    name={measure.name + option.key}
                                    className="radio__input__btn"
                                    disabled={true}
                                    //checked={value && value.indexOf(option.key) == -1}
                                    onChange={(e) => {
                                        // if(!value) {
                                        //     onMeasureChange(measure, { value: [], completed: true });
                                        // } else if(value.indexOf(option.key) != -1) {
                                        //     value.splice(value.indexOf(option.key), 1);
                                        //     onMeasureChange(measure, { value: value, completed: true });
                                        // }
                                    }}
                                />
                                <label htmlFor={measure.name + option.key + "No"} className="radio__input__btn__label">
                                    No
                                </label>
                            </li>
                        </ul>
                    </div>
                );
            })}
        </>
    );
});
