import React, { Component } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { Text } from "office-ui-fabric-react/lib/Text";

const rootClassName = cx(
    "cygraph-StatusProgress-root",
    css`
        margin-bottom: var(--spacing-l);
        margin-top: var(--spacing-s);
        min-height: 70px;

        ul.step-anchor {
            list-style: none;
            overflow: hidden;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            background: var(--white);
            border-radius: var(--border-radius);
            box-shadow: var(--box-shadow);
            border: 1px solid var(--border-color-dark);
            margin-bottom: var(--spacing);
            min-height: 70px;

            & li + li:before {
                padding: 0;
            }

            & > li {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                padding-left: 0;
                margin-bottom: 0;
                list-style: none;
                width: 20%;
            }

            & > li > a,
            & > li > a:hover {
                font-family: var(--font-family);
                color: var(--text-color-dark);
                padding: 10px 0 10px 45px;
                text-decoration: none;
                position: relative;
                display: block;
                border: 0 !important;
                border-radius: 0;
                outline-style: none;
                background: var(--white);
                width: 100%;
                min-height: 70px;

                & span:first-child {
                    margin-bottom: var(--spacing-xxxs);
                    font-weight: 400;
                }
            }

            & > li > a:after {
                content: " ";
                display: block;
                width: 0;
                height: 0;
                border-top: 50px solid transparent;
                border-bottom: 50px solid transparent;
                border-left: 30px solid var(--white);
                position: absolute;
                top: 50%;
                margin-top: -50px;
                left: 100%;
                z-index: 2;
            }

            & > li > a:before {
                content: " ";
                display: block;
                width: 0;
                height: 0;
                border-top: 50px solid transparent;
                border-bottom: 50px solid transparent;
                border-left: 30px solid var(--border-color-darker);
                position: absolute;
                top: 50%;
                margin-top: -50px;
                margin-left: 1px;
                left: 100%;
                z-index: 1;
            }

            & > li.active > a {
                border-color: var(--primary-selection-color) !important;
                color: #fff !important;
                background: var(--primary-selection-color) !important;
            }

            & > li.active > a:after {
                border-left: 30px solid var(--primary-selection-color) !important;
            }

            & > li.blocked > a {
                border-color: var(--orange-10) !important;
                color: #fff !important;
                background: var(--orange-10) !important;
            }

            & > li.blocked > a:after {
                border-left: 30px solid var(--orange-10) !important;
            }

            & > li.done > a {
                border-color: var(--border-color) !important;
                background: var(--border-color) !important;
            }

            & > li.done > a:after {
                border-left: 30px solid var(--border-color) !important;
            }
        }

        @media screen and (max-width: 768px) {
            ul.step-anchor > li {
                float: none !important;
                margin-bottom: 0;
                width: 100%;
            }

            ul.step-anchor > li > a,
            ul.step-anchor > li > a:hover {
                padding-left: 15px;
                margin-right: 0;
                margin-bottom: 1px;
            }

            ul.step-anchor > li > a:after,
            ul.step-anchor > li > a:before {
                display: none;
            }
        }
    `
);

export const StatusProgress = observer(
    class StatusProgress extends Component {
        render() {
            const { className, children } = this.props;
            const count = React.Children.count(children);

            return (
                <div className={cx(rootClassName, className)} data-test="statusProgress">
                    <ul className={cx("nav nav-tabs", "step-anchor", `count-${count}`)}>
                        {React.Children.map(children, (child) => {
                            return React.cloneElement(child, {
                                width: `${100 / count}%`,
                            });
                        })}
                    </ul>
                </div>
            );
        }
    }
);

export const StatusProgressStep = observer(
    class StatusProgressStep extends Component {
        render() {
            const { className, active, done, blocked, title, children, width } = this.props;
            return (
                <li
                    className={cx("nav-item", className, active && "active", done && "done", blocked && "blocked")}
                    style={{ width }}
                >
                    <a className="nav-link">
                        <Text variant="mediumPlus" block>
                            {title}
                        </Text>
                        <Text variant="small" block>
                            {children}
                        </Text>
                    </a>
                </li>
            );
        }
    }
);
