import React from "react";
import { observer } from "mobx-react";

import { Stack } from "office-ui-fabric-react/lib/Stack";
import { PanelType } from "office-ui-fabric-react/lib/Panel";
import { PrimaryButton, ActionButton } from "office-ui-fabric-react/lib/Button";

import { ObservablePanel } from "../../../base/components/ObservablePanel";

import { ModuleEditForm } from "../../components/modules/ModuleEditForm";

export const PatternModuleEditPanel = observer(({ visible, title, module, isDirty, isValid, onApply, onCancel }) => {
    return (
        <ObservablePanel
            hidden={!visible}
            pageTitle={title}
            pageType="Module"
            panelType={PanelType.smallFixedFar}
            styles={{ content: { height: "100%" } }}
            onClosePanel={() => onCancel()}
            onRenderFooter={() => (
                <Stack horizontal horizontalAlign="space-between">
                    <Stack horizontal tokens={{ childrenGap: 15 }}></Stack>
                    <Stack horizontal tokens={{ childrenGap: 15 }}>
                        <ActionButton styles={{ root: { height: "32px" } }} onClick={() => onCancel()} text="Cancel" />
                        <PrimaryButton onClick={() => onApply(module)} text="Apply" disabled={!isDirty || !isValid} />
                    </Stack>
                </Stack>
            )}
        >
            {visible && <ModuleEditForm formData={module} isDirty={isDirty} />}
        </ObservablePanel>
    );
});
