import { observable, action, flow } from "mobx";

import { StateFlowService } from "../../../api/stateflows";
import { PaginationStore } from "../../base/PaginationStore";
import { StateFlowStore } from "./StateFlowStore";

export class StateFlowPickerStore {
    private parentStore: StateFlowStore;
    private stateFlowService: StateFlowService;

    @observable public loading: boolean = false;
    @observable public selectedStateFlowIds: string[] = [];
    @observable public stateflows: any[] = [];
    @observable public query: any;
    @observable public pagination: PaginationStore;
    @observable public error: any;

    constructor(parentStore: StateFlowStore) {
        this.parentStore = parentStore;
        this.stateFlowService = parentStore.stateFlowService;
        this.pagination = new PaginationStore();
    }

    public loadStateFlows = flow(function* (options: any) {
        const o = Object.assign({ page: 1, query: { keywords: null } }, options);

        this.loading = true;
        this.stateflows = [];
        this.query = o.query;
        this.error = null;

        try {
            const result = yield this.stateFlowService.searchStateFlows({
                pageSize: this.pagination.itemsPerPage || 5,
                startIndex: (o.page - 1) * (this.pagination.itemsPerPage || 5),
                query: o.query,
            });
            this.stateflows = result.items;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
            this.query = result.query;
        } catch (e) {
            this.error = e;
            console.error(e);
        } finally {
            this.loading = false;
        }
    });

    @action
    public setSelected(registries: any[]) {
        this.selectedStateFlowIds = registries.map((i) => i.id);
    }

    @action
    public setSelectedWithId(id: string | null) {
        if (!id) {
            this.selectedStateFlowIds = [];
        } else {
            if (this.selectedStateFlowIds.length == 1 && this.selectedStateFlowIds[0] == id) {
                return;
            }
            this.selectedStateFlowIds = [id];
        }
    }

    @action
    public setSelectedWithIds(ids: string[] | null) {
        if (ids == null || ids.length == 0) {
            this.selectedStateFlowIds = [];
        } else {
            this.selectedStateFlowIds = ids;
        }
    }
}
