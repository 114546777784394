import React from "react";
import moment from "moment";

import { tenantInfo } from "../../../../configProvider";

import { StyleSheet, Page, Image, View, Text } from "@react-pdf/renderer";

import { Paragraph } from "@ui/pdf/Paragraph";
import { Heading1, Heading2 } from "@ui/pdf/Heading";

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    header: {
        position: "absolute",
        fontSize: 9,
        top: 35,
        left: 0,
        right: 0,
        textAlign: "center",
    },
    logo: {
        width: 100,
        marginHorizontal: "auto",
    },
    wrapper: {
        position: "absolute",
        top: 200,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "#44424D",
    },
    content: {
        position: "absolute",
        top: 70,
        left: 0,
        right: 0,
        textAlign: "center",
    },
    footer: {
        position: "absolute",
        fontSize: 9,
        bottom: 15,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
    },
});

export const AssessmentCoverPage = ({ assessment, workItem }) => {
    return (
        <Page style={styles.body}>
            <View style={styles.header} fixed>
                <Image src={tenantInfo.logoUrl} style={styles.logo} />
            </View>
            <View style={styles.wrapper}>
                <View style={styles.content}>
                    <Heading1 invert center>
                        {assessment.code}
                    </Heading1>
                    <Heading2 invert center>
                        {workItem.product.name} Report
                    </Heading2>
                    {assessment.closed && (
                        <Paragraph center style={{ color: "grey" }}>
                            Completed {moment.utc(assessment.closed).format("Do MMM YYYY")}
                        </Paragraph>
                    )}
                </View>
                <Text style={styles.footer} fixed>
                    {tenantInfo.name} - Confidential
                </Text>
            </View>
        </Page>
    );
};
