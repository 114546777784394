import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';

import { TextField } from '@ui/elements/TextField';
import { Dropdown } from '@ui/elements/Dropdown';
import { DefaultButton, IconButton } from '@ui/elements/Button';
import { FontIcon } from '@ui/elements/Icon';
import { TooltipHost } from '@ui/elements/Tooltip';

import { IconPickerCallout } from '@baseComponents/IconPickerCallout';

const formContainerClass = cx(css`
    padding: 5px,
`);

const iconClass = cx(css`
    font-size: 40px;
    height: 40px;
    width: 40px;
`);

export const NumberEditor = observer(
  class NumberEditor extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            iconFilter: '',
            isCalloutOpen: false,
        }
    }
    render () {
        const { formData, dataSources, dataSourceStore, instance } = this.props;
        const { coreDataSources, selectionStore } = dataSourceStore;
        return (
            <r-grid columns="1">
                {formData.properties.dataSeries && formData.properties.dataSeries.length > 0 && formData.properties.dataSeries.map((DS,i) => {
                    const selectedPartDataSource = DS.dataSourceId ? dataSources.find(D => D.id === DS.dataSourceId) : null;
                    const selectedDataSource = selectedPartDataSource ? selectionStore.dataSources.find(D => D.id === selectedPartDataSource.properties.mainSource) : null;
                    const selectedCoreDataSource = selectedDataSource ? coreDataSources.find(D => D.id === selectedDataSource.coreDataSourceId) : null;

                    return(
                    <div className={formContainerClass} key={`DS_${i}`}>
                        <r-cell span="2" style={{color: 'var(--text-color-light)'}}>
                            <div style={{cursor:'pointer'}} onClick={()=>{
                                this.setState({isCalloutOpen: !this.state.isCalloutOpen})
                            }}>
                                <span className="calloutTarget">Selected Icon</span>
                                <IconPickerCallout 
                                    target={`calloutTarget`}
                                    isCalloutOpen={this.state.isCalloutOpen}
                                    onIconPick={(iconName) => {
                                        DS.selectedIcon = iconName;
                                        this.setState({isCalloutOpen: false});
                                    }}
                                />
                            </div>
                        </r-cell>

                        <r-cell span="2">
                            <div style={{cursor:'pointer'}} onClick={()=>{
                                this.setState({isCalloutOpen: !this.state.isCalloutOpen})
                            }}>
                                {DS.selectedIcon ? <FontIcon 
                                    iconName={DS.selectedIcon}
                                    className={iconClass}
                                /> : <span>None</span>}
                            </div>
                        </r-cell>
                        <r-cell span="2">
                            <div className="dataSourceDropDownContainer">
                                <Dropdown
                                    required
                                    label="Data source"
                                    selectedKey={DS.dataSourceId}
                                    onChange={(ev, op) => {
                                        if(op) {
                                            DS.dataSourceId = op.key;
                                        }
                                    }}
                                    options={dataSources.map(DSource => {
                                        return {
                                            key: DSource.id,
                                            text: DSource.title,
                                        }
                                    })}                                            
                                />
                                {DS.dataSourceId && <TooltipHost
                                    content="Edit data source"
                                    id={`editTooltipId`}
                                    calloutProps={{ gapSpace: 0 }}
                                    styles={{display:'inline-block', maginLeft: '5px'}}
                                >
                                    <IconButton 
                                        iconProps={{ iconName: 'Edit' }}
                                        title="Edit"
                                        ariaLabel="Edit"
                                        onClick={() => {
                                                const idx = formData.properties.dataSeries.findIndex(D => D.id === DS.id);
                                                if (idx > -1) {
                                                    const pagePart = instance.selectionStore.getPagePart(DS.dataSourceId);
                                                    instance.lifecycleStore.onEditPart(pagePart.section, pagePart.zone, pagePart.part);
                                                }
                                            }
                                        }
                                    />
                                </TooltipHost> }
                            </div>
                        </r-cell>
                        {selectedCoreDataSource && <r-cell span="2">
                            <r-cell span="2">
                                <div>
                                    <Dropdown
                                            required
                                            label="Source Field"
                                            selectedKey={DS.dataDisplayType || 'field'}
                                            onChange={(ev, op) => {
                                                if(op) {
                                                    DS.dataDisplayType = op.key;
                                                }
                                            }}
                                            options={
                                                [{
                                                    text: 'Specific field', key: 'field'
                                                },{
                                                    text: 'Count of records', key: 'count'
                                                }]
                                            }
                                        />
                                </div>
                            </r-cell>
                            {DS.dataDisplayType !== 'count' && <r-cell span="2">
                                <div>
                                    <Dropdown
                                        required
                                        label="Value Field"
                                        selectedKey={DS.dataValue}
                                        onChange={(ev, op) => {
                                            if(op) {
                                                DS.dataValue = op.key;
                                            }
                                        }}
                                        options={selectedCoreDataSource.fields.map(F => {
                                            return {
                                                key: F.id,
                                                text: F.title,
                                            }
                                        })}
                                    />
                                </div>
                            </r-cell> }
                            {DS.dataDisplayType !== 'count' && <r-cell span="2">
                                <div>
                                    <Dropdown
                                        required
                                        label="Chart type"
                                        selectedKey={DS.orderField || 'singleValue'}
                                        onChange={(ev, op) => {
                                            if(op) {
                                                DS.orderField = op.key;
                                            }
                                        }}
                                        options={[{key: 'singleValue', text: 'Single Value'},{key: 'comparison', text: 'Comparison'}]}
                                    />
                                </div>
                            </r-cell> }
                            {selectedCoreDataSource && <r-cell span="2">
                                <div>
                                    <TextField 
                                        label="Unit"
                                        required
                                        value={DS.unit}
                                        onChange={(ev, val) => DS.unit = val} 
                                    />
                                </div>
                            </r-cell>}
                            {selectedCoreDataSource && <r-cell span="2">
                                <div>
                                    <Dropdown
                                        required
                                        label="Show unit"
                                        selectedKey={DS.unitDisplay}
                                        onChange={(ev, op) => {
                                            if(op) {
                                                DS.unitDisplay = op.key;
                                            }
                                        }}
                                        options={[
                                            {
                                                key: 'hide',
                                                text: 'Hide unit',
                                            },{
                                                key: 'pre',
                                                text: 'Show before',
                                            },{
                                                key: 'after',
                                                text: 'Show after',
                                            }]
                                        }
                                    />
                                </div>
                            </r-cell>}
                        </r-cell>}
                        {DS.dataSourceId && <r-cell span="2">
                            <DefaultButton text="Remove data series" style={{marginTop:'10px', width: '238px'}} onClick={() => {
                                const idx = formData.properties.dataSeries.findIndex(D => D.id === DS.id);
                                if (idx > -1) {
                                    formData.properties.dataSeries.splice(idx, 1);
                                }
                            }} />
                        </r-cell>}
                    </div>)}
                )} 
            </r-grid>
      );
    }
  }
);
