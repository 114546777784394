import React, { Component } from "react";
import { observer } from "mobx-react";
import moment from "moment";

import { getFileTypeIconProps } from "@uifabric/file-type-icons";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { Text } from "office-ui-fabric-react/lib/Text";
import { MessageBar } from "office-ui-fabric-react/lib/MessageBar";
import { DetailsListLayoutMode, Selection, SelectionMode, ConstrainMode } from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { ContextualMenu } from "office-ui-fabric-react/lib/ContextualMenu";
import { PersonaSize } from "office-ui-fabric-react/lib/Persona";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { WrappedContent } from "@modules/base/components/WrappedContent";
import { PhotoPersona } from "@identity/containers/utilities/PhotoPersona";

export const EvidenceGridView = observer(
    class EvidenceGridView extends Component {
        constructor(props) {
            super(props);

            this.state = {};

            this._selection = new Selection({
                onSelectionChanged: () => {
                    if (this.props.onSelectionChanged) {
                        this.props.onSelectionChanged(this._selection);
                    }
                },
            });
        }

        render() {
            const { loading, className, onEvidenceClick } = this.props;
            const { contextualMenuProps } = this.state;

            this._columns = [
                {
                    key: "type",
                    name: "",
                    fieldName: "type",
                    minWidth: 25,
                    maxWidth: 25,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        if (item.document) {
                            return <Icon {...getFileTypeIconProps({ extension: item.document.extension, size: 20 })} />;
                        } else if (item.workItem) {
                            return <Icon iconName="WorkItem" style={{ fontSize: 20 }} />;
                        } else if (item.link) {
                            return <Icon iconName="Link" style={{ fontSize: 20 }} />;
                        }
                        return <Icon iconName="EditNote" style={{ fontSize: 20 }} />;
                    },
                },
                {
                    key: "title",
                    name: "Title",
                    fieldName: "title",
                    minWidth: 210,
                    isRowHeader: true,
                    isResizable: true,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return (
                            <WrappedContent>
                                {item.link && <span>{item.link}</span>}
                                {item.document && (
                                    <a
                                        href="#"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            ev.stopPropagation();
                                            this.props.onDocumentClick(item);
                                        }}
                                    >
                                        {item.document.name}
                                    </a>
                                )}
                                {item.workItem && (
                                    <a
                                        href="#"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            ev.stopPropagation();
                                            this.props.onWorkItemClick(item);
                                        }}
                                    >
                                        {item.workItem.title}
                                    </a>
                                )}
                                {item.notes && (
                                    <Text variant="small" block>
                                        {item.notes}
                                    </Text>
                                )}
                                {item.type && (
                                    <Text variant="small" block>
                                        {item.type} {item.workItem && <span>- {item.workItem.status}</span>}
                                    </Text>
                                )}
                            </WrappedContent>
                        );
                    },
                    isPadded: true,
                },
                {
                    key: "modified",
                    name: "Modified",
                    fieldName: "modified",
                    minWidth: 170,
                    maxWidth: 230,
                    isResizable: true,
                    isSorted: true,
                    isSortedDescending: true,
                    sortAscendingAriaLabel: "Sorted A to Z",
                    sortDescendingAriaLabel: "Sorted Z to A",
                    onColumnClick: this._onColumnClick,
                    data: "date",
                    onRender: (item) => {
                        return (
                            <>
                                <PhotoPersona
                                    principalId={item && item.modifiedBy ? item.modifiedBy.id : null}
                                    size={PersonaSize.size28}
                                    text={item.modifiedBy.name}
                                    showSecondaryText={true}
                                    secondaryText={moment.utc(item.modified).fromNow()}
                                />
                            </>
                        );
                    },
                },
            ];

            const filtered = this._getFilteredEvidences();

            return (
                <BoxPanel shadow className={className}>
                    <ShimmeredDetailsList
                        items={filtered}
                        compact={false}
                        columns={this._columns}
                        selectionMode={SelectionMode.none}
                        getKey={this._getKey}
                        setKey="multiple"
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.horizontalConstrained}
                        isHeaderVisible={true}
                        selection={this._selection}
                        selectionPreservedOnEmptyClick={true}
                        onItemInvoked={onEvidenceClick}
                        //onItemContextMenu={(item, index, ev) => this._onItemContextMenu(item, index, ev) }
                        enterModalSelectionOnTouch={true}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        checkButtonAriaLabel="Row checkbox"
                        enableShimmer={loading}
                        ariaLabelForShimmer="Evidences are being fetched"
                        ariaLabelForGrid="Evidence list"
                    />

                    {!loading && !filtered.length && <MessageBar>There are no matching evidences.</MessageBar>}

                    {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}
                </BoxPanel>
            );
        }

        _getFilteredEvidences() {
            const { evidences, view, keywords } = this.props;

            var filtered = evidences;
            if ((view || "all") != "all") {
                filtered = evidences.filter((a) => a.type == view);
            }

            if (keywords) {
                filtered = filtered.filter((a) => a.document.name.toLowerCase().indexOf(keywords.toLowerCase()) != -1);
            }

            return filtered;
        }

        _getKey(item, index) {
            return item ? item.id : index;
        }

        _getSelectionDetails() {
            const selectionCount = this._selection.getSelectedCount();

            switch (selectionCount) {
                case 0:
                    return "No items selected";
                case 1:
                    return "1 item selected: " + this._selection.getSelection()[0].name;
                default:
                    return `${selectionCount} items selected`;
            }
        }

        _onColumnClick(ev, column) {}

        _onItemContextMenu(item, index, ev) {
            const contextualMenuProps = {
                target: ev.target,
                items: [
                    {
                        key: "text",
                        name: `${this._selection.getSelectedCount()} selected`,
                    },
                ],
                onDismiss: () => {
                    this.setState({
                        contextualMenuProps: undefined,
                    });
                },
            };

            if (index > -1) {
                this.setState({
                    contextualMenuProps: contextualMenuProps,
                });
            }

            return false;
        }
    }
);
