import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { useStores } from "@hooks/useStores";

import { AppPage } from "@modules/layout/containers/AppPage";
import { JsonQueryString } from "@modules/utils/JsonQueryString";
import { ObservablePagination } from "@modules/base/components/ObservablePagination";

import { AuditActivityGridView } from "@modules/controls/components/audits/AuditActivityGridView";

export const AuditActivityBrowsePage = withRouter(
    observer(({ match }) => {
        const { pageContext, routing, controlStore } = useStores();
        const { auditBrowseStore } = controlStore;
        const { breadcrumb, error, audits, query, loading, pagination } = auditBrowseStore;

        useEffect(() => {
            pageContext.updateQueryStringParams(routing.location.search);
            auditBrowseStore.loadAudits({
                page: pageContext.queryStringParams.p || 1,
                query: JsonQueryString.decode(pageContext.queryStringParams.query),
            });
        }, [match, routing, routing.location.search]);

        return (
            <AppPage
                loading={loading}
                breadcrumb={breadcrumb}
                error={error}
                hasPageNav={true}
                hasSidebar={false}
                className="controls list audit browse"
            >
                <AuditActivityGridView loading={loading} query={query} audits={audits} />

                <ObservablePagination
                    {...pagination}
                    onPageChange={(index) => {
                        routing.push(JsonQueryString.build("/controls/audits", query, index + 1));
                    }}
                />
            </AppPage>
        );
    })
);
