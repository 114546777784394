import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";
import { withRouter } from "react-router";

import { JsonQueryString } from "@modules/utils/JsonQueryString";
import { ObservablePagination } from "@modules/base/components/ObservablePagination";

import { WorkItemGridView } from "@modules/workitems/components/core/WorkItemGridView";
import { WorkItemCommandBar } from "@modules/workitems/components/core/WorkItemCommandBar";

export const EntityWorkItemsTab = withRouter(
    observer(({ entity, match }) => {
        const { entityStore, routing, pageContext } = useStores();
        const { loading, workItems, pagination, query } = entityStore.workItemStore;

        useEffect(() => {
            if (entity) {
                pageContext.updateQueryStringParams(routing.location.search);
                entityStore.workItemStore.loadWorkItems({
                    page: pageContext.queryStringParams.p || 1,
                    query: JsonQueryString.decode(pageContext.queryStringParams.query),
                    entityId: entity.id,
                });
            }
        }, [entity, match]);

        return (
            <>
                <WorkItemCommandBar
                    loading={loading}
                    filterQuery={query}
                    filterContext={{
                        processing: loading,
                        resultCount: pagination.totalItemCount,
                    }}
                    onFilterChange={(q, f) => {
                        routing.push(JsonQueryString.build(`/entities/browse/${entity.id}/work-items`, q));
                    }}
                    onNewRequest={(product) => {
                        if (!product) {
                            routing.push(`/demand/catalogue?entityId=${entity.id}`);
                        } else {
                            lifecycleStore.onCreate({}, { workItem: { product } });
                        }
                    }}
                    onExport={() => entityStore.workItemStore.exportWorkItems({})}
                />

                <WorkItemGridView
                    workItems={workItems}
                    loading={loading}
                    onWorkItemClick={(workItem) => routing.push(`/demand/browse/${workItem.id}`)}
                />

                <ObservablePagination
                    {...pagination}
                    onPageChange={(index) => {
                        routing.push(JsonQueryString.build(match.url, query, index + 1));
                    }}
                />
            </>
        );
    })
);
