import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { useStores } from "@hooks/useStores";

import { AppPage } from "@modules/layout/containers/AppPage";
import { JsonQueryString } from "@modules/utils/JsonQueryString";

import { ControlDashboardSummary } from "@modules/controls/components/core/ControlDashboardSummary";
import { ControlDomainCardListView } from "@modules/controls/components/domains/ControlDomainCardListView";
import { ControlDomainGridView } from "@modules/controls/components/domains/ControlDomainGridView";

export const ControlDashboardPage = withRouter(
    observer(({ match }) => {
        const { pageContext, routing, controlStore } = useStores();
        const { dashboardStore } = controlStore;
        const { breadcrumb, error, domains, query, loading, pagination } = dashboardStore;

        useEffect(() => {
            pageContext.updateQueryStringParams(routing.location.search);
            dashboardStore.loadControls({
                page: pageContext.queryStringParams.p || 1,
                query: JsonQueryString.decode(pageContext.queryStringParams.query),
            });
        }, [match, routing, pageContext]);

        return (
            <AppPage
                loading={loading}
                breadcrumb={breadcrumb}
                error={error}
                hasPageNav={true}
                hasSidebar={false}
                className="controls dashboard"
            >
                <ControlDashboardSummary />
                <ControlDomainGridView query={query} loading={loading} domains={domains} />
            </AppPage>
        );
    })
);
