import React, { useEffect, useCallback } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { useStores } from "@hooks/useStores";

import { JsonQueryString } from "@modules/utils/JsonQueryString";
import { AppPage } from "@modules/layout/containers/AppPage";
import { ObservablePagination } from "@modules/base/components/ObservablePagination";

import { ExceptionGridView } from "@modules/risks/components/exceptions/ExceptionGridView";
import { ExceptionSideNav } from "@modules/risks/components/exceptions/ExceptionSideNav";
import { ExceptionCommandBar } from "@modules/risks/components/exceptions/ExceptionCommandBar";

import { RiskNewPanel } from "@modules/risks/containers/panels/RiskNewPanel";

export const ExceptionBrowsePage = withRouter(
    observer(({ match }) => {
        const { riskStore, pageContext, routing } = useStores();
        const { exceptionBrowseStore } = riskStore;
        const { loading, error, breadcrumb, pagination, risks, query } = exceptionBrowseStore;

        useEffect(() => {
            pageContext.updateQueryStringParams(routing.location.search);
            exceptionBrowseStore.loadRisks({
                page: pageContext.queryStringParams.p || 1,
                query: JsonQueryString.decode(pageContext.queryStringParams.query),
            });
        }, [match, routing, pageContext]);

        const onNewRisk = useCallback(() => {
            routing.push("/exceptions/new");
        }, []);

        return (
            <AppPage
                error={error}
                breadcrumb={breadcrumb}
                loading={loading}
                hasSidebar={false}
                className="risks list has--page-nav"
            >
                <ExceptionSideNav onLinkClick={(item) => routing.push(item.url)} onNewRisk={onNewRisk} />

                <>
                    <ExceptionCommandBar
                        onNewRisk={onNewRisk}
                        filterQuery={query}
                        filterContext={{
                            processing: loading,
                            resultCount: pagination.totalItemCount,
                        }}
                        onFilterChange={(q, f) => {
                            routing.push(JsonQueryString.build("/exceptions/browse", q));
                        }}
                        onExport={() => {
                            exceptionBrowseStore.exportRisks({});
                        }}
                    />

                    <ExceptionGridView
                        risks={risks}
                        loading={loading}
                        query={query}
                        onRiskClick={(risk) => routing.push(`/exceptions/browse/${risk.id}`)}
                    />

                    <ObservablePagination
                        {...pagination}
                        onPageChange={(index) => {
                            routing.push(JsonQueryString.build("/exceptions/browse", query, index + 1));
                        }}
                    />

                    <RiskNewPanel
                        saving={riskStore.lifecycleStore.saving}
                        onRiskCreated={(risk) => this.props.routing.push(`/exceptions/browse/${risk.id}`)}
                    />
                </>
            </AppPage>
        );
    })
);
