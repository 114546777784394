import React from 'react';
import { observer } from 'mobx-react';

import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';

import { BoxPanel } from '../../../base/components/BoxPanel';

export const RiskTypesCommandBar = observer( (props) => {
    const { keywords, onSearchChange, onNewRiskType } = props;

    const items = [
        {
            key: 'newRiskType',
            text: 'New Risk Type',
            iconProps: { iconName: 'Add' },
            onClick: () => onNewRiskType(null),
        },
    ];

    const farItems = [
        {
            key: 'search',
            text: 'Search',
            onRender: () => {
                return (
                <SearchBox
                    value={keywords}
                    placeholder="Search"
                    styles={{ root: { height: 'auto', minWidth: '270px' }}}
                    onSearch={onSearchChange}
                    onClear={() => onSearchChange("")}
                    onSearch={onSearchChange}
                />
                );
            }
        }
    ];

    return (
        <BoxPanel shadow>
            <CommandBar
                styles={{ root: { paddingRight: '0px' } }}
                items={items}
                farItems={farItems}
            />
        </BoxPanel>
    );

});