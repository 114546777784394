import React from "react";
import { observer } from "mobx-react";

import ContentEditable from "react-contenteditable";
import { TextField } from "office-ui-fabric-react/lib/TextField";

export const ModuleFieldText = observer(({ module, group, measure, disabled, onUpdateMeasure }) => {
    return (
        <div>
            <TextField
                placeholder={measure.placeholder}
                multiline={measure.multiline}
                autoAdjustHeight
                readOnly={true}
                autoComplete="off"
                //defaultValue={values[measure.name].value}
                onBlur={(ev) => {
                    //onMeasureChange(measure, { value: ev.target.value, completed: true });
                }}
            />
        </div>
    );
});
