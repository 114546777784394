import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";

import { ContextualMenu } from "office-ui-fabric-react/lib/ContextualMenu";

export const StateFlowEditorMenu = observer(({ store }) => {
    const { visible, position, menuItems } = store;

    const onItemClick = useCallback(
        (ev, item) => {
            store.resolve(true, item.key);
        },
        [store]
    );

    const onDismiss = useCallback(
        (ev) => {
            store.resolve(false);
        },
        [store]
    );

    return <ContextualMenu items={toJS(menuItems)} hidden={!visible} target={position} onItemClick={onItemClick} onDismiss={onDismiss} />;
});
