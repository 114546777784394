import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { cx } from 'emotion';

import Dropzone from 'react-dropzone';
import { getFileTypeIconProps } from '@uifabric/file-type-icons';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { ChoiceGroup } from 'office-ui-fabric-react/lib/ChoiceGroup';

export const EvidenceEditForm = observer(
    class EvidenceEditForm extends Component {
        
        render () {
            const { advisories, formData } = this.props;

            return (
                <div>
                    <div>
                        <ChoiceGroup
                            required
                            label="Evidence type"
                            selectedKey={formData.evidenceType}
                            onChange={(ev, op) => {
                                if(op) {
                                    formData.evidenceType = op.key;
                                }
                            }}
                            options={[
                                {
                                    key: 'Note',
                                    iconProps: { iconName: 'EditNote' },
                                    text: 'Note'
                                },
                                {
                                    key: 'Document',
                                    iconProps: { iconName: 'TextDocument' },
                                    text: 'Document'
                                },
                                {
                                    key: 'WorkItem',
                                    iconProps: { iconName: 'WorkItem' },
                                    text: 'Work Item',
                                    disabled: true
                                },
                                {
                                    key: 'Link',
                                    iconProps: { iconName: 'Link' },
                                    text: 'Link'
                                }
                                ]}/>     
                    </div>
                    { formData.evidenceType == 'Document' && (
                        <div>
                            <Label required>Document</Label>
                            <Dropzone onDrop={(accepted, rejected, evt) => {
                                    formData.file = accepted[0];
                                    evt.stopPropagation();
                                }} multiple={false}>
                                {({getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, open}) => (
                                    <div {...getRootProps()} className={cx('dropzone field', isDragActive && 'active', isDragAccept && 'accept', isDragReject && 'reject')}>
                                        <input {...getInputProps()} />
                                        { formData.file && (
                                            <div>
                                                <Icon {...getFileTypeIconProps({ extension: formData.file.name.split('.').pop(), size: 20 })} />
                                                <p>{formData.file.name}</p>
                                            </div>
                                        ) }
                                        { !formData.file && (
                                            <div>
                                                <Icon iconName="Upload" />
                                                <p>Drag 'n' drop a file here, or click to select file</p>
                                            </div>
                                        ) }
                                    </div>
                                )}
                            </Dropzone>
                            <div style={{display: 'none'}}>{formData.file && formData.file.name}</div>
                        </div>
                    ) }
                    { formData.evidenceType == 'WorkItem' && (
                        <div>
                            work item picker
                        </div>
                    ) }
                    { formData.evidenceType == 'Link' && (
                        <div>
                            <TextField 
                                label="Link URL"
                                required
                                value={formData.link}
                                onChange={(ev, val) => {
                                    formData.link = val;                                        
                                }} />
                        </div>
                    ) }

                    <div>
                        <TextField 
                            label="Notes"
                            required={formData.evidenceType == 'Note'}
                            multiline
                            autoAdjustHeight
                            value={formData.notes}
                            onChange={(ev, val) => {
                                formData.notes = val;                                        
                            }} />
                    </div>
                    <div>
                        <Label required>Selected advisories</Label>
                        <ul className="row--xs">
                            { advisories.map((advisory) => (
                                <li key={advisory.id}>
                                    <Icon iconName="StatusCircleRing" />&nbsp;&nbsp;{advisory.title}
                                </li>
                            )) }
                        </ul>
                    </div>
                </div>
            );
        }
    }
);