import React, { useCallback } from "react";
import { observer } from "mobx-react";

import { Dialog, DialogType, DialogFooter } from "office-ui-fabric-react/lib/Dialog";

import { ActionButton, SpinnerButton } from "@ui/elements/Button";

export const ActivityInstanceDeleteDialog = observer(({ store }) => {
    const { visible, formData, formOptions, isValid, processing } = store;

    const onSave = useCallback(() => {
        store.resolve(true);
    }, [store]);

    const onDismiss = useCallback(() => {
        store.resolve(false);
    }, [store]);

    return (
        <Dialog
            hidden={!visible}
            onDismiss={onDismiss}
            dialogContentProps={{
                type: DialogType.normal,
                title: "Remove Automation",
                closeButtonAriaLabel: "Close",
                subText: `Are you sure you want to remove the automation "${formData ? formData.name : ""}"?`,
            }}
            modalProps={{ isBlocking: false }}
        >
            <DialogFooter>
                <ActionButton onClick={onDismiss} text="Cancel" disabled={processing} />
                <SpinnerButton onClick={onSave} text="Remove" disabled={!isValid || processing} loading={processing} />
            </DialogFooter>
        </Dialog>
    );
});
