import React from "react";
import { observer } from "mobx-react";

import { Label } from "@ui/elements/Label";
import { Dropdown } from "@ui/elements/Dropdown";
import { Stack } from "@ui/elements/Stack";

import { FieldSetView } from "@modules/base/components/FieldSetView";

export const IdentityUserEditForm = observer(({ formData, formOptions }) => {
    return (
        <>
            <FieldSetView title="User Details" columns={1}>
                <r-grid columns="1" columns-l="2">
                    <r-cell span="1">
                        <Label>Name</Label>
                        <div>{formData.name}</div>
                    </r-cell>
                    <r-cell span="1">
                        <Label>Email</Label>
                        <div>{formData.email || "N/A"}</div>
                    </r-cell>
                    <r-cell span="1">
                        <Label>Job Title</Label>
                        <div>{formData.jobTitle || "N/A"}</div>
                    </r-cell>
                    <r-cell span="1">
                        <Label>Provider</Label>
                        <div>{formData.provider}</div>
                    </r-cell>
                </r-grid>
            </FieldSetView>
            <FieldSetView title="Roles & Memberships" columns={1}>
                <Stack tokens={{ childrenGap: 10 }}>
                    <Dropdown
                        placeholder="Select roles"
                        label="Roles"
                        selectedKeys={formData.roles}
                        onChange={(event, item) => {
                            if (item.selected) {
                                formData.roles = [...formData.roles, item.key];
                            } else {
                                formData.roles = formData.roles.filter((g) => g != item.key);
                            }
                        }}
                        multiSelect
                        options={formOptions.roles.map((role) => {
                            return { key: role.key, text: role.text };
                        })}
                    />
                    <Dropdown
                        placeholder="Select teams"
                        label="Teams"
                        selectedKeys={formData.groups}
                        onChange={(event, item) => {
                            if (item.selected) {
                                formData.groups = [...formData.groups, item.key];
                            } else {
                                formData.groups = formData.groups.filter((g) => g != item.key);
                            }
                        }}
                        multiSelect
                        options={formOptions.groups.map((group) => {
                            return { key: group.id, text: group.name, data: group };
                        })}
                    />
                </Stack>
            </FieldSetView>
        </>
    );
});
