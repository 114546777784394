import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { cx } from "emotion";

import { MiniBar } from "./containers/MiniBar";
import { ExtendedSearchPanel } from "./containers/ExtendedSearchPanel";

export const FilterBar = inject("filterBarStore")(
    observer(
        class FilterBar extends Component {
            constructor(props) {
                super(props);
                this.state = {
                    isExtendPanelOpen: false,
                };
            }

            onFiltersChange = (fields) => {
                const { onFiltersChange } = this.props;
                // console.log('filterbar onFiltersChange fields', fields);
                if (onFiltersChange) onFiltersChange(fields);
            };

            render() {
                const {
                    className,
                    visible,
                    onHideFilterBar,
                    fields,
                    minibarFilters,
                    panelFilters,
                    calloutFilters,
                    hideCloseBarButton,
                    hideOpenPanelIcon,
                    onClearAll,
                    disabled,
                } = this.props;
                const { isExtendPanelOpen } = this.state;
                // console.log('fields', fields);
                return visible ? (
                    <div className={cx("filterBarContainer", className)}>
                        {minibarFilters && minibarFilters.length > 0 && (
                            <MiniBar
                                fields={fields}
                                disabled={false}
                                onHideFilterBar={onHideFilterBar}
                                onClearAll={onClearAll}
                                filters={minibarFilters}
                                calloutFilters={calloutFilters}
                                onFiltersChange={this.onFiltersChange}
                                hideCloseBarButton={hideCloseBarButton}
                                showOpenExtendedPanelButton={panelFilters && panelFilters.length > 0 && !!!hideOpenPanelIcon}
                                onShowExtendedPanelClick={() => {
                                    this.setState({ isExtendPanelOpen: true });
                                }}
                            />
                        )}
                        {panelFilters && panelFilters.length > 0 && !!!hideOpenPanelIcon && (
                            <ExtendedSearchPanel
                                visible={isExtendPanelOpen}
                                title={`Search Advisories`}
                                onClosePanel={() => {
                                    this.setState({ isExtendPanelOpen: false });
                                }}
                                filters={panelFilters}
                                fields={fields}
                                onFiltersChange={this.onFiltersChange}
                            />
                        )}
                    </div>
                ) : null;
            }
        }
    )
);
