import { observable, flow, computed } from "mobx";

import { RiskService } from "../../../api/risks";
import { RiskStore } from "./RiskStore";
import { RiskSelectionStore } from "./RiskSelectionStore";
import { RiskPermissionStore } from "./RiskPermissionStore";

export class RiskLifecycleStore {
    public parentStore: RiskStore;
    public riskService: RiskService;
    public selectionStore: RiskSelectionStore;
    public permissionStore: RiskPermissionStore;

    @observable public saving: boolean = false;

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
        this.selectionStore = parentStore.selectionStore;
        this.permissionStore = parentStore.permissionStore;
    }

    @computed
    public get canEdit() {
        const risk = this.selectionStore.risk;
        const lifecycle = this.selectionStore.lifecycle;

        if (!risk || !lifecycle) {
            return false;
        }

        return (
            lifecycle.status !== "Closed" &&
            lifecycle.status !== "Cancelled" &&
            lifecycle.status !== "Transferred" &&
            this.permissionStore.canEdit
        );
    }

    public onEdit = flow(function* (trigger, options) {
        try {
            const risk = this.selectionStore.risk;
            const lifecycle = this.selectionStore.lifecycle;

            if (!risk || !lifecycle) {
                return false;
            }

            const store = this.parentStore.editFormStore;
            const { formData } = yield store.show({ risk, lifecycle });

            if (formData) {
                this.saving = true;

                const updated = yield this.riskService.updateRisk({
                    ...formData,
                    triggerId: trigger.id,
                    ...{ comment: options.comment },
                });
                this.selectionStore.loadRisk(risk.id);
                yield this.parentStore.commentStore.loadComments();

                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `${risk.type.name} ${risk.code} updated successfully`
                );
            }
            store.hide();
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.saving = false;
        }
    });

    @computed
    public get canAssign() {
        const risk = this.selectionStore.risk;
        const lifecycle = this.selectionStore.lifecycle;

        if (!risk || !lifecycle) {
            return false;
        }

        return (
            lifecycle.status != "Closed" &&
            lifecycle.status != "Cancelled" &&
            lifecycle.status !== "Transferred" &&
            this.permissionStore.canAssign
        );
    }

    public onAssign = flow(function* (trigger, options) {
        try {
            const risk = this.selectionStore.risk;
            const lifecycle = this.selectionStore.lifecycle;

            if (!risk || !lifecycle) {
                return false;
            }

            const store = this.parentStore.assignFormStore;
            const { formData } = yield store.show({ risk, lifecycle });

            if (formData) {
                this.saving = true;

                const updated = yield this.riskService.assignRisk({
                    ...formData,
                    triggerId: trigger.id,
                    ...{ comment: options.comment },
                });
                this.selectionStore.setLifecycle(updated);
                yield this.parentStore.commentStore.loadComments();

                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `${risk.type.name} ${risk.code} assigned to ${updated.assignedToUser.name} successfully`
                );
            }
            store.hide();
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.saving = false;
        }
    });

    @computed
    public get canSubmit() {
        const risk = this.selectionStore.risk;
        const lifecycle = this.selectionStore.lifecycle;

        if (!risk || !lifecycle) {
            return false;
        }

        return lifecycle.status == "Draft" && this.permissionStore.canSubmit;
    }

    @computed
    public get canSubmitWithReview() {
        const risk = this.selectionStore.risk;
        const lifecycle = this.selectionStore.lifecycle;

        if (!risk || !lifecycle) {
            return false;
        }

        return lifecycle.status == "Draft" && !!risk.review && this.permissionStore.canSubmit;
    }

    public onSubmit = flow(function* (trigger, options) {
        this.saving = true;

        try {
            const risk = this.selectionStore.risk;
            const lifecycle = yield this.riskService.submitRisk({
                id: risk.id,
                triggerId: trigger.id,
                ...{ comment: options.comment },
            });
            this.selectionStore.setLifecycle(lifecycle);
            yield this.parentStore.commentStore.loadComments();
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.saving = false;
        }
    });

    @computed
    public get canMonitor() {
        const risk = this.selectionStore.risk;
        const lifecycle = this.selectionStore.lifecycle;

        if (!risk || !lifecycle) {
            return false;
        }

        return !!risk.review && this.permissionStore.canMonitor;
    }

    public onMonitor = flow(function* (trigger, options) {
        this.saving = true;
        try {
            const risk = this.selectionStore.risk;
            const lifecycle = this.selectionStore.lifecycle;

            if (!risk || !lifecycle) {
                return false;
            }

            const updated = yield this.riskService.monitorRisk({
                id: risk.id,
                triggerId: trigger.id,
                ...{ comment: options.comment },
            });
            this.selectionStore.setLifecycle(updated);
            yield this.parentStore.commentStore.loadComments();

            this.parentStore.rootStore.layoutStore.displayToastNotification(
                `${risk.type.name} ${risk.code} submitted successfully`
            );
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.saving = false;
        }
    });

    @computed
    public get canReview() {
        const risk = this.selectionStore.risk;
        const lifecycle = this.selectionStore.lifecycle;

        if (!risk || !lifecycle) {
            return false;
        }

        return (
            lifecycle.status != "Closed" &&
            lifecycle.status != "Cancelled" &&
            lifecycle.status !== "Transferred" &&
            this.permissionStore.canReview
        );
    }

    public onReview = flow(function* (trigger, options) {
        try {
            const risk = this.selectionStore.risk;
            const lifecycle = this.selectionStore.lifecycle;

            if (!risk || !lifecycle) {
                return false;
            }

            const store = this.parentStore.reviewFormStore;
            const { formData } = yield store.show({
                risk,
                lifecycle,
                review: risk.review,
            });

            if (formData) {
                this.saving = true;
                yield this.riskService.createRiskReview(risk.id, {
                    ...formData,
                    triggerId: trigger.id,
                    ...{ comment: options.comment },
                });
                yield this.selectionStore.loadRisk(risk.id);
                yield this.parentStore.commentStore.loadComments();
            }
            store.hide();
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.saving = false;
        }
    });

    @computed
    public get canClose() {
        const risk = this.selectionStore.risk;
        const lifecycle = this.selectionStore.lifecycle;

        if (!risk || !lifecycle) {
            return false;
        }

        return (
            (lifecycle.status == "Submitted" || lifecycle.status == "Monitoring") &&
            !!risk.review &&
            this.permissionStore.canClose
        );
    }

    public onClose = flow(function* (trigger, options) {
        this.saving = true;

        try {
            const risk = this.selectionStore.risk;
            const lifecycle = yield this.riskService.closeRisk({
                id: risk.id,
                triggerId: trigger.id,
                ...{ comment: options.comment },
            });
            this.selectionStore.setLifecycle(lifecycle);
            yield this.parentStore.commentStore.loadComments();
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.saving = false;
        }
    });

    @computed
    public get canCancel() {
        const risk = this.selectionStore.risk;
        const lifecycle = this.selectionStore.lifecycle;

        if (!risk || !lifecycle) {
            return false;
        }

        return (
            lifecycle.status != "Closed" &&
            lifecycle.status != "Cancelled" &&
            lifecycle.status !== "Transferred" &&
            this.permissionStore.canCancel
        );
    }

    public onCancel = flow(function* (trigger, options) {
        this.saving = true;

        try {
            const risk = this.selectionStore.risk;
            const lifecycle = yield this.riskService.cancelRisk({
                id: risk.id,
                triggerId: trigger.id,
                ...{ comment: options.comment },
            });
            this.selectionStore.setLifecycle(lifecycle);
            yield this.parentStore.commentStore.loadComments();
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.saving = false;
        }
    });

    @computed
    public get canTransfer() {
        const risk = this.selectionStore.risk;
        const lifecycle = this.selectionStore.lifecycle;

        if (!risk || !lifecycle) {
            return false;
        }

        return (
            lifecycle.status != "Draft" &&
            lifecycle.status != "Cancelled" &&
            lifecycle.status !== "Transferred" &&
            this.permissionStore.canTransfer
        );
    }

    public onTransfer = flow(function* (trigger, options) {
        try {
            const risk = this.selectionStore.risk;
            const lifecycle = this.selectionStore.lifecycle;

            const store = this.parentStore.transferFormStore;
            const { formData } = yield store.show({
                risk,
                lifecycle,
                review: risk.review,
                allowReview: false,
                ...options.args,
            });

            if (formData) {
                this.saving = true;

                const updated = yield this.riskService.transferRisk({
                    ...formData,
                    triggerId: trigger.id,
                    ...{ comment: options.comment },
                });
                this.selectionStore.loadRisk(risk.id);
                yield this.parentStore.commentStore.loadComments();
            }
            store.hide();
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.saving = false;
        }
    });

    @computed
    public get canDelete() {
        const risk = this.selectionStore.risk;
        const lifecycle = this.selectionStore.lifecycle;

        if (!risk || !lifecycle) {
            return false;
        }

        return lifecycle.status != "Closed" && lifecycle.status !== "Transferred" && this.permissionStore.canDelete;
    }

    public onDelete = flow(function* (trigger, options) {
        this.saving = true;

        try {
            const risk = this.selectionStore.risk;
            yield this.riskService.deleteRisk({
                id: risk.id,
                triggerId: trigger.id,
                ...{ comment: options.comment },
            });
            yield this.selectionStore.loadRisk(risk.id);
            yield this.parentStore.commentStore.loadComments();
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.saving = false;
        }
    });

    @computed
    public get canFlow() {
        const risk = this.selectionStore.risk;
        const lifecycle = this.selectionStore.lifecycle;

        if (!risk || !lifecycle) {
            return false;
        }

        return (
            lifecycle.status != "Closed" &&
            lifecycle.status != "Cancelled" &&
            lifecycle.status !== "Transferred" &&
            this.permissionStore.canFlow
        );
    }

    @computed
    public get canFlowWithReview() {
        const risk = this.selectionStore.risk;
        const lifecycle = this.selectionStore.lifecycle;

        if (!risk || !lifecycle) {
            return false;
        }

        return (
            !!risk.review &&
            lifecycle.status != "Closed" &&
            lifecycle.status != "Cancelled" &&
            lifecycle.status !== "Transferred" &&
            this.permissionStore.canFlow
        );
    }

    public onFlow = flow(function* (trigger, options) {
        this.saving = true;

        try {
            const risk = this.selectionStore.risk;
            const lifecycle = yield this.riskService.flowRisk({
                id: risk.id,
                triggerId: trigger.id,
                ...{ comment: options.comment },
            });
            this.selectionStore.setLifecycle(lifecycle);
            yield this.parentStore.commentStore.loadComments();
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.saving = false;
        }
    });

    public onShare = flow(function* () {
        const risk = this.selectionStore.risk;
    });

    @computed
    public get canAddComment() {
        return this.permissionStore.canAddComment;
    }

    @computed public get canManageTasks() {
        const risk = this.selectionStore.risk;
        const lifecycle = this.selectionStore.lifecycle;

        if (!risk || !lifecycle) {
            return false;
        }

        return (
            lifecycle.status != "Closed" &&
            lifecycle.status != "Cancelled" &&
            lifecycle.status !== "Transferred" &&
            this.permissionStore.canManageTasks
        );
    }

    @computed public get canManageAutomations() {
        const risk = this.selectionStore.risk;
        const lifecycle = this.selectionStore.lifecycle;

        if (!risk || !lifecycle) {
            return false;
        }

        return (
            lifecycle.status != "Closed" &&
            lifecycle.status != "Cancelled" &&
            lifecycle.status !== "Transferred" &&
            this.permissionStore.canManageAutomations
        );
    }

    // @computed public get isTaskModalOpen() {
    //     return this.parentStore.viewStore.isTaskModalOpen;
    // }

    // @action public toggleTaskModal() {
    //     this.parentStore.viewStore.isTaskModalOpen = !this.parentStore.viewStore.isTaskModalOpen;
    // }

    // @action public openTaskModal() {
    //     this.parentStore.viewStore.isTaskModalOpen = true;
    // }

    // @action public closeTaskModal() {
    //     this.parentStore.viewStore.isTaskModalOpen = false;
    // }
}
