import React from "react";
import { observer } from "mobx-react";

import { CommandBar } from "office-ui-fabric-react/lib/CommandBar";

import { BoxPanel } from "@modules/base/components/BoxPanel";

export const WorkItemLinkCommandBar = observer(({ canAddLink, onAddLink }) => {
    const items = [
        {
            key: "addLink",
            text: "Add Related Work",
            iconProps: { iconName: "Add" },
            ariaLabel: "add",
            disabled: !canAddLink,
            subMenuProps: {
                items: [
                    {
                        key: "addLinkExisting",
                        text: "Existing Demand Request",
                        disabled: !canAddLink,
                        onClick: () => onAddLink({ mode: "existing" }),
                    },
                    {
                        key: "addLinkNew",
                        text: "New Demand Request",
                        disabled: !canAddLink,
                        onClick: () => onAddLink({ mode: "new" }),
                    },
                ],
            },
        },
    ];

    return (
        <BoxPanel shadow attached>
            <CommandBar style={{ paddingRight: "0px" }} items={items} />
        </BoxPanel>
    );
});
