import React from "react";
import { observer } from "mobx-react";

import { ComingSoon } from "@modules/base/components/ComingSoon";
import { BoxPanel } from "@modules/base/components/BoxPanel";

export const AssuranceTriageTab = observer(() => {
    return (
        <BoxPanel shadow padding>
            <ComingSoon />
        </BoxPanel>
    );
});
