import React, { Component } from 'react';
import { observer } from 'mobx-react';

export const TaskSeverity = observer(
    class TaskSeverity extends Component {
        render () {
            const { severity } = this.props;
            let divClassName = "status status--dark";
            let iClassName = "fas fa-asterisk";
            let name = "Note"
            switch (severity) {
                case "Critical":
                    divClassName = "status status--danger";
                    iClassName = "fas fa-exclamation-triangle";
                    name ="Critical";
                break;
                case "High":
                    divClassName = "status status--warning";
                    iClassName = "fas fa-angle-double-up";
                    name ="High";
                break;
                case "Medium":
                    divClassName = "status status--primary";
                    iClassName = "fas fa-angle-up";
                    name ="Medium";
                break;
                case "Low":
                    divClassName = "status status--success";
                    iClassName = "fas fa-angle-down";
                    name ="Low";
                break;
            }
            return (
                <React.Fragment>
                    <div className={divClassName}><i className={iClassName}></i>{name}</div>
                </React.Fragment>
            );
        }
    }
);