import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";

import { Stack } from "office-ui-fabric-react/lib/Stack";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { PanelType } from "office-ui-fabric-react/lib/Panel";
import { PrimaryButton, DefaultButton } from "office-ui-fabric-react/lib/Button";

import { BigTextField } from "../../../base/components/BigTextField";
import { ObservablePanel } from "../../../base/components/ObservablePanel";

const getQueryStringParams = (query) => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query).split("&").reduce((params, param) => {
              let [key, value] = param.split("=");
              params[key] = value ? decodeURIComponent(value.replace(/\+/g, " ")) : "";
              return params;
          }, {})
        : {};
};

export const PageDuplicationPanel = inject(
    "routing",
    "pageStore"
)(
    withRouter(
        observer(
            class PageDuplicationPanel extends Component {
                render() {
                    const { duplicateFormStore } = this.props.pageStore;
                    const { visible, formData, formOptions, isValid } = duplicateFormStore;
                    const valuePath =
                        formData && (formData.pathOriginal || formData.path)
                            ? formData.path.replace(`${formOptions.parent.path}/`, "")
                            : "";
                    return (
                        <ObservablePanel
                            hidden={!visible}
                            pageType={`Copy ${formData ? formData.originalTitle || "Page" : "Page"}`}
                            panelType={PanelType.medium}
                            onClosePanel={() => this._onHide()}
                            onRenderFooter={() => this._renderFooter()}
                        >
                            {formData && (
                                <r-grid columns-s="1" columns="6">
                                    <r-cell span="6">
                                        <div>
                                            <BigTextField
                                                label="Title"
                                                required
                                                placeholder="Please enter a page title"
                                                value={formData.title}
                                                transparent
                                                onChange={(ev, value) => (formData.title = value)}
                                            />
                                        </div>
                                    </r-cell>
                                    <r-cell span="6">
                                        <div>
                                            <TextField
                                                required
                                                label="Path"
                                                prefix={`${formOptions.parent.path}/`}
                                                value={valuePath}
                                                onChange={(ev, val) => {
                                                    formData.pathOriginal = val;
                                                    formData.path = `${formOptions.parent.path}/${val}`;
                                                }}
                                            />
                                        </div>
                                    </r-cell>
                                </r-grid>
                            )}
                        </ObservablePanel>
                    );
                }

                _renderFooter() {
                    const { duplicateFormStore } = this.props.pageStore;
                    const { isValid, saving } = duplicateFormStore;
                    return (
                        <Stack horizontal horizontalAlign="space-between">
                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <PrimaryButton onClick={() => this._onSave("Major")} text="Save" disabled={!isValid} />
                            </Stack>
                            <Stack horizontal tokens={{ childrenGap: 20 }}>
                                <DefaultButton onClick={() => this._onHide()} text="Cancel" disabled={saving} />
                            </Stack>
                        </Stack>
                    );
                }

                _onSave() {
                    const { duplicateFormStore } = this.props.pageStore;
                    const { isValid, saving } = duplicateFormStore;
                    const { match, routing, pageStore } = this.props;

                    if (isValid && !saving) {
                        duplicateFormStore.copyPage();
                        duplicateFormStore.hide();
                        const params = getQueryStringParams(routing.location.search);
                        pageStore.browseStore.loadPages({
                            libraryId: match.params.libraryId,
                            parentId: match.params.pageId,
                            page: params["p"] || 1,
                            keywords: params["k"],
                        });
                    }
                }

                _onHide() {
                    const { duplicateFormStore } = this.props.pageStore;
                    const { formData, formOptions } = duplicateFormStore;

                    if (this.props.onCancelled) {
                        this.props.onCancelled(formData, formOptions.parent);
                    }

                    duplicateFormStore.hide();
                }
            }
        )
    )
);
