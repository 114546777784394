import React from "react";

import { Text } from "@react-pdf/renderer";

export const AdvisoryCompliance = ({ compliance }) => {
    if (compliance === "NonCompliant") {
        return <Text style={{ fontSize: 10 }}>Non Compliant</Text>;
    }

    return <Text style={{ fontSize: 10 }}>{compliance}</Text>;
};
