import React, { Component } from "react";
import { observer, inject } from "mobx-react";

import { JsonQueryString } from "@modules/utils/JsonQueryString";
import { AppPage } from "@modules/layout/containers/AppPage";
import { ObservablePagination } from "@modules/base/components/ObservablePagination";

import { EntityGridView } from "@modules/entities/components/core/EntityGridView";
import { EntitySideNav } from "@modules/entities/components/core/EntitySideNav";
import { EntityCommandBar } from "@modules/entities/components/core/EntityCommandBar";

import { EntityNewPanel } from "@modules/entities/containers/panels/EntityNewPanel";

const getQueryStringParams = (query) => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query).split("&").reduce((params, param) => {
              let [key, value] = param.split("=");
              params[key] = value ? decodeURIComponent(value.replace(/\+/g, " ")) : "";
              return params;
          }, {})
        : {};
};

export const EntityBrowsePage = inject(
    "pageContext",
    "entityStore",
    "routing"
)(
    observer(
        class EntityBrowsePage extends Component {
            componentWillMount() {
                const { entityStore, routing } = this.props;
                const { browseStore } = entityStore;

                const params = getQueryStringParams(routing.location.search);
                browseStore.loadEntities({
                    page: params["p"] || 1,
                    query: JsonQueryString.decode(params["query"]),
                });
            }

            componentWillReceiveProps(nextProps) {
                const { entityStore, routing } = nextProps;
                const { browseStore } = entityStore;

                const params = getQueryStringParams(routing.location.search);
                browseStore.loadEntities({
                    page: params["p"] || 1,
                    query: JsonQueryString.decode(params["query"]),
                });
            }

            render() {
                const { entityStore, routing } = this.props;
                const { lifecycleStore, browseStore } = entityStore;
                const { loading, error, breadcrumb, query, pagination, entities } = browseStore;

                return (
                    <AppPage
                        error={error}
                        breadcrumb={breadcrumb}
                        loading={loading}
                        hasSidebar={false}
                        className="entities list has--page-nav"
                    >
                        <EntitySideNav
                            query={query}
                            hideAgentActions={!lifecycleStore.showAgentActions}
                            onLinkClick={(item) => this.props.routing.push(item.url)}
                            onNewEntity={() => this._onNewEntity()}
                        />

                        <>
                            <EntityCommandBar
                                filterQuery={query}
                                filterContext={{
                                    processing: loading,
                                    resultCount: pagination.totalItemCount,
                                }}
                                onFilterChange={(q, f) => {
                                    routing.push(JsonQueryString.build("/entities/browse", q));
                                }}
                                hideAgentActions={!lifecycleStore.showAgentActions}
                                onNewEntity={() => this._onNewEntity()}
                            />

                            <EntityGridView
                                entities={entities}
                                loading={loading}
                                query={query}
                                onEntityClick={(entity) => routing.push(`/entities/browse/${entity.id}`)}
                                onWatchlistChange={(item, add) => browseStore.toggleWatchlist(item, add)}
                            />

                            <ObservablePagination
                                {...pagination}
                                onPageChange={(index) => {
                                    routing.push(JsonQueryString.build("/entities/browse", query, index + 1));
                                }}
                            />
                        </>
                        <EntityNewPanel onAfterSave={(entity) => routing.push(`/entities/browse/${entity.id}`)} />
                    </AppPage>
                );
            }

            _onNewEntity() {
                const { newStore } = this.props.entityStore;
                newStore.show({});
            }
        }
    )
);
