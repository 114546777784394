import { observable, action, computed } from "mobx";

import { ModellingService } from "../../../api/modelling";
import { PatternEditorStore } from "./PatternEditorStore";

export class PatternAdvisoryEditStore {
    public parentStore: PatternEditorStore;
    public modellingService: ModellingService;

    @observable public advisory: any = null;
    @observable public measure: any = null;
    @observable public snapshot: any = null;
    @observable public title: string = null;
    @observable public visible: boolean = false;
    public options: any;

    constructor(parentStore: PatternEditorStore) {
        this.parentStore = parentStore;
        this.modellingService = parentStore.modellingService;
    }

    @computed
    public get isDirty() {
        return JSON.stringify(this.snapshot) != JSON.stringify(this.advisory);
    }

    @computed
    public get isValid() {
        if (!this.advisory) {
            return false;
        }

        return (
            this.advisory.title &&
            this.advisory.description &&
            this.advisory.mitigations &&
            this.advisory.control &&
            this.advisory.control.id &&
            this.advisory.level
        );
    }

    @action
    public show(options) {
        const promise = new Promise((resolve, reject) => {
            this.options = {
                resolve,
                reject,
            };
        });

        const advisory = options.advisory || {
            name: "-",
            control: { id: null, title: null },
            description: null,
            mitigations: null,
            level: "Medium",
            measureId: options.measure ? options.measure.id : null,
            compliantWhen: options.measure && options.measure.type == "Boolean" ? true : null,
            compliantWhenAll: options.measure.type == "MultiChoice" ? false : undefined,
        };

        this.title = options.title;
        this.measure = options.measure;
        this.snapshot = advisory;
        this.advisory = JSON.parse(JSON.stringify(advisory));
        this.visible = true;

        return promise;
    }

    @action
    public hide(options) {
        this.options = null;
        this.title = null;
        this.advisory = null;
        this.measure = null;
        this.visible = false;
    }

    @action
    public onApply(options) {
        this.options.resolve({ success: true, advisory: this.advisory });
        this.hide(options);
    }

    @action
    public onCancel(options) {
        this.options.resolve({ success: false });
        this.hide(options);
    }
}
