import React from "react";
import { observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";

import { ActionButton } from "office-ui-fabric-react/lib/Button";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { Checkbox } from "office-ui-fabric-react/lib/Checkbox";

export const ModuleFieldMenu = observer(({ module, group, measure, advisoryMap, disabled, onListAdvisories, onEditMeasure, onRemoveMeasure, onAddOption }) => {
    return disabled ? null : (
        <Stack horizontal>
            <Stack horizontal grow tokens={{ childrenGap: 15 }} styles={{ root: { margin: "auto 0" } }}>
                <Checkbox
                    label="Allow N/A"
                    checked={measure.naMode == "allow" || measure.naMode == "justify"}
                    onChange={(ev, checked) => {
                        measure.naMode = checked ? "justify" : null;
                    }}
                />
                {(measure.naMode == "allow" || measure.naMode == "justify") && (
                    <Checkbox
                        label="Require N/A justification"
                        checked={measure.naMode == "justify"}
                        onChange={(ev, checked) => {
                            measure.naMode = checked ? "justify" : "allow";
                        }}
                    />
                )}
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 15 }}>
                {(measure.type == "Choice" || measure.type == "MultiChoice") && (
                    <ActionButton
                        styles={{ icon: { fontSize: "12px", fontWeight: "bold" } }}
                        iconProps={{ iconName: "Add" }}
                        onClick={() => {
                            onAddOption({
                                module,
                                group,
                                measure,
                                option: {
                                    key: uuidv4(),
                                    text: "New option",
                                },
                            });
                        }}
                    >
                        Add option
                    </ActionButton>
                )}
                <ActionButton
                    styles={{ icon: { fontSize: "12px", fontWeight: "bold" } }}
                    iconProps={{ iconName: "Encryption" }}
                    onClick={() => {
                        onListAdvisories({
                            module,
                            group,
                            measure,
                        });
                    }}
                >
                    Advisories ({advisoryMap[measure.id] ? advisoryMap[measure.id].length : 0})
                </ActionButton>
                <ActionButton
                    styles={{ icon: { fontSize: "12px", fontWeight: "bold" } }}
                    iconProps={{ iconName: "Edit" }}
                    onClick={() => {
                        onEditMeasure({
                            module,
                            group,
                            measure,
                        });
                    }}
                >
                    Edit
                </ActionButton>
                <ActionButton
                    styles={{ icon: { fontSize: "12px", fontWeight: "bold" } }}
                    iconProps={{ iconName: "Delete" }}
                    disabled={advisoryMap[measure.id] && advisoryMap[measure.id].length > 0}
                    onClick={() => {
                        onRemoveMeasure({
                            module,
                            group,
                            measure,
                        });
                    }}
                >
                    Remove
                </ActionButton>
            </Stack>
        </Stack>
    );
});
