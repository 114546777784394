import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { useStores } from "@hooks/useStores";

import { AppPage } from "@modules/layout/containers/AppPage";
import { JsonQueryString } from "@modules/utils/JsonQueryString";
import { ObservablePagination } from "@modules/base/components/ObservablePagination";

import { StrategicControlHeader } from "@modules/controls/components/strategic/StrategicControlHeader";
import { ControlMaturityProgress } from "@modules/controls/components/core/ControlMaturityProgress";

import { StrategicControlTabList } from "@modules/controls/containers/tabs/StrategicControlTabList";
import { DeploymentNewPanel } from "@modules/controls/containers/panels/DeploymentNewPanel";
import { MilestoneNewPanel } from "@modules/controls/containers/panels/MilestoneNewPanel";

export const StrategicControlViewerPage = withRouter(
    observer(({ match }) => {
        const { pageContext, routing, controlStore } = useStores();
        const { strategicViewerStore } = controlStore;
        const { breadcrumb, error, control, parent, loading, selectedTab } = strategicViewerStore;

        useEffect(() => {
            if (match.params.id) {
                strategicViewerStore.loadControl({ id: match.params.id });
            }
        }, [match.params.id]);

        useEffect(() => {
            strategicViewerStore.selectTab(match.params.tab);
        }, [match.params.id, match.params.tab]);

        return (
            <AppPage
                loading={loading}
                breadcrumb={breadcrumb}
                error={error}
                hasPageNav={false}
                hasSidebar={false}
                className="controls list strategic viewer"
            >
                {control && (
                    <>
                        <StrategicControlHeader
                            control={control}
                            parent={parent}
                            canDeploy={(options) => controlStore.deploymentNewFormStore.show(options)}
                            onDeploy={(options) => controlStore.deploymentNewFormStore.show(options)}
                            canMilestone={(options) => controlStore.milestoneNewFormStore.show(options)}
                            onMilestone={(options) => controlStore.milestoneNewFormStore.show(options)}
                        />
                        <ControlMaturityProgress control={control} />
                        <StrategicControlTabList control={control} selectedTab={selectedTab} />

                        <DeploymentNewPanel store={controlStore.deploymentNewFormStore} />
                        <MilestoneNewPanel store={controlStore.milestoneNewFormStore} />
                    </>
                )}
            </AppPage>
        );
    })
);
