import React, { useEffect, useCallback } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { RiskNewPanel } from "@modules/risks/containers/panels/RiskNewPanel";

import { AdvisoryCommandBar } from "@modules/assessments/components/advisories/AdvisoryCommandBar";
import { AdvisoryGridView } from "@modules/assessments/components/advisories/AdvisoryGridView";
import { AdvisoryProgress } from "@modules/assessments/components/advisories/AdvisoryProgress";
import { AdvisoryActionBar } from "@modules/assessments/components/advisories/AdvisoryActionBar";
import { AdvisoryViewPanel } from "@modules/assessments/containers/panels/AdvisoryViewPanel";
import { EvidenceEditPanel } from "@modules/assessments/containers/panels/EvidenceEditPanel";

export const AssessmentAdvisoriesTab = observer(({ assessment, workItem }) => {
    const { assessmentStore, riskStore } = useStores();
    const { evidenceStore, advisoryStore, lifecycleStore, permissionStore } = assessmentStore;
    const { editStore, typeStore } = riskStore;
    const assessmentId = assessment ? assessment.id : null;
    const {
        selectedAdvisory,
        advisories,
        selectedAdvisories,
        allAdvisoriesClosed,
        allRequiredRisksRaised,
        requiredEvidencesProvided,
        gridView,
        searchKeywords,
        riskMappings,
        loading,
        loadingRiskMappings,
        evidenceMappings,
    } = advisoryStore;

    useEffect(() => {
        if (assessmentId) {
            advisoryStore.loadAssessmentData(assessmentId);
        }
    }, [assessmentId, advisoryStore]);

    const onEvidencesCrated = useCallback(
        (evidences) => {
            if (assessmentId) {
                advisoryStore.loadAdvisoryEvidences(assessmentId);
            }
        },
        [assessmentId, advisoryStore]
    );

    const onRiskSaved = useCallback(
        (evidences) => {
            if (assessmentId) {
                advisoryStore.loadAdvisoryRisks(assessmentId);
            }
        },
        [assessmentId, advisoryStore]
    );

    const onSelectionChanged = useCallback(
        (selection) => {
            if (assessmentId) {
                advisoryStore.setSelectedAdvisories({
                    count: selection.getSelectedCount(),
                    selected: selection.getSelection(),
                    reset: () => selection.setAllSelected(false),
                });
            }
        },
        [assessmentId, advisoryStore]
    );

    const onGridViewChanged = useCallback(
        (view) => {
            if (assessmentId) {
                advisoryStore.setGridView(view);
                if (advisoryStore.selectedAdvisories) {
                    advisoryStore.selectedAdvisories.reset();
                }
            }
        },
        [assessmentId, advisoryStore]
    );

    const onAddRisk = useCallback(
        (options) => {
            if (assessment && workItem) {
                const o = Object.assign(
                    {
                        clearData: true,
                        allowReview: false,
                        disableTarget: true,
                        disablePortfolio: true,
                        disableRegistry: true,
                        disableTrigger: true,
                        trigger: "Assessment",
                        portfolio: assessment.portfolio,
                        target: assessment.target,
                        advisories: advisoryStore.selectedAdvisories,
                        assessment: assessment,
                        lifecycle: workItem
                            ? {
                                  businessContact: {
                                      ...workItem.businessContact,
                                  },
                                  technicalContact: {
                                      ...workItem.technicalContact,
                                  },
                                  assignedToUserId: workItem.assignedToUser.id,
                                  assignedToGroupId: workItem.assignedToGroup.id,
                              }
                            : {},
                    },
                    options
                );

                editStore.show(o);
            }
        },
        [assessment, workItem, advisoryStore, editStore]
    );

    const onAddEvidence = useCallback(() => {
        evidenceStore.show({
            selectedAdvisories: advisoryStore.selectedAdvisories,
        });
    }, [assessmentId, evidenceStore, advisoryStore]);

    const onMarkAs = useCallback(
        (final) => {
            advisoryStore.markAs(final);
        },
        [assessmentId, advisoryStore]
    );

    const onSearchKeywordsChange = useCallback(
        (keywords) => {
            advisoryStore.setSearchKeywords(keywords);
        },
        [assessmentId, advisoryStore]
    );

    const onAdvisoryClick = useCallback(
        (advisory) => {
            advisoryStore.show({ advisory });
        },
        [assessmentId, advisoryStore]
    );

    return (
        <>
            <AdvisoryCommandBar
                assessment={assessment}
                view={gridView}
                keywords={searchKeywords}
                onViewChanged={onGridViewChanged}
                onSearchChange={onSearchKeywordsChange}
                advisories={advisories}
                allRequiredRisksRaised={allRequiredRisksRaised}
                selectedAdvisories={selectedAdvisories}
                allAdvisoriesClosed={allAdvisoriesClosed}
                canUpdateAdvisories={() => lifecycleStore.canUpdateAdvisories}
                onMarkAs={onMarkAs}
                riskTypes={typeStore.assessmentTypes}
                canAddRisk={() => lifecycleStore.canAddRisk}
                onAddRisk={onAddRisk}
                canAddEvidence={() => lifecycleStore.canAddEvidence}
                onAddEvidence={onAddEvidence}
            />

            <AdvisoryProgress assessment={assessment} advisories={advisories} />

            {!loading && !loadingRiskMappings && (
                <AdvisoryActionBar
                    className="animated fadeIn"
                    assessment={assessment}
                    isAssignedToUser={permissionStore.isAssignedToUser}
                    advisories={advisories}
                    canReview={() => lifecycleStore.canReview}
                    onReview={() => lifecycleStore.onReview()}
                    canClose={() => lifecycleStore.canClose}
                    onClose={() => lifecycleStore.onClose()}
                />
            )}

            <AdvisoryGridView
                loading={loading}
                assessment={assessment}
                advisories={advisories}
                riskMappings={riskMappings}
                evidenceMappings={evidenceMappings}
                view={gridView}
                keywords={searchKeywords}
                onAdvisoryClick={onAdvisoryClick}
                onSelectionChanged={onSelectionChanged}
            />

            {selectedAdvisory && <AdvisoryViewPanel requiredEvidencesProvided={requiredEvidencesProvided} />}

            <EvidenceEditPanel onAfterSave={onEvidencesCrated} />

            <RiskNewPanel onRiskCreated={onRiskSaved} />
        </>
    );
});
