import React, { Component } from 'react';
import { cx, css } from 'emotion';

import { Text } from 'office-ui-fabric-react/lib/Text';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';

import { NavigationList } from '@baseComponents/NavigationList';
import { BigSearchBox } from '@baseComponents/BigSearchBox';

const rootClassName = cx(
  'cygraph-AssessmentSideNav-root',
  css` 
    & .container {
      padding: 10px 0;
    }
    & .sidebar--inner {
      display: flex;
      flex-direction: column;
    }
    & .navigation {
      flex-grow: 1;
      margin-bottom: 15px;
      overflow: auto;
    }
    & .launch-pad button {
      width: 100%;
      text-align: left;
      height: 40px;
      font-size: 15px;
    }
  `
);

const bigSearchBoxClassName = cx('row--l', 'cyAssessmentSearchBar',
    css`
        margin-bottom: 15px !important;
    `
);

export class AssessmentSideNav extends Component {

  render () {
    const { className, canViewResourcePages, canViewCalendarPages, loading, onNewTriage, canViewAdvisoryPages } = this.props;

    const navLinkGroups = [
      {
        name: 'Assurance Management',
        key: 'assurance',
        url: '/assurance/dashboard',
        hideGrouping: true,
        links: [
          {
            name: 'Assurance Dashboard',
            key: 'dashboard',
            url: '/assurance/dashboard',
            iconProps: { iconName: 'ViewDashboard' }
          },
          {
            name: 'Browse Assessments',
            key: 'browse',
            url: '/assurance/browse',
            iconProps: { iconName: 'ViewList' }
          },
        ]
      },
    ];

    if(canViewCalendarPages) {
      navLinkGroups[0].links.push({
        name: 'Assessment Calendar',
        key: 'calendar',
        url: '/assurance/calendar',
        iconProps: { iconName: 'Calendar' }
      });
    }

    if(canViewResourcePages) {
      navLinkGroups[0].links = [...navLinkGroups[0].links, ...[ {
        name: 'Resource Timeline',
        key: 'resource-timeline',
        url: '/assurance/timeline',
        iconProps: { iconName: 'TimelineDelivery' }
      },
      {
        name: 'Resource Workload',
        key: 'resource-workload',
        url: '/assurance/workload',
        iconProps: { iconName: 'WorkforceManagement' }
      }]];
    }

    if(canViewCalendarPages) {
      navLinkGroups[0].links.push({
        name: 'Portfolio Timeline',
        key: 'portfolio-timeline',
        url: '/assurance/portfolios',
        iconProps: { iconName: 'FabricNetworkFolder' }
      });
    }

    if (canViewAdvisoryPages) {
      navLinkGroups[0].links.push({
        name: 'Browse Advisories',
        key: 'browse-advisories',
        url: '/advisories/browse',
        iconProps: { iconName: 'Questionnaire' }
      });
    }

    return (
      loading ? null :
      <div className={cx(rootClassName, className, "sidebar sidebar--left sidebar--nav")}>
        <div className="sidebar--inner scrollbar--root">
          <div className="container">
            <Text className="title" variant="xLarge" block>Assurance<br/>Management</Text>
          </div>

          <BigSearchBox
            className={bigSearchBoxClassName}
            placeholder="Search for assessments"
            url="/assurance/browse" />

          <div className="navigation scrollbar--root" data-test="assessmentNavBar">
            <NavigationList
              onLinkClick={this.props.onLinkClick}
              ariaLabel="Assurance management navigation"
              groups={navLinkGroups}
            />
          </div>
          { onNewTriage && (
            <div className="launch-pad">
              <PrimaryButton 
                data-test="newAssessmentTriageButton"
                text="New Assessment Triage"
                iconProps={{ iconName: 'Add' }}
                onClick={onNewTriage} />
            </div>
          ) }
        </div>
      </div>
    );
  }
};