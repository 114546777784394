import { flow, observable } from "mobx";

import { RootStore } from "@modules/base/RootStore";
import { ActivityService } from "@api/activities";
import { PipeService } from "@api/pipes";

export class ActivityStore {
    public rootStore: RootStore;
    public activityService: ActivityService;
    public pipeService: PipeService;
    public webSocket: WebSocket;
    @observable public connected: boolean = false;
    public ackId = 0;
    public attempts = 0;

    public listeners: any[] = [];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.activityService = new ActivityService(rootStore.authProvider);
        this.pipeService = new PipeService(rootStore.authProvider);
    }

    public connect = flow(function* () {
        if (this.connected && this.webSocket) {
            return this.webSocket;
        }

        this.attempts++;
        const result = yield this.pipeService.negotiate();
        this.webSocket = new WebSocket(result.uri, "json.webpubsub.azure.v1");

        const promise = new Promise((resolve) => {
            this.webSocket.onopen = () => this.onPipeOpen().then(() => resolve(this.webSocket));
            this.webSocket.onmessage = (ev) => this.onPipeMessage(ev);
            this.webSocket.onclose = (e) => this.onPipeClose(e);
            this.webSocket.onerror = (err) => this.onPipeError(err);
        });

        return promise;
    });

    public disconnect = flow(function* () {
        if (this.webSocket) {
            this.webSocket.onopen = null;
            this.webSocket.onmessage = null;
            this.webSocket.onclose = null;
            this.webSocket.onerror = null;
            this.webSocket.close();
            this.webSocket = null;
        }
    });

    public onPipeOpen = flow(function* () {
        console.log("activity message pipe opened");
        this.connected = true;
        this.attempts = 0;
    });

    public onPipeMessage = flow(function* (ev) {
        const eventData = JSON.parse(ev.data);
        if (eventData.type === "system" && eventData.event === "connected") {
            console.log("activity message pipe connected");
            this.rejoinGroups();
        } else if (eventData.from === "group" && eventData.group) {
            if (eventData.data && eventData.data.message && eventData.data.message.context) {
                this.listeners.forEach((listener) => {
                    if (listener.context.id === eventData.data.message.context.id) {
                        listener.listener.onData(eventData, eventData.data);
                    }
                });
            }
        }
    });

    public onPipeClose = flow(function* (e) {
        console.log("activity message pipe closed", e.reason);
        this.connected = false;
        if (this.listeners.length !== 0) {
            console.log("recovering");
            setTimeout(
                () => {
                    this.connect();
                },
                this.attempts < 30 ? 2000 : 10 ** (this.attempts - 30)
            );
        }
    });
    public onPipeError = flow(function* (err) {
        console.log("activity message pipe error", err);
        this.webSocket.close();
    });

    public rejoinGroups = flow(function* () {
        if (this.webSocket && this.webSocket.readyState === 1) {
            this.listeners.forEach((listener) => {
                this.webSocket.send(
                    JSON.stringify({
                        type: "event",
                        event: "join-context",
                        ackId: this.ackId++,
                        dataType: "json",
                        data: {
                            instanceId: listener.instanceId,
                            context: listener.context,
                            data: {},
                        },
                    })
                );
            });
        }
    });

    public addListener = flow(function* (options) {
        console.log("addListener called", options);
        this.listeners.push(options);

        const webSocket = yield this.connect();
        if (webSocket && webSocket.readyState === 1) {
            webSocket.send(
                JSON.stringify({
                    type: "event",
                    event: "join-context",
                    ackId: this.ackId++,
                    dataType: "json",
                    data: {
                        instanceId: options.instanceId,
                        context: options.context,
                        data: {},
                    },
                })
            );
        }
    });

    public removeListener = flow(function* (options) {
        console.log("removeListener called", options);
        this.listeners = this.listeners.filter((l) => l.instanceId !== options.instanceId);

        if (this.listeners.length === 0) {
            this.disconnect();
        }
    });
}
