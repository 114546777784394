import React from "react";
import Dropzone from "react-dropzone";
import { cx } from "emotion";

export const DropZone = ({ children, openTriggerRef, ...props }) => {
    const options = { multiple: true, noClick: true, ...props };
    return (
        <Dropzone {...options}>
            {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, open }) => {
                if (openTriggerRef) {
                    openTriggerRef.current = open;
                }
                return (
                    <div
                        {...getRootProps()}
                        className={cx(
                            "dropzone",
                            isDragActive && "active",
                            isDragAccept && "accept",
                            isDragReject && "reject"
                        )}
                    >
                        <input {...getInputProps()} />
                        {children}
                    </div>
                );
            }}
        </Dropzone>
    );
};
