import { observable, action, computed } from "mobx";

import { PageService } from "../../../api/pages";
import { PageInstanceStore } from "./PageInstanceStore";

export class PagePartEditStore {
    private pageService: PageService;
    public parentStore: PageInstanceStore;

    @observable public visible: boolean = false;
    @observable public formData: any;
    @observable public formOptions: any;
    @observable public saving: boolean = false;

    constructor(parentStore: PageInstanceStore) {
        this.parentStore = parentStore;
        this.pageService = parentStore.pageService;
    }

    @computed
    public get isValid() {
        if(!this.formData || !this.formData.type || !this.formData.title || !this.formData.chrome || !this.formData.theme){
            return false;
        }

        const registration = this.parentStore.lifecycleStore.getRegistration(this.formData);
        if(!registration) {
            return false;
        }

        if(registration.validator) {
            return registration.validator(this.formData, this.parentStore);
        }

        return true;
    }

    @action
    public show(options) {
        this.formData = JSON.parse(JSON.stringify(options.part));

        const promise = new Promise( (resolve, reject) => {
            this.formOptions = { resolve, reject, pageTitle: options.page.title };
        });

        this.visible = true;
        return promise;
    }

    @action
    public hide(options) {
        this.formData = null;
        this.formOptions = null;
        this.visible = false;
    }
}
