import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';

import { DragDropContext } from 'react-beautiful-dnd';

import { RiskBoardColumn } from './RiskBoardColumn';

const rootClassName = cx(
    'cygraph-RiskBoardView-root',
    css` 
        margin-bottom: var(--spacing-l);
        margin-top: var(--spacing-s);

        & .column-container {
            display: flex;
        }
    `
);

export const RiskBoardView = observer(
    class RiskBoardView extends Component {
        
        constructor(props) {
            super(props);
            this.state = { enabledColumns: [] };
        }

        render () {
            const { board, className, onCardClick, onRenderCard } = this.props;
            const columns = Object.keys(board);

            return (
                <div className={cx(className, rootClassName)}>
                    <DragDropContext 
                        onDragEnd={this._onDragEnd}
                        onDragStart={this._onDragStart}>
                        <r-grid columns={columns.length}>
                            { columns.map((id) => {
                                const column = board[id];
                                return (
                                <r-cell class="column-container" key={id}>
                                    <RiskBoardColumn 
                                        id={id}
                                        loading={column.loading} 
                                        title={column.title}
                                        items={column.items} 
                                        onCardClick={onCardClick}
                                        isDropDisabled={this._isColumnDisabled}
                                        isDragDisabled={!column.allowDrag}
                                        onRenderCard={onRenderCard} />
                                </r-cell>);
                        }) }
                        </r-grid>
                    </DragDropContext>
                </div>
            );
        }

        _isColumnDisabled = (column) => {
            return this.state.enabledColumns.indexOf(column) === -1;
        };

        _onDragStart = result => {
            const { board } = this.props;
            this.setState({
                enabledColumns: board[result.source.droppableId].allowedDrops || []
            });            
        };

        _onDragEnd = (result) => {
            const { onCardMoved } = this.props;

            if (!result.destination || result.reason != 'DROP' || result.source.droppableId == result.destination.droppableId) {
                return;
            }
            
            if(onCardMoved) {
                onCardMoved({ 
                    itemId: result.draggableId, 
                    fromId: result.source.droppableId, 
                    fromIndex: result.source.index,
                    toId: result.destination.droppableId,
                    toIndex: result.destination.index 
                });
            }
        };
    }
);