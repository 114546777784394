import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { ObservablePagination } from "@modules/base/components/ObservablePagination";

import { ControlSetCardView } from "@modules/controls/components/sets/ControlSetCardView";
import { ControlSetCommandBar } from "@modules/controls/components/sets/ControlSetCommandBar";

export const ControlsFrameworksTab = observer(() => {
    const { routing, controlStore, pageContext } = useStores();
    const { setBrowseStore } = controlStore;
    const { loading, keywords, pagination, sets } = setBrowseStore;

    useEffect(() => {
        pageContext.updateQueryStringParams(routing.location.search);
        setBrowseStore.loadControlSets({
            page: pageContext.queryStringParams.p || 1,
            keywords: pageContext.queryStringParams.k,
        });
    }, []);

    return (
        <>
            <ControlSetCommandBar
                keywords={keywords}
                onSearchChange={(keywords) => {
                    if (keywords) {
                        routing.push(`/settings/control-sets?k=${encodeURIComponent(keywords)}`);
                    } else {
                        routing.push(`/settings/control-sets`);
                    }
                }}
            />
            <BoxPanel shadow padding small style={{ minHeight: 300 }}>
                <ControlSetCardView
                    sets={sets}
                    loading={loading}
                    onControlSetClick={(item) => routing.push(`/settings/control-sets/${item.id}`)}
                    query={{}}
                />
            </BoxPanel>
            <ObservablePagination
                {...pagination}
                onPageChange={(index) => {
                    routing.push(
                        `/settings/control-sets?p=${index + 1}${keywords ? `&k=${encodeURIComponent(keywords)}` : ""}`
                    );
                }}
            />
        </>
    );
});
