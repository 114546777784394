import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion'
import moment from 'moment';
import Moment from 'react-moment';

import { BoxPanel } from '../../../base/components/BoxPanel';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { MessageBar } from 'office-ui-fabric-react/lib/MessageBar';
import { DetailsListLayoutMode, Selection, SelectionMode, ConstrainMode } from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { ContextualMenu } from 'office-ui-fabric-react/lib/ContextualMenu';
import { Persona, PersonaSize } from 'office-ui-fabric-react/lib/Persona';

export const VersionGridView = observer(
    class VersionGridView extends Component {
        constructor(props) {
            super(props);

            this.state = {};

            this._selection = new Selection({
              onSelectionChanged: () => {
                if(this.props.onSelectionChanged) {
                  this.props.onSelectionChanged(this._selection);
                }
              }
            });
        }

        render () {
            const { loading, className, onAdvisoryClick } = this.props;
            const { contextualMenuProps } = this.state;
            

            this._columns = [
                {
                  key: 'version',
                  name: 'Version',
                  fieldName: 'version',
                  minWidth: 50,
                  maxWidth: 80,
                  isRowHeader: true,
                  isResizable: true,
                  onColumnClick: this._onColumnClick,
                  data: 'string',
                  onRender: (item) => {
                    return (
                      <div className={cx(
                        'advisory-title',
                        css`
                          white-space: initial;
                        `
                      )}>
                        <a href="javascript:" onClick={() => this.props.onAdvisoryClick(item)}>{item.version}</a><br/>
                      </div>
                      );
                  },
                  isPadded: true
                },
                {
                    key: 'notes',
                    name: 'Notes',
                    fieldName: 'notes',
                    minWidth: 230,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: 'string',
                    onRender: (item) => {
                      return <div className={cx(
                        'advisory-control',
                        css`
                          white-space: initial;
                        `
                      )}>{item.notes}</div>;
                    },
                    isPadded: false
                },
                {
                  key: 'createdBy',
                  name: 'Created By',
                  fieldName: 'createdBy',
                  minWidth: 230,
                  maxWidth: 260,
                  isResizable: false,
                  onColumnClick: this._onColumnClick,
                  onRender: (item) => {
                    return (
                      <Persona 
                        size={PersonaSize.size28} 
                        text={item.createdBy.name} 
                        showSecondaryText={true} 
                        secondaryText={item.createdBy.jobTitle} />
                    );
                  },
                  isPadded: false
                },
                {
                  key: 'created',
                  name: 'Date Created',
                  fieldName: 'created',
                  minWidth: 100,
                  maxWidth: 130,
                  isResizable: true,
                  isSorted: true,
                  isSortedDescending: true,
                  sortAscendingAriaLabel: 'Sorted A to Z',
                  sortDescendingAriaLabel: 'Sorted Z to A',
                  onColumnClick: this._onColumnClick,
                  data: 'date',
                  onRender: (item) => {
                    return <Moment date={item.created} fromNow />;
                  },
                  isPadded: true
                }
            ];

            const filtered = this._getFilteredVersions();

            return (
              <BoxPanel shadow className={className}>
                <ShimmeredDetailsList
                  items={filtered}
                  compact={false}
                  columns={this._columns}
                  selectionMode={SelectionMode.multiple}
                  getKey={this._getKey}
                  setKey="multiple"
                  layoutMode={DetailsListLayoutMode.justified}
                  constrainMode={ConstrainMode.horizontalConstrained}
                  isHeaderVisible={true}
                  selection={this._selection}
                  selectionPreservedOnEmptyClick={true}
                  onItemInvoked={onAdvisoryClick}
                  //onItemContextMenu={(item, index, ev) => this._onItemContextMenu(item, index, ev) }
                  enterModalSelectionOnTouch={true}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  checkButtonAriaLabel="Row checkbox"
                  enableShimmer={loading}
                  removeFadingOverlay={true}
                  shimmerLines={10}
                  ariaLabelForShimmer="Versions are being fetched"
                  ariaLabelForGrid="Version list"
                />
                
                { ! loading && !filtered.length && (
                  <MessageBar>
                    There are no matching advisories.
                  </MessageBar>
                )}

                {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}
              </BoxPanel> 
            );
        }

        _getFilteredVersions() {
          const { versions, view, keywords } = this.props;

          var filtered = versions;

          if(keywords) {
            filtered = filtered.filter(a => a.title.toLowerCase().indexOf(keywords.toLowerCase()) != -1);
          }

          return filtered;
        }

        _getKey(item, index) {
            return item ? item.id : index;
        }

        _getSelectionDetails() {
            const selectionCount = this._selection.getSelectedCount();
        
            switch (selectionCount) {
              case 0:
                return 'No items selected';
              case 1:
                return '1 item selected: ' + (this._selection.getSelection()[0]).name;
              default:
                return `${selectionCount} items selected`;
            }
        }

        _onColumnClick(ev, column) {
            //console.log(column);
        }

        _onItemContextMenu(item, index, ev) {
            const contextualMenuProps = {
              target: ev.target,
              items: [
                {
                  key: 'text',
                  name: `${this._selection.getSelectedCount()} selected`
                }
              ],
              onDismiss: () => {
                this.setState({
                  contextualMenuProps: undefined
                });
              }
            };
        
            if (index > -1) {
              this.setState({
                contextualMenuProps: contextualMenuProps
              });
            }
        
            return false;
          };
    }
);