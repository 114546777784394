import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { useStores } from "@hooks/useStores";

import { Text } from "office-ui-fabric-react/lib/Text";

import { AppPage } from "@modules/layout/containers/AppPage";

import { SettingsListNav } from "@modules/settings/containers/utilities/SettingsListNav";

import { AssuranceSettingsPivot } from "@modules/settings/containers/tabs/AssuranceSettingsPivot";
import { AssuranceSettingsTabList } from "@modules/settings/containers/tabs/AssuranceSettingsTabList";

export const AssuranceSettingsPage = withRouter(
    observer(({ match }) => {
        const { settingsStore } = useStores();
        const { assuranceSettingsStore } = settingsStore;
        const { breadcrumb, loading, error, defaultTab, selectedTab, activeTabs } = assuranceSettingsStore;

        useEffect(() => {
            if (match) {
                assuranceSettingsStore.setSelectedTab(match.params.tab);
            }
        }, [match.params.tab]);

        return (
            <AppPage
                loading={loading}
                error={error}
                breadcrumb={breadcrumb}
                hasSidebar={false}
                className="settings demand has--page-nav"
            >
                <SettingsListNav />
                <div>
                    <Text className="row--xxs" variant="xLarge" block>
                        Assurance Management
                    </Text>
                    <Text className="row--s" variant="small" block>
                        Assurance management module allows you to perform point in time assessments against controls
                        adopted by your organisation. Supports customisable controls frameworks and various
                        architectural use cases.
                    </Text>

                    <AssuranceSettingsPivot defaultTab={defaultTab} selectedTab={selectedTab} activeTabs={activeTabs} />
                    <AssuranceSettingsTabList />
                </div>
            </AppPage>
        );
    })
);
