
import { OneColumnLayout } from './OneColumnLayout';
import { TwoColumnsLayout } from './TwoColumnsLayout';
import { ThreeColumnsLayout } from './ThreeColumnsLayout';
import { FourColumnsLayout } from './FourColumnsLayout';
import { FiveColumnsLayout } from './FiveColumnsLayout';
import { OneThirdLeftLayout } from './OneThirdLeftLayout';
import { OneThirdRightLayout } from './OneThirdRightLayout';

export const layouts: any = {
    'one-column': {
        name: 'One column',
        icon: 'SingleColumn',
        supportedZones: ['main'], defaultZone: 'main',
        control: OneColumnLayout
    },
    'two-columns': {
        name: 'Two columns',
        icon: 'DoubleColumn',
        supportedZones: ['left', 'right'], defaultZone: 'left',
        control: TwoColumnsLayout
    },
    'three-columns': {
        name: 'Three columns',
        icon: 'TripleColumn',
        supportedZones: ['left', 'middle', 'right'], defaultZone: 'left',
        control: ThreeColumnsLayout
    },
    'four-columns': {
        name: 'Four columns',
        icon: 'QuadColumn',
        supportedZones: ['left', 'second', 'thrid', 'right'], defaultZone: 'left',
        control: FourColumnsLayout
    },
    'five-columns': {
        name: 'Five columns',
        icon: 'QuadColumn',
        supportedZones: ['left', 'second', 'middle', 'fourth', 'right'], defaultZone: 'left',
        control: FiveColumnsLayout
    },
    'one-third-left': {
        name: 'One-third left',
        icon: 'ColumnLeftTwoThirds',
        supportedZones: ['left', 'right'], defaultZone: 'left',
        control: OneThirdLeftLayout
    },
    'one-third-right': {
        name: 'One-third right',
        icon: 'ColumnRightTwoThirds',
        supportedZones: ['left', 'right'], defaultZone: 'right',
        control: OneThirdRightLayout
    }
};
