import React from "react";
import { observer } from "mobx-react";
import { cx } from "emotion";

import { Stack } from "@ui/elements/Stack";
import { ShimmerElementType, ShimmerElementsGroup } from "@ui/elements/Shimmer";

export const ActivityStreamListShimmer = observer(({ repeat }) => {
    let tasks = [];
    for (let index = 0; index < repeat; index++) {
        tasks = [
            ...[
                { self: false, attached: false },
                { self: false, attached: true },
                { self: false, attached: false },
                { self: false, attached: true },
                { self: false, attached: true, small: true },
            ],
            ...tasks,
        ];
    }
    return (
        <ul>
            {tasks.map(({ self, attached, small }, i) => {
                return (
                    <li
                        key={i}
                        className={cx("activity--item", self && "activity--self", attached && "activity--attached")}
                    >
                        <Stack className="activity--container" horizontal>
                            {!self && (
                                <Stack.Item className="activity--gutter">
                                    {!attached && (
                                        <div className="activity--avatar">
                                            <div
                                                style={{
                                                    display: "flex",
                                                    borderRadius: "4px",
                                                    background: "var(--border-color)",
                                                    height: "32px",
                                                }}
                                            ></div>
                                        </div>
                                    )}
                                </Stack.Item>
                            )}
                            <Stack.Item grow className="activity--message">
                                {!attached && (
                                    <div className="activity--header">
                                        <div
                                            style={{
                                                display: "flex",
                                                borderRadius: "4px",
                                                border: "2px solid var(--white)",
                                                borderBottom: "4px solid var(--white)",
                                                background: "var(--border-color)",
                                            }}
                                        >
                                            <ShimmerElementsGroup
                                                flexWrap={true}
                                                width="100%"
                                                shimmerElements={[
                                                    {
                                                        type: ShimmerElementType.line,
                                                        width: "35%",
                                                        height: 11,
                                                        verticalAlign: "bottom",
                                                    },
                                                    { type: ShimmerElementType.gap, width: "5%", height: 11 },
                                                    { type: ShimmerElementType.line, width: "30%", height: 11 },
                                                    { type: ShimmerElementType.gap, width: "30%", height: 11 },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className="activity--content">
                                    <div
                                        style={{
                                            display: "flex",
                                            borderRadius: "4px",
                                            border: "2px solid var(--white)",
                                            background: "var(--border-color)",
                                        }}
                                    >
                                        <ShimmerElementsGroup
                                            flexWrap={true}
                                            width="100%"
                                            shimmerElements={
                                                small
                                                    ? [
                                                          {
                                                              type: ShimmerElementType.line,
                                                              width: "70%",
                                                              height: 11,
                                                              verticalAlign: "bottom",
                                                          },
                                                          { type: ShimmerElementType.gap, width: "30%", height: 11 },
                                                      ]
                                                    : [
                                                          {
                                                              type: ShimmerElementType.line,
                                                              width: "100%",
                                                              height: 11,
                                                              verticalAlign: "bottom",
                                                          },
                                                          { type: ShimmerElementType.line, width: "90%", height: 11 },
                                                          { type: ShimmerElementType.gap, width: "10%", height: 20 },
                                                      ]
                                            }
                                        />
                                    </div>
                                </div>
                            </Stack.Item>
                        </Stack>
                    </li>
                );
            })}
        </ul>
    );
});
