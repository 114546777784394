import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import Moment from "react-moment";

import { css } from "@uifabric/utilities/lib/css";
import { Label } from "office-ui-fabric-react/lib/Label";

import { AppPage } from "@layout/containers/AppPage";
import { ObservableSeparator } from "@baseComponents/ObservableSeparator";
import { ObservablePagination } from "@baseComponents/ObservablePagination";
import { BoxPanel } from "@baseComponents/BoxPanel";
import { SettingsListNav } from "@modules/settings/containers/utilities/SettingsListNav";

import { ControlHeader } from "../../components/core/ControlHeader";
import { ControlGridView } from "../../components/core/ControlGridView";

const getQueryStringParams = (query) => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query).split("&").reduce((params, param) => {
              let [key, value] = param.split("=");
              params[key] = value ? decodeURIComponent(value.replace(/\+/g, " ")) : "";
              return params;
          }, {})
        : {};
};

export const ControlViewerPage = inject(
    "pageContext",
    "routing",
    "controlStore"
)(
    withRouter(
        observer(
            class ControlViewerPage extends Component {
                componentWillMount() {
                    const { match, routing, controlStore } = this.props;
                    const params = getQueryStringParams(routing.location.search);
                    controlStore.viewerStore.loadControl({ page: params["p"] || 1, keywords: params["k"], parentId: match.params.id });
                }

                componentWillReceiveProps(nextProps) {
                    const { match, routing, controlStore } = nextProps;
                    const params = getQueryStringParams(routing.location.search);
                    controlStore.viewerStore.loadControl({ page: params["p"] || 1, keywords: params["k"], parentId: match.params.id });
                }

                render() {
                    const { routing, controlStore } = this.props;
                    const { loading, saving, error, breadcrumb, pagination, set, controls, control } = controlStore.viewerStore;

                    return (
                        <AppPage
                            loading={loading || saving}
                            error={error}
                            breadcrumb={breadcrumb}
                            hasSidebar={false}
                            className={css("control viewer", !error && "has--page-nav")}
                        >
                            <SettingsListNav />
                            <div>
                                {set && control && <ControlHeader mode={"view"} set={set} control={control} hideActions />}

                                {set && control && (
                                    <div className="row--s">
                                        <ObservableSeparator>Control</ObservableSeparator>
                                        <BoxPanel shadow padding>
                                            <div className="row--s">
                                                <r-grid columns="3">
                                                    <r-cell span="3">
                                                        <div>
                                                            <Label>Description</Label>
                                                            <div>{control.description || "N/A"}</div>
                                                        </div>
                                                    </r-cell>
                                                    <r-cell>
                                                        <div>
                                                            <Label>Control Set</Label>
                                                            <div>{set.name}</div>
                                                        </div>
                                                    </r-cell>
                                                    <r-cell span="2">
                                                        <div>
                                                            <Label>Tags</Label>
                                                            <div>{control.tags.length > 0 ? control.tags.join(", ") : "None"}</div>
                                                        </div>
                                                    </r-cell>
                                                    <r-cell>
                                                        <div>
                                                            <Label>Active?</Label>
                                                            <div>{control.active ? "Yes" : "No"}</div>
                                                        </div>
                                                    </r-cell>
                                                    <r-cell>
                                                        <div>
                                                            <Label>Modified</Label>
                                                            <div>
                                                                <Moment utc date={control.modified} fromNow />
                                                            </div>
                                                        </div>
                                                    </r-cell>
                                                </r-grid>
                                            </div>
                                        </BoxPanel>
                                    </div>
                                )}

                                {controls && (
                                    <div className="row--s">
                                        <ObservableSeparator>Child Controls</ObservableSeparator>
                                        <ControlGridView
                                            controls={controls}
                                            loading={loading}
                                            onPagePathClick={(item, _) => routing.push(item.path)}
                                            query={{}}
                                        />

                                        {set && control && (
                                            <ObservablePagination
                                                {...pagination}
                                                onPageChange={(index) => {
                                                    routing.push(`/settings/control-sets/${set.id}/${control.id}?p=${index + 1}`);
                                                }}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        </AppPage>
                    );
                }
            }
        )
    )
);
