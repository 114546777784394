import { observable, action } from "mobx";
import download from 'downloadjs';

import { WorkItemService } from '../../../api/workitems';
import { AssessmentStore } from "./AssessmentStore";

export type DocumentGridView = 'all' | 'internalOnly';

export class AssessmentDocumentStore {
    private workItemService: WorkItemService;
    public parentStore: AssessmentStore;

    @observable public documents: any[] = [];
    @observable public selectedDocument: any;
    @observable public gridView: DocumentGridView = 'all';
    @observable public selectedDocuments: any;
    @observable public keywords: string;
    @observable public loading: boolean = false;
    @observable public saving: boolean = false;

    constructor(parentStore: AssessmentStore) {
        this.parentStore = parentStore;
        this.workItemService =  parentStore.rootStore.workItemStore.workItemService;
    }

    @action
    public setSelectedDocuments(selection) {
        this.selectedDocuments = selection;
    }

    @action
    public setGridView(view : DocumentGridView) {
        this.gridView = view;
    }

    @action
    public setSearchKeywords(keywords : string) {
        this.keywords = keywords;
    }

    @action
    public downloadDocument(document: any) {
        return this.workItemService.downloadWorkItemDocument(document.sourceId, document.id)
            .then((blob) => {
                download(blob, `${document.document.name}${document.document.extension}`, document.contentType);
            });
    }

    @action
    public loadDocuments(workItemId: string) {
        this.loading = true;
        this.documents = [];
        return this.workItemService.getWorkItemDocuments(workItemId)
            .then(this.loadDocumentsOnSuccess, this.loadDocumentsOnError);
    }

    @action.bound
    public loadDocumentsOnSuccess(result) {
        this.documents = result;
        this.loading = false;
        return result;
    }

    @action.bound
    public loadDocumentsOnError(error) {
        console.error(error);
        this.loading = false;
        throw error;
    }

    @action
    public uploadDocument(document) {
        this.saving = true;
        return this.workItemService.uploadWorkItemDocument(document)
            .then(this.uploadDocumentOnSuccess, this.uploadDocumentOnError);
    }

    @action.bound
    public uploadDocumentOnSuccess(result) {
        const index = this.documents.findIndex(d => d.id == result.id);
        if(index == -1) {
            this.documents.unshift(result);
        } else {
            this.documents.splice(index, 1, result);
        }
        this.saving = false;
        return result;
    }

    @action.bound
    public uploadDocumentOnError(error) {
        console.error(error);
        this.saving = false;
        throw error;
    }
}
