import React, { Component } from "react";
import { observer } from "mobx-react";

import { Label } from "office-ui-fabric-react/lib/Label";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { Toggle } from "office-ui-fabric-react/lib/Toggle";
import { Dropdown } from "office-ui-fabric-react/lib/Dropdown";
import { ChoiceGroup } from "office-ui-fabric-react/lib/ChoiceGroup";

export const WorkItemField = observer(
    class WorkItemField extends Component {
        render() {
            const { field, visible } = this.props;

            if (!visible) {
                return null;
            }

            switch (field.type) {
                case "String":
                    return this._renderStringField();
                case "Boolean":
                    return this._renderBooleanField();
            }

            return null;
        }

        _renderStringField() {
            const { field, workItem, disabled, readOnly } = this.props;

            if (field.computed) {
                if (readOnly) {
                    return this._renderReadOnly(field.label, workItem[field.name]);
                }

                return (
                    <TextField
                        label={field.label}
                        readOnly={true}
                        disabled={true}
                        placeholder={field.placeholder}
                        defaultValue={workItem[field.name]}
                        multiline={field.multiline}
                        autoAdjustHeight={field.multiline}
                        onChange={(ev, value) => (workItem[field.name] = value)} //value
                    />
                );
            }

            if (field.options && field.options.length) {
                let options = field.options;

                if (!field.dropdown) {
                    if (field.withIcons) {
                        options = options.map((option) => {
                            return {
                                key: option.key,
                                text: option.text,
                                iconProps: { iconName: option.icon },
                            };
                        });
                    }

                    if (readOnly) {
                        const op = options.find((o) => o.key == workItem[field.name]);
                        return this._renderReadOnly(field.label, op ? op.text : "");
                    }

                    return (
                        <ChoiceGroup
                            label={field.label}
                            disabled={disabled}
                            readOnly={readOnly}
                            placeholder={field.placeholder}
                            selectedKey={workItem[field.name]}
                            onChange={(ev, op) => (workItem[field.name] = op.key)}
                            options={options}
                        />
                    );
                }

                if (readOnly) {
                    const op = options.find((o) => o.key == workItem[field.name]);
                    return this._renderReadOnly(field.label, op.text);
                }

                return (
                    <Dropdown
                        label={field.label}
                        disabled={disabled}
                        readOnly={readOnly}
                        placeholder={field.placeholder}
                        selectedKey={workItem[field.name]}
                        onChange={(ev, op) => (workItem[field.name] = op.key)}
                        options={options}
                    />
                );
            }

            if (readOnly) {
                return this._renderReadOnly(field.label, workItem[field.name]);
            }

            return (
                <TextField
                    label={field.label}
                    disabled={disabled}
                    readOnly={readOnly}
                    placeholder={field.placeholder}
                    value={workItem[field.name]}
                    multiline={field.multiline}
                    autoAdjustHeight={field.multiline}
                    onChange={(ev, value) => (workItem[field.name] = value)} //value
                />
            );
        }

        _renderBooleanField() {
            const { field, workItem, disabled, readOnly } = this.props;

            if (readOnly) {
                return this._renderReadOnly(
                    field.label,
                    workItem[field.name] ? field.onText || "Yes" : field.offText || "No"
                );
            }

            return (
                <Toggle
                    label={field.label}
                    disabled={disabled}
                    readOnly={readOnly}
                    placeholder={field.placeholder}
                    defaultChecked={workItem[field.name]}
                    onText={field.onText || "Yes"}
                    offText={field.offText || "No"}
                    onChange={(ev, val) => (workItem[field.name] = val)}
                />
            );
        }

        _renderReadOnly(label, value) {
            return (
                <div>
                    <Label>{label}</Label>
                    <div>{value}</div>
                </div>
            );
        }
    }
);
