import { observable, action, computed, flow } from "mobx";

import { DataSourceStore } from "./DataSourceStore";

export class DataSourceBrowseStore {

    public parentStore: DataSourceStore;

    @observable public breadcrumb: any[] = [
        { text: 'Settings', key: 'settings', onClick: () => this.parentStore.rootStore.routing.push(`/settings`) },
        { text: 'Data Sources', key: 'browse', isCurrentItem: true },
    ];

    @computed public get dataSources() {
        return this.parentStore.selectionStore.dataSources
    }

    constructor(parentStore: DataSourceStore) {
        this.parentStore = parentStore;
    }

}
