import { observable, computed, flow, action } from "mobx";

import { WorkItemService } from "@api/workitems";
import { WorkItemStore } from "./WorkItemStore";
import { WorkItemSelectionStore } from "./WorkItemSelectionStore";
import { WorkItemPermissionStore } from "./WorkItemPermissionStore";
import { isGuest } from "../../identity/stores/PrincipalContextUtilities";

export interface ResponseHandle {
    resolve: (value: any) => void;
    reject: (value?: any) => void;
}

export class WorkItemLifecycleStore {
    private workItemService: WorkItemService;
    public parentStore: WorkItemStore;
    public selectionStore: WorkItemSelectionStore;
    public permissionStore: WorkItemPermissionStore;

    @observable public saving: boolean = false;
    @observable public loading: boolean = false;

    constructor(parentStore: WorkItemStore) {
        this.parentStore = parentStore;
        this.workItemService = parentStore.workItemService;
        this.selectionStore = parentStore.selectionStore;
        this.permissionStore = parentStore.permissionStore;
    }

    @computed
    public get canCreate() {
        const workItem = this.selectionStore.workItem;

        if (!workItem) {
            return false;
        }

        return this.permissionStore.isOwner || this.permissionStore.isAdmin;
    }

    public onCreate = flow(function* (trigger, options) {
        try {
            const store = this.parentStore.newFormStore;
            const { success, formData } = yield store.show({
                ...{ workItem: options.workItem },
                ...options.args,
            });

            if (success && formData) {
                this.saving = true;

                const { documents, ...workItem } = formData;

                const created = yield this.workItemService.createWorkItem({
                    ...workItem,
                    triggerId: trigger.id,
                    ...{ comment: options.comment },
                });

                yield this.parentStore.selectionStore.setSelected(created);
                yield this.parentStore.documentStore.uploadDocuments(documents, created.id, false);

                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Demand request ${created.code} created successfully.`
                );

                store.hide();
                return { success, workItem: created };
            }

            store.hide();
            return { success };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error };
        } finally {
            this.saving = false;
        }
    });

    @computed
    public get canEdit() {
        const workItem = this.selectionStore.workItem;

        if (!workItem || this.saving) {
            return false;
        }

        return workItem.status != "Closed" && workItem.status != "Cancelled" && this.permissionStore.canEdit;
    }

    public onEdit = flow(function* (trigger, options) {
        const workItem = this.selectionStore.workItem;

        if (!workItem) {
            return { success: false };
        }

        try {
            const store = this.parentStore.editFormStore;
            const { success, formData } = yield store.show({
                ...{ workItem },
                ...options.args,
            });

            if (success && formData) {
                this.saving = true;
                const { documents, ...workItem } = formData;

                const updated = yield this.workItemService.updateWorkItem({
                    ...workItem,
                    triggerId: trigger.id,
                    ...{ comment: options.comment },
                });

                yield this.parentStore.selectionStore.setSelected(updated);
                yield this.parentStore.documentStore.uploadDocuments(documents, updated.id, false);

                //yield this.parentStore.commentStore.loadComments(updated.id);

                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Demand request ${updated.code} updated successfully.`
                );

                store.hide();
                return { success, workItem: updated };
            }

            store.hide();
            return { success };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error };
        } finally {
            this.saving = false;
        }
    });

    @computed
    public get canAssign() {
        const workItem = this.selectionStore.workItem;

        if (!workItem || this.saving) {
            return false;
        }

        return workItem.status != "Closed" && workItem.status != "Cancelled" && this.permissionStore.canAssign;
    }

    public onAssign = flow(function* (trigger, options) {
        const workItem = this.selectionStore.workItem;

        if (!workItem) {
            return { success: false };
        }

        try {
            const store = this.parentStore.assignFormStore;
            const { success, formData } = yield store.show({
                ...{ workItem },
                ...options.args,
            });

            if (success && formData) {
                this.saving = true;

                const updated = yield this.workItemService.assignWorkItem({
                    ...formData,
                    triggerId: trigger.id,
                    ...{ comment: options.comment },
                });
                yield this.parentStore.selectionStore.setSelected(updated);
                yield this.parentStore.commentStore.loadComments(updated.id);

                if (options.toast !== "hide") {
                    this.parentStore.rootStore.layoutStore.displayToastNotification(
                        `Demand request ${workItem.code} assigned to ${updated.assignedToUser.name} from ${updated.assignedToGroup.name}`
                    );
                }

                store.hide();
                return { success, workItem: updated };
            }

            store.hide();
            return { success };
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.saving = false;
        }
    });

    @computed
    public get canStart() {
        const workItem = this.selectionStore.workItem;

        if (!workItem || this.saving) {
            return false;
        }

        return (workItem.status === "Open" || workItem.status === "Assigned") && this.permissionStore.canStart;
    }

    public onStart = flow(function* (trigger, options) {
        const { success, workItem } = yield this.onInvokeAction("start", trigger, options);

        if (success && workItem) {
            this.parentStore.rootStore.layoutStore.displayToastNotification(
                `Demand request ${workItem.code} successfully started by ${workItem.modifiedBy.name}`
            );
        }
    });

    @computed
    public get canResolve() {
        const workItem = this.selectionStore.workItem;

        if (!workItem || this.saving) {
            return false;
        }

        return (workItem.status === "InProgress" || workItem.status === "Blocked") && this.permissionStore.canResolve;
    }

    public onResolve = flow(function* (trigger, options) {
        const { success, workItem } = yield this.onInvokeAction("resolve", trigger, options);

        if (success && workItem) {
            this.parentStore.rootStore.layoutStore.displayToastNotification(
                `Demand request ${workItem.code} successfully started by ${workItem.modifiedBy.name}`
            );
        }
    });

    @computed
    public get canReopen() {
        const workItem = this.selectionStore.workItem;

        if (!workItem || this.saving) {
            return false;
        }

        return workItem.status === "Resolved" && this.permissionStore.canReopen;
    }

    public onReopen = flow(function* (trigger, options) {
        const { success, workItem } = yield this.onInvokeAction("reopen", trigger, options);

        if (success && workItem) {
            this.parentStore.rootStore.layoutStore.displayToastNotification(
                `Demand request ${workItem.code} successfully started by ${workItem.modifiedBy.name}`
            );
        }
    });

    @computed
    public get canBlock() {
        const workItem = this.selectionStore.workItem;

        if (!workItem || this.saving) {
            return false;
        }

        return workItem.status === "InProgress" && this.permissionStore.canBlock;
    }

    public onBlock = flow(function* (trigger, options) {
        const { success, workItem } = yield this.onInvokeAction("block", trigger, options);

        if (success && workItem) {
            this.parentStore.rootStore.layoutStore.displayToastNotification(
                `Demand request ${workItem.code} successfully marked as blocked by ${workItem.modifiedBy.name}`
            );
        }
    });

    @computed
    public get canUnblock() {
        const workItem = this.selectionStore.workItem;

        if (!workItem || this.saving) {
            return false;
        }

        return workItem.status === "Blocked" && this.permissionStore.canUnblock;
    }

    public onUnblock = flow(function* (trigger, options) {
        const { success, workItem } = yield this.onInvokeAction("unblock", trigger, options);

        if (success && workItem) {
            this.parentStore.rootStore.layoutStore.displayToastNotification(
                `Demand request ${workItem.code} successfully unblocked by ${workItem.modifiedBy.name}`
            );
        }
    });

    @computed
    public get canClose() {
        const workItem = this.selectionStore.workItem;

        if (!workItem || this.saving) {
            return false;
        }

        return workItem.status === "Resolved" && this.permissionStore.canClose;
    }

    public onClose = flow(function* (trigger, options) {
        const { success, workItem } = yield this.onInvokeAction("close", trigger, options);

        if (success && workItem) {
            this.parentStore.rootStore.layoutStore.displayToastNotification(
                `Demand request ${workItem.code} successfully closed by ${workItem.modifiedBy.name}`
            );
        }
    });

    @computed
    public get canCancel() {
        const workItem = this.selectionStore.workItem;

        if (!workItem || this.saving) {
            return false;
        }

        return workItem.status !== "Closed" && workItem.status !== "Cancelled" && this.permissionStore.canCancel;
    }

    public onCancel = flow(function* (trigger, options) {
        const { success, workItem } = yield this.onInvokeAction("cancel", trigger, options);

        if (success && workItem) {
            this.parentStore.rootStore.layoutStore.displayToastNotification(
                `Demand request ${workItem.code} successfully cancelled by ${workItem.modifiedBy.name}`
            );
        }
    });

    @computed
    public get canDelete() {
        const workItem = this.selectionStore.workItem;

        if (!workItem || this.saving) {
            return false;
        }

        return workItem.status !== "Closed" && this.permissionStore.canDelete;
    }

    public onDelete = flow(function* (trigger, options) {
        const workItem = this.selectionStore.workItem;

        if (!workItem) {
            return { success: false };
        }
        try {
            this.saving = true;

            yield this.workItemService.deleteWorkItem({
                id: workItem.id,
                triggerId: trigger.id,
                ...{ comment: options.comment },
            });
            return { success: true };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error };
        } finally {
            this.saving = false;
        }
    });

    @computed
    public get canFlow() {
        const workItem = this.selectionStore.workItem;

        if (!workItem || this.saving) {
            return false;
        }

        return workItem.status !== "Closed" && workItem.status !== "Cancelled" && this.permissionStore.canFlow;
    }

    public onFlow = flow(function* (trigger, options) {
        yield this.onInvokeAction("flow", trigger, options);
    });

    @computed
    public get canAddLink() {
        const workItem = this.selectionStore.workItem;

        if (!workItem) {
            return false;
        }

        return (
            workItem.status != "Draft" &&
            workItem.status != "Cancelled" &&
            (this.permissionStore.isOwner || this.permissionStore.isAdmin)
        );
    }

    public onAddLink = flow(function* (trigger, options) {
        try {
            let target = null;
            const workItem = this.selectionStore.workItem;

            if (options.mode === "new") {
                const productPickerStore = this.parentStore.rootStore.productStore.pickerStore;
                const productQuery = { isInternal: true };
                const productResult = yield productPickerStore.show({ query: productQuery });
                productPickerStore.hide();

                if (!productResult.success) {
                    return productResult;
                }

                const newWorkItemStore = this.parentStore.newFormStore;
                const workItemResult = yield newWorkItemStore.show({
                    ...{ workItem: { product: productResult.product } },
                });
                newWorkItemStore.hide();

                if (!workItemResult.success) {
                    return workItemResult;
                }

                target = yield this.workItemService.createWorkItem({
                    ...workItemResult.formData,
                });
            }

            const store = this.parentStore.linkNewFormStore;
            const { success, formData } = yield store.show({
                ...{ link: { sourceId: workItem.id, target }, workItem },
                ...options.args,
            });

            if (success && formData) {
                this.saving = true;

                const created = yield this.workItemService.createWorkItemLink({
                    ...formData,
                    triggerId: trigger.id,
                    ...{ comment: options.comment },
                });

                store.hide();
                return { success, link: created };
            }

            store.hide();
            return { success };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error };
        } finally {
            this.saving = false;
        }
    });

    public onInvokeAction = flow(function* (action, trigger, options) {
        try {
            const workItem = this.selectionStore.workItem;

            if (!workItem) {
                return { success: false };
            }

            this.saving = true;
            const updated = yield this.workItemService[`${action}WorkItem`]({
                id: workItem.id,
                triggerId: trigger.id,
                ...{ comment: options.comment },
            });
            yield this.parentStore.selectionStore.setSelected(updated);
            //yield this.parentStore.commentStore.loadComments(updated.id);

            return { success: true, workItem: updated };
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.saving = false;
        }
    });

    @computed
    public get canAddComment() {
        const workItem = this.selectionStore.workItem;

        if (!workItem) {
            return false;
        }

        return workItem.status !== "Closed" && workItem.status !== "Cancelled" && this.permissionStore.canAddComment;
    }

    /* old methods */

    @computed
    public get canUpdate() {
        return this.permissionStore.isOwner || this.permissionStore.isAdmin;
    }

    @computed
    public get canShare() {
        return this.permissionStore.isOwner || this.permissionStore.isAdmin;
    }

    @computed
    public get canReview() {
        return this.permissionStore.isOwner || this.permissionStore.isAdmin;
    }

    @computed
    public get canViewResourcePages() {
        return this.permissionStore.canViewResourcePages;
    }

    @computed public get isGuestUser() {
        return isGuest(this.parentStore.selectionStore.currentUser);
    }

    @computed public get isLoadingWorkItems() {
        return this.parentStore.browseStore.loading;
    }

    @computed public get isNewWorkItemFormOpen() {
        return this.parentStore.newFormStore.visible;
    }

    @computed public get isEditWorkItemFormOpen() {
        return this.parentStore.editFormStore.visible;
    }

    @computed public get isProcessing() {
        const { selectionStore } = this.parentStore;
        return selectionStore.loading || this.parentStore.loading || this.parentStore.saving;
    }

    @computed
    public get canManageTasks() {
        const { permissionStore } = this.parentStore;
        return permissionStore.canManageTasks;
    }

    @computed
    public get canManageAutomations() {
        const { permissionStore } = this.parentStore;
        return permissionStore.canManageAutomations;
    }

    @computed public get displayNewWorkItemSuccessMessage() {
        const { newFormStore } = this.parentStore;
        //return newFormStore.displaySuccessMessage;
        return false;
    }

    @computed public get isTasksContainerOpen() {
        return this.parentStore.taskModalStore.isVisible;
    }

    @action public hideNewWorkItemSuccessMessage() {
        const { newFormStore } = this.parentStore;
        //newFormStore.displaySuccessMessage = false;
    }

    @action public showNewWorkItemForm(productId) {
        this.parentStore.newFormStore.show(productId);
    }

    @action public hideNewWorkItemForm() {
        this.parentStore.newFormStore.visible = false;
    }

    @action public toggleTaskModal() {
        this.parentStore.taskModalStore.visible = !this.parentStore.taskModalStore.isVisible;
    }

    @action public openTaskModal() {
        this.parentStore.taskModalStore.visible = true;
    }

    @action public closeTaskModal() {
        this.parentStore.taskModalStore.visible = false;
    }
}
