import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';

import { PageZone } from '../elements/PageZone';

export const TwoColumnsLayout = observer(
  class TwoColumnsLayout extends PureComponent {
    render () {
        const { className, instance, section } = this.props;

        return (
          <div className={className}>
            <r-grid columns-s={1} columns={1} columns-l={2}>
              <r-cell>
                <PageZone instance={instance} section={section} name="left" title="Left Zone" />
              </r-cell>
              <r-cell>
                <PageZone instance={instance} section={section} name="right" title="Right Zone" />
              </r-cell>
            </r-grid>
          </div>
        );
    }
  }
);