import { observable, flow, action, computed } from "mobx";

import { StateFlowService } from "../../../api/stateflows";
import { StateFlowSelectionStore } from "./StateFlowSelectionStore";
import { StateFlowPermissionStore } from "./StateFlowPermissionStore";
import { StateFlowStore } from "./StateFlowStore";

export class StateFlowLifecycleStore {
    public parentStore: StateFlowStore;
    public stateFlowService: StateFlowService;
    public selectionStore: StateFlowSelectionStore;
    public permissionStore: StateFlowPermissionStore;

    @observable public saving: boolean = false;

    constructor(parentStore: StateFlowStore) {
        this.parentStore = parentStore;
        this.stateFlowService = parentStore.stateFlowService;
        this.selectionStore = parentStore.selectionStore;
        this.permissionStore = parentStore.permissionStore;
    }

    public onRunAction = flow(function* (action, options) {
        switch (action) {
            case "viewStateFlow":
                yield this.onView(options);
                break;
            case "editStateFlow":
                yield this.onEdit(options);
                break;
            case "editStateFlowProperties":
                yield this.onEdit({ ...options, ...{ mode: "properties" } });
                break;
            case "duplicateStateFlow":
                yield this.onDuplicate(options);
                break;
            case "deleteStateFlow":
                yield this.onDelete(options);
                break;
        }
    });

    public onContextMenu = flow(function* (options) {
        const { success, action } = yield this.parentStore.contextMenuStore.show({
            ...options,
            permissions: {
                canEdit: this.canEdit,
                canDuplicate: this.canDuplicate,
                canDelete: this.canDelete,
            },
        });
        yield this.parentStore.contextMenuStore.hide();

        if (success) {
            yield this.onRunAction(action, options);
        }
    });

    @action
    public onView(options) {
        this.parentStore.rootStore.routing.push(`/settings/workflows/${options.stateFlow.id}`);
    }

    @computed
    public get canEdit() {
        return this.permissionStore.canEdit;
    }

    public onEdit = flow(function* (options) {
        if (options.mode === "properties") {
            const current = yield this.stateFlowService.getStateFlowManifest(options.stateFlow.id);

            const { success, stateFlow } = yield this.parentStore.editFormStore.show({
                ...options,
                stateFlow: current,
            });
            yield this.parentStore.editFormStore.hide();

            if (success && stateFlow) {
                const updated = yield this.stateFlowService.updateStateFlow({
                    mode: "Build",
                    notes: "Properties updated",
                    entity: stateFlow,
                });

                yield this.parentStore.browseStore.replace(updated);
            }
        } else {
            this.parentStore.rootStore.routing.push(`/settings/workflows/${options.stateFlow.id}/editor`);
        }
    });

    @computed
    public get canDuplicate() {
        return this.permissionStore.canEdit;
    }

    public onDuplicate = flow(function* (options) {
        const current = yield this.stateFlowService.getStateFlowManifest(options.stateFlow.id);
        current.name = `Copy of ${current.name}`;

        const { success, stateFlow } = yield this.parentStore.editFormStore.show({
            ...options,
            stateFlow: current,
        });
        yield this.parentStore.editFormStore.hide();

        if (success && stateFlow) {
            const updated = yield this.stateFlowService.createStateFlow({
                mode: "Build",
                notes: "Properties updated",
                entity: stateFlow,
            });

            yield this.parentStore.browseStore.replace(updated);
        }
    });

    @computed
    public get canDelete() {
        return this.permissionStore.canDelete;
    }

    @action
    public onDelete(options) {
        //nothing
    }
}
