import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";

import { PanelType } from "office-ui-fabric-react/lib/Panel";
import { PrimaryButton, ActionButton } from "office-ui-fabric-react/lib/Button";

import { ObservablePanel } from "@modules/base/components/ObservablePanel";
import { ActionPanel } from "@modules/base/components/ActionPanel";

import { EvidenceEditForm } from "@modules/assessments/components/evidences/EvidenceEditForm";

export const EvidenceEditPanel = inject("assessmentStore")(
    withRouter(
        observer(
            class EvidenceEditPanel extends Component {
                render() {
                    const { evidenceStore } = this.props.assessmentStore;
                    const { visible, formData, selectedAdvisories, isValid } = evidenceStore;

                    return (
                        <ObservablePanel
                            hidden={!visible}
                            pageTitle={"New Evidence"}
                            pageType="Evidence"
                            panelType={PanelType.small}
                            onClosePanel={() => this._onHidePanel()}
                            onRenderFooter={() => this._renderFooter()}
                        >
                            {formData && <EvidenceEditForm formData={formData} isValid={isValid} advisories={selectedAdvisories.selected} />}
                        </ObservablePanel>
                    );
                }

                _renderFooter() {
                    const { evidenceStore, lifecycleStore, documentStore } = this.props.assessmentStore;
                    const saving = this.props.saving;
                    const disabled = !evidenceStore.isValid || !lifecycleStore.canAddEvidence || saving;

                    return (
                        <ActionPanel>
                            <ActionButton style={{ height: 32 }} onClick={() => this._onHidePanel()} text="Cancel" disabled={saving} />
                            <PrimaryButton iconProps={{ iconName: "Save" }} onClick={() => this._onSaveEvidence()} text="Save" disabled={disabled} />
                        </ActionPanel>
                    );
                }

                _onSaveEvidence() {
                    const { onAfterSave } = this.props;
                    const { evidenceStore, documentStore, selectionStore } = this.props.assessmentStore;
                    const { formData, isValid, selectedAdvisories } = evidenceStore;

                    if (isValid) {
                        if (formData.evidenceType == "Document") {
                            return documentStore
                                .uploadDocument({
                                    sourceId: selectionStore.workItem.id,
                                    internalOnly: false,
                                    mode: "Major",
                                    file: formData.file,
                                })
                                .then((document) => {
                                    const evidences = selectedAdvisories.selected.map((advisory) => ({
                                        advisoryId: advisory.id,
                                        type: formData.evidenceType,
                                        notes: formData.notes,
                                        document: {
                                            id: document.document.id,
                                        },
                                    }));
                                    return evidenceStore.createEvidences(evidences).then((result) => {
                                        if (onAfterSave) {
                                            onAfterSave(evidences);
                                        }
                                        if (selectedAdvisories.reset) {
                                            selectedAdvisories.reset();
                                        }
                                        evidenceStore.hide();
                                    });
                                });
                        }
                        const evidences = selectedAdvisories.selected.map((advisory) => ({
                            advisoryId: advisory.id,
                            type: formData.evidenceType,
                            notes: formData.notes,
                            link: formData.link,
                            linkType: {
                                id: formData.linkTypeId,
                            },
                            workItem: {
                                id: formData.workItemId,
                            },
                        }));
                        return evidenceStore.createEvidences(evidences).then(() => {
                            if (onAfterSave) {
                                onAfterSave(evidences);
                            }
                            if (selectedAdvisories.reset) {
                                selectedAdvisories.reset();
                            }
                            evidenceStore.hide();
                        });
                    }
                }

                _onHidePanel() {
                    const { evidenceStore } = this.props.assessmentStore;
                    evidenceStore.hide();
                }
            }
        )
    )
);
