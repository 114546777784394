import React from "react";

import MDEditor from "@uiw/react-md-editor";

export const MarkdownViewer = ({ value }) => {
    return (
        <MDEditor.Markdown
            source={value}
            linkTarget={(url, props) => {
                return "_blank";
            }}
        />
    );
};
