import React, { Component } from "react";
import { observer } from "mobx-react";

import { Dropdown } from "office-ui-fabric-react/lib/Dropdown";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { Toggle } from "office-ui-fabric-react/lib/Toggle";
import { ComboBox } from "office-ui-fabric-react/lib/ComboBox";

import { BigTextField } from "@modules/base/components/BigTextField";
import { ObservableSeparator } from "@modules/base/components/ObservableSeparator";
import { RichTextField } from "@modules/editors/components/RichTextField";
import { GroupDropDown } from "@modules/identity/containers/pickers/GroupDropDown";
import { UserDropDown } from "@modules/identity/containers/pickers/UserDropDown";
import { StateFlowPicker } from "@modules/stateflows/containers/pickers/StateFlowPicker";

import { ProductStatus, AutoAssignmentModes, autoAssignmentModeOrDefault } from "../../stores/ProductContants";

export const ProductEditForm = observer(
    class ProductEditForm extends Component {
        render() {
            const { formData, options } = this.props;
            return (
                <>
                    <r-grid columns="6">
                        <r-cell span="6">
                            <div>
                                <BigTextField
                                    label="Name"
                                    required
                                    className="product-name"
                                    placeholder="Please enter a product name"
                                    value={formData.name}
                                    transparent
                                    onChange={(ev, value) => (formData.name = value)}
                                />
                            </div>
                        </r-cell>
                    </r-grid>
                    <r-grid class="row--l" columns="6">
                        <r-cell span="6">
                            <ObservableSeparator>Key Information</ObservableSeparator>
                        </r-cell>
                        <r-cell span="6">
                            <div>
                                <TextField
                                    label="Summary"
                                    maxLength={400}
                                    value={formData.summary}
                                    onChange={(ev, value) => (formData.summary = value)}
                                    multiline
                                />
                            </div>
                        </r-cell>
                        <r-cell span="6">
                            <div>
                                <TextField
                                    label="Friendly Name"
                                    maxLength={400}
                                    value={formData.friendlyName}
                                    onChange={(ev, value) => (formData.friendlyName = value)}
                                />
                            </div>
                        </r-cell>
                        <r-cell span="3">
                            <div>
                                <ComboBox
                                    required
                                    label="Availability"
                                    allowFreeform={true}
                                    autoComplete="on"
                                    options={options.availabilities.map((o) => ({ key: o, text: o }))}
                                    onChange={(ev, item, index, value) => {
                                        formData.availability = item ? item.key : value;
                                        if (!item && value && options.availabilities.indexOf(value) === -1) {
                                            options.availabilities.push(value);
                                        }
                                    }}
                                    selectedKey={formData.availability}
                                    useComboBoxAsMenuWidth
                                />
                            </div>
                        </r-cell>
                        <r-cell span="3">
                            <div>
                                <ComboBox
                                    required
                                    label="Category"
                                    allowFreeform={true}
                                    autoComplete="on"
                                    options={options.categories.map((o) => ({ key: o, text: o }))}
                                    onChange={(ev, item, index, value) => {
                                        formData.category = item ? item.key : value;
                                        if (!item && value && options.categories.indexOf(value) === -1) {
                                            options.categories.push(value);
                                        }
                                    }}
                                    selectedKey={formData.category}
                                    useComboBoxAsMenuWidth
                                />
                            </div>
                        </r-cell>
                        <r-cell span="3">
                            <div>
                                <ComboBox
                                    required
                                    label="Provider"
                                    allowFreeform={true}
                                    autoComplete="on"
                                    options={options.providers.map((o) => ({ key: o, text: o }))}
                                    onChange={(ev, item, index, value) => {
                                        formData.provider = item ? item.key : value;
                                        if (!item && value && options.providers.indexOf(value) === -1) {
                                            options.providers.push(value);
                                        }
                                    }}
                                    selectedKey={formData.provider}
                                    useComboBoxAsMenuWidth
                                />
                            </div>
                        </r-cell>
                        <r-cell span="3">
                            <div>
                                <ComboBox
                                    label="State"
                                    required
                                    options={Object.entries(ProductStatus).map(([key, text]) => ({ key, text }))}
                                    selectedKey={formData.state}
                                    onChange={(_, item) => (formData.state = item.key)}
                                    useComboBoxAsMenuWidth
                                />
                            </div>
                        </r-cell>
                    </r-grid>
                    <r-grid class="row--l" columns="6">
                        <r-cell span="6">
                            <ObservableSeparator>Assignment Settings</ObservableSeparator>
                        </r-cell>
                        <r-cell span="3">
                            <div>
                                <GroupDropDown
                                    label="Auto-assigned Group"
                                    required
                                    placeholder="Select a group"
                                    selected={formData.assignedToGroup}
                                    onChange={(group) => (formData.assignedToGroup = group)}
                                />
                            </div>
                        </r-cell>
                        <r-cell span="3">
                            <div>&nbsp;</div>
                        </r-cell>
                        <r-cell span="3">
                            <div>
                                <Dropdown
                                    label="Auto Assignment Mode"
                                    placeholder="Select an auto assignment mode"
                                    required
                                    selectedKey={autoAssignmentModeOrDefault(formData.autoAssignmentMode)}
                                    onChange={(_, item) => (formData.autoAssignmentMode = item.key)}
                                    options={Object.entries(AutoAssignmentModes).map(([key, text]) => ({ key, text }))}
                                />
                            </div>
                        </r-cell>
                        <r-cell span="3">
                            <div>
                                {formData.autoAssignmentMode === "workItem.assignedToUser" && (
                                    <UserDropDown
                                        label="Auto-assigned User"
                                        required
                                        placeholder="Select a user"
                                        groupId={formData.assignedToGroup ? formData.assignedToGroup.id : null}
                                        selected={formData.assignedToUser}
                                        onChange={(user) => (formData.assignedToUser = user)}
                                    />
                                )}
                            </div>
                        </r-cell>
                    </r-grid>
                    <r-grid class="row--l" columns="6">
                        <r-cell span="6">
                            <ObservableSeparator>Display Settings</ObservableSeparator>
                        </r-cell>
                        <r-cell span="3">
                            <div>
                                <Toggle
                                    label="Customer facing"
                                    checked={formData.isCustomerFacing}
                                    inlineLabel
                                    onChange={(ev, checked) => (formData.isCustomerFacing = checked)}
                                />
                            </div>
                        </r-cell>
                        <r-cell span="3">
                            <div>
                                <Toggle
                                    label="Promoted"
                                    checked={formData.isPromoted}
                                    inlineLabel
                                    onChange={(ev, checked) => (formData.isPromoted = checked)}
                                />
                            </div>
                        </r-cell>
                    </r-grid>
                    <r-grid class="row--l" columns="6">
                        <r-cell span="6">
                            <ObservableSeparator>Request Settings</ObservableSeparator>
                        </r-cell>
                        <r-cell span="3">
                            <div>
                                <Toggle
                                    label="Requestable"
                                    checked={formData.isRequestable}
                                    inlineLabel
                                    onChange={(ev, checked) => {
                                        if (!checked) {
                                            formData.descriptionTemplate = null;
                                        }
                                        formData.isRequestable = checked;
                                    }}
                                />
                            </div>
                        </r-cell>
                        <r-cell span="3">
                            <div>
                                <Toggle
                                    label="Internal"
                                    checked={formData.isInternal}
                                    inlineLabel
                                    onChange={(ev, checked) => (formData.isInternal = checked)}
                                />
                            </div>
                        </r-cell>
                        {formData.isRequestable && formData.isInternal && (
                            <r-cell span="3">
                                <div>
                                    <StateFlowPicker
                                        label="Workflow"
                                        target="WorkItem"
                                        required={formData.isRequestable && formData.isInternal}
                                        selectedKey={formData.flowId}
                                        onChange={(stateFlow) => {
                                            formData.flowId = stateFlow ? stateFlow.id : null;
                                        }}
                                    />
                                </div>
                            </r-cell>
                        )}
                        <r-cell span="6">
                            <div>
                                {formData.isRequestable && !formData.isInternal && (
                                    <TextField
                                        label="Request URL"
                                        value={formData.requestUrl}
                                        onChange={(ev, value) => (formData.requestUrl = value)}
                                    />
                                )}
                                {formData.isRequestable && formData.isInternal && (
                                    <RichTextField
                                        label="Description Template"
                                        placeholder="Please enter a description template of the work item"
                                        value={formData.descriptionTemplate}
                                        onChange={(val) => (formData.descriptionTemplate = val)}
                                    />
                                )}
                            </div>
                        </r-cell>
                    </r-grid>
                </>
            );
        }
    }
);
