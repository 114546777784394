import React from "react";
import { Text } from "@react-pdf/renderer";

export const PageHeader = ({ header, ...rest }) => {
    return (
        <Text {...rest} fixed>
            {header}
        </Text>
    );
};
