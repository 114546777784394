import React from "react";

import { Dropdown } from "office-ui-fabric-react/lib/Dropdown";
import { Stack } from "office-ui-fabric-react/lib/Stack";

import { WorkItemPriority } from "@modules/workitems/components/core/WorkItemPriority";

export function WorkItemPriorityPicker({ ...rest }) {
    return (
        <Dropdown
            onRenderTitle={(options) => (
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    {options.map((option) => (
                        <WorkItemPriority key={option.key} value={option.key} />
                    ))}
                </Stack>
            )}
            onRenderOption={(option) => <WorkItemPriority value={option.key} />}
            options={["High", "Medium", "Low"].map((priority) => ({ key: priority, text: priority }))}
            {...rest}
        />
    );
}
