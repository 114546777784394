import React, { useEffect, useCallback } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { Stack } from "@ui/elements/Stack";
import { ActionButton, PrimaryButton } from "@ui/elements/Button";

import { ObservablePagination } from "@modules/base/components/ObservablePagination";
import { BoxPanel } from "@modules/base/components/BoxPanel";

import { RoleNames, isUserInAllRoles } from "@modules/identity/stores/PrincipalContextUtilities";

import { ActivityInstanceListView } from "@modules/workflows/components/core/ActivityInstanceListView";

import { ActivityInstanceNewPanel } from "@modules/workflows/containers/panels/ActivityInstanceNewPanel";
import { ActivityInstanceEditPanel } from "@modules/workflows/containers/panels/ActivityInstanceEditPanel";
import { ActivityInstanceDeleteDialog } from "@modules/workflows/containers/panels/ActivityInstanceDeleteDialog";
import { AutomationBuilderDialog } from "@modules/workflows/containers/dialogs/AutomationBuilderDialog";

export const ModuleAutomationsTab = observer(({ context }) => {
    const { workflowStore, principalContext } = useStores();
    const { browseStore, lifecycleStore } = workflowStore;
    const user = principalContext.current;
    const isAdmin = isUserInAllRoles(user, [RoleNames.Admin]);

    const searchInstances = useCallback((options) => {
        browseStore.loadActivityInstances({ ...options, scopes: [context] });
    }, []);

    const onShowBuilder = useCallback(() => {
        lifecycleStore.onShowBuilder({ context });
    }, [searchInstances]);

    const onEditActivity = useCallback(
        (instance) => {
            lifecycleStore.onEditActivity({ instance, context }).then((result) => {
                if (result.success) {
                    searchInstances({});
                }
            });
        },
        [searchInstances]
    );

    const onAddActivity = useCallback(
        (instance) => {
            lifecycleStore
                .onAddActivity({
                    instance: {
                        definition: {
                            uniqueId: instance.definition.uniqueId,
                        },
                        trigger: {
                            event: instance.trigger.event,
                            group: instance.trigger.group,
                            condition: instance.trigger.condition,
                        },
                        scope: context,
                    },
                    context,
                })
                .then((result) => {
                    if (result.success) {
                        searchInstances({});
                    }
                });
        },
        [searchInstances]
    );

    const onDeleteActivity = useCallback(
        (instance) => {
            lifecycleStore.onDeleteActivity({ instance, context }).then((result) => {
                if (result.success) {
                    searchInstances({});
                }
            });
        },
        [searchInstances]
    );

    const onPageChange = useCallback(
        (index) => {
            searchInstances({ page: index + 1, query: browseStore.query });
        },
        [searchInstances]
    );

    useEffect(() => {
        searchInstances({});
        return () => browseStore.reset();
    }, []);

    return (
        <>
            <BoxPanel padding shadow small>
                <Stack className="row--s" horizontal horizontalAlign="space-between">
                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                        {isAdmin && (
                            <PrimaryButton iconProps={{ iconName: "Add" }} onClick={onShowBuilder} text="Automation" />
                        )}
                        <ActionButton
                            iconProps={{ iconName: "Refresh" }}
                            onClick={() => searchInstances({})}
                            text="Refresh"
                        />
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 5 }}></Stack>
                </Stack>
                <ActivityInstanceListView
                    className="row--s"
                    context={context}
                    loading={browseStore.loading}
                    instances={browseStore.instances}
                    canCreate={isAdmin}
                    onCreate={onShowBuilder}
                    canEdit={isAdmin}
                    onEdit={onEditActivity}
                    onRemove={onDeleteActivity}
                />
            </BoxPanel>
            <AutomationBuilderDialog store={workflowStore.builderStore} onSelect={onAddActivity} />
            <ActivityInstanceNewPanel store={workflowStore.newFormStore} />
            <ActivityInstanceEditPanel store={workflowStore.editFormStore} />
            <ActivityInstanceDeleteDialog store={workflowStore.deleteFormStore} />
            {!browseStore.error && <ObservablePagination {...browseStore.pagination} onPageChange={onPageChange} />}
        </>
    );
});
