import React, { Component, Fragment } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import { Dialog, DialogType, DialogFooter } from "office-ui-fabric-react/lib/Dialog";
import { PrimaryButton } from "office-ui-fabric-react/lib/Button";

import { AppPage } from "@modules/layout/containers/AppPage";

import { WorkItemReview } from "@modules/workitems/components/core/WorkItemReview";
import { WorkItemSidebar } from "@modules/workitems/components/core/WorkItemSidebar";

export const WorkItemReviewPage = inject(
    "pageContext",
    "productStore",
    "portfolioStore",
    "workItemStore",
    "systemStore",
    "assessmentStore",
    "routing",
    "layoutStore"
)(
    withRouter(
        observer(
            class WorkItemReviewPage extends Component {
                constructor(props) {
                    super(props);
                    this.state = {
                        show404Dialog: false,
                    };
                }

                async componentWillMount() {
                    this.loadData(this.props);
                }

                async componentWillReceiveProps(nextProps) {
                    this.loadData(nextProps);
                }

                async loadData(props) {
                    const { portfolioStore, productStore, workItemStore, systemStore, match } = props;
                    const { selectionStore } = workItemStore;
                    try {
                        const workItem = await selectionStore.loadWorkItem(match.params.id);
                        await portfolioStore.loadPortfolios({});
                        await productStore.loadProductSchema(workItem.product.id, workItem.schemaVersion);
                        await productStore.setSelectedWithId(workItem.product.id);
                        await systemStore.setSelectedWithId(workItem.assetId);
                    } catch (e) {
                        console.error(e);
                        this.setState({
                            show404Dialog: true,
                        });
                    }
                }

                render() {
                    const { productStore, portfolioStore, workItemStore, systemStore, routing, match, layoutStore } =
                        this.props;
                    const { reviewPage, selectionStore } = workItemStore;
                    const { breadcrumb } = reviewPage;

                    return (
                        <AppPage
                            title="Demand"
                            onTitleClick={() => {
                                routing.push(`/boards`);
                            }}
                            breadcrumb={breadcrumb}
                            hasSidebar={true}
                            className="work-item edit"
                            error={selectionStore.error}
                        >
                            <Dialog
                                hidden={!this.state.show404Dialog}
                                onDismiss={this.onDismiss404Dialog}
                                dialogContentProps={{
                                    type: DialogType.largeHeader,
                                    title: "The requested demand request doesn't exist",
                                    subText:
                                        "It seems that you requested a work item that doesn't exist, please check the url or go back to the list.",
                                }}
                                modalProps={{
                                    isBlocking: true,
                                    styles: { main: { minWidth: 550, maxWidth: 650 } },
                                }}
                            >
                                <DialogFooter>
                                    <PrimaryButton
                                        onClick={() => {
                                            routing.push(`/demand/catalogue`);
                                        }}
                                        text="Create a new demand request"
                                    />
                                    <PrimaryButton
                                        onClick={() => {
                                            routing.push(`/demand/browse`);
                                        }}
                                        text="Go back to the list"
                                    />
                                </DialogFooter>
                            </Dialog>
                            {portfolioStore.activePortfolios &&
                                productStore.selectedProduct &&
                                selectionStore.workItem &&
                                productStore.schema && (
                                    <Fragment>
                                        <WorkItemReview
                                            schema={productStore.schema}
                                            product={productStore.selectedProduct}
                                            portfolios={portfolioStore.activePortfolios}
                                            workItem={selectionStore.workItem}
                                            selectedSystem={systemStore.selectedSystem}
                                            canSave={(workItem) => this._canContinue(workItem)}
                                            onSave={(workItem) => this._continue(workItem)}
                                            onCancel={(workItem) => this._cancel(workItem)}
                                        />
                                        <WorkItemSidebar workItem={selectionStore.workItem} />
                                    </Fragment>
                                )}
                        </AppPage>
                    );
                }

                _canContinue(workItem) {
                    return !workItem.assessmentId;
                }

                _continue(workItem) {
                    const { assessmentStore, workItemStore, routing, layoutStore } = this.props;
                    const { updateWorkItem } = workItemStore;
                    return assessmentStore
                        .createAssessment({
                            trigger: "Manual",
                            portfolio: {
                                id: workItem.portfolioId,
                            },
                            target: {
                                type: "System",
                                id: workItem.assetId,
                            },
                        })
                        .then((assessment) => {
                            workItem.assessmentId = assessment.id;
                            layoutStore.displayToastNotification(
                                <>Assessment {assessment.code} has been created. Please complete self assessment.</>
                            );
                            return updateWorkItem(workItem).then(() => {
                                routing.push(`/assessments/${assessment.id}`);
                            });
                        });
                }

                _cancel(workItem) {
                    const { routing } = this.props;
                    routing.push(`/demand/${workItem.id}`);
                }
            }
        )
    )
);
