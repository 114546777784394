import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';

const rootClassName = cx(
  'cygraph-ErrorPanel-root',
      css`
      display: table;
      position: absolute;
      height: 75vh;
      width: 100%;
      margin-left: calc( -1 * var(--app-nav-mini-width));

      & .block {
        display: table-cell;
        vertical-align: middle;
      }

      & .inner {
        margin-left: auto;
        margin-right: auto;
        position: relative;
        max-width: 440px;
        width: calc(100% - 40px);
        margin-bottom: 28px;
        min-width: 320px;
        min-height: 338px;
        overflow: hidden;
      }

      & .title {
        text-align: center;
        font-size: 55px;
        color: var(--border-color-dark);
      }
    `
  );

export const ErrorPanel = observer(
  class ErrorPanel extends Component {

    render () {
      const { status, code, traceId, message } = this.props.error;
      
      return (
        <div className={rootClassName}>
          <div className="block">
            <div className="inner">
              <div className="title">
                { this._showErrorPage() }
              </div>
              
            </div>
          </div>
        </div>
      );
    }

    _showErrorPage() {
      const { status } = this.props.error;
      
      if(!status) {
        return null;
      }
  
      switch(status) {
        case 404: return 'Page Not Found';
        case 403: return 'Unauthorised';
      }
  
      return null;
    }
  }
);
