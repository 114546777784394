import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { BoxPanel } from '../../../../base/components/BoxPanel';

export const AdvisoriesCommandBar = observer(
    class AdvisoriesCommandBar extends Component {
        
        render () {
            const { 
                keywords,
                onSearchChange,
                onStatusChange,
                onSeverityChange,
                advisoriesSeverities,
                advisoriesStatus,
                selectedSeverity,
                selectedState,
            } = this.props;

            const selectedAdvisorySeverity = advisoriesSeverities.filter(AS => AS.key === selectedSeverity); 
            const selectedAdvisoryStatus = advisoriesStatus.filter(AS => AS.key === selectedState);

            const items = [
                {
                    key: 'severity',
                    text: `Severity: ${selectedAdvisorySeverity[0]?selectedAdvisorySeverity[0].text:'All'}`,
                    // cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
                    // iconProps: { iconName: 'Add' },
                    subMenuProps: {
                        items: advisoriesSeverities.map(A => {
                            return {
                                key: A.key,
                                text: A.text,
                                onClick: () => onSeverityChange(A.key),
                            }
                        })
                    },
                },
                {
                    key: 'status',
                    text: `Status: ${selectedAdvisoryStatus[0]?selectedAdvisoryStatus[0].text:'All'}`,
                    // cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
                    // iconProps: { iconName: 'Add' },
                    subMenuProps: {
                        items: advisoriesStatus.map(A => {
                            return {
                                key: A.key,
                                text: A.text,
                                onClick: () => onStatusChange(A.key),
                            }
                        })
                    },
                },
            ];

            const farItems = [
                {
                    key: 'search',
                    text: 'Search',
                    onRender: () => {
                        return (
                            <SearchBox
                                value={keywords}
                                placeholder="Search by name"
                                styles={{ root: { height: 'auto', minWidth: '270px' }}}
                                onSearch={onSearchChange}
                                onClear={e=>{onSearchChange("")}}  
                            />
                        );
                    }
                }
            ];

            return (
                <BoxPanel shadow>
                    <CommandBar
                        styles={{ root: { paddingRight: '0px' } }}
                        items={items}
                        farItems={farItems}
                    />
                </BoxPanel>
            );
        }
    }
);