import React from "react";
import { observer } from "mobx-react";

import { Dropdown } from "office-ui-fabric-react/lib/Dropdown";
import { DatePicker } from "office-ui-fabric-react/lib/DatePicker";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { Label } from "@ui/elements/Label";
import { Text } from "@ui/elements/Text";

import { AdaptiveField } from "@modules/base/components/AdaptiveField";
import { ObservableSeparator } from "@modules/base/components/ObservableSeparator";
import { PrincipalPicker } from "@modules/identity/containers/PrincipalPicker";
import { RichTextField } from "@modules/editors/components/RichTextField";

import { ControlEditGridView } from "@modules/controls/components/deployments/ControlEditGridView";

export const MilestoneEditForm = observer(({ formData, formOptions }) => {
    return (
        <Stack tokens={{ childrenGap: 20 }} data-test="newWorkItemForm">
            <div>
                <r-grid columns="2">
                    <r-cell span="2">
                        <Text variant="mediumPlus">
                            <br />
                            {formOptions.control.code} - {formOptions.control.name}
                        </Text>
                    </r-cell>
                    <r-cell>
                        <AdaptiveField
                            label="Threat Surface"
                            required
                            type="Dropdown"
                            mode="edit"
                            valueConverter="simple"
                            value={formData.threatSurface}
                            onChange={(val) => {
                                formData.threatSurface = val;
                            }}
                            options={[
                                { key: "Wellcome General", text: "Wellcome General" },
                                { key: "Wellcome Fund", text: "Wellcome Fund" },
                            ]}
                        />
                    </r-cell>
                    <r-cell span="2">
                        <ControlEditGridView
                            controls={formData.controls}
                            onUpdate={(update) => {
                                const index = formData.controls.findIndex((c) => c.control.id === update.control.id);
                                if (index !== -1) {
                                    formData.controls[index] = update;
                                }
                            }}
                        />
                    </r-cell>
                    <r-cell>
                        <DatePicker
                            label="Start date"
                            isRequired={true}
                            placeholder="Select a start date..."
                            ariaLabel="Select a start date"
                            value={formData.startDate ? new Date(formData.startDate) : null}
                            onSelectDate={(date) => (formData.startDate = date)}
                        />
                    </r-cell>
                    <r-cell>
                        <DatePicker
                            label="Due date"
                            isRequired={true}
                            placeholder="Select a due date..."
                            ariaLabel="Select a due date"
                            value={formData.dueDate ? new Date(formData.dueDate) : null}
                            onSelectDate={(date) => (formData.dueDate = date)}
                        />
                    </r-cell>
                    <r-cell>
                        <PrincipalPicker
                            label="Owner"
                            required
                            selected={formData.owner}
                            onSelected={(contact) => (formData.owner = contact)}
                        />
                    </r-cell>
                    <r-cell span="2">
                        <RichTextField
                            label="Notes"
                            placeholder="Please enter any additional notes you want to share with the owner."
                            value={formData.description}
                            onChange={(val) => {
                                formData.description = val;
                            }}
                        />
                    </r-cell>
                </r-grid>
            </div>
        </Stack>
    );
});
