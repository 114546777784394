import { observable, computed, flow } from "mobx";

import { WorkItemService } from "@api/workitems";
import { WorkItemStore } from "./WorkItemStore";
import { PaginationStore } from "@modules/base/PaginationStore";
import { PrincipalContextStore } from "../../identity/stores/PrincipalContextStore";
import { isUserInAllRoles } from "../../identity/stores/PrincipalContextUtilities";

export class WorkItemBrowseStore {
    public workItemService: WorkItemService;
    public parentStore: WorkItemStore;
    public principalContext: PrincipalContextStore;

    @observable public workItems: any[] = [];
    @observable public query: any = {};
    @observable public loading: boolean = false;
    @observable public pagination: PaginationStore;
    @observable public error: any = null;

    @observable public breadcrumb: any[] = [
        {
            text: "Demand Management",
            key: "demand",
            onClick: () => this.parentStore.rootStore.routing.push(`/demand/dashboard`),
        },
        { text: "Browse Requests", key: "browse", as: "h4", isCurrentItem: true },
    ];

    constructor(parentStore: WorkItemStore) {
        this.parentStore = parentStore;
        this.workItemService = parentStore.workItemService;
        this.principalContext = parentStore.rootStore.principalContext;
        this.pagination = parentStore.pagination;
    }

    @computed
    public get isAgent() {
        const user = this.principalContext.current;
        return user && isUserInAllRoles(user, ["agent"]);
    }

    public loadWorkItems = flow(function* (options) {
        const o = Object.assign({ page: 1, pageSize: 25, query: {} }, options);
        this.query = o.query;
        this.loading = true;
        this.error = null;

        try {
            const result = yield this.workItemService.searchWorkItems({
                pageSize: o.pageSize,
                startIndex: (o.page - 1) * o.pageSize,
                query: o.query,
            });
            this.workItems = result.items;
            this.query = result.query;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
            return result;
        } catch (error) {
            console.error(error);
            this.error = error;
            return error;
        } finally {
            this.loading = false;
        }
    });

    public exportWorkItems = flow(function* (options) {
        const o = Object.assign({ page: 1, query: this.query }, options);
        try {
            const result = yield this.workItemService.exportWorkItems({
                pageSize: this.pagination.itemsPerPage,
                startIndex: (o.page - 1) * this.pagination.itemsPerPage,
                query: o.query,
            });
            if (result) {
                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Demand export request has been queued. You will receive an email shortly when export is ready.`
                );
            }
        } catch (e) {
            console.error(e);
            this.error = e;
        }
    });
}
