export const TargetMetadata = {
    RiskLifecycle: {
        startStates: ["Draft"],
        endStates: ["Closed", "Cancelled", "Transferred"],
        cancelStates: ["Cancelled"],
        states: [
            { id: "Draft", name: "Draft", stages: ["start"] },
            { id: "Assigned", name: "Assigned", stages: ["start", "middle"] },
            { id: "Submitted", name: "Submitted", stages: ["start", "middle", "end"] },
            { id: "Monitoring", name: "Monitoring", stages: ["start", "middle", "end"] },
            { id: "Transferred", name: "Transferred", stages: ["end"] },
            { id: "Closed", name: "Closed", stages: ["end"] },
            { id: "Cancelled", name: "Cancelled", stages: ["end"] },
        ],
        commands: [
            {
                id: "zerodai:commands:risks:edit",
                name: "Edit",
                conditions: ["zerodai:commands:risks:canEdit"],
                restrictions: [
                    { source: "Draft", target: "Draft" },
                    { source: "Assigned", target: "Assigned" },
                    { source: "Submitted", target: "Submitted" },
                    { source: "Monitoring", target: "Monitoring" },
                ],
            },
            {
                id: "zerodai:commands:risks:assign",
                name: "Assign",
                conditions: ["zerodai:commands:risks:canAssign"],
                restrictions: [
                    { source: "Draft", target: "Draft" },
                    { source: "Draft", target: "Assigned" },
                    { source: "Assigned", target: "Assigned" },
                    { source: "Submitted", target: "Submitted" },
                    { source: "Monitoring", target: "Monitoring" },
                ],
            },
            {
                id: "zerodai:commands:risks:submit",
                name: "Submit",
                conditions: ["zerodai:commands:risks:canSubmit", "zerodai:commands:risks:canSubmitWithReview"],
                restrictions: [
                    { source: "Draft", target: "Submitted" },
                    { source: "Assigned", target: "Submitted" },
                ],
            },
            {
                id: "zerodai:commands:risks:monitor",
                name: "Monitor",
                conditions: ["zerodai:commands:risks:canMonitor"],
                restrictions: [{ source: "Submitted", target: "Monitoring" }],
            },
            {
                id: "zerodai:commands:risks:review",
                name: "Review",
                conditions: ["zerodai:commands:risks:canReview"],
                restrictions: [
                    { source: "Draft", target: "Draft" },
                    { source: "Assigned", target: "Assigned" },
                    { source: "Submitted", target: "Submitted" },
                    { source: "Monitoring", target: "Monitoring" },
                ],
            },
            {
                id: "zerodai:commands:risks:close",
                name: "Close",
                conditions: ["zerodai:commands:risks:canClose"],
                restrictions: [
                    { source: "Submitted", target: "Closed" },
                    { source: "Monitoring", target: "Closed" },
                ],
            },
            {
                id: "zerodai:commands:risks:transfer",
                name: "Transfer",
                conditions: ["zerodai:commands:risks:canTransfer"],
                restrictions: [
                    { source: "Submitted", target: "Closed" },
                    { source: "Monitoring", target: "Closed" },
                ],
            },
            {
                id: "zerodai:commands:risks:cancel",
                name: "Cancel",
                conditions: ["zerodai:commands:risks:canCancel"],
                restrictions: [
                    { source: "Draft", target: "Cancelled" },
                    { source: "Assigned", target: "Cancelled" },
                    { source: "Submitted", target: "Cancelled" },
                    { source: "Monitoring", target: "Cancelled" },
                ],
            },
            {
                id: "zerodai:commands:risks:delete",
                name: "Delete",
                conditions: ["zerodai:commands:risks:canDelete"],
                restrictions: [
                    { source: "Draft", target: "Draft" },
                    { source: "Assigned", target: "Assigned" },
                    { source: "Submitted", target: "Submitted" },
                    { source: "Monitoring", target: "Monitoring" },
                ],
            },
            {
                id: "zerodai:commands:risks:flow",
                name: "Transition",
                conditions: [
                    "zerodai:commands:risks:canFlow",
                    "zerodai:commands:risks:canFlowWithReview",
                    "zerodai:commands:risks:isContributor",
                    "zerodai:commands:risks:isOwner",
                    "zerodai:commands:risks:isApprover",
                ],
                restrictions: [
                    { source: "Open", target: "Open" },
                    { source: "Assigned", target: "Assigned" },
                    { source: "Submitted", target: "Submitted" },
                    { source: "Monitoring", target: "Monitoring" },
                ],
            },
        ],
        conditions: [
            {
                id: "zerodai:commands:risks:canEdit",
                name: "Can Edit",
            },
            {
                id: "zerodai:commands:risks:canAssign",
                name: "Can Assign",
            },
            {
                id: "zerodai:commands:risks:canSubmit",
                name: "Can Submit",
            },
            {
                id: "zerodai:commands:risks:canSubmitWithReview",
                name: "Can Submit with Review",
            },
            {
                id: "zerodai:commands:risks:canMonitor",
                name: "Can Monitor",
            },
            {
                id: "zerodai:commands:risks:canReview",
                name: "Can Review",
            },
            {
                id: "zerodai:commands:risks:canClose",
                name: "Can Close",
            },
            {
                id: "zerodai:commands:risks:canTransfer",
                name: "Can Transfer",
            },
            {
                id: "zerodai:commands:risks:canCancel",
                name: "Can Cancel",
            },
            {
                id: "zerodai:commands:risks:canDelete",
                name: "Can Delete",
            },
            {
                id: "zerodai:commands:risks:canFlow",
                name: "Can Transition",
            },
            {
                id: "zerodai:commands:risks:canFlowWithReview",
                name: "Can Transition with Review",
            },
            {
                id: "zerodai:commands:risks:isReader",
                name: "Is Reader",
            },
            {
                id: "zerodai:commands:risks:isContributor",
                name: "Is Contributor",
            },
            {
                id: "zerodai:commands:risks:isOwner",
                name: "Is Owner",
            },
            {
                id: "zerodai:commands:risks:isApprover",
                name: "Is Approver",
            },
        ],
    },
    WorkItem: {
        startStates: ["Open"],
        endStates: ["Closed", "Cancelled"],
        cancelStates: ["Cancelled"],
        states: [
            { id: "Open", name: "Open", stages: ["start"] },
            { id: "Assigned", name: "Assigned", stages: ["start", "middle"] },
            { id: "InProgress", name: "In Progress", stages: ["start", "middle", "end"] },
            { id: "Resolved", name: "Resolved", stages: ["start", "middle", "end"] },
            { id: "Blocked", name: "Blocked", stages: ["start", "middle", "end"] },
            { id: "Closed", name: "Closed", stages: ["end"] },
            { id: "Cancelled", name: "Cancelled", stages: ["end"] },
        ],
        commands: [
            {
                id: "zerodai:commands:work-items:edit",
                name: "Edit",
                conditions: ["zerodai:commands:work-items:canEdit"],
                restrictions: [
                    { source: "Open", target: "Open" },
                    { source: "Assigned", target: "Assigned" },
                    { source: "InProgress", target: "InProgress" },
                    { source: "Blocked", target: "Blocked" },
                    { source: "Resolved", target: "Resolved" },
                ],
            },
            {
                id: "zerodai:commands:work-items:assign",
                name: "Assign",
                conditions: ["zerodai:commands:work-items:canAssign"],
                restrictions: [
                    { source: "Open", target: "Open" },
                    { source: "Open", target: "Assigned" },
                    { source: "Assigned", target: "Assigned" },
                    { source: "InProgress", target: "InProgress" },
                    { source: "Blocked", target: "Blocked" },
                ],
            },
            {
                id: "zerodai:commands:work-items:start",
                name: "Start",
                conditions: ["zerodai:commands:work-items:canStart"],
                restrictions: [
                    { source: "Open", target: "InProgress" },
                    { source: "Assigned", target: "InProgress" },
                ],
            },
            {
                id: "zerodai:commands:work-items:resolve",
                name: "Resolve",
                conditions: ["zerodai:commands:work-items:canResolve"],
                restrictions: [
                    { source: "InProgress", target: "Resolved" },
                    { source: "Blocked", target: "Resolved" },
                ],
            },
            {
                id: "zerodai:commands:work-items:reopen",
                name: "Reopen",
                conditions: ["zerodai:commands:work-items:canReopen"],
                restrictions: [{ source: "Resolved", target: "InProgress" }],
            },
            {
                id: "zerodai:commands:work-items:block",
                name: "Block",
                conditions: ["zerodai:commands:work-items:canBlock"],
                restrictions: [{ source: "InProgress", target: "Blocked" }],
            },
            {
                id: "zerodai:commands:work-items:unblock",
                name: "Unblock",
                conditions: ["zerodai:commands:work-items:canUnblock"],
                restrictions: [{ source: "Blocked", target: "InProgress" }],
            },
            {
                id: "zerodai:commands:work-items:close",
                name: "Close",
                conditions: ["zerodai:commands:work-items:canClose"],
                restrictions: [{ source: "Resolved", target: "Closed" }],
            },
            {
                id: "zerodai:commands:work-items:cancel",
                name: "Cancel",
                conditions: ["zerodai:commands:work-items:canCancel"],
                restrictions: [
                    { source: "Open", target: "Cancelled" },
                    { source: "Assigned", target: "Cancelled" },
                    { source: "InProgress", target: "Cancelled" },
                    { source: "Blocked", target: "Cancelled" },
                    { source: "Resolved", target: "Cancelled" },
                ],
            },
            {
                id: "zerodai:commands:work-items:delete",
                name: "Delete",
                conditions: ["zerodai:commands:work-items:canDelete"],
                restrictions: [
                    { source: "Open", target: "Open" },
                    { source: "Assigned", target: "Assigned" },
                    { source: "InProgress", target: "InProgress" },
                    { source: "Blocked", target: "Blocked" },
                ],
            },
            {
                id: "zerodai:commands:work-items:flow",
                name: "Transition",
                conditions: [
                    "zerodai:commands:work-items:canFlow",
                    "zerodai:commands:work-items:isContributor",
                    "zerodai:commands:work-items:isOwner",
                ],
                restrictions: [
                    { source: "Open", target: "Open" },
                    { source: "Assigned", target: "Assigned" },
                    { source: "InProgress", target: "InProgress" },
                    { source: "Blocked", target: "Blocked" },
                    { source: "Resolved", target: "Resolved" },
                ],
            },
        ],
        conditions: [
            {
                id: "zerodai:commands:work-items:canEdit",
                name: "Can Edit",
            },
            {
                id: "zerodai:commands:work-items:canAssign",
                name: "Can Assign",
            },
            {
                id: "zerodai:commands:work-items:canStart",
                name: "Can Start",
            },
            {
                id: "zerodai:commands:work-items:canResolve",
                name: "Can Resolve",
            },
            {
                id: "zerodai:commands:work-items:canReopen",
                name: "Can Reopen",
            },
            {
                id: "zerodai:commands:work-items:canBlock",
                name: "Can Block",
            },
            {
                id: "zerodai:commands:work-items:canUnblock",
                name: "Can Unblock",
            },
            {
                id: "zerodai:commands:work-items:canClose",
                name: "Can Close",
            },
            {
                id: "zerodai:commands:work-items:canCancel",
                name: "Can Cancel",
            },
            {
                id: "zerodai:commands:work-items:canDelete",
                name: "Can Delete",
            },
            {
                id: "zerodai:commands:work-items:canFlow",
                name: "Can Transition",
            },
            {
                id: "zerodai:commands:work-items:isReader",
                name: "Is Reader",
            },
            {
                id: "zerodai:commands:work-items:isContributor",
                name: "Is Contributor",
            },
            {
                id: "zerodai:commands:work-items:isOwner",
                name: "Is Owner",
            },
        ],
    },
};

export const isTargetStartState = (target, state) => {
    return TargetMetadata[target].startStates.indexOf(state.mappedTo) !== -1;
};
export const isTargetEndState = (target, state) => {
    return TargetMetadata[target].endStates.indexOf(state.mappedTo) !== -1;
};
export const isTargetCancelState = (target, state) => {
    return TargetMetadata[target].cancelStates.indexOf(state.mappedTo) !== -1;
};

export const hasTransitionStates = (target, sourceMappedTo, targetMappedTo) => {
    if (!sourceMappedTo || !targetMappedTo) {
        return false;
    }

    return (
        TargetMetadata[target].commands.findIndex(
            (c) => c.restrictions.findIndex((r) => r.source === sourceMappedTo && r.target === targetMappedTo) !== -1
        ) !== -1
    );
};
