import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';
import * as moment from 'moment';

import { Persona, PersonaSize } from 'office-ui-fabric-react/lib/Persona';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Card } from '@uifabric/react-cards';

import { RiskScore } from '../reviews/RiskScore';
import { RoutingLink } from '../../../base/components/RoutingLink';
import { PhotoPersona } from '../../../identity/containers/utilities/PhotoPersona';
const rootClassName = cx(
    'cygraph-RiskCardView-root',
    css` 
        max-width: 100%;
        margin-bottom: var(--spacing-s);
        background-color: var(--app-header-background);
        padding: 0 0 var(--spacing-xs) 0;
        user-select: none;

        & > .ms-CardItem {
            margin-top: 3px;
            padding: 0 var(--spacing-xs);
        }

        & > .headerItem {
            margin-top: 0;
            padding: var(--spacing-xs);
            border-bottom: 2px solid var(--border-color-darker);
            background-color: var(--border-color);
        }

        & > .scoreItem {
            padding: var(--spacing-xs);
            border-bottom: 1px solid var(--body-background);
        }

        & > .footerItem {
            margin-top: 10px;
        }

        &.selected {
            & > .headerItem {
                /*background: #e7e4f1;*/
            }
        }
    `
);


export const RiskCardView = observer(
    class RiskCardView extends PureComponent {
        
        render () {
            const { risk, className, onRiskClick, selected } = this.props;
            return (
                <Card
                    aria-label="Clickable vertical card with image bleeding at the center of the card" 
                    className={cx(className, rootClassName, selected && 'selected')}
                    onClick={() => {onRiskClick && onRiskClick(risk)}}>
                    <Card.Item className="headerItem">
                        <div className="header">
                            <Stack horizontal tokens={{ childrenGap: 16 }}>
                                <Text variant="medium" className="code">
                                    <RoutingLink url={`/risks/${risk.id}`} onClick={(ev, url, routing) => {
                                        ev.stopPropagation();
                                        routing.push(url);
                                    }}>
                                    {risk.code}
                                    </RoutingLink>
                                </Text>
                                <Stack.Item grow={1}>
                                    <span />
                                </Stack.Item>
                                <Text variant="medium" className="portfolio">{risk.portfolio.name}</Text>
                            </Stack>
                        </div>
                    </Card.Item>
                    <Card.Item>
                        <Text className="title">{risk.title}</Text>
                    </Card.Item>
                    <Card.Item className="scoreItem">
                        <Stack horizontal horizontalAlign="space-between">
                            <Text variant="small">
                                <RiskScore 
                                    score={risk.review ? risk.review.grossScore : null} 
                                    size="mini"
                                    maxImpact={4} 
                                    maxLikelihood={4} />
                            </Text>
                            <Text variant="small">
                                <RiskScore 
                                    score={risk.review ? risk.review.netScore : null} 
                                    size="mini"
                                    maxImpact={4} 
                                    maxLikelihood={4} />
                            </Text>
                        </Stack>
                    </Card.Item>
                    <Card.Item className="footerItem">
                        <div className="footer">
                            <Stack horizontal tokens={{ childrenGap: 16 }}>
                                <PhotoPersona
                                    principalId={risk.modifiedBy ? risk.modifiedBy.id : null}
                                    text={risk.modifiedBy.name} 
                                    size={PersonaSize.size28}
                                    showSecondaryText
                                    secondaryText={moment.utc(risk.modified).format('Do MMM YYYY')} />
                                <Stack.Item grow={1}>
                                    <span />
                                </Stack.Item>
                                <Icon iconName="MoreVertical" className="icon" />
                            </Stack>
                        </div>
                    </Card.Item>
                </Card>
            );
        }
    }
);