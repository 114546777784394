import React, { useCallback } from "react";
import { observer } from "mobx-react";

import { PanelType } from "office-ui-fabric-react/lib/Panel";

import { ActionButton, SpinnerButton } from "@ui/elements/Button";

import { ObservablePanel } from "@modules/base/components/ObservablePanel";
import { ActionPanel } from "@modules/base/components/ActionPanel";

import { WorkItemAssignForm } from "../../components/core/WorkItemAssignForm";

export const WorkItemAssignPanel = observer(({ store }) => {
    const { visible, formData, formOptions, isValid, processing } = store;

    const onSave = useCallback(() => {
        store.resolve(true);
    }, [store]);

    const onDismiss = useCallback(() => {
        store.resolve(false);
    }, [store]);

    return (
        <ObservablePanel
            hidden={!visible}
            pageType="Assign Group &amp; User"
            pageTitle={formOptions ? formOptions.pageTitle : "Demand Request"}
            panelType={PanelType.smallFixedFar}
            onClosePanel={onDismiss}
            onLightDismissClick={onDismiss}
            onRenderFooter={() => (
                <ActionPanel>
                    <ActionButton onClick={onDismiss} text="Cancel" disabled={processing} />
                    <SpinnerButton
                        onClick={onSave}
                        iconProps={{ iconName: "AddFriend" }}
                        text="Assign"
                        disabled={!isValid || processing}
                        loading={processing}
                    />
                </ActionPanel>
            )}
        >
            {formData && <WorkItemAssignForm formData={formData} formOptions={formOptions} isValid={isValid} />}
        </ObservablePanel>
    );
});
