import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { useStores } from "@hooks/useStores";
import Dropzone from "react-dropzone";
import { cx } from "emotion";

import { StateFlowProgressBar } from "@modules/stateflows/components/core/StateFlowProgressBar";
import { StateFlowActionBar } from "@modules/stateflows/components/core/StateFlowActionBar";

import { WorkItemHeader } from "@modules/workitems/components/core/WorkItemHeader";
import { WorkItemAssessmentBar } from "@modules/workitems/components/core/WorkItemAssessmentBar";

import { ProductPickerPanel } from "@modules/products/containers/panels/ProductPickerPanel";

import { WorkItemAssignPanel } from "@modules/workitems/containers/panels/WorkItemAssignPanel";
import { WorkItemNewPanel } from "@modules/workitems/containers/panels/WorkItemNewPanel";
import { WorkItemEditPanel } from "@modules/workitems/containers/panels/WorkItemEditPanel";
import { WorkItemLinkNewPanel } from "@modules/workitems/containers/panels/WorkItemLinkNewPanel";
import { WorkItemLinkEditPanel } from "@modules/workitems/containers/panels/WorkItemLinkEditPanel";
import { WorkItemTabList } from "@modules/workitems/containers/tabs/WorkItemTabList";

export const WorkItemViewerPage = withRouter(
    observer(({ match }) => {
        const { workItemStore, productStore, routingManager } = useStores();
        const { viewerStore, documentStore, lifecycleStore, stateFlowStore } = workItemStore;
        const { loading, error, breadcrumb, workItem, selectedTab } = viewerStore;
        const { manifest, subject, triggers, conditions, state, subState, subStateId, currentSubState } =
            stateFlowStore;

        useEffect(() => {
            if (match.params.id) {
                viewerStore.loadWorkItem(match.params.id);
            }
        }, [match.params.id]);

        useEffect(() => {
            viewerStore.selectTab(match.params.tab);
        }, [match.params.id, match.params.tab]);

        return (
            <>
                {workItem && (
                    <>
                        <Dropzone
                            onDrop={(acceptedFiles) => documentStore.uploadDocuments(acceptedFiles, workItem.id)}
                            multiple={true}
                            noClick={true}
                        >
                            {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, open }) => (
                                <div
                                    {...getRootProps()}
                                    className={cx(
                                        "dropzone",
                                        isDragActive && "active",
                                        isDragAccept && "accept",
                                        isDragReject && "reject"
                                    )}
                                >
                                    <input {...getInputProps()} />

                                    <WorkItemHeader
                                        workItem={workItem}
                                        triggers={triggers}
                                        conditions={conditions}
                                        onTrigger={(trigger, options) => stateFlowStore.onTrigger(trigger, options)}
                                        onNavigateBack={() => routingManager.push("/demand/browse")}
                                    />

                                    <StateFlowProgressBar
                                        manifest={manifest}
                                        subject={subject}
                                        state={state}
                                        subState={subState}
                                        subStateId={subStateId}
                                    />

                                    <StateFlowActionBar routingManager={routingManager} subState={currentSubState} />

                                    {workItem.assessmentId && (
                                        <WorkItemAssessmentBar
                                            routingManager={routingManager}
                                            assessmentId={workItem.assessmentId}
                                        />
                                    )}

                                    <WorkItemTabList workItem={workItem} selectedTab={selectedTab} onUpload={open} />

                                    <ProductPickerPanel store={productStore.pickerStore} />
                                    <WorkItemNewPanel store={workItemStore.newFormStore} />
                                    <WorkItemEditPanel store={workItemStore.editFormStore} />
                                    <WorkItemLinkNewPanel store={workItemStore.linkNewFormStore} />
                                    <WorkItemLinkEditPanel store={workItemStore.linkEditFormStore} />
                                    <WorkItemAssignPanel store={workItemStore.assignFormStore} />
                                </div>
                            )}
                        </Dropzone>
                    </>
                )}
            </>
        );
    })
);
