import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';

import { Text } from 'office-ui-fabric-react/lib/Text';
import { Stack } from 'office-ui-fabric-react/lib/Stack';

import { ObservableBadge } from '../../../base/components/ObservableBadge';
import { ActionSet } from '../../../base/components/ActionSet';

const rootClassName = cx(
  'cygraph-PortfolioHeader-root',
  css` 
    & .badge {
      & > div {
        outline: none;
      }
    } 

    & .title {
      margin: var(--spacing-s) 0;
      & > div {
        outline: none;
      }
    }

    & .description {
      color: var(--text-color-light);
      font-size: var(--font-size-xs);
      margin-top: var(--spacing-xs);
      & > div {
        outline: none;
      }
    }

    & .meta {
      display: inline-block;
      margin-right: 20px;
    }

    & .facepile {
      margin: var(--spacing-s) 0 var(--spacing-xxs) 0;

      & .ms-Facepile-member:not(:last-child) {
        margin-right: -10px;
      }

      & .ms-Facepile-addButton {
        background-color: transparent;
      }
    }
  `
);

export const RiskTypeViewerPageHeader = observer(
  class RiskTypeViewerPageHeader extends PureComponent {

    render () {
        const { className, riskType } = this.props;
        const { items, overflowItems } = this._getActionSetItems();

        return (
          <div className={cx(rootClassName, className)}>
            <Stack horizontal horizontalAlign="space-between">
              <ObservableBadge className="badge">Risk Type</ObservableBadge>
              <ActionSet items={items} overflowItems={overflowItems} />
            </Stack>
            <Stack horizontal horizontalAlign="space-between">
              <Stack.Item grow>
                <Text className="title" variant="xLarge" block>{riskType && riskType.name}</Text>
              </Stack.Item>             
            </Stack>
          </div>
        );
    }

    _getActionSetItems() {
      const { hideActions, canEdit, onEdit } = this.props;
      const items = [];
      const overflowItems = [];

      if(hideActions) {
        return { items, overflowItems };
      }

      if (canEdit) items.push({
        key: 'edit',
        name: 'Edit',
        iconProps: { iconName: 'Edit' },
        ariaLabel: 'Edit',
        onClick: onEdit,
      });

      return { items, overflowItems };
    }
  }
);