export const ConfigModules = {
    Core: 'zerodai:core',
    Modelling: 'zerodai:modelling',
    Assessments: 'zerodai:assessments',
    WorkItems: 'zerodai:work-items',
    Risks: 'zerodai:risks',
    Insights: 'zerodai:insights',
    Assets: 'zerodai:assets',
    Entities: 'zerodai:entities',
    Planning: 'zerodai:planning',
    Awareness: 'zerodai:awareness',
};