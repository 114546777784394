import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import * as moment from "moment";

import { AppPage } from "@modules/layout/containers/AppPage";

import { WorkItemTimelineView } from "@modules/workitems/components/core/WorkItemTimelineView";
import { WorkItemSideNav } from "@modules/workitems/components/core/WorkItemSideNav";

export const WorkItemTimelinePage = inject(
    "pageContext",
    "workItemStore",
    "boardsStore",
    "routing"
)(
    observer(
        class WorkItemTimelinePage extends Component {
            componentWillMount() {
                const { workItemStore, boardsStore, routing, pageContext } = this.props;

                pageContext.updateQueryStringParams(routing.location.search);
                if (pageContext.queryStringParams.start && pageContext.queryStringParams.end) {
                    workItemStore.timelineStore.loadWorkItems({
                        view: pageContext.queryStringParams.view,
                        date: pageContext.queryStringParams.date,
                        start: pageContext.queryStringParams.start,
                        end: pageContext.queryStringParams.end,
                    });
                }
                boardsStore.loadBoards({ cached: true });
            }

            componentWillReceiveProps(nextProps) {
                const { workItemStore, boardsStore, routing, pageContext } = nextProps;

                pageContext.updateQueryStringParams(routing.location.search);
                if (pageContext.queryStringParams.start && pageContext.queryStringParams.end) {
                    workItemStore.timelineStore.loadWorkItems({
                        view: pageContext.queryStringParams.view,
                        date: pageContext.queryStringParams.date,
                        start: pageContext.queryStringParams.start,
                        end: pageContext.queryStringParams.end,
                    });
                }
                boardsStore.loadBoards({ cached: true });
            }

            render() {
                const { workItemStore, boardsStore, routing } = this.props;
                const { timelineStore } = workItemStore;
                const { breadcrumb, error, loading, date, events, resources, products, view, isAgent } = timelineStore;

                return (
                    <AppPage
                        error={error}
                        breadcrumb={breadcrumb}
                        loading={loading || !events}
                        hasSidebar={false}
                        className="work-items list has--page-nav"
                    >
                        <WorkItemSideNav
                            isAgent={isAgent}
                            onLinkClick={(item) => routing.push(item.url)}
                            onNewRequest={() => routing.push(`/demand/catalogue`)}
                        />

                        <WorkItemTimelineView
                            events={events}
                            resources={resources}
                            products={products}
                            view={view}
                            loading={loading}
                            date={date}
                            query={{}}
                            onDatesSet={(rangeInfo) => {
                                // console.log('onDatesSet', rangeInfo);
                                const date = moment(rangeInfo.view.calendar.getDate()).utc().format();
                                const start = moment(rangeInfo.start).utc().format();
                                const end = moment(rangeInfo.end).utc().format();
                                routing.push(
                                    `/demand/timeline?view=${rangeInfo.view.type.substr(
                                        16
                                    )}&date=${date}&start=${start}&end=${end}`
                                );
                            }}
                            onEventClick={(info) => {
                                routing.push(`/demand/browse/${info.event.extendedProps.assessment.id}`);
                            }}
                        />
                    </AppPage>
                );
            }
        }
    )
);
