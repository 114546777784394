import React from "react";
import { observer } from "mobx-react";

import { CommandBar } from "@ui/elements/CommandBar";

import { BoxPanel } from "@modules/base/components/BoxPanel";

import { WorkItemFilterBar } from "@modules/workitems/components/core/WorkItemFilterBar";

export const WorkItemCommandBar = observer(
    ({ isGuestUser, filterQuery, filterContext, onFilterChange, onNewRequest, onExport }) => {
        const items = [
            {
                showToGuest: false,
                key: "newRequest",
                text: "New Request",
                iconProps: { iconName: "Add" },
                onClick: () => onNewRequest(null),
            },
        ];

        const farItems = [];

        if (filterQuery && onFilterChange && filterContext) {
            farItems.push({
                key: "filterBar",
                text: "Filter Bar",
                onRender: () => {
                    return <WorkItemFilterBar value={filterQuery} onChange={onFilterChange} context={filterContext} />;
                },
            });
        }

        if (onExport) {
            farItems.push({
                className: "test-ExportToCSV",
                key: "export",
                name: "Export to CSV",
                iconOnly: true,
                iconProps: { iconName: "ExcelDocument" },
                onClick: onExport,
            });
        }

        return (
            <BoxPanel shadow dataTest={`WorkItemCommandBar`}>
                <CommandBar
                    styles={{ root: { paddingRight: "0px" } }}
                    items={isGuestUser ? items.filter((I) => I.showToGuest) : items}
                    farItems={farItems}
                />
            </BoxPanel>
        );
    }
);
