import React from "react";
import { observer } from "mobx-react";
import { useId } from "@fluentui/react-hooks";

import { Persona, PersonaSize } from "office-ui-fabric-react/lib/Persona";

import { TextField } from "@ui/elements/TextField";
import { SearchBox } from "@ui/elements/SearchBox";
import { Dropdown } from "@ui/elements/Dropdown";
import { DateRange } from "@ui/elements/DateRange";
import { Stack } from "@ui/elements/Stack";
import { ExtendedLabel } from "@ui/elements/ExtendedLabel";
import { Label } from "@ui/elements/Label";
import { IconButton } from "@ui/elements/Button";
import { DurationField } from "@ui/elements/DurationField";
import { DurationRangeField } from "@ui/elements/DurationRangeField";
import { Toggle } from "@ui/elements/Toggle";

import { UserHoverCard } from "@modules/identity/containers/utilities/UserHoverCard";
import { PhotoPersona } from "@modules/identity/containers/utilities/PhotoPersona";
import { ProductPicker } from "@modules/products/containers/pickers/ProductPicker";
import { RegistryPicker } from "@modules/risks/containers/pickers/RegistryPicker";
import { PortfolioPicker } from "@modules/portfolios/containers/pickers/PortfolioPicker";
import { PrincipalPicker } from "@modules/identity/containers/PrincipalPicker";

const AdaptiveFieldRegistrations = {};
const EmptyDisplayText = "Not Set";

export const registerFieldRenderer = (type, mode, component) => {
    AdaptiveFieldRegistrations[type] = AdaptiveFieldRegistrations[type] || {};
    AdaptiveFieldRegistrations[type][mode] = component;
};

export const SearchAdaptiveField = observer(({ value, onChange, ...rest }) => {
    return (
        <SearchBox value={value || ""} onSearch={(value) => onChange(value)} onClear={() => onChange(null)} {...rest} />
    );
});

export const TextAdaptiveField = observer(({ value, onChange, ...rest }) => {
    return <TextField value={value || ""} onChange={(ev, value) => onChange(value)} {...rest} />;
});

export const ToggleAdaptiveField = observer(({ value, onChange, ...rest }) => {
    return <Toggle checked={value} onChange={(ev, value) => onChange(value)} {...rest} />;
});

export const PrincipalAdaptiveField = observer(({ value, onChange, valueConverter, multiple, ...rest }) => {
    return (
        <PrincipalPicker
            selectedIds={convertFromSelection(valueConverter, multiple, value)}
            onSelected={(users) => {
                onChange(convertToSelection(valueConverter, multiple, users));
            }}
            multiple={multiple}
            {...rest}
        />
    );
});

export const PrincipalViewAdaptiveField = observer(({ value, label, secondaryText }) => {
    return (
        <>
            <Label>{label}</Label>
            {value ? (
                value.type === "User" ? (
                    <UserHoverCard principal={value}>
                        <PhotoPersona
                            principalId={value.id}
                            size={PersonaSize.size28}
                            text={value.name}
                            showSecondaryText={true}
                            secondaryText={secondaryText || value.jobTitle}
                        />
                    </UserHoverCard>
                ) : (
                    <Persona size={PersonaSize.size28} text={value.name} />
                )
            ) : (
                EmptyDisplayText
            )}
        </>
    );
});

export const DropdownAdaptiveField = observer(
    ({ label, required, disabled, value, onChange, multiple, allowClear, ...rest }) => {
        const pickerId = useId("dropdownAdaptiveField");
        return (
            <Stack>
                <ExtendedLabel label={label} required={required} disabled={disabled} htmlFor={pickerId}>
                    {allowClear && (multiple ? value && value.length > 0 : !!value) && (
                        <IconButton
                            styles={{ root: { width: 24, height: 24 }, icon: { fontSize: 13, lineHeight: 13 } }}
                            iconProps={{ iconName: "ClearFilter" }}
                            onClick={() => onChange(multiple ? [] : null)}
                        />
                    )}
                </ExtendedLabel>
                <Dropdown
                    id={pickerId}
                    required={required}
                    disabled={disabled}
                    selectedKey={multiple ? undefined : value || null}
                    selectedKeys={multiple ? value || [] : undefined}
                    onChange={(_, option) => {
                        if (multiple) {
                            if (option) {
                                const newSelected = [...(value || [])];
                                const index = newSelected.indexOf(option.key);
                                if (index == -1) {
                                    newSelected.push(option.key);
                                } else {
                                    newSelected.splice(index, 1);
                                }
                                onChange(newSelected);
                            }
                        } else {
                            onChange(option ? option.key : null);
                        }
                    }}
                    multiSelect={multiple}
                    {...rest}
                />
            </Stack>
        );
    }
);

export const DateRangeAdaptiveField = observer(({ value, onChange, before, ...rest }) => {
    // TODO
    return <DateRange onChange={(ev, val) => onChange(val)} {...rest} />;
});

export const DurationAdaptiveField = observer(({ onChange, ...rest }) => {
    // TODO
    return <DurationField onChange={(val, display) => onChange(val, display)} {...rest} />;
});

export const DurationRangeAdaptiveField = observer(({ onChange, ...rest }) => {
    // TODO
    return <DurationRangeField onChange={(val, display) => onChange(val, display)} {...rest} />;
});

export const AdaptiveField = observer(({ type, mode, name, ...rest }) => {
    if (!AdaptiveFieldRegistrations[type]) {
        console.log(`Adaptive field type ${type} is unknown`);
        return null;
    }

    let searchMode = mode || "edit";
    if (!AdaptiveFieldRegistrations[type][searchMode]) {
        searchMode = "all";
    }

    const FieldRenderComponent = AdaptiveFieldRegistrations[type][searchMode];
    if (!FieldRenderComponent) {
        console.log(`Adaptive field type ${type} does not support mode ${mode}`);
        return null;
    }

    return <FieldRenderComponent {...rest} />;
});

registerFieldRenderer("Search", "all", SearchAdaptiveField);
registerFieldRenderer("Text", "all", TextAdaptiveField);
registerFieldRenderer("Dropdown", "all", DropdownAdaptiveField);
registerFieldRenderer("Principal", "all", PrincipalAdaptiveField);
registerFieldRenderer("Principal", "view", PrincipalViewAdaptiveField);
registerFieldRenderer("DateRange", "all", DateRangeAdaptiveField);
registerFieldRenderer("Duration", "all", DurationAdaptiveField);
registerFieldRenderer("DurationRange", "all", DurationRangeAdaptiveField);
registerFieldRenderer("Toggle", "all", ToggleAdaptiveField);

const convertFromSelection = (type, multiple, value) => {
    switch (type || "complex") {
        case "id":
            return value;
        case "simple":
            if (multiple) {
                return (value || []).map((v) => v.id);
            }
            return value ? value.id : null;
        case "complex":
            if (multiple) {
                return (value || []).map((v) => v.id);
            }
            return value ? value.id : null;
    }
};

const convertToSelection = (type, multiple, value) => {
    switch (type || "complex") {
        case "id":
            if (multiple) {
                return (value || []).map((v) => v.id);
            }
            return value ? value.id : null;
        case "simple":
            if (multiple) {
                return (value || []).map((v) => ({
                    id: v.id,
                }));
            }
            return value ? { id: value.id } : null;
        case "complex":
            return value;
    }
};

export const ProductAdaptiveField = observer(({ value, onChange, valueConverter, multiple, ...rest }) => {
    return (
        <ProductPicker
            value={convertFromSelection(valueConverter, multiple, value)}
            onChange={(products) => {
                onChange(convertToSelection(valueConverter, multiple, products));
            }}
            multiple={multiple}
            {...rest}
        />
    );
});

registerFieldRenderer("Product", "all", ProductAdaptiveField);

export const PortfolioAdaptiveField = observer(({ value, onChange, valueConverter, multiple, ...rest }) => {
    return (
        <PortfolioPicker
            selectedIds={convertFromSelection(valueConverter, multiple, value)}
            onChange={(products) => {
                onChange(convertToSelection(valueConverter, multiple, products));
            }}
            multiple={multiple}
            {...rest}
        />
    );
});

registerFieldRenderer("Portfolio", "all", PortfolioAdaptiveField);

export const RiskRegistryAdaptiveField = observer(({ value, onChange, valueConverter, multiple, ...rest }) => {
    return (
        <RegistryPicker
            selectedIds={convertFromSelection(valueConverter, multiple, value)}
            onChange={(registries) => {
                onChange(convertToSelection(valueConverter, multiple, registries));
            }}
            multiple={multiple}
            {...rest}
        />
    );
});

registerFieldRenderer("Registry", "all", RiskRegistryAdaptiveField);
