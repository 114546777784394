import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { Persona, PersonaSize } from "office-ui-fabric-react/lib/Persona";

import { Label } from "@ui/elements/Label";
import { TooltipHost } from "@ui/elements/Tooltip";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { AdaptiveField } from "@modules/base/components/AdaptiveField";
import { FieldSetView } from "@modules/base/components/FieldSetView";
import { UserHoverCard } from "@modules/identity/containers/utilities/UserHoverCard";
import { PhotoPersona } from "@modules/identity/containers/utilities/PhotoPersona";

import { ControlMaturity } from "@modules/controls/components/core/ControlMaturity";
import { DeploymentGridView } from "@modules/controls/components/deployments/DeploymentGridView";

export const StrategicControlSummaryTab = observer(({ control, etag }) => {
    const { principalContext, productStore, workItemStore } = useStores();
    // const { commentStore } = workItemStore;
    // const { schema } = productStore;
    // const current = schema && workItem && schema.productId === workItem.product.id;

    // useEffect(() => {
    //     workItemStore.summaryStore.loadWorkItemSummary(workItem);
    //     productStore.loadProductSchema(workItem.product.id, workItem.schemaVersion);
    //     return () => workItemStore.summaryStore.unloadWorkItemSummary(workItem);
    // }, [workItem, etag]);

    return (
        <BoxPanel shadow padding style={{ minHeight: 300 }}>
            <FieldSetView title="Key Information" description="Basic information about this tactical control.">
                <r-grid columns="2" class="no--margin">
                    <r-cell>
                        <div className="row--xs">
                            <Label>Compliance Score</Label>
                            <div>4.6</div>
                        </div>
                    </r-cell>
                    <r-cell>
                        <div className="row--xs">
                            <Label>Last Audit</Label>
                            <div>24 Feb 2022</div>
                        </div>
                    </r-cell>
                    <r-cell>
                        <div className="row--xs">
                            <Label>Maturity Level</Label>
                            <ControlMaturity size="medium" score={"Defined"} />
                        </div>
                    </r-cell>
                    <r-cell>
                        <div className="row--xs">
                            <Label>Total Scope</Label>
                            <div>23 assets</div>
                        </div>
                    </r-cell>
                    <r-cell>
                        <div className="row--xs">
                            <AdaptiveField type="Principal" mode="view" label="Owner" value={control.modifiedBy} />
                        </div>
                    </r-cell>
                    <r-cell>
                        <div className="row--xs">
                            <AdaptiveField
                                type="Principal"
                                mode="view"
                                label="Assigned To"
                                value={control.modifiedBy}
                            />
                        </div>
                    </r-cell>
                </r-grid>
            </FieldSetView>
        </BoxPanel>
    );
});
