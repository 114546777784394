import React, { Component } from "react";
import { observer, inject } from "mobx-react";

import { ComingSoon } from "@modules/base/components/ComingSoon";
import { BoxPanel } from "@modules/base/components/BoxPanel";

export const PatternDiagramTab = inject(
    "modellingStore",
    "routing"
)(
    observer(
        class PatternDiagramTab extends Component {
            componentWillMount() {
                const { modellingStore, routing } = this.props;
            }

            componentWillReceiveProps(nextProps) {
                const { modellingStore, routing } = nextProps;
            }

            render() {
                const { modellingStore, routing } = this.props;

                return (
                    <BoxPanel shadow padding>
                        <ComingSoon />
                    </BoxPanel>
                );
            }
        }
    )
);
