import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "@hooks/useStores";

import { ObservablePagination } from "@modules/base/components/ObservablePagination";

import { StateFlowVersionGridView } from "@modules/stateflows/components/versions/StateFlowVersionGridView";

export const StateFlowVersionsTab = observer(({ stateFlow }) => {
    const { stateFlowStore } = useStores();
    const { versions, loading, pagination } = stateFlowStore.versionStore;

    useEffect(() => {
        if (stateFlow != null) {
            stateFlowStore.versionStore.loadStateFlowVersions({ stateFlowId: stateFlow.id });
        }
    }, [stateFlow]);

    return (
        <div>
            <StateFlowVersionGridView loading={loading} versions={versions} />

            <ObservablePagination
                {...pagination}
                onPageChange={(index) => {
                    stateFlowStore.versionStore.loadStateFlowVersions({ stateFlowId: stateFlow.id, page: index + 1 });
                }}
            />
        </div>
    );
});
