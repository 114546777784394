import React from "react";
import { observer } from "mobx-react";
import moment from "moment";

import { TextField } from "office-ui-fabric-react/lib/TextField";
import { Text } from "office-ui-fabric-react/lib/Text";
import { DatePicker } from "office-ui-fabric-react/lib/DatePicker";
import { Toggle } from "office-ui-fabric-react/lib/Toggle";

import { BigTextField } from "@modules/base/components/BigTextField";
import { RichDropdown } from "@modules/base/richdropdown/RichDropdown";
import { PrincipalPicker } from "@modules/identity/containers/PrincipalPicker";

export const TaskDefinitionEditForm = observer(({ disabled, formData, formOptions }) => {
    return (
        <>
            <r-grid columns="2">
                <r-cell span="2">
                    {formOptions && formOptions.simpleTitleField ? (
                        <TextField
                            label="Title"
                            required
                            placeholder="Please enter a task title"
                            dataTest="newWorkItemTitle"
                            disabled={disabled}
                            value={formData.title}
                            onChange={(ev, value) => {
                                formData.title = value;
                            }}
                        />
                    ) : (
                        <BigTextField
                            label="Title"
                            required
                            placeholder="Please enter a task title"
                            disabled={disabled}
                            value={formData.title}
                            transparent
                            onChange={(ev, value) => {
                                formData.title = value;
                            }}
                        />
                    )}
                </r-cell>
                <r-cell span="2">
                    <TextField
                        label="Description"
                        disabled={disabled}
                        multiline
                        required
                        rows={3}
                        value={formData.description}
                        onChange={(ev, value) => {
                            formData.description = value;
                        }}
                    />
                </r-cell>
                <r-cell>
                    <DatePicker
                        label="Due date"
                        isRequired
                        disabled={disabled}
                        value={moment.utc(formData.dueDate || new Date()).toDate()}
                        onSelectDate={(dateValue) => {
                            formData.dueDate = dateValue;
                        }}
                        placeholder="Select a due date..."
                        ariaLabel="Select a due date"
                    />
                </r-cell>
                <r-cell>&nbsp;</r-cell>
                <r-cell>
                    <RichDropdown
                        disabled={!!formData.id || disabled}
                        placeholder="Task type"
                        label="Task type"
                        selectedKey={formData.useCase}
                        required
                        options={[
                            {
                                title: "Complete Task",
                                key: "Completion",
                                description: <Text>Task will display with a 'Mark as completed' button</Text>,
                            },
                            {
                                title: "Approve/Reject Task",
                                key: "Approval",
                                description: <Text>Task will display with Approve & Reject buttons</Text>,
                            },
                            {
                                title: "Yes/No Task",
                                key: "Confirmation",
                                description: <Text>Task will display with Yes & No buttons</Text>,
                            },
                        ]}
                        onOptionChanged={(item) => {
                            formData.useCase = item.key;
                        }}
                    />
                </r-cell>
                <r-cell>
                    <RichDropdown
                        placeholder="Select an option"
                        label="Task response mode"
                        disabled={!!formData.id || disabled}
                        selectedKey={formData.mode}
                        required
                        onOptionChanged={(option) => {
                            formData.mode = option.key;
                        }}
                        options={[
                            {
                                key: "First",
                                title: "Single response",
                                description: <Text>Task will be complete when any user responds</Text>,
                            },
                            // {
                            //     key: 'Any',
                            //     title: 'Any response will complete task',
                            //     description: <Text>Any response will close the task</Text>
                            // },
                            {
                                key: "All",
                                title: "Multi response",
                                description: <Text>Task will be complete when all assigned users respond</Text>,
                            },
                        ]}
                    />
                </r-cell>
                <r-cell span="2">
                    <PrincipalPicker
                        label="Assigned to"
                        placeholder="Pick a user"
                        disabled={disabled}
                        multiple
                        selected={formData.assignedTos}
                        onSelected={(users) => {
                            formData.assignedTos = users;
                        }}
                    />
                </r-cell>
                <r-cell>
                    <Toggle
                        label="Send email notification on assignment"
                        disabled={disabled}
                        checked={formData.notify}
                        onChange={(ev, checked) => {
                            formData.notify = checked;
                        }}
                    />
                </r-cell>
                <r-cell span="2">
                    <PrincipalPicker
                        label="On completion send notification to"
                        placeholder="Pick a user"
                        disabled={disabled}
                        multiple
                        selected={formData.settings.listeners.completion || []}
                        onSelected={(users) => {
                            formData.settings.listeners.completion = users;
                        }}
                    />
                </r-cell>
            </r-grid>
        </>
    );
});
