import { generateAdvisoriesQuery } from '../queries';

async function getAdvisoriesFlatByControlFinalCompliance(query, groupBy, api) {
    const computedQuery = generateAdvisoriesQuery(query);
    const sentQuery = {
        query: computedQuery,
        groupBy: groupBy ? groupBy : "parentControl",
    };
    // debugger
    // console.log('sentQuery', JSON.stringify(sentQuery))
    const results = await api(sentQuery);
    return results;
}

export { getAdvisoriesFlatByControlFinalCompliance }