import { observable, action, computed, flow } from "mobx";
import { BaseChartStoreClass } from './BaseChartStoreClass';

 /**
  *             chartData = [
                {
                    category: 'Food',
                    values: [
                        {
                            group: 'totalOpen',
                            value: 20,
                        },
                        {
                            group: 'openInPeriod',
                            value: 12,
                        }, 
                        {
                            group: 'closedInPeriod',
                            value: 12,
                        },
                    ]
                },
                {
                    category: 'Cyber',
                    values: [
                        {
                            group: 'totalOpen',
                            value: 50,
                        },
                        {
                            group: 'openInPeriod',
                            value: 20,
                        }, 
                        {
                            group: 'closedInPeriod',
                            value: 36,
                        },
                    ]
                },
                {
                    category: 'Clothing',
                    values: [
                        {
                            group: 'totalOpen',
                            value: 30,
                        },
                        {
                            group: 'openInPeriod',
                            value: 16,
                        }, 
                        {
                            group: 'closedInPeriod',
                            value: 8,
                        },
                    ]
                },
            ];
  */
export class StackedBarStore extends BaseChartStoreClass{

    private onClicks = {};
    public onClickCallbackExists = false;

    @computed public get isDynamic() {
        if (this.rawData.find(RD => RD.isDynamic)) return true
        return false
    }

    @computed public get xField() {
        // debugger
        if (this.rawData.find(RD => RD.fieldType === 'xField')) {
            return this.rawData.find(RD => RD.fieldType === 'xField').dataX || '';
        } else {
            return ''
        }
    }

    @computed public get data() {
        let chartData = [];
        this.onClicks = {};
        // console.log('this.isDynamic', this.isDynamic)
        if (this.isDynamic) {
            // isDynamic: true,
            if (this.rawData[0].dataSource.length > 0) {
                this.rawData[0].dataSource.forEach((row, i) => {                    
                    const groupBy = this.rawData[0].dataX;
                    const value = this.rawData[0].valueField;
                    const stackByField = this.rawData[0].stackByField;
                    if (!chartData.find(C => C.category === row[groupBy])) {
                        chartData.push({
                            category: row[groupBy],
                            values: [],
                        });
                    }
                    const cat = chartData.find(C => C.category === row[groupBy]);
                    // this.onClicks.push(this.isFunction(D.onClick) ? D.onClick.bind(this, DS.id) : null)
                    // this.onClicks[`${i}${D.label}`] = this.isFunction(D.onClick) ? D.onClick.bind(this, [DS.id, D.dataValue]) : null;
                    cat.values.push(Object.assign({},{
                        group: row[stackByField],
                        value: row[value],
                    }));
                });
            }
        } else {
            this.rawData
                .filter(RD => RD.fieldType !== 'xField' )
                .forEach((D) => {
                    D.dataSource.forEach((DS,i) => {                        
                        if (!chartData.find(C => C.category === DS[this.xField])) {
                            // debugger
                            chartData.push({
                                category: DS[this.xField],
                                values: [],
                            });
                        }
                        const cat = chartData.find(C => C.category === DS[this.xField]);
                        this.onClicks[`${DS.label}${D.dataX}`] = this.isFunction(D.onClick) ? D.onClick.bind(this, [DS.id, D.dataX]) : null;
                        this.onClickCallbackExists = this.isFunction(D.onClick) ? true : false;
                        // debugger
                        cat.values.push(Object.assign({},{
                            group: D.label,
                            value: DS[D.dataX],
                        }));
                    })
            });
        }
        return chartData;
    }

    @computed public get categories() {
        const categories = new Set();
        this.data.forEach(dataSet => {
            categories.add(dataSet.category);
        });
        return Array.from(categories)
    }

    public getChartData(element) {
        const categories = new Set();
        const groups = new Set();
        const cols = {};
        this.data.forEach(dataSet => {
            categories.add(dataSet.category);
            dataSet.values.forEach(value => {
                groups.add(value.group);
                if (!cols[value.group]) cols[value.group] = [];
                cols[value.group].push(value.value) 
            });
        });
        const columns = Object.keys(cols).map(C => [`${C}`, ...cols[C]])
        let title = {};
        // if (this.title && this.showTitle) {
        //     title = {
        //         position: 'top-left',
        //         padding: this.titlePadding,
        //         text: this.title,
        //     }
        // }
        // console.log('columns', columns);
        return {
            // padding: this.padding,
            bindto: element,
            title,
            data: {
                onclick: (d, i) => {                    
                    const key = `${this.categories[d.x]}${d.id}`;
                    // debugger
                    if (this.isFunction(this.onClicks[key])) this.onClicks[key](d)
                },
                // onmouseover: () => {
                //     this.onMouseOver();
                // },
                columns: columns,
                type: 'bar',
                groups: [Array.from(groups)]
            },
            axis: {
                rotated: (this.orientation =='horizontal') ? true : false,
                x: {
                    type: 'category',
                    categories: Array.from(categories),
                },
            },
        }
    }

    public csvData() {
        const csvValues = [];
        if (this.data.length > 0 && this.xField) {
            const categories = this.data.map(D => D.category);
            const groups = this.data[0].values.map(V => V.group).sort();
            for (let i=0; i<categories.length; i++) {
                const csvRow = {};
                csvRow['Dimension'] = categories[i][0].toUpperCase() + categories[i].slice(1);
                groups.forEach(G => {
                    const values = this.data.find(D => D.category === categories[i]);
                    if (values) {
                        const value = values.values.find(V => V.group === G);
                        csvRow[G] = value ? value.value : 0;
                    } else {
                        csvRow[G] = 0;
                    }
                });
                csvValues.push(csvRow);
            }   
        }
        return csvValues;
    }

}
