import React, { Component } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { NormalPeoplePicker, ValidationState } from "office-ui-fabric-react/lib/Pickers";
import { Persona, PersonaSize, PersonaPresence } from "office-ui-fabric-react/lib/Persona";
import { Label } from "office-ui-fabric-react/lib/Label";

const rootClassName = cx(
    "cygraph-AssetPicker-container",
    css`
        & .ms-BasePicker-text {
            background-color: var(--white);
            min-height: 32px;
        }
        & .ms-Persona-coin {
            width: 20px;
        }
        & .ms-Persona-initials {
            display: none;
        }
        & .ms-Persona-presence {
            top: 6px;
            right: 0;
        }
    `
);

const calloutClassName = cx(
    "cygraph-AssetPicker-callout",
    css`
        & .ms-Persona {
            width: 300px;
        }
        & .ms-Persona-coin {
            width: 10px;
        }
        & .ms-Persona-initials {
            display: none;
        }
        & .ms-Persona-presence {
            top: -4px;
            right: 14px;
        }
    `
);

const personaClassName = cx(
    "cygraph-AssetPicker-persona",
    css`
        height: 20px;
        & .ms-Persona {
            width: 300px;
        }
        & .ms-Persona-coin {
            width: 10px;
        }
        & .ms-Persona-initials {
            display: none;
        }
        & .ms-Persona-presence {
            top: 7px;
            right: 18px;
        }
    `
);

export const AssetPicker = observer(
    class AssetPicker extends Component {
        render() {
            const { label, placeholder, readOnly, required, itemLimit, disabled, className, onSelected, onFilter, selected, multiple, loading } = this.props;

            const suggestionProps = {
                suggestionsHeaderText: "Suggested apps and assets",
                mostRecentlyUsedHeaderText: "Suggested apps and assets",
                noResultsFoundText: "No apps or assets found",
                loadingText: "Loading...",
                showRemoveButtons: false,
                suggestionsAvailableAlertText: "Asset picker suggestions available",
                suggestionsContainerAriaLabel: "Suggested apps and assets",
            };

            const items = multiple ? selected || [] : selected ? [selected] : [];

            return (
                <div className={cx(className, rootClassName)} data-test={this.props["data-test"]}>
                    <Label required={required} disabled={disabled}>
                        {label}
                    </Label>

                    {readOnly ? (
                        <>{loading || items.length ? items.map((asset) => <span key={asset.id}>{asset.name}</span>) : "Not Set"}</>
                    ) : (
                        <NormalPeoplePicker
                            required={required}
                            selectedItems={items.map((s) => this._getPersonaFromAsset(s))}
                            onResolveSuggestions={(text, current, limit) => {
                                return onFilter(text, current, limit).then((result) => {
                                    return result.items.map((s) => this._getPersonaFromAsset(s));
                                });
                            }}
                            //onEmptyInputFocus={returnMostRecentlyUsed}
                            pickerSuggestionsProps={suggestionProps}
                            className={"cygraph-AssetPicker"}
                            onValidateInput={this._validateInput}
                            removeButtonAriaLabel={"Remove"}
                            itemLimit={multiple ? itemLimit : 1}
                            inputProps={{
                                placeholder: placeholder,
                            }}
                            pickerCalloutProps={{
                                className: calloutClassName,
                            }}
                            onChange={(items) => {
                                if (onSelected) {
                                    const current = items.map((i) => i.data);
                                    onSelected(multiple ? current : current.length ? current[0] : null);
                                }
                                return items;
                            }}
                            resolveDelay={300}
                            disabled={disabled}
                        />
                    )}
                </div>
            );
        }

        _getPersonaFromAsset(asset) {
            return {
                key: asset.id,
                text: asset.name,
                secondaryText: asset.alias,
                presenceTitle: `Status - ${asset.state}`,
                //size: PersonaSize.size32,
                presence: this._getPresenceFromState(asset.state),
                data: asset,
            };
        }

        _getPresenceFromState(state) {
            switch (state) {
                case "Conceptual":
                    return PersonaPresence.offline;
                case "Developing":
                    return PersonaPresence.away;
                case "Testing":
                    return PersonaPresence.away;
                case "Live":
                    return PersonaPresence.online;
                case "Decommissioning":
                    return PersonaPresence.blocked;
                case "Decommissioned":
                    return PersonaPresence.busy;
                default:
                    return PersonaPresence.offline;
            }
        }

        _getTextFromItem(persona) {
            return persona.name;
        }

        _validateInput(input) {
            if (input.indexOf("@") !== -1) {
                return ValidationState.valid;
            } else if (input.length > 1) {
                return ValidationState.warning;
            } else {
                return ValidationState.invalid;
            }
        }
    }
);
