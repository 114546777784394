import React from "react";
import { observer } from "mobx-react";

import { TextField } from "@ui/elements/TextField";

import { WorkflowUserPicker } from "@modules/workflows/components/fields/WorkflowUserPicker";

export const SendEmailActivityEditor = observer(({ formData, formOptions }) => {
    return (
        <div>
            <r-grid columns="4">
                <r-cell span="4">
                    <TextField
                        label="Subject"
                        required
                        value={formData.settings.subject || ""}
                        onChange={(ev, val) => {
                            formData.settings.subject = val;
                        }}
                    />
                </r-cell>
                <r-cell span="4">
                    <TextField
                        label="Body"
                        required
                        multiline
                        style={{ minHeight: 150 }}
                        value={formData.settings.body || ""}
                        onChange={(ev, val) => {
                            formData.settings.body = val;
                        }}
                    />
                </r-cell>
                <r-cell span="4">
                    <WorkflowUserPicker
                        label="Recipients"
                        required
                        multiple
                        allowEmail
                        propertyModel={formOptions.propertyModel}
                        value={formData.settings.recipients}
                        onChange={(val) => {
                            formData.settings.recipients = val;
                        }}
                    />
                </r-cell>
            </r-grid>
        </div>
    );
});
