import React from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { Stack } from "@ui/elements/Stack";
import { FontIcon } from "@ui/elements/Icon";
import { Shimmer, ShimmerElementType } from "@ui/elements/Shimmer";

const rootClassName = cx(
    "cygraph-ActivityInstanceShimmerView-root",
    css`
        background: var(--white);
        border-radius: var(--border-radius);
        box-shadow: var(--box-shadow);
        border: 1px solid var(--border-color);
        padding: var(--spacing-s);
        margin-bottom: var(--spacing-xs);
        min-height: 100px;
    `
);

export const ActivityInstanceShimmerView = observer(({ className }) => {
    return (
        <Stack
            className={cx(rootClassName, className)}
            horizontal
            tokens={{ childrenGap: 35 }}
            verticalFill
            verticalAlign="center"
        >
            <Stack horizontal tokens={{ childrenGap: 25 }} verticalFill verticalAlign="center">
                <Shimmer shimmerElements={[{ type: ShimmerElementType.circle, height: 30 }]} />
                <FontIcon iconName="DoubleChevronRight8" style={{ fontSize: 13, color: "var(--grey-light)" }} />
                <Shimmer shimmerElements={[{ type: ShimmerElementType.circle, height: 30 }]} />
            </Stack>
            <Stack grow tokens={{ childrenGap: 5 }}>
                <Shimmer width="50%" shimmerElements={[{ type: ShimmerElementType.line, height: 18 }]} />
                <Shimmer width="40%" shimmerElements={[{ type: ShimmerElementType.line, height: 12 }]} />
                <Shimmer width="25%" shimmerElements={[{ type: ShimmerElementType.line, height: 12 }]} />
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 5 }} style={{ width: 230 }} verticalFill verticalAlign="center">
                <Shimmer shimmerElements={[{ type: ShimmerElementType.circle, height: 30 }]} />
                <Stack grow tokens={{ childrenGap: 5 }} verticalFill verticalAlign="center">
                    <Shimmer width="70%" shimmerElements={[{ type: ShimmerElementType.line, height: 14 }]} />
                    <Shimmer width="45%" shimmerElements={[{ type: ShimmerElementType.line, height: 12 }]} />
                </Stack>
            </Stack>
            <Stack.Item style={{ width: 150 }} verticalFill verticalAlign="center">
                <Shimmer width="60%" shimmerElements={[{ type: ShimmerElementType.line, height: 20 }]} />
            </Stack.Item>
        </Stack>
    );
});
