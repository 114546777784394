import { observable, action, computed } from "mobx";

import { WorkflowService } from "@api/workflows";
import { getEntityTypeFromModule } from "./WorkflowConstants";

import { WorkflowStore } from "./WorkflowStore";

interface ResponseHandle {
    resolve: (value: any) => void;
    reject: (value?: any) => void;
}

export class WorkflowEditFormStore {
    public parentStore: WorkflowStore;
    public workflowService: WorkflowService;
    private responseHandle?: ResponseHandle;

    @observable public visible: boolean = false;
    @observable public formData: any;
    @observable public formOptions: any;
    public registration: any;

    constructor(parentStore: WorkflowStore) {
        this.parentStore = parentStore;
        this.workflowService = parentStore.workflowService;
    }

    @action
    public show(options) {
        let propertyModel = options.context.type;
        if (options.context.type === "module") {
            propertyModel = getEntityTypeFromModule(options.context.id);
        }

        this.formOptions = { trigger: options.trigger, propertyModel };
        this.formData = { ...options.instance };

        this.registration = this.parentStore.registryStore.getRegistration(this.formData.definition);
        if (this.registration && this.registration.onInit) {
            this.registration.onInit(this.formData, true, this.formOptions, this.parentStore);
        }

        this.visible = true;

        return new Promise<any>((resolve, reject) => {
            this.responseHandle = { resolve, reject };
        });
    }

    @action
    public hide(_) {
        this.visible = false;
        this.formData = null;
        this.formOptions = null;
        this.responseHandle = null;
        this.registration = null;
    }

    @action
    public resolve(success) {
        let data = this.formData;
        if (this.registration && this.registration.onResolve) {
            data = this.registration.onResolve(this.formData, this.formOptions, this.parentStore);
        }
        this.responseHandle.resolve({
            success,
            formData: data,
            formOptions: this.formOptions,
        });
    }

    @action
    public reject(error) {
        this.responseHandle.reject(error);
    }

    @computed
    public get isValid() {
        if (!this.formData) {
            return false;
        }

        var ok =
            this.formData.id &&
            this.formData.name &&
            this.formData.definition &&
            this.formData.definition.uniqueId &&
            this.formData.order >= 0 &&
            this.formData.scope &&
            this.formData.scope.id &&
            this.formData.scope.type &&
            this.formData.trigger &&
            this.formData.trigger.event &&
            this.formData.trigger.group &&
            this.formData.settings;

        if (!ok) {
            return false;
        }

        if (!this.registration) {
            return false;
        }

        if (this.registration.validator) {
            return this.registration.validator(this.formData, this.formOptions, this.parentStore);
        }

        return true;
    }
}
