import React, { useState, useRef, useCallback, useMemo, useEffect } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";
import debounce from "lodash.debounce";

import { AdaptiveField } from "@modules/base/components/AdaptiveField";

import { Text } from "@ui/elements/Text";
import { DefaultButton, IconButton } from "@ui/elements/Button";
import { Stack } from "@ui/elements/Stack";
import { Spinner, SpinnerSize } from "@ui/elements/Spinner";
import { TooltipHost } from "@ui/elements/Tooltip";
import { Callout } from "@ui/elements/Callout";

const rootClassName = cx(
    "cygraph-FilterBar-root",
    css`
        display: flex;
        align-items: center;
    `
);

const calloutClassName = cx(
    "cygraph-FilterBar-callout",
    css`
        padding: var(--spacing-xs);
        width: 350px;
        min-width: 350px;

        & .ms-Callout-main {
            padding-bottom: var(--spacing-xxs);
        }
    `
);

export const FilterBar = observer(({ className, disabled, value, onChange, context, filters, debounceTime }) => {
    const buttonRef = useRef();
    const [visible, setVisible] = useState(false);
    const [activeCount, setActiveCount] = useState(0);

    const onOpenCallout = useCallback(
        (ev) => {
            if (!disabled) {
                setVisible(true);
            }
        },
        [disabled]
    );

    const debouncedOnChange = useMemo(() => debounce(onChange, debounceTime || 300), [onChange, debounceTime]);

    useEffect(() => {
        setActiveCount(
            filters.filter((f) => (f.multiple ? value[f.name] && value[f.name].length > 0 : !!value[f.name])).length
        );
    }, [filters, value]);

    const onClearAll = useCallback(
        (ev) => {
            filters.forEach((filter) => {
                value[filter.name] = undefined;
            });
            debouncedOnChange(value, filters);
        },
        [filters, value, debouncedOnChange]
    );

    const onDismiss = useCallback((ev) => {
        setVisible(false);
    }, []);

    const keywordsFilter = filters.find((f) => ["Search", "Text"].indexOf(f.type) !== -1 && f.keywords);
    return (
        <div className={cx(rootClassName, className)}>
            {keywordsFilter && (
                <AdaptiveField
                    type={"Search"}
                    mode="edit"
                    placeholder={keywordsFilter.placeholder}
                    styles={{ root: { height: "32px", minWidth: "270px", marginRight: 5 } }}
                    value={value[keywordsFilter.name]}
                    onChange={(val) => {
                        value[keywordsFilter.name] = val;
                        debouncedOnChange(value, keywordsFilter);
                    }}
                    label={keywordsFilter.label}
                    disabled={disabled || keywordsFilter.disabled}
                />
            )}
            <div ref={buttonRef} role="button" disabled={disabled}>
                <DefaultButton
                    disabled={disabled}
                    iconProps={{ iconName: activeCount ? "FilterSolid" : "Filter" }}
                    onClick={onOpenCallout}
                    text={activeCount ? `Filter / ${activeCount}` : `Filters`}
                />
            </div>
            {buttonRef.current && (
                <Callout
                    gapSpace={0}
                    hidden={!visible}
                    className={calloutClassName}
                    target={buttonRef.current}
                    onDismiss={onDismiss}
                >
                    <Stack tokens={{ childrenGap: 10 }}>
                        <Stack horizontal horizontalAlign="space-between">
                            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
                                <Text variant="large">Filters</Text>
                                {context && (
                                    <Text variant="large">
                                        {context.processing ? (
                                            <Spinner size={SpinnerSize.small} />
                                        ) : (
                                            <span>({context.resultCount || 0} results)</span>
                                        )}
                                    </Text>
                                )}
                            </Stack>
                            <TooltipHost content="Clear all filters">
                                <IconButton iconProps={{ iconName: "ClearFilter" }} onClick={onClearAll} />
                            </TooltipHost>
                        </Stack>
                        {visible &&
                            filters.map((filter) => (
                                <FilterBarField
                                    key={filter.name}
                                    filter={filter}
                                    value={value}
                                    disabled={disabled}
                                    onChange={debouncedOnChange}
                                />
                            ))}
                    </Stack>
                </Callout>
            )}
        </div>
    );
});

export const FilterBarField = observer(({ disabled, filter, value, onChange, context }) => {
    const { type, name, ...rest } = filter;
    return (
        <AdaptiveField
            type={type}
            mode="edit"
            valueConverter="simple"
            value={value[name]}
            onChange={(val) => {
                value[name] = val;
                onChange(value, filter);
            }}
            {...rest}
        />
    );
});
