import React from "react";
import { observer } from "mobx-react";

import { Label } from "@ui/elements/Label";
import { Text } from "@ui/elements/Text";

import { isNotNullOrEmpty } from "@modules/editors/components/RichTextField";
import RichTextViewer from "@modules/editors/components/RichTextViewer";
import { AdaptiveField } from "@modules/base/components/AdaptiveField";
import { RoutingLink } from "@base/components/RoutingLink";

export const RiskDetailsSection = observer(({ risk }) => {
    return (
        <div>
            <r-grid columns-l="2" columns="1" class="no--margin">
                <r-cell span-l="2" span="1">
                    <div className="row--s">
                        <Label>Risk Summary</Label>
                        {isNotNullOrEmpty(risk.description) ? (
                            <RichTextViewer className="description" value={risk.description} />
                        ) : (
                            <span>No risk summary set</span>
                        )}
                    </div>
                </r-cell>
                <r-cell span-l="2" span="1">
                    <div className="row--s">
                        <Label>Risk Background</Label>
                        {isNotNullOrEmpty(risk.context) ? (
                            <RichTextViewer className="description" value={risk.context} />
                        ) : (
                            <span>No risk background set</span>
                        )}
                    </div>
                </r-cell>
                <r-cell span-l="1" span="1">
                    <div className="row--xs">
                        <Label>Portfolio</Label>
                        <Text block nowrap>
                            {risk.portfolio && risk.portfolio.name}
                        </Text>
                    </div>
                </r-cell>
                <r-cell span-l="1" span="1">
                    <div className="row--xs">
                        <Label>{risk.target.type}</Label>
                        <RoutingLink url={`/assets/browse/${risk.target.id}`}>{risk.target.name}</RoutingLink>
                    </div>
                </r-cell>
                <r-cell span-l="1" span="1">
                    <div className="row--xs">
                        <Label>Registry</Label>
                        {risk.registry.name}
                    </div>
                </r-cell>
                <r-cell span-l="1" span="1">
                    <div className="row--xs">
                        <Label>Trigger</Label>
                        <Text block nowrap>
                            {risk.trigger || "Not set"}
                        </Text>
                    </div>
                </r-cell>
                <r-cell span-l="1" span="1">
                    <div className="row--xs">
                        <AdaptiveField type="Principal" mode="view" label="Requester" value={risk.requestedBy} />
                    </div>
                </r-cell>
                {risk.assessment && (
                    <r-cell span-l="1" span="1">
                        <div className="row--xs">
                            <Label>Related Assessment</Label>
                            <RoutingLink url={`/assurance/browse/${risk.assessment.id}`}>
                                {risk.assessment.code}
                            </RoutingLink>
                        </div>
                    </r-cell>
                )}
            </r-grid>
        </div>
    );
});
