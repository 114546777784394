import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Nav } from 'office-ui-fabric-react/lib/Nav';

export const PageHierarchyNav = observer(
    class PageHierarchyNav extends Component {

        render () {
          const { sitemap, selectedKey } = this.props;

          return (
            <Nav
              onLinkClick={this._onLinkClick}
              ariaLabel="Site Map"
              selectedKey={selectedKey}
              groups={sitemap}
            />
          );
        }

        _onLinkClick = (ev, item) => {
          const { onItemClick } = this.props;
          ev.preventDefault();
          if(item && onItemClick) {
            onItemClick(item);
          }
        };
    }
);