import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";

import { PanelType } from "office-ui-fabric-react/lib/Panel";
import { PrimaryButton, ActionButton } from "office-ui-fabric-react/lib/Button";

import { ActionPanel } from "../../../base/components/ActionPanel";
import { ObservablePanel } from "../../../base/components/ObservablePanel";
import { AdvisoryEditForm } from "../../components/advisories/AdvisoryEditForm";

export const AdvisoryEditPanel = inject(
    "patternStore",
    "routing"
)(
    withRouter(
        observer(
            class AdvisoryEditPanel extends Component {
                render() {
                    const { visible, isValid, title, advisory, measure, onApply, onCancel } = this.props;

                    return (
                        <ObservablePanel
                            hidden={!visible}
                            pageTitle={title || (advisory && advisory.id && advisory.title) || "New Advisory"}
                            pageType="Advisory"
                            panelType={PanelType.medium}
                            onClosePanel={() => onCancel()}
                            onRenderFooter={() => (
                                <ActionPanel>
                                    <ActionButton styles={{ root: { height: "32px" } }} onClick={() => onCancel()} text="Cancel" />
                                    <PrimaryButton onClick={() => onApply()} text="Apply" disabled={!isValid} />
                                </ActionPanel>
                            )}
                        >
                            {advisory && <AdvisoryEditForm isValid={isValid} formData={advisory} measure={measure} />}
                        </ObservablePanel>
                    );
                }
            }
        )
    )
);
