import React from 'react';
import { Modal as FabricModal } from 'office-ui-fabric-react/lib/Modal';

const Modal = (props) => {

    return (
        <FabricModal 
            {...props}
        />
    );
}

export { Modal }