import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { cx, css } from 'emotion';

import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';

import { PrincipalDropDown } from '../../../identity/containers/PrincipalDropDown';

const dropdownStyles = { dropdown: { width: 270 } };

export const WorkItemAssignForm = inject('workItemStore')(observer(
    class WorkItemAssignForm extends Component {

        constructor(props) {
            super(props)
            this.state = {
                groups: [],
                mappedGroups: [],
                isFirstLoad: true,
            }    
            this.onGroupChange = this.onGroupChange.bind(this);
            this.getGroups();
        }

        async getGroups() {
            const { workItemStore } = this.props;
            await  workItemStore.reAssignFormStore.loadGroups()
            const groups = workItemStore.reAssignFormStore.groups;
            const mappedGroups = groups.map(G => {
                return {
                    key: G.id,
                    text: G.name,
                }
            })
            this.setState({
                groups: groups,
                mappedGroups: mappedGroups,
            })
        }

        onGroupChange(event, item) {
            const { onGroupSelected, onContactSelected } = this.props;
            const { groups, isFirstLoad } = this.state;
            const selectedGroup = groups.find(G => G.id === item.key);
            if (selectedGroup) {
                onGroupSelected(selectedGroup);
                if (isFirstLoad) {
                    this.setState({
                        isFirstLoad: false
                    })
                } else {
                    onContactSelected(null);
                }
            }
        }
        render () {
            const { assignedContact, onContactSelected, assignedGroup } = this.props;
            return (
                <Stack vertical>
                    <Stack horizontal tokens={{childrenGap: 40, padding: 10}} >
                        <Stack.Item align="auto" grow={12}>
                            <Dropdown
                                label="Group"
                                selectedKey={assignedGroup ? (assignedGroup.key || assignedGroup.id) : undefined}
                                onChange={this.onGroupChange}
                                placeholder="Select a group"
                                options={this.state.mappedGroups}
                                styles={dropdownStyles}
                                />
                        </Stack.Item>
                    </Stack>
                    <Stack horizontal tokens={{childrenGap: 40, padding: 10}} >
                        <Stack.Item align="auto" grow={12}>
                            <PrincipalDropDown 
                                label="Contact"
                                required
                                selectedGroup={assignedGroup}
                                selected={assignedContact}
                                onSelected={onContactSelected}
                                styles={dropdownStyles}
                            />
                        </Stack.Item>
                    </Stack>
                </Stack>
            );
        }
    }
));