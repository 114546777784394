import React from "react";
import { observer } from "mobx-react";

import { Stack } from "@ui/elements/Stack";
import { PersonaSize, PersonaPresence } from "@ui/elements/Persona";

import { PhotoPersona } from "@modules/identity/containers/utilities/PhotoPersona";
import RichTextViewer from "@modules/editors/components/RichTextViewer";

export const ActivityStreamListItem = observer(({ message, onRenderAttachment, self, attached, timestamp }) => {
    return (
        <Stack className="activity--container" horizontal>
            {!self && (
                <Stack.Item className="activity--gutter">
                    {!attached && (
                        <div className="activity--avatar">
                            <PhotoPersona
                                principalId={message.actor.id}
                                size={PersonaSize.size28}
                                presence={PersonaPresence.none}
                                hidePersonaDetails={true}
                                text={message.actor.name}
                                imageAlt={message.actor.name}
                            />
                        </div>
                    )}
                </Stack.Item>
            )}
            <Stack.Item grow className="activity--message">
                {!attached && (
                    <div className="activity--header">
                        <span className="activity--author">{message.actor.name}</span>
                        <time
                            className="activity--timestamp"
                            dir="auto"
                            dateTime={timestamp.toISOString()}
                            title={timestamp.format("DD/MM/YY HH:mm")}
                        >
                            {timestamp.format("DD/MM/YY HH:mm")}
                        </time>
                    </div>
                )}
                <Stack className="activity--content" tokens={{ childrenGap: 5 }}>
                    <div className="activity--content-text">
                        {message.contentType === "text/json+rich-text" && <RichTextViewer value={message.content} />}
                        {message.contentType === "text/plain" && <span>{message.content}</span>}
                    </div>
                    {message.attachments &&
                        message.attachments.map((attachment, ai) => (
                            <div key={ai}>{onRenderAttachment(attachment, ai, message)}</div>
                        ))}
                </Stack>
            </Stack.Item>
        </Stack>
    );
});
