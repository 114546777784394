import React, { Component } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import * as moment from "moment";
import Moment from "react-moment";

import { TooltipHost, TooltipDelay, DirectionalHint } from "office-ui-fabric-react/lib/Tooltip";

const rootClassName = cx(
    "cygraph-DueIndicator-root",
    css`
        & .container {
            display: inline-block;
            width: 30px;
            text-align: center;
            border-radius: 2px;
            font-size: 10px;
            color: #fff;
            padding: 1px;

            &.old {
                background: var(--red-6);
            }

            &.medium {
                background: var(--orange-6);
            }

            &.young {
                background: var(--green-6);
            }

            &.completed {
                background: var(--grey-6);
            }
        }
    `
);

export const AgeIndicator = observer(
    class AgeIndicator extends Component {
        render() {
            const { className, hideTooltip, startDate, closedDate, creationDate } = this.props;

            if (!startDate) {
                return null;
            }

            const refDate = moment.utc(startDate);
            const momentClosedDate = closedDate
                ? moment.utc(closedDate).startOf("day")
                : moment.utc();
            const diffInDays = momentClosedDate.diff(refDate, "days");
            const diffInMonths = momentClosedDate.diff(refDate, "months");
            const precision = Math.abs(diffInMonths) > 0 ? "m" : "d";
            const level = diffInDays < 3 ? "young" : diffInDays < 7 ? "medium" : "old";

            const strings = this.props.strings || {
                started: "Started on",
                closed: "Closed on",
            };

            const tooltipProps = {
                onRenderContent: () => (
                    <div>
                        {startDate && (
                            <>
                                <span>
                                    {strings.started}{" "}
                                    <Moment utc date={startDate} format="DD/MM/YYYY" />
                                </span>{" "}
                                <br />
                            </>
                        )}
                        {closedDate && (
                            <span>
                                {" "}
                                {strings.closed}{" "}
                                <Moment utc date={closedDate} format="DD/MM/YYYY" />
                            </span>
                        )}
                    </div>
                ),
            };

            return (
                <div className={cx(rootClassName, className)}>
                    <TooltipHost
                        tooltipProps={tooltipProps}
                        calloutProps={{ hidden: !!hideTooltip }}
                        delay={TooltipDelay.medium}
                        directionalHint={DirectionalHint.bottomLeftEdge}
                    >
                        <div
                            className={cx("container", closedDate ? "completed" : level, precision)}
                        >
                            {precision === "d" ? diffInDays : diffInMonths}
                            {precision}
                        </div>
                    </TooltipHost>
                </div>
            );
        }
    }
);
