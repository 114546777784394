import React, { PureComponent } from "react";
import { observer } from "mobx-react";

import { ContextualMenu } from "office-ui-fabric-react/lib/ContextualMenu";

export const PageContextualMenu = observer(
    class PageContextualMenu extends PureComponent {
        render() {
            const { className, hidden, page, target } = this.props;
            const contextualMenuProps = this._getContextualMenuProps();

            return <ContextualMenu className={className} {...contextualMenuProps} />;
        }

        _getContextualMenuProps() {
            const { routing, hidden, page, onDismiss, target, canEditProperties, onEditProperties, canDuplicatePages, onDuplicatePage } = this.props;

            const contextualMenuProps = {
                hidden: hidden || !page || !target,
                target: target,
                items: [],
                onDismiss: onDismiss,
            };

            contextualMenuProps.items.push({
                key: "viewPage",
                text: "View Page",
                iconProps: { iconName: "PageArrowRight" },
                onClick: () => routing.push(page.path),
            });
            contextualMenuProps.items.push({
                key: "editPage",
                text: "Edit Page",
                iconProps: { iconName: "Edit" },
                onClick: () => routing.push(`${page.path}?mode=edit`),
            });
            contextualMenuProps.items.push({
                key: "duplicatePages",
                text: "Copy Page",
                iconProps: { iconName: "Copy" },
                disabled: !canDuplicatePages || !canDuplicatePages(),
                onClick: () => onDuplicatePage(page),
            });
            contextualMenuProps.items.push({
                key: "editProperties",
                text: "Edit Properties",
                iconProps: { iconName: "PageHeaderEdit" },
                disabled: !canEditProperties || !canEditProperties(),
                onClick: () => onEditProperties(page),
            });
            contextualMenuProps.items.push({
                key: "manageLibrary",
                text: "Manage Library",
                iconProps: { iconName: "DocLibrary" },
                onClick: () => routing.push(`/settings/page-libraries/${page.library.id}`),
            });

            return contextualMenuProps;
        }
    }
);
