//import { authProvider } from '../../authProvider';
import { MsalAuthProvider } from "react-aad-msal";

console.log(window.location.hostname);
const globalObject: any = window;

function getEnvironmentName(hostname) {
    if (globalObject.cygboot && globalObject.cygboot.bootstrap) {
        return globalObject.cygboot.environment;
    }
    switch (hostname) {
        case "cygraph-int-app.azurewebsites.net":
            return "Integration";
        case "demo1.zerodayapp.com":
            return "Integration";
        case "demo1.zerod.ai":
            return "Integration";
        case "mnscorp.zerod.ai":
            return "Integration";
        case "dominos.zerod.ai":
            return "Integration";
        case "metrobank.zerod.ai":
            return "Integration";
        case "cyb-eun-nprd-cygraph-app1.azurewebsites.net":
            return "Integration";
        case "cyb-eun-prd-cygraph-app1.azurewebsites.net":
            return "Production";
        case "app-cyg-uks-ih01.azurewebsites.net":
            return "Production";
        default:
            return "Development";
    }
}

function getApiEndpoint(hostname) {
    if (globalObject.cygboot && globalObject.cygboot.bootstrap) {
        return globalObject.cygboot.apiEndpoint;
    }
    switch (hostname) {
        case "cygraph-int-app.azurewebsites.net":
            return "https://cygraph-int-api.azurewebsites.net/v1.0";
        case "demo1.zerodayapp.com":
            return "https://demo1-api.zerodayapp.com/v1.0";
        case "mnscorp.zerod.ai":
            return "https://mnscorp-api.zerod.ai/v1.0";
        case "dominos.zerod.ai":
            return "https://dominos-api.zerod.ai/v1.0";
        case "metrobank.zerod.ai":
            return "https://metrobank-api.zerod.ai/v1.0";
        case "demo1.zerod.ai":
            return "https://demo1-api.zerod.ai/v1.0";
        case "cyb-eun-nprd-cygraph-app1.azurewebsites.net":
            return "https://cyb-eun-nprd-cygraph-api1.azurewebsites.net/v1.0";
        case "cyb-eun-prd-cygraph-app1.azurewebsites.net":
            return "https://cyb-eun-prd-cygraph-api1.azurewebsites.net/v1.0";
        case "app-cyg-uks-ih01.azurewebsites.net":
            return "https://app-cyg-uks-ih02.azurewebsites.net/v1.0";
        case "cygraph-app.eu.ngrok.io":
            return "https://cygraph-api.eu.ngrok.io/v1.0";
        default:
            return "https://localhost:44303/v1.0";
    }
}

export const Constants = {
    environment: getEnvironmentName(window.location.hostname),
    baseUrl: getApiEndpoint(window.location.hostname),
};

export class ServiceError extends Error {
    public type: string;
    public traceId: string;
    public status: number;
    public code: string;
    public error: any;

    constructor(params) {
        super(params.title);
        this.name = "ServiceError";
        this.type = params.type;
        this.traceId = params.traceId;
        this.status = params.status;
        this.code = params.code;
        this.error = params.error;
    }
}

export class ServiceBase {
    public authProvider: any;

    constructor(authProvider: any) {
        this.authProvider = authProvider;
    }

    private async handleErrors(response) {
        if (!response.ok) {
            const data = await response.json();
            const error = new ServiceError(data);
            throw error;
        }
        return response;
    }

    public async getBlob(url: string, options: any = null) {
        const token = await this.authProvider.getAccessToken();

        return await fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token.accessToken,
                "Access-Control-Allow-Origin": window.location.origin,
            },
            ...options,
        })
            .then(this.handleErrors)
            .then((r) => r.blob());
    }

    /**
     * Get
     */
    public async get(url: string) {
        const token = await this.authProvider.getAccessToken();

        return await fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token.accessToken,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": window.location.origin,
            },
        })
            .then(this.handleErrors)
            .then((r) => {
                if (r.status === 204) {
                    return null;
                }
                return r.json();
            });
    }

    public async postForm(url: string, data: FormData) {
        const token = await this.authProvider.getAccessToken();

        return await fetch(url, {
            method: "POST",
            body: data,
            headers: {
                Authorization: "Bearer " + token.accessToken,
                "Access-Control-Allow-Origin": window.location.origin,
            },
        })
            .then(this.handleErrors)
            .then((r) => r.json());
    }

    /**
     * Post
     */
    public async post(url: string, data: any) {
        const token = await this.authProvider.getAccessToken();

        return await fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                Authorization: "Bearer " + token.accessToken,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": window.location.origin,
            },
        })
            .then(this.handleErrors)
            .then((r) => r.json());
    }

    public async putForm(url: string, data: FormData) {
        const token = await this.authProvider.getAccessToken();

        return await fetch(url, {
            method: "PUT",
            body: data,
            headers: {
                Authorization: "Bearer " + token.accessToken,
                "Access-Control-Allow-Origin": window.location.origin,
            },
        })
            .then(this.handleErrors)
            .then((r) => r.json());
    }

    /**
     * Put
     */
    public async put(url: string, data: any) {
        const token = await this.authProvider.getAccessToken();

        return await fetch(url, {
            method: "PUT",
            body: JSON.stringify(data),
            headers: {
                Authorization: "Bearer " + token.accessToken,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": window.location.origin,
            },
        })
            .then(this.handleErrors)
            .then((r) => r.json());
    }

    /**
     * Delete
     */
    public async delete(url: string, data: any) {
        const token = await this.authProvider.getAccessToken();

        return await fetch(url, {
            method: "DELETE",
            body: JSON.stringify(data),
            headers: {
                Authorization: "Bearer " + token.accessToken,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": window.location.origin,
            },
        })
            .then(this.handleErrors)
            .then((r) => r.json());
    }
}
