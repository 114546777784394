import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { cx, css } from 'emotion';
import { withRouter } from 'react-router';

import { AppPage } from '../../../layout/containers/AppPage';
import { Chart } from '../../../charting/components/Chart';


export const InsightsPortfolios = inject('pageContext', 'insightsStore','routing')(withRouter(observer(
    class InsightsPortfolios extends Component {
        render () {
            const { insightsPortfolioPageStore } = this.props.insightsStore

            return (
                <AppPage title="Insights" hasSidebar={false} className="insights">
                    {/* Portfolio dashboard
                    <hr />
                    Upcoming activities
                    <Chart
                        data={insightsPortfolioPageStore.gaugeData}
                    />
                    <hr />
                    Infosec engagement 
                    <Chart 
                        data={insightsPortfolioPageStore.timeSeriesChartData}
                    />
                    <hr />
                    <Chart 
                        data={insightsPortfolioPageStore.pieData}
                    />
                    <hr /> */}
                </AppPage>
            );
        }
    }
)));