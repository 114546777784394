import { observable, action, computed, flow } from "mobx";

import { PaginationStore } from "../../base/PaginationStore";
import { PrincipalContextStore } from "../../identity/stores/PrincipalContextStore";
import { isUserAuthenticated, isUserInAnyRoles } from "../../identity/stores/PrincipalContextUtilities";

import { RiskService } from "../../../api/risks";
import { RiskStore } from "./RiskStore";
export class ExceptionDashboardStore {
    private parentStore: RiskStore;
    private riskService: RiskService;
    public principalContext: PrincipalContextStore;

    @observable public assessments: any[] = [];
    @observable public pagination: PaginationStore;
    @observable public loading: boolean = false;
    @observable public tab: string = "raised-by-me";

    @observable public tabs: any = {
        "raised-by-me": {
            title: "Raised by me",
            guest: true,
            templateId: "F4F60D5A-1CA6-4DB0-B12F-510C290F32E8",
        },
        "assigned-to-me": {
            title: "Assigned to me",
            roles: ["assessments", "risks"],
            templateId: "6B37793C-3FCA-420C-AEC7-0948691AF20A",
        },
        "assigned-to-my-team": {
            title: "Assigned to my team",
            roles: ["assessments", "risks"],
            templateId: "A13D4309-E222-426C-8C94-C5296741C29A",
        },
        "im-a-stakeholder": {
            title: "I'm a stakeholder",
            guest: true,
            templateId: "DC133E53-214D-4141-B278-27C9253CC4A6",
        },
    };

    @observable public error: any;
    @observable public breadcrumb: any[] = [
        {
            text: "Exception Management",
            key: "exceptions",
            onClick: () => this._goToUrl(`/exceptions/dashboard`),
        },
        { text: "Exception Dashboard", key: "dashboard", isCurrentItem: true },
    ];

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;

        this.principalContext = parentStore.rootStore.principalContext;
        this.pagination = new PaginationStore();
        this.pagination.itemsPerPage = 10;
    }

    @computed
    public get myTabs() {
        const user = this.principalContext.current;
        if (!isUserAuthenticated(user)) {
            return {};
        }

        return Object.entries(this.tabs).reduce((filtered, pair: any) => {
            const [key, value] = pair;
            if (value.guest || isUserInAnyRoles(user, value.roles)) {
                filtered[key] = value;
            }
            return filtered;
        }, {});
    }

    public loadDashboard = flow(function* (query: any) {
        this.loading = true;
        this.risks = [];

        const options = Object.assign({ page: 1, keywords: null }, query);

        try {
            const result = yield this.riskService.searchRisks({
                pageSize: this.pagination.itemsPerPage,
                startIndex: (options.page - 1) * this.pagination.itemsPerPage,
                query: {
                    templateId: this.tabs[this.tab].templateId,
                    ...{ type: { template: ["zerodai:risks:exception"] } },
                },
            });
            this.risks = result.items;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
        } catch (e) {
            console.error(e);
            this.error = e;
        } finally {
            this.loading = false;
        }
    });

    @action
    public selectTab(tab: string) {
        if (this.tab !== tab) {
            this.tab = tab;
            this.loadDashboard({ tab: tab });
        }
    }

    _goToUrl = (url) => {
        if (url) {
            this.parentStore.rootStore.routing.push(url);
        }
    };
}
