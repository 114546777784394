import { ServiceBase, Constants } from "../graph/serviceBase";

export class ModellingService extends ServiceBase {
    public async getPatterns(options) {
        return this.get(
            `${Constants.baseUrl}/patterns?pageSize=${options.pageSize}&startIndex=${options.startIndex}&keywords=${
                options.keywords || ""
            }`
        );
    }

    public async searchPatterns(options) {
        return this.post(`${Constants.baseUrl}/patterns/search`, options);
    }

    public async createPattern(pattern) {
        return this.post(`${Constants.baseUrl}/patterns`, pattern);
    }

    public async updatePattern(pattern) {
        return this.put(`${Constants.baseUrl}/patterns/${pattern.entity.id}`, pattern);
    }

    public async getPattern(id) {
        return this.get(`${Constants.baseUrl}/patterns/${id}`);
    }

    public async getPatternSummary(id, version) {
        return this.get(`${Constants.baseUrl}/patterns/${id}/summary?version=${version || ""}`);
    }

    public async getPatternManifest(id, version) {
        return this.get(`${Constants.baseUrl}/patterns/${id}/manifest?version=${version || ""}`);
    }

    public async getControls(options) {
        if (!options.nested) {
            return this.get(
                `${Constants.baseUrl}/controls?pageSize=${options.pageSize}&startIndex=${options.startIndex}&keywords=${
                    options.keywords || ""
                }`
            );
        }
        return this.get(
            `${Constants.baseUrl}/controls/nested?pageSize=${options.pageSize}&startIndex=${
                options.startIndex
            }&keywords=${options.keywords || ""}`
        );
    }

    public async getPatternVersions(options) {
        return this.get(
            `${Constants.baseUrl}/patterns/${options.id}/versions?pageSize=${options.pageSize}&startIndex=${
                options.startIndex
            }&keywords=${options.keywords || ""}`
        );
    }
}

export class ControlService extends ServiceBase {
    public async getControlMaps(options) {
        return this.get(
            `${Constants.baseUrl}/control-maps?pageSize=${options.pageSize}&startIndex=${options.startIndex}&keywords=${
                options.keywords || ""
            }&activeOnly=${options.activeOnly || ""}`
        );
    }

    public async getMapCategories(options: any = {}) {
        return this.get(
            `${Constants.baseUrl}/control-maps/categories?pageSize=${options.pageSize || 250}&startIndex=${
                options.startIndex || 0
            }&keywords=${options.keywords || ""}`
        );
    }

    public async getControlMap(mapId: string) {
        return this.get(`${Constants.baseUrl}/control-maps/${mapId}`);
    }

    public async createControlMap(options) {
        return this.post(`${Constants.baseUrl}/control-maps`, options);
    }

    public async updateControlMap(options) {
        return this.put(`${Constants.baseUrl}/control-maps/${options.entity.id}`, options);
    }

    public async getControlMapModel(mapId: string) {
        return this.get(`${Constants.baseUrl}/control-maps/${mapId}/model`);
    }

    public async getControlSets(options) {
        return this.get(
            `${Constants.baseUrl}/control-sets?pageSize=${options.pageSize}&startIndex=${options.startIndex}&keywords=${
                options.keywords || ""
            }&activeOnly=${options.activeOnly || ""}`
        );
    }

    public async getControlSet(setId: string) {
        return this.get(`${Constants.baseUrl}/control-sets/${setId}`);
    }

    public async getControlSetControls(options) {
        return this.get(
            `${Constants.baseUrl}/control-sets/${options.setId}/controls?pageSize=${options.pageSize}&startIndex=${
                options.startIndex
            }&keywords=${options.keywords || ""}&parentId=${options.parentId || ""}`
        );
    }

    public async getControls(options) {
        if (!options.nested) {
            return this.get(
                `${Constants.baseUrl}/controls?pageSize=${options.pageSize}&startIndex=${options.startIndex}&keywords=${
                    options.keywords || ""
                }&parentId=${options.parentId || ""}`
            );
        }

        return this.get(
            `${Constants.baseUrl}/controls/nested?pageSize=${options.pageSize}&startIndex=${
                options.startIndex
            }&keywords=${options.keywords || ""}&parentId=${options.parentId || ""}`
        );
    }

    public async searchControls(options) {
        return this.post(`${Constants.baseUrl}/controls/search`, options);
    }

    public async getControl(controlId: string) {
        return this.get(`${Constants.baseUrl}/controls/${controlId}`);
    }

    public async updatePortfolio(control) {
        return this.put(`${Constants.baseUrl}/controls/${control.id}`, control);
    }

    public async createPortfolio(control) {
        return this.post(`${Constants.baseUrl}/controls`, control);
    }
}
