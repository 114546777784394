import React from "react";

import { TableRow } from "./TableRow";
import { getDefaultBorderIncludes } from "./Utils";

export const TableBody = (props) => {
    const rowCells = React.Children.toArray(props.children);
    const { includeLeftBorder, includeBottomBorder, includeRightBorder, includeTopBorder } = getDefaultBorderIncludes(props);
    const dataRows = props.data ?? [];

    return dataRows.map((data, rowIndex) => (
        <TableRow
            {...props}
            key={rowIndex}
            data={data}
            includeLeftBorder={includeLeftBorder}
            includeBottomBorder={includeBottomBorder}
            includeRightBorder={includeRightBorder}
            includeTopBorder={includeTopBorder}
        >
            {rowCells}
        </TableRow>
    ));
};
