import { observable, action, computed, flow } from "mobx";

import { SystemStore } from "./SystemStore";

export class SystemBrowsePageStore {
    public parentStore: SystemStore;
    @observable public selectedTab: string = "Summary";

    @computed public get breadcrumb(): any[] {
        const { selectedSystem } = this.parentStore;
        const title = selectedSystem ? selectedSystem.name : "Loading...";
        return [
            { text: "Assets", key: "assets", onClick: () => this.parentStore.rootStore.routing.push(`/assets`) },
            { text: "Browse", key: "browse", as: "h4", isCurrentItem: true },
        ];
    }

    constructor(parentStore: SystemStore) {
        this.parentStore = parentStore;
    }
}
