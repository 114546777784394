import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { cx, css } from "emotion";
import { withRouter } from "react-router";
import { MessageBarButton, MessageBar, MessageBarType } from "office-ui-fabric-react";

import { AppPage } from "@modules/layout/containers/AppPage";
import { BoardCommandBar } from "@boards/components/BoardCommandBar";
import { WorkItemDetailsPanel } from "@workitems/components/tabs/relatedWorkItems/panels/WorkItemDetailsPanel";
import { CloseItemDialog } from "@workitems/components/dialogs/CloseItemDialog";
import { ResolveItemDialog } from "@workitems/components/dialogs/ResolveItemDialog";
import { AssignItemDialog } from "@workitems/components/dialogs/AssignItemDialog";
import { CancelItemDialog } from "@workitems/components/dialogs/CancelItemDialog";
import { ReOpenItemDialog } from "@workitems/components/dialogs/ReOpenItemDialog";
import { WorkItemNewPanel } from "@workitems/containers/panels/WorkItemNewPanel";
import { WorkItemEditPanel } from "@workitems/containers/panels/WorkItemEditPanel";
import { KanbanBoard } from "@baseComponents/kanbanboard/KanbanBoard";

import { WorkItemGridView } from "@workitems/components/core/WorkItemGridView";
import { WorkItemSideNav } from "@workitems/components/core/WorkItemSideNav";

export const BoardPage = inject(
    "pageContext",
    "productStore",
    "workItemStore",
    "boardsStore",
    "routing"
)(
    withRouter(
        observer(
            class BoardPage extends Component {
                constructor(props) {
                    super(props);
                    this.state = {
                        selectedWorkItem: null,
                        displayAsCards: true,
                        displayLeftSideBar: true,
                    };
                }

                componentWillMount() {
                    const { productStore, workItemStore, routing, match, boardsStore } = this.props;
                    productStore.loadProducts("", 1, 250);
                    const { newFormStore } = workItemStore;
                    if (match.params.boardId) {
                        this.loadBoardData(match.params.boardId);
                    }
                    if (match.params.productId) {
                        newFormStore.visible = true;
                        newFormStore.productId = match.params.productId;
                        newFormStore.formData.product.id = match.params.productId;
                    }
                }

                componentWillReceiveProps(nextProps) {
                    const { productStore, workItemStore, routing, boardsStore } = this.props;
                    const { match } = nextProps;
                    productStore.loadProducts("", 1, 250);
                    if (match.params.boardId) {
                        this.loadBoardData(match.params.boardId);
                    }
                    const { newFormStore } = workItemStore;
                    if (match.params.productId) {
                        newFormStore.visible = true;
                        newFormStore.productId = match.params.productId;
                        newFormStore.formData.product.id = match.params.productId;
                    }
                }

                loadBoardData = async (boardId) => {
                    const { boardsStore, routing, pageContext } = this.props;
                    const { selectedBoard } = boardsStore;
                    boardsStore.loadBoards({});
                    boardsStore.showWorkItemEditPanel = false;
                    pageContext.updateQueryStringParams(routing.location.search);
                    const query = {
                        page: pageContext.queryStringParams.p || 1,
                        keywords: pageContext.queryStringParams.k,
                    };
                    await boardsStore.selectBoard(boardId || selectedBoard.id, query);
                    this.setState({
                        displayAsCards: boardsStore.selectedBoard.display === "Cards" ? true : false,
                    });
                };

                confirmAssign = () => {
                    const { boardsStore, workItemStore } = this.props;
                    const { reAssignFormStore } = workItemStore;
                    boardsStore.confirmAssign(reAssignFormStore.selectedContact, reAssignFormStore.selectedGroup);
                };

                openEditWorkItemPanel = async (workItem) => {
                    const { productStore, workItemStore } = this.props;
                    const { editFormStore } = workItemStore;
                    await productStore.loadProductSchema(workItem.product.id);
                    await productStore.setSelectedWithId(workItem.product.id);
                    editFormStore.show(workItem, productStore.schema);
                };

                toggleSidebar = () => {
                    this.setState({
                        displayLeftSideBar: !this.state.displayLeftSideBar,
                    });
                };

                openAssignForm = (workItem) => {
                    const { boardsStore, workItemStore } = this.props;
                    const { reAssignFormStore } = workItemStore;
                    reAssignFormStore.selectedContact = workItem.assignedToUser;
                    reAssignFormStore.selectedGroup = workItem.assignedToGroup;
                    boardsStore.openAssignDialog(workItem);
                };

                render() {
                    const { boardsStore, productStore, workItemStore, routing, match } = this.props;
                    const { newFormStore, reAssignFormStore, editFormStore, lifecycleStore } = workItemStore;
                    const { selectedBoard, breadcrumb, error, isGuestUser } = boardsStore;
                    const { displayAsCards } = this.state;
                    return (
                        <AppPage
                            title="Demand"
                            onTitleClick={() => {
                                routing.push(`/demand/boards`);
                            }}
                            breadcrumb={breadcrumb}
                            loading={
                                boardsStore.isLoading ||
                                workItemStore.loading ||
                                productStore.loading ||
                                !boardsStore.selectedBoard
                            }
                            hasSidebar={false}
                            className={cx("work-items list", this.state.displayLeftSideBar && "has--page-nav")}
                            error={error}
                        >
                            <WorkItemSideNav
                                boards={boardsStore.boards}
                                isGuestUser={isGuestUser}
                                onLinkClick={(item) => routing.push(item.url)}
                                onNewRequest={() => routing.push(`/demand/catalogue`)}
                            />

                            <div>
                                {lifecycleStore.displayNewWorkItemSuccessMessage && (
                                    <MessageBar
                                        actions={
                                            <div>
                                                <MessageBarButton
                                                    onClick={() => {
                                                        boardsStore.showEditPanel(newFormStore.lastCreatedWorkItem);
                                                        editFormStore.visible = true;
                                                        lifecycleStore.hideNewWorkItemSuccessMessage();
                                                    }}
                                                >
                                                    Quick Edit
                                                </MessageBarButton>
                                                <MessageBarButton
                                                    onClick={() => {
                                                        routing.push(`/demand/${newFormStore.lastCreatedWorkItem.id}`);
                                                        lifecycleStore.hideNewWorkItemSuccessMessage();
                                                    }}
                                                >
                                                    Edit demand...
                                                </MessageBarButton>
                                            </div>
                                        }
                                        messageBarType={MessageBarType.success}
                                        isMultiline={false}
                                        onDismiss={() => lifecycleStore.hideNewWorkItemSuccessMessage()}
                                    >
                                        {" "}
                                        Request submitted correctly
                                    </MessageBar>
                                )}
                                <BoardCommandBar
                                    keywords={boardsStore.keywords}
                                    products={productStore.promotedProducts}
                                    onToggleKanban={() => {
                                        this.setState({ displayAsCards: !displayAsCards });
                                    }}
                                    isCard={displayAsCards}
                                    onNewRequest={(product) => {
                                        if (!product) {
                                            routing.push(`/demand/catalogue`);
                                        } else {
                                            // routing.push(`/boards/${selectedBoard.id}/new/${product.id}`)
                                            newFormStore.show(product.id);
                                            // newFormStore.visible = true;
                                            // newFormStore.productId = product.id;
                                            // newFormStore.formData.product.id = product.id;
                                        }
                                    }}
                                    onSearchChange={(keywords) => {
                                        if (keywords) {
                                            routing.push(
                                                `/demand/boards/${selectedBoard.id}?k=${encodeURIComponent(keywords)}`
                                            );
                                        } else {
                                            routing.push(`/demand/boards/${selectedBoard.id}`);
                                        }
                                    }}
                                />

                                {boardsStore.selectedKanbanBoard && displayAsCards && (
                                    <>
                                        <KanbanBoard
                                            data={boardsStore.selectedKanbanBoard}
                                            isLoading={boardsStore.isLoading}
                                            onDragEnd={(result) => boardsStore.handleDrag(result)}
                                            onCardClickDetail={(workItem) => boardsStore.showDetailPanel(workItem)}
                                            onAssignClick={(workItem) => this.openAssignForm(workItem)}
                                            onEditClick={(workItem) => {
                                                boardsStore.showEditPanel(workItem);
                                                editFormStore.visible = true;
                                            }}
                                        />
                                    </>
                                )}
                                {!displayAsCards && (
                                    <>
                                        <WorkItemGridView
                                            workItems={selectedBoard.workItems}
                                            loading={false}
                                            query={{}}
                                            onProductClick={(workItem) =>
                                                routing.push(`/demand/catalogue/${workItem.id}`)
                                            }
                                            onWorkItemClick={(workItem) => routing.push(`/demand/${workItem.id}`)}
                                        />
                                    </>
                                )}
                                {boardsStore.showWorkItemDetailsPanel && (
                                    <WorkItemDetailsPanel
                                        isOpen={boardsStore.showWorkItemDetailsPanel}
                                        onDismissPanel={() => boardsStore.hideItemDetailsPanel()}
                                        workItem={boardsStore.selectedWorkItem}
                                        hideLinkType={true}
                                        title={`Work item`}
                                    />
                                )}
                                {boardsStore.showWorkItemEditPanel && (
                                    <WorkItemEditPanel
                                        onAfterSave={this.loadBoardData}
                                        onHidePanel={() => {
                                            boardsStore.showWorkItemEditPanel = false;
                                            editFormStore.visible = false;
                                        }}
                                        workItem={boardsStore.selectedWorkItem}
                                    />
                                )}
                                {boardsStore.showCloseDialog && (
                                    <CloseItemDialog
                                        onCancel={() => boardsStore.cancelMovingCard()}
                                        onConfirm={(message) => boardsStore.confirmStatusChange(message, "close")}
                                    />
                                )}
                                {boardsStore.showCancelDialog && (
                                    <CancelItemDialog
                                        onCancel={() => boardsStore.cancelMovingCard()}
                                        onConfirm={(message) => boardsStore.confirmStatusChange(message, "cancel")}
                                    />
                                )}
                                {boardsStore.showReOpenDialog && (
                                    <ReOpenItemDialog
                                        onCancel={() => boardsStore.cancelMovingCard()}
                                        onConfirm={(message) => boardsStore.confirmStatusChange(message, "reopen")}
                                    />
                                )}
                                {boardsStore.showResolveDialog && (
                                    <ResolveItemDialog
                                        onCancel={() => boardsStore.cancelMovingCard()}
                                        onConfirm={(message) => boardsStore.confirmStatusChange(message, "resolve")}
                                    />
                                )}
                                {boardsStore.showAssignDialog && (
                                    <AssignItemDialog
                                        onCancel={() => boardsStore.cancelMovingCard()}
                                        onConfirm={this.confirmAssign}
                                        selectedContact={reAssignFormStore.selectedContact}
                                        selectedGroup={reAssignFormStore.selectedGroup}
                                        onContactSelected={(principal) =>
                                            (reAssignFormStore.selectedContact = principal)
                                        }
                                        onGroupSelected={(principal) => reAssignFormStore.selectGroup(principal)}
                                    />
                                )}
                                {newFormStore.visible && (
                                    <WorkItemNewPanel
                                        onSavePanel={() => {
                                            routing.push(`/demand/boards/${selectedBoard.id}`);
                                        }}
                                        onHidePanel={() => {}}
                                    />
                                )}
                            </div>
                        </AppPage>
                    );
                }
            }
        )
    )
);
