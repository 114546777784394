import React from "react";
import { observer } from "mobx-react";

import { PivotRouting, PivotRoutingItem } from "@modules/base/components/PivotRouting";

export const ControlsSettingsPivot = observer(({ activeTabs, selectedTab, defaultTab }) => {
    return (
        <PivotRouting
            data-test="controlsSettingsTabList"
            aria-label="Controls settings, frameworks and automations"
            selectedKey={activeTabs.indexOf(selectedTab) === -1 ? defaultTab : selectedTab}
            onLinkClick={(item, routing) => {
                if (item.props.itemKey === defaultTab) {
                    routing.push(`/settings/controls`);
                } else {
                    routing.push(`/settings/controls/${item.props.itemKey}`);
                }
            }}
        >
            <PivotRoutingItem itemKey={defaultTab} headerText="General" itemIcon="Settings" />
            <PivotRoutingItem itemKey="frameworks" headerText="Frameworks" itemIcon="ProductCatalog" />
            <PivotRoutingItem itemKey="maps" headerText="Maps" itemIcon="Communications" />
            <PivotRoutingItem itemKey="automations" headerText="Automations" itemIcon="ChatBot" />
            <PivotRoutingItem itemKey="recycle-bin" headerText="Recycle Bin" itemIcon="RecycleBin" itemCount={0} />
        </PivotRouting>
    );
});
