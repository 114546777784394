import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { useStores } from "@hooks/useStores";
import * as microsoftTeams from "@microsoft/teams-js";

import { JsonQueryString } from "@modules/utils/JsonQueryString";
import { ObservablePagination } from "@modules/base/components/ObservablePagination";

import { PageInstanceViewer } from "@modules/pages/containers/utilities/PageInstanceViewer";

import { AssessmentGridView } from "@modules/assessments/components/core/AssessmentGridView";
import { AssessmentCommandBar } from "@modules/assessments/components/core/AssessmentCommandBar";

export const AssessmentBrowsePage = withRouter(
    observer(({ match }) => {
        const { assessmentStore, pageContext, routing } = useStores();
        const { browseStore, lifecycleStore } = assessmentStore;
        const { breadcrumb, error, isGuestUser, assessments, query, loading, pagination } = browseStore;

        useEffect(() => {
            pageContext.updateQueryStringParams(routing.location.search);
            browseStore.loadAssessments({
                page: pageContext.queryStringParams.p || 1,
                query: JsonQueryString.decode(pageContext.queryStringParams.query),
            });
        }, [match, routing, pageContext]);

        return (
            <>
                <PageInstanceViewer path="/_/teams/assurance/browse" optional={true} />

                <AssessmentCommandBar
                    loading={loading}
                    isGuestUser={isGuestUser}
                    filterQuery={query}
                    filterContext={{
                        processing: loading,
                        resultCount: pagination.totalItemCount,
                    }}
                    onFilterChange={(q, f) => {
                        routing.push(JsonQueryString.build("/teams/assurance/browse", q));
                    }}
                    onNewTriage={() => {
                        microsoftTeams.tasks.startTask(
                            {
                                url: `${window.location.origin}/teams/assurance/triage`,
                                fallbackUrl: `${window.location.origin}/assurance/triage`,
                                width: "large",
                                height: "large",
                                title: `Security Assurance Triage`,
                            },
                            (err, result) => {
                                if (!err) {
                                    routing.push(`/teams/assurance/browse/${result.data.assessment.id}`);
                                }
                            }
                        );
                    }}
                />

                <AssessmentGridView
                    assessments={assessments}
                    loading={loading}
                    query={query}
                    onAssessmentClick={(assessment) => routing.push(`/teams/assurance/browse/${assessment.id}`)}
                />

                <ObservablePagination
                    {...pagination}
                    onPageChange={(index) => {
                        routing.push(JsonQueryString.build("/teams/assurance/browse", query, index + 1));
                    }}
                />
            </>
        );
    })
);
