import { observable, flow } from "mobx";

import { PaginationStore } from "../../base/PaginationStore";
import { RiskService } from "../../../api/risks";
import { RiskStore } from "./RiskStore";

export class RiskBrowseStore {
    public parentStore: RiskStore;
    public riskService: RiskService;

    @observable public risks: any[] = [];
    @observable public pagination: PaginationStore;
    @observable public query: any = {};
    @observable public loading: boolean = false;
    @observable public error: any;
    @observable public breadcrumb: any[] = [
        {
            text: "Risk Management",
            key: "risks",
            onClick: () => this._goToUrl(`/risks/dashboard`),
        },
        { text: "Browse Risks", key: "browse", isCurrentItem: true },
    ];

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
        this.pagination = new PaginationStore();
    }

    public loadRisks = flow(function* (query: any) {
        this.loading = true;
        const options = Object.assign({ page: 1, pageSize: 25, keywords: null, query: {} }, query);

        this.query = {
            ...options.query,
        };

        try {
            const result = yield this.riskService.searchRisks({
                pageSize: options.pageSize,
                startIndex: (options.page - 1) * options.pageSize,
                keywords: options.keywords,
                query: {
                    ...this.query,
                    ...{ type: { template: ["zerodai:risks:generic"] } },
                },
            });
            this.risks = result.items;
            this.query = result.query;
            this.keywords = result.query.keywords;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);

            return result;
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.loading = false;
        }
    });

    public exportRisks = flow(function* (query) {
        try {
            const options = Object.assign({ page: 1, keywords: null }, query);
            const result = yield this.riskService.exportRisks({
                pageSize: 100,
                startIndex: 0,
                query: {
                    ...this.query,
                    ...options.query,
                    ...{ type: { template: ["zerodai:risks:generic"] } },
                },
            });

            if (result) {
                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Risk export request has been queued. You will receive an email shortly when export is ready.`
                );
            }
        } catch (error) {
            console.error(error);
            this.error = error;
        }
    });

    _goToUrl = (url) => {
        if (url) {
            this.parentStore.rootStore.routing.push(url);
        }
    };
}
