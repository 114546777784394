import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';

import { NormalPeoplePicker, ValidationState } from 'office-ui-fabric-react/lib/Pickers';
import { PersonaPresence, PersonaSize, Persona } from 'office-ui-fabric-react/lib/Persona';

export const SystemViewer = observer(
    class SystemViewer extends Component {
        
        render () {
            const { system, className } = this.props;

            return (
                    <div className={cx(
                        'cygraph-SystemPicker-container',
                        className,
                        css`  
                            & .ms-Persona {
                                height: 20px;
                            }
                            & .ms-Persona-coin {
                                width: 20px;
                            }
                            & .ms-Persona-initials {
                                display: none;
                            }
                            & .ms-Persona-presence {
                                top: 16px;
                                right: 18px;
                            }
                            & .ms-Persona--online {
                                height: 20px;
                            }
                        `
                    )}>
                    {system && <Persona
                        {...this._getPersonaFromSystem(system)}
                    />
                    }
                </div>
            );
        }

        _getPersonaFromSystem(system) {
            return { 
                key: system.id, 
                text: system.name, 
                //secondaryText: system.alias, 
                presenceTitle: `Status - ${system.state}`,
                //size: PersonaSize.size32,
                presence: this._getPresenceFromState(system.state),
                data: system 
            };
        }

        _getPresenceFromState(state) {
            switch(state) {
                case 'Conceptual': return PersonaPresence.offline;
                case 'Developing': return PersonaPresence.away;
                case 'Testing': return PersonaPresence.away;
                case 'Live': return PersonaPresence.online;
                case 'Decommissioning': return PersonaPresence.blocked;
                case 'Decommissioned': return PersonaPresence.busy;
                default: return PersonaPresence.offline;
            }
        }

    }
);