import React from "react";
import { observer } from "mobx-react";

import { Badge } from "@ui/elements/Badge";
import { MessageBar } from "@ui/elements/MessageBar";
import { PersonaSize } from "@ui/elements/Persona";
import { DetailsListLayoutMode, SelectionMode, ConstrainMode } from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { RoutingLink } from "@modules/base/components/RoutingLink";
import { WrappedContent } from "@modules/base/components/WrappedContent";
import { PhotoPersona } from "@modules/identity/containers/utilities/PhotoPersona";

export const IdentityUserGridView = observer(({ loading, users, onUserClick }) => {
    const columns = [
        {
            key: "name",
            name: "Name",
            fieldName: "name",
            minWidth: 210,
            maxWidth: 250,
            data: "string",
            onRender: (item) => {
                return (
                    <WrappedContent>
                        <RoutingLink
                            url={`/settings/identity/users/${item.id}`}
                            onClick={() => onUserClick && onUserClick(item)}
                            onClickEnabled={onUserClick}
                        >
                            <PhotoPersona
                                size={PersonaSize.size28}
                                principalId={item.id}
                                text={item.name}
                                showSecondaryText={true}
                                secondaryText={item.userPrincipalName}
                            />
                        </RoutingLink>
                    </WrappedContent>
                );
            },
            isPadded: true,
        },
        {
            key: "jobTitle",
            name: "Job Title",
            fieldName: "jobTitle",
            minWidth: 140,
            maxWidth: 180,
            data: "string",
            onRender: (item) => {
                return <WrappedContent>{item.jobTitle || "-"}</WrappedContent>;
            },
        },
        {
            key: "email",
            name: "Email",
            fieldName: "email",
            minWidth: 210,
            maxWidth: 250,
            data: "string",
            onRender: (item) => {
                return <WrappedContent>{item.email || "-"}</WrappedContent>;
            },
        },
        {
            key: "roles",
            name: "Roles",
            fieldName: "roles",
            minWidth: 120,
            data: "string",
            onRender: (item) => {
                return (
                    <WrappedContent>
                        {item.roles.map((role, i) => (
                            <Badge variant={role == "admin" ? "red" : role == "agent" ? "blue" : "none"} key={i}>
                                {role}
                            </Badge>
                        ))}
                    </WrappedContent>
                );
            },
        },
    ];

    return (
        <BoxPanel shadow>
            <ShimmeredDetailsList
                items={users}
                compact={false}
                columns={columns}
                selectionMode={SelectionMode.none}
                getKey={(item, index) => (item ? item.id : index)}
                setKey="single"
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.horizontalConstrained}
                onItemInvoked={onUserClick}
                isHeaderVisible={true}
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="Row checkbox"
                enableShimmer={loading}
                removeFadingOverlay={true}
                shimmerLines={25}
                ariaLabelForShimmer="Users being fetched"
                ariaLabelForGrid="User list"
            />
            {!users.length === 0 && !loading && <MessageBar>There are no matching users.</MessageBar>}
        </BoxPanel>
    );
});
