import { computed } from "mobx";

import { PrincipalContextStore } from "../../identity/stores/PrincipalContextStore";
import {
    RoleNames,
    isUserInAllRoles,
    isUserInAnyRoles,
} from "../../identity/stores/PrincipalContextUtilities";

import { AssetService } from "../../../api/assets";
import { AssetStore } from "./AssetStore";
import { AssetSelectionStore } from "./AssetSelectionStore";

export class AssetPermissionStore {
    public parentStore: AssetStore;
    public assetService: AssetService;
    public selectionStore: AssetSelectionStore;
    public principalContext: PrincipalContextStore;

    constructor(parentStore: AssetStore) {
        this.parentStore = parentStore;
        this.assetService = parentStore.assetService;
        this.selectionStore = parentStore.selectionStore;
        this.principalContext = parentStore.rootStore.principalContext;
    }

    @computed
    public get isAgent() {
        return isUserInAnyRoles(this.principalContext.current, [RoleNames.Admin, RoleNames.Agent]);
    }

    @computed
    public get canEdit() {
        return (
            isUserInAllRoles(this.principalContext.current, [RoleNames.Agent, RoleNames.Assets]) ||
            isUserInAllRoles(this.principalContext.current, [RoleNames.Admin])
        );
    }
}
