import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';

import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction'

import { Text } from 'office-ui-fabric-react/lib/Text';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { MessageBar } from 'office-ui-fabric-react/lib/MessageBar';
import { Persona, PersonaSize } from 'office-ui-fabric-react/lib/Persona';

import { BoxPanel } from '../../../base/components/BoxPanel';

const rootClassName = cx(
  'cygraph-WorkItemTimelineView-root',
  css`
    & .product {
      padding-left 5px;
      display: inline-flex;
    }

    & .portfolio {
      margin-left: -13px;
      display: inline-block;
      min-height: 30px;
    }
  `
);

export const WorkItemTimelineView = observer(
    class WorkItemTimelineView extends Component {

      render () {
          const { className, events, resources, loading, view, date, onDatesSet, onEventClick } = this.props;
          
          return (
            <BoxPanel className={cx(rootClassName, className)} shadow padding>
              <FullCalendar
                schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                height={resources.length == 0 ? 300 : "auto"}
                plugins={[ resourceTimelinePlugin, interactionPlugin ]}
                initialView={`resourceTimeline${view || 'Month'}`}
                weekends={false}
                expandRows={true}
                initialDate={date}
                headerToolbar={{
                  left: 'prev,next today',
                  center: 'title',
                  right: 'resourceTimelineWeek,resourceTimelineMonth,resourceTimelineQuarter'
                }}
                views = {{resourceTimelineQuarter: {
                  type: 'resourceTimeline',
                  buttonText: '3 months',
                  duration:{ months: 3},
                  slotDuration: {days: 7},
                }
                }}
                eventClick={onEventClick}
                dateClick={(info) => {
                  const calendarApi = info.view.calendar;
                  calendarApi.changeView('resourceTimelineDay', info.date);
                }}
                datesSet={onDatesSet}
                events={events}
                resources={resources}
                resourceAreaWidth="25%"
                resourceGroupField="groupId"
                resourceOrder="userName,title"
                resourceGroupLabelContent={this.renderResourceGroup}
                resourceLabelContent={this.renderResource}
              />
              { ! loading && !events.length && (
                <MessageBar>
                  There are no matching requests.
                </MessageBar>
              )}

            </BoxPanel> 
          );
      }

      renderResourceGroup = (args) => {
        const { products } = this.props;
        const product = products.find(u => u.id === args.groupValue).product;

        return (
          <div className="product">
            {product.name}
          </div>
        );
      };

      renderResource = (args) => {
        return (
          <div className="portfolio">
            <Text variant="medium">Portfolio: {args.fieldValue}</Text>
          </div>
        );
      };
    }
);