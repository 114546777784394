import { observable, computed } from "mobx";

import { SystemStore } from "./SystemStore";

export class SystemViewPageStore {
    public parentStore: SystemStore;
    @observable public selectedTab: string = "Summary";

    @computed public get breadcrumb(): any[] {
        const { selectedSystem } = this.parentStore;
        const title = selectedSystem ? selectedSystem.name : "Loading...";
        return [
            { text: "Assets", key: "systems", onClick: () => this.parentStore.rootStore.routing.push(`/assets`) },
            { text: "Browse", key: "browse", onClick: () => this.parentStore.rootStore.routing.push(`/assets`) },
            { text: title, key: "systemTitle", as: "h4", isCurrentItem: true },
        ];
    }

    constructor(parentStore: SystemStore) {
        this.parentStore = parentStore;
    }
}
