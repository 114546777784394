import { observable, flow } from "mobx";

import { PaginationStore } from "../../base/PaginationStore";
import { RiskService } from "../../../api/risks";
import { RiskStore } from "./RiskStore";

export class RiskFilterDataStore {
    public parentStore: RiskStore;
    public riskService: RiskService;
    public riskLevels: any[] = [{}];

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
    }

    public loadSubStatuses = flow(function* (isException) {
        try {
            const template = isException ? "zerodai:risks:exception" : "zerodai:risks:generic";
            const subStatues = yield this.riskService.getSubStatuses(template);
            return subStatues
                .filter((S) => S !== null)
                .map((S) => {
                    return {
                        key: S,
                        text: S,
                    };
                });
        } catch (e) {
            console.error(e);
            this.error = e;
            return [];
        }
    });

    public loadPortfolios = flow(function* () {
        try {
            const retrievedPortfolios = yield this.parentStore.rootStore.portfolioStore.loadPortfolios("", 1, 250);
            return retrievedPortfolios.map((P) => {
                return {
                    key: P.id,
                    text: P.name,
                    data: P,
                };
            });
        } catch (e) {
            console.error(e);
            this.error = e;
            return [];
        }
    });
}
