import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { FieldSetView } from "@modules/base/components/FieldSetView";

import { WorkItemZone } from "@modules/workitems/components/core/WorkItemZone";

import { AssessmentSummary } from "@modules/assessments/components/core/AssessmentSummary";
import { AssessmentDetailsSection } from "@modules/assessments/components/core/AssessmentDetailsSection";
import { AssessmentWorkflowSection } from "@modules/assessments/components/core/AssessmentWorkflowSection";
import { AssessmentContactList } from "@modules/assessments/components/core/AssessmentContactList";

export const AssessmentSummaryTab = observer(({ assessment, workItem, hasRisks, hasExceptions }) => {
    const { assessmentStore, productStore } = useStores();
    const { schema } = productStore;
    const { modules } = assessmentStore.viewStore;

    useEffect(() => {
        if (workItem) {
            if (!schema || schema.productId != workItem.product.id) {
                productStore.loadProductSchema(workItem.product.id, workItem.schemaVersion);
            }
        }
    }, [schema, workItem]);

    return (
        <BoxPanel shadow padding style={{ minHeight: 500 }}>
            {assessment && assessment.summary && assessment.summary.compliantCount != null && (
                <FieldSetView title="Assessment Stats" description="Outcome of the assessment.">
                    <AssessmentSummary
                        horizontal
                        assessment={assessment}
                        modules={modules}
                        hasRisks={hasRisks}
                        hasExceptions={hasExceptions}
                    />
                </FieldSetView>
            )}
            <FieldSetView title="Key Information" description="Basic information about this assurance request.">
                <AssessmentDetailsSection workItem={workItem} />
            </FieldSetView>
            <FieldSetView title="Key Contacts" description="Key contacts for the assurance request.">
                <AssessmentContactList workItem={workItem} assessment={assessment} />
            </FieldSetView>
            <FieldSetView
                title="Workflow Progress"
                description="Information about the progress of the assurance request and assigned user."
            >
                <AssessmentWorkflowSection workItem={workItem} assessment={assessment} />
            </FieldSetView>
            {schema && schema.extensions && schema.extensions.length && workItem && (
                <FieldSetView title="Triage Info" description="Additional information captured during triage process.">
                    <div className="row--s">
                        <WorkItemZone zone="topRight" readOnly schema={schema} workItem={workItem} />
                        <WorkItemZone zone="topLeft" readOnly schema={schema} workItem={workItem} />
                        <WorkItemZone zone="middle" readOnly schema={schema} workItem={workItem} />
                        <WorkItemZone zone="bottomLeft" readOnly schema={schema} workItem={workItem} />
                        <WorkItemZone zone="bottomRight" readOnly schema={schema} workItem={workItem} />
                        <WorkItemZone zone="main" readOnly defaultZone schema={schema} workItem={workItem} />
                    </div>
                </FieldSetView>
            )}
        </BoxPanel>
    );
});
