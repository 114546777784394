import React from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";
import Moment from "react-moment";

import { getInitials } from "@uifabric/utilities/lib/initials";
import { MessageBar } from "office-ui-fabric-react/lib/MessageBar";
import { DetailsListLayoutMode, ConstrainMode, SelectionMode } from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { PersonaSize } from "office-ui-fabric-react/lib/Persona";
import { Stack } from "office-ui-fabric-react/lib/Stack";

import { WrappedContent } from "@modules/base/components/WrappedContent";
import { PhotoPersona } from "@modules/identity/containers/utilities/PhotoPersona";

const rootClassName = cx(
    css(`
        & .ms-List {
            background: var(--background-color);
            border-radius: 2px;
            padding: 5px 0;
        }
        &.empty {
            display: none;
        }
        & .ms-DetailsRow {
            background: var(--background-color);
        }
    `)
);

export const TaskAssignmentGridView = observer(({ assignments, loading }) => {
    const columns = [
        {
            key: "title",
            name: "Assigned To",
            minWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: "string",
            onRender: (item) => {
                return (
                    <Stack verticalFill verticalAlign="center">
                        <PhotoPersona
                            principalId={item.assignedTo.id}
                            size={PersonaSize.size28}
                            text={item.assignedTo.name}
                            imageInitials={getInitials(item.assignedTo.name)}
                            initialsColor={item.status === "Pending" ? 12 : 21}
                            showUnknownPersonaCoin={!item}
                        />
                    </Stack>
                );
            },
            isPadded: false,
        },
        {
            key: "status",
            name: "Status",
            minWidth: 130,
            maxWidth: 180,
            isResizable: false,
            data: "string",
            onRender: (item) => {
                return (
                    <Stack verticalFill verticalAlign="center">
                        <WrappedContent>{item.status}</WrappedContent>
                        {item.response && item.useCase !== "Completion" ? <WrappedContent>({item.response})</WrappedContent> : null}
                    </Stack>
                );
            },
            isPadded: true,
        },
        {
            key: "closed",
            name: "Closed",
            fieldName: "closed",
            minWidth: 100,
            maxWidth: 140,
            isResizable: false,
            data: "string",
            onRender: (item) => {
                return (
                    <Stack verticalFill verticalAlign="center">
                        <WrappedContent rightAlign>{item.closed && <Moment utc date={item.closed} format="Do MMM YYYY" />}</WrappedContent>
                    </Stack>
                );
            },
            isPadded: true,
        },
    ];

    return (
        <>
            <ShimmeredDetailsList
                className={cx(rootClassName, !loading && assignments.length === 0 && "empty")}
                items={assignments}
                compact={true}
                columns={columns}
                selectionMode={SelectionMode.none}
                getKey={(item, index) => (item ? item.id : index)}
                setKey="single"
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.horizontalConstrained}
                isHeaderVisible={false}
                enableShimmer={loading}
                shimmerLines={3}
                ariaLabelForShimmer="Loading task assignments"
                ariaLabelForGrid="Task assignments"
            />
            {!loading && assignments.length === 0 && <MessageBar>There are no assignments found.</MessageBar>}
        </>
    );
});
