import { observable, flow, computed, action } from "mobx";

import { validateTargetData } from "../../properties/stores/PropertyValidators";

import { EntityService } from "../../../api/entities";
import { EntityStore } from "./EntityStore";

export class EntityEditStore {
    public parentStore: EntityStore;
    public entityService: EntityService;

    @observable public visible: boolean = false;
    @observable public formData: any;
    @observable public originalFormData: any;
    @observable public formOptions: any;
    @observable public saving: boolean = false;
    @observable public isCloseDialogOpen: boolean = false;
    @observable public controls: any[];
    @observable public messages: any;

    constructor(parentStore: EntityStore) {
        this.parentStore = parentStore;
        this.entityService = parentStore.entityService;
    }

    @computed
    public get isValid() {
        if (!this.formOptions || !this.formOptions.properties) {
            return false;
        }
        const result = validateTargetData(this.formOptions.properties, this.formData);
        this.messages = result.messages;

        return this.formData && this.formData.id && result.isValid;
    }

    @computed
    public get isDirty() {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
    }

    @action
    public resetFormData() {
        this.formData = JSON.parse(JSON.stringify(this.originalFormData));
    }

    public show = flow(function* (options) {
        this.visible = true;
        const propertyService = this.parentStore.rootStore.propertyStore.propertyService;

        const properties = yield propertyService.searchProperties({
            pageSize: 250,
            startIndex: 0,
            query: { keywords: null, target: ["Entity"], readOnly: false, activeOnly: true },
        });

        const sections = yield propertyService.searchPropertySections({
            pageSize: 250,
            startIndex: 0,
            query: { keywords: null, target: ["Entity"], activeOnly: true },
        });

        this.formOptions = { properties: properties.items, sections: sections.items };
        this.formData = JSON.parse(JSON.stringify(options.entity));
        this.originalFormData = JSON.parse(JSON.stringify(options.entity));
    });

    @action
    public hide(options) {
        this.visible = false;
        this.formData = null;
        this.formOptions = null;
    }

    public updateEntity = flow(function* () {
        this.saving = true;
        this.error = null;

        try {
            if (this.isValid) {
                const result = yield this.entityService.updateEntity(this.formData);
                //              this.parentStore.mapBrowseStore.replace(result);
                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Entity ${result.name} updated successfully`
                );
                return result;
            }
        } catch (e) {
            console.error(e);
            this.error = e;
        } finally {
            this.saving = false;
        }
    });
}
