import React, { Component } from "react";
import { observer, inject } from "mobx-react";

import { JsonQueryString } from "@modules/utils/JsonQueryString";
import { AppPage } from "@modules/layout/containers/AppPage";
import { ObservablePagination } from "@modules/base/components/ObservablePagination";

import { AssetGridView } from "@modules/assets/components/core/AssetGridView";
import { AssetSideNav } from "@modules/assets/components/core/AssetSideNav";
import { AssetCommandBar } from "@modules/assets/components/core/AssetCommandBar";

import { AssetNewPanel } from "@modules/assets/containers/panels/AssetNewPanel";

const getQueryStringParams = (query) => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query).split("&").reduce((params, param) => {
              let [key, value] = param.split("=");
              params[key] = value ? decodeURIComponent(value.replace(/\+/g, " ")) : "";
              return params;
          }, {})
        : {};
};

export const AssetWatchlistPage = inject(
    "pageContext",
    "assetStore",
    "routing"
)(
    observer(
        class AssetWatchlistPage extends Component {
            componentWillMount() {
                const { assetStore, routing } = this.props;
                const { watchlistStore } = assetStore;

                const params = getQueryStringParams(routing.location.search);
                watchlistStore.loadAssets({
                    page: params["p"] || 1,
                    query: JsonQueryString.decode(params["query"]),
                });
            }

            componentWillReceiveProps(nextProps) {
                const { assetStore, routing } = nextProps;
                const { watchlistStore } = assetStore;

                const params = getQueryStringParams(routing.location.search);
                watchlistStore.loadAssets({
                    page: params["p"] || 1,
                    query: JsonQueryString.decode(params["query"]),
                });
            }

            render() {
                const { assetStore, routing } = this.props;
                const { lifecycleStore, watchlistStore } = assetStore;
                const { loading, error, breadcrumb, query, pagination, assets } = watchlistStore;

                return (
                    <AppPage
                        error={error}
                        breadcrumb={breadcrumb}
                        loading={loading}
                        hasSidebar={false}
                        className="assets list has--page-nav"
                    >
                        <AssetSideNav
                            query={query}
                            hideAgentActions={!lifecycleStore.showAgentActions}
                            onLinkClick={(item) => routing.push(item.url)}
                            onNewAsset={() => this._onNewAsset()}
                        />

                        <>
                            <AssetCommandBar
                                filterQuery={query}
                                filterContext={{
                                    processing: loading,
                                    resultCount: pagination.totalItemCount,
                                }}
                                onFilterChange={(q, f) => {
                                    routing.push(JsonQueryString.build("/assets/browse", q));
                                }}
                                hideAgentActions={!lifecycleStore.showAgentActions}
                                onNewAsset={() => this._onNewAsset()}
                            />

                            <AssetGridView
                                assets={assets}
                                loading={loading}
                                query={{}}
                                onAssetClick={(asset) => routing.push(`/assets/browse/${asset.id}`)}
                                onWatchlistChange={(item, add) => watchlistStore.toggleWatchlist(item, add)}
                            />

                            <ObservablePagination
                                {...pagination}
                                onPageChange={(index) => {
                                    routing.push(JsonQueryString.build("/assets/browse", query, index + 1));
                                }}
                            />
                        </>
                        <AssetNewPanel onAfterSave={(asset) => routing.push(`/assets/browse/${asset.id}`)} />
                    </AppPage>
                );
            }

            _onNewAsset() {
                const { newStore } = this.props.assetStore;
                newStore.show({});
            }
        }
    )
);
