import React from "react";
import { observer } from "mobx-react";

import { ObservableSeparator } from "@modules/base/components/ObservableSeparator";

import { Text } from "@ui/elements/Text";

export const FieldSetView = observer(({ title, description, children, columns }) => {
    return (
        <div className="cygraph-FieldSetView-root">
            <r-grid columns="12">
                <r-cell span="12">
                    <ObservableSeparator>{title}</ObservableSeparator>
                </r-cell>
                {(columns !== 1 || !!description) && (
                    <r-cell span={columns === 1 ? "12" : "5"}>
                        {typeof description === "string" ? <Text variant="small">{description}</Text> : description}
                    </r-cell>
                )}
                <r-cell span={columns === 1 ? "12" : "7"}>{children}</r-cell>
            </r-grid>
        </div>
    );
});
