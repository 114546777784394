import React from 'react';
import { cx, css } from 'emotion';
import { Stack } from 'office-ui-fabric-react';

import { actions as quickActions } from './QuickActionsConst';
import { FontIcon } from '@ui/elements/Icon';
import { Text } from '@ui/elements/Text';
import { useStores } from '../../../../../hooks/useStores';

const blockClassName = cx(css`

    margin: 0px;
    padding: 8px;

    & .fontIcon {
        font-size: 20px;
    }

    & .fontIcon.icon {
        padding: 8px;
        background-color: var(--background-color);
    }

    & .link {
        margin-top: 12px;
        color: var(--nav-color-accent);
    }

    & .link:hover {
        color: rgb(124, 234, 190);
        text-decoration: underline;
    }
`);

function getLink(action, actionType) {
    if (actionType && actionType.key === 'internalUrl') {
        return action.action
    }
    if (actionType && actionType.key === 'externalUrl') {
        return action.action
    }
    return actionType.link
}

function onClick(action, actionType, routing) {
    if (actionType && actionType.key === 'externalUrl') {
        window.location.href = action.action
    } else {
        routing.push(getLink(action, actionType))
    }
}

export const QuickActionsViewerPart = (props) => {
    const { part } = props;
    const { routing, themeProvider } = useStores();
    const theme = themeProvider.getTheme();

    return (
        <r-grid columns={part.properties.columns || 2} style={{width:'100%', gridGap: '1px'}}>
          {part.properties.actions && part.properties.actions.map((action, idx) => {
            const actionType = quickActions.find(A => A.key === action.type);
            return <r-cell 
                key={`key-${idx}`}
                style={{
                    cursor: 'pointer',
                    boxShadow: '0 0 0 1px var(--green-3)',
                }}
            > 
                <div 
                    className={blockClassName}
                    onClick={() => onClick(action, actionType, routing)}
                >
                    <Stack horizontalAlign="flex-start" verticalAlign="center">
                        <Stack horizontal horizontalAlign="space-between" style={{width: '100%'}}>
                            {action.icon && <FontIcon
                                className="fontIcon icon"
                                iconName={action.icon}
                                // style={{backgroundColor: theme.palette.themeDarkAlt}}
                            />}
                            {action.icon && <FontIcon
                                className="fontIcon"
                                iconName="Go"
                            />}
                        </Stack>
                        <Text className="link">
                            <h3>{action.title}</h3>
                        </Text>
                        {action.description &&  <p> {action.description} </p> }
                    </Stack>
                </div>
            </r-cell>
            })}
        </r-grid>
    );
}