import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { MessageBar } from 'office-ui-fabric-react/lib/MessageBar';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { cx, css } from 'emotion';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import Moment from 'react-moment';
import { DetailsListLayoutMode, Selection, SelectionMode, ConstrainMode } from 'office-ui-fabric-react/lib/DetailsList';
import { BoxPanel } from '../../../base/components/BoxPanel';
import { ObservablePagination } from '../../../base/components/ObservablePagination';
import { AssessmentHistoryCommandBar } from '../../components/systemView/assessmentHistoryTab/AssessmentHistoryCommandBar';
import { WrappedContent } from '../../../base/components/WrappedContent';
import { AssessmentStatusBadge } from '../../../assessments/components/core/AssessmentStatusBadge';

const moment = require('moment');

export const AssessmentHistoryTab = inject('systemStore', 'routing')(observer(
    class AssessmentHistoryTab extends Component {

        constructor (props) {
            super(props);
        }

        componentWillMount() {
            const { systemStore } = this.props;
            if (systemStore.selectedSystem && systemStore.selectedSystem.assessments === null)  systemStore.loadSystemAssessments(systemStore.selectedSystem.id, {});
        }

        componentWillReceiveProps(nextProps) {
            const { systemStore } = nextProps;
            if (systemStore.selectedSystem && systemStore.selectedSystem.assessments === null) systemStore.loadSystemAssessments(systemStore.selectedSystem.id, {});
        }

        _columns = [
            {
              key: 'name',
              name: 'Name',
              fieldName: 'name',
              minWidth: 310,
              isRowHeader: true,
              isResizable: true,
              onColumnClick: this._onColumnClick,
              data: 'string',
              onRender: (item) => {
                return (
                  <WrappedContent>
                    <a role="button" onClick={() => this.props.routing.push(`/assessments/browse/${item.id}`) /* //assessments/browse/768e9873-3941-4d15-9c6e-116662c1260c // this.props.onAssessmentClick(item) */}>{item.code} - {item.title || item.target.name}</a><br/>
                    { item.target.type && <Text variant="small">{item.target.type} <span>assessment</span> 
                        { item.started && <span>{ item.trigger == 'Manual' ? ' manually' : ' automaticlly' } started by {item.startedBy ? item.startedBy.name : '(Not Set)'} <Moment date={item.started} fromNow /></span> }</Text> }
                  </WrappedContent>
                  );
              },
              isPadded: true
            },
            {
              key: 'portfolio',
              name: 'Portfolio',
              fieldName: 'portfolio',
              minWidth: 100,
              maxWidth: 150,
              isResizable: false,
              onColumnClick: this._onColumnClick,
              data: 'string',
              onRender: (item) => {
                return (<span>{item.portfolio.name}</span>);
              },
              isPadded: true
            },
            {
              key: 'patternVersion',
              name: 'Type',
              fieldName: 'patternVersion',
              minWidth: 250,
              maxWidth: 300,
              isResizable: false,
              onColumnClick: this._onColumnClick,
              data: 'string',
              onRender: (item) => {
                return (
                  <WrappedContent>
                    <p>{item.patternVersion.name}</p>
                    <p>v{item.patternVersion.version}</p>
                  </WrappedContent>
                );
              },
              isPadded: true
            },
            {
                key: 'state',
                name: 'Status',
                fieldName: 'state',
                minWidth: 100,
                maxWidth: 130,
                isResizable: false,
                onColumnClick: this._onColumnClick,
                data: 'string',
                onRender: (item) => {
                  return <AssessmentStatusBadge assessment={item} />;
                },
                isPadded: true
            },
            {
              key: 'modified',
              name: 'Date Modified',
              fieldName: 'modified',
              minWidth: 100,
              maxWidth: 130,
              isResizable: true,
              isSorted: true,
              isSortedDescending: true,
              sortAscendingAriaLabel: 'Sorted A to Z',
              sortDescendingAriaLabel: 'Sorted Z to A',
              onColumnClick: this._onColumnClick,
              data: 'date',
              onRender: (item) => {
                return <Moment utc date={item.modified} fromNow />;
              },
              isPadded: true
            }
        ];
      
        render () {
            const assessments = this.props.assessments || [];
            const { keywords, selectedState, assessmentsStates } = this.props;
            return (
                <React.Fragment>
                    <AssessmentHistoryCommandBar 
                        onSearchChange={this.props.handleAssessmentsFilterKeywordsChange}
                        onChangeStatus={this.props.handleAssessmentsFilterStatusChange}
                        keywords={keywords}
                        selectedState={selectedState}
                        assessmentsStates={assessmentsStates}
                    />
                    <BoxPanel shadow>
                    <ShimmeredDetailsList
                        items={assessments}
                        compact={false}
                        columns={this._columns}
                        selectionMode={SelectionMode.none}
                        getKey={this._getKey}
                        setKey="single"
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.horizontalConstrained}
                        isHeaderVisible={true}
                        selection={this._selection}
                        selectionPreservedOnEmptyClick={true}                        
                        enterModalSelectionOnTouch={true}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        checkButtonAriaLabel="Row checkbox"
                        // enableShimmer={loading}
                        ariaLabelForShimmer="Assessments being fetched"
                        ariaLabelForGrid="Assessment list"
                        />
                        { !assessments.length && (
                            <MessageBar>
                                There are no matching assessments.
                            </MessageBar>
                        )}
                        {/* {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />} */}
                    </BoxPanel>
                    <ObservablePagination 
                        {...this.props.pagination}
                        onPageChange={(index) => this.props.handlePageChange(index)}
                    />
                </React.Fragment>
            );
        }
    }
));