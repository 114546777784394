import React from "react";
import { observer } from "mobx-react";

import { Text } from "@ui/elements/Text";
import { MessageBar } from "@ui/elements/MessageBar";

import { DetailsListLayoutMode, SelectionMode, ConstrainMode } from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { WrappedContent } from "@modules/base/components/WrappedContent";
import { AdaptiveField } from "@modules/base/components/AdaptiveField";

export const ControlEditGridView = observer((props) => {
    const { controls, loading, disableSelection, onUpdate } = props;

    const columns = [
        {
            key: "control",
            name: "Control",
            fieldName: "control",
            isResizable: true,
            data: "string",
            onRender: (item) => {
                return (
                    <WrappedContent>
                        {item.control.code} - {item.control.name}
                    </WrappedContent>
                );
            },
            isPadded: true,
        },
        {
            key: "capabilityLevel",
            name: "Target Capability Level",
            fieldName: "capabilityLevel",
            minWidth: 170,
            isResizable: false,
            data: "string",
            onRender: (item) => {
                return (
                    <AdaptiveField
                        type="Dropdown"
                        mode="edit"
                        valueConverter="simple"
                        value={item.capabilityLevel}
                        onChange={(val) => {
                            onUpdate({ ...item, capabilityLevel: val });
                        }}
                        options={[
                            { key: "Not Applicable", text: "Not Applicable" },
                            { key: "Accidental", text: "Accidental" },
                            { key: "Insider Attack", text: "Insider Attack" },
                            { key: "Hacker", text: "Hacker" },
                            { key: "Organised Crime", text: "Organised Crime" },
                        ]}
                    />
                );
            },
            isPadded: false,
        },
    ];

    return (
        <BoxPanel shadow>
            <ShimmeredDetailsList
                items={controls}
                compact={false}
                columns={columns}
                selectionMode={disableSelection ? SelectionMode.none : SelectionMode.none}
                getKey={(item, index) => (item ? item.id : index)}
                setKey="single"
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.horizontalConstrained}
                isHeaderVisible={true}
                selectionPreservedOnEmptyClick={true}
                //onItemContextMenu={this._onItemContextMenu}
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="Row checkbox"
                enableShimmer={loading}
                removeFadingOverlay={true}
                shimmerLines={15}
                ariaLabelForShimmer="Controls being fetched"
                ariaLabelForGrid="Control list"
            />

            {!loading && !controls.length && <MessageBar>There are no matching controls.</MessageBar>}
        </BoxPanel>
    );
});
