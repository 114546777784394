import React from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { Text } from "office-ui-fabric-react/lib/Text";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { IconButton } from "office-ui-fabric-react/lib/Button";
import { TooltipHost } from "office-ui-fabric-react/lib/Tooltip";

import { ObservableBadge } from "@baseComponents/ObservableBadge";
import { ActionSet } from "@baseComponents/ActionSet";

const rootClassName = cx(
    "cygraph-AssetHeader-root",
    css`
        margin: var(--spacing-s) 0px;

        .title {
            margin: var(--spacing-xs) 0;
        }

        & .description {
            color: var(--text-color-light);
            font-size: var(--font-size-xs);
        }

        & .back {
            width: 45px;
            & i {
                font-size: 30px;
                height: 30px;
                line-height: 30px;
                margin-right: 10px;
            }
        }
    `
);

export const AssetHeader = observer(
    ({ asset, onNavigateBack, hideAllActions, hideAgentActions, canEdit, onEdit, canUpload, onUpload }) => {
        const items = [];
        const overflowItems = [];

        if (!hideAllActions) {
            if (!hideAgentActions) {
                items.push({
                    key: "edit",
                    name: "Edit",
                    iconOnly: true,
                    iconProps: { iconName: "Edit" },
                    disabled: !canEdit || !canEdit(),
                    ariaLabel: "Edit",
                    onClick: onEdit,
                });
            }

            items.push({
                key: "upload",
                name: "Upload",
                iconOnly: true,
                iconProps: { iconName: "Upload" },
                disabled: !canUpload || !canUpload(),
                ariaLabel: "Upload",
                onClick: () => onUpload({ internalOnly: false }),
            });
        }

        return (
            <div className={rootClassName}>
                <Stack horizontal>
                    {onNavigateBack && (
                        <Stack.Item>
                            <TooltipHost content="Navigate back to all assets">
                                <IconButton
                                    className="back"
                                    iconProps={{ iconName: "NavigateBack" }}
                                    title="Navigate back"
                                    ariaLabel="Navigate back"
                                    onClick={onNavigateBack}
                                />
                            </TooltipHost>
                        </Stack.Item>
                    )}
                    <Stack.Item grow>
                        <Stack horizontal horizontalAlign="space-between">
                            <ObservableBadge className="badge">Asset</ObservableBadge>
                            <ActionSet items={items} overflowItems={overflowItems} />
                        </Stack>
                    </Stack.Item>
                </Stack>
                <Text className="title" variant="xLarge" block>
                    {asset.code} - {asset.name}
                </Text>
            </div>
        );
    }
);
