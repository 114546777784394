import React, { Component } from "react";
import { observer } from "mobx-react";

import { AgeIndicator } from "../../../base/components/AgeIndicator";

export const RiskAgeIndicator = observer(
    class RiskAgeIndicator extends Component {
        render() {
            const { className, risk } = this.props;

            if (!risk || !risk.lifecycle) {
                return null;
            }
            return (
                <AgeIndicator
                    className={className}
                    creationDate={risk.lifecycle.created}
                    startDate={risk.lifecycle.submitted}
                    closedDate={risk.lifecycle.closed}
                    strings={{
                        started: "Submitted on",
                        closed: "Closed on",
                    }}
                />
            );
        }
    }
);
