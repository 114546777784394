import { observable, flow } from "mobx";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

import { ActivityStore } from "./ActivityStore";
import { PaginationStore } from "@modules/base/PaginationStore";
import { ActivityService } from "@api/activities";

export class ActivityContextStore {
    public parentStore: ActivityStore;
    public activityService: ActivityService;

    @observable public instanceId: string;
    @observable public snapshot: string;
    @observable public query: any;
    @observable public messages: any[] = [];
    @observable public context: any = null;
    @observable public loading: boolean = false;
    @observable public saving: boolean = false;
    @observable public error: any;
    @observable public loaded: boolean = false;
    @observable public pagination: PaginationStore;
    @observable public listening: boolean = false;
    @observable public page: number = 1;
    @observable public content: any;
    @observable public contentType: string = "text/plain";

    constructor(parentStore: ActivityStore) {
        this.parentStore = parentStore;
        this.activityService = parentStore.activityService;
        this.pagination = new PaginationStore();
        this.instanceId = uuidv4();
    }

    public onLoad = flow(function* (options) {
        if (this.loading) {
            console.log("Activity message loading already in progress");
            return;
        }

        if (this.loaded) {
            console.log("Activity message are fully loaded");
            return;
        }

        this.page = this.page + 1;

        const o = Object.assign(
            { page: this.page, query: {} },
            { query: { context: [this.context], timestamp: { before: this.snapshot } } }
        );
        this.loading = true;
        this.query = o.query;
        this.error = null;

        try {
            const result = yield this.activityService.searchActivityMessages({
                pageSize: this.pagination.itemsPerPage,
                startIndex: (o.page - 1) * this.pagination.itemsPerPage,
                query: {
                    ...this.query,
                    ...o.query,
                },
            });

            this.loaded = result.items.length === 0;
            this.messages = [...result.items.reverse(), ...this.messages];
            this.query = result.query;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.loading = false;
        }
    });

    public onSave = flow(function* () {
        if (!this.content || !this.contentType) {
            return;
        }
        this.saving = true;
        try {
            const result = yield this.activityService.createActivityMessage({
                timestamp: moment.utc().toISOString(),
                version: "1.0.0",
                internalOnly: false,
                context: {
                    id: this.context.id,
                    type: this.context.type,
                    name: this.context.name,
                },
                content: this.content,
                contentType: this.contentType,
            });

            this.addToBottom([result]);
            this.content = null;
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.saving = false;
        }
    });

    public start = flow(function* ({ context }) {
        this.context = context;

        if (!this.snapshot) {
            this.snapshot = moment.utc().toISOString();
        }

        this.parentStore.addListener({
            instanceId: this.instanceId,
            context: this.context,
            listener: this,
        });

        this.loaded = false;
        this.pagination = new PaginationStore();
        this.messages = [];
        this.page = 0;
        this.onLoad();
    });

    public stop = flow(function* () {
        this.parentStore.removeListener({
            instanceId: this.instanceId,
            context: this.context,
            listener: this,
        });
    });

    public addToBottom = flow(function* (messages) {
        messages.forEach((message) => {
            const indexOfCurrent = this.messages.findIndex((m) => m.id === message.id);
            if (indexOfCurrent === -1) {
                if (this.messages.length === 0) {
                    this.messages = [message];
                } else {
                    const ts = moment.utc(message.timestamp);
                    for (let i = this.messages.length - 1; i >= 0; i--) {
                        if (moment.utc(this.messages[i].timestamp).isBefore(ts)) {
                            this.messages.splice(i + 1, 0, message);
                            this.messages = [...this.messages];
                            break;
                        }
                    }
                }
            } else {
                this.messages[indexOfCurrent] = { ...message };
            }
        });
    });

    public onData = flow(function* (ev, data) {
        if (data) {
            if (data.event === "activity.message.created") {
                this.addToBottom([data.message]);
            }
        }
    });

    // public onConnected = flow(function* () {
    //     console.log("onConnected received on context", this.listening, this.firstTime);
    //     if (this.listening && this.firstTime) {
    //         this.firstTime = false;
    //         yield this.parentStore.onLoad({ instanceId: this.instanceId, context: this.context }, this);
    //     }
    // });

    // public onData = flow(function* (ev, data) {
    //     console.log("onData received on the context", data);
    //     if (data) {
    //         this.activities = [
    //             ...this.activities,
    //             ...data.items.reverse(), //.filter((a) => a.metadata.type === ActivityType.Status)
    //         ];
    //     }
    // });

    // public onLoadZ = flow(function* (options) {
    //     console.log("onLoad called on the context", this.context);
    //     if (this.context && this.context.id) {
    //         yield this.parentStore.onLoad({ instanceId: this.instanceId, context: this.context }, this);
    //     }
    // });
}
