import React, { Component } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { TooltipHost, TooltipDelay, DirectionalHint } from "office-ui-fabric-react/lib/Tooltip";
import { Label } from "office-ui-fabric-react/lib/Label";

const rootClassName = cx(
    "cygraph-ControlMaturity-root",
    css`
        & .wrapper {
            display: flex;
            flex-direction: row;
        }

        & .text {
            margin-left: 3px;
            color: var(--text-color-dark);

            &.mini {
                font-size: 12px;
            }
        }

        & .score {
            width: 20px;
            height: 20px;
            background: var(--grey-2);
            margin-right: 2px;

            &.mini {
                width: 10px;
                margin-top: 4px;
                height: 10px;
            }

            &.medium {
                width: 14px;
                margin-top: 2px;
                height: 14px;
            }

            &.Optimised {
                background: var(--blue-6);
            }
            &.Managed {
                background: var(--green-6);
            }
            &.Defined {
                background: var(--yellow-6);
            }
            &.Repeatable {
                background: var(--orange-6);
            }
            &.Initial {
                background: var(--red-6);
            }
        }
    `
);

const scores = ["Initial", "Repeatable", "Defined", "Managed", "Optimised"];

export const ControlMaturity = observer(
    class ControlMaturity extends Component {
        render() {
            const { hideTooltip, score, size } = this.props;
            const index = scores.indexOf(score || "None");
            const maxImpact = 5;
            const maxLikelihood = 5;

            const tooltipProps = {
                onRenderContent: () =>
                    score && (
                        <div>
                            <div>
                                <Label>Score</Label> {index < 0 ? 0 : index} out of {maxLikelihood}
                            </div>
                        </div>
                    ),
            };

            return (
                <div className={rootClassName}>
                    <TooltipHost
                        tooltipProps={tooltipProps}
                        calloutProps={{ hidden: index == -1 || !!hideTooltip }}
                        delay={TooltipDelay.medium}
                        directionalHint={DirectionalHint.bottomLeftEdge}
                    >
                        <div className="wrapper">
                            {scores.map((s, i) => (
                                <div key={i} className={cx("score", size, i <= index && score)}></div>
                            ))}
                            <div className={cx("text", size)}>{score || "None"}</div>
                        </div>
                    </TooltipHost>
                </div>
            );
        }
    }
);
