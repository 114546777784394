import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { useStores } from "@hooks/useStores";

import { AppPage } from "@modules/layout/containers/AppPage";
import { JsonQueryString } from "@modules/utils/JsonQueryString";
import { ObservablePagination } from "@modules/base/components/ObservablePagination";

import { StrategicControlGridView } from "@modules/controls/components/strategic/StrategicControlGridView";

export const StrategicControlBrowsePage = withRouter(
    observer(({ match }) => {
        const { pageContext, routing, controlStore } = useStores();
        const { strategicBrowseStore } = controlStore;
        const { breadcrumb, error, controls, query, loading, pagination } = strategicBrowseStore;

        useEffect(() => {
            pageContext.updateQueryStringParams(routing.location.search);
            strategicBrowseStore.loadControls({
                page: pageContext.queryStringParams.p || 1,
                query: JsonQueryString.decode(pageContext.queryStringParams.query),
            });
        }, [match, routing, pageContext]);

        return (
            <AppPage
                loading={loading}
                breadcrumb={breadcrumb}
                error={error}
                hasPageNav={true}
                hasSidebar={false}
                className="controls list strategic"
            >
                <StrategicControlGridView loading={loading} query={query} controls={controls} />

                <ObservablePagination
                    {...pagination}
                    onPageChange={(index) => {
                        routing.push(JsonQueryString.build("/controls/browse", query, index + 1));
                    }}
                />
            </AppPage>
        );
    })
);
