import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';

import { NormalPeoplePicker, ValidationState } from 'office-ui-fabric-react/lib/Pickers';
import { PersonaPresence, PersonaSize } from 'office-ui-fabric-react/lib/Persona';

import { Label } from 'office-ui-fabric-react/lib/Label';

export const SystemPicker = observer(
    class SystemPicker extends Component {
        
        render () {
            const { label, placeholder, required, itemLimit, disabled, className, onSelected, onFilter, selected, multiple } = this.props;

            const suggestionProps = {
                suggestionsHeaderText: 'Suggested apps and systems',
                mostRecentlyUsedHeaderText: 'Suggested apps and systems',
                noResultsFoundText: 'No apps or systems found',
                loadingText: 'Loading...',
                showRemoveButtons: false,
                suggestionsAvailableAlertText: 'System picker suggestions available',
                suggestionsContainerAriaLabel: 'Suggested apps and systems'
            };

            const items = multiple ? (selected || []) : (selected ? [selected] : []);

            return (
                <div className={cx(
                        'cygraph-SystemPicker-container',
                        className,
                        css`  
                            & .ms-BasePicker-text {
                                background-color: var(--white);
                                min-height: 32px;
                            }
                            & .ms-Persona-coin {
                                width: 20px;
                            }
                            & .ms-Persona-initials {
                                display: none;
                            }
                            & .ms-Persona-presence {
                                top: 6px;
                                right: 0;
                            }
                        `
                    )}>
                    <Label required={required} disabled={disabled}>{label}</Label>

                    <NormalPeoplePicker
                        required={required}
                        selectedItems={items.map(s =>  this._getPersonaFromSystem(s))}
                        onResolveSuggestions={(text, current, limit) => {
                            return onFilter(text, current, limit).then((result) => {
                                return result.items.map(s => this._getPersonaFromSystem(s));
                            });
                        }}
                        //onEmptyInputFocus={returnMostRecentlyUsed}
                        pickerSuggestionsProps={suggestionProps}
                        className={'cygraph-SystemPicker'}
                        onValidateInput={this._validateInput}
                        removeButtonAriaLabel={'Remove'}
                        itemLimit={multiple ? itemLimit : 1}
                        inputProps={{
                            placeholder: placeholder
                        }}
                        pickerCalloutProps={{
                            className: cx(
                                'cygraph-SystemPicker-callout',
                                className,
                                css`
                                    & .ms-Persona {
                                        width: 300px;
                                    }
                                    & .ms-Persona-coin {
                                        width: 10px;
                                    }
                                    & .ms-Persona-initials {
                                        display: none;
                                    }
                                    & .ms-Persona-presence {
                                        top: -4px;
                                        right: 14px;
                                    }
                                `
                            )
                        }}
                        onChange={(items) => {
                            if(onSelected) {
                                const current = items.map((i) => i.data);
                                onSelected(multiple ? current : (current.length ? current[0] : null));
                            }   
                            return items;
                        }}
                        resolveDelay={300}
                        disabled={disabled}
                    />
                </div>
            );
        }

        _getPersonaFromSystem(system) {
            return { 
                key: system.id, 
                text: system.name, 
                secondaryText: system.alias, 
                presenceTitle: `Status - ${system.state}`,
                //size: PersonaSize.size32,
                presence: this._getPresenceFromState(system.state),
                data: system 
            };
        }

        _getPresenceFromState(state) {
            switch(state) {
                case 'Conceptual': return PersonaPresence.offline;
                case 'Developing': return PersonaPresence.away;
                case 'Testing': return PersonaPresence.away;
                case 'Live': return PersonaPresence.online;
                case 'Decommissioning': return PersonaPresence.blocked;
                case 'Decommissioned': return PersonaPresence.busy;
                default: return PersonaPresence.offline;
            }
        }

        _getTextFromItem(persona) {
            return persona.name;
        }

        _onFilterChanged(filterText, currentPersonas, limitResults) {
            return []
        }

        _validateInput(input) {
            if (input.indexOf('@') !== -1) {
              return ValidationState.valid;
            } else if (input.length > 1) {
              return ValidationState.warning;
            } else {
              return ValidationState.invalid;
            }
        }          
    }
);