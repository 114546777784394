import React, { Component, Fragment } from "react";
import { observer } from "mobx-react";

import { SearchBox } from "office-ui-fabric-react/lib/SearchBox";
import { CommandBar } from "office-ui-fabric-react/lib/CommandBar";
import { BoxPanel } from "../../../base/components/BoxPanel";

export const StateFlowCommandBar = observer(
    class StateFlowCommandBar extends Component {
        render() {
            const { keywords, onSearchChange, onCreate, onImport } = this.props;

            const items = [
                {
                    key: "new",
                    text: "New",
                    iconProps: { iconName: "Add" },
                    disabled: !onCreate,
                    onClick: onCreate,
                },
                {
                    key: "import",
                    text: "Import",
                    iconProps: { iconName: "Upload" },
                    disabled: !onImport,
                    onClick: onImport,
                },
            ];

            const farItems = [
                {
                    key: "search",
                    text: "Search",
                    onRender: () => {
                        return (
                            <SearchBox
                                value={keywords}
                                placeholder="Search by name or alias"
                                styles={{ root: { height: "auto", minWidth: "270px" } }}
                                onSearch={onSearchChange}
                            />
                        );
                    },
                },
            ];

            return (
                <BoxPanel shadow>
                    <CommandBar styles={{ root: { paddingRight: "0px" } }} items={items} farItems={farItems} />
                </BoxPanel>
            );
        }
    }
);
