import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { v4 as uuidv4 } from 'uuid';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { DefaultButton, IconButton } from 'office-ui-fabric-react/lib/Button';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { Callout } from 'office-ui-fabric-react/lib/Callout';
import { ColorPicker} from 'office-ui-fabric-react/lib/ColorPicker';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { ObservableSeparator } from '../../../base/components/ObservableSeparator';
import { cx, css } from 'emotion';

const colorPickerClassName = cx (css`
    maxWidth: 352px,
    maxHeight: 352px,
    panel: {
        padding: 12
    },
    colorRectangle: {
        height: 268px
    },
`);

export const GridCalendarPartEditor = inject('dataSourceStore')(observer(
  class GridCalendarPartEditor extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isColorPickerOpen: []
        };
    }
    render () {
      const { formData, audiences, dataSourceStore, dataSources, instance } = this.props;
      return (
            <div>
                <r-grid columns="2">
                    <r-cell span="2">
                        <ObservableSeparator>Calendar Settings</ObservableSeparator>
                    </r-cell>
                    <r-cell span="2">
                        <DefaultButton text="Add event series" onClick={() => {
                            if (!formData.properties.dataSeries) formData.properties.dataSeries = [];
                            formData.properties.dataSeries.push({
                                dataSourceId: null,
                                label: 'New Event Series',
                                id: uuidv4(),
                            })
                        }} />
                    </r-cell>
                    <r-cell span="2">
                        <r-grid columns="2">
                            <r-cell span="1">
                                <Toggle 
                                    label="Hide legend"
                                    checked={formData.properties.hideLegend}
                                    onChange={(ev, checked) => {
                                        formData.properties.hideLegend = checked;
                                    }}  
                                />
                            </r-cell>
                            {!formData.properties.hideLegend && <r-cell span="1">
                                <Toggle 
                                    label="Show as"
                                    checked={formData.properties.displayAsButton}
                                    onChange={(ev, checked) => {
                                        formData.properties.displayAsButton = checked;
                                    }}
                                    onText="Buttons"
                                    offText="Lists"
                                />
                            </r-cell>}
                        </r-grid>
                    </r-cell>
                    <r-grid columns="2">
                        {formData.properties.dataSeries && formData.properties.dataSeries.length > 0 && formData.properties.dataSeries.map((DS,i) => {
                            const selectedPartDataSource = DS.dataSourceId ? dataSources.find(D => D.id === DS.dataSourceId) : null;
                            const selectedCoreDataSource = selectedPartDataSource ? dataSourceStore.coreDataSourceDataSource(selectedPartDataSource.properties.mainSource) : null;
                            return(
                            <div style={{border:"1px solid black", padding: '5px', minWidth: '250px'}} key={`DS_${i}`}>
                                <r-cell span="2">
                                    <div style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-end',
                                        }}>
                                        <Dropdown
                                            required
                                            label="Data source for calendar"
                                            selectedKey={DS.dataSourceId}
                                            onChange={(ev, op) => {
                                                if(op) {
                                                    DS.dataSourceId = op.key;
                                                }
                                            }}
                                            options={
                                                dataSources
                                                    .map(DSource => {
                                                        return {
                                                            key: DSource.id,
                                                            text: DSource.title,
                                                        }
                                                })
                                            }
                                        />
                                        {DS.dataSourceId && <TooltipHost
                                            content="Edit data source"
                                            id={`editTooltipId`}
                                            calloutProps={{ gapSpace: 0 }}
                                            styles={{display:'inline-block', maginLeft: '5px'}}
                                        >
                                            <IconButton 
                                                iconProps={{ iconName: 'Edit' }}
                                                title="Edit"
                                                ariaLabel="Edit"
                                                onClick={() => {
                                                        const idx = formData.properties.dataSeries.findIndex(D => D.id === DS.id);
                                                        if (idx > -1) {
                                                            const pagePart = instance.selectionStore.getPagePart(DS.dataSourceId);
                                                            instance.lifecycleStore.onEditPart(pagePart.section, pagePart.zone, pagePart.part);
                                                        }
                                                    }
                                                }
                                            />
                                        </TooltipHost> }
                                    </div>
                                </r-cell>
                                {DS.dataSourceId && <r-grid columns="2">
                                    <r-cell span="2">
                                            <TextField 
                                                label="Name"
                                                required
                                                value={DS.label}
                                                onChange={(ev, val) => DS.label = val}
                                            />
                                    </r-cell>
                                    <r-cell>
                                        <TextField 
                                            label="Color"
                                            readOnly
                                            style={{
                                                color: DS.eventsColor || '#3788d8',
                                                backgroundColor: DS.eventsColor || '#3788d8',
                                                cursor: 'context-menu',
                                            }}
                                        />
                                    </r-cell>
                                    <r-cell>
                                            <IconButton
                                                className={`colorPicker${DS.id}`}
                                                style={{marginTop: '28px'}}
                                                title="Color Picker"
                                                iconProps={{ iconName: 'Color' }}
                                                onClick={()=>{
                                                    const co = this.state.isColorPickerOpen;
                                                    co.forEach(element => {
                                                    element = false;
                                                    })
                                                    co[DS.id] = true;
                                                    this.setState({
                                                        isColorPickerOpen: co,
                                                    })
                                                }}
                                            />
                                        {this.state.isColorPickerOpen[DS.id] && <Callout                                        
                                            role="alertdialog"
                                            gapSpace={0}
                                            target={`.colorPicker${DS.id}`}
                                            onDismiss={() => {
                                                const co = this.state.isColorPickerOpen;
                                                co[DS.id] = false;
                                                this.setState({
                                                    isColorPickerOpen: co,
                                            })
                                            }}
                                            setInitialFocus
                                            >
                                            <div className="inner">
                                            <ColorPicker
                                                color={DS.eventsColor}
                                                onChange={(ev, colorObject)=> {
                                                    DS.eventsColor = colorObject.str
                                                }}
                                                alphaType='none'
                                                alphaSliderHidden={true}
                                                showPreview={true}
                                                className={colorPickerClassName}
                                            />
                                            </div>
                                            </Callout>}
                                    </r-cell>
                                    <r-cell span="2">
                                        <div>
                                            <Dropdown
                                                required
                                                label="Title field"
                                                selectedKey={DS.titleField}
                                                onChange={(ev, op) => {
                                                    if(op) {
                                                        DS.titleField = op.key;
                                                    }
                                                }}
                                                options={selectedCoreDataSource.fields.map(F => {
                                                    return {
                                                        key: F.id,
                                                        text: F.title,
                                                    }
                                                })}
                                            />
                                        </div>
                                    </r-cell>
                                    <r-cell span="2">
                                        <div>
                                            <Dropdown
                                                required
                                                label="Start date field"
                                                selectedKey={DS.startDateField}
                                                onChange={(ev, op) => {
                                                    if(op) {
                                                        DS.startDateField = op.key;
                                                    }
                                                }}
                                                options={selectedCoreDataSource.fields.map(F => {
                                                    return {
                                                        key: F.id,
                                                        text: F.title,
                                                    }
                                                })}
                                            />
                                        </div>
                                    </r-cell>
                                    <r-cell span="2">
                                    <Toggle label="Full day event" checked={DS.isFullDayEvent} onChange={(ev, checked) => {
                                        DS.isFullDayEvent = checked;
                                        }}  />
                                    </r-cell>
                                    <r-cell span="2">
                                        <div>
                                            <Dropdown                                            
                                                label="End date field"
                                                selectedKey={DS.endDateField}
                                                onChange={(ev, op) => {
                                                    if(op) {
                                                        DS.endDateField = op.key;
                                                    }
                                                }}
                                                options={selectedCoreDataSource.fields.map(F => {
                                                    return {
                                                        key: F.id,
                                                        text: F.title,
                                                    }
                                                })}
                                            />
                                        </div>
                                    </r-cell>
                                </r-grid>}
                                <r-cell span="2">
                                    <DefaultButton text="Remove data series" styles={{marginTop:'5px',width: '100%'}} onClick={() => {
                                        const idx = formData.properties.dataSeries.findIndex(D => D.id === DS.id);
                                        if (idx > -1) {
                                            formData.properties.dataSeries.splice(idx, 1);
                                        }
                                    }} />
                                </r-cell>
                            </div>)})}
                  </r-grid>
              </r-grid>
          </div>
      );
    }
  }
));
