import { observable, computed, action } from "mobx";

import { ResponseHandle } from "@modules/base/types/ResponseHandle";

import { WorkItemService } from "../../../api/workitems";
import { WorkItemStore } from "./WorkItemStore";

export class WorkItemAssignFormStore {
    public parentStore: WorkItemStore;
    public workItemService: WorkItemService;

    private responseHandle?: ResponseHandle;

    @observable public visible: boolean = false;
    @observable public processing: boolean = false;
    @observable public formData: any;
    @observable public formOptions: any;

    constructor(parentStore: WorkItemStore) {
        this.parentStore = parentStore;
        this.workItemService = this.parentStore.workItemService;
    }

    @action
    public show(options) {
        this.visible = true;
        this.processing = false;
        this.formOptions = {
            pageTitle: options.pageTitle || `${options.workItem.code} - ${options.workItem.title}`,
        };
        this.formData = {
            id: options.workItem.id,
            assignedToGroup: options.workItem.assignedToGroup,
            assignedToUser: options.workItem.assignedToUser,
        };

        return new Promise<any>((resolve, reject) => {
            this.responseHandle = { resolve, reject };
        });
    }

    @action
    public hide(_) {
        this.visible = false;
        this.processing = false;
        this.formData = null;
        this.formOptions = null;
        this.responseHandle = null;
    }

    @action
    public resolve(success) {
        this.processing = true;
        this.responseHandle.resolve({
            success,
            formData: this.formData,
            formOptions: this.formOptions,
        });
    }

    @action
    public reject(error) {
        this.processing = true;
        this.responseHandle.reject({
            error,
            formData: this.formData,
            formOptions: this.formOptions,
        });
    }

    @computed
    public get isValid() {
        return (
            this.formData &&
            this.formData.id &&
            this.formData.assignedToGroup &&
            this.formData.assignedToGroup.id &&
            this.formData.assignedToUser &&
            this.formData.assignedToUser.id
        );
    }

    // public onAssign = flow(function*(){
    //     this.saving = true;
    //     try {
    //         const result = yield this.workItemService.assignWorkItem(this.formData);
    //         this.parentStore.selectionStore.setSelected(result);
    //         return result;
    //     } catch (e) {
    //         console.error(e);
    //         throw e;
    //     } finally {
    //         this.saving = false;
    //     }
    // });

    // public onAfterAssign = flow(function*(workItem){
    //     if(workItem) {
    //         this.parentStore.rootStore.layoutStore.displayToastNotification(
    //             `Request ${workItem.code} assigned to ${workItem.assignedToUser.name} from ${workItem.assignedToGroup.name}`);
    //     }
    // });
}
