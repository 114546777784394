import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { css } from '@uifabric/utilities/lib/css';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Pivot, PivotItem } from 'office-ui-fabric-react/lib/Pivot';

import { AppPage } from '../../../layout/containers/AppPage';
import { SettingsListNav } from '../../../settings/containers/utilities/SettingsListNav';

import { AssetPropertiesTab } from '../tabs/AssetPropertiesTab';
import { AssetSectionsTab } from '../tabs/AssetSectionsTab';
import { AssetWorkflowsTab } from '../tabs/AssetWorkflowsTab';

export const AssetSettingsPage = inject('pageContext', 'assetStore', 'propertyStore', 'routing')(observer(
    class AssetSettingsPage extends Component {
    
        render () {
            const { loading, error, breadcrumb } = this.props.assetStore.settingsStore;

            return (
                <AppPage error={error} breadcrumb={breadcrumb} 
                    loading={loading} hasSidebar={false} 
                    className={css('settings assets list', !error && 'has--page-nav')}>
                    <SettingsListNav />
                    <div>
                        <Text className="title" variant="xLarge" block>
                            Asset Management
                        </Text>

                        <Pivot aria-label="Asset Settings Tabs">
                            <PivotItem headerText="Properties">
                                <AssetPropertiesTab />
                            </PivotItem>
                            <PivotItem headerText="Sections">
                                <AssetSectionsTab />
                            </PivotItem>
                            <PivotItem headerText="Workflows">
                                <AssetWorkflowsTab />
                            </PivotItem>
                        </Pivot>
                    </div>
                </AppPage>
            );
        }
    }
));
