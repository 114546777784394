import React, { Component } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";
import Moment from "react-moment";

import { Card } from "@uifabric/react-cards";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { Image, ImageFit } from "office-ui-fabric-react/lib/Image";
import { Text } from "office-ui-fabric-react/lib/Text";

const rootClassName = cx(
    "cygraph-ControlSetCardView-root",
    css`
        & .set {
            background-color: var(--white);
            max-width: 100%;

            &:focus {
                outline: none;
            }
        }

        & .image {
            background-color: var(--border-color);
        }

        & .version {
            color: var(--text-color-lighter);
        }

        & .description {
            color: var(--text-color-lighter);
            min-height: 110px;
        }

        & .footer {
            border-top: 1px solid var(--border-color);
            padding-top: 10px;
        }
    `
);

export const ControlSetCardView = observer(
    class ControlSetCardView extends Component {
        render() {
            const { className, loading, sets } = this.props;
            const cardTokens = { childrenMargin: 15 };

            return loading || !sets ? null : (
                <div className={cx(rootClassName, className)}>
                    <r-grid columns="3" columns-l="4">
                        {sets.map((set) => (
                            <r-cell key={set.id}>
                                <Card
                                    className="set"
                                    aria-label="Clickable vertical card with image bleeding at the top of the card"
                                    onClick={(ev) => this._onControlSetClick(ev, set)}
                                    tokens={cardTokens}
                                >
                                    <Card.Item fill>
                                        <Image
                                            className="image"
                                            src={set.imageUrl || "/images/logos/zerodai.png"}
                                            width="100%"
                                            height={125}
                                            imageFit={ImageFit.centerContain}
                                            alt={set.name}
                                        />
                                    </Card.Item>
                                    <Card.Section>
                                        <Text className="version" variant="small">
                                            {set.provider} {set.version ? `v${set.version}` : ""}
                                        </Text>
                                        <Text className="name" variant="large">
                                            {set.name}
                                        </Text>
                                    </Card.Section>
                                    <Card.Section>
                                        <Text className="description" variant="small">
                                            {set.description}
                                        </Text>
                                        <Text className="published" variant="small">
                                            <Moment utc date={set.published} format="Do MMM YYYY" />
                                        </Text>
                                    </Card.Section>
                                    <Card.Item grow={1}>
                                        <span />
                                    </Card.Item>
                                    <Card.Section horizontal className="footer">
                                        {set.active && <Icon iconName="Completed" />}
                                        {set.locked && <Icon iconName="Lock" />}
                                        <Stack.Item grow={1}>
                                            <span />
                                        </Stack.Item>
                                        <Icon iconName="MoreVertical" />
                                    </Card.Section>
                                </Card>
                            </r-cell>
                        ))}
                    </r-grid>
                </div>
            );
        }

        _onControlSetClick = (ev, item) => {
            const { onControlSetClick } = this.props;
            ev.preventDefault();
            if (item && onControlSetClick) {
                onControlSetClick(item, ev);
            }
        };
    }
);
