import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { useStores } from "@hooks/useStores";

import { Text } from "office-ui-fabric-react/lib/Text";

import { AppPage } from "@modules/layout/containers/AppPage";
import { ObservablePagination } from "@modules/base/components/ObservablePagination";

import { SettingsListNav } from "@modules/settings/containers/utilities/SettingsListNav";
import { StateFlowGridView } from "@modules/stateflows/components/core/StateFlowGridView";
import { StateFlowCommandBar } from "@modules/stateflows/components/core/StateFlowCommandBar";

import { StateFlowEditPanel } from "@modules/stateflows/containers/panels/StateFlowEditPanel";
import { StateFlowContextMenu } from "@modules/stateflows/containers/utilities/StateFlowContextMenu";

export const StateFlowBrowsePage = withRouter(
    observer(({ match }) => {
        const { stateFlowStore, routing } = useStores();
        const { browseStore, lifecycleStore, editFormStore, contextMenuStore } = stateFlowStore;
        const { breadcrumb, stateFlows, query, loading, error } = browseStore;

        useEffect(() => {
            browseStore.loadStateFlows({});
        }, []);

        return (
            <AppPage loading={loading} error={error} breadcrumb={breadcrumb} hasSidebar={false} className="workflows list has--page-nav">
                <SettingsListNav />
                <div>
                    <Text className="title" variant="xLarge" block>
                        Workflows
                    </Text>

                    <StateFlowCommandBar
                        keywords={query.keywords}
                        onSearchChange={(keywords) => {
                            if (keywords) {
                                routing.push(`/settings/workflows?k=${encodeURIComponent(keywords)}`);
                            } else {
                                routing.push(`/settings/workflows`);
                            }
                        }}
                    />

                    <StateFlowGridView
                        stateFlows={stateFlows}
                        loading={loading}
                        query={query}
                        onStateFlowClick={(stateFlow) => lifecycleStore.onView({ stateFlow })}
                        onStateFlowMenuClick={(stateFlow, ev) => lifecycleStore.onContextMenu({ stateFlow, target: ev.target })}
                    />

                    <ObservablePagination
                        {...browseStore.pagination}
                        onPageChange={(index) => {
                            routing.push(`/settings/workflows?p=${index + 1}${browseStore.keywords ? `&k=${encodeURIComponent(browseStore.keywords)}` : ""}`);
                        }}
                    />

                    <StateFlowEditPanel store={editFormStore} />
                    <StateFlowContextMenu store={contextMenuStore} />
                </div>
            </AppPage>
        );
    })
);
