import React, { Component } from "react";

import { AppLogo } from "./AppLogo";

export class AppNavHome extends Component {
    static displayName = AppNavHome.name;

    render() {
        const { appName, home, routing } = this.props;
        return (
            <div className="app__nav__logo__wrapper">
                <a
                    onClick={(ev) => {
                        ev.preventDefault();
                        routing.push(home.url);
                    }}
                    href="#"
                    title={appName || "Zerodai"}
                >
                    <AppLogo width={this.props.width} />
                    <div className="app__nav__logo__label">{appName || "Zerodai"}</div>
                </a>
            </div>
        );
    }
}
