import { computed } from "mobx";
import { RootStore } from "./RootStore";

export class ConfigurationStore {
    public rootStore: RootStore;

    // @observable public areTasksEnabled: boolean = false;
    @computed
    public get areTasksEnabled() {
        const allowed = ["app-cyg-uks-ih01.azurewebsites.net", "demo1.zerod.ai", "localhost"];
        const isRightDomain = allowed.find((hostname) => window.location.hostname.includes(hostname));
        return isRightDomain ? true : false;
    }

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }
}
