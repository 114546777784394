import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { FieldSetView } from "@modules/base/components/FieldSetView";

import { RiskDetailsSection } from "@modules/risks/components/core/RiskDetailsSection";
import { RiskWorkflowSection } from "@modules/risks/components/core/RiskWorkflowSection";
import { RiskContactList } from "@modules/risks/components/core/RiskContactList";
import { ReviewSummary } from "@modules/risks/components/reviews/ReviewSummary";

export const RiskSummaryTab = observer(() => {
    const { riskStore } = useStores();
    const { risk, lifecycle } = riskStore.viewStore;

    useEffect(() => {
        riskStore.commentStore.loadComments();
    }, [risk ? risk.id : null]);

    return (
        <BoxPanel shadow padding style={{ minHeight: 500 }}>
            <FieldSetView title="Key Information" description="Basic information about this risk.">
                <RiskDetailsSection risk={risk} lifecycle={lifecycle} />
            </FieldSetView>
            <FieldSetView title="Key Contacts" description="Key contacts for the risk.">
                <RiskContactList risk={risk} lifecycle={lifecycle} />
            </FieldSetView>
            <FieldSetView
                title="Workflow Progress"
                description="Information about the progress of the risk and assigned user."
            >
                <RiskWorkflowSection risk={risk} lifecycle={lifecycle} />
            </FieldSetView>
            <FieldSetView title="Recent Review" description="Summary of most recent risk review undertaken.">
                <ReviewSummary risk={risk} lifecycle={lifecycle} />
            </FieldSetView>
        </BoxPanel>
    );
});
