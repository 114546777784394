import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router';
import { cx } from 'emotion';
import Dropzone from 'react-dropzone';

import { RiskHeader } from '../../../../risks/components/core/RiskHeader';
import { RiskStatusProgress } from '../../../../risks/components/core/RiskStatusProgress';

import { RiskViewTabList } from '../../../../risks/containers/tabs/RiskViewTabList';
import { RiskReviewPanel } from '../../../../risks/containers/panels/RiskReviewPanel';
import { RiskTransferPanel } from '../../../../risks/containers/panels/RiskTransferPanel';

export const RiskViewPage = inject('pageContext', 'riskStore', 'routing')(withRouter(observer(
    class RiskViewPage extends Component {

        componentWillMount() {
            const { riskStore, match } = this.props;

            riskStore.viewStore.loadRisk(match.params.id);
        }

        componentWillReceiveProps(nextProps) {
            const { riskStore, match } = nextProps;

            riskStore.viewStore.loadRisk(match.params.id);
        }
    
        render () {
            const { riskStore, match } = this.props;
            const { viewStore, documentStore, lifecycleStore, reviewStore, transferStore } = riskStore;
            const { loading, error, breadcrumb, risk, lifecycle } = viewStore;
            
            return (
                <>
                    { risk && risk.id == match.params.id && lifecycle && (
                        <>
                            <Dropzone onDrop={acceptedFiles => riskStore.documentStore.onUpload(acceptedFiles)} 
                                disabled={!riskStore.documentStore.canUpload} multiple={true} noClick={true}>
                                {({getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, open}) => (
                                <div {...getRootProps()} className={cx('dropzone', isDragActive && 'active', isDragAccept && 'accept', isDragReject && 'reject')}>
                                    <input {...getInputProps()} />
                                    
                                    <RiskHeader risk={risk} lifecycle={lifecycle}
                                        onSubmit={() => lifecycleStore.onSubmit() }
                                        onMonitor={() => reviewStore.onMonitor() }
                                        onClose={() => lifecycleStore.onClose() }
                                        onTransfer={() => transferStore.onTransfer() }
                                        onDelete={() => lifecycleStore.onDelete() }
                                        onShare={() => lifecycleStore.onShare() }
                                        onReview={() => reviewStore.onReview() }
                                        canUpload={() => documentStore.canUpload}
                                        onUpload={open}
                                        onNavigateBack={() => this.props.routing.push('/teams/risks/browse')}
                                        />

                                    <RiskStatusProgress risk={risk} lifecycle={lifecycle} />

                                    <RiskViewTabList risk={risk} lifecycle={lifecycle} onUpload={open} />

                                    <RiskReviewPanel onReviewSuccess={this._onReviewSuccess} />

                                    <RiskTransferPanel onTransferSuccess={this._onTransferSuccess} />
                                </div>
                                )}
                            </Dropzone>
                            
                        </>
                    ) }
                </>
            );
        }

        _onShareRisk() {

        }

        _onTransferSuccess = (lifecycle) => {
            const { riskStore, match } = this.props;

            riskStore.loadRisk(match.params.id).then((result) => {

            });
        };

        _onTransfer = () => {
            const { transferStore, viewStore } = this.props.riskStore;

            transferStore.show({ 
                review: viewStore.risk.review, 
                risk: viewStore.risk, 
                allowReview: true,
                allowSubmit: true,
                clearData: true
            });
        };

        _onReviewSuccess = (review) => {
            const { riskStore, match } = this.props;

            riskStore.viewStore.loadRisk(match.params.id).then((result) => {

            });
        };

        _onReview(monitor) {
            const { reviewStore, viewStore } = this.props.riskStore;
            reviewStore.show({ 
                review: viewStore.risk.review, 
                risk: viewStore.risk, 
                monitor,
                clearData: true 
            });
        }

        // _isPageLoading() {
        //     const { riskStore } = this.props;
        //     const { selectedRisk, selectedLifecycle } = riskStore;
            
        //     const loading = riskStore.loading || !selectedRisk  || !selectedLifecycle;

        //     return loading;
        // }

        // _getPageSubTitle() {
        //     const { selectedRisk, selectedLifecycle, loading } = this.props.riskStore;

        //     if(loading || !selectedRisk || !selectedLifecycle) {
        //         return 'Loading...';
        //     }

        //     return selectedRisk.title;
        // }

        // _onTitleClick() {
        //     const { routing } = this.props;
        //     routing.push(`/risks/browse`);
        // }
    }
)));
