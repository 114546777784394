import React from "react";
import { observer } from "mobx-react";
import { Route, Switch } from "react-router";

import { EntitySummaryTab } from "@modules/entities/containers/tabs/EntitySummaryTab";
import { EntityAssetsTab } from "@modules/entities/containers/tabs/EntityAssetsTab";
import { EntityDocumentsTab } from "@modules/entities/containers/tabs/EntityDocumentsTab";
import { EntityWorkItemsTab } from "@modules/entities/containers/tabs/EntityWorkItemsTab";
import { EntityAssessmentsTab } from "@modules/entities/containers/tabs/EntityAssessmentsTab";
import { EntityComplianceTab } from "@modules/entities/containers/tabs/EntityComplianceTab";

export const EntityTabList = observer(({ entity, onUpload }) => {
    return (
        <Switch>
            <Route
                exact
                path={`/entities/browse/:id/documents`}
                component={() => <EntityDocumentsTab entity={entity} onUpload={onUpload} />}
            />
            <Route
                exact
                path={`/entities/browse/:id/work-items`}
                component={() => <EntityWorkItemsTab entity={entity} />}
            />
            <Route
                exact
                path={`/entities/browse/:id/assessments`}
                component={() => <EntityAssessmentsTab entity={entity} />}
            />
            <Route
                exact
                path={`/entities/browse/:id/compliance`}
                component={() => <EntityComplianceTab entity={entity} />}
            />
            <Route exact path={`/entities/browse/:id/assets`} component={() => <EntityAssetsTab entity={entity} />} />
            <Route exact path={`/entities/browse/:id`} component={() => <EntitySummaryTab entity={entity} />} />
        </Switch>
    );
});
