import React, { useEffect, useCallback } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { useStores } from "@hooks/useStores";

import { JsonQueryString } from "@modules/utils/JsonQueryString";
import { AppPage } from "@modules/layout/containers/AppPage";
import { ObservablePagination } from "@modules/base/components/ObservablePagination";

import { RiskGridView } from "@modules/risks/components/core/RiskGridView";
import { RiskSideNav } from "@modules/risks/components/core/RiskSideNav";
import { RiskCommandBar } from "@modules/risks/components/core/RiskCommandBar";

import { RiskNewPanel } from "@modules/risks/containers/panels/RiskNewPanel";

export const RiskBrowsePage = withRouter(
    observer(({ match }) => {
        const { riskStore, pageContext, routing } = useStores();
        const { browseStore, typeStore, registryStore, editStore } = riskStore;
        const { loading, error, breadcrumb, keywords, pagination, risks, query } = browseStore;

        useEffect(() => {
            pageContext.updateQueryStringParams(routing.location.search);
            browseStore.loadRisks({
                page: pageContext.queryStringParams.p || 1,
                query: JsonQueryString.decode(pageContext.queryStringParams.query),
            });
        }, [match, routing, pageContext]);

        const onNewRisk = useCallback(() => {
            routing.push("/risks/new");
        }, []);

        return (
            <AppPage
                error={error}
                breadcrumb={breadcrumb}
                loading={loading}
                hasSidebar={false}
                className="risks list has--page-nav"
            >
                <RiskSideNav
                    registries={registryStore.registries}
                    onLinkClick={(item) => routing.push(item.url)}
                    onNewRisk={onNewRisk}
                />

                <>
                    <RiskCommandBar
                        onNewRisk={onNewRisk}
                        filterQuery={query}
                        filterContext={{
                            processing: loading,
                            resultCount: pagination.totalItemCount,
                        }}
                        onFilterChange={(q, f) => {
                            routing.push(JsonQueryString.build("/risks/browse", q));
                        }}
                        onExport={() => {
                            riskStore.browseStore.exportRisks();
                        }}
                    />

                    <RiskGridView
                        risks={risks}
                        loading={loading}
                        query={query}
                        onRiskClick={(risk) => routing.push(`/risks/browse/${risk.id}`)}
                    />

                    <ObservablePagination
                        {...pagination}
                        onPageChange={(index) => {
                            routing.push(JsonQueryString.build("/risks/browse", query, index + 1));
                        }}
                    />

                    <RiskNewPanel saving={riskStore.lifecycleStore.saving} />
                </>
            </AppPage>
        );
    })
);
