import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { PanelType } from "office-ui-fabric-react/lib/Panel";

import { ActionButton } from "@ui/elements/Button";

import { ObservablePanel } from "@modules/base/components/ObservablePanel";
import { ActionPanel } from "@modules/base/components/ActionPanel";

import { StateFlowMockPreview } from "@modules/stateflows/components/preview/StateFlowMockPreview";

const rootClassName = cx(
    "cygraph-StateFlowPreviewPanel-root",
    css`
        & .ms-Panel-content {
            height: 100%;
            overflow: hidden;
            background-color: var(--body-background);
            padding-top: 30px;
        }
    `
);

export const StateFlowPreviewPanel = observer(({ store }) => {
    const { visible, manifest, mock, subState } = store;

    const onDismiss = useCallback(() => {
        store.resolve(false);
    }, [store]);

    return (
        <ObservablePanel
            hidden={!visible}
            className={rootClassName}
            onRenderNavigation={() => <div style={{ padding: "13px 24px" }}>Workflow Preview - (Current State - {subState})</div>}
            panelType={PanelType.large}
            onClosePanel={onDismiss}
            onLightDismissClick={onDismiss}
            onRenderFooter={() => (
                <ActionPanel>
                    <ActionButton onClick={onDismiss} text="Close" />
                </ActionPanel>
            )}
        >
            {manifest && mock && <StateFlowMockPreview store={store} />}
        </ObservablePanel>
    );
});
