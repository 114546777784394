import React from "react";
import { observer } from "mobx-react";

import { SearchBox } from "@ui/elements/SearchBox";
import { CommandBar } from "@ui/elements/CommandBar";
import { BoxPanel } from "@modules/base/components/BoxPanel";

export const IdentityGroupCommandBar = observer(({ keywords, onSearchChange, onAddGroup }) => {
    const items = [
        {
            key: "addIdentityGroup",
            text: "Add Group",
            iconProps: { iconName: "Add" },
            onClick: () => onAddGroup({}),
            disabled: !onAddGroup,
        },
    ];
    const farItems = [
        {
            key: "search",
            text: "Search",
            onRender: () => {
                return (
                    <SearchBox
                        value={keywords}
                        placeholder="Search by name"
                        styles={{ root: { height: "auto", minWidth: "270px" } }}
                        onSearch={onSearchChange}
                        onClear={() => {
                            onSearchChange(null);
                        }}
                    />
                );
            },
        },
    ];

    return (
        <BoxPanel shadow>
            <CommandBar styles={{ root: { paddingRight: "0px" } }} items={items} farItems={farItems} />
        </BoxPanel>
    );
});
