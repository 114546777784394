import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import Moment from "react-moment";

import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { MessageBar } from "office-ui-fabric-react/lib/MessageBar";
import { Icon } from "office-ui-fabric-react/lib/Icon";

import { ActivitiesFeedStore } from "./ActivitiesFeedStore";
import { BoxPanel } from "../components/BoxPanel";
import RichTextViewer from "../../editors/components/RichTextViewer";
import { ContinuationButton } from "../continuationButton/ContinuationButton";

export const ActivitiesFeed = inject("rootStore")(
    withRouter(
        observer(
            class ActivitiesFeed extends Component {
                constructor(props) {
                    super(props);
                    const activitiesFeedStore = new ActivitiesFeedStore(this.props.rootStore);
                    activitiesFeedStore.setSelected(props.feedType, props.entityId);
                    this.state = {
                        activitiesFeedStore,
                    };
                }

                componentWillReceiveProps(nextProps) {
                    const { entityId, feedType } = nextProps;
                    const { activitiesFeedStore } = this.state;
                    if (entityId && feedType) activitiesFeedStore.setSelected(feedType, entityId);
                }

                loadMoreActivitites = () => {
                    const { activitiesFeedStore } = this.state;
                    activitiesFeedStore.loadMoreActivities();
                };

                getIconType(activityType) {
                    switch (activityType) {
                        case "Analytics":
                            return "AnalyticsView";
                        case "Status":
                            return "Switch";
                        case "Notification":
                            return "Message";
                        case "Error":
                            return "Error";
                        case "Security":
                            return "SecurityGroup";
                        case "Delete":
                            return "Delete";
                        default:
                        case "Property":
                            return "PageArrowRight";
                    }
                }

                get columns() {
                    return [
                        {
                            key: "summary",
                            name: "Activity",
                            fieldName: "summary",
                            minWidth: 150,
                            isRowHeader: true,
                            isResizable: true,
                            data: "string",
                            onRender: (item) => {
                                return (
                                    <Stack horizontal verticalAlign="middle" tokens={{ childrenGap: 40 }}>
                                        <Icon iconName={this.getIconType(item.metadata.type)} />
                                        <RichTextViewer className="description" value={item.summary.value ? item.summary.value : null} />
                                    </Stack>
                                );
                            },
                            isPadded: true,
                        },
                        {
                            key: "user",
                            name: "User",
                            fieldName: "user",
                            minWidth: 100,
                            maxWidth: 130,
                            isResizable: false,
                            data: "string",
                            onRender: (item) => {
                                return <span>{item.actor.value}</span>;
                            },
                            isPadded: false,
                        },
                        {
                            key: "date",
                            name: "Date",
                            fieldName: "date",
                            minWidth: 150,
                            maxWidth: 180,
                            isResizable: false,
                            data: "string",
                            onRender: (item) => {
                                return <Moment utc date={item.metadata.timestamp} fromNow />;
                            },
                            isPadded: false,
                        },
                    ];
                }

                render() {
                    const { activitiesFeedStore } = this.state;
                    return (
                        <>
                            <BoxPanel shadow dataTest="ActivitiesBox">
                                <ShimmeredDetailsList
                                    items={activitiesFeedStore.activities}
                                    compact={false}
                                    columns={this.columns}
                                    setKey="multiple"
                                    isHeaderVisible={true}
                                    selectionPreservedOnEmptyClick={true}
                                    checkboxVisibility={2}
                                    ariaLabelForSelectionColumn="Toggle selection"
                                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                    checkButtonAriaLabel="Row checkbox"
                                    ariaLabelForShimmer="Activities are being fetched"
                                    ariaLabelForGrid="ACtivities list"
                                />

                                {activitiesFeedStore.continuationToken && <ContinuationButton onButtonClick={this.loadMoreActivitites} />}

                                {activitiesFeedStore.activities.length === 0 && !activitiesFeedStore.loading && <MessageBar>No activities found</MessageBar>}

                                {activitiesFeedStore.loading && <MessageBar>Loading activities</MessageBar>}
                            </BoxPanel>
                        </>
                    );
                }
            }
        )
    )
);
