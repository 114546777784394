import { observable, flow, computed, action } from "mobx";
import { v4 as uuidv4 } from 'uuid';

import { PageService } from '../../../api/pages';
import { PageInstanceStore } from "./PageInstanceStore";
import { PagePermissionStore } from "./PagePermissionStore";
import { PageSelectionStore } from "./PageSelectionStore";

export class PageLifecycleStore {

    public pageService: PageService;
    public parentStore: PageInstanceStore;
    public selectionStore: PageSelectionStore;
    public permissionStore: PagePermissionStore;

    @observable public saving: boolean = false;
    @observable public snapshot: any;
    @observable public mode: string = 'view';

    constructor(parentStore: PageInstanceStore) {
        this.parentStore = parentStore;
        this.pageService = parentStore.pageService;
        this.selectionStore = parentStore.selectionStore;
        this.permissionStore = parentStore.permissionStore;
    }

    @action 
    public reset() {
        this.mode = 'view';
        this.snapshot = null;
    }

    @action 
    public setMode(mode: string) {
        this.mode = mode;
    }

    @computed
    public get isEditMode() {
        return this.mode === 'edit';
    }
    
    @computed
    public get canEdit() {
        const page = this.selectionStore.page;

        if(!page) {
            return false;
        }

        return this.mode !== 'edit' && this.permissionStore.canEdit;
    }

    @action 
    public onEdit() {
        const page = this.selectionStore.page;
        this.snapshot = JSON.stringify(page);
        this.setMode('edit');
    }

    @computed
    public get canCancel() {
        const page = this.selectionStore.page;

        if(!page) {
            return false;
        }

        return this.mode === 'edit' && !this.saving && this.permissionStore.canEdit;
    }

    @action 
    public onCancel() {
        var snapshot = JSON.parse(this.snapshot);
        this.selectionStore.page = snapshot;
        this.snapshot = null;
        this.setMode('view');
    }

    @computed
    public get canSave() {
        const page = this.selectionStore.page;

        if(!page) {
            return false;
        }

        return this.mode === 'edit' && !this.saving && this.permissionStore.canSave;
    }

    @computed
    public get canPublish() {
        const page = this.selectionStore.page;

        if(!page) {
            return false;
        }

        return (this.mode === 'edit' || page.currentVersion !== page.publishedVersion) && !this.saving && this.permissionStore.canSave;
    }

    public onSave = flow(function*(mode) {
        this.saving = true;

        try {
            // if (title) {
            //     this.selectionStore.page.title = title;
            // }
            const svgRefs = {};
            //save reference to the SVG
            this.selectionStore.page.content.sections
                .forEach(S => S.zones
                    .forEach(Z => Z.parts
                        .forEach(P =>  {
                            if (P.type == "pages:core:chart-viewer") svgRefs[P.id] = P.svgRef
                        })));
            this.selectionStore.page = yield this.pageService.updatePage({
                mode: mode || 'Build',
                notes: "Initial update",
                entity: this.selectionStore.page
            });
            //restore reference to the SVG
            this.selectionStore.page.content.sections
                .forEach(S => S.zones
                    .forEach(Z => Z.parts
                        .forEach(P =>  {
                            if (P.type == "pages:core:chart-viewer") P.svgRef = svgRefs[P.id]
                        })));
            this.snapshot = null;
            this.setMode('view');
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.saving = false;
        }
    });

    @action
    public getRegistration(part) {
        return this.parentStore.parentStore.registryStore.getRegistration(part);
    }

    @action
    public onAddSection(index) {
        console.log(index);
        const page = this.selectionStore.page;

        if(!page || !page.content.sections) {
            return false;
        }

        const section = { id: uuidv4(), theme: 'none', layout: 'one-column', zones: [] };
        page.content.sections.splice(index, 0, section);
        page.content.sections = [...page.content.sections];

        return true;
    }

    @action
    public onEditSection(section) {
        const page = this.selectionStore.page;

        if(!section || !page || !page.content.sections) {
            return false;
        }

        var index = page.content.sections.indexOf(section);
        if(index === -1) {
            return false;
        }

        this.parentStore.sectionEditStore.show({ 
            section, page
        }).then((section) => {
            if(section) {
                const layoutStore = this.parentStore.parentStore.layoutStore;
                layoutStore.rearrange(section);

                page.content.sections.splice(index, 1, section);
                page.content.sections = [...page.content.sections];
            }
        });

        return true;
    }

    @action
    public onMoveSection(move) {
        const page = this.selectionStore.page;

        if(!move || !page || !page.content.sections) {
            return false;
        }

        const updated = this.reorder(page.content.sections, move.sourceIndex, move.destinationIndex);
        page.content.sections = [...updated];
    }

    @action
    public onDuplicateSection(section) {
        const page = this.selectionStore.page;

        if(!section || !page || !page.content.sections) {
            return false;
        }

        var index = page.content.sections.indexOf(section);
        if(index === -1) {
            return false;
        }

        /// TODO need guid updates .. get a client side lib?
        var copy = JSON.parse(JSON.stringify(page.content.sections[index]));

        copy.id = uuidv4();
        copy.theme = copy.theme || 'none';
        copy.zones.forEach(zone => {
            zone.id = uuidv4();
            zone.theme = zone.theme || 'none';
            zone.parts.forEach(part => {
                part.id = uuidv4();
            });
        });

        page.content.sections.splice(index, 0, copy);
        page.content.sections = [...page.content.sections];

        return true;
    }

    @action
    public onDeleteSection(section) {
        const page = this.selectionStore.page;

        if(!section || !page || !page.content.sections) {
            return false;
        }

        var index = page.content.sections.indexOf(section);
        if(index === -1) {
            return false;
        }

        page.content.sections.splice(index, 1);
        page.content.sections = [...page.content.sections];
        
        return true;
    }

    @action
    public onAddPart(options) {
        const page = this.selectionStore.page;
        
        if(!options || !page || !page.content.sections) {
            return false;
        }

        this.parentStore.partPickerStore.show(options).then((result: any) => {
            if(result) {
                const section = page.content.sections.find(s => s.id === result.sectionId);
                if(section) {
                    const part = {
                        id: uuidv4(),
                        title: result.registration.name,
                        type: result.registration.type,
                        version: result.registration.version,
                        chrome: result.registration.chome || 'none',
                        theme: result.registration.theme || 'none',
                        properties: {},
                    };

                    let zone = section.zones.find(z => z.name == result.zoneName);
                    if(!zone) {
                        zone = { id: uuidv4(), name: result.zoneName, theme: 'none', parts: [part] };
                        section.zones = [...section.zones, zone];
                    } else {
                        zone.parts = [...zone.parts, part];
                        section.zones = [...section.zones];
                    }
                }
            }
        });

        return true;
    }

    @action
    public onDuplicatePart(section, zone, part) {
        const page = this.selectionStore.page;

        if(!section || !zone || !part || !page || !page.content.sections) {
            return false;
        }

        const index = zone.parts.findIndex(p => p.id === part.id);

        if(index === -1) {
            return false;
        }

        var copy = JSON.parse(JSON.stringify(part));
        copy.id = uuidv4();
        copy.title = `${copy.title} (copy)`;

        zone.parts.splice(index, 0, copy);
        zone.parts = [...zone.parts];
        section.zones = [...section.zones];
        
        return true;
    }

    @action
    public onDeletePart(section, zone, part) {
        const page = this.selectionStore.page;

        if(!section || !zone || !part || !page || !page.content.sections) {
            return false;
        }

        zone.parts = zone.parts.filter(p => p.id !== part.id);
        section.zones = [...section.zones];
        
        return true;
    }

    @action
    public onMovePart(move) {
        const page = this.selectionStore.page;
        
        if(!move || !page || !page.content.sections) {
            return false;
        }

        const sourceSection = page.content.sections.find(s => s.id === move.sourceSectionId);
        const sourceZone = sourceSection.zones.find(z => z.name === move.sourceZoneName);
        const [movedPart] = sourceZone.parts.splice(move.sourceIndex, 1);
        sourceZone.parts = [...sourceZone.parts];

        const destinationSection = page.content.sections.find(s => s.id === move.destinationSectionId);
        const destinationZone = destinationSection.zones.find(z => z.name === move.destinationZoneName);
        if(destinationZone) {
            destinationZone.parts.splice(move.destinationIndex, 0, movedPart);
            destinationZone.parts = [...destinationZone.parts];
        } else {
            const zone = { id: uuidv4(), name: move.destinationZoneName, theme: 'none', parts: [movedPart] };
            destinationSection.zones = [...destinationSection.zones, zone];
        }
    }

    @action
    public onEditPart(section, zone, part) {
        const page = this.selectionStore.page;

        if(!section || !zone || !part || !page || !page.content.sections) {
            return false;
        }

        this.parentStore.partEditStore.show({ 
            page, section, zone, part
        }).then((part: any) => {
            if(part && part.id && part.type) {
                const index = zone.parts.findIndex(p => p.id === part.id);
                if(index !== -1) {
                    zone.parts.splice(index, 1, part);
                    zone.parts = [...zone.parts];
                    page.content.sections = [...page.content.sections];
                }
            }
        });

        return true;
    }

    private reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
    };
}
