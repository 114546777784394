import React from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

export const ObservableBadge = observer(({ children, className }) => {
    return (
        <div
            className={cx(
                className,
                css`
                    background: var(--nav-background);
                    color: var(--nav-color);
                    display: inline-block;
                    padding: 2px 12px;
                    border-radius: 2px;
                    font-size: var(--font-size-xxs);
                    margin: auto 0;
                    height: 32px;
                    line-height: 26px;
                `
            )}
        >
            {children}
        </div>
    );
});
