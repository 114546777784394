import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks/useStores';

import { ObservableSeparator } from '../../base/components/ObservableSeparator';
import { getCoreDataSource } from '../../datasources/utils/getCoreDataSource';
import { TextField } from '@ui/elements/TextField';
import { Dropdown } from '@ui/elements/Dropdown';
import { Toggle } from '@ui/elements/Toggle';

export const TaskListPartEditor = observer((props) => {
    const { formData, dataSources } = props;
    const { dataSourceStore } = useStores();
    const taskDataSource = dataSources.filter(DS => DS.properties.mainSource === 'dataSource:tasks');
    const coreDataSource = getCoreDataSource(formData.properties.dataSourceId, dataSources, dataSourceStore);
    
    return (
        <div>
            <r-grid columns="3">
                <r-cell span="3">
                    <ObservableSeparator>Task List Setup</ObservableSeparator>
                </r-cell>
                <r-cell span="2">
                    <Dropdown
                        required
                        label="Data source"
                        selectedKey={formData.properties.dataSourceId}
                        onChange={(ev, op) => {
                            if(op) {
                                formData.properties.dataSourceId = op.key;
                            }
                        }}
                        options={taskDataSource.map(DSource => {
                            return {
                                key: DSource.id,
                                text: DSource.title,
                            }
                        })}
                    />
                </r-cell>
                <r-cell span="1">
                    <Dropdown
                        required
                        multiSelect
                        label="Displayed Fields"
                        selectedKeys={formData.properties.selectedFields}
                        onChange={(event, item) => {
                            if (item) {
                                if (!formData.properties.selectedFields) formData.properties.selectedFields = [];
                                formData.properties.selectedFields = item.selected ? [...formData.properties.selectedFields, item.key] : formData.properties.selectedFields.filter(key => key !== item.key);
                            }
                        }}
                        options={coreDataSource && coreDataSource.fields ? coreDataSource.fields.map(F =>{
                            return {
                                text: F.title,
                                key: F.id,
                            }
                        }).sort(F => (F.text < F.text)) : []}
                    />
                </r-cell>
                <r-cell span="1">
                    <TextField
                        label="Displayed result"
                        type="number"
                        value={formData.properties.limitResult || 10}
                        onChange={(ev, value) => {
                            formData.properties.limitResult = value;
                        }}
                    />
                </r-cell>
                <r-cell span="1">
                    <Toggle
                        label="Show export to CSV"
                        checked={formData.properties.showCSV}
                        onChange={(ev, checked) => {
                            formData.properties.showCSV = checked;
                        }}
                    />
                </r-cell>
        </r-grid>
    </div>
    );
})
