import React, { Component } from "react";
import { observer } from "mobx-react";

import { Icon } from "office-ui-fabric-react/lib/Icon";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { Spinner } from "office-ui-fabric-react/lib/Spinner";
import { MessageBar } from "office-ui-fabric-react/lib/MessageBar";
import { ObservablePanel } from "@baseComponents/ObservablePanel";
import { AssessmentComment } from "./AssessmentComment";

export const AssessmentCommentPanel = observer(
    class AssessmentCommentPanel extends Component {
        constructor(props) {
            super(props);
            this.state = { comment: "" };
            this.containerRef = React.createRef();
            this.textFieldRef = React.createRef();
        }

        componentDidMount() {
            this._scrollToBottom();
        }

        render() {
            const { context, hidden, onClosePanel } = this.props;

            return (
                <ObservablePanel
                    hidden={hidden}
                    pageTitle={(context && context.title) || "Comments"}
                    pageType="Assessment Discussion"
                    onClosePanel={onClosePanel}
                    onOpened={() => this._focusOnTextField()}
                    onRenderFooter={() => this._renderFooter()}
                >
                    {context && this._renderCore()}
                </ObservablePanel>
            );
        }

        _renderFooter() {
            const { disabled } = this.props;

            return (
                <div className="add__comment__modal__footer__content">
                    <div className="form__group form__group--flex--row comment__input">
                        <TextField
                            componentRef={this.textFieldRef}
                            className="form__control form__control--block"
                            value={this.state.comment}
                            autoComplete="off"
                            onChange={(ev, value) => this.setState({ comment: value })}
                            onKeyPress={(ev) => {
                                if (ev.key === "Enter") {
                                    ev.preventDefault();
                                    this._onSendComment();
                                }
                            }}
                            disabled={disabled}
                            placeholder={disabled ? "Comments disabled" : "Enter comment"}
                        />
                        <button
                            className="btn btn--secondary btn--icon--only"
                            title="Post comment"
                            onClick={() => this._onSendComment()}
                        >
                            <Icon iconName="CommentAdd" />
                        </button>
                    </div>
                </div>
            );
        }

        _scrollToBottom() {
            const container = this.containerRef.current;
            if (container) {
                const scrollable = container.parentElement.parentElement;
                scrollable.scrollTop = scrollable.scrollHeight;
            }
        }

        _focusOnTextField() {
            const textField = this.textFieldRef.current;
            if (textField) {
                textField.focus();
            }
        }

        _onSendComment() {
            const { disabled, onCommentAdded } = this.props;

            if (!disabled && this.state.comment) {
                onCommentAdded({ content: this.state.comment }).then(() => {
                    this.setState({ comment: "" });
                    this._scrollToBottom();
                    this._focusOnTextField();
                });
            }
        }

        _renderCore() {
            const { context, loading, comments } = this.props;

            return (
                <>
                    <div className="comments" ref={this.containerRef}>
                        {context && !loading && !comments.length && (
                            <MessageBar>
                                There are no messages at the moment. You can share a message by typing it in the textbox
                                below and pressing enter.
                            </MessageBar>
                        )}
                        {comments.map((comment) => (
                            <AssessmentComment key={comment.id} comment={comment} />
                        ))}
                        {context && loading && !comments.length && <Spinner label="Loading comments..." />}
                    </div>
                </>
            );
        }
    }
);
