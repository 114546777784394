import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';

import { TooltipHost, TooltipDelay, DirectionalHint } from 'office-ui-fabric-react/lib/Tooltip';

const rootClassName = cx(
    'cygraph-ThresholdIndicator-root',
    css` 
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        margin: auto;
        border-radius: 50%;
        margin-top: 8px;

        & .wrapper {
            background: var(--border-color);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80px;
            height: 80px;
            border-radius: 50%;
        }

        & .threshold {
            background: var(--blue-6);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            box-shadow: -1px -0px 15px var(--blue-6);
            transition: height 3s, width 3s;

            &.zero {
                background: var(--body-background);
                box-shadow: none;
            }
            &.crossed {
                background: var(--red-6);
                box-shadow: none;
                box-shadow: -1px -0px 15px var(--red-6);
                transition: height 3s linear, width 3s linear;
            }
        }        
    `
);

export const ThresholdIndicator = observer(
    class ThresholdIndicator extends Component {

        render () {
            const { className, hideTooltip, threshold, current } = this.props;

            // const tooltipProps = {
            //     onRenderContent: () => (
            //       <div>d
            //         <span>{closedDate ? 'Was due on' : level} <Moment utc date={dueDate} format="DD/MM/YYYY" /></span> 
            //         { closedDate && (<span>, Closed <Moment utc date={closedDate} format="DD/MM/YYYY" /></span>) }
            //         <span> ({precision === 'd' ? diffInDays : diffInMonths}{precision})</span>
            //       </div>
            //     )
            // };

            const size = `${(current > threshold || current === 0 ? 100 : Math.max(current/threshold*100, 30))}%`;

            return (
                <div className={cx(rootClassName, className)}>
                    <div className="container">
                        <div className="wrapper">
                            <div className={cx('threshold', current === 0 && 'zero', current > threshold && 'crossed')}
                                style={{ width: size, height: size }}>
                                {current === 0 ? null : current}
                            </div>
                        </div>
                    </div>

                    {/* <TooltipHost
                        tooltipProps={tooltipProps}
                        calloutProps={{ hidden: !!hideTooltip }}
                        delay={TooltipDelay.medium}
                        directionalHint={DirectionalHint.bottomLeftEdge}>
                        <div className={cx('container', closedDate && 'completed', level.replace(' ', '-').toLowerCase(), precision)}>
                            {precision === 'd' ? diffInDays : diffInMonths}{precision}
                        </div>
                    </TooltipHost> */}
                </div>
            );
        }
    }
);