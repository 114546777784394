import React, { Component } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { Icon } from "office-ui-fabric-react/lib/Icon";

import { ModuleNames, getModuleConfig } from "../../../../configProvider";

const rootClassName = cx(
    "cygraph-RiskHeatmap-root",
    css`
        & th.title {
        }
        & th.legend {
            font-weight: normal;
            &.legend-x div {
                height: 20px;
            }
            &.legend-y div {
                width: 20px;
                transform: rotate(-90deg);
                -webkit-transform: rotate(-90deg);
                -moz-transform: rotate(-90deg);
                -ms-transform: rotate(-90deg);
                -o-transform: rotate(-90deg);
                filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
            }
        }

        & th.axis {
            font-weight: normal;
            background: var(--border-color);

            &.axis-x div {
                height: 20px;
            }
            &.axis-y div {
                width: 20px;
            }
        }

        & td.cell {
            width: 60px;
            height: 60px;
            margin-right: 2px;
            text-align: center;

            &.tiny {
                width: 25px;
                height: 25px;
                & .selected.marker {
                    font-size: 10px;
                    font-weight: 600;
                    width: 20px;
                }
            }

            &.mini {
                width: 40px;
                height: 40px;
            }

            &.medium {
                width: 100px;
                height: 100px;

                & .selected.marker {
                    font-size: 16px;
                    font-weight: 600;
                }
            }

            &.large {
                width: 120px;
                height: 120px;

                & .selected.marker {
                    font-size: 18px;
                    font-weight: 600;
                }
            }

            &:not(.disabled) {
                cursor: pointer;
            }

            &.disabled {
                opacity: 1;
            }

            &.Minor {
                background: var(--green-6);
                &:not(.disabled):hover {
                    background: var(--green-5);
                }
            }
            &.Moderate {
                background: var(--yellow-6);
                &:not(.disabled):hover {
                    background: var(--yellow-5);
                }
            }
            &.Major {
                background: var(--orange-6);
                &:not(.disabled):hover {
                    background: var(--orange-5);
                }
            }
            &.Critical {
                background: var(--red-6);
                &:not(.disabled):hover {
                    background: var(--red-5);
                }
            }
            & .selected {
                font-size: 20px;
                color: var(--white);

                &.marker {
                    font-size: 11px;
                    background: var(--white);
                    color: var(--text-color-dark);
                    padding: 3px 3px;
                    margin: 2px 0;
                    border-radius: 12px;
                    display: inline-block;
                    width: 45px;
                }
            }
        }
    `
);

export const RiskHeatmap = observer(
    class RiskHeatmap extends Component {
        render() {
            const { score, scores, title, disabled, onChange, size } = this.props;
            const riskModule = getModuleConfig(ModuleNames.Risks);
            const invert = !riskModule.invertAxis;

            //const { score, likelihood, impact, description } = this.props.score;
            const matrix = this._createMatrix(riskModule);
            // console.log(JSON.stringify(scores))
            return (
                <div className={rootClassName}>
                    <table>
                        <tbody>
                            {title && (
                                <tr>
                                    <th className="title" colSpan={matrix[0].length + 2}>
                                        {title}
                                    </th>
                                </tr>
                            )}
                            <tr>
                                <th className="legend legend-y" rowSpan={matrix.length + 1}>
                                    <div>{!invert ? "Impact" : "Likelihood"}</div>
                                </th>
                            </tr>
                            {matrix.map((row, yi) => (
                                <tr key={yi}>
                                    <th className="axis axis-y">
                                        <div>{invert ? row[0].likelihood : row[0].impact}</div>
                                    </th>
                                    {row.map((cell, xi) => (
                                        <td
                                            key={xi}
                                            className={cx("cell", cell.score, size, disabled && "disabled")}
                                            onClick={() => {
                                                if (onChange) {
                                                    onChange(cell);
                                                }
                                            }}
                                        >
                                            {scores &&
                                                scores.map((item, ii) => {
                                                    const score = item.score;
                                                    return score && score.likelihood == cell.likelihood && score.impact == cell.impact ? (
                                                        item.selected ? (
                                                            <Icon key={ii} className={cx("selected")} iconName="Completed" />
                                                        ) : (
                                                            <div key={ii} className={cx("selected marker", size)}>
                                                                {item.text}
                                                            </div>
                                                        )
                                                    ) : null;
                                                })}
                                            {score && score.likelihood == cell.likelihood && score.impact == cell.impact && (
                                                <Icon className={cx("selected")} iconName="Completed" />
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                            <tr>
                                <th colSpan="2">&nbsp;</th>
                                {matrix[0].map((cell, ci) => (
                                    <th key={ci} className="axis axis-x">
                                        <div>{invert ? cell.impact : cell.likelihood}</div>
                                    </th>
                                ))}
                            </tr>
                            <tr>
                                <th colSpan="2">&nbsp;</th>
                                <th className="legend legend-x" colSpan={matrix[0].length}>
                                    <div>{invert ? "Impact" : "Likelihood"}</div>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        }

        _getScore(cell) {
            const score = cell.impact * cell.likelihood;

            if (score >= 8 && cell.impact >= 3) {
                return "Critical";
            }
            if (score <= 2) {
                return "Minor";
            }
            if (score <= 4 && cell.impact <= 3) {
                return "Moderate";
            }
            return "Major";
        }

        _createMatrix(riskModule) {
            const maxImpact = riskModule.maxImpact || 4;
            const maxLikelihood = riskModule.maxLikelihood || 4;
            const invert = !riskModule.invertAxis;
            const scoreCompute = riskModule.score || this._getScore;

            const maxY = invert ? maxImpact : maxLikelihood;
            const maxX = invert ? maxLikelihood : maxImpact;
            const matrix = [];

            for (let y = maxY; y >= 1; y--) {
                const inner = [];
                for (let x = 1; x <= maxX; x++) {
                    const cell = {
                        likelihood: invert ? y : x,
                        impact: invert ? x : y,
                    };
                    cell.score = scoreCompute(cell);
                    inner.push(cell);
                }
                matrix.push(inner);
            }

            return matrix;
        }
    }
);
