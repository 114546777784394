import React, { Component } from "react";
import { observer } from "mobx-react";
import { cx } from "emotion";

import { MessageBar } from "office-ui-fabric-react/lib/MessageBar";

import { BoxPanel } from "@modules/base/components/BoxPanel";

import { ProductCardView } from "../core/ProductCardView";

export const CatalogueListView = observer(
    class CatalogueListView extends Component {
        render() {
            const { className, loading, products, onCardClick, isSimplifiedView, pickerMode } = this.props;

            if (!loading && !products.length) {
                return (
                    <BoxPanel shadow>
                        <MessageBar>There are no matching products or services.</MessageBar>
                    </BoxPanel>
                );
            }

            return (
                <div className={cx(className, "product-list")} data-test="product-list">
                    <r-grid columns-l={pickerMode ? 2 : 4} columns={pickerMode ? 2 : 2} columns-s="1">
                        {products.map((product) => (
                            <r-cell span="1" key={product.id}>
                                <ProductCardView product={product} onCardClick={onCardClick} isSimplifiedView={isSimplifiedView} />
                            </r-cell>
                        ))}
                    </r-grid>
                </div>
            );
        }
    }
);
