import React, { Component, Fragment } from "react";
import { observer, inject } from "mobx-react";

import { BoxPanel } from "../../../base/components/BoxPanel";
import { ObservableSeparator } from "../../../base/components/ObservableSeparator";

export const PatternOverviewTab = inject(
    "patternStore",
    "routing"
)(
    observer(
        class PatternOverviewTab extends Component {
            componentWillMount() {
                const { patternStore, routing } = this.props;
            }

            componentWillReceiveProps(nextProps) {
                const { patternStore, routing } = nextProps;
            }

            render() {
                const { patternStore, routing } = this.props;
                const { pattern, manifest } = patternStore.selectionStore;

                return (
                    <BoxPanel shadow padding>
                        <r-grid columns="3">
                            <r-cell span="3">
                                <ObservableSeparator className="row--s">Details</ObservableSeparator>
                            </r-cell>
                            <r-cell span="1"></r-cell>
                            <r-cell span="2">
                                <r-grid columns="3">
                                    <r-span></r-span>
                                </r-grid>
                            </r-cell>
                            <r-cell span="3">
                                <ObservableSeparator className="row--s">About</ObservableSeparator>
                            </r-cell>
                            <r-cell span="1"></r-cell>
                            <r-cell span="2"></r-cell>
                        </r-grid>
                    </BoxPanel>
                );
            }
        }
    )
);
