import React from "react";
import { observer } from "mobx-react";
import { useBoolean } from "@fluentui/react-hooks";
import { cx, css } from "emotion";

import { CombinedSearchItemView } from "@modules/home/components/CombinedSearchItemView";
import { CombinedSearchShimmer } from "@modules/home/components/CombinedSearchShimmer";

const rootClassName = cx(
    "cygraph-CombinedSearchListView-root",
    css`
        & .wrapper {
            padding: var(--spacing-s);
        }

        & .showMore {
            text-align: left;
            margin-top: 10px;

            & a {
                cursor: pointer;
            }
        }

        & .item {
            min-height: 40px;
            margin-bottom: var(--spacing-xs);

            & .description {
                color: var(--text-color-lightest);
            }
            & .role {
                color: var(--text-color-lightest);
                text-transform: lowercase;
            }
        }
    `
);

export const CombinedSearchListView = observer(({ className, loading, items, show, includeRole }) => {
    const [minimal, { toggle }] = useBoolean(true);

    if (loading) {
        return (
            <div className={cx(rootClassName, className)}>
                {Array.from({ length: show || 10 }).map((v, i) => (
                    <CombinedSearchShimmer className="item" key={i} includeRole={includeRole} />
                ))}
            </div>
        );
    }

    if (!loading && items.length === 0) {
        return <div className={cx(rootClassName, className)}>No recent activities found.</div>;
    }

    return (
        <div className={cx(rootClassName, className)}>
            {items.slice(0, Math.min(items.length, show || items.length)).map((item) => (
                <CombinedSearchItemView className="item" key={item.id} item={item} includeRole={includeRole} />
            ))}
            {!minimal && items.length > show && (
                <>
                    {items.slice(show - 1).map((item) => (
                        <CombinedSearchItemView className="item" key={item.id} item={item} includeRole={includeRole} />
                    ))}
                </>
            )}
            {!loading && show && items.length > show && (
                <div className="showMore">
                    <a onClick={toggle}>{minimal ? "Show more" : "Show less"}</a>
                </div>
            )}
        </div>
    );
});
