import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";
import Moment from "react-moment";

import { Label } from "@ui/elements/Label";
import { Text } from "@ui/elements/Text";
import { FontIcon } from "@ui/elements/Icon";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { FieldSetView } from "@modules/base/components/FieldSetView";
import { AdaptiveField } from "@modules/base/components/AdaptiveField";

import { WellKnownSectionIds } from "@modules/properties/stores/PropertyContants";
import { PropertyField } from "@modules/properties/components/core/PropertyField";

export const AssetSummaryTab = observer(({ asset }) => {
    const { assetStore } = useStores();
    const { settingsStore } = assetStore;
    const { properties, sections } = settingsStore;

    useEffect(() => {
        settingsStore.loadPropertySections({ page: 1, query: {} });
        settingsStore.loadProperties({ page: 1, query: { activeOnly: true } });
    }, [asset]);

    const viewable = properties.filter((p) => !p.hidden && p.section.id !== WellKnownSectionIds.AssetBuiltIn);
    const groupedBySections = viewable.reduce((acc, curr) => {
        if (!acc[curr.section.id]) acc[curr.section.id] = [];
        acc[curr.section.id].push(curr);
        return acc;
    }, {});

    const filteredSections = sections.filter((s) => !!groupedBySections[s.id]);

    return (
        <BoxPanel shadow padding>
            {asset && (
                <>
                    <FieldSetView title="Key Information" description="Basic information about this asset.">
                        <r-grid columns="2">
                            <r-cell span-l="2" span="2">
                                <div className="row--xs">
                                    <Label>Summary</Label>
                                    <Text block>{asset.summary || "Not set"}</Text>
                                </div>
                            </r-cell>
                            <r-cell span-l="1" span="1">
                                <div className="row--xs">
                                    <Label>Unique Id</Label>
                                    <Text block nowrap>
                                        {asset.code || "Not set"}
                                    </Text>
                                </div>
                            </r-cell>
                            <r-cell span-l="1" span="1">
                                <div className="row--xs">
                                    <Label>Alias</Label>
                                    <Text block nowrap>
                                        {asset.alias || "Not set"}
                                    </Text>
                                </div>
                            </r-cell>
                            <r-cell span-l="1" span="1">
                                <div className="row--xs">
                                    <Label>URL</Label>
                                    <Text block nowrap>
                                        {asset.detailsUrl ? (
                                            <Text block nowrap>
                                                <a target="_blank" href={asset.detailsUrl}>
                                                    Link <FontIcon iconName="NavigateExternalInline" />
                                                </a>
                                            </Text>
                                        ) : (
                                            "Not set"
                                        )}
                                    </Text>
                                </div>
                            </r-cell>
                            <r-cell span-l="1" span="1">
                                <div className="row--xs">
                                    <Label>Status</Label>
                                    <Text block nowrap>
                                        {asset.state}
                                    </Text>
                                </div>
                            </r-cell>
                            <r-cell span-l="1" span="1">
                                <div className="row--xs">
                                    <Label>Portfolio</Label>
                                    <Text block nowrap>
                                        {asset.portfolio && asset.portfolio.name}
                                    </Text>
                                </div>
                            </r-cell>
                            <r-cell span-l="1" span="1">
                                <div className="row--xs">
                                    <Label>Type</Label>
                                    <Text block nowrap>
                                        {asset.pattern && asset.pattern.name}
                                    </Text>
                                </div>
                            </r-cell>
                            <r-cell span-l="1" span="1">
                                <div className="row--xs">
                                    <Label>Operational Since</Label>
                                    <Text block nowrap>
                                        {asset.operationalSince ? (
                                            <Moment utc date={asset.operationalSince} format="ddd Do MMM YYYY" />
                                        ) : (
                                            "Not set"
                                        )}
                                    </Text>
                                </div>
                            </r-cell>
                        </r-grid>
                    </FieldSetView>
                    <FieldSetView title="Key Contacts" description="Key contacts for the asset.">
                        <r-grid columns="2" class="no--margin">
                            <r-cell>
                                <div className="row--xs">
                                    <AdaptiveField
                                        type="Principal"
                                        mode="view"
                                        label="Business Contact"
                                        value={asset.businessContact}
                                    />
                                </div>
                            </r-cell>
                            <r-cell>
                                <div className="row--xs">
                                    <AdaptiveField
                                        type="Principal"
                                        mode="view"
                                        label="Technical Contact"
                                        value={asset.technicalContact}
                                    />
                                </div>
                            </r-cell>
                        </r-grid>
                    </FieldSetView>
                    {filteredSections.map((section) => (
                        <FieldSetView key={section.id} title={section.name}>
                            <r-grid columns="2">
                                {groupedBySections[section.id].map((property) => (
                                    <r-cell
                                        key={property.id}
                                        span-l={
                                            property.type === "Text" && property.schema && property.schema.richText
                                                ? 2
                                                : 1
                                        }
                                        span="2"
                                        className="row--xs"
                                    >
                                        <PropertyField property={property} data={asset} readOnly />
                                    </r-cell>
                                ))}
                            </r-grid>
                        </FieldSetView>
                    ))}
                </>
            )}
        </BoxPanel>
    );
});

// export const AssetSummaryTab2 = inject(
//     "assetStore",
//     "propertyStore",
//     "routing"
// )(
//     observer(
//         class AssetSummaryTab extends Component {
//             componentWillMount() {
//                 this._onLoadData(this.props);
//             }

//             /*         componentWillReceiveProps(nextProps) {
//             const { assetStore, routing } = nextProps;
//             const { settingsStore } = assetStore;

//             const params = getQueryStringParams(routing.location.search);
//             settingsStore.loadProperties({ page: params["p"] || 1, query: { keywords: params["k"] } });
//         } */

//             render() {
//                 const { viewerStore, settingsStore } = this.props.assetStore;
//                 const { properties, sections } = settingsStore;

//                 const viewable = properties.filter(
//                     (p) => !p.hidden && p.section.id !== WellKnownSectionIds.AssetBuiltIn
//                 );
//                 const groupedBySections = viewable.reduce((acc, curr) => {
//                     if (!acc[curr.section.id]) acc[curr.section.id] = [];
//                     acc[curr.section.id].push(curr);
//                     return acc;
//                 }, {});

//                 const filteredSections = sections.filter((s) => !!groupedBySections[s.id]);

//                 return (
//                     <BoxPanel shadow padding>
//                         {viewerStore.asset && (
//                             <>
//                                 <FieldSetView title="Key Information" description="Basic information about this asset.">
//                                     <r-grid columns="2">
//                                         <r-cell span-l="2" span="2">
//                                             <div className="row--xs">
//                                                 <Label>Summary</Label>
//                                                 <Text block>{viewerStore.asset.summary || "Not set"}</Text>
//                                             </div>
//                                         </r-cell>
//                                         <r-cell span-l="1" span="1">
//                                             <div className="row--xs">
//                                                 <Label>Unique Id</Label>
//                                                 <Text block nowrap>
//                                                     {viewerStore.asset.code || "Not set"}
//                                                 </Text>
//                                             </div>
//                                         </r-cell>
//                                         <r-cell span-l="1" span="1">
//                                             <div className="row--xs">
//                                                 <Label>Alias</Label>
//                                                 <Text block nowrap>
//                                                     {viewerStore.asset.alias || "Not set"}
//                                                 </Text>
//                                             </div>
//                                         </r-cell>
//                                         <r-cell span-l="1" span="1">
//                                             <div className="row--xs">
//                                                 <Label>URL</Label>
//                                                 <Text block nowrap>
//                                                     {viewerStore.asset.detailsUrl ? (
//                                                         <Text block nowrap>
//                                                             <a target="_blank" href={viewerStore.asset.detailsUrl}>
//                                                                 Link <FontIcon iconName="NavigateExternalInline" />
//                                                             </a>
//                                                         </Text>
//                                                     ) : (
//                                                         "Not set"
//                                                     )}
//                                                 </Text>
//                                             </div>
//                                         </r-cell>
//                                         <r-cell span-l="1" span="1">
//                                             <div className="row--xs">
//                                                 <Label>Status</Label>
//                                                 <Text block nowrap>
//                                                     {viewerStore.asset.state}
//                                                 </Text>
//                                             </div>
//                                         </r-cell>
//                                         <r-cell span-l="1" span="1">
//                                             <div className="row--xs">
//                                                 <Label>Portfolio</Label>
//                                                 <Text block nowrap>
//                                                     {viewerStore.asset.portfolio && viewerStore.asset.portfolio.name}
//                                                 </Text>
//                                             </div>
//                                         </r-cell>
//                                         <r-cell span-l="1" span="1">
//                                             <div className="row--xs">
//                                                 <Label>Type</Label>
//                                                 <Text block nowrap>
//                                                     {viewerStore.asset.pattern && viewerStore.asset.pattern.name}
//                                                 </Text>
//                                             </div>
//                                         </r-cell>
//                                         <r-cell span-l="1" span="1">
//                                             <div className="row--xs">
//                                                 <Label>Operational Since</Label>
//                                                 <Text block nowrap>
//                                                     {viewerStore.asset.operationalSince ? (
//                                                         <Moment
//                                                             utc
//                                                             date={viewerStore.asset.operationalSince}
//                                                             format="ddd Do MMM YYYY"
//                                                         />
//                                                     ) : (
//                                                         "Not set"
//                                                     )}
//                                                 </Text>
//                                             </div>
//                                         </r-cell>
//                                     </r-grid>
//                                 </FieldSetView>
//                                 <FieldSetView title="Key Contacts" description="Key contacts for the asset.">
//                                     <r-grid columns="2" class="no--margin">
//                                         <r-cell>
//                                             <div className="row--xs">
//                                                 <AdaptiveField
//                                                     type="Principal"
//                                                     mode="view"
//                                                     label="Business Contact"
//                                                     value={viewerStore.asset.businessContact}
//                                                 />
//                                             </div>
//                                         </r-cell>
//                                         <r-cell>
//                                             <div className="row--xs">
//                                                 <AdaptiveField
//                                                     type="Principal"
//                                                     mode="view"
//                                                     label="Technical Contact"
//                                                     value={viewerStore.asset.technicalContact}
//                                                 />
//                                             </div>
//                                         </r-cell>
//                                     </r-grid>
//                                 </FieldSetView>
//                                 {filteredSections.map((section) => (
//                                     <FieldSetView key={section.id} title={section.name}>
//                                         <r-grid columns="2">
//                                             {groupedBySections[section.id].map((property) => (
//                                                 <r-cell
//                                                     key={property.id}
//                                                     span-l={
//                                                         property.type === "Text" &&
//                                                         property.schema &&
//                                                         property.schema.richText
//                                                             ? 2
//                                                             : 1
//                                                     }
//                                                     span="2"
//                                                     className="row--xs"
//                                                 >
//                                                     <PropertyField
//                                                         property={property}
//                                                         data={viewerStore.asset}
//                                                         readOnly
//                                                     />
//                                                 </r-cell>
//                                             ))}
//                                         </r-grid>
//                                     </FieldSetView>
//                                 ))}
//                             </>
//                         )}
//                     </BoxPanel>
//                 );
//             }

//             _onLoadData = (props) => {
//                 const { settingsStore } = props.assetStore;

//                 settingsStore.loadPropertySections({ page: 1, query: {} });
//                 settingsStore.loadProperties({ page: 1, query: { activeOnly: true } });
//             };
//         }
//     )
// );
