import { observable, action, computed, flow } from "mobx";

import { WorkflowService } from "../../../api/workflows";

import { TaskStore } from "./TaskStore";

interface ResponseHandle {
    resolve: (value: any) => void;
    reject: (value?: any) => void;
}

export class TaskResponseFormStore {
    public parentStore: TaskStore;
    public workflowService: WorkflowService;
    private responseHandle?: ResponseHandle;

    @observable public visible: boolean = false;
    @observable public formData: any;
    @observable public formOptions: any;

    constructor(parentStore: TaskStore) {
        this.parentStore = parentStore;
        this.workflowService = parentStore.workflowService;
    }

    public show = flow(function* (options) {
        yield this.parentStore.selectionStore.loadTask(options.task.id);
        const task = this.parentStore.selectionStore.task;

        this.formOptions = { ...options, task };
        this.formData = { id: task.id, success: null, response: null, notes: null };

        this.visible = true;

        return new Promise<any>((resolve, reject) => {
            this.responseHandle = { resolve, reject };
        });
    });

    @action
    public hide(_) {
        this.visible = false;
        this.formData = null;
        this.formOptions = null;
        this.responseHandle = null;
    }

    @action
    public resolve(success) {
        this.responseHandle.resolve({
            success,
            formData: this.formData,
            formOptions: this.formOptions,
        });
    }

    @action
    public reject(error) {
        this.responseHandle.reject(error);
    }

    @computed
    public get isValid() {
        if (!this.formData) {
            return false;
        }

        return (
            this.formData.id &&
            this.formData.response &&
            (this.formData.success === true || this.formData.success === false)
        );
    }
}
