import React from "react";

import { PropertyTable } from "@ui/pdf/Table";

export const RequestPropertyTable = ({ workItem, schema }) => {
    const isFieldVisible = (schema, field, workItem) => {
        if (!field.visibleWhen) {
            return true;
        }

        return eval(field.visibleWhen);
    };

    const properties = [
        { name: "Description", value: workItem.description, type: "rich-text" },
        { name: "Go-live Date", value: workItem.dueDate, type: "date" },
    ];

    if (schema && schema.extensions) {
        const visible = schema.extensions.filter((field) => isFieldVisible(schema, field, workItem));
        visible.forEach((field) => {
            switch (field.type) {
                case "String":
                    if (field.options && field.options.length) {
                        const op = field.options.find((o) => o.key == workItem[field.name]);
                        properties.push({ name: field.label, value: op ? op.text : "" });
                    } else {
                        properties.push({ name: field.label, value: workItem[field.name] });
                    }
                    break;
                case "Boolean":
                    properties.push({ name: field.label, value: workItem[field.name] ? field.onText || "Yes" : field.offText || "No" });
                    break;
                default:
                    properties.push({ name: field.label, value: workItem[field.name] });
                    break;
            }
        });
    }

    return <PropertyTable data={properties} />;
};
