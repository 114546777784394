import { observable, action, computed, flow } from "mobx";

import { PaginationStore } from "../../base/PaginationStore";
import { PrincipalContextStore } from "../../identity/stores/PrincipalContextStore";
import { isUserAuthenticated, isUserInAllRoles } from "../../identity/stores/PrincipalContextUtilities";
import { AssessmentService } from "../../../api/assessments";
import { AssessmentStore } from "./AssessmentStore";

export class AssessmentDashboardStore {
    public parentStore: AssessmentStore;
    public assessmentService: AssessmentService;
    public principalContext: PrincipalContextStore;

    @observable public assessments: any[] = [];
    @observable public pagination: PaginationStore;
    @observable public loading: boolean = false;
    @observable public tab: string = "raised-by-me";
    @observable public tabs: any = {
        "raised-by-me": {
            title: "Raised by me",
            roles: [],
            templateId: "A697CB6D-9D22-41EF-BD1A-C1D90DFE9DAE",
        },
        "assigned-to-me": {
            title: "Assigned to me",
            roles: ["agent", "assessments"],
            templateId: "FFCEB651-5AE1-4502-BC4D-9D6818BB6FC1",
        },
        "assigned-to-my-team": {
            title: "Assigned to my team",
            roles: ["agent", "assessments"],
            templateId: "8C2DC7D5-D0C2-41FD-9F89-6106F3AD8B5B",
        },
        "im-a-stakeholder": {
            title: "I'm a stakeholder",
            roles: [],
            templateId: "14DDDC7B-B563-44AD-8CBD-A1AB51BC8A18",
        },
    };
    @observable public error: any;

    constructor(parentStore: AssessmentStore) {
        this.parentStore = parentStore;
        this.assessmentService = parentStore.assessmentService;
        this.principalContext = parentStore.rootStore.principalContext;
        this.pagination = new PaginationStore();
        this.pagination.itemsPerPage = 10;
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [
            { text: "Assurance Management", key: "assurance", onClick: () => this._goToUrl(`/assurance/dashboard`) },
            { text: "Assurance Dashboard", key: "dashboard", isCurrentItem: true },
        ];

        return builder;
    }

    @computed
    public get myTabs() {
        const user = this.principalContext.current;
        if (!isUserAuthenticated(user)) {
            return {};
        }

        return Object.entries(this.tabs).reduce((filtered, pair: any) => {
            const [key, value] = pair;
            if (isUserInAllRoles(user, value.roles)) {
                filtered[key] = value;
            }
            return filtered;
        }, {});
    }

    public loadAssessments = flow(function* (query: any) {
        this.loading = true;
        this.assessments = [];
        const options = Object.assign({ page: 1, keywords: null }, query);

        try {
            const result = yield this.assessmentService.searchAssessments({
                pageSize: this.pagination.itemsPerPage,
                startIndex: (options.page - 1) * this.pagination.itemsPerPage,
                query: {
                    templateId: this.tabs[this.tab].templateId,
                },
            });
            this.assessments = result.items;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
        } catch (e) {
            console.error(e);
            this.error = e;
        } finally {
            this.loading = false;
        }
    });

    @action
    public selectTab(tab: string) {
        if (this.tab !== tab) {
            this.tab = tab;
            this.loadAssessments({ tab: tab });
        }
    }

    _goToUrl = (url) => {
        if (url) {
            this.parentStore.rootStore.routing.push(url);
        }
    };
}
