import React from "react";
import { observer } from "mobx-react";
import { cx } from "emotion";
import moment from "moment";

import { ActivityStreamListItem } from "@modules/activities/components/core/ActivityStreamListItem";

export const ActivityStreamList = observer(({ user, messages, onRenderAttachment }) => {
    return (
        <ul>
            {messages.map((message, i) => {
                const timestamp = moment.utc(message.timestamp);
                const self = user && user.id === message.actor.id;
                const attached =
                    i > 0 &&
                    message.actor.id === messages[i - 1].actor.id &&
                    timestamp.diff(moment.utc(messages[i - 1].timestamp), "minute", false) < 5;
                return (
                    <li
                        key={message.id}
                        className={cx(
                            //"animated faster slideInRight",
                            "activity--item",
                            self && "activity--self",
                            attached && "activity--attached"
                        )}
                    >
                        <ActivityStreamListItem
                            message={message}
                            timestamp={timestamp}
                            self={self}
                            attached={attached}
                            onRenderAttachment={onRenderAttachment}
                        />
                    </li>
                );
            })}
        </ul>
    );
});
