import React, { Component } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

const rootClassName = cx(
    "cygraph-AssessmentSummary-root",
    css`
        & .counter {
            margin-bottom: var(--spacing-s);

            & .count {
                font-size: var(--font-size-xl);
                border-radius: 60px;
                width: 60px;
                height: 60px;
                line-height: 60px;
                text-align: center;
                padding: 0;
            }

            & .text {
                line-height: 20px;
                padding-top: var(--spacing-xxxs);
            }
        }
    `
);

export const AssessmentSummary = observer(
    class AssessmentSummary extends Component {
        render() {
            const { assessment, className, horizontal, onSummaryClick, hasRisks, hasExceptions } = this.props;

            if (!assessment.summary) {
                return null;
            }

            const { compliantCount, nonCompliantCount, riskCount, exceptionCount } = assessment.summary;

            if (compliantCount == null) {
                return null;
            }

            const showRisks = hasRisks || riskCount > 0;
            const showExceptions = hasExceptions || exceptionCount > 0;

            return (
                <div className={cx(rootClassName, className)}>
                    <r-grid columns={horizontal ? 2 + (showRisks ? 1 : 0) + (showExceptions ? 1 : 0) : 1}>
                        <r-cell>
                            <div className="counter clickable" onClick={() => onSummaryClick && onSummaryClick("compliant")}>
                                <div className="count message message--success">{compliantCount || 0}</div>
                                <div className="text">Compliant Advisories</div>
                            </div>
                        </r-cell>
                        <r-cell>
                            <div className="counter clickable" onClick={() => onSummaryClick && onSummaryClick("nonCompliant")}>
                                <div className="count message message--warning">{nonCompliantCount || 0}</div>
                                <div className="text">Non-Compliant Advisories</div>
                            </div>
                        </r-cell>
                        {showExceptions && (
                            <r-cell>
                                <div className="counter clickable" onClick={() => onSummaryClick && onSummaryClick("exception")}>
                                    <div className="count message message--danger">{exceptionCount || 0}</div>
                                    <div className="text">Exceptions Raised</div>
                                </div>
                            </r-cell>
                        )}
                        {showRisks && (
                            <r-cell>
                                <div className="counter clickable" onClick={() => onSummaryClick && onSummaryClick("risk")}>
                                    <div className="count message message--danger">{riskCount || 0}</div>
                                    <div className="text">Risks Identified</div>
                                </div>
                            </r-cell>
                        )}
                    </r-grid>
                </div>
            );
        }
    }
);
