import React from "react";
import { observer } from "mobx-react";

import { FilterBar } from "@modules/base/components/FilterBar";

import { WorkflowConstants } from "@modules/workflows/stores/WorkflowConstants";

export const ActivityBuilderFilterBar = observer(({ value, onChange, context, disabled }) => {
    const filters = [
        {
            name: "keywords",
            label: "Keywords",
            type: "Search",
            keywords: true,
            placeholder: "Search by name",
        },
        {
            name: "triggerType",
            label: "Trigger Type",
            type: "Dropdown",
            multiple: true,
            allowClear: true,
            options: WorkflowConstants.triggers.map((trigger) => ({
                key: trigger.id,
                text: trigger.name,
                icon: trigger.icon,
            })),
        },
        {
            name: "activity",
            label: "Automation Type",
            type: "Dropdown",
            multiple: true,
            allowClear: true,
            options: WorkflowConstants.activities.map((activity) => ({
                key: activity.id,
                text: activity.name,
                icon: activity.icon,
            })),
        },
        {
            name: "scope",
            label: "Scope",
            type: "Dropdown",
            multiple: true,
            allowClear: true,
            options: WorkflowConstants.scopes.map((scope) => ({
                key: scope.id,
                text: scope.name,
                icon: scope.icon,
            })),
        },
    ];
    return <FilterBar value={value} onChange={onChange} context={context} filters={filters} disabled={disabled} />;
});
