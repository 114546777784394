import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion'

import { Dialog, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';

const rootClassName = cx(
    'cygraph-ObservableDialog-root',
    css`

    `
);

export const ObservableDialog = observer(
    class ObservableDialog extends Component {

        render () {
            const { visible, type, title, subText, isBlocking, className, children, canConfirm, onConfirm, onCancel, context } = this.props;

            return (
                <Dialog
                    className={cx(rootClassName, className)}
                    hidden={!visible}
                    onDismiss={() => onCancel(context)}
                    dialogContentProps={{ type, title, subText }}
                    modalProps={{
                        isBlocking: isBlocking,
                    }}>
                    { children }
                    <DialogFooter>
                        <PrimaryButton onClick={() => onConfirm(context)} text="OK" disabled={canConfirm && !canConfirm()} />
                        <DefaultButton onClick={() => onCancel(context)} text="Cancel" />
                    </DialogFooter>
                </Dialog>
            );
        }
    }
);