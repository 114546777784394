import React from "react";
import { observer } from "mobx-react";

import { ActionButton } from "office-ui-fabric-react/lib/Button";

import { ContentEditor } from "../../../editors/components/ContentEditor";

import { sanitize } from "../../stores/PatternUtilities";

export const ModuleFieldChoice = observer(({ module, group, measure, disabled, onUpdateOption, onRemoveOption }) => {
    return measure.options.map((option) => {
        //const value = values[measure.name].value;

        return (
            <div className="form__group form__group--flex--row" key={option.key}>
                {!disabled && (
                    <ActionButton
                        styles={{ icon: { fontSize: "12px" } }}
                        iconProps={{ iconName: "Delete" }}
                        onClick={() =>
                            onRemoveOption({
                                module,
                                group,
                                measure,
                                option,
                            })
                        }
                    ></ActionButton>
                )}
                <ul className="radio__input__btns__list">
                    <li className="radio__input__btns__list__item">
                        <input
                            type="radio"
                            id={measure.name + option.key}
                            name={measure.name}
                            className="radio__input__btn"
                            disabled={true}
                            //checked={value == option.key}
                            onChange={(e) => {
                                //onMeasureChange(measure, { value: option.key, completed: true });
                            }}
                        />
                        <label htmlFor={measure.name + option.key} className="radio__input__btn__label">
                            <ContentEditor
                                html={option.text || ""}
                                disabled={disabled}
                                onSubmit={(evt, val) => {
                                    onUpdateOption({
                                        module,
                                        group,
                                        measure,
                                        option: { key: option.key, text: sanitize(val) },
                                    });
                                }}
                            />
                        </label>
                    </li>
                </ul>
            </div>
        );
    });
});
