import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router';

import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { PanelType } from 'office-ui-fabric-react/lib/Panel';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';

import { ObservablePanel } from '../../../base/components/ObservablePanel';
import { PageEditForm } from '../../components/core/PageEditForm';

const getQueryStringParams = query => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
};

export const PageEditPanel = inject('routing', 'pageStore')(withRouter(observer(
    class PageEditPanel extends Component {
        
        render () {
            const { editFormStore } = this.props.pageStore;
            const { visible, formData, formOptions, isValid } = editFormStore;
            
            return (
                <ObservablePanel
                    hidden={!visible}
                    pageType={ formData && formData.id ? 'Edit Properties' : 'Add Page' }
                    panelType={PanelType.medium}
                    onClosePanel={() => this._onHide()}
                    onRenderFooter={() => this._renderFooter()}>
                    { formData && ( 
                        <PageEditForm
                            formData={formData}
                            formOptions={formOptions}
                            isValid={isValid} />
                    ) }
                </ObservablePanel>
            );
        }

        _renderFooter() {
            const { editFormStore } = this.props.pageStore;
            const { isValid, saving } = editFormStore;
            return (
                <Stack horizontal horizontalAlign="space-between">
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <PrimaryButton onClick={() => this._onSave('Major')} text="Publish" disabled={!isValid} />
                        <DefaultButton onClick={() => this._onSave('Minor')} text="Save as Draft" disabled={!isValid} />
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <DefaultButton onClick={() => this._onHide()} text="Cancel" disabled={saving} />
                    </Stack>
                </Stack>
            );
        }

        _onSave(mode) {
            const { editFormStore } = this.props.pageStore;
            const { formData, riskId, isValid, saving } = editFormStore;
            const { match, routing, pageStore } = this.props;
            
            if(isValid && !saving) {
                if(formData.id) {
                    editFormStore.editPage(mode, formData).then((page) => {
                        if(this.props.onSuccess) {
                            this.props.onSuccess(page, mode);
                        }
                        editFormStore.hide({clearData: true});
                        const params = getQueryStringParams(routing.location.search);
                        pageStore.browseStore.loadPages({ libraryId: match.params.libraryId, parentId: match.params.pageId, page: params["p"] || 1, keywords: params["k"] });
                    });
                } else {
                    editFormStore.createPage(mode, formData).then((page) => {
                        if(this.props.onSuccess) {
                            this.props.onSuccess(page, mode);
                        }
    
                        editFormStore.hide({clearData: true});
                    });
                }                
            }
        }

        _onHide() {
            const { editFormStore } = this.props.pageStore;
            const { formData, formOptions } = editFormStore;
       
            if(this.props.onCancelled) {
                this.props.onCancelled(formData, formOptions.parent);
            }

            editFormStore.hide();
        }
    }
)));