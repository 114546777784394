import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { Separator } from 'office-ui-fabric-react/lib/Separator';

import { PageSection } from './PageSection';

export const PageSectionDivider = observer(
  class PageSectionDivider extends Component {
    render () {
        const { className, instance, section, index } = this.props;

        return (
          <div className={className}>
            <div className="page--section-divider">
                <div className="wrapper">
                  <Separator>
                    <IconButton 
                      iconProps={{iconName: 'CircleAdditionSolid'}} 
                      title="Add section" ariaLabel="Add section"
                      onClick={() => instance.lifecycleStore.onAddSection(index, section)} />
                  </Separator>
                </div>
              </div>
          </div>
        );
    }
  }
);