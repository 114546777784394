import { observable, action, flow } from "mobx";

import { ProductService } from "../../../api/graph";
import { ProductStore } from "./ProductStore";

export class ProductSelectionStore {
    public productService: ProductService;
    public parentStore: ProductStore;

    @observable public loading: boolean = false;
    @observable public product: any;
    @observable public schema: any;

    constructor(parentStore: ProductStore) {
        this.parentStore = parentStore;
        this.productService = parentStore.productService;
    }

    public clear = flow(function* () {
        this.product = null;
        this.schema = null;
    });

    @action
    public setSelected(product) {
        this.product = product;
    }

    public loadProduct = flow(function* (id) {
        this.loading = true;

        if (this.product && this.product.id !== id) {
            this.product = null;
        }
        try {
            this.product = yield this.productService.getProduct(id);
        } finally {
            this.loading = false;
        }
    });

    public loadProductSchema = flow(function* (id, version) {
        this.loading = true;

        if (this.schema && this.schema.productId !== id) {
            this.schema = null;
        }
        try {
            this.schema = yield this.productService.getProductSchema(id, version);
            return this.schema;
        } finally {
            this.loading = false;
        }
    });
}
