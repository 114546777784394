import { Constants, ServiceBase } from "../graph/serviceBase";

export class AssessmentService extends ServiceBase {
    public async getAssessments(options) {
        return this.get(
            `${Constants.baseUrl}/assessments?pageSize=${options.pageSize}&startIndex=${
                options.startIndex
            }&keywords=${options.keywords || ""}`
        );
    }

    public async searchAssessments(options) {
        return this.post(`${Constants.baseUrl}/assessments/search`, options);
    }

    public async exportAssessments(options) {
        return this.post(`${Constants.baseUrl}/assessments/export`, options);
    }

    public async createAssessment(assessment) {
        return this.post(`${Constants.baseUrl}/assessments`, assessment);
    }

    public async startAssessment(id) {
        return this.post(`${Constants.baseUrl}/assessments/${id}/start`, {});
    }

    public async submitAssessment(id) {
        return this.post(`${Constants.baseUrl}/assessments/${id}/submit`, {});
    }

    public async reviewAssessment(id) {
        return this.post(`${Constants.baseUrl}/assessments/${id}/review`, {});
    }

    public async closeAssessment(id) {
        return this.post(`${Constants.baseUrl}/assessments/${id}/close`, {});
    }

    public async cancelAssessment(id) {
        return this.post(`${Constants.baseUrl}/assessments/${id}/cancel`, {});
    }

    public getAssessment(id) {
        return this.get(`${Constants.baseUrl}/assessments/${id}`);
    }

    public getAssessmentWorkItem(id) {
        return this.get(`${Constants.baseUrl}/assessments/${id}/work-item`);
    }

    public async getAssessmentValues(id) {
        return this.get(`${Constants.baseUrl}/assessments/${id}/values`);
    }

    public async downloadAssessmentValues(id, moduleId = null) {
        return this.getBlob(
            `${Constants.baseUrl}/assessments/${id}/values.csv?moduleId=${moduleId || ""}`
        );
    }

    public async updateAssessmentValue(value) {
        return this.put(
            `${Constants.baseUrl}/assessments/${value.assessmentId}/values/${value.measureId}`,
            value
        );
    }

    public async getAssessmentModules(id) {
        return this.get(`${Constants.baseUrl}/assessments/${id}/modules`);
    }

    public async getAssessmentAdvisories(id) {
        return this.get(`${Constants.baseUrl}/assessments/${id}/advisories`);
    }

    public async closeAssessmentAdvisories(id, advisories) {
        return this.post(`${Constants.baseUrl}/assessments/${id}/advisories`, advisories);
    }

    public async getAssessmentComments(id, uniqueId) {
        return this.get(`${Constants.baseUrl}/assessments/${id}/comments?uniqueId=${uniqueId}`);
    }

    public async createAssessmentComment(comment) {
        return this.post(
            `${Constants.baseUrl}/assessments/${comment.assessmentId}/comments`,
            comment
        );
    }

    public async getAssessmentCommentCount(id) {
        return this.get(`${Constants.baseUrl}/assessments/${id}/comments/count`);
    }

    public async getAssessmentEvidences(id) {
        return this.get(`${Constants.baseUrl}/evidences?assessmentId=${id}`);
    }

    public async searchAdvisories(queryOptions: any = {}) {
        return this.post(`${Constants.baseUrl}/advisories/search`, queryOptions);
    }

    public async getAdvisoryEvidences(id) {
        return this.get(`${Constants.baseUrl}/evidences?advisoryId=${id}`);
    }

    public async getEvidence(id) {
        return this.get(`${Constants.baseUrl}/evidences/${id}`);
    }

    public async createEvidence(evidence) {
        return this.post(`${Constants.baseUrl}/evidences`, evidence);
    }

    public async updateEvidence(evidence) {
        return this.put(`${Constants.baseUrl}/evidences/${evidence.id}`, evidence);
    }

    public async removeEvidence(id) {
        return this.delete(`${Constants.baseUrl}/evidences/${id}`, {});
    }

    public async downloadEvidence(id) {
        return this.getBlob(`${Constants.baseUrl}/evidences/${id}/content`);
    }

    public async getAssessmentRisks(id, template) {
        return this.get(`${Constants.baseUrl}/assessments/${id}/risks?template=${template || ""}`);
    }

    public async getAdvisoryRiskMapping(id) {
        return this.get(`${Constants.baseUrl}/assessments/${id}/advisories/risks`);
    }

    public async getAdvisoryEvidenceMapping(id) {
        return this.get(`${Constants.baseUrl}/assessments/${id}/advisories/evidences`);
    }

    public async getAssessmentActivities(
        assessmentId: string,
        continuationToken: string = null,
        maxResults: number = 25
    ) {
        const requestContinuationToken = continuationToken
            ? `&continuationToken=${continuationToken}`
            : "";
        return this.get(
            `${Constants.baseUrl}/work-items/${assessmentId}/activities?maxResults=${maxResults}&${requestContinuationToken}`
        );
    }

    public async getAssessmentsOpenedClosed(query) {
        return this.post(`${Constants.baseUrl}/assessments/open-close`, query);
    }

    public async getAssessmentsGroupByCount(query) {
        return this.post(`${Constants.baseUrl}/assessments/group-by-count`, query);
    }

    public async getAdvisoriesGroupByCount(query) {
        return this.post(`${Constants.baseUrl}/advisories/group-by-count`, query);
    }

    public async getAdvisoriesFlatByControlFinalCompliance(query) {
        return this.post(`${Constants.baseUrl}/advisories/flat/control-final-compliance`, query);
    }

    public async getAdvisoriesFlatByPortfolio(query) {
        return this.post(`${Constants.baseUrl}/advisories/flat/portfolios`, query);
    }

    public async getAdvisoriesControlMap(query) {
        return this.post(`${Constants.baseUrl}/advisories/control-map`, query);
    }
}
