import React, { Children } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { Stack } from "@ui/elements/Stack";
import { FontIcon } from "@ui/elements/Icon";
import { Text } from "@ui/elements/Text";

import { ControlMaturity } from "@modules/controls/components/core/ControlMaturity";

const rootClassName = cx(
    "cygraph-ControlDomainCardView-root",
    css`
        max-width: 100%;
        height: 100%;
        min-height: 80px;
        padding: var(--spacing-s) var(--spacing-s);
        background-color: var(--app-header-background);
        position: relative;

        &.card {
            margin-bottom: 0;
        }
    `
);
function hashCode(s) {
    for (var i = 0, h = 0; i < s.length; i++) h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
    return h;
}
export const ControlDomainCardView = observer(({ domain }) => {
    return (
        <Stack className={cx("card", rootClassName)} horizontal tokens={{ childrenGap: 15 }}>
            <Stack.Item style={{ minWidth: 55 }}>
                <FontIcon
                    iconName={domain.icon || "flaticon-009-data-security"}
                    style={{ color: "var(--primary-brand-color)", fontSize: 45 }}
                />
            </Stack.Item>
            <Stack.Item>
                <Stack tokens={{ childrenGap: 5 }}>
                    <Text variant="mediumPlus" block>
                        {domain.code} - {domain.name}
                    </Text>
                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                        <Text block>1 strategic control</Text>
                        <Text block>3 tactical controls</Text>
                    </Stack>
                    <br />
                    <Stack horizontal tokens={{ childrenGap: 25 }}>
                        <Text variant="small">{(Math.random() * 5).toFixed(1)}</Text>
                        <ControlMaturity
                            size="mini"
                            score={
                                ["Initial", "Repeatable", "Defined", "Managed", "Optimised"][hashCode(domain.code) % 4]
                            }
                        />
                    </Stack>
                </Stack>
            </Stack.Item>
        </Stack>
    );
});
