import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react";

import * as joint from "jointjs";

import { StateFlowStateToolItem } from "@modules/stateflows/components/editor/StateFlowStateToolItem";
import { TargetMetadata } from "../../stores/TargetMetadata";

export const StateFlowToolBarView = observer(({ manifest, graph }) => {
    const toolBar = useMemo(() => {
        const el = new joint.shapes.standard.Rectangle({
            attrs: {
                body: {
                    fill: "var(--white)",
                    strokeWidth: 1,
                },
            },
        });

        el.set("id", `${manifest.id}-tool-bar`);
        el.set("z", 21);
        el.prop("data", {
            type: "toolBar",
            locked: true,
        });

        graph.addCell(el);
        return el;
    }, [graph]);

    useEffect(() => {
        if (manifest) {
            toolBar.set("id", `${manifest.id}-tool-bar`);

            if (manifest && manifest.stages) {
                toolBar.size(335 * manifest.stages.length, 70);
            }
        }

        return () => {};
    }, [toolBar, manifest]);

    const states = TargetMetadata[manifest.target].states;

    return (
        <>
            {states &&
                states.map((state, i) => <StateFlowStateToolItem key={state.id} state={state} index={i} manifest={manifest} graph={graph} parent={toolBar} />)}
        </>
    );
});
