import React from "react";
import { observer } from "mobx-react";
import moment from "moment";

import { TextField } from "office-ui-fabric-react/lib/TextField";
import { Text } from "office-ui-fabric-react/lib/Text";
import { Toggle } from "office-ui-fabric-react/lib/Toggle";

import { RichDropdown } from "@modules/base/richdropdown/RichDropdown";

import { WorkflowUserPicker } from "@modules/workflows/components/fields/WorkflowUserPicker";
import { WorkflowDatePicker } from "@modules/workflows/components/fields/WorkflowDatePicker";

export const CreateTaskActivityEditor = observer(({ disabled, formData, formOptions }) => {
    return (
        <>
            <r-grid columns="2">
                <r-cell span="2">
                    <TextField
                        label="Title"
                        required
                        placeholder="Please enter a task title"
                        dataTest="newWorkItemTitle"
                        disabled={disabled}
                        value={formData.settings.task.title}
                        onChange={(ev, value) => {
                            formData.settings.task.title = value;
                        }}
                    />
                </r-cell>
                <r-cell span="2">
                    <TextField
                        label="Description"
                        disabled={disabled}
                        multiline
                        required
                        rows={3}
                        value={formData.settings.task.description}
                        onChange={(ev, value) => {
                            formData.settings.task.description = value;
                        }}
                    />
                </r-cell>
                <r-cell>
                    <WorkflowDatePicker
                        label="Due date"
                        required
                        disabled={disabled}
                        propertyModel={formOptions.propertyModel}
                        value={formData.settings.task.dueDate}
                        onChange={(dateValue) => {
                            formData.settings.task.dueDate = dateValue;
                        }}
                        placeholder="Select a due date..."
                        ariaLabel="Select a due date"
                    />
                </r-cell>
                <r-cell>&nbsp;</r-cell>
                <r-cell>
                    <RichDropdown
                        disabled={!!formData.settings.task.id || disabled}
                        placeholder="Task type"
                        label="Task type"
                        selectedKey={formData.settings.task.useCase}
                        required
                        options={[
                            {
                                title: "Complete Task",
                                key: "Completion",
                                description: <Text>Task will display with a 'Mark as completed' button</Text>,
                            },
                            {
                                title: "Approve/Reject Task",
                                key: "Approval",
                                description: <Text>Task will display with Approve & Reject buttons</Text>,
                            },
                            {
                                title: "Yes/No Task",
                                key: "Confirmation",
                                description: <Text>Task will display with Yes & No buttons</Text>,
                            },
                        ]}
                        onOptionChanged={(item) => {
                            formData.settings.task.useCase = item.key;
                        }}
                    />
                </r-cell>
                <r-cell>
                    <RichDropdown
                        placeholder="Select an option"
                        label="Task response mode"
                        disabled={!!formData.settings.task.id || disabled}
                        selectedKey={formData.settings.task.mode}
                        required
                        onOptionChanged={(option) => {
                            formData.settings.task.mode = option.key;
                        }}
                        options={[
                            {
                                key: "First",
                                title: "Single response",
                                description: <Text>Task will be complete when any user responds</Text>,
                            },
                            // {
                            //     key: 'Any',
                            //     title: 'Any response will complete task',
                            //     description: <Text>Any response will close the task</Text>
                            // },
                            {
                                key: "All",
                                title: "Multi response",
                                description: <Text>Task will be complete when all assigned users respond</Text>,
                            },
                        ]}
                    />
                </r-cell>
                <r-cell span="2">
                    <WorkflowUserPicker
                        label="Assigned to"
                        multiple
                        propertyModel={formOptions.propertyModel}
                        value={formData.settings.task.assignedTos || []}
                        onChange={(val) => {
                            formData.settings.task.assignedTos = val;
                        }}
                    />
                </r-cell>
                <r-cell>
                    <Toggle
                        label="Send email notification on assignment"
                        disabled={disabled}
                        checked={formData.settings.task.notify}
                        onChange={(ev, checked) => {
                            formData.settings.task.notify = checked;
                        }}
                    />
                </r-cell>
                <r-cell span="2">
                    <WorkflowUserPicker
                        label="On completion send notification to"
                        multiple
                        propertyModel={formOptions.propertyModel}
                        value={formData.settings.task.settings.listeners.completion || []}
                        onChange={(val) => {
                            formData.settings.task.settings.listeners.completion = val;
                        }}
                    />
                </r-cell>
            </r-grid>
        </>
    );
});
