import React, { Component } from "react";
import { observer } from "mobx-react";

import { Text } from "office-ui-fabric-react/lib/Text";
import { MarkdownViewer } from "@modules/editors/components/MarkdownViewer";

export const TriageWizardItem = observer(
    class TriageWizardItem extends Component {
        render() {
            const { title, description, children } = this.props;

            return (
                <div className="ms-u-slideRightIn40">
                    <r-grid columns-l="5" columns="2" columns-s="1">
                        <r-cell span-l="2" span="1" span-s="1">
                            <div className="row--xs">
                                <Text variant={"mediumPlus"} block>
                                    {title}
                                </Text>
                            </div>
                            <div className="">
                                <Text variant={"small"} block>
                                    {description && <MarkdownViewer value={description} />}
                                </Text>
                            </div>
                        </r-cell>
                        <r-cell span-l="3" span="1" span-s="1">
                            {children}
                        </r-cell>
                    </r-grid>
                </div>
            );
        }
    }
);
