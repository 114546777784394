import moment from 'moment';

async function getPortfoliosContact(query, groupBy, api) {
    const response = await api({keywords: null, startIndex: 0, pageSize: 250});
    const portfoliosContacts = []
    response.items
        .filter(portfolio => {
            if (query && query.portfolios) {
                return query.portfolios.map(p => p.toString()).includes(portfolio.id.toString())
            } else {
                return true
            }
        })
        .forEach(portfolio => {
            if (portfolio.portfolioLead) {
                portfoliosContacts.push({
                    principalId: portfolio.portfolioLead.id,
                    portfolioName: portfolio.name,
                    name: portfolio.portfolioLead.name,
                    jobTitle: portfolio.portfolioLead.jobTitle,
                    email: portfolio.portfolioLead.email,
                    contactType: 'Portfolio Lead',
                })
            }
            if (portfolio.dataPrivacyLead) {
                portfoliosContacts.push({
                    principalId: portfolio.dataPrivacyLead.id,
                    portfolioName: portfolio.name,
                    name: portfolio.dataPrivacyLead.name,
                    jobTitle: portfolio.dataPrivacyLead.jobTitle,
                    email: portfolio.dataPrivacyLead.email,
                    contactType: 'Data Privacy Lead',
                })
            }
            if (portfolio.assurancePrimary) {
                portfoliosContacts.push({
                    principalId: portfolio.assurancePrimary.id,
                    portfolioName: portfolio.name,
                    name: portfolio.assurancePrimary.name,
                    jobTitle: portfolio.assurancePrimary.jobTitle,
                    email: portfolio.assurancePrimary.email,
                    contactType: 'Assurance Primary',
                })
            }
            if (portfolio.assuranceSecondary) {
                portfoliosContacts.push({
                    principalId: portfolio.assuranceSecondary.id,
                    portfolioName: portfolio.name,
                    name: portfolio.assuranceSecondary.name,
                    jobTitle: portfolio.assuranceSecondary.jobTitle,
                    email: portfolio.assuranceSecondary.email,
                    contactType: 'Assurance Secondary',
                })
            }
            if (portfolio.portfolioChampion) {
                portfoliosContacts.push({
                    principalId: portfolio.portfolioChampion.id,
                    portfolioName: portfolio.name,
                    name: portfolio.portfolioChampion.name,
                    jobTitle: portfolio.portfolioChampion.jobTitle,
                    email: portfolio.portfolioChampion.email,
                    contactType: 'Portfolio Champion',
                })
            }
            if (portfolio.securityArchitect) {
                portfoliosContacts.push({
                    principalId: portfolio.securityArchitect.id,
                    portfolioName: portfolio.name,
                    name: portfolio.securityArchitect.name,
                    jobTitle: portfolio.securityArchitect.jobTitle,
                    email: portfolio.securityArchitect.email,
                    contactType: 'Security Architect',
                })
            }
    })
    return { items: portfoliosContacts, query };
}

export { getPortfoliosContact }
