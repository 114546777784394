import { observable, flow, action, computed } from "mobx";

import { PaginationStore } from "../../base/PaginationStore";
import { EntityService } from '../../../api/entities';
import { EntityStore } from "./EntityStore";

export class EntityDashboardStore {

    private parentStore: EntityStore;
    private entityService: EntityService;

    @observable public loading: boolean = false;
    @observable public entities: any[] = [];
    @observable public query: any;
    @observable public pagination: PaginationStore;
    @observable public error: any;

    constructor(parentStore: EntityStore) {
        this.parentStore = parentStore;
        this.entityService = parentStore.entityService;
        this.pagination = new PaginationStore();
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [];

        builder.push({ text: 'Entity Management', key: 'entities', onClick: () => this.parentStore.rootStore.routing.push(`/entities/dashboard`) });
        builder.push({ text: 'Entity Dashboard', key: 'entities-dashboard', isCurrentItem: true });

        return builder;
    }

    public loadDashboard = flow(function*(options: any) {
        const o = Object.assign({ page: 1, query: { keywords: null } }, options);

        this.loading = true;
        
        try {
            
        } catch (e) {
            this.error = e;
            console.error(e)
        } finally {
            this.loading = false;
        }
    });

    @action
    public replace(asset) {
        const index = this.entities.findIndex(p => p.id === asset.id);

        if(index !== -1) {            
            this.entities = [...this.entities.splice(index, 1, asset)];
        }
    }
}
