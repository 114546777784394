import React, { Component } from "react";
import { observer } from "mobx-react";

import { WellKnownFieldNames, WellKnownSectionIds } from "@modules/properties/stores/PropertyContants";
import { PropertyField } from "@modules/properties/components/core/PropertyField";
import { BigTextField } from "@baseComponents//BigTextField";
import { ObservableSeparator } from "@baseComponents/ObservableSeparator";

export const EntityEditForm = observer(
    class EntityEditForm extends Component {
        render() {
            const { formData, formOptions } = this.props;
            const { properties, sections } = formOptions;

            if (!properties || properties.length == 0 || !sections || sections.length == 0) {
                return null;
            }

            const editable = properties.filter(
                (p) => !p.readOnly && !p.hidden && p.staticName != WellKnownFieldNames.Name
            );
            const groupedBySections = editable.reduce((acc, curr) => {
                if (!acc[curr.section.id]) acc[curr.section.id] = [];
                acc[curr.section.id].push(curr);
                return acc;
            }, {});

            return (
                <>
                    <r-grid columns="6">
                        <r-cell span="6">
                            <div>
                                <BigTextField
                                    label="Name"
                                    required
                                    className="entity-name"
                                    placeholder="Please enter a name"
                                    value={formData.name}
                                    transparent
                                    onChange={(ev, value) => (formData.name = value)}
                                />
                            </div>
                        </r-cell>
                    </r-grid>
                    {formData &&
                        sections.map((section) => (
                            <div key={section.id}>
                                {groupedBySections[section.id] && (
                                    <div className="row--l">
                                        {section.id !== WellKnownSectionIds.EntityBuiltIn && (
                                            <ObservableSeparator className="row--s">{section.name}</ObservableSeparator>
                                        )}
                                        <r-grid columns="6">
                                            {groupedBySections[section.id].map((property) => (
                                                <r-cell
                                                    span={this._getColumnSpan(property)}
                                                    key={property.id}
                                                    className="row--xs"
                                                >
                                                    <PropertyField property={property} data={formData} />
                                                </r-cell>
                                            ))}
                                        </r-grid>
                                    </div>
                                )}
                            </div>
                        ))}
                </>
            );
        }

        _getColumnSpan(property) {
            return property.type == "Text" &&
                property.schema &&
                (property.schema.richText || property.schema.maxLength > 400)
                ? 6
                : 3;
        }
    }
);
