import React from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { Text } from "office-ui-fabric-react/lib/Text";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { IconButton } from "office-ui-fabric-react/lib/Button";
import { TooltipHost } from "office-ui-fabric-react/lib/Tooltip";

import { ObservableBadge } from "@modules/base/components/ObservableBadge";
import { ActionSet } from "@modules/base/components/ActionSet";

import { WorkItemToolBarHost } from "@modules/workitems/components/adapters/WorkItemToolBarHost";

const rootClassName = cx(
    "cygraph-WorkItemHeader-root",
    css`
        margin: var(--spacing-s) 0px;

        .title {
            margin: var(--spacing-xs) 0;
        }

        & .description {
            color: var(--text-color-light);
            font-size: var(--font-size-xs);
        }

        & .back {
            width: 45px;
            & i {
                font-size: 30px;
                height: 30px;
                line-height: 30px;
                margin-right: 10px;
            }
        }
    `
);

export const WorkItemHeader = observer(({ workItem, onNavigateBack, triggers, conditions, onTrigger }) => {
    if (!workItem) {
        return null;
    }

    const items = [];
    const overflowItems = [];

    triggers
        .filter((t) => t.location == "Default" || t.location == "Quicklaunch")
        .forEach((trigger) => {
            items.push({
                key: trigger.id,
                name: trigger.name,
                primary: trigger.variant == "Primary",
                iconOnly: trigger.iconProps && trigger.iconProps.iconOnly,
                iconProps: trigger.iconProps,
                confirmProps: trigger.confirmProps,
                disabled:
                    trigger.command.condition &&
                    (!conditions[trigger.command.condition] || !conditions[trigger.command.condition]()),
                onClick: (ev, comment) => {
                    onTrigger(
                        trigger,
                        {
                            args: trigger.command.args,
                            comment: comment && comment.length ? { content: comment } : null,
                        },
                        ev
                    );
                },
            });
        });

    triggers
        .filter((t) => t.location == "Overflow")
        .forEach((trigger) => {
            overflowItems.push({
                key: trigger.id,
                name: trigger.name,
                primary: trigger.variant == "Primary",
                iconProps: trigger.iconProps,
                confirmProps: trigger.confirmProps,
                disabled:
                    trigger.command.condition &&
                    (!conditions[trigger.command.condition] || !conditions[trigger.command.condition]()),
                onClick: (ev, comment) => {
                    onTrigger(
                        trigger,
                        {
                            args: trigger.command.args,
                            comment: comment && comment.length ? { content: comment } : null,
                        },
                        ev
                    );
                },
            });
        });

    return (
        <div className={rootClassName} data-test="workItemHeader">
            <Stack horizontal>
                {onNavigateBack && (
                    <Stack.Item>
                        <TooltipHost content="Navigate back to all demand requests">
                            <IconButton
                                className="back"
                                iconProps={{ iconName: "NavigateBack" }}
                                title="Navigate back"
                                ariaLabel="Navigate back"
                                onClick={onNavigateBack}
                            />
                        </TooltipHost>
                    </Stack.Item>
                )}
                <Stack.Item grow>
                    <Stack horizontal horizontalAlign="space-between">
                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                            <ObservableBadge>{workItem.product.name}</ObservableBadge>
                            <WorkItemToolBarHost workItem={workItem} />
                        </Stack>
                        <ActionSet items={items} overflowItems={overflowItems} />
                    </Stack>
                </Stack.Item>
            </Stack>
            <Text className="title" variant="xLarge" block>
                {workItem.code} - {workItem.title}
            </Text>
        </div>
    );
});
