import { observable, action } from "mobx";

import { RootStore } from "../../base/RootStore";
import { PaginationStore } from "../../base/PaginationStore";
import { AssetService } from "../../../api/assets";

import { AssetSettingsStore } from "./AssetSettingsStore";

import { AssetSelectionStore } from "./AssetSelectionStore";
import { AssetPermissionStore } from "./AssetPermissionStore";
import { AssetLifecycleStore } from "./AssetLifecycleStore";

import { AssetDashboardStore } from "./AssetDashboardStore";
import { AssetBrowseStore } from "./AssetBrowseStore";
import { AssetWatchlistStore } from "./AssetWatchlistStore";
import { AssetViewerStore } from "./AssetViewerStore";
import { AssetNewStore } from "./AssetNewStore";
import { AssetEditStore } from "./AssetEditStore";

import { AssetAssessmentStore } from "./AssetAssessmentStore";
import { AssetWorkItemStore } from "./AssetWorkItemStore";
import { AssetDocumentStore } from "./AssetDocumentStore";

export class AssetStore {
    public assetService: AssetService;
    public rootStore: RootStore;

    public settingsStore: AssetSettingsStore;

    public selectionStore: AssetSelectionStore;
    public permissionStore: AssetPermissionStore;
    public lifecycleStore: AssetLifecycleStore;

    public dashboardStore: AssetDashboardStore;
    public browseStore: AssetBrowseStore;
    public watchlistStore: AssetWatchlistStore;
    public viewerStore: AssetViewerStore;
    public documentStore: AssetDocumentStore;
    public newStore: AssetNewStore;
    public editStore: AssetEditStore;

    public assessmentStore: AssetAssessmentStore;
    public workItemStore: AssetWorkItemStore;

    @observable public selectedAsset: any;
    @observable public assets: any[] = [];
    @observable public pagination: PaginationStore;
    @observable public keywords: string;
    @observable public loading: boolean = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        this.assetService = new AssetService(rootStore.authProvider);

        this.settingsStore = new AssetSettingsStore(this);

        this.selectionStore = new AssetSelectionStore(this);
        this.permissionStore = new AssetPermissionStore(this);
        this.lifecycleStore = new AssetLifecycleStore(this);

        this.dashboardStore = new AssetDashboardStore(this);
        this.browseStore = new AssetBrowseStore(this);
        this.watchlistStore = new AssetWatchlistStore(this);
        this.viewerStore = new AssetViewerStore(this);
        this.documentStore = new AssetDocumentStore(this);
        this.newStore = new AssetNewStore(this);
        this.editStore = new AssetEditStore(this);

        this.assessmentStore = new AssetAssessmentStore(this);
        this.workItemStore = new AssetWorkItemStore(this);

        this.pagination = new PaginationStore();
    }

    @action
    public setSelected(system: any) {
        this.selectedAsset = system;
    }

    @action
    public createNew(system: Partial<any>) {
        this.selectedAsset = system;
    }

    @action
    public setSelectedWithId(id: string | null) {
        if (id == null) {
            this.selectedAsset = null;
        } else {
            if (this.selectedAsset && this.selectedAsset.id === id) {
                return;
            }
            this.selectedAsset = this.assets.find((p) => p.id === id);
            if (this.selectedAsset == null) {
                this.loadAsset(id);
            }
        }
    }

    @action
    public loadAssets(query) {
        this.loading = true;
        var options = Object.assign({ page: 1, keywords: null }, query);
        return this.assetService
            .getAssets({
                pageSize: this.pagination.itemsPerPage,
                startIndex: (options.page - 1) * this.pagination.itemsPerPage,
                keywords: options.keywords,
            })
            .then(this.loadAssetsOnSuccess, this.loadAssetsOnError);
    }

    @action.bound
    public loadAssetsOnSuccess(result) {
        this.assets = result.items;
        this.keywords = result.keywords;
        this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
        this.loading = false;
        return result;
    }

    @action.bound
    public loadAssetsOnError(error) {
        console.error(error);
        this.loading = false;
        return error;
    }

    @action
    public createAsset(system: any) {
        this.loading = true;
        return this.assetService.createAsset(system).then(this.createAssetOnSuccess, this.createAssetOnError);
    }

    @action.bound
    public createAssetOnSuccess(result) {
        this.assets = result.items;
        this.loading = false;
        return result;
    }

    @action.bound
    public createAssetOnError(error) {
        console.error(error);
        this.loading = false;
        return error;
    }

    @action
    public loadAsset(id: string) {
        return this.assetService.getAsset(id).then(this.loadAssetOnSuccess, this.loadAssetOnError);
    }

    @action.bound
    public loadAssetOnSuccess(result) {
        this.selectedAsset = result;
        return result;
    }

    @action.bound
    public loadAssetOnError(error) {
        console.error(error);
        return error;
    }

    @action
    public async loadAssetAssessments(id: string, options: any) {
        this.loading = true;
        const apiOptions = {
            pageSize: 25,
            startIndex: 0,
            keywords: null,
        };

        if (options.keywords) apiOptions.keywords = options.keywords;
        if (options.page) apiOptions.startIndex = (options.page - 1) * this.pagination.itemsPerPage;
        if (options.pageSize) apiOptions.pageSize = options.pageSize;
        // var options = Object.assign({ page: 1, keywords: null }, query);

        if (this.selectedAsset !== undefined) {
            return this.assetService
                .getAssetAssessments(id, apiOptions)
                .then(this.loadAssetAssessmentOnSuccess, this.loadAssetAssessmentOnError);
        } else {
            return this.assetService
                .getAsset(id)
                .then(
                    await this.assetService
                        .getAssetAssessments(id, apiOptions)
                        .then(this.loadAssetAssessmentOnSuccess, this.loadAssetAssessmentOnError),
                    this.loadAssetOnError
                );
        }
    }

    @action.bound
    public loadAssetAssessmentOnSuccess(result) {
        // debugger
        this.selectedAsset.assessments = result.items;
        this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
        this.loading = false;
        return result;
    }

    @action.bound
    public loadAssetAssessmentOnError(error) {
        // debugger
        console.error(error);
        this.loading = false;
        return error;
    }

    @action
    public changeAssessmentsStatusFilter(state: string) {
        this.selectedAsset.assessmentsFilters = Object.assign(this.selectedAsset.assessmentsFilters, {
            state,
            page: 0,
        });
    }

    @action
    public changeAssessmentsKeywordsFilter(keywords: string) {
        this.selectedAsset.assessmentsFilters = Object.assign(this.selectedAsset.assessmentsFilters, {
            keywords,
            page: 0,
        });
        return this.loadAssetAssessments(this.selectedAsset.id, this.selectedAsset.assessmentsFilters);
    }

    @action
    public changeAssessmentsPage(page: number) {
        this.selectedAsset.assessmentsFilters = Object.assign(this.selectedAsset.assessmentsFilters, { page });
        return this.loadAssetAssessments(this.selectedAsset.id, this.selectedAsset.assessmentsFilters);
    }

    @action
    public async loadAssetAdvisories(id: string, options: any) {
        this.loading = true;
        // console.log('options', options);
        const apiOptions = {
            pageSize: 25,
            startIndex: 0,
            keywords: null,
            finalCompliance: null,
            level: null,
        };

        if (options.keywords) apiOptions.keywords = options.keywords;
        if (options.finalCompliance && options.finalCompliance != "All")
            apiOptions.finalCompliance = options.finalCompliance;
        if (options.level && options.level != "All") apiOptions.level = options.level;
        if (options.page) apiOptions.startIndex = (options.page - 1) * this.pagination.itemsPerPage;
        if (options.pageSize) apiOptions.pageSize = options.pageSize;
        // var options = Object.assign({ page: 1, keywords: null }, query);
        // console.log("apiOptions", apiOptions);
        if (this.selectedAsset !== undefined) {
            return this.assetService
                .getAssetAdvisories(id, apiOptions)
                .then(this.loadAssetAdvisoriesOnSuccess, this.loadAssetAdvisoriesOnError);
        } else {
            return this.assetService
                .getAsset(id)
                .then(
                    await this.assetService
                        .getAssetAdvisories(id, apiOptions)
                        .then(this.loadAssetAdvisoriesOnSuccess, this.loadAssetAdvisoriesOnError),
                    this.loadAssetOnError
                );
        }
    }

    @action.bound
    public loadAssetAdvisoriesOnSuccess(result) {
        // debugger
        this.selectedAsset.advisories = result.items;
        this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
        this.loading = false;
        return result;
    }

    @action.bound
    public loadAssetAdvisoriesOnError(error) {
        //debugger
        console.error(error);
        // this.loading = false;
        return error;
    }

    @action
    public changeAdvisoriesStatusFilter(finalCompliance: string) {
        // console.log('finalCompliance', finalCompliance);
        this.selectedAsset.advisoriesFilters = Object.assign(this.selectedAsset.advisoriesFilters, {
            finalCompliance,
            page: 0,
        });
        return this.loadAssetAdvisories(this.selectedAsset.id, this.selectedAsset.advisoriesFilters);
    }

    @action
    public changeAdvisoriesSeverityFilter(level: string) {
        // console.log('severity', level);
        this.selectedAsset.advisoriesFilters = Object.assign(this.selectedAsset.advisoriesFilters, {
            level,
            page: 0,
        });
        return this.loadAssetAdvisories(this.selectedAsset.id, this.selectedAsset.advisoriesFilters);
    }

    @action
    public changeAdvisoriesKeywordFilter(keywords: string) {
        this.selectedAsset.advisoriesFilters = Object.assign(this.selectedAsset.advisoriesFilters, {
            keywords,
            page: 0,
        });
        return this.loadAssetAdvisories(this.selectedAsset.id, this.selectedAsset.advisoriesFilters);
    }

    @action
    public changeAdvisoriesPage(page: number) {
        this.selectedAsset.advisoriesFilters = Object.assign(this.selectedAsset.advisoriesFilters, {
            page,
        });
        return this.loadAssetAdvisories(this.selectedAsset.id, this.selectedAsset.advisoriesFilters);
    }
}
