import { observable, flow } from "mobx";

import { AssessmentService } from "../../../api/assessments";
import { WorkItemService } from "../../../api/workitems";

import { DataSourceStore } from "./DataSourceStore";

export class DataSourceSelectionStore {
    public parentStore: DataSourceStore;
    public assessmentService: AssessmentService;
    public workItemService: WorkItemService;

    constructor(parentStore: DataSourceStore) {
        this.parentStore = parentStore;
        this.assessmentService = new AssessmentService(this.parentStore.rootStore.authProvider);
        this.workItemService = new WorkItemService(this.parentStore.rootStore.authProvider);
    }

    @observable public dataSources: any[] = [
        {
            id: "dataSource:assessments",
            name: "Assessments",
            coreDataSourceId: "coreDataSource:assessments",
            description: "Assessment list.",
            groupByFields: null,
            query: {},
        },
        {
            id: "dataSource:assessments:openedClosed",
            name: "Assessments Opened/Closed by Months",
            coreDataSourceId: "coreDataSource:assessments:openedClosed",
            description: "Assessment list. Grouped by Open, closed",
            groupByFields: null,
            query: {},
        },
        {
            id: "dataSource:assessments:groupByCount",
            name: "Assessments Group by count",
            coreDataSourceId: "coreDataSource:assessments:groupByCount",
            description: "Assessments list. Grouped by different dimensions",
            groupByFields: null,
            query: {},
        },
        {
            id: "dataSource:advisories:groupByCount",
            name: "Advisories Group by count",
            coreDataSourceId: "coreDataSource:advisories:groupByCount",
            description: "Advisories list. Grouped by different dimensions",
            groupByFields: null,
            query: {},
        },
        {
            id: "dataSource:advisories:flat:groupByCount",
            name: "Advisories Group by controls/final",
            coreDataSourceId: "coreDataSource:advisories:flat:controlFinalCompliance",
            description: "Advisories list. Grouped by controls/final",
            groupByFields: null,
            query: {},
        },
        {
            id: "dataSource:advisories:flat:portfolios",
            name: "Advisories Group by portfolio",
            coreDataSourceId: "coreDataSource:advisories:flat:portfolio",
            description: "Advisories list. Grouped by portfolio",
            groupByFields: null,
            query: {},
        },
        {
            id: "dataSource:risk",
            name: "Risks",
            coreDataSourceId: "coreDataSource:risk",
            description: "Risk list.",
            groupByFields: null,
            query: {},
        },
        {
            id: "dataSource:risk:groupByCount",
            name: "Risks group by count",
            coreDataSourceId: "coreDataSource:risk:groupByCount",
            description: "Risks, filtered in different ways and grouped by different dimensions",
            groupByFields: null,
            query: {},
        },
        {
            id: "dataSource:workItem",
            name: "Work Items",
            coreDataSourceId: "coreDataSource:workItem",
            description: "Work Item list.",
            groupByFields: null,
            query: {},
        },
        {
            id: "dataSource:workItem:openedClosed",
            name: "Work Item Opened/Closed by Months",
            coreDataSourceId: "coreDataSource:workItem:openedClosed",
            description: "Work Item list. Grouped by Open, closed",
            groupByFields: null,
            query: {},
        },
        {
            id: "dataSource:workItem:groupByCount",
            name: "Work Item Group by count",
            coreDataSourceId: "coreDataSource:workItem:groupByCount",
            description: "Work Item list. Grouped by different dimensions",
            groupByFields: null,
            query: {},
        },
        {
            id: "dataSource:portfolios:contacts",
            name: "Portfolios contacts",
            coreDataSourceId: "coreDataSource:portfolios:contacts",
            groupByFields: null,
            query: {},
        },
        {
            id: "dataSource:controlMaps:controls",
            name: "Controls Maps",
            coreDataSourceId: "coreDataSource:controlMaps:controls",
            groupByFields: null,
            query: {},
        },
        {
            id: "dataSource:heatmap",
            name: "Heatmap",
            coreDataSourceId: "coreDataSource:heatmap",
            groupByFields: null,
            query: {},
        },
        {
            id: "dataSource:tasks",
            name: "Task List",
            coreDataSourceId: "coreDataSource:tasks",
            description: "Task list filter in different ways",
            groupByFields: null,
            query: {},
        },
        {
            id: "dataSource:exceptions",
            name: "Exceptions",
            coreDataSourceId: "coreDataSource:exceptions",
            description: "Excepions, filtered in different ways",
            groupByFields: null,
            query: {},
        },
        {
            id: "dataSource:exceptions:groupByCount",
            name: "Exceptions group by count",
            coreDataSourceId: "coreDataSource:exceptions:groupByCount",
            description: "Excepions, filtered in different ways and grouped by different dimensions",
            groupByFields: null,
            query: {},
        },
        //GROUPED DS
        {
            id: "coreDataSource:riskLevel:status",
            name: "Risk Level / Status  (Grouped) [Demo data]",
            coreDataSourceId: "coreDataSource:riskLevel:status",
            description: "portfolios:contacts",
            groupByFields: null,
            query: {},
        },

        //STATIC DATA SOURCES
        {
            id: "dataSource:activities:upcoming",
            name: "Upcoming Activities [Demo data]",
            coreDataSourceId: "coreDataSource:activities:upcoming",
            groupByFields: null,
            query: {},
        },
        {
            id: "dataSource:CISO:teamActivity",
            name: "CISO Team Activity [Demo data]",
            coreDataSourceId: "coreDataSource:CISO:teamActivity",
            groupByFields: null,
            query: {},
        },
        {
            id: "dataSource:food:keycontacts",
            name: "Food Key Contacts [Demo data]",
            coreDataSourceId: "coreDataSource:food:keycontacts",
            groupByFields: null,
            query: {},
        },
        {
            id: "dataSource:CISO:totalIncomingRequests",
            name: "CISO Total Incoming Requests [Demo data]",
            coreDataSourceId: "coreDataSource:CISO:totalIncomingRequests",
            groupByFields: null,
            query: {},
        },
        {
            id: "dataSource:CISO:engagement",
            name: "CISO Engagement  [Demo data]",
            coreDataSourceId: "coreDataSource:CISO:engagement",
            groupByFields: null,
            query: {},
        },
        {
            id: "dataSource:foodportfolio:risks",
            name: "Food Portfolio Risks  [Demo data]",
            coreDataSourceId: "coreDataSource:foodportfolio:risks",
            groupByFields: null,
            query: {},
        },
        {
            id: "dataSource:CISO:risks",
            name: "CISO Risks  [Demo data]",
            coreDataSourceId: "coreDataSource:CISO:risks",
            groupByFields: null,
            query: {},
        },
        {
            id: "dataSource:CISO:risks:totals",
            name: "CISO Risks Totals [Demo data]",
            coreDataSourceId: "coreDataSource:CISO:risks:totals",
            groupByFields: null,
            query: {},
        },
        {
            id: "dataSource:demands:open:status",
            name: "Open demands (Assessment status) [Demo data]",
            coreDataSourceId: "coreDataSource:demands:open:status",
            groupByFields: null,
            query: {},
        },
        {
            id: "dataSource:demands:open:products",
            name: "Open demands (Type) [Demo data]",
            coreDataSourceId: "coreDataSource:demands:open:products",
            query: {},
        },
        {
            id: "dataSource:demands:open:last6months",
            name: "Demands - Open/Close  [Demo data]",
            coreDataSourceId: "coreDataSource:demands",
            query: {},
        },
        {
            id: "dataSource:engagement",
            name: "Engagement level  [Demo data]",
            coreDataSourceId: "coreDataSource:engagement",
            query: {},
        },
    ];
}
