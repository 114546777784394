import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { PanelType } from 'office-ui-fabric-react/lib/Panel';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { Facepile, OverflowButtonType } from 'office-ui-fabric-react/lib/Facepile';
import { PersonaSize } from 'office-ui-fabric-react/lib/Persona';

import { ObservablePanel } from '../../../base/components/ObservablePanel';
import { ObservableSeparator } from '../../../base/components/ObservableSeparator';

import { PermissionSetGridView } from '../../components/PermissionSetGridView';

export const SharingScopesPanel = observer(
    class SharingScopesPanel extends Component {
        
        render () {
            const { visible, scopes, canShare, onShare, onRevoke } = this.props;
            
            return (
                <ObservablePanel
                    hidden={!visible}
                    pageTitle={scopes && scopes.length && scopes[0].label || 'Manage Access'}
                    pageType={'Sharing'}
                    panelType={PanelType.small}
                    onDismiss={() => this._onHidePanel()}
                    onClosePanel={() => this._onHidePanel()}
                    onRenderFooter={() => this._renderFooter()}>
                    { scopes && ( 
                        <div>
                            { scopes.map((scope, index) => (
                                <div key={scope.id} className="row--s">
                                    { index === 0 ? (
                                        <Stack horizontal horizontalAlign="space-between">
                                            <Stack.Item grow>
                                                <ObservableSeparator>Direct access</ObservableSeparator>
                                            </Stack.Item>
                                            { canShare && canShare() && (
                                                <Stack.Item>
                                                    <Facepile
                                                        className="facepile"
                                                        personas={[]}
                                                        maxDisplayablePersonas={5}
                                                        personaSize={PersonaSize.size24}
                                                        overflowButtonType={OverflowButtonType.descriptive}
                                                        showAddButton
                                                        addButtonProps={{
                                                            ariaLabel: 'Share with',
                                                            onClick: () => onShare() 
                                                        }}
                                                    />
                                                </Stack.Item>
                                            ) }                                            
                                      </Stack>
                                    ) : (
                                        <ObservableSeparator>{`Inherted from : ${scope.label}`}</ObservableSeparator>
                                    ) }
                                    <PermissionSetGridView 
                                        isHeaderVisible={false} 
                                        permissions={scope.permissions} 
                                        canShare={() => index === 0 && canShare()} 
                                        onRevoke={onRevoke} />
                                </div>
                            )) }
                        </div>
                    ) }
                </ObservablePanel>
            );
        }

        _renderFooter() {
            return (
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <div>&nbsp;</div>
                    <DefaultButton onClick={() => this._onHidePanel()} text="Close" />
                </Stack>
            );
        }

        _onHidePanel() {
            if(this.props.onClose) {
                this.props.onClose();
            }
        }
    }
);