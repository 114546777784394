import { computed, flow } from "mobx";

import { WorkItemService } from "../../../api/workitems";
import { WorkItemStore } from "./WorkItemStore";

export class WorkItemSummaryStore {
    private workItemService: WorkItemService;
    public parentStore: WorkItemStore;

    constructor(parentStore: WorkItemStore) {
        this.parentStore = parentStore;
        this.workItemService = parentStore.workItemService;
    }

    @computed
    public get workItem() {
        return this.parentStore.selectionStore.workItem;
    }

    public loadWorkItemSummary = flow(function* (workItem: any) {
        yield this.parentStore.rootStore.productStore.loadProductSchema(workItem.product.id, workItem.schemaVersion);
        yield this.parentStore.rootStore.productStore.setSelectedWithId(workItem.product.id);
        //yield this.parentStore.commentStore.loadComments(workItem.id);
    });

    public unloadWorkItemSummary = flow(function* (workItem: any) {
        //yield this.parentStore.commentStore.reset(workItem.id, true);
    });
}
