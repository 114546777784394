import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Text } from 'office-ui-fabric-react/lib/Text';
import { MessageBar } from 'office-ui-fabric-react/lib/MessageBar';
import { DetailsListLayoutMode, Selection, SelectionMode, ConstrainMode } from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { ContextualMenu } from 'office-ui-fabric-react/lib/ContextualMenu';
import { IconButton } from 'office-ui-fabric-react/lib/Button';

import { BoxPanel } from '../../../base/components/BoxPanel';
import { ListItemReveal } from '../../../base/components/ListItemReveal';
import { WrappedContent } from '../../../base/components/WrappedContent';

export const SchemaFieldGridView = observer(
    class SchemaFieldGridView extends Component {

        constructor(props) {
            super(props);

            this.state = {};

            this._selection = new Selection({
                onSelectionChanged: () => {
                  this.setState({
                    selectionDetails: this._getSelectionDetails()
                  });
                }
              });

            this._columns = [
              {
                key: 'name',
                name: 'Name',
                fieldName: 'name',
                minWidth: 120,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: 'string',
                onRender: (item) => {
                  return (
                    <WrappedContent>
                      <div>{item.name}</div>
                      <ListItemReveal>
                        { this.props.onFieldMenuClick && <IconButton iconProps={{ iconName: 'MoreVertical' }} onClick={(ev) => this.props.onFieldMenuClick(item, ev)} /> }
                      </ListItemReveal>
                      <Text variant="small" block>{item.description || 'No description provided'}</Text>
                    </WrappedContent>
                  );
                },
                isPadded: true
              },
              {
                key: 'label',
                name: 'Label',
                fieldName: 'label',
                minWidth: 270,
                isResizable: false,
                onColumnClick: this._onColumnClick,
                data: 'string',
                onRender: (item) => {
                  return (<span>{item.label}</span>);
                },
                isPadded: true
              },
              {
                key: 'type',
                name: 'Type',
                fieldName: 'type',
                minWidth: 120,
                maxWidth: 150,
                isResizable: false,
                onColumnClick: this._onColumnClick,
                data: 'string',
                onRender: (item) => {
                  return (<span>{item.type}</span>);
                },
                isPadded: true
              },
              {
                key: 'required',
                name: 'Required',
                fieldName: 'required',
                minWidth: 110,
                isResizable: false,
                onColumnClick: this._onColumnClick,
                data: 'string',
                onRender: (item) => {
                  return (<span>{item.required}</span>);
                },
                isPadded: true
              },
              {
                key: 'hidden',
                name: 'Hidden',
                fieldName: 'hidden',
                minWidth: 100,
                isResizable: false,
                onColumnClick: this._onColumnClick,
                data: 'string',
                onRender: (item) => {
                  return (<span>{item.hidden ? 'Yes' : 'No'}</span>);
                },
                isPadded: true
              },
              {
                key: 'isInternal',
                name: 'Internal',
                fieldName: 'isInternal',
                minWidth: 100,
                isResizable: false,
                onColumnClick: this._onColumnClick,
                data: 'string',
                onRender: (item) => {
                  return (<span>{item.isInternal ? 'Yes' : 'No'}</span>);
                },
                isPadded: true
              },
          ];
        }

        render () {
            const { className, fields, loading, onFieldClick, disableSelection } = this.props;
            const { contextualMenuProps } = this.state;
            
            return (
              <BoxPanel shadow>
                <ShimmeredDetailsList
                  items={fields}
                  compact={false}
                  columns={this._columns}
                  selectionMode={disableSelection ? SelectionMode.none : SelectionMode.none}
                  getKey={this._getKey}
                  setKey="single"
                  layoutMode={DetailsListLayoutMode.justified}
                  constrainMode={ConstrainMode.horizontalConstrained}
                  isHeaderVisible={true}
                  selection={this._selection}
                  selectionPreservedOnEmptyClick={true}
                  onItemInvoked={onFieldClick}
                  //onItemContextMenu={this._onItemContextMenu}
                  enterModalSelectionOnTouch={true}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  checkButtonAriaLabel="Row checkbox"
                  enableShimmer={loading}
                  removeFadingOverlay={true}
                  shimmerLines={15}
                  ariaLabelForShimmer="Page being fetched"
                  ariaLabelForGrid="Page list"
                />
                
                { ! loading && !fields.length && (
                  <MessageBar>
                    There are no matching fields.
                  </MessageBar>
                )}

                {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}
              </BoxPanel> 
            );
        }

        _getKey(item, index) {
            return item ? item.name : index;
        }

        _getSelectionDetails() {
            const selectionCount = this._selection.getSelectedCount();
        
            switch (selectionCount) {
              case 0:
                return 'No items selected';
              case 1:
                return '1 item selected: ' + (this._selection.getSelection()[0]).name;
              default:
                return `${selectionCount} items selected`;
            }
        }

        _onColumnClick(ev, column) {
            console.log(column);
        }

        _onItemContextMenu = (item, index, ev) => {
            const contextualMenuProps = {
              target: ev.target,
              items: [
                {
                  key: 'text',
                  name: `${this._selection.getSelectedCount()} selected`
                }
              ],
              onDismiss: () => {
                this.setState({
                  contextualMenuProps: undefined
                });
              }
            };
        
            if (index > -1) {
              this.setState({
                contextualMenuProps: contextualMenuProps
              });
            }
        
            return false;
        };
    }
);