import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { AppPage } from "@modules/layout/containers/AppPage";

import { PageInstanceViewer } from "@modules/pages/containers/utilities/PageInstanceViewer";

import { SettingsCardView } from "@modules/settings/components/core/SettingsCardView";
import { SettingsListNav } from "@modules/settings/containers/utilities/SettingsListNav";

export const SettingsDashboardPage = observer(() => {
    const { settingsStore, routing } = useStores();
    const { loading, settings } = settingsStore;

    const onTitleClick = useCallback(() => {
        routing.push(`/settings`);
    }, [routing]);

    const onLinkClick = useCallback(
        (item) => {
            if (item && item.url) {
                routing.push(item.url);
            }
        },
        [routing]
    );

    return (
        <AppPage
            title={"Settings"}
            onTitleClick={onTitleClick}
            hasSidebar={false}
            className="settings list has--page-nav"
        >
            <SettingsListNav />
            <div>
                <PageInstanceViewer path="/_/settings" optional={true} />
                <SettingsCardView loading={loading} settings={settings} onItemClick={onLinkClick} />
            </div>
        </AppPage>
    );
});
