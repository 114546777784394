import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";
import Moment from "react-moment";

import { Label } from "office-ui-fabric-react/lib/Label";
import { ComboBox } from "office-ui-fabric-react/lib/ComboBox";

import { AppPage } from "@modules/layout/containers/AppPage";
import { BoxPanel } from "@modules/base/components/BoxPanel";
import { FieldSetView } from "@modules/base/components/FieldSetView";

import { PersonalAccountSideNav } from "@modules/accounts/components/core/PersonalAccountSideNav";
import { PersonalAccountHeader } from "@modules/accounts/components/core/PersonalAccountHeader";

export const AccountOverviewPage = observer(() => {
    const { accountStore, themeProvider, routing } = useStores();
    const { loading, breadcrumb, error, user, photo } = accountStore.overviewStore;

    useEffect(() => {
        accountStore.overviewStore.loadOverview({});
    }, []);

    return (
        <AppPage
            error={error}
            breadcrumb={breadcrumb}
            loading={loading || !user}
            hasSidebar={false}
            className="accounts dashboard has--page-nav"
        >
            {user && (
                <>
                    <PersonalAccountSideNav user={user} photo={photo} onLinkClick={(item) => routing.push(item.url)} />

                    <>
                        <PersonalAccountHeader user={user} title="Account Overview" />

                        <BoxPanel shadow padding>
                            <FieldSetView title="User Details">
                                <r-grid columns="1" columns-l="2">
                                    <r-cell span="1">
                                        <Label>Name</Label>
                                        <div>{user.name}</div>
                                    </r-cell>
                                    <r-cell span="1">
                                        <Label>Email</Label>
                                        <div>{user.email}</div>
                                    </r-cell>
                                    <r-cell span="1">
                                        <Label>Job Title</Label>
                                        <div>{user.jobTitle}</div>
                                    </r-cell>
                                    <r-cell span="1">
                                        <Label>Department</Label>
                                        <div>{user.department || "N/A"}</div>
                                    </r-cell>
                                    <r-cell span="1">
                                        <Label>Preferred Language</Label>
                                        <div>{user.preferredLanguage || "N/A"}</div>
                                    </r-cell>
                                </r-grid>
                            </FieldSetView>
                            <FieldSetView title="Login Details">
                                <r-grid columns="1" columns-l="2">
                                    <r-cell span="1">
                                        <Label>Provider</Label>
                                        <div>{user.provider}</div>
                                    </r-cell>
                                    <r-cell span="1">
                                        <Label>UPN</Label>
                                        <div>{user.userPrincipalName}</div>
                                    </r-cell>
                                    <r-cell span="1">
                                        <Label>Active Since</Label>
                                        <div>
                                            <Moment utc date={user.created} format="Do MMM YYYY" />
                                        </div>
                                    </r-cell>
                                </r-grid>
                            </FieldSetView>
                            <FieldSetView title="App Settings">
                                <r-grid columns="1" columns-l="2">
                                    <r-cell span="1">
                                        <Label>Default Portfolio</Label>
                                        <div>{user.defaultPortfolio || "N/A"}</div>
                                    </r-cell>
                                    <r-cell span="1">
                                        <Label>Roles</Label>
                                        <div>{user.roles.join(", ")}</div>
                                    </r-cell>
                                    <r-cell span="1">
                                        <ComboBox
                                            label="Theme"
                                            defaultSelectedKey={themeProvider.name}
                                            options={themeProvider.themes}
                                            onChange={(event, option, index, value) => {
                                                themeProvider.setTheme(option.key);
                                            }}
                                        />
                                    </r-cell>
                                </r-grid>
                            </FieldSetView>
                        </BoxPanel>
                    </>
                </>
            )}
        </AppPage>
    );
});
