import { computed } from "mobx";

import { StateFlowService } from "../../../api/stateflows";
import { StateFlowSelectionStore } from "./StateFlowSelectionStore";
import { StateFlowStore } from "./StateFlowStore";

export class StateFlowPermissionStore {
    public parentStore: StateFlowStore;
    public stateFlowService: StateFlowService;
    public selectionStore: StateFlowSelectionStore;

    constructor(parentStore: StateFlowStore) {
        this.parentStore = parentStore;
        this.stateFlowService = parentStore.stateFlowService;
        this.selectionStore = parentStore.selectionStore;
    }

    @computed
    public get canEdit() {
        return true;
    }

    @computed
    public get canDelete() {
        return false;
    }
}
