import { UnknownPartPart } from "./UnknownPartPart";
import { RichTextEditorPart } from "./RichTextEditorPart";
import { ImageViewerPart } from "./ImageViewerPart";
import { ImageViewerPartEditor } from "./ImageViewerPartEditor";
import { GridCalendarPart } from "./GridCalendarPart";
import { GridCalendarPartEditor } from "./GridCalendarPartEditor";
import { ResourceTimelinePart } from "./ResourceTimelinePart";
import { ResourceTimelinePartEditor } from "./ResourceTimelinePartEditor";
import { EmbeddedContentPart } from "./EmbeddedContentPart";
import { EmbeddedContentPartEditor } from "./EmbeddedContentPartEditor";
import { BaseActionPart } from "./BaseActionPart";
import { DataSourcePartEditor } from "../../../datasources/parts/DataSourcePartEditor";
import { DatasourceViewerPart } from "../../../datasources/parts/DatasourceViewerPart";
import { DataSourcePartCanBeDeleted } from "../../../datasources/parts/DataSourcePartCanBeDeleted";
import { TablePartEditor } from "../../../datasources/parts/TablePartEditor";
import { TablePart } from "../../../datasources/parts/TablePart";
import { TablePartActions } from "../../../datasources/parts/TablePartActions";
import { ChartViewerPart } from "../../../charting/parts/ChartViewerPart";
import { ChartViewerPartEditor } from "../../../charting/parts/ChartViewerPartEditor";
import { ChartViewerActions } from "../../../charting/parts/ChartViewerActions";
import { ControlMapViewerActions } from "../../../controls/components/parts/ControlMapViewerActions";
import { ControlMapViewerPart } from "../../../controls/components/parts/ControlMapViewerPart";
import { ControlMapPartEditor } from "../../../controls/components/parts/ControlMapPartEditor";
import { RagStatusPartEditor } from "../../../rag_status/parts/RagStatusPartEditor";
import { RagStatusViewerActions } from "../../../rag_status/parts/RagStatusViewerActions";
import { RagStatusViewerPart } from "../../../rag_status/parts/RagStatusViewerPart";
import { ProgressIndicatorPartEditor } from "./manual_insight/progress_indicator/ProgressIndicatorPartEditor";
import { ProgressIndicatorViewerActions } from "./manual_insight/progress_indicator/ProgressIndicatorViewerActions";
import { ProgressIndicatorViewerPart } from "./manual_insight/progress_indicator/ProgressIndicatorViewerPart";
import { RagStatusSnapshotPartEditor } from "./manual_insight/rag_status_snapshot/RagStatusSnapshotPartEditor";
import { RagStatusSnapshotViewerPart } from "./manual_insight/rag_status_snapshot/RagStatusSnapshotViewerPart";
import { RagStatusSnapshotViewerAction } from "./manual_insight/rag_status_snapshot/RagStatusSnapshotViewerActions";
import { QuickActionsPartEditor } from "./quick_actions/QuickActionsPartEditor";
import { QuickActionsViewerActions } from "./quick_actions/QuickActionsViewerActions";
import { QuickActionsViewerPart } from "./quick_actions/QuickActionsViewerPart";
import { TaskListPartEditor } from "../../../workflows/web_parts/TaskListPartEditor";
import { TaskListViewerActions } from "../../../workflows/web_parts/TaskListViewerActions";
import { TaskListViewerPart } from "../../../workflows/web_parts/TaskListViewerPart";
import { UserInfoPartEditor } from "../../../identity/components/parts/UserInfoPartEditor";
import { UserInfoViewerPart } from "../../../identity/components/parts/UserInfoViewerPart";
import { UserInfoViewerActions } from "../../../identity/components/parts/UserInfoViewerActions";
import { HeatmapViewerPart } from "./heatmap/HeatmapViewerPart";
import { HeatmapViewerActions } from "./heatmap/HeatmapViewerActions";
import { HeatmapPartEditor } from "./heatmap/HeatmapPartEditor";

export const registrations: any = {
    "pages:core:unknown-part@1.0": {
        name: "Unknown Part",
        type: "pages:core:unknown-part",
        version: "1.0",
        selectable: false,
        control: UnknownPartPart,
        actions: BaseActionPart,
    },
    "pages:core:unknown-part@latest": "pages:core:unknown-part@1.0",
    "pages:core:rich-text-editor@1.0": {
        name: "Rich Text",
        type: "pages:core:rich-text-editor",
        description: "Add and format text",
        icon: "Font",
        version: "1.0",
        selectable: true,
        featured: true,
        visible: true,
        control: RichTextEditorPart,
        actions: BaseActionPart,
    },
    "pages:core:rich-text-editor@latest": "pages:core:rich-text-editor@1.0",
    "pages:core:image-viewer@1.0": {
        name: "Image",
        type: "pages:core:image-viewer",
        description: "Show an image",
        icon: "Photo2",
        version: "1.0",
        selectable: true,
        featured: true,
        visible: true,
        control: ImageViewerPart,
        editor: ImageViewerPartEditor,
        actions: BaseActionPart,
        validator: (part) => {
            return part && part.properties && part.properties.src && part.properties.imageFit;
        },
    },
    "pages:core:image-viewer@latest": "pages:core:image-viewer@1.0",
    "pages:core:grid-calendar@1.0": {
        name: "Calendar",
        type: "pages:core:grid-calendar",
        description: "Show a calendar",
        icon: "Calendar",
        version: "1.0",
        selectable: true,
        featured: true,
        visible: true,
        control: GridCalendarPart,
        editor: GridCalendarPartEditor,
        actions: BaseActionPart,
        validator: (part) => {
            return part && part.properties && part.properties;
        },
    },
    "pages:core:grid-calendar@latest": "pages:core:grid-calendar@1.0",
    "pages:core:resource-timeline@1.0": {
        name: "Resource Timeline",
        type: "pages:core:resource-timeline",
        description: "Show a resource timeline",
        icon: "TimelineDelivery",
        version: "1.0",
        selectable: true,
        featured: true,
        visible: true,
        control: ResourceTimelinePart,
        editor: ResourceTimelinePartEditor,
        actions: BaseActionPart,
        validator: (part) => {
            return part && part.properties && part.properties.dataSeries && part.properties.dataSeries.length > 0;
        },
    },
    "pages:core:resource-timeline@latest": "pages:core:resource-timeline@1.0",
    "pages:core:embedded-content@1.0": {
        name: "Embed",
        type: "pages:core:embedded-content",
        description: "Embed content from other sites YouTube, Vimeo, and more",
        icon: "Embed",
        version: "1.0",
        selectable: true,
        featured: true,
        visible: true,
        control: EmbeddedContentPart,
        editor: EmbeddedContentPartEditor,
        actions: BaseActionPart,
        validator: (part) => {
            return part && part.properties && part.properties.src;
        },
    },
    "pages:core:embedded-content@latest": "pages:core:embedded-content@1.0",
    "pages:core:chart-viewer@1.0": {
        name: "Chart",
        type: "pages:core:chart-viewer",
        description: "Display a chart from any datasource",
        icon: "BarChartVerticalEdit",
        version: "1.0",
        selectable: true,
        featured: true,
        visible: true,
        control: ChartViewerPart,
        editor: ChartViewerPartEditor,
        actions: ChartViewerActions,
        validator: (part) => {
            return part && part.properties && part.properties.dataSeries && part.properties.dataSeries.length > 0;
        },
    },
    "pages:core:chart-viewer@latest": "pages:core:chart-viewer@1.0",
    "pages:core:data-source@1.0": {
        name: "Data Source",
        type: "pages:core:data-source",
        description: "Get data from an external source and use it in other components",
        icon: "Database",
        version: "1.0",
        selectable: true,
        featured: true,
        visible: false,
        displayPartEditForm: false,
        invisiblePart: true,
        control: DatasourceViewerPart,
        editor: DataSourcePartEditor,
        canBeDeleted: DataSourcePartCanBeDeleted,
        validator: (part) => {
            return part && part.properties.mainSource;
        },
    },
    "pages:core:data-source@latest": "pages:core:data-source@1.0",
    "pages:core:table-viewer@1.0": {
        name: "Table",
        type: "pages:core:table-viewer",
        description: "Display any datasource as a table",
        icon: "Table",
        version: "1.0",
        selectable: true,
        featured: true,
        visible: false,
        control: TablePart,
        editor: TablePartEditor,
        actions: TablePartActions,
        validator: (part) => {
            return part && part.properties.dataSourceId;
        },
    },
    "pages:core:table-viewer@latest": "pages:core:table-viewer@1.0",
    "pages:core:control-map@1.0": {
        name: "Control Map",
        type: "pages:core:control-map",
        description: "Display a control map",
        icon: "Octagon",
        version: "1.0",
        selectable: true,
        featured: true,
        visible: false,
        control: ControlMapViewerPart,
        editor: ControlMapPartEditor,
        actions: ControlMapViewerActions,
        validator: (part) => {
            return part && part.properties.controlMapId;
        },
    },
    "pages:core:control-map@latest": "pages:core:control-map@1.0",
    "pages:core:rag-status@1.0": {
        name: "RAG Status",
        type: "pages:core:rag-status",
        description: "Display a RAG status",
        icon: "BuildQueue",
        version: "1.0",
        selectable: true,
        featured: true,
        visible: false,
        control: RagStatusViewerPart,
        editor: RagStatusPartEditor,
        actions: RagStatusViewerActions,
        validator: (part) => {
            let areDsOk = true;
            if (part.properties && part.properties.dataSeries && part.properties.dataSeries.length > 0) {
                part.properties.dataSeries.forEach((DS) => {
                    if (!DS.dataSourceId) {
                        areDsOk = false;
                    }

                    if (DS.dataDisplayType !== "count") {
                        if (!DS.dataValue) areDsOk = false;
                        if (!DS.labelField) areDsOk = false;
                    }
                    if (!DS.upperLimit) {
                        areDsOk = false;
                    }
                    if (!DS.lowerLimit) {
                        areDsOk = false;
                    }
                });
            }
            return (
                part &&
                part.properties &&
                part.properties.dataSeries &&
                part.properties.dataSeries.length > 0 &&
                areDsOk
            );
        },
    },
    "pages:core:rag-status@latest": "pages:core:rag-status@1.0",
    "pages:core:progress-indicator@1.0": {
        name: "Progress Indicator",
        type: "pages:core:progress-indicator",
        description: "Display a progress indicator",
        icon: "ImportMirrored",
        version: "1.0",
        selectable: true,
        featured: true,
        visible: false,
        control: ProgressIndicatorViewerPart,
        editor: ProgressIndicatorPartEditor,
        actions: ProgressIndicatorViewerActions,
        validator: (part) => {
            return part && part.properties;
        },
    },
    "pages:core:propgress-bar@latest": "pages:core:propgress-bar@1.0",
    "pages:core:rag-status-snapshot-indicator@1.0": {
        name: "RAG Status Snapshot",
        type: "pages:core:rag-status-snapshot-indicator",
        description: "Display a snapshot of a RAG Status",
        icon: "BuildQueue",
        version: "1.0",
        selectable: true,
        featured: true,
        visible: false,
        control: RagStatusSnapshotViewerPart,
        editor: RagStatusSnapshotPartEditor,
        actions: RagStatusSnapshotViewerAction,
        validator: (part) => {
            return part && part.properties;
        },
    },
    "pages:core:rag-status-snapshot-indicator@latest": "pages:core:rag-status-snapshot-indicator@1.0",
    "pages:core:task-list@1.0": {
        name: "Task List",
        type: "pages:core:task-list",
        description: "Display a list of tasks",
        icon: "TaskSolid",
        version: "1.0",
        selectable: true,
        featured: true,
        visible: false,
        control: TaskListViewerPart,
        editor: TaskListPartEditor,
        actions: TaskListViewerActions,
        validator: (part) => {
            return part && part.properties;
        },
    },
    "pages:core:task-list@latest": "pages:core:task-list@1.0",
    "pages:core:quick-actions@1.0": {
        name: "Quick Actions",
        type: "pages:core:quick-actions",
        description: "Display a list of actions",
        icon: "AddLink",
        version: "1.0",
        selectable: true,
        featured: true,
        visible: false,
        control: QuickActionsViewerPart,
        editor: QuickActionsPartEditor,
        actions: QuickActionsViewerActions,
        validator: (part) => {
            return part && part.properties;
        },
    },
    "pages:core:quick-actions@latest": "pages:core:quick-actions@1.0",
    "pages:core:user-info@1.0": {
        name: "User information",
        type: "pages:core:user-info",
        description: "Display a user info",
        icon: "ProfileSearch",
        version: "1.0",
        selectable: true,
        featured: true,
        visible: false,
        control: UserInfoViewerPart,
        editor: UserInfoPartEditor,
        actions: UserInfoViewerActions,
        validator: (part) => {
            return part && part.properties;
        },
    },
    "pages:core:user-info@latest": "pages:core:user-info@1.0",
    "pages:core:heatmap@1.0": {
        name: "Heatmap",
        type: "pages:core:heatmap",
        description: "Display a heatmap",
        icon: "GridViewSmall",
        version: "1.0",
        selectable: true,
        featured: true,
        visible: false,
        control: HeatmapViewerPart,
        editor: HeatmapPartEditor,
        actions: HeatmapViewerActions,
        validator: (part) => {
            return part && part.properties;
        },
    },
    "pages:core:heatmap@latest": "pages:core:heatmap@1.0",
};
