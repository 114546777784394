import React from 'react';
import { 
    ComboBox as FabricComboBox
} from 'office-ui-fabric-react/lib/ComboBox';

const ComboBox = (props) => {

    return (
        <FabricComboBox 
            {...props}
        />
    );
}

export { ComboBox }