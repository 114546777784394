import { observable, flow } from "mobx";

import { WorkflowService } from "../../../api/workflows";
import { PaginationStore } from "../../base/PaginationStore";
import { WorkflowStore } from "./WorkflowStore";

export class WorkflowBrowseStore {
    public parentStore: WorkflowStore;
    public workflowService: WorkflowService;

    @observable public instances: any[] = [];
    // @observable public summary: any = { overdueCount: 0, pendingCount: 0, personalCount: 0, totalCount: 0 };
    @observable public loading: boolean = false;
    @observable public query: any = {};
    @observable public pagination: PaginationStore;
    @observable public error: any = null;

    constructor(parentStore: WorkflowStore) {
        this.parentStore = parentStore;
        this.workflowService = parentStore.workflowService;
        this.pagination = new PaginationStore();
    }

    public reloadActivityInstances = flow(function* () {
        return yield this.loadActivityInstances({ query: this.query });
    });

    public loadActivityInstances = flow(function* (options: any) {
        const o = Object.assign({ page: 1, query: { keywords: null } }, options);

        this.loading = true;
        this.instances = [];
        this.query = o.query;
        this.error = null;

        const scope = o.context ? [o.context] : o.scopes || [];
        if (o.manifest && o.manifest.states) {
            o.manifest.states.forEach((state) => {
                if (state.triggers) {
                    state.triggers.forEach((trigger) => {
                        scope.push({ id: trigger.id, type: "trigger" });
                    });
                }
            });
        }

        try {
            const result = yield this.workflowService.searchActivityInstances({
                pageSize: this.pagination.itemsPerPage,
                startIndex: ((o.page || 1) - 1) * this.pagination.itemsPerPage,
                query: { ...o.query, scope: scope, expandScopes: !!o.context },
            });

            this.instances = result.items;
            this.query = result.query;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
            return result;
        } catch (e) {
            this.error = e;
            console.error(e);
        } finally {
            this.loading = false;
        }
    });

    public reset = flow(function* (options: any) {
        this.loading = false;
        this.instances = [];
        this.query = {};
        this.error = null;
    });

    // public getActivityInstanceSummary = flow(function* (entityId: string) {
    //     //this.taskSummary = { overdueCount: 0, pendingCount: 0, personalCount: 0, totalCount: 0 };
    //     try {
    //         const result = yield this.workflowService.getTaskSummary(entityId);
    //         this.summary = result.tasks;
    //     } catch (e) {
    //         this.error = e;
    //         console.log(e);
    //     }
    // });
}
