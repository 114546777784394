import React from "react";
import { observer } from "mobx-react";

import { Stack } from "@ui/elements/Stack";
import { Text } from "@ui/elements/Text";

import { EntityIcon } from "@modules/base/components/EntityIcon";

export const RiskTypeListItem = observer(({ className, type, onClick }) => {
    return (
        <Stack
            className={className}
            tokens={{ childrenGap: 20 }}
            horizontalAlign="center"
            onClick={() => onClick(type)}
        >
            <EntityIcon type="risk" size={65} />
            <Text variant="mediumPlus" style={{ fontWeight: "600" }}>
                {type.name}
            </Text>
            <Stack.Item grow={1}>
                <Text variant="small" className="description">
                    {type.summary}
                </Text>
            </Stack.Item>
        </Stack>
    );
});
