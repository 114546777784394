import React, { Component } from "react";
import { cx, css } from "emotion";

import { Text } from "office-ui-fabric-react/lib/Text";
import { Image, ImageFit } from "office-ui-fabric-react/lib/Image";

import { NavigationList } from "@baseComponents/NavigationList";

const rootClassName = cx(
    "cygraph-PersonalAccountSideNav-root",
    css`
        & .container {
            padding: 10px 0;
            margin-bottom: 20px;
        }
        & .sidebar--inner {
            display: flex;
            flex-direction: column;
        }
        & .navigation {
            flex-grow: 1;
            margin-bottom: 15px;
            overflow: auto;
        }
        & .launch-pad button {
            width: 100%;
            text-align: left;
            height: 40px;
            font-size: 15px;
        }
        & .avatar {
            border-radius: 50%;
            margin: auto;
        }
        & .name {
            margin: auto;
            text-align: center;
            margin-top: 10px;
        }
    `
);

export class PersonalAccountSideNav extends Component {
    render() {
        const { className, loading, user, photo } = this.props;

        const navLinkGroups = [
            {
                name: "Account",
                key: "/account",
                url: "/account/overview",
                hideGrouping: true,
                links: [
                    {
                        name: "Account Overview",
                        key: "/account/overview",
                        url: "/account/overview",
                        iconProps: { iconName: "AccountManagement" },
                    },
                    {
                        name: "My Tasks",
                        key: "/account/tasks",
                        url: "/account/tasks",
                        iconProps: { iconName: "EventToDoLogo" },
                    },
                    {
                        name: "Notifications",
                        key: "/account/notifications",
                        url: "/account/notifications",
                        disabled: true,
                        iconProps: { iconName: "CannedChat" },
                    },
                ],
            },
        ];

        return loading ? null : (
            <div className={cx(rootClassName, className, "sidebar sidebar--left sidebar--nav")}>
                <div className="sidebar--inner scrollbar--root">
                    <div className="container">
                        {photo && <Image className="avatar" src={photo} imageFit={ImageFit.contain} width={160} height={160} />}
                        {!photo && <Image className="avatar" src="/images/account.png" imageFit={ImageFit.contain} width={160} height={160} />}
                        <Text className="name" variant="mediumPlus" block>
                        {user.name}
                        </Text>
                    </div>

                    <div className="navigation scrollbar--root">
                        <NavigationList
                            onLinkClick={this.props.onLinkClick}
                            ariaLabel="Account navigation"
                            groups={navLinkGroups}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
