import React from "react";
import { observer } from "mobx-react";

export const StateFlowTarget = observer(({ value }) => {
    if (!value) {
        return null;
    }

    switch (value) {
        case "RiskLifecycle":
            return <span>Risk</span>;
        case "WorkItem":
            return <span>Demand</span>;
    }

    return null;
});
