import { observable, flow, computed, action } from "mobx";

import { PaginationStore } from "../../base/PaginationStore";
import { PageService } from '../../../api/pages';
import { PageStore } from "./PageStore";

export class PageBrowseStore {

    public parentStore: PageStore;
    public pageService: PageService;

    private targetElement: any;

    @observable public loading: boolean = false;
    @observable public pages: any[] = [];
    @observable public path: any[] = [];
    @observable public keywords: string;
    @observable public parent: any;
    @observable public selectedPage: any = null;
    @observable public pagination: PaginationStore;
    @observable public error: any;

    constructor(parentStore: PageStore) {
        this.parentStore = parentStore;
        this.pageService = parentStore.pageService;
        this.pagination = new PaginationStore();
    }

    @action
    public onPageSelected(page: any, ev: any) {
        this.targetElement = ev.target;
        this.selectedPage = page;
    }

    @action
    public onPageUnselected() {
        this.targetElement = null;
        this.selectedPage = null;
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [];

        builder.push({ text: 'Settings', key: 'settings', onClick: () => this.parentStore.rootStore.routing.push(`/settings`) });
        builder.push({ text: 'Page Libraries', key: 'libraries', onClick: () => this.parentStore.rootStore.routing.push(`/settings/page-libraries`) });

        if(this.parent) {
            this.path.forEach((node, index) => {
                builder.push({ 
                    text: node.title, 
                    key: node.id, 
                    onClick: node.id === this.parent.id ? null : () => this.parentStore.rootStore.routing.push(`/settings/pages/${node.libraryId}/${node.id}`),
                    isCurrentItem: node.id === this.parent.id
                });
            });
        }

        return builder;
    }

    public loadPages = flow(function*(query: any) {
        this.loading = true;
        const options = Object.assign({ page: 1, keywords: null }, query);

        this.pages = [];
        this.path = [];
        this.keywords = null;
        this.parent = null;
        this.error = null;
        
        try {
            const result = yield this.pageService.getPages({ 
                pageSize: this.pagination.itemsPerPage, 
                startIndex: (options.page - 1) *  this.pagination.itemsPerPage,
                keywords: options.keywords,
                parentId: options.parentId,
            });
            this.pages = result.items;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
            this.keywords = result.keywords;

            this.parent = yield this.pageService.getPageContent(options.parentId, false);

            this.path = yield this.pageService.getPageBreadcrumb({
                libraryId: options.libraryId,
                pageId: options.parentId,
                maxLevel: 10
            });            
        } catch (e) {
            this.error = e;
            console.error(e)
        } finally {
            this.loading = false;
        }
    });

    public onAddPage = flow(function*(query: any) {
        this.parentStore.editFormStore.show({ parent: this.parent }).then(child => {
            this.pages = [child, ...this.pages];
        });
    });
}
    