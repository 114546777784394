import React from "react";

import { View } from "@react-pdf/renderer";
import { getDefaultBorderIncludes } from "./Utils";

export const TableRow = (props) => {
    const rowCells = React.Children.toArray(props.children);
    const { includeLeftBorder, includeBottomBorder, includeRightBorder, includeTopBorder } = getDefaultBorderIncludes(props);

    let remainingWeighting = 1;
    let numberOfWeightingsDefined = 0;
    rowCells.forEach((i) => {
        if (i.props.weighting !== undefined) {
            remainingWeighting -= i.props.weighting;
            numberOfWeightingsDefined++;
        }
    });

    const weightingsPerNotSpecified = Math.ceil(remainingWeighting / (rowCells.length - numberOfWeightingsDefined));

    return (
        <View
            style={{
                borderBottom: includeBottomBorder && "1pt solid #e6e6e6",
                borderRight: includeRightBorder && "1pt solid #e6e6e6",
                borderLeft: includeLeftBorder && "1pt solid #e6e6e6",
                borderTop: includeTopBorder && "1pt solid #e6e6e6",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "stretch",
                marginTop: -1,
            }}
            wrap={false}
        >
            {rowCells.map((rc, columnIndex) =>
                React.cloneElement(rc, {
                    weighting: rc.props.weighting ?? weightingsPerNotSpecified,
                    data: props.data,
                    key: columnIndex,
                    fontSize: props.fontSize,
                    textAlign: props.textAlign,
                    includeLeftBorder: columnIndex === 0,
                    includeRightBorder: columnIndex !== rowCells.length - 1,
                })
            )}
        </View>
    );
};
