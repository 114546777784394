import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';

import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction'

import { MessageBar } from 'office-ui-fabric-react/lib/MessageBar';
import { Persona, PersonaSize } from 'office-ui-fabric-react/lib/Persona';

import { BoxPanel } from '@baseComponents/BoxPanel';
import { PhotoPersona } from "@identity/containers/utilities/PhotoPersona";
import { ThresholdIndicator } from '@baseComponents/ThresholdIndicator';

const rootClassName = cx(
  'cygraph-AssessmentWorkloadView-root',
  css`
    & .fc-timeline-event.fc-event.workload {
      border: 0;
      background: var(--white);
    }
    & .fc-theme-standard td, 
    & .fc-theme-standard th {
      border-left: 0;
    }
    & .user {
      padding-left 5px;
      display: inline-flex;
    }

    & .portfolio {
      margin-left: -13px;
      display: inline-block;
      min-height: 30px;
    }
  `
);

export const AssessmentWorkloadView = observer(
    class AssessmentWorkloadView extends Component {

      render () {
          const { className, workload, resources, loading, view, date, onDatesSet, onEventClick } = this.props;
          
          return (
            <BoxPanel className={cx(rootClassName, className)} shadow padding>
              <FullCalendar
                schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                height={resources.length == 0 ? 300 : "auto"}
                plugins={[ resourceTimelinePlugin, interactionPlugin ]}
                initialView={`resourceTimeline${view || 'Month'}`}
                weekends={false}
                expandRows={true}
                initialDate={date}
                headerToolbar={{
                  left: 'prev,next today',
                  center: 'title',
                  right: 'resourceTimelineWeek,resourceTimelineMonth'
                }}
                eventClick={onEventClick}
                dateClick={(info) => {
                  const calendarApi = info.view.calendar;
                  calendarApi.changeView('resourceTimelineWeek', info.date);
                }}
                datesSet={onDatesSet}
                events={workload}
                resources={resources}
                slotMinWidth={100}
                slotDuration={{days: 1}}
                resourceAreaWidth="25%"
                resourceOrder="index"
                eventContent={this.renderWorkload}
                eventClassNames="workload"
                resourceLabelContent={this.renderResource}
              />
              { ! loading && !workload.length && (
                <MessageBar>
                  There are no matching assessments.
                </MessageBar>
              )}

            </BoxPanel> 
          );
      }

      renderWorkload = (args) => {
        return <ThresholdIndicator threshold={6} current={args.event.extendedProps.matches.length} />;
      }

      renderResource = (args) => {
        const user = args.resource.extendedProps.user;

        return (
          <div className="user">
            <PhotoPersona
              principalId={user ? user.id : null}
              size={PersonaSize.size32} 
              text={user ? user.name : 'Unassigned'} 
              showUnknownPersonaCoin={!user}
              showSecondaryText={user} 
              secondaryText={user && user.email ? user.email : 'Unknown'} />
          </div>
        );
      };
    }
);