import { observable, flow, action, computed } from "mobx";
import { v4 as uuidv4 } from "uuid";

import { ModellingService } from "../../../api/modelling";
import { PatternStore } from "./PatternStore";
import { PatternJsonEditorStore } from "./PatternJsonEditorStore";
import { PatternModuleEditStore } from "./PatternModuleEditStore";
import { PatternPublishStore } from "./PatternPublishStore";
import { PatternAdvisoryListStore } from "./PatternAdvisoryListStore";
import { PatternAdvisoryEditStore } from "./PatternAdvisoryEditStore";

export class PatternEditorStore {
    public parentStore: PatternStore;
    public modellingService: ModellingService;
    public jsonEditorStore: PatternJsonEditorStore;
    public moduleEditStore: PatternModuleEditStore;
    public publishStore: PatternPublishStore;
    public advisoryListStore: PatternAdvisoryListStore;
    public advisoryEditStore: PatternAdvisoryEditStore;

    @observable public manifest: any;
    @observable public snapshot: any;
    @observable public saving: boolean = false;
    @observable public isEditMode: boolean = false;

    constructor(parentStore: PatternStore) {
        this.parentStore = parentStore;
        this.modellingService = parentStore.modellingService;
        this.jsonEditorStore = new PatternJsonEditorStore(this);
        this.moduleEditStore = new PatternModuleEditStore(this);
        this.publishStore = new PatternPublishStore(this);
        this.advisoryListStore = new PatternAdvisoryListStore(this);
        this.advisoryEditStore = new PatternAdvisoryEditStore(this);
    }

    public getPathToMeasure(measure) {
        const modules = this.manifest.modules;
        for (let i = 0; i < modules.length; i++) {
            for (let j = 0; j < modules[i].groups.length; j++) {
                for (let k = 0; k < modules[i].groups[j].measures.length; k++) {
                    if (modules[i].groups[j].measures[k].id === measure.id) {
                        return {
                            module: modules[i],
                            group: modules[i].groups[j],
                            measure: modules[i].groups[j].measures[k],
                        };
                    }
                }
            }
        }
    }

    @action
    public setEditMode(isEditMode) {
        this.isEditMode = isEditMode;
    }

    @action
    public onResetManifest(options) {
        this.manifest = JSON.parse(JSON.stringify(this.snapshot));
    }

    @computed
    public get advisoryMap() {
        const mapping = {};
        if (this.manifest && this.manifest.advisories) {
            this.manifest.advisories.forEach((advisory) => {
                const map = mapping[advisory.measureId] || [];
                map.push(advisory);
                mapping[advisory.measureId] = map;
            });
        }
        return mapping;
    }

    public onListAdvisories = flow(function* (options) {
        yield this.advisoryListStore.show(options);
    });

    public onAddAdvisory = flow(function* (options) {
        const { success, advisory } = yield this.advisoryEditStore.show({
            title: options.measure.title,
            measure: options.measure,
        });

        if (success) {
            advisory.id = uuidv4();
            advisory.name = `a${advisory.id.replaceAll("-", "")}`;

            this.manifest.advisories = [...this.manifest.advisories, advisory];
        }
    });

    public onEditAdvisory = flow(function* (options) {
        const current = this.manifest.advisories.find((a) => a.id == options.advisory.id);
        const path = this.getPathToMeasure({ id: current.measureId });

        const { success, advisory } = yield this.advisoryEditStore.show({
            title: path.measure.title,
            ...path,
            advisory: current,
        });

        if (success) {
            const currentIndex = this.manifest.advisories.findIndex((a) => a.id == options.advisory.id);
            this.manifest.advisories[currentIndex] = advisory;
        }
    });

    public onRemoveAdvisory = flow(function* (options) {
        const indexOfAdvisory = this.manifest.advisories.findIndex((a) => a.id == options.advisory.id);

        if (indexOfAdvisory !== -1) {
            this.manifest.advisories.splice(indexOfAdvisory, 1);
        }
    });

    @action
    public onAddOption(options) {
        const module = this.manifest.modules.find((m) => m.id == options.module.id);
        const group = module.groups.find((g: any) => g.id == options.group.id);
        const measure = group.measures.find((m: any) => m.id == options.measure.id);

        measure.options = [...measure.options, { key: options.option.key, ...options.option }];

        return measure;
    }

    @action
    public onUpdateOption(options) {
        const module = this.manifest.modules.find((m) => m.id == options.module.id);
        const group = module.groups.find((g: any) => g.id == options.group.id);
        const measure = group.measures.find((m: any) => m.id == options.measure.id);
        const option = measure.options.find((o: any) => o.key == options.option.key);
        const indexOfOption = measure.options.findIndex((o: any) => o.key == options.option.key);
        if (indexOfOption !== -1) {
            measure.options[indexOfOption] = { ...option, ...options.option };
        }
        return measure;
    }

    @action
    public onRemoveOption(options) {
        const module = this.manifest.modules.find((m) => m.id == options.module.id);
        const group = module.groups.find((g: any) => g.id == options.group.id);
        const measure = group.measures.find((m: any) => m.id == options.measure.id);
        const indexOfOption = measure.options.findIndex((o: any) => o.key == options.option.key);
        if (indexOfOption !== -1) {
            measure.options.splice(indexOfOption, 1);
        }
        return measure;
    }

    @action
    public onMoveMeasure(options) {
        const module = this.manifest.modules.find((m) => m.id == options.module.id);

        const sourceGroup = module.groups.find((g) => g.id == options.group.id);
        const indexOfMeasure = sourceGroup.measures.findIndex((m) => m.id == options.measure.id);

        if (indexOfMeasure !== -1) {
            const sourceMeasures = Array.from(sourceGroup.measures);
            const [removed] = sourceMeasures.splice(indexOfMeasure, 1);
            sourceGroup.measures = sourceMeasures;

            const destinationGroup = module.groups.find((g) => g.id == options.destination.group.id);
            const destinationMeasures = Array.from(destinationGroup.measures);
            destinationMeasures.splice(options.destination.index, 0, removed);
            destinationGroup.measures = destinationMeasures;
        }
    }

    @action
    public onAddMeasure(options) {
        const module = this.manifest.modules.find((m) => m.id == options.module.id);
        const group = module.groups.find((g: any) => g.id == options.group.id);

        const id = uuidv4();
        const measure = {
            ...{
                artifactIds: null,
                description: null,
                display: "Default",
                enabled: null,
                required: null,
                source: null,
                name: `m${id.replaceAll("-", "")}`,
            },
            ...options.measure,
            id: id,
        };

        group.measures = [...group.measures, measure];

        return measure;
    }

    public onEditMeasure = flow(function* (options) {
        const module = this.manifest.modules.find((m) => m.id == options.module.id);
        const group = module.groups.find((g: any) => g.id == options.group.id);
        const measure = group.measures.find((m: any) => m.id == options.measure.id);

        const { success, value } = yield this.jsonEditorStore.show({ title: measure.title, value: measure });
        if (success) {
            this.onUpdateMeasure({
                module,
                group,
                measure: value,
            });
        }
    });

    @action
    public onUpdateMeasure(options) {
        const module = this.manifest.modules.find((m) => m.id == options.module.id);
        const group = module.groups.find((g: any) => g.id == options.group.id);
        const measure = group.measures.find((m: any) => m.id == options.measure.id);
        const indexOfMeasure = group.measures.findIndex((m: any) => m.id == options.measure.id);
        if (indexOfMeasure !== -1) {
            group.measures[indexOfMeasure] = { ...measure, ...options.measure };
        }
    }

    @action
    public onRemoveMeasure(options) {
        if (this.advisoryMap[options.measure.id] && this.advisoryMap[options.measure.id].length > 0) {
            this.parentStore.rootStore.layoutStore.displayToastNotification(
                `Question can not be removed as there are advisories depending on this. Please remove the related advisories first.`
            );
            return;
        }

        const module = this.manifest.modules.find((m) => m.id == options.module.id);
        const group = module.groups.find((g: any) => g.id == options.group.id);

        const measures = Array.from(group.measures);
        const indexOfMeasure = measures.findIndex((m: any) => m.id == options.measure.id);
        measures.splice(indexOfMeasure, 1);
        group.measures = measures;
    }

    @action
    public onAddGroup(options) {
        const module = this.manifest.modules.find((m) => m.id == options.module.id);

        const id = uuidv4();
        const newGroup = {
            id: id,
            name: `g${id.replaceAll("-", "")}`,
            title: "New Section",
            condition: "true",
            layout: "Vertical",
            measures: [],
        };

        const groups = Array.from(module.groups);
        const indexOfGroup = groups.findIndex((g: any) => g.id == options.group.id);

        if (options.location == "before") {
            groups.splice(indexOfGroup, 0, newGroup);
        } else if (options.location == "after") {
            groups.splice(indexOfGroup + 1, 0, newGroup);
        } else {
            groups.push(newGroup);
        }

        module.groups = groups;
    }

    public onEditGroup = flow(function* (options) {
        const module = this.manifest.modules.find((m) => m.id == options.module.id);
        const group = module.groups.find((g: any) => g.id == options.group.id);

        const { success, value } = yield this.jsonEditorStore.show({ title: group.title, value: group });
        if (success) {
            this.onUpdateGroup({
                module,
                group: value,
            });
        }
    });

    @action
    public onUpdateGroup(options) {
        const module = this.manifest.modules.find((m) => m.id == options.module.id);
        const group = module.groups.find((g: any) => g.id == options.group.id);
        const indexOfGroup = module.groups.findIndex((g: any) => g.id == options.group.id);
        if (indexOfGroup !== -1) {
            module.groups[indexOfGroup] = { ...group, ...options.group };
        }
    }

    @action
    public onMoveGroup(options) {
        const module = this.manifest.modules.find((m) => m.id == options.module.id);
        const sourceIndex = module.groups.findIndex((g) => g.id == options.group.id);

        if (sourceIndex !== -1) {
            const groups = Array.from(module.groups);
            const [removed] = groups.splice(sourceIndex, 1);
            groups.splice(options.destination.index, 0, removed);
            module.groups = groups;
        }
    }

    @action
    public onDuplicateGroup(options) {
        const module = this.manifest.modules.find((m) => m.id == options.module.id);
        const newGroup = JSON.parse(JSON.stringify(options.group));
        newGroup.id = uuidv4();
        newGroup.name = `g${newGroup.id.replaceAll("-", "")}`;

        newGroup.measures.forEach((measure) => {
            measure.id = uuidv4();
            measure.name = `m${measure.id.replaceAll("-", "")}`;
        });

        const groups = Array.from(module.groups);
        const indexOfGroup = groups.findIndex((g: any) => g.id == options.group.id);
        groups.splice(indexOfGroup, 0, newGroup);
        module.groups = groups;
    }

    @action
    public onRemoveGroup(options) {
        const module = this.manifest.modules.find((m) => m.id == options.module.id);
        const groups = Array.from(module.groups);
        const indexOfGroup = groups.findIndex((g: any) => g.id == options.group.id);
        groups.splice(indexOfGroup, 1);
        module.groups = groups;
    }

    @action
    public onMoveToModule(options) {
        const module = this.manifest.modules.find((m) => m.id == options.module.id);
        const sourceIndex = module.groups.findIndex((g) => g.id == options.group.id);

        if (sourceIndex !== -1) {
            const groups = Array.from(module.groups);
            const [removed] = groups.splice(sourceIndex, 1);
            module.groups = groups;

            const destination = this.manifest.modules.find((m) => m.id == options.destination.id);
            const destinationGroups = Array.from(destination.groups);

            if (options.location == "start") {
                destinationGroups.splice(0, 0, removed);
            } else {
                destinationGroups.push(removed);
            }
            destination.groups = destinationGroups;
        }
    }

    public onAddModule = flow(function* (options) {
        const id = uuidv4();

        const { success, module } = yield this.moduleEditStore.show({
            title: this.manifest.name,
            module: {
                condition: "true",
                groups: [],
                name: `m${id.replaceAll("-", "")}`,
                title: null,
                ...options.module,
                id: id,
            },
        });

        if (success) {
            this.manifest.modules.push(module);
        }
    });

    public onEditModule = flow(function* (options) {
        const module = this.manifest.modules.find((m) => m.id == options.module.id);

        const { success, value } = yield this.jsonEditorStore.show({ title: module.title, value: module });
        if (success) {
            this.onUpdateModule({
                module: value,
            });
        }
    });

    @action
    public onUpdateModule(options) {
        const module = this.manifest.modules.find((m) => m.id == options.module.id);
        const indexOfModule = this.manifest.modules.findIndex((m: any) => m.id == options.module.id);
        if (indexOfModule !== -1) {
            this.manifest.modules[indexOfModule] = { ...module, ...options.module };
        }
    }

    @action
    public onRemoveModule(options) {
        const modules = Array.from(this.manifest.modules);
        const indexOfModule = modules.findIndex((m: any) => m.id == options.module.id);
        modules.splice(indexOfModule, 1);
        this.manifest.modules = modules;
    }

    public onSave = flow(function* (options) {
        const { success, value } = yield this.publishStore.show({
            title: this.manifest.name,
            value: {
                mode: options.node,
                notes: options.notes,
            },
        });

        if (success) {
            console.log(value);
            yield this.savePattern(value.mode, value.notes);
        }
    });

    @computed
    public get isDirty() {
        return JSON.stringify(this.snapshot) != JSON.stringify(this.manifest);
    }

    public prepare = flow(function* (manifest) {
        this.snapshot = manifest;
        this.manifest = JSON.parse(JSON.stringify(manifest));
    });

    public reset = flow(function* () {
        const isDirty = this.isDirty;
        this.manifest = JSON.parse(JSON.stringify(this.snapshot));
        this.isEditMode = false;

        if (isDirty) {
            this.parentStore.rootStore.layoutStore.displayToastNotification(
                `Any uncommitted pattern changes discarded, showing version ${this.manifest.version}`
            );
        }
    });

    public savePattern = flow(function* (mode, notes = null) {
        this.saving = true;

        try {
            const pattern = yield this.modellingService.updatePattern({
                mode,
                notes,
                entity: this.manifest,
            });

            const manifest = yield this.modellingService.getPatternManifest(pattern.id, pattern.currentVersion);
            this.prepare(manifest);

            this.parentStore.rootStore.layoutStore.displayToastNotification(`Pattern updated to version ${pattern.currentVersion}`);
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.saving = false;
        }
    });
}
