import React from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { Modal } from "office-ui-fabric-react/lib/Modal";

const modalClassName = cx(
    "cygraph-BigModal-root",
    css`
        .ms-Dialog-main {
            width: 90%;
            height: 90%;
            padding: 25px;

            & .ms-Modal-scrollableContent {
                height: 100%;
                max-height: 100%;
                overflow: hidden;
            }

            & .modalInternalContainer {
                height: 100%;
                overflow-y: auto;
                padding: 0 10px;
            }
        }
    `
);

export const BigModal = observer(({ children, className, ...rest }) => {
    return (
        <Modal className={cx(modalClassName, className)} {...rest}>
            <div className="modalInternalContainer">{children}</div>
        </Modal>
    );
});
