import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { PanelType } from 'office-ui-fabric-react/lib/Panel';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { ObservablePanel } from '../../../base/components/ObservablePanel';
import { PortfolioEditForm } from '../../components/core/PortfolioEditForm';

export const PortfolioNewPanel = observer(
    class PortfolioNewPanel extends Component {
        
        render () {
            const { visible, formData, isValid } = this.props.store;
            
            return (
                <ObservablePanel
                    hidden={!visible}
                    pageType={'New Portfolio'}
                    panelType={PanelType.small}
                    onClosePanel={() => this._onHidePanel()}
                    onRenderFooter={() => this._renderFooter()}>
                    { formData && ( 
                        <PortfolioEditForm formData={formData} isValid={isValid} />
                    ) }
                </ObservablePanel>
            );
        }

        _renderFooter() {
            const { isValid, saving } = this.props.store;

            return (
                <Stack horizontal horizontalAlign="space-between">
                    <PrimaryButton onClick={() => this._onSaveSection()} text="Save" disabled={!isValid || saving} />
                    <DefaultButton onClick={() => this._onHidePanel()} text="Cancel" disabled={saving} />
                </Stack>
            );
        }

        _onSaveSection() {
            const { formData, isValid, saving } = this.props.store;

            if(isValid && !saving) {
                this.props.store.createPortfolio(formData).then((portfolio) => {
                    if(this.props.onAfterSave) {
                        if(this.props.onAfterSave(portfolio) === true) {
                            this.props.store.hide();
                        }
                    } else {
                        this.props.store.hide();
                    }
                });
            }
        }

        _onHidePanel() {
            const { saving } = this.props.store;

            if(!saving) {
                this.props.store.hide();
            }
        }
    }
);
