import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";

import { Label } from "office-ui-fabric-react/lib/Label";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { Text } from "office-ui-fabric-react/lib/Text";
import { Toggle } from "office-ui-fabric-react/lib/Toggle";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { Dropdown } from "office-ui-fabric-react/lib/Dropdown";

import { BigTextField } from "../../../base/components/BigTextField";
import { ObservableSeparator } from "../../../base/components/ObservableSeparator";
import { PrincipalPicker } from "../../../identity/containers/PrincipalPicker";
import { RichDropdown } from "../../../base/richdropdown/RichDropdown";
import { StateFlowPicker } from "../../../stateflows/containers/pickers/StateFlowPicker";
import { RichTextField } from "../../../editors/components/RichTextField";
import { RegistryPicker } from "../../containers/pickers/RegistryPicker";
import { GroupDropDown } from "../../../identity/containers/pickers/GroupDropDown";
import { MarkdownField } from "@modules/editors/components/MarkdownField";

import { AutoAssignmentModes, autoAssignmentModeOrDefault, templates } from "../../stores/RiskContants";
import { UserDropDown } from "../../../identity/containers/pickers/UserDropDown";

function computeCodePreview(prefix, suffix, paddedZeros, numberIndex) {
    var pZeros = "";
    for (let i = 0; i < parseInt(paddedZeros) - numberIndex.length; i++) {
        pZeros += "0";
    }
    return prefix + pZeros + numberIndex + suffix;
}

function assembleCodePreview(prefix, suffix, paddedZeros) {
    if (parseInt(paddedZeros) > 0) {
        return `${prefix}{0:D${paddedZeros}}${suffix}`;
    } else {
        return prefix + "{0}" + suffix;
    }
}

export const RiskTypeForm = observer((props) => {
    const { formData, isEdit } = props;
    const [prefix, setPrefix] = useState("");
    const [suffix, setSuffix] = useState("");
    const [paddedZeros, setPaddedZeros] = useState("0");

    useEffect(() => {
        if (formData && formData.codeFormat) {
            setPrefix(formData.codeFormat.substr(0, formData.codeFormat.indexOf("{")));
            setSuffix(formData.codeFormat.substr(formData.codeFormat.indexOf("}") + 1, formData.codeFormat.length));
            let midSection = formData.codeFormat.substr(formData.codeFormat.indexOf("{") + 1);
            midSection = midSection.substr(0, formData.codeFormat.indexOf("}"));
            if (midSection.indexOf(":D") > 0) {
                let numberOfZeros = midSection.split(":D");
                numberOfZeros = numberOfZeros[1].split("}");
                setPaddedZeros(numberOfZeros[0]);
            } else {
                setPaddedZeros("0");
            }
        }
    }, [formData]);

    return (
        <>
            <r-grid columns="2">
                <r-cell span="2">
                    <BigTextField
                        label="Name"
                        required
                        placeholder="Please enter risk type name"
                        value={formData && formData.name}
                        transparent
                        onChange={(ev, value) => {
                            formData.name = value;
                        }}
                    />
                </r-cell>
                <r-cell span="2">
                    <Toggle
                        label="Inherit code format from Risk Registry"
                        checked={formData ? formData.inherithCodeFormat : false}
                        onChange={(event, checked) => {
                            if (!formData.inherithCodeFormat) formData.inherithCodeFormat = checked;
                            formData.inherithCodeFormat = checked;
                            formData.codeFormat = null;
                            setPrefix("");
                            setPaddedZeros(0);
                            setSuffix("");
                        }}
                    />
                </r-cell>
                {formData && !formData.inherithCodeFormat === true && (
                    <r-cell span="2">
                        <r-grid columns="3">
                            <r-cell>
                                <TextField
                                    label="Prefix"
                                    value={prefix}
                                    onChange={(ev, value) => {
                                        setPrefix(value || "");
                                        formData.codeFormat = assembleCodePreview(value || "", suffix, paddedZeros);
                                    }}
                                />
                            </r-cell>
                            <r-cell>
                                <TextField
                                    label="Padded Zeros"
                                    value={paddedZeros}
                                    type="number"
                                    onChange={(ev, value) => {
                                        setPaddedZeros(value);
                                        formData.codeFormat = assembleCodePreview(prefix, suffix, value);
                                    }}
                                />
                            </r-cell>
                            <r-cell>
                                <TextField
                                    label="Suffix"
                                    value={suffix}
                                    onChange={(ev, value) => {
                                        setSuffix(value || "");
                                        formData.codeFormat = assembleCodePreview(prefix, value || "", paddedZeros);
                                    }}
                                />
                            </r-cell>
                        </r-grid>
                    </r-cell>
                )}
                {formData && !formData.inherithCodeFormat === true && (
                    <r-cell span="2">
                        <Label>Examples</Label>
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <Text>{computeCodePreview(prefix, suffix, paddedZeros, "5")},</Text>
                            <Text>{computeCodePreview(prefix, suffix, paddedZeros, "23")},</Text>
                            <Text>{computeCodePreview(prefix, suffix, paddedZeros, "1054")}</Text>
                        </Stack>
                    </r-cell>
                )}
                <r-cell span="1">
                    <Dropdown
                        required
                        label="Template"
                        disabled={props.isEdit}
                        selectedKey={formData ? formData.template : null}
                        onChange={(event, item) => {
                            formData.template = item.key;
                        }}
                        options={templates}
                    />
                </r-cell>
                <r-cell span="1">
                    <TextField
                        label="Summary"
                        value={formData ? formData.summary : ""}
                        onChange={(ev, value) => (formData.summary = value)}
                    />
                </r-cell>
                <r-cell span="1">
                    <RichDropdown
                        placeholder="Pick a state"
                        label="State"
                        required
                        selectedKey={formData ? formData.state : null}
                        options={[
                            {
                                key: "Conceptual",
                                title: "Conceptual",
                                description: "Conceptual",
                            },
                            {
                                key: "InTesting",
                                title: "In Testing",
                                description: "In Testing",
                            },
                            {
                                key: "Operational",
                                title: "Operational",
                                description: "Operational",
                            },
                            {
                                key: "Deprecated",
                                title: "Deprecated",
                                description: "Deprecated",
                            },
                            {
                                key: "Retired",
                                title: "Retired",
                                description: "Retired",
                            },
                        ]}
                        onOptionChanged={(item) => {
                            formData.state = item.key;
                        }}
                    />
                </r-cell>
                <r-cell span="2">
                    <ObservableSeparator>Extended Settings</ObservableSeparator>
                </r-cell>
                <r-cell span="1">
                    <PrincipalPicker
                        label="Owner"
                        placeholder={`Search for an user`}
                        onSelected={(user) => (formData.owner = user)}
                        selected={formData && formData.owner}
                    />
                </r-cell>
                <r-cell span="1">
                    <RegistryPicker
                        placeholder="Please select a registry"
                        label="Default Registry"
                        selectedId={formData && formData.defaultRegistryId ? formData.defaultRegistryId : null}
                        onChange={(registry) => {
                            if (registry && registry.id) {
                                formData.defaultRegistryId = registry.id;
                                formData.defaultRegistry = registry;
                            }
                        }}
                    />
                </r-cell>
                <r-cell span="1">
                    <StateFlowPicker
                        label="Workflow"
                        target="RiskLifecycle"
                        multiple={false}
                        required
                        selectedKey={formData.flowId}
                        onChange={(item) => {
                            formData.flowId = item ? item.id : null;
                        }}
                    />
                </r-cell>
                <r-cell span="1">
                    <Toggle
                        label="Locked"
                        checked={formData && formData.locked ? formData.locked : false}
                        onChange={(event, checked) => {
                            if (!formData.promoted) formData.locked = checked;
                            formData.locked = checked;
                        }}
                    />
                </r-cell>
                <r-cell span="2">
                    <ObservableSeparator>Display Settings</ObservableSeparator>
                </r-cell>
                <r-cell span="1">
                    <TextField
                        label="Order"
                        type="number"
                        min="1"
                        max="100"
                        value={formData ? formData.order : ""}
                        onChange={(ev, value) => (formData.order = parseInt(value))}
                    />
                </r-cell>
                <r-cell span="1">
                    <Toggle
                        label="Promoted"
                        checked={formData && formData.promoted ? formData.promoted : false}
                        onChange={(event, checked) => {
                            if (!formData.promoted) formData.promoted = checked;
                            formData.promoted = checked;
                        }}
                    />
                </r-cell>
                <r-cell span="2">
                    <MarkdownField
                        label="Score Tooltip"
                        value={formData ? formData.scoreTooltip : ""}
                        onChange={(ev, value) => (formData.scoreTooltip = value)}
                    />
                </r-cell>
                <r-cell span="2">
                    <ObservableSeparator>Request Settings</ObservableSeparator>
                </r-cell>
                <r-cell span="2">
                    <RichTextField
                        label="Context Template"
                        value={(formData && formData.contextTemplate) || null}
                        onChange={(val) => {
                            if (formData) formData.contextTemplate = val;
                        }}
                    />
                </r-cell>
                <r-cell span="2">
                    <RichTextField
                        label="Description Template"
                        value={(formData && formData.descriptionTemplate) || null}
                        onChange={(val) => {
                            if (formData) formData.descriptionTemplate = val;
                        }}
                    />
                </r-cell>
                <r-cell span="1">
                    <Toggle
                        label="Requestable"
                        checked={formData && formData.requestable ? formData.requestable : false}
                        onChange={(event, checked) => {
                            if (!formData.requestable) formData.requestable = checked;
                            formData.requestable = checked;
                        }}
                    />
                </r-cell>
                <r-cell span="1">
                    <Toggle
                        label="Internal"
                        checked={formData && formData.internal ? formData.internal : false}
                        onChange={(event, checked) => {
                            if (!formData.internal) formData.internal = checked;
                            formData.internal = checked;
                        }}
                    />
                </r-cell>
                <r-cell span="1">
                    <Toggle
                        label="Link To Assessment"
                        checked={formData && formData.linkToAssessment ? formData.linkToAssessment : false}
                        onChange={(event, checked) => {
                            if (!formData.linkToAssessment) formData.linkToAssessment = checked;
                            formData.linkToAssessment = checked;
                        }}
                    />
                </r-cell>
                <r-cell span="2">
                    <ObservableSeparator>Assignment Settings</ObservableSeparator>
                </r-cell>
                <r-cell span="1">
                    <GroupDropDown
                        label="Auto-assigned Group"
                        placeholder="Select a group"
                        selected={formData && formData.assignedToGroup}
                        onChange={(group) => (formData.assignedToGroup = group)}
                    />
                </r-cell>
                <r-cell span="1">
                    <Dropdown
                        label="Auto Assignment Mode"
                        placeholder="Select an auto assignment mode"
                        selectedKey={formData ? autoAssignmentModeOrDefault(formData.autoAssignmentMode) : null}
                        onChange={(_, item) => (formData.autoAssignmentMode = item.key)}
                        options={Object.entries(AutoAssignmentModes).map(([key, text]) => ({ key, text }))}
                    />
                </r-cell>
                <r-cell span="1">
                    {formData && formData.autoAssignmentMode === "workItem.assignedToUser" && (
                        <UserDropDown
                            label="Auto-assigned User"
                            placeholder="Select a user"
                            groupId={formData && formData.assignedToGroup ? formData.assignedToGroup.id : null}
                            selected={formData.assignedToUser}
                            onChange={(user) => (formData.assignedToUser = user)}
                        />
                    )}
                </r-cell>
            </r-grid>
        </>
    );
});
