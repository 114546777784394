import React, { Component } from "react";
import { observer } from "mobx-react";

import { Text } from "office-ui-fabric-react/lib/Text";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { Stack } from "office-ui-fabric-react/lib/Stack";

export const Statistic = observer(
    class Statistic extends Component {
        render() {
            const { className, title, children, value, precision, iconName, prefix, suffix } = this.props;

            return (
                <Stack tokens={{ childrenGap: 10 }} className={className}>
                    <Stack.Item>
                        <Text variant="medium" block styles={{ root: { color: "var(--text-color-lighter)" } }}>
                            {title}
                        </Text>
                    </Stack.Item>
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        {iconName && (
                            <Text variant="xxLarge" block>
                                <Icon iconName={iconName} />
                            </Text>
                        )}
                        {prefix && (
                            <Text variant="xxLarge" block>
                                {prefix}
                            </Text>
                        )}
                        {children ? (
                            <Text variant="xxLarge">{children}</Text>
                        ) : (
                            <Text variant="xxLarge" block>
                                {(value || 0).toFixed(precision || 0)}
                            </Text>
                        )}
                        {suffix && (
                            <Text variant="xxLarge" block>
                                {suffix}
                            </Text>
                        )}
                    </Stack>
                </Stack>
            );
        }
    }
);
