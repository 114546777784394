import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import { BoxPanel } from '@baseComponents/BoxPanel';
import { FilterBar } from '@base/filterBar/FilterBar';

export class AdvisoryBroswerPageCommandBar extends Component {

    render () {
        const { isFilterChanged, onFiltersChange, fields, onClearAll } = this.props;
        // console.log('fields', fields);
        const items = [];
        const farItems = [
            {
                showToGuest: true,
                key: 'filter',
                name: 'Filter Advisories',
                iconOnly: true,
                iconProps: isFilterChanged ? { iconName: 'FilterSolid' } : { iconName: 'Filter' },
                onRender:() => { return (
                        <FilterBar
                            visible = {true}
                            minibarFilters={['keywords',]}
                            calloutFilters={['initialCompliances', 'finalCompliances', 'level', 'portfolios','controls', 'assessments', ]}
                            keywordPlaceHolder="Search by name or alias"
                            onFiltersChange = {onFiltersChange}
                            onClearAll={onClearAll}
                            hideCloseBarButton
                            fields={fields}
                        />)
                }
            },
        ];

        return (
            <BoxPanel shadow attached>
                <CommandBar
                    styles={{ root: { paddingRight: '0px' } }}
                    items={items}
                    farItems={farItems}
                />
            </BoxPanel>
        );
    }
};