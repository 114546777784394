import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { DetailsListLayoutMode, SelectionMode, ConstrainMode } from 'office-ui-fabric-react/lib/DetailsList';
import { TextField } from '@ui/elements/TextField';
import { MessageBar } from '@ui/elements/MessageBar';
import { ShimmeredDetailsList } from '@ui/elements/ShimmeredDetailsList';
import { IconButton } from '@ui/elements/Button';

import { BoxPanel } from '@baseComponents/BoxPanel';
import { WrappedContent } from '@baseComponents/WrappedContent';
import { ListItemReveal } from '@baseComponents/ListItemReveal';

export const ControlMapControlGridView = observer(
    class ControlMapControlGridView extends Component {

        constructor(props) {
            super(props);

            this._columns = [
              {
                key: 'name',
                name: 'Name',
                fieldName: 'name',
                minWidth: 200,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                onRender: (item) => {
                  return (
                    <WrappedContent>
                      {item.name}
                      <ListItemReveal>
                        { this.props.onControlRemoveClick && (
                          <IconButton iconProps={{ iconName: 'Delete' }} onClick={(ev) => this.props.onControlRemoveClick(item, ev)} />
                        )}
                      </ListItemReveal>
                    </WrappedContent>
                    );
                },
                isPadded: true
              },
              {
                key: 'weighting',
                name: 'Weighting',
                fieldName: 'weighting',
                minWidth: 90,
                maxWidth: 120,
                isResizable: false,
                data: 'string',
                onRender: (item) => {
                  return (
                    <div>
                        <TextField 
                            required
                            type="number"
                            min="0"
                            max="1"
                            step="0.01"
                            value={item.weighting}
                            onChange={(ev, val) => {
                                if(val) {
                                    this.props.onWeightingUpdated(item, parseFloat(val));
                                }
                            }}
                        />
                    </div>
                  );
                },
                isPadded: false
              },
              {
                key: 'absentValue',
                name: 'Absent Value',
                fieldName: 'absentValue',
                minWidth: 90,
                maxWidth: 120,
                isResizable: false,
                data: 'string',
                onRender: (item) => {
                  return (
                    <div>
                        <TextField 
                            type="number"
                            min="0"
                            max="1"
                            step="0.01"
                            value={item.absentValue}
                            onChange={(ev, val) => {
                                if(val) {
                                  this.props.onAbsentValueUpdated(item, parseFloat(val));
                                } else {
                                  this.props.onAbsentValueUpdated(item, null);
                                }
                            }}
                        />
                    </div>
                  );
                },
                isPadded: false
              }
          ];
        }

        render () {
            const { className, controls, loading, onControlClick, disableSelection } = this.props;
            
            return (
              <BoxPanel shadow>
                <ShimmeredDetailsList
                  items={controls}
                  compact={false}
                  columns={this._columns}
                  selectionMode={disableSelection ? SelectionMode.none : SelectionMode.none}
                  getKey={this._getKey}
                  setKey="single"
                  layoutMode={DetailsListLayoutMode.justified}
                  constrainMode={ConstrainMode.horizontalConstrained}
                  isHeaderVisible={true}
                  selection={this._selection}
                  selectionPreservedOnEmptyClick={true}
                  onItemInvoked={onControlClick}
                  enterModalSelectionOnTouch={true}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  checkButtonAriaLabel="Row checkbox"
                  enableShimmer={loading}
                  removeFadingOverlay={true}
                  shimmerLines={15}
                  ariaLabelForShimmer="Page being fetched"
                  ariaLabelForGrid="Axis control list"
                />
                
                { ! loading && !controls.length && (
                  <MessageBar>
                    There are no controls on this axis.
                  </MessageBar>
                )}
              </BoxPanel> 
            );
        }

        _getKey(item, index) {
            return item ? item.id : index;
        }
    }
);