import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { cx, css } from 'emotion'

import { Text } from 'office-ui-fabric-react/lib/Text';

import { AppNavHome } from '../../../layout/components/AppNavHome';

const rootClassName = cx(
    'cygraph-SignedOutPage-root',
    css`

    display: table;
    position: absolute;
    height: 100%;
    width: 100%;
    margin-left: calc( -1 * var(--app-nav-mini-width));

    & .block {
        display: table-cell;
        vertical-align: middle;
    }

    & .inner {
        margin-left: auto;
        margin-right: auto;
        position: relative;
        max-width: 440px;
        width: calc(100% - 40px);
        margin-bottom: 28px;
        background-color: var(--white);
        box-shadow: 0 2px 6px rgba(0,0,0,0.2);
        min-width: 320px;
        min-height: 338px;
        overflow: hidden;
    }

    & .header {
        display: flex;
        align-items: center;
        margin: 5px 20px;
        font-size: var(--font-size-l);
    }

    & .content {
        border-top: 2px solid var(--body-background);
        padding: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 200px;
    }
    `
);

export const SignedOutPage = inject('pageContext', 'principalContext', 'routing')(observer(
    class SignedOutPage extends Component {

        render () {
            const { pageContext, routing } = this.props; 

            return (
                <div className={cx(rootClassName)}>
                    <div className="block">
                        <div className="inner">
                            <div className="header">
                                <AppNavHome width={50} appName={'Zerodai'} home={{url: '/'}} routing={routing} /> Zerodai
                            </div>
                            <div className="content">
                                <Text variant="mediumPlus">You are now signed out</Text>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
));
