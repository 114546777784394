import React, { Component } from "react";
import { cx, css } from "emotion";

import { Dropdown } from "office-ui-fabric-react/lib/Dropdown";
import { Callout } from "office-ui-fabric-react/lib/Callout";
import { Icon } from "office-ui-fabric-react/lib/Icon";

const calloutOptioncontainerClassName = cx(css`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: 10px 20px;
    border-bottom: 1px solid var(--grey-4);

    :hover {
        background-color: var(--grey-2);
    }
`);

const selectedOptionClassName = cx(css`
    background-color: var(--green-5);
`);

export class RichDropdown extends Component {
    constructor(props) {
        super(props);
        this.containerElement = React.createRef();
        this.state = {
            isOptionCalloutOpen: false,
            selectedOption: null,
            classAppendix: Math.random().toString().substr(2, 8),
        };
    }

    onDropDownClick() {
        this.setState({
            isOptionCalloutOpen: !this.state.isOptionCalloutOpen,
        });
    }

    onDismissCallout() {
        this.setState({
            isOptionCalloutOpen: false,
        });
    }

    getSelectedOption() {
        const { options, selectedKey } = this.props;
        const { selectedOption } = this.state;
        if (selectedKey) {
            return options.find((O) => O.key === selectedKey);
        } else {
            return selectedOption;
        }
    }

    render() {
        const { onOptionChanged, placeholder, label, width, options, required, disabled, className, calloutWidth } = this.props;
        const { isOptionCalloutOpen } = this.state;
        // const calloutWidth = width;
        const selectedOption = this.getSelectedOption();
        return (
            <div
                ref={this.containerElement}
                className={cx(
                    className,
                    css`
                        width: ${width ? `${width}px` : "100%"};
                    `
                )}
            >
                <Dropdown
                    className={cx(`richDropDown-dropdown-${this.state.classAppendix}`)}
                    placeholder={selectedOption ? selectedOption.title : placeholder}
                    label={label}
                    options={[]}
                    disabled={disabled}
                    required={required}
                    onClick={() => this.onDropDownClick()}
                />
                {isOptionCalloutOpen && !disabled && (
                    <Callout
                        ref={this.calloutElement}
                        className={cx(
                            "RichDropdown-callout",
                            css`
                                min-width: 200px;
                                margin-top: 6px;
                                width: ${calloutWidth ? `` : "auto"};
                            `
                        )}
                        calloutWidth={calloutWidth}
                        target={`.richDropDown-dropdown-${this.state.classAppendix}`}
                        onDismiss={() => this.onDismissCallout()}
                        isBeakVisible={false}
                        setInitialFocus
                        // directionalHintFixed
                        // directionalHint={5}
                    >
                        {options.map((option, idx) => {
                            const isOptionSelected = selectedOption ? option.key === selectedOption.key : false;
                            return (
                                <div
                                    key={`k_${idx}`}
                                    className={cx(calloutOptioncontainerClassName, isOptionSelected ? selectedOptionClassName : "")}
                                    onClick={() => {
                                        this.setState({
                                            selectedOption: option,
                                        });
                                        if (onOptionChanged) onOptionChanged(option);
                                        this.onDismissCallout();
                                    }}
                                >
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <b>{option.title}</b>
                                        {isOptionSelected && <Icon iconName="Accept" />}
                                    </div>
                                    {option.description}
                                </div>
                            );
                        })}
                    </Callout>
                )}
            </div>
        );
    }
}
