import React, { Component} from 'react';
import { observer } from 'mobx-react';
import { cx, css} from 'emotion';
import { IconButton } from 'office-ui-fabric-react/lib/Button';

const ContextualMenuClassName = cx(
  css `
    height: 20px;
    float: right;
  `
)

export const GroupsContextualMenu = observer(
  class GroupsContextualMenu extends Component {
    
    constructor(props) {
      super(props)
     }

    render () {
      const { 
        groupId,
        onEditClick,
      } = this.props;

      const menuProps = {
        shouldFocusOnMount: true,
        items: [{
          key: 'edit',
          iconProps: {
            iconName: 'Edit',
          },
          text: 'Edit group',
          onClick: () => onEditClick(groupId),
        }]
      };

      return (<IconButton iconProps={{iconName: 'More'}} className={ContextualMenuClassName} menuProps={menuProps} />);
    }
  }
);