import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { useStores } from "@hooks/useStores";

import { JsonQueryString } from "@modules/utils/JsonQueryString";
import { AppPage } from "@modules/layout/containers/AppPage";
import { ObservablePagination } from "@modules/base/components/ObservablePagination";

import { PageInstanceViewer } from "@modules/pages/containers/utilities/PageInstanceViewer";

import { AssessmentGridView } from "@modules/assessments/components/core/AssessmentGridView";
import { AssessmentCommandBar } from "@modules/assessments/components/core/AssessmentCommandBar";
import { AssessmentSideNav } from "@modules/assessments/components/core/AssessmentSideNav";

export const AssessmentBrowsePage = withRouter(
    observer(({ match }) => {
        const { assessmentStore, pageContext, routing } = useStores();
        const { browseStore, lifecycleStore } = assessmentStore;
        const { breadcrumb, error, isGuestUser, assessments, query, loading, pagination } = browseStore;

        useEffect(() => {
            pageContext.updateQueryStringParams(routing.location.search);
            browseStore.loadAssessments({
                page: pageContext.queryStringParams.p || 1,
                query: JsonQueryString.decode(pageContext.queryStringParams.query),
            });
        }, [match, routing, pageContext]);

        return (
            <AppPage
                error={error}
                breadcrumb={breadcrumb}
                loading={loading || !assessments}
                hasSidebar={false}
                className="assessments list has--page-nav"
            >
                <AssessmentSideNav
                    onLinkClick={(item) => routing.push(item.url)}
                    onNewTriage={() => routing.push("/assurance/triage")}
                    canViewResourcePages={lifecycleStore.canViewResourcePages}
                    canViewCalendarPages={lifecycleStore.canViewCalendarPages}
                    canViewAdvisoryPages={lifecycleStore.canViewAdvisoryPages}
                />

                <>
                    <PageInstanceViewer path="/_/assurance/browse" optional={true} />

                    <AssessmentCommandBar
                        loading={loading}
                        isGuestUser={isGuestUser}
                        filterQuery={query}
                        filterContext={{
                            processing: loading,
                            resultCount: pagination.totalItemCount,
                        }}
                        onFilterChange={(q, f) => {
                            routing.push(JsonQueryString.build("/assurance/browse", q));
                        }}
                        onNewTriage={() => routing.push("/assurance/triage")}
                        onExport={() => {
                            assessmentStore.browseStore.exportAssessments({});
                        }}
                    />

                    <AssessmentGridView
                        assessments={assessments}
                        loading={loading}
                        query={{}}
                        onAssessmentClick={(assessment) => routing.push(`/assurance/browse/${assessment.id}`)}
                    />

                    <ObservablePagination
                        {...pagination}
                        onPageChange={(index) => {
                            routing.push(JsonQueryString.build("/assurance/browse", query, index + 1));
                        }}
                    />
                </>
            </AppPage>
        );
    })
);
