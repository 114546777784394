import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion'

import { Icon } from 'office-ui-fabric-react/lib/Icon';

export const MeasureIndexItem = observer(
    class MeasureIndexItem extends Component {
        render () {
            const { measure, index, completed, active, onMeasureClick } = this.props;

            return (
                <a role="button"
                    onClick={() => onMeasureClick(measure)} 
                    className={cx('question', completed && 'question--success', active && 'question--active')}>
                    <div className="question__count__wrapper">
                        <div className="question__count">{completed && active ? <Icon iconName="Accept" /> : String(index).padStart(2, '0')}</div>
                    </div>
                    <div>
                        <div className="question__label">{measure.title}</div>
                    </div>
                </a>
            );
        }
    }
);