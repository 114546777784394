import { observable, computed, action } from "mobx";

import { LayoutStore } from "./LayoutStore";
import { IMenuStore, IMenuItem } from "./components/IMenuStore";

export class MenuStore implements IMenuStore {
    static readonly homeItemId: string = '/';
    readonly layoutStore: LayoutStore;
    @observable public items: MenuItem[];
    @observable public selectedItemId: string;

    constructor(layoutStore: LayoutStore, items: IMenuItem[]) {
        this.layoutStore = layoutStore;
        this.items = items.map(item => new MenuItem(item));
    }

    @computed get home() {
        return this.items.find(i => i.id === MenuStore.homeItemId);
    }

    @computed get filtered() {
        var user = this.layoutStore.rootStore.principalContext.current;
        return this.items.filter(i => !i.isVisible || i.isVisible(user));
    }

    @action
    public add(item: IMenuItem) {
        this.items.push(new MenuItem(item));
    }

    @action
    public locationChanged(location, action) {
        const item = this.items.find(i => ((i.isSelected && i.isSelected(location.pathname)) || i.url === location.pathname));
        if(item) {
            this.selectedItemId = item.id;
        } else {
            this.selectedItemId = null;
        }
    }
}

export class MenuItem {
    readonly id: string;
    @observable public title: string;
    @observable public url: string;
    @observable public icon: string;
    public isVisible?: (user: any) => boolean;
    public isSelected?: (url: string) => boolean;

    constructor(other: IMenuItem) {
        this.id = other.id;
        this.title = other.title;
        this.url = other.url;
        this.icon = other.icon;
        this.isVisible = other.isVisible;
        this.isSelected = other.isSelected;
    }
}
