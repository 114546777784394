import React from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { ActionButton } from "office-ui-fabric-react/lib/Button";

export const WorkflowContextLink = observer(({ context, children, onPreClick }) => {
    const { routing } = useStores();

    const getUrl = () => {
        switch (context.type) {
            case "work-item":
                return `/demand/browse/${encodeURIComponent(context.id)}`;
            case "assessment":
                return `/assurance/browse/${encodeURIComponent(context.id)}`;
            case "risk":
                return `/risks/browse/${encodeURIComponent(context.id)}`;
            default:
                return `/deeplink?type=${encodeURIComponent(context.type)}&id=${encodeURIComponent(context.id)}`;
        }
    };
    return (
        <ActionButton
            style={{ height: 32, lineHeight: 32, padding: 0, marginLeft: -7 }}
            iconProps={{ iconName: "NavigateExternalInline" }}
            onClick={(e) => {
                if (onPreClick) {
                    onPreClick();
                }

                routing.push(getUrl());
            }}
        >
            {children || context.label}
        </ActionButton>
    );
});
