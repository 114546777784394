import React, { Component } from "react";
import { observer, inject } from "mobx-react";

import { Pivot, PivotItem, PivotLinkFormat } from "office-ui-fabric-react/lib/Pivot";

import { AppPage } from "../../../layout/containers/AppPage";

import { RiskGridView } from "../../components/core/RiskGridView";
import { RiskSideNav } from "../../components/core/RiskSideNav";
import { RegistryCardList } from "../../components/registries/RegistryCardList";
import { RiskNewPanel } from "../panels/RiskNewPanel";

export const RiskDashboardPage = inject(
    "riskStore",
    "routing"
)(
    observer(
        class RiskDashboardPage extends Component {
            componentWillMount() {
                const { dashboardStore, registryStore } = this.props.riskStore;
                dashboardStore.loadDashboard({});
                registryStore.loadRegistries({ cached: true });
            }

            componentWillReceiveProps(nextProps) {
                const { dashboardStore, registryStore } = nextProps.riskStore;
                dashboardStore.loadDashboard({});
                registryStore.loadRegistries({ cached: true });
            }

            render() {
                const { dashboardStore, registryStore } = this.props.riskStore;
                const { loading, breadcrumb, error, risks } = dashboardStore;

                return (
                    <AppPage
                        error={error}
                        breadcrumb={breadcrumb}
                        loading={loading}
                        hasSidebar={false}
                        className="risks dashboard has--page-nav"
                    >
                        <RiskSideNav
                            registries={registryStore.registries}
                            onLinkClick={(item) => this.props.routing.push(item.url)}
                            onNewRisk={(options) => this._onNewRisk(options)}
                        />

                        <div style={{ marginTop: "30px" }}>
                            {registryStore.registries.length !== 0 && (
                                <RegistryCardList
                                    className="animated fadeIn"
                                    loading={loading}
                                    registries={registryStore.registries}
                                />
                            )}

                            <Pivot
                                aria-label="Risk reviews, lifecycles, related documents and work"
                                linkFormat={PivotLinkFormat.links}
                            >
                                {Object.keys(risks).map((id) => {
                                    const tab = risks[id];
                                    return (
                                        <PivotItem key={id} headerText={tab.title}>
                                            <RiskGridView risks={tab.items} loading={tab.loading} query={{}} />
                                        </PivotItem>
                                    );
                                })}
                            </Pivot>

                            <RiskNewPanel />
                        </div>
                    </AppPage>
                );
            }

            _onNewRisk() {
                this.props.routing.push("/risks/new");
            }
        }
    )
);
