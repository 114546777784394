import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { AppPage } from '@layout/containers/AppPage';
import { ComingSoon } from '@baseComponents/ComingSoon';

export const CalendarOverview = observer(
    class CalendarOverview extends Component {
        render () {
            const { children } = this.props;
            
            return (
                <AppPage title="Calendar" hasSidebar={false} className="calendar">
                    <ComingSoon />
                    {children}
                </AppPage>
            );
        }
    }
);