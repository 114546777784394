import React from "react";
import { observer } from "mobx-react";

import { MessageBar } from "@ui/elements/MessageBar";
import { Stack } from "@ui/elements/Stack";
import { PersonaSize } from "@ui/elements/Persona";
import { IconButton } from "@ui/elements/Button";

import { PrincipalPicker } from "@modules/identity/containers/PrincipalPicker";
import { PhotoPersona } from "@modules/identity/containers/utilities/PhotoPersona";

export const IdentityUserNewForm = observer(({ formData }) => {
    return (
        <Stack tokens={{ childrenGap: 15 }}>
            <MessageBar>
                Search users from the organisational directory. Any users selected below will be added to the
                application as guest users. After adding users, their roles and group memberships can be updated using
                the edit user dialog.
            </MessageBar>

            <PrincipalPicker
                mode="external"
                placeholder="Search by name or email"
                //selected={formData.users}
                onSelected={(user) => {
                    if (
                        formData.users.findIndex(
                            (u) => u.externalId === user.externalId && u.provider === user.provider
                        ) === -1
                    ) {
                        formData.users = [user, ...formData.users];
                    }
                }}
            />

            <Stack tokens={{ childrenGap: 15 }}>
                {formData.users.map((user) => (
                    <div key={`${user.externalId}@${user.provider}`}>
                        <Stack horizontal horizontalAlign="space-between">
                            <Stack.Item grow>
                                <PhotoPersona
                                    size={PersonaSize.size28}
                                    principalId={user.id}
                                    text={user.name}
                                    showSecondaryText={true}
                                    secondaryText={user.userPrincipalName}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <IconButton
                                    title={`Remove ${user.name}`}
                                    iconProps={{ iconName: "Cancel" }}
                                    onClick={() => {
                                        formData.users = formData.users.filter(
                                            (u) => !(u.externalId === user.externalId && u.provider === user.provider)
                                        );
                                    }}
                                />
                            </Stack.Item>
                        </Stack>
                    </div>
                ))}
            </Stack>
        </Stack>
    );
});
