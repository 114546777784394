import React from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";
import Moment from "react-moment";

import { Text } from "office-ui-fabric-react/lib/Text";
import { Stack } from "office-ui-fabric-react/lib/Stack";

import { ObservableBadge } from "@modules/base/components/ObservableBadge";
import { ActionSet } from "@modules/base/components/ActionSet";
import { ContentEditor } from "@modules/editors/components/ContentEditor";
import { sanitize } from "@modules/patterns/stores/PatternUtilities";

import { StateFlowTarget } from "@modules/stateflows/components/core/StateFlowTarget";

const rootClassName = cx(
    "cygraph-StateFlowEditorHeader-root",
    css`
        margin: var(--spacing-s) 0;

        .title {
            margin: var(--spacing-xs) 0;
        }

        & .description {
            color: var(--text-color-light);
            font-size: var(--font-size-xs);
        }

        & .tags span {
            color: var(--text-color-light);
            font-size: 12px;
            padding: 0 var(--spacing-xs);
            display: inline-block;
            margin-right: var(--spacing-xxxxs);
            border-radius: var(--border-radius);
            border: 1px solid var(--border-color-dark);
        }
    `
);

export const StateFlowEditorHeader = observer(({ stateFlow, manifest, ...rest }) => {
    const { hideActions, canDiscard, onDiscard, canSave, onSave, canPublish, onPublish } = rest;

    const items = [];
    const overflowItems = [];

    if (hideActions) {
        return { items, overflowItems };
    }

    if (canDiscard && canDiscard()) {
        items.push({
            key: "discard",
            name: "Discard",
            iconProps: { iconName: "Uneditable" },
            ariaLabel: "Discard",
            onClick: onDiscard,
        });
    }

    items.push({
        key: "save",
        name: "Save",
        iconProps: { iconName: "Save" },
        disabled: !canSave || !canSave(),
        ariaLabel: "Save",
        onClick: onSave,
    });

    items.push({
        key: "publish",
        name: "Publish",
        primary: true,
        className: "publishButton",
        iconProps: { iconName: "ReadingMode" },
        disabled: !canPublish || !canPublish(),
        ariaLabel: "Publish",
        onClick: () => onPublish(),
    });

    return (
        <div className={rootClassName}>
            <Stack horizontal horizontalAlign="space-between">
                <ObservableBadge className="badge">
                    <StateFlowTarget value={manifest.target} /> Workflow Editor
                </ObservableBadge>
                <ActionSet items={items} overflowItems={overflowItems} />
            </Stack>
            <Stack horizontal horizontalAlign="space-between" verticalFill verticalAlign="center">
                <Text className="title" variant="xLarge" block>
                    <ContentEditor
                        html={manifest.name || ""}
                        onSubmit={(evt, val) => {
                            manifest.name = sanitize(val);
                        }}
                    />
                </Text>
                {stateFlow && (
                    <Text className="description" variant="xSmall" block>
                        saved <Moment utc date={stateFlow.modified} fromNow />
                    </Text>
                )}
            </Stack>

            <div className="description">{manifest.description}</div>
            {manifest.tags && (
                <div className="tags">
                    {manifest.tags.map((t) => (
                        <span>{t}</span>
                    ))}
                </div>
            )}
        </div>
    );
});
