import React, { useCallback, useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { useStores } from "@hooks/useStores";

import { JsonQueryString } from "@modules/utils/JsonQueryString";

import { AppPage } from "@modules/layout/containers/AppPage";
import { ObservablePagination } from "@modules/base/components/ObservablePagination";

import { PersonalAccountSideNav } from "../../components/core/PersonalAccountSideNav";
import { PersonalAccountHeader } from "../../components/core/PersonalAccountHeader";

import { TaskFilterBar } from "@modules/workflows/components/tasks/TaskFilterBar";
import { TaskDefinitionGridView } from "@modules/workflows/components/tasks/TaskDefinitionGridView";
import { TaskResponseEditDialog } from "@modules/workflows/containers/dialogs/TaskResponseEditDialog";
import { TaskDefinitionNewPanel } from "@modules/workflows/containers/panels/TaskDefinitionNewPanel";
import { TaskDefinitionEditPanel } from "@modules/workflows/containers/panels/TaskDefinitionEditPanel";

export const AccountTaskBrowsePage = withRouter(
    observer(({ match }) => {
        const { pageContext, accountStore, taskStore, routing } = useStores();

        const { overviewStore, taskBrowseStore } = accountStore;
        const { user, photo } = overviewStore;
        const { editStore, lifecycleStore } = taskStore;
        const { loading, error, breadcrumb, tasks, query, pagination } = taskBrowseStore;

        useEffect(() => {
            pageContext.updateQueryStringParams(routing.location.search);

            try {
                taskBrowseStore.loadMyTasks({
                    page: pageContext.queryStringParams.p || 1,
                    query: pageContext.queryStringParams.query
                        ? JsonQueryString.decode(pageContext.queryStringParams.query)
                        : { status: ["Pending"] },
                });

                if (pageContext.queryStringParams.id) {
                    lifecycleStore.onRespond({}, { task: { id: pageContext.queryStringParams.id } }).then(() => {
                        routing.push(JsonQueryString.build("/account/tasks", query));
                    });
                }
            } catch (e) {
                console.error(e);
            }
        }, [pageContext, match]);

        const filterTasks = useCallback((filters) => {
            const kf = filters.find((f) => f.fieldName == "keywords");
            const sf = filters.find((f) => f.fieldName == "status");

            routing.push(
                JsonQueryString.build("/account/tasks", {
                    keywords: kf ? kf.value : null,
                    status: sf ? sf.items.filter((s) => s.checked).map((s) => s.key) : null,
                })
            );
        }, []);

        return (
            <AppPage
                error={error}
                breadcrumb={breadcrumb}
                loading={overviewStore.loading || loading || !user}
                hasSidebar={false}
                className="accounts my-tasks has--page-nav"
            >
                {user && (
                    <>
                        <PersonalAccountSideNav
                            user={user}
                            photo={photo}
                            onLinkClick={(item) => routing.push(item.url)}
                        />
                        <>
                            <PersonalAccountHeader user={user} title="My Tasks" />
                            <TaskFilterBar standalone query={query} onChange={filterTasks} />

                            <TaskDefinitionGridView
                                tasks={tasks}
                                loading={loading}
                                query={query}
                                displayContextLink
                                onEdit={(task) => {
                                    lifecycleStore.onEdit({}, { task }).then((result) => {
                                        if (result.success) {
                                            routing.push(JsonQueryString.build("/account/tasks", query));
                                        }
                                    });
                                }}
                                onRespond={(task) => {
                                    lifecycleStore.onRespond({}, { task }).then((result) => {
                                        if (result.success) {
                                            routing.push(JsonQueryString.build("/account/tasks", query));
                                        }
                                    });
                                }}
                                canRespond={(item) => {
                                    return (
                                        item.status === "Pending" &&
                                        !!item.assignments.find(
                                            (A) => A.assignedTo.id === user.id && A.status === "Pending"
                                        )
                                    );
                                }}
                                canEdit={(item) => {
                                    return (
                                        item.status === "Pending" &&
                                        (user.id === item.createdBy.id ||
                                            user.roles.findIndex((R) => R == "admin") > -1)
                                    );
                                }}
                            />

                            <ObservablePagination
                                {...pagination}
                                onPageChange={(index) => {
                                    routing.push(JsonQueryString.build("/account/tasks", query, index + 1));
                                }}
                            />

                            <TaskDefinitionNewPanel />
                            <TaskDefinitionEditPanel />
                            <TaskResponseEditDialog
                                onEdit={(task) => {
                                    lifecycleStore.onEdit({}, { task }).then((result) => {
                                        if (result.success) {
                                            routing.push(JsonQueryString.build("/account/tasks", query));
                                        }
                                    });
                                }}
                            />
                        </>
                    </>
                )}
            </AppPage>
        );
    })
);

// export const AccountTaskBrowsePage2 = inject(
//     "pageContext",
//     "accountStore",
//     "taskStore",
//     "routing"
// )(
//     observer(
//         class AccountTaskBrowsePage extends Component {
//             componentWillMount() {
//                 this.loadData(this.props);
//             }

//             componentWillReceiveProps(nextProps) {
//                 this.loadData(nextProps);
//             }

//             async loadData(props) {
//                 const { pageContext, routing } = props;
//                 const { taskBrowseStore } = this.props.accountStore;
//                 pageContext.updateQueryStringParams(routing.location.search);

//                 try {
//                     taskBrowseStore.loadMyTasks(this.getQueryFromSQ(pageContext));
//                 } catch (e) {
//                     console.error(e);
//                 }
//             }

//             getQueryFromSQ(pageContext) {
//                 let query = {};
//                 query = {
//                     page: pageContext.queryStringParams.p || 1,
//                     query: JsonQueryString.decode(pageContext.queryStringParams.query),
//                 };

//                 if (!query.query || Object.keys(query.query).length === 0) {
//                     query.query = {
//                         status: ["Pending"],
//                     };
//                 }
//                 // console.log(`getQueryFromSQ`, query);
//                 return query;
//             }

//             loadMyTasks(filters) {
//                 const { routing } = this.props;

//                 const kf = filters.find((F) => F.fieldName == "keywords");
//                 const sf = filters.find((F) => F.fieldName == "status");

//                 const query = {
//                     keywords: kf ? kf.value : null,
//                     status: sf ? sf.items.filter((I) => I.checked).map((I) => I.key) : null,
//                 };

//                 routing.push(`/account/tasks?p=1&${query ? `query=${JsonQueryString.encode(query)}` : ""}`);
//             }

//             render() {
//                 const { accountStore, taskStore, routing, pageContext } = this.props;
//                 const { overviewStore, taskBrowseStore } = accountStore;
//                 const { user, photo } = overviewStore;
//                 const { loading, error, breadcrumb, tasks, query, pagination } = taskBrowseStore;
//                 const { editStore, lifecycleStore } = taskStore;
//                 // console.log("query ATBP", query, query ? query.keywords || null : null)
//                 return (
//                     <AppPage
//                         error={error}
//                         breadcrumb={breadcrumb}
//                         loading={overviewStore.loading || loading || !user}
//                         hasSidebar={false}
//                         className="accounts my-tasks has--page-nav"
//                     >
//                         {user && (
//                             <>
//                                 <PersonalAccountSideNav
//                                     user={user}
//                                     photo={photo}
//                                     onLinkClick={(item) => routing.push(item.url)}
//                                 />
//                                 <>
//                                     <PersonalAccountHeader user={user} title="My Tasks" />
//                                     <FilterBar
//                                         onClearAll={() => routing.push(`/account/tasks?p=1`)}
//                                         visible={true}
//                                         onFiltersChange={(filters) => this.loadMyTasks(filters)}
//                                         hideCloseBarButton
//                                         hideOpenPanelIcon
//                                         minibarFilters={["keywords"]}
//                                         calloutFilters={["status"]}
//                                         fields={[
//                                             {
//                                                 fieldType: "text",
//                                                 fieldName: "keywords",
//                                                 placeholder: "Search by name or alias",
//                                                 value: query ? query.keywords || null : null,
//                                             },
//                                             {
//                                                 fieldType: "dropdown",
//                                                 fieldName: "status",
//                                                 placeholder: "Status",
//                                                 multiselect: true,
//                                                 defaultSelection: ["Pending"],
//                                                 items: [
//                                                     {
//                                                         key: "Pending",
//                                                         text: "Pending",
//                                                         checked:
//                                                             query && query.status
//                                                                 ? query.status.includes("Pending")
//                                                                 : false,
//                                                     },
//                                                     {
//                                                         key: "Completed",
//                                                         text: "Completed",
//                                                         checked:
//                                                             query && query.status
//                                                                 ? query.status.includes("Completed")
//                                                                 : false,
//                                                     },
//                                                     {
//                                                         key: "Cancelled",
//                                                         text: "Cancelled",
//                                                         checked:
//                                                             query && query.status
//                                                                 ? query.status.includes("Cancelled")
//                                                                 : false,
//                                                     },
//                                                     {
//                                                         key: "Expired",
//                                                         text: "Expired",
//                                                         checked:
//                                                             query && query.status
//                                                                 ? query.status.includes("Expired")
//                                                                 : false,
//                                                     },
//                                                 ],
//                                             },
//                                         ]}
//                                     />
//                                     <TaskDefinitionGridView
//                                         tasks={tasks}
//                                         loading={loading}
//                                         query={query}
//                                         displayContextLink
//                                         onEdit={(task) => {
//                                             lifecycleStore.onEdit({}, { task }).then((result) => {
//                                                 if (result.success) {
//                                                     routing.push(
//                                                         `/account/tasks?p=1${
//                                                             query ? `&query=${JsonQueryString.encode(query)}` : ""
//                                                         }`
//                                                     );
//                                                 }
//                                             });
//                                         }}
//                                         onRespond={(task) => {
//                                             lifecycleStore.onRespond({}, { task }).then((result) => {
//                                                 if (result.success) {
//                                                     routing.push(
//                                                         `/account/tasks?p=1${
//                                                             query ? `&query=${JsonQueryString.encode(query)}` : ""
//                                                         }`
//                                                     );
//                                                 }
//                                             });
//                                         }}
//                                         canRespond={(item) => {
//                                             return (
//                                                 item.status === "Pending" &&
//                                                 !!item.assignments.find(
//                                                     (A) => A.assignedTo.id === user.id && A.status === "Pending"
//                                                 )
//                                             );
//                                         }}
//                                         canEdit={(item) => {
//                                             return (
//                                                 item.status === "Pending" &&
//                                                 (user.id === item.createdBy.id ||
//                                                     user.roles.findIndex((R) => R == "admin") > -1)
//                                             );
//                                         }}
//                                     />

//                                     <ObservablePagination
//                                         {...pagination}
//                                         onPageChange={(index) => {
//                                             routing.push(
//                                                 `/account/tasks?p=${index + 1}${
//                                                     query ? `&query=${JsonQueryString.encode(query)}` : ""
//                                                 }`
//                                             );
//                                         }}
//                                     />

//                                     <TaskDefinitionNewPanel />
//                                     <TaskDefinitionEditPanel />
//                                     <TaskResponseEditDialog
//                                         onEdit={(task) => {
//                                             lifecycleStore.onEdit({}, { task }).then((result) => {
//                                                 if (result.success) {
//                                                     routing.push(
//                                                         `/account/tasks?p=1${
//                                                             query ? `&query=${JsonQueryString.encode(query)}` : ""
//                                                         }`
//                                                     );
//                                                 }
//                                             });
//                                         }}
//                                     />
//                                 </>
//                             </>
//                         )}
//                     </AppPage>
//                 );
//             }
//         }
//     )
// );
