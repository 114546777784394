import React, { useCallback } from "react";
import { observer } from "mobx-react";

import { Stack } from "office-ui-fabric-react/lib/Stack";
import { Text } from "office-ui-fabric-react/lib/Text";

import { BigModal } from "@modules/base/components/BigModal";

import { ActivityInstanceBuilderTab } from "@modules/workflows/containers/tabs/ActivityInstanceBuilderTab";
import { ActivityBuilderFilterBar } from "@modules/workflows/components/core/ActivityBuilderFilterBar";

export const AutomationBuilderDialog = observer(({ store, onSelect }) => {
    const { visible, formOptions } = store;

    const onDismiss = useCallback(() => {
        store.resolve(false);
    }, [store]);

    return (
        <BigModal
            className="animated fadeIn fast"
            isOpen={visible}
            onDismiss={onDismiss}
            isBlocking={false}
            dragOptions={false}
        >
            <Stack className="row--s" horizontal horizontalAlign="space-between">
                <Text variant="xLarge">Automations</Text>
                <Stack horizontal tokens={{ childrenGap: 5 }}>
                    <ActivityBuilderFilterBar value={store.query} onChange={(val, fs) => {}} />
                </Stack>
            </Stack>
            {formOptions && (
                <ActivityInstanceBuilderTab query={{ scope: [formOptions.propertyModel] }} onSelect={onSelect} />
            )}
        </BigModal>
    );
});
