import * as microsoftTeams from "@microsoft/teams-js";
import { CryptoUtils } from "msal";
import * as moment from "moment";

export type CacheLocation = "localStorage" | "sessionStorage";

const StorageKeys = {
    AccessToken: "zerodai:teams:accessToken",
};

export class TeamsAuthConfig {
    clientId: string;
    tokenExchangeUri: string;
    tokenExchangeMethod?: string;
    cacheLocation?: CacheLocation;
}

export class AuthenticationParameters {}

export class AccessTokenResponse {
    accessToken: string;
}

export class TeamsAuthProvider {
    private config: TeamsAuthConfig;

    private cacheStorage: BrowserStorage;

    constructor(config: TeamsAuthConfig) {
        this.config = config;
        this.cacheStorage = new BrowserStorage(config.cacheLocation);
    }

    public getAccessToken = async (parameters?: AuthenticationParameters): Promise<AccessTokenResponse> => {
        try {
            const instance = moment.utc();
            const accessTokenRaw = this.cacheStorage.getItem(StorageKeys.AccessToken);
            if (accessTokenRaw) {
                const accessToken = this.extractToken(accessTokenRaw);
                const accessTokenExpired = instance.isAfter(moment.unix(accessToken.exp));

                if (!accessTokenExpired) {
                    return { accessToken: accessTokenRaw };
                }
            }

            // could be more efficient and a lot more error handling and logging!

            const authToken = await this.getClientSideToken();
            const exchangedToken = await this.getServerSideToken(authToken);

            this.cacheStorage.setItem(StorageKeys.AccessToken, exchangedToken.accessToken);
            return exchangedToken;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    public getAccountInfo = () => {};

    // 1. Get auth token
    // Ask Teams to get us a token from AAD
    private getClientSideToken() {
        console.log("Getting auth token from Microsoft Teams");

        return new Promise((resolve, reject) => {
            microsoftTeams.authentication.getAuthToken({
                successCallback: (result) => {
                    resolve(result);
                },
                failureCallback: function (error) {
                    reject(error);
                },
            });
        });
    }

    // 2. Exchange that token for a token with the required permissions
    // using the web service (see /auth/token handler in app.js)
    private getServerSideToken(clientSideToken): Promise<any> {
        console.log("Exchange for server-side token");

        return new Promise((resolve, reject) => {
            microsoftTeams.getContext((context) => {
                fetch(this.config.tokenExchangeUri, {
                    method: this.config.tokenExchangeMethod || "post",
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": window.location.origin,
                    },
                    body: JSON.stringify({
                        tenantId: context.tid,
                        token: clientSideToken,
                    }),
                    mode: "cors",
                    cache: "default",
                })
                    .then((response: any) => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            reject(response.error);
                        }
                    })
                    .then((responseJson) => {
                        if (responseJson.error) {
                            reject(responseJson.error);
                        } else {
                            resolve(responseJson);
                        }
                    });
            });
        });
    }

    private decodeJwt(jwtToken) {
        if (!jwtToken) {
            return null;
        }
        var idTokenPartsRegex = /^([^\.\s]*)\.([^\.\s]+)\.([^\.\s]*)$/;
        var matches = idTokenPartsRegex.exec(jwtToken);
        if (!matches || matches.length < 4) {
            // this._requestContext.logger.warn("The returned id_token is not parsable.");
            return null;
        }
        var crackedToken = {
            header: matches[1],
            JWSPayload: matches[2],
            JWSSig: matches[3],
        };
        return crackedToken;
    }

    private extractToken = function (encodedToken) {
        var decodedToken = this.decodeJwt(encodedToken);
        if (!decodedToken) {
            return null;
        }
        try {
            var base64IdToken = decodedToken.JWSPayload;
            var base64Decoded = CryptoUtils.base64Decode(base64IdToken);
            if (!base64Decoded) {
                // this._requestContext.logger.info("The returned id_token could not be base64 url safe decoded.");
                return null;
            }
            // ECMA script has JSON built-in support
            return JSON.parse(base64Decoded);
        } catch (err) {
            // this._requestContext.logger.error("The returned id_token could not be decoded" + err);
        }
        return null;
    };
}

export class BrowserStorage {
    // Singleton

    protected cacheLocation: CacheLocation;

    constructor(cacheLocation: CacheLocation) {
        if (!window) {
            throw "Browser storage class could not find window object";
        }

        const storageSupported = typeof window[cacheLocation] !== "undefined" && window[cacheLocation] != null;
        if (!storageSupported) {
            throw "Storage location is not supported";
        }
        this.cacheLocation = cacheLocation;
    }

    /**
     * add value to storage
     * @param key
     * @param value
     */
    setItem(key: string, value: string): void {
        window[this.cacheLocation].setItem(key, value);
    }

    /**
     * get one item by key from storage
     * @param key
     */
    getItem(key: string): string {
        return window[this.cacheLocation].getItem(key);
    }

    /**
     * remove value from storage
     * @param key
     */
    removeItem(key: string): void {
        return window[this.cacheLocation].removeItem(key);
    }

    /**
     * clear storage (remove all items from it)
     */
    clear(): void {
        return window[this.cacheLocation].clear();
    }
}
