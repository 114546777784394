import { computed } from "mobx";

import { PrincipalContextStore } from "../../identity/stores/PrincipalContextStore";
import { RoleNames, isUserInAnyRoles } from "../../identity/stores/PrincipalContextUtilities";

import { WorkflowService } from "../../../api/workflows";
import { WorkflowStore } from "@modules/workflows/stores/WorkflowStore";
import { WorkflowSelectionStore } from "@modules/workflows/stores/WorkflowSelectionStore";

export class WorkflowPermissionStore {
    public parentStore: WorkflowStore;
    public selectionStore: WorkflowSelectionStore;
    public workflowService: WorkflowService;
    public principalContext: PrincipalContextStore;

    constructor(parentStore: WorkflowStore) {
        this.parentStore = parentStore;
        this.workflowService = parentStore.workflowService;
        this.selectionStore = parentStore.selectionStore;
        this.principalContext = parentStore.rootStore.principalContext;
    }

    @computed
    public get isAdmin() {
        return isUserInAnyRoles(this.principalContext.current, [RoleNames.Admin]);
    }

    @computed
    public get isAgent() {
        return isUserInAnyRoles(this.principalContext.current, [RoleNames.Agent]);
    }

    @computed
    public get canRespond() {
        const user = this.principalContext.current;
        const instance = this.selectionStore.instance;

        if (!instance || !user) {
            return false;
        }

        return instance.assignments.find((a) => a.assignedTo.id === user.id && a.status === "Pending");
    }

    @computed
    public get canEdit() {
        const user = this.principalContext.current;
        const instance = this.selectionStore.instance;

        if (!instance || !user) {
            return false;
        }

        return this.isAdmin || instance.createdBy.id === user.id;
    }
}
