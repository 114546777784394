import React, { Component } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { Text } from "office-ui-fabric-react/lib/Text";
import { Stack } from "office-ui-fabric-react/lib/Stack";

import { sanitize } from "../../stores/PatternUtilities";

import { ContentEditor } from "@modules/editors/components/ContentEditor";
import { ObservableBadge } from "@modules/base/components/ObservableBadge";
import { ActionSet } from "@modules/base/components/ActionSet";

const rootClassName = cx(
    "cygraph-PatternHeader-root",
    css`
        margin: var(--spacing-s) 0;

        .title {
            margin: var(--spacing-s) 0;
        }

        & .description {
            color: var(--text-color-light);
            font-size: var(--font-size-xs);
            margin-top: var(--spacing-xs);
        }
    `
);

export const PatternHeader = observer(
    class PatternHeader extends Component {
        render() {
            const { isEditMode, pattern, manifest } = this.props;
            const { items, overflowItems } = this._getActionSetItems();

            return (
                <div className={rootClassName}>
                    <Stack horizontal horizontalAlign="space-between">
                        <ObservableBadge className="badge">{pattern.target} Pattern</ObservableBadge>
                        <ActionSet items={items} overflowItems={overflowItems} />
                    </Stack>
                    <Text className="title" variant="xLarge" block>
                        <ContentEditor
                            html={manifest.name}
                            disabled={!isEditMode}
                            onSubmit={(e, val) => {
                                manifest.name = sanitize(val);
                            }}
                        />
                    </Text>
                    <div className="description">
                        <ContentEditor
                            html={(manifest.tags || []).join(", ")}
                            disabled={!isEditMode}
                            onSubmit={(e, val) => {
                                manifest.tags = sanitize(val)
                                    .split(",")
                                    .map((n) => (n ? n.trim() : n))
                                    .filter(function (el) {
                                        return el != null && el != "";
                                    });
                            }}
                        />
                    </div>
                </div>
            );
        }

        _getActionSetItems() {
            const { hideActions, isEditMode, canEdit, onEdit, canCancel, onCancel, canSave, onSave, canPublish, onPublish } = this.props;

            const items = [];
            const overflowItems = [];

            if (hideActions) {
                return { items, overflowItems };
            }

            if (!isEditMode) {
                items.push({
                    key: "edit",
                    name: "Edit",
                    iconProps: { iconName: "Edit" },
                    disabled: !canEdit || !canEdit(),
                    ariaLabel: "Edit",
                    onClick: onEdit,
                });
            } else {
                items.push({
                    key: "cancel",
                    name: "Discard",
                    iconProps: { iconName: "Uneditable" },
                    disabled: !canCancel || !canCancel(),
                    ariaLabel: "Discard",
                    onClick: onCancel,
                });

                items.push({
                    key: "save",
                    name: "Save",
                    iconProps: { iconName: "Save" },
                    disabled: !canSave || !canSave(),
                    ariaLabel: "Save",
                    onClick: () => onSave(),
                });
            }

            items.push({
                key: "publish",
                name: "Publish",
                primary: true,
                className: "publishButton",
                iconProps: { iconName: "ReadingMode" },
                disabled: !canPublish || !canPublish(),
                ariaLabel: "Publish",
                onClick: () => onPublish(),
            });

            return { items, overflowItems };
        }
    }
);
