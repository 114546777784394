import React from "react";
import { observer } from "mobx-react";
import moment from "moment";
import { cx, css } from "emotion";

import { Stack } from "@ui/elements/Stack";
import { Toggle } from "@ui/elements/Toggle";
import { Text } from "@ui/elements/Text";
import { FontIcon } from "@ui/elements/Icon";
import { PersonaSize } from "@ui/elements/Persona";
import { IconButton } from "@ui/elements/Button";

import { RoutingLink } from "@modules/base/components/RoutingLink";
import { ListItemReveal } from "@modules/base/components/ListItemReveal";
import { UserHoverCard } from "@modules/identity/containers/utilities/UserHoverCard";
import { PhotoPersona } from "@modules/identity/containers/utilities/PhotoPersona";

import {
    getEventDisplayName,
    getEventIconName,
    getActivityDisplayName,
    getActivityIconName,
    getActivityScopeLabel,
} from "@modules/workflows/stores/WorkflowConstants";

const rootClassName = cx(
    "cygraph-ActivityInstanceCardView-root",
    css`
        background: var(--white);
        border-radius: var(--border-radius);
        box-shadow: var(--box-shadow);
        border: 1px solid var(--border-color);
        padding: var(--spacing-s);
        margin-bottom: var(--spacing-xs);
        min-height: 100px;
    `
);

export const ActivityInstanceCardView = observer(({ className, instance, simple, canEdit, onEdit, onRemove }) => {
    return (
        <Stack
            className={cx(rootClassName, className, "ms-DetailsRow")}
            horizontal
            tokens={{ childrenGap: 35 }}
            verticalFill
            verticalAlign="center"
        >
            <Stack horizontal tokens={{ childrenGap: 25 }} verticalFill verticalAlign="center">
                <FontIcon iconName={getEventIconName(instance.trigger.event)} style={{ fontSize: 30 }} />
                <FontIcon iconName="DoubleChevronRight8" style={{ fontSize: 13, color: "var(--grey-light)" }} />
                <FontIcon iconName={getActivityIconName(instance.definition)} style={{ fontSize: 30 }} />
            </Stack>
            <Stack.Item grow>
                <Text variant="mediumPlus" block>
                    {canEdit ? (
                        <RoutingLink onClick={() => onEdit(instance)} onClickEnabled={!!onEdit}>
                            {instance.name}
                        </RoutingLink>
                    ) : (
                        <span>{instance.name}</span>
                    )}
                </Text>
                <Text variant="small" block>
                    {getEventDisplayName(instance.trigger.event)} 🠖 {getActivityDisplayName(instance.definition)}
                </Text>
                <Text variant="small" block>
                    {getActivityScopeLabel(instance.scope)}
                </Text>
                <Text variant="small" block>
                    {instance.notes}
                </Text>
            </Stack.Item>
            {canEdit && (onEdit || onRemove) && (
                <Stack.Item shrink>
                    <ListItemReveal>
                        <IconButton
                            styles={{ menuIcon: { fontSize: "18px" } }}
                            menuIconProps={{ iconName: "MoreVertical" }}
                            menuProps={{
                                items: [
                                    {
                                        key: "edit",
                                        text: "Edit",
                                        iconProps: {
                                            iconName: "Edit",
                                        },
                                        onClick: () => onEdit(instance),
                                    },
                                    {
                                        key: "remove",
                                        text: "Remove",
                                        iconProps: {
                                            iconName: "Delete",
                                        },
                                        onClick: () => onRemove(instance),
                                    },
                                ],
                            }}
                        />
                    </ListItemReveal>
                </Stack.Item>
            )}
            {!simple && (
                <Stack.Item style={{ width: 230 }} verticalFill verticalAlign="center">
                    <UserHoverCard principal={instance.createdBy}>
                        <PhotoPersona
                            principalId={instance.createdBy ? instance.createdBy.id : null}
                            size={PersonaSize.size28}
                            text={instance.createdBy ? instance.createdBy.name : "Unassigned"}
                            showUnknownPersonaCoin={!instance.createdBy}
                            showSecondaryText={instance.createdBy}
                            secondaryText={moment.utc(instance.created).format("Do MMM YYYY, h:mm:ss a")}
                        />
                    </UserHoverCard>
                </Stack.Item>
            )}
            {!simple && (
                <Stack.Item style={{ width: 150 }} verticalFill verticalAlign="center">
                    <Toggle
                        checked={instance.active}
                        disabled={!canEdit}
                        onText="Enabled"
                        offText="Disabled"
                        onChange={(ev, val) => {
                            onEdit({ ...instance, active: val });
                        }}
                    />
                </Stack.Item>
            )}
        </Stack>
    );
});
