import React from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { Stack } from "office-ui-fabric-react/lib/Stack";

import { ContextContainerHost } from "@modules/workflows/containers/utilities/ContextContainerHost";
import { TaskContainerHost } from "@modules/workflows/containers/utilities/TaskContainerHost";
import { AutomationContainerHost } from "@modules/workflows/containers/utilities/AutomationContainerHost";

export const AssessmentToolBarHost = observer(({ assessment }) => {
    const { routingManager, assessmentStore } = useStores();

    const canManageTasks = assessmentStore.lifecycleStore.canManageTasks;
    const canManageAutomations = assessmentStore.lifecycleStore.canManageAutomations;

    const context = { id: assessment.id, type: "assessment", label: assessment.code, resource: assessment };

    return (
        <Stack horizontal tokens={{ childrenGap: 0 }}>
            <ContextContainerHost context={context} />
            <TaskContainerHost context={context} routingManager={routingManager} canManage={canManageTasks} />
            <AutomationContainerHost
                context={context}
                routingManager={routingManager}
                canManage={canManageAutomations}
            />
        </Stack>
    );
});
