import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react";

import * as joint from "jointjs";

import { isTargetEndState, isTargetCancelState } from "../../stores/TargetMetadata";
import { portIn, portOut } from "./StateFlowStatePorts";
import { StateFlowTriggerView } from "./StateFlowTriggerView";

export const StateFlowStateView = observer(({ state, index, manifest, parent, graph, options }) => {
    const rect = useMemo(() => {
        const el = new joint.shapes.standard.Rectangle({
            attrs: {
                body: {
                    fill: "var(--white)",
                    rx: 3,
                    ry: 3,
                    strokeWidth: 2,
                    stroke: "var(--border-color-darker)",
                },
                label: {
                    fill: "var(--text-color)",
                    fontSize: 14,
                },
            },
            ports: {
                groups: {
                    in: portIn,
                    out: portOut,
                },
            },
        });

        el.set("id", state.id);
        parent.embed(el);
        el.set("z", 60);
        graph.addCell(el);

        return el;
    }, [graph]);

    useEffect(() => {
        // const onPositionChanged = (event) => {
        //     if (state) {
        //         state.design = { position: event.attributes.position };
        //     }
        // };

        if (state) {
            rect.size(225, 75);
            rect.set("id", state.id);
            parent.embed(rect);

            rect.prop("data", {
                id: state.id,
                type: "state",
                locked: false,
                mappedTo: state.mappedTo,
            });
            //rect.fitEmbeds();

            // rect.on("change:position", onPositionChanged);
        }

        // return () => {
        //     rect.off("change:position", onPositionChanged);
        // };
    }, [rect, parent, state, index]);

    useEffect(() => {
        if (state && manifest) {
            const initialState = manifest.initialStateId === state.id;
            const portInLookup = { group: "in", id: "in" };
            if (!initialState) {
                if (!rect.hasPort(portInLookup)) {
                    rect.addPort(portInLookup);
                }
            } else {
                rect.removePort(portInLookup);
            }

            const portOutLookup = { group: "out", id: "out" };
            if (!isTargetEndState(manifest.target, state)) {
                if (!rect.hasPort(portOutLookup)) {
                    rect.addPort(portOutLookup);
                }
            } else {
                rect.removePort(portOutLookup);
            }
        }
    }, [rect, state, manifest]);

    useEffect(() => {
        if (state) {
            if (isTargetCancelState(manifest.target, state)) {
                const count = manifest.states.reduce((acc, s) => {
                    return (
                        acc +
                        (!s.triggers ? 0 : s.triggers.filter((t) => t.command && t.command.to === state.id).length)
                    );
                }, 0);
                rect.attr("label/text", `${state.name} (${count})`);
            } else {
                rect.attr("label/text", state.name);
            }
        }
    }, [rect, state, manifest]);

    useEffect(() => {
        if (state) {
            if (state.design && state.design.position) {
                const current = rect.position();
                rect.translate(state.design.position.x - current.x, state.design.position.y - current.y);
            } else {
                rect.position(35, 65 + index * 85, { parentRelative: true });
            }
        }
    }, [rect, state, index]);

    useEffect(() => {
        return () => {
            rect.remove();
        };
    }, []);

    const showHiddenTriggers = options && options.showHiddenTriggers;
    const cancelStateIds = (manifest.states || [])
        .filter((state) => isTargetCancelState(manifest.target, state))
        .map((state) => state.id);

    return (
        <>
            {state &&
                state.triggers &&
                state.triggers
                    .filter(
                        (trigger) =>
                            !!trigger.command.to &&
                            (showHiddenTriggers || cancelStateIds.indexOf(trigger.command.to) === -1)
                    )
                    .map((trigger, i) => (
                        <StateFlowTriggerView
                            key={trigger.id}
                            state={state}
                            trigger={trigger}
                            index={i}
                            manifest={manifest}
                            graph={graph}
                            parent={rect}
                            options={options}
                        />
                    ))}
        </>
    );
});
