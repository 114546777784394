import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { useStores } from "@hooks/useStores";
import * as moment from "moment";

import { AppPage } from "@modules/layout/containers/AppPage";

import { AuditActivityCalendarView } from "@modules/controls/components/audits/AuditActivityCalendarView";

export const AuditActivityCalendarPage = withRouter(
    observer(({ match }) => {
        const { pageContext, routing, controlStore } = useStores();
        const { auditCalendarStore } = controlStore;
        const { breadcrumb, error, date, events, query, view, loading } = auditCalendarStore;

        useEffect(() => {
            pageContext.updateQueryStringParams(routing.location.search);
            if (pageContext.queryStringParams.start && pageContext.queryStringParams.end) {
                auditCalendarStore.loadEvents({ ...pageContext.queryStringParams });
            }
        }, [match, routing, routing.location.search]);

        return (
            <AppPage
                loading={loading}
                breadcrumb={breadcrumb}
                error={error}
                hasPageNav={true}
                hasSidebar={false}
                className="controls list audit calendar"
            >
                <AuditActivityCalendarView
                    events={events}
                    view={view}
                    loading={loading}
                    date={date}
                    query={query}
                    onDatesSet={(rangeInfo) => {
                        const date = moment(rangeInfo.view.calendar.getDate()).utc().format();
                        const start = moment(rangeInfo.start).utc().format();
                        const end = moment(rangeInfo.end).utc().format();
                        routing.push(
                            `/controls/calendar?view=${rangeInfo.view.type.substr(
                                7
                            )}&date=${date}&start=${start}&end=${end}`
                        );
                    }}
                    onEventClick={(info) => {
                        //console.log(info);
                        //routing.push(`/assurance/browse/${info.event.extendedProps.assessment.id}`);
                        // viewStore.show({
                        //     assessmentId: info.event.extendedProps.assessment.id
                        // });
                    }}
                />
            </AppPage>
        );
    })
);
