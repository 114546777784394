import React, { Component } from "react";
import { observer } from "mobx-react";

import { Stack } from "office-ui-fabric-react/lib/Stack";
import { IconButton } from "office-ui-fabric-react/lib/Button";
import { Text } from "office-ui-fabric-react/lib/Text";

import { BoxPanel } from "../../../base/components/BoxPanel";

export const ModuleCardView = observer(
    class ModuleCardView extends Component {
        render() {
            const { className, module, isEditMode, onModuleClick, canEditModule, onEditModule, canRemoveModule, onRemoveModule } = this.props;
            const count = module.groups.reduce((acc, group) => acc + group.measures.length, 0);

            return (
                <BoxPanel
                    padding
                    small
                    shadow
                    className={className}
                    role="button"
                    onClick={(ev) => {
                        ev.preventDefault();
                        onModuleClick(module);
                    }}
                >
                    <Stack horizontal>
                        <Stack.Item grow={1}>
                            <Text variant="large" block className="row--xxs">
                                {module.title}
                            </Text>
                            <Text variant="small" block>
                                {module.groups.length} groups, {count} questions
                            </Text>
                        </Stack.Item>
                        {isEditMode && (
                            <Stack tokens={{ maxWidth: 50 }} styles={{}}>
                                <IconButton
                                    styles={{ menuIcon: { fontWeight: "bold", fontSize: "20px" } }}
                                    menuIconProps={{ iconName: "MoreVertical" }}
                                    menuProps={{
                                        items: [
                                            {
                                                key: "editModule",
                                                text: "Edit module",
                                                iconProps: { iconName: "Edit" },
                                                disabled: !canEditModule,
                                                onClick: (e) => {
                                                    e.preventDefault();
                                                    onEditModule({ module });
                                                },
                                            },
                                            {
                                                key: "removeModule",
                                                text: "Remove module",
                                                iconProps: { iconName: "Delete" },
                                                disabled: module.groups.length > 0 && !canRemoveModule,
                                                onClick: (e) => {
                                                    e.preventDefault();
                                                    onRemoveModule({ module });
                                                },
                                            },
                                        ],
                                    }}
                                />
                            </Stack>
                        )}
                    </Stack>
                </BoxPanel>
            );
        }
    }
);
