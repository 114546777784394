import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";

import { Stack } from "office-ui-fabric-react/lib/Stack";
import { PanelType } from "office-ui-fabric-react/lib/Panel";
import { PrimaryButton, ActionButton } from "office-ui-fabric-react/lib/Button";

import { ObservablePanel } from "../../../base/components/ObservablePanel";
import { ReviewEditForm } from "../../components/reviews/ReviewEditForm";
import { RiskEditForm } from "../../components/core/RiskEditForm";

export const RiskNewPanel = inject(
    "riskStore",
    "routing"
)(
    withRouter(
        observer(
            class RiskEditPanel extends Component {
                render() {
                    const { editStore } = this.props.riskStore;
                    const { visible, formData, formOptions } = editStore;

                    return (
                        <ObservablePanel
                            hidden={!visible}
                            pageType={(formOptions && formOptions.typeName) || "Risk"}
                            panelType={PanelType.small}
                            onClosePanel={() => this._onDismissClick()}
                            onRenderFooter={() => this._renderFooter()}
                            onLightDismissClick={() => this._onDismissClick()}
                        >
                            {formData && this._renderSections()}
                        </ObservablePanel>
                    );
                }

                _renderSections() {
                    const { editStore } = this.props.riskStore;
                    const { formData, formOptions, isValid, isSectionValid, section } = editStore;

                    return (
                        <div>
                            {section == "Risk" && (
                                <div>
                                    <RiskEditForm
                                        formData={formData}
                                        formOptions={formOptions}
                                        isSectionValid={isSectionValid}
                                        isValid={isValid}
                                    />
                                </div>
                            )}
                            {section == "Review" && (
                                <div>
                                    <ReviewEditForm
                                        formData={formData.review}
                                        formOptions={formOptions}
                                        isSectionValid={isSectionValid}
                                        isValid={isValid}
                                    />
                                </div>
                            )}
                        </div>
                    );
                }

                _renderFooter() {
                    const { editStore } = this.props.riskStore;
                    const { formData, formOptions, isValid, isSectionValid, section } = editStore;

                    if (!formData || !formOptions) {
                        return null;
                    }

                    return (
                        <Stack horizontal horizontalAlign="space-between">
                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                {formOptions.allowReview && section != "Risk" && (
                                    <ActionButton
                                        styles={{ root: { height: "32px" } }}
                                        onClick={() => editStore.previousSection()}
                                        text="Back"
                                        disabled={this.props.saving}
                                    />
                                )}
                            </Stack>
                            <Stack horizontal tokens={{ childrenGap: 20 }}>
                                <ActionButton
                                    styles={{ root: { height: "32px" } }}
                                    onClick={() => this._onHidePanel()}
                                    text="Cancel"
                                    disabled={this.props.saving}
                                />
                                {(!formOptions.allowReview || section == "Review") && (
                                    <PrimaryButton
                                        onClick={() => this._onSaveRisk()}
                                        iconProps={{ iconName: "Save" }}
                                        text="Save"
                                        disabled={!isValid || this.props.saving}
                                    />
                                )}
                                {formOptions &&
                                    formOptions.allowSubmit &&
                                    (!formOptions.allowReview || section == "Review") && (
                                        <PrimaryButton
                                            onClick={() => this._onSaveRisk(true)}
                                            iconProps={{ iconName: "Save" }}
                                            text="Save &amp; Submit"
                                            disabled={!isValid || this.props.saving}
                                        />
                                    )}
                                {formOptions.allowReview && section == "Risk" && (
                                    <PrimaryButton
                                        onClick={() => editStore.nextSection()}
                                        text="Next"
                                        disabled={!isSectionValid || this.props.saving}
                                    />
                                )}
                            </Stack>
                        </Stack>
                    );
                }

                _onSaveRisk(submit) {
                    const { editStore } = this.props.riskStore;
                    const { formData, isValid } = editStore;

                    if (isValid) {
                        formData.submit = !!submit;
                        this.props.riskStore.createRisk(formData).then((risk) => {
                            editStore.hide({ clearData: true });
                            if (this.props.onRiskCreated) {
                                this.props.onRiskCreated(risk);
                            } else {
                                this.props.routing.push(`/risks/browse/${risk.id}`);
                            }
                        });
                    }
                }

                _onHidePanel() {
                    const { editStore } = this.props.riskStore;
                    editStore.hide({ clearData: true });
                }

                _onDismissClick() {
                    const { editStore } = this.props.riskStore;
                    this._onHidePanel();
                }

                _onHideDialog(ok) {
                    const { editStore } = this.props.riskStore;
                    editStore.setDialogVisible(false);
                    if (ok) {
                        this._onHidePanel();
                    }
                }
            }
        )
    )
);
