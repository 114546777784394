import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { useStores } from "@hooks/useStores";

import { AppPage } from "@modules/layout/containers/AppPage";
import { BoxPanel } from "@modules/base/components/BoxPanel";

import { StateFlowEditorView } from "@modules/stateflows/components/editor/StateFlowEditorView";
import { StateFlowEditorHeader } from "@modules/stateflows/components/editor/StateFlowEditorHeader";
import { StateFlowEditorCommandBar } from "@modules/stateflows/components/editor/StateFlowEditorCommandBar";
import { StateFlowPublishDialog } from "@modules/stateflows/components/core/StateFlowPublishDialog";
import { StateFlowStateEditPanel } from "@modules/stateflows/containers/panels/StateFlowStateEditPanel";
import { StateFlowStageEditPanel } from "@modules/stateflows/containers/panels/StateFlowStageEditPanel";
import { StateFlowTriggerEditPanel } from "@modules/stateflows/containers/panels/StateFlowTriggerEditPanel";
import { StateFlowPreviewPanel } from "@modules/stateflows/containers/panels/StateFlowPreviewPanel";
import { StateFlowEditorMenu } from "@modules/stateflows/containers/utilities/StateFlowEditorMenu";

export const StateFlowEditorPage = withRouter(
    observer(({ match }) => {
        const { stateFlowStore, routing } = useStores();
        const { editorStore } = stateFlowStore;
        const { breadcrumb, stateFlow, manifest, options, loading, error } = editorStore;

        useEffect(() => {
            editorStore.loadStateFlow(match.params.id);
        }, [match.params.id]);

        return (
            <AppPage loading={loading} error={error} breadcrumb={breadcrumb} hasSidebar={false} className="workflows view">
                {!loading && manifest && (
                    <div>
                        <StateFlowEditorHeader
                            stateFlow={stateFlow}
                            manifest={manifest}
                            canDiscard={() => editorStore.canDiscard}
                            onDiscard={(options) => editorStore.reset(options)}
                            canSave={() => editorStore.canSave}
                            onSave={() => editorStore.onSave({ mode: "Build", confirm: false })}
                            canPublish={() => editorStore.canPublish}
                            onPublish={() => editorStore.onSave({ mode: "Minor" })}
                        />

                        <StateFlowEditorCommandBar
                            onNew={() => true}
                            onDuplicate={() => true}
                            showHiddenTriggers={options.showHiddenTriggers}
                            onShowHiddenTriggers={() => (options.showHiddenTriggers = !options.showHiddenTriggers)}
                            onPreview={(options) => editorStore.onPreview(options)}
                        />

                        <BoxPanel shadow padding style={{ padding: 15 }}>
                            <StateFlowEditorView
                                manifest={manifest}
                                mode="edit"
                                options={editorStore.options}
                                isDirty={editorStore.isDirty}
                                onAddStage={(options) => editorStore.onAddStage(options)}
                                onEditStage={(options) => editorStore.onEditStage(options)}
                                onRemoveStage={(options) => editorStore.onRemoveStage(options)}
                                onAddState={(options) => editorStore.onAddState(options)}
                                onEditState={(options) => editorStore.onEditState(options)}
                                onRemoveState={(options) => editorStore.onRemoveState(options)}
                                onMoveState={(options) => editorStore.onMoveState(options)}
                                onAddTrigger={(options) => editorStore.onAddTrigger(options)}
                                onEditTrigger={(options) => editorStore.onEditTrigger(options)}
                                onMoveTrigger={(options) => editorStore.onMoveTrigger(options)}
                                onRemoveTrigger={(options) => editorStore.onRemoveTrigger(options)}
                                onContextMenu={(options) => editorStore.onContextMenu(options)}
                            />
                            <StateFlowEditorMenu store={editorStore.contextMenuStore} />
                        </BoxPanel>

                        <StateFlowPublishDialog
                            visible={editorStore.publishStore.visible}
                            value={editorStore.publishStore.value}
                            isDirty={editorStore.publishStore.isDirty}
                            isValid={editorStore.publishStore.isValid}
                            onSave={(options) => editorStore.publishStore.onSave(options)}
                            onCancel={() => editorStore.publishStore.onCancel()}
                        />

                        <StateFlowStateEditPanel store={editorStore.stateEditStore} />
                        <StateFlowStageEditPanel store={editorStore.stageEditStore} />
                        <StateFlowTriggerEditPanel store={editorStore.triggerEditStore} />
                        <StateFlowPreviewPanel store={editorStore.previewStore} />
                    </div>
                )}
            </AppPage>
        );
    })
);
