import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { PrincipalActivityStore } from '../../stores/PrincipalActivityStore';
import { isUserInAnyRoles, RoleNames } from '../../stores/PrincipalContextUtilities';
import { UserHoverCard as StatelessUserHoverCard } from '../../components/users/UserHoverCard';

export const UserHoverCard = inject('rootStore', 'principalContext')(observer(
    class UserHoverCard extends Component {

        constructor(props) {
            super(props);

            this.state = { 
                activityStore: new PrincipalActivityStore(this.props.rootStore),
            }
        }
    
        render () {
            const { activityStore } = this.state;
            const { activities, loading, principal } = activityStore;
            const { current } = this.props.principalContext;
            const simpler = !isUserInAnyRoles(current, [RoleNames.Admin, RoleNames.Agent]);

            if(!this.props.principal) {
                return this.props.children;
            }
            
            return (
                <StatelessUserHoverCard 
                    loading={loading}
                    principal={principal || this.props.principal}
                    activities={activities}
                    plain={this.props.plain || simpler}
                    onCardVisible={() => {
                        if(this.props.principal) {
                            //loactivityStore.loadActivities(this.props.principal.id, !simpler);
                        }
                    }}>
                    { this.props.children }
                </StatelessUserHoverCard>
            );
        }
    }
));
