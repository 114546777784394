const actions = [
  {
      key: "newAssessmentTriage",
      text: "New Assessment Triage",
      link: "/assurance/triage",
      icon: "AssessmentGroup",
      title: "New assessment triage",
  },
  {
      key: "newWorkItem",
      text: "New Work Item",
      link: "/demand/catalogue",
      icon: "WorkItem",
      title: "New Request",
  },
  {
      key: "browseException",
      text: "Browse Exception",
      link: "/exceptions/browse",
      icon: "ViewList",
      title: "Browse Exception",
  },
  {
      key: "externalUrl",
      text: "External URL",
  },
  {
      key: "internalUrl",
      text: "Internal URL",
  },
];

export { actions }