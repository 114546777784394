import React from "react";

import { View, Text } from "@react-pdf/renderer";

import { Paragraph } from "@ui/pdf/Paragraph";

export const RichTextViewer = ({ value, style }) => {
    return value ? (
        <View style={style}>
            {value
                .filter((n) => n.type === "paragraph")
                .map((n, i) => (
                    <Paragraph key={i}>
                        {n.children
                            .filter((c) => !!c.text)
                            .map((t, j) => (
                                <Text key={j}>{t.text}</Text>
                            ))}
                    </Paragraph>
                ))}
        </View>
    ) : null;
};
