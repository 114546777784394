import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { Persona, PersonaSize } from "office-ui-fabric-react/lib/Persona";

import { Label } from "@ui/elements/Label";
import { TooltipHost } from "@ui/elements/Tooltip";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { JsonQueryString } from "@modules/utils/JsonQueryString";
import { ObservablePagination } from "@modules/base/components/ObservablePagination";
import { AdaptiveField } from "@modules/base/components/AdaptiveField";
import { FieldSetView } from "@modules/base/components/FieldSetView";
import { UserHoverCard } from "@modules/identity/containers/utilities/UserHoverCard";
import { PhotoPersona } from "@modules/identity/containers/utilities/PhotoPersona";

import { ControlMaturity } from "@modules/controls/components/core/ControlMaturity";
import { DeploymentGridView } from "@modules/controls/components/deployments/DeploymentGridView";
import { TacticalControlGridView } from "@modules/controls/components/tactical/TacticalControlGridView";

export const StrategicControlChildrenTab = observer(({ control, etag }) => {
    const { pageContext, routing, controlStore } = useStores();
    const { tacticalBrowseStore } = controlStore;
    const { controls, query, loading, pagination } = tacticalBrowseStore;

    useEffect(() => {
        pageContext.updateQueryStringParams(routing.location.search);
        tacticalBrowseStore.loadControls({
            page: pageContext.queryStringParams.p || 1,
            query: {
                parent: [{ id: control.id }],
            },
        });
    }, [control, etag, routing.location.search]);

    return (
        <>
            <TacticalControlGridView loading={loading} query={query} controls={controls} />
            <ObservablePagination
                {...pagination}
                onPageChange={(index) => {
                    routing.push(JsonQueryString.build(`/controls/browse/${control.id}/children`, {}, index + 1));
                }}
            />
        </>
    );
});
