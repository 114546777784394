import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import { TagPicker } from 'office-ui-fabric-react/lib/Pickers';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import { DatePicker, DayOfWeek, IDatePickerStrings } from 'office-ui-fabric-react/lib/DatePicker';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { IconButton, Stack } from 'office-ui-fabric-react';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { cs, css, cx } from 'emotion';
import { Persona, PersonaSize } from 'office-ui-fabric-react/lib/Persona';
import { SystemPicker } from '../../../../../systems/components/SystemPicker';
import { SystemViewer } from '../../../../../systems/components/SystemViewer';
import { RichTextField } from '../../../../../editors/components/RichTextField';
import { ObservableBadge } from '../../../../../base/components/ObservableBadge';
import { ObservablePanel } from '../../../../../base/components/ObservablePanel';
import { BigTextField } from '../../../../../base/components/BigTextField';
import RichTextViewer from '../../../../../editors/components/RichTextViewer';
import { PhotoPersona } from '../../../../../identity/containers/utilities/PhotoPersona';

const buttonStyles = { root: { marginRight: 8 } };

export const WorkItemDetailsPanel = observer(
    class WorkItemDetailsPanel extends Component {

        componentWillMount() {
            if (!this.props.workItem && this.props.selectedLink) this.props.loadWorkItem(this.props.selectedLink.target.id);
        }

        componentWillReceiveProps(nextProps) {
            if (!nextProps.workItem && nextProps.selectedLink) {
                nextProps.loadWorkItem(nextProps.selectedLink.target.id);
            }
        }

        onSaveButtonPressed () {
            const { onSaveButton } = this.props;
            onSaveButton(this.state);
        }

        _getTextFromItem (item) {
            return item.name;
        }

        get _workItemLinkTypes () {
            const { workItemLinkTypes } = this.props;
            return workItemLinkTypes.map(L => {
                return {
                    key: L.id, 
                    text: L.name
                }
            })
        }

        get _portfolios() {
            const { portfolios } = this.props;
            return portfolios.map(L => {
                return {
                    key: L.id, 
                    text: L.name
                }
            })
        }

        get _products() {
            const { products } = this.props;
            return products.map(L => {
                return {
                    key: L.id, 
                    text: L.name
                }
            })
        }

        get _priorities() {
            return [
                { key: 'High', text: 'High' },
                { key: 'Medium', text: 'Medium' },
                { key: 'Low', text: 'Low' }
            ];
        }

        onRenderFooterContent = () => {
            const { onDismissPanel, onGoToWorkItem, workItem, selectedLink} = this.props;
            // const { linkType, workItemType, portfolio, priority} = this.state;
            return (
                <div>
                    <DefaultButton
                        onClick={onDismissPanel}
                        className={cx(css`margin-right: 10px`)}
                    >
                        Close
                    </DefaultButton>
                    {onGoToWorkItem && <DefaultButton
                        onClick={() => onGoToWorkItem(selectedLink.target.id)}
                    > Go to the Work Item </DefaultButton>}
                </div>
            )};

        get isReady() {
            const { loading, selectedLink, workItem, hideLinkType } = this.props;
            if (!loading && workItem && workItem.product && workItem.portfolio) {
                if (hideLinkType || (!hideLinkType && selectedLink)) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        }

        render () {
            const {  
                isOpen,
                onDismissPanel,
                loading,
                selectedLink,
                workItem,
                hideLinkType,
                title,
            } = this.props;
            return (
                <ObservablePanel
                    hidden={!isOpen}
                    onClosePanel={onDismissPanel}
                    closeButtonAriaLabel="Close"
                    onRenderFooter={this.onRenderFooterContent}
                    isFooterAtBottom={true}
                    type={PanelType.medium}
                    pageType={title?title:`Related Work Item`}
                >
                    {this.isReady && (                        
                        <Stack vertical>
                            {!hideLinkType &&
                            <Stack horizontal tokens={{childrenGap: 40, padding: 10}} styles={{root: {borderBottom: '1px solid'}}}>
                                <Stack.Item align="auto" grow={12}>
                                    <Text 
                                        variant={'xxLarge'}
                                    > {selectedLink.target.title} </Text>
                                </Stack.Item>
                            </Stack>}
                            {hideLinkType &&
                            <Stack horizontal tokens={{childrenGap: 40, padding: 10}} styles={{root: {borderBottom: '1px solid'}}}>
                                <Stack.Item align="auto" grow={12}>
                                    <Text 
                                        variant={'xxLarge'}
                                    > {workItem.title} </Text>
                                </Stack.Item>
                            </Stack>}
                            {!hideLinkType &&
                            <Stack horizontal tokens={{childrenGap: 40, padding: 10}}>
                                <Stack.Item align="auto" grow={12}>
                                    <Label>Link type:</Label>
                                    <Text>{selectedLink.linkType.name}</Text>
                                </Stack.Item>
                            </Stack>}
                            <Stack horizontal tokens={{childrenGap: 40, padding: 10}}>
                                <Stack.Item align="auto" grow={6}>
                                    <Label>Type:</Label>
                                    <Text>{workItem.product.name}</Text>
                                </Stack.Item>
                            </Stack>
                            <Stack horizontal tokens={{childrenGap: 40, padding: 10}}>
                                <Stack.Item align="auto" grow={6}>
                                    <Label>Portfolio:</Label>
                                    <Text>{workItem.portfolio.name}</Text>
                                </Stack.Item>
                                <Stack.Item align="auto" grow={6}>
                                    <Label>System:</Label>
                                    <SystemViewer 
                                        system={workItem.system}
                                    />
                                </Stack.Item>
                            </Stack>
                            <Stack horizontal tokens={{childrenGap: 40, padding: 10}}>
                                <Stack.Item align="auto" grow={6}>
                                    <Label>Business Contact:</Label>
                                    {workItem.businessContact ? <PhotoPersona
                                        principalId={workItem.businessContact ? workItem.businessContact.id : null}
                                        size={PersonaSize.size28} 
                                        text={workItem.businessContact.name} />
                                    : <Text>Not Assigned</Text>}
                                </Stack.Item>
                                <Stack.Item align="auto" grow={6}>
                                    <Label>Technical Contact:</Label>
                                    {workItem.technicalContact ? <PhotoPersona
                                        principalId={workItem.technicalContact ?workItem.technicalContact.id : null}
                                        size={PersonaSize.size28} 
                                        text={workItem.technicalContact.name}  />
                                    : <Text>Not Assigned</Text>}
                                </Stack.Item>
                            </Stack>
                            <Stack horizontal tokens={{childrenGap: 40, padding: 10}}>
                                <Stack.Item align="auto" grow={6}>
                                    <Label>Priority:</Label>
                                    <Text>Priority: {workItem.priority}</Text>
                                </Stack.Item>
                                <Stack.Item align="auto" grow={6}>
                                    <Label>Due date:</Label>
                                    <Text>{workItem.dueDate}</Text>
                                </Stack.Item>
                            </Stack>
                            <Stack horizontal tokens={{childrenGap: 40, padding: 10}}>
                                <Stack.Item align="auto" grow={12}>
                                    <Label>Details:</Label>
                                    <RichTextViewer value={workItem.description} />
                                </Stack.Item>
                            </Stack>
                        </Stack>
                    )}
                    {loading && (
                        <Text block>Loading... </Text>
                    )}
                    {!loading && workItem === null && (
                         <Text block>Loading work item information</Text>
                    )}
                </ObservablePanel>
            );
        }
    }
);