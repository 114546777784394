import React, { Component } from "react";
import { observer, inject } from "mobx-react";

import { JsonQueryString } from "../../../utils/JsonQueryString";
import { AppPage } from "../../../layout/containers/AppPage";
import { ObservablePagination } from "../../../base/components/ObservablePagination";

import { RiskGridView } from "../../components/core/RiskGridView";
import { RiskSideNav } from "../../components/core/RiskSideNav";
import { RiskCommandBar } from "../../components/core/RiskCommandBar";
import { RiskNewPanel } from "../panels/RiskNewPanel";

import { netRiskScoresItems } from "../../stores/RiskContants";

import { ModuleNames, getModuleConfig } from "../../../../configProvider";

const getQueryStringParams = (query) => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query).split("&").reduce((params, param) => {
              let [key, value] = param.split("=");
              params[key] = value ? decodeURIComponent(value.replace(/\+/g, " ")) : "";
              return params;
          }, {})
        : {};
};

export const RiskPriorityPage = inject(
    "pageContext",
    "riskStore",
    "routing"
)(
    observer(
        class RiskPriorityPage extends Component {
            componentWillMount() {
                const { riskStore, routing } = this.props;
                const { riskPriorityStore, typeStore, registryStore } = riskStore;

                const params = getQueryStringParams(routing.location.search);
                registryStore.loadRegistries({ cached: true });
                riskPriorityStore.loadPortfolios();
                let query = {
                    keywords: null,
                    portfolio: null,
                    subStatus: null,
                    assignedToUser: null,
                };
                if (params.query) {
                    query = JSON.parse(params["query"]);
                }

                riskPriorityStore.loadSubStatuses();
                riskPriorityStore.loadAssignedUser(query.assignedToUser);
                riskPriorityStore.loadRisks({ page: params["p"] || 1, query: query });
            }

            componentWillReceiveProps(nextProps) {
                const { riskStore, routing } = nextProps;
                const { riskPriorityStore, typeStore, registryStore } = riskStore;

                const params = getQueryStringParams(routing.location.search);

                riskPriorityStore.loadPortfolios();
                typeStore.loadTypes({ cached: true });
                registryStore.loadRegistries({ cached: true });
                let query = {
                    keywords: null,
                    portfolio: null,
                    subStatus: null,
                    assignedToUser: null,
                };
                if (params.query) {
                    query = JsonQueryString.decode(params["query"]);
                }

                riskPriorityStore.loadSubStatuses();
                riskPriorityStore.loadAssignedUser(query.assignedToUser);
                riskPriorityStore.loadRisks({ page: params["p"] || 1, query: query });
            }

            get filterFields() {
                const { riskStore } = this.props;
                const { riskPriorityStore } = riskStore;
                const { portfolios, statusList, query, assignedToObjects } = riskPriorityStore;
                const {
                    keywords,
                    portfolio,
                    subStatus,
                    status,
                    grossScore,
                    grossImpact,
                    grossLikelihood,
                    netImpact,
                    netLikelihood,
                    netScore,
                    registry,
                } = query;

                const riskModule = getModuleConfig(ModuleNames.Risks);
                //get the number of options I need for the likelyhood from the module, if nothing is found on the module I default to 4
                //1. Create an array of that size
                //2. Get the keys of that array and convert it to an array to have something like [0,1,2,3,4 ...]
                //3. Map that array adding 1 and the likelihood (and impact) starts from 1
                const impactArray = Array.from(Array(riskModule.maxImpact || 4).keys()).map((E) => E + 1);
                const likelihoodArray = Array.from(Array(riskModule.maxLikelihood || 4).keys()).map((E) => E + 1);
                const impactDropdownOptions = impactArray.map((I) => {
                    return { key: I, text: I };
                });
                const likelihoodDropdownOptions = likelihoodArray.map((I) => {
                    return { key: I, text: I };
                });
                const gL = grossLikelihood && grossLikelihood.length > 0 ? grossLikelihood : likelihoodArray;
                const gI = grossImpact && grossImpact.length > 0 ? grossImpact : impactArray;
                const nL = netLikelihood && netLikelihood.length > 0 ? netLikelihood : likelihoodArray;
                const nI = netImpact && netImpact.length > 0 ? netImpact : impactArray;

                const grossScoreItems = [];
                const netScoreItems = [];
                gL.forEach((L) => {
                    gI.forEach((I) => {
                        grossScoreItems.push({
                            likelihood: L,
                            impact: I,
                        });
                    });
                });
                nL.forEach((L) => {
                    nI.forEach((I) => {
                        netScoreItems.push({
                            likelihood: L,
                            impact: I,
                        });
                    });
                });

                return [
                    {
                        fieldType: "registryPicker",
                        fieldName: "registry",
                        placeholder: "Registry",
                        selectedItems: registry ? registry[0] : [],
                    },
                    {
                        fieldType: "dropdown",
                        fieldName: "portfolio",
                        placeholder: "Portfolios",
                        multiselect: true,
                        items: portfolios ? portfolios : [],
                        selectedItems: portfolio ? portfolio.map((P) => P.id) : [],
                        defaultSelection: [],
                        getValues: (items) => {
                            if (items)
                                return items
                                    .filter((I) => I.checked)
                                    .map((I) => {
                                        return { id: I.key };
                                    });
                            else return null;
                        },
                    },
                    {
                        fieldType: "dropdown",
                        fieldName: "subStatus",
                        placeholder: "Status",
                        multiselect: true,
                        items: statusList ? statusList : [],
                        selectedItems: subStatus ? subStatus : [],
                        defaultSelection: [],
                        getValues: (items) => {
                            if (items) return items.filter((I) => I.checked).map((I) => I.key);
                            else return null;
                        },
                    },
                    {
                        fieldType: "dropdown",
                        fieldName: "grossScore",
                        placeholder: "Gross Score",
                        multiselect: true,
                        items: netRiskScoresItems,
                        selectedItems: grossScore ? grossScore : [],
                        defaultSelection: [],
                        getValues: (items) => {
                            if (items) return items.filter((I) => I.checked).map((I) => I.key);
                            else return null;
                        },
                    },
                    {
                        fieldType: "dropdown",
                        fieldName: "grossImpact",
                        placeholder: "Gross Impact",
                        multiselect: true,
                        items: impactDropdownOptions,
                        selectedItems: grossImpact ? grossImpact : [],
                        defaultSelection: [],
                        getValues: (items) => {
                            if (items) return items.filter((I) => I.checked).map((I) => parseInt(I.key));
                            else return null;
                        },
                    },
                    {
                        fieldType: "dropdown",
                        fieldName: "grossLikelihood",
                        placeholder: "Gross Likelihood",
                        multiselect: true,
                        items: likelihoodDropdownOptions,
                        selectedItems: grossLikelihood ? grossLikelihood : [],
                        defaultSelection: [],
                        getValues: (items) => {
                            if (items) return items.filter((I) => I.checked).map((I) => parseInt(I.key));
                            else return null;
                        },
                    },
                    {
                        fieldType: "group",
                        fieldName: "grossImpactLikelihood",
                        placeholder: "Gross Impact/Likelihood",
                        fields: ["grossImpact", "grossLikelihood", "grossLikelihoodImpact"],
                    },
                    {
                        fieldType: "riskScorePicker",
                        fieldName: "grossLikelihoodImpact",
                        placeholder: "Selected Gross Score ",
                        items: grossScoreItems ? grossScoreItems : [],
                        readOnly: true,
                    },
                    {
                        fieldType: "group",
                        fieldName: "netImpactLikelihood",
                        placeholder: "Net Impact/Likelihood",
                        fields: ["netImpact", "netLikelihood", "netLikelihoodImpact"],
                    },
                    {
                        fieldType: "riskScorePicker",
                        fieldName: "netLikelihoodImpact",
                        placeholder: "Selected Net Score ",
                        items: netScoreItems ? netScoreItems : [],
                        readOnly: true,
                    },
                    {
                        fieldType: "dropdown",
                        fieldName: "netImpact",
                        placeholder: "Net Impact",
                        multiselect: true,
                        items: impactDropdownOptions,
                        selectedItems: netImpact ? netImpact : [],
                        defaultSelection: [],
                        getValues: (items) => {
                            if (items) return items.filter((I) => I.checked).map((I) => parseInt(I.key));
                            else return null;
                        },
                    },
                    {
                        fieldType: "dropdown",
                        fieldName: "netLikelihood",
                        placeholder: "Net Likelihood",
                        multiselect: true,
                        items: likelihoodDropdownOptions,
                        selectedItems: netLikelihood ? netLikelihood : [],
                        defaultSelection: [],
                        getValues: (items) => {
                            if (items) return items.filter((I) => I.checked).map((I) => parseInt(I.key));
                            else return null;
                        },
                    },
                    {
                        fieldType: "dropdown",
                        fieldName: "subStatus",
                        placeholder: "Status",
                        multiselect: true,
                        items: statusList ? statusList : [],
                        selectedItems: subStatus ? subStatus : [],
                        defaultSelection: [],
                        getValues: (items) => {
                            if (items) return items.filter((I) => I.checked).map((I) => I.key);
                            else return null;
                        },
                    },
                    {
                        fieldType: "dropdown",
                        fieldName: "netScore",
                        placeholder: "Net Score",
                        multiselect: true,
                        items: netRiskScoresItems,
                        selectedItems: netScore ? netScore : [],
                        defaultSelection: [],
                        getValues: (items) => {
                            if (items) return items.filter((I) => I.checked).map((I) => I.key);
                            else return null;
                        },
                    },
                    {
                        fieldType: "personPicker",
                        fieldName: "assignedTo",
                        placeholder: "Assigned To",
                        items: assignedToObjects ? assignedToObjects : [],
                        getValues: (items) => {
                            if (items)
                                return items.map((I) => {
                                    return { id: I.id };
                                });
                            else return null;
                        },
                    },
                    {
                        fieldType: "text",
                        fieldName: "keywords",
                        placeholder: "Search by name or alias",
                        value: keywords,
                        getValues: (items) => {
                            if (items) return items.value;
                            else return null;
                        },
                    },
                ];
            }

            render() {
                const { riskStore, routing } = this.props;
                const { loading, error, breadcrumb, keywords, pagination, risks, query } = riskStore.riskPriorityStore;

                return (
                    <AppPage
                        error={error}
                        breadcrumb={breadcrumb}
                        loading={loading}
                        hasSidebar={false}
                        className="risks list has--page-nav"
                    >
                        <RiskSideNav
                            registries={riskStore.registryStore.registries}
                            onLinkClick={(item) => this.props.routing.push(item.url)}
                            onNewRisk={(options) => this._onNewRisk(options)}
                        />

                        <>
                            <RiskCommandBar
                                keywords={keywords}
                                types={riskStore.typeStore.genericTypes}
                                onNewRisk={(options) => this._onNewRisk(options)}
                                filterFields={this.filterFields}
                                minibarFilters={["keywords"]}
                                onExport={() => {
                                    riskStore.riskPriorityStore.exportRisks();
                                }}
                                calloutFilters={[
                                    "registry",
                                    "portfolio",
                                    "subStatus",
                                    "assignedTo",
                                    "grossScore",
                                    "grossImpactLikelihood",
                                    "netScore",
                                    "netImpactLikelihood",
                                ]}
                                onSearchChange={(filters) => {
                                    if (filters) {
                                        const keywordsFilter = filters.find((F) => F.fieldName === "keywords");
                                        const portfoliosFilter = filters.find((F) => F.fieldName === "portfolio");
                                        const subStatusFilter = filters.find((F) => F.fieldName === "subStatus");
                                        const assignedToFilter = filters.find((F) => F.fieldName === "assignedTo");
                                        const grossScoreToFilter = filters.find((F) => F.fieldName === "grossScore");
                                        const grossImpactToFilter = filters.find((F) => F.fieldName === "grossImpact");
                                        const grossLikelihoodToFilter = filters.find(
                                            (F) => F.fieldName === "grossLikelihood"
                                        );
                                        const netScoreToFilter = filters.find((F) => F.fieldName === "netScore");
                                        const netImpactToFilter = filters.find((F) => F.fieldName === "netImpact");
                                        const netLikelihoodToFilter = filters.find(
                                            (F) => F.fieldName === "netLikelihood"
                                        );

                                        const query = {
                                            keywords: keywordsFilter.getValues(keywordsFilter),
                                            portfolio: portfoliosFilter.getValues(portfoliosFilter.items),
                                            subStatus: subStatusFilter.getValues(subStatusFilter.items),
                                            assignedToUser: assignedToFilter.getValues(assignedToFilter.items),
                                            grossScore: grossScoreToFilter.getValues(grossScoreToFilter.items),
                                            netScore: netScoreToFilter.getValues(netScoreToFilter.items),
                                            grossImpact: grossImpactToFilter.getValues(grossImpactToFilter.items),
                                            grossLikelihood: grossLikelihoodToFilter.getValues(
                                                grossLikelihoodToFilter.items
                                            ),
                                            netImpact: netImpactToFilter.getValues(netImpactToFilter.items),
                                            netLikelihood: netLikelihoodToFilter.getValues(netLikelihoodToFilter.items),
                                        };
                                        routing.push(`/risks/priority?query=${JsonQueryString.encode(query)}`);
                                    } else {
                                        routing.push(`/risks/proprity`);
                                    }
                                }}
                                // onSearchChange={(keywords) => {
                                //     if (keywords) {
                                //         routing.push(
                                //             `/risks/priority?k=${encodeURIComponent(keywords)}`
                                //         );
                                //     } else {
                                //         routing.push(`/risks/priority`);
                                //     }
                                // }}
                            />

                            <RiskGridView
                                risks={risks}
                                loading={loading}
                                query={query}
                                onRiskClick={(risk) => routing.push(`/risks/browse/${risk.id}`)}
                            />

                            <ObservablePagination
                                {...pagination}
                                onPageChange={(index) => {
                                    routing.push(
                                        `/risks/priority?p=${index + 1}${
                                            query ? `&query=${JsonQueryString.encode(query)}` : ""
                                        }`
                                    );
                                }}
                            />

                            <RiskNewPanel saving={riskStore.lifecycleStore.saving} />
                        </>
                    </AppPage>
                );
            }

            _onNewRisk() {
                this.props.routing.push("/risks/new");
            }
        }
    )
);
