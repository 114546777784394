import React, { Component, Fragment, useRef } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';

import { TagPicker } from 'office-ui-fabric-react/lib/Pickers';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { IconButton } from 'office-ui-fabric-react/lib/Button';

const containerClassName = css`  
& .ms-BasePicker-text {
    background-color: var(--white);
    min-height: 32px;
}
& .ms-Persona-presence {
    top: 6px;
    right: 0;
}
`;

const labelDivContainerClassName = cx(css`
    display: flex;
    justify-content: space-between;
    align-items: center;

    & button.ms-Button--icon {
        margin-top: -3px;
        min-width: 30px;
    }

    & span.ms-Button-flexContainer {
        justify-content: flex-end;
    }
`);

export class AsyncPicker extends Component {

    constructor(props) {
        super(props)
    }

    getTextFromItem = (item) => {
        const { textField } = this.props;
        return 'item[textField]';
    }

    filterSuggestedTags = async (filterText, tagList) => {
        const { api, idField, textField, preTextField, pickedField, selected } = this.props;
        const results = await api(filterText);
        return results
            .filter(item => !selected.includes(item[idField]))
            .map(item => ({ 
                key: item[idField],
                name: preTextField ? `${item[preTextField]} ${item[textField] ? `- ${item[textField]}` : ``}` : (item[textField] || item[idField]), picked: item[pickedField], data: item 
            }))
    };

    onItemSelected = (item) => {
        const { onItemPicked } = this.props;
        if (onItemPicked) onItemPicked(item)
        return null
    }

    onItemsChange = (items) => {
        const { onItemsChange } = this.props;
        if (onItemsChange) onItemsChange(items)
    }

    render () {
        const { label, required, disabled, className, selected, multiple, loading, placeholder, maxPicks, onClearFilter, showClearFilter } = this.props;

        const pickerSuggestionsProps = {
            suggestionsHeaderText: 'Suggested items',
            mostRecentlyUsedHeaderText: 'Suggested items',
            noResultsFoundText: 'No matching items found',
            loadingText: 'Loading...',
            showRemoveButtons: false,
            suggestionsAvailableAlertText: 'Suggestions available',
            suggestionsContainerAriaLabel: 'Suggested items'
        };

        const items = multiple ? (selected || []) : (selected ? [selected] : []);
        // console.log('selected', selected)
        return (
            <div className={cx(
                    'cygraph-PrincipalPicker-container',
                    className,
                    containerClassName
                )}>
                <div className={labelDivContainerClassName}>
                    <div className="left">
                        { label && <Label required={required} disabled={disabled}>{label}&nbsp;</Label>}
                        { loading && <Spinner size={SpinnerSize.xSmall} /> }
                    </div>
                    <div className="right">
                        {showClearFilter && selected && selected.length > 0 && <IconButton
                            iconProps={{ iconName: 'ClearFilter' }}
                            title="Clear Filter"
                            ariaLabel="Clear Filter"
                            onClick={() => {
                                if (onClearFilter) onClearFilter()
                            }}
                        />}
                    </div>
                </div>
                <TagPicker
                    removeButtonAriaLabel="Remove"
                    onChange={this.onItemsChange}
                    onResolveSuggestions={this.filterSuggestedTags}
                    onItemSelected={this.onItemSelected}
                    getTextFromItem={this.getTextFromItem}
                    pickerSuggestionsProps={pickerSuggestionsProps}
                    placeholder={placeholder}
                    selectedItems={selected ? selected.map(F => {
                        return {
                            key: F.id,
                            name: F.name,
                            data: F,
                        }
                    }) : []}
                />
            </div>
        );
    }
};