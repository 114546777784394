import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { Dialog, DialogType } from "office-ui-fabric-react/lib/Dialog";
import { ActionButton } from "office-ui-fabric-react/lib/Button";
import { MessageBar } from "office-ui-fabric-react/lib/MessageBar";
import { TextField } from "office-ui-fabric-react/lib/TextField";

import { ActionPanel } from "@modules/base/components/ActionPanel";

import { WorkflowContextLink } from "@modules/workflows/components/core/WorkflowContextLink";
import { TaskDefinitionHeader } from "@modules/workflows/components/tasks/TaskDefinitionHeader";
import { TaskDefinitionSummary } from "@modules/workflows/components/tasks/TaskDefinitionSummary";
import { TaskResponseActions } from "@modules/workflows/components/tasks/TaskResponseActions";

export const TaskResponseEditDialog = observer(({ onEdit }) => {
    const { taskStore } = useStores();
    const { responseFormStore, lifecycleStore } = taskStore;
    const { saving } = lifecycleStore;
    const { isValid, formData, formOptions } = responseFormStore;

    const onDismiss = useCallback(() => {
        responseFormStore.resolve(false);
    }, [responseFormStore]);

    const onSave = useCallback(() => {
        responseFormStore.resolve(true);
    }, [responseFormStore]);

    return (
        <Dialog
            hidden={!responseFormStore.visible}
            onDismiss={onDismiss}
            dialogContentProps={{
                type: DialogType.largeHeader,
                styles: { title: { paddingRight: 24 } },
                title: (
                    <TaskDefinitionHeader
                        task={formOptions ? formOptions.task : null}
                        canEdit={formOptions && lifecycleStore.canEdit}
                        onEdit={(task) => {
                            onDismiss();
                            onEdit(task);
                        }}
                    />
                ),
            }}
            modalProps={{ isBlocking: false }}
            maxWidth={1000}
            minWidth={700}
        >
            {formData && formOptions && (
                <>
                    {lifecycleStore.haveResponded && lifecycleStore.isPending && (
                        <MessageBar className="row--xs">Your task is completed, awaiting others.</MessageBar>
                    )}

                    {formOptions.task && <TaskDefinitionSummary definition={formOptions.task} />}

                    {lifecycleStore.canRespond && (
                        <TextField
                            label="Notes"
                            multiline
                            autoAdjustHeight
                            value={formData.notes}
                            disabled={saving}
                            onChange={(ev, val) => {
                                formData.notes = val;
                            }}
                        />
                    )}

                    <div style={{ marginTop: 15 }}>
                        <ActionPanel
                            onRenderLeftMenu={() =>
                                formOptions.task.context && formOptions.task.context.type !== "trigger" ? (
                                    <WorkflowContextLink context={formOptions.task.context} onPreClick={onDismiss}>
                                        {formOptions.task.context.label}
                                    </WorkflowContextLink>
                                ) : null
                            }
                        >
                            <ActionButton style={{ height: 32 }} onClick={onDismiss} text="Close" disabled={saving} />
                            {lifecycleStore.canRespond && (
                                <TaskResponseActions
                                    task={formOptions.task}
                                    onResponse={(response) => {
                                        formData.success = response.success;
                                        formData.response = response.value;
                                        onSave();
                                    }}
                                    onDismiss={onDismiss}
                                    disabled={!isValid && saving}
                                />
                            )}
                        </ActionPanel>
                    </div>
                </>
            )}
        </Dialog>
    );
});
