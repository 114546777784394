import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion'
import { Text } from 'office-ui-fabric-react/lib/Text';
import { ObservableBadge } from '../../../base/components/ObservableBadge';

const rootClassName = cx(
  'cygraph-SystemViewHeader-root',
  css` 
    margin: var(--spacing-s) 0;

    .title {
      margin: var(--spacing-xs) 0;
    }

    & .description {
      color: var(--text-color-light);
      font-size: var(--font-size-xs);
    }
  `
);

export const SystemViewHeader = observer(
    class SystemViewHeader extends Component {
        render () {
            const { system } = this.props;
            return (
              <div className={rootClassName}>
                <ObservableBadge className="badge">Asset</ObservableBadge>
                <Text className="title" variant="xLarge" block>{system ? system.name : ''}</Text>
              </div>
            );
        }
    }
);