import React, { PureComponent } from 'react';
import { observer, inject } from 'mobx-react';
import { cx, css } from 'emotion';

import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';

import { PagePartPlaceholder } from  '../elements/PagePartPlaceholder';

const rootClassName = cx(
  'cygraph-ResourceTimelinePart-root',
  css`
  `
);

export const ResourceTimelinePart = inject('dataSourceStore', 'routing')(observer(
  class ResourceTimelinePart extends PureComponent {

    get resources() {
      const { className, instance, part, zone, section, registry, dataSourceStore } = this.props;
      const resourcesSet = new Set();
      const titles = {};
      let resources = [];
      if (part.properties && part.properties.dataSeries) {
        part.properties.dataSeries.forEach((DS,i) => {
          const data = instance.selectionStore.data.find(D => D.id === DS.dataSourceId);
          const selectedPartDataSource = DS.dataSourceId ? instance.selectionStore.dataSources.find(D => D.id === DS.dataSourceId) : null;
          const selectedCoreDataSource = dataSourceStore.coreDataSourceDataSource(selectedPartDataSource.properties.mainSource);
          const groupField = selectedCoreDataSource.timelineGrouppingFields.find(F => F.id === DS.groupField);
          if (data) {
            data.data.forEach(D => {
              let rId = null;
              let title = null;
              if (groupField.idField) {
                rId = D[DS.groupField][groupField.idField];
                title = D[DS.groupField][groupField.nameField];
              } else {
                rId = D[DS.groupField];
                title = D[DS.groupField];
              }
              resourcesSet.add(rId);
              titles[rId] = title;
            })
          }          
        });
        resources = Array.from(resourcesSet).map(R => {
          return {
            id: R,
            title: titles[R],
          }
        });
      }
      return resources
    }

    get events() {
      const { className, instance, part, zone, section, registry, dataSourceStore } = this.props;
      const events = [];
      if (part.properties && part.properties.dataSeries) {
        part.properties.dataSeries.forEach((DS,i) => {
          const data = instance.selectionStore.data.find(D => D.id === DS.dataSourceId);
          const selectedPartDataSource = DS.dataSourceId ? instance.selectionStore.dataSources.find(D => D.id === DS.dataSourceId) : null;
          const selectedCoreDataSource = dataSourceStore.coreDataSourceDataSource(selectedPartDataSource.properties.mainSource);
          const groupField = selectedCoreDataSource.timelineGrouppingFields.find(F => F.id === DS.groupField);
          if (data) {
            data.data
              .forEach(D => {
                const eventTitle = DS.titleField ? D[DS.titleField]: DS.label;
  
                const resource = this.resources.find(R => R.id === (groupField.idField ? D[DS.groupField][groupField.idField] :D[DS.groupField]) );
                let colour = DS.eventsColor || '#3788d8'
                events.push(
                  {
                    title: eventTitle,
                    start: D[DS.startDateField],
                    end: D[DS.endDateField],
                    resourceId: resource ? resource.id : '',
                    backgroundColor: colour,
                    borderColor: colour,
                    id: D.id,
                    // url: selectedCoreDataSource.getCalendarClick ? '#' : null,
                  }
                )
              })
            }
        })
      }
      return events
    }

    render () {
      const { className, instance, part, zone, section, registry, dataSourceStore } = this.props;
      const mode = instance.lifecycleStore.mode;
      
      // const resources = [];
      // const events = [];

      // if (part.properties.dataSeries && part.properties.dataSeries.length > 0) {
      //   part.properties.dataSeries.forEach((DS,i) => {
      //     const data = instance.selectionStore.data.find(D => D.id === DS.dataSourceId);
      //     const selectedPartDataSource = DS.dataSourceId ? instance.selectionStore.dataSources.find(D => D.id === DS.dataSourceId) : null;
      //     const selectedCoreDataSource = dataSourceStore.coreDataSourceDataSource(selectedPartDataSource.properties.mainSource);
      //     if (data) {
      //       data.data
      //         .forEach(D => {
      //           const eventTitle = DS.titleField ? D[DS.titleField]: DS.label;
      //           let colour = 'var(--green-9)';
      //           if(D.priority === 'Medium') {
      //               colour = 'var(--blue-9)';
      //           } else if(D.priority === 'High') {
      //               colour = 'var(--red-9)';
      //           }
      //           events.push(
      //             {
      //               title: eventTitle,
      //               start: D[DS.startDateField],
      //               end: D[DS.endDateField],
      //               resourceId: `resourceId`,
      //               backgroundColor: colour,
      //               borderColor: colour,
      //               id: D.id,
      //               // url: selectedCoreDataSource.getCalendarClick ? '#' : null,
      //             }
      //           )
      //         })
      //       }          
      //   })
      // }

      return (
        <div className={cx(rootClassName, className, mode)}>
          <PagePartPlaceholder {...this.props} isConfigured={part.properties}>
            <FullCalendar
              schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
              plugins={[ resourceTimelinePlugin ]}
              initialView="resourceTimelineMonth"
              weekends={false}
              expandRows={true}
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth'
              }}
              resources={this.resources}
              events={this.events}
            />
          </PagePartPlaceholder>
        </div>
      );
    }
  }
));