import React from "react";
import { observer } from "mobx-react";

import { TextField } from "@ui/elements/TextField";
import { Stack } from "@ui/elements/Stack";
import { PersonaSize } from "@ui/elements/Persona";
import { IconButton } from "@ui/elements/Button";

import { FieldSetView } from "@modules/base/components/FieldSetView";
import { BigTextField } from "@modules/base/components/BigTextField";
import { PrincipalPicker } from "@modules/identity/containers/PrincipalPicker";
import { PhotoPersona } from "@modules/identity/containers/utilities/PhotoPersona";

export const IdentityGroupEditForm = observer(({ formData, formOptions }) => {
    return (
        <>
            <r-grid columns="1">
                <r-cell>
                    <BigTextField
                        label="Name"
                        required
                        placeholder="Please enter a group name"
                        value={formData.name}
                        transparent
                        onChange={(ev, value) => {
                            formData.name = value;
                        }}
                    />
                </r-cell>
                <r-cell>
                    <TextField
                        label="Email"
                        value={formData.email}
                        onChange={(ev, value) => {
                            formData.email = value;
                        }}
                    />
                </r-cell>
            </r-grid>
            <FieldSetView title="Group Members" columns={1}>
                <Stack tokens={{ childrenGap: 15 }}>
                    <PrincipalPicker
                        mode="external"
                        placeholder="Search by name or email"
                        //selected={formData.users}
                        onSelected={(user) => {
                            if (
                                formData.users.findIndex(
                                    (u) => u.externalId === user.externalId && u.provider === user.provider
                                ) === -1
                            ) {
                                formData.users = [user, ...formData.users];
                            }
                        }}
                    />
                    <Stack tokens={{ childrenGap: 15 }}>
                        {formData.users.map((user) => (
                            <div key={`${user.externalId}@${user.provider}`}>
                                <Stack horizontal horizontalAlign="space-between">
                                    <Stack.Item grow>
                                        <PhotoPersona
                                            size={PersonaSize.size28}
                                            principalId={user.id}
                                            text={user.name}
                                            showSecondaryText={true}
                                            secondaryText={user.userPrincipalName}
                                        />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <IconButton
                                            title={`Remove ${user.name}`}
                                            iconProps={{ iconName: "Cancel" }}
                                            onClick={() => {
                                                formData.users = formData.users.filter(
                                                    (u) =>
                                                        !(
                                                            u.externalId === user.externalId &&
                                                            u.provider === user.provider
                                                        )
                                                );
                                            }}
                                        />
                                    </Stack.Item>
                                </Stack>
                            </div>
                        ))}
                    </Stack>
                </Stack>
            </FieldSetView>
        </>
    );
});
