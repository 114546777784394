import { observable, action } from "mobx";

import { RootStore } from "../../base/RootStore";
import { PaginationStore } from "../../base/PaginationStore";
import { RiskService } from "../../../api/risks";

import { StateFlowInstanceStore } from "@modules/stateflows/stores/StateFlowInstanceStore";

import { RiskSelectionStore } from "./RiskSelectionStore";
import { RiskPermissionStore } from "./RiskPermissionStore";
import { RiskLifecycleStore } from "./RiskLifecycleStore";
import { RiskTypeStore } from "./RiskTypeStore";
import { RiskRegistryStore } from "./RiskRegistryStore";
import { RiskRegistryBrowseStore } from "./RiskRegistryBrowseStore";

import { RiskViewStore } from "./RiskViewStore";
import { RiskDashboardStore } from "./RiskDashboardStore";
import { RiskBrowseStore } from "./RiskBrowseStore";
import { RiskHeatmapStore } from "./RiskHeatmapStore";
import { RiskPriorityStore } from "./RiskPriorityStore";

import { ExceptionViewerStore } from "./ExceptionViewerStore";
import { ExceptionDashboardStore } from "./ExceptionDashboardStore";
import { ExceptionBrowseStore } from "./ExceptionBrowseStore";
import { ExceptionCatalogueStore } from "./ExceptionCatalogueStore";
import { ExceptionNewStore } from "./ExceptionNewStore";

import { RiskEditStore } from "./RiskEditStore";
import { RiskAssessmentStore } from "./RiskAssessmentStore";
import { RiskReviewStore } from "./RiskReviewStore";
import { RiskTreatmentStore } from "./RiskTreatmentStore";
import { RiskReviewBoardStore } from "./RiskReviewBoardStore";
import { RiskSummaryStore } from "./RiskSummaryStore";
import { RiskTransferStore } from "./RiskTransferStore";
import { RiskDocumentStore } from "./RiskDocumentStore";
import { RiskAssignFormStore } from "./RiskAssignFormStore";
import { RiskReviewFormStore } from "./RiskReviewFormStore";
import { RiskTransferFormStore } from "./RiskTransferFormStore";
import { RiskEditFormStore } from "./RiskEditFormStore";
import { RiskCatalogueStore } from "./RiskCatalogueStore";

import { RiskTypeViewerPageStore } from "./RiskTypeViewerPageStore";
import { RiskCommentStore } from "./RiskCommentStore";
import { RiskRegistryViewerPageStore } from "./RiskRegistryViewerPageStore";
import { RiskRegistryEditFormStore } from "./RiskRegistryEditFormStore";
import { RiskRegistryNewFormStore } from "./RiskRegistryNewFormStore";
import { RiskTypeEditFormStore } from "./RiskTypeEditFormStore";
import { RiskTypeNewFormStore } from "./RiskTypeNewFormStore";

import { RiskFilterDataStore } from "./RiskFilterDataStore";

export class RiskStore {
    public riskService: RiskService;
    public rootStore: RootStore;

    public selectionStore: RiskSelectionStore;
    public permissionStore: RiskPermissionStore;
    public lifecycleStore: RiskLifecycleStore;
    public typeStore: RiskTypeStore;
    public registryBrowseStore: RiskRegistryBrowseStore;
    public registryStore: RiskRegistryStore;
    public stateFlowStore: StateFlowInstanceStore;

    public viewStore: RiskViewStore;
    public dashboardStore: RiskDashboardStore;
    public browseStore: RiskBrowseStore;
    public riskHeatmapStore: RiskHeatmapStore;
    public riskPriorityStore: RiskPriorityStore;

    public exceptionViewerStore: ExceptionViewerStore;
    public exceptionDashboardStore: ExceptionDashboardStore;
    public exceptionBrowseStore: ExceptionBrowseStore;
    public exceptionCatalogueStore: ExceptionCatalogueStore;
    public exceptionNewStore: ExceptionNewStore;

    public editStore: RiskEditStore;
    public assessmentStore: RiskAssessmentStore;
    public reviewStore: RiskReviewStore;
    public treatmentStore: RiskTreatmentStore;
    public reviewBoardStore: RiskReviewBoardStore;
    public summaryStore: RiskSummaryStore;
    public transferStore: RiskTransferStore;
    public documentStore: RiskDocumentStore;
    public assignFormStore: RiskAssignFormStore;
    public reviewFormStore: RiskReviewFormStore;
    public transferFormStore: RiskTransferFormStore;
    public editFormStore: RiskEditFormStore;
    public riskCatalogueStore: RiskCatalogueStore;

    public typeViewerPageStore: RiskTypeViewerPageStore;
    public riskRegistryViewerPageStore: RiskRegistryViewerPageStore;
    public riskRegistryEditFormStore: RiskRegistryEditFormStore;
    public riskRegistryNewFormStore: RiskRegistryNewFormStore;
    public riskTypeEditFormStore: RiskTypeEditFormStore;
    public riskTypeNewFormStore: RiskTypeNewFormStore;
    public riskFilterDataStore: RiskFilterDataStore;

    public commentStore: RiskCommentStore;

    @observable public selectedRisk: any;
    @observable public selectedLifecycle: any;
    @observable public risks: any[] = [];
    @observable public pagination: PaginationStore;
    @observable public keywords: string;
    @observable public loading: boolean = false;
    @observable public saving: boolean = false;
    @observable public error: any;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.pagination = new PaginationStore();
        this.riskService = new RiskService(rootStore.authProvider);

        this.selectionStore = new RiskSelectionStore(this);
        this.permissionStore = new RiskPermissionStore(this);
        this.lifecycleStore = new RiskLifecycleStore(this);
        this.registryStore = new RiskRegistryStore(this);
        this.registryBrowseStore = new RiskRegistryBrowseStore(this);
        this.typeStore = new RiskTypeStore(this);
        this.typeViewerPageStore = new RiskTypeViewerPageStore(this);
        this.riskRegistryViewerPageStore = new RiskRegistryViewerPageStore(this);
        this.riskRegistryEditFormStore = new RiskRegistryEditFormStore(this);
        this.riskTypeEditFormStore = new RiskTypeEditFormStore(this);
        this.riskTypeNewFormStore = new RiskTypeNewFormStore(this);
        this.riskRegistryNewFormStore = new RiskRegistryNewFormStore(this);
        this.riskFilterDataStore = new RiskFilterDataStore(this);
        this.stateFlowStore = new StateFlowInstanceStore(rootStore.stateFlowStore, {
            properties: {
                target: "RiskLifecycle",
                state: "status",
                subState: "subStatus",
            },
            commands: {
                "zerodai:commands:risks:edit": (trigger, options) => this.lifecycleStore.onEdit(trigger, options),
                "zerodai:commands:risks:assign": (trigger, options) => this.lifecycleStore.onAssign(trigger, options),
                "zerodai:commands:risks:submit": (trigger, options) => this.lifecycleStore.onSubmit(trigger, options),
                "zerodai:commands:risks:monitor": (trigger, options) => this.lifecycleStore.onMonitor(trigger, options),
                "zerodai:commands:risks:review": (trigger, options) => this.lifecycleStore.onReview(trigger, options),
                "zerodai:commands:risks:close": (trigger, options) => this.lifecycleStore.onClose(trigger, options),
                "zerodai:commands:risks:transfer": (trigger, options) =>
                    this.lifecycleStore.onTransfer(trigger, options),
                "zerodai:commands:risks:cancel": (trigger, options) => this.lifecycleStore.onCancel(trigger, options),
                "zerodai:commands:risks:delete": (trigger, options) => this.lifecycleStore.onDelete(trigger, options),
                "zerodai:commands:risks:flow": (trigger, options) => this.lifecycleStore.onFlow(trigger, options),
            },
            conditions: {
                "zerodai:commands:risks:canEdit": () => this.lifecycleStore.canEdit,
                "zerodai:commands:risks:canAssign": () => this.lifecycleStore.canAssign,
                "zerodai:commands:risks:canSubmit": () => this.lifecycleStore.canSubmit,
                "zerodai:commands:risks:canSubmitWithReview": () => this.lifecycleStore.canSubmitWithReview,
                "zerodai:commands:risks:canMonitor": () => this.lifecycleStore.canMonitor,
                "zerodai:commands:risks:canReview": () => this.lifecycleStore.canReview,
                "zerodai:commands:risks:canClose": () => this.lifecycleStore.canClose,
                "zerodai:commands:risks:canTransfer": () => this.lifecycleStore.canTransfer,
                "zerodai:commands:risks:canCancel": () => this.lifecycleStore.canCancel,
                "zerodai:commands:risks:canDelete": () => this.lifecycleStore.canDelete,
                "zerodai:commands:risks:canFlow": () => this.lifecycleStore.canFlow,
                "zerodai:commands:risks:canFlowWithReview": () => this.lifecycleStore.canFlowWithReview,
                "zerodai:commands:risks:isReader": () => this.permissionStore.isReader,
                "zerodai:commands:risks:isContributor": () => this.permissionStore.isContributor,
                "zerodai:commands:risks:isOwner": () => this.permissionStore.isOwner,
                "zerodai:commands:risks:isApprover": () => this.permissionStore.isApprover,
            },
        });

        this.viewStore = new RiskViewStore(this);
        this.dashboardStore = new RiskDashboardStore(this);
        this.browseStore = new RiskBrowseStore(this);
        this.riskHeatmapStore = new RiskHeatmapStore(this);
        this.riskPriorityStore = new RiskPriorityStore(this);

        this.exceptionViewerStore = new ExceptionViewerStore(this);
        this.exceptionDashboardStore = new ExceptionDashboardStore(this);
        this.exceptionBrowseStore = new ExceptionBrowseStore(this);
        this.exceptionCatalogueStore = new ExceptionCatalogueStore(this);
        this.exceptionNewStore = new ExceptionNewStore(this);

        this.editStore = new RiskEditStore(this);
        this.assessmentStore = new RiskAssessmentStore(this);
        this.reviewStore = new RiskReviewStore(this);
        this.treatmentStore = new RiskTreatmentStore(this);
        this.reviewBoardStore = new RiskReviewBoardStore(this);
        this.summaryStore = new RiskSummaryStore(this);
        this.transferStore = new RiskTransferStore(this);
        this.documentStore = new RiskDocumentStore(this);
        this.assignFormStore = new RiskAssignFormStore(this);
        this.reviewFormStore = new RiskReviewFormStore(this);
        this.transferFormStore = new RiskTransferFormStore(this);
        this.editFormStore = new RiskEditFormStore(this);
        this.riskCatalogueStore = new RiskCatalogueStore(this);

        this.commentStore = new RiskCommentStore(this);
    }

    @action
    public setSelected(risk) {
        this.selectedRisk = risk;
    }

    @action
    public loadRisks(query) {
        this.loading = true;
        var options = Object.assign({ page: 1, keywords: null }, query);

        return this.riskService
            .getRisks({
                pageSize: this.pagination.itemsPerPage,
                startIndex: (options.page - 1) * this.pagination.itemsPerPage,
                keywords: options.keywords,
            })
            .then(this.loadRisksOnSuccess, this.loadRisksOnError);
    }

    @action.bound
    public loadRisksOnSuccess(result) {
        this.risks = result.items;
        this.keywords = result.keywords;
        this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
        this.loading = false;
        return result;
    }

    @action.bound
    public loadRisksOnError(error) {
        console.error(error);
        this.loading = false;
        return error;
    }

    @action
    public loadRisk(id) {
        this.loading = true;
        if (!this.selectedRisk || this.selectedRisk.id != id) {
            this.selectedRisk = null;
            this.selectedLifecycle = null;
        }
        return this.riskService.getRisk(id).then((risk) => {
            return this.riskService.getRiskLifecycle(id).then((lifecycle) => {
                return this.loadRiskOnSuccess(risk, lifecycle);
            }, this.loadRiskOnError);
        }, this.loadRiskOnError);
    }

    @action.bound
    public loadRiskOnSuccess(risk, lifecycle) {
        this.selectedRisk = risk;
        this.selectedLifecycle = lifecycle;
        this.loading = false;
        return { risk, lifecycle };
    }

    @action.bound
    public loadRiskOnError(error) {
        console.error(error);
        this.loading = false;
        return error;
    }

    @action
    public createRisk(risk) {
        this.saving = true;
        return this.riskService.createRisk(risk).then(this.createRiskOnSuccess, this.createRiskOnError);
    }

    @action.bound
    public createRiskOnSuccess(result) {
        this.selectedRisk = result;
        this.saving = false;
        return result;
    }

    @action.bound
    public createRiskOnError(error) {
        console.error(error);
        this.saving = false;
        throw error;
    }

    @action
    public updateRisk(risk) {
        this.saving = true;
        return this.riskService.updateRisk(risk).then(this.updateRiskOnSuccess, this.updateRiskOnError);
    }

    @action.bound
    public updateRiskOnSuccess(result) {
        this.selectedRisk = result;
        this.saving = false;
        return result;
    }

    @action.bound
    public updateRiskOnError(error) {
        console.error(error);
        this.saving = false;
        throw error;
    }

    /* LIFECYCLE METHODS */

    @action
    public submitRisk(id) {
        this.saving = true;
        this.error = null;
        return this.riskService.submitRisk(id).then(this.onStatusChangeOnSuccess, this.onStatusChangeOnError);
    }

    @action
    public monitorRisk(id) {
        this.saving = true;
        this.error = null;
        return this.riskService.monitorRisk(id).then(this.onStatusChangeOnSuccess, this.onStatusChangeOnError);
    }

    @action
    public closeRisk(id) {
        this.saving = true;
        this.error = null;
        return this.riskService.closeRisk(id).then(this.onStatusChangeOnSuccess, this.onStatusChangeOnError);
    }

    @action
    public transferRisk(risk) {
        this.saving = true;
        this.error = null;
        return this.riskService.transferRisk(risk).then(this.onStatusChangeOnSuccess, this.onStatusChangeOnError);
    }

    @action.bound
    public onStatusChangeOnSuccess(result) {
        this.selectedLifecycle = result;
        this.saving = false;
        return result;
    }

    @action.bound
    public onStatusChangeOnError(error) {
        this.error = error;
        this.saving = false;
        return error;
    }
}
