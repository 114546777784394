import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion'

import { IconButton } from 'office-ui-fabric-react/lib/Button';

const nodeClassName = cx(
  'cygraph-ModuleTreeNode-root',
  css`
    margin: var(--spacing-xxs) 0;

    &.inner {
      margin-left: 50px;
    }
    .wrapper {
      font-size: var(--font-size-xxs);
      display: flex;
      flex-direction: row;
      border-radius: 5px;
      background-color: var(--body-background);

      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        min-height: 35px;
      }

      & .type {
        width: 130px;
        border-radius: 3px 0 0 3px;

        &.module {
          background-color: var(--green-10);
          color: #333333;
        }
        &.group {
          background-color: var(--green-7);
          color: #333333;
        }
        &.measure {
          background-color: var(--green-2);
          color: #333333;
        }
        &.advisory {
          background-color: var(--orange-2);
          color: #333333;
        }
      }
      & .title {
        padding: 0 var(--spacing-s);
      }
      & .actions {
        margin-left: auto;
      }
    }
  `
);

export const ModuleTreeNode = observer(
    class ModuleTreeNode extends Component {
        render () {
            const { title, type, children, disabled, index, className, menuProps } = this.props;
            
            return (
              <div className={cx(nodeClassName, className)}>
                <div className="wrapper">
                  <div className={cx('type', type.toLowerCase())}>{type}</div>
                  <div className="title">{index >= 0 ? `${index+1}.` : ''} {title} {disabled && '(Not Applicable)'}</div>
                  <div className="actions">
                    { menuProps && menuProps.enabled && (
                      <IconButton iconProps={{ iconName: 'More' }} onClick={(ev) => menuProps.onClick(ev, menuProps)}
                        title="Settings" ariaLabel="Settings" disabled={disabled} />
                    ) }
                  </div>
                </div>
                <div className="children">
                  {children}
                </div>
              </div>
            );
        }
    }
);