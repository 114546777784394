import React, { PureComponent } from "react";
import { observer } from "mobx-react";

import { Text } from "office-ui-fabric-react/lib/Text";

import { RegistryCardView } from "./RegistryCardView";

export const RegistryCardList = observer(
    class RegistryCardList extends PureComponent {
        render() {
            const { registries, loading, className, onRegistryClick } = this.props;

            return (
                <div className={className}>
                    <Text className="row--xs" block variant="medium">
                        <strong>Registries</strong> {loading && <span>loading...</span>}
                    </Text>

                    <r-grid
                        columns-l={this._getLargeScreenColumns()}
                        columns={this._getMediumScreenColumns()}
                        columns-s="1"
                    >
                        {registries.map((registry) => {
                            return (
                                <div className="row--s" key={registry.id}>
                                    <r-cell>
                                        <RegistryCardView
                                            registry={registry}
                                            onRegistryClick={onRegistryClick}
                                        />
                                    </r-cell>
                                </div>
                            );
                        })}
                    </r-grid>
                </div>
            );
        }

        _getMediumScreenColumns() {
            const { vertical } = this.props;

            if (vertical) {
                return 1;
            }

            return 2;
        }

        _getLargeScreenColumns() {
            const { registries, vertical } = this.props;

            if (vertical) {
                return 1;
            }

            if (registries.length % 3 == 0) {
                return 3;
            }

            if (registries.length % 4 == 0) {
                return 4;
            }

            return 3;
        }
    }
);
