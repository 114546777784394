import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";

import { AppPage } from "../../../layout/containers/AppPage";

import { PageInstanceViewer } from "@modules/pages/containers/utilities/PageInstanceViewer";

import { TriageFormViewer } from "../../components/core/TriageFormViewer";
import { TriageHelpPanel } from "../panels/TriageHelpPanel";

export const TriageFormPage = inject(
    "pageContext",
    "triageStore",
    "routing",
    "layoutStore"
)(
    withRouter(
        observer(
            class TriageFormPage extends Component {
                componentWillMount() {
                    const { triageStore } = this.props;
                    triageStore.loadSchema();
                }

                componentWillReceiveProps(nextProps) {
                    const { triageStore } = nextProps;
                    triageStore.loadSchema();
                }

                render() {
                    const { triageStore } = this.props;
                    const { configuration, selectedIndex, loading, saving, selectedTriage } = triageStore;

                    return (
                        <AppPage breadcrumb={triageStore.breadcrumb} loading={loading} hasSidebar={false}>
                            {!loading && (
                                <>
                                    <PageInstanceViewer path="/_/assurance/triage" optional={true} />

                                    <TriageFormViewer
                                        configuration={configuration}
                                        selectedIndex={selectedIndex}
                                        onSelectedIndexChanged={(index) => {
                                            if (configuration.pages.length == index) {
                                                this._onFireEvents("onPreSummary");
                                            }
                                            triageStore.selectedIndex = index;
                                        }}
                                        formData={triageStore.formData}
                                        formOptions={triageStore.formOptions}
                                        onFullAssessment={this._onFullAssessment}
                                        canFullAssessment={() => !loading && !saving && !selectedTriage}
                                        onHelp={this._onHelp}
                                    />

                                    <TriageHelpPanel />
                                </>
                            )}
                        </AppPage>
                    );
                }

                _onFireEvents = (event) => {
                    const { configuration, formData } = this.props.triageStore;
                    if (!configuration.events || !configuration.events[event]) {
                        return;
                    }
                    const toFire = configuration.events[event];
                    toFire.forEach((ev) => {
                        if (ev.action === "set") {
                            formData[ev.field] = this._getComputed(formData, ev, configuration);
                        }
                    });
                };

                _onFullAssessment = (triage) => {
                    const { triageStore, routing, layoutStore } = this.props;
                    triageStore.registerTriage(triage).then((updated) => {
                        layoutStore.displayToastNotification(
                            <>Assessment {updated.assessment.code} has been created. Please complete self assessment.</>
                        );
                        routing.push(`/assurance/browse/${updated.assessment.id}`);
                    });
                };

                _onHelp = (props, field, op) => {
                    const { helpStore } = this.props.triageStore;
                    helpStore.show({ request: props, field });
                };

                _getComputed = ($, ev, configuration) => {
                    if (!ev.computed) {
                        return null;
                    }

                    return eval(ev.computed);
                };
            }
        )
    )
);
