import React, { Component } from 'react';
import { cx , css} from 'emotion';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button'; 
import { FocusZone } from 'office-ui-fabric-react/lib/FocusZone';
import { Callout } from 'office-ui-fabric-react/lib/Callout';

const wrapperClassName = cx(
        css`
            & button {
                margin-left: 5px;
            }
        `
    );

const calloutClassName = cx(
    'cygraph-PopConfirm-callout',
    'test-filter-bar-callout',
        css`
        padding: var(--spacing-xs);
        max-width: 350px;
        & .content {
            margin-bottom: var(--spacing-xxs);
        }

        & button {
            font-size: var(--font-size-xxs);
            font-weight: normal;
        }
        `
    );
    
const clearAllButton = cx(css`
    margin-top: 10px;
    width: 100%;
    font-size: var(--font-size) !important;
    height: 36px !important;
`);
export class BarContainer extends Component {

    constructor(props) {
        super(props)
        this.state = { visible: false, comment: null };
    }

    _toggleVisibility() {
        this.setState({ visible: !this.state.visible });
    }

    render() {
        const { filtersNumber, children, disabled, onCancel, onClearAll } = this.props;
        return <>
                <div 
                    className={wrapperClassName} 
                    role="button" 
                    disabled={false} 
                    onClick={() => this._toggleVisibility()} 
                    ref={trigger => (this._trigger = trigger)}
                >
                    <DefaultButton 
                        data-test={`FilterBarFilterButton`}
                        iconProps={{ iconName: filtersNumber ? 'FilterSolid' : 'Filter' }}
                        onClick={() => this._toggleVisibility()}
                        text={filtersNumber ? `Filter / ${filtersNumber}` : `Filters`}
                    />
                </div>
                {this.state.visible && !disabled && (
                    <div>
                        <Callout
                            role="alertdialog"
                            gapSpace={0}
                            hidden={disabled}
                            className={calloutClassName}
                            target={this._trigger}
                            onDismiss={() => {
                                this._toggleVisibility();
                                if(onCancel) {
                                    onCancel();
                                }
                            }}
                            setInitialFocus={true}>
                            <FocusZone>
                                {children}
                                {filtersNumber > 0 ? <DefaultButton data-test={`FilterBarClearAllButton`} className={clearAllButton} text="Clear all filters" onClick={onClearAll} /> : null}
                            </FocusZone>
                        </Callout>
                    </div>
                )}
        </>  
    }
}