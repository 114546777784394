import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import { BoxPanel } from '../../../../base/components/BoxPanel';

export const AssessmentHistoryCommandBar = observer(
    class AssessmentHistoryCommandBar extends Component {
        
        render () {
            const { selectedState, keywords, assessmentsStates, onSearchChange, onChangeStatus } = this.props;
            const selectedAssessmentState = assessmentsStates.filter(AS => AS.key === selectedState)

            const items = [
                // {
                //     key: 'status',
                //     text: `Status: ${selectedAssessmentState[0]?selectedAssessmentState[0].text:'All'}`,
                //     // cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
                //     // iconProps: { iconName: 'Add' },
                //     subMenuProps: {
                //         items: assessmentsStates.map(A => {
                //             return {
                //                 key: A.key,
                //                 text: A.text,
                //                 onClick: () => onChangeStatus(A.key),
                //             }
                //         })
                //     },
                // }
            ];

            const farItems = [
                {
                    key: 'search',
                    text: 'Search',
                    onRender: () => {
                        return (
                            <SearchBox
                                value={keywords}
                                placeholder="Search by title"
                                styles={{ root: { height: 'auto', minWidth: '270px' }}}
                                onSearch={onSearchChange}
                                onClear={e=>{onSearchChange("")}}  
                            />
                        );
                    }
                }
            ];

            return (
                <BoxPanel shadow>
                    <CommandBar
                        styles={{ root: { paddingRight: '0px' } }}
                        items={items}
                        farItems={farItems}
                    />
                </BoxPanel>
            );
        }
    }
);