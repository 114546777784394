import React from "react";

import { Stack } from "office-ui-fabric-react/lib/Stack";

export const ActionPanel = ({ children, onRenderLeftMenu }) => {
    return (
        <Stack horizontal horizontalAlign="space-between">
            <Stack horizontal tokens={{ childrenGap: 5 }}>
                {onRenderLeftMenu && onRenderLeftMenu()}
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 5 }}>
                {children}
            </Stack>
        </Stack>
    );
};
