import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { ModuleNames, isModuleEnabled } from "../../../configProvider";
import { isUserInAllRoles } from "@modules/identity/stores/PrincipalContextUtilities";
import { JsonQueryString } from "@modules/utils/JsonQueryString";

import { KeyStatisticItemView } from "@modules/home/components/KeyStatisticItemView";
import { KeyStatisticShimmer } from "@modules/home/components/KeyStatisticShimmer";

export const KeyStatisticListView = observer(({ className }) => {
    const [items, setItems] = useState(null);
    const { principalContext, workItemStore, assessmentStore, riskStore } = useStores();
    const user = principalContext.current;

    const isAgent = isUserInAllRoles(user, ["agent"]);
    const showRisks = isModuleEnabled(ModuleNames.Risks) && isUserInAllRoles(user, ["admin", "agent"]);

    useEffect(() => {
        if (user) {
            Promise.all([
                workItemStore.workItemService
                    .searchWorkItems({
                        pageSize: 1,
                        startIndex: 0,
                        query: {
                            templateId: isAgent
                                ? "7d4646f6-b9e7-4b40-b0b7-aec3b7337dc9"
                                : "c93f91a3-ac51-4f9e-a646-616a6d550f98",
                            isAssessment: false,
                        },
                    })
                    .then((result) => ({
                        title: "My Demand",
                        count: result.totalItems,
                        icon: "ExternalTFVC",
                        color: "var(--green-8)",
                        url: JsonQueryString.build("/demand/browse", result.query),
                    })),
                assessmentStore.assessmentService
                    .searchAssessments({
                        pageSize: 1,
                        startIndex: 0,
                        query: {
                            templateId: isAgent
                                ? "ffceb651-5ae1-4502-bc4d-9d6818bb6fc1"
                                : "a697cb6d-9d22-41ef-bd1a-c1d90dfe9dae",
                        },
                    })
                    .then((result) => ({
                        title: "My Assessments",
                        count: result.totalItems,
                        icon: "WorkItem",
                        color: "var(--purple-8)",
                        url: JsonQueryString.build("/assurance/browse", result.query),
                    })),
                showRisks
                    ? riskStore.riskService
                          .searchRisks({
                              pageSize: 1,
                              startIndex: 0,
                              query: {
                                  type: { template: ["zerodai:risks:generic"] },
                                  templateId: isAgent
                                      ? "6b37793c-3fca-420c-aec7-0948691af20a"
                                      : "f4f60d5a-1ca6-4db0-b12f-510c290f32e8",
                              },
                          })
                          .then((result) => ({
                              title: "My Risks",
                              count: result.totalItems,
                              icon: "ReportHacked",
                              color: "var(--red-8)",
                              url: JsonQueryString.build("/risks/browse", result.query),
                          }))
                    : Promise.resolve({ title: "My Risks", count: 0, enabled: false }),
                riskStore.riskService
                    .searchRisks({
                        pageSize: 1,
                        startIndex: 0,
                        query: {
                            type: { template: ["zerodai:risks:exception"] },
                            templateId: isAgent
                                ? "6b37793c-3fca-420c-aec7-0948691af20a"
                                : "f4f60d5a-1ca6-4db0-b12f-510c290f32e8",
                        },
                    })
                    .then((result) => ({
                        title: "My Exceptions",
                        count: result.totalItems,
                        icon: "Shield",
                        color: "var(--orange-8)",
                        url: JsonQueryString.build("/exceptions/browse", result.query),
                    })),
            ]).then((results) => {
                setItems(results.filter((r) => r.enabled !== false));
            });
        }
    }, [user]);

    if (!user || !items) {
        return (
            <div className={className}>
                <r-grid columns="9" columns-s="3">
                    {Array.from({ length: 3 }).map((item, i) => (
                        <r-cell span="3" key={i}>
                            <KeyStatisticShimmer />
                        </r-cell>
                    ))}
                </r-grid>
            </div>
        );
    }

    return (
        <div className={className}>
            <r-grid columns={showRisks ? 12 : 9} columns-s="3">
                {items &&
                    items.map((item, i) => (
                        <r-cell span="3" key={i}>
                            <KeyStatisticItemView
                                iconName={item.icon}
                                color={item.color}
                                title={item.title}
                                value={item.count}
                                url={item.url}
                            />
                        </r-cell>
                    ))}
            </r-grid>
        </div>
    );
});
