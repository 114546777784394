import { useEffect, useMemo } from "react";
import { observer } from "mobx-react";

import * as joint from "jointjs";

export const StateFlowTriggerView = observer(({ state, trigger, index, manifest, parent, graph }) => {
    const link = useMemo(() => {
        const el = new joint.shapes.standard.Link({
            attrs: {
                wrapper: {
                    cursor: "default",
                },
            },
        });
        el.router("normal");
        el.connector("smooth");

        el.set("id", trigger.id);
        parent.embed(el);
        el.set("z", 80);

        graph.addCell(el);
        return el;
    }, [graph]);

    useEffect(() => {
        if (trigger) {
            link.set("id", trigger.id);
            //parent.embed(link);

            link.prop("data", {
                id: trigger.id,
                type: "trigger",
                state: state.id,
            });

            link.attr("line", {
                stroke: trigger.variant === "Primary" ? "var(--nav-color-accent)" : "var(--text-color-lighter)",
                strokeDasharray: trigger.variant === "Primary" ? null : "4,6",
            });

            link.labels([
                {
                    attrs: {
                        rect: {
                            fill: "var(--white)",
                            fillOpacity: 0.8,
                        },
                        text: {
                            text: `  ${trigger.name}  `,
                            fontSize: 12,
                            fill: "var(--text-color)",
                        },
                    },
                },
            ]);

            link.source({ id: state.id, magnet: "portBody", port: "out" });
            link.target({ id: trigger.command.to, magnet: "portBody", port: "in" });
        }

        return () => {};
    }, [link, parent, state, trigger]);

    useEffect(() => {
        return () => {
            link.remove();
        };
    }, []);

    return null;
});
