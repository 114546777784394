import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router';

import { AppPage } from '../../../layout/containers/AppPage';
import { ObservablePagination } from '../../../base/components/ObservablePagination';
import { SettingsListNav } from '../../../settings/containers/utilities/SettingsListNav';

import { PageGridView } from '../../components/core/PageGridView';
import { PageCommandBar } from '../../components/core/PageCommandBar';
import { PageContextualMenu } from '../../components/core/PageContextualMenu';
import { PageHeader } from '../../components/core/PageHeader';
import { PageMetadata } from '../../components/core/PageMetadata';

import { PageEditPanel } from '../panels/PageEditPanel';
import { PageDuplicationPanel } from '../panels/PageDuplicationPanel';

const getQueryStringParams = query => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
};

export const PageBrowsePage = inject('pageContext', 'settingsStore', 'routing', 'pageStore')(withRouter(observer(
    class PageBrowsePage extends Component {

        componentWillMount() {
            const { match, routing, pageStore } = this.props;

            const params = getQueryStringParams(routing.location.search);
            pageStore.browseStore.loadPages({ libraryId: match.params.libraryId, parentId: match.params.pageId, page: params["p"] || 1, keywords: params["k"] });
        }

        componentWillReceiveProps(nextProps) {
            const { match, routing, pageStore } = nextProps;

            const params = getQueryStringParams(routing.location.search);
            pageStore.browseStore.loadPages({ libraryId: match.params.libraryId, parentId: match.params.pageId, page: params["p"] || 1, keywords: params["k"] });
        }

        async onEditProperties(page) {
            const { match, routing, pageStore } = this.props;
            const { loading, error, breadcrumb, keywords, pagination, pages, selectedPage, targetElement, parent } = pageStore.browseStore;
            try {                
                pageStore.editFormStore.show({parent});
                pageStore.instanceStore.lifecycleStore.onEdit();
                await pageStore.viewerStore.loadPageContentByPath(page.path);
                pageStore.editFormStore.show({
                    parent, 
                    page: pageStore.viewerStore.selectionStore.page,
                });
            } catch (e) {
                pageStore.editFormStore.show({parent, page});
            }
        }

        async onDuplicatePage(page) {
            const { pageStore } = this.props;
            const { parent } = pageStore.browseStore;
            try {                
                pageStore.duplicateFormStore.show({parent});
                pageStore.instanceStore.lifecycleStore.onEdit();
                await pageStore.viewerStore.loadPageContentByPath(page.path);
                const pageObject = pageStore.viewerStore.selectionStore.page;
                pageObject.originalTitle = pageObject.title;
                pageObject.title = pageObject.title + ' Copy';
                pageObject.path = pageObject.path + '-copy';
                pageStore.duplicateFormStore.show({
                    parent, 
                    page: pageObject,
                });
            } catch (e) {
                pageStore.duplicateFormStore.show({parent, page});
            }
        }

        render () {
            const { match, routing, pageStore } = this.props;
            const { loading, error, breadcrumb, keywords, pagination, pages, selectedPage, targetElement, parent } = pageStore.browseStore;
            
            return (
                <AppPage 
                    breadcrumb={breadcrumb}
                    hasSidebar={false}
                    className="pages list has--page-nav">
                    <SettingsListNav />
                    <div>
                        { parent && <PageHeader className="row--l" mode={'view'} page={parent} hideActions /> }

                        <PageCommandBar 
                            canAddPage={() => !!parent} 
                            onAddPage={() => pageStore.browseStore.onAddPage() }
                            keywords={keywords}
                            onSearchChange={(keywords) => { 
                                if(keywords) {
                                    routing.push(`/settings/pages/${match.params.libraryId}/${match.params.pageId}?k=${encodeURIComponent(keywords)}`);
                                } else {
                                    routing.push(`/settings/pages/${match.params.libraryId}/${match.params.pageId}`);
                                };
                            }} />

                        <PageGridView
                            pages={pages}
                            loading={loading}
                            onPagePathClick={(item, ev) => routing.push(item.path) }
                            onPageMenuClick={(item, ev) => pageStore.browseStore.onPageSelected(item, ev)}
                            query={{}} />

                        <ObservablePagination 
                            {...pagination}
                            onPageChange={(index) => { 
                                routing.push(`/settings/pages/${match.params.libraryId}/${match.params.pageId}?p=${index + 1}${keywords ? `&k=${encodeURIComponent(keywords)}` : ''}`);
                            }} />

                        <PageContextualMenu 
                            routing={routing}
                            hidden={selectedPage == null || targetElement == null}
                            page={selectedPage}
                            target={targetElement}
                            onDismiss={() => pageStore.browseStore.onPageUnselected()}
                            canEditProperties={() => true}
                            onEditProperties={(page) => this.onEditProperties(page)}
                            canDuplicatePages={() => true}
                            onDuplicatePage={(page) => this.onDuplicatePage(page)}
                        />

                        <PageEditPanel />
                        
                        <PageDuplicationPanel />

                    </div> 
                </AppPage>
            );
        }
    }
)));
