import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";

import { Pivot, PivotItem, PivotLinkFormat } from "office-ui-fabric-react/lib/Pivot";
import { DefaultButton } from "office-ui-fabric-react/lib/Button";

import { AppPage } from "../../../layout/containers/AppPage";
import { ActionBar } from "../../../base/components/ActionBar";
import { PatternHeader } from "../../components/core/PatternHeader";
import { PatternSidebar } from "../../components/core/PatternSidebar";

import { PatternSummary } from "../../components/core/PatternSummary";
import { PatternPublishDialog } from "../../components/core/PatternPublishDialog";
import { ModuleCardListView } from "../../components/modules/ModuleCardListView";
import { PatternOverviewTab } from "../tabs/PatternOverviewTab";
import { PatternDiagramTab } from "../tabs/PatternDiagramTab";
import { PatternModulesTab } from "../tabs/PatternModulesTab";
import { PatternAdvisoriesTab } from "../tabs/PatternAdvisoriesTab";
import { PatternVersionsTab } from "../tabs/PatternVersionsTab";
import { PatternModuleEditPanel } from "../panels/PatternModuleEditPanel";
import { PatternJsonEditorPanel } from "../panels/PatternJsonEditorPanel";

export const PatternViewerPage = inject(
    "pageContext",
    "patternStore",
    "routing"
)(
    withRouter(
        observer(
            class PatternViewerPage extends Component {
                componentWillMount() {
                    const { patternStore, match } = this.props;
                    return patternStore.viewerStore.loadPattern(match.params.id, match.params.version);
                }

                componentWillReceiveProps(nextProps) {
                    const { patternStore, match } = nextProps;
                    return patternStore.viewerStore.loadPattern(match.params.id, match.params.version);
                }

                render() {
                    const { routing, patternStore } = this.props;
                    const { viewerStore, editorStore } = patternStore;
                    const { loading, error, breadcrumb, pattern, manifest, summary } = viewerStore;
                    const { visible, module, isDirty, isValid } = editorStore.moduleEditStore;

                    return (
                        <AppPage error={error} breadcrumb={breadcrumb} loading={loading} hasSidebar={true} className="settings patterns list">
                            {!loading && pattern && manifest && (
                                <div>
                                    <PatternHeader
                                        pattern={pattern}
                                        manifest={manifest}
                                        isEditMode={editorStore.isEditMode}
                                        canEdit={() => !editorStore.isEditMode && !editorStore.saving}
                                        onEdit={() => editorStore.setEditMode(true)}
                                        canPublish={() => !editorStore.saving}
                                        onPublish={() => {
                                            editorStore.onSave({ mode: "Minor" });
                                            editorStore.setEditMode(false);
                                        }}
                                        canSave={() => editorStore.isEditMode && editorStore.isDirty}
                                        onSave={() => {
                                            editorStore.savePattern("Build");
                                            editorStore.setEditMode(false);
                                        }}
                                        canCancel={() => editorStore.isEditMode}
                                        onCancel={() => editorStore.reset()}
                                    />

                                    {summary && pattern && <PatternSummary pattern={pattern} summary={summary} />}

                                    {manifest && (manifest.modules.length || editorStore.isEditMode) && (
                                        <ModuleCardListView
                                            className="row"
                                            modules={manifest.modules}
                                            isEditMode={editorStore.isEditMode}
                                            canAddModule={editorStore.isEditMode && !editorStore.saving}
                                            onAddModule={() => editorStore.onAddModule({})}
                                            canEditModule={editorStore.isEditMode && !editorStore.saving}
                                            onEditModule={(options) => editorStore.onEditModule(options)}
                                            canRemoveModule={editorStore.isEditMode && !editorStore.saving}
                                            onRemoveModule={(options) => editorStore.onRemoveModule(options)}
                                            onModuleClick={(module) => {
                                                if (editorStore.isDirty) {
                                                    patternStore.rootStore.layoutStore.displayToastNotification(
                                                        `Pattern has unsaved changes, please save or discard before moving away from page.`
                                                    );
                                                } else {
                                                    routing.push(`/settings/patterns/${pattern.id}/-/${module.id}`);
                                                }
                                            }}
                                            onRemoveModule={(options) => editorStore.onRemoveModule(options)}
                                        />
                                    )}

                                    {manifest && manifest.modules.length === 0 && !editorStore.isEditMode && (
                                        <ActionBar
                                            className="row animated fast"
                                            title="Empty Pattern"
                                            description="Looks like there are no modules added to the pattern, get started by adding your first module."
                                            variant="info"
                                        >
                                            <DefaultButton
                                                disabled={editorStore.saving}
                                                onClick={() => {
                                                    editorStore.setEditMode(true);
                                                    editorStore.onAddModule({});
                                                }}
                                            >
                                                Add a module
                                            </DefaultButton>
                                        </ActionBar>
                                    )}

                                    <Pivot aria-label="Pattern overview, data flow diagram, modules and advisories" linkFormat={PivotLinkFormat.links}>
                                        <PivotItem headerText={`Overview`}>
                                            <PatternOverviewTab />
                                        </PivotItem>
                                        <PivotItem headerText={`Data Flow`}>
                                            <PatternDiagramTab />
                                        </PivotItem>
                                        <PivotItem headerText={`Questionnaire`}>
                                            <PatternModulesTab />
                                        </PivotItem>
                                        <PivotItem headerText={`Advisories`}>
                                            <PatternAdvisoriesTab />
                                        </PivotItem>
                                        <PivotItem headerText={`Version History`}>
                                            <PatternVersionsTab />
                                        </PivotItem>
                                    </Pivot>

                                    <PatternSidebar pattern={pattern} manifest={manifest} />

                                    <PatternModuleEditPanel
                                        visible={visible}
                                        module={module}
                                        isDirty={isDirty}
                                        isValid={isValid}
                                        onApply={() => editorStore.moduleEditStore.onApply()}
                                        onCancel={() => editorStore.moduleEditStore.onCancel()}
                                    />

                                    <PatternJsonEditorPanel
                                        title={editorStore.jsonEditorStore.title}
                                        visible={editorStore.jsonEditorStore.visible}
                                        value={editorStore.jsonEditorStore.value}
                                        isDirty={editorStore.jsonEditorStore.isDirty}
                                        onApply={() => editorStore.jsonEditorStore.onApply()}
                                        onCancel={() => editorStore.jsonEditorStore.onCancel()}
                                        onChange={(value) => editorStore.jsonEditorStore.onChange(value)}
                                    />

                                    <PatternPublishDialog
                                        visible={editorStore.publishStore.visible}
                                        value={editorStore.publishStore.value}
                                        isDirty={editorStore.publishStore.isDirty}
                                        isValid={editorStore.publishStore.isValid}
                                        onSave={(options) => editorStore.publishStore.onSave(options)}
                                        onCancel={() => editorStore.publishStore.onCancel()}
                                    />
                                </div>
                            )}
                        </AppPage>
                    );
                }
            }
        )
    )
);
