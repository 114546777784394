import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { List } from 'office-ui-fabric-react/lib/List';

import { PageSection } from './PageSection';
import { PageSectionDivider } from './PageSectionDivider';

const rootClassName = cx(
  'cygraph-PageContentViewer-root',
  css` 
    & .page--section-divider {
      display: none;
    }
    &.edit {
      & .page--section-divider {
        display: block;
        & i {
          color: var(--grey-3);
        }
        &:hover {
          & i {
            color: var(--primary-brand-color);
          }
        }
      }
    }
  `
);

export const PageContentViewer = observer(
  class PageContentViewer extends Component {
    render () {
        const { className, page, mode, instance, sections } = this.props;

        return (
          <div className={cx(rootClassName, className, mode)}>
            <DragDropContext 
              onDragEnd={this._onDragEnd}
              onDragStart={this._onDragStart}>
              <Droppable droppableId="page-viewer" type="section" isDropDisabled={mode !== 'edit'}>
                  {(provided, snapshot) => (
                    <div className={cx('droppable', snapshot.isDraggingOver && 'dragging-over', snapshot.draggingFromThisWith && 'dragging-from')} 
                      {...provided.droppableProps} ref={provided.innerRef}>
                        <PageSectionDivider instance={instance} section={null} index={0} />
                        <List items={sections} onRenderCell={(section, index, isScrolling) => {
                            return (
                              <div key={section.id}>
                                <PageSection instance={instance} section={section} index={index} />
                              </div>
                            );
                        }} />
                        {provided.placeholder}
                        { sections.length > 0 && (
                          <PageSectionDivider instance={instance} section={null} index={sections.length} />
                        ) }
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        );
    }

    _onDragStart = (result) => {
    };

    _onDragEnd = (result) => {
      console.log('onDragEnd', result);
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      if(result.reason !== 'DROP') {
        return;
      }

      if(result.type === 'section') {
        this.props.instance.lifecycleStore.onMoveSection({
          sectionId: result.draggableId,
          sourceIndex: result.source.index,
          destinationIndex: result.destination.index,
        });
      } else if(result.type === 'part') {
        const source =  result.source.droppableId.split('@');
        const destination =  result.destination.droppableId.split('@');
        this.props.instance.lifecycleStore.onMovePart({
          partId: result.draggableId,
          sourceSectionId: source[0],
          sourceZoneName: source[1],
          sourceIndex: result.source.index,
          destinationSectionId: destination[0],
          destinationZoneName: destination[1],
          destinationIndex: result.destination.index,
        });
      }
    };
  }
);