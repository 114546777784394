import { observable, action, computed, flow } from "mobx";

import { RootStore } from '../RootStore';

export class FilterBarStore {

    @observable public portfolios: any[] = [];
    @observable public products: any[] = [];
    @observable public filters: any = [
        {
            name: 'keywords',
            value: '',
        },
        {
            name: 'portfolio',
            placeholder: 'Portfolios',
            qsObject: 'obj',
            items: []
        },
        {
            name: 'assessment',
            placeholder: 'Assessments',
            qsObject: 'obj',
            items: [],
        },
        {
            name: 'controls',
            placeholder: 'Controls',
            qsObject: 'obj',
            items: []
        },
        {
            name: 'products',
            placeholder: 'Products',
            qsObject: 'obj',
            items: []
        },
        {
            name: 'age',
            placeholder: 'Age',
            qsObject: 'flat',
            items: [
                {
                    key: 'week',
                    text: 'Older than a week',
                },
                {
                    key: 'month',
                    text: 'Older than a month',
                },
                {
                    key: '3months',
                    text: 'Older than 3 months',
                },
            ]
        },
        {
            name: 'status',
            placeholder: 'States',
            qsObject: 'flat',
            items: [
                {
                    key: 'Open',
                    text: 'Open',
                    checked: true,
                },
                {
                    key: 'Assigned',
                    text: 'Assigned',
                    checked: true,
                },
                {
                    key: 'InProgress',
                    text: 'In Progress',
                    checked: true,
                },
                {
                    key: 'Blocked',
                    text: 'Blocked',
                    checked: true,
                },
                {
                    key: 'Resolved',
                    text: 'Resolved',
                    checked: true,
                },
                {
                    key: 'Closed',
                    text: 'Closed',
                    checked: false,
                },
                {
                    key: 'Cancelled',
                    text: 'Cancelled',
                    checked: false,
                },
            ]
        },
        {
            name: 'initialComplianceLevel',
            placeholder: 'Initial Compliance',
            qsObject: 'flat',
            items: [
                {
                    key: 'Compliant',
                    text: 'Compliant',
                },
                {
                    key: 'NonCompliant',
                    text: 'Non Compliant',
                },
                {
                    key: 'Pending',
                    text: 'Pending',
                },
            ]
        },
        {
            name: 'finalComplianceLevel',
            placeholder: 'Final Compliance',
            qsObject: 'flat',
            items: [
                {
                    key: 'Compliant',
                    text: 'Compliant',
                },
                {
                    key: 'NonCompliant',
                    text: 'Non Compliant',
                },
                {
                    key: 'Pending',
                    text: 'Pending',
                },
            ]
        },
        {
            name: 'advisoryLevel',
            placeholder: 'Severity',
            qsObject: 'flat',
            items: [
                {
                    key: 'critical',
                    text: 'Critical',
                },
                {
                    key: 'high',
                    text: 'High',
                },
                {
                    key: 'medium',
                    text: 'Medium',
                },
                {
                    key: 'low',
                    text: 'Low',
                },
                {
                    key: 'note',
                    text: 'Note',
                },
            ]
        },
        {
            name: 'assessmentStatus',
            placeholder: 'States',
            qsObject: 'flat',
            items: [
                {
                    key: 'Draft',
                    text: 'Draft',
                    checked: true,
                },
                {
                    key: 'Started',
                    text: 'Started',
                    checked: true,
                },
                {
                    key: 'Remediation',
                    text: 'Remediation',
                    checked: true,
                },
                {
                    key: 'Reviewing',
                    text: 'Reviewing',
                    checked: true,
                },
                {
                    key: 'Closed',
                    text: 'Closed',
                    checked: false,
                },
                {
                    key: 'Cancelled',
                    text: 'Cancelled',
                    checked: false,
                },
            ],
        },
        {
            name: 'assignedTo',
            placeholder: 'Assigned To',
            qsObject: 'obj',
            items: [],
        },
    ];
    @observable public loading: boolean = false;
    @observable public error: any = null;
    @observable public showFilterBar: boolean = false;
    @observable public isExtendPanelOpen: boolean = false;
    public rootStore: RootStore;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @computed public get isFilterChanged() {
        let isFilterChanged = false;
        if (this.filters.keywords !== '') isFilterChanged = true;
        if (this.filters.portfolios && this.filters.portfolios.length !== 0) isFilterChanged = true;
        if (this.filters.portfolios && this.filters.portfolios.length !== 0) isFilterChanged = true;
        if (this.filters.assignedTo && this.filters.assignedTo !== null) isFilterChanged = true;
        if (this.filters.status && this.filters.status.length !== 4) {
            isFilterChanged = true;
        } else {
            // if (JSON.stringify(this.filters.status.sort()) !== JSON.stringify(this.defaultSelectedStatus.sort())) isFilterChanged = true;
        }
        return isFilterChanged
    }

    @computed public get selectedFilters() {
        const selectedFilters = {}
        this.filters.forEach(filter => {
            if (typeof filter.value !== 'undefined') {
                selectedFilters[filter.name] = filter.value
            }
            if (typeof filter.items !== 'undefined') {
                selectedFilters[filter.name] = filter.items.filter(I => I.checked).map(I=> {
                    return I.data || I.key || I.id
                });
            }
        });
        return selectedFilters;
    }

    @computed public get queryString() {
        const qs = {}
        debugger
        this.filters
            .filter(F => F.value || (F.items && F.items.filter(I => I.checked).length > 0))
            .map(F => {
                debugger
                if (F.value) {
                    return {[F.name]: F.value};
                } else {
                    if (F.qsObject === 'flat') {
                        return {[F.name]: F.items.filter(I => I.checked).map(I => I.key)};
                    } else {
                        return {[F.name]: F.items.filter(I => I.checked).map(I => { return {id: I.key} })};
                    }
                }
            })
            .forEach(element => {
                const k = Object.keys(element);
                qs[k[0]] = element[k[0]]                
            });
        return JSON.stringify(qs);
    }

    searchAssessments = flow(function*(keywords) {
        return this.rootStore.assessmentStore.browseStore.searchAssessments({keywords}, {}, true)
    })

    searchControls = flow(function*(keywords) {
        return this.rootStore.controlStore.selectionStore.searchControls({keywords}, true)
    })

    loadPortfolios = flow(function*(force: boolean = false, cb: any = null) {
        this.error = null;
        try {
            if ((this.portfolios.length === 0) || force) {
                this.portfolios = yield this.rootStore.portfolioStore.loadPortfolios('', 1, 250);
                const portfolioFilter = this.filters.find(F => F.name === 'portfolio');
                portfolioFilter.items = this.portfolios.map(P => {
                    return {
                        key: P.id,
                        text: P.name,
                        checked: false,
                    }
                });
            }
            if ((this.products.length === 0) || force) {
                this.products = yield this.rootStore.productStore.loadProducts('', 1, 250, true);
                const productFilter = this.filters.find(F => F.name === 'products');
                productFilter.items = this.products.map(P => {
                    return {
                        key: P.id,
                        text: P.name,
                        checked: false,
                    }
                });
            }
            if (cb) cb();
        } catch (e) {
            console.error(e);
            this.error = e;
        }
    })

    loadPrincipals = flow(function*(ids: any[], cb: any = null) {
        this.error = null;
        try {
            if (ids.length > 0) {
                const principals = yield this.rootStore.pickerStore.loadPrincipals(ids.map(P => P.id));
                if (cb) cb(principals);
            }
        } catch (e) {
            console.error(e);
            this.error = e;
            
        }
    })

    @action public onDropDownChange(value, field) {
        if (value.selected) {
            this.filters[field].values.push(value);
        } else {
            this.filters[field].values.splice(this.filters[field].findIndex(G => G.key === value.key),1);
        }
    }

    @action public onRadioChange(value, field) {
        if (value.selected) {
            this.filters[field].values[0] = value;
        } else {
            this.filters[field].values = [];
        }
    }

    @action public displayCallout(element) {
        this.filters[element].showMe = true;
    }

}
