import React, { useRef, useEffect } from "react";
import { observer } from "mobx-react";
import * as moment from "moment";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

import { MessageBar } from "@ui/elements/MessageBar";

import { BoxPanel } from "@modules/base/components/BoxPanel";

export const AuditActivityCalendarView = observer((props) => {
    const { className, loading, date, events, view, onEventClick, onDatesSet } = props;
    const calendarRef = useRef();
    useEffect(() => {
        if (calendarRef.current) {
            const newView = `dayGrid${view || "Month"}`;
            const api = calendarRef.current.getApi();
            if (!moment(api.getDate()).isSame(date, "day")) {
                api.gotoDate(date);
                api.changeView(newView);
            }
        }
    }, [date, view]);
    return (
        <BoxPanel className={className} shadow padding>
            <FullCalendar
                ref={calendarRef}
                plugins={[dayGridPlugin, interactionPlugin]}
                weekends={false}
                locale="en-gb"
                headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridDay,dayGridWeek,dayGridMonth",
                }}
                eventClick={onEventClick}
                dateClick={(info) => {
                    const calendarApi = info.view.calendar;
                    calendarApi.changeView("dayGridDay", info.date);
                }}
                datesSet={onDatesSet}
                events={events}
            />
            {!loading && !events.length && <MessageBar>There are no matching events.</MessageBar>}
        </BoxPanel>
    );
});
