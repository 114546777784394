import React from "react";
import { observer } from "mobx-react";

import { PivotRouting, PivotRoutingItem } from "@modules/base/components/PivotRouting";

export const AssetRoutingPivot = observer(({ asset, selectedTab }) => {
    const defaultTab = "summary";
    const activeTabs = [defaultTab, "documents", "work-items", "assessments", "compliance"];
    return (
        <PivotRouting
            data-test="assetRoutingPivot"
            aria-label="Asset properties, related documents and work"
            selectedKey={activeTabs.indexOf(selectedTab) === -1 ? defaultTab : selectedTab}
            onLinkClick={(item, routing) => {
                if (item.props.itemKey === defaultTab) {
                    routing.push(`/assets/browse/${asset.id}`);
                } else {
                    routing.push(`/assets/browse/${asset.id}/${item.props.itemKey}`);
                }
            }}
        >
            <PivotRoutingItem itemKey="summary" headerText="Summary" itemIcon="Info" />
            <PivotRoutingItem itemKey="documents" headerText="Related Documents" itemIcon="DocLibrary" />
            <PivotRoutingItem itemKey="work-items" headerText="Related Work" itemIcon="ExternalTFVC" />
            <PivotRoutingItem itemKey="assessments" headerText="Related Assessments" itemIcon="WorkItem" />
            <PivotRoutingItem itemKey="compliance" headerText="Policy & Compliance" itemIcon="Shield" />
        </PivotRouting>
    );
});
