import React, { Component } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { PrimaryButton, CommandBarButton, DefaultButton } from "office-ui-fabric-react/lib/Button";
import { OverflowSet } from "office-ui-fabric-react/lib/OverflowSet";
import { TooltipHost } from "office-ui-fabric-react/lib/Tooltip";

import { PopConfirm } from "./PopConfirm";

const rootClassName = cx(
    "cygraph-ActionSet-root",
    css`
        & .ms-OverflowSet-item {
            margin-right: 5px;
            & button {
                min-height: 30px;
            }
        }
        & .ms-OverflowSet-item {
            & button[disabled],
            & button.disabled {
                border-color: #cccccc;
            }
        }
    `
);

const overflowClassName = cx(
    "cygraph-ActionSet-root",
    css`
        & .overflowMenuItem {
            width: 100%;
            height: 36px;
            line-height: 36px;
            display: block;
            text-align: left;
        }
    `
);

const noOp = () => undefined;

export const ActionSet = observer(
    class ActionSet extends Component {
        render() {
            const { className, items, overflowItems, ariaLabel } = this.props;
            // console.log('ActionSet')
            return (
                <div className={cx(rootClassName, className)}>
                    <OverflowSet
                        ariaLabel={ariaLabel}
                        role="menubar"
                        items={items}
                        overflowItems={overflowItems}
                        onRenderOverflowButton={(overflowItems) =>
                            this._onRenderOverflowButton(overflowItems)
                        }
                        onRenderItem={(item) => this._onRenderItem(item)}
                    />
                </div>
            );
        }

        _onRenderItem(item) {
            if (item.confirmProps) {
                return (
                    <PopConfirm
                        {...item.confirmProps}
                        disabled={item.disabled}
                        commentBox={item.commentBox}
                        onConfirm={item.onClick}
                    >
                        {this._onRenderItemCore(item, noOp)}
                    </PopConfirm>
                );
            }
            return this._onRenderItemCore(item);
        }

        _onRenderItemCore(item, onClick) {
            if (item.onRender) {
                return item.onRender(item, onClick);
            }

            if (item.tooltip) {
                const TButton = item.primary ? PrimaryButton : DefaultButton;
                return (
                    <TooltipHost content={item.tooltip}>
                        <TButton
                            role="menuitem"
                            onClick={onClick || item.onClick}
                            disabled={item.disabled}
                            iconProps={item.iconProps}
                            menuProps={item.subMenuProps}
                            text={item.name}
                            data-test={`ActionSet_${item.name.replace(/[^a-zA-Z0-9]+/g, "_")}`}
                            className={item.className}
                        />
                    </TooltipHost>
                );
            }

            if (item.primary) {
                return (
                    <PrimaryButton
                        role="menuitem"
                        onClick={onClick || item.onClick}
                        disabled={item.disabled}
                        iconProps={item.iconProps}
                        menuProps={item.subMenuProps}
                        text={item.name}
                        data-test={`ActionSet_${item.name.replace(/[^a-zA-Z0-9]+/g, "_")}`}
                        className={item.className}
                    />
                );
            }

            if (item.iconOnly) {
                const buttonStyles = {
                    root: {
                        minWidth: 0,
                        padding: "0 4px",
                        alignSelf: "stretch",
                        height: "32px",
                    },
                };

                return (
                    <TooltipHost content={item.name}>
                        <DefaultButton
                            role="menuitem"
                            onClick={onClick || item.onClick}
                            disabled={item.disabled}
                            iconProps={item.iconProps}
                            menuProps={item.subMenuProps}
                            styles={buttonStyles}
                            data-test={`ActionSet_${item.name.replace(/[^a-zA-Z0-9]+/g, "_")}`}
                            className={item.className}
                        />
                    </TooltipHost>
                );
            }

            return (
                <DefaultButton
                    role="menuitem"
                    onClick={onClick || item.onClick}
                    disabled={item.disabled}
                    iconProps={item.iconProps}
                    menuProps={item.subMenuProps}
                    text={item.name}
                    data-test={`ActionSet_${item.name.replace(/[^a-zA-Z0-9]+/g, "_")}`}
                    className={item.className}
                />
            );
        }

        _onRenderOverflowButton(overflowItems) {
            const buttonStyles = {
                root: {
                    minWidth: 0,
                    padding: "0 4px",
                    alignSelf: "stretch",
                    height: "auto",
                },
            };

            overflowItems.forEach((item) => {
                if (item.confirmProps) {
                    const renderer = item.onRender;
                    item.onRender = (i) => {
                        return (
                            <PopConfirm
                                {...i.confirmProps}
                                disabled={i.disabled}
                                onConfirm={i.onClick}
                                commentBox={i.commentBox}
                                focusTrap
                            >
                                {renderer ? (
                                    renderer(i)
                                ) : (
                                    <CommandBarButton
                                        className="overflowMenuItem"
                                        role="menuitem"
                                        onClick={noOp}
                                        disabled={i.disabled}
                                        iconProps={i.iconProps}
                                        menuProps={i.subMenuProps}
                                        text={i.name}
                                    />
                                )}
                            </PopConfirm>
                        );
                    };
                }
            });

            return (
                <DefaultButton
                    ariaLabel="More items"
                    role="menuitem"
                    styles={buttonStyles}
                    menuIconProps={{ iconName: "More" }}
                    menuProps={{
                        items: overflowItems,
                        calloutProps: { className: overflowClassName },
                    }}
                    data-test={`ActionSet_Item`}
                />
            );
        }
    }
);
