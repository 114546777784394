import moment from 'moment';
import { computeTimelineFilter } from './computeTimelineFilter';

async function getExceptionsGroupByCount(query, groupBy, api) {
    // console.log(query)
    // portfolio
    let portfolioFilter = null;
    if (query.portfolios) {
        portfolioFilter = query.portfolios.map(P => { return {
            id: P
        }})
    }
    // sub-status
    let subStatusFilter = null;
    if (query.subStatus) {
        subStatusFilter = query.subStatus
    }
    // status
    let statusFilter = null;
    if (query.status) {
        statusFilter = query.status
    }
    // assignedTo
    let assignedToUserFilter = null;
    if (query.assignedToUser) {
        const selectedId = query.assignedToUser[0] ? query.assignedToUser[0].id : query.assignedToUser.id;
        assignedToUserFilter = [{id: selectedId}];
    }
    // closed
    let closedFilterObject = null;
    if (query.closed) {
        closedFilterObject = computeTimelineFilter(query.closed[0]?query.closed[0]:query.closed);
    }
    // created
    let createdFilterObject = null;
    if (query.created) {
        createdFilterObject = computeTimelineFilter(query.created[0]?query.created[0]:query.created);
    }
    // expiry
    let expiryFilterObject = null;
    if (query.expiry) {
        expiryFilterObject = computeTimelineFilter(query.expiry[0]?query.expiry[0]:query.expiry);
    }
    // netScore
    let netScoreFilterObj = null;
    if (query.netScore) {
        netScoreFilterObj = query.netScore
    }
    // netImpact
    let netImpactFilterObj = null;
    if (query.netImpact) {
        netImpactFilterObj = query.netImpact
    }
    // netLikelihood
    let netLikelihoodFilterObj = null;
    if (query.netLikelihood) {
        netLikelihoodFilterObj = query.netLikelihood
    }
    
    // debugger
    const results = await api({
        groupBy: groupBy ? groupBy : "portfolio",
        query: {
            netScore: netScoreFilterObj,
            netImpact: netImpactFilterObj,
            netLikelihood: netLikelihoodFilterObj,
            portfolio: portfolioFilter,
            subStatus: subStatusFilter,
            status: statusFilter,
            assignedToUser: assignedToUserFilter,
            closed: closedFilterObject,
            created: createdFilterObject,
            nextReviewDue: expiryFilterObject,
            type: { template: ["zerodai:risks:exception"] }
        }
    });
    return results
}

export { getExceptionsGroupByCount }