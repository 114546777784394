import React, { useCallback } from "react";
import { observer } from "mobx-react";

import { PanelType } from "office-ui-fabric-react/lib/Panel";

import { ActionButton } from "@ui/elements/Button";

import { ActionPanel } from "@modules/base/components/ActionPanel";
import { ObservablePanel } from "@modules/base/components/ObservablePanel";

import { WorkflowHistoryViewer } from "@modules/workflows/components/core/WorkflowHistoryViewer";

export const WorkflowHistoryViewerPanel = observer(({ store }) => {
    const { history, activities } = store;

    const onDismiss = useCallback(() => {
        store.resolve(false);
    }, [store]);

    return (
        <ObservablePanel
            hidden={!store.visible}
            pageType="Automation History"
            panelType={PanelType.medium}
            onClosePanel={onDismiss}
            onRenderFooter={() => (
                <ActionPanel>
                    <ActionButton onClick={onDismiss} text="Close" />
                </ActionPanel>
            )}
        >
            {history && activities && <WorkflowHistoryViewer history={history} activities={activities} />}
        </ObservablePanel>
    );
});
