import { observable, flow } from "mobx";

import { IdentityService } from "@api/identity";
import { PrincipalStore } from "@modules/identity/stores/PrincipalStore";

export class IdentityLifecycleStore {
    public identityService: IdentityService;
    public parentStore: PrincipalStore;

    @observable public saving: boolean = false;
    @observable public error: any = null;

    constructor(parentStore: PrincipalStore) {
        this.parentStore = parentStore;
        this.identityService = parentStore.identityService;
    }

    public onAddUsers = flow(function* (options) {
        try {
            const store = this.parentStore.userNewFormStore;
            const { success, formData } = yield store.show({
                ...{},
                ...options.args,
            });

            if (success && formData) {
                this.saving = true;

                const result = yield this.identityService.resolvePrincipals(formData.users);

                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `User${formData.users.length === 1 ? "" : "s"} added successfully.`
                );

                this.parentStore.userBrowseStore.reload();

                store.hide();
                return { success, users: result };
            }

            store.hide();
            return { success };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error };
        } finally {
            this.saving = false;
        }
    });

    public onEditUser = flow(function* (options) {
        try {
            const store = this.parentStore.userEditFormStore;
            const { success, formData, formOptions } = yield store.show({
                ...{ user: options.user },
                ...options.args,
            });

            if (success && formData && formOptions) {
                this.saving = true;

                const updated = yield this.identityService.updateUser({
                    ...formData,
                });

                const groupsToAdd = formData.groups.filter((g) => formOptions.initialGroups.indexOf(g) === -1);
                for (let i = 0; i < groupsToAdd.length; i++) {
                    yield this.identityService.addUserToGroup(formData.id, groupsToAdd[i]);
                }

                const groupsToRemove = formOptions.initialGroups.filter((g) => formData.groups.indexOf(g) === -1);
                for (let i = 0; i < groupsToRemove.length; i++) {
                    yield this.identityService.removeUserFromGroup(formData.id, groupsToRemove[i]);
                }

                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `User ${updated.name} updated successfully.`
                );

                this.parentStore.userBrowseStore.replace(updated);

                store.hide();
                return { success, user: updated };
            }

            store.hide();
            return { success };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error };
        } finally {
            this.saving = false;
        }
    });

    public onAddGroup = flow(function* (options) {
        try {
            const store = this.parentStore.groupNewFormStore;
            const { success, formData, formOptions } = yield store.show({
                ...{ group: options.group || {} },
                ...options.args,
            });

            if (success && formData && formOptions) {
                this.saving = true;

                const { users, ...group } = formData;
                const result = yield this.identityService.createGroup(group);
                const resolved = yield this.identityService.resolvePrincipals(users);

                for (let i = 0; i < resolved.length; i++) {
                    yield this.identityService.addUserToGroup(resolved[i].id, result.id);
                }

                this.parentStore.rootStore.layoutStore.displayToastNotification(`${group.name} created successfully.`);

                this.parentStore.groupBrowseStore.reload();

                store.hide();
                return { success, users: result };
            }

            store.hide();
            return { success };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error };
        } finally {
            this.saving = false;
        }
    });

    public onEditGroup = flow(function* (options) {
        try {
            const store = this.parentStore.groupEditFormStore;
            const { success, formData, formOptions } = yield store.show({
                ...{ group: options.group },
                ...options.args,
            });

            if (success && formData && formOptions) {
                this.saving = true;

                const { users, ...group } = formData;
                const updated = yield this.identityService.updateGroup(group);
                const resolved = yield this.identityService.resolvePrincipals(users);

                const usersToAdd = resolved.filter((g) => formOptions.initialUsers.indexOf(g.id) === -1);
                for (let i = 0; i < usersToAdd.length; i++) {
                    yield this.identityService.addUserToGroup(usersToAdd[i].id, formData.id);
                }

                const usersToRemove = formOptions.initialUsers.filter(
                    (g) => resolved.findIndex((x) => x.id === g) === -1
                );
                for (let i = 0; i < usersToRemove.length; i++) {
                    yield this.identityService.removeUserFromGroup(usersToRemove[i], formData.id);
                }

                this.parentStore.rootStore.layoutStore.displayToastNotification(`${group.name} updated successfully.`);

                this.parentStore.groupBrowseStore.replace(updated);

                store.hide();
                return { success, users: updated };
            }

            store.hide();
            return { success };
        } catch (error) {
            console.error(error);
            this.error = error;
            return { success: false, error };
        } finally {
            this.saving = false;
        }
    });
}
