import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router';

import { useStores } from '../../../../hooks/useStores';

import { AppPage } from '../../../layout/containers/AppPage';
import { ObservablePagination } from '../../../base/components/ObservablePagination';
import { SettingsListNav } from '../../../settings/containers/utilities/SettingsListNav';

import { RegistryCommandBar } from '../../components/registries/RegistryCommandBar';
import { RegistryGridView } from '../../components/registries/RegistryGridView';
import { RegistryNewPanel } from '../panels/RegistryNewPanel';

export const RiskRegistriesSettingsPage = withRouter(observer((props) => {
    const { routing, pageContext, riskStore } = useStores();
    const { registryBrowseStore, registryStore, riskRegistryNewFormStore } = riskStore;
    const { breadcrumb, loading, pagination, error, keywords, registries } = registryBrowseStore;
    const { riskRegistriesRoles } = registryStore;
    pageContext.updateQueryStringParams(routing.location.search);

    useEffect(()=>{
        registryBrowseStore.loadRegistries({
            page: pageContext.queryStringParams.p || 1,
            keywords: pageContext.queryStringParams.k
        })
    }, []);

    return (
        <AppPage 
            loading={loading} 
            error={error} 
            breadcrumb={breadcrumb}
            hasSidebar={false}
            className="products list has--page-nav">
            <SettingsListNav />
            <>
                <RegistryNewPanel 
                    onCreateNewRiskRegistry = {() => {
                        registryBrowseStore.loadRegistries({
                            page: pageContext.queryStringParams.p || 1,
                            keywords: pageContext.queryStringParams.k
                        })
                    }}
                />
                <RegistryCommandBar 
                    onNewRiskRegistry={() => {
                        riskRegistryNewFormStore.show()
                    }}
                    keywords={keywords}
                    onSearchChange={(keywords) => {                         
                        routing.push(`/settings/risk-registries${keywords ? `?k=${encodeURIComponent(keywords)}` : ''}`);
                    }} />

                <RegistryGridView 
                    riskRegistriesRoles={riskRegistriesRoles}
                    registries={registries}
                    loading={loading}
                />

                <ObservablePagination 
                    {...pagination}
                    onPageChange={(index) => { 
                        routing.push(`/settings/risk-registries?p=${index + 1}${pageContext.queryStringParams.k ? `&k=${encodeURIComponent(pageContext.queryStringParams.k)}` : ''}`);
                    }} 
                />
            </>
        </AppPage>
    );
}));
