import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router';

import { MeasureIndexList } from '../../../../assessments/components/modules/MeasureIndexList';
import { MeasureEditList } from '../../../../assessments/components/modules/MeasureEditList';
import { ModuleCommandBar } from '../../../../assessments/components/modules/ModuleCommandBar';
import { AssessmentCommentPanel } from '../../../../assessments/components/comments/AssessmentCommentPanel';

import '../../../../assessments/components/modules/AssessmentQuestionnaire.css';

export const AssessmentModulePage = inject('pageContext', 'assessmentStore', 'routing')(withRouter(observer(
    class AssessmentModulePage extends Component {

        constructor(props) {
            super(props);

            this.editListRef = React.createRef();
        }

        componentWillMount() {
            const { assessmentStore, match } = this.props;
            
            assessmentStore.moduleStore.loadAssessmentModule(match.params.id, match.params.moduleId);
            assessmentStore.commentStore.loadAssessmentCommentCount(match.params.id);
        }

        componentWillReceiveProps(nextProps) {
            const { assessmentStore, match } = nextProps;

            assessmentStore.moduleStore.loadAssessmentModule(match.params.id, match.params.moduleId);
            assessmentStore.commentStore.loadAssessmentCommentCount(match.params.id);
        }
    
        render () {
            const { moduleStore, lifecycleStore, commentStore } = this.props.assessmentStore;
            const { loading, saving, error, disabled, assessment, manifest, values, breadcrumb, module, measure, latestUpdate } = moduleStore;

            return (
                <>
                    { !loading && (
                        <>
                            <div className="questions__col">
                                <MeasureEditList 
                                    ref={this.editListRef}
                                    assessment={assessment} 
                                    selectedMeasure={measure}
                                    values={values}
                                    module={module}
                                    disabled={disabled}
                                    isReadOnly={lifecycleStore.isReadOnly}
                                    commentCount={commentStore.counts}
                                    onCommentsTriggered={(measure) => commentStore.setCommentContext(measure)}             
                                    onScrollToMeasure={(measure) => moduleStore.setSelectedMeasure(measure)}
                                    onMeasureChange={(measure, value) => moduleStore.updateAssessmentValue(measure, value)} />

                                <ModuleCommandBar
                                    assessment={assessment} 
                                    selectedMeasure={measure}
                                    saving={saving}
                                    latestUpdate={latestUpdate}
                                    values={values}
                                    module={module} 
                                    manifest={manifest}
                                    onAssessment={() => moduleStore.onAssessment()}
                                    onModule={(module) => moduleStore.onModule(module)}
                                    onDownload={(module) => moduleStore.onDownload(module)}
                                    isReadOnly={lifecycleStore.isReadOnly} />
                            </div>
                            { this.renderCommentPanel() } 
                        </>
                    ) }
                </>
            );
        }

        renderCommentPanel() {
            const { lifecycleStore, commentStore, moduleStore } = this.props.assessmentStore;
            const { comments, loading, context } = commentStore;

            return (
                <AssessmentCommentPanel
                    disabled={lifecycleStore.isReadOnly}
                    hidden={!context}
                    comments={comments}
                    loading={loading}
                    onCommentAdded={(comment) => commentStore.createAssessmentComment(comment)} 
                    context={context}
                    assessment={moduleStore.assessment} 
                    measure={moduleStore.measure}
                    onClosePanel={() => commentStore.setCommentContext(null)} />
            );
        }
    }
)));
