import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { slugify } from "../../../base/slugify";
import { Label } from "office-ui-fabric-react/lib/Label";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { Text } from "office-ui-fabric-react/lib/Text";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { Dropdown } from "office-ui-fabric-react/lib/Dropdown";

import { BigTextField } from "../../../base/components/BigTextField";
import { ObservableSeparator } from "../../../base/components/ObservableSeparator";
import { PrincipalPicker } from "../../../identity/containers/PrincipalPicker";
import { RichDropdown } from "../../../base/richdropdown/RichDropdown";

function onRoleChange(item, roles) {
    if (item.selected) {
        if (!roles) roles = [];
        roles.push(item.key);
    } else {
        roles.splice(roles.indexOf(item.key), 1);
    }
    return roles;
}

function riskRegistriesRolesOptions(accessRole, riskRegistriesRoles) {
    return riskRegistriesRoles
        .filter((role) => role.allowedFor.find((A) => A === accessRole))
        .map((role) => {
            return {
                key: role.key,
                title: role.text,
                description: role.text,
            };
        });
}

function computeCodePreview(prefix, suffix, paddedZeros, numberIndex) {
    var pZeros = "";
    for (let i = 0; i < parseInt(paddedZeros) - numberIndex.length; i++) {
        pZeros += "0";
    }
    return prefix + pZeros + numberIndex + suffix;
}

function assembleCodePreview(prefix, suffix, paddedZeros) {
    if (parseInt(paddedZeros) > 0) {
        return `${prefix}{0:D${paddedZeros}}${suffix}`;
    } else {
        return prefix + "{0}" + suffix;
    }
}

export const RegistryForm = observer((props) => {
    const { formData, riskRegistriesRoles, principalContextRoles, onFieldChanged } = props;
    const [prefix, setPrefix] = useState("");
    const [suffix, setSuffix] = useState("");
    const [paddedZeros, setPaddedZeros] = useState("0");

    useEffect(() => {
        if (formData && formData.codeFormat) {
            setPrefix(formData.codeFormat.substr(0, formData.codeFormat.indexOf("{")));
            setSuffix(formData.codeFormat.substr(formData.codeFormat.indexOf("}") + 1, formData.codeFormat.length));
            let midSection = formData.codeFormat.substr(formData.codeFormat.indexOf("{") + 1);
            midSection = midSection.substr(0, formData.codeFormat.indexOf("}"));
            if (midSection.indexOf(":D") > 0) {
                let numberOfZeros = midSection.split(":D");
                numberOfZeros = numberOfZeros[1].split("}");
                setPaddedZeros(numberOfZeros[0]);
            } else {
                setPaddedZeros("0");
            }
        }
    }, [formData]);

    return (
        <>
            <r-grid columns="1">
                <r-cell>
                    <BigTextField
                        label="Name"
                        placeholder="Please enter registry name"
                        value={formData && formData.name}
                        transparent
                        required
                        onChange={(ev, value) => {
                            formData.name = value;
                            formData.slug = slugify(formData.name);
                        }}
                    />
                </r-cell>
                <r-cell>
                    <r-grid columns="3">
                        <r-cell>
                            <TextField
                                label="Prefix"
                                required
                                value={prefix}
                                onChange={(ev, value) => {
                                    setPrefix(value || "");
                                    formData.codeFormat = assembleCodePreview(value || "", suffix, paddedZeros);
                                }}
                            />
                        </r-cell>
                        <r-cell>
                            <TextField
                                label="Padded Zeros"
                                value={paddedZeros}
                                type="number"
                                onChange={(ev, value) => {
                                    setPaddedZeros(value);
                                    formData.codeFormat = assembleCodePreview(prefix, suffix, value);
                                }}
                            />
                        </r-cell>
                        <r-cell>
                            <TextField
                                label="Suffix"
                                value={suffix}
                                onChange={(ev, value) => {
                                    setSuffix(value || "");
                                    formData.codeFormat = assembleCodePreview(prefix, value || "", paddedZeros);
                                }}
                            />
                        </r-cell>
                    </r-grid>
                </r-cell>
                <r-cell>
                    <Label>Examples:</Label>
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <Text>{computeCodePreview(prefix, suffix, paddedZeros, "5")},</Text>
                        <Text>{computeCodePreview(prefix, suffix, paddedZeros, "23")},</Text>
                        <Text>{computeCodePreview(prefix, suffix, paddedZeros, "1054")}</Text>
                    </Stack>
                </r-cell>
            </r-grid>
            <r-grid columns="1">
                <r-cell>
                    <ObservableSeparator>Guest access</ObservableSeparator>
                </r-cell>
            </r-grid>
            <r-grid columns="2">
                <r-cell span="1">
                    <RichDropdown
                        placeholder="Pick a role"
                        label="Guest access"
                        selectedKey={formData && formData.policies ? formData.policies.guestRequestorAccess : null}
                        // required
                        options={[
                            {
                                key: null,
                                title: "Do Nothing",
                                description: "Do Nothing",
                            },
                            {
                                key: "Risk.Reader",
                                title: "Guests can raise risks​",
                                description: "Guests can raise risks​",
                            },
                            {
                                key: "Risk.Contributor",
                                title: "Guests can raise & contribute to risks​",
                                description: "Guests can raise & contribute to risks​",
                            },
                        ]}
                        onOptionChanged={(item) => {
                            if (!formData.policies) formData.policies = {};
                            formData.policies.guestRequestorAccess = item.key;
                        }}
                    />
                </r-cell>
            </r-grid>
            <r-grid columns="1">
                <r-cell>
                    <ObservableSeparator>Role Based Access</ObservableSeparator>
                </r-cell>
            </r-grid>
            <r-grid columns="2">
                <r-cell span="1">
                    <Dropdown
                        placeholder="Select roles"
                        label="People that can raise a risk"
                        selectedKeys={
                            formData && formData.policies && formData.policies.requestorRoles
                                ? formData.policies.requestorRoles
                                : []
                        }
                        onChange={(event, item) => {
                            if (!formData.policies) formData.policies = {};
                            formData.policies.requestorRoles = onRoleChange(
                                item,
                                JSON.parse(JSON.stringify(formData.policies.requestorRoles || []))
                            );
                        }}
                        multiSelect
                        options={principalContextRoles.map((role) => {
                            return { key: role.key, text: role.text };
                        })}
                    />
                </r-cell>
                <r-cell span="1">
                    <Dropdown
                        placeholder="Select roles"
                        label="People that can read all risks​"
                        selectedKeys={
                            formData && formData.policies && formData.policies.readerRoles
                                ? formData.policies.readerRoles
                                : []
                        }
                        onChange={(event, item) => {
                            if (!formData.policies) formData.policies = {};
                            formData.policies.readerRoles = onRoleChange(
                                item,
                                JSON.parse(JSON.stringify(formData.policies.readerRoles || []))
                            );
                        }}
                        multiSelect
                        options={principalContextRoles.map((role) => {
                            return { key: role.key, text: role.text };
                        })}
                    />
                </r-cell>
                <r-cell span="1">
                    <Dropdown
                        placeholder="Select roles"
                        label="People that can contribute to all risks"
                        selectedKeys={
                            formData && formData.policies && formData.policies.contributorRoles
                                ? formData.policies.contributorRoles
                                : []
                        }
                        onChange={(event, item) => {
                            if (!formData.policies) formData.policies = {};
                            formData.policies.contributorRoles = onRoleChange(
                                item,
                                JSON.parse(JSON.stringify(formData.policies.contributorRoles || []))
                            );
                        }}
                        multiSelect
                        options={principalContextRoles.map((role) => {
                            return { key: role.key, text: role.text };
                        })}
                    />
                </r-cell>
                <r-cell span="1">
                    <Dropdown
                        placeholder="Select roles"
                        label="People that can assign risks"
                        selectedKeys={
                            formData && formData.policies && formData.policies.assignRoles
                                ? formData.policies.assignRoles
                                : []
                        }
                        onChange={(event, item) => {
                            if (!formData.policies) formData.policies = {};
                            formData.policies.assignRoles = onRoleChange(
                                item,
                                JSON.parse(JSON.stringify(formData.policies.assignRoles || []))
                            );
                        }}
                        multiSelect
                        options={principalContextRoles.map((role) => {
                            return { key: role.key, text: role.text };
                        })}
                    />
                </r-cell>
            </r-grid>
            <r-grid columns="1">
                <r-cell>
                    <ObservableSeparator>Registry Approvers</ObservableSeparator>
                </r-cell>
            </r-grid>
            <r-grid columns="2">
                <r-cell span="1">
                    <Label>Primary approver</Label>
                    <PrincipalPicker
                        placeholder={`Search for an approver`}
                        onSelected={(user) => (formData.primaryApprover = user)}
                        selected={formData && formData.primaryApprover}
                    />
                </r-cell>
                <r-cell span="1">
                    <Label>Secondary approver</Label>
                    <PrincipalPicker
                        placeholder={`Search for an approver`}
                        onSelected={(user) => (formData.secondaryApprover = user)}
                        selected={formData && formData.secondaryApprover}
                    />
                </r-cell>
            </r-grid>
            <r-grid columns="1">
                <r-cell>
                    <ObservableSeparator>Policy Based Access</ObservableSeparator>
                </r-cell>
            </r-grid>
            <r-grid columns="2">
                <r-cell span="1">
                    <RichDropdown
                        placeholder="Pick a role"
                        label="Portfolio contacts can"
                        selectedKey={formData && formData.policies ? formData.policies.portfolioContactAccess : null}
                        // required
                        options={riskRegistriesRolesOptions("portfolioContactAccess", riskRegistriesRoles)}
                        onOptionChanged={(item) => {
                            if (!formData.policies) formData.policies = {};
                            formData.policies.portfolioContactAccess = item.key;
                        }}
                    />
                </r-cell>
                <r-cell span="1">
                    <RichDropdown
                        placeholder="Pick a role"
                        label="Project contacts can"
                        selectedKey={formData && formData.policies ? formData.policies.lifecycleContactAccess : null}
                        // required
                        options={riskRegistriesRolesOptions("lifecycleContactAccess", riskRegistriesRoles)}
                        onOptionChanged={(item) => {
                            if (!formData.policies) formData.policies = {};
                            formData.policies.lifecycleContactAccess = item.key;
                        }}
                    />
                </r-cell>
                <r-cell span="1">
                    <RichDropdown
                        placeholder="Pick a role"
                        label="Assigned to user can​"
                        selectedKey={
                            formData && formData.policies ? formData.policies.assignedToUserAccess : "Risk.Owner"
                        }
                        required
                        options={riskRegistriesRolesOptions("assignedToUserAccess", riskRegistriesRoles)}
                        onOptionChanged={(item) => {
                            if (!formData.policies) formData.policies = {};
                            formData.policies.assignedToUserAccess = item.key;
                        }}
                    />
                </r-cell>
                <r-cell span="1">
                    <RichDropdown
                        placeholder="Pick a role"
                        label="Assigned to group can"
                        selectedKey={formData && formData.policies ? formData.policies.assignedToGroupAccess : null}
                        // required
                        options={riskRegistriesRolesOptions("assignedToGroupAccess", riskRegistriesRoles)}
                        onOptionChanged={(item) => {
                            if (!formData.policies) formData.policies = {};
                            formData.policies.assignedToGroupAccess = item.key;
                        }}
                    />
                </r-cell>
            </r-grid>
        </>
    );
});
