import React, { Component } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { Label } from "office-ui-fabric-react/lib/Label";
import { ActionButton } from "office-ui-fabric-react/lib/Button";
import { TooltipHost } from "office-ui-fabric-react/lib/Tooltip";
import { Icon } from "office-ui-fabric-react/lib/Icon";

import { TriageWizard } from "../core/TriageWizard";
import { TriageWizardItem } from "../core/TriageWizardItem";
import { TriageSummaryItem } from "../core/TriageSummaryItem";
import { TriageFormField } from "../core/TriageFormField";

const rootClassName = cx(
    "cygraph-TriageWizardLayout-root",
    css`
        min-height: 150px;

        & .field {
            position: relative;

            & .tooltip {
                position: absolute;
                right: -12px;
                top: 4px;
            }
        }
    `
);

export const TriageWizardLayout = observer(
    class TriageWizardLayout extends Component {
        render() {
            const { configuration, selectedIndex, formData, formOptions, isVisible, onHelp } = this.props;

            return (
                <TriageWizard className={cx(rootClassName, "row--xxl")} selectedIndex={selectedIndex}>
                    {configuration.pages.map((page, pi) => (
                        <TriageWizardItem key={pi} title={page.title} description={page.description}>
                            {page.sections.map((section, si) => (
                                <div key={si} className="row--s">
                                    {section.title && <Label>{section.title}</Label>}
                                    <r-grid columns={section.columns || 1}>
                                        {section.fields.map((field, fi) => {
                                            const visible = isVisible(formData, field, configuration);
                                            return (
                                                visible && (
                                                    <r-cell key={fi} span={field.span || 1} class="row--xxxs">
                                                        <div className="field">
                                                            <TriageFormField
                                                                {...{ field, section, page, ...this.props }}
                                                            />
                                                            {field.helpProps && (
                                                                <div className="actions pd">
                                                                    <ActionButton
                                                                        iconProps={{
                                                                            iconName:
                                                                                field.helpProps.iconName || "InfoSolid",
                                                                        }}
                                                                        onClick={(ev) => onHelp(field.helpProps, field)}
                                                                    >
                                                                        {field.helpProps.label || "See examples"}
                                                                    </ActionButton>
                                                                </div>
                                                            )}
                                                            {field.tooltip && (
                                                                <div className="tooltip">
                                                                    <TooltipHost content={field.tooltip}>
                                                                        <Icon iconName="Info" />
                                                                    </TooltipHost>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </r-cell>
                                                )
                                            );
                                        })}
                                    </r-grid>
                                </div>
                            ))}
                        </TriageWizardItem>
                    ))}
                    <TriageSummaryItem {...{ configuration, formData, formOptions, isVisible }} />
                </TriageWizard>
            );
        }
    }
);
