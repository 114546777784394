import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { MessageBar } from 'office-ui-fabric-react/lib/MessageBar';
import { DetailsListLayoutMode, SelectionMode, ConstrainMode } from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { IconButton } from 'office-ui-fabric-react/lib/Button';

import { BoxPanel } from '@baseComponents/BoxPanel';
import { ListItemReveal } from '@baseComponents/ListItemReveal';
import { RoutingLink } from '@baseComponents/RoutingLink';
import { WrappedContent } from '@baseComponents/WrappedContent';

export const ControlMapAxisGridView = observer(
    class ControlMapAxisGridView extends Component {

        constructor(props) {
            super(props);

            this._columns = [
              {
                key: 'name',
                name: 'Name',
                fieldName: 'name',
                minWidth: 200,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                onRender: (item) => {
                  return (
                    <WrappedContent>
                      <RoutingLink
                        onClick={() => this.props.onAxisClick && this.props.onAxisClick(item)} 
                        onClickEnabled={this.props.onAxisClick}>{item.name}
                      </RoutingLink>
                      <ListItemReveal>
                        { this.props.onAxisRemoveClick && (
                          <IconButton iconProps={{ iconName: 'Delete' }} onClick={(ev) => this.props.onAxisRemoveClick(item, ev)} />
                        )}
                      </ListItemReveal>
                    </WrappedContent>
                    );
                },
                isPadded: true
              },
              {
                key: 'targetValue',
                name: 'Target Value',
                fieldName: 'targetValue',
                minWidth: 80,
                maxWidth: 120,
                isResizable: false,
                data: 'string',
                onRender: (item) => {
                  return (<span>{item.targetValue.toFixed(2)}</span>);
                },
                isPadded: true
              },
              {
                key: 'absentValue',
                name: 'Absent Value',
                fieldName: 'absentValue',
                minWidth: 80,
                maxWidth: 120,
                isResizable: false,
                data: 'string',
                onRender: (item) => {
                  return (<span>{item.absentValue.toFixed(2)}</span>);
                },
                isPadded: true
              },
              {
                key: 'controls',
                name: 'Controls',
                fieldName: 'controls',
                minWidth: 50,
                maxWidth: 70,
                isResizable: false,
                data: 'string',
                onRender: (item) => {
                  return (<span>{item.parts.length}</span>);
                },
                isPadded: true
              }
          ];
        }

        render () {
            const { className, axes, loading, onAxisClick, disableSelection } = this.props;
            
            return (
              <BoxPanel shadow>
                <ShimmeredDetailsList
                  items={axes}
                  compact={false}
                  columns={this._columns}
                  selectionMode={disableSelection ? SelectionMode.none : SelectionMode.none}
                  getKey={this._getKey}
                  setKey="single"
                  layoutMode={DetailsListLayoutMode.justified}
                  constrainMode={ConstrainMode.horizontalConstrained}
                  isHeaderVisible={true}
                  selection={this._selection}
                  selectionPreservedOnEmptyClick={true}
                  onItemInvoked={onAxisClick}
                  enterModalSelectionOnTouch={true}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  checkButtonAriaLabel="Row checkbox"
                  enableShimmer={loading}
                  removeFadingOverlay={true}
                  shimmerLines={15}
                  ariaLabelForShimmer="Page being fetched"
                  ariaLabelForGrid="Axis list"
                />
                
                { ! loading && !axes.length && (
                  <MessageBar>
                    There are no axis specified.
                  </MessageBar>
                )}
              </BoxPanel> 
            );
        }

        _getKey(item, index) {
            return item ? item.id : index;
        }
    }
);