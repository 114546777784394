import React, { Component } from "react";
import { cx, css } from "emotion";

import { Text } from "office-ui-fabric-react/lib/Text";
import { PrimaryButton } from "office-ui-fabric-react/lib/Button";

import { ObservableSeparator } from "../../../base/components/ObservableSeparator";
import { NavigationList } from "../../../base/components/NavigationList";
import { BigSearchBox } from "../../../base/components/BigSearchBox";

const rootClassName = cx(
    "cygraph-ExceptionSideNav-root",
    css`
        & .container {
            padding: 10px 0;
        }
        & .sidebar--inner {
            display: flex;
            flex-direction: column;
        }
        & .navigation {
            flex-grow: 1;
            margin-bottom: 15px;
            overflow: auto;
        }
        & .launch-pad button {
            width: 100%;
            text-align: left;
            height: 40px;
            font-size: 15px;
        }
    `
);

const bigSearchBoxClassName = cx(
    "row--l",
    css`
        margin-bottom: 15px !important;
    `
);

export class ExceptionSideNav extends Component {
    render() {
        const { className, loading, registries, onNewRisk } = this.props;

        const navLinkGroups = [
            {
                name: "Exception Management",
                key: "/exceptions",
                url: "/exceptions/dashboard",
                hideGrouping: true,
                links: [
                    {
                        name: "Exception Dashboard",
                        key: "/exceptions/dashboard",
                        url: "/exceptions/dashboard",
                        iconProps: { iconName: "ViewDashboard" },
                    },
                    {
                        name: "Browse Exceptions",
                        key: "/exceptions/browse",
                        url: "/exceptions/browse",
                        iconProps: { iconName: "ViewList" },
                    },
                ],
            },
        ];

        const registriesGroup = [
            {
                name: "Exception Registries",
                key: "/exceptions/boards",
                hideGrouping: true,
                links: (registries || []).map((registry) => ({
                    name: registry.name,
                    url: `/exceptions/boards/${registry.id}`,
                    iconProps: { iconName: "Taskboard" },
                    key: registry.id,
                    data: registry,
                })),
            },
        ];

        return loading ? null : (
            <div className={cx(rootClassName, className, "sidebar sidebar--left sidebar--nav")}>
                <div className="sidebar--inner scrollbar--root">
                    <div className="container">
                        <Text className="title" variant="xLarge" block>
                            Exception
                            <br />
                            Management
                        </Text>
                    </div>

                    <BigSearchBox
                        className={bigSearchBoxClassName}
                        placeholder="Search for exceptions"
                        url="/exceptions/browse"
                    />

                    <div className="navigation scrollbar--root">
                        <NavigationList
                            onLinkClick={this.props.onLinkClick}
                            ariaLabel="Exception management navigation"
                            groups={navLinkGroups}
                        />

                        {registries && registries.length !== 0 && (
                            <>
                                <div className="container">
                                    <ObservableSeparator>Exception Registries</ObservableSeparator>
                                </div>
                                <NavigationList
                                    onLinkClick={this.props.onLinkClick}
                                    ariaLabel="Exception registries navigation"
                                    groups={registriesGroup}
                                />
                            </>
                        )}
                    </div>

                    {onNewRisk && (
                        <div className="launch-pad">
                            <PrimaryButton text="New" iconProps={{ iconName: "Add" }} onClick={onNewRisk} />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
