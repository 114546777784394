import { observable, computed } from "mobx";

export class System {
    @observable id: string;
    @observable name: string;
    @observable alias: string;
    @observable summary: string;
    @observable detailsUrl: string;
    @observable businessCriticality: string;
    @observable state: string;
    @observable operationalSince: string;
    @observable portfolioId: string;
    @observable primarySupplierId: string;
    @observable patternId: string;
    @observable assessments: [] = null;
    @observable assessmentsFilters: {
        state: string,
        keywords: string,
        page: number,
    };
    @observable advisories: [] = null;
    @observable advisoriesFilters: {
        finalCompliance: string,
        level: string,
        keywords: string,
        page: number,
    };
    @observable advisoriesPage: number;

    constructor(other: Partial<System>) {
        Object.assign(this, other);
        this.advisoriesFilters = {
            finalCompliance: 'All',
            level: 'All',
            keywords: '',
            page: 1,
        }
        this.assessmentsFilters = {
            state: 'All',
            keywords: '',
            page: 1,
        }
    }

    @computed
    public get isNew() {
        return !!this.id;
    }
}
