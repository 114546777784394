import React from 'react';

import { MessageBar } from 'office-ui-fabric-react/lib/MessageBar';
import { DetailsListLayoutMode, SelectionMode, ConstrainMode } from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import Moment from 'react-moment';

import { BoxPanel } from '../../../base/components/BoxPanel';
import { RoutingLink } from '../../../base/components/RoutingLink';
import { WrappedContent } from '../../../base/components/WrappedContent';
import { Stack } from 'office-ui-fabric-react';
import { PhotoPersona } from '../../../identity/containers/utilities/PhotoPersona';
import { RegistryPolicyAccess } from './RegistryPolicyAccess';

export const RegistryGridView = (props) =>  {
  const { registries, loading, riskRegistriesRoles } = props

  const _columns = [
      {
        key: 'name',
        name: 'Name',
        fieldName: 'name',
        minWidth: 200,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        onRender: (item) => {
          return (
            <WrappedContent>
              <RoutingLink url={`/settings/risk-registries/${item.id}`}> {item.name} </RoutingLink>
            </WrappedContent>
            );
        },
        isPadded: true
      },
      {
        key: 'guestAccess:',
        name: 'Guest access',
        fieldName: 'guestAccess',
        minWidth: 250,
        isResizable: false,
        data: 'string',
        onRender: (item) => {
          return (<>
            <RegistryPolicyAccess 
              riskRegistriesRoles={riskRegistriesRoles}
              access={item.policies ? item.policies.guestRequestorAccess : "-"}
            />
            </>);
        },
        isPadded: true
      },
      {
        key: 'primaryApprover',
        name: 'Primary Approver',
        fieldName: 'primaryApprover',
        minWidth: 250,
        isResizable: false,
        data: 'string',
        onRender: (item) => {
          return (<>
            {item.primaryApprover ? <PhotoPersona 
              principalId={item.primaryApprover.id}
              text={item.primaryApprover.name} 
              showSecondaryText={true} 
              secondaryText={item.primaryApprover.jobTitle} 
            /> : `No primary approver defined`}
            </>);
        },
        isPadded: true
      },
      {
        key: 'secondaryApprover',
        name: 'Secondary Approver',
        fieldName: 'secondaryApprover',
        minWidth: 250,
        isResizable: false,
        data: 'string',
        onRender: (item) => {
          return (<>
            {item.secondaryApprover ? <PhotoPersona 
              principalId={item.secondaryApprover.id}
              text={item.secondaryApprover.name} 
              showSecondaryText={true} 
              secondaryText={item.secondaryApprover.jobTitle} 
            /> : `No secondary approver defined`}
            </>);
        },
        isPadded: true
      },
  ];

  const _getKey = (item, index) => {
    return item ? item.id : index;
  }

  return (
    <BoxPanel shadow>
      <ShimmeredDetailsList
        items={registries}
        compact={false}
        columns={_columns}
        getKey={_getKey}
        setKey="single"
        selectionMode={SelectionMode.none}
        layoutMode={DetailsListLayoutMode.justified}
        constrainMode={ConstrainMode.horizontalConstrained}
        isHeaderVisible={true}
        enterModalSelectionOnTouch={true}
        ariaLabelForSelectionColumn="Toggle selection"
        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
        checkButtonAriaLabel="Row checkbox"
        enableShimmer={loading}
        removeFadingOverlay={true}
        shimmerLines={15}
        ariaLabelForShimmer="Page being fetched"
        ariaLabelForGrid="Page list"
      />
      
      { ! loading && !registries.length && (
        <MessageBar>
          There are no matching types.
        </MessageBar>
      )}

      {/* {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />} */}
    </BoxPanel> 
  );
}