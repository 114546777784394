import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';

import { PagePartPlaceholder } from  '../elements/PagePartPlaceholder';

const rootClassName = cx(
  'cygraph-EmbeddedContentPart-root',
  css`

  `
);

export const EmbeddedContentPart = observer(
  class EmbeddedContentPart extends PureComponent {
    render () {
      const { className, instance, part, zone, section, registry } = this.props;
      const mode = instance.lifecycleStore.mode;
      
      return (
        <div className={cx(rootClassName, className, mode)}>
          <PagePartPlaceholder {...this.props} isConfigured={part.properties && part.properties.src}>
            <iframe width="100%" height="100%" 
              src={part.properties.src}
              frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
            </iframe>
          </PagePartPlaceholder>

        </div>
      );
    }
  }
);