import React, { Component } from "react";
import { observer, inject } from "mobx-react";

import { Pivot, PivotItem, PivotLinkFormat } from "office-ui-fabric-react/lib/Pivot";

import { AppPage } from "../../../layout/containers/AppPage";

import { EntitySideNav } from "../../components/core/EntitySideNav";

import { EntityNewPanel } from "../panels/EntityNewPanel";

export const EntityDashboardPage = inject(
    "entityStore",
    "routing"
)(
    observer(
        class EntityDashboardPage extends Component {
            componentWillMount() {
                const { dashboardStore } = this.props.entityStore;
                dashboardStore.loadDashboard({});
            }

            componentWillReceiveProps(nextProps) {
                const { dashboardStore } = nextProps.entityStore;
                dashboardStore.loadDashboard({});
            }

            render() {
                const { lifecycleStore, dashboardStore } = this.props.entityStore;
                const { loading, breadcrumb, error, entities } = dashboardStore;

                return (
                    <AppPage
                        error={error}
                        breadcrumb={breadcrumb}
                        loading={loading}
                        hasSidebar={false}
                        className="entities dashboard has--page-nav"
                    >
                        <EntitySideNav
                            hideAgentActions={!lifecycleStore.showAgentActions}
                            onLinkClick={(item) => this.props.routing.push(item.url)}
                            onNewEntity={() => this._onNewEntity()}
                        />

                        <EntityNewPanel
                            onAfterSave={(entity) =>
                                this.props.routing.push(`/entities/browse/${entity.id}`)
                            }
                        />
                    </AppPage>
                );
            }

            _onNewEntity() {
                const { newStore } = this.props.entityStore;
                newStore.show({});
            }
        }
    )
);
