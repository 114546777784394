import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router';
import { AppPage } from '../../../layout/containers/AppPage';

import { SettingsListNav } from '../../../settings/containers/utilities/SettingsListNav';
import { GroupListCommandBar } from '../../components/groups/GroupListCommandBar';
import { GroupGridView } from '../../components/groups/GroupGridView';
import { EditGroupPanel } from '../panels/EditGroupPanel';

const getQueryStringParams = query => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
  };

export const GroupListPage = inject('pageContext', 'principalContext', 'pickerStore', 'settingsStore', 'routing')(withRouter(observer(
    class GroupListPage extends Component {

        constructor(props) {
            super(props)
            this.onCommandBarSearch = this.onCommandBarSearch.bind(this)
            this.state = {
                keywords: '',
                page: 1,
            };
        }

        componentWillMount() {
            const { match, routing, settingsStore, principalContext, pickerStore } = this.props;
            const { editGroupStore } = principalContext;
            const { groupPickerStore } = pickerStore;
            const params = getQueryStringParams(routing.location.search);
            //settingsStore.selectItem(match.params.selectedItem);
            if (match.params.groupId) {
                editGroupStore.visible = true
                editGroupStore.groupId = match.params.groupId
            }            
            groupPickerStore.searchPrincipals({
                page: params.p || 1, 
                keywords: params.k || '',
            });
            this.setState({
                page: params.p || 1, 
                keywords: params.k || '',
            })
        }

        componentWillReceiveProps(nextProps) {
            const { match, routing, settingsStore, pickerStore } = this.props;
            const { groupPickerStore } = pickerStore;
            const params = getQueryStringParams(routing.location.search);
            if (nextProps.match.params.selectedItem !== match.params.selectedItem) {
                //settingsStore.selectItem(nextProps.match.params.selectedItem);
            }
            const page = params.p || 1;
            const keywords = params.k || '';
            if ((page !== this.state.page) || (keywords !== this.state.keywords)) {
                groupPickerStore.searchPrincipals({
                    page: params.p, 
                    keywords: params.k,
                });
                this.setState({
                    page: params.p, 
                    keywords: params.k,
                })
            }
        }

        onCommandBarSearch(keywords) {
            const { routing } = this.props;
            routing.push(`${routing.location.pathname}?p=1&k=${keywords}`)
        }

        afterSavingGroup = () => {
            const { routing, pickerStore } = this.props;
            const { groupPickerStore } = pickerStore;
            const params = getQueryStringParams(routing.location.search);
            groupPickerStore.searchPrincipals({
                page: this.state.page, 
                keywords: this.state.keywords,
            });
        }

        render () {
            const { page, keywords } = this.state;
            const { routing, principalContext, pickerStore } = this.props;
            const { editGroupStore } = principalContext;
            const { groupPickerStore } = pickerStore;

            return (
                <AppPage 
                    title={"Settings"}
                    onTitleClick={() => {routing.push(`/settings`)}}
                    subTitle="Groups"
                    // subTitle={boardsStore.isLoading || !boardsStore.selectedBoard ? "Loading" : selectedBoard.name}
                    // loading={boardsStore.isLoading || workItemStore.loading || productStore.loading || !boardsStore.selectedBoard}
                    hasSidebar={false} 
                    className="work-items list has--page-nav">
                    <SettingsListNav />
                    <div>
                        <GroupListCommandBar 
                            onSearchChange={this.onCommandBarSearch}
                            keywords={keywords || ''}
                        />
                        {groupPickerStore.principals && <GroupGridView 
                            page={page || 1}
                            keywords={keywords || ''}
                            groups={groupPickerStore.principals}
                            loading={groupPickerStore.loading}
                            pagination={groupPickerStore.pagination}
                            onPageChange={(index, keywords) => {
                                routing.push(`/settings/groups?p=${index + 1}&k=${keywords}`);
                            }}
                            onEditClick={(groupId) => {
                                // routing.push(`/groups/${groupId}?p=${page || 1}&k=${keywords || ''}`);
                                editGroupStore.visible = true;
                                editGroupStore.groupId = groupId;
                            }}
                        />}
                        {editGroupStore.visible && editGroupStore.groupId && (<EditGroupPanel 
                            page={page || 1}
                            keywords={keywords || ''}
                            onSave={this.afterSavingGroup}
                        />)}
                    </div>
                </AppPage>
            );
        }
    }
)));
