import React from "react";
import { observer } from "mobx-react";
import { Route, Switch } from "react-router";

import { PivotItem } from "office-ui-fabric-react/lib/Pivot";

import { PivotRouting } from "@modules/base/components/PivotRouting";

import { StrategicControlSummaryTab } from "./StrategicControlSummaryTab";
import { StrategicControlChildrenTab } from "./StrategicControlChildrenTab";
import { StrategicControlActivitiesTab } from "./StrategicControlActivitiesTab";

export const StrategicControlTabList = observer(({ selectedTab, control, onUpload }) => {
    const defaultTab = "summary";
    const knownTabs = [defaultTab, "children", "milestones", "activities"];

    return (
        <>
            <PivotRouting
                data-test="controlTabs"
                aria-label="Strategic control properties, tactical controls, deployments and milestones."
                selectedKey={knownTabs.indexOf(selectedTab) === -1 ? defaultTab : selectedTab}
                onLinkClick={(item, routing) => {
                    if (item.props.itemKey === defaultTab) {
                        routing.push(`/controls/browse/${control.id}`);
                    } else {
                        routing.push(`/controls/browse/${control.id}/${item.props.itemKey}`);
                    }
                }}
            >
                <PivotItem itemKey={defaultTab} headerText="Summary" />
                <PivotItem itemKey="children" headerText="Tactical Controls" />
                <PivotItem itemKey="milestones" headerText="Deployments &amp; Milestones" />
                <PivotItem itemKey="activities" headerText="Activities &amp; Evidence" />
            </PivotRouting>

            <Switch>
                <Route
                    exact
                    path="/controls/browse/:id/children"
                    component={() => <StrategicControlChildrenTab control={control} />}
                />
                <Route
                    exact
                    path="/controls/browse/:id/milestones"
                    component={() => <StrategicControlActivitiesTab control={control} />}
                />
                <Route
                    exact
                    path="/controls/browse/:id/activities"
                    component={() => <StrategicControlActivitiesTab control={control} />}
                />
                <Route path="/controls/browse/:id" component={() => <StrategicControlSummaryTab control={control} />} />
            </Switch>
        </>
    );
});
