import { computeTimelineFilter } from '../data/computeTimelineFilter';

function generateAssessmentQuery(query) {
    // console.log(JSON.stringify(query))
    // console.log('query.created', query.created);
    let timeLineFilterObject = null;
    let createdFilterObject = null;
    let startedFilterObject = null;
    let dueDateFilterObject = null;
    let closedFilterObject = null;
    if (query.timeLine) {
        timeLineFilterObject = computeTimelineFilter(query.timeLine[0]?query.timeLine[0]:query.timeLine);
    }
    if (query.created) {
        createdFilterObject = computeTimelineFilter(query.created[0]?query.created[0]:query.created);
    }
    if (query.started) {
        startedFilterObject = computeTimelineFilter(query.started[0]?query.started[0]:query.started);
    }
    if (query.dueDate) {
        dueDateFilterObject = computeTimelineFilter(query.dueDate[0]?query.dueDate[0]:query.dueDate);
    }
    if (query.closed) {
        closedFilterObject = computeTimelineFilter(query.closed[0]?query.closed[0]:query.closed);
    }
    // portfolio
    let portfolioFilter = null;
    if (query.portfolios) {
        portfolioFilter = query.portfolios.map(P => { return {
            id: P
        }})
    }
    // assessmentState
    let assessmentStateFilter = null;
    if (query.assessmentState) {
        assessmentStateFilter = query.assessmentState.map(s => s);
    }
    // user
    let userFilter = null;
    if (query.assignedToUser) {
        const selectedId = query.assignedToUser[0] ? query.assignedToUser[0].id : query.assignedToUser.id;
        if (selectedId) userFilter = [{id: selectedId}];
    }
    // sort
    let sortFilter = null;
    if (query.sort) {
        sortFilter = [{
            property: query.sort.property,
            asc: query.sort.asc,
        }]
    }
    // requestedBy
    let requestedByFilter = null;
    if (query.requestedBy) {
        const selectedId = query.requestedBy[0] ? query.requestedBy[0].id : query.requestedBy.id;
        if (selectedId) requestedByFilter = [{id: selectedId}];
    }
    // priority
    let priorityFilter = null;
    if (query.priority) {
        const selectedPriority = query.assignedToUser[0] ? query.assignedToUser[0].id : query.assignedToUser.id;
        if (selectedPriority) priorityFilter = [];
    }
    // console.log(query)

    const sentQuery = {
        targetType: null,
        status: assessmentStateFilter,
        targetId: null,
        portfolio: portfolioFilter,
        assignedTo: null,
        timeline: timeLineFilterObject,
        created: createdFilterObject,
        started: startedFilterObject,
        dueDate: dueDateFilterObject,
        closed: closedFilterObject,
        assignedToUser: userFilter,
        requestedBy: requestedByFilter,
        priority: priorityFilter,
        keywords: null,
        templateId: null,
        sort: null,
    };
    
    return sentQuery;
}

export { generateAssessmentQuery }