import React, { Component } from 'react';
import { observer } from 'mobx-react';

export const SystemViewAssessmentsStatus = observer(
    class SystemViewAssessmentsStatus extends Component {
        render () {
            return (
                <React.Fragment>
                    <div className="title row--l">Assessment Status<span className="title__seperator"></span><span className="sub__title">Up-to-date</span></div>
                    <div className="row--l flex--align--center">
                        <div className="count__container">
                        <div className="message message--success message--large pad--s"><i className="fas fa-check"></i></div>
                        </div>
                        <div>
                        <div className="title row--xxxxs">Next assessment date: February 12th 2020</div>
                        <div className="title--xs">Total assessments: 1</div>
                        </div>
                    </div>
                    <button className="btn btn--secondary btn--block" onClick={()=>{this.props.routing.push(`/systems/${this.props.systemId}/history`)}}>View Assessments</button>
                </React.Fragment>
            );
        }
    }
);