export const runRecentSearch = ({ showRisks, user, workItemStore, assessmentStore, riskStore }) => {
    return Promise.all([
        workItemStore.browseStore.loadWorkItems({
            pageSize: 10,
            query: {
                templateId: "8c3f0bca-c904-4143-8c2d-f660fdae3611",
                isAssessment: false,
                sort: [{ property: "modified", asc: false }],
            },
        }),
        assessmentStore.browseStore.loadAssessments({
            pageSize: 10,
            query: { templateId: "14dddc7b-b563-44ad-8cbd-a1ab51bc8a18", sort: [{ property: "modified", asc: false }] },
        }),
        riskStore.exceptionBrowseStore.loadRisks({
            pageSize: 10,
            query: { templateId: "dc133e53-214d-4141-b278-27c9253cc4a6", sort: [{ property: "modified", asc: false }] },
        }),
        showRisks
            ? riskStore.browseStore.loadRisks({
                  pageSize: 10,
                  query: {
                      templateId: "dc133e53-214d-4141-b278-27c9253cc4a6",
                      sort: [{ property: "modified", asc: false }],
                  },
              })
            : Promise.resolve({ items: [] }),
    ]).then((results) => {
        const items = [
            ...results[0].items.map((n) => ({
                entity: "work-item",
                id: n.id,
                code: n.code,
                title: n.title,
                status: n.subStatus,
                created: n.created,
                modified: n.modified,
                modifiedBy: n.modifiedBy,
                type: `Demand / ${n.product.name}`,
                role:
                    n.assignedToUser && n.assignedToUser.id === user.id
                        ? "Assigned to you"
                        : n.requestedBy.id === user.id || n.createdBy.id === user.id
                        ? "Requested by you"
                        : "You are a stakeholder",
            })),
            ...results[1].items.map((n) => ({
                entity: "assessment",
                id: n.id,
                code: n.code,
                title: n.title,
                status: n.state,
                created: n.created,
                modified: n.modified,
                modifiedBy: n.modifiedBy,
                type: `Assurance / ${n.target.name}`,
                role:
                    n.assignedToUser && n.assignedToUser.id === user.id
                        ? "Assigned to you"
                        : n.createdBy.id === user.id
                        ? "Requested by you"
                        : "You are a stakeholder",
            })),
            ...results[2].items.map((n) => ({
                entity: "exception",
                id: n.id,
                code: n.code,
                title: n.title,
                status: n.lifecycle.subStatus,
                created: n.created,
                modified: n.modified,
                modifiedBy: n.modifiedBy,
                type: `Exception / ${n.type.name}`,
                role:
                    n.lifecycle.assignedToUser && n.lifecycle.assignedToUser.id === user.id
                        ? "Assigned to you"
                        : n.requestedBy.id === user.id || n.createdBy.id === user.id
                        ? "Requested by you"
                        : "You are a stakeholder",
            })),
            ...results[3].items.map((n) => ({
                entity: "risk",
                id: n.id,
                code: n.code,
                title: n.title,
                status: n.lifecycle.subStatus,
                created: n.created,
                modified: n.modified,
                modifiedBy: n.modifiedBy,
                type: `Risk / ${n.type.name}`,
                role:
                    n.lifecycle.assignedToUser && n.lifecycle.assignedToUser.id === user.id
                        ? "Assigned to you"
                        : n.requestedBy.id === user.id || n.createdBy.id === user.id
                        ? "Requested by you"
                        : "You are a stakeholder",
            })),
        ];

        return items.sort((a, b) => (a.modified > b.modified ? -1 : 1));
    });
};
