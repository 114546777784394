export const portIn = {
    position: {
        name: "left",
    },
    attrs: {
        portBody: {
            magnet: "passive",
            r: 5,
            fill: "var(--text-color-dark)",
            stroke: "var(--text-color-dark)",
        },
    },
    label: {
        position: {
            name: "right",
            args: { y: 6 },
        },
        markup: [
            {
                tagName: "text",
                selector: "label",
                className: "label-text",
            },
        ],
    },
    markup: [
        {
            tagName: "circle",
            selector: "portBody",
        },
    ],
};

export const portOut = {
    position: {
        name: "right",
    },
    attrs: {
        portBody: {
            magnet: true,
            r: 8,
            fill: "#E6A502",
            stroke: "var(--text-color-dark)",
            strokeWidth: 2,
        },
    },
    label: {
        position: {
            name: "left",
            args: { y: 6 },
        },
        markup: [
            {
                tagName: "text",
                selector: "label",
                className: "label-text",
            },
        ],
    },
    markup: [
        {
            tagName: "circle",
            selector: "portBody",
        },
    ],
};
