import React from "react";
import { observer } from "mobx-react";

import { Stack } from "@ui/elements/Stack";
import { FontIcon } from "@ui/elements/Icon";
import { Text } from "@ui/elements/Text";

export const ActivityStreamHeader = observer(({ context }) => {
    if (!context) {
        return null;
    }
    return (
        <Stack className="activity--stream-header" horizontal verticalFill tokens={{ childrenGap: 10 }}>
            <Text variant="medium">
                <FontIcon iconName="OfficeChat" style={{ color: "var(--primary-selection-color)" }} />
            </Text>

            <Text variant="medium">Activity Stream</Text>
        </Stack>
    );
});
