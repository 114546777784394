import React from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { Pivot, PivotItem, PivotLinkFormat } from "office-ui-fabric-react/lib/Pivot";

export const PivotRouting = observer(({ children, onLinkClick, ...rest }) => {
    const { routing } = useStores();

    return (
        <Pivot
            onLinkClick={(item) => {
                onLinkClick(item, routing);
            }}
            linkFormat={PivotLinkFormat.links}
            {...rest}
        >
            {children}
        </Pivot>
    );
});

export const PivotRoutingItem = PivotItem;
export const PivotRoutingLinkFormat = PivotLinkFormat;
