import React, { Component } from "react";
import { observer } from "mobx-react";

import { CommandBar } from "office-ui-fabric-react/lib/CommandBar";
import { BoxPanel } from "@modules/base/components/BoxPanel";

import { RiskFilterBar } from "@modules/risks/components/core/RiskFilterBar";

export const RiskCommandBar = observer(
    class RiskCommandBar extends Component {
        render() {
            const { onNewRisk, onRefresh, onExport, filterQuery, onFilterChange, filterContext } = this.props;

            const items = [
                {
                    key: "newRisk",
                    text: "New",
                    iconProps: { iconName: "Add" },
                    onClick: () => onNewRisk(),
                },
            ];

            if (onRefresh) {
                items.push({
                    key: "refresh",
                    text: "Refresh",
                    iconProps: { iconName: "Refresh" },
                    onClick: onRefresh,
                });
            }

            const farItems = [];

            if (filterQuery && onFilterChange && filterContext) {
                farItems.push({
                    key: "filterBar",
                    text: "Filter Bar",
                    onRender: () => {
                        return <RiskFilterBar value={filterQuery} onChange={onFilterChange} context={filterContext} />;
                    },
                });
            }

            if (onExport) {
                farItems.push({
                    key: "export",
                    name: "Export to CSV",
                    iconOnly: true,
                    iconProps: { iconName: "ExcelDocument" },
                    onClick: onExport,
                });
            }

            return (
                <BoxPanel shadow>
                    <CommandBar styles={{ root: { paddingRight: "0px" } }} items={items} farItems={farItems} />
                </BoxPanel>
            );
        }
    }
);
