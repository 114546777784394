import React from "react";

import { Link, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    link: { fontSize: 10, marginTop: 0, color: "#1fbf7f" },
});

export const ExternalLink = ({ style, children, src }) => {
    return (
        <Link src={src} style={[styles.link, style]}>
            {children}
        </Link>
    );
};

export const InternalLink = ({ style, children, src }) => {
    return (
        <Link src={`${window.location.protocol}//${window.location.host}${src}`} style={[styles.link, style]}>
            {children}
        </Link>
    );
};
