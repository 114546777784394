import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { cx, css } from 'emotion';
import { withRouter } from 'react-router';
import { BigSearchBox } from '@baseComponents/BigSearchBox';

import { AppPage } from '@layout/containers/AppPage';
import { BoardsListDashboard } from '@boards/components/dashboard/BoardsListDashboard';
import { WorkItemSideNav } from '@workitems/components/core/WorkItemSideNav';

const bigSearchBoxClassName = cx('row--l',
    css`
        margin-bottom: 15px!important
    `
)

export const BoardsListPage = inject('pageContext', 'boardsStore', 'routing', 'workItemStore')(withRouter(observer(
    class BoardsListPage extends Component {

        constructor(props) {
            super(props)
            this.state = {
                selectedWorkItem: null,
            };
        }

        componentWillMount() {
            this.loadBoardsData(this.props);
        }

        componentWillReceiveProps(nextProps) {
            this.loadBoardsData(nextProps);
        }

        loadBoardsData = async (props) => {
            const { boardsStore, routing, pageContext } = props;
            const { dashboardStore } = boardsStore;
            pageContext.updateQueryStringParams(routing.location.search);
            const query = ({ page: pageContext.queryStringParams.p || 1, keywords: pageContext.queryStringParams.k });
            await boardsStore.loadBoards(query);
            dashboardStore.loadDashboard();
        }

        render () {
            const { boardsStore, match, routing, workItemStore } = this.props;
            const { dashboardStore, isGuestUser } = boardsStore;
            const { breadcrumb, error } = dashboardStore;
            const { newFormStore, lifecycleStore } = workItemStore;

            return (
                <AppPage
                    breadcrumb={breadcrumb}
                    loading={boardsStore.loading}
                    hasSidebar={false}
                    className="work-items list has--page-nav"
                    error={error}>

                    <WorkItemSideNav boards={boardsStore.boards} 
                        isGuestUser={isGuestUser}
                        onLinkClick={(item) => routing.push(item.url)}
                        onNewRequest={() => routing.push(`/demand/catalogue`)} />
                    
                    <div data-test={`BoardsListDashboardContainer`}>
                        { false && <BigSearchBox
                            className={bigSearchBoxClassName}
                            placeholder="Search for demands by title"
                            url="/demand/browse" />
                        }

                        <BoardsListDashboard 
                            assignedToMe={dashboardStore.assignedToMe}
                            createdByMe={dashboardStore.createdByMe}
                            assignedToMyTeam={dashboardStore.assignedToMyTeam}
                            loading={dashboardStore.loading}
                            onProductClick={(workItem) => routing.push(`/demand/catalogue/${workItem.id}`)}
                            onWorkItemClick={(workItem) => routing.push(`/demand/browse/${workItem.id}`)}
                            onPageChange={(box, index)=> dashboardStore.changePage(box, index)}
                            isGuestUser={lifecycleStore.isGuestUser}
                            products={null}
                            onNewRequest={(product) => {
                                if(!product) {
                                    routing.push(`/demand/catalogue`);
                                } else {
                                    newFormStore.show(product.id)
                                }
                            }}
                            onSearchChange={(keywords) => { 
                                if(keywords) {
                                    routing.push(`/demand/browse?k=${encodeURIComponent(keywords)}`);
                                };
                            }}
                        />
                    </div>
                </AppPage>
            );
        }
    }
)));
