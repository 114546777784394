import { observable, flow, computed } from "mobx";

import { PaginationStore } from "../../base/PaginationStore";
import { ControlService } from '../../../api/modelling';
import { ControlStore } from "./ControlStore";

export class ControlSetBrowseStore {

    public controlService: ControlService;
    public parentStore: ControlStore;

    @observable public loading: boolean = false;
    @observable public sets: any[] = [];
    @observable public keywords: string;
    @observable public pagination: PaginationStore;
    @observable public error: any;

    constructor(parentStore: ControlStore) {
        this.parentStore = parentStore;
        this.controlService = parentStore.controlService;
        this.pagination = new PaginationStore();
    }
    
    @computed
    public get breadcrumb() {
        const builder: any[] = [];

        builder.push({ text: 'Settings', key: 'settings', onClick: () => this.parentStore.rootStore.routing.push(`/settings`) });
        builder.push({ text: 'Control Sets', key: 'control-sets', onClick: () => this.parentStore.rootStore.routing.push(`/settings/control-sets`) });

        return builder;
    }

    public loadControlSets = flow(function*(query: any) {
        const options = Object.assign({ page: 1, keywords: null, activeOnly: false }, query);

        this.loading = true;
        this.sets = [];
        this.keywords = options.keywords;
        this.error = null;
        
        try {
            const result = yield this.controlService.getControlSets({ 
                pageSize: this.pagination.itemsPerPage, 
                startIndex: (options.page - 1) *  this.pagination.itemsPerPage,
                keywords: options.keywords,
                activeOnly: options.activeOnly,
            });

            this.sets = result.items;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
            this.keywords = result.query.keywords;
        } catch (e) {
            this.error = e;
            console.error(e)
        } finally {
            this.loading = false;
        }
    });
}
