import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';

export const TriageWizardActions = observer(
    class TriageWizardActions extends Component {

        render () {
            const { selectedIndex, canSubmit, onSubmit } = this.props;

            return (
                <Stack horizontal horizontalAlign="space-between">
                    <div>
                        { selectedIndex != 0 && (
                            <DefaultButton data-test="triageWizardBackButton" text="Back" onClick={() => this._onNavigateBack()} allowDisabledFocus disabled={!this._canNavigateBack()} />
                        ) }
                    </div>
                    <div>
                        { !this._isSummaryView() ? (
                            <PrimaryButton data-test="triageWizardNextButton" text="Next" onClick={() => this._onNavigateNext()} allowDisabledFocus disabled={!this._canNavigateNext()} />
                        ) : (
                            <PrimaryButton data-test="triageWizardContinueButton" text="Continue with full assessment" onClick={() => onSubmit()} disabled={!canSubmit()} allowDisabledFocus />
                        ) }
                    </div>
                </Stack>
            );
        }

        _isSummaryView() {
            return (this.props.selectedIndex + 1) == this.props.steps;
        }

        _canNavigateNext() {
            return (this.props.selectedIndex + 1) < this.props.steps && this.props.isValid();
        }

        _onNavigateNext() {
            this.props.onSelectedIndexChanged(this.props.selectedIndex + 1);
        }

        _canNavigateBack() {
            return this.props.selectedIndex > 0;
        }

        _onNavigateBack() {
            this.props.onSelectedIndexChanged(this.props.selectedIndex - 1);
        }
    }
);