import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { CommandBar } from '@ui/elements/CommandBar';
import { BoxPanel } from '@baseComponents/BoxPanel';

export const ControlMapControlCommandBar = observer(
    class ControlMapControlCommandBar extends Component {
        
        render () {
            const { axis, controls, onAddControl } = this.props;

            const items = [];

            items.push({
                key: 'addControl',
                text: 'Add Control',
                ariaLabel: 'Add Control',
                iconProps: { iconName: 'Add' },
                subMenuProps: {
                    items: controls.map(control => ({
                        key: control.id,
                        text: control.name,
                        canCheck: true,                   
                        items: control.children.map(child => ({
                            key: child.id,
                            text: child.name,
                            canCheck: true,
                            isChecked: !!axis.parts.find(a => a.id == child.id),
                            onClick: () => {
                                onAddControl(child)
                            },
                        }))
                    })),
                },
            });

            const farItems = [];

            return (
                <BoxPanel shadow attached>
                    <CommandBar
                        styles={{ root: { paddingRight: '0px' } }}
                        items={items}
                        farItems={farItems}
                    />
                </BoxPanel>
            );
        }
    }
);