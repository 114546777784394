import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';
import Moment from 'react-moment';

import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { ContextualMenuItemType } from 'office-ui-fabric-react/lib/ContextualMenu';

import './AppHeader.css';

const dropDownClassName = cx(
    'ms-ContextualMenu-list',
    css`
        overflow: hidden;
    `
);

const iconClassName = cx(
    dropDownClassName,
    css`
        color: var(--nav-color-accent);
        font-size: 50px;
    `
);

const iconRedClassName = cx(
    css`
        color: red;
    `
);

export const AppHeaderNotification = observer(
    class AppHeaderNotification extends Component {

        constructor(props) {
            super(props);
        }

        get menuProps() {
            const { layoutStore } = this.props;
            return {
                onMenuDismissed: () => {
                    layoutStore.markAllNotificationsRead()
                },
                items: [
                    {
                        key: 'section1',
                        itemType: ContextualMenuItemType.Section,
                        sectionProps: {
                            topDivider: true,
                            bottomDivider: true,
                            title: 'Notifications',
                            items: layoutStore.notifications.map(N => {
                                return {
                                    key: N.message,
                                    text: <div>
                                            {!N.read && <FontIcon iconName='LocationDot' className={iconRedClassName} />} {N.message} <i><Text variant={'tiny'}><Moment utc date={N.time} fromNow /></Text></i>
                                        </div>,
                                    onClick: () => {},
                                }
                            }),
                        },
                    }
                ]
            }
        };

        render () {
            const { layoutStore, hide } = this.props;
            return (<>
                    {!hide && <div>
                        {layoutStore.notifications.length < 1 && <IconButton iconProps={{ iconName: 'Mail', title: 'Show notifications' }} className={iconClassName} /> }
                        {!layoutStore.unreadMessages && layoutStore.notifications.length > 0 && <IconButton iconProps={{ iconName: 'Mail', title: 'Show notifications' }} className={iconClassName} menuProps={this.menuProps} /> }
                        {layoutStore.unreadMessages && <IconButton iconProps={{ iconName: 'MailAlert', title: 'Show notifications' }} className={iconClassName} menuProps={this.menuProps} /> }
                    </div>}
                </>
            );
        }
    }
);