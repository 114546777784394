import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { ActionButton, SpinnerButton } from "@ui/elements/Button";
import { PanelType } from "office-ui-fabric-react/lib/Panel";

import { ActionPanel } from "@modules/base/components/ActionPanel";
import { ObservablePanel } from "@modules/base/components/ObservablePanel";

import { TaskDefinitionEditForm } from "@modules/workflows/components/tasks/TaskDefinitionEditForm";

export const TaskDefinitionEditPanel = observer(({}) => {
    const { taskStore } = useStores();
    const { editFormStore, lifecycleStore } = taskStore;
    const { saving } = lifecycleStore;
    const { isValid, formData, formOptions } = editFormStore;

    const onDismiss = useCallback(() => {
        editFormStore.resolve(false);
    }, [editFormStore]);

    const onSave = useCallback(() => {
        editFormStore.resolve(true);
    }, [editFormStore]);

    return (
        <ObservablePanel
            hidden={!editFormStore.visible}
            pageType="Edit Task"
            panelType={PanelType.medium}
            onClosePanel={onDismiss}
            onRenderFooter={() => (
                <ActionPanel>
                    <ActionButton disabled={saving} onClick={onDismiss} text="Cancel" />
                    <SpinnerButton loading={saving} iconProps={{ iconName: "Save" }} disabled={saving || !isValid} onClick={onSave} text="Save" />
                </ActionPanel>
            )}
        >
            {formData && formOptions && <TaskDefinitionEditForm disabled={saving} formData={formData} formOptions={formOptions} />}
        </ObservablePanel>
    );
});
