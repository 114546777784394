import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { css } from '@uifabric/utilities/lib/css';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Pivot, PivotItem } from 'office-ui-fabric-react/lib/Pivot';

import { AppPage } from '../../../layout/containers/AppPage';
import { SettingsListNav } from '../../../settings/containers/utilities/SettingsListNav';

import { EntityPropertiesTab } from '../tabs/EntityPropertiesTab';
import { EntitySectionsTab } from '../tabs/EntitySectionsTab';
import { EntityWorkflowsTab } from '../tabs/EntityWorkflowsTab';

export const EntitySettingsPage = inject('pageContext', 'entityStore', 'propertyStore', 'routing')(observer(
    class EntitySettingsPage extends Component {
    
        render () {
            const { loading, error, breadcrumb } = this.props.entityStore.settingsStore;

            return (
                <AppPage error={error} breadcrumb={breadcrumb} 
                    loading={loading} hasSidebar={false} 
                    className={css('settings entities list', !error && 'has--page-nav')}>
                    <SettingsListNav />
                    <div>
                        <Text className="title" variant="xLarge" block>
                            Entity Management
                        </Text>

                        <Pivot aria-label="Entity Settings Tabs">
                            <PivotItem headerText="Properties">
                                <EntityPropertiesTab />
                            </PivotItem>
                            <PivotItem headerText="Sections">
                                <EntitySectionsTab />
                            </PivotItem>
                            <PivotItem headerText="Workflows">
                                <EntityWorkflowsTab />
                            </PivotItem>
                        </Pivot>
                    </div>
                </AppPage>
            );
        }
    }
));
