import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion'

const badgeClassName =  cx(
    'cygraph-StatusBadge-badge',
    css`
    box-shadow: none;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    box-sizing: border-box;
    position: relative;
    height: 24px;
    min-width: 24px;
    display: flex;
    align-items: center;
    `
);

const dotClassName = cx(
'cygraph-StatusBadge-dot',
    css`
    position: absolute;
    height: 8px;
    width: 8px;
    top: auto;
    left: 0;
    text-align: center;
    box-sizing: content-box;
    background-clip: content-box;
    background-color: var(--blue);
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: #ffffff;
    border-image: initial;
    `
);

const contentClassName = cx(
'cygraph-StatusBadge-content',
    css`
    padding-top: 0px;
    padding-right: 8px;
    padding-bottom: 0px;
    padding-left: 16px;
    min-width: 0px;
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    `
);

export const StatusBadge = observer(
    class StatusBadge extends Component {
        render () {
            const { children, className } = this.props;
            
            return (
              <div 
                className={cx(badgeClassName, className)}>
                    <div className={cx(dotClassName, this._getVariantClassName())}></div>
                    <div className={contentClassName}>{ children }</div>                  
              </div>
            );
        }

        _getVariantClassName() {
            const { variant } = this.props;

            switch(variant){
                case 'red':
                    return css`
                        background-color: var(--red);
                    `;
                case 'orange':
                    return css`
                        background-color: var(--orange);
                    `;
                case 'green':
                    return css`
                        background-color: var(--green);
                    `;
                case 'blue':
                    return css`
                        background-color: var(--blue);
                    `;
                case 'pink':
                    return css`
                        background-color: var(--pink);
                    `;
                case 'yellow':
                    return css`
                        background-color: var(--yellow);
                    `;
                case 'purple':
                    return css`
                        background-color: var(--purple);
                    `;
                default:
                    return css`
                        background-color: var(--grey);
                    `;
            }
        }
    }
);

