import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion'

import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';

const rootClassName = cx(
  'cygraph-AdvisoryCompliance-level',
  css`
    display: flex;
    flex-direction: row;
    position: relative;

    & .half {
      width: 30px;
      height: 20px;
      background-color: var(--grey-3);
      border: 1px solid var(--grey-5);
      align-items: center;
      justify-content: center;
      box-shadow: var(--box-shadow);
    }
    & .initial {
      border-radius: 20px 0px 0px 20px;
      margin-right: 3px;
    }
    & .final {
      border-radius: 0px 20px 20px 0px;
    }
    & .Compliant {
      background-color: var(--green-6);
      border: 1px solid var(--green-6);
    }
    & .NonCompliant {
      background-color: var(--red-6);
      border: 1px solid var(--red-6);
    }

    & .direction {
      position: absolute;
      left: 20px;
      top: -7px;
      font-size: 21px;
      color: var(--white);
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  `
);

export const AdvisoryCompliancePill = observer(
    class AdvisoryCompliancePill extends Component {
        render () {
            const { advisory } = this.props;
            
            return (
              <TooltipHost
                content={this._getTooltipText()}>
                <div className={rootClassName}>
                  <div className={cx('half', 'initial', advisory.initialCompliance)}></div>
                  <div className="direction">
                      <Icon iconName="SkypeArrow" />
                  </div>
                  <div className={cx('half', 'final', advisory.finalCompliance)}></div>
              </div>
            </TooltipHost>
            );
        }

        _getTooltipText() {
          const { initialCompliance, finalCompliance } = this.props.advisory;
          const final = finalCompliance == null ? 'the analyst is yet to mark this advisory' : `the analyst has marked this as ${this._getComplianceLabel(finalCompliance)}`
          return `Initial answer from self assessment was ${this._getComplianceLabel(initialCompliance)}, ${final}.`
        }

        _getComplianceLabel(compliance) {
          if(compliance == null) {
            return null;
          }

          if(compliance == 'Compliant') {
            return 'compliant';
          }

          return 'non-compliant';
        }
    }
);