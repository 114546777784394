import { observable, flow, computed } from "mobx";

import { PrincipalContextStore } from "../../identity/stores/PrincipalContextStore";
import { AccountStore } from "./AccountStore";

export class AccountTaskBrowseStore {
    private parentStore: AccountStore;
    private principalContext: PrincipalContextStore;

    @observable public breadcrumb: any[] = [
        { text: "Account", key: "account", onClick: () => this._goToUrl(`/account/overview`) },
        { text: "My Tasks", key: "tasks", isCurrentItem: true },
    ];

    constructor(parentStore: AccountStore) {
        this.parentStore = parentStore;
        this.principalContext = this.parentStore.rootStore.principalContext;
    }

    @computed
    public get user() {
        return this.principalContext.current;
    }

    @computed
    public get photo() {
        return this.principalContext.photo;
    }

    @computed
    public get loading() {
        return this.parentStore.rootStore.taskStore.browseStore.loading;
    }

    @computed
    public get tasks() {
        return this.parentStore.rootStore.taskStore.browseStore.tasks;
    }

    @computed
    public get pagination() {
        return this.parentStore.rootStore.taskStore.browseStore.pagination;
    }

    @computed
    public get error() {
        return this.parentStore.rootStore.taskStore.browseStore.error;
    }

    @computed
    public get query() {
        return this.parentStore.rootStore.taskStore.browseStore.query;
    }

    public loadMyTasks = flow(function* (options: any) {
        yield this.parentStore.rootStore.taskStore.browseStore.loadMyTasks(options);
    });

    _goToUrl = (url) => {
        if (url) {
            this.parentStore.rootStore.routing.push(url);
        }
    };
}
