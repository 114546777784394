import React from "react";
import { observer } from "mobx-react";

import { Dropdown } from "office-ui-fabric-react/lib/Dropdown";
import { DatePicker } from "office-ui-fabric-react/lib/DatePicker";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { Label } from "@ui/elements/Label";
import { Text } from "@ui/elements/Text";

import { AdaptiveField } from "@modules/base/components/AdaptiveField";
import { ObservableSeparator } from "@modules/base/components/ObservableSeparator";
import { PrincipalPicker } from "@modules/identity/containers/PrincipalPicker";
import { RichTextField } from "@modules/editors/components/RichTextField";

import { MeasureEditGridView } from "@modules/controls/components/deployments/MeasureEditGridView";

export const ControlReviewForm = observer(({ formData, formOptions }) => {
    return (
        <Stack tokens={{ childrenGap: 20 }} data-test="controlReviewForm">
            <div>
                <r-grid columns="2">
                    <r-cell span="2">
                        <Text variant="mediumPlus">
                            <br />
                            {formOptions.control.code} - {formOptions.control.name}
                        </Text>
                    </r-cell>
                    <r-cell>
                        <AdaptiveField
                            label="Threat Surface"
                            required
                            type="Dropdown"
                            mode="edit"
                            valueConverter="simple"
                            value={formData.threatSurface}
                            onChange={(val) => {
                                formData.threatSurface = val;
                            }}
                            options={[
                                { key: "Wellcome General", text: "Wellcome General" },
                                { key: "Wellcome Fund", text: "Wellcome Fund" },
                            ]}
                        />
                    </r-cell>
                    <r-cell span="2">
                        {formData.groups.map((group) => (
                            <div key={group.capabilityLevel.id} className="row--s">
                                <Label required>{group.capabilityLevel.name} (required)</Label>
                                <MeasureEditGridView
                                    measures={group.measures}
                                    onUpdate={(update) => {
                                        const index = group.measures.findIndex((m) => m.id === update.id);
                                        if (index !== -1) {
                                            group.measures[index] = update;
                                        }
                                    }}
                                />
                            </div>
                        ))}
                    </r-cell>
                    <r-cell span="2">
                        <RichTextField
                            label="Notes"
                            placeholder="Please enter any additional notes you want to capture against this review."
                            value={formData.description}
                            onChange={(val) => {
                                formData.description = val;
                            }}
                        />
                    </r-cell>
                </r-grid>
            </div>
        </Stack>
    );
});
