import { observable, action, computed, flow } from "mobx";

import { IdentityService } from "@api/identity";
import { PrincipalStore } from "@modules/identity/stores/PrincipalStore";
import { ResponseHandle } from "@modules/base/types/ResponseHandle";

export class IdentityUserEditFormStore {
    public parentStore: PrincipalStore;
    public identityService: IdentityService;
    private responseHandle?: ResponseHandle;

    @observable public visible: boolean = false;
    @observable public processing: boolean = false;
    @observable public formData: any;
    @observable public formOptions: any;

    constructor(parentStore: PrincipalStore) {
        this.parentStore = parentStore;
        this.identityService = parentStore.identityService;
    }

    public show = flow(function* (options) {
        this.visible = true;

        const user = yield this.identityService.getPrincipal(options.user.id);
        // const usersGroups = yield this.identityService.getUserGroups({
        //     userId: user.id,
        //     pageSize: 50,
        // });
        const groups = yield this.identityService.searchGroups({
            pageSize: 50,
        });

        this.formOptions = {
            ...options,
            pageType: options.pageType || "Edit User",
            roles: this.parentStore.rootStore.principalContext.roles,
            groups: groups.items,
            initialGroups: user.groups,
            initialRoles: user.roles,
        };

        this.formData = { ...user };

        return new Promise<any>((resolve, reject) => {
            this.responseHandle = { resolve, reject };
        });
    });

    @action
    public hide(_) {
        this.visible = false;
        this.processing = false;
        this.formData = null;
        this.formOptions = null;
        this.responseHandle = null;
    }

    @action
    public resolve(success) {
        if (!this.processing && this.responseHandle) {
            this.processing = true;
            this.responseHandle.resolve({
                success,
                formData: this.formData,
                formOptions: this.formOptions,
            });
        }
    }

    @action
    public reject(error) {
        if (!this.processing && this.responseHandle) {
            this.processing = true;
            this.responseHandle.reject({
                error,
                formData: this.formData,
                formOptions: this.formOptions,
            });
        }
    }

    @computed
    public get isValid() {
        if (!this.formData) {
            return false;
        }

        return (
            this.formData &&
            this.formData.id &&
            this.formData.roles &&
            this.formData.roles.length >= 0 &&
            this.formData.groups &&
            this.formData.groups.length >= 0
        );
    }
}
