import React from "react";
import { observer } from "mobx-react";

import { TextField } from "@ui/elements/TextField";
import { Stack } from "@ui/elements/Stack";
import { Toggle } from "@ui/elements/Toggle";
import { FontIcon } from "@ui/elements/Icon";
import { Dropdown } from "@ui/elements/Dropdown";
import { Text } from "@ui/elements/Text";

import { BigTextField } from "@modules/base/components/BigTextField";
import { ObservableSeparator } from "@modules/base/components/ObservableSeparator";

import {
    getEventDisplayName,
    getEventIconName,
    getActivityDisplayName,
    getActivityIconName,
} from "@modules/workflows/stores/WorkflowConstants";

export const ActivityInstanceEditForm = observer(({ isValid, formData, formOptions, registration }) => {
    return (
        <div>
            <r-grid columns="2">
                <r-cell span="2">
                    <Stack
                        className="card card-bg card-xs"
                        style={{ marginTop: 10 }}
                        horizontal
                        tokens={{ childrenGap: 25 }}
                        verticalFill
                        verticalAlign="center"
                    >
                        <Stack horizontal tokens={{ childrenGap: 20 }} verticalFill verticalAlign="center">
                            <FontIcon iconName={getEventIconName(formData.trigger.event)} style={{ fontSize: 30 }} />
                            <FontIcon
                                iconName="DoubleChevronRight8"
                                style={{ fontSize: 13, color: "var(--grey-light)" }}
                            />
                            <FontIcon iconName={getActivityIconName(formData.definition)} style={{ fontSize: 30 }} />
                        </Stack>
                        <Stack.Item grow>
                            <Text variant="mediumPlus" block>
                                {formData.name || "Unnamed Automation"}
                            </Text>
                            <Text variant="small" block>
                                {getEventDisplayName(formData.trigger.event)}
                                <span> 🠖 </span>
                                {getActivityDisplayName(formData.definition)}
                            </Text>
                        </Stack.Item>
                        <Stack style={{ width: 55 }} verticalFill verticalAlign="center" horizontalAlign="center">
                            <Toggle checked={formData.active} onChange={(ev, val) => (formData.active = val)} />
                            <Text variant="small" block>
                                {formData.active ? "Enabled" : "Disabled"}
                            </Text>
                        </Stack>
                    </Stack>
                </r-cell>
                <r-cell span="2">
                    <BigTextField
                        label="Name"
                        required
                        placeholder="Please enter a name for the automation"
                        transparent
                        value={formData.name}
                        onChange={(ev, val) => {
                            formData.name = val;
                        }}
                    />
                </r-cell>
                <r-cell span="2">
                    {formData && registration && registration.editor && (
                        <registration.editor
                            registration={registration}
                            formData={formData}
                            settings={formData.settings}
                            formOptions={formOptions}
                            isValid={isValid}
                        />
                    )}
                </r-cell>
            </r-grid>

            <r-grid columns="4">
                <r-cell span="4">
                    <ObservableSeparator className="row--xs">Settings</ObservableSeparator>
                </r-cell>
                <r-cell span="4">
                    <TextField
                        label="Notes"
                        multiline
                        value={formData.notes || ""}
                        onChange={(ev, val) => {
                            formData.notes = val;
                        }}
                    />
                </r-cell>
                <r-cell>
                    <TextField
                        label="Order"
                        required
                        type="number"
                        value={formData.order}
                        onChange={(ev, val) => {
                            const parsed = parseInt(val);
                            if (!isNaN(parsed)) {
                                formData.order = parsed;
                            }
                        }}
                    />
                </r-cell>
            </r-grid>
        </div>
    );
});
