import { observable, action } from "mobx";

import { loadTheme } from "office-ui-fabric-react/lib/Styling";
import { initializeIcons } from "office-ui-fabric-react/lib/Icons";
import { initializeFileTypeIcons } from "@uifabric/file-type-icons";
import { initializeFlatIcons1, initializeFlatIcons2 } from "./iconProvider";

const alternateTheme = {
    themePrimary: "#22d88f",
    themeLighterAlt: "#010906",
    themeLighter: "#062317",
    themeLight: "#0a412b",
    themeTertiary: "#158256",
    themeSecondary: "#1fbf7f",
    themeDarkAlt: "#36dd9a",
    themeDark: "#52e2a8",
    themeDarker: "#7ceabe",
    neutralLighterAlt: "#424459",
    neutralLighter: "#484a60",
    neutralLight: "#52546b",
    neutralQuaternaryAlt: "#585b72",
    neutralQuaternary: "#5e6077",
    neutralTertiaryAlt: "#75778e",
    neutralTertiary: "#f8f7f7",
    neutralSecondary: "#f9f9f8",
    neutralPrimaryAlt: "#fafaf9",
    neutralPrimary: "#f5f4f2",
    neutralDark: "#fdfcfc",
    black: "#fefefd",
    white: "#3B3D51",
};

const defaultTheme = {
    themePrimary: "#22d88f",
    themeLighterAlt: "#010906",
    themeLighter: "#062317",
    themeLight: "#0a412b",
    themeTertiary: "#158256",
    themeSecondary: "#1fbf7f",
    themeDarkAlt: "#36dd9a",
    themeDark: "#52e2a8",
    themeDarker: "#7ceabe",
    neutralLighterAlt: "#f8f8f8",
    neutralLighter: "#f4f4f4",
    neutralLight: "#eaeaea",
    neutralQuaternaryAlt: "#dadada",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c8c8",
    neutralTertiary: "#595959",
    neutralSecondary: "#373737",
    neutralPrimaryAlt: "#2f2f2f",
    neutralPrimary: "#000000",
    neutralDark: "#151515",
    black: "#0b0b0b",
    white: "#ffffff",
};

const darkTheme = {
    themePrimary: "#22d88f",
    themeLighterAlt: "#f5fdfa",
    themeLighter: "#d7f9ec",
    themeLight: "#b6f4db",
    themeTertiary: "#73e8b9",
    themeSecondary: "#3add9c",
    themeDarkAlt: "#1fc382",
    themeDark: "#1aa56d",
    themeDarker: "#137951",
    neutralLighterAlt: "#373636",
    neutralLighter: "#3f3e3e",
    neutralLight: "#4c4b4b",
    neutralQuaternaryAlt: "#545353",
    neutralQuaternary: "#5b5a5a",
    neutralTertiaryAlt: "#777575",
    neutralTertiary: "#e5e4e2",
    neutralSecondary: "#eae8e7",
    neutralPrimaryAlt: "#eeedec",
    neutralPrimary: "#d9d7d4",
    neutralDark: "#f6f6f5",
    black: "#fafafa",
    white: "#2D2C2C",
};

const contrastTheme = {
    themePrimary: "#22d88f",
    themeLighterAlt: "#f5fdfa",
    themeLighter: "#d7f9ec",
    themeLight: "#b6f4db",
    themeTertiary: "#73e8b9",
    themeSecondary: "#3add9c",
    themeDarkAlt: "#1fc382",
    themeDark: "#1aa56d",
    themeDarker: "#137951",
    neutralLighterAlt: "#0b0b0b",
    neutralLighter: "#151515",
    neutralLight: "#252525",
    neutralQuaternaryAlt: "#2f2f2f",
    neutralQuaternary: "#373737",
    neutralTertiaryAlt: "#595959",
    neutralTertiary: "#c8c8c8",
    neutralSecondary: "#d0d0d0",
    neutralPrimaryAlt: "#dadada",
    neutralPrimary: "#ffffff",
    neutralDark: "#f4f4f4",
    black: "#f8f8f8",
    white: "#000000",
};

// const defaultTheme = {
//   themePrimary: '#22d88f',
//   themeLighterAlt: '#f5fdfa',
//   themeLighter: '#d7f9ec',
//   themeLight: '#b6f4db',
//   themeTertiary: '#73e8b9',
//   themeSecondary: '#3add9c',
//   themeDarkAlt: '#1fc382',
//   themeDark: '#1aa56d',
//   themeDarker: '#137951',
//   neutralLighterAlt: '#faf9f8',
//   neutralLighter: '#f3f2f1',
//   neutralLight: '#edebe9',
//   neutralQuaternaryAlt: '#e1dfdd',
//   neutralQuaternary: '#d0d0d0',
//   neutralTertiaryAlt: '#c8c6c4',
//   neutralTertiary: '#a19f9d',
//   neutralSecondary: '#605e5c',
//   neutralPrimaryAlt: '#3b3a39',
//   neutralPrimary: '#323130',
//   neutralDark: '#201f1e',
//   black: '#000000',
//   white: '#ffffff',
// };

class ThemeProvider {
    themes = observable([
        { key: "default", text: "Default" },
        { key: "alternate", text: "Alternate" },
        { key: "dark", text: "Dark" },
        { key: "contrast", text: "Contrast" },
    ]);

    name = observable.box("default");

    constructor(name) {
        this.setTheme(name);
    }

    getName() {
        return this.name;
    }

    getTheme() {
        return this.theme;
    }

    getThemeFromName(name) {
        switch (name) {
            case "default":
                return defaultTheme;
            case "contrast":
                return contrastTheme;
            case "alternate":
                return alternateTheme;
            case "dark":
                return darkTheme;
            default:
                return defaultTheme;
        }
    }

    setTheme = action((name) => {
        this.name = name == "dark" || name == "contrast" || name == "alternate" ? name : "default";
        this.theme = loadTheme({
            palette: this.getThemeFromName(this.name),
        });

        const defaultHosted = name == "default" && window.top != window.self;
        const darkHosted = name == "dark" && window.top != window.self;

        const secondary = darkHosted ? "#4C4B4B" : name == "dark" || name == "contrast" ? "#232323" : "#22293b";
        const navLight = darkHosted
            ? this.theme.palette.white
            : name == "dark" || name == "contrast"
            ? this.theme.palette.white
            : "#374055";

        const background = defaultHosted ? "#F3F2F1" : darkHosted ? "#201F1F" : this.theme.palette.neutralLighter;

        this.applyTheme({
            "primary-brand-color": this.theme.palette.themePrimary,
            "secondary-brand-color": secondary,
            "nav-background": secondary,
            "nav-background-light": navLight,
            "highlight-color": this.theme.palette.neutralLight,
            "primary-link-color": this.theme.palette.neutralDark,
            "primary-selection-color": "#1FBF7F",
            "scrollbar-color": this.theme.palette.neutralTertiaryAlt,
            white: this.theme.palette.white,
            black: this.theme.palette.black,
            "app-header-background": this.theme.palette.white,
            "background-color": background,
            "body-background": background,
            "text-color-dark": this.theme.palette.neutralDark,
            "text-color": this.theme.palette.neutralPrimary,
            "text-color-light": this.theme.palette.neutralSecondary,
            "text-color-lighter": this.theme.palette.neutralTertiary,
            "text-color-lightest": this.theme.palette.neutralTertiary,
            "border-color-light": this.theme.palette.neutralLighter,
            "border-color": this.theme.palette.neutralLight,
            "border-color-dark": this.theme.palette.neutralQuaternaryAlt,
            "border-color-darker": this.theme.palette.neutralTertiaryAlt,
        });

        window.localStorage.setItem("zerodai:preference:theme", this.name);
    });

    applyTheme(themeObject) {
        Object.entries(themeObject).forEach((_) => document.body.style.setProperty(`--${_[0]}`, `${_[1]}`));
    }
}

if (window.top != window.self && document.body.className.indexOf("hosted") === -1) {
    document.body.className = document.body.className + " hosted scrollbar--root";
}

export const themeProvider = new ThemeProvider(window.localStorage.getItem("zerodai:preference:theme"));

initializeIcons("/fabric/fonts/");
initializeFileTypeIcons();
initializeFlatIcons1();
initializeFlatIcons2();
