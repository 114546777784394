import React, { Component } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { getId } from "@uifabric/utilities/lib/getId";

import { Label } from "office-ui-fabric-react/lib/Label";
import { NormalPeoplePicker, ValidationState } from "office-ui-fabric-react/lib/Pickers";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";
import { IconButton } from "office-ui-fabric-react/lib/Button";
import { Stack } from "office-ui-fabric-react/lib/Stack";

const containerClassName = css`
    & .ms-BasePicker-text {
        background-color: var(--white);
        min-height: 32px;
    }
    & .ms-Persona-presence {
        top: 6px;
        right: 0;
    }
`;

export const PrincipalPicker = observer(
    class PrincipalPicker extends Component {
        render() {
            const {
                label,
                placeholder,
                required,
                itemLimit,
                disabled,
                className,
                onSelected,
                onFilter,
                selected,
                multiple,
                loading,
                onClearFilter,
                showClearFilter,
                allowClear,
            } = this.props;

            const suggestionProps = {
                suggestionsHeaderText: "Suggested people from directory",
                mostRecentlyUsedHeaderText: "Suggested people from directory",
                noResultsFoundText: "No matching people found",
                loadingText: "Loading...",
                showRemoveButtons: false,
                suggestionsAvailableAlertText: "People picker suggestions available",
                suggestionsContainerAriaLabel: "Suggested people from directory",
            };

            const items = multiple ? selected || [] : selected ? [selected] : [];

            const pickerId = getId("principalPicker");

            return (
                <div
                    className={cx("cygraph-PrincipalPicker-container", className, containerClassName)}
                    data-test={this.props["data-test"]}
                >
                    {label && (
                        <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                            <Stack horizontal tokens={{ childrenGap: 5 }}>
                                <Label required={required} disabled={disabled} htmlFor={pickerId}>
                                    {label}
                                </Label>
                                {loading && <Spinner size={SpinnerSize.xSmall} />}
                            </Stack>
                            {(allowClear || showClearFilter) && (multiple ? items && items.length > 0 : !!items) && (
                                <IconButton
                                    styles={{ root: { width: 24, height: 24 }, icon: { fontSize: 13, lineHeight: 13 } }}
                                    iconProps={{ iconName: "ClearFilter" }}
                                    onClick={() => {
                                        if (showClearFilter) {
                                            // FIX .. backward compatibility
                                            if (onClearFilter) onClearFilter();
                                        } else {
                                            onSelected(multiple ? [] : null);
                                        }
                                    }}
                                />
                            )}
                        </Stack>
                    )}
                    <NormalPeoplePicker
                        id={pickerId}
                        required={required}
                        selectedItems={items.map((s) => this._getPersonaFromPrincipal(s))}
                        onResolveSuggestions={(text, current, limit) => {
                            if (text) {
                                return onFilter(text, current, limit).then((result) => {
                                    return result.items.map((s) => this._getPersonaFromPrincipal(s));
                                });
                            }
                        }}
                        //onEmptyInputFocus={returnMostRecentlyUsed}
                        pickerSuggestionsProps={suggestionProps}
                        className="cygraph-PrincipalPicker"
                        onValidateInput={this._validateInput}
                        removeButtonAriaLabel={"Remove"}
                        itemLimit={multiple ? itemLimit : 1}
                        inputProps={{
                            placeholder: placeholder,
                        }}
                        pickerCalloutProps={{
                            className: cx("cygraph-PrincipalPicker-callout"),
                        }}
                        onChange={(items) => {
                            if (onSelected) {
                                const current = items.map((i) => i.data);
                                onSelected(multiple ? current : current.length ? current[0] : null);
                            }
                            return items;
                        }}
                        resolveDelay={300}
                        disabled={disabled}
                    />
                </div>
            );
        }

        _getPersonaFromPrincipal(principal) {
            var empty = principal.id == "00000000-0000-0000-0000-000000000000";
            return {
                key: empty ? principal.externalId : principal.id,
                text: principal.name,
                secondaryText: principal.userPrincipalName,
                //size: PersonaSize.size32,
                data: principal,
            };
        }

        _getTextFromItem(persona) {
            return persona.name;
        }

        _onFilterChanged(filterText, currentPersonas, limitResults) {
            return [];
        }

        _validateInput(input) {
            if (input.indexOf("@") !== -1) {
                return ValidationState.valid;
            } else if (input.length > 1) {
                return ValidationState.warning;
            } else {
                return ValidationState.invalid;
            }
        }
    }
);
