import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';

import { ComingSoon } from '@baseComponents/ComingSoon';
import { BoxPanel } from '@baseComponents/BoxPanel';

export const AssetComplianceTab = inject('assetStore', 'routing')(observer(
    class AssetComplianceTab extends Component {

        componentWillMount() {
            const { assetStore, routing } = this.props;
        }

        componentWillReceiveProps(nextProps) {
            const { assetStore, routing } = nextProps;
        }
    
        render () {
            const { assetStore, routing } = this.props;

            return (
                <BoxPanel shadow padding>
                    <ComingSoon />
                </BoxPanel>
            );
        }
    }
));
