import React, { Component } from 'react';
import { cx, css } from 'emotion';

import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { WorkItemAssignForm } from '../../containers/forms/WorkItemAssignForm';

export class AssignItemDialog extends Component {

    render () {
        const { onCancel, onConfirm, selectedContact, selectedGroup, onContactSelected, onGroupSelected} = this.props;
        return (
            <Dialog
                onDismiss={onCancel}
                hidden={false}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Assign work item',
                    closeButtonAriaLabel: 'Close',
                    // subText: 'Please assign the work item before proceding',
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}
                >
                    <WorkItemAssignForm 
                        assignedContact = {selectedContact}
                        onContactSelected = {onContactSelected}
                        assignedGroup = {selectedGroup}
                        onGroupSelected = {onGroupSelected}
                    />
                    <DialogFooter>
                        <PrimaryButton onClick={onConfirm} text="Assign" />
                        <DefaultButton onClick={onCancel} text="Cancel" />
                    </DialogFooter>
            </Dialog>
        );
    }
};