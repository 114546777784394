import { observable, action } from "mobx";

import { RiskStore } from "./RiskStore";

export class RiskTargetPickerStore {

    private parentStore: RiskStore;

    @observable public targetType: string;
    @observable public targetId: string;
    @observable public targetName: string;
    @observable public targetData: any;

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
    }

    @action
    public setTargetType(targetType) {
        this.targetType = targetType;
        this.setTargetData(null);
    }

    @action
    public setTargetData(targetData) {
        if(targetData) {
            this.targetData = targetData;
            this.targetId = targetData.id;
            this.targetName = targetData.name;
        } else {
            this.targetId = null;
            this.targetName = null;
            this.targetData = null;
        }
    }
}
