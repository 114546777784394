import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Moment from 'react-moment';

import { ActivityItem } from 'office-ui-fabric-react/lib/ActivityItem';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { getInitials } from '@uifabric/utilities/lib/initials';

export const AssessmentComment = observer(
    class AssessmentComment extends Component {
        
        render () {
            const { comment } = this.props;
            
            const item = {
                activityDescription: [
                    <div key={1} className="comment__meta">
                        <span>{comment.createdBy.name}</span>
                        <span className="comment__time"><Moment date={comment.created} fromNow /></span>
                    </div>
                ],
                activityPersonas: [{ imageInitials: getInitials(comment.createdBy.name), text: comment.createdBy.name }],
                comments: <Text block className="comment__response">{comment.content}</Text>,
              };

            return (
                <div className="comment">
                    <ActivityItem {...item} />
                </div>
            );
        }
    }
);