import React from "react";
import { observer } from "mobx-react";

import { mergeStyleSets } from "office-ui-fabric-react/lib/Styling";

import { Stack } from "@ui/elements/Stack";
import { FontIcon } from "@ui/elements/Icon";

import RichTextViewer from "@modules/editors/components/RichTextViewer";

const classNames = mergeStyleSets({
    container: {
        padding: "0.5rem",
        boxShadow: "var(--box-shadow)",
        backgroundColor: "var(--white)",
        borderRadius: "var(--border-radius)",
    },
    icon: {
        fontSize: "1.3rem",
        padding: "0.5rem",
    },
    title: {
        textTransform: "uppercase",
        color: "var(--text-color-lightest)",
        letterSpacing: "0.6px",
        fontSize: "0.7rem",
    },
    state: {
        marginBottom: "0.6px",
        fontSize: "1rem",
    },
});

export const StateFlowChangeCard = observer(({ attachment, title, icon }) => {
    const comment = attachment.payload.comment;
    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <Stack
                className={classNames.container}
                horizontal
                verticalFill
                verticalAlign="center"
                tokens={{ childrenGap: 10 }}
            >
                <Stack.Item>
                    <FontIcon
                        className={classNames.icon}
                        iconName={
                            icon ||
                            (attachment.payload.trigger
                                ? attachment.payload.trigger.icon || "VisioDiagram"
                                : "Location")
                        }
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <div className={classNames.title}>{title || "Current Status"}</div>
                    <div className={classNames.state}>{attachment.payload.after.subState}</div>
                    {/* (!attachment.payload.before ||
                                attachment.payload.before.subStateId !== attachment.payload.after.subStateId) */}
                </Stack.Item>
            </Stack>
            {comment && (
                <div className="activity--content-text">
                    {comment.contentType === "text/json+rich-text" && <RichTextViewer value={comment.content} />}
                    {comment.contentType === "text/plain" && <span>{comment.content}</span>}
                </div>
            )}
        </Stack>
    );
});
