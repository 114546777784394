import React, { PureComponent } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import ContentEditable from "react-contenteditable";
import { Text } from "office-ui-fabric-react/lib/Text";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { Facepile, OverflowButtonType } from "office-ui-fabric-react/lib/Facepile";
import { PersonaSize } from "office-ui-fabric-react/lib/Persona";

import { ObservableBadge } from "../../../base/components/ObservableBadge";
import { ActionSet } from "../../../base/components/ActionSet";

import { PageMetadata } from "./PageMetadata";

const rootClassName = cx(
    "cygraph-PageHeader-root",
    css`
        & .badge {
            & > div {
                outline: none;
            }
        }

        & .title {
            margin: var(--spacing-s) 0;
            & > div {
                outline: none;
            }
        }

        & .description {
            color: var(--text-color-light);
            font-size: var(--font-size-xs);
            margin-top: var(--spacing-xs);
            & > div {
                outline: none;
            }
        }

        & .meta {
            display: inline-block;
            margin-right: 20px;
        }

        & .facepile {
            margin: var(--spacing-s) 0 var(--spacing-xxs) 0;

            & .ms-Facepile-member:not(:last-child) {
                margin-right: -10px;
            }

            & .ms-Facepile-addButton {
                background-color: transparent;
            }
        }
    `
);

export const PageHeader = observer(
    class PageHeader extends PureComponent {
        render() {
            const { className, page, hideMetadata, isEditMode, sharedWith, canShare, onShare, onScopes } = this.props;
            const { items, overflowItems } = this._getActionSetItems();

            return (
                <div className={cx(rootClassName, className)}>
                    <Stack horizontal horizontalAlign="space-between">
                        <ObservableBadge className="badge">
                            <ContentEditable html={page.type || "Page"} disabled={!isEditMode} onChange={(evt) => (page.type = evt.target.value)} />
                        </ObservableBadge>
                        <ActionSet items={items} overflowItems={overflowItems} />
                    </Stack>
                    <Stack horizontal horizontalAlign="space-between">
                        <Stack.Item grow>
                            <Text className="title" variant="xLarge" block>
                                <ContentEditable html={page.title} disabled={!isEditMode} onChange={(evt) => (page.title = evt.target.value)} />
                            </Text>
                        </Stack.Item>
                        <Stack.Item>
                            <Facepile
                                className="facepile"
                                personas={
                                    !sharedWith
                                        ? []
                                        : sharedWith.map((s) => ({
                                              personaName: s.name,
                                              data: s,
                                              onClick: onScopes,
                                          }))
                                }
                                maxDisplayablePersonas={5}
                                personaSize={PersonaSize.size32}
                                overflowButtonProps={{
                                    ariaLabel: "More users",
                                    onClick: () => onScopes(),
                                }}
                                overflowButtonType={OverflowButtonType.descriptive}
                                showAddButton={canShare && canShare()}
                                addButtonProps={{
                                    ariaLabel: "Share with",
                                    onClick: () => onShare(),
                                }}
                            />
                        </Stack.Item>
                    </Stack>
                    {!hideMetadata && <PageMetadata page={page} />}
                    {(isEditMode || page.summary) && (
                        <div className="description">
                            <ContentEditable html={page.summary} disabled={!isEditMode} onChange={(evt) => (page.summary = evt.target.value)} />
                        </div>
                    )}
                </div>
            );
        }

        _getActionSetItems() {
            const { hideActions, isEditMode, canEdit, onEdit, canCancel, onCancel, canSave, onSave, canPublish } = this.props;

            const items = [];
            const overflowItems = [];

            if (hideActions) {
                return { items, overflowItems };
            }

            if (!isEditMode) {
                items.push({
                    key: "edit",
                    name: "Edit",
                    iconProps: { iconName: "Edit" },
                    disabled: !canEdit || !canEdit(),
                    ariaLabel: "Edit",
                    onClick: onEdit,
                });
            } else {
                items.push({
                    key: "cancel",
                    name: "Discard",
                    iconProps: { iconName: "Uneditable" },
                    disabled: !canCancel || !canCancel(),
                    ariaLabel: "Discard",
                    onClick: onCancel,
                });

                items.push({
                    key: "save",
                    name: "Save",
                    iconProps: { iconName: "Save" },
                    disabled: !canSave || !canSave(),
                    ariaLabel: "Save",
                    onClick: () => onSave("Minor"),
                });
            }

            items.push({
                key: "publish",
                name: "Publish",
                primary: true,
                className: "publishButton",
                iconProps: { iconName: "ReadingMode" },
                disabled: !canPublish || !canPublish(),
                confirmProps: { title: "Are you sure you want to publish this page?" },
                ariaLabel: "Publish",
                onClick: () => {
                    document.getElementsByClassName("publishButton")[0].click();
                    onSave("Major");
                },
            });

            return { items, overflowItems };
        }
    }
);
