import { observable, computed, flow, action } from "mobx";
import download from "downloadjs";

import { AssessmentService } from "../../../api/assessments";
import { AssessmentStore } from "./AssessmentStore";
import { AssessmentPermissionStore } from "./AssessmentPermissionStore";

export class AssessmentLifecycleStore {
    private assessmentService: AssessmentService;
    public parentStore: AssessmentStore;
    public permissionStore: AssessmentPermissionStore;

    @observable public saving: boolean = false;

    constructor(parentStore: AssessmentStore) {
        this.parentStore = parentStore;
        this.permissionStore = parentStore.permissionStore;
        this.assessmentService = parentStore.assessmentService;
    }

    @computed get displayAdvisoriesFilterBar() {
        return this.parentStore.advisoryStore.displayFilterBar;
    }

    @computed
    public get canViewResourcePages() {
        return this.permissionStore.canViewResourcePages;
    }

    @computed
    public get canViewCalendarPages() {
        return this.permissionStore.canViewCalendarPages;
    }

    @computed
    public get canViewAdvisoryPages() {
        return this.permissionStore.canViewAdvisoryPages;
    }

    @computed
    public get isReadOnly() {
        const assessment = this.parentStore.selectionStore.assessment;

        if (!assessment) {
            return false;
        }

        var readOnly = assessment.state != "Draft" && assessment.state != "Started";

        if (!readOnly) {
            return !this.permissionStore.canUpdateValues;
        }

        return readOnly;
    }

    @computed
    public get showSubmissionSuccessful() {
        const assessment = this.parentStore.selectionStore.assessment;
        const user = this.permissionStore.principalContext.current;

        if (!assessment || !user) {
            return false;
        }

        return (
            assessment.state == "Remediation" &&
            assessment.submittedBy &&
            assessment.submittedBy.id == user.id &&
            !this.permissionStore.isAssignedToUser
        );
    }

    @computed
    public get isRisksTabAvailable() {
        const assessment = this.parentStore.selectionStore.assessment;

        if (!assessment) {
            return false;
        }

        return assessment.state == "Reviewing" || assessment.state == "Closed";
    }

    @computed
    public get isAdvisoriesTabAvailable() {
        const assessment = this.parentStore.selectionStore.assessment;

        if (!assessment) {
            return false;
        }

        return assessment.state == "Remediation" || assessment.state == "Reviewing" || assessment.state == "Closed";
    }

    @computed
    public get isModulesComplete() {
        const modules = this.parentStore.viewStore.modules;

        if (!modules) {
            return false;
        }

        return !modules.find((m) => m.percentComplete < 100);
    }

    @computed
    public get canStart() {
        const assessment = this.parentStore.selectionStore.assessment;

        if (!assessment) {
            return false;
        }

        return assessment.state == "Draft" && this.permissionStore.canStart;
    }

    public onStart = flow(function* () {
        this.saving = true;

        try {
            const assessment = this.parentStore.selectionStore.assessment;
            const updated = yield this.assessmentService.startAssessment(assessment.id);
            this.parentStore.selectionStore.assessment = updated;
            this.parentStore.viewStore.autoSelectTab();
        } catch (error) {
            console.error(error);
            this.error = error;
        }

        this.saving = false;
    });

    @computed
    public get canSubmit() {
        const assessment = this.parentStore.selectionStore.assessment;

        if (!assessment) {
            return false;
        }

        return (
            (assessment.state == "Draft" || assessment.state == "Started") &&
            this.isModulesComplete &&
            this.permissionStore.canSubmit
        );
    }

    public onSubmit = flow(function* () {
        this.saving = true;

        try {
            const assessment = this.parentStore.selectionStore.assessment;
            const updated = yield this.assessmentService.submitAssessment(assessment.id);
            // debugger
            this.parentStore.rootStore.layoutStore.displayToastNotification(
                `Assessment ${assessment.code} has been sent for review.`
            );
            this.parentStore.selectionStore.assessment = updated;
            this.parentStore.advisoryStore.loadAssessmentAdvisories(updated.id);
            this.parentStore.viewStore.autoSelectTab();
        } catch (error) {
            console.error(error);
            this.error = error;
        }

        this.saving = false;
    });

    @computed
    public get canReview() {
        const assessment = this.parentStore.selectionStore.assessment;
        const { allAdvisoriesClosed, allRequiredEvidencesProvided, advisories } = this.parentStore.advisoryStore;

        if (!assessment) {
            return false;
        }

        const count = advisories.filter((advisory) => {
            return advisory.state == "Closed" && advisory.finalCompliance == "NonCompliant";
        }).length;

        return (
            assessment.state == "Remediation" &&
            allAdvisoriesClosed &&
            allRequiredEvidencesProvided &&
            count > 0 &&
            this.permissionStore.canReview
        );
    }

    public onReview = flow(function* () {
        this.saving = true;

        try {
            const assessment = this.parentStore.selectionStore.assessment;
            const updated = yield this.assessmentService.reviewAssessment(assessment.id);
            this.parentStore.selectionStore.assessment = updated;
            this.parentStore.viewStore.autoSelectTab();
        } catch (error) {
            console.error(error);
            this.error = error;
            this.parentStore.rootStore.layoutStore.displayToastNotification(error.message);
        }

        this.saving = false;
    });

    @computed
    public get canClose() {
        const assessment = this.parentStore.selectionStore.assessment;
        const { allAdvisoriesClosed, allRequiredRisksRaised, allRequiredEvidencesProvided } =
            this.parentStore.advisoryStore;

        if (!assessment) {
            return false;
        }

        return (
            (assessment.state == "Reviewing" || assessment.state == "Remediation") &&
            allAdvisoriesClosed &&
            allRequiredRisksRaised &&
            (assessment.state != "Remediation" || allRequiredEvidencesProvided) &&
            this.permissionStore.canClose
        );
    }

    public onClose = flow(function* () {
        this.saving = true;

        try {
            const assessment = this.parentStore.selectionStore.assessment;
            const updated = yield this.assessmentService.closeAssessment(assessment.id);
            this.parentStore.rootStore.layoutStore.displayToastNotification(
                `Assessment ${assessment.code} has been closed`
            );
            this.parentStore.selectionStore.assessment = updated;
            this.parentStore.viewStore.autoSelectTab();
        } catch (error) {
            console.error(error);
            this.error = error;
            this.parentStore.rootStore.layoutStore.displayToastNotification(error.message);
        }

        this.saving = false;
    });

    @computed
    public get canCancel() {
        const assessment = this.parentStore.selectionStore.assessment;

        if (!assessment) {
            return false;
        }

        return assessment.state != "Closed" && assessment.state != "Cancelled" && this.permissionStore.canCancel;
    }

    public onCancel = flow(function* () {
        this.saving = true;

        try {
            const assessment = this.parentStore.selectionStore.assessment;
            const updated = yield this.assessmentService.cancelAssessment(assessment.id);
            this.parentStore.selectionStore.assessment = updated;
            this.parentStore.viewStore.autoSelectTab();
        } catch (error) {
            console.error(error);
            this.error = error;
        }

        this.saving = false;
    });

    @computed
    public get canUpdateAdvisories() {
        const assessment = this.parentStore.selectionStore.assessment;
        const { selectedAdvisories, requiredEvidencesProvided } = this.parentStore.advisoryStore;

        if (!assessment) {
            return false;
        }

        return (
            assessment.state == "Remediation" &&
            selectedAdvisories &&
            selectedAdvisories.count > 0 &&
            requiredEvidencesProvided &&
            this.permissionStore.canUpdateAdvisories
        );
    }

    @computed
    public get canAddEvidence() {
        const assessment = this.parentStore.selectionStore.assessment;
        const { selectedAdvisories } = this.parentStore.advisoryStore;

        if (!assessment) {
            return false;
        }

        return (
            assessment.state == "Remediation" &&
            selectedAdvisories &&
            selectedAdvisories.count > 0 &&
            this.permissionStore.canAddEvidence
        );
    }

    @computed
    public get canAddRisk() {
        const assessment = this.parentStore.selectionStore.assessment;
        const { selectedAdvisories } = this.parentStore.advisoryStore;

        if (!assessment) {
            return false;
        }

        return (
            assessment.state == "Reviewing" &&
            selectedAdvisories &&
            selectedAdvisories.count > 0 &&
            this.permissionStore.canUpdateAdvisories
        );
    }

    @computed
    public get canExportCsv() {
        const assessment = this.parentStore.selectionStore.assessment;

        if (!assessment) {
            return false;
        }

        return this.permissionStore.canRead;
    }

    public onExportCsv = flow(function* () {
        this.saving = true;

        try {
            const assessment = this.parentStore.selectionStore.assessment;
            const blob = yield this.assessmentService.downloadAssessmentValues(assessment.id);
            download(blob, `${assessment.code}.csv`, "text/csv");
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.saving = false;
        }
    });

    @computed
    public get canUpload() {
        const assessment = this.parentStore.selectionStore.assessment;

        if (!assessment) {
            return false;
        }

        return assessment.state != "Closed" && assessment.state != "Cancelled" && this.permissionStore.canUpload;
    }

    @computed
    public get canAssign() {
        const assessment = this.parentStore.selectionStore.assessment;
        const workItem = this.parentStore.selectionStore.workItem;

        if (!assessment || !workItem) {
            return false;
        }

        return (
            assessment.state != "Closed" &&
            assessment.state != "Cancelled" &&
            workItem.status !== "Resolved" &&
            workItem.status !== "Closed" &&
            workItem.status !== "Cancelled" &&
            this.permissionStore.canAssign
        );
    }

    public onAssign = flow(function* () {
        const assessment = this.parentStore.selectionStore.assessment;
        const pageTitle = `${assessment.code} - ${assessment.title}`;

        // this.parentStore.rootStore.workItemStore.assignFormStore.show({
        //     workItem,
        //     pageTitle,
        // });

        const current = this.parentStore.selectionStore.workItem;
        const workItemStore = this.parentStore.rootStore.workItemStore;
        workItemStore.selectionStore.setSelected(current);
        const { success, workItem } = yield workItemStore.lifecycleStore.onAssign(
            {},
            {
                args: { pageTitle },
                toast: "hide",
            }
        );

        if (success && workItem) {
            this.parentStore.selectionStore.workItem = workItem;
            this.parentStore.rootStore.layoutStore.displayToastNotification(
                `Assessment ${assessment.code} assigned to ${workItem.assignedToUser.name} from ${workItem.assignedToGroup.name}`
            );
        }
    });

    // public onAfterAssign = flow(function* (workItem) {
    //     if (workItem) {
    //         this.parentStore.selectionStore.workItem = workItem;
    //         const assessment = this.parentStore.selectionStore.assessment;

    //         this.parentStore.rootStore.layoutStore.displayToastNotification(
    //             `Assessment ${assessment.code} assigned to ${workItem.assignedToUser.name} from ${workItem.assignedToGroup.name}`
    //         );
    //     }
    // });

    public onReport = flow(function* () {
        const assessment = this.parentStore.selectionStore.assessment;
        this.parentStore.rootStore.routing.push(`/assurance/browse/${assessment.id}/report`);
    });

    public hideAdvisoriesFilterBar = () => {
        this.parentStore.advisoryStore.displayFilterBar = false;
    };

    @computed
    public get canAddComments() {
        return this.permissionStore.canAddComments;
    }

    @computed
    public get canManageTasks() {
        return this.permissionStore.canManageTasks;
    }

    @computed
    public get canManageAutomations() {
        return this.permissionStore.canManageAutomations;
    }
}
