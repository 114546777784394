import { observable, flow, computed, action } from "mobx";

import { RiskService } from "../../../api/risks";
import { RiskStore } from "./RiskStore";

export class RiskRegistryViewerPageStore {
    private parentStore: RiskStore;
    private riskService: RiskService;

    @observable public riskRegistry: any;
    @observable public loading: boolean = false;
    @observable public error: any;

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
    }

    @computed
    public get breadcrumb() {
        const routing = this.parentStore.rootStore.routing;
        const builder: any[] = [];

        builder.push({
            text: "Settings",
            key: "settings",
            onClick: () => routing.push(`/settings`),
        });
        builder.push({
            text: "Risk Management",
            key: "settings-risks",
            onClick: () => routing.push(`/settings/risks`),
        });
        builder.push({
            text: "Registries",
            key: "risks-registries",
            onClick: () => routing.push(`/settings/risks/registries`),
        });

        if (!this.riskRegistry) {
            return builder;
        }

        builder.push({ text: this.riskRegistry.name, key: this.riskRegistry.id });

        return builder;
    }

    @action
    public reset() {
        this.riskRegistry = null;
    }

    public loadRegistry = flow(function* (id: string) {
        this.loading = true;
        this.riskRegistry = null;

        try {
            this.riskRegistry = yield this.riskService.getRegistry(id);
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.loading = false;
        }
    });
}
