import React from "react";
import { observer } from "mobx-react";
import Moment from "react-moment";

import { StatusProgress, StatusProgressStep } from "@modules/base/components/StatusProgress";

export const ControlMaturityProgress = observer((props) => {
    const { control, className } = props;

    const isStatusOneOf = (status) => {
        return status.indexOf(control.state) != -1;
    };
    return (
        <StatusProgress className={className}>
            <StatusProgressStep title="Initial" active={isStatusOneOf(["Draft"])} done={!isStatusOneOf(["Draft"])}>
                <span>
                    Initiated on <Moment date={control.created} format="Do MMM YYYY" />
                </span>
            </StatusProgressStep>
            <StatusProgressStep
                title="Repeatable"
                active={isStatusOneOf(["Started"])}
                done={!isStatusOneOf(["Draft", "Started"])}
            >
                <span>
                    Repeatable since <Moment date={control.created} format="Do MMM YYYY" />
                </span>
            </StatusProgressStep>
            <StatusProgressStep
                title="Defined"
                blocked
                active={true}
                done={isStatusOneOf(["Reviewing", "Closed", "Cancelled"])}
            >
                <span>
                    Defined since <Moment date={control.modified} format="Do MMM YYYY" />
                </span>
            </StatusProgressStep>
            <StatusProgressStep
                title="Managed"
                active={isStatusOneOf(["Reviewing"])}
                done={isStatusOneOf(["Closed", "Cancelled"])}
            >
                <span>Not yet achieved</span>
            </StatusProgressStep>
            <StatusProgressStep title="Optimised" active={isStatusOneOf(["Closed", "Cancelled"])}>
                <span>Not yet achieved</span>
            </StatusProgressStep>
        </StatusProgress>
    );
});
