import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';

import { Image, ImageFit } from 'office-ui-fabric-react/lib/Image';

import { PagePartPlaceholder } from  '../elements/PagePartPlaceholder';

const rootClassName = cx(
  'cygraph-ImageViewerPart-root',
  css`
  `
);

export const ImageViewerPart = observer(
  class ImageViewerPart extends PureComponent {
    render () {
      const { className, instance, part, zone, section, registry } = this.props;
      const mode = instance.lifecycleStore.mode;
      
      return (
        <div className={cx(rootClassName, className, mode)}>
          <PagePartPlaceholder {...this.props} isConfigured={part.properties && part.properties.src && part.properties.imageFit}>
            <Image {...part.properties}
                styles={{ root: { width: '100%',  height: '100%' }}}
                imageFit={ImageFit[part.properties.imageFit || 'none']} />
          </PagePartPlaceholder>
        </div>
      );
    }
  }
);