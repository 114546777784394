import { observable, flow } from "mobx";

import { WorkItemService } from "../../../api/workitems";
import { WorkItemStore } from "./WorkItemStore";

export class WorkItemLinkTypeStore {
    private workItemService: WorkItemService;
    public parentStore: WorkItemStore;

    @observable public loading: boolean = false;
    @observable public linkTypes: any[] = [];

    constructor(parentStore: WorkItemStore) {
        this.parentStore = parentStore;
        this.workItemService = parentStore.workItemService;
    }

    public loadWorkItemLinkTypes = flow(function* () {
        this.loading = true;
        try {
            if (this.linkTypes.length === 0) {
                const result = yield this.workItemService.getWorkItemLinkTypes();
                this.linkTypes = result;
            }
            return this.linkTypes;
        } catch (e) {
            console.error(e);
            throw e;
        } finally {
            this.loading = false;
        }
    });
}
