import React, { Component } from "react";
import { observer } from "mobx-react";

import { PrimaryButton } from "office-ui-fabric-react/lib/Button";

import { ActionBar } from "@baseComponents/ActionBar";
import { PopConfirm } from "@baseComponents/PopConfirm";

export const AdvisoryActionBar = observer(
    class AdvisoryActionBar extends Component {
        render() {
            const { className, assessment, isAssignedToUser, advisories, canReview, onReview, canClose, onClose } = this.props;
            const count = advisories.filter((advisory) => {
                return advisory.state == "Closed" && advisory.finalCompliance == "NonCompliant";
            }).length;

            if (assessment.state == "Remediation") {
                if (canReview && canReview()) {
                    return (
                        <ActionBar
                            className={className}
                            margin={false}
                            title="Non-compliant advisories require action"
                            description={`There ${count == 1 ? "is" : "are"} ${count} outstanding non-compliant ${
                                count == 1 ? "advisory" : "advisories"
                            } in this assessment. If it is not possible to remediate these advisories, you should proceed to risk review by clicking on 'Review Risks' where you will be able to create risks against these advisories. Please note, during risk review process advisories will be locked and you will no longer be able to make any changes to them. `}
                            variant="dark"
                            compact
                        >
                            <PopConfirm title="Are you sure you want to proceed to risk review?" disabled={!canReview()} onConfirm={onReview}>
                                <PrimaryButton text="Review Risks" disabled={!canReview()} />
                            </PopConfirm>
                        </ActionBar>
                    );
                } else if (canClose && canClose()) {
                    return (
                        <ActionBar
                            className={className}
                            margin={false}
                            title="Assessment awaiting sign-off"
                            description="There are no non-compliant advisories open. Click 'Close' to close the assessment."
                            variant="dark"
                            compact
                        >
                            {canClose && (
                                <PopConfirm title="Are you sure you want to close the assessment?" disabled={!canClose()} onConfirm={onClose}>
                                    <PrimaryButton text="Close" disabled={!canClose()} />
                                </PopConfirm>
                            )}
                        </ActionBar>
                    );
                } else if (isAssignedToUser) {
                    return (
                        <ActionBar
                            className={className}
                            margin={false}
                            title="Open advisories that require attention"
                            description="Please review the open advisories and mark them compliant or non-compliant as required.  Please ensure that you attach evidence when marking advisories."
                            variant="dark"
                            compact
                        ></ActionBar>
                    );
                }
            } else if (assessment.state == "Reviewing") {
                if (canClose && canClose()) {
                    return (
                        <ActionBar
                            className={className}
                            margin={false}
                            title="Assessment awaiting sign-off"
                            description="You have now created draft risks for all non-compliant advisories and they are ready to be sent to the risk team. Click 'Close' to close the assessment and send the risks to risk team for review."
                            variant="dark"
                            compact
                        >
                            {canClose && (
                                <PopConfirm title="Are you sure you want to close the assessment?" disabled={!canClose()} onConfirm={onClose}>
                                    <PrimaryButton text="Close" disabled={!canClose()} />
                                </PopConfirm>
                            )}
                        </ActionBar>
                    );
                } else {
                    return (
                        <ActionBar
                            className={className}
                            margin={false}
                            title="Non-compliant advisories require action"
                            description={`There ${count == 1 ? "is" : "are"} ${count} outstanding non-compliant ${
                                count == 1 ? "advisory" : "advisories"
                            } in this assessment. Please create risks against all non-compliant advisories to close the assessment.`}
                            variant="dark"
                            compact
                        ></ActionBar>
                    );
                }
            }

            return null;
        }
    }
);
