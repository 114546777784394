import React from "react";

import { Icon } from "office-ui-fabric-react/lib/Icon";
import { Stack } from "office-ui-fabric-react/lib/Stack";

export function WorkItemPriority({ value }) {
    const { icon, color } = {
        High: {
            icon: "Important",
            color: "var(--red-10)",
        },
        Medium: {
            icon: "LocationDot",
            color: "var(--blue)",
        },
        Low: {
            icon: "Down",
            color: "var(--green-12)",
        },
    }[value];

    return (
        <Stack horizontal tokens={{ childrenGap: 5 }}>
            <Icon style={{ color: color }} iconName={icon} aria-hidden="true" title={value} />
            <span>{value}</span>
        </Stack>
    );
}
