import React, { Component } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { Callout, DirectionalHint } from "office-ui-fabric-react/lib/Callout";
import { FocusTrapZone } from "office-ui-fabric-react/lib/FocusTrapZone";
import { Label } from "office-ui-fabric-react/lib/Label";
import { RiskHeatmap } from "./RiskHeatmap";
import { RiskScore } from "./RiskScore";

const rootClassName = cx(
    "cygraph-RiskScoreField-root",
    css`
        & .input {
            box-shadow: none;
            border-width: 1px;
            border-style: solid;
            border-color: var(--black);
            border-radius: 2px;
            background: var(--white);
            padding: var(--spacing-xxxs);
            cursor: pointer;

            &.disabled {
                cursor: initial;
            }
        }
    `
);

const calloutClassName = cx(
    "cygraph-RiskScoreField-callout",
    css`
        & .cygraph-RiskHeatmap-root {
            padding-right: 20px;
        }
    `
);

export const RiskScoreField = observer(
    class RiskScoreField extends Component {
        constructor(props) {
            super(props);

            this.state = {
                showPicker: false,
                selectedScore: null,
            };
        }

        render() {
            const { required, label, disabled, score } = this.props;

            return (
                <div className={rootClassName}>
                    <div className="wrapper">
                        <Label required={required}>{label}</Label>
                        <div className={cx("input", disabled && "disabled")} onClick={(e) => this._onClick(e)} ref={(el) => (this._buttonElement = el)}>
                            <RiskScore {...this.props} hideTooltip />
                        </div>
                        {!disabled && this.state.showPicker && (
                            <Callout
                                isBeakVisible={false}
                                className={calloutClassName}
                                gapSpace={0}
                                doNotLayer={false}
                                target={this._buttonElement}
                                directionalHint={DirectionalHint.bottomLeftEdge}
                                onDismiss={() => this._onDismiss()}
                                setInitialFocus={true}
                            >
                                <FocusTrapZone firstFocusableSelector="cygraph-RiskHeatmap-root" isClickableOutsideFocusTrap={true}>
                                    <RiskHeatmap size="mini" title={label} disabled={disabled} score={score} onChange={(score) => this._onChange(score)} />
                                </FocusTrapZone>
                            </Callout>
                        )}
                    </div>
                </div>
            );
        }

        _onChange(score) {
            const { onChange } = this.props;
            if (onChange) {
                onChange(score);
                this._onDismiss();
            } else {
                this.setState((prevState) => {
                    prevState.selectedScore = score;
                    prevState.showPicker = false;
                    return prevState;
                });
            }
        }

        _onClick(event) {
            this.setState((prevState) => {
                prevState.showPicker = !prevState.showPicker;
                return prevState;
            });
        }

        _onDismiss() {
            this.setState((prevState) => {
                prevState.showPicker = false;
                return prevState;
            });
        }
    }
);
