import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { cx, css } from 'emotion'

import Moment from 'react-moment';

import { ObservablePagination } from '../../../base/components/ObservablePagination';
import { BoxPanel } from '../../../base/components/BoxPanel';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { MessageBar } from 'office-ui-fabric-react/lib/MessageBar';
import { IconButton, Stack } from 'office-ui-fabric-react';
import { DetailsListLayoutMode, Selection, SelectionMode, ConstrainMode } from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { ContextualMenu } from 'office-ui-fabric-react/lib/ContextualMenu';
import { Persona, PersonaSize } from 'office-ui-fabric-react/lib/Persona';
import { PrincipalPickerStore } from '../../stores/PrincipalPickerStore';
import { GroupsContextualMenu } from './GroupsContextualMenu';

export const GroupGridView = 
    class GroupGridView extends Component {
        constructor(props) {
            super(props);
            this.state = {
              showContextualMenu: null
            };
        }

        get _columns() {
          const { onEditClick } = this.props;
          return [
          {
            key: 'name',
            name: 'Group name',
            fieldName: 'name',
            minWidth: 310,
            data: 'string',
            onRender: (item) => {
              return (
                <div className={cx(
                  'workitem-summary',
                  'grid-view-hover-button',
                )}>
                  <a role="button" onClick={() => this.props.onEditClick(item.id)}>{item.name}</a>
                    {this.state.showContextualMenu === item.id ? ( <GroupsContextualMenu
                          groupId={item.id}
                          onEditClick={onEditClick}
                      />)
                    : null}
                </div>
              );
            },
            isPadded: true
          },
        ];
        }

        render () {
          const { onPageChange, keywords, groups, loading, pagination } = this.props;
          return (
            <>
              <BoxPanel shadow >
                { groups && <ShimmeredDetailsList
                  items={groups}
                  compact={false}
                  columns={this._columns}
                  selectionMode={SelectionMode.none}
                  getKey={this._getKey}
                  setKey="single"
                  layoutMode={DetailsListLayoutMode.justified}
                  constrainMode={ConstrainMode.horizontalConstrained}
                  isHeaderVisible={true}
                  onRenderRow={(props, defaultRender) => <div 
                    onMouseEnter={() => {this.setState({showContextualMenu:props.item.id})}}
                    onMouseLeave={() => {this.setState({showContextualMenu:null})}}
                    >{defaultRender(props)}</div> }
                  enterModalSelectionOnTouch={true}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  checkButtonAriaLabel="Row checkbox"
                  enableShimmer={loading}
                  removeFadingOverlay={true}
                  shimmerLines={25}
                  ariaLabelForShimmer="Groups being fetched"
                  ariaLabelForGrid="Group list"
                />}
                
                { ! loading && ! groups.length && (
                  <MessageBar>
                    There are no matching groups.
                  </MessageBar>
                )}                
              </BoxPanel>
              <ObservablePagination
                {...pagination}
                onPageChange={(index) => onPageChange(index, keywords)}
              />
            </>
          );
        }

        _getKey(item, index) {
            return item ? item.id : index;
        }

    };