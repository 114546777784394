import React from 'react';
import { 
    SearchBox as FabricSearchBox,
} from 'office-ui-fabric-react/lib/SearchBox';

const SearchBox = (props) => {
    return (
        <FabricSearchBox 
            {...props}
        />
    );
}

export { SearchBox }