import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';

import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

import { ObservableSeparator } from '../../../base/components/ObservableSeparator';

export const ImageViewerPartEditor = observer(
  class ImageViewerPartEditor extends PureComponent {
    render () {
      const { formData, audiences } = this.props;

      return (
          <div>
              <r-grid columns="2">
                  <r-cell span="2">
                      <ObservableSeparator>Image Settings</ObservableSeparator>
                  </r-cell>
                  <r-cell span="2">
                      <div>
                          <TextField 
                              label="Image URL"
                              required
                              value={formData.properties.src}
                              onChange={(ev, val) => formData.properties.src = val} 
                          />
                      </div>
                  </r-cell>
                  <r-cell span="2">
                      <div>
                          <TextField 
                              label="Image Alternate Text"
                              value={formData.properties.alt}
                              onChange={(ev, val) => formData.properties.alt = val} 
                          />
                      </div>
                  </r-cell>
                  <r-cell>
                      <div>
                          <Dropdown
                              required
                              label="Image Fit"
                              selectedKey={formData.properties.imageFit}
                              onChange={(ev, op) => {
                                  if(op) {
                                    formData.properties.imageFit = op.key;
                                  }
                              }}
                              options={[
                                  {
                                      key: 'none',
                                      text: 'None'
                                  },
                                  {
                                      key: 'center',
                                      text: 'Center'
                                  },
                                  {
                                      key: 'contain',
                                      text: 'Contain',
                                  },
                                  {
                                      key: 'cover',
                                      text: 'Cover',
                                  },
                                  {
                                      key: 'centerCover',
                                      text: 'Center Cover'
                                  },
                                  {
                                      key: 'centerContain',
                                      text: 'Center Contain',
                                  }
                              ]}/>     
                      </div>
                  </r-cell>
              </r-grid>
          </div>
      );
    }
  }
);
