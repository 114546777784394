import { observable, flow, action } from "mobx";

import { WorkflowService } from "../../../api/workflows";
import { WorkflowStore } from "@modules/workflows/stores/WorkflowStore";

export class WorkflowSelectionStore {
    public parentStore: WorkflowStore;
    public workflowService: WorkflowService;

    @observable public id: string = null;
    @observable public instance: any = null;
    @observable public loading: boolean = false;

    constructor(parentStore: WorkflowStore) {
        this.parentStore = parentStore;
        this.workflowService = parentStore.workflowService;
    }

    @action
    public setSelected(instance) {
        this.instance = instance;
    }

    public loadActivityInstance = flow(function* loadTask(id: string) {
        if (this.id !== id || !this.instance) {
            this.loading = true;
            this.id = id;
            this.instance = null;
        }

        try {
            this.instance = yield this.workflowService.getTaskDefinition(id);
            return this.instance;
        } finally {
            this.loading = false;
        }
    });
}
