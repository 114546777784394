import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";

import { Stack } from "office-ui-fabric-react/lib/Stack";
import { PanelType } from "office-ui-fabric-react/lib/Panel";
import { PrimaryButton } from "office-ui-fabric-react/lib/Button";

import { ObservablePanel } from "@baseComponents/ObservablePanel";

import { ControlMapAxisEditForm } from "../../components/maps/ControlMapAxisEditForm";

export const ControlMapAxisEditPanel = inject(
    "controlStore",
    "routing"
)(
    withRouter(
        observer(
            class ControlMapAxisEditPanel extends Component {
                componentWillMount() {
                    const { controlStore } = this.props;

                    // controlStore.mapEditStore.loadControls({ cache: true });
                }

                componentWillReceiveProps(nextProps) {
                    const { controlStore } = nextProps;

                    // controlStore.mapEditStore.loadControls({ cache: false });
                }

                render() {
                    const { controlStore } = this.props;
                    const { mapEditStore, optionsStore } = controlStore;
                    const { selectedAxis, loading, isValid, controls } = mapEditStore;

                    return (
                        <>
                            <ObservablePanel
                                pageType={`Edit Control Map Axis`}
                                panelType={PanelType.medium}
                                hidden={!selectedAxis}
                                onClosePanel={() => this._onCancel(false)}
                                onDismiss={() => this._onCancel(true)}
                                onRenderFooter={this._renderFooter}
                            >
                                {selectedAxis && (
                                    <ControlMapAxisEditForm
                                        formData={selectedAxis}
                                        isValid={isValid}
                                        loading={loading}
                                        options={optionsStore}
                                        controls={controls}
                                        onAxisUpdated={mapEditStore.onAxisUpdated}
                                        onAxisRemoved={mapEditStore.onAxisRemoved}
                                    />
                                )}
                            </ObservablePanel>
                        </>
                    );
                }

                _renderFooter = () => {
                    const { controlStore } = this.props;
                    const { mapEditStore } = controlStore;
                    return (
                        <Stack horizontal horizontalAlign="space-between">
                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <PrimaryButton
                                    onClick={() => this._onSave()}
                                    text="Done"
                                    disabled={!mapEditStore.isAxisValid}
                                />
                            </Stack>
                            <Stack horizontal tokens={{ childrenGap: 20 }}></Stack>
                        </Stack>
                    );
                };

                _onSave() {
                    const { controlStore, onDone, onAfterDone } = this.props;
                    const { mapEditStore } = controlStore;
                    const { selectedAxis } = mapEditStore;

                    if (onDone) {
                        Promise.resolve(onDone(selectedAxis)).then((axis) => {
                            if (onAfterDone) {
                                Promise.resolve(onAfterDone(axis || selectedAxis)).then(() =>
                                    mapEditStore.setSelectedAxis(null)
                                );
                            } else {
                                mapEditStore.setSelectedAxis(null);
                            }
                        });
                    } else {
                        mapEditStore.setSelectedAxis(null);
                    }
                }

                _onCancel(dismiss) {
                    const { controlStore, onCancel } = this.props;
                    const { mapEditStore } = controlStore;

                    if (onCancel) {
                        Promise.resolve(onCancel(dismiss)).then(() => mapEditStore.setSelectedAxis(null));
                    } else {
                        mapEditStore.setSelectedAxis(null);
                    }
                }
            }
        )
    )
);
