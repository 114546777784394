import { observable, flow, computed } from "mobx";

import { PrincipalContextStore } from "../../identity/stores/PrincipalContextStore";
import { isUserInAnyRoles } from "../../identity/stores/PrincipalContextUtilities";

import { RiskService } from "../../../api/risks";
import { RiskSelectionStore } from "./RiskSelectionStore";
import { RiskPermissionRoles } from "./RiskPermissionStore";
import { RiskStore } from "./RiskStore";

export class RiskRegistryStore {
    public parentStore: RiskStore;
    public selectionStore: RiskSelectionStore;
    public riskService: RiskService;
    public principalContext: PrincipalContextStore;

    @observable public registries: any[] = [];
    @observable public loading: boolean = false;
    @observable public error: any;
    @observable public riskRegistriesRoles: any[] = [
        {
            key:  null,
            text: 'Nothing',
            allowedFor: [
                "portfolioContactAccess",
                "lifecycleContactAccess",
                "assignedToGroupAccess",
            ],
        },
        {
            key:  'Risk.Reader',
            text: 'Read',
            allowedFor: [
                "portfolioContactAccess",
                "lifecycleContactAccess",
                "assignedToGroupAccess",
            ],

        },
        {
            key: 'Risk.Contributor',
            text: 'Contribute',
            allowedFor: [
                "portfolioContactAccess",
                "lifecycleContactAccess",
                "assignedToGroupAccess",
            ],

        },
        {
            key:  'Risk.Owner',
            text: 'Review & Submit​',
            allowedFor: [
                "assignedToUserAccess",
                "assignedToGroupAccess",
            ],
        },
        {
            key:  'Risk.Approver',
            text: 'Approve',
            allowedFor: [
                "assignedToUserAccess",
            ],
        },
    ]
    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
        this.selectionStore = parentStore.selectionStore;
        this.principalContext = parentStore.rootStore.principalContext;
    }

    @computed
    public get requestorRegistries() {
        const user = this.principalContext.current;
        const risk = this.selectionStore.risk;

        if (!risk || !user || !this.registries) {
            return [];
        }

        return this.registries.filter(
            (r) =>
                r.policies.requestorRoles != null &&
                (isUserInAnyRoles(user, r.policies.requestorRoles) ||
                    RiskPermissionRoles.Reader === r.policies.guestRequestorAccess ||
                    RiskPermissionRoles.Reader === r.policies.guestRequestorAccess)
        );
    }

    @computed
    public get hasRequestorRegistries() {
        return this.requestorRegistries.length > 0;
    }

    public loadRegistries = flow(function* (query: any) {
        const options = { ...{ cached: false }, ...query };
        if (options.cached && this.registries.length > 0) {
            return;
        }

        this.loading = true;
        this.registries = [];

        try {
            this.registries = yield this.riskService.getRegistrySummary();
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.loading = false;
        }
    });
}
