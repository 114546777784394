import React from "react";
import { observer } from "mobx-react";

import { Stack } from "@ui/elements/Stack";
import { Shimmer, ShimmerElementType } from "@ui/elements/Shimmer";

export const CombinedSearchShimmer = observer(({ className, includeRole }) => {
    return (
        <div className={className}>
            <Stack horizontal tokens={{ childrenGap: 15 }}>
                <Stack.Item>
                    <Shimmer width="40px" shimmerElements={[{ type: ShimmerElementType.line, height: 40 }]} />
                </Stack.Item>
                <Stack.Item grow>
                    <Stack grow tokens={{ childrenGap: 5 }}>
                        <Shimmer width="65%" shimmerElements={[{ type: ShimmerElementType.line, height: 14 }]} />
                        <Shimmer width="45%" shimmerElements={[{ type: ShimmerElementType.line, height: 10 }]} />
                        <Shimmer width="25%" shimmerElements={[{ type: ShimmerElementType.line, height: 10 }]} />
                    </Stack>
                </Stack.Item>
                {includeRole && (
                    <Stack.Item>
                        <Shimmer width="90px" shimmerElements={[{ type: ShimmerElementType.line, height: 10 }]} />
                    </Stack.Item>
                )}
            </Stack>
        </div>
    );
});
