import React, { Component } from "react";
import { observer, inject } from "mobx-react";

import { BoxPanel } from "../../../base/components/BoxPanel";
import { ModuleTreeView } from "../../components/modules/ModuleTreeView";
import { AdvisoryEditPanel } from "../panels/AdvisoryEditPanel";

export const PatternModulesTab = inject(
    "modellingStore",
    "patternStore",
    "routing"
)(
    observer(
        class PatternModulesTab extends Component {
            componentWillMount() {
                const { modellingStore, routing } = this.props;
            }

            componentWillReceiveProps(nextProps) {
                const { modellingStore, routing } = nextProps;
            }

            render() {
                const { patternStore } = this.props;
                const { pattern, manifest } = patternStore.selectionStore;

                return (
                    <BoxPanel shadow padding>
                        {pattern && manifest && (
                            <>
                                <ModuleTreeView
                                    pattern={pattern}
                                    manifest={manifest}
                                    onAddAdvisory={(measure) => this._onAddAdvisory(measure)}
                                    onRemoveAdvisory={(advisory) => this._onRemoveAdvisory(advisory)}
                                />

                                <AdvisoryEditPanel />
                            </>
                        )}
                    </BoxPanel>
                );
            }

            _onRemoveAdvisory(advisory) {
                const { modellingStore, patternStore } = this.props;
                const { manifest } = patternStore.selectionStore;

                modellingStore.removeAdvisory(advisory);
                modellingStore
                    .updatePattern({
                        mode: "Build",
                        notes: `Removed advisory '${advisory.title}'`,
                        entity: manifest,
                    })
                    .then((pattern) => {
                        return patternStore.selectionStore.loadPattern(pattern.id, pattern.version);
                    });
            }

            _onAddAdvisory(measure) {
                const { advisoryStore } = this.props.modellingStore;
                advisoryStore.show({ measure });
            }
        }
    )
);
