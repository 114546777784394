import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';

import { Draggable } from 'react-beautiful-dnd';

import { PageSectionMenu } from './PageSectionMenu';

const rootClassName = cx(
  'cygraph-PageSection-root',
  css`
    position: relative;

    & .page--section {
      &.none {
        background-color: var(--white);
        margin-bottom: var(--spacing-l);
      }
      &.transparent {
        background-color: transparent;
        margin-bottom: var(--spacing-l);
      }
      &.neutral {
        background-color: var(--body-background);
        color: var(--text-color-dark);
        padding: var(--spacing-l);
        margin-bottom: var(--spacing-l);
        border: 1px solid var(--border-color);
      }
      &.soft {
        background: var(--green-1);
        color: var(--text-color-dark);
        padding: var(--spacing-l);
        margin-bottom: var(--spacing-l);
        border: 1px solid var(--border-color-dark);
      }
      &.strong {
        background: var(--green-3);
        color: var(--text-color);
        padding: var(--spacing-l);
        margin-bottom: var(--spacing-l);
        border: 1px solid var(--green-7);
      }
      &.dragging {
        background-color: var(--white);
      }
    }

    & .page--section-content {
      & > r-grid > r-cell {
        display: flex;
        & > div {
          flex-grow: 1;
          display: flex;
          & > div {
            flex-grow: 1;
          }
        }
      }
    }

    & .page--section-menu {
      position: absolute;
      left: -50px;
      width: 50px;
      top: 0;
      display: none;
      height: 100%;
      /**border-right: 2px solid var(--nav-background);**/

      & .move {
        padding: 3px 8px 2px 8px;
        font-size: 16px;
        color: var(--primary-brand-color);
        &:hover {
          background-color: rgb(243, 242, 241);
        }
      }

      & .wrapper {
        width: 32px;
        background-color: var(--nav-background);
        border-radius: var(--border-radius) 0 0 var(--border-radius);
        min-height: 100px;
        float: right;
      }
    }

    &.edit {
      & .page--section {
        min-height: 100px;
        border: 1px dashed var(--border-color);

        &.dragging {
          border: 1px solid var(--border-color);
          marging-bottom: 1px;
        }
      }
    }

    &.view.hidden {
      display: none;
    }

    &:hover {
      &.edit {
        & .page--section-menu {
          display: block;
        }
      }
    }
  `
);

export const PageSection = observer(
  class PageSection extends PureComponent {
    render () {
      const { className, instance, section, index } = this.props;
      const mode = instance.lifecycleStore.mode;
      const layout = instance.parentStore.layoutStore.getLayout(section);
      return (
        <div className={cx(rootClassName, className, mode, section.hidden && 'hidden')}>
          <Draggable draggableId={section.id} index={index} type="section" isDragDisabled={mode !== 'edit'}>
              {(provided, snapshot) => (
                <div className={cx("page--section", section.theme, snapshot.isDragging && 'dragging')} 
                  {...provided.draggableProps} ref={provided.innerRef}>
                  <PageSectionMenu className="page--section-menu" instance={instance} section={section} index={index} provided={provided} />
                  <layout.control className="page--section-content" instance={instance} section={section} />
                </div>
              )}
          </Draggable>     
        </div>
      );
    }
  }
);