import { ServiceBase, Constants } from "../graph/serviceBase";
import { useStaticRendering } from "mobx-react";

export class BoardsService extends ServiceBase {
    /**
     * Get boards
     */
    public async getBoards() {
        return this.get(`${Constants.baseUrl}/boards`);
    }

    /**
     * Get a single board
     */
    public async getBoard(id: string) {
        return this.get(`${Constants.baseUrl}/boards/${id}`);
    }

    /**
     * Get work items from a board
     */
    public async getWorkItemsFromABoard(boardId: string, options: any) {
        const paginationOptions = options.pageSize
            ? `pageSize=${options.pageSize}&startIndex=${options.startIndex}&`
            : "";
        const keywordsOptions = options.keywords ? `keywords=${options.keywords}` : "";
        return this.get(`${Constants.baseUrl}/boards/${boardId}/work-items?${paginationOptions}${keywordsOptions}`);
    }
}
