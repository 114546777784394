import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Pivot, PivotItem } from 'office-ui-fabric-react/lib/Pivot';
import { Box } from './Box';

export const BoardsListDashboard = observer(
    class BoardsListDashboard extends Component {
        constructor(props) {
            super(props);

        }

        render () {
            const { loading, assignedToMe, createdByMe, assignedToMyTeam, onProductClick, onWorkItemClick, onPageChange, isGuestUser, products, onNewRequest} = this.props;
            return (
              <Pivot aria-label="Default boards">
                { !isGuestUser && (
                  <PivotItem headerText="Assigned to me">
                    {assignedToMe && <Box                       
                      data={assignedToMe}
                      dataTest={`assignedToMeContainer`}
                      onProductClick={onProductClick}
                      onWorkItemClick={onWorkItemClick}
                      onPageChange={(index)=> onPageChange('assignedToMe', index)}
                      isGuestUser={isGuestUser}
                      products={products}
                      onNewRequest={onNewRequest}
                    />}
                  </PivotItem>
                ) }                
                <PivotItem headerText="Created by me">
                  {createdByMe && <Box 
                    data={createdByMe}
                    dataTest={`CreatedByMeContainer`}
                    onProductClick={onProductClick}
                    onWorkItemClick={onWorkItemClick}
                    onPageChange={(index)=> onPageChange('createdByMe', index)}
                    isGuestUser={isGuestUser}
                    products={products}
                    onNewRequest={onNewRequest}
                  />}
                </PivotItem>
                { !isGuestUser && (
                  <PivotItem headerText="Assigned to my team">
                    {assignedToMyTeam && <Box 
                      data={assignedToMyTeam}
                      dataTest={`assignedToMyTeamContainer`}
                      onProductClick={onProductClick}
                      onWorkItemClick={onWorkItemClick}
                      onPageChange={(index)=> onPageChange('assignedToMyTeam', index)}
                      isGuestUser={isGuestUser}
                      products={products}
                      onNewRequest={onNewRequest}
                    />}
                  </PivotItem>
                ) }                
              </Pivot>
            );
        }

        _getKey(item, index) {
            return item ? item.id : index;
        }

        _getSelectionDetails() {
            const selectionCount = this._selection.getSelectedCount();
        
            switch (selectionCount) {
              case 0:
                return 'No items selected';
              case 1:
                return '1 item selected: ' + (this._selection.getSelection()[0]).name;
              default:
                return `${selectionCount} items selected`;
            }
        }

        _onColumnClick(ev, column) {
        }

        _onItemContextMenu(item, index, ev) {
            const contextualMenuProps = {
              target: ev.target,
              items: [
                {
                  key: 'text',
                  name: `${this._selection.getSelectedCount()} selected`
                }
              ],
              onDismiss: () => {
                this.setState({
                  contextualMenuProps: undefined
                });
              }
            };
        
            if (index > -1) {
              this.setState({
                contextualMenuProps: contextualMenuProps
              });
            }
        
            return false;
          };
    }
);