import { observable, flow } from "mobx";

import { PaginationStore } from "../../base/PaginationStore";
import { RiskService } from "../../../api/risks";
import { RiskStore } from "./RiskStore";

export class ExceptionBrowseStore {
    public parentStore: RiskStore;
    public riskService: RiskService;

    @observable public risks: any[] = [];
    @observable public portfolios: any[] = [];
    @observable public statusList: any[] = [];
    @observable public assignedToObjects: any[] = [];
    @observable public pagination: PaginationStore;
    @observable public keywords: string;
    @observable public query: any = {};
    @observable public loading: boolean = false;
    @observable public error: any;
    @observable public breadcrumb: any[] = [
        {
            text: "Exception Management",
            key: "exceptions",
            onClick: () => this._goToUrl(`/exceptions/dashboard`),
        },
        { text: "Browse Exceptions", key: "browse", isCurrentItem: true },
    ];

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
        this.pagination = new PaginationStore();
    }

    public loadRisks = flow(function* (query: any) {
        this.loading = true;
        const o = Object.assign({ page: 1, pageSize: 25, keywords: null }, query);

        try {
            const result = yield this.riskService.searchRisks({
                pageSize: o.pageSize,
                startIndex: (o.page - 1) * o.pageSize,
                query: {
                    ...this.query,
                    ...o.query,
                    ...{ type: { template: ["zerodai:risks:exception"] } },
                },
            });
            this.risks = result.items;
            this.keywords = result.query.keywords;
            this.query = result.query;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);

            return result;
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.loading = false;
        }
    });

    public exportRisks = flow(function* (query) {
        try {
            const options = Object.assign({ page: 1, keywords: null }, query);
            const result = yield this.riskService.exportRisks({
                pageSize: 100,
                startIndex: 0,
                query: {
                    ...this.query,
                    ...options.query,
                    ...{ type: { template: ["zerodai:risks:exception"] } },
                },
            });

            if (result) {
                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Exception export request has been queued. You will receive an email shortly when export is ready.`
                );
            }
        } catch (error) {
            console.error(error);
            this.error = error;
        }
    });

    public loadSubStatuses = flow(function* () {
        try {
            this.statusList = yield this.parentStore.riskFilterDataStore.loadSubStatuses(true);
            // console.log(this.statusList);
        } catch (e) {
            console.error(e);
            this.error = e;
            this.statusList = [];
        }
    });

    public loadPortfolios = flow(function* () {
        try {
            this.portfolios = yield this.parentStore.riskFilterDataStore.loadPortfolios();
        } catch (e) {
            console.error(e);
            this.error = e;
            this.portfolios = [];
        }
    });

    loadAssignedUser = flow(function* (ids: any = null) {
        try {
            const principals = ids ? ids : [];
            if (principals.length > 0) {
                this.assignedToObjects = yield this.parentStore.rootStore.pickerStore.loadPrincipals(
                    principals.map((U) => U.id)
                );
            } else {
                this.assignedToObjects = [];
            }
        } catch (e) {
            this.assignedTo = [];
            console.error(e);
        }
    });

    _goToUrl = (url) => {
        if (url) {
            this.parentStore.rootStore.routing.push(url);
        }
    };
}
