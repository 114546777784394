import React, { Component } from "react";
import { observer } from "mobx-react";

import { TextField } from "office-ui-fabric-react/lib/TextField";

import { BigTextField } from "../../../base/components/BigTextField";

export const PropertySectionEditForm = observer(
    class PropertySectionEditForm extends Component {
        render() {
            const { formData } = this.props;

            return (
                <div>
                    <r-grid columns="6">
                        <r-cell span="6">
                            <div>
                                <BigTextField
                                    label="Name"
                                    required
                                    placeholder="Please enter a section title"
                                    value={formData.name}
                                    transparent
                                    onChange={(ev, value) => (formData.name = value)}
                                />
                            </div>
                        </r-cell>
                        <r-cell span="3">
                            <div>
                                <TextField
                                    label="Order"
                                    required
                                    type="number"
                                    min={0}
                                    max={100}
                                    step={1}
                                    value={formData.order}
                                    onChange={(ev, value) => (formData.order = value)}
                                />
                            </div>
                        </r-cell>
                    </r-grid>
                </div>
            );
        }
    }
);
