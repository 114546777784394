import React, { PureComponent } from 'react';
import { inject, observer } from 'mobx-react';
import { cx, css } from 'emotion';
import { saveSvgAsPng } from 'save-svg-as-png';
import { json2csvAsync } from 'json-2-csv';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { IconButton } from 'office-ui-fabric-react/lib/Button';

const calloutProps = { gapSpace: 0 };
const hostStyles = { root: { display: 'inline-block' } };

export const QuickActionsViewerActions = (props) => {
      const { className, instance, part, themeProvider } = props;
      return (
        <div className={cx(className, 'page--part-action')}>
        </div>
      );
    };
