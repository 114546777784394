import React from "react";
import { observer } from "mobx-react";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { AdaptiveField } from "@modules/base/components/AdaptiveField";
import { FieldSetView } from "@modules/base/components/FieldSetView";

export const IdentityGeneralTab = observer(() => {
    return (
        <BoxPanel shadow padding style={{ minHeight: 500 }}>
            <FieldSetView title="Engagement" description="Basic engagement statistics from across the platform.">
                Coming soon.
            </FieldSetView>
        </BoxPanel>
    );
});
