import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import { MeasureEditItem } from './MeasureEditItem';

export const MeasureEditList = observer(
    class MeasureEditList extends Component {

        componentDidMount() {
            window.addEventListener('scroll', this._handleScroll);
        }
        
        componentWillUnmount() {
            window.removeEventListener('scroll', this._handleScroll);
        }

        render () {
            const { assessment, module, values, disabled, isReadOnly, commentCount, onMeasureChange, onCommentsTriggered } = this.props;
            let index = 1;

            return (
                <div className="form__questions__wrapper">
                    { module && values && module.groups.map((group) => {
                        return (
                            <Fragment key={group.id}>
                            {
                                group.measures && group.measures.map((measure) => {
                                    return (
                                        this._isEnabled(assessment, measure, {values}) && values[measure.name] ?
                                        <MeasureEditItem
                                            key={measure.id}
                                            assessment={assessment}
                                            values={values}
                                            measure={measure} 
                                            index={index++} 
                                            disabled={isReadOnly || disabled[measure.name]}
                                            commentCount={commentCount}
                                            onMeasureChange={onMeasureChange}
                                            onCommentsTriggered={onCommentsTriggered} /> : null
                                    );
                                })
                            }
                            </Fragment>
                        );
                    }) }
                </div>
            );
        }

        goTo(measure) {
            const el = document.getElementById(measure.id);
            if(el) {
                window.scrollTo({ behavior: 'smooth', top: el.offsetTop });
            }
        }

        _isEnabled(assessment, measure, context) {
            if(!measure.enabled) {
                return true;
            }

            return eval(measure.enabled);
        }

        _handleScroll = (event) => {
            const { module, onScrollToMeasure } = this.props;
            const scrollTop = event.srcElement.documentElement.scrollTop;

            for(let i = 0; i < module.groups.length; i++) {
                let group = module.groups[i];
                for(let j = 0; j < group.measures.length; j++) {
                    let measure = group.measures[j];

                    const el = document.getElementById(measure.id);
                    if(el && el.offsetTop >= (scrollTop - el.offsetHeight)) {
                        onScrollToMeasure(measure);
                        return;
                    }
                }
            }
        }
    }
);