import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { AgeIndicator } from '../../../base/components/AgeIndicator';

export const WorkItemAgeIndicator = observer(
    class WorkItemAgeIndicator extends Component {

        render () {
            const { className, workItem } = this.props;
            if(!workItem) {
                return null;
            }
            return (
                <AgeIndicator className={className} creationDate={workItem.created} startDate={workItem.started} closedDate={workItem.closed} />
            );
        }
    }
);