import React, { useCallback, useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";
import { cx, css } from "emotion";

import { Text } from "@ui/elements/Text";
import { Stack } from "@ui/elements/Stack";
import { Shimmer, ShimmerElementType } from "@ui/elements/Shimmer";

import { TruncatedText } from "@modules/base/components/TruncatedText";
import { BoxPanel } from "@modules/base/components/BoxPanel";
import { RoutingButton } from "@modules/base/components/RoutingButton";
import { RoutingLink } from "@modules/base/components/RoutingLink";
import { ObservableSeparator } from "@modules/base/components/ObservableSeparator";

import { TaskResponseEditDialog } from "@modules/workflows/containers/dialogs/TaskResponseEditDialog";
import { TaskDefinitionEditPanel } from "@modules/workflows/containers/panels/TaskDefinitionEditPanel";

const rootClassName = cx(
    "cygraph-SimpleTaskListView-root",
    css`
        min-height: 400px;
        padding: 15px 25px 20px !important;

        & .task {
            border-bottom: 1px solid var(--border-color);
            margin-bottom: var(--spacing-s);
            padding-bottom: var(--spacing-s);
        }
        & .task:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }

        & .viewAll {
            width: 100%;
            border: 1px solid var(--border-color);
        }
    `
);

export const SimpleTaskListView = observer(({ className }) => {
    const { taskStore } = useStores();
    const { loading, tasks, pagination } = taskStore.browseStore;

    const reloadMyTasks = useCallback(() => {
        taskStore.browseStore.loadMyTasks({ pageSize: 5, query: { status: ["Pending"] } });
    }, []);

    const onRespond = useCallback((task) => {
        taskStore.lifecycleStore.onRespond({}, { task }).then((result) => {
            if (result.success) {
                reloadMyTasks();
            }
        });
    }, []);

    const onEdit = useCallback((task) => {
        taskStore.lifecycleStore.onEdit({}, { task }).then((result) => {
            if (result.success) {
                reloadMyTasks();
            }
        });
    }, []);

    useEffect(() => {
        reloadMyTasks();
    }, []);

    return (
        <BoxPanel className={cx(rootClassName, className)} shadow padding small>
            <Stack tokens={{ childrenGap: 15 }} className="wrapper">
                <Stack.Item className="row--xxs">
                    <ObservableSeparator>My Tasks</ObservableSeparator>
                </Stack.Item>
                <Stack.Item grow>
                    {loading &&
                        Array.from({ length: 5 }).map((v, i) => (
                            <div key={i} className="task">
                                <Stack grow tokens={{ childrenGap: 5 }}>
                                    <Shimmer
                                        width="80%"
                                        className="row--xxxs"
                                        shimmerElements={[{ type: ShimmerElementType.line, height: 14 }]}
                                    />
                                    <Shimmer
                                        width="90%"
                                        shimmerElements={[{ type: ShimmerElementType.line, height: 10 }]}
                                    />
                                    <Shimmer
                                        width="65%"
                                        shimmerElements={[{ type: ShimmerElementType.line, height: 10 }]}
                                    />
                                </Stack>
                            </div>
                        ))}
                    {!loading && tasks.length === 0 && <div>You have no tasks assigned to you.</div>}
                    {!loading &&
                        tasks.map((task) => (
                            <div key={task.id} className="task">
                                <Text variant="medium" block className="row--xxxs">
                                    <RoutingLink onClick={() => onRespond(task)} onClickEnabled={true}>
                                        {task.title}
                                    </RoutingLink>
                                </Text>

                                <Text variant="small" block style={{ color: "var(--text-color-lightest)" }}>
                                    <TruncatedText text={task.description} words={30} html />
                                </Text>
                            </div>
                        ))}
                </Stack.Item>
                <Stack.Item>
                    {!loading && pagination.hasMorePages && (
                        <RoutingButton className="viewAll" url="/account/tasks">
                            View all
                        </RoutingButton>
                    )}
                </Stack.Item>
            </Stack>
            <TaskDefinitionEditPanel />
            <TaskResponseEditDialog onEdit={onEdit} />
        </BoxPanel>
    );
});
