import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion'
import Moment from 'react-moment';

import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

export const MeasureEditItem = observer(
    class MeasureEditItem extends Component {
        
        render () {
            const { assessment, measure, index, disabled, completed, active, commentCount, onMeasureChange, onCommentsTriggered } = this.props;

            return (
                <div id={measure.id} className={cx('form__question', disabled && 'form__question--disabled')}>
                    <div className="form__question__details">
                    <span className="form__question__count">{String(index).padStart(2, '0')}</span>
                    <div className="form__question__decorator">
                        <div className="form__question__label">{measure.title}</div>
                        { measure.description && (
                            <div className="form__question__description">{measure.description}</div>
                        ) }
                        <div className="form__response__wrapper">
                            { this._renderQuestion() }
                        </div>
                    </div>
                    <div className="form__question__btns__wrapper no-print">
                        <button className="btn btn--secondary btn--block show__comments__modal"
                            onClick={() => onCommentsTriggered({ 
                                assessmentId: assessment.id,
                                uniqueId: measure.id,
                                title: measure.title
                            })}>
                            {commentCount && commentCount.references[measure.id] || 0} 
                            <Icon iconName="SkypeMessage" />
                        </button>
                    </div>
                    </div>
                </div>
            );
        }

        _renderQuestion() {
            const { measure } = this.props;

            switch(measure.type) {
                case 'Choice':
                    return this._renderChoice();
                case 'MultiChoice':
                    return this._renderMultiChoice();
                case 'Boolean':
                    return this._renderBoolean();
                case 'Text':
                    return this._renderText();
                default:
                    return null;
            }
        }

        _renderText() {
            const { measure, values, disabled, index, completed, active, onMeasureChange } = this.props;

            return (
                <div>
                    <TextField 
                        placeholder={measure.placeholder} 
                        multiline={measure.multiline}
                        autoAdjustHeight
                        disabled={disabled}
                        autoComplete="off"
                        defaultValue={values[measure.name].value}                    
                        onBlur={(ev) => {
                            onMeasureChange(measure, { value: ev.target.value, completed: true });
                        }} />
                </div>
            );
        }        

        _renderBoolean() {
            const { measure, values, disabled, index, completed, active, onMeasureChange } = this.props;

            return (
                <div className="form__group form__group--flex--row">
                    { measure.label && (
                        <label className="form__label form__label--large">{measure.label}</label>
                    ) }
                    <ul className="radio__input__btns__list">
                        <li className="radio__input__btns__list__item">
                            <input type="radio" id={measure.name + 'Yes'} 
                                name={measure.name} className="radio__input__btn"
                                disabled={disabled}
                                checked={values[measure.name].value === true}
                                onChange={(e) => { 
                                    onMeasureChange(measure, { value: true, completed: true });
                                }} />
                            <label htmlFor={measure.name + 'Yes'} className="radio__input__btn__label">Yes</label>
                        </li>
                        <li className="radio__input__btns__list__item">
                            <input type="radio" id={measure.name + 'No'} 
                                name={measure.name} className="radio__input__btn"
                                disabled={disabled}
                                checked={values[measure.name].value === false}
                                onChange={(e) => { 
                                    onMeasureChange(measure, { value: false, completed: true });
                                }} />
                            <label htmlFor={measure.name + 'No'} className="radio__input__btn__label">No</label>
                        </li>
                    </ul>
                </div>
            );
        }

        _renderMultiChoice() {
            const { measure, values, disabled, index, completed, active, onMeasureChange } = this.props;

            return (
                measure.options.map(option => {
                    const value = values[measure.name].value;

                    return (
                        <div className="form__group form__group--flex--row" key={option.key}>
                            { option.text && (
                                <label className="form__label form__label--large">{option.text}</label>
                            ) }
                            <ul className="radio__input__btns__list">
                                <li className="radio__input__btns__list__item">
                                    <input type="radio" id={measure.name + option.key + 'Yes'} 
                                        name={measure.name + option.key} className="radio__input__btn"
                                        disabled={disabled}
                                        checked={value && value.indexOf(option.key) != -1}
                                        onChange={(e) => {
                                            if(!value) {
                                                onMeasureChange(measure, { value: [option.key], completed: true });
                                            } else if(value.indexOf(option.key) == -1) {
                                                value.push(option.key);
                                                onMeasureChange(measure, { value: value, completed: true });
                                            }
                                        }} />
                                    <label htmlFor={measure.name + option.key + 'Yes'} className="radio__input__btn__label">Yes</label>
                                </li>
                                <li className="radio__input__btns__list__item">
                                    <input type="radio" id={measure.name + option.key + 'No'} 
                                        name={measure.name + option.key} className="radio__input__btn"
                                        disabled={disabled}
                                        checked={value && value.indexOf(option.key) == -1}
                                        onChange={(e) => { 
                                            if(!value) {
                                                onMeasureChange(measure, { value: [], completed: true });
                                            } else if(value.indexOf(option.key) != -1) {
                                                value.splice(value.indexOf(option.key), 1);
                                                onMeasureChange(measure, { value: value, completed: true });
                                            }
                                        }} />
                                    <label htmlFor={measure.name + option.key + 'No'} className="radio__input__btn__label">No</label>
                                </li>
                            </ul>
                        </div>
                    );
                })
            );
        }

        _renderChoice() {
            const { measure, values, disabled, index, completed, active, onMeasureChange } = this.props;

            return (
                measure.options.map(option => {
                    const value = values[measure.name].value;

                    return (
                        <div className="form__group form__group--flex--row" key={option.key}>

                            <ul className="radio__input__btns__list">
                                <li className="radio__input__btns__list__item">
                                    <input type="radio" id={measure.name + option.key} 
                                        name={measure.name} className="radio__input__btn"
                                        disabled={disabled}
                                        checked={value == option.key}
                                        onChange={(e) => { 
                                            onMeasureChange(measure, { value: option.key, completed: true });
                                        }} />
                                    <label htmlFor={measure.name + option.key} className="radio__input__btn__label">{option.text}</label>
                                </li>
                            </ul>
                        </div>
                    );
                })
            );
        }
    }
);