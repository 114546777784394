import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion'

import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator';

const rootClassName = cx(
    'cygraph-AdvisoryProgress-root',
    css`
        background: #fff;
        border-bottom: 0;
        height: 2px;
        & .ms-ProgressIndicator-itemProgress {
            padding: 0;
        }
    `
);

export const AdvisoryProgress = observer(
    class AdvisoryProgress extends Component {
        
        render () {
            const { advisories } = this.props;

            const totalCount = advisories.length;
            const closedCount = advisories.filter(a => a.state == 'Closed').length;

            return (
                <div className={cx(rootClassName, 'animated fadeIn')}>
                    <ProgressIndicator percentComplete={(closedCount) / totalCount } />
                </div>
            );
        }
    }
);