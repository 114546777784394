import React, { Component } from "react";
import { observer } from "mobx-react";
import Moment from "react-moment";

import { Link } from "office-ui-fabric-react/lib/Link";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { Toggle } from "office-ui-fabric-react/lib/Toggle";
import { ActionButton } from "office-ui-fabric-react/lib/Button";
import { ChoiceGroup } from "office-ui-fabric-react/lib/ChoiceGroup";
import { DatePicker } from "office-ui-fabric-react/lib/DatePicker";
import { Dropdown } from "office-ui-fabric-react/lib/Dropdown";

import { PortfolioPicker } from "../../../portfolios/containers/pickers/PortfolioPicker";
import { AssetPicker } from "../../../assets/containers/pickers/AssetPicker";
import { PrincipalPicker } from "../../../identity/containers/PrincipalPicker";

export const TriageFormField = observer(
    class TriageFormField extends Component {
        render() {
            const { readOnly } = this.props;

            if (readOnly) {
                return this._renderViewMode();
            } else {
                return this._renderEditMode();
            }
        }

        _renderViewMode = () => {
            const { field, section, page, configuration, formData, formOptions, onHelp } = this.props;

            if (!field || !field.type) {
                return null;
            }

            switch (field.type) {
                case "User":
                    return formData[field.name] ? formData[field.name].name : null;
                case "Portfolio":
                    return formData[field.name] ? formData[field.name].name : null;
                case "Asset":
                    return formData[field.name] ? formData[field.name].name : null;
                case "Toggle":
                    if (typeof formData[field.name] === "undefined") {
                        return null;
                    }
                    return formData[field.name] ? field.onText || "Yes" : field.offText || "No";
                case "Text":
                    return (
                        <>
                            {formData[field.name] &&
                                formData[field.name].split("\n").map((p, i) => {
                                    return <p key={i}>{p}</p>;
                                })}
                        </>
                    );
                case "Date":
                    return formData[field.name] ? <Moment date={formData[field.name]} format={field.format} /> : null;
                case "Choice":
                    if (!formData[field.name]) {
                        return null;
                    }

                    return field.options.find((o) => o.key === formData[field.name]).text;
            }

            return null;
        };

        _renderEditMode = () => {
            const { field, section, page, configuration, formData, formOptions, onHelp } = this.props;

            if (!field || !field.type) {
                return null;
            }

            switch (field.type) {
                case "User":
                    return (
                        <PrincipalPicker
                            label={field.label}
                            required={field.required}
                            selected={formData[field.name]}
                            data-test={field.name}
                            onSelected={(principal) => {
                                formData[field.name] = principal;
                                this._onFieldUpdate();
                            }}
                        />
                    );
                case "Portfolio":
                    return (
                        <PortfolioPicker
                            label={field.label}
                            required={field.required}
                            placeholder={field.placeholder}
                            selected={formData[field.name]}
                            data-test={field.name}
                            onChange={(portfolio) => {
                                formData[field.name] = portfolio;
                                this._onFieldUpdate();
                            }}
                        />
                    );
                case "Asset":
                    const isNewKey = `${field.name}-isNew`;
                    if (!formOptions[isNewKey]) {
                        return (
                            <div className="row--xs">
                                <AssetPicker
                                    className="row--xxxs"
                                    label={field.label}
                                    required={field.required}
                                    placeholder={field.placeholder}
                                    selectedId={formData[field.name] ? formData[field.name].id : null}
                                    data-test={field.name}
                                    onChange={(system) => {
                                        formData[field.name] = system;
                                        formOptions[isNewKey] = system ? false : formOptions[isNewKey];
                                        this._onFieldUpdate();
                                    }}
                                />

                                <Link
                                    data-test="newSystemToggle"
                                    onClick={() => {
                                        formData[field.name] = null;
                                        formOptions[isNewKey] = true;
                                        this._onFieldUpdate();
                                    }}
                                >
                                    {field.newLinkLabel || "Can't find the app or system? Create new!"}
                                </Link>
                            </div>
                        );
                    } else {
                        return (
                            <div className="row--xs">
                                <TextField
                                    data-test="newSystemTextField"
                                    className="row--xxxs"
                                    label={field.label}
                                    required={field.required}
                                    value={formData[field.name] ? formData[field.name].name : null}
                                    onChange={(ev, name) => {
                                        formData[field.name] = { name };
                                    }}
                                />

                                <Link
                                    onClick={() => {
                                        formData[field.name] = null;
                                        formOptions[isNewKey] = false;
                                    }}
                                >
                                    Lookup an existing app or system
                                </Link>
                            </div>
                        );
                    }
                case "Toggle":
                    return (
                        <Toggle
                            onText={field.onText || "Yes"}
                            offText={field.offText || "No"}
                            label={field.label}
                            data-test={field.name}
                            required={field.required}
                            defaultChecked={field.defaultValue}
                            checked={formData[field.name]}
                            onChange={(ev, v) => {
                                formData[field.name] = v;
                                this._onFieldUpdate();
                            }}
                        />
                    );
                case "Text":
                    return (
                        <TextField
                            label={field.label}
                            required={field.required}
                            multiline={field.multiline}
                            autoAdjustHeight={field.autoAdjustHeight}
                            value={formData[field.name]}
                            data-test={field.name}
                            onChange={(ev, v) => {
                                formData[field.name] = v;
                                this._onFieldUpdate();
                            }}
                        />
                    );
                case "Date":
                    return (
                        <DatePicker
                            label={field.label}
                            isRequired={field.required}
                            placeholder={field.placeholder}
                            ariaLabel={field.ariaLabel}
                            value={formData[field.name]}
                            data-test={field.name}
                            onSelectDate={(date) => {
                                formData[field.name] = date;
                                this._onFieldUpdate();
                            }}
                        />
                    );
                case "Choice":
                    if (field.format && field.format === "radio-group") {
                        return (
                            <ChoiceGroup
                                data-test={field.name}
                                label={field.label}
                                required={field.required}
                                options={field.options.map((op) => ({
                                    key: op.key,
                                    text: op.text,
                                    onRenderField: !op.helpProps
                                        ? null
                                        : (props, render) => {
                                              return (
                                                  <div className="actions">
                                                      {render(props)}
                                                      <ActionButton
                                                          data-test={`${field.name}${op.key}`}
                                                          iconProps={{ iconName: op.helpProps.iconName || "InfoSolid" }}
                                                          onClick={(ev) => onHelp(op.helpProps, field, op)}
                                                      >
                                                          {op.helpProps.label || "See examples"}
                                                      </ActionButton>
                                                  </div>
                                              );
                                          },
                                }))}
                                selectedKey={formData[field.name]}
                                onChange={(ev, op) => {
                                    formData[field.name] = op ? op.key : null;
                                    this._onFieldUpdate();
                                }}
                            />
                        );
                    } else if (field.format && field.format === "dropdown") {
                        return (
                            <Dropdown
                                label={field.label}
                                data-test={field.name}
                                required={field.required}
                                options={field.options}
                                selectedKey={formData[field.name]}
                                onChange={(ev, op) => {
                                    formData[field.name] = op ? op.key : null;
                                    this._onFieldUpdate();
                                }}
                            />
                        );
                    }

                    return (
                        <ChoiceGroup
                            label={field.label}
                            data-test={field.name}
                            required={field.required}
                            options={field.options}
                            selectedKey={formData[field.name]}
                            onChange={(ev, op) => {
                                formData[field.name] = op ? op.key : null;
                                this._onFieldUpdate();
                            }}
                        />
                    );
            }

            return null;
        };

        _onFieldUpdate = () => {
            const { field, formData, formOptions } = this.props;

            if (field.onUpdate) {
                field.onUpdate.forEach((update) => {
                    if (update.action) {
                        if (update.action === "set") {
                            if (update.path && formData[field.name]) {
                                formData[update.field] = formData[field.name][update.path];
                            } else if (typeof update.value !== "undefined") {
                                formData[update.field] = update.value;
                            } else {
                                formData[update.field] = formData[field.name];
                            }
                        }
                    }
                });
            }
        };
    }
);
