import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";

import { css } from "@uifabric/utilities/lib/css";
import { Pivot, PivotItem } from "office-ui-fabric-react/lib/Pivot";

import { AppPage } from "../../../layout/containers/AppPage";
import { BoxPanel } from "../../../base/components/BoxPanel";
import { SettingsListNav } from "../../../settings/containers/utilities/SettingsListNav";

import { ProductHeader } from "../../components/core/ProductHeader";
import { ProductSummary } from "../../components/core/ProductSummary";
import { SchemaSummary } from "../../components/schema/SchemaSummary";
import { ProductEditPanel } from "../../containers/panels/ProductEditPanel";

export const ProductViewerPage = inject(
    "pageContext",
    "routing",
    "productStore"
)(
    withRouter(
        observer(
            class ProductViewerPage extends Component {
                componentWillMount() {
                    const { match, productStore } = this.props;
                    productStore.viewerStore.loadProduct(match.params.id);
                }

                componentWillReceiveProps(nextProps) {
                    const { match, productStore } = nextProps;
                    productStore.viewerStore.loadProduct(match.params.id);
                }

                render() {
                    const { viewerStore, editFormStore } = this.props.productStore;
                    const { loading, error, breadcrumb, product, schema, selectedTab } = viewerStore;

                    return (
                        <AppPage
                            loading={loading}
                            error={error}
                            breadcrumb={breadcrumb}
                            hasSidebar={false}
                            className={css("products viewer", !error && "has--page-nav")}
                        >
                            <SettingsListNav />
                            <div>
                                {product && (
                                    <ProductHeader
                                        product={product}
                                        canEdit={() => true}
                                        onEdit={() => editFormStore.show({ product })}
                                    />
                                )}

                                <Pivot
                                    aria-label="Product details and related content"
                                    selectedKey={selectedTab}
                                    onLinkClick={(tab) => {
                                        viewerStore.selectedTab = tab.props.itemKey;
                                    }}
                                >
                                    <PivotItem headerText="Summary" itemKey="summary" key="summary">
                                        <BoxPanel padding>
                                            <ProductSummary loading={loading} product={product} />
                                        </BoxPanel>
                                    </PivotItem>
                                    <PivotItem headerText="Schema" itemKey="schema" key="schema">
                                        <BoxPanel padding>
                                            <SchemaSummary loading={loading} schema={schema} />
                                        </BoxPanel>
                                    </PivotItem>
                                </Pivot>
                            </div>

                            <ProductEditPanel
                                onHidePanel={() => {
                                    editFormStore.hide();
                                }}
                                onAfterSave={() => {
                                    editFormStore.hide();
                                }}
                            />
                        </AppPage>
                    );
                }
            }
        )
    )
);
