import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { SearchBox } from '@ui/elements/SearchBox';
import { CommandBar } from '@ui/elements/CommandBar';
import { BoxPanel } from '@baseComponents/BoxPanel';

export const ControlMapAxisCommandBar = inject(
    "controlStore"
) (observer(
    class ControlMapAxisCommandBar extends Component {

        componentWillMount() {
            const { controlStore } = this.props;
            controlStore.mapEditStore.loadControls({ cache: true });
        }

        render () {
            const { axes, controlStore, keywords, onSearchChange, onAddAxis, onAddControlAxis } = this.props;
            const { mapEditStore, optionsStore } = controlStore;
            const { selectedAxis, loading, isValid, controls } = mapEditStore;

            const items = [];

            items.push({
                key: 'addGenericAxis',
                text: 'Add Generic Axis',
                ariaLabel: 'Add Generic Axis',
                iconProps: { iconName: 'Add' },
                onClick: () => onAddAxis({ name: 'New Axis' })
            });

            items.push({
                key: 'addControlAxis',
                text: 'Add Control Axis',
                ariaLabel: 'Add Control Axis',
                iconProps: { iconName: 'Add' },
                // onRender: (item) => {
                //     return <ControlHierarchyPicker 
                //         controls={controls}
                //         // onControlSearch={onControlSearch}
                //         onControlPicked={(control) => onAddControlAxis(control)}
                //     />
                // }
                subMenuProps: {
                    items: controls.map(control => ({
                        key: control.id,
                        text: control.name,
                        //canCheck: true,
                        //  isChecked: !!axes.find(a => a.id == control.id),
                        onClick: () => onAddControlAxis(control),
                    })),
                },
            });

            const farItems = [                 
                {
                    key: 'search',
                    text: 'Search',
                    onRender: () => {
                        return (
                        <SearchBox
                            value={keywords}
                            placeholder="Search by axis or control name"
                            styles={{ root: { height: 'auto', minWidth: '270px' }}}
                            onClear={() => onSearchChange && onSearchChange(null)}
                            onChange={(ev, val) => onSearchChange && onSearchChange(val)}
                            onSearch={onSearchChange} />
                        );
                    }
                }
            ];

            return (
                <BoxPanel shadow attached>
                    <CommandBar
                        styles={{ root: { paddingRight: '0px' } }}
                        items={items}
                        farItems={farItems}
                    />
                </BoxPanel>
            );
        }
    }
));