import React from "react";
import { v4 as uuidv4 } from "uuid";

import { Stack } from "office-ui-fabric-react/lib/Stack";
import { IconButton } from "office-ui-fabric-react/lib/Button";
import { ContextualMenuItemType } from "office-ui-fabric-react/lib/ContextualMenu";

import { GhostIconButton } from "../../../base/components/GhostIconButton";

export function ModuleIndexGroupMenu({
    manifest,
    module,
    group,
    dragHandleProps,
    onAddMeasure,
    onAddGroup,
    onEditGroup,
    onRemoveGroup,
    onDuplicateGroup,
    onMoveToModule,
}) {
    return (
        <Stack horizontal>
            <Stack.Item grow>{group.title}</Stack.Item>
            <Stack horizontal token={{ childrenGap: 5 }}>
                <IconButton
                    ariaLabel="Add question"
                    role="menuitem"
                    styles={{ menuIcon: { fontSize: "16px" } }}
                    menuIconProps={{ iconName: "Add" }}
                    menuProps={{
                        items: [
                            {
                                key: "Text",
                                text: "Text",
                                onClick: () =>
                                    onAddMeasure({
                                        module,
                                        group,
                                        measure: {
                                            title: "New text based question",
                                            type: "Text",
                                        },
                                    }),
                            },
                            {
                                key: "Boolean",
                                text: "Yes/No",
                                onClick: () =>
                                    onAddMeasure({
                                        module,
                                        group,
                                        measure: {
                                            title: "Yes/No question",
                                            type: "Boolean",
                                        },
                                    }),
                            },
                            {
                                key: "Choice",
                                text: "Choice",
                                onClick: () =>
                                    onAddMeasure({
                                        module,
                                        group,
                                        measure: {
                                            title: "New choice question",
                                            type: "Choice",
                                            options: [
                                                {
                                                    key: uuidv4(),
                                                    text: "New option",
                                                },
                                            ],
                                        },
                                    }),
                            },
                            {
                                key: "MultiChoice",
                                text: "Multi Choice",
                                onClick: () =>
                                    onAddMeasure({
                                        module,
                                        group,
                                        measure: {
                                            title: "New multi choice question",
                                            type: "MultiChoice",
                                            display: "CheckboxList",
                                            options: [
                                                {
                                                    key: uuidv4(),
                                                    text: "New option",
                                                },
                                            ],
                                        },
                                    }),
                            },
                        ],
                    }}
                    title="Add question"
                    ariaLabel="Add question"
                />
                <GhostIconButton iconProps={{ iconName: "Move" }} title="Move group" ariaLabel="Move group" dragHandleProps={dragHandleProps} />
                <IconButton
                    ariaLabel="More items"
                    role="menuitem"
                    styles={{ menuIcon: { fontSize: "16px", fontWeight: "bold" } }}
                    menuIconProps={{ iconName: "MoreVertical" }}
                    menuProps={{
                        items: [
                            {
                                key: "Edit group",
                                text: "Edit group",
                                iconProps: {
                                    iconName: "Edit",
                                },
                                onClick: () => onEditGroup({ module, group }),
                            },
                            {
                                key: "Duplicate group",
                                text: "Duplicate group",
                                iconProps: {
                                    iconName: "Copy",
                                },
                                onClick: () => onDuplicateGroup({ module, group }),
                            },
                            {
                                key: "Add group after",
                                text: "Add group after",
                                iconProps: {
                                    iconName: "NewFolder",
                                },
                                onClick: () => onAddGroup({ module, group, location: "after" }),
                            },
                            {
                                key: "Move group to",
                                text: "Move group to",
                                disabled: manifest.modules.length <= 1,
                                iconProps: {
                                    iconName: "MoveToFolder",
                                },
                                subMenuProps: {
                                    items: manifest.modules
                                        .filter((m) => m.id !== module.id)
                                        .map((m) => ({
                                            key: m.id,
                                            text: m.title,
                                            onClick: () => onMoveToModule({ module, group, destination: m, location: "end" }),
                                        })),
                                },
                            },
                            {
                                key: "divider1",
                                itemType: ContextualMenuItemType.Divider,
                            },
                            {
                                key: "Remove group",
                                text: "Remove group",
                                iconProps: {
                                    iconName: "Delete",
                                },
                                onClick: () => onRemoveGroup({ module, group }),
                            },
                        ],
                    }}
                />
            </Stack>
        </Stack>
    );
}
