import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router';

import { useStores } from '../../../../hooks/useStores';

import { AppPage } from '../../../layout/containers/AppPage';
import { ObservablePagination } from '../../../base/components/ObservablePagination';
import { SettingsListNav } from '../../../settings/containers/utilities/SettingsListNav';
import { RiskTypeNewPanel } from '../panels/RiskTypeNewPanel';
import { RiskTypesCommandBar } from '../../components/core/RiskTypesCommandBar';
import { RiskTypeGridView } from '../../components/core/RiskTypeGridView';

export const RiskTypesSettingsPage = withRouter(observer((props) => {
    const { routing, pageContext, riskStore } = useStores();
    const { typeStore, riskTypeNewFormStore } = riskStore;
    const { breadcrumb, loading, pagination, error, keywords } = typeStore;
    pageContext.updateQueryStringParams(routing.location.search);

    useEffect(()=>{
        typeStore.loadTypes({
            page: pageContext.queryStringParams.p || 1,
            keywords: pageContext.queryStringParams.k
        })
    }, []);

    return (
        <AppPage 
            loading={loading} 
            error={error} 
            breadcrumb={breadcrumb}
            hasSidebar={false}
            className="products list has--page-nav">
            <SettingsListNav />
            <>
                <RiskTypesCommandBar 
                    onNewRiskType={() => {
                        riskTypeNewFormStore.show();
                    }}
                    keywords={keywords}
                    onSearchChange={(keywords) => { 
                        
                        routing.push(`/settings/risk-types${keywords ? `?k=${encodeURIComponent(keywords)}` : ''}`);
                    }} />

                <RiskTypeGridView 
                    riskTypes={riskStore.typeStore.types}
                    loading={loading}
                />

                <ObservablePagination 
                    {...pagination}
                    onPageChange={(index) => { 
                        routing.push(`/settings/risk-types?p=${index + 1}${pageContext.queryStringParams.k ? `&k=${encodeURIComponent(pageContext.queryStringParams.k)}` : ''}`);
                    }}
                />
                <RiskTypeNewPanel 
                    onSaveNewRiskType={() => {
                        typeStore.loadTypes({
                            page: pageContext.queryStringParams.p || 1,
                            keywords: pageContext.queryStringParams.k
                        })
                    }}
                />
            </>
        </AppPage>
    );
}));
