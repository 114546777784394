import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router';
import { cx, css } from 'emotion';

import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { PanelType } from 'office-ui-fabric-react/lib/Panel';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { ObservablePanel } from '../../../base/components/ObservablePanel';
import { ErrorDialog } from '../../../base/components/dialogs/ErrorDialog';

export const EditUserPanel = inject('principalContext','routing')(withRouter(observer(
    class EditUserPanel extends Component {

        constructor(props) {
            super(props);
            this.state = {
                showErrorDialog: false,
            };
        }

        componentWillMount() {
            this.loadData(this.props);
          }
  
        componentWillReceiveProps(nextProps) {
            this.loadData(nextProps);
        }

        async loadData(data) {
            const { principalContext } = data;
            const { editUserStore } = principalContext;
            editUserStore.loadFormData();
        }

        onRoleChange(item) {
            const { principalContext } = this.props;
            const { editUserStore } = principalContext;
            if (item.selected) {
                editUserStore.formData.roles.push(item.key)
            } else {
                editUserStore.formData.roles.splice(editUserStore.formData.roles.indexOf(item.key),1)
            }
        }

        onGroupChange(item) {
            const { principalContext } = this.props;
            const { editUserStore } = principalContext;
            const selectedGroup = editUserStore.groups.find(G => G.id === item.key);
            if (item.selected) {
                editUserStore.groupsToAdd.add(item.key);
                editUserStore.groupsToRemove.delete(item.key);
                editUserStore.formData.groups.push(selectedGroup);
            } else {
                editUserStore.groupsToRemove.add(item.key);
                editUserStore.groupsToAdd.delete(item.key);
                editUserStore.formData.groups.splice(editUserStore.formData.groups.findIndex(G => G.id === selectedGroup.id),1);
            }
        }

        get selectedRoles() {
            const { principalContext } = this.props;
            const { editUserStore } = principalContext;
            let selectedKeys = []
            if (editUserStore.formData.roles && editUserStore.formData.roles.length > 0) {
                selectedKeys = editUserStore.formData.roles;
            }
            return selectedKeys;
        }

        get selectedGroups() {
            const { principalContext } = this.props;
            const { editUserStore } = principalContext;
            let selectedGroups = []
            if (editUserStore.formData.groups && editUserStore.formData.groups.length > 0) {
                selectedGroups = editUserStore.formData.groups.map(group => { return group.id });
            }
            return selectedGroups;
        }

        get groups() {
            const { principalContext } = this.props;
            const { editUserStore } = principalContext;
            return editUserStore.groups.map(group => {return {key:group.id, text:group.name}})
        }

        render () {
            const { showErrorDialog, errorDialogTitle, errorDialogMessage } = this.state;
            const { principalContext, userId } = this.props;
            const { editUserStore } = principalContext;
            return (
                <>
                    <ObservablePanel
                        pageType={`Edit user: ${editUserStore.formData.name}`}
                        panelType={PanelType.medium}
                        onClosePanel={() => this._onHidePanel()}
                        onRenderFooter={() => this._renderFooter()}>
                            <Stack vertical>
                                {showErrorDialog && (<MessageBar
                                    messageBarType={MessageBarType.error}
                                    isMultiline={false}
                                    onDismiss={()=> this._onDismissErrorDialog}
                                    dismissButtonAriaLabel="Close"
                                >
                                    {errorDialogMessage}
                                </MessageBar>)}
                                <Text variant="medium">Name: {editUserStore.formData.name}</Text>
                                <Text>Job Title: {editUserStore.formData.jobTitle}</Text>
                                <Text>Email: {editUserStore.formData.email}</Text>
                                <Dropdown
                                    placeholder="Select roles"
                                    label="Select user's roles"
                                    selectedKeys={this.selectedRoles}
                                    onChange={(event, item) => this.onRoleChange(item)}
                                    multiSelect
                                    options={editUserStore.parentStore.roles.map(role => {return {key:role.key, text:role.text}})}
                                />
                                <Dropdown
                                    placeholder="Select teams"
                                    label="Select user's teams"
                                    selectedKeys={this.selectedGroups}
                                    onChange={(event, item) => this.onGroupChange(item)}
                                    multiSelect
                                    options={this.groups}
                                />
                            </Stack>
                    </ObservablePanel>
                </>
            );
        }

        _renderFooter() {
            const { principalContext } = this.props;
            const { editUserStore } = principalContext;
            return (
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <PrimaryButton onClick={() => this._onSaveUser()} iconProps={{iconName: 'Save'}}  text="Save" disabled={!editUserStore.isValid} />
                    <DefaultButton onClick={() => this._onHidePanel()} iconProps={{iconName: 'Cancel'}}  text="Cancel" />
                </Stack>
            );
        }

        async _onSaveUser() {
            const { principalContext, onSaveSuccess} = this.props;
            const { editUserStore } = principalContext;
            try {
                await editUserStore.saveUser();
                if (onSaveSuccess) onSaveSuccess()
                this._onHidePanel();
            } catch (e) {
                this.setState({
                    showErrorDialog: true,
                    errorDialogMessage: `There was a problem saving the changes for the user: ${editUserStore.formData.name}, please try again`,
                    errorDialogTitle: 'Error while saving the user',
                })
            }
        }

        _onDismissErrorDialog() {
            this.setState({
                showErrorDialog: false,
            })
        }

        _onHidePanel() {
            const { principalContext } = this.props;
            const { editUserStore } = principalContext;
            editUserStore.closeForm()
        }
    }
)));