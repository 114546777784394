import { observable, action, computed, flow, toJS } from "mobx";

import { RiskService } from "../../../api/risks";

import { isNotNullOrEmpty } from '../../editors/components/RichTextField';
import { RiskStore } from "./RiskStore";

export type SectionName = 'Risk' | 'Review';

export class RiskTransferStore {
    private riskService: RiskService;
    public parentStore: RiskStore;

    @observable public visible: boolean = false;
    @observable public formData: any;
    @observable public formOptions: any;
    @observable public section: SectionName = 'Risk';
    @observable public allowReview: boolean = false;
    @observable public loading: boolean = false;
    @observable public saving: boolean = false;
    @observable public error: any = null;
    @observable public isDialogVisible: boolean = false;

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
    }

    @computed
    public get isValid() {
        if(!this.formData){
            return false;
        }

        const review = this.formData.review;
        return this.isSectionValid && (!this.allowReview || (review && review.grossScore.score && review.netScore.score && 
                review.treatments && review.treatments.length == 1 && review.treatments[0].type && isNotNullOrEmpty(review.treatments[0].description) &&
                isNotNullOrEmpty(review.grossScore.description) && isNotNullOrEmpty(review.netScore.description) ));
    }

    @computed
    public get isSectionValid() {
        if(!this.formData){
            return false;
        }

        return this.formData.id && this.formData.title && this.formData.registry && this.formData.registry.id
            && isNotNullOrEmpty(this.formData.description) && isNotNullOrEmpty(this.formData.context);
    }

    @action
    public setDialogVisible(visible) {
        this.isDialogVisible = visible;
    }

    @action
    public nextSection() {
        if(this.allowReview && this.section == 'Risk') {
            this.section = 'Review';
        }
    }

    @action
    public previousSection() {
        if(this.allowReview && this.section == 'Review') {
            this.section = 'Risk';
        }
    }

    @action
    public show(options) {
        this.visible = true;
        this.allowReview = options.allowReview;
        this.section = 'Risk';

        if(this.formData && !options.clearData) {
            return;
        }

        this.formOptions = {
            currentRegistry: options.risk.registry,
            checkDataLoss: options.checkDataLoss,
            allowSubmit: options.allowSubmit,
        };

        let current = options.review;
        if(current) {
            current = toJS(current);
            current.nextReviewDue = null;
        }

        this.formData = {
            id: options.risk.id,
            registry: null,
            title: options.risk.title,
            description: options.risk.description,
            context: options.risk.context,
            review: !this.allowReview ? null : current
        }
    }

    @action
    public hide(options) {
        this.visible = false;

        if(options && options.clearData) {
            this.formData = null;
            this.formOptions = null;
        }
    }

    public onTransfer = flow(function* () {
        const { risk } = this.parentStore.selectionStore;
        
        this.show({ 
            review: risk.review, 
            risk: risk, 
            allowReview: true,
            allowSubmit: true,
            clearData: true
        });
    });

    // @action
    // public transferRisk(risk) {
    //     this.saving = true;
    //     this.error = null;
    //     return this.riskService.transferRisk(risk)
    //         .then(this.onStatusChangeOnSuccess, this.onStatusChangeOnError);
    // }

    // @action.bound
    // public onStatusChangeOnSuccess(result) {        
    //     this.saving = false;
    //     return result;
    // }

    // @action.bound
    // public onStatusChangeOnError(error) {
    //     this.error = error;
    //     this.saving = false;
    //     return error;
    // }
}
