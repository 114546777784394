import { observable, flow, computed, action } from "mobx";

import { EntityService } from "../../../api/entities";
import { EntityStore } from "./EntityStore";

export class EntityViewerStore {
    private parentStore: EntityStore;
    private entityService: EntityService;

    @observable public loading: boolean = false;
    @observable public error: any;
    @observable public selectedTab: string;

    constructor(parentStore: EntityStore) {
        this.parentStore = parentStore;
        this.entityService = parentStore.entityService;
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [];

        builder.push({ text: "Entity Management", key: "entities", onClick: () => this.parentStore.rootStore.routing.push(`/entities/dashboard`) });
        builder.push({ text: "Browse Entities", key: "entities-browse", onClick: () => this.parentStore.rootStore.routing.push(`/entities/browse`) });

        if (!this.entity) {
            return builder;
        }

        builder.push({
            text: this.entity.name,
            key: this.entity.id,
            isCurrentItem: true,
        });

        return builder;
    }

    @computed
    public get entity() {
        return this.parentStore.selectionStore.entity;
    }

    @action
    public setEntity(entity) {
        this.parentStore.selectionStore.setEntity(entity);
    }

    @action
    public setSelectedTab(tab) {
        this.selectedTab = tab;
    }

    public loadEntity = flow(function* (id: string, tab: string) {
        this.error = null;
        this.selectedTab = tab;

        if (!this.entity || this.entity.id !== id) {
            this.loading = true;
            try {
                yield this.parentStore.selectionStore.loadEntity(id);
            } catch (error) {
                console.error(error);
                this.error = error;
            } finally {
                this.loading = false;
            }
        }
    });
}
