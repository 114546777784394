import React from "react";
import { observer } from "mobx-react";

import { CommandBar } from "office-ui-fabric-react/lib/CommandBar";
import { BoxPanel } from "@modules/base/components/BoxPanel";

import { ExceptionFilterBar } from "@modules/risks/components/exceptions/ExceptionFilterBar";

export const ExceptionCommandBar = observer(
    ({ onNewRisk, onRefresh, onExport, filterQuery, onFilterChange, filterContext }) => {
        const items = [
            {
                key: "newRisk",
                text: "New",
                iconProps: { iconName: "Add" },
                onClick: () => onNewRisk(),
            },
        ];

        if (onRefresh) {
            items.push({
                key: "refresh",
                text: "Refresh",
                iconProps: { iconName: "Refresh" },
                onClick: onRefresh,
            });
        }

        const farItems = [];

        if (filterQuery && onFilterChange && filterContext) {
            farItems.push({
                key: "filterBar",
                text: "Filter Bar",
                onRender: () => {
                    return <ExceptionFilterBar value={filterQuery} onChange={onFilterChange} context={filterContext} />;
                },
            });
        }

        if (onExport) {
            farItems.push({
                key: "export",
                name: "Export to CSV",
                iconOnly: true,
                iconProps: { iconName: "ExcelDocument" },
                onClick: onExport,
            });
        }

        return (
            <BoxPanel shadow>
                <CommandBar styles={{ root: { paddingRight: "0px" } }} items={items} farItems={farItems} />
            </BoxPanel>
        );
    }
);
