import React from "react";
import { observer } from "mobx-react";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { AdaptiveField } from "@modules/base/components/AdaptiveField";
import { FieldSetView } from "@modules/base/components/FieldSetView";

export const AssuranceGeneralTab = observer(() => {
    return (
        <BoxPanel shadow padding style={{ minHeight: 500 }}>
            <FieldSetView title="Management" description="Basic information about this assurance request.">
                <r-grid columns="2" class="no--margin">
                    <r-cell>
                        <AdaptiveField type="Principal" mode="view" label="Primary Assurance Manager" />
                    </r-cell>
                    <r-cell>
                        <AdaptiveField type="Principal" mode="view" label="Secondary Assurance Manager" />
                    </r-cell>
                </r-grid>
            </FieldSetView>
            <FieldSetView title="Planning" description="Basic information about this demand request.">
                <r-grid columns="2" class="no--margin">
                    <r-cell>
                        <AdaptiveField
                            type="Text"
                            mode="view"
                            disabled
                            label="Demand Type"
                            value="Security Assurance"
                        />
                    </r-cell>
                    <r-cell span="2">
                        <AdaptiveField
                            type="Toggle"
                            mode="view"
                            label="Evidence is required for all advisories"
                            disabled
                            value={true}
                            onText="Yes"
                            offText="No"
                        />
                    </r-cell>
                </r-grid>
            </FieldSetView>
            <FieldSetView
                title="Default Notifications"
                description={
                    <>
                        Default notifications which apply to all assessments in the module. This approach is now{" "}
                        <strong>deprecated</strong> and will be replaced by demand module level automations.
                    </>
                }
            >
                <r-grid columns="2" class="no--margin"></r-grid>
            </FieldSetView>
        </BoxPanel>
    );
});
