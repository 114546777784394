import React, { PureComponent } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { Text } from "office-ui-fabric-react/lib/Text";
import { Stack } from "office-ui-fabric-react/lib/Stack";

import { ObservableBadge } from "@baseComponents/ObservableBadge";
import { ActionSet } from "@baseComponents/ActionSet";

const rootClassName = cx(
    "cygraph-PersonalAccountHeader-root",
    css`
        margin: var(--spacing-s) 0;

        .title {
            margin: var(--spacing-xs) 0;
        }

        & .description {
            color: var(--text-color-light);
            font-size: var(--font-size-xs);
        }
    `
);

export const PersonalAccountHeader = observer(
    class PersonalAccountHeader extends PureComponent {
        render() {
            const { user, title, subTitle } = this.props;
            const { items, overflowItems } = this._getActionSetItems();

            return (
                <div className={rootClassName}>
                    <Stack horizontal horizontalAlign="space-between">
                        <ObservableBadge className="badge">{user.name}</ObservableBadge>
                        <ActionSet items={items} overflowItems={overflowItems} />
                    </Stack>
                    <Text className="title" variant="xLarge" block>
                        {title}
                    </Text>
                    {subTitle && (
                        <Text className="title" variant="xSmall" block>
                            {subTitle}
                        </Text>
                    )}
                </div>
            );
        }

        _getActionSetItems() {
            const { user } = this.props;

            const items = [];
            const overflowItems = [];

            return { items, overflowItems };
        }
    }
);
