import { observable, action, computed, flow } from "mobx";

import { PrincipalContextStore } from "./PrincipalContextStore";
import { IdentityService } from "../../../api/identity";

export class PrincipalEditUserStore {
    public parentStore: PrincipalContextStore;
    private identityService: IdentityService;

    @observable public visible: boolean = false;
    @observable public loading: boolean = false;
    @observable public saving: boolean = false;
    @observable public formData: any = {};
    @observable public groups: any[] = [];
    @observable public groupsToAdd: any = new Set();
    @observable public groupsToRemove: any = new Set();
    @observable public userId: string = "";

    constructor(parentStore: PrincipalContextStore, identityService: IdentityService) {
        this.parentStore = parentStore;
        this.identityService = identityService;
    }

    @computed public get isValid() {
        return this.userId && (this.formData.groupsToAdd || this.formData.groupsToRemove || this.formData.roles)
            ? true
            : false;
    }

    @action public closeForm() {
        this.visible = false;
        this.userId = "";
        this.groupsToAdd = new Set();
        this.groupsToRemove = new Set();
    }

    loadFormData = flow(function* () {
        this.loading = true;
        try {
            if (this.userId.length === 36) {
                this.groupsToAdd = new Set();
                this.groupsToRemove = new Set();
                const user = yield this.identityService.getPrincipal(this.userId);
                const usersGroups = yield this.identityService.getUserGroups({
                    userId: this.userId,
                    pageSize: 50,
                    startIndex: 0,
                    keywords: "",
                });
                const loadedGroups = yield this.identityService.searchPrincipalsGroups({
                    pageSize: 500,
                    startIndex: 0,
                });
                this.formData = user;
                this.formData.groups = usersGroups.items;
                this.groups = loadedGroups.items;
            }
            this.loading = false;
        } catch (e) {
            console.error(e);
            this.loading = false;
            throw e;
        }
    });

    saveUser = flow(function* () {
        if (this.isValid) {
            try {
                yield this.identityService.updateUser({
                    ...this.formData,
                    groups: (this.formData.groups || []).map((g) => g.id),
                });
                if (this.groupsToAdd.size > 0) {
                    for (let groupId of this.groupsToAdd) {
                        yield this.identityService.addUserToGroup(this.userId, groupId);
                    }
                }
                if (this.groupsToRemove.size > 0) {
                    for (let groupId of this.groupsToRemove) {
                        yield this.identityService.removeUserFromGroup(this.userId, groupId);
                    }
                }
                this.groupsToAdd = new Set();
                this.groupsToRemove = new Set();
            } catch (e) {
                console.error(e);
                throw e;
            }
        }
    });
}
