import React, { Component } from "react";
import { observer } from "mobx-react";

import Moment from "react-moment";

import { Text } from "office-ui-fabric-react/lib/Text";
import { MessageBar } from "office-ui-fabric-react/lib/MessageBar";
import { DetailsListLayoutMode, Selection, SelectionMode, ConstrainMode } from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { ContextualMenu } from "office-ui-fabric-react/lib/ContextualMenu";
import { Persona, PersonaSize } from "office-ui-fabric-react/lib/Persona";

import { BoxPanel } from "../../../base/components/BoxPanel";
import { RoutingLink } from "../../../base/components/RoutingLink";
import { WrappedContent } from "../../../base/components/WrappedContent";
import { PhotoPersona } from "../../../identity/containers/utilities/PhotoPersona";

import { RiskScore } from "../reviews/RiskScore";

export const RiskGridView = observer(
    class RiskGridView extends Component {
        constructor(props) {
            super(props);

            this.state = {};

            this._selection = new Selection({
                onSelectionChanged: () => {
                    this.setState({
                        selectionDetails: this._getSelectionDetails(),
                    });
                },
            });
        }

        render() {
            const { risks, loading, className, onRiskClick, disableSelection, query } = this.props;
            const { contextualMenuProps } = this.state;

            const columns = [
                {
                    key: "name",
                    name: "Name",
                    fieldName: "name",
                    minWidth: 310,
                    isRowHeader: true,
                    isResizable: true,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return (
                            <WrappedContent>
                                <RoutingLink
                                    url={`/risks/browse/${item.id}`}
                                    onClick={() => this.props.onRiskClick && this.props.onRiskClick(item)}
                                    onClickEnabled={this.props.onRiskClick}
                                >
                                    {item.code} - {item.title}
                                </RoutingLink>
                                <br />
                                {item.target.type && (
                                    <Text variant="small">
                                        {item.type.name} <span>for</span>{" "}
                                        <RoutingLink url={`/assets/browse/${item.target.id}`}>
                                            {item.target.name}
                                        </RoutingLink>
                                        {item.created && (
                                            <span>
                                                {item.trigger == "Manual" || item.trigger == "Assessment"
                                                    ? " manually"
                                                    : " automatically"}{" "}
                                                created <Moment date={item.created} fromNow />
                                            </span>
                                        )}
                                    </Text>
                                )}
                            </WrappedContent>
                        );
                    },
                    isPadded: true,
                },
                {
                    key: "portfolio",
                    name: "Portfolio",
                    fieldName: "portfolio",
                    minWidth: 80,
                    maxWidth: 110,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return <span>{item.portfolio.name}</span>;
                    },
                    isPadded: true,
                },
                {
                    key: "grossScore",
                    name: "Gross Risk",
                    fieldName: "grossScore",
                    minWidth: 105,
                    maxWidth: 150,
                    isResizable: false,
                    isSorted:
                        query && query.sort && query.sort.length && query.sort[0].property == "grossScoreCalculated",
                    isSortedDescending: true,
                    sortAscendingAriaLabel: "Sorted A to Z",
                    sortDescendingAriaLabel: "Sorted Z to A",
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return (
                            <RiskScore
                                score={item.review ? item.review.grossScore : null}
                                size="mini"
                                maxImpact={4}
                                maxLikelihood={4}
                            />
                        );
                    },
                    isPadded: true,
                },
                {
                    key: "netScore",
                    name: "Net Risk",
                    fieldName: "netScore",
                    minWidth: 105,
                    maxWidth: 150,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return (
                            <RiskScore
                                score={item.review ? item.review.netScore : null}
                                size="mini"
                                maxImpact={4}
                                maxLikelihood={4}
                            />
                        );
                    },
                    isPadded: true,
                },
                /* {
                  key: 'treatments',
                  name: 'Treatments',
                  fieldName: 'treatments',
                  minWidth: 105,
                  maxWidth: 150,
                  isResizable: false,
                  onColumnClick: this._onColumnClick,
                  data: 'string',
                  onRender: (item) => {
                    return item.review ? (
                      <div>
                        <span>{item.review.treatmentsCount} </span>
                        {item.review.treatmentEffectiveness != 'Unknown' && item.review.treatmentEffectiveness != 0 && (
                          <TreatmentEffectiveness effectiveness={item.review.treatmentEffectiveness} />
                        )}
                      </div>
                      ) : null;
                  },
                  isPadded: true
                }, */
                {
                    key: "assigned",
                    name: "Assigned",
                    fieldName: "assigned",
                    minWidth: 200,
                    maxWidth: 230,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return (
                            <PhotoPersona
                                principalId={item.lifecycle.assignedToUser ? item.lifecycle.assignedToUser.id : null}
                                size={PersonaSize.size28}
                                text={item.lifecycle.assignedToUser ? item.lifecycle.assignedToUser.name : "Unassigned"}
                                showUnknownPersonaCoin={!item.lifecycle.assignedToUser}
                                showSecondaryText={true}
                                secondaryText={item.lifecycle.assignedToGroup && item.lifecycle.assignedToGroup.name}
                            />
                        );
                    },
                    isPadded: false,
                },
                {
                    key: "status",
                    name: "Status",
                    fieldName: "status",
                    minWidth: 100,
                    maxWidth: 120,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return <WrappedContent>{item.lifecycle.subStatus}</WrappedContent>;
                    },
                    isPadded: true,
                },
                {
                    key: "created",
                    name: "Date Created",
                    fieldName: "created",
                    minWidth: 100,
                    maxWidth: 130,
                    isResizable: true,
                    // isSorted: !query || !query.sort || !query.sort.length || query.sort[0].property == "created",
                    // isSortedDescending: true,
                    // sortAscendingAriaLabel: "Sorted A to Z",
                    // sortDescendingAriaLabel: "Sorted Z to A",
                    onColumnClick: this._onColumnClick,
                    data: "date",
                    onRender: (item) => {
                        return <Moment utc date={item.created} fromNow />;
                    },
                    isPadded: true,
                },
            ];

            return (
                <BoxPanel shadow>
                    <ShimmeredDetailsList
                        items={risks}
                        compact={false}
                        columns={columns}
                        selectionMode={disableSelection ? SelectionMode.none : SelectionMode.none}
                        getKey={this._getKey}
                        setKey="single"
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.horizontalConstrained}
                        isHeaderVisible={true}
                        selection={this._selection}
                        selectionPreservedOnEmptyClick={true}
                        onItemInvoked={onRiskClick}
                        //onItemContextMenu={(item, index, ev) => this._onItemContextMenu(item, index, ev) }
                        enterModalSelectionOnTouch={true}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        checkButtonAriaLabel="Row checkbox"
                        enableShimmer={loading}
                        removeFadingOverlay={true}
                        shimmerLines={25}
                        ariaLabelForShimmer="Risks being fetched"
                        ariaLabelForGrid="Risk list"
                    />

                    {!loading && !risks.length && <MessageBar>There are no matching risks.</MessageBar>}

                    {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}
                </BoxPanel>
            );
        }

        _getKey(item, index) {
            return item ? item.id : index;
        }

        _getSelectionDetails() {
            const selectionCount = this._selection.getSelectedCount();

            switch (selectionCount) {
                case 0:
                    return "No items selected";
                case 1:
                    return "1 item selected: " + this._selection.getSelection()[0].name;
                default:
                    return `${selectionCount} items selected`;
            }
        }

        _onColumnClick(ev, column) {
            console.log(column);
        }

        _onItemContextMenu(item, index, ev) {
            const contextualMenuProps = {
                target: ev.target,
                items: [
                    {
                        key: "text",
                        name: `${this._selection.getSelectedCount()} selected`,
                    },
                ],
                onDismiss: () => {
                    this.setState({
                        contextualMenuProps: undefined,
                    });
                },
            };

            if (index > -1) {
                this.setState({
                    contextualMenuProps: contextualMenuProps,
                });
            }

            return false;
        }
    }
);
