import React from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { Text } from "office-ui-fabric-react/lib/Text";
import { Stack } from "office-ui-fabric-react/lib/Stack";

import { ObservableBadge } from "@modules/base/components/ObservableBadge";
import { ActionSet } from "@modules/base/components/ActionSet";

import { StateFlowMockToolBarHost } from "@modules/stateflows/components/preview/StateFlowMockToolBarHost";

const rootClassName = cx(
    "cygraph-StateFlowMockHeader-root",
    css`
        margin: var(--spacing-s) 0;

        .title {
            margin: var(--spacing-xs) 0;
        }

        & .description {
            color: var(--text-color-light);
            font-size: var(--font-size-xs);
        }

        & .back {
            width: 45px;
            & i {
                font-size: 30px;
                height: 30px;
                line-height: 30px;
                margin-right: 10px;
            }
        }
    `
);

export const StateFlowMockHeader = observer(({ mock, triggers, conditions, onTrigger }) => {
    const items = [];
    const overflowItems = [];

    triggers
        .filter((t) => t.location == "Default" || t.location == "Quicklaunch")
        .forEach((trigger) => {
            items.push({
                key: trigger.id,
                name: trigger.name,
                primary: trigger.variant == "Primary",
                iconOnly: trigger.iconProps && trigger.iconProps.iconOnly,
                iconProps: trigger.iconProps,
                confirmProps: trigger.confirmProps,
                disabled: trigger.command.condition && (!conditions[trigger.command.condition] || !conditions[trigger.command.condition]()),
                onClick: (ev, comment) => {
                    onTrigger(
                        trigger,
                        {
                            args: trigger.command.args,
                            comment: comment && comment.length ? { content: comment } : null,
                        },
                        ev
                    );
                },
            });
        });

    triggers
        .filter((t) => t.location == "Overflow")
        .forEach((trigger) => {
            overflowItems.push({
                key: trigger.id,
                name: trigger.name,
                primary: trigger.variant == "Primary",
                iconProps: trigger.iconProps,
                confirmProps: trigger.confirmProps,
                disabled: trigger.command.condition && (!conditions[trigger.command.condition] || !conditions[trigger.command.condition]()),
                onClick: (ev, comment) => {
                    onTrigger(
                        trigger,
                        {
                            args: trigger.command.args,
                            comment: comment && comment.length ? { content: comment } : null,
                        },
                        ev
                    );
                },
            });
        });

    items.push({
        key: "upload",
        name: "Upload",
        iconOnly: true,
        iconProps: { iconName: "Upload" },
        disabled: false,
        ariaLabel: "Upload",
        onClick: () => onTrigger("Upload"),
    });

    return (
        <div className={rootClassName}>
            <Stack horizontal>
                <Stack.Item grow>
                    <Stack horizontal horizontalAlign="space-between">
                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                            <ObservableBadge>{mock.type}</ObservableBadge>
                            <StateFlowMockToolBarHost onTrigger={onTrigger} />
                        </Stack>
                        <ActionSet items={items} overflowItems={overflowItems} />
                    </Stack>
                </Stack.Item>
            </Stack>
            <Text className="title" variant="xLarge" block>
                {mock.code} - {mock.title}
            </Text>
        </div>
    );
});
