import React, { Component } from "react";
import { observer } from "mobx-react";
import Moment from "react-moment";

import { Label } from "office-ui-fabric-react/lib/Label";

import RichTextViewer from "../../../editors/components/RichTextViewer";
import { isNotNullOrEmpty } from "../../../editors/components/RichTextField";
import { TreatmentEffectiveness } from "../treatments/TreatmentEffectiveness";

import { RiskScore } from "./RiskScore";
import { RiskScoreTooltip } from "./RiskScoreTooltip";
import { RiskHeatmap } from "./RiskHeatmap";

export const ReviewSummary = observer(
    class ReviewSummary extends Component {
        render() {
            const { risk } = this.props;

            if (!risk || !risk.review) {
                return <div className="row--s">No risk reviews found</div>;
            }

            const isExceptionTemplate = risk.type && risk.type.template === "zerodai:risks:exception";
            const isControlException = isExceptionTemplate && risk.type.name === "Control Exception";

            return (
                <div>
                    <r-grid columns-l="2" columns="2" columns-s="1">
                        <r-cell span="1">
                            {isExceptionTemplate ? null : (
                                <div className="row--s">
                                    <Label>Gross Risk</Label>
                                    <div className="row--xxs">
                                        <RiskScore
                                            score={risk.review ? risk.review.grossScore : null}
                                            maxImpact={4}
                                            maxLikelihood={4}
                                        />
                                    </div>

                                    {risk.review && isNotNullOrEmpty(risk.review.grossScore.description) ? (
                                        <RichTextViewer
                                            className="description"
                                            value={risk.review.grossScore.description}
                                        />
                                    ) : (
                                        <span>No gross risk description set</span>
                                    )}
                                </div>
                            )}
                            <div className="row--s">
                                <Label>Net Risk</Label>
                                <div className="row--xxs">
                                    <RiskScore
                                        score={risk.review ? risk.review.netScore : null}
                                        maxImpact={4}
                                        maxLikelihood={4}
                                    />
                                </div>

                                {risk.review && isNotNullOrEmpty(risk.review.netScore.description) ? (
                                    <RichTextViewer className="description" value={risk.review.netScore.description} />
                                ) : (
                                    <span>No net risk description set</span>
                                )}
                            </div>
                            {isControlException && (
                                <>
                                    <div className="row--s">
                                        <Label>Risk Event Type</Label>
                                        {!risk.review || !risk.review.category ? (
                                            <span>No risk event type set</span>
                                        ) : (
                                            <span>{risk.review.category.name}</span>
                                        )}
                                    </div>
                                    <div className="row--s">
                                        <Label>Related Controls</Label>
                                        {!risk.review || !risk.review.controls || risk.review.controls.length == 0 ? (
                                            <span>No related controls</span>
                                        ) : (
                                            <span>{risk.review.controls.map((c) => c.name).join(", ")}</span>
                                        )}
                                    </div>
                                    <div className="row--s">
                                        <Label>Related Policies / Standards</Label>
                                        {!risk.review || !risk.review.policyDescription ? (
                                            <span>No related policies / standards</span>
                                        ) : (
                                            <span>{risk.review.policyDescription}</span>
                                        )}
                                    </div>
                                    <div className="row--s">
                                        <Label>Business Risk Appetite</Label>
                                        {!risk.review || !risk.review.businessAppetite ? (
                                            <span>No business risk appetite set</span>
                                        ) : (
                                            <span>{risk.review.businessAppetite}</span>
                                        )}
                                    </div>
                                </>
                            )}
                            <div className="row--s">
                                <Label>Expiry Date</Label>
                                {!risk.review || !risk.review.nextReviewDue ? (
                                    <span>No expiry date set</span>
                                ) : (
                                    <span>
                                        <Moment date={risk.review.nextReviewDue} utc format="Do MMM YYYY" />
                                    </span>
                                )}
                            </div>
                            {isExceptionTemplate ? null : (
                                <>
                                    <div className="row--s">
                                        <Label>Treatment Effectiveness</Label>
                                        <TreatmentEffectiveness
                                            effectiveness={risk.review ? risk.review.treatmentEffectiveness : null}
                                        />
                                    </div>
                                    {risk.review && (
                                        <div className="row--s">
                                            <Label>Current Treatments</Label>
                                            {risk.review.treatments.length == 0 && <div>No treatments added</div>}
                                            <div>
                                                {risk.review.treatments.map((treatment) => (
                                                    <div key={treatment.id}>
                                                        <div>{treatment.type}</div>
                                                        <RichTextViewer
                                                            className="description"
                                                            value={treatment.description}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </r-cell>
                        <r-cell span="1">
                            <RiskHeatmap
                                scores={
                                    risk.review
                                        ? isExceptionTemplate
                                            ? [
                                                  {
                                                      text: "Net",
                                                      score: risk.review.netScore,
                                                  },
                                              ]
                                            : [
                                                  {
                                                      text: "Gross",
                                                      score: risk.review.grossScore,
                                                  },
                                                  {
                                                      text: "Net",
                                                      score: risk.review.netScore,
                                                  },
                                              ]
                                        : null
                                }
                                disabled
                            />
                            {risk.type && <RiskScoreTooltip tooltip={risk.type.scopeTooltip} />}
                        </r-cell>
                    </r-grid>
                </div>
            );
        }
    }
);
