import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';

import { IconButton } from 'office-ui-fabric-react';

const rootClassName = cx(
    'cygraph-FavouriteButton-root',
    css` 
        
    `
);

export const FavouriteButton = observer(
    class FavouriteButton extends Component {
        render () {
            const { className, checked, onChange, disabled } = this.props;
            
            const title = checked ? 'Currently in watchlist' : 'Currently not in watchlist';
            const iconName = checked ? 'FavoriteStarFill' : 'FavoriteStar';

            return (
                <div
                    className={cx(className, rootClassName)}>
                    <IconButton 
                        iconProps={{ iconName: iconName }} 
                        onClick={(ev) => {
                            if(onChange) {
                                onChange(ev, !checked);
                            }
                        }}
                        title={title} ariaLabel={title} 
                        disabled={disabled} />
                </div>
            );
        }
    }
);