import React, { Component } from 'react';
import { observer } from 'mobx-react';

import * as moment from 'moment';

import { getFileTypeIconProps, FileIconType } from '@uifabric/file-type-icons';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { MessageBar } from 'office-ui-fabric-react/lib/MessageBar';
import { DetailsListLayoutMode, Selection, SelectionMode, ConstrainMode } from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { ContextualMenu } from 'office-ui-fabric-react/lib/ContextualMenu';
import { Persona, PersonaSize } from 'office-ui-fabric-react/lib/Persona';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { IconButton } from 'office-ui-fabric-react/lib/Button';

import { BoxPanel } from '../../../base/components/BoxPanel';
import { RoutingLink } from '../../../base/components/RoutingLink';
import { WrappedContent } from '../../../base/components/WrappedContent';
import { ListItemReveal } from '../../../base/components/ListItemReveal';
import { PhotoPersona } from '../../../identity/containers/utilities/PhotoPersona';
export const PageLibraryGridView = observer(
    class PageLibraryGridView extends Component {
        constructor(props) {
            super(props);

            this.state = {};

            this._selection = new Selection({
                onSelectionChanged: () => {
                  this.setState({
                    selectionDetails: this._getSelectionDetails()
                  });
                }
              });

            this._columns = [
              {
                key: 'type',
                fieldName: 'type',
                minWidth: 40,
                maxWidth: 40,
                isResizable: false,
                onColumnClick: this._onColumnClick,
                data: 'string',
                onRender: (item) => {
                  return (
                    <Icon {...getFileTypeIconProps({ size: 32, type: FileIconType.docset })}  />
                  );
                }
              },
              {
                key: 'name',
                name: 'Name',
                fieldName: 'name',
                minWidth: 200,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: 'string',
                onRender: (item) => {
                  return (
                    <WrappedContent>
                      <RoutingLink url={`/settings/page-libraries/${item.id}`} 
                        onClick={() => this.props.onPageLibraryClick && this.props.onPageLibraryClick(item)} 
                        onClickEnabled={this.props.onPageLibraryClick}>{item.name}
                      </RoutingLink>
                      <ListItemReveal>
                        { this.props.onPagePathClick && <IconButton iconProps={{ iconName: 'PageArrowRight' }} onClick={(ev) => this.props.onPagePathClick(item, ev)} /> }
                        { false && this.props.onPageDrilldownClick && <IconButton iconProps={{ iconName: 'PageList' }} onClick={(ev) => this.props.onPageDrilldownClick(item, ev)} /> }
                      </ListItemReveal>
                      <Text variant="small" block>{item.path}</Text>
                    </WrappedContent>
                    );
                },
                isPadded: true
              },
              {
                key: 'allowGuestAccess',
                name: 'Guest Access?',
                fieldName: 'allowGuestAccess',
                minWidth: 100,
                maxWidth: 130,
                isResizable: false,
                onColumnClick: this._onColumnClick,
                data: 'string',
                onRender: (item) => {
                  return (<span>{(item.allowGuestAccess ? 'Yes' : 'No')}</span>);
                },
                isPadded: true
              },
              {
                key: 'viewerRoles',
                name: 'Viewer Roles',
                fieldName: 'viewerRoles',
                minWidth: 150,
                maxWidth: 300,
                isResizable: false,
                onColumnClick: this._onColumnClick,
                data: 'string',
                onRender: (item) => {
                  return (<span>{item.viewerRoles && item.viewerRoles.length > 0 ? item.viewerRoles.join(', ') : 'none'}</span>);
                },
                isPadded: true
              },
              {
                key: 'contributorRoles',
                name: 'Contributor Roles',
                fieldName: 'contributorRoles',
                minWidth: 150,
                maxWidth: 300,
                isResizable: false,
                onColumnClick: this._onColumnClick,
                data: 'string',
                onRender: (item) => {
                  return (<span>{item.contributorRoles && item.contributorRoles.length > 0 ? item.contributorRoles.join(', ') : 'none'}</span>);
                },
                isPadded: true
              },
              {
                key: 'created',
                name: 'Created',
                fieldName: 'created',
                minWidth: 170,
                maxWidth: 220,
                isResizable: true,
                isSorted: true,
                isSortedDescending: true,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'date',
                onRender: (item) => {
                  return <PhotoPersona
                    principalId={item.createdBy ? item.createdBy.id : null}  
                    size={PersonaSize.size28} 
                    text={item.createdBy.name} 
                    showSecondaryText={true} 
                    secondaryText={moment.utc(item.created).fromNow()} />;
                },
                isPadded: true
              }
            ];
        }

        render () {
            const { className, libraries, loading, onRiskClick, disableSelection } = this.props;
            const { contextualMenuProps } = this.state;
            
            return (
              <BoxPanel shadow>
                <ShimmeredDetailsList
                  items={libraries}
                  compact={false}
                  columns={this._columns}
                  selectionMode={disableSelection ? SelectionMode.none : SelectionMode.none}
                  getKey={this._getKey}
                  setKey="single"
                  layoutMode={DetailsListLayoutMode.justified}
                  constrainMode={ConstrainMode.horizontalConstrained}
                  isHeaderVisible={true}
                  selection={this._selection}
                  selectionPreservedOnEmptyClick={true}
                  onItemInvoked={onRiskClick}
                  //onItemContextMenu={(item, index, ev) => this._onItemContextMenu(item, index, ev) }
                  enterModalSelectionOnTouch={true}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  checkButtonAriaLabel="Row checkbox"
                  enableShimmer={loading}
                  removeFadingOverlay={true}
                  shimmerLines={25}
                  ariaLabelForShimmer="Page libraries being fetched"
                  ariaLabelForGrid="Page libraries list"
                />
                
                { ! loading && !libraries.length && (
                  <MessageBar>
                    There are no matching page libraries.
                  </MessageBar>
                )}

                {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}
              </BoxPanel> 
            );
        }

        _getKey(item, index) {
            return item ? item.id : index;
        }

        _getSelectionDetails() {
            const selectionCount = this._selection.getSelectedCount();
        
            switch (selectionCount) {
              case 0:
                return 'No items selected';
              case 1:
                return '1 item selected: ' + (this._selection.getSelection()[0]).name;
              default:
                return `${selectionCount} items selected`;
            }
        }

        _onColumnClick(ev, column) {
            console.log(column);
        }

        _onItemContextMenu(item, index, ev) {
            const contextualMenuProps = {
              target: ev.target,
              items: [
                {
                  key: 'text',
                  name: `${this._selection.getSelectedCount()} selected`
                }
              ],
              onDismiss: () => {
                this.setState({
                  contextualMenuProps: undefined
                });
              }
            };
        
            if (index > -1) {
              this.setState({
                contextualMenuProps: contextualMenuProps
              });
            }
        
            return false;
          };
    }
);