import React, { Component } from "react";
import { observer } from "mobx-react";

import { Label } from "office-ui-fabric-react/lib/Label";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { Dropdown } from "office-ui-fabric-react/lib/Dropdown";

import { PortfolioPicker } from "../../../portfolios/containers/pickers/PortfolioPicker";
import { RichTextField } from "../../../editors/components/RichTextField";
import { BigTextField } from "../../../base/components/BigTextField";
import { ObservableSeparator } from "../../../base/components/ObservableSeparator";
import { PrincipalPicker } from "../../../identity/containers/PrincipalPicker";
import { SecurityTrimmed } from "../../../identity/containers/utilities/SecurityTrimmed";
import { RoleNames } from "../../../identity/stores/PrincipalContextUtilities";

import { RegistryPicker } from "../../containers/pickers/RegistryPicker";
import { RiskTargetPicker } from "../../containers/pickers/RiskTargetPicker";

export const RiskEditForm = observer(
    class RiskEditForm extends Component {
        render() {
            const { formData, formOptions } = this.props;

            return (
                <div>
                    <div className="row--xs">
                        <r-grid columns="1">
                            <r-cell>
                                <BigTextField
                                    label="Title"
                                    required
                                    placeholder="Please enter a title"
                                    value={formData.title}
                                    transparent
                                    onChange={(ev, value) => (formData.title = value)}
                                />
                            </r-cell>
                        </r-grid>
                        <SecurityTrimmed any={[RoleNames.Admin, RoleNames.Risks]}>
                            <r-grid columns="1">
                                <r-cell>
                                    <ObservableSeparator>Request Source</ObservableSeparator>
                                </r-cell>
                            </r-grid>
                            <r-grid columns="2">
                                <r-cell>
                                    <Dropdown
                                        label="Trigger"
                                        autoComplete="on"
                                        disabled={formOptions.disableTrigger}
                                        selectedKey={formData.trigger}
                                        onChange={(ev, op) => (formData.trigger = op.key)}
                                        options={["Manual", "Assessment", "Event"].map((p) => ({
                                            key: p,
                                            text: p,
                                        }))}
                                    />
                                </r-cell>
                                <r-cell>
                                    <PrincipalPicker
                                        label="Requestor"
                                        selected={formData.requestedBy}
                                        onSelected={(contact) => (formData.requestedBy = contact)}
                                    />
                                </r-cell>
                            </r-grid>
                        </SecurityTrimmed>
                        <r-grid columns="1">
                            <r-cell>
                                <ObservableSeparator>Key Information</ObservableSeparator>
                            </r-cell>
                        </r-grid>
                        <r-grid columns="2">
                            <r-cell span="1">
                                <PortfolioPicker
                                    placeholder="Please select a portfolio"
                                    label="Portfolio"
                                    disabled={
                                        formOptions.disablePortfolio && formData.portfolio && formData.portfolio.id
                                    }
                                    required
                                    selected={formData.portfolio}
                                    onChange={(portfolio) => {
                                        formData.portfolio = portfolio;
                                    }}
                                />
                            </r-cell>
                            <r-cell span="1">
                                <RegistryPicker
                                    placeholder="Please select a registry"
                                    label="Registry"
                                    disabled={formOptions.disableRegistry && formData.registry && formData.registry.id}
                                    required
                                    filter={formOptions.registryFilter}
                                    selected={formData.registry}
                                    onChange={(registry) => {
                                        formData.registry = registry;
                                    }}
                                />
                            </r-cell>
                            <r-cell span="1">
                                <PrincipalPicker
                                    label="Business Contact"
                                    required
                                    placeholder="Please select a business contact"
                                    selectedId={
                                        formData.lifecycle.businessContact
                                            ? formData.lifecycle.businessContact.id
                                            : null
                                    }
                                    onSelected={(contact) => {
                                        formData.lifecycle.businessContact = contact;
                                    }}
                                />
                            </r-cell>
                            <r-cell span="1">
                                <PrincipalPicker
                                    label="Technical Contact"
                                    required
                                    placeholder="Please select a technical contact"
                                    selectedId={
                                        formData.lifecycle.technicalContact
                                            ? formData.lifecycle.technicalContact.id
                                            : null
                                    }
                                    onSelected={(contact) => {
                                        formData.lifecycle.technicalContact = contact;
                                    }}
                                />
                            </r-cell>
                        </r-grid>
                    </div>

                    <div className="row--xs">
                        <RiskTargetPicker
                            placeholder="Please select a target"
                            label="Target"
                            required
                            disabled={formOptions.disableTarget}
                            disableType={formOptions.disableTargetType}
                            selected={formData.target}
                            onChange={(target) => {
                                formData.target = target;
                            }}
                        />
                    </div>
                    <div className="row--xs">
                        <RichTextField
                            label="Summary"
                            required
                            placeholder="Please enter a summary of the risk."
                            value={formData.description}
                            onChange={(val) => {
                                formData.description = val;
                            }}
                        />
                    </div>

                    <div className="row--xs">
                        <RichTextField
                            label="Background"
                            required
                            placeholder="Please enter risk background"
                            value={formData.context}
                            onChange={(val) => {
                                formData.context = val;
                            }}
                        />
                    </div>

                    {!formOptions.disableAssessment && formData.assessment && (
                        <div className="row--xs">
                            <Label required>Selected Assessment</Label>
                            <div>
                                <Icon iconName="StatusCircleRing" />
                                &nbsp;&nbsp;{formData.assessment.title}
                            </div>
                        </div>
                    )}

                    {!formOptions.disableAssessment && formData.advisories && (
                        <div className="row--xs">
                            <Label required>Selected Advisories</Label>
                            <ul className="row--xs">
                                {formData.advisories.map((advisory) => (
                                    <li key={advisory.id}>
                                        <Icon iconName="StatusCircleRing" />
                                        &nbsp;&nbsp;{advisory.title}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            );
        }
    }
);
