import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react";

import * as joint from "jointjs";

import { StateFlowStateView } from "./StateFlowStateView";

export const StateFlowStageView = observer(({ stage, index, manifest, graph, parent, options }) => {
    const rect = useMemo(() => {
        const el = new joint.shapes.standard.HeaderedRectangle({
            position: { x: 325 * index, y: 15 },
            size: { width: 310, height: 650 },
            attrs: {
                root: {
                    magnet: false,
                },
                body: {
                    rx: 3,
                    ry: 3,
                    fill: "var(--body-background)",
                    stroke: "var(--border-color)",
                    strokeWidth: 1,
                },
                header: {
                    rx: 3,
                    ry: 3,
                    fill: "var(--white)",
                    color: "var(--text-color)",
                    stroke: "var(--border-color)",
                    strokeWidth: 1,
                    height: 50,
                },
                headerText: {
                    fontSize: 15,
                    fontWeight: "normal",
                    stroke: "var(--text-color)",
                    textVerticalAnchor: "middle",
                    textAnchor: "middle",
                    refY: "25",
                },
            },
        });

        el.set("id", stage.id);
        parent.embed(el);
        el.set("z", 40);
        graph.addCell(el);

        return el;
    }, [graph]);

    useEffect(() => {
        if (stage) {
            rect.set("id", stage.id);
            parent.embed(rect);

            //rect.position(335 * index, 15, { parentRelative: true });

            rect.prop("data", {
                id: stage.id,
                type: "stage",
                locked: true,
                stage: index === 0 ? "start" : index + 1 === manifest.stages.length ? "end" : "middle",
            });

            rect.attr("headerText/text", stage.name);
        }
    }, [rect, parent, stage, index]);

    useEffect(() => {
        if (stage) {
            const current = rect.position();
            rect.translate(325 * index - current.x, 0, {});
        }
    }, [rect, stage, index]);

    useEffect(() => {
        return () => {
            rect.remove();
        };
    }, []);

    const states = (manifest.states || []).filter((state) => state.stage === stage.id);

    return (
        <>
            {states &&
                states.map((state, i) => (
                    <StateFlowStateView key={state.id} state={state} index={i} manifest={manifest} graph={graph} parent={rect} options={options} />
                ))}
        </>
    );
});
