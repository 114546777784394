import React from "react";
import { observer } from "mobx-react";

import { Placeholder } from "@modules/base/components/Placeholder";
import { BoxPanel } from "@modules/base/components/BoxPanel";

export const DemandRecycleBinTab = observer(() => {
    return (
        <BoxPanel shadow padding>
            <Placeholder
                iconName={"RecycleBin"}
                iconText={"Recycle Bin"}
                description={"There are no items in the demand recycle bin."}
            />
        </BoxPanel>
    );
});
