import React from "react";
import { observer } from "mobx-react";

import { Placeholder } from "@modules/base/components/Placeholder";

import { ActivityInstanceCardView } from "@modules/workflows/components/core/ActivityInstanceCardView";
import { ActivityInstanceShimmerView } from "@modules/workflows/components/core/ActivityInstanceShimmerView";

export const ActivityInstanceListView = observer((props) => {
    const { context, loading, instances, canCreate, onCreate, onCreateLabel, simple, canEdit, onEdit, onRemove } =
        props;

    return (
        <div className="ms-List">
            {loading && [1, 2, 3, 4].map((i) => <ActivityInstanceShimmerView key={i} />)}
            {!loading &&
                instances.map((instance) => (
                    <ActivityInstanceCardView
                        key={instance.id}
                        instance={instance}
                        simple={simple}
                        canEdit={
                            canEdit &&
                            (!context.id || (context.type === instance.scope.type && context.id === instance.scope.id))
                        }
                        onEdit={onEdit}
                        onRemove={onRemove}
                    />
                ))}
            {!loading && !instances.length && (
                <Placeholder
                    iconName={"ChatBot"}
                    iconText={"Automations"}
                    description={"There are no automations configured."}
                    buttonIcon="Add"
                    buttonLabel={onCreateLabel || "Automation"}
                    onConfigure={canCreate && onCreate ? () => onCreate() : null}
                />
            )}
        </div>
    );
});
