import React from "react";
import { observer } from "mobx-react";

import { PivotRouting, PivotRoutingItem } from "@modules/base/components/PivotRouting";

export const IdentitySettingsPivot = observer(({ activeTabs, selectedTab, defaultTab }) => {
    return (
        <PivotRouting
            data-test="identitySettingsPivot"
            aria-label="Users & groups management"
            selectedKey={activeTabs.indexOf(selectedTab) === -1 ? defaultTab : selectedTab}
            onLinkClick={(item, routing) => {
                if (item.props.itemKey === defaultTab) {
                    routing.push(`/settings/identity`);
                } else {
                    routing.push(`/settings/identity/${item.props.itemKey}`);
                }
            }}
        >
            <PivotRoutingItem itemKey="general" headerText="General" itemIcon="Settings" />
            <PivotRoutingItem itemKey="users" headerText="Users" itemIcon="Contact" />
            <PivotRoutingItem itemKey="groups" headerText="Groups" itemIcon="Teamwork" />
            <PivotRoutingItem itemKey="automations" headerText="Automations" itemIcon="ChatBot" />
            <PivotRoutingItem itemKey="recycle-bin" headerText="Recycle Bin" itemIcon="RecycleBin" itemCount={0} />
        </PivotRouting>
    );
});
