import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';

const rootClassName = cx(
  'cygraph-UnknownPartPart-root',
  css` 

  `
);

export const UnknownPartPart = observer(
  class UnknownPartPart extends PureComponent {
    render () {
        const { className, mode, part, zone, page, section } = this.props;

        return (
          <div className={cx(rootClassName, className)}>
            Unknown Part Type <strong><code>{part.type}</code></strong>
          </div>
        );
    }
  }
);