import React, { useState, useEffect } from "react";
import { useStores } from "@hooks/useStores";
import { ProductService } from "@api/graph";

import { getId } from "@uifabric/utilities/lib/getId";

import { Stack } from "@ui/elements/Stack";
import { Spinner, SpinnerSize } from "@ui/elements/Spinner";
import { Label } from "@ui/elements/Label";
import { ComboBox } from "@ui/elements/ComboBox";
import { IconButton } from "@ui/elements/Button";

export const ProductPicker = ({
    label,
    required,
    multiple,
    disabled,
    className,
    onChange,
    except,
    filter,
    value,
    allowClear,
    ...rest
}) => {
    const { authProvider } = useStores();
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        (async function () {
            setLoading(true);
            const service = new ProductService(authProvider);
            const result = await service.getProducts({ startIndex: 0, pageSize: 250 });
            setProducts(result.items);
            setLoading(false);
        })();
    }, []);

    const pickerId = getId("productPicker");

    return (
        <Stack className={className}>
            <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                <Stack horizontal tokens={{ childrenGap: 5 }}>
                    <Label required={required} disabled={disabled} htmlFor={pickerId}>
                        {label} {disabled}
                    </Label>
                    {loading && <Spinner size={SpinnerSize.xSmall} />}
                </Stack>
                {allowClear && (multiple ? value && value.length > 0 : !!value) && (
                    <IconButton
                        styles={{ root: { width: 24, height: 24 }, icon: { fontSize: 13, lineHeight: 13 } }}
                        iconProps={{ iconName: "ClearFilter" }}
                        onClick={() => onChange(multiple ? [] : null)}
                    />
                )}
            </Stack>
            <ComboBox
                id={pickerId}
                selectedKey={value}
                allowFreeform={true}
                multiSelect={multiple}
                useComboBoxAsMenuWidth={true}
                autoComplete="on"
                disabled={disabled}
                options={products
                    .filter(filter || ((r) => true))
                    .filter((r) => (except || []).indexOf(r.id) === -1)
                    .map((p) => ({ key: p.id, text: p.name }))}
                onChange={(_, option) => {
                    if (multiple) {
                        if (option) {
                            const newSelected = [...(value || [])];
                            const index = newSelected.indexOf(option.key);
                            if (index == -1) {
                                newSelected.push(option.key);
                            } else {
                                newSelected.splice(index, 1);
                            }
                            onChange(products.filter((p) => newSelected.indexOf(p.id) !== -1));
                        }
                    } else {
                        onChange(option ? products.find((p) => p.id === option.key) : null);
                    }
                }}
                {...rest}
            />
        </Stack>
    );
};
