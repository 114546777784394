import { observable, action, computed, flow } from "mobx";

import { AssessmentService } from '../../../api/assessments';
import { AssessmentStore } from "./AssessmentStore";

export class AssessmentCommentStore {
    public parentStore: AssessmentStore;
    private assessmentService: AssessmentService;

    @observable public context: any;
    @observable public counts: any;
    @observable public loading;
    @observable public comments: any[] = [];

    constructor(parentStore: AssessmentStore) {
        this.parentStore = parentStore;
        this.assessmentService = parentStore.assessmentService;
    }

    setCommentContext = flow(function*(context : any) {
        this.context = context;
        this.comments = [];

        if(context) {
            try {            
                this.loading = true;
                this.comments = yield this.assessmentService.getAssessmentComments(context.assessmentId, context.uniqueId);
                this.loading = false;
            } catch (error) {
                console.error(error);
                this.loading = false;
                throw error;
            }
        }
    });

    createAssessmentComment = flow(function*(comment : any) {
        this.loading = true;
        var options = Object.assign({}, this.context);
        options = Object.assign(options, comment);

        try {            
            var result = yield this.assessmentService.createAssessmentComment(options);
            this.comments.push(result);
            if(this.counts) {
                this.counts.total++;
                if(result.uniqueId) {
                    if(!this.counts.references[result.uniqueId]) {
                        this.counts.references[result.uniqueId] = 0;
                    }
                    this.counts.references[result.uniqueId]++;
                }
            }
            this.loading = false;
        } catch (error) {
            console.error(error);
            this.loading = false;
            throw error;
        }
    });

    loadAssessmentCommentCount = flow(function*(id: string) {
        this.loading = true;
        this.counts = null;

        try {            
            this.counts = yield this.assessmentService.getAssessmentCommentCount(id);
            this.loading = false;
        } catch (error) {
            console.error(error);
            this.loading = false;
            throw error;
        }
    });
}
