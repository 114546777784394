import React, { Component } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { Text } from "office-ui-fabric-react/lib/Text";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { IconButton } from "office-ui-fabric-react/lib/Button";
import { TooltipHost } from "office-ui-fabric-react/lib/Tooltip";

import { ObservableBadge } from "@modules/base/components/ObservableBadge";
import { ActionSet } from "@modules/base/components/ActionSet";

import { AssessmentToolBarHost } from "@modules/assessments/components/adapters/AssessmentToolBarHost";

const rootClassName = cx(
    "cygraph-AssessmentHeader-root",
    css`
        margin: var(--spacing-s) 0px;

        .title {
            margin: var(--spacing-xs) 0;
        }

        & .description {
            color: var(--text-color-light);
            font-size: var(--font-size-xs);
        }

        & .back {
            width: 45px;
            & i {
                font-size: 30px;
                height: 30px;
                line-height: 30px;
                margin-right: 10px;
            }
        }
    `
);

export const AssessmentHeader = observer(
    class AssessmentHeader extends Component {
        render() {
            const { assessment, onNavigateBack } = this.props;
            const { items, overflowItems } = this._getActionSetItems();

            return (
                <div className={rootClassName}>
                    <Stack horizontal>
                        {onNavigateBack && (
                            <Stack.Item>
                                <TooltipHost content="Navigate back to all assurance">
                                    <IconButton
                                        className="back"
                                        iconProps={{ iconName: "NavigateBack" }}
                                        title="Navigate back"
                                        ariaLabel="Navigate back"
                                        onClick={onNavigateBack}
                                    />
                                </TooltipHost>
                            </Stack.Item>
                        )}
                        <Stack.Item grow>
                            <Stack horizontal horizontalAlign="space-between">
                                <Stack horizontal tokens={{ childrenGap: 20 }}>
                                    <ObservableBadge>{assessment.target.type} Assessment</ObservableBadge>
                                    <AssessmentToolBarHost assessment={assessment} />
                                </Stack>
                                <ActionSet items={items} overflowItems={overflowItems} />
                            </Stack>
                        </Stack.Item>
                    </Stack>
                    <Text className="title" variant="xLarge" block>
                        {assessment.code} - {assessment.title || assessment.target.name}
                    </Text>
                </div>
            );
        }

        _getActionSetItems() {
            const {
                assessment,
                workItem,
                canStart,
                onStart,
                canSubmit,
                onSubmit,
                canDelete,
                onDelete,
                onShare,
                canCancel,
                onCancel,
                canClose,
                onClose,
                canReview,
                onReview,
                canExportCsv,
                onExportCsv,
                canUpload,
                onUpload,
                canAssign,
                onAssign,
                onReport,
            } = this.props;

            const items = [];
            const overflowItems = [];

            if (assessment.state !== "Closed" && assessment.state !== "Cancelled") {
                overflowItems.push({
                    key: "assign",
                    name: "Assign",
                    disabled: !canAssign || !canAssign(),
                    iconProps: { iconName: "AddFriend" },
                    onClick: onAssign,
                });
            }

            switch (assessment.state) {
                case "Draft":
                    items.push({
                        key: "start",
                        name: "Start",
                        primary: true,
                        disabled: !canStart || !canStart(),
                        iconProps: { iconName: "InboxCheck" },
                        confirmProps: {
                            title: "Are you sure you want to start the assessment?",
                        },
                        onClick: onStart,
                    });
                    if (false) {
                        overflowItems.push({
                            key: "delete",
                            name: "Delete",
                            iconProps: { iconName: "Delete" },
                            disabled: !canDelete || !canDelete(),
                            confirmProps: {
                                title: "Are you sure you want to delete the assessment?",
                            },
                            onClick: onDelete,
                        });
                    }
                    overflowItems.push({
                        key: "share",
                        name: "Share",
                        disabled: true,
                        iconProps: { iconName: "Share" },
                        onClick: onShare,
                    });
                    break;
                case "Started":
                    items.push({
                        key: "submit",
                        name: "Submit",
                        primary: true,
                        disabled: !canSubmit || !canSubmit(),
                        iconProps: { iconName: "InboxCheck" },
                        confirmProps: {
                            title: "Are you sure you want to submit the assessment for review?",
                        },
                        onClick: onSubmit,
                    });
                    overflowItems.push({
                        key: "cancel",
                        name: "Cancel",
                        disabled: !canCancel || !canCancel(),
                        iconProps: { iconName: "Cancel" },
                        confirmProps: {
                            title: "Are you sure you want to cancel the risk?",
                        },
                        onClick: onCancel,
                    });
                    overflowItems.push({
                        key: "share",
                        name: "Share",
                        disabled: true,
                        iconProps: { iconName: "Share" },
                        onClick: onShare,
                    });
                    break;
                case "Submitted":
                    items.push({
                        key: "monitor",
                        name: "Accept & Monitor",
                        primary: true,
                        iconProps: { iconName: "ReminderTime" },
                        confirmProps: {
                            title: "Are you sure you want to accept the risk?",
                        },
                        onClick: onCancel,
                    });
                    overflowItems.push({
                        key: "cancel",
                        name: "Cancel",
                        disabled: !canCancel || !canCancel(),
                        iconProps: { iconName: "Cancel" },
                        confirmProps: {
                            title: "Are you sure you want to cancel the risk?",
                        },
                        onClick: onCancel,
                    });
                    overflowItems.push({
                        key: "close",
                        name: "Close",
                        disabled: !canClose || !canClose(),
                        iconProps: { iconName: "CircleStop" },
                        confirmProps: {
                            title: "Are you sure you want to close the risk?",
                        },
                        onClick: onClose,
                    });
                    overflowItems.push({
                        key: "share",
                        name: "Share",
                        disabled: true,
                        iconProps: { iconName: "Share" },
                        onClick: onShare,
                    });
                    break;
                case "Remediation":
                    if (canClose && canClose()) {
                        items.push({
                            primary: true,
                            key: "close",
                            name: "Close",
                            disabled: !canClose || !canClose(),
                            iconProps: { iconName: "CircleStop" },
                            confirmProps: {
                                title: "Are you sure you want to close the assessment?",
                            },
                            onClick: onClose,
                        });
                    } else {
                        items.push({
                            primary: true,
                            key: "reviewRisks",
                            name: "Review Risks",
                            disabled: !canReview || !canReview(),
                            iconProps: { iconName: "ReportHacked" },
                            confirmProps: {
                                title: "Are you sure you want to proceed to risk review?",
                            },
                            onClick: onReview,
                        });
                    }
                    overflowItems.push({
                        key: "cancel",
                        name: "Cancel",
                        disabled: !canCancel || !canCancel(),
                        iconProps: { iconName: "Cancel" },
                        confirmProps: {
                            title: "Are you sure you want to cancel the risk?",
                        },
                        onClick: onCancel,
                    });
                    overflowItems.push({
                        key: "share",
                        name: "Share",
                        disabled: true,
                        iconProps: { iconName: "Share" },
                        onClick: onShare,
                    });
                    break;
                case "Reviewing":
                    items.push({
                        primary: true,
                        key: "close",
                        name: "Close",
                        disabled: !canClose || !canClose(),
                        iconProps: { iconName: "CircleStop" },
                        confirmProps: {
                            title: "Are you sure you want to close the assessment?",
                        },
                        onClick: onClose,
                    });
                    overflowItems.push({
                        key: "cancel",
                        name: "Cancel",
                        disabled: !canCancel || !canCancel(),
                        iconProps: { iconName: "Cancel" },
                        confirmProps: {
                            title: "Are you sure you want to cancel the risk?",
                        },
                        onClick: onCancel,
                    });
                    overflowItems.push({
                        key: "share",
                        name: "Share",
                        disabled: true,
                        iconProps: { iconName: "Share" },
                        onClick: onShare,
                    });
                    break;
                case "Closed":
                    items.push({
                        key: "viewReport",
                        name: "View Report",
                        iconProps: { iconName: "WorkItem" },
                        onClick: onReport,
                    });
                    break;
            }

            items.push({
                key: "upload",
                name: "Upload",
                iconOnly: true,
                iconProps: { iconName: "Upload" },
                disabled: !canUpload || !canUpload(),
                ariaLabel: "Upload",
                onClick: () => onUpload({ internalOnly: false }),
            });

            items.push({
                key: "exportCsv",
                name: "Export Questions and Answers",
                iconOnly: true,
                disabled: !canExportCsv || !canExportCsv(),
                iconProps: { iconName: "ExcelDocument" },
                onClick: onExportCsv,
            });

            return { items, overflowItems };
        }
    }
);
