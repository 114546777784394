import React, { Component, Fragment } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";
import Moment from "react-moment";

import { Text } from "office-ui-fabric-react/lib/Text";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { PrimaryButton, DefaultButton } from "office-ui-fabric-react/lib/Button";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { ObservableBadge } from "@modules/base/components/ObservableBadge";
import RichTextViewer from "@modules/editors/components/RichTextViewer";

import { WorkItemFieldValue } from "./WorkItemFieldValue";

const headerClassName = cx(
    "cygraph-WorkItemReview-title",
    css`
        margin: var(--spacing-xs) 0;
    `
);

const propTableClassName = cx(
    "cygraph-WorkItemReview-table",
    css`
        margin: var(--spacing-xs) 0;
        width: 100%;
        border-collapse: collapse;

        & th {
            text-align: left;
            padding: 7px 15px 7px 0;
            font-weight: normal;
            max-width: 175px;
            min-width: 150px;
            font-size: var(--font-size-xxs);
            border-bottom: 1px solid var(--text-color-lightest);
            color: var(--text-color-light);
        }
        & td {
            text-align: left;
            padding: 6px 0;
            border-bottom: 1px solid var(--text-color-lightest);
        }
    `
);

const recommendationClassName = cx(
    "cygraph-WorkItemReview-recommendation",
    css`
        padding: var(--spacing-l);
        margin: var(--spacing-xxl);
    `
);

export const WorkItemReview = observer(
    class WorkItemReview extends Component {
        getPortfolio = () => {
            const { portfolios, workItem } = this.props;
            if (portfolios.find((p) => p.id == workItem.portfolio.id))
                return portfolios.find((p) => p.id == workItem.portfolio.id).name;
            else return null;
        };

        render() {
            const { product, portfolios, workItem, canSave, selectedSystem, schema } = this.props;
            return (
                <Fragment>
                    <div>
                        <ObservableBadge>{product.name}</ObservableBadge>
                        <Text variant="xLarge" block className={headerClassName}>
                            {workItem.title}
                        </Text>

                        <r-grid columns-l="2" columns="1">
                            <r-cell span-l="1" span="1">
                                <table className={propTableClassName} border="0">
                                    <tbody>
                                        <tr>
                                            <th>Portfolio</th>
                                            <td>{workItem.portfolio.id ? this.getPortfolio() : null}</td>
                                        </tr>
                                        <tr>
                                            <th>System</th>
                                            <td>{selectedSystem ? selectedSystem.name : ""}</td>
                                        </tr>
                                        <tr>
                                            <th>Priority</th>
                                            <td>{workItem.priority}</td>
                                        </tr>
                                        <tr>
                                            <th>Due Date</th>
                                            <td>
                                                <Moment value={workItem.dueDate} format="DD MMM YYYY" />
                                            </td>
                                        </tr>
                                        <tr>
                                            {workItem.description ? (
                                                <RichTextViewer className="description" value={workItem.description} />
                                            ) : (
                                                ""
                                            )}
                                        </tr>
                                        {schema &&
                                            schema.extensions &&
                                            schema.extensions.map((field) => {
                                                return this._isFieldVisible(schema, field, workItem) ? (
                                                    <tr key={field.name}>
                                                        <th>{field.label}</th>
                                                        <td>
                                                            <WorkItemFieldValue
                                                                visible={true}
                                                                schema={schema}
                                                                field={field}
                                                                workItem={workItem}
                                                            />
                                                        </td>
                                                    </tr>
                                                ) : null;
                                            })}
                                    </tbody>
                                </table>
                            </r-cell>
                            <r-cell span-l="1" span="1">
                                <BoxPanel className={recommendationClassName}>
                                    <Text variant="large" block>
                                        Next Steps!
                                    </Text>
                                    <br />

                                    <p>
                                        Based on your responses, we have categorised your system to be in the security{" "}
                                        <strong>{this._getTier(workItem)}</strong>. You must complete the following
                                        modules in the full assessment.
                                    </p>
                                    <br />

                                    <table className={propTableClassName} border="0">
                                        <tbody>
                                            <tr>
                                                <th>Base Security Assessment</th>
                                                <td>
                                                    <Icon iconName={"CheckboxComposite"} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Application Module</th>
                                                <td>
                                                    <Icon
                                                        iconName={
                                                            workItem.systemSource == "internal"
                                                                ? "CheckboxComposite"
                                                                : "Checkbox"
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Infrastructure Module</th>
                                                <td>
                                                    <Icon
                                                        iconName={
                                                            workItem.systemSource == "third-party"
                                                                ? "CheckboxComposite"
                                                                : "Checkbox"
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Personal Data Module</th>
                                                <td>
                                                    <Icon
                                                        iconName={
                                                            workItem.hasPersonalInfo ? "CheckboxComposite" : "Checkbox"
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <br />
                                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                                        <PrimaryButton
                                            text="Continue with full assessment"
                                            allowDisabledFocus
                                            iconProps={{ iconName: "WorkItem" }}
                                            disabled={!canSave || !canSave(workItem)}
                                            onClick={() => this.props.onSave(workItem)}
                                        />
                                        <DefaultButton
                                            text="Not now"
                                            allowDisabledFocus
                                            disabled={false}
                                            onClick={() => this.props.onCancel(workItem)}
                                        />
                                    </Stack>
                                </BoxPanel>
                            </r-cell>
                        </r-grid>
                    </div>
                </Fragment>
            );
        }

        _isFieldVisible(schema, field, workItem) {
            if (!field.visibleWhen) {
                return true;
            }

            return eval(field.visibleWhen);
        }

        _getTier(workItem) {
            if (
                (workItem.hasDataProcessing && workItem.hasSensitiveDP) ||
                (workItem.hasPersonalInfo && workItem.hasSensitivePI)
            ) {
                return "Tier 1";
            }

            if (workItem.hasPersonalInfo) {
                return "Tier 2";
            }

            return "Tier 3";
        }
    }
);
