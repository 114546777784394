import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { useStores } from "@hooks/useStores";

import { ObservablePagination } from "@modules/base/components/ObservablePagination";

import { ProductGridView } from "@modules/products/components/core/ProductGridView";
import { ProductCommandBar } from "@modules/products/components/core/ProductCommandBar";
import { ProductNewPanel } from "@modules/products/containers/panels/ProductNewPanel";

export const DemandCatalogueTab = withRouter(
    observer(({ match }) => {
        const { productStore, routing, pageContext } = useStores();
        const { newFormStore, browseStore } = productStore;
        const { products, keywords, loading, pagination } = browseStore;

        useEffect(() => {
            pageContext.updateQueryStringParams(routing.location.search);
            browseStore.loadProducts({
                page: pageContext.queryStringParams.p || 1,
                keywords: pageContext.queryStringParams.k,
            });
        }, [match]);

        return (
            <>
                <ProductCommandBar
                    onNewProduct={() => {
                        newFormStore.show();
                    }}
                    keywords={keywords}
                    onSearchChange={(keywords) => {
                        routing.push(`/settings/products${keywords ? `?k=${encodeURIComponent(keywords)}` : ""}`);
                    }}
                />
                <ProductGridView
                    products={products}
                    loading={loading}
                    onStateFlowClick={(product) => {
                        if (product && product.flowId) {
                            routing.push(`/settings/workflows/${product.flowId}`);
                        }
                    }}
                />
                <ObservablePagination
                    {...pagination}
                    onPageChange={(index) => {
                        routing.push(
                            `/settings/demand/catalogue?p=${index + 1}${
                                keywords ? `&k=${encodeURIComponent(keywords)}` : ""
                            }`
                        );
                    }}
                />
                <ProductNewPanel
                    onAfterSave={(item) => {
                        routing.push(`/settings/products/${item.id}`);
                    }}
                />
            </>
        );
    })
);
