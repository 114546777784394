import React, { Component } from "react";
import { observer } from "mobx-react";

import { Slider } from "office-ui-fabric-react/lib/Slider";
import { ComboBox } from "office-ui-fabric-react/lib/ComboBox";
import { Toggle } from "office-ui-fabric-react/lib/Toggle";
import { TextField } from "office-ui-fabric-react/lib/TextField";

import { BigTextField } from "../../../base/components/BigTextField";
import { ObservableSeparator } from "../../../base/components/ObservableSeparator";

import { PropertyTypes, EntityTypes } from "../../stores/PropertyContants";

export const PropertyEditForm = observer(
    class PropertyEditForm extends Component {
        render() {
            const { formData, formOptions } = this.props;

            return (
                <div>
                    <r-grid columns="6">
                        <r-cell span="6">
                            <div>
                                <BigTextField
                                    label="Name"
                                    required
                                    placeholder="Please enter a property name"
                                    value={formData.name}
                                    transparent
                                    onChange={(ev, value) => (formData.name = value)}
                                />
                            </div>
                        </r-cell>
                        <r-cell span="6">
                            <div>
                                <TextField
                                    label="Description"
                                    value={formData.description}
                                    multiline={true}
                                    autoAdjustHeight
                                    onChange={(ev, value) => (formData.description = value)}
                                />
                            </div>
                        </r-cell>
                        <r-cell span="3">
                            <div>
                                <ComboBox
                                    label="Section"
                                    required
                                    autoComplete="on"
                                    options={(formOptions.sections || []).map((section) => ({
                                        key: section.id,
                                        text: section.name,
                                    }))}
                                    onChange={(ev, item) => (formData.section = { id: item.key })}
                                    selectedKey={formData.section ? formData.section.id : null}
                                    useComboBoxAsMenuWidth
                                />
                            </div>
                        </r-cell>
                        <r-cell span="3">
                            <div>
                                <TextField
                                    label="Order"
                                    required
                                    type="number"
                                    min={0}
                                    max={100}
                                    step={1}
                                    value={formData.order}
                                    onChange={(ev, value) => (formData.order = value)}
                                />
                            </div>
                        </r-cell>
                        <r-cell span="3">
                            <div>
                                <ComboBox
                                    label="Type"
                                    required
                                    autoComplete="on"
                                    options={Object.keys(PropertyTypes).map((key) => ({
                                        key: key,
                                        text: PropertyTypes[key],
                                    }))}
                                    onChange={(ev, item) => {
                                        if (formData.type !== item.key) {
                                            formData.schema = {};
                                        }
                                        formData.type = item.key;
                                    }}
                                    selectedKey={formData.type}
                                    useComboBoxAsMenuWidth
                                />
                            </div>
                        </r-cell>
                    </r-grid>

                    <ObservableSeparator className="row--s">Property Settings</ObservableSeparator>

                    {formData.type == PropertyTypes.Lookup && (
                        <div className="row--xs">
                            <ComboBox
                                label="Entity"
                                required
                                autoComplete="on"
                                options={Object.keys(EntityTypes).map((key) => ({ key: key, text: EntityTypes[key] }))}
                                onChange={(ev, item) => {
                                    formData.schema.entity = item.key;
                                }}
                                selectedKey={formData.schema.entity}
                                useComboBoxAsMenuWidth
                            />
                        </div>
                    )}

                    {formData.type == PropertyTypes.Number && (
                        <div className="row--xs">
                            <r-grid columns="2">
                                <r-cell>
                                    <TextField
                                        label="Decimal Places"
                                        type="number"
                                        value={formData.schema.decimalPlaces}
                                        min="0"
                                        max="10"
                                        onChange={(ev, value) => {
                                            if (!value) {
                                                formData.schema.decimalPlaces = null;
                                            } else {
                                                formData.schema.decimalPlaces = parseInt(value);
                                            }
                                        }}
                                    />
                                </r-cell>
                                <r-cell>
                                    <TextField
                                        label="Default"
                                        type="number"
                                        value={formData.schema.default}
                                        onChange={(ev, value) => {
                                            if (!value) {
                                                formData.schema.default = null;
                                            } else if (formData.schema.decimalPlaces === 0) {
                                                formData.schema.default = parseInt(value);
                                            } else {
                                                formData.schema.default = parseFloat(value);
                                            }
                                        }}
                                    />
                                </r-cell>
                                <r-cell>
                                    <TextField
                                        label="Min"
                                        type="number"
                                        value={formData.schema.min}
                                        onChange={(ev, value) => {
                                            if (!value) {
                                                formData.schema.min = null;
                                            } else if (formData.schema.decimalPlaces === 0) {
                                                formData.schema.min = parseInt(value);
                                            } else {
                                                formData.schema.min = parseFloat(value);
                                            }
                                        }}
                                    />
                                </r-cell>
                                <r-cell>
                                    <TextField
                                        label="Max"
                                        type="number"
                                        value={formData.schema.max}
                                        onChange={(ev, value) => {
                                            if (!value) {
                                                formData.schema.max = null;
                                            } else if (formData.schema.decimalPlaces === 0) {
                                                formData.schema.max = parseInt(value);
                                            } else {
                                                formData.schema.max = parseFloat(value);
                                            }
                                        }}
                                    />
                                </r-cell>
                            </r-grid>
                        </div>
                    )}

                    {formData.type == PropertyTypes.Choice && (
                        <>
                            <div className="row--xs">
                                <TextField
                                    label="Options"
                                    placeholder="Place each option on a separate line"
                                    value={formData.schema.options ? formData.schema.options.join("\n") : []}
                                    multiline={true}
                                    autoAdjustHeight
                                    onChange={(ev, value) => (formData.schema.options = value.split("\n"))}
                                />
                            </div>
                            <div className="row--xs">
                                <TextField
                                    label="Default"
                                    value={formData.schema.default}
                                    onChange={(ev, value) => (formData.schema.default = value)}
                                />
                            </div>
                        </>
                    )}

                    {formData.type == PropertyTypes.Text && (
                        <>
                            <div className="row--xs">
                                <r-grid columns="2">
                                    <r-cell>
                                        <Toggle
                                            label="Rich Text"
                                            checked={formData.schema.richText}
                                            onChange={(ev, checked) => {
                                                formData.schema.richText = checked;
                                                if (checked) {
                                                    formData.schema.maxLength = null;
                                                }
                                            }}
                                        />
                                    </r-cell>
                                    <r-cell>
                                        <TextField
                                            label="Max Length"
                                            type="number"
                                            value={formData.schema.maxLength}
                                            disabled={formData.schema.richText}
                                            onChange={(ev, value) => {
                                                if (!value) {
                                                    formData.schema.maxLength = null;
                                                } else {
                                                    formData.schema.maxLength = parseInt(value);
                                                }
                                            }}
                                        />
                                    </r-cell>
                                    <r-cell>
                                        <div className="row--xs">
                                            <TextField
                                                label="Default"
                                                value={formData.schema.default}
                                                onChange={(ev, value) => (formData.schema.default = value)}
                                            />
                                        </div>
                                    </r-cell>
                                </r-grid>
                            </div>
                        </>
                    )}

                    {formData.type == PropertyTypes.Date && (
                        <div className="row--xs">
                            <TextField
                                label="Default"
                                type="date"
                                value={formData.schema.default}
                                onChange={(ev, value) => (formData.schema.default = value)}
                            />
                        </div>
                    )}

                    {formData.type == PropertyTypes.Boolean && (
                        <div className="row--xs">
                            <Toggle
                                label="Default"
                                inlineLabel
                                checked={formData.schema.default}
                                onChange={(ev, checked) => (formData.schema.default = checked)}
                            />
                        </div>
                    )}

                    <div className="row--xs">
                        <Toggle
                            label="Value is required for this property"
                            inlineLabel
                            checked={formData.required}
                            onChange={(ev, checked) => (formData.required = checked)}
                        />
                    </div>

                    <div className="row--xs">
                        <Toggle
                            label="Property is read only in forms"
                            inlineLabel
                            checked={formData.readOnly}
                            onChange={(ev, checked) => (formData.readOnly = checked)}
                        />
                    </div>

                    <div className="row--xs">
                        <Toggle
                            label="Allow multiple values"
                            inlineLabel
                            checked={formData.multiValue}
                            onChange={(ev, checked) => (formData.multiValue = checked)}
                        />
                    </div>

                    <div className="row--xs">
                        <Toggle
                            label="Property is hidden from end users"
                            inlineLabel
                            checked={formData.hidden}
                            onChange={(ev, checked) => (formData.hidden = checked)}
                        />
                    </div>

                    <div className="row--xs">
                        <Toggle
                            label="Make the property searchable"
                            inlineLabel
                            checked={formData.searchable}
                            onChange={(ev, checked) => (formData.searchable = checked)}
                        />
                    </div>

                    <div className="row--xs">
                        <Toggle
                            label="Property is active?"
                            inlineLabel
                            checked={formData.active}
                            onChange={(ev, checked) => (formData.active = checked)}
                        />
                    </div>

                    <div className="row--xs">
                        <Toggle
                            label="Show property in default lists"
                            inlineLabel
                            checked={formData.default}
                            onChange={(ev, checked) => (formData.default = checked)}
                        />
                    </div>
                </div>
            );
        }
    }
);
