import { observable, flow, computed, action } from "mobx";

import { RiskService } from "../../../api/risks";
import { RiskStore } from "./RiskStore";

export class RiskTypeViewerPageStore {
    private parentStore: RiskStore;
    private riskService: RiskService;

    @observable public type: any;
    @observable public loading: boolean = false;
    @observable public error: any;

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
    }

    @computed
    public get breadcrumb() {
        const routing = this.parentStore.rootStore.routing;
        const builder: any[] = [];

        builder.push({
            text: "Settings",
            key: "settings",
            onClick: () => routing.push(`/settings`),
        });
        builder.push({
            text: "Risk Management",
            key: "settings-risks",
            onClick: () => routing.push(`/settings/risks`),
        });
        builder.push({
            text: "Catalogue",
            key: "risks-catalogue",
            onClick: () => routing.push(`/settings/risks/catalogue`),
        });

        if (!this.type) {
            return builder;
        }

        builder.push({ text: this.type.name, key: this.type.id });

        return builder;
    }

    @action
    public resetType() {
        this.type = null;
    }

    @computed public get canEdit() {
        return this.type ? this.type.locked === false : false;
    }

    public loadType = flow(function* (id: string) {
        this.loading = true;
        this.types = [];

        try {
            this.type = yield this.riskService.getType(id);
        } catch (error) {
            console.error(error);
            this.error = error;
        } finally {
            this.loading = false;
        }
    });
}
