import React from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";
import Moment from "react-moment";

import { BoxPanel } from "../../../base/components/BoxPanel";
import { MessageBar } from "office-ui-fabric-react/lib/MessageBar";
import { DetailsListLayoutMode, Selection, SelectionMode, ConstrainMode } from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { Persona, PersonaSize } from "office-ui-fabric-react/lib/Persona";

export const StateFlowVersionGridView = observer(({ className, versions, loading, onVersionClick }) => {
    const columns = [
        {
            key: "version",
            name: "Version",
            fieldName: "version",
            minWidth: 50,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: "string",
            onRender: (item) => {
                return (
                    <div
                        className={cx(
                            "advisory-title",
                            css`
                                white-space: initial;
                            `
                        )}
                    >
                        <a href="javascript:" onClick={() => onVersionClick(item)}>
                            {item.version}
                        </a>
                        <br />
                    </div>
                );
            },
            isPadded: true,
        },
        {
            key: "notes",
            name: "Notes",
            fieldName: "notes",
            minWidth: 230,
            isResizable: false,
            data: "string",
            onRender: (item) => {
                return (
                    <div
                        className={cx(
                            "advisory-control",
                            css`
                                white-space: initial;
                            `
                        )}
                    >
                        {item.notes}
                    </div>
                );
            },
            isPadded: false,
        },
        {
            key: "createdBy",
            name: "Created By",
            fieldName: "createdBy",
            minWidth: 230,
            maxWidth: 260,
            isResizable: false,
            onRender: (item) => {
                return <Persona size={PersonaSize.size28} text={item.createdBy.name} showSecondaryText={true} secondaryText={item.createdBy.jobTitle} />;
            },
            isPadded: false,
        },
        {
            key: "created",
            name: "Date Created",
            fieldName: "created",
            minWidth: 100,
            maxWidth: 130,
            isResizable: true,
            isSorted: true,
            isSortedDescending: true,
            sortAscendingAriaLabel: "Sorted A to Z",
            sortDescendingAriaLabel: "Sorted Z to A",
            data: "date",
            onRender: (item) => {
                return <Moment date={item.created} fromNow />;
            },
            isPadded: true,
        },
    ];

    return (
        <BoxPanel shadow className={className}>
            <ShimmeredDetailsList
                items={versions}
                compact={false}
                columns={columns}
                selectionMode={SelectionMode.multiple}
                getKey={(item, index) => (item ? item.id : index)}
                setKey="multiple"
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.horizontalConstrained}
                isHeaderVisible={true}
                selectionPreservedOnEmptyClick={true}
                onItemInvoked={onVersionClick}
                //onItemContextMenu={(item, index, ev) => this._onItemContextMenu(item, index, ev) }
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="Row checkbox"
                enableShimmer={loading}
                removeFadingOverlay={true}
                shimmerLines={10}
                ariaLabelForShimmer="Versions are being fetched"
                ariaLabelForGrid="Version list"
            />

            {!loading && !versions.length && <MessageBar>There are no matching workflow versions.</MessageBar>}
        </BoxPanel>
    );
});
