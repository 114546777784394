import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";

import { useStores } from "@hooks/useStores";
import { Constants } from "@api/graph/serviceBase";

import { Persona, PersonaSize } from "office-ui-fabric-react/lib/Persona";

export const PhotoPersona = observer((props) => {
    const { authProvider } = useStores();
    const [photo, setPhoto] = useState(null);

    useEffect(() => {
        if (props.principalId) {
            authProvider.getAccessToken().then((token) => {
                setPhoto(
                    `${Constants.baseUrl}/principals/${props.principalId}/photo/$value?token=${token.accessToken}`
                );
            });
        }
    }, [authProvider, props.principalId]);

    return <Persona {...props} imageUrl={photo} size={props.size || PersonaSize.size32} />;
});

// export const PhotoPersona = (props) => {
//     const stores = useStores();
//     const [photo, setPhoto] = useState(null);

//     useEffect(() => {
//         const abortController = new AbortController();

//         if (props.principalId) {
//             stores.principalStore.utilities
//                 .loadPrincipalPhoto(props.principalId, { signal: abortController.signal })
//                 .then((retrievedPhoto) => {
//                     if (retrievedPhoto) {
//                         setPhoto(URL.createObjectURL(retrievedPhoto));
//                     }
//                 })
//                 .catch((e) => {
//                     setPhoto(null);
//                 });
//         }

//         return () => {
//             abortController.abort();
//             if (photo) {
//                 URL.revokeObjectURL(photo);
//             }
//         };
//     }, [props.principalId]);

//     return <Persona {...props} imageUrl={photo} size={props.size || PersonaSize.size32} />;
// };
