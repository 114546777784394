import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { Logger, LogLevel } from "msal";
import { TeamsAuthProvider } from "./modules/teams/TeamsAuthProvider";
import { Constants } from "./api/graph/serviceBase";

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

function getTenantInfo(hostname) {
    if (window.cygboot && window.cygboot.bootstrap) {
        return {
            tenantId: window.cygboot.tenantId,
            clientId: window.cygboot.clientId,
            authority: window.cygboot.authority,
            scope: window.cygboot.scope,
        };
    }

    switch (hostname) {
        case "cygraph-int-app.azurewebsites.net":
            return {
                tenantId: "b24badd3-3af2-4e67-899d-3fcec7641caf",
                clientId: "77f96cd1-8c70-44df-98dd-b3c39be022f2",
                authority: "https://login.microsoftonline.com/b24badd3-3af2-4e67-899d-3fcec7641caf",
                scope: "e9affd9c-532e-4e0d-801e-9398819c7754/user_impersonation",
            };
        case "demo1.zerodayapp.com":
            return {
                tenantId: "b24badd3-3af2-4e67-899d-3fcec7641caf",
                clientId: "77f96cd1-8c70-44df-98dd-b3c39be022f2",
                authority: "https://login.microsoftonline.com/b24badd3-3af2-4e67-899d-3fcec7641caf",
                scope: "e9affd9c-532e-4e0d-801e-9398819c7754/user_impersonation",
            };
        case "demo1.zerod.ai":
            return {
                tenantId: "b24badd3-3af2-4e67-899d-3fcec7641caf",
                clientId: "77f96cd1-8c70-44df-98dd-b3c39be022f2",
                authority: "https://login.microsoftonline.com/b24badd3-3af2-4e67-899d-3fcec7641caf",
                scope: "e9affd9c-532e-4e0d-801e-9398819c7754/user_impersonation",
            };
        case "cyb-eun-nprd-cygraph-app1.azurewebsites.net":
            return {
                tenantId: "bd5c6713-7399-4b31-be79-78f2d078e543",
                clientId: "d200a7dc-106f-48b8-9cfa-a7097d2378d5",
                authority: "https://login.microsoftonline.com/bd5c6713-7399-4b31-be79-78f2d078e543",
                scope: "e87365f1-d141-4433-9dba-10c6914ca2fa/user_impersonation",
            };
        case "cyb-eun-prd-cygraph-app1.azurewebsites.net":
            return {
                tenantId: "bd5c6713-7399-4b31-be79-78f2d078e543",
                clientId: "9d4b6d0f-0fd6-4166-81a4-4a2076fc51c2",
                authority: "https://login.microsoftonline.com/bd5c6713-7399-4b31-be79-78f2d078e543",
                scope: "7805319d-b918-4b52-9304-c82d4be48396/user_impersonation",
            };
        case "app-cyg-uks-ih01.azurewebsites.net":
            return {
                tenantId: "dominos.co.uk",
                clientId: "a0933607-77af-4e15-a378-3e2760b9c6b5",
                authority: "https://login.microsoftonline.com/dominos.co.uk",
                scope: "3b7d47ba-b43b-4c00-a7b0-492aadd813b5/user_impersonation",
            };
        default:
            return {
                tenantId: "b24badd3-3af2-4e67-899d-3fcec7641caf",
                clientId: "77f96cd1-8c70-44df-98dd-b3c39be022f2",
                authority: "https://login.microsoftonline.com/b24badd3-3af2-4e67-899d-3fcec7641caf",
                scope: "e9affd9c-532e-4e0d-801e-9398819c7754/user_impersonation",
            };
    }
}

const tenantInfo = getTenantInfo(window.location.hostname);

const teamsAuthProvider = new TeamsAuthProvider({
    clientId: tenantInfo.clientId,
    tokenExchangeUri: `${Constants.baseUrl}/bot/auth`,
    cacheLocation: "localStorage",
});

// The auth provider should be a singleton. Best practice is to only have it ever instantiated once.
// Avoid creating an instance inside the component it will be recreated on each render.
// If two providers are created on the same page it will cause authentication errors.
const msalAuthProvider = new MsalAuthProvider(
    {
        auth: {
            clientId: tenantInfo.clientId,
            authority: tenantInfo.authority,
            //authority: "https://login.microsoftonline.com/common",
            postLogoutRedirectUri: window.location.origin + "/signed-out",
            redirectUri: window.location.origin + "/sign-in",
            validateAuthority: true,

            // After being redirected to the "redirectUri" page, should user
            // be redirected back to the Url where their login originated from?
            navigateToLoginRequestUrl: true,
        },
        // Enable logging of MSAL events for easier troubleshooting.
        // This should be disabled in production builds.
        system: {
            loadFrameTimeout: 10000,
            logger: new Logger(
                (logLevel, message, containsPii) => {
                    // console.log("[MSAL]", message);
                },
                {
                    //level: LogLevel.Verbose,
                    level: LogLevel.Warning,
                    piiLoggingEnabled: true,
                }
            ),
        },
        cache: {
            cacheLocation: "sessionStorage",
            storeAuthStateInCookie: isIE,
        },
    },
    {
        scopes: [tenantInfo.scope],
    },
    {
        loginType: isIE ? LoginType.Popup : LoginType.Redirect,
        // When a token is refreshed it will be done by loading a page in an iframe.
        // Rather than reloading the same page, we can point to an empty html file which will prevent
        // site resources from being loaded twice.
        tokenRefreshUri: window.location.origin + "/empty",
        //tokenRefreshUri: window.location.origin
    }
);

export class StaticAuthProvider {
    constructor(config) {
        this.config = config;
    }

    getAccessToken = () => {
        try {
            const accessToken = localStorage.getItem(`${this.config.baseKey}:accessToken`);
            return {
                accessToken,
            };
        } catch (error) {
            console.error(error);
            return null;
        }
    };
}

const isCypress = !!localStorage.getItem("zerodai:cypress");
const cypressAuthProvider = new StaticAuthProvider({ baseKey: "zerodai:cypress" });

export const authProvider = isCypress
    ? cypressAuthProvider
    : window.top != window.self
    ? teamsAuthProvider
    : msalAuthProvider;
