import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import { cx } from "emotion";

import Dropzone from "react-dropzone";
import { Pivot, PivotItem, PivotLinkFormat } from "office-ui-fabric-react/lib/Pivot";
import { PanelType } from "office-ui-fabric-react/lib/Panel";

import { ObservablePanel } from "@modules/base/components/ObservablePanel";
import { BoxPanel } from "@modules/base/components/BoxPanel";

import { WorkItemAssignPanel } from "@modules/workitems/containers/panels/WorkItemAssignPanel";

import { AssessmentHeader } from "@modules/assessments/components/core/AssessmentHeader";
import { AssessmentStatusProgress } from "@modules/assessments/components/core/AssessmentStatusProgress";
import { AssessmentSidebar } from "@modules/assessments/components/core/AssessmentSidebar";
import { AssessmentModuleList } from "@modules/assessments/components/modules/AssessmentModuleList";

import { AssessmentDocumentsTab } from "@modules/assessments/containers/tabs/AssessmentDocumentsTab";
import { AssessmentSummaryTab } from "@modules/assessments/containers/tabs/AssessmentSummaryTab";

import { ViewTabType } from "@modules/assessments/stores/AssessmentViewStore";

export const AssessmentViewPanel = inject(
    "assessmentStore",
    "workItemStore"
)(
    withRouter(
        observer(
            class AssessmentViewPanel extends Component {
                render() {
                    const { assessmentStore, workItemStore } = this.props;
                    const { lifecycleStore, viewStore } = assessmentStore;
                    const { loading, visible, assessment, workItem, modules, tab } = viewStore;

                    return (
                        <ObservablePanel hidden={!visible} panelType={PanelType.largeFixed} onClosePanel={() => this._onHidePanel()}>
                            {!loading && assessment && workItem && (
                                <>
                                    <Dropzone
                                        disabled={!lifecycleStore.canUpload}
                                        onDrop={(acceptedFiles) => viewStore.onUpload(acceptedFiles)}
                                        multiple={true}
                                        noClick={true}
                                    >
                                        {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, open }) => (
                                            <div
                                                {...getRootProps()}
                                                className={cx("dropzone", isDragActive && "active", isDragAccept && "accept", isDragReject && "reject")}
                                            >
                                                <input {...getInputProps()} />

                                                <AssessmentHeader
                                                    assessment={assessment}
                                                    workItem={workItem}
                                                    canStart={() => lifecycleStore.canStart}
                                                    onStart={() => lifecycleStore.onStart()}
                                                    canSubmit={() => lifecycleStore.canSubmit}
                                                    onSubmit={() => lifecycleStore.onSubmit()}
                                                    canReview={() => lifecycleStore.canReview}
                                                    onReview={() => lifecycleStore.onReview()}
                                                    canClose={() => lifecycleStore.canClose}
                                                    onClose={() => lifecycleStore.onClose()}
                                                    canCancel={() => lifecycleStore.canCancel}
                                                    onCancel={() => lifecycleStore.onCancel()}
                                                    canExportCsv={() => lifecycleStore.canExportCsv}
                                                    onExportCsv={() => lifecycleStore.onExportCsv()}
                                                    canAssign={() => lifecycleStore.canAssign}
                                                    onAssign={() => lifecycleStore.onAssign()}
                                                    canUpload={() => lifecycleStore.canUpload}
                                                    onUpload={open}
                                                />

                                                <AssessmentStatusProgress assessment={assessment} workItem={workItem} />

                                                <AssessmentModuleList
                                                    disabled={lifecycleStore.isReadOnly}
                                                    modules={modules}
                                                    onModuleClick={(module) => viewStore.onModule(module)}
                                                />

                                                <Pivot
                                                    aria-label="Assessment advisories, related documents and work"
                                                    selectedKey={tab}
                                                    onLinkClick={(item) => viewStore.selectTab(item.itemKey)}
                                                    linkFormat={PivotLinkFormat.links}
                                                >
                                                    <PivotItem itemKey={ViewTabType.Summary} headerText="Summary">
                                                        <AssessmentSummaryTab assessment={assessment} workItem={workItem} />
                                                    </PivotItem>

                                                    <PivotItem itemKey={ViewTabType.Documents} headerText="Related Documents">
                                                        <AssessmentDocumentsTab
                                                            assessment={assessment}
                                                            workItem={workItem}
                                                            canUpload={() => lifecycleStore.canUpload}
                                                            onUpload={open}
                                                        />
                                                    </PivotItem>

                                                    <PivotItem itemKey={ViewTabType.Properties} headerText="Properties">
                                                        <BoxPanel>
                                                            <AssessmentSidebar detached assessment={assessment} workItem={workItem} />
                                                        </BoxPanel>
                                                    </PivotItem>
                                                </Pivot>
                                            </div>
                                        )}
                                    </Dropzone>

                                    <WorkItemAssignPanel
                                        store={workItemStore.assignFormStore}
                                        onSave={() => workItemStore.assignFormStore.onAssign()}
                                        onAfterSave={(item) => lifecycleStore.onAfterAssign(item)}
                                    />
                                </>
                            )}
                        </ObservablePanel>
                    );
                }

                _onHidePanel() {
                    const { viewStore } = this.props.assessmentStore;
                    viewStore.hide();
                }
            }
        )
    )
);
