import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { useStores } from "@hooks/useStores";

import { AppPage } from "@modules/layout/containers/AppPage";

import { StateFlowHeader } from "@modules/stateflows/components/core/StateFlowHeader";
import { StateFlowSidebar } from "@modules/stateflows/components/core/StateFlowSidebar";
import { StateFlowSummary } from "@modules/stateflows/components/core/StateFlowSummary";
import { StateFlowPublishDialog } from "@modules/stateflows/components/core/StateFlowPublishDialog";

import { StateFlowTabList } from "@modules/stateflows/containers/tabs/StateFlowTabList";

export const StateFlowViewerPage = withRouter(
    observer(({ match }) => {
        const { stateFlowStore, routing } = useStores();
        const { viewerStore, editorStore } = stateFlowStore;
        const { breadcrumb, stateFlow, manifest, summary, selectedTab, loading, error } = viewerStore;

        useEffect(() => {
            viewerStore.loadStateFlow(match.params.id);
        }, [match.params.id]);

        useEffect(() => {
            viewerStore.setSelectedTab(match.params.tab);
        }, [match.params.id, match.params.tab]);

        return (
            <AppPage loading={loading} error={error} breadcrumb={breadcrumb} hasSidebar={true} className="workflows view">
                {!loading && stateFlow && manifest && (
                    <div>
                        <StateFlowHeader
                            stateFlow={stateFlow}
                            manifest={manifest}
                            canEdit={() => true}
                            onEdit={(options) => {
                                if (!options || options.mode === "editor") {
                                    routing.push(`/settings/workflows/${stateFlow.id}/editor`);
                                }
                            }}
                            canPublish={() => stateFlow.currentVersion !== stateFlow.publishedVersion}
                            onPublish={() => editorStore.onSave({ mode: "Minor" }).then((stateFlow) => {})}
                        />

                        {summary && stateFlow && <StateFlowSummary stateFlow={stateFlow} summary={summary} />}

                        <StateFlowTabList selectedTab={selectedTab} stateFlow={stateFlow} manifest={manifest} />
                        <StateFlowSidebar stateFlow={stateFlow} manifest={manifest} />

                        <StateFlowPublishDialog
                            visible={editorStore.publishStore.visible}
                            value={editorStore.publishStore.value}
                            isDirty={editorStore.publishStore.isDirty}
                            isValid={editorStore.publishStore.isValid}
                            onSave={(options) => editorStore.publishStore.onSave(options)}
                            onCancel={() => editorStore.publishStore.onCancel()}
                        />
                    </div>
                )}
            </AppPage>
        );
    })
);
