import React, { useEffect, useCallback } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { useStores } from "@hooks/useStores";

import { Text } from "@ui/elements/Text";

import { AppPage } from "@modules/layout/containers/AppPage";

import { RiskTypeListView } from "@modules/risks/components/core/RiskTypeListView";
import { RiskNewPanel } from "@modules/risks/containers/panels/RiskNewPanel";

export const RiskCataloguePage = withRouter(
    observer(({ match }) => {
        const { riskStore, routing } = useStores();
        const { editStore, riskCatalogueStore } = riskStore;
        const { error, breadcrumb, loading, types } = riskCatalogueStore;

        useEffect(() => {
            riskCatalogueStore.loadTypes({});
        }, []);

        const onNewRisk = useCallback(
            (options) => {
                const o = Object.assign(
                    {
                        allowReview: false,
                        allowSubmit: false,
                        disableTargetType: true,
                        target: {
                            type: "Asset",
                        },
                    },
                    options
                );

                editStore.show(o);
            },
            [editStore]
        );

        return (
            <AppPage
                error={error}
                breadcrumb={breadcrumb}
                loading={loading}
                hasSidebar={false}
                className="risk types list"
            >
                <>
                    <Text variant="xLarge" block className="row--s">
                        New Risk
                    </Text>

                    <RiskTypeListView
                        loading={loading}
                        types={types}
                        onClick={(type) => {
                            onNewRisk({ type });
                        }}
                    />

                    <RiskNewPanel
                        saving={riskStore.lifecycleStore.saving}
                        onRiskCreated={(risk) => routing.push(`/risks/browse/${risk.id}`)}
                    />
                </>
            </AppPage>
        );
    })
);
