import React from "react";
import { observer } from "mobx-react";
import { Route, Switch } from "react-router";

import { RisksRegistriesTab } from "./RisksRegistriesTab";
import { RisksGeneralTab } from "./RisksGeneralTab";
import { RisksCatalogueTab } from "./RisksCatalogueTab";
import { ModuleWorkflowsTab } from "./ModuleWorkflowsTab";
import { ModuleAutomationsTab } from "./ModuleAutomationsTab";
import { RisksRecycleBinTab } from "./RisksRecycleBinTab";

export const RisksSettingsTabList = observer(({}) => {
    return (
        <Switch>
            <Route exact path="/settings/risks/registries" component={() => <RisksRegistriesTab />} />
            <Route exact path="/settings/risks/catalogue" component={() => <RisksCatalogueTab />} />
            <Route
                exact
                path="/settings/risks/automations"
                component={() => (
                    <ModuleAutomationsTab
                        context={{ type: "module", id: "4fe548f0-f670-4c66-817f-34c5fbcb5a82", label: "Risks" }}
                    />
                )}
            />{" "}
            <Route
                exact
                path="/settings/risks/workflows"
                component={() => <ModuleWorkflowsTab target="RiskLifecycle" />}
            />
            <Route exact path="/settings/risks/recycle-bin" component={() => <RisksRecycleBinTab />} />
            <Route path="/settings/risks" component={() => <RisksGeneralTab />} />
        </Switch>
    );
});
