import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

import { ObservableSeparator } from '../../../base/components/ObservableSeparator';

export const PartEditForm = observer(
    class PartEditForm extends Component {
        
        render () {
            const { formData, audiences } = this.props;

            return (
                <div>
                    <r-grid columns="2">
                        <r-cell span="2">
                            <ObservableSeparator>Display Settings</ObservableSeparator>
                        </r-cell>
                        <r-cell>
                            <div>
                                <Dropdown
                                    required
                                    label="Chrome"
                                    selectedKey={formData.chrome}
                                    onChange={(ev, op) => {
                                        if(op) {
                                            formData.chrome = op.key;
                                        }
                                    }}
                                    options={[
                                        {
                                            key: 'none',
                                            text: 'None'
                                        },
                                        {
                                            key: 'title-only',
                                            text: 'Title Only'
                                        },
                                        {
                                            key: 'border-only',
                                            text: 'Border Only',
                                        },
                                        {
                                            key: 'title-and-border',
                                            text: 'Title and Border',
                                        },
                                    ]}/>     
                            </div>
                        </r-cell>
                        <r-cell>
                            <div>
                                <Dropdown
                                    required
                                    label="Theme"
                                    selectedKey={formData.theme}
                                    onChange={(ev, op) => {
                                        if(op) {
                                            formData.theme = op.key;
                                        }
                                    }}
                                    options={[
                                        {
                                            key: 'none',
                                            text: 'None'
                                        },
                                        {
                                            key: 'transparent',
                                            text: 'Transparent'
                                        },
                                        {
                                            key: 'neutral',
                                            text: 'Neutral'
                                        },
                                        {
                                            key: 'soft',
                                            text: 'Soft',
                                        },
                                        {
                                            key: 'strong',
                                            text: 'Strong',
                                        }
                                    ]}/>     
                            </div>
                        </r-cell>
                        <r-cell>
                            <div>
                                <TextField 
                                    label="Width"
                                    value={formData.width}
                                    onChange={(ev, val) => formData.width = val} 
                                />
                            </div>
                        </r-cell>
                        <r-cell>
                            <div>
                                <TextField 
                                    label="Height"
                                    value={formData.height}
                                    onChange={(ev, val) => formData.height = val} 
                                />
                            </div>
                        </r-cell>
                        <r-cell>
                            <div>
                                <TextField 
                                    label="Tooltip"
                                    value={formData.properties.tooltip}
                                    onChange={(ev, val) => formData.properties.tooltip = val} 
                                />
                            </div>
                        </r-cell>
                    </r-grid>
                </div>
            );
        }
    }
);