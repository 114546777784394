import { observable, computed } from "mobx";

import { WorkItemService } from "../../../api/workitems";
import { WorkItemStore } from "./WorkItemStore";

export class WorkItemReviewPage {
    private workItemService: WorkItemService;
    public parentStore: WorkItemStore;

    @observable public selectedWorkItem: any;
    @observable public workItems: any[] = [];

    constructor(parentStore: WorkItemStore) {
        this.parentStore = parentStore;
        this.workItemService = parentStore.workItemService;
    }

    @computed public get breadcrumb(): any[] {
        const { workItem } = this.parentStore.selectionStore;
        const title = workItem ? workItem.code : "Loading...";
        const workItemId = workItem ? workItem.id : null;
        return [
            {
                text: "Demand Management",
                key: "demand",
                onClick: () => this.parentStore.rootStore.routing.push(`demand/dashboard`),
            },
            {
                text: "Browse Requests",
                key: "browse",
                onClick: () => this.parentStore.rootStore.routing.push(`/demand/browse`),
            },
            {
                text: title,
                key: "workItemId",
                onClick: () => this.parentStore.rootStore.routing.push(`/demand/${workItemId}`),
            },
            { text: "Review", key: "workItemReview", as: "h4", isCurrentItem: true },
        ];
    }
}
