import { observable, action, computed, flow } from "mobx";
//apis
import { WorkItemService } from "../../../api/workitems";
import { BoardsService } from "../../../api/boards";
import { IdentityService } from "../../../api/identity";
import { SystemService } from "../../../api/graph";

import { isGuest } from "../../identity/stores/PrincipalContextUtilities";

//ts types
import { KanbanBoard } from "../../base/components/kanbanboard/KanbanBoardType";
import { WorkItem } from "../../workitems/stores/types/WorkItem";
import { Board } from "./types/Board";
//stores
import { RootStore } from "../../base/RootStore";
import { BoardsDashboardStore } from "./BoardsDashboardStore";

export class BoardsStore {
    public workItemService: WorkItemService;
    public boardsService: BoardsService;
    public identityService: IdentityService;
    private systemService: SystemService;
    private toastVerb: string;
    public rootStore: RootStore;
    public dashboardStore: BoardsDashboardStore;

    @observable private boardsList: any[] = [];
    @observable private previousBoardState: KanbanBoard = null;
    @observable private loading: boolean = false;
    private selectedBoardReady: boolean = false;
    private draggableId: string = null;
    @observable public selectedBoard: Board;
    @observable public keywords: string = "";
    @observable public selectedWorkItem: WorkItem = null;
    @observable public showWorkItemDetailsPanel: boolean = false;
    @observable public showWorkItemEditPanel: boolean = false;
    @observable public showAssignDialog: boolean = false;
    @observable public showCloseDialog: boolean = false;
    @observable public showResolveDialog: boolean = false;
    @observable public showReOpenDialog: boolean = false;
    @observable public showCancelDialog: boolean = false;
    @observable public error: any = null;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.workItemService = new WorkItemService(rootStore.authProvider);
        this.boardsService = new BoardsService(rootStore.authProvider);
        this.dashboardStore = new BoardsDashboardStore(this, this.workItemService, this.boardsService);
        this.systemService = new SystemService(rootStore.authProvider);
        this.selectedBoardReady = false;
        this.loading = false;
    }

    public get isLoading() {
        return this.loading;
    }

    public get boards() {
        return this.boardsList;
    }

    @computed
    public get isGuestUser() {
        return isGuest(this.rootStore.principalContext.current);
    }

    public get selectedKanbanBoard() {
        if (this.selectedBoardReady) {
            return this.selectedBoard.kanbanBoard;
        } else {
            return null;
        }
    }

    @computed public get breadcrumb(): any[] {
        return [
            { text: "Demand Management", key: "demand", onClick: () => this.rootStore.routing.push(`/demand/dashboard`) },
            { text: "Demand Dashboard", key: "dashboard", onClick: () => this.rootStore.routing.push(`/demand/dashboard`) },
            { text: this.selectedBoard ? this.selectedBoard.name : "Loading...", key: "board", as: "h4", isCurrentItem: true },
        ];
    }

    showDetailPanel = flow(function* (workItem) {
        workItem.system = yield this.systemService.getSystem(workItem.assetId);
        this.selectedWorkItem = workItem;
        this.showWorkItemDetailsPanel = true;
    });

    @action public hideItemDetailsPanel() {
        this.showWorkItemDetailsPanel = false;
        this.showWorkItemEditPanel = false;
        this.selectedWorkItem = null;
    }

    @action public showEditPanel(workItem) {
        this.selectedWorkItem = workItem;
        this.showWorkItemEditPanel = true;
    }

    public set board(board) {
        this.selectedBoard = board;
    }

    @action public handleDrag(dragResults) {
        const { source, destination, draggableId } = dragResults;
        let card = null;
        const newColumns = Array.from(this.selectedBoard.kanbanBoard.columns);
        this.previousBoardState = Object.assign({}, this.selectedBoard.kanbanBoard);
        if (source.droppableId === destination.droppableId) {
            //dropped in the same column
            if (destination.index !== source.index) {
                const column = newColumns.find((C) => C.id === destination.droppableId);
                const columnIndex = newColumns.indexOf(column);
                const newCards = Array.from(column.cards);
                card = newCards.splice(source.index, 1);
                newCards.splice(destination.index, 0, card[0]);
                newCards.forEach((C, IDX) => (C.order = IDX));
                const newColumn = {
                    ...column,
                    cards: newCards,
                };
                newColumns.splice(columnIndex, 1, newColumn);
            }
        } else {
            //dropped in a different column
            //1 remove the card from the start column
            const startColumn = newColumns.find((C) => C.id === source.droppableId);
            const startColumnIndex = newColumns.indexOf(startColumn);
            const startCards = Array.from(startColumn.cards);
            card = startCards.splice(source.index, 1);
            startCards.forEach((C, IDX) => (C.order = IDX));
            const newStartColumn = {
                ...startColumn,
                cards: startCards,
            };
            newColumns.splice(startColumnIndex, 1, newStartColumn);

            //2 add the card to the destination column
            const endColumn = newColumns.find((C) => C.id === destination.droppableId);
            const endColumnIndex = newColumns.indexOf(endColumn);
            const endCards = Array.from(endColumn.cards);
            endCards.splice(destination.index, 0, card[0]);
            const newEndColumn = {
                ...endColumn,
                cards: endCards,
            };
            newColumns.splice(endColumnIndex, 1, newEndColumn);
        }
        //updating the board on the UI
        this.selectedBoard = {
            ...this.selectedBoard,
            kanbanBoard: {
                ...this.selectedBoard.kanbanBoard,
                columns: Object.assign([], newColumns),
            },
        };
        this.draggableId = draggableId;
        //CHECK FOR DIALOG:
        switch (destination.droppableId) {
            case "inProgress":
                if (card[0].label === "Open")
                    this.workItemService.updateWorkItemStatus(this.draggableId, "start", null).then(this.onUpdateStatusSuccess, this.onUpdateStatusError);
                if (card[0].label === "Assigned")
                    this.workItemService.updateWorkItemStatus(this.draggableId, "start", null).then(this.onUpdateStatusSuccess, this.onUpdateStatusError);
                if (card[0].label === "Resolved") this.showReOpenDialog = true;
                break;
            case "resolved":
                if (card[0].label === "InProgress") this.showResolveDialog = true;
                break;
            case "closed":
                if (card[0].label === "Assigned") this.showCancelDialog = true;
                if (card[0].label === "Open") this.showCancelDialog = true;
                if (card[0].label === "InProgress") this.showCancelDialog = true;
                if (card[0].label === "Blocked") this.showCancelDialog = true;
                if (card[0].label === "Resolved") this.showCloseDialog = true;
                break;
        }
    }

    @action public openAssignDialog(workItem) {
        this.previousBoardState = Object.assign({}, this.selectedBoard.kanbanBoard);
        this.draggableId = workItem.id;
        this.showAssignDialog = true;
    }

    @action public onCloseAssignDialogue() {
        this.showAssignDialog = false;
    }

    confirmAssign = flow(function* (person: any, group: any) {
        this.showAssignDialog = false;
        if (person.id && group.id) {
            try {
                const result = yield this.workItemService.assignWorkItem({
                    id: this.draggableId,
                    assignedToGroup: {
                        id: group.id,
                    },
                    assignedToUser: {
                        id: person.id,
                    },
                });
                this.onUpdateStatusSuccess(result);
            } catch (e) {
                console.error(e);
                return e;
            }
        } else {
            console.error("Expected person.id and group.id to have a value");
            return new Error("Expected person.id and group.id to have a value");
        }
    });

    @action public setToast(state) {
        let verb = "";
        switch (state) {
            case "start":
                verb = "started";
                break;
            case "resolve":
                verb = "resolved";
                break;
            case "reopen":
                verb = "opened";
                break;
            case "block":
                verb = "blocked";
                break;
            case "unblock":
                verb = "unblocked";
                break;
            case "cancel":
                verb = "cancelled";
                break;
            case "close":
                verb = "closed";
                break;
        }
        this.toastVerb = verb;
    }

    confirmStatusChange = flow(function* (message: any, status: string) {
        this.showResolveDialog = false;
        try {
            let commentObject = null;
            if (!(message && message.length === 1 && message[0].children[0].text === "")) {
                commentObject = {
                    content: message,
                    sourceId: this.draggableId,
                    internalOnly: false,
                };
            }
            this.setToast(status);
            const result = yield this.workItemService.updateWorkItemStatus(this.draggableId, status, commentObject);
            this.onUpdateStatusSuccess(result);
        } catch (e) {
            console.error(e);
            return e;
        }
    });

    @action.bound public onUpdateStatusSuccess(result) {
        let newBoard = Object.assign({}, this.selectedBoard.kanbanBoard);
        const newColumns = Object.assign([], newBoard.columns);
        if (this.toastVerb)
            this.rootStore.layoutStore.displayToastNotification(`Request ${result.code} has been ${this.toastVerb} and stakeholders have been notified.`);
        newColumns.forEach((COLUMN) => {
            COLUMN.cards.forEach((CARD) => {
                if (CARD.id === result.id) {
                    CARD.workItem = Object.assign({}, result);
                    CARD.label = CARD.workItem.status;
                }
            });
        });
        newBoard = {
            ...newBoard,
            columns: newColumns,
        };
        this.selectedBoard.kanbanBoard = Object.assign({}, newBoard);
        this.closeAllDialogs();
        return true;
    }

    @action.bound
    public onUpdateStatusError(error) {
        console.error(error);
        return error;
    }

    @action public cancelMovingCard() {
        this.selectedBoard.kanbanBoard = Object.assign({}, this.previousBoardState);
        this.closeAllDialogs();
    }

    @action public closeAllDialogs() {
        this.showAssignDialog = false;
        this.showResolveDialog = false;
        this.showCloseDialog = false;
        this.showCancelDialog = false;
        this.showReOpenDialog = false;
    }

    selectBoard = flow(function* (id: string, query: any) {
        this.error = null;
        this.selectedBoardReady = false;
        this.loading = true;
        try {
            //1 load board informations
            const results = yield this.boardsService.getBoard(id);
            this.selectedBoard = {
                id: results.id,
                name: results.name,
                created: new Date(results.created).getTime(),
                createdBy: results.createdBy,
                description: results.description,
                display: results.display,
                modified: new Date(results.modified).getTime(),
                modifiedBy: results.modifiedBy,
                query: results.query,
                kanbanBoard: null,
                workItems: [],
            };
            //2 load boards items
            const options = Object.assign({ page: 1, keywords: null }, query);
            const searchOptions = {
                pageSize: 25,
                startIndex: (options.page - 1) * 25,
                keywords: options.keywords,
            };
            const workItemsResults = yield this.boardsService.getWorkItemsFromABoard(this.selectedBoard.id, searchOptions);
            this.loadWorkItemsOnSuccess(workItemsResults);
        } catch (e) {
            console.error(e);
            this.selectedBoardReady = false;
            this.loading = false;
            this.error = e;
        }
    });

    @action.bound public loadWorkItemsOnSuccess(result) {
        this.loading = false;
        this.selectedBoardReady = true;
        this.keywords = result.keywords;
        this.selectedBoard.workItems = result.items;
        const kanbanBoard = {
            columns: [
                {
                    id: "open",
                    title: "Open",
                    label: "0/0",
                    cards: [],
                    allowedDrops: [],
                },
                {
                    id: "inProgress",
                    title: "In Progress",
                    label: "0/0",
                    cards: [],
                    allowedDrops: ["Assigned", "Open", "Resolved"],
                },
                {
                    id: "resolved",
                    title: "Resolved",
                    label: "0/0",
                    cards: [],
                    allowedDrops: ["InProgress"],
                },
                {
                    id: "closed",
                    title: "Closed",
                    label: "0/0",
                    cards: [],
                    allowedDrops: ["Assigned", "Open", "InProgress", "Blocked", "Resolved"],
                },
            ],
        };
        result.items.forEach((workItem) => {
            let column = null;
            switch (workItem.status) {
                case "InProgress":
                case "Blocked":
                    column = kanbanBoard.columns.find((column) => column.id === "inProgress");
                    break;
                case "Completed":
                case "Cancelled":
                case "Closed":
                    column = kanbanBoard.columns.find((column) => column.id === "closed");
                    break;
                case "Resolved":
                    column = kanbanBoard.columns.find((column) => column.id === "resolved");
                    break;
                case "NotStarted":
                case "Assigned":
                default:
                    column = kanbanBoard.columns.find((column) => column.id === "open");
                    break;
            }
            if (column) {
                column.cards.push({
                    id: workItem.id,
                    title: workItem.title,
                    description: workItem.description,
                    label: workItem.status,
                    draggable: true,
                    workItem: workItem,
                    order: column.cards.length,
                });
                column.label = `${column.cards.length}/${column.cards.length}`;
            }
        });
        this.selectedBoard.kanbanBoard = Object.assign({}, kanbanBoard);
    }

    public loadBoards = flow(function* (query) {
        const options = Object.assign({ page: 1, keywords: null, cached: false }, query);

        if (options.cached && this.boardsList.length > 0) {
            return;
        }

        this.error = null;
        const searchOptions = {
            pageSize: 25,
            startIndex: (options.page - 1) * 25,
            keywords: options.keywords,
        };
        this.loading = true;
        try {
            const results = yield this.boardsService.getBoards().then(this.loadBoardsOnSuccess, this.loadBoardsError);
            this.boardsList = results;
        } catch (e) {
            console.error(e);
            this.error = e;
        } finally {
            this.loading = false;
        }
    });
}
