import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";

import { Stack } from "office-ui-fabric-react/lib/Stack";
import { PrimaryButton, ActionButton } from "office-ui-fabric-react/lib/Button";

import { AppPage } from "../../../layout/containers/AppPage";
import { BoxPanel } from "../../../base/components/BoxPanel";

import { RiskEditForm } from "../../components/core/RiskEditForm";
import { ExceptionSideNav } from "../../components/exceptions/ExceptionSideNav";
import { ObservableBadge } from "../../../base/components/ObservableBadge";

export const ExceptionNewPage = inject(
    "pageContext",
    "riskStore",
    "routing"
)(
    withRouter(
        observer(
            class ExceptionNewPage extends Component {
                componentWillMount() {
                    this.loadData(this.props);
                }

                componentWillReceiveProps(nextProps) {
                    this.loadData(nextProps);
                }

                loadData(props) {
                    const { exceptionNewStore } = props.riskStore;
                    exceptionNewStore.loadNewForm({ typeId: props.match.params.id });
                }

                render() {
                    const { riskStore, routing } = this.props;
                    const { loading, error, breadcrumb, exceptionType } = riskStore.exceptionNewStore;
                    const { formData, formOptions, isValid } = riskStore.editStore;

                    return (
                        <AppPage error={error} breadcrumb={breadcrumb} loading={loading} hasSidebar={false} className="risks new has--page-nav">
                            <ExceptionSideNav types={riskStore.typeStore.exceptionTypes} onLinkClick={(item) => this.props.routing.push(item.url)} />
                            <BoxPanel padding>
                                {exceptionType && (
                                    <div className="row--xs">
                                        <ObservableBadge>{exceptionType.name}</ObservableBadge>
                                    </div>
                                )}
                                {formData && (
                                    <div className="row--xs">
                                        <RiskEditForm formData={formData} formOptions={formOptions} isValid={isValid} />
                                    </div>
                                )}
                                <Stack horizontal horizontalAlign="space-between">
                                    <Stack horizontal tokens={{ childrenGap: 15 }}></Stack>
                                    <Stack horizontal tokens={{ childrenGap: 15 }}>
                                        <PrimaryButton
                                            onClick={() => this._onSaveRisk()}
                                            iconProps={{ iconName: "Save" }}
                                            text="Save"
                                            disabled={!isValid || this.props.saving}
                                        />
                                    </Stack>
                                </Stack>
                            </BoxPanel>
                        </AppPage>
                    );
                }

                _onSaveRisk(submit) {
                    const { editStore } = this.props.riskStore;
                    const { formData, isValid } = editStore;

                    if (isValid) {
                        formData.submit = !!submit;
                        this.props.riskStore.createRisk(formData).then((risk) => {
                            editStore.hide({ clearData: true });
                            this.props.routing.push(`/exceptions/browse/${risk.id}`);
                        });
                    }
                }
            }
        )
    )
);
