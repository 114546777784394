import { observable, flow, computed, action } from "mobx";

import { AssetService } from "../../../api/assets";
import { AssetStore } from "./AssetStore";

export class AssetViewerStore {
    private parentStore: AssetStore;
    private assetService: AssetService;

    @observable public loading: boolean = false;
    @observable public error: any;
    @observable public selectedTab: string;

    constructor(parentStore: AssetStore) {
        this.parentStore = parentStore;
        this.assetService = parentStore.assetService;
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [];

        builder.push({
            text: "Asset Management",
            key: "assets",
            onClick: () => this.parentStore.rootStore.routing.push(`/assets/dashboard`),
        });
        builder.push({
            text: "Browse Assets",
            key: "assets-browse",
            onClick: () => this.parentStore.rootStore.routing.push(`/assets/browse`),
        });

        if (!this.asset) {
            return builder;
        }

        builder.push({
            text: this.asset.name,
            key: this.asset.id,
            isCurrentItem: true,
        });

        return builder;
    }

    @computed
    public get asset() {
        return this.parentStore.selectionStore.asset;
    }

    @action
    public setAsset(asset) {
        this.parentStore.selectionStore.setAsset(asset);
    }

    @action
    public setSelectedTab(tab) {
        this.selectedTab = tab;
    }

    public loadAsset = flow(function* (id: string) {
        this.error = null;

        if (!this.asset || this.asset.id !== id) {
            this.loading = true;
            try {
                yield this.parentStore.selectionStore.loadAsset(id);
            } catch (error) {
                console.error(error);
                this.error = error;
            } finally {
                this.loading = false;
            }
        }
    });
}
