import React from "react";
import { observer } from "mobx-react";

import { AdaptiveField } from "@modules/base/components/AdaptiveField";

export const AssessmentContactList = observer(({ assessment, workItem }) => {
    return (
        <r-grid columns="2" class="no--margin">
            {workItem && (
                <>
                    <r-cell>
                        <div className="row--xs">
                            <AdaptiveField
                                type="Principal"
                                mode="view"
                                label="Project Business Contact"
                                value={workItem.businessContact}
                            />
                        </div>
                    </r-cell>
                    <r-cell>
                        <div className="row--xs">
                            <AdaptiveField
                                type="Principal"
                                mode="view"
                                label="Project Technical Contact"
                                value={workItem.technicalContact}
                            />
                        </div>
                    </r-cell>
                </>
            )}
            {assessment && (
                <>
                    <r-cell>
                        <div className="row--xs">
                            <AdaptiveField
                                type="Principal"
                                mode="view"
                                label="Portfolio Business Contact"
                                value={assessment.portfolioContact}
                            />
                        </div>
                    </r-cell>
                    <r-cell>
                        <div className="row--xs">
                            <AdaptiveField
                                type="Principal"
                                mode="view"
                                label="Portfolio Technical Contact"
                                value={assessment.portfolioTechnical}
                            />
                        </div>
                    </r-cell>
                </>
            )}
        </r-grid>
    );
});
