import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';

import { ColumnHeader } from './ColumnHeader';

const ColumnClassName = cx(
    'dndColumn',
        css`
        display: flex;
        flex-direction: column;
        `
);
const ColumnClassNameDraggedOver = cx(
    'dndColumn',
        css`
        background-color: rgb(250,250,220);
        margin-right:5px;
        display: flex;
        flex-direction: column;
        `
);

export const Column = observer(
    class Column extends Component {
        render() {
            const { provided, innerRef, children, isDraggingOver, title, label } = this.props;
            return (
                <div 
                    {...provided.droppableProps} 
                    ref={innerRef}
                    style={{padding: '5px', width: '25%'}}
                >
                    <ColumnHeader 
                        title={title}
                        label={label}
                    />
                    <div className={isDraggingOver ? ColumnClassNameDraggedOver: ColumnClassName}>
                        {children}
                    </div>
              </div>
            );
          }
    }
);