import React, { Component } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { Text } from "office-ui-fabric-react/lib/Text";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { List } from "office-ui-fabric-react/lib/List";
import { FocusZone, FocusZoneDirection } from "office-ui-fabric-react/lib/FocusZone";
import { Shimmer } from "office-ui-fabric-react/lib/Shimmer";
import { Droppable, Draggable } from "react-beautiful-dnd";

import { ShimmerCard } from "../../../base/components/ShimmerCard";

const rootClassName = cx(
    "cygraph-RiskBoardColumn-root",
    css`
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        & .column-header {
            margin-bottom: var(--spacing-xs);
            padding: 0 0 var(--spacing-xxs) 0;
            border-bottom: 1px solid var(--border-color);
            display: flex;

            & > span {
                font-weight: 600;
            }

            & .title {
                flex-grow: 1;
                margin-bottom: 0;
            }

            & .destination {
                display: none;
            }

            & .source {
                display: none;
            }
        }

        & .droppable {
            flex-grow: 1;
            min-height: 200px;

            &.dragging-over .column-header .destination {
                color: var(--green-10);
                display: block;
            }

            &.dragging-from .column-header .source {
                color: var(--blue-10);
                display: block;
            }

            &.dragging-from .column-header .destination {
                display: none;
            }
        }
    `
);

export const RiskBoardColumn = observer(
    class RiskBoardColumn extends Component {
        render() {
            const { id, title, items, loading, isDropDisabled, isDragDisabled, className, onRenderCard } = this.props;

            return (
                <div className={cx(className, rootClassName)}>
                    <Droppable droppableId={id} isDropDisabled={isDropDisabled(id)}>
                        {(provided, snapshot) => (
                            <div
                                className={cx("droppable", snapshot.isDraggingOver && "dragging-over", snapshot.draggingFromThisWith && "dragging-from")}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                <div className="column-header">
                                    <Text className="title" variant="medium">
                                        {title}
                                    </Text>
                                    <Icon className="source" iconName="RadioBtnOn" />
                                    <Icon className="destination" iconName="CompletedSolid" />
                                </div>
                                <FocusZone direction={FocusZoneDirection.vertical}>
                                    <div className="container" data-is-scrollable={true}>
                                        <Shimmer customElementsGroup={this._getCustomElements()} isDataLoaded={!loading}>
                                            <List
                                                items={items}
                                                onRenderCell={(item, index, isScrolling) => {
                                                    return (
                                                        <div key={item.id}>
                                                            <Draggable draggableId={item.id} index={index} isDragDisabled={isDragDisabled}>
                                                                {(provided, snapshot) => (
                                                                    <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                                        {onRenderCard(item, snapshot, this.props)}
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </Shimmer>
                                    </div>
                                </FocusZone>
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>
            );
        }

        _getCustomElements = () => {
            const cards = parseInt(Math.random() * 3 + 2);
            return Array.from({ length: cards }, (v, i) => Math.random()).map((r, i) => {
                return <ShimmerCard key={i} lines={r < 0.35 ? 2 : 1} />;
            });
        };
    }
);
