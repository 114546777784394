import React, { Component } from 'react';

import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';

export class ErrorDialog extends Component {

    close = () => {
        const { onDismiss } = this.props;        
        if (onDismiss) onDismiss();
        else window.location.reload();
    }

    render () {
        const { title, subText, hidden } = this.props;
        return (
            <Dialog
                hidden={hidden}
                onDismiss={()=>{}}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: title,
                    subText: subText,
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } }
                }}
            >
            <DialogFooter>
                <PrimaryButton onClick={this.close} text="Close" />
            </DialogFooter>
        </Dialog>
        );
    }
};