import React from "react";
import { observer } from "mobx-react-lite";

import { BoxPanel } from "@modules/base/components/BoxPanel";

import { StateFlowEditorView } from "@modules/stateflows/components/editor/StateFlowEditorView";

export const StateFlowSummaryTab = observer(({ stateFlow, manifest }) => {
    return (
        <BoxPanel shadow padding style={{ padding: 15 }}>
            <StateFlowEditorView stateFlow={stateFlow} manifest={manifest} mode="preview" />
        </BoxPanel>
    );
});
