import { registerIcons } from "office-ui-fabric-react/lib/Styling";

/*
    To turn css listing into json object use the regex substitution tool at https://regex101.com/
    Expression:  \.(flaticon-\d{3}-[\w-]+):[.\w\s{]*:\s"\\f([a-z0-9]*)";[.\w\s]*}
    Substitution: "$1": "\\uF$2",
*/
export function initializeFlatIcons1(baseUrl = "/fonts/", options) {
    const subset = {
        style: {
            MozOsxFontSmoothing: "grayscale",
            WebkitFontSmoothing: "antialiased",
            fontStyle: "normal",
            fontWeight: "normal",
            speak: "none",
        },
        fontFace: {
            fontFamily: `"Flaticon1"`,
            src: `url("${baseUrl}Flaticon1.woff") format("woff"), url("${baseUrl}Flaticon1.ttf") format("truetype"), url("${baseUrl}Flaticon1.svg#Flaticon") format("svg")`,
        },
        icons: {
            "flaticon-001-account": "\uF100",
            "flaticon-002-attention": "\uF101",
            "flaticon-003-cctv": "\uF102",
            "flaticon-004-cloud": "\uF103",
            "flaticon-005-computer": "\uF104",
            "flaticon-006-cookies": "\uF105",
            "flaticon-007-credit-card": "\uF106",
            "flaticon-008-data-protection": "\uF107",
            "flaticon-009-data-security": "\uF108",
            "flaticon-010-disabled": "\uF109",
            "flaticon-011-door-handle": "\uF10a",
            "flaticon-012-download": "\uF10b",
            "flaticon-013-encryption": "\uF10c",
            "flaticon-014-error": "\uF10d",
            "flaticon-015-eye-scan": "\uF10e",
            "flaticon-016-find": "\uF10f",
            "flaticon-017-fingerprint": "\uF110",
            "flaticon-018-firewall": "\uF111",
            "flaticon-019-ftp": "\uF112",
            "flaticon-020-gdpr": "\uF113",
            "flaticon-021-hacker": "\uF114",
            "flaticon-022-harddisk": "\uF115",
            "flaticon-023-key": "\uF116",
            "flaticon-024-laptop": "\uF117",
            "flaticon-025-lock": "\uF118",
            "flaticon-026-money": "\uF119",
            "flaticon-027-network": "\uF11a",
            "flaticon-028-lock": "\uF11b",
            "flaticon-029-password": "\uF11c",
            "flaticon-030-pattern": "\uF11d",
            "flaticon-031-phishing": "\uF11e",
            "flaticon-032-police-badge": "\uF11f",
            "flaticon-033-privacy": "\uF120",
            "flaticon-034-problem": "\uF121",
            "flaticon-035-protection": "\uF122",
            "flaticon-036-secure-payment": "\uF123",
            "flaticon-037-settings": "\uF124",
            "flaticon-038-shield": "\uF125",
            "flaticon-039-smartphone": "\uF126",
            "flaticon-040-spam": "\uF127",
            "flaticon-041-spy": "\uF128",
            "flaticon-042-transfer": "\uF129",
            "flaticon-043-umbrella": "\uF12a",
            "flaticon-044-unlock": "\uF12b",
            "flaticon-045-update": "\uF12c",
            "flaticon-046-user": "\uF12d",
            "flaticon-047-virus": "\uF12e",
            "flaticon-048-vpn": "\uF12f",
            "flaticon-049-warning": "\uF130",
            "flaticon-050-web-security": "\uF131",
        },
    };

    registerIcons(subset, options);
}

export function initializeFlatIcons2(baseUrl = "/fonts/", options) {
    const subset = {
        style: {
            MozOsxFontSmoothing: "grayscale",
            WebkitFontSmoothing: "antialiased",
            fontStyle: "normal",
            fontWeight: "normal",
            speak: "none",
        },
        fontFace: {
            fontFamily: `"Flaticon2"`,
            src: `url("${baseUrl}Flaticon2.woff") format("woff"), url("${baseUrl}Flaticon2.ttf") format("truetype"), url("${baseUrl}Flaticon2.svg#Flaticon") format("svg")`,
        },
        icons: {
            "flaticon-001-shopping-bag": "\uF100",
            "flaticon-002-antivirus": "\uF101",
            "flaticon-003-hacker": "\uF102",
            "flaticon-004-antivirus": "\uF103",
            "flaticon-005-antivirus": "\uF104",
            "flaticon-006-chip": "\uF105",
            "flaticon-007-cloud": "\uF106",
            "flaticon-008-cloud": "\uF107",
            "flaticon-009-cloud": "\uF108",
            "flaticon-010-cloud": "\uF109",
            "flaticon-011-computer": "\uF10a",
            "flaticon-012-calendar": "\uF10b",
            "flaticon-013-document": "\uF10c",
            "flaticon-014-emergency": "\uF10d",
            "flaticon-015-folder": "\uF10e",
            "flaticon-016-folder": "\uF10f",
            "flaticon-017-global": "\uF110",
            "flaticon-018-global": "\uF111",
            "flaticon-019-hacker": "\uF112",
            "flaticon-020-hacker": "\uF113",
            "flaticon-021-hacker2": "\uF114",
            "flaticon-022-hacker": "\uF115",
            "flaticon-023-hacker": "\uF116",
            "flaticon-024-home": "\uF117",
            "flaticon-025-internet": "\uF118",
            "flaticon-026-house": "\uF119",
            "flaticon-027-key": "\uF11a",
            "flaticon-028-gear": "\uF11b",
            "flaticon-029-key": "\uF11c",
            "flaticon-030-key": "\uF11d",
            "flaticon-031-laptop": "\uF11e",
            "flaticon-032-laptop": "\uF11f",
            "flaticon-033-laptop": "\uF120",
            "flaticon-034-laptop": "\uF121",
            "flaticon-035-lock": "\uF122",
            "flaticon-036-lock": "\uF123",
            "flaticon-037-lock": "\uF124",
            "flaticon-038-lock": "\uF125",
            "flaticon-039-malware": "\uF126",
            "flaticon-040-smartphone": "\uF127",
            "flaticon-041-page": "\uF128",
            "flaticon-042-password": "\uF129",
            "flaticon-043-password": "\uF12a",
            "flaticon-044-password": "\uF12b",
            "flaticon-045-password": "\uF12c",
            "flaticon-046-safe": "\uF12d",
            "flaticon-047-safe": "\uF12e",
            "flaticon-048-bug": "\uF12f",
            "flaticon-049-lock": "\uF130",
            "flaticon-050-webpage": "\uF131",
            "flaticon-051-secure": "\uF132",
            "flaticon-052-security-camera": "\uF133",
            "flaticon-053-server": "\uF134",
            "flaticon-054-shield": "\uF135",
            "flaticon-055-shield": "\uF136",
            "flaticon-056-shield": "\uF137",
            "flaticon-057-smartphone": "\uF138",
            "flaticon-058-shield": "\uF139",
            "flaticon-059-webpage": "\uF13a",
            "flaticon-060-webpage": "\uF13b",
            "flaticon-061-webpage": "\uF13c",
            "flaticon-062-target": "\uF13d",
            "flaticon-063-target": "\uF13e",
            "flaticon-064-hourglass": "\uF13f",
            "flaticon-065-hourglass": "\uF140",
            "flaticon-066-usb": "\uF141",
            "flaticon-067-user": "\uF142",
            "flaticon-068-view": "\uF143",
            "flaticon-069-view": "\uF144",
            "flaticon-070-virus": "\uF145",
            "flaticon-071-hacker": "\uF146",
            "flaticon-072-password": "\uF147",
            "flaticon-073-web": "\uF148",
            "flaticon-074-shield": "\uF149",
        },
    };

    registerIcons(subset, options);
}
