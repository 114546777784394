import React from "react";
import { observer } from "mobx-react";
import moment from "moment";

import { Text } from "@ui/elements/Text";
import { Stack } from "@ui/elements/Stack";

import { EntityIcon } from "@modules/base/components/EntityIcon";
import { EntityLink } from "@modules/base/components/EntityLink";

export const CombinedSearchItemView = observer(({ className, item, includeRole }) => {
    return (
        <div className={className}>
            <Stack horizontal tokens={{ childrenGap: 15 }}>
                <Stack.Item>
                    <EntityIcon type={item.entity} size={40} />
                </Stack.Item>
                <Stack.Item grow>
                    <EntityLink type={item.entity} id={item.id}>
                        <Text block variant="medium">
                            {item.code} - {item.title}
                        </Text>
                    </EntityLink>
                    <Text block variant="small" className="description">
                        Last activity {moment.utc(item.modified).format("DD MMM YYYY")} by {item.modifiedBy.name} -{" "}
                        {item.status}
                    </Text>
                    <Text block variant="small" className="description">
                        {item.type}
                    </Text>
                </Stack.Item>
                {includeRole && (
                    <Stack.Item>
                        <Text block variant="small" className="role">
                            {item.role}
                        </Text>
                    </Stack.Item>
                )}
            </Stack>
        </div>
    );
});
