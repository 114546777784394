import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router';

import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { PanelType } from 'office-ui-fabric-react/lib/Panel';
import { PrimaryButton, DefaultButton, IconButton } from 'office-ui-fabric-react/lib/Button';

import { ObservablePanel } from '../../../base/components/ObservablePanel';

import { PropertyEditForm } from '../../components/core/PropertyEditForm';

export const PropertyEditPanel = inject('propertyStore')(withRouter(observer(
    class PropertyEditPanel extends Component {
        
        render () {
            const store = this.props.propertyStore.editStore;
            const { visible, formData, formOptions, isValid } = store;
            
            return (
                <ObservablePanel
                    hidden={!visible}
                    pageType="Edit Property"
                    panelType={PanelType.medium}
                    onClosePanel={() => this._onCancel(false)}
                    onRenderFooter={() => this._renderFooter()}>
                    { formData && ( 
                        <PropertyEditForm 
                            formData={formData}
                            formOptions={formOptions}
                            isValid={isValid} />
                    ) }
                </ObservablePanel>
            );
        }

        _renderFooter() {
            const store = this.props.propertyStore.editStore;
            const { isValid, isDirty } = store;

            return (
                <Stack horizontal horizontalAlign="space-between">
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <PrimaryButton onClick={() => this._onSave()} iconProps={{iconName: 'Save'}} text="Save" disabled={!isValid} />
                        { isDirty && <IconButton iconProps={{iconName: 'Undo'}} onClick={() => {store.resetFormData()}} text="Undo Changes" /> }
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <DefaultButton onClick={() => this._onCancel(false)} iconProps={{iconName: 'Cancel'}} text="Cancel" />
                    </Stack>
                </Stack>
            );
        }

        _onSave() {
            const { propertyStore, onAfterSave } = this.props;
            const store = propertyStore.editStore;

            Promise.resolve(store.updateProperty()).then((axis) => {
                if(onAfterSave) {
                    Promise.resolve(onAfterSave(axis))
                        .then(() => store.hide());
                } else {
                    store.hide();
                }
            });
        }

        _onCancel(dismiss) {
            const { propertyStore, onCancel } = this.props;
            const store = propertyStore.editStore;

            if(onCancel) {
                Promise.resolve(onCancel(dismiss))
                    .then(() => store.hide());
            } else {
                store.hide();
            }
        }
    }
)));