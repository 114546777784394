import React, { Component } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";
import Moment from "react-moment";

import { Card } from "@uifabric/react-cards";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { Image, ImageFit } from "office-ui-fabric-react/lib/Image";
import { Text } from "office-ui-fabric-react/lib/Text";

const rootClassName = cx(
    "cygraph-ControlMapCardView-root",
    css`
        & .map {
            background-color: var(--white);
            max-width: 100%;
            &:focus {
                outline: none;
            }
        }

        & .image {
            background-color: var(--border-color);
        }

        & .version {
            color: var(--text-color-lighter);
        }

        & .description {
            color: var(--text-color-lighter);
            min-height: 110px;
        }

        & .footer {
            border-top: 1px solid var(--border-color);
            padding-top: 10px;
        }
    `
);

export const ControlMapCardView = observer(
    class ControlMapCardView extends Component {
        render() {
            const { className, loading, maps } = this.props;
            const cardTokens = { childrenMargin: 15 };

            return loading || !maps ? null : (
                <div className={cx(rootClassName, className)}>
                    <r-grid columns="3" columns-l="4">
                        {maps.map((map) => (
                            <r-cell key={map.id}>
                                <Card
                                    className="map"
                                    aria-label="Clickable vertical card with image bleeding at the top of the card"
                                    onClick={(ev) => this._onControlMapClick(ev, map)}
                                    tokens={cardTokens}
                                >
                                    <Card.Item fill>
                                        <Image
                                            className="image"
                                            src={map.image || "/images/logos/zerodai.png"}
                                            width="100%"
                                            height={225}
                                            imageFit={ImageFit.centerContain}
                                            alt={map.name}
                                        />
                                    </Card.Item>
                                    <Card.Section style={{ minHeight: 200 }}>
                                        <Card.Section>
                                            <Text className="version" variant="small">
                                                {map.category}
                                            </Text>
                                            <Text className="name" variant="large">
                                                {map.name}
                                            </Text>
                                        </Card.Section>
                                        <Card.Item grow>
                                            <Text className="description" variant="small">
                                                {map.description || "N/A"}
                                            </Text>
                                        </Card.Item>
                                        <Card.Item>
                                            <Text className="published" variant="small">
                                                <Moment utc date={map.modified} format="Do MMM YYYY" />
                                            </Text>
                                        </Card.Item>
                                    </Card.Section>
                                    <Card.Section horizontal className="footer">
                                        <Text className="version" variant="small">
                                            v{map.version}
                                        </Text>
                                        <Stack.Item grow={1}>
                                            <span />
                                        </Stack.Item>
                                        <Icon iconName="MoreVertical" />
                                    </Card.Section>
                                </Card>
                            </r-cell>
                        ))}
                    </r-grid>
                </div>
            );
        }

        _onControlMapClick = (ev, item) => {
            const { onControlMapClick } = this.props;
            ev.preventDefault();
            if (item && onControlMapClick) {
                onControlMapClick(item, ev);
            }
        };
    }
);
