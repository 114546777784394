import { observable, action, computed } from "mobx";

import { TargetMetadata, isTargetEndState } from "./TargetMetadata";
import { StateFlowEditorStore } from "./StateFlowEditorStore";

export class StateFlowStateEditStore {
    public parentStore: StateFlowEditorStore;

    @observable public formData: any = null;
    @observable public formOptions: any = null;
    @observable public snapshot: any = null;
    @observable public title: string = null;
    @observable public visible: boolean = false;
    @observable public processing: boolean = false;

    public options: any;

    constructor(parentStore: StateFlowEditorStore) {
        this.parentStore = parentStore;
    }

    @computed
    public get isDirty() {
        return JSON.stringify(this.snapshot) != JSON.stringify(this.formData);
    }

    @computed
    public get isValid() {
        return this.formData && this.formData.id && this.formData.stage && this.formData.name && this.formData.mappedTo;
    }

    @action
    public show(options) {
        const promise = new Promise((resolve, reject) => {
            this.options = {
                resolve,
                reject,
            };
        });

        this.title = options.title;
        this.snapshot = options.value;
        this.formData = JSON.parse(JSON.stringify(options.value));

        const indexOfStage = options.manifest.stages.findIndex((s) => s.id === this.formData.stage);
        const stage = indexOfStage === 0 ? "start" : indexOfStage + 1 === options.manifest.stages.length ? "end" : "middle";

        this.formOptions = {
            mappedToEditable: options.mappedToEditable,
            states: (TargetMetadata[options.manifest.target].states || []).filter((s) => s.stages.indexOf(stage) !== -1),
            enableTrigger: !isTargetEndState(options.manifest.target, this.formData),
        };

        this.visible = true;
        this.processing = false;

        return promise;
    }

    @action
    public hide(options) {
        this.options = null;
        this.title = null;
        this.formData = null;
        this.formOptions = null;
        this.visible = false;
        this.processing = false;
    }

    @action
    public onChange(state) {
        this.formData = state;
    }

    @action
    public resolve(success, options) {
        this.processing = success;
        this.options.resolve({ ...options, success, value: this.formData });
    }

    @action
    public reject(error) {
        this.options.reject({ success: false, error });
    }
}
