import React, { Component } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { Shimmer, ShimmerElementsGroup, ShimmerElementType } from "office-ui-fabric-react/lib/Shimmer";
import { Stack } from "office-ui-fabric-react/lib/Stack";

const rootClassName = cx(
    "cygraph-ShimmerCard-root",
    css`
        max-width: 100%;

        & > .headerItem {
            margin-top: 0;
            padding: var(--spacing-xs) var(--spacing-s);
            height: 44px;
            border-bottom: 2px solid var(--border-color-darker);
            background-color: var(--border-color);
        }

        & .gap {
            height: var(--spacing-s);
            background: var(--body-background);
        }
    `
);

export const ShimmerCard = observer(
    class ShimmerCard extends Component {
        render() {
            const { className, lines } = this.props;

            return (
                <div className={cx(className, rootClassName)}>
                    <div className="headerItem">
                        <Stack horizontal tokens={{ childrenGap: 16 }}>
                            <Shimmer width="70px" />
                            <Stack.Item grow={1}>
                                <span />
                            </Stack.Item>
                            <Shimmer width="100px" />
                        </Stack>
                    </div>
                    <div style={{ border: "12px solid var(--white)", borderTop: 0 }}>
                        <ShimmerElementsGroup
                            flexWrap={true}
                            width="100%"
                            shimmerElements={[
                                { type: ShimmerElementType.line, width: "90%", height: 10, verticalAlign: "bottom" },
                                { type: ShimmerElementType.gap, width: "10%", height: 20 },
                            ]}
                        />
                        {lines > 1 && (
                            <ShimmerElementsGroup
                                flexWrap={true}
                                width="100%"
                                shimmerElements={[
                                    {
                                        type: ShimmerElementType.line,
                                        width: "70%",
                                        height: 10,
                                        verticalAlign: "bottom",
                                    },
                                    { type: ShimmerElementType.gap, width: "30%", height: 20 },
                                ]}
                            />
                        )}
                    </div>
                    <div style={{ border: "12px solid var(--white)", borderTop: 0 }}>
                        <ShimmerElementsGroup
                            flexWrap={true}
                            width="100%"
                            shimmerElements={[
                                { type: ShimmerElementType.line, width: "35%", height: 10, verticalAlign: "bottom" },
                                { type: ShimmerElementType.gap, width: "30%", height: 20 },
                                { type: ShimmerElementType.line, width: "35%", height: 10, verticalAlign: "bottom" },
                            ]}
                        />
                    </div>
                    <div style={{ borderBottom: "1px solid var(--body-background)" }}></div>
                    <div
                        style={{
                            display: "flex",
                            border: "12px solid var(--white)",
                            borderTop: "4px solid var(--white)",
                            borderBottom: "8px solid var(--white)",
                        }}
                    >
                        <ShimmerElementsGroup
                            shimmerElements={[
                                { type: ShimmerElementType.circle, height: 32 },
                                { type: ShimmerElementType.gap, width: 16, height: 40 },
                            ]}
                        />
                        <ShimmerElementsGroup
                            flexWrap={true}
                            width="100%"
                            shimmerElements={[
                                { type: ShimmerElementType.line, width: "100%", height: 10, verticalAlign: "bottom" },
                                { type: ShimmerElementType.line, width: "90%", height: 8 },
                                { type: ShimmerElementType.gap, width: "10%", height: 20 },
                            ]}
                        />

                        <ShimmerElementsGroup
                            shimmerElements={[
                                { type: ShimmerElementType.gap, width: 40, height: 40 },
                                { type: ShimmerElementType.line, height: 20, width: 5 },
                                { type: ShimmerElementType.gap, width: 10, height: 40 },
                            ]}
                        />
                    </div>
                    <div className="gap"></div>
                </div>
            );
        }
    }
);
