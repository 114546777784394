import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';

import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator';
 
const rootClassName = cx(
    'cygraph-TriageWizardIndicator-root',
    css`
    & .ms-ProgressIndicator-itemDescription {
        margin-top: var(--spacing-xxxs);
    }
    `
);

export const TriageWizardIndicator = observer(
    class TriageWizardIndicator extends Component {

        render () {
            const { selectedIndex, steps } = this.props;

            return (
                <div className={cx(rootClassName, 'row--xl')}>
                    <ProgressIndicator 
                        description={`Progress ${selectedIndex+1} of ${steps}`} 
                        barHeight={20} 
                        percentComplete={Math.max(0.01, (selectedIndex + 1)/ (steps * 1.0))} />
                </div>
            );
        }
    }
);