import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { AssetPicker as StatelessAssetPicker } from '../../components/core/AssetPicker';

import { AssetPickerStore } from '../../stores/AssetPickerStore';

export const AssetPicker = inject('assetStore')(observer(
    class AssetPicker extends Component {

        constructor(props) {
            super(props);

            this.state = { 
                pickerStore: new AssetPickerStore(this.props.assetStore),
            }
        }

        componentWillMount() {
            const { pickerStore } = this.state;
            const { multiple, selected, selectedId, selectedIds } = this.props;

            if(typeof(selected) !== 'undefined') {
                pickerStore.setSelected(multiple ? (selected || []) : (selected ? [selected] : []));
            } else if(!multiple) {
                pickerStore.setSelectedWithId(selectedId);
            } else {
                pickerStore.setSelectedWithIds(selectedIds);
            }
        }

        componentWillReceiveProps(nextProps) {
            const { pickerStore } = this.state;
            const { multiple, selected, selectedId, selectedIds } = nextProps;

            if(typeof(selected) !== 'undefined') {
                if(selected != this.props.selected) {
                    pickerStore.setSelected(multiple ? (selected || []) : (selected ? [selected] : []));
                }
            } else if(!multiple) {
                if(selectedId != this.props.selectedId) {
                    pickerStore.setSelectedWithId(selectedId);
                }
            } else {
                if(selectedIds != this.props.selectedIds) {
                    pickerStore.setSelectedWithIds(selectedIds);
                }
            }
        }
    
        render () {
            const { pickerStore } = this.state;
            const { className, label, required, placeholder, readOnly, multiple, itemCount, disabled, onChange } = this.props;
            const { selectedAssets, loading } = pickerStore;
            
            const selected = multiple ? selectedAssets : (selectedAssets.length ? selectedAssets[0] : null);

            return (
                <StatelessAssetPicker
                    {...{ className, label, placeholder, readOnly, required, itemCount, disabled, multiple, loading }}
                    selected={selected}
                    data-test={this.props["data-test"]}
                    onFilter={(text, current, limit) => {
                        return pickerStore.loadAssets({ keywords: text });
                    }}
                    onSelected={(system) => {                       
                        pickerStore.setSelected(multiple ? (system || []) : (system ? [system] : []));
                        if(onChange) {
                            onChange(system);
                        }
                    }} />
            );
        }
    }
));
