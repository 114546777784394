import { IdentityService } from "@api/identity";
import { RootStore } from "@modules/base/RootStore";

import { PrincipalStoreUtilities } from "@modules/identity/stores/PrincipalStoreUtilities";
import { IdentityUserBrowseStore } from "@modules/identity/stores/IdentityUserBrowseStore";
import { IdentityUserEditFormStore } from "@modules/identity/stores/IdentityUserEditFormStore";
import { IdentityUserNewFormStore } from "@modules/identity/stores/IdentityUserNewFormStore";
import { IdentityGroupBrowseStore } from "@modules/identity/stores/IdentityGroupBrowseStore";
import { IdentityGroupEditFormStore } from "@modules/identity/stores/IdentityGroupEditFormStore";
import { IdentityGroupNewFormStore } from "@modules/identity/stores/IdentityGroupNewFormStore";
import { IdentityLifecycleStore } from "@modules/identity/stores/IdentityLifecycleStore";

export class PrincipalStore {
    public rootStore: RootStore;
    public identityService: IdentityService;
    public utilities: PrincipalStoreUtilities;

    public userBrowseStore: IdentityUserBrowseStore;
    public userEditFormStore: IdentityUserEditFormStore;
    public userNewFormStore: IdentityUserNewFormStore;
    public groupBrowseStore: IdentityGroupBrowseStore;
    public groupEditFormStore: IdentityGroupEditFormStore;
    public groupNewFormStore: IdentityGroupNewFormStore;
    public lifecycleStore: IdentityLifecycleStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.identityService = new IdentityService(rootStore.authProvider);
        this.utilities = new PrincipalStoreUtilities(rootStore);

        this.userBrowseStore = new IdentityUserBrowseStore(this);
        this.userEditFormStore = new IdentityUserEditFormStore(this);
        this.userNewFormStore = new IdentityUserNewFormStore(this);
        this.groupBrowseStore = new IdentityGroupBrowseStore(this);
        this.groupEditFormStore = new IdentityGroupEditFormStore(this);
        this.groupNewFormStore = new IdentityGroupNewFormStore(this);
        this.lifecycleStore = new IdentityLifecycleStore(this);
    }
}
