export const RoleNames = {
    Guest: "guest",
    Agent: "agent",
    Admin: "admin",
    Assessments: "assessments",
    WorkItems: "work-items",
    Risks: "risks",
    Insights: "insights",
    Assets: "assets",
    Entities: "entities",
    Processes: "processes",
};

export const isUserAuthenticated = (user) => {
    return user && user.id;
};

export const isUserInAnyRoles = (user, roles) => {
    return user && user.roles && roles.find((r) => user.roles.indexOf(r) !== -1);
};

export const isUserInAllRoles = (user, roles) => {
    return user && user.roles && !roles.find((r) => user.roles.indexOf(r) === -1);
};

export const isUserInAnyGroups = (user, groups) => {
    return user && user.groups && groups.find((r) => user.groups.indexOf(r) !== -1);
};

export const isUserInAllGroups = (user, groups) => {
    return user && user.groups && !groups.find((r) => user.groups.indexOf(r) === -1);
};

export const isGuest = (user) => {
    if (user) {
        if (user.roles.length < 1) {
            return true;
        } else {
            return user.roles.indexOf(RoleNames.Guest) !== -1;
        }
    } else {
        return true;
    }
};
