import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { PanelType } from 'office-ui-fabric-react/lib/Panel';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { ObservablePanel } from '../../../base/components/ObservablePanel';
import { SectionEditForm } from '../../components/sections/SectionEditForm';

export const SectionEditPanel = observer(
    class SectionEditPanel extends Component {
        
        render () {
            const { sectionEditStore } = this.props.instance;
            const { visible, formData, formOptions, isValid } = sectionEditStore;
            const layouts = this.props.instance.parentStore.layoutStore.layouts;

            
            return (
                <ObservablePanel
                    hidden={!visible}
                    pageTitle={formData ? formOptions.pageTitle : 'Page'}
                    pageType={'Edit Section'}
                    panelType={PanelType.small}
                    onClosePanel={() => this._onHidePanel()}
                    onRenderFooter={() => this._renderFooter()}>
                    { formData && ( 
                        <SectionEditForm 
                            formData={formData}
                            layouts={layouts}
                            isValid={isValid} />
                    ) }
                </ObservablePanel>
            );
        }

        _renderFooter() {
            const { sectionEditStore } = this.props.instance;
            const { isValid } = sectionEditStore;

            return (
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <PrimaryButton onClick={() => this._onSaveSection()} text="Apply" disabled={!isValid} />
                    <DefaultButton onClick={() => this._onHidePanel()} text="Cancel" />
                </Stack>
            );
        }

        _onSaveSection() {
            const { sectionEditStore } = this.props.instance;
            const { formOptions, formData } = sectionEditStore;
            formOptions.resolve(formData);
            sectionEditStore.hide();
        }

        _onHidePanel() {
            const { sectionEditStore } = this.props.instance;
            const { formOptions } = sectionEditStore;
            formOptions.resolve();
            sectionEditStore.hide();
        }
    }
);