import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import * as microsoftTeams from "@microsoft/teams-js";

import { TextField } from 'office-ui-fabric-react/lib/TextField';

const getQueryStringParams = query => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
};

export const TabConfigurePage = inject('routing')(observer(
    class TabConfigurePage extends Component {

        state = {};

        componentWillMount() {
            const { routing } = this.props;
            this.onSettingsLoad();
        }

        componentWillReceiveProps(nextProps) {
            const { routing } = nextProps;
            this.onSettingsLoad();
        }

        onSettingsLoad() {
            microsoftTeams.settings.getSettings((settings) => {
                this.setState({...settings})
            });
        }

        onSettingsSave(assessmentId) {
            const settings = {
                websiteUrl: `${window.location.origin}/assurance/browse/${assessmentId}`,
                contentUrl: `${window.location.origin}/teams/assurance/browse/${assessmentId}`,
                entityId: assessmentId,
                suggestedDisplayName: "Assurance",
            };

            this.setState(settings, () => {
                microsoftTeams.settings.setSettings(settings);
                microsoftTeams.settings.setValidityState(!!assessmentId);
            });
        }
    
        render () {
            return (
                <>
                    <TextField 
                        label="Assurance"
                        placeholder="Please provide the long id of the asssessment"
                        required
                        value={this.state.entityId}
                        onChange={(ev, val) => {
                            this.onSettingsSave(val);                                        
                        }} />
                </>
            );
        }
    }
));
