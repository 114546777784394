import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import Moment from "react-moment";

import { Stack } from "office-ui-fabric-react/lib/Stack";
import { Label } from "office-ui-fabric-react/lib/Label";
import { Text } from "office-ui-fabric-react/lib/Text";
import { PanelType } from "office-ui-fabric-react/lib/Panel";
import { ActionButton } from "office-ui-fabric-react/lib/Button";

import { ObservablePanel } from "../../../base/components/ObservablePanel";
import { isNotNullOrEmpty } from "../../../editors/components/RichTextField";
import RichTextViewer from "../../../editors/components/RichTextViewer";

import { RiskScore } from "../../components/reviews/RiskScore";
import { RiskHeatmap } from "../../components/reviews/RiskHeatmap";
import { TreatmentEffectiveness } from "../../components/treatments/TreatmentEffectiveness";

export const RiskSummaryPanel = inject(
    "riskStore",
    "routing"
)(
    withRouter(
        observer(
            class RiskSummaryPanel extends Component {
                render() {
                    const { summaryStore } = this.props.riskStore;
                    const { visible, selectedRisk } = summaryStore;

                    return (
                        <ObservablePanel
                            hidden={!visible}
                            pageType="Risk"
                            panelType={PanelType.small}
                            onClosePanel={() => this._onHidePanel()}
                            onRenderFooter={() => this._renderFooter()}
                        >
                            {selectedRisk && this._renderSummary()}
                        </ObservablePanel>
                    );
                }

                _renderSummary() {
                    const { summaryStore } = this.props.riskStore;
                    const { selectedRisk } = summaryStore;

                    return (
                        <div>
                            <r-grid columns="1">
                                <r-cell span="1">
                                    <div className="row--xxs" style={{ marginTop: "10px" }}>
                                        <Text variant="large">
                                            {selectedRisk.code} - {selectedRisk.title}
                                        </Text>
                                    </div>

                                    <r-grid columns="2">
                                        <r-cell span="1">
                                            <div className="row--xxs">
                                                <Label>Portfolio</Label>
                                                <div>{selectedRisk.portfolio.name}</div>
                                            </div>
                                        </r-cell>
                                        <r-cell span="1">
                                            <div className="row--xxs">
                                                <Label>Risk Registry</Label>
                                                <div>{selectedRisk.registry.name}</div>
                                            </div>
                                        </r-cell>
                                    </r-grid>

                                    <div className="row--s">
                                        <Label>Risk Description</Label>
                                        {isNotNullOrEmpty(selectedRisk.description) ? (
                                            <RichTextViewer
                                                className="description"
                                                value={selectedRisk.description}
                                            />
                                        ) : (
                                            <span>No risk description set</span>
                                        )}
                                    </div>

                                    <div className="row--s">
                                        <Label>Risk Background</Label>
                                        {isNotNullOrEmpty(selectedRisk.context) ? (
                                            <RichTextViewer
                                                className="description"
                                                value={selectedRisk.context}
                                            />
                                        ) : (
                                            <span>No risk background set</span>
                                        )}
                                    </div>

                                    <r-grid columns="2">
                                        <r-cell span="1">
                                            <div className="row--xxs">
                                                <Label>Last Reviewed</Label>
                                                <div>
                                                    {selectedRisk.review ? (
                                                        <Moment
                                                            utc
                                                            date={selectedRisk.review.reviewed}
                                                            format="Do MMM YYY"
                                                        />
                                                    ) : (
                                                        <span>Risk has not been reviewed</span>
                                                    )}
                                                </div>
                                            </div>
                                        </r-cell>
                                        <r-cell span="1">
                                            <div className="row--xxs">
                                                <Label>Status</Label>
                                                <div>{selectedRisk.lifecycle.status}</div>
                                            </div>
                                        </r-cell>
                                    </r-grid>

                                    <div className="row--s">
                                        <Label>Gross Risk</Label>
                                        <div className="row--xxs">
                                            <RiskScore
                                                score={
                                                    selectedRisk.review
                                                        ? selectedRisk.review.grossScore
                                                        : null
                                                }
                                                maxImpact={4}
                                                maxLikelihood={4}
                                            />
                                        </div>

                                        {selectedRisk.review &&
                                        isNotNullOrEmpty(
                                            selectedRisk.review.grossScore.description
                                        ) ? (
                                            <RichTextViewer
                                                className="description"
                                                value={selectedRisk.review.grossScore.description}
                                            />
                                        ) : (
                                            <span>No gross risk description set</span>
                                        )}
                                    </div>

                                    <div className="row--s">
                                        <Label>Net Risk</Label>
                                        <div className="row--xxs">
                                            <RiskScore
                                                score={
                                                    selectedRisk.review
                                                        ? selectedRisk.review.netScore
                                                        : null
                                                }
                                                maxImpact={4}
                                                maxLikelihood={4}
                                            />
                                        </div>

                                        {selectedRisk.review &&
                                        isNotNullOrEmpty(
                                            selectedRisk.review.netScore.description
                                        ) ? (
                                            <RichTextViewer
                                                className="description"
                                                value={selectedRisk.review.netScore.description}
                                            />
                                        ) : (
                                            <span>No net risk description set</span>
                                        )}
                                    </div>
                                    <div className="row--s">
                                        <Label>Treatment Effectiveness</Label>
                                        <TreatmentEffectiveness
                                            effectiveness={
                                                selectedRisk.review
                                                    ? selectedRisk.review.treatmentEffectiveness
                                                    : null
                                            }
                                        />
                                    </div>
                                    {selectedRisk.review && selectedRisk.review.treatments && (
                                        <div className="row--s">
                                            <Label>Current Treatments</Label>
                                            {selectedRisk.review.treatments.length == 0 && (
                                                <div>No treatments added</div>
                                            )}
                                            <div>
                                                {selectedRisk.review.treatments.map((treatment) => (
                                                    <div key={treatment.id}>
                                                        <div>{treatment.type}</div>
                                                        <RichTextViewer
                                                            className="description"
                                                            value={treatment.description}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </r-cell>
                            </r-grid>
                        </div>
                    );
                }

                _renderFooter() {
                    return (
                        <Stack horizontal horizontalAlign="space-between">
                            <Stack horizontal tokens={{ childrenGap: 15 }}>
                                <span />
                            </Stack>
                            <Stack horizontal tokens={{ childrenGap: 15 }}>
                                <ActionButton onClick={() => this._onHidePanel()} text="Close" />
                            </Stack>
                        </Stack>
                    );
                }

                _onHidePanel() {
                    const { summaryStore } = this.props.riskStore;
                    summaryStore.hide();
                }
            }
        )
    )
);
