import { observable, flow, computed, action } from "mobx";
import { v4 as uuidv4 } from 'uuid';

import { ControlService } from '../../../api/modelling';
import { ControlStore } from "./ControlStore";

export class ControlMapNewStore {

    public controlService: ControlService;
    public parentStore: ControlStore;

    @observable public visible: boolean = false;
    @observable public formData: any;
    @observable public originalFormData: any;
    @observable public formOptions: any;
    @observable public selectedAxis: any;
    @observable public saving: boolean = false;
    @observable public isCloseDialogOpen: boolean = false;
    @observable public controls: any[];

    constructor(parentStore: ControlStore) {
        this.parentStore = parentStore;
        this.controlService = parentStore.controlService;
    }

    @computed
    public get isValid() {
        if(!this.formData){
            return false;
        }

        return this.formData.name && this.formData.category && this.formData.tags 
            && this.formData.axes && this.formData.axes.length > 0;
    }

    @computed
    public get isAxisValid() {
        if(!this.selectedAxis){
            return false;
        }

        return this.selectedAxis.id && this.selectedAxis.name && this.selectedAxis.absentValue >= 0 && this.selectedAxis.absentValue <= 1
            && this.selectedAxis.targetValue >= 0 && this.selectedAxis.targetValue <= 1 && this.selectedAxis.parts
            && this.selectedAxis.parts.length === this.selectedAxis.parts.filter(p => p.id && p.name && p.weighting >= 0 && p.weighting <= 1).length;
    }

    @computed
    public get isDirty() {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
    }

    @action
    public resetFormData() {
        this.formData = JSON.parse(JSON.stringify(this.originalFormData));
    }

    @action
    public show(options) {
        const map = Object.assign({ axes: [], tags: [] }, options.map || {});
        
        this.visible = true;
        this.formOptions = { keywords: null };
        this.formData = JSON.parse(JSON.stringify(map));
        this.originalFormData = JSON.parse(JSON.stringify(map));
    }

    @action
    public hide(options) {
        this.visible = false;
        this.formData = null;
        this.formOptions = null;
        this.selectedAxis = null;
    }

    @action
    public onImageChanged = (imageUri) => {
        this.formData.image = imageUri;
    };

    @action
    public onAxisValuesUpdated = (values) => {
        this.formData.axes = this.formData.axes.map((axis) => ({
            ...axis,
            targetValue: values[axis.id] || 0
        }));
    };

    @action
    public setSelectedAxis = (axis) => {
        this.selectedAxis = axis;
    };

    @action
    public onAxisUpdated = (axis) => {
        const index = this.formData.axes.findIndex(a => a.id === axis.id);
        if(index !== -1) {
            this.formData.axes.splice(index, 1, axis);
            this.formData.axes = [...this.formData.axes];
        }
    };

    @action
    public onAxisRemoved = (axis) => {
        this.formData.axes = this.formData.axes.filter(a => a.id !== axis.id);
    };

    @action
    public onSearchChange = (keywords) => {
        this.formOptions.keywords = keywords;
    };

    @action
    public onAddAxis = (defaults) => {
        const axis = Object.assign({
            id: uuidv4(),
            name: 'New Axis',
            absentValue: 0,
            targetValue: 0.5,
            parts: []
        }, defaults);

        this.formData.axes = [...this.formData.axes, axis];

        return axis;
    };

    @action
    public onAddControlAxis = (control) => {
        const current = this.formData.axes.find(a => a.id === control.id);

        if(!current) {
            const axis = Object.assign({ absentValue: 0, targetValue: 0.5, }, 
                { id: uuidv4(), name: control.name, parts: (control.children || []).map(c => ({
                    id: c.id, name: c.name, absentValue: null, weighting: 1,
                })) });

            this.formData.axes = [...this.formData.axes, axis];

            return axis;
        }
    };

    public loadControls = flow(function*(options) {
        this.saving = true;
        this.error = null;

        const o = Object.assign({ pageSize: 250, startIndex: 0, keywords: null, nested: true, cache: true }, options);

        if(o.cache && this.controls != null && this.controls.length > 0) {
            return;
        }

        try {
            this.controls = yield this.controlService.getControls(o);
        } catch (e) {
            console.error(e);
            this.error = e;
        } finally {
            this.saving = false;
        }
    });

    public createControlMap = flow(function*() {
        this.saving = true;
        this.error = null;

        try {
            if(this.isValid) {
                const result = yield this.controlService.createControlMap({
                    mode: 'Minor',
                    notes: 'Control map created',
                    entity: this.formData
                });
//                this.parentStore.mapBrowseStore.replace(result);
                this.parentStore.rootStore.layoutStore.displayToastNotification(
                    `Control map ${result.name} created successfully`);
                return result;
            }
        } catch (e) {
            console.error(e);
            this.error = e;
        } finally {
            this.saving = false;
        }
    });
}