import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";

import { AppPage } from "../../../layout/containers/AppPage";
import { ObservablePagination } from "../../../base/components/ObservablePagination";
import { ObservableDialog } from "../../../base/components/ObservableDialog";

import { RiskCardView } from "../../components/core/RiskCardView";
import { RiskBoardView } from "../../components/core/RiskBoardView";
import { RiskCommandBar } from "../../components/core/RiskCommandBar";
import { RiskSideNav } from "../../components/core/RiskSideNav";
import { RegistryHeader } from "../../components/registries/RegistryHeader";

import { RiskNewPanel } from "../panels/RiskNewPanel";
import { RiskAssignPanel } from "../panels/RiskAssignPanel";
import { RiskSummaryPanel } from "../panels/RiskSummaryPanel";
import { RiskReviewPanel } from "../panels/RiskReviewPanel";
import { RiskTransferPanel } from "../panels/RiskTransferPanel";

const getQueryStringParams = (query) => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query).split("&").reduce((params, param) => {
              let [key, value] = param.split("=");
              params[key] = value ? decodeURIComponent(value.replace(/\+/g, " ")) : "";
              return params;
          }, {})
        : {};
};

export const RiskBoardPage = inject(
    "pageContext",
    "riskStore",
    "routing"
)(
    withRouter(
        observer(
            class RiskBoardPage extends Component {
                componentWillMount() {
                    const { riskStore, routing, match } = this.props;
                    const { reviewBoardStore, typeStore, registryStore } = riskStore;

                    const params = getQueryStringParams(routing.location.search);
                    reviewBoardStore.loadBoard({
                        page: params["p"] || 1,
                        keywords: params["k"],
                        registryId: match.params.id,
                    });
                    typeStore.loadTypes({ cached: true });
                    registryStore.loadRegistries({ cached: true });
                }

                componentWillReceiveProps(nextProps) {
                    const { riskStore, routing, match } = nextProps;
                    const { reviewBoardStore, typeStore, registryStore } = riskStore;

                    const params = getQueryStringParams(routing.location.search);
                    reviewBoardStore.loadBoard({
                        page: params["p"] || 1,
                        keywords: params["k"],
                        registryId: match.params.id,
                    });
                    typeStore.loadTypes({ cached: true });
                    registryStore.loadRegistries({ cached: true });
                }

                render() {
                    const { riskStore, routing, match } = this.props;
                    const { lifecycleStore, reviewBoardStore } = riskStore;
                    const { loading, breadcrumb, error, registry, board, dialog, keywords } =
                        reviewBoardStore;

                    return (
                        <AppPage
                            breadcrumb={breadcrumb}
                            error={error}
                            loading={loading}
                            hasSidebar={false}
                            className="risks kanban pending-review has--page-nav"
                        >
                            <RiskSideNav
                                types={riskStore.typeStore.genericTypes}
                                registries={riskStore.registryStore.registries}
                                onLinkClick={(item) => this.props.routing.push(item.url)}
                                onNewRisk={(options) => this._onNewRisk(options)}
                            />

                            <div>
                                {registry && <RegistryHeader registry={registry} />}

                                <RiskCommandBar
                                    keywords={keywords}
                                    types={riskStore.typeStore.genericTypes}
                                    onNewRisk={(options) => this._onNewRisk(options)}
                                    onRefresh={() => this._onRefresh()}
                                    onSearchChange={(keywords) => {
                                        if (keywords) {
                                            routing.push(
                                                `/risks/boards/${
                                                    match.params.id
                                                }?k=${encodeURIComponent(keywords)}`
                                            );
                                        } else {
                                            routing.push(`/risks/boards/${match.params.id}`);
                                        }
                                    }}
                                />

                                <RiskBoardView
                                    board={board}
                                    onRenderCard={(item, snapshot) => (
                                        <RiskCardView
                                            risk={item}
                                            onRiskClick={() => this._onViewRisk(item)}
                                            selected={snapshot.isDragging}
                                        />
                                    )}
                                    onCardMoved={this._onCardMoved}
                                    onMonitor={(risk, move) => this._onReview(risk, true, move)}
                                    onClose={(risk, move) => this._onClose(risk, move)}
                                    onTransfer={(risk, move) => riskStore.transferRisk(risk)}
                                    onShare={(risk) => this._onShareRisk(risk.id)}
                                    onReview={(risk) => this._onReview(risk, false)}
                                />

                                <ObservablePagination
                                    {...riskStore.reviewBoardStore.pagination}
                                    onPageChange={(index) => {
                                        routing.push(
                                            `/risks/pending-review/${match.params.id}?p=${
                                                index + 1
                                            }${
                                                riskStore.keywords
                                                    ? `&k=${encodeURIComponent(riskStore.keywords)}`
                                                    : ""
                                            }`
                                        );
                                    }}
                                />

                                <RiskNewPanel onRiskCreated={this._onRiskCreated} />

                                <RiskSummaryPanel />

                                <RiskReviewPanel
                                    saving={lifecycleStore.saving}
                                    store={riskStore.reviewFormStore}
                                />

                                <RiskTransferPanel
                                    saving={lifecycleStore.saving}
                                    store={riskStore.transferFormStore}
                                />

                                <RiskAssignPanel
                                    saving={lifecycleStore.saving}
                                    store={riskStore.assignFormStore}
                                />

                                {/* <RiskReviewPanel onReviewCancelled={this._onReviewCancelled} />

                                <RiskTransferPanel
                                    onTransferSuccess={this._onTransferSuccess}
                                    onTransferCancelled={this._onTransferCancelled}
                                /> */}

                                <ObservableDialog
                                    {...dialog}
                                    onConfirm={this._onCloseConfirmed}
                                    onCancel={this._onCloseCancelled}
                                />
                            </div>
                        </AppPage>
                    );
                }

                _onCardMoved = (ev) => {
                    const { reviewStore, transferStore, reviewBoardStore } = this.props.riskStore;

                    const risk = reviewBoardStore.board[ev.fromId].items.find(
                        (r) => r.id == ev.itemId
                    );

                    reviewBoardStore.move(ev);

                    if (ev.toId === "monitoring") {
                        reviewStore.show({
                            review: risk.review,
                            risk: risk,
                            monitor: true,
                            clearData: true,
                        });
                    } else if (ev.toId === "closed") {
                        reviewBoardStore.dialog.show({
                            title: "Close Risk",
                            subText: `Are you sure you want to close risk ${risk.code}?`,
                            isBlocking: true,
                            context: risk,
                        });
                    } else if (ev.toId === "transferred") {
                        transferStore.show({
                            review: risk.review,
                            risk: risk,
                            allowReview: true,
                            allowSubmit: true,
                            clearData: true,
                        });
                    }
                };

                _onTransferCancelled = (formData) => {
                    const { reviewBoardStore } = this.props.riskStore;
                    reviewBoardStore.reverse();
                };

                _onTransferSuccess = (lifecycle) => {
                    const { reviewBoardStore } = this.props.riskStore;

                    reviewBoardStore.loadRisk(lifecycle.riskId).then((updated) => {
                        reviewBoardStore.update(updated);
                    });
                };

                _onCloseCancelled = (risk) => {
                    const { reviewBoardStore } = this.props.riskStore;
                    reviewBoardStore.dialog.hide();
                    reviewBoardStore.reverse();
                };

                _onCloseConfirmed = (risk) => {
                    const { reviewBoardStore } = this.props.riskStore;
                    reviewBoardStore.dialog.hide();

                    this.props.riskStore.closeRisk(risk.id).then((updated) => {
                        reviewBoardStore.update(updated);
                    });
                };

                _onClose = (risk) => {
                    const { reviewBoardStore } = this.props.riskStore;

                    reviewBoardStore.dialog.show({
                        title: "Close Risk",
                        subText: `Are you sure you want to close risk ${risk.code}?`,
                        isBlocking: true,
                        context: risk,
                    });
                };

                _onReviewCancelled = () => {
                    const { reviewBoardStore } = this.props.riskStore;
                    reviewBoardStore.reverse();
                };

                _onReview(risk) {
                    const { reviewStore } = this.props.riskStore;

                    reviewStore.show({
                        review: risk.review,
                        risk: risk,
                        monitor: false,
                        clearData: true,
                    });
                }

                _onRiskCreated = (risk) => {
                    const { reviewBoardStore } = this.props.riskStore;
                    reviewBoardStore.insert(risk);
                };

                _onRefresh() {
                    const { riskStore, routing, match } = this.props;
                    const { reviewBoardStore } = riskStore;

                    const params = getQueryStringParams(routing.location.search);
                    reviewBoardStore.loadBoard({
                        page: params["p"] || 1,
                        keywords: params["k"],
                        registryId: match.params.id,
                    });
                }

                _onNewRisk(options) {
                    const { editStore } = this.props.riskStore;

                    const o = Object.assign(
                        {
                            allowReview: false,
                            allowSubmit: false,
                            disableTargetType: true,
                            target: {
                                type: "Asset",
                            },
                        },
                        options
                    );

                    editStore.show(o);
                }

                _onViewRisk(risk) {
                    const { summaryStore } = this.props.riskStore;
                    summaryStore.show({
                        id: risk.id,
                        risk,
                    });
                }
            }
        )
    )
);
