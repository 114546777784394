import React from "react";
import { observer } from "mobx-react";

import { TagPicker, ValidationState } from "office-ui-fabric-react/lib/Pickers";
import { Label } from "office-ui-fabric-react/lib/Label";

import { BigTextField } from "@modules/base/components/BigTextField";
import { MarkdownField } from "@modules/editors/components/MarkdownField";

export const StateFlowEditForm = observer(({ formData }) => {
    return (
        <div>
            <r-grid columns="1">
                <r-cell>
                    <BigTextField
                        label="Name"
                        required
                        placeholder="Please enter a name for workflow"
                        transparent
                        value={formData.name || ""}
                        onChange={(ev, val) => {
                            formData.name = val;
                        }}
                    />
                </r-cell>
                <r-cell>
                    <MarkdownField
                        label="Description"
                        value={formData.description || ""}
                        onChange={(val) => {
                            formData.description = val;
                        }}
                    />
                </r-cell>
                <r-cell>
                    <Label>Tags</Label>
                    <TagPicker
                        selectedItems={(formData.tags || []).map((i) => ({ key: i, name: i }))}
                        onResolveSuggestions={(keywords) => [{ key: keywords, name: keywords }]}
                        getTextFromItem={(item) => item.name}
                        onValidateInput={(input) =>
                            (formData.tags || []).findIndex((t) => t.key === input) !== -1
                                ? ValidationState.invalid
                                : ValidationState.valid
                        }
                        onChange={(items) => {
                            formData.tags = items.map((t) => t.key);
                            return items;
                        }}
                    />
                </r-cell>
            </r-grid>
        </div>
    );
});
