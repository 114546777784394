import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { css } from '@uifabric/utilities/lib/css';
import { OverflowSet } from 'office-ui-fabric-react/lib/OverflowSet';

import { AppNavHome } from './AppNavHome';
import { AppNavLink } from './AppNavLink';

import './AppNavigation.css';

export const AppNavigation = observer(
  class AppNavigation extends Component {

    render () {
      return true ? this._renderRawList() : this._renderOverflowSet();
    }

    _renderOverflowSet () {
      const { appName, menu, routing } = this.props;
      
      return (
          <nav className="app__nav no-print">
              <AppNavHome appName={appName} home={menu.home} routing={routing} />
              <div className="app__nav__items__wrapper scrollbar--root">
              <ul className="app__nav__items__list">
                <OverflowSet
                    aria-label="Vertical Example"
                    role="menubar"
                    vertical
                    items={menu.filtered}
                    overflowItems={[]}
                    onRenderItem={(item) => {
                      return (
                        <li className={css('app__nav__item', true && 'animated fadeIn fast')} key={item.id} >
                          <AppNavLink item={item} selectedItemId={menu.selectedItemId} routing={routing} />
                        </li>
                      );
                    }}
                  />
                </ul>
              </div>
          </nav>
      );
    }

    _renderRawList () {
      const { appName, menu, routing } = this.props;
      
      return (
          <nav className="app__nav no-print">
              <AppNavHome appName={appName} home={menu.home} routing={routing} />
              <div className="app__nav__items__wrapper scrollbar--root">
                <ul className="app__nav__items__list">
                  {
                    menu.filtered.map(item => {
                      if(item.id == '$divide') {
                        return (
                          <li className={css('app__nav__item', 'push')} key={item.id} >&nbsp;</li>
                        );
                      } else {
                        return (
                          <li className={css('app__nav__item', true && 'animated fadeIn fast')} key={item.id} >
                            <AppNavLink item={item} selectedItemId={menu.selectedItemId} routing={routing} />
                          </li>
                        );
                      }
                    })
                  }
                </ul>
              </div>
          </nav>
      );
    }
  }
);
