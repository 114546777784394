import React, { useMemo, useEffect, useCallback } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { ActivityStream } from "@modules/activities/components/core/ActivityStream";

import { ActivityContextStore } from "@modules/activities/stores/ActivityContextStore";

export const ActivityStreamContext = observer(({ context, onRenderAttachment, showHeader }) => {
    const { principalContext, activityStore } = useStores();
    const activityContextStore = useMemo(() => new ActivityContextStore(activityStore), [context && context.id]);
    const { page } = activityContextStore;

    const onLoad = useCallback(() => {
        activityContextStore.onLoad({ context, page: page + 1 });
    }, [activityContextStore, page]);

    useEffect(() => {
        activityContextStore.start({ context });
        return () => {
            activityContextStore.stop();
        };
    }, [context && context.id]);

    return (
        <ActivityStream
            showHeader={showHeader}
            store={activityContextStore}
            user={principalContext.current}
            onLoad={onLoad}
            onRenderAttachment={onRenderAttachment}
        />
    );
});
