import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion'
import Moment from 'react-moment';

import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';

const rootClassName = cx(
    'cygraph-AssessmentModule-root',
        css`
            .pad-l {
                padding-left: 10px;
            }
          `
    );

const buttonClassName = cx(
    'cygraph-AssessmentModule-button',
        css`
            width: 100%;
            &.start {
                background-color: var(--blue-12);
                border-color: var(--blue-12);
                color: var(--white);
                &:hover {
                    background-color: var(--blue-15);
                    border-color: var(--blue-15);
                }
            }
          `
    );

export const AssessmentModule = observer(
    class AssessmentModule extends Component {
        
        render () {
            const { module, percentComplete, modified, disabled } = this.props;

            if(disabled) {
                return this._renderDisabled();
            }

            return (
                <div className={rootClassName}>
                    <div className="row flex">
                        <div className="count__container">
                            { this._renderPercentComplete() }
                        </div>
                        <div className="pad-l">
                            <div className="title no--margin">{module.title}</div>
                            { modified && percentComplete != 0 ? (
                                <div className="title--xxs">Updated <Moment utc date={modified} fromNow /></div>
                            ) : (
                                <div className="title--xxs">Not started</div>
                            )}
                        </div>
                    </div>
                    { this._renderButton() }
                </div>
            );
        }

        _renderPercentComplete() {
            const { percentComplete } = this.props;
            let className = 'message--secondary';

            if(percentComplete == 100) {
                className = 'message--success';
            } else if(percentComplete == 0) {
                className = 'message--primary';
            }

            return (
                <div className={cx('message message--large', className)}>{percentComplete}%</div>
            );
        }

        _renderButton() {
            const { module, percentComplete, onModuleClick, readOnly } = this.props;

            if(readOnly) {
                return <DefaultButton text={'View Answers'}
                    className={buttonClassName}
                    onClick={() => onModuleClick(module)} />;
            }

            if(percentComplete == 100) {
                return <PrimaryButton text={'View Answers'}
                    className={buttonClassName}
                    onClick={() => onModuleClick(module)} />;
            } else if(percentComplete == 0) {
                return <DefaultButton text={'Start'} 
                    className={cx(buttonClassName, 'start')}
                    onClick={() => onModuleClick(module)} />;
            } else {
                return <DefaultButton text={'Continue...'} 
                    className={buttonClassName}
                    onClick={() => onModuleClick(module)} />;
            }
        }

        _renderDisabled() {
            const { module } = this.props;

            return (
                <div>
                    <div className="row text--align--center">
                        <div className="title no--margin">{module.title}</div>
                        <div className="title--xs">Not applicable</div>
                    </div>
                    <div className="text--align--center">
                        <Icon iconName="Blocked" />
                    </div>
                </div>
            );
        }
    }
);