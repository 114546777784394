import React, { Component } from "react";
import { observer } from "mobx-react";

import { DueIndicator } from "@baseComponents/DueIndicator";

export const AssessmentDueIndicator = observer(
    class AssessmentDueIndicator extends Component {
        render() {
            const { className, assessment } = this.props;

            if (!assessment || !assessment.dueDate || assessment.status == "Cancelled") {
                return null;
            }

            return <DueIndicator className={className} dueDate={assessment.dueDate} closedDate={assessment.closed} />;
        }
    }
);
