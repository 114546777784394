import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { useStores } from "@hooks/useStores";

import { JsonQueryString } from "@modules/utils/JsonQueryString";
import { AppPage } from "@modules/layout/containers/AppPage";
import { ObservablePagination } from "@modules/base/components/ObservablePagination";

import { WorkItemGridView } from "@modules/workitems/components/core/WorkItemGridView";
import { WorkItemCommandBar } from "@modules/workitems/components/core/WorkItemCommandBar";
import { WorkItemSideNav } from "@modules/workitems/components/core/WorkItemSideNav";

import { WorkItemNewPanel } from "@modules/workitems/containers/panels/WorkItemNewPanel";

export const WorkItemBrowsePage = withRouter(
    observer(({ match }) => {
        const { workItemStore, pageContext, routing } = useStores();
        const { browseStore, lifecycleStore } = workItemStore;
        const { breadcrumb, error, isAgent, workItems, query, loading, pagination } = browseStore;

        useEffect(() => {
            pageContext.updateQueryStringParams(routing.location.search);
            browseStore.loadWorkItems({
                page: pageContext.queryStringParams.p || 1,
                query: JsonQueryString.decode(pageContext.queryStringParams.query),
            });
        }, [match]);

        return (
            <AppPage
                loading={loading}
                breadcrumb={breadcrumb}
                error={error}
                hasSidebar={false}
                className="work-items list has--page-nav"
            >
                <WorkItemSideNav
                    isAgent={isAgent}
                    onLinkClick={(item) => routing.push(item.url)}
                    onNewRequest={() => routing.push("/demand/catalogue")}
                />

                <>
                    <WorkItemCommandBar
                        loading={loading}
                        isAgent={isAgent}
                        filterQuery={query}
                        filterContext={{
                            processing: loading,
                            resultCount: pagination.totalItemCount,
                        }}
                        onFilterChange={(q, f) => {
                            routing.push(JsonQueryString.build("/demand/browse", q));
                        }}
                        onNewRequest={(product) => {
                            if (!product) {
                                routing.push("/demand/catalogue");
                            } else {
                                lifecycleStore.onCreate({}, { workItem: { product } });
                            }
                        }}
                        onExport={() => browseStore.exportWorkItems({})}
                    />

                    <WorkItemGridView
                        workItems={workItems}
                        loading={loading}
                        query={query}
                        onProductClick={(workItem) => routing.push(`/demand/catalogue/${workItem.id}`)}
                        onWorkItemClick={(workItem) => routing.push(`/demand/browse/${workItem.id}`)}
                    />

                    <ObservablePagination
                        {...pagination}
                        onPageChange={(index) => {
                            routing.push(JsonQueryString.build("/demand/browse", query, index + 1));
                        }}
                    />

                    <WorkItemNewPanel store={workItemStore.newFormStore} />
                </>
            </AppPage>
        );
    })
);
