import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { Label } from "@ui/elements/Label";
import { Text } from "@ui/elements/Text";
import { FontIcon } from "@ui/elements/Icon";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { FieldSetView } from "@modules/base/components/FieldSetView";
import { AdaptiveField } from "@modules/base/components/AdaptiveField";

import { WellKnownSectionIds } from "@modules/properties/stores/PropertyContants";
import { PropertyField } from "@modules/properties/components/core/PropertyField";

export const EntitySummaryTab = observer(({ entity }) => {
    const { entityStore } = useStores();
    const { settingsStore } = entityStore;
    const { properties, sections } = settingsStore;

    useEffect(() => {
        settingsStore.loadPropertySections({ page: 1, query: {} });
        settingsStore.loadProperties({ page: 1, query: { activeOnly: true } });
    }, [entity]);

    const viewable = properties.filter((p) => !p.hidden && p.section.id !== WellKnownSectionIds.EntityBuiltIn);
    const groupedBySections = viewable.reduce((acc, curr) => {
        if (!acc[curr.section.id]) acc[curr.section.id] = [];
        acc[curr.section.id].push(curr);
        return acc;
    }, {});

    const filteredSections = sections.filter((s) => !!groupedBySections[s.id]);

    return (
        <BoxPanel shadow padding>
            {entity && (
                <>
                    <FieldSetView title="Key Information" description="Basic information about this entity.">
                        <r-grid columns="2">
                            <r-cell span-l="2" span="2">
                                <div className="row--xs">
                                    <Label>Summary</Label>
                                    <Text block>{entity.summary || "Not set"}</Text>
                                </div>
                            </r-cell>
                            <r-cell span-l="1" span="1">
                                <div className="row--xs">
                                    <Label>Unique Id</Label>
                                    <Text block nowrap>
                                        {entity.code || "Not set"}
                                    </Text>
                                </div>
                            </r-cell>
                            <r-cell span-l="1" span="1">
                                <div className="row--xs">
                                    <Label>Alias</Label>
                                    <Text block nowrap>
                                        {entity.alias || "Not set"}
                                    </Text>
                                </div>
                            </r-cell>
                            <r-cell span-l="1" span="1">
                                <div className="row--xs">
                                    <Label>Website</Label>
                                    <Text block nowrap>
                                        {entity.website ? (
                                            <Text block nowrap>
                                                <a target="_blank" href={entity.website}>
                                                    Link <FontIcon iconName="NavigateExternalInline" />
                                                </a>
                                            </Text>
                                        ) : (
                                            "Not set"
                                        )}
                                    </Text>
                                </div>
                            </r-cell>
                            <r-cell span-l="1" span="1">
                                <div className="row--xs">
                                    <Label>Status</Label>
                                    <Text block nowrap>
                                        {entity.state}
                                    </Text>
                                </div>
                            </r-cell>
                            <r-cell span-l="1" span="1">
                                <div className="row--xs">
                                    <Label>Portfolio</Label>
                                    <Text block nowrap>
                                        {entity.portfolio && entity.portfolio.name}
                                    </Text>
                                </div>
                            </r-cell>
                            <r-cell span-l="1" span="1">
                                <div className="row--xs">
                                    <Label>Business Criticality</Label>
                                    <Text block nowrap>
                                        {entity.businessCriticality}
                                    </Text>
                                </div>
                            </r-cell>
                            <r-cell span-l="1" span="1">
                                <div className="row--xs">
                                    <Label>Industry</Label>
                                    <Text block nowrap>
                                        {entity.industry ? entity.industry.name : "Not set"}
                                    </Text>
                                </div>
                            </r-cell>
                        </r-grid>
                    </FieldSetView>
                    <FieldSetView title="Key Contacts" description="Key contacts for the entity.">
                        <r-grid columns="2" class="no--margin">
                            <r-cell>
                                <div className="row--xs">
                                    <AdaptiveField
                                        type="Principal"
                                        mode="view"
                                        label="Business Contact"
                                        value={entity.businessContact}
                                    />
                                </div>
                            </r-cell>
                            <r-cell>
                                <div className="row--xs">
                                    <AdaptiveField
                                        type="Principal"
                                        mode="view"
                                        label="Technical Contact"
                                        value={entity.technicalContact}
                                    />
                                </div>
                            </r-cell>
                        </r-grid>
                    </FieldSetView>
                    {filteredSections.map((section) => (
                        <FieldSetView key={section.id} title={section.name}>
                            <r-grid columns="2">
                                {groupedBySections[section.id].map((property) => (
                                    <r-cell
                                        key={property.id}
                                        span-l={
                                            property.type === "Text" && property.schema && property.schema.richText
                                                ? 2
                                                : 1
                                        }
                                        span="2"
                                        className="row--xs"
                                    >
                                        <PropertyField property={property} data={entity} readOnly />
                                    </r-cell>
                                ))}
                            </r-grid>
                        </FieldSetView>
                    ))}
                </>
            )}
        </BoxPanel>
    );
});
