import React, { Component } from "react";
import { observer, inject } from "mobx-react";

import { ObservablePagination } from "../../../../base/components/ObservablePagination";

import { RiskGridView } from "../../../../risks/components/core/RiskGridView";
import { RiskCommandBar } from "../../../../risks/components/core/RiskCommandBar";
import { RiskNewPanel } from "../../../../risks/containers/panels/RiskNewPanel";

const getQueryStringParams = (query) => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query).split("&").reduce((params, param) => {
              let [key, value] = param.split("=");
              params[key] = value ? decodeURIComponent(value.replace(/\+/g, " ")) : "";
              return params;
          }, {})
        : {};
};

export const RiskBrowsePage = inject(
    "riskStore",
    "routing"
)(
    observer(
        class RiskBrowsePage extends Component {
            componentWillMount() {
                const { riskStore, routing } = this.props;
                const { browseStore } = riskStore;

                const params = getQueryStringParams(routing.location.search);
                browseStore.loadRisks({ page: params["p"] || 1, keywords: params["k"] });
            }

            componentWillReceiveProps(nextProps) {
                const { riskStore, routing } = nextProps;
                const { browseStore } = riskStore;

                const params = getQueryStringParams(routing.location.search);
                browseStore.loadRisks({ page: params["p"] || 1, keywords: params["k"] });
            }

            render() {
                const { riskStore, routing } = this.props;
                const { loading, error, breadcrumb, keywords, pagination, risks } =
                    riskStore.browseStore;

                return (
                    <>
                        <RiskCommandBar
                            keywords={keywords}
                            onNewRisk={() => this._onNewRisk()}
                            onSearchChange={(keywords) => {
                                if (keywords) {
                                    routing.push(
                                        `/teams/risks/browse?k=${encodeURIComponent(keywords)}`
                                    );
                                } else {
                                    routing.push(`/teams/risks/browse`);
                                }
                            }}
                        />

                        <RiskGridView
                            risks={risks}
                            loading={loading}
                            query={{}}
                            onRiskClick={(risk) => routing.push(`/teams/risks/browse/${risk.id}`)}
                        />

                        <ObservablePagination
                            {...pagination}
                            onPageChange={(index) => {
                                routing.push(
                                    `/teams/risks/browse?p=${index + 1}${
                                        keywords ? `&k=${encodeURIComponent(keywords)}` : ""
                                    }`
                                );
                            }}
                        />

                        <RiskNewPanel />
                    </>
                );
            }

            _onNewRisk() {
                const { editStore } = this.props.riskStore;
                editStore.show({
                    allowReview: true,
                    disableTargetType: true,
                    target: {
                        type: "System",
                    },
                });
            }
        }
    )
);
