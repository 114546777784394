import React from "react";
import { observer } from "mobx-react";

import { DueIndicator } from "@modules/base/components/DueIndicator";

export const TaskDueDateIndicator = observer(({ className, children, definition, defaultValue }) => {
    if (!definition || !definition.dueDate || definition.status === "Cancelled") {
        return defaultValue || null;
    }

    return (
        <DueIndicator className={className} dueDate={definition.dueDate} closedDate={definition.closed}>
            &nbsp;&nbsp;{children}
        </DueIndicator>
    );
});
