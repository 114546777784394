import React, { useEffect, useCallback } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { DocumentCommandBar } from "@modules/base/components/documents/DocumentCommandBar";
import { DocumentGridView } from "@modules/base/components/documents/DocumentGridView";

export const AssetDocumentsTab = observer(({ asset, onUpload }) => {
    const { assetStore } = useStores();
    const { documentStore } = assetStore;
    const { loading, saving, documents, selectedDocuments, gridView, keywords } = documentStore;
    const assetId = asset ? asset.id : null;

    const canUpload = () => lifecycleStore.canUpload;

    useEffect(() => {
        if (asset) {
            documentStore.loadDocuments(asset.id);
        }
    }, [assetId]);

    const onDownloadInvoked = useCallback(
        (document) => {
            documentStore.downloadDocument(document);
        },
        [assetId, documentStore]
    );

    const onSelectionChanged = useCallback(
        (selection) => {
            documentStore.setSelectedDocuments({
                count: selection.getSelectedCount(),
                selected: selection.getSelection(),
                reset: () => selection.setAllSelected(false),
            });
        },
        [assetId, documentStore]
    );

    const onGridViewChanged = useCallback(
        (view) => {
            documentStore.setGridView(view);
            if (documentStore.selectedDocuments) {
                documentStore.selectedDocuments.reset();
            }
        },
        [assetId, documentStore]
    );

    const onSearchKeywordsChange = useCallback(
        (keywords) => {
            documentStore.setSearchKeywords(keywords);
        },
        [assetId, documentStore]
    );

    return (
        <>
            <DocumentCommandBar
                documents={documents}
                selectedDocuments={selectedDocuments}
                view={gridView}
                keywords={keywords}
                canUpload={canUpload}
                onViewChanged={onGridViewChanged}
                onUploadInvoked={onUpload}
                onDownloadInvoked={onDownloadInvoked}
                onSearchChange={onSearchKeywordsChange}
            />

            <DocumentGridView
                loading={loading}
                saving={saving}
                documents={documents}
                view={gridView}
                keywords={keywords}
                onDocumentClick={onDownloadInvoked}
                onSelectionChanged={onSelectionChanged}
            />
        </>
    );
});
