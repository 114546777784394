function shuffle(array) {
    let currentIndex = array.length,
        randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
}

export const runCombinedSearch = ({ showRisks, user, keywords, workItemStore, assessmentStore, riskStore }) => {
    return Promise.all([
        workItemStore.browseStore.loadWorkItems({ pageSize: 5, query: { keywords } }),
        assessmentStore.browseStore.loadAssessments({ pageSize: 5, query: { keywords } }),
        riskStore.exceptionBrowseStore.loadRisks({ pageSize: 5, query: { keywords } }),
        showRisks
            ? riskStore.browseStore.loadRisks({ pageSize: 5, query: { keywords } })
            : Promise.resolve({ items: [] }),
    ]).then((results) => {
        const shuffled = shuffle([
            ...results[0].items.map((n) => ({
                entity: "work-item",
                id: n.id,
                code: n.code,
                title: n.title,
                status: n.subStatus,
                created: n.created,
                modified: n.modified,
                modifiedBy: n.modifiedBy,
                type: `Demand / ${n.product.name}`,
                role:
                    n.assignedToUser && n.assignedToUser.id === user.id
                        ? "Assigned to you"
                        : n.requestedBy.id === user.id || n.createdBy.id === user.id
                        ? "Requested by you"
                        : "You are a stakeholder",
            })),
            ...results[1].items.map((n) => ({
                entity: "assessment",
                id: n.id,
                code: n.code,
                title: n.title,
                status: n.state,
                created: n.created,
                modified: n.modified,
                modifiedBy: n.modifiedBy,
                type: `Assurance / ${n.target.name}`,
                role:
                    n.assignedToUser && n.assignedToUser.id === user.id
                        ? "Assigned to you"
                        : n.createdBy.id === user.id
                        ? "Requested by you"
                        : "You are a stakeholder",
            })),
            ...results[2].items.map((n) => ({
                entity: "exception",
                id: n.id,
                code: n.code,
                title: n.title,
                status: n.lifecycle.subStatus,
                created: n.created,
                modified: n.modified,
                modifiedBy: n.modifiedBy,
                type: `Exception / ${n.type.name}`,
                role:
                    n.lifecycle.assignedToUser && n.lifecycle.assignedToUser.id === user.id
                        ? "Assigned to you"
                        : n.requestedBy.id === user.id || n.createdBy.id === user.id
                        ? "Requested by you"
                        : "You are a stakeholder",
            })),
            ...results[3].items.map((n) => ({
                entity: "risk",
                id: n.id,
                code: n.code,
                title: n.title,
                status: n.lifecycle.subStatus,
                created: n.created,
                modified: n.modified,
                modifiedBy: n.modifiedBy,
                type: `Risk / ${n.type.name}`,
                role:
                    n.lifecycle.assignedToUser && n.lifecycle.assignedToUser.id === user.id
                        ? "Assigned to you"
                        : n.requestedBy.id === user.id || n.createdBy.id === user.id
                        ? "Requested by you"
                        : "You are a stakeholder",
            })),
        ]);

        return shuffled;
    });
};
