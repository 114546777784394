import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';

import { Text } from 'office-ui-fabric-react/lib/Text';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Image, ImageFit } from 'office-ui-fabric-react/lib/Image';

import { ObservableBadge } from '@baseComponents/ObservableBadge';
import { ActionSet } from '@baseComponents/ActionSet';

const rootClassName = cx(
  'cygraph-ControlHeader-root',
  css` 
    & .badge {
      & > div {
        outline: none;
      }
    } 

    & .title {
      margin: var(--spacing-s) 0;
      & > div {
        outline: none;
      }
    }

    & .description {
      color: var(--text-color-light);
      font-size: var(--font-size-xs);
      margin-top: var(--spacing-xs);
      & > div {
        outline: none;
      }
    }

    & .meta {
      display: inline-block;
      margin-right: 20px;
    }

    & .image {
      min-width: 200px;
    }
  `
);

export const ControlHeader = observer(
  class ControlHeader extends PureComponent {

    render () {
        const { className, set, control } = this.props;
        const { items, overflowItems } = this._getActionSetItems();

        return (
          <div className={cx(rootClassName, className)}>
            <Stack horizontal horizontalAlign="space-between">
              <ObservableBadge className="badge">Control</ObservableBadge>
              <ActionSet items={items} overflowItems={overflowItems} />
            </Stack>
            <Stack horizontal horizontalAlign="space-between">
              <Stack.Item grow>
                <Text className="title" variant="xLarge" block>
                  {control.name}
                </Text>
              </Stack.Item>
              <Stack.Item>
                  <div className="image">
                    <Image src={set.imageUrl || '/images/logos/zerodai.png'} 
                      width="100%" height={65} imageFit={ImageFit.centerContain} alt={set.name} />
                  </div>
                </Stack.Item>              
            </Stack>
          </div>
        );
    }

    _getActionSetItems() {
      const { hideActions } = this.props;

      const items = [];
      const overflowItems = [];

      if(hideActions) {
        return { items, overflowItems };
      }

      return { items, overflowItems };
    }
  }
);