import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import * as microsoftTeams from "@microsoft/teams-js";

import { CatalogueListView } from "../../../../products/components/catalogue/CatalogueListView";
import { CatalogueCommandBar } from "../../../../products/components/catalogue/CatalogueCommandBar";
import { ProductDetailPanel } from "../../../../products/containers/panels/ProductDetailPanel";
import { WorkItemNewPanel } from "../../../../workitems/containers/panels/WorkItemNewPanel";

export const ProductCataloguePage = inject(
    "pageContext",
    "productStore",
    "workItemStore",
    "routing"
)(
    withRouter(
        observer(
            class ProductCataloguePage extends Component {
                componentWillMount() {
                    const { productStore } = this.props;
                    productStore.clearProductSelected();
                    productStore.catalogueStore.loadProducts({});
                    productStore.optionsStore.loadProductOptions();
                }

                componentWillReceiveProps(nextProps) {
                    const { productStore } = nextProps;
                    productStore.clearProductSelected();
                    productStore.catalogueStore.loadProducts({});
                    productStore.optionsStore.loadProductOptions();
                }

                onHideNewWorkItemPanel = () => {
                    const { workItemStore } = this.props;
                    const { newFormStore } = workItemStore;
                    newFormStore.visible = false;
                    newFormStore.productId = "";
                    newFormStore.resetTheForm();
                };

                onWorkItemSaved = (workItem) => {
                    microsoftTeams.tasks.submitTask({
                        name: "new-demand",
                        data: workItem,
                    });
                };

                render() {
                    const { productStore, workItemStore } = this.props;
                    const { catalogueStore, optionsStore } = productStore;
                    const { isSimplifiedView, selectedCategory } = catalogueStore;
                    const { newFormStore } = workItemStore;

                    return (
                        <>
                            <CatalogueCommandBar
                                className="row--s"
                                categories={optionsStore.categories}
                                selectedCategory={selectedCategory}
                                onCategoryChange={(category) => catalogueStore.setSelectedCategory(category)}
                                isSimplifiedView={isSimplifiedView}
                                onToggleViewChange={(ev, checked) => catalogueStore.setSimplifiedView(checked)}
                            />

                            <CatalogueListView
                                products={catalogueStore.filteredProducts}
                                onCardClick={(product) => productStore.setSelected(product)}
                                isSimplifiedView={isSimplifiedView}
                            />

                            <ProductDetailPanel
                                visible={productStore.selectedProduct && !newFormStore.visible}
                                onClosePanel={() => productStore.clearProductSelected()}
                                onCreateNewWorkItem={() => newFormStore.show(productStore.selectedProductId)}
                                product={productStore.selectedProduct}
                                isSimplifiedView={isSimplifiedView}
                            />

                            {productStore.selectedProduct && newFormStore.visible && (
                                <WorkItemNewPanel
                                    onHidePanel={this.onHideNewWorkItemPanel}
                                    onSavePanel={this.onWorkItemSaved}
                                />
                            )}
                        </>
                    );
                }
            }
        )
    )
);
