import React, { Component, Fragment } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { ContextualMenu } from "office-ui-fabric-react/lib/ContextualMenu";
import { ModuleTreeNode } from "./ModuleTreeNode";

const rootClassName = cx("cygraph-ModuleTreeView-root", css``);

export const ModuleTreeView = observer(
    class ModuleTreeView extends Component {
        constructor(props) {
            super(props);
            this.state = {};
        }

        render() {
            const { pattern, manifest } = this.props;
            const { contextualMenuProps } = this.state;

            return (
                <div className={rootClassName}>
                    {manifest &&
                        manifest.modules.map((module) => {
                            return (
                                <ModuleTreeNode
                                    key={module.id}
                                    className="root"
                                    type="Module"
                                    title={module.title}
                                    disabled={module.condition == "false"}
                                    menuProps={{ enabled: false, type: "Module", data: module, onClick: (ev, props) => this._onContextMenuClick(ev, props) }}
                                >
                                    {module.groups.map((group) => {
                                        return (
                                            <ModuleTreeNode
                                                key={group.id}
                                                className="inner"
                                                type="Group"
                                                title={group.title}
                                                menuProps={{
                                                    enabled: false,
                                                    type: "Group",
                                                    data: group,
                                                    onClick: (ev, props) => this._onContextMenuClick(ev, props),
                                                }}
                                            >
                                                {group.measures.map((measure) => {
                                                    return (
                                                        <ModuleTreeNode
                                                            key={measure.id}
                                                            className="inner"
                                                            type="Measure"
                                                            title={measure.title}
                                                            menuProps={{
                                                                enabled: true,
                                                                type: "Measure",
                                                                data: measure,
                                                                onClick: (ev, props) => this._onContextMenuClick(ev, props),
                                                            }}
                                                        >
                                                            {manifest.advisories
                                                                .filter((a) => a.measureId == measure.id)
                                                                .map((advisory, aIndex) => {
                                                                    return (
                                                                        <ModuleTreeNode
                                                                            key={advisory.id || aIndex}
                                                                            className="inner"
                                                                            type="Advisory"
                                                                            title={advisory.title}
                                                                            menuProps={{
                                                                                enabled: true,
                                                                                type: "Advisory",
                                                                                data: advisory,
                                                                                onClick: (ev, props) => this._onContextMenuClick(ev, props),
                                                                            }}
                                                                        ></ModuleTreeNode>
                                                                    );
                                                                })}
                                                        </ModuleTreeNode>
                                                    );
                                                })}
                                            </ModuleTreeNode>
                                        );
                                    })}
                                </ModuleTreeNode>
                            );
                        })}
                    {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}
                </div>
            );
        }

        _onContextMenuClick(ev, props) {
            const { pattern, manifest, onAddAdvisory, onRemoveAdvisory } = this.props;

            let menuItems = [];

            if (props.type == "Measure") {
                menuItems = [
                    {
                        key: "newAdvisory",
                        text: "New Advisory",
                        iconProps: { iconName: "Add" },
                        onClick: () => onAddAdvisory(props.data),
                    },
                ];
            } else if (props.type == "Advisory") {
                menuItems = [
                    {
                        key: "removeAdvisory",
                        text: "Remove Advisory",
                        iconProps: { iconName: "Delete" },
                        onClick: () => onRemoveAdvisory(props.data),
                    },
                ];
            }

            const contextualMenuProps = {
                target: ev.target,
                items: menuItems,
                onDismiss: () => {
                    this.setState({
                        contextualMenuProps: undefined,
                    });
                },
            };

            if (props.data) {
                this.setState({
                    contextualMenuProps: contextualMenuProps,
                });
            }

            return false;
        }
    }
);
