import React from "react";
import { observer } from "mobx-react";

import { Stack } from "office-ui-fabric-react/lib/Stack";

import { GhostButton } from "@ui/elements/Button";

export const StateFlowMockToolBarHost = observer(({ onTrigger }) => {
    return (
        <Stack horizontal tokens={{ childrenGap: 0 }}>
            <Stack horizontal verticalAlign="center" verticalFill>
                <GhostButton iconProps={{ iconName: "EventToDoLogo", title: "Tasks" }} onClick={() => onTrigger("Tasks")}>
                    Tasks / 0
                </GhostButton>
            </Stack>
            <Stack horizontal verticalAlign="center" verticalFill>
                <GhostButton iconProps={{ iconName: "ChatBot", title: "Tasks" }} onClick={() => onTrigger("Automation")}>
                    Automation / 0
                </GhostButton>
            </Stack>
        </Stack>
    );
});
