import React, { Component } from "react";
import { observer } from "mobx-react";

import { TextField } from "office-ui-fabric-react/lib/TextField";

import { BigTextField } from "../../../base/components/BigTextField";

export const PageEditForm = observer(
    class PageEditForm extends Component {
        render() {
            const { formData, formOptions } = this.props;
            const valuePath =
                formData.pathOriginal || formData.path ? formData.path.replace(`${formOptions.parent.path}/`, "") : "";
            return (
                <div>
                    <r-grid columns-s="1" columns="6">
                        <r-cell span="6">
                            <div>
                                <BigTextField
                                    label="Title"
                                    required
                                    placeholder="Please enter a page title"
                                    value={formData.title}
                                    transparent
                                    onChange={(ev, value) => (formData.title = value)}
                                />
                            </div>
                        </r-cell>
                        <r-cell span="6">
                            <div>
                                <TextField
                                    required
                                    label="Summary"
                                    multiline
                                    autoAdjustHeight
                                    value={formData.summary}
                                    onChange={(ev, val) => {
                                        formData.summary = val;
                                    }}
                                />
                            </div>
                        </r-cell>
                        <r-cell span="6">
                            <div>
                                <TextField
                                    required
                                    label="Path"
                                    prefix={`${formOptions.parent.path}/`}
                                    value={valuePath}
                                    onChange={(ev, val) => {
                                        formData.pathOriginal = val;
                                        formData.path = `${formOptions.parent.path}/${val}`;
                                    }}
                                />
                            </div>
                        </r-cell>
                        <r-cell span="3">
                            <div>
                                <TextField
                                    required
                                    label="Type"
                                    value={formData.type}
                                    onChange={(ev, val) => {
                                        formData.type = val;
                                    }}
                                />
                            </div>
                        </r-cell>
                    </r-grid>
                </div>
            );
        }
    }
);
