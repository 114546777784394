import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react";

import * as joint from "jointjs";

import { StateFlowStageView } from "@modules/stateflows/components/editor/StateFlowStageView";

export const StateFlowCanvasView = observer(({ manifest, graph, position, options }) => {
    const canvas = useMemo(() => {
        const el = new joint.shapes.standard.Rectangle({
            attrs: {
                root: {
                    magnet: false,
                },
                body: {
                    fill: "var(--white)",
                    strokeWidth: 0,
                },
            },
        });

        el.set("id", `${manifest.id}-canvas`);
        el.set("z", 20);
        el.prop("data", {
            type: "canvas",
            locked: true,
        });

        graph.addCell(el);
        el.size(325 * manifest.stages.length, 650);
        if (position) {
            el.position(position.x, position.y, {});
        }

        return el;
    }, [graph]);

    useEffect(() => {
        if (manifest) {
            canvas.set("id", `${manifest.id}-canvas`);

            if (manifest && manifest.stages) {
                canvas.size(325 * manifest.stages.length, 650);
            }
        }
    }, [canvas, manifest]);

    useEffect(() => {
        if (position) {
            canvas.position(position.x, position.y, { parentRelative: true });
        }
    }, [canvas, position]);

    return (
        <>
            {manifest &&
                manifest.stages &&
                manifest.stages.map((stage, index) => (
                    <StateFlowStageView key={stage.id} manifest={manifest} stage={stage} index={index} graph={graph} parent={canvas} options={options} />
                ))}
        </>
    );
});
