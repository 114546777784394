import React, { Component } from 'react';

export class AppLogo extends Component {
  static displayName = AppLogo.name;

  render () {
    return (true ?
        (<img src="/images/brand/circle-green/Logo Z circle_green_PNG_transparent_sm.png" alt="Zerodai" {...this.props} />)
        :
        (
        <svg className="app__nav__logo" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000" space="preserve">
            <g>
                <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
                <path d="M2913,3859.8c-1122.3-632.2-2069.2-1178-2102.6-1211.4l-61.3-61.3L724.1,181c-22.3-2049.7-19.5-2414.5,13.9-2464.6C802-2375.5,4856.8-4790,4951.5-4790c119.7,0,4213.5,2300.3,4263.6,2395c27.9,55.7,39,590.4,55.7,2478.6l19.5,2408.9l-69.6,66.8c-39,33.4-977.5,601.5-2083.1,1258.8C5611.5,4723.1,5101.9,5012.8,5037.8,5010C4982.1,5010,4283.1,4631.2,2913,3859.8z M6834.1,3417.1c927.4-548.6,1687.6-1002.6,1693.2-1005.4c2.8-5.6-788.1-453.9-1762.8-999.8L4998.9,420.5l-1743.3,1036C2172.2,2099.8,1528.9,2498,1551.2,2512c16.7,11.1,810.4,456.7,1760.1,991.4c1450.9,816,1735,966.4,1782.3,944.1C5124.2,4430.7,5906.7,3968.5,6834.1,3417.1z M3021.6,1010.9L4745.4-13.9v-2052.5v-2052.5L2974.2-3057.8L1205.8-1994l16.7,1838c13.9,1757.3,27.8,2188.9,61.3,2188.9C1292.2,2033,2074.7,1573.5,3021.6,1010.9z M8769.6-89.1c-11.2-1122.3-22.3-2041.3-25.1-2044.1c0-2.8-735.2-414.9-1631.9-916.2c-896.7-501.3-1682-944.1-1746.1-980.3l-119.7-69.6v2044.1V-13.9l1748.9,980.3c960.8,540.3,1757.3,980.3,1771.2,980.3C8780.7,1949.4,8783.5,1088.9,8769.6-89.1z"></path>
                <path d="M4926.4,4060.3c-89.1-52.9-122.5-111.4-125.3-225.6c0-270.1,356.5-339.8,462.3-89.1C5352.5,3960.1,5124.2,4174.5,4926.4,4060.3z"></path>
                <path d="M4926.4,3135.8c-86.3-36.2-153.2-142-153.2-239.5c0-27.9,33.4-89.1,72.4-136.5c144.8-175.4,428.8-78,428.8,147.6C5274.5,3094,5093.5,3211,4926.4,3135.8z"></path>
                <path d="M4842.9,2158.3c-130.9-128.1-61.3-373.2,114.2-415c153.2-39,317.5,97.5,317.5,261.8c0,33.4-36.2,97.5-80.7,142C5087.9,2252.9,4940.4,2258.5,4842.9,2158.3z"></path>
                <path d="M4876.3,1278.2c-30.6-16.7-69.6-75.2-91.9-128.1c-36.2-86.3-36.2-105.8,8.3-197.7c78-161.5,270.1-189.4,398.2-61.3c55.7,55.7,75.2,197.7,39,292.4C5191,1283.8,4982.1,1339.5,4876.3,1278.2z"></path>
                <path d="M4007.4-156c-197.7-214.4,64-529.1,311.9-376c144.8,91.9,150.4,303.6,11.1,412.2C4246.9-55.7,4082.6-75.2,4007.4-156z"></path>
                <path d="M3211-609.9c-55.7-55.7-80.8-108.6-80.8-169.9c0-119.7,130.9-250.6,250.6-250.6c119.8,0,250.6,130.9,250.6,250.6c0,61.3-25,114.2-80.7,169.9s-108.6,80.8-169.9,80.8C3319.6-529.1,3266.7-554.2,3211-609.9z"></path>
                <path d="M2473-1002.6c-86.3-33.4-150.4-136.5-150.4-239.5c0-220,284.1-320.3,428.9-147.6c117,136.5,89.1,292.4-66.8,373.2C2595.5-969.1,2559.3-966.4,2473-1002.6z"></path>
                <path d="M1584.6-1517.8c-130.9-128.1-58.5-378.8,117-412.2c178.2-33.4,345.3,133.7,303.6,300.8C1960.5-1448.1,1715.5-1384.1,1584.6-1517.8z"></path>
                <path d="M5720.1-103c-103-36.2-167.1-130.9-167.1-236.7c0-289.6,387.1-342.5,484.6-66.8c33.4,97.5-36.2,242.3-139.2,284.1C5798.1-80.8,5784.2-80.8,5720.1-103z"></path>
                <path d="M6483.2-596c-236.7-105.8-153.2-462.3,105.8-462.3c217.2,0,325.8,239.5,183.8,401C6692.1-568.1,6594.6-548.6,6483.2-596z"></path>
                <path d="M7290.8-1058.3c-136.5-55.7-183.8-214.4-108.6-359.3c44.6-91.9,91.9-114.2,225.6-114.2c250.6,0,320.3,342.5,97.5,459.5C7413.3-1024.8,7377.1-1022.1,7290.8-1058.3z"></path>
                <path d="M8101.2-1528.9c-169.9-72.4-211.6-278.5-83.5-406.6c147.6-150.4,415-41.8,415,164.3C8432.6-1604.1,8246-1467.6,8101.2-1528.9z"></path>
                </g>
            </g>
        </svg>
    ));
  }
}
