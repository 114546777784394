import { observable, flow } from "mobx";

import { ControlService } from '../../../api/modelling';
import { ControlStore } from "./ControlStore";

export class ControlOptionsStore {

    public controlService: ControlService;
    public parentStore: ControlStore;

    @observable public loading: boolean = false;
    @observable public categories = [];
    @observable public error: any = null;

    constructor(parentStore: ControlStore) {
        this.parentStore = parentStore;
        this.controlService = parentStore.controlService;
    }

    public loadControlMapOptions = flow(function*() {
        this.loading = true;

        try {
            const result = yield this.controlService.getMapCategories({});
            this.categories = result.items;
        } catch (e) {
            console.error(e);
            this.error = e;
        } finally {
            this.loading = false;
        }
    });
}
