import React, { Component } from "react";
import { observer } from "mobx-react";
import * as moment from "moment";

import { Text } from "office-ui-fabric-react/lib/Text";
import { MessageBar } from "office-ui-fabric-react/lib/MessageBar";
import { DetailsListLayoutMode, Selection, SelectionMode, ConstrainMode } from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { ContextualMenu } from "office-ui-fabric-react/lib/ContextualMenu";
import { PersonaSize } from "office-ui-fabric-react/lib/Persona";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { IconButton } from "office-ui-fabric-react/lib/Button";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { ListItemReveal } from "@modules/base/components/ListItemReveal";
import { RoutingLink } from "@modules/base/components/RoutingLink";
import { WrappedContent } from "@modules/base/components/WrappedContent";
import { PhotoPersona } from "@modules/identity/containers/utilities/PhotoPersona";

import { StateFlowTarget } from "@modules/stateflows/components/core/StateFlowTarget";

export const StateFlowGridView = observer(
    class StateFlowGridView extends Component {
        constructor(props) {
            super(props);

            this.state = {};

            this._selection = new Selection({
                onSelectionChanged: () => {
                    this.setState({
                        selectionDetails: this._getSelectionDetails(),
                    });
                },
            });

            this._columns = [
                {
                    key: "name",
                    name: "Name",
                    fieldName: "name",
                    minWidth: 200,
                    isRowHeader: true,
                    isResizable: true,
                    data: "string",
                    onRender: (item) => {
                        return (
                            <WrappedContent>
                                <RoutingLink
                                    url={`/settings/workflows/${item.id}`}
                                    onClick={() => this.props.onStateFlowClick && this.props.onStateFlowClick(item)}
                                    onClickEnabled={this.props.onStateFlowClick}
                                >
                                    {item.name}
                                </RoutingLink>
                                <ListItemReveal>
                                    {this.props.onStateFlowMenuClick && (
                                        <IconButton
                                            iconProps={{ iconName: "MoreVertical", style: { fontWeight: 600, color: "var(--black)" } }}
                                            onClick={(ev) => this.props.onStateFlowMenuClick(item, ev)}
                                        />
                                    )}
                                </ListItemReveal>
                                <Text variant="small" block>
                                    {item.description}
                                </Text>
                            </WrappedContent>
                        );
                    },
                    isPadded: true,
                },
                {
                    key: "target",
                    name: "Target",
                    fieldName: "target",
                    minWidth: 150,
                    maxWidth: 180,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return <StateFlowTarget value={item.target} />;
                    },
                    isPadded: true,
                },
                {
                    key: "version",
                    name: "Version",
                    fieldName: "version",
                    minWidth: 80,
                    maxWidth: 110,
                    isResizable: false,
                    data: "string",
                    onRender: (item) => {
                        return (
                            <div>
                                {item.currentVersion}
                                {item.publishedVersion && item.currentVersion !== item.publishedVersion && (
                                    <div title={`Published version is ${item.publishedVersion}`}>
                                        <Icon iconName="ReadingMode" />
                                    </div>
                                )}
                            </div>
                        );
                    },
                    isPadded: true,
                },
                {
                    key: "published",
                    name: "Published",
                    fieldName: "published",
                    minWidth: 170,
                    maxWidth: 220,
                    isResizable: true,
                    sortAscendingAriaLabel: "Sorted A to Z",
                    sortDescendingAriaLabel: "Sorted Z to A",
                    data: "date",
                    onRender: (item) => {
                        return item.publishedBy ? (
                            <PhotoPersona
                                principalId={item.publishedBy.id}
                                size={PersonaSize.size28}
                                text={item.publishedBy.name}
                                showSecondaryText={true}
                                secondaryText={moment.utc(item.published).fromNow()}
                            />
                        ) : null;
                    },
                    isPadded: true,
                },
                {
                    key: "created",
                    name: "Created",
                    fieldName: "created",
                    minWidth: 170,
                    maxWidth: 220,
                    isResizable: true,
                    isSorted: true,
                    isSortedDescending: true,
                    sortAscendingAriaLabel: "Sorted A to Z",
                    sortDescendingAriaLabel: "Sorted Z to A",
                    data: "date",
                    onRender: (item) => {
                        return (
                            <PhotoPersona
                                principalId={item.createdBy ? item.createdBy.id : null}
                                size={PersonaSize.size28}
                                text={item.createdBy.name}
                                showSecondaryText={true}
                                secondaryText={moment.utc(item.created).fromNow()}
                            />
                        );
                    },
                    isPadded: true,
                },
            ];
        }

        render() {
            const { stateFlows, loading, className, onStateFlowClick } = this.props;
            const { contextualMenuProps } = this.state;

            return (
                <BoxPanel shadow>
                    <ShimmeredDetailsList
                        items={stateFlows}
                        compact={false}
                        columns={this._columns}
                        selectionMode={SelectionMode.none}
                        getKey={this._getKey}
                        setKey="single"
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.horizontalConstrained}
                        isHeaderVisible={true}
                        selection={this._selection}
                        selectionPreservedOnEmptyClick={true}
                        onItemInvoked={onStateFlowClick}
                        //onItemContextMenu={(item, index, ev) => this._onItemContextMenu(item, index, ev) }
                        enterModalSelectionOnTouch={true}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        checkButtonAriaLabel="Row checkbox"
                        enableShimmer={loading}
                        removeFadingOverlay={true}
                        shimmerLines={10}
                        ariaLabelForShimmer="Workflows being fetched"
                        ariaLabelForGrid="Workflow list"
                    />

                    {!loading && !stateFlows.length && <MessageBar>There are no matching workflows.</MessageBar>}

                    {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}
                </BoxPanel>
            );
        }

        _getKey(item, index) {
            return item ? item.id : index;
        }

        _getSelectionDetails() {
            const selectionCount = this._selection.getSelectedCount();

            switch (selectionCount) {
                case 0:
                    return "No items selected";
                case 1:
                    return "1 item selected: " + this._selection.getSelection()[0].name;
                default:
                    return `${selectionCount} items selected`;
            }
        }

        _onColumnClick(ev, column) {
            console.log(column);
        }

        _onItemContextMenu(item, index, ev) {
            const contextualMenuProps = {
                target: ev.target,
                items: [
                    {
                        key: "text",
                        name: `${this._selection.getSelectedCount()} selected`,
                    },
                ],
                onDismiss: () => {
                    this.setState({
                        contextualMenuProps: undefined,
                    });
                },
            };

            if (index > -1) {
                this.setState({
                    contextualMenuProps: contextualMenuProps,
                });
            }

            return false;
        }
    }
);
