import React, { Component } from "react";
import { observer, inject } from "mobx-react";

import { JsonQueryString } from "../../utils/JsonQueryString";
import { SearchBox } from "office-ui-fabric-react/lib/SearchBox";

export const BigSearchBox = inject("routing")(
    observer(
        class BigSearchBox extends Component {
            render() {
                const { className, value, placeholder, style } = this.props;

                return (
                    <div className={className} style={style}>
                        <SearchBox
                            value={value}
                            placeholder={placeholder}
                            styles={{
                                root: { height: "auto", minWidth: "240px", maxWidth: "550px", margin: "auto" },
                                field: { padding: "10px" },
                            }}
                            //onEscape={() => this._onUpdate(null)}
                            onClear={() => this._onUpdate(null)}
                            onSearch={this._onUpdate}
                        />
                    </div>
                );
            }

            _onUpdate = (keywords) => {
                const { routing, onSearch, url, queryKey } = this.props;

                if (url) {
                    let fullUrl = url;
                    if (keywords) {
                        const key = encodeURIComponent(queryKey || "query");
                        fullUrl = `${url}${url.indexOf("?") !== -1 ? "&" : "?"}${key}=${JsonQueryString.encode({
                            keywords,
                        })}`;
                    }
                    routing.push(fullUrl);
                } else if (onSearch) {
                    onSearch(keywords);
                }
            };
        }
    )
);
