export const buildRoles = (additionalRoles) => {
    const roles = [
        { key: 'default', text: 'Default Roles', itemType: 2 },
        { key: 'Reader', text: 'Reader' },
        { key: 'Contributor', text: 'Contributor' },
        { key: 'Owner', text: 'Owner' }
    ];

    if(additionalRoles) {
        if(additionalRoles.length > 0) {
            roles.push({ key: 'custom', text: 'Custom Roles', itemType: 2 });
            additionalRoles.forEach(role => {
                roles.push(role);
            });
        } else {
            Object.keys(additionalRoles).forEach(section => {
                roles.push({ key: section, text: section, itemType: 2 });
                additionalRoles[section].forEach(role => {
                    roles.push(role);
                });
            });
        }
    }

    return roles;
}