import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";

import { Stack } from "office-ui-fabric-react/lib/Stack";
import { PanelType } from "office-ui-fabric-react/lib/Panel";
import { PrimaryButton, DefaultButton, IconButton } from "office-ui-fabric-react/lib/Button";
import { Dialog, DialogType, DialogFooter } from "office-ui-fabric-react/lib/Dialog";

import { ObservablePanel } from "@baseComponents/ObservablePanel";

import { AssetEditForm } from "../../components/core/AssetEditForm";

export const AssetNewPanel = inject(
    "assetStore",
    "routing"
)(
    withRouter(
        observer(
            class AssetNewPanel extends Component {
                render() {
                    const { newStore } = this.props.assetStore;
                    const { formData, formOptions, visible, isCloseDialogOpen, loading, isValid, isDirty } = newStore;
                    return (
                        <>
                            <ObservablePanel
                                pageType={`New Asset`}
                                panelType={PanelType.medium}
                                hidden={!visible}
                                onClosePanel={() => this._onCancel(false)}
                                onDismiss={() => this._onCancel(true)}
                                onRenderFooter={this._renderFooter}
                            >
                                {formData && (
                                    <AssetEditForm formData={formData} formOptions={formOptions} isValid={isValid} isDirty={isDirty} loading={loading} />
                                )}
                            </ObservablePanel>
                            <Dialog
                                hidden={!isCloseDialogOpen}
                                onDismiss={() => this._onKeepEditing()}
                                dialogContentProps={{
                                    type: DialogType.largeHeader,
                                    title: "You might lose your changes",
                                    subText: "It looks like you have some unsaved changes, are you sure that you want to lose them?",
                                }}
                                modalProps={{
                                    isBlocking: true,
                                    styles: { main: { maxWidth: 450 } },
                                }}
                            >
                                <DialogFooter>
                                    <PrimaryButton onClick={() => this._onCancel(false)} text="Close" />
                                    <DefaultButton onClick={() => this._onKeepEditing()} text="Keep editing" />
                                </DialogFooter>
                            </Dialog>
                        </>
                    );
                }

                _renderFooter = () => {
                    const { newStore } = this.props.assetStore;

                    return (
                        <Stack horizontal horizontalAlign="space-between">
                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <PrimaryButton onClick={() => this._onSave()} iconProps={{ iconName: "Save" }} text="Save" disabled={!newStore.isValid} />
                                {newStore.isDirty && (
                                    <IconButton
                                        iconProps={{ iconName: "Undo" }}
                                        onClick={() => {
                                            newStore.resetFormData();
                                        }}
                                        text="Undo Changes"
                                    />
                                )}
                            </Stack>
                            <Stack horizontal tokens={{ childrenGap: 20 }}>
                                <DefaultButton onClick={() => this._onCancel(false)} iconProps={{ iconName: "Cancel" }} text="Cancel" />
                            </Stack>
                        </Stack>
                    );
                };

                _onSave() {
                    const { assetStore, onAfterSave } = this.props;
                    const { newStore } = assetStore;

                    return newStore.createAsset().then((item) => {
                        if (onAfterSave) {
                            Promise.resolve(onAfterSave(item)).then(() => newStore.hide({}));
                        } else {
                            newStore.hide({});
                        }
                    });
                }

                _onCancel(dismiss) {
                    const { assetStore, onCancel } = this.props;
                    const { newStore } = assetStore;

                    if (dismiss && newStore.isDirty) {
                        newStore.isCloseDialogOpen = true;
                        return;
                    }

                    if (onCancel) {
                        Promise.resolve(onCancel(dismiss)).then(() => newStore.hide({}));
                    } else {
                        newStore.hide({});
                    }
                }

                _onKeepEditing() {
                    const { assetStore } = this.props;
                    const { newStore } = assetStore;

                    newStore.isCloseDialogOpen = false;
                }
            }
        )
    )
);
