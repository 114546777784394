import React from "react";
import { observer } from "mobx-react";

import { SearchBox } from "office-ui-fabric-react/lib/SearchBox";
import { CommandBar } from "office-ui-fabric-react/lib/CommandBar";

import { BoxPanel } from "@modules/base/components/BoxPanel";

export const ProductCommandBar = observer(({ keywords, onSearchChange, onNewProduct }) => {
    const items = [
        {
            key: "newDemandType",
            text: "New Demand Type",
            iconProps: { iconName: "Add" },
            onClick: () => onNewProduct(null),
        },
    ];

    const farItems = [
        {
            key: "search",
            text: "Search",
            onRender: () => {
                return (
                    <SearchBox
                        value={keywords}
                        placeholder="Search"
                        styles={{ root: { height: "auto", minWidth: "270px" } }}
                        onClear={() => onSearchChange(null)}
                        onSearch={onSearchChange}
                    />
                );
            },
        },
    ];

    return (
        <BoxPanel shadow>
            <CommandBar styles={{ root: { paddingRight: "0px" } }} items={items} farItems={farItems} />
        </BoxPanel>
    );
});
