import { observable, computed } from "mobx";

import { PageService } from '../../../api/pages';
import { PageInstanceStore } from "./PageInstanceStore";

export class PagePermissionStore {

    public pageService: PageService;
    public parentStore: PageInstanceStore;

    @observable public saving: boolean = false;

    constructor(parentStore: PageInstanceStore) {
        this.parentStore = parentStore;
        this.pageService = parentStore.pageService;
    }

    @computed
    public get canRead() {
        const permission = this.parentStore.selectionStore.permission;

        if(!permission) {
            return false;
        }

        return permission.canRead;
    }    

    @computed
    public get canEdit() {
        const permission = this.parentStore.selectionStore.permission;

        if(!permission) {
            return false;
        }

        return permission.canUpdate;
    }

    @computed
    public get canSave() {
        const permission = this.parentStore.selectionStore.permission;

        if(!permission) {
            return false;
        }

        return permission.canUpdate;
    }

    @computed
    public get canDelete() {
        const permission = this.parentStore.selectionStore.permission;

        if(!permission) {
            return false;
        }

        return permission.canDelete;
    }

    @computed
    public get canShare() {
        const permission = this.parentStore.selectionStore.permission;

        if(!permission) {
            return false;
        }

        return permission.canShare;
    }
}
