import { observable, action, flow } from "mobx";

import { ControlService } from "../../../api/modelling";
import { ControlStore } from "./ControlStore";

export class ControlPickerStore {
    private parentStore: ControlStore;
    private controlService: ControlService;

    @observable public selectedControls: any[] = [];
    @observable public controls: any[] = [];
    @observable public loading: boolean = false;

    constructor(parentStore: ControlStore) {
        this.parentStore = parentStore;
        this.controlService = parentStore.controlService;
    }

    public loadControls = flow(function* (options) {
        this.loading = true;
        const o = Object.assign({ pageSize: 25, startIndex: 0 }, options);

        try {
            const result = yield this.controlService.getControls(o);
            this.controls = result.items;
            return result.items;
        } catch (e) {
            console.error(e);
            this.error = e;
        } finally {
            this.loading = false;
        }
    });

    public loadControlsByIds = flow(function* (ids) {
        this.loading = true;

        try {
            const result = yield Promise.all(
                ids.map((id) => {
                    return this.controlService.getControl(id);
                })
            );
            this.controls = result;
        } catch (e) {
            console.error(e);
            this.error = e;
        } finally {
            this.loading = false;
        }
    });

    @action
    public setSelected(assets: any[]) {
        this.selectedControls = assets;
    }

    @action
    public setSelectedWithId(id: string | null) {
        if (!id) {
            this.selectedControls = [];
        } else {
            if (this.selectedControls.length == 1 && this.selectedControls[0].id == id) {
                return;
            }
            return this.loadControlsByIds([id]);
        }
    }

    @action
    public setSelectedWithIds(ids: string[] | null) {
        if (ids == null || ids.length == 0) {
            this.selectedControls = [];
        } else {
            return this.loadControlsByIds(ids);
        }
    }
}
