import React from "react";
import {
    Persona as FabricPersona,
    PersonaSize as FabricPersonaSize,
    PersonaPresence as FabricPersonaPresence,
} from "office-ui-fabric-react/lib/Persona";

const Persona = (props) => {
    return <FabricPersona {...props} />;
};

const PersonaSize = FabricPersonaSize;
const PersonaPresence = FabricPersonaPresence;

export { Persona, PersonaSize, PersonaPresence };
