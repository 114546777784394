import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router';

import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';

import { SettingsListNav as StatelessSettingsListNav } from '../../components/core/SettingsListNav';

export const SettingsListNav = inject('settingsStore', 'routing')(withRouter(observer(
  class SettingsListNav extends Component {

        render () {
          const { settingsStore } = this.props;
          const { loading, keywords, filteredSettings } = settingsStore;

          return (
            <div className="sidebar sidebar--left sidebar--nav">
                  <div className="sidebar--inner">

                    <SearchBox placeholder="Filter settings"
                      autoComplete="off"
                      styles={{ root: { height: '40px', borderColor: 'var(--border-color-dark)' }}}
                      underlined
                      value={keywords} 
                      onClear={(e) => settingsStore.setKeywords(null)}
                      onChange={(e, newValue) => settingsStore.setKeywords(newValue)} />

                    <StatelessSettingsListNav 
                      loading={loading} 
                      settings={filteredSettings}
                      onItemClick={this._onLinkClick} />

                  </div>
              </div>
          );
        }

        _onLinkClick = (item) => {
          if(item && item.url) {
            this.props.routing.push(item.url);
          }
        };
    }
)));