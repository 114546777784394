import React, { useCallback } from "react";

import { DefaultButton } from "office-ui-fabric-react/lib/Button";

import { ActionBar } from "@modules/base/components/ActionBar";

export function WorkItemAssessmentBar({ routingManager, assessmentId }) {
    const goToAssessment = useCallback(() => {
        routingManager.push(`/assurance/browse/${assessmentId}`);
    }, [routingManager, assessmentId]);

    return (
        <ActionBar
            compact
            className="row--s animated pulse fast"
            title="Security Assurance Assessment"
            description="A security assurance assessment has been created from this request. You can can view current status and details of the assessment by clicking the button to the right."
            variant="white"
        >
            <DefaultButton onClick={goToAssessment} iconProps={{ iconName: "WorkItem" }}>
                Go to assessment
            </DefaultButton>
        </ActionBar>
    );
}
