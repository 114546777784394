import React from 'react';
import { cx, css } from 'emotion';
import { Stack } from 'office-ui-fabric-react/lib/Stack';

import { DefaultButton } from '../../../base/ui';
import { PhotoPersona } from '../../containers/utilities/PhotoPersona';
import { useStores } from '../../../../hooks/useStores';
import { PersonaSize } from "office-ui-fabric-react/lib/Persona";

export const UserInfoViewerPart = (props) => {
    const { part } = props;
    const { principalContext, routing } = useStores();
    const { style, showEditProfileButton, user, showSalutation } = part.properties;

    let selectedUser = user
    if (style === 'currentUser') {
        selectedUser = principalContext.current;
    }

    const today = new Date()
    const currentHour = today.getHours()
    let salutation = 'Good evening';

    if (currentHour < 12) {
        salutation = 'Good morning';
    } else if (currentHour < 18) {
        salutation = 'Good afternoon';
    }

    let photoPersonaProps = {}
    if (showSalutation) {
        photoPersonaProps = {
            text: salutation,
            showSecondaryText: true,
            secondaryText: selectedUser.name,
            tertiaryText: selectedUser.jobTitle,
        }
    } else {
        photoPersonaProps = {
            text: selectedUser ? selectedUser.name: '',
            showSecondaryText: true,
            secondaryText: selectedUser ? selectedUser.jobTitle : '',
        }
    }

    return (<Stack horizontalAlign="space-between" horizontal >
        {selectedUser && <PhotoPersona
            size={PersonaSize.size56} 
            principalId={selectedUser.id}
            {...photoPersonaProps}
        />}
        {style === 'currentUser' && showEditProfileButton && <DefaultButton
            text="Edit profile"
            onClick={()=>{ routing.push('/account/overview') }}
            className={cx(css` max-width: 150px `)}
        />}
    </Stack>
    );
}