import { observable, flow, computed } from "mobx";

import { WorkflowService } from "../../../api/workflows";
import { WorkflowStore } from "./WorkflowStore";

export class WorkflowSummaryStore {
    public parentStore: WorkflowStore;
    public workflowService: WorkflowService;

    @observable public summary: any = null;
    @observable public loading: boolean = false;
    @observable public error: any = null;

    constructor(parentStore: WorkflowStore) {
        this.parentStore = parentStore;
        this.workflowService = parentStore.workflowService;
    }

    @computed
    public get totalTaskCount() {
        if (!this.summary || !this.summary.tasks) {
            return 0;
        }

        return this.summary.tasks.totalCount;
    }

    @computed
    public get totalTaskCountTooltip() {
        if (!this.summary || !this.summary.tasks) {
            return "No tasks configured";
        }

        const { pendingCount, totalCount, personalCount } = this.summary.tasks;

        return `${pendingCount} out of ${totalCount} ${totalCount == 1 ? "task is" : "tasks are"} pending${
            personalCount > 0 ? `, ${personalCount} assigned to you` : ""
        }`;
    }

    @computed
    public get totalActivityCount() {
        if (!this.summary || !this.summary.activities) {
            return 0;
        }

        return this.summary.activities.totalCount;
    }

    @computed
    public get totalActivityCountTooltip() {
        if (!this.summary || !this.summary.activities) {
            return "No automations configured";
        }

        const { directCount, inheritedCount } = this.summary.activities;

        return `${directCount} automation${
            directCount === 1 ? "" : "s"
        } configured directly, ${inheritedCount} automation${inheritedCount === 1 ? " is" : "s are"} inherited`;
    }

    public reset = flow(function* (options: any) {
        this.summary = null;
    });

    public loadEntitySummary = flow(function* (options: any) {
        const o = Object.assign({}, options);
        this.loading = true;
        this.summary = null;
        this.error = null;

        try {
            this.summary = yield this.workflowService.getWorkflowEntitySummary(o.id);
        } catch (e) {
            this.error = e;
            console.error(e);
        } finally {
            this.loading = false;
        }
    });
}
