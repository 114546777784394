import moment from 'moment';
import { generateWorkItemQuery } from '../queries';

async function getWorkItemOpenedClosed(query, groupBy, api) {
    const computedQuery = generateWorkItemQuery(query);
    // if (!computedQuery.timeline) {
    //     computedQuery.timeline = {
    //         before: moment().utc().startOf('month').add(1, 'month').format(),
    //         after: moment().utc().startOf('month').subtract(6, 'months').format(),
    //     };
    // }
    return await api({
        query: computedQuery
    });
}

export { getWorkItemOpenedClosed }