import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Sidebar } from '../../../base/components/Sidebar'
import { SidebarProperties } from '../../../base/components/SidebarProperties';
import { SidebarProperty } from '../../../base/components/SidebarProperty';

export const SystemViewSideBar = inject('systemStore')(observer(
    class SystemViewSideBar extends Component {
        constructor(props) {
            super(props)
        }
        render () {
            const { systemStore } = this.props;
            const { selectedSystem } = systemStore;

            return (
                <React.Fragment>
                    {selectedSystem && <Sidebar dataTest="AssetSideBar">
                        <SidebarProperties title="Details" icon="List" open>
                            <SidebarProperty label="Portfolio" value={selectedSystem.portfolio ? selectedSystem.portfolio.name : ''} />
                            <SidebarProperty label="Type" value={selectedSystem.pattern ? selectedSystem.pattern.name : ''} />
                            <SidebarProperty label="Alias" value={selectedSystem.alias} />
                            <SidebarProperty label="Business Criticality" value={selectedSystem.businessCriticality} />
                            <SidebarProperty label="URL" type="externalLink" value="Link" url={selectedSystem.detailsUrl} />
                            <SidebarProperty label="State" value={selectedSystem.state} />
                        </SidebarProperties>
                        {selectedSystem.primarySupplier && (
                            <SidebarProperties title="Primary Supplier" icon="Ferry" open>
                                <SidebarProperty label="Name" value={selectedSystem.primarySupplier.name} />
                                <SidebarProperty label="Created" value={selectedSystem.primarySupplier.created} type="datetime" />
                                <SidebarProperty label="State" value={selectedSystem.primarySupplier.state} />
                            </SidebarProperties>
                        )}
                        <SidebarProperties title="About" icon="Info" open>
                            <SidebarProperty label="Created" value={selectedSystem.created} type="datetime" />
                            <SidebarProperty value={selectedSystem.createdBy} type="principal" />
                            <SidebarProperty label="Last Modified" value={selectedSystem.modified} type="datetime" />
                            <SidebarProperty value={selectedSystem.modifiedBy} type="principal" />
                        </SidebarProperties>
                    </Sidebar>}
                </React.Fragment>
            );
        }
    }
));