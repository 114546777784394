import React from "react";
import { cx, css } from "emotion";
import { observer } from "mobx-react";

import { Label } from "office-ui-fabric-react/lib/Label";

import { MarkdownEditor } from "./MarkdownEditor";

export const MarkdownField = observer(
    ({ value, onChange, label, placeholder, required, disabled, height, className, dataTest }) => {
        return (
            <div className="ms-TextField ms-TextField--multiline" data-test={dataTest || "MarkdownField"}>
                <div className="ms-TextField-wrapper">
                    <Label required={required}>{label}</Label>
                    <div
                        className={cx(
                            className,
                            "ms-TextField-fieldGroup",
                            css`
                                box-shadow: none;
                                border-width: 1px;
                                border-style: solid;
                                border-color: var(--black);
                                border-radius: 2px;
                                background: var(--white);
                            `
                        )}
                    >
                        <MarkdownEditor
                            height={height}
                            placeholder={placeholder}
                            required={required}
                            disabled={disabled}
                            value={value}
                            onChange={onChange}
                            className="ms-TextField-field"
                        />
                    </div>
                </div>
            </div>
        );
    }
);
