import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import ContainerDimensions from 'react-container-dimensions'
import { TimeSeries} from './TimeSeries';
import { Gauge } from './Gauge';
import { Pie } from './Pie';
import { XyChart } from './XyChart';
import { StackedBar } from './StackedBar';
import { NumberChart } from './NumberChart';
import { RadarChart } from './RadarChart';
import {cx, css} from 'emotion';

const chartContainerClassName = cx(
    css`
        .c3-tooltip-container table th {
            background-color: var(--app-header-background);
            color: var(--text-color-light)
        }
        .c3-tooltip-container table td.name,
        .c3-tooltip-container table td.value {
            background-color: var(--background-color);
            color: var(--text-color-light);
        }
    `
)

export const Chart = observer((props) => {
    return <div className={cx(chartContainerClassName, props.className)}>
            {props.type === 'pie' && <Pie {...props} />}
            {props.type === 'gauge' && <Gauge {...props} />}
            {props.type === 'timeSeries' && <TimeSeries {...props} />}
            {props.type === 'xyChart' && <XyChart {...props} />}
            {props.type === 'stackedBar' && <StackedBar {...props} />}
            {props.type === 'number' && <NumberChart {...props} />}
            {props.type === 'radar' && <RadarChart {...props} />}
        </div>
    ;
});
