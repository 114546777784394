import React, { Component } from "react";
import { observer } from "mobx-react";

import { SearchBox } from "office-ui-fabric-react/lib/SearchBox";
import { CommandBar } from "office-ui-fabric-react/lib/CommandBar";
import { BoxPanel } from "@modules/base/components/BoxPanel";

export const UserListCommandBar = observer(
    class UserListCommandBar extends Component {
        render() {
            const { keywords, onSearchChange, onAddUser } = this.props;

            const items = [
                {
                    key: "newIdentityUser",
                    text: "Add User",
                    iconProps: { iconName: "Add" },
                    onClick: () => onAddUser(null),
                },
            ];

            const farItems = [
                {
                    key: "search",
                    text: "Search",
                    onRender: () => {
                        return (
                            <SearchBox
                                value={keywords}
                                placeholder="Search by title"
                                styles={{ root: { height: "auto", minWidth: "270px" } }}
                                onSearch={onSearchChange}
                                onClear={() => {
                                    onSearchChange("");
                                }}
                                onChange={(e, s) => {
                                    if (s === "") onSearchChange("");
                                }}
                            />
                        );
                    },
                },
            ];

            return (
                <BoxPanel shadow>
                    <CommandBar styles={{ root: { paddingRight: "0px" } }} items={items} farItems={farItems} />
                </BoxPanel>
            );
        }
    }
);
