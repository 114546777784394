import { observable, computed, action, toJS } from "mobx";

import { RiskService } from "../../../api/risks";
import { RiskStore } from "./RiskStore";

interface ResponseHandle {
    resolve: (value: any) => void;
    reject: (value?: any) => void;
}

export class RiskAssignFormStore {
    public parentStore: RiskStore;
    public riskService: RiskService;
    private responseHandle?: ResponseHandle;

    @observable public visible: boolean = false;
    @observable public formData: any;
    @observable public formOptions: any;

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = this.parentStore.riskService;
    }

    @action
    public show(options) {
        this.visible = true;
        this.formOptions = {
            pageTitle: options.pageTitle || `${options.risk.code} - ${options.risk.title}`,
        };
        this.formData = {
            id: options.risk.id,
            assignedToGroup: options.lifecycle.assignedToGroup,
            assignedToUser: options.lifecycle.assignedToUser,
        };

        return new Promise<any>((resolve, reject) => {
            this.responseHandle = { resolve, reject };
        });
    }

    @action
    public hide(_) {
        this.visible = false;
        this.formData = null;
        this.formOptions = null;
        this.responseHandle = null;
    }

    @action
    public resolve(formData) {
        this.responseHandle.resolve(toJS(formData));
    }

    @action
    public reject(error) {
        this.responseHandle.reject(toJS(error));
    }

    @computed
    public get isValid() {
        return (
            this.formData &&
            this.formData.id &&
            this.formData.assignedToGroup &&
            this.formData.assignedToGroup.id &&
            this.formData.assignedToUser &&
            this.formData.assignedToUser.id
        );
    }
}
