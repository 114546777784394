import React, { Component } from "react";
import { observer } from "mobx-react";
import Moment from "react-moment";

import { Text } from "office-ui-fabric-react/lib/Text";
import { MessageBar } from "office-ui-fabric-react/lib/MessageBar";
import { DetailsListLayoutMode, Selection, SelectionMode, ConstrainMode } from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { ContextualMenu } from "office-ui-fabric-react/lib/ContextualMenu";
import { PersonaSize } from "office-ui-fabric-react/lib/Persona";
import { Icon } from "office-ui-fabric-react/lib/Icon";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { RoutingLink } from "@modules/base/components/RoutingLink";
import { WrappedContent } from "@modules/base/components/WrappedContent";
import { ListItemReveal } from "@modules/base/components/ListItemReveal";
import { PriorityScore } from "@modules/base/components/kanbanboard/components/PriorityScore";
import { PhotoPersona } from "@modules/identity/containers/utilities/PhotoPersona";

import { WorkItemDueIndicator } from "@modules/workitems/components/core/WorkItemDueIndicator";
import { WorkItemAgeIndicator } from "@modules/workitems/components/core/WorkItemAgeIndicator";

export const WorkItemGridView = observer(
    class WorkItemGridView extends Component {
        constructor(props) {
            super(props);

            this.state = {};

            this._selection = new Selection({
                onSelectionChanged: () => {
                    this.setState({
                        selectionDetails: this._getSelectionDetails(),
                    });
                },
            });

            this._columns = [
                {
                    key: "title",
                    name: "Title",
                    fieldName: "title",
                    // minWidth: 150,
                    isRowHeader: true,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return (
                            <WrappedContent>
                                <RoutingLink
                                    url={`/demand/browse/${item.id}`}
                                    onClick={() => this.props.onWorkItemClick && this.props.onWorkItemClick(item)}
                                    onClickEnabled={this.props.onWorkItemClick}
                                >
                                    {item.code} - {item.title}
                                </RoutingLink>
                                <ListItemReveal>
                                    {item.assessmentId && (
                                        <RoutingLink primary url={`/assurance/browse/${item.assessmentId}`}>
                                            <Icon iconName="WorkItem" />
                                        </RoutingLink>
                                    )}
                                </ListItemReveal>
                                <Text variant="small" data-test="requestedBy" block>
                                    {item.product.name} requested by {item.requestedBy.name}{" "}
                                    <Moment date={item.created} fromNow />
                                </Text>
                            </WrappedContent>
                        );
                    },
                    isPadded: true,
                },
                {
                    key: "age",
                    name: "Age",
                    fieldName: "Age",
                    minWidth: 35,
                    maxWidth: 50,
                    isResizable: false,
                    data: "string",
                    onRender: (item) => {
                        return <WorkItemAgeIndicator workItem={item} />;
                    },
                    isPadded: false,
                },
                {
                    key: "due",
                    name: "Due",
                    fieldName: "due",
                    minWidth: 35,
                    maxWidth: 50,
                    isResizable: false,
                    data: "string",
                    onRender: (item) => {
                        return <WorkItemDueIndicator workItem={item} />;
                    },
                    isPadded: false,
                },
                {
                    key: "portfolio",
                    name: "Portfolio",
                    fieldName: "portfolio",
                    minWidth: 80,
                    maxWidth: 110,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return <span>{item.portfolio.name}</span>;
                    },
                    isPadded: false,
                },
                {
                    key: "priority",
                    name: "Priority",
                    fieldName: "priority",
                    minWidth: 65,
                    maxWidth: 70,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    isPadded: false,
                    onRender: (item) => {
                        return <PriorityScore priority={item.priority} size="mini" hideName={true} />;
                    },
                },
                {
                    key: "assigned",
                    name: "Assigned",
                    fieldName: "assigned",
                    minWidth: 200,
                    maxWidth: 230,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    onRender: (item) => {
                        return (
                            <PhotoPersona
                                principalId={item.assignedToUser ? item.assignedToUser.id : null}
                                size={PersonaSize.size28}
                                text={item.assignedToUser ? item.assignedToUser.name : "Unassigned"}
                                showUnknownPersonaCoin={!item.assignedToUser}
                                showSecondaryText={true}
                                secondaryText={item.assignedToGroup.name}
                            />
                        );
                    },
                    isPadded: false,
                },
                {
                    key: "subStatus",
                    name: "Status",
                    fieldName: "subStatus",
                    minWidth: 90,
                    maxWidth: 110,
                    isResizable: false,
                    onColumnClick: this._onColumnClick,
                    data: "string",
                    isPadded: false,
                },
                {
                    key: "modified",
                    name: "Date Modified",
                    fieldName: "modified",
                    minWidth: 100,
                    maxWidth: 130,
                    isResizable: true,
                    // isSorted: true,
                    // isSortedDescending: true,
                    // sortAscendingAriaLabel: "Sorted A to Z",
                    // sortDescendingAriaLabel: "Sorted Z to A",
                    onColumnClick: this._onColumnClick,
                    data: "date",
                    onRender: (item) => {
                        return <Moment utc date={item.modified} fromNow />;
                    },
                    isPadded: true,
                },
            ];
        }

        render() {
            const { workItems, loading, className, onWorkItemClick, compact } = this.props;
            const { contextualMenuProps } = this.state;

            return (
                <BoxPanel shadow className={"cyWorkItemGridView"}>
                    <ShimmeredDetailsList
                        items={workItems}
                        columns={this._columns}
                        selectionMode={SelectionMode.none}
                        getKey={this._getKey}
                        setKey="single"
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.horizontalConstrained}
                        isHeaderVisible={true}
                        selection={this._selection}
                        selectionPreservedOnEmptyClick={true}
                        onItemInvoked={onWorkItemClick}
                        //onItemContextMenu={(item, index, ev) => this._onItemContextMenu(item, index, ev) }
                        enterModalSelectionOnTouch={true}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        checkButtonAriaLabel="Row checkbox"
                        enableShimmer={loading}
                        removeFadingOverlay={true}
                        shimmerLines={25}
                        ariaLabelForShimmer="Work item being fetched"
                        ariaLabelForGrid="Work item list"
                    />

                    {!loading && !workItems.length && <MessageBar>There are no matching work items.</MessageBar>}

                    {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}
                </BoxPanel>
            );
        }

        _getKey(item, index) {
            return item ? item.id : index;
        }

        _getSelectionDetails() {
            const selectionCount = this._selection.getSelectedCount();

            switch (selectionCount) {
                case 0:
                    return "No items selected";
                case 1:
                    return "1 item selected: " + this._selection.getSelection()[0].name;
                default:
                    return `${selectionCount} items selected`;
            }
        }

        _onColumnClick(ev, column) {}

        _onItemContextMenu(item, index, ev) {
            const contextualMenuProps = {
                target: ev.target,
                items: [
                    {
                        key: "text",
                        name: `${this._selection.getSelectedCount()} selected`,
                    },
                ],
                onDismiss: () => {
                    this.setState({
                        contextualMenuProps: undefined,
                    });
                },
            };

            if (index > -1) {
                this.setState({
                    contextualMenuProps: contextualMenuProps,
                });
            }

            return false;
        }
    }
);
