import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';
import * as moment from 'moment';
import Moment from 'react-moment';

import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { ContextualMenu } from 'office-ui-fabric-react/lib/ContextualMenu';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';

const rootClassName = cx(
    'cygraph-ModuleCommandBar-root',
    css`
        position: fixed;
        left: calc(var(--app-nav-mini-width) + var(--sidebar-width-l));
        right: 0;
        bottom: 0;
        height: 56px;
        line-height: 38px;
        display: flex;

        & .wrapper {
            display: flex;
            width: 96%;
            max-width: 600px;
            margin: 0 auto;
            padding-right: var(--spacing);
            background: var(--nav-background);
            color: var(--nav-color);
            height: 100%;
            border: 1px solid var(--border-color);
            border-bottom: 0;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            padding: 8px 18px;
        }

        & .saving {
            padding: 7px 10px 7px 0;
        }

        & .modified {
            text-transform: lowercase;
            color: var(--primary-brand-color);
        }

        & .buttons {
            padding: 4px 0;
            & > div {
                margin-left: 10px;
            }
        }

        & .grow {
            flex-grow: 1;
        }

        @media screen and (max-width: 1023px) {
            left: calc(var(--app-nav-mini-width) + 30px);
            right: 30px;
        }

    `
);

export const ModuleCommandBar = observer(
    class ModuleCommandBar extends Component {
        render () {
            const { className, saving, isReadOnly, manifest, module, latestUpdate, onAssessment, onModule, onDownload } = this.props;

            const menuProps = {
                items: manifest.modules.map(module => ({
                    key: module.id,
                    text: module.title,
                    onClick: () => onModule(module),
                })),
            };

            return (
                <div className={cx(rootClassName, className)}>
                    <div className="wrapper animated fadeInUp">
                        <div className="saving">
                            { saving && <Spinner size={SpinnerSize.large} /> }
                        </div>
                        { isReadOnly ? (
                            <div className="grow modified">
                                <Icon iconName="LockSolid"/> assessment is read-only
                            </div> 
                        ) : (
                            <div className="grow modified">
                                { saving && ( 'saving...' ) }
                                { !saving && latestUpdate && (
                                <>
                                    <TooltipHost content={`Responses last saved on ${moment(latestUpdate).format('Do MMM YYYY, h:mm:ss a')}`}>
                                        auto saved { moment(latestUpdate).isAfter() ? 'a few seconds ago' : <Moment utc date={latestUpdate} fromNow /> } 
                                    </TooltipHost> 
                                </>
                                ) }
                                { !saving && !latestUpdate && (
                                <>
                                    <TooltipHost content="Responses to questions are automatically saved when you fill them in">
                                        auto save in operation
                                    </TooltipHost>                                    
                                </>
                                ) }                          
                            </div> 
                        ) }               
                        <div className="buttons">
                            <TooltipHost content="Export questions and answers to CSV">
                                <IconButton 
                                    iconProps={{ iconName: 'ExcelDocument' }}
                                    disabled={!onDownload}
                                    onClick={() => onDownload(module)} />
                            </TooltipHost>
                            
                            <TooltipHost content="Back to full assessment">
                                <IconButton 
                                    iconProps={{ iconName: 'WorkItem' }}
                                    disabled={!onAssessment}
                                    onClick={() => onAssessment()} />
                            </TooltipHost>

                            <TooltipHost content="Switch module">
                                <IconButton 
                                    iconProps={{ iconName: 'NoteForward' }}
                                    disabled={!onModule}
                                    menuProps={menuProps} />
                            </TooltipHost>
                        </div>
                    </div>
                </div>
            );
        }

        _getMenu(props) {
            return <ContextualMenu {...props} />;
        }          
    }
);