import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Stack } from 'office-ui-fabric-react';

import { BoxPanel } from '@baseComponents/BoxPanel';

export const BoardCommandBar = observer(
    class BoardCommandBar extends Component {
        
        render () {
            const { keywords, products, onSearchChange, onNewRequest, onToggleKanban, isCard } = this.props;
            const items = [
                {
                  key: 'newRequest',
                  text: 'New Request',
                  iconProps: { iconName: 'Add' },
                  onClick: () => onNewRequest(null),
                  split: products && products.length > 0,
                  subMenuProps: products ? {
                    onItemClick: (ev, item) => {
                        if(item && item.key) {
                            onNewRequest(item.data)
                        }
                    },
                    items: (products || []).map((p) => {
                        return { key: p.id, text: p.name, data: p }
                    })
                  } : null,
                },
            ];

            const farItems = [
                {
                    key: 'toggleKanban',
                    text: 'Kanban Board',
                    onRender: () => {
                        return (
                            <Stack horizontal styles={{ root: { height: 'auto', marginRight: '10px', marginTop: '12px' }}}>
                                <Stack.Item align="auto" styles={{root:{marginRight: '5px'}}}>
                                    <Text nowrap block>
                                        Kanban Board
                                    </Text>
                                </Stack.Item>
                                <Stack.Item align="auto">
                                    <Toggle checked={isCard} onChange={onToggleKanban} />
                                </Stack.Item>
                            </Stack>
                        );
                    }
                },
                {
                    key: 'search',
                    text: 'Search',
                    onRender: () => {
                        return (
                            <SearchBox
                                value={keywords}
                                placeholder="Search by title"
                                styles={{ root: { height: 'auto', minWidth: '270px' }}}
                                onSearch={onSearchChange} />
                        );
                    }
                }
            ];

            return (
                <BoxPanel shadow>
                    <CommandBar
                        styles={{ root: { paddingRight: '0px' } }}
                        items={items}
                        farItems={farItems}
                    />
                </BoxPanel>
            );
        }
    }
);