import React, { Component } from "react";
import { observer } from "mobx-react";

import { RichTextField } from "../../../editors/components/RichTextField";
import { BigTextField } from "../../../base/components/BigTextField";
import { PrincipalPicker } from "../../../identity/containers/PrincipalPicker";

import { RegistryPicker } from "../../containers/pickers/RegistryPicker";

export const RiskTransferForm = observer(
    class RiskTransferForm extends Component {
        render() {
            const { formData, formOptions } = this.props;

            return (
                <div>
                    <div className="row--xs">
                        <r-grid columns="2">
                            <r-cell span="1">
                                <RegistryPicker
                                    placeholder="Please select a risk registry"
                                    label="Transfer to Registry"
                                    except={formOptions.excludeCurrentRegistry ? [formOptions.currentRegistry.id] : []}
                                    required
                                    selected={formData.registry}
                                    onChange={(registry) => {
                                        formData.registry = registry;
                                    }}
                                />
                            </r-cell>
                        </r-grid>
                    </div>

                    <div className="row--xs">
                        <BigTextField
                            label="Title"
                            required
                            placeholder="Please enter a risk title"
                            value={formData.title}
                            transparent
                            onChange={(ev, value) => (formData.title = value)}
                        />
                    </div>

                    <r-grid columns="2">
                        <r-cell span="1">
                            <PrincipalPicker
                                label="Business Contact"
                                required
                                placeholder="Please select a business contact"
                                selectedId={
                                    formData.lifecycle.businessContact ? formData.lifecycle.businessContact.id : null
                                }
                                onSelected={(contact) => {
                                    formData.lifecycle.businessContact = contact;
                                }}
                            />
                        </r-cell>
                        <r-cell span="1">
                            <PrincipalPicker
                                label="Technical Contact"
                                required
                                placeholder="Please select a technical contact"
                                selectedId={
                                    formData.lifecycle.technicalContact ? formData.lifecycle.technicalContact.id : null
                                }
                                onSelected={(contact) => {
                                    formData.lifecycle.technicalContact = contact;
                                }}
                            />
                        </r-cell>
                    </r-grid>

                    <div className="row--xs">
                        <RichTextField
                            label="Summary"
                            required
                            placeholder="Please enter a summary of the risk."
                            value={formData.description}
                            onChange={(val) => {
                                formData.description = val;
                            }}
                        />
                    </div>

                    <div className="row--xs">
                        <RichTextField
                            label="Background"
                            required
                            placeholder="Please enter risk background"
                            value={formData.context}
                            onChange={(val) => {
                                formData.context = val;
                            }}
                        />
                    </div>
                </div>
            );
        }
    }
);
