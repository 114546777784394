import React, { useState, useEffect, useCallback, useMemo } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import * as joint from "jointjs";

import { StateFlowToolBarView } from "@modules/stateflows/components/editor/StateFlowToolBarView";
import { StateFlowCanvasView } from "@modules/stateflows/components/editor/StateFlowCanvasView";
import { hasTransitionStates } from "@modules/stateflows/stores/TargetMetadata";

const namespace = joint.shapes;

const rootClassName = cx(
    "cygraph-StateFlowEditorView-root",
    css`
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        overflow: auto;
    `
);

export const StateFlowEditorView = observer(
    ({
        manifest,
        mode,
        options,
        onAddStage,
        onEditStage,
        onRemoveStage,
        onMoveState,
        onAddState,
        onEditState,
        onRemoveState,
        onAddTrigger,
        onEditTrigger,
        onMoveTrigger,
        onRemoveTrigger,
        onContextMenu,
    }) => {
        const graph = useMemo(() => new joint.dia.Graph({}, { cellNamespace: namespace }), []);
        const [paper, setPaper] = useState(null);

        const preview = mode === "preview";

        const contextMenuCallback = useCallback(
            (model, position) => {
                if (!preview) {
                    const type = model.prop("data/type");
                    if (type === "state") {
                        onContextMenu({ position, type, state: model.prop("data") });
                    } else if (type === "stage") {
                        onContextMenu({ position, type, stage: model.prop("data") });
                    } else if (type === "trigger") {
                        onContextMenu({
                            position,
                            type,
                            trigger: { id: model.prop("data/id") },
                            state: { id: model.prop("data/state") },
                        });
                    }
                }
            },
            [preview, onContextMenu]
        );

        const onModelClick = useCallback(
            (model) => {
                if (!preview) {
                    const elementType = model.prop("data/type");
                    if (elementType === "state") {
                        onEditState({ state: { id: model.prop("data/id") } });
                    } else if (elementType === "stage") {
                        onEditStage({ stage: { id: model.prop("data/id") } });
                    } else if (elementType === "trigger") {
                        onEditTrigger({
                            trigger: { id: model.prop("data/id") },
                            state: { id: model.prop("data/state") },
                        });
                    }
                }
            },
            [preview, onEditStage, onEditState]
        );

        const onModelRemove = useCallback(
            (model) => {
                if (!preview) {
                    const elementType = model.prop("data/type");
                    if (elementType === "state") {
                        onRemoveState({ state: { id: model.prop("data/id") } });
                    } else if (elementType === "stage") {
                        onRemoveStage({ stage: { id: model.prop("data/id") } });
                    } else if (elementType === "trigger") {
                        onRemoveTrigger({
                            trigger: { id: model.prop("data/id") },
                            state: { id: model.prop("data/state") },
                        });
                    }
                }
            },
            [preview, onRemoveStage, onRemoveState]
        );

        const onStateAdd = useCallback(
            (model) => {
                if (!preview) {
                    const elementType = model.prop("data/type");
                    if (elementType === "stage") {
                        onAddState({ stage: { id: model.prop("data/id"), stage: model.prop("data/stage") } });
                    }
                }
            },
            [preview, onAddState]
        );

        const onStageAdd = useCallback(
            (model) => {
                if (!preview) {
                    const elementType = model.prop("data/type");
                    if (elementType === "stage") {
                        onAddStage({ stage: { id: model.prop("data/id") }, position: "after" });
                    }
                }
            },
            [preview, onAddStage]
        );

        const contextMenuButton = useMemo(() => {
            return new joint.elementTools.Button({
                x: "0",
                y: "0",
                offset: {
                    x: 20,
                    y: 0,
                },
                markup: [
                    {
                        tagName: "circle",
                        selector: "button",
                        attributes: {
                            r: 8,
                            fill: "var(--background-color)",
                            stroke: "var(--blue-10)",
                            "stroke-width": 2,
                            cursor: "pointer",
                        },
                    },
                    {
                        tagName: "path",
                        selector: "icon",
                        attributes: {
                            d: "M 4 0 L -4 0 M -4 -3 L 4 -3 M -4 3 L 4 3",
                            fill: "var(--background-color)",
                            stroke: "var(--blue-10)",
                            "stroke-width": 2,
                            "pointer-events": "none",
                        },
                    },
                ],
                action: function (ev) {
                    contextMenuCallback(this.model, { x: ev.clientX, y: ev.clientY });
                },
            });
        }, [contextMenuCallback]);

        const editElementButton = useMemo(() => {
            return new joint.elementTools.Button({
                x: "0",
                y: "0",
                offset: {
                    x: 20,
                    y: 0,
                },
                markup: [
                    {
                        tagName: "circle",
                        selector: "button",
                        attributes: {
                            r: 8,
                            fill: "#f6f6f6",
                            stroke: "var(--blue-10)",
                            "stroke-width": 2,
                            cursor: "pointer",
                        },
                    },
                    {
                        tagName: "path",
                        selector: "icon",
                        attributes: {
                            d: "M -2 -5 L -2 -2 L 0 -1 L 2 -2 L 2 -5 M 0 -1 L 0 5",
                            fill: "none",
                            stroke: "var(--blue-10)",
                            "stroke-width": 2,
                            "pointer-events": "none",
                        },
                    },
                ],
                action: function (evt) {
                    onModelClick(this.model);
                },
            });
        }, [onModelClick]);

        const removeElementButton = useMemo(() => {
            return new joint.elementTools.Button({
                x: "100%",
                y: "0",
                offset: {
                    x: -20,
                    y: 0,
                },
                markup: [
                    {
                        tagName: "circle",
                        selector: "button",
                        attributes: {
                            r: 8,
                            fill: "#f6f6f6",
                            stroke: "var(--red-10)",
                            "stroke-width": 2,
                            cursor: "pointer",
                        },
                    },
                    {
                        tagName: "path",
                        selector: "icon",
                        attributes: {
                            d: "M -4 -4 L 4 4 M -4 4 L 4 -4",
                            fill: "none",
                            stroke: "var(--red-10)",
                            "stroke-width": 2,
                            "pointer-events": "none",
                        },
                    },
                ],
                action: function (evt) {
                    onModelRemove(this.model);
                },
            });
        }, [onModelRemove]);

        const addStateButton = useMemo(() => {
            return new joint.elementTools.Button({
                x: "0",
                y: "0",
                offset: {
                    x: 40,
                    y: 0,
                },
                markup: [
                    {
                        tagName: "circle",
                        selector: "button",
                        attributes: {
                            r: 8,
                            fill: "#f6f6f6",
                            stroke: "var(--green-10)",
                            "stroke-width": 2,
                            cursor: "pointer",
                        },
                    },
                    {
                        tagName: "path",
                        selector: "icon",
                        attributes: {
                            d: "M 0 -5 L 0 5 M -5 0 L 5 0",
                            fill: "none",
                            stroke: "var(--green-10)",
                            "stroke-width": 2,
                            "pointer-events": "none",
                        },
                    },
                ],
                action: function (evt) {
                    onStateAdd(this.model);
                },
            });
        }, [onStateAdd]);

        const addStageButton = useMemo(() => {
            return new joint.elementTools.Button({
                x: "100%",
                y: "80%",
                offset: {
                    x: 0,
                    y: 0,
                },
                markup: [
                    {
                        tagName: "circle",
                        selector: "button",
                        attributes: {
                            r: 8,
                            fill: "#f6f6f6",
                            stroke: "var(--green-10)",
                            "stroke-width": 2,
                            cursor: "pointer",
                        },
                    },
                    {
                        tagName: "path",
                        selector: "icon",
                        attributes: {
                            d: "M 0 -5 L 0 5 M -5 0 L 5 0",
                            fill: "none",
                            stroke: "var(--green-10)",
                            "stroke-width": 2,
                            "pointer-events": "none",
                        },
                    },
                ],
                action: function (evt) {
                    onStageAdd(this.model);
                },
            });
        }, [onStageAdd]);

        const removeLinkButton = useMemo(() => {
            return new joint.linkTools.Button({
                distance: "20%",
                markup: [
                    {
                        tagName: "circle",
                        selector: "button",
                        attributes: {
                            r: 8,
                            fill: "#f6f6f6",
                            stroke: "var(--red-10)",
                            "stroke-width": 2,
                            cursor: "pointer",
                        },
                    },
                    {
                        tagName: "path",
                        selector: "icon",
                        attributes: {
                            d: "M -4 -4 L 4 4 M -4 4 L 4 -4",
                            fill: "none",
                            stroke: "var(--red-10)",
                            "stroke-width": 2,
                            "pointer-events": "none",
                        },
                    },
                ],
                action: function (evt) {
                    onModelRemove(this.model);
                },
            });
        }, [onModelRemove]);

        const canvasRef = useCallback((canvas) => {
            if (canvas) {
                const paper = new joint.dia.Paper({
                    el: canvas,
                    model: graph,
                    width: preview ? "100%" : 325 * manifest.stages.length,
                    height: preview ? 700 : 650,
                    gridSize: 1,
                    //drawGrid: true,
                    interactive: (cellView) => {
                        if (preview) {
                            return false;
                        }
                        if (cellView.model.isElement() && cellView.model.prop("data/locked") === true) {
                            return { elementMove: false };
                            /*
                        if (cellView.model.parent()) {
                            return { elementMove: false };
                        } */
                        }

                        return true;
                    },
                    linkPinning: false, // Prevent link being dropped in blank paper area
                    snapLinks: { radius: 10 },
                    markAvailable: true,
                    defaultLink: (cellView, magnet) => {
                        const link = new joint.shapes.standard.Link({
                            attrs: {
                                wrapper: {
                                    cursor: "default",
                                },
                                line: {
                                    stroke: "var(--text-color-dark)",
                                },
                            },
                        });
                        link.router("normal");
                        link.connector("smooth");

                        return link;
                    },
                    defaultConnectionPoint: { name: "boundary" },
                    validateMagnet: (cellView, magnet) => {
                        // Note that this is the default behaviour. It is shown for reference purposes.
                        // Disable linking interaction for magnets marked as passive
                        return magnet.getAttribute("magnet") !== "passive";
                    },
                    // elementView: joint.dia.ElementView.extend({
                    //     pointerdblclick: function (evt, x, y) {
                    //         joint.dia.CellView.prototype.pointerdblclick.apply(this, arguments);
                    //         this.notify("element:pointerdblclick", evt, x, y);
                    //         this.model.remove();
                    //     },
                    // }),
                    cellViewNamespace: namespace,
                });

                // Register events

                paper.on("cell:contextmenu", (cell, ev) => {
                    contextMenuCallback(cell.model, { x: ev.clientX, y: ev.clientY });
                });

                paper.on("link:connect", (linkView) => {
                    const trigger = linkView.model.prop("data");

                    if (trigger && trigger.type === "trigger") {
                        onMoveTrigger({
                            trigger,
                            target: { id: linkView.targetView.model.prop("data/id") },
                        });
                    } else {
                        const options = {
                            source: { id: linkView.sourceView.model.prop("data/id") },
                            target: { id: linkView.targetView.model.prop("data/id") },
                        };

                        onAddTrigger(options).then(() => linkView.model.remove());
                    }
                });

                paper.on("link:mouseenter", (linkView) => {
                    if (!preview) {
                        const linkType = linkView.model.prop("data/type");
                        if (linkType === "trigger") {
                            var tools = new joint.dia.ToolsView({
                                tools: [new joint.linkTools.TargetArrowhead()], //, removeLinkButton],
                            });
                            linkView.addTools(tools);
                        }
                    }
                });

                paper.on("link:mouseleave", (linkView) => {
                    if (!preview) {
                        linkView.removeTools();
                    }
                });

                // paper.on("element:mouseenter", (elementView, ev) => {
                //     // const elementType = elementView.model.prop("data/type");
                //     // if (!preview && ["state", "stage"].indexOf(elementType) !== -1) {
                //     //     var tools = new joint.dia.ToolsView({
                //     //         tools: [contextMenuButton],
                //     //     });
                //     //     elementView.addTools(tools);
                //     // }

                //     if (!preview && false) {
                //         const elementType = elementView.model.prop("data/type");
                //         if (elementType === "state") {
                //             var tools = new joint.dia.ToolsView({
                //                 tools: [editElementButton, removeElementButton],
                //             });
                //             elementView.addTools(tools);
                //         } else if (elementType === "stage") {
                //             const stageType = elementView.model.prop("data/stage");
                //             const toolActions = [addStateButton, editElementButton];
                //             if (stageType === "middle") {
                //                 toolActions.push(removeElementButton);
                //             }
                //             if (stageType !== "end") {
                //                 toolActions.push(addStageButton);
                //             }
                //             var tools = new joint.dia.ToolsView({
                //                 tools: toolActions,
                //             });
                //             elementView.addTools(tools);
                //         }
                //     }
                // });

                // paper.on("element:mouseleave", (elementView, ev) => {
                //     //ev.stopPropagation();
                //     //ev.preventDefault();
                //     if (!preview) {
                //         //elementView.removeTools();
                //     }
                // });

                paper.on("element:pointerdblclick", (elementView) => onModelClick(elementView.model));
                paper.on("link:pointerdblclick", (linkView) => onModelClick(linkView.model));

                if (preview) {
                    paper.scaleContentToFit({});
                }

                setPaper(paper);
            }
        }, []);

        const onValidateConnection = useCallback(
            (cellViewS, magnetS, cellViewT, magnetT, end, linkView) => {
                // Prevent linking from output ports to input ports within one element
                if (cellViewS === cellViewT) {
                    return false;
                }
                // Prevent linking to output ports
                if (!magnetT || magnetT.getAttribute("port-group") !== "in") {
                    return false;
                }

                const sourceModel = cellViewS.model.prop("data");
                const targetModel = cellViewT.model.prop("data");

                if (!sourceModel || sourceModel.type !== "state" || !targetModel || targetModel.type !== "state") {
                    return false;
                }

                const source = manifest.states.find((s) => s.id == sourceModel.id);
                const target = manifest.states.find((s) => s.id == targetModel.id);

                return source && target && hasTransitionStates(manifest.target, source.mappedTo, target.mappedTo);
            },
            [manifest]
        );

        useEffect(() => {
            const callback = (cell) => {
                //console.log("cell", cell);
                const elementType = cell.prop("data/type");
                if (elementType !== "state") {
                    return;
                }

                var parentId = cell.get("parent");
                if (!parentId) {
                    return;
                }

                var parent = graph.getCell(parentId);
                var parentBbox = parent.getBBox();
                var cellBbox = cell.getBBox();

                if (
                    parentBbox.containsPoint(cellBbox.origin()) &&
                    parentBbox.containsPoint(cellBbox.topRight()) &&
                    parentBbox.containsPoint(cellBbox.corner()) &&
                    parentBbox.containsPoint(cellBbox.bottomLeft())
                ) {
                    onMoveState({
                        state: { id: cell.prop("data/id") },
                        position: cell.attributes.position,
                    });

                    // All the four corners of the child are inside
                    // the parent area.
                    return;
                }

                // Revert the child position.
                cell.set("position", cell.previous("position"));
            };
            graph.on("change:position", callback);

            return () => graph.off("change:position", callback);
        }, [onMoveState]);

        useEffect(() => {
            if (paper) {
                paper.options.validateConnection = onValidateConnection;
            }
        }, [paper, onValidateConnection]);

        useEffect(() => {
            return () => graph.clear();
        }, []);

        return (
            <div className={cx(rootClassName, "scrollbar--root")}>
                <div className="container" ref={canvasRef} style={{ width: 325 * manifest.stages.length, height: 650 }}>
                    <StateFlowCanvasView manifest={manifest} graph={graph} options={options} />
                </div>
            </div>
        );
    }
);
