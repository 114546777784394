import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { TagPicker, ValidationState } from "office-ui-fabric-react/lib/Pickers";
import { Label } from "office-ui-fabric-react/lib/Label";
import { Stack } from "office-ui-fabric-react/lib/Stack";

export const WorkItemPicker = observer(({ label, placeholder, required, multiple, itemLimit, disabled, className, selected, onChange }) => {
    const { workItemStore } = useStores();

    const onResolve = useCallback((keywords) => {
        const options = { keywords, pageSize: 25, startIndex: 0 };
        return workItemStore.workItemService.getWorkItems(options).then((result) => {
            return toOptions(result.items);
        });
    }, []);

    const onSuggestion = useCallback(() => {
        return toOptions(workItemStore.browseStore.workItems);
    }, []);

    const onValidate = useCallback(() => {
        return ValidationState.valid;
    }, []);

    const onSelect = useCallback(
        (items) => {
            if (onChange) {
                const current = items.map((i) => i.data);
                onChange(multiple ? current : current.length ? current[0] : null);
            }
            return items;
        },
        [onChange]
    );

    const suggestionProps = {
        suggestionsHeaderText: "Suggested demand requests",
        mostRecentlyUsedHeaderText: "Suggested demand requests",
        noResultsFoundText: "No matching demand requests found",
        loadingText: "Loading...",
        showRemoveButtons: false,
        suggestionsAvailableAlertText: "Demand request suggestions available",
        suggestionsContainerAriaLabel: "Suggested demand requests",
    };

    const toOptions = (data) => {
        return data.map((i) => ({ key: i.id, name: `${i.code} - ${i.title}`, data: i }));
    };

    const items = multiple ? selected || [] : selected ? [selected] : [];

    return (
        <div className={className}>
            <Stack horizontal horizontalAlign="space-between">
                <Label required={required} disabled={disabled}>
                    {label}
                </Label>
            </Stack>
            <TagPicker
                required={required}
                selectedItems={toOptions(items)}
                onResolveSuggestions={onResolve}
                onEmptyInputFocus={onSuggestion}
                pickerSuggestionsProps={suggestionProps}
                onValidateInput={onValidate}
                removeButtonAriaLabel={"Remove"}
                itemLimit={multiple ? itemLimit : 1}
                inputProps={{ placeholder: placeholder }}
                onChange={onSelect}
                resolveDelay={300}
                disabled={disabled}
            />
        </div>
    );
});
