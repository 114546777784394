import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { PrincipalPickerStore } from '../stores/PrincipalPickerStore';
import { PrincipalDropDown as StatelessPrincipalDropDown } from '../components/PrincipalDropDown';

export const PrincipalDropDown = inject('rootStore')(observer(
    class PrincipalDropDown extends Component {

        constructor(props) {
            super(props);

            this.state = { 
                pickerStore: new PrincipalPickerStore(this.props.rootStore),
            }
        }

        componentWillMount() {
            this.loadData(this.props)
        }

        componentWillReceiveProps(nextProps) {
            this.loadData(nextProps)
        }

        loadData = (props) => {
            const { pickerStore } = this.state;
            const { multiple, selected, selectedId, selectedIds, selectedGroup } = props;

            if(typeof(selected) !== 'undefined') {
                pickerStore.setSelected(multiple ? (selected || []) : (selected ? [selected] : []));
            } else if(!multiple) {
                pickerStore.setSelectedWithId(selectedId);
            } else {
                pickerStore.setSelectedWithIds(selectedIds);
            }
            if (selectedGroup) {
                pickerStore.searchUsers({selectedGroup})
            } else {
                pickerStore.searchUsers();
            }
        }
    
        render () {
            const { pickerStore } = this.state;
            const { multiple, onSelected, selectedGroup } = this.props;
            const { selectedPrincipals, loading, usersList } = pickerStore;
            
            const selected = multiple ? selectedPrincipals : (selectedPrincipals.length ? selectedPrincipals[0] : null);

            return (
                <StatelessPrincipalDropDown 
                    {...this.props}
                    loading={loading}
                    selected={selected} 
                    principals={usersList}
                    onFilter={(text, current, limit) => {
                        return pickerStore.searchUsers({ page: 1, keywords: text, selectedGroup: selectedGroup });
                    }}
                    onSelected={(principals) => {
                        const updated = multiple ? (principals || []) : (principals ? [principals] : []);

                        if(updated.length > 0) {
                            return pickerStore.resolvePrincipals(updated).then((resolved) => {
                                pickerStore.setSelected(resolved);
                                if(onSelected) {
                                    onSelected(multiple ? resolved : (resolved.length ? resolved[0] : null));
                                }
                            });
                        } else {
                            pickerStore.setSelected(updated);
                            if(onSelected) {
                                onSelected(multiple ? updated : (updated.length ? updated[0] : null));
                            }
                        }
                    }} />                
            );
        }
    }
));
