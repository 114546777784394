import React, { Component } from "react";
import { observer } from "mobx-react";

import { Text } from "office-ui-fabric-react/lib/Text";

import { AssessmentModule } from "./AssessmentModule";

export const AssessmentModuleList = observer(
    class AssessmentModuleList extends Component {
        render() {
            const { className, modules, disabled, onModuleClick } = this.props;
            const completed = modules.filter((m) => m.percentComplete == 100).length;

            return modules && modules.length ? (
                <div className={className}>
                    <Text className="row--xs" block variant="medium">
                        <strong>Questionnaire Modules</strong> - Completed {completed} out of {modules.length} {disabled && "(Read Only)"}
                    </Text>

                    <r-grid columns-l={this._getLargeScreenColumns()} columns={this._getMediumScreenColumns()} columns-s="1">
                        {modules.map((module) => {
                            return (
                                <div key={module.id}>
                                    <r-cell class="card">
                                        <AssessmentModule
                                            module={module}
                                            readOnly={disabled}
                                            disabled={!this._isEnabled(module)}
                                            modified={module.modified}
                                            percentComplete={module.percentComplete}
                                            onModuleClick={onModuleClick}
                                        />
                                    </r-cell>
                                </div>
                            );
                        })}
                    </r-grid>
                </div>
            ) : null;
        }

        _isEnabled(module) {
            if (module.condition == "true" || module.condition == "always") {
                return true;
            }

            if (module.condition == "false" || module.condition == "never") {
                return false;
            }

            return eval(module.condition);
        }

        _getMediumScreenColumns() {
            const { vertical } = this.props;

            if (vertical) {
                return 1;
            }

            return 2;
        }

        _getLargeScreenColumns() {
            const { modules, vertical } = this.props;

            if (vertical) {
                return 1;
            }

            if (modules.length % 3 == 0) {
                return 3;
            }

            if (modules.length % 4 == 0) {
                return 4;
            }

            return 3;
        }
    }
);
