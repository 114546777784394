import React from "react";
import { observer } from "mobx-react";

import { Sidebar } from "@modules/base/components/Sidebar";
import { SidebarProperties } from "@modules/base/components/SidebarProperties";
import { SidebarProperty } from "@modules/base/components/SidebarProperty";

import { StateFlowTarget } from "@modules/stateflows/components/core/StateFlowTarget";

export const StateFlowSidebar = observer(({ stateFlow, manifest }) => {
    return (
        <Sidebar>
            <SidebarProperties title="Details" icon="List" open>
                <SidebarProperty label="Target" value={<StateFlowTarget value={stateFlow.target} />} />
                <SidebarProperty label="Current Version" value={stateFlow.currentVersion} />
                <SidebarProperty label="Published Version" value={stateFlow.publishedVersion} />
                <SidebarProperty label="Published" value={stateFlow.published} type="datetime" />
                <SidebarProperty value={stateFlow.publishedBy} type="principal" />
                <SidebarProperty label="Locked" value={manifest.locked ? "Yes" : "No"} />
            </SidebarProperties>
            <SidebarProperties title="About" icon="Info" open>
                <SidebarProperty label="Created" value={stateFlow.created} type="datetime" />
                <SidebarProperty value={stateFlow.createdBy} type="principal" />
                <SidebarProperty label="Last Modified" value={stateFlow.modified} type="datetime" />
                <SidebarProperty value={stateFlow.modifiedBy} type="principal" />
            </SidebarProperties>
        </Sidebar>
    );
});
