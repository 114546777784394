import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';

import { Text } from '@ui/elements/Text';
import { Stack } from '@ui/elements/Stack';

import { ObservableBadge } from '@baseComponents/ObservableBadge';
import { ActionSet } from '@baseComponents/ActionSet';

const rootClassName = cx(
  'cygraph-ControlMapHeader-root',
  css` 
    & .badge {
      & > div {
        outline: none;
      }
    } 

    & .title {
      margin: var(--spacing-s) 0;
      & > div {
        outline: none;
      }
    }

    & .description {
      color: var(--text-color-light);
      font-size: var(--font-size-xs);
      margin-top: var(--spacing-xs);
      & > div {
        outline: none;
      }
    }

    & .meta {
      display: inline-block;
      margin-right: 20px;
    }

    & .image {
      min-width: 200px;
    }
  `
);

export const ControlMapHeader = observer(
  class ControlMapHeader extends PureComponent {

    render () {
        const { className, map } = this.props;
        const { items, overflowItems } = this._getActionSetItems();

        return (
          <div className={cx(rootClassName, className)}>
            <Stack horizontal horizontalAlign="space-between">
              <ObservableBadge className="badge">Control Map</ObservableBadge>
              <ActionSet items={items} overflowItems={overflowItems} />
            </Stack>
            <Stack horizontal horizontalAlign="space-between">
              <Stack.Item grow>
                <Text className="title" variant="xLarge" block>
                  {map.name}
                </Text>
              </Stack.Item>
              <Stack.Item>
              </Stack.Item>              
            </Stack>
          </div>
        );
    }

    _getActionSetItems() {
      const { hideActions, canEdit, onEdit } = this.props;

      const items = [];
      const overflowItems = [];

      if(hideActions) {
        return { items, overflowItems };
      }

      items.push({
        key: 'edit',
        name: 'Edit',
        iconProps: { iconName: 'Edit' },
        disabled: !canEdit || !canEdit(),
        ariaLabel: 'Edit',
        onClick: onEdit,
      });

      return { items, overflowItems };
    }
  }
);