import React, { PureComponent } from 'react';
import { Dropdown } from '@ui/elements/Dropdown';
import { TooltipHost } from '@ui/elements/Tooltip'
import { DateRangePicker } from '@baseComponents/DateRangePicker';
import moment from 'moment';
import { computeTimelineFilter } from '../../stores/data/computeTimelineFilter';
export class DateRangeComboPicker extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            rangeType: props.filter && props.filter.value ? props.filter.value.rangeType : null,
            dynamicRangeType: props.filter && props.filter.value ? props.filter.value.dynamicRangeType : null,
            fixedRangeStart: props.filter && props.filter.value ? props.filter.value.fixedRangeStart : null,
            fixedRangeEnd: props.filter && props.filter.value ? props.filter.value.fixedRangeEnd : null,
        };
    }

    onFieldChange(value, field) {
        const {onFilterChange} = this.props;
        const filterState = {...this.state};
        filterState[field] = value;
        onFilterChange(filterState);
        this.setState({[field]: value});
    }

    render () {
        const { key, filter, title } = this.props;
        const computedTimelineFilter = computeTimelineFilter({
            rangeType: 'dynamicRange',
            dynamicRangeType: this.state.dynamicRangeType,
        });
        // console.log(this.state.dynamicRangeType, computedTimelineFilter)
        return <r-cell span="2" key={key}>
            <r-grid columns="2">
                <r-cell span="2">
                    {title}
                </r-cell>
                <r-cell>
                    <Dropdown
                        selectedKey={this.state.rangeType || 'null'}
                        onChange={(ev, op) => {
                            if (op) {
                                this.onFieldChange(op.key, 'rangeType')
                                if (op.key === 'dynamicRange') this.onFieldChange('Last6Months', 'dynamicRangeType')
                            }
                        }}
                        options={[
                            { key: 'null', text: 'None'},
                            { key: 'dynamicRange', text: 'Dynamic Date Range'},
                            { key: 'fixedRange', text: 'Fixed Date Range'},
                        ]}
                    />
                </r-cell>
                <r-cell>
                    {this.state.rangeType === 'dynamicRange' && 
                          <TooltipHost
                          content={this.state.dynamicRangeType ? `${computedTimelineFilter.after ? `After: ${moment(computedTimelineFilter.after).format("lll")}` : ''} ${computedTimelineFilter.before ? ` Before:  ${moment(computedTimelineFilter.before).format("lll")}`: ''}` : ""}
                          // This id is used on the tooltip itself, not the host
                          // (so an element with this id only exists when the tooltip is shown)
                        //   id={tooltipId}
                        //   calloutProps={calloutProps}
                        //   styles={hostStyles}
                        >
                            <Dropdown
                                selectedKey={this.state.dynamicRangeType}
                                onChange={(ev, op) => {
                                    if (op) {
                                        this.onFieldChange(op.key, 'dynamicRangeType')
                                    }
                                }}
                                options={[
                                    { key: 'WithinLastWeek', text: 'Within last week'},
                                    { key: 'WithinLast7Days', text: 'Within last 7 days'},
                                    { key: 'WithinLastMonth', text: 'Within last month'},
                                    { key: 'WithinLast30Days', text: 'Within last 30 days'},
                                    { key: 'WithinLast3Months', text: 'Within last 3 months'},
                                    { key: 'WithinLast90Days', text: 'Within last 90 days'},
                                    { key: 'WithinLast6Months', text: 'Within last 6 months'},
                                    { key: 'WithinLast12Months', text: 'Within last 12 months'},
                                    { key: 'Older1Day', text: 'Older than 1 day'},
                                    { key: 'Older7Days', text: 'Older than 7 days'},
                                    { key: 'Older30Days', text: 'Older than 30 days'},
                                    { key: 'Older90Days', text: 'Older than 90 days'},
                                ]}
                            /> </TooltipHost> }
                            {this.state.rangeType === 'fixedRange' && <DateRangePicker 
                                    inputDateChanged={(date) => {
                                        this.onFieldChange(date.startDate, 'fixedRangeStart')
                                        this.onFieldChange(date.endDate, 'fixedRangeEnd')
                                    }}
                                    selectedStartDate={this.fixedRangeStart ? new Date(this.fixedRangeStart) : null}
                                    selectedEndDate={this.fixedRangeEnd ? new Date(this.fixedRangeEnd) : null}
                                    textFieldValue=""
                                />           
                    } 
                </r-cell>
            </r-grid>
        </r-cell>
    }
  };
