import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';

const rootClassName = cx(
    'cygraph-ListItemReveal-root',
    css`
        
    `
);

export const ListItemReveal = observer(
    class ListItemReveal extends Component {

        render () {
            const { children, active } = this.props;
            
            return (
                <div
                    className={cx(rootClassName, active && 'active')}>
                    {children}
                </div>
            );
        }
    }
);