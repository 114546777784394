import React, { Component } from 'react';
import { css } from '@uifabric/utilities/lib/css';
import { Icon } from 'office-ui-fabric-react/lib/Icon';

export class AppNavLink extends Component {
  static displayName = AppNavLink.name;

  render () {
    const { item, routing, selectedItemId } = this.props;    
    return (
        <a className={css('app__nav__item__link', selectedItemId == item.id && 'app__nav__item__link--active')} 
            onClick={() => routing.push(item.url)} title={item.title}>
            { item.icon && <Icon iconName={item.icon} /> }
            <span className="app__nav__item__label">{item.title}</span>
        </a>
    );
  }
}
