import React, { Component } from "react";
import { observer } from "mobx-react";
import { cx, css } from "emotion";

import { Text } from "office-ui-fabric-react/lib/Text";
import { FocusTrapCallout, Callout } from "office-ui-fabric-react/lib/Callout";
import { PrimaryButton, DefaultButton } from "office-ui-fabric-react/lib/Button";
import { FocusZone } from "office-ui-fabric-react/lib/FocusZone";
import { Stack } from "office-ui-fabric-react/lib/Stack";

import { RichTextField, isNotNullOrEmpty } from "@modules/editors/components/RichTextField";

const wrapperClassName = cx("cygraph-PopConfirm-wrapper", css``);

const calloutClassName = cx(
    "cygraph-PopConfirm-callout",
    css`
        padding: var(--spacing-xs);
        max-width: 380px;

        & .content {
            margin-bottom: var(--spacing-xxs);
        }

        & button {
            font-size: var(--font-size-xxs);
            height: 24px;
            min-width: 50px;
            font-weight: normal;
        }
    `
);

export const PopConfirm = observer(
    class PopConfirm extends Component {
        constructor(props) {
            super(props);

            this.state = { visible: false, comment: null };
        }

        render() {
            const { children, focusTrap, disabled } = this.props;

            return (
                <>
                    <div
                        className={wrapperClassName}
                        role="button"
                        disabled={disabled}
                        onClick={() => this._toggleVisibility()}
                        ref={(trigger) => (this._trigger = trigger)}
                    >
                        {children}
                    </div>
                    {this.state.visible && !disabled && <div>{this._createCallout(focusTrap ? FocusTrapCallout : Callout)}</div>}
                </>
            );
        }

        _createCallout(CalloutType) {
            const { title, subText, onConfirm, onCancel, okText, cancelText, disabled, commentBox, comments } = this.props;

            return (
                <CalloutType
                    role="alertdialog"
                    gapSpace={0}
                    hidden={disabled}
                    className={calloutClassName}
                    target={this._trigger}
                    onDismiss={() => {
                        this._toggleVisibility();
                        if (onCancel) {
                            onCancel();
                        }
                    }}
                    setInitialFocus={true}
                >
                    <div className="content">
                        {title && (
                            <div className="title">
                                <Text variant="medium">{title}</Text>
                            </div>
                        )}
                        {subText && (
                            <div className="subText">
                                <p>{subText}</p>
                            </div>
                        )}
                    </div>
                    <FocusZone>
                        <Stack vertical tokens={{ childrenGap: 10 }}>
                            {(comments || commentBox) && (
                                <Stack vertical tokens={{ childrenGap: 10 }}>
                                    <RichTextField
                                        label={comments && comments.label ? comments.label : "Notes"}
                                        required={comments && comments.required}
                                        value={this.state.comment}
                                        onChange={(val) => {
                                            this.setState({ comment: val });
                                        }}
                                    />
                                </Stack>
                            )}
                            <Stack horizontal horizontalAlign="space-between">
                                <Stack horizontal tokens={{ childrenGap: 10 }}></Stack>
                                <Stack horizontal tokens={{ childrenGap: 10 }}>
                                    <PrimaryButton
                                        disabled={comments && comments.required && !isNotNullOrEmpty(this.state.comment)}
                                        onClick={(ev) => {
                                            //this._toggleVisibility();
                                            if (onConfirm) {
                                                onConfirm(ev, this.state.comment);
                                            }
                                        }}
                                    >
                                        {okText || "Yes"}
                                    </PrimaryButton>
                                    <DefaultButton
                                        onClick={(ev) => {
                                            this._toggleVisibility();
                                            if (onCancel) {
                                                onCancel(ev);
                                            }
                                        }}
                                    >
                                        {cancelText || "No"}
                                    </DefaultButton>
                                </Stack>
                            </Stack>
                        </Stack>
                    </FocusZone>
                </CalloutType>
            );
        }

        _toggleVisibility() {
            this.setState({ visible: !this.state.visible });
        }

        _onCalloutDismiss() {
            this.setState({ visible: false });
        }
    }
);
