import React from "react";
import { observer } from "mobx-react";

import { RoutingLink } from "./RoutingLink";

export const EntityLink = observer(({ type, id, children }) => {
    let url = null;
    switch (type) {
        case "work-item":
            url = `/demand/browse/${id}`;
            break;
        case "assessment":
            url = `/assurance/browse/${id}`;
            break;
        case "exception":
            url = `/exceptions/browse/${id}`;
            break;
        case "risk":
            url = `/risks/browse/${id}`;
            break;
    }
    return <RoutingLink url={url}>{children}</RoutingLink>;
});
