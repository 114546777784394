import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { useStores } from "@hooks/useStores";

import { AppPage } from "@modules/layout/containers/AppPage";
import { JsonQueryString } from "@modules/utils/JsonQueryString";
import { ObservablePagination } from "@modules/base/components/ObservablePagination";

import { TacticalControlHeader } from "@modules/controls/components/tactical/TacticalControlHeader";
import { ControlMaturityProgress } from "@modules/controls/components/core/ControlMaturityProgress";

import { TacticalControlTabList } from "@modules/controls/containers/tabs/TacticalControlTabList";
import { ControlReviewPanel } from "@modules/controls/containers/panels/ControlReviewPanel";

export const TacticalControlViewerPage = withRouter(
    observer(({ match }) => {
        const { pageContext, routing, controlStore } = useStores();
        const { tacticalViewerStore } = controlStore;
        const { breadcrumb, error, control, parent, loading, selectedTab } = tacticalViewerStore;

        useEffect(() => {
            if (match.params.id) {
                tacticalViewerStore.loadControl({ id: match.params.id });
            }
        }, [match.params.id]);

        useEffect(() => {
            tacticalViewerStore.selectTab(match.params.tab);
        }, [match.params.id, match.params.tab]);

        return (
            <AppPage
                loading={loading}
                breadcrumb={breadcrumb}
                error={error}
                hasPageNav={false}
                hasSidebar={false}
                className="controls list tactical viewer"
            >
                {control && (
                    <>
                        <TacticalControlHeader
                            control={control}
                            parent={parent}
                            canReview={(options) => controlStore.controlReviewFormStore.show(options)}
                            onReview={(options) => controlStore.controlReviewFormStore.show(options)}
                        />
                        <ControlMaturityProgress control={control} />
                        <TacticalControlTabList control={control} selectedTab={selectedTab} />
                        <ControlReviewPanel store={controlStore.controlReviewFormStore} />
                    </>
                )}
            </AppPage>
        );
    })
);
