import React from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { PrimaryButton, DefaultButton } from "office-ui-fabric-react/lib/Button";

export const RoutingButton = observer(({ url, primary, children, ...rest }) => {
    const { routing } = useStores();

    if (primary) {
        return (
            <PrimaryButton {...rest} onClick={() => routing.push(url)}>
                {children}
            </PrimaryButton>
        );
    } else {
        return (
            <DefaultButton {...rest} onClick={() => routing.push(url)}>
                {children}
            </DefaultButton>
        );
    }
});
