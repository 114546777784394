import { computed } from "mobx";

import { PrincipalContextStore } from "@modules/identity/stores/PrincipalContextStore";
import { RoleNames, isUserInAnyRoles, isUserInAnyGroups } from "@modules/identity/stores/PrincipalContextUtilities";

import { RiskService } from "../../../api/risks";
import { RiskSelectionStore } from "./RiskSelectionStore";
import { RiskStore } from "./RiskStore";

export const RiskPermissionRoles = {
    Reader: "Risk.Reader",
    Contributor: "Risk.Contributor",
    Owner: "Risk.Owner",
    Approver: "Risk.Approver",
};
export class RiskPermissionStore {
    public parentStore: RiskStore;
    public selectionStore: RiskSelectionStore;
    public riskService: RiskService;
    public principalContext: PrincipalContextStore;

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
        this.selectionStore = parentStore.selectionStore;
        this.principalContext = parentStore.rootStore.principalContext;
    }

    public requestorRegistryFilter(r) {
        const user = this.principalContext.current;

        if (!user || !r.policies || !r.policies.requestorRoles) {
            return false;
        }

        return (
            isUserInAnyRoles(user, r.policies.requestorRoles) ||
            RiskPermissionRoles.Reader === r.policies.guestRequestorAccess ||
            RiskPermissionRoles.Contributor === r.policies.guestRequestorAccess
        );
    }

    @computed
    public get isAdmin() {
        return isUserInAnyRoles(this.principalContext.current, [RoleNames.Admin]);
    }

    @computed
    public get isRiskAgent() {
        return isUserInAnyRoles(this.principalContext.current, [RoleNames.Risks]);
    }

    @computed
    public get isRequestor() {
        const user = this.principalContext.current;
        const risk = this.selectionStore.risk;

        if (!risk || !user) {
            return false;
        }

        return (risk.createdBy && risk.createdBy.id == user.id) || (risk.requestedBy && risk.requestedBy.id == user.id);
    }

    @computed
    public get isReader() {
        const user = this.principalContext.current;
        const risk = this.selectionStore.risk;
        const registry = risk.registry;

        if (!risk || !user || !registry || !registry.policies) {
            return false;
        }

        if (
            registry.policies.readerRoles != null &&
            isUserInAnyRoles(this.principalContext.current, registry.policies.readerRoles)
        ) {
            return true;
        }

        if (
            ((risk.portfolioContact && user.id == risk.portfolioContact.id) ||
                (risk.portfolioTechnical && user.id == risk.portfolioTechnical.id)) &&
            RiskPermissionRoles.Reader === registry.policies.portfolioContactAccess
        ) {
            return true;
        }

        if (
            ((risk.lifecycle.businessContact && user.id == risk.lifecycle.businessContact.id) ||
                (risk.lifecycle.technicalContact && user.id == risk.lifecycle.technicalContact.id)) &&
            RiskPermissionRoles.Reader === registry.policies.lifecycleContactAccess
        ) {
            return true;
        }

        if (
            (user.id == risk.requestedBy.id || user.id == risk.createdBy.id) &&
            RiskPermissionRoles.Reader === registry.policies.guestRequestorAccess
        ) {
            return true;
        }

        if (RiskPermissionRoles.Reader === registry.policies.assignedToGroupAccess && risk.lifecycle.assignedToGroup) {
            if (isUserInAnyGroups(user, [risk.lifecycle.assignedToGroup.id])) {
                return true;
            }
        }

        return false;
    }

    @computed
    public get isContributor() {
        const user = this.principalContext.current;
        const risk = this.selectionStore.risk;
        const registry = risk.registry;

        if (!risk || !user || !registry || !registry.policies) {
            return false;
        }

        if (
            registry.policies.contributorRoles != null &&
            isUserInAnyRoles(this.principalContext.current, registry.policies.contributorRoles)
        ) {
            return true;
        }

        if (
            ((risk.portfolioContact && user.id == risk.portfolioContact.id) ||
                (risk.portfolioTechnical && user.id == risk.portfolioTechnical.id)) &&
            RiskPermissionRoles.Contributor === registry.policies.portfolioContactAccess
        ) {
            return true;
        }

        if (
            ((risk.lifecycle.businessContact && user.id == risk.lifecycle.businessContact.id) ||
                (risk.lifecycle.technicalContact && user.id == risk.lifecycle.technicalContact.id)) &&
            RiskPermissionRoles.Contributor === registry.policies.lifecycleContactAccess
        ) {
            return true;
        }

        if (
            (user.id == risk.requestedBy.id || user.id == risk.createdBy.id) &&
            RiskPermissionRoles.Contributor === registry.policies.guestRequestorAccess
        ) {
            return true;
        }

        if (
            RiskPermissionRoles.Contributor === registry.policies.assignedToGroupAccess &&
            risk.lifecycle.assignedToGroup
        ) {
            if (isUserInAnyGroups(user, [risk.lifecycle.assignedToGroup.id])) {
                return true;
            }
        }

        return false;
    }

    @computed
    public get isOwner() {
        const user = this.principalContext.current;
        const risk = this.selectionStore.risk;
        const lifecycle = this.selectionStore.lifecycle;
        const registry = risk.registry;

        if (!lifecycle || !user || !registry) {
            return false;
        }

        if (lifecycle.assignedToUser && lifecycle.assignedToUser.id == user.id) {
            return true;
        }

        if (!registry.policies) {
            return false;
        }

        if (RiskPermissionRoles.Owner === registry.policies.assignedToGroupAccess && lifecycle.assignedToGroup) {
            if (isUserInAnyGroups(user, [lifecycle.assignedToGroup.id])) {
                return true;
            }
        }

        return false;
    }

    @computed
    public get isApprover() {
        const user = this.principalContext.current;
        const risk = this.selectionStore.risk;
        const registry = risk.registry;

        if (!risk || !user || !registry) {
            return false;
        }

        if (
            (registry.primaryApprover != null && user.id == registry.primaryApprover.id) ||
            (registry.secondaryApprover != null && user.id == registry.secondaryApprover.id)
        ) {
            return true;
        }

        if (!registry.policies) {
            return false;
        }

        if (
            risk.lifecycle.assignedToUser != null &&
            user.id == risk.lifecycle.assignedToUser.id &&
            RiskPermissionRoles.Approver === registry.policies.assignedToUserAccess
        ) {
            return true;
        }

        return false;
    }

    @computed
    public get isAnyAssignRoles() {
        const user = this.principalContext.current;
        const risk = this.selectionStore.risk;
        const registry = risk.registry;

        if (!risk || !user || !registry || !registry.policies) {
            return false;
        }

        if (
            registry.policies.assignRoles != null &&
            isUserInAnyRoles(this.principalContext.current, registry.policies.assignRoles)
        ) {
            return true;
        }

        return false;
    }

    @computed
    public get canCreate() {
        return this.isAdmin || this.isRiskAgent;
    }

    @computed
    public get canRead() {
        return this.isReader || this.isContributor || this.isOwner || this.isApprover || this.isAdmin;
    }

    @computed
    public get canEdit() {
        return this.isOwner || this.isApprover || this.isAdmin;
    }

    @computed
    public get canAssign() {
        return this.isOwner || this.isAnyAssignRoles || this.isAdmin;
    }

    @computed
    public get canAddDocument() {
        return this.isContributor || this.isOwner || this.isApprover || this.isAdmin;
    }

    @computed
    public get canAddComment() {
        return this.isContributor || this.isOwner || this.isApprover || this.isAdmin;
    }

    @computed
    public get canSubmit() {
        return this.isOwner;
    }

    @computed
    public get canMonitor() {
        return this.isApprover;
    }

    @computed
    public get canReview() {
        return this.isOwner || this.isApprover;
    }

    @computed
    public get canClose() {
        return this.isApprover;
    }

    @computed
    public get canCancel() {
        return this.isOwner || this.isApprover;
    }

    @computed
    public get canTransfer() {
        return this.isApprover;
    }

    @computed
    public get canDelete() {
        return this.isOwner;
    }

    @computed
    public get canFlow() {
        return this.isOwner || this.isApprover;
    }

    @computed
    public get canManageTasks() {
        return this.isOwner || this.isApprover || this.isAdmin;
    }

    @computed
    public get canManageAutomations() {
        return this.isOwner || this.isApprover || this.isAdmin;
    }
}
