import React from "react";
import { observer } from "mobx-react";
import moment from "moment";

import { Persona, PersonaSize } from "office-ui-fabric-react/lib/Persona";

import { Label } from "@ui/elements/Label";
import { TooltipHost } from "@ui/elements/Tooltip";

import { AdaptiveField } from "@modules/base/components/AdaptiveField";
import { UserHoverCard } from "@modules/identity/containers/utilities/UserHoverCard";
import { PhotoPersona } from "@modules/identity/containers/utilities/PhotoPersona";

export const WorkItemWorkflowSection = observer(({ workItem }) => {
    return (
        <r-grid columns="2" class="no--margin">
            <r-cell>
                <div className="row--xs">
                    <Label>Stage</Label>
                    <div>{workItem.stage}</div>
                </div>
            </r-cell>
            <r-cell>
                <div className="row--xs">
                    <Label>Status</Label>
                    <div>
                        <TooltipHost
                            content={`Internal status of ${
                                workItem.status == "InProgress" ? '"In Progress"' : workItem.status
                            }`}
                        >
                            <span>{workItem.subStatus}</span>
                        </TooltipHost>
                    </div>
                </div>
            </r-cell>

            <r-cell>
                <div className="row--xs">
                    <Label>Assigned Group</Label>
                    {workItem.assignedToGroup ? (
                        <Persona size={PersonaSize.size28} text={workItem.assignedToGroup.name} />
                    ) : (
                        "Not Set"
                    )}
                </div>
            </r-cell>

            <r-cell>
                <div className="row--xs">
                    <Label>Assigned User</Label>
                    {workItem.assignedToUser ? (
                        <UserHoverCard principal={workItem.assignedToUser}>
                            <PhotoPersona
                                principalId={workItem.assignedToUser.id}
                                size={PersonaSize.size28}
                                text={workItem.assignedToUser.name}
                                showSecondaryText={true}
                                secondaryText={workItem.assignedToUser.jobTitle}
                            />
                        </UserHoverCard>
                    ) : (
                        "Not Set"
                    )}
                </div>
            </r-cell>
            <r-cell>
                <div className="row--xs">
                    <AdaptiveField
                        type="Principal"
                        mode="view"
                        label="Created"
                        value={workItem.createdBy}
                        secondaryText={moment.utc(workItem.created).format("Do MMM YYYY h:mm a")}
                    />
                </div>
            </r-cell>
            <r-cell>
                <div className="row--xs">
                    <AdaptiveField
                        type="Principal"
                        mode="view"
                        label="Last Modified"
                        value={workItem.modifiedBy}
                        secondaryText={moment.utc(workItem.modified).format("Do MMM YYYY h:mm a")}
                    />
                </div>
            </r-cell>
        </r-grid>
    );
});
