import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { useStores } from "@hooks/useStores";

import { Pivot, PivotItem, PivotLinkFormat } from "office-ui-fabric-react/lib/Pivot";

import { JsonQueryString } from "@modules/utils/JsonQueryString";
import { AppPage } from "@modules/layout/containers/AppPage";
import { ObservablePagination } from "@modules/base/components/ObservablePagination";
import { isUserInAllRoles } from "@modules/identity/stores/PrincipalContextUtilities";

import { WorkItemGridView } from "@modules/workitems/components/core/WorkItemGridView";
import { WorkItemCommandBar } from "@modules/workitems/components/core/WorkItemCommandBar";
import { WorkItemSideNav } from "@modules/workitems/components/core/WorkItemSideNav";

import { WorkItemNewPanel } from "@modules/workitems/containers/panels/WorkItemNewPanel";

export const WorkItemDashboardPage = withRouter(
    observer(({ match }) => {
        const { workItemStore, routing, principalContext } = useStores();
        const { dashboardStore, lifecycleStore } = workItemStore;
        const { breadcrumb, error, isAgent, workItems, query, loading, myTabs, tab } = dashboardStore;
        const user = principalContext.current;

        useEffect(() => {
            if (user && !tab) {
                dashboardStore.selectTab(isUserInAllRoles(user, ["agent"]) ? "assigned-to-me" : "requested-by-me");
            }
        }, [user, tab]);

        return (
            <AppPage
                loading={loading}
                breadcrumb={breadcrumb}
                error={error}
                hasSidebar={false}
                className="work-items dashboard has--page-nav"
            >
                <WorkItemSideNav
                    onLinkClick={(item) => routing.push(item.url)}
                    isAgent={isAgent}
                    onNewRequest={(product) => {
                        if (!product) {
                            routing.push("/demand/catalogue");
                        } else {
                            lifecycleStore.onCreate({}, { workItem: { product } });
                        }
                    }}
                />

                <>
                    {tab && (
                        <Pivot
                            aria-label="Demands"
                            selectedKey={tab}
                            onLinkClick={(item) => dashboardStore.selectTab(item.props.itemKey)}
                            linkFormat={PivotLinkFormat.links}
                        >
                            {Object.keys(myTabs).map((id) => {
                                const myTab = myTabs[id];
                                return (
                                    <PivotItem key={id} itemKey={id} headerText={myTab.title}>
                                        <WorkItemCommandBar
                                            onNewRequest={(product) => {
                                                if (!product) {
                                                    routing.push("/demand/catalogue");
                                                } else {
                                                    lifecycleStore.onCreate({}, { workItem: { product } });
                                                }
                                            }}
                                        />
                                        <WorkItemGridView workItems={workItems} loading={loading} query={query} />
                                    </PivotItem>
                                );
                            })}
                        </Pivot>
                    )}

                    <WorkItemNewPanel store={workItemStore.newFormStore} />
                </>
            </AppPage>
        );
    })
);
