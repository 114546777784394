import React, { Component } from "react";
import { observer } from "mobx-react";

import { PageContentViewer } from "../elements/PageContentViewer";

export const PageViewer = observer(
    class PageViewer extends Component {
        render() {
            const { className, instance } = this.props;
            const page = instance.selectionStore.page;
            const mode = instance.lifecycleStore.mode;

            return (
                <div className={className}>
                    {page && (
                        <PageContentViewer
                            instance={instance}
                            page={page}
                            mode={mode}
                            sections={page.content.sections}
                        />
                    )}
                </div>
            );
        }
    }
);
