import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';
import { cx, css} from 'emotion';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { DefaultButton, IconButton } from 'office-ui-fabric-react/lib/Button';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';

const dataSourceDivClassName = cx(css`
border: 1px solid black;
padding: 5px;
min-width: 250px;

.dataSourceDropDownContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.dataSourceDropDownContainer .ms-Dropdown {
    width: 200px;
    margin-right: 5px;
}
`);

export const XyEditor = observer(
  class XyEditor extends PureComponent {
    render () {
        const { formData, dataSources, dataSourceStore, instance} = this.props;
        const { coreDataSources, selectionStore } = dataSourceStore;
        return (
            <r-grid columns="2">
                {formData.properties.dataSeries && formData.properties.dataSeries.length > 0 && formData.properties.dataSeries.map((DS,i) => {
                    
                    const selectedPartDataSource = DS.dataSourceId ? dataSources.find(D => D.id === DS.dataSourceId) : null;
                    const selectedDataSource = selectedPartDataSource ? selectionStore.dataSources.find(D => D.id === selectedPartDataSource.properties.mainSource) : null;
                    const selectedCoreDataSource = selectedDataSource ? coreDataSources.find(D => D.id === selectedDataSource.coreDataSourceId) : null;
                    
                    return(
                        <div className={dataSourceDivClassName} key={`DS_${i}`}>
                            <r-cell span="2">
                                <div className="dataSourceDropDownContainer">
                                    <Dropdown
                                        required
                                        label="Data source"
                                        selectedKey={DS.dataSourceId}
                                        onChange={(ev, op) => {
                                            if(op) {
                                                DS.dataSourceId = op.key;
                                            }
                                        }}
                                        options={dataSources.map(DSource => {
                                            return {
                                                key: DSource.id,
                                                text: DSource.title,
                                            }
                                        })}                                            
                                    />
                                    {DS.dataSourceId && <TooltipHost
                                        content="Edit data source"
                                        id={`editTooltipId`}
                                        calloutProps={{ gapSpace: 0 }}
                                        styles={{display:'inline-block', maginLeft: '5px'}}
                                    >
                                        <IconButton 
                                            iconProps={{ iconName: 'Edit' }}
                                            title="Edit"
                                            ariaLabel="Edit"
                                            onClick={() => {
                                                    const idx = formData.properties.dataSeries.findIndex(D => D.id === DS.id);
                                                    if (idx > -1) {
                                                        const pagePart = instance.selectionStore.getPagePart(DS.dataSourceId);
                                                        instance.lifecycleStore.onEditPart(pagePart.section, pagePart.zone, pagePart.part);
                                                    }
                                                }
                                            }
                                        />
                                    </TooltipHost> }
                                </div>
                            </r-cell>
                            {selectedCoreDataSource && (<r-grid columns="2">
                                <r-cell span="2">
                                    <div>
                                        <Dropdown
                                            required
                                            label="Y value field"
                                            selectedKey={DS.dataValue}
                                            onChange={(ev, op) => {
                                                if(op) {
                                                    DS.dataValue = op.key;
                                                    if (DS.label === 'Label') DS.label = op.text;
                                                }
                                            }}
                                            options={selectedCoreDataSource.fields.map(F => {
                                                return {
                                                    key: F.id,
                                                    text: F.title,
                                                }
                                            })}
                                        />
                                    </div>
                                </r-cell>
                                <r-cell span="2">
                                    <div>
                                        <TextField 
                                            label="Label"
                                            required
                                            value={DS.label}
                                            onChange={(ev, val) => DS.label = val} 
                                        />
                                    </div>
                                </r-cell>
                                <r-cell span="2">
                                    <div>
                                        <Dropdown
                                            required
                                            label="Series Type"
                                            selectedKey={DS.type}
                                            onChange={(ev, op) => {
                                                if(op) {
                                                    DS.type = op.key;
                                                }
                                            }}
                                            options={[
                                                {
                                                    key: 'bar',
                                                    text: 'Bar'
                                                },
                                                {
                                                    key: 'line',
                                                    text: 'Line',
                                                },
                                                {
                                                    key: 'spline',
                                                    text: 'Spline'
                                                },
                                            ]}
                                        />
                                    </div>
                                </r-cell>
                                <r-cell span="2">
                                    <div>
                                        <Dropdown
                                            required
                                            label="X value field"
                                            selectedKey={DS.dataX}
                                            onChange={(ev, op) => {
                                                if(op) {
                                                    DS.dataX = op.key;
                                                }
                                            }}
                                            options={selectedCoreDataSource.fields.map(F => {
                                                return {
                                                    key: F.id,
                                                    text: F.title,
                                                }
                                            })}
                                        />
                                    </div>
                                </r-cell>
                                <r-cell span="2">
                                    <Dropdown
                                        label="Sorting"
                                        selectedKey={DS.horizontalSorting  || 'none'}
                                        onChange={(ev, op) => {
                                            if(op) {
                                                DS.horizontalSorting = op.key;
                                            }
                                        }}
                                        options={[
                                            {
                                                key: 'none',
                                                text: 'Default (works most of the time)'
                                            },
                                            {
                                                key: 'alphabetical',
                                                text: 'Alphabetical'
                                            },
                                            {
                                                key: 'temporal',
                                                text: 'Temporal'
                                            },
                                        ]}
                                    />
                                </r-cell>
                                {DS.horizontalSorting && DS.horizontalSorting !== 'none' && <r-cell span="2">
                                    <div>
                                        <Dropdown
                                            required
                                            label="Sort Field"
                                            selectedKey={DS.horizontalSortingField}
                                            onChange={(ev, op) => {
                                                if(op) {
                                                    DS.horizontalSortingField = op.key;
                                                }
                                            }}
                                            options={selectedCoreDataSource.fields.map(F => {
                                                return {
                                                    key: F.id,
                                                    text: F.title,
                                                }
                                            })}
                                        />
                                    </div>
                                </r-cell>}
                            </r-grid>)}
                            <r-cell span="2">
                                <DefaultButton text="Remove data series" styles={{marginTop:'5px'}} onClick={() => {
                                    const idx = formData.properties.dataSeries.findIndex(D => D.id === DS.id);
                                    if (idx > -1) {
                                        formData.properties.dataSeries.splice(idx, 1);
                                    }
                                }} />
                            </r-cell>
                        </div>)}
                    )}
            </r-grid>
      );
    }
  }
);
