import React, { useState, useEffect } from "react";
import { useId } from "@fluentui/react-hooks";

import { useStores } from "../../../../hooks/useStores";

import { Stack } from "office-ui-fabric-react/lib/Stack";
import { ComboBox } from "office-ui-fabric-react/lib/ComboBox";
import { Label } from "office-ui-fabric-react/lib/Label";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";

export const SimplePatternPicker = ({ className, multiple, disabled, required, placeholder, label, selected, onChange }) => {
    const { patternStore } = useStores();
    const [patterns, setPatterns] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const pickerId = useId("comboBox");

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            const result = await patternStore.modellingService.getPatterns({ pageSize: 250, startIndex: 0 });
            setPatterns(result.items);
            setLoading(false);
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (selected) {
            if (multiple) {
                setSelectedKeys(selected.map((p) => p.id));
            } else {
                setSelectedKeys([selected.id]);
            }
        } else {
            setSelectedKeys([]);
        }
    }, [selected, multiple]);

    return (
        <div className={className}>
            <Stack horizontal={true} verticalAlign={"center"}>
                <Label required={required} disabled={disabled} htmlFor={pickerId}>
                    {label}
                </Label>
                {loading && <Spinner size={SpinnerSize.xSmall} />}
            </Stack>
            <ComboBox
                id={pickerId}
                placeholder={placeholder}
                disabled={disabled}
                required={required}
                multiSelect={multiple}
                selectedKey={selectedKeys}
                allowFreeform={false}
                useComboBoxAsMenuWidth={true}
                autoComplete="on"
                options={patterns.map((p) => ({ key: p.id, text: p.name, data: p }))}
                onChange={(event, option, index, value) => {
                    if (multiple) {
                        if (option) {
                            const newSelected = [...selectedKeys];
                            const index = newSelected.indexOf(option.key);
                            if (index == -1) {
                                newSelected.push(option.key);
                            } else {
                                newSelected.splice(index, 1);
                            }

                            if (onChange) {
                                onChange(patterns.filter((r) => newSelected.indexOf(r.id) != -1));
                            }
                        }
                    } else {
                        if (onChange) {
                            onChange(option ? option.data : null);
                        }
                    }
                }}
            />
        </div>
    );
};
