import React, { useEffect, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "@hooks/useStores";

import { ObservablePagination } from "@modules/base/components/ObservablePagination";
import { BoxPanel } from "@modules/base/components/BoxPanel";

import { RoleNames, isUserInAllRoles } from "@modules/identity/stores/PrincipalContextUtilities";

import { ActivityInstanceListView } from "@modules/workflows/components/core/ActivityInstanceListView";

import { ActivityInstanceEditPanel } from "@modules/workflows/containers/panels/ActivityInstanceEditPanel";
import { ActivityInstanceDeleteDialog } from "@modules/workflows/containers/panels/ActivityInstanceDeleteDialog";

export const StateFlowAutomationsTab = observer(({ stateFlow, manifest }) => {
    const { workflowStore, principalContext, routing } = useStores();
    const { browseStore, lifecycleStore, builderStore } = workflowStore;
    const user = principalContext.current;
    const isAdmin = isUserInAllRoles(user, [RoleNames.Admin]);

    const searchInstances = useCallback((options) => {
        browseStore.loadActivityInstances({
            ...options,
        });
    }, []);

    useEffect(() => {
        if (stateFlow != null) {
            searchInstances({ manifest });
        }
    }, [stateFlow]);

    let context = {};
    switch (manifest.target) {
        case "WorkItem":
            context.type = "work-item";
            break;
        case "Assessment":
            context.type = "assessment";
            break;
        case "RiskLifecycle":
            context.type = "risk";
            break;
    }

    return (
        <>
            <BoxPanel padding shadow small>
                <ActivityInstanceListView
                    className="row--s"
                    context={context}
                    loading={browseStore.loading}
                    instances={browseStore.instances}
                    canCreate={isAdmin}
                    onCreate={() => {
                        routing.push(`/settings/workflows/${stateFlow.id}/editor`);
                    }}
                    onCreateLabel="Edit Workflow"
                    canEdit={isAdmin}
                    onEdit={(instance) => {
                        lifecycleStore.onEditActivity({ instance, context }).then((result) => {
                            if (result.success) {
                                searchInstances({ manifest });
                            }
                        });
                    }}
                    onRemove={(instance) => {
                        lifecycleStore.onDeleteActivity({ instance, context }).then((result) => {
                            if (result.success) {
                                searchInstances({ manifest });
                            }
                        });
                    }}
                />
            </BoxPanel>
            <ActivityInstanceEditPanel store={workflowStore.editFormStore} />
            <ActivityInstanceDeleteDialog store={workflowStore.deleteFormStore} />
            {!browseStore.error && (
                <ObservablePagination
                    {...browseStore.pagination}
                    onPageChange={(index) => {
                        searchInstances({ page: index + 1, query: browseStore.query });
                    }}
                />
            )}
        </>
    );
});
