import { observable, action, flow } from "mobx";

import { RiskService } from "../../../api/risks";
import { RiskStore } from "./RiskStore";

export class RiskSelectionStore {
    public parentStore: RiskStore;
    public riskService: RiskService;

    @observable public id: string = null;
    @observable public risk: any = null;
    @observable public lifecycle: any = null;
    @observable public loading: boolean = false;

    constructor(parentStore: RiskStore) {
        this.parentStore = parentStore;
        this.riskService = parentStore.riskService;
    }

    @action
    public setLifecycle(lifecycle) {
        this.risk.lifecycle = lifecycle;
        this.lifecycle = lifecycle;
        this.parentStore.stateFlowStore.setSubject(lifecycle);
    }

    public loadRisk = flow(function* loadRisk(id: string) {
        if (this.id !== id || !this.risk) {
            this.loading = true;
            this.id = id;
            this.risk = null;
            this.lifecycle = null;
        }

        try {
            this.risk = yield this.riskService.getRisk(id);
            const lifecycle = yield this.riskService.getRiskLifecycle(id);
            this.setLifecycle(lifecycle);
            return this.risk;
        } finally {
            this.loading = false;
        }
    });
}
