import React from "react";
import { observer } from "mobx-react";

import { Stack } from "@ui/elements/Stack";
import { Text } from "@ui/elements/Text";
import { FontIcon } from "@ui/elements/Icon";

import { EntityIcon } from "@modules/base/components/EntityIcon";

export const QuickLinkItemView = observer(({ entityType, title, description, url }) => {
    return (
        <div className="quickLink">
            <Stack className="row" tokens={{ childrenGap: 15 }} horizontal horizontalAlign="space-between">
                <Stack.Item>
                    <EntityIcon type={entityType} size={55} />
                </Stack.Item>
                {url && (
                    <Stack.Item>
                        <FontIcon iconName="ArrowUpRight" style={{ color: "var(--highlight-color)", fontSize: 30 }} />
                    </Stack.Item>
                )}
            </Stack>
            <Stack tokens={{ childrenGap: 15 }}>
                <Text variant="medium" style={{ fontWeight: "600" }}>
                    {title}
                </Text>
                <Text variant="small" style={{ color: "var(--text-color-lightest)" }}>
                    {description}
                </Text>
            </Stack>
        </div>
    );
});
