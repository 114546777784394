import React from "react";
import { observer } from "mobx-react";
import { cx } from "emotion";

import { ModuleFieldGroup } from "./ModuleFieldGroup";

export const ModuleFieldListView = observer(
    ({
        className,
        module,
        indexMap,
        advisoryMap,
        disabled,
        onListAdvisories,
        onUpdateMeasure,
        onEditMeasure,
        onRemoveMeasure,
        onAddOption,
        onUpdateOption,
        onRemoveOption,
    }) => {
        return (
            <div className={cx(className, "questions__col", disabled && "disabled")}>
                <div className="form__questions__wrapper">
                    {module.groups.map((group) => (
                        <ModuleFieldGroup
                            key={group.id}
                            module={module}
                            group={group}
                            indexMap={indexMap}
                            advisoryMap={advisoryMap}
                            disabled={disabled}
                            onListAdvisories={onListAdvisories}
                            onAddOption={onAddOption}
                            onEditMeasure={onEditMeasure}
                            onUpdateOption={onUpdateOption}
                            onRemoveOption={onRemoveOption}
                            onUpdateMeasure={onUpdateMeasure}
                            onRemoveMeasure={onRemoveMeasure}
                        />
                    ))}
                </div>
            </div>
        );
    }
);
