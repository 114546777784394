export const WellKnownFieldNames = {
    Id: "id",
    Name: "name",
    StaticName: "staticName",
    Created: "created",
    CreatedBy: "createdBy",
    Modified: "modified",
    ModifiedBy: "modifiedBy",
    Page: "page",
    Code: "code",
    Portfolio: "portfolio",
};

export const WellKnownSectionIds = {
    AssetBuiltIn: "eaeac16c-b9f5-4049-9aca-0ccc91985915",
    EntityBuiltIn: "e44bcc55-40e3-4eec-b07e-0424fa85ff16",
};

export const PropertyTypes = {
    /// <summary>
    /// An integer value.
    /// </summary>
    Number: "Number",

    /// <summary>
    /// A boolean yes/no value.
    /// </summary>
    Boolean: "Boolean",

    /// <summary>
    /// A text value.
    /// </summary>
    Text: "Text",

    /// <summary>
    /// A date and time value.
    /// </summary>
    Date: "Date",

    /// <summary>
    /// A lookup value to other entities.
    /// </summary>
    Lookup: "Lookup",

    /// <summary>
    /// Choice.
    /// </summary>
    Choice: "Choice",
};

export const EntityTypes = {
    /// <summary>
    /// A principal.
    /// </summary>
    Principal: "Principal",

    /// <summary>
    /// An entity.
    /// </summary>
    Entity: "Entity",

    /// <summary>
    /// An asset.
    /// </summary>
    Asset: "Asset",

    /// <summary>
    /// A portfolio.
    /// </summary>
    Portfolio: "Portfolio",

    /// <summary>
    /// A product.
    /// </summary>
    Product: "Product",

    /// <summary>
    /// A product.
    /// </summary>
    Industry: "Industry",

    /// <summary>
    /// A page.
    /// </summary>
    Page: "Page",

    /// <summary>
    /// An assessment.
    /// </summary>
    Assessment: "Assessment",

    /// <summary>
    /// An assessment.
    /// </summary>
    WorkItem: "WorkItem",

    /// <summary>
    /// A pattern.
    /// </summary>
    Pattern: "Pattern",

    /// <summary>
    /// A control.
    /// </summary>
    Control: "Control",
};
