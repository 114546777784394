import { observable, action } from "mobx";

import { StateFlowStore } from "./StateFlowStore";

export class StateFlowContextMenuStore {
    public parentStore: StateFlowStore;

    @observable public menuItems: any[] = [];
    @observable public visible: boolean = false;

    public target: any = null;
    public options: any;

    constructor(parentStore: StateFlowStore) {
        this.parentStore = parentStore;
    }

    @action
    public show(options) {
        const promise = new Promise((resolve, reject) => {
            this.options = {
                resolve,
                reject,
            };
        });

        const items = [];
        items.push({
            key: "viewStateFlow",
            text: "View Workflow",
            iconProps: { iconName: "PageArrowRight" },
        });

        items.push({
            key: "editStateFlow",
            text: "Edit Workflow",
            disabled: !options.permissions || !options.permissions.canEdit,
            iconProps: { iconName: "VisioDiagram" },
        });

        items.push({
            key: "editStateFlowProperties",
            text: "Edit Properties",
            disabled: !options.permissions || !options.permissions.canEdit,
            iconProps: { iconName: "Edit" },
        });

        items.push({
            key: "duplicateStateFlow",
            text: "Duplicate Workflow",
            disabled: !options.permissions || !options.permissions.canDuplicate,
            iconProps: { iconName: "DependencyAdd" },
        });

        items.push({
            key: "divider_1",
            itemType: 1,
        });

        items.push({
            key: "deleteStateFlow",
            text: "Remove Workflow",
            disabled: !options.permissions || !options.permissions.canDelete,
            iconProps: { iconName: "Delete", style: { color: !options.permissions || !options.permissions.canDelete ? "var(--red-4)" : "var(--red-10)" } },
        });

        this.menuItems = items;
        this.target = options.target;
        this.visible = true;

        return promise;
    }

    @action
    public hide(options) {
        this.target = null;
        this.menuItems = [];
        this.options = null;
        this.visible = false;
    }

    @action
    public resolve(success, action, options) {
        this.options.resolve({ success, action, ...options });
    }

    @action
    public reject(error) {
        this.options.reject({ success: false, error });
    }
}
