import React, { useEffect, useState } from 'react';
import handleViewport from 'react-in-viewport';
import { observer } from 'mobx-react';
import { cx, css } from 'emotion';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { Ticker } from "react-flip-ticker";
import { NumberStore } from '../stores/NumberStore';
import { DefaultButton } from '@ui/elements/Button';
require('intersection-observer');


const containerClass = cx(css`
    justify-content: center;
    display: flex;
`);
const iconContainerClass = cx(css`
    display: flex;
    align-items: center;
    margin-left: 8px;
`);
const fontIconClass = cx(css`
    font-size:60px;
`);
const valueClass = cx(css`
    font-size:15px;
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    .after {
        margin-bottom: 8px;
        margin-left: 3px;
    }
    .before {
        margin-bottom: 8px;
        margin-right: 3px;
    }
`);
const valueContainerClass = cx(css`
    display: flex;
    flex-direction: column;
    align-content: center;
`);
const valueNumberClass = cx(css`
    font-size:40px;
    font-weight: bold;
`);

const supIcrease = cx(css`
    color: var(--green)
`);
const supDecrease = cx(css`
    color: var(--red)
`);

const bottomLinkTextClassname = cx(css`
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: 36px;
    padding: 10px;
    cursor: pointer;
    background-color: var(--green-4);
`);

const showAllButtonClassName = cx(css`
    width: 100%;
`)

export const NumberChart = observer((props) => {
 
    const numberStore = new NumberStore();
    numberStore.rawData = props.dataSeries;
    numberStore.title = props.title;
    // console.log(props.theme.palette.themeDark);
    const [enteredViewport, setEnteredViewport] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            props.onRendered();
        }, 250);
      }, []);

    const NumberBlock = handleViewport((props) => {
        const { inViewport, forwardedRef } = props;
        const value = inViewport || enteredViewport ? numberStore.data.value  : 0;
        return (
            <div className={valueClass} ref={forwardedRef} onClick={()=>{
                if (numberStore.onClick) numberStore.onClick()
            }}>
                {numberStore.data.unitDisplay === 'pre' && <span className="before">{numberStore.data.unit}</span>}
                <Ticker textClassName={valueNumberClass}>{value}</Ticker>
                <sup className={numberStore.data.increase?supIcrease:supDecrease}>{numberStore.data.isOrdered && ` (${numberStore.data.increase?'+':''}${numberStore.data.change}%) `}</sup>
                {numberStore.data.unitDisplay === 'after' && <span className="after">{numberStore.data.unit}</span>}
            </div>
        );
    });

    return <>
        <div className={containerClass}>
            {numberStore.data.selectedIcon && <div>
                <FontIcon
                    iconName={numberStore.data.selectedIcon}
                    className={fontIconClass}
                />
            </div>}
            <div className={iconContainerClass}>
                <div className={valueContainerClass}>
                    <NumberBlock 
                        onEnterViewport={() => {}}
                        onLeaveViewport={() => { setEnteredViewport(true); }}
                    />
                </div>
            </div>
        </div>
        {props.showBottomLink && <DefaultButton
          text={props.bottomLinkText || 'View all'}
          className={showAllButtonClassName}          
          onClick={() => {
            if (numberStore.onClick) numberStore.onClick()
          }}
        />}
        </>
    ;
});
