import React from "react";
import Moment from "react-moment";

import { Statistic } from "@modules/base/components/Statistic";

export const PatternSummary = ({ summary, pattern }) => {
    let targetPlural;
    let targetIconName;
    switch (pattern.target) {
        case "Asset":
            targetPlural = "Assets";
            targetIconName = "WebAppBuilderFragment";
            break;
        case "Entity":
            targetPlural = "Entities";
            targetIconName = "Org";
            break;
        case "Process":
            targetPlural = "Processes";
            targetIconName = "WebAppBuilderFragment";
            break;
    }

    return summary ? (
        <r-grid columns="2" columns-l="4">
            <r-span span="1">
                <Statistic className="card card-s" title="Usage in assessments" value={summary.assessmentCount} iconName="WorkItem" />
            </r-span>
            <r-span span="1">
                <Statistic
                    className="card card-s"
                    title={`Usage in assessments (v${pattern.publishedVersion || pattern.currentVersion} only)`}
                    value={summary.versionCount}
                    iconName="WorkItem"
                />
            </r-span>
            <r-span span="1">
                <Statistic className="card card-s" title={`${targetPlural} tagged with this pattern`} value={summary.targetCount} iconName={targetIconName} />
            </r-span>
            <r-span span="1">
                <Statistic className="card card-s" title="Last used in assessment">
                    {summary.lastUsed ? <Moment utc date={summary.lastUsed} fromNow /> : "Never"}
                </Statistic>
            </r-span>
        </r-grid>
    ) : null;
};
