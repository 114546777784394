import React, { Component } from 'react';
import { observer } from 'mobx-react';

export const TreatmentEffectiveness = observer(
    class TreatmentEffectiveness extends Component {

        render () {
            return (
                <span>{this._getLabel()}</span>
            );
        }

        _getLabel() {
            const { effectiveness } = this.props;

            if(!effectiveness) {
                return 'Unknown';
            }
            
            switch(effectiveness) {
                case 'FullyEffective':
                    return 'Fully Effective';
                case 'SubstantiallyEffective':
                    return 'Substantially Effective';
                case 'PartiallyEffective':
                    return 'Partially Effective';
                case 'LargelyIneffective':
                    return 'Largely Ineffective';
                case 'TotallyIneffective':
                    return 'Totally Ineffective';
                case 'Unknown':
                    return 'Unknown';
            }

            return effectiveness;
        }
    }
);
