import React from "react";
import { Text, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    h1: {
        fontSize: 28,
        color: "#1fbf7f",
        marginTop: 0,
        marginBottom: 20,
    },
    h2: {
        fontSize: 18,
        color: "#1fbf7f",
        marginTop: 0,
        marginBottom: 14,
    },
    h3: {
        fontSize: 14,
        marginTop: 0,
        marginBottom: 12,
    },
    h4: {
        fontSize: 12,
        fontWeight: "bold",
        marginTop: 0,
        marginBottom: 8,
    },
    h5: {
        fontSize: 10,
        fontWeight: "bold",
        marginTop: 0,
        marginBottom: 5,
    },
    center: { textAlign: "center" },
    invert: { color: "#ffffff" },
});

export const Heading1 = ({ style, children, center, invert }) => {
    return <Text style={[styles.h1, center && styles.center, invert && styles.invert, style]}>{children}</Text>;
};

export const Heading2 = ({ style, children, center, invert }) => {
    return <Text style={[styles.h2, center && styles.center, invert && styles.invert, style]}>{children}</Text>;
};

export const Heading3 = ({ style, children, center, invert }) => {
    return <Text style={[styles.h3, center && styles.center, invert && styles.invert, style]}>{children}</Text>;
};

export const Heading4 = ({ style, children, center, invert }) => {
    return <Text style={[styles.h4, center && styles.center, invert && styles.invert, style]}>{children}</Text>;
};

export const Heading5 = ({ style, children, center, invert }) => {
    return <Text style={[styles.h5, center && styles.center, invert && styles.invert, style]}>{children}</Text>;
};
