import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { BoxPanel } from '../../../base/components/BoxPanel';
import { cx, css } from 'emotion';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { MessageBar } from 'office-ui-fabric-react/lib/MessageBar';
import { TaskSeverity } from '../../components/systemView/advisoriesTab/TaskSeverity';
import { AdvisoriesCommandBar } from '../../components/systemView/advisoriesTab/AdvisoriesCommandBar';
import { ObservablePagination } from '../../../base/components/ObservablePagination';

export const AdvisoriesTab = inject('systemStore')(observer(
    class AdvisoriesTab extends Component {

        constructor(props) {
            super(props);
            this._sortAndFilter = this._sortAndFilter.bind(this);
            this.state = {
                columns: this._columns,
            }
        }

        componentWillMount() {
            const { systemStore } = this.props;
            if (systemStore.selectedSystem && systemStore.selectedSystem.advisories === null)  systemStore.loadSystemAdvisories(systemStore.selectedSystem.id, {});
        }

        componentWillReceiveProps(nextProps) {
            const { systemStore } = nextProps;
            if (systemStore.selectedSystem && systemStore.selectedSystem.advisories === null) systemStore.loadSystemAdvisories(systemStore.selectedSystem.id, {});
        }

        _sortAndFilter (event, column) {
            // @TODO: Hook this to and API CALL to actually sort 
        }

        get _columns() {
            return [
                {
                    key: 'advisories',
                    name: 'Advisory',
                    fieldName: 'advisories',
                    minWidth: 150,
                    // maxWidth: 130,
                    isResizable: true,
                    onColumnClick: this._sortAndFilter,
                    data: 'string',
                    onRender: (item) => {
                      return (
                        <React.Fragment>
                            {item.title}
                        </React.Fragment>
                      );
                    },
                    isPadded: true
                },
                {
                    key: 'relatedQuestion',
                    name: 'Related Question',
                    fieldName: 'relatedQuestion',
                    minWidth: 150,
                    // maxWidth: 130,
                    isResizable: false,
                    // onColumnClick: this._onColumnClick,
                    data: 'string',
                    onRender: (item) => {
                    return (
                        <React.Fragment>
                            {item.relatedQuestion || '-'}
                        </React.Fragment>
                    );
                    },
                    isPadded: false
                },
                {
                    key: 'serverity',
                    name: 'Serverity',
                    fieldName: 'serverity',
                    minWidth: 100,
                    maxWidth: 130,
                    isResizable: false,
                    onColumnClick: this._sortAndFilter,
                    data: 'string',
                    onRender: (item) => {
                      return (
                        <React.Fragment>
                            <TaskSeverity severity = {item.level} />
                        </React.Fragment>
                      );
                    },
                    isPadded: false
                },
                {
                    key: 'category',
                    name: 'Category',
                    fieldName: 'category',
                    minWidth: 100,
                    maxWidth: 130,
                    isResizable: false,
                    // onColumnClick: this._onColumnClick,
                    data: 'string',
                    onRender: (item) => {
                      return (
                        <React.Fragment>
                            {item.control.value}
                        </React.Fragment>
                      );
                    },
                    isPadded: false
                },
                {
                    key: 'status',
                    name: 'Status',
                    fieldName: 'status',
                    minWidth: 100,
                    maxWidth: 130,
                    isResizable: false,
                    // onColumnClick: this._sortAndFilter,
                    data: 'string',
                    onRender: (item) => {
                        return (
                        <React.Fragment>
                            <div className="message message--primary">{item.finalCompliance || 'Pending'}</div>
                        </React.Fragment>
                        );
                    },
                    isPadded: false
                },
                // {
                //     key: 'actions',
                //     name: 'Actions',
                //     fieldName: 'status',
                //     minWidth: 100,
                //     maxWidth: 130,
                //     isResizable: false,
                //     // onColumnClick: this._onColumnClick,
                //     data: 'string',
                //     onRender: (item) => {
                //         return (
                //         <React.Fragment>
                //             <button className="btn btn--icon--only">
                //                 <i className="fas fa-check"></i>
                //             </button>
                //         </React.Fragment>
                //         );
                //     },
                //     isPadded: false
                // }
            ]

        }

        render () {
            const { 
                advisories,
                loading,
                advisoriesSeverities,
                advisoriesStatus,
                keywords,
                selectedSeverity,
                selectedState,
                onSearchChange,
                onStatusChange,
                onSeverityChange,
            } = this.props;
            const { 
                columns 
            } = this.state;
            
            return (
                <React.Fragment>
                    <AdvisoriesCommandBar
                        advisoriesSeverities={advisoriesSeverities}
                        advisoriesStatus={advisoriesStatus}
                        selectedSeverity={selectedSeverity}
                        selectedState={selectedState}
                        keywords={keywords}
                        onSearchChange={onSearchChange}
                        onStatusChange={onStatusChange}
                        onSeverityChange={onSeverityChange}
                    />
                    <BoxPanel shadow>
                        <ShimmeredDetailsList
                            items={advisories}
                            compact={false}
                            columns={columns}
                            // className={taskGridCss}
                            // selectionMode={SelectionMode.multiple}
                            // getKey={this._getKey}
                            setKey="multiple"
                            // layoutMode={DetailsListLayoutMode.justified}
                            // constrainMode={ConstrainMode.horizontalConstrained}
                            isHeaderVisible={true}
                            // selection={this._selection}
                            selectionPreservedOnEmptyClick={true}
                            // onItemInvoked={onAdvisoryClick}
                            // onItemContextMenu={(item, index, ev) => this._onItemContextMenu(item, index, ev) }
                            enterModalSelectionOnTouch={true}
                            ariaLabelForSelectionColumn="Toggle selection"
                            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                            checkButtonAriaLabel="Row checkbox"
                            // enableShimmer={loading}
                            ariaLabelForShimmer="Advisories are being fetched"
                            ariaLabelForGrid="Advisories list"
                        />
                        { ! loading && !advisories.length && (
                            <MessageBar>
                                There are no matching advisories.
                            </MessageBar>
                        )}
                        {/* {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />} */}
                    </BoxPanel>
                    <ObservablePagination 
                        {...this.props.pagination}
                        onPageChange={(index) => this.props.onPageChange(index)}
                    />
                </React.Fragment>
            );
        }
    }
));