import React, { Component } from "react";
import { observer, inject } from "mobx-react";

import { Stack } from "office-ui-fabric-react/lib/Stack";
import { ComboBox } from "office-ui-fabric-react/lib/ComboBox";
import { Label } from "office-ui-fabric-react/lib/Label";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";
import { getId } from "@uifabric/utilities/lib/getId";

import { StateFlowPickerStore } from "../../stores/StateFlowPickerStore";
import { RoutingLink } from "@base/components/RoutingLink";

export const StateFlowPicker = inject("stateFlowStore")(
    observer(
        class StateFlowPicker extends Component {
            constructor(props) {
                super(props);

                this.state = {
                    pickerStore: new StateFlowPickerStore(this.props.stateFlowStore),
                };
            }

            componentWillMount() {
                const { pickerStore } = this.state;
                const { multiple, selected, selectedId, selectedIds, target } = this.props;

                if (!pickerStore.loading && pickerStore.stateflows.length == 0) {
                    pickerStore.loadStateFlows({ query: { target: [target] } });
                }

                if (typeof selected !== "undefined") {
                    pickerStore.setSelected(multiple ? selected || [] : selected ? [selected] : []);
                } else if (!multiple) {
                    pickerStore.setSelectedWithId(selectedId);
                } else {
                    pickerStore.setSelectedWithIds(selectedIds);
                }
            }

            componentWillReceiveProps(nextProps) {
                const { pickerStore } = this.state;
                const { multiple, selected, selectedId, selectedIds } = nextProps;

                if (!pickerStore.loading && pickerStore.stateflows.length == 0) {
                    pickerStore.loadStateFlows();
                }

                if (typeof selected !== "undefined") {
                    pickerStore.setSelected(multiple ? selected || [] : selected ? [selected] : []);
                } else if (!multiple) {
                    pickerStore.setSelectedWithId(selectedId);
                } else {
                    pickerStore.setSelectedWithIds(selectedIds);
                }
            }

            render() {
                const { pickerStore } = this.state;
                const {
                    label,
                    required,
                    placeholder,
                    multiple,
                    disabled,
                    className,
                    onChange,
                    except,
                    selectedKey,
                    readOnly,
                } = this.props;
                const { stateflows, selectedStateFlowIds, loading } = pickerStore;

                const selected = selectedKey
                    ? selectedKey
                    : multiple
                    ? selectedStateFlowIds
                    : selectedStateFlowIds.length
                    ? selectedStateFlowIds[0]
                    : null;
                const pickerId = getId("registryPicker");

                const selectedStateFlow = stateflows.find((sf) => sf.id === selected);

                return (
                    <div className={className}>
                        <Stack horizontal={true} verticalAlign={"center"}>
                            <Label required={required} disabled={disabled} htmlFor={pickerId}>
                                {label}
                            </Label>
                            {loading && <Spinner size={SpinnerSize.xSmall} />}
                        </Stack>
                        {readOnly ? (
                            selectedStateFlow ? (
                                <RoutingLink url={`/settings/workflows/${selectedStateFlow.id}`}>
                                    {selectedStateFlow ? selectedStateFlow.name : "Not defined"}
                                </RoutingLink>
                            ) : null
                        ) : (
                            <ComboBox
                                id={pickerId}
                                placeholder={placeholder}
                                disabled={disabled}
                                required={required}
                                multiSelect={multiple}
                                selectedKey={selected}
                                allowFreeform={true}
                                useComboBoxAsMenuWidth={true}
                                autoComplete="on"
                                options={stateflows
                                    .filter((r) => (except || []).indexOf(r.id) === -1)
                                    .map((p) => ({ key: p.id, text: p.name, data: p }))}
                                onChange={(event, option, index, value) => {
                                    if (multiple) {
                                        if (option) {
                                            const newSelected = [...selectedStateFlowIds];
                                            const index = newSelected.indexOf(option.key);
                                            if (index == -1) {
                                                newSelected.push(option.key);
                                            } else {
                                                newSelected.splice(index, 1);
                                            }
                                            pickerStore.setSelectedWithIds(newSelected);

                                            if (onChange) {
                                                onChange(stateflows.filter((r) => newSelected.indexOf(r.id) != -1));
                                            }
                                        }
                                    } else {
                                        pickerStore.setSelectedWithId(option ? option.key : null);
                                        if (onChange) {
                                            onChange(option ? option.data : null);
                                        }
                                    }
                                }}
                            />
                        )}
                    </div>
                );
            }
        }
    )
);
