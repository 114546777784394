import React from "react";
import moment from "moment";
import filesize from "filesize";

import { View, Text, Image } from "@react-pdf/renderer";
import { getFileTypeIconProps } from "@uifabric/file-type-icons";
import { getIconContent } from "office-ui-fabric-react/lib/Icon";
import { Paragraph } from "@ui/pdf/Paragraph";
import { Table, TableHeader, TableBody, TableCell, DataTableCell } from "@ui/pdf/Table";

export const DocumentTable = ({ documents }) => {
    return (
        <Table data={documents}>
            <TableHeader>
                <TableCell weighting={0.06}>Type</TableCell>
                <TableCell weighting={0.54}>Title</TableCell>
                <TableCell weighting={0.12}>Size</TableCell>
                <TableCell weighting={0.08}>Version</TableCell>
                <TableCell weighting={0.22}>Modified</TableCell>
            </TableHeader>
            <TableBody>
                <DataTableCell
                    weighting={0.06}
                    getContent={(r) => {
                        const iconProps = getFileTypeIconProps({ extension: r.document.extension, size: 32, imageFileType: "png" });
                        const iconContent = getIconContent(iconProps.iconName);
                        return <Image style={{ width: 32, height: 32 }} src={iconContent.children.props.src} />;
                    }}
                />
                <DataTableCell
                    weighting={0.54}
                    getContent={(r) => (
                        <View>
                            <Paragraph style={{ fontSize: 11, marginBottom: 12 }}>
                                {r.document.name}
                                {r.document.extension}
                            </Paragraph>
                            <Paragraph style={{ color: "#444444" }}>{r.notes}</Paragraph>
                        </View>
                    )}
                />
                <DataTableCell weighting={0.12} getContent={(r) => <Text>{filesize(r.document.size)}</Text>} />
                <DataTableCell weighting={0.08} getContent={(r) => <Text>{r.document.version}</Text>} />
                <DataTableCell
                    weighting={0.22}
                    getContent={(r) => (
                        <View>
                            <Paragraph>{r.modifiedBy.name}</Paragraph>
                            <Paragraph>{moment.utc(r.modified).format("Do MMM YYYY")}</Paragraph>
                        </View>
                    )}
                />
            </TableBody>
        </Table>
    );
};
