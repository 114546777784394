import React from "react";
import { observer } from "mobx-react";

import { Text } from "@ui/elements/Text";
import { MessageBar } from "@ui/elements/MessageBar";
import { FontIcon } from "@ui/elements/Icon";

import { DetailsListLayoutMode, SelectionMode, ConstrainMode } from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";

import { BoxPanel } from "@modules/base/components/BoxPanel";
import { WrappedContent } from "@modules/base/components/WrappedContent";
import { AdaptiveField } from "@modules/base/components/AdaptiveField";

export const MeasureEditGridView = observer((props) => {
    const { measures, loading, disableSelection, onUpdate } = props;

    const columns = [
        {
            key: "measure",
            name: "Measure",
            fieldName: "measure",
            isRowHeader: true,
            isResizable: true,
            data: "string",
            onRender: (item) => {
                return (
                    <WrappedContent>
                        {item.title}
                        <br />
                        <Text variant="small" block>
                            {item.description}
                        </Text>
                    </WrappedContent>
                );
            },
            isPadded: true,
        },
        {
            key: "value",
            name: "Compliant?",
            fieldName: "value",
            minWidth: 170,
            isResizable: false,
            data: "string",
            onRender: (item) => {
                return (
                    <AdaptiveField
                        type="Toggle"
                        mode="edit"
                        valueConverter="simple"
                        value={item.value}
                        onChange={(val) => {
                            onUpdate({ ...item, value: val });
                        }}
                        onText="Compliant"
                        offText="Non Compliant"
                    />
                );
            },
            isPadded: false,
        },
        {
            key: "attach",
            name: <FontIcon iconName="Attach" />,
            fieldName: "attach",
            minWidth: 30,
            isResizable: false,
            onRender: (item) => {
                return <FontIcon iconName="Add" />;
            },
            isPadded: false,
        },
    ];

    return (
        <BoxPanel shadow>
            <ShimmeredDetailsList
                items={measures}
                compact={false}
                columns={columns}
                selectionMode={disableSelection ? SelectionMode.none : SelectionMode.none}
                getKey={(item, index) => (item ? item.id : index)}
                setKey="single"
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.horizontalConstrained}
                isHeaderVisible={true}
                selectionPreservedOnEmptyClick={true}
                //onItemContextMenu={this._onItemContextMenu}
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="Row checkbox"
                enableShimmer={loading}
                removeFadingOverlay={true}
                shimmerLines={15}
                ariaLabelForShimmer="Control measures being fetched"
                ariaLabelForGrid="Control measure list"
            />

            {!loading && !measures.length && <MessageBar>There are no matching control measures.</MessageBar>}
        </BoxPanel>
    );
});
