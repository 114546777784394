import React from "react";
import { cx, css } from "emotion";

import { Icon } from "office-ui-fabric-react/lib/Icon";

const rootClassName = cx(
    "cygraph-GhostIconButton-root",
    css`
        display: inline-block;
        padding: 5px 8px 4px 11px;
        font-size: 16px;
        width: 32px;
        height: 32px;
        color: var(--primary-brand-color);
    `
);

export function GhostIconButton({ title, ariaLabel, iconProps, dragHandleProps }) {
    return (
        <span
            className={cx(rootClassName, "ms-Button", "ms-Button--icon")}
            role="button"
            {...dragHandleProps}
            title={title}
            aria-label={ariaLabel}
            data-is-focusable="true"
        >
            <span className="ms-Button-flexContainer">
                <Icon {...iconProps} title={title} ariaLabel={ariaLabel} />
            </span>
        </span>
    );
}
