import { observable, flow, computed, action } from "mobx";

import { PaginationStore } from "../../base/PaginationStore";
import { ProductService } from '../../../api/graph';
import { ProductStore } from "./ProductStore";

export class ProductBrowseStore {

    public parentStore: ProductStore;
    public productService: ProductService;

    @observable public loading: boolean = false;
    @observable public products: any[] = [];
    @observable public keywords: string;
    @observable public pagination: PaginationStore;
    @observable public error: any;

    constructor(parentStore: ProductStore) {
        this.parentStore = parentStore;
        this.productService = parentStore.productService;
        this.pagination = new PaginationStore();
    }

    @computed
    public get breadcrumb() {
        const builder: any[] = [];

        builder.push({ text: 'Settings', key: 'settings', onClick: () => this.parentStore.rootStore.routing.push(`/settings`) });
        builder.push({ text: 'Products & Service', key: 'products', onClick: () => this.parentStore.rootStore.routing.push(`/settings/products`) });

        return builder;
    }

    public loadProducts = flow(function*(query: any) {
        this.loading = true;
        const options = Object.assign({ page: 1, keywords: null }, query);

        this.products = [];
        this.keywords = null;
        this.error = null;
        
        try {
            const result = yield this.productService.getProducts({ 
                pageSize: this.pagination.itemsPerPage, 
                startIndex: (options.page - 1) *  this.pagination.itemsPerPage,
                keywords: options.keywords,
            });

            this.products = result.items;
            this.pagination.setPaging(result.pageSize, result.totalItems, result.startIndex);
            this.keywords = result.keywords;
        } catch (e) {
            this.error = e;
            console.error(e)
        } finally {
            this.loading = false;
        }
    });

    @action
    public replace(product) {
        const index = this.products.findIndex(p => p.id === product.id);

        if(index !== -1) {            
            this.products = [...this.products.splice(index, 1, product)];
        }
    }
}
    