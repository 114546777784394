import React, { Component } from "react";
import { observer, inject } from "mobx-react";

import { Pivot, PivotItem, PivotLinkFormat } from "office-ui-fabric-react/lib/Pivot";

import { AppPage } from "../../../layout/containers/AppPage";
import { BigSearchBox } from "../../../base/components/BigSearchBox";

import { ExceptionGridView } from "../../components/exceptions/ExceptionGridView";
import { ExceptionSideNav } from "../../components/exceptions/ExceptionSideNav";
import { RiskNewPanel } from "../panels/RiskNewPanel";

export const ExceptionDashboardPage = inject(
    "riskStore",
    "routing"
)(
    observer(
        class ExceptionDashboardPage extends Component {
            componentWillMount() {
                const { exceptionDashboardStore, typeStore } = this.props.riskStore;
                exceptionDashboardStore.loadDashboard({});
                typeStore.loadTypes({ cached: true });
            }

            componentWillReceiveProps(nextProps) {
                const { exceptionDashboardStore, typeStore } = nextProps.riskStore;
                exceptionDashboardStore.loadDashboard({});
                typeStore.loadTypes({ cached: true });
            }

            render() {
                const { exceptionDashboardStore, typeStore } = this.props.riskStore;
                const { loading, breadcrumb, error, risks, tab, myTabs } = exceptionDashboardStore;

                return (
                    <AppPage
                        error={error}
                        breadcrumb={breadcrumb}
                        loading={loading}
                        hasSidebar={false}
                        className="risks dashboard has--page-nav"
                    >
                        <ExceptionSideNav
                            onLinkClick={(item) => this.props.routing.push(item.url)}
                            onNewRisk={(options) => this._onNewRisk(options)}
                        />

                        <>
                            <Pivot
                                aria-label="Exception reviews, lifecycles, related documents and work"
                                selectedKey={tab}
                                onLinkClick={(item) => exceptionDashboardStore.selectTab(item.props.itemKey)}
                                linkFormat={PivotLinkFormat.links}
                            >
                                {Object.keys(myTabs).map((id) => {
                                    const tab = myTabs[id];
                                    return (
                                        <PivotItem key={id} itemKey={id} headerText={tab.title}>
                                            <ExceptionGridView risks={risks} loading={loading} query={{}} />
                                        </PivotItem>
                                    );
                                })}
                            </Pivot>

                            <RiskNewPanel
                                onRiskCreated={(risk) => this.props.routing.push(`/exceptions/browse/${risk.id}`)}
                            />
                        </>
                    </AppPage>
                );
            }

            _onNewRisk() {
                this.props.routing.push("/exceptions/new");
            }
        }
    )
);
