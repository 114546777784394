import React from "react";
import { observer } from "mobx-react";

import { cx, css } from "emotion";

import { IconButton } from "office-ui-fabric-react/lib/Button";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { TooltipHost } from "office-ui-fabric-react/lib/Tooltip";

const rootClassName = cx(
    "cygraph-ModuleCommandBar-root",
    css`
        position: fixed;
        left: calc(var(--app-nav-mini-width) + var(--sidebar-width-l));
        right: 0;
        bottom: 0;
        height: 56px;
        line-height: 38px;
        display: flex;

        & .wrapper {
            display: flex;
            width: 96%;
            max-width: 600px;
            margin: 0 auto;
            padding-right: var(--spacing);
            background: var(--nav-background);
            color: var(--nav-color);
            height: 100%;
            border: 1px solid var(--border-color);
            border-bottom: 0;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            padding: 8px 18px;
        }

        & .saving {
            padding: 7px 10px 7px 0;
        }

        & .modified {
            text-transform: lowercase;
            color: var(--primary-brand-color);
        }

        & .buttons {
            padding: 4px 0;
            & > div {
                margin-left: 10px;
            }
        }

        & .grow {
            flex-grow: 1;
        }

        @media screen and (max-width: 1023px) {
            left: calc(var(--app-nav-mini-width) + 30px);
            right: 30px;
        }
    `
);

export const ModuleCommandBar = observer(({ className, isReadOnly, saving, manifest, isDirty, onSave, onReset, onPattern, onDownload, onModule }) => {
    const menuProps = {
        items: manifest.modules.map((module) => ({
            key: module.id,
            text: module.title,
            onClick: () => onModule(module),
        })),
    };

    return (
        <div className={cx(rootClassName, className)}>
            <div className="wrapper animated fadeInUp">
                <div className="saving">{saving && <Spinner size={SpinnerSize.large} />}</div>
                {isReadOnly ? (
                    <div className="grow modified">
                        <Icon iconName="LockSolid" /> pattern is read-only
                    </div>
                ) : (
                    <div className="grow modified">
                        {saving && "saving..."}
                        {!saving && (
                            <>
                                <TooltipHost content={`Last saved version is ${manifest.version}${isDirty ? ", there are some unsaved changes" : ""}`}>
                                    Last saved version is {manifest.version} {isDirty ? "*" : ""}
                                </TooltipHost>
                            </>
                        )}
                    </div>
                )}

                <div className="buttons">
                    <TooltipHost content="Save all changes">
                        <IconButton iconProps={{ iconName: "Save" }} disabled={!onSave} onClick={() => onSave({ module })} />
                    </TooltipHost>

                    <TooltipHost content="Reset changes to last saved">
                        <IconButton iconProps={{ iconName: "Rotate90CounterClockwise" }} disabled={!onReset} onClick={() => onReset({ module })} />
                    </TooltipHost>

                    <TooltipHost content="Export questions to CSV">
                        <IconButton iconProps={{ iconName: "ExcelDocument" }} disabled={!onDownload} onClick={() => onDownload(module)} />
                    </TooltipHost>

                    <TooltipHost content="Back to pattern">
                        <IconButton iconProps={{ iconName: "WebComponents" }} disabled={!onPattern} onClick={() => onPattern()} />
                    </TooltipHost>

                    <TooltipHost content="Switch module">
                        <IconButton iconProps={{ iconName: "NoteForward" }} disabled={!onModule} menuProps={menuProps} />
                    </TooltipHost>
                </div>
            </div>
        </div>
    );
});
