import React from "react";

import { PropertyTable } from "@ui/pdf/Table";

export const ContactPropertyTable = ({ workItem, assessment }) => {
    const properties = [];

    properties.push({ name: "Assessment Requester", value: workItem.requestedBy, type: "principal" });
    properties.push({ name: "Project Business Contact", value: workItem.businessContact, type: "principal" });
    properties.push({ name: "Project Technical Contact", value: workItem.technicalContact, type: "principal" });
    properties.push({ name: "Portfolio Business Contact", value: assessment.portfolioContact, type: "principal" });
    properties.push({ name: "Portfolio Technical Contact", value: assessment.portfolioTechnical, type: "principal" });

    return <PropertyTable data={properties} />;
};
