import React, { Component } from "react";
import { observer } from "mobx-react";

import { Text } from "office-ui-fabric-react/lib/Text";
import { CompoundButton } from "office-ui-fabric-react/lib/Button";

import { ModuleCardView } from "./ModuleCardView";

export const ModuleCardListView = observer(
    class ModuleCardListView extends Component {
        render() {
            const { className, modules, isEditMode, canAddModule, onAddModule, canEditModule, onEditModule, canRemoveModule, onRemoveModule, onModuleClick } =
                this.props;

            return modules ? (
                <div className={className}>
                    <Text className="row--xs" block variant="medium">
                        <strong>Questionnaire Modules</strong>
                    </Text>

                    <div>
                        <r-grid columns-l={this._getLargeScreenColumns()} columns={this._getMediumScreenColumns()} columns-s="1">
                            {canAddModule && (
                                <r-cell>
                                    <CompoundButton
                                        styles={{
                                            root: {
                                                width: "100%",
                                                maxWidth: "100%",
                                                padding: "var(--spacing-xl)",
                                                height: "110px",
                                            },
                                        }}
                                        iconProps={{ iconName: "Add" }}
                                        primary={false}
                                        secondaryText="Add additional groups and questions"
                                        onClick={() => onAddModule()}
                                    >
                                        Add module
                                    </CompoundButton>
                                </r-cell>
                            )}

                            {modules.map((module) => {
                                return (
                                    <r-cell key={module.id}>
                                        <ModuleCardView
                                            module={module}
                                            isEditMode={isEditMode}
                                            onModuleClick={onModuleClick}
                                            canEditModule={canEditModule}
                                            onEditModule={onEditModule}
                                            canRemoveModule={canRemoveModule}
                                            onRemoveModule={onRemoveModule}
                                        />
                                    </r-cell>
                                );
                            })}
                        </r-grid>
                    </div>
                </div>
            ) : null;
        }

        _getMediumScreenColumns() {
            const { vertical } = this.props;

            if (vertical) {
                return 1;
            }

            return 2;
        }

        _getLargeScreenColumns() {
            const { modules, canAddModule, vertical } = this.props;

            if (vertical) {
                return 1;
            }

            if (modules.length % (3 - (canAddModule ? 1 : 0)) == 0) {
                return 3;
            }

            if (modules.length % (4 - (canAddModule ? 1 : 0)) == 0) {
                return 4;
            }

            return 3;
        }
    }
);
