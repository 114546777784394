import React, { Component } from "react";
import { observer, inject } from "mobx-react";

import { Pivot, PivotItem, PivotLinkFormat } from "office-ui-fabric-react/lib/Pivot";
import { cx, css } from "emotion";

import { AppPage } from "@layout/containers/AppPage";
import { BigSearchBox } from "@baseComponents/BigSearchBox";

import { PageInstanceViewer } from "@modules/pages/containers/utilities/PageInstanceViewer";

import { AssessmentGridView } from "../../components/core/AssessmentGridView";
import { AssessmentCommandBar } from "../../components/core/AssessmentCommandBar";
import { AssessmentSideNav } from "../../components/core/AssessmentSideNav";

const getQueryStringParams = (query) => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query).split("&").reduce((params, param) => {
              let [key, value] = param.split("=");
              params[key] = value ? decodeURIComponent(value.replace(/\+/g, " ")) : "";
              return params;
          }, {})
        : {};
};

const bigSearchBoxClassName = cx(
    "row--l",
    css`
        margin-bottom: 15px !important;
    `
);

export const AssessmentDashboardPage = inject(
    "pageContext",
    "assessmentStore",
    "routing"
)(
    observer(
        class AssessmentDashboardPage extends Component {
            componentWillMount() {
                const { assessmentStore, routing } = this.props;

                const params = getQueryStringParams(routing.location.search);
                assessmentStore.dashboardStore.loadAssessments({ page: params["p"] || 1, keywords: params["k"] });
            }

            componentWillReceiveProps(nextProps) {
                const { assessmentStore, routing } = nextProps;

                const params = getQueryStringParams(routing.location.search);
                assessmentStore.dashboardStore.loadAssessments({ page: params["p"] || 1, keywords: params["k"] });
            }

            render() {
                const { assessmentStore, routing } = this.props;
                const { lifecycleStore } = assessmentStore;
                const { breadcrumb, error, loading, tab, myTabs, assessments, keywords, pagination } =
                    assessmentStore.dashboardStore;

                return (
                    <AppPage
                        error={error}
                        breadcrumb={breadcrumb}
                        loading={loading}
                        hasSidebar={false}
                        className="assessments dashboard has--page-nav"
                    >
                        <AssessmentSideNav
                            onLinkClick={(item) => routing.push(item.url)}
                            onNewTriage={() => routing.push("/assurance/triage")}
                            canViewResourcePages={lifecycleStore.canViewResourcePages}
                            canViewCalendarPages={lifecycleStore.canViewCalendarPages}
                            canViewAdvisoryPages={lifecycleStore.canViewAdvisoryPages}
                        />

                        <div>
                            <PageInstanceViewer path="/_/assurance/dashboard" optional={true} />

                            {false && (
                                <BigSearchBox
                                    className={bigSearchBoxClassName}
                                    placeholder="Search for assessments by title or unique identifier"
                                    url="/assurance/browse"
                                />
                            )}

                            <Pivot
                                aria-label="Assessments"
                                selectedKey={tab}
                                onLinkClick={(item) => assessmentStore.dashboardStore.selectTab(item.props.itemKey)}
                                linkFormat={PivotLinkFormat.links}
                            >
                                {Object.keys(myTabs).map((id) => {
                                    const tab = myTabs[id];
                                    return (
                                        <PivotItem key={id} itemKey={id} headerText={tab.title}>
                                            <AssessmentCommandBar
                                                onNewTriage={() => routing.push("/assurance/triage")}
                                            />
                                            <AssessmentGridView
                                                assessments={assessments}
                                                loading={loading}
                                                query={{}}
                                            />
                                        </PivotItem>
                                    );
                                })}
                            </Pivot>
                        </div>
                    </AppPage>
                );
            }
        }
    )
);
