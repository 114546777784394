import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { useStores } from "@hooks/useStores";

import { ObservablePagination } from "@modules/base/components/ObservablePagination";

import { IdentityGroupGridView } from "@modules/identity/components/groups/IdentityGroupGridView";
import { IdentityGroupCommandBar } from "@modules/identity/components/groups/IdentityGroupCommandBar";
import { IdentityGroupEditPanel } from "@modules/identity/containers/panels/IdentityGroupEditPanel";
import { IdentityGroupNewPanel } from "@modules/identity/containers/panels/IdentityGroupNewPanel";

export const IdentityGroupListTab = withRouter(
    observer(({ match }) => {
        const { principalStore, routing, pageContext } = useStores();
        const { groupBrowseStore, lifecycleStore } = principalStore;
        const { groups, keywords, loading, pagination } = groupBrowseStore;

        useEffect(() => {
            pageContext.updateQueryStringParams(routing.location.search);
            groupBrowseStore.loadGroups({
                page: pageContext.queryStringParams.p || 1,
                keywords: pageContext.queryStringParams.k,
            });
        }, [match]);

        return (
            <>
                <IdentityGroupCommandBar
                    keywords={keywords}
                    onSearchChange={(keywords) => {
                        routing.push(
                            `/settings/identity/groups?p=${1}${keywords ? `&k=${encodeURIComponent(keywords)}` : ""}`
                        );
                    }}
                    onAddGroup={(options) => {
                        lifecycleStore.onAddGroup(options);
                    }}
                />

                <IdentityGroupGridView
                    groups={groups}
                    loading={loading}
                    onGroupClick={(group) => {
                        lifecycleStore.onEditGroup({ group });
                    }}
                />

                <ObservablePagination
                    {...pagination}
                    onPageChange={(index) => {
                        routing.push(
                            `/settings/identity/groups?p=${index + 1}${
                                keywords ? `&k=${encodeURIComponent(keywords)}` : ""
                            }`
                        );
                    }}
                />

                <IdentityGroupEditPanel store={principalStore.groupEditFormStore} />
                <IdentityGroupNewPanel store={principalStore.groupNewFormStore} />
            </>
        );
    })
);
