import React from "react";

import { View, Text } from "@react-pdf/renderer";

export const AssessmentSummary = ({ assessment, showException, showRisk }) => {
    const { compliantCount, nonCompliantCount, riskCount, exceptionCount } = assessment.summary;

    const sections = [
        {
            name: "Compliant Advisories",
            count: compliantCount || 0,
            color: "#1fbf7f",
        },
        {
            name: "Non-Compliant Advisories",
            count: nonCompliantCount || 0,
            color: "#ff9500",
        },
    ];

    if (showException) {
        sections.push({
            name: "Exceptions Raised",
            count: exceptionCount || 0,
            color: "#ff2e55",
        });
    }

    if (showRisk) {
        sections.push({
            name: "Risks Identified",
            count: riskCount || 0,
            color: "#ff2e55",
        });
    }

    return (
        <View
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "stretch",
                marginTop: 20,
                marginLeft: -5,
                marginRight: -5,
            }}
            wrap={false}
        >
            {sections.map((section) => (
                <View
                    key={section.name}
                    style={{
                        flex: 1,
                        justifyContent: "stretch",
                        textAlign: "center",
                        marginLeft: 5,
                        marginRight: 5,
                        border: "1pt solid #e6e6e6",
                        borderColor: section.color,
                        borderRadius: 5,
                        lineHeight: 1.3,
                    }}
                    wrap={false}
                >
                    <Text
                        style={{
                            fontSize: 18,
                            paddingTop: 7,
                            paddingBottom: 5,
                            backgroundColor: section.color,
                            color: "#ffffff",
                        }}
                    >
                        {section.count || 0}
                    </Text>
                    <View style={{ fontSize: 10, paddingTop: 10, paddingBottom: 10, wordWrap: "pre" }}>
                        <Text>{section.name}</Text>
                    </View>
                </View>
            ))}
        </View>
    );
};
