import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { PanelType } from 'office-ui-fabric-react/lib/Panel';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';

import { ObservablePanel } from '../../../base/components/ObservablePanel';
import { PermissionShareForm } from '../../components/PermissionShareForm';

export const SharingFormPanel = observer(
    class SharingFormPanel extends Component {
        
        render () {
            const { visible, formData, formOptions, isValid } = this.props;
            
            return (
                <ObservablePanel
                    hidden={!visible}
                    pageTitle={formOptions ? formOptions.pageTitle : 'Page'}
                    pageType={'Sharing'}
                    panelType={PanelType.smallFixedFar}
                    onClosePanel={() => this._onHidePanel()}
                    onRenderFooter={() => this._renderFooter()}>
                    { formData && ( 
                        <PermissionShareForm 
                            formData={formData}
                            formOptions={formOptions}
                            isValid={isValid} />
                    ) }
                </ObservablePanel>
            );
        }

        _renderFooter() {
            const { isValid } = this.props;

            // const { sectionEditStore } = this.props.instance;
            // const { isValid } = sectionEditStore;

            return (
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <PrimaryButton onClick={() => this._onSaveShare()} text="Share" disabled={!isValid} />
                    <DefaultButton onClick={() => this._onHidePanel()} text="Cancel" />
                </Stack>
            );
        }

        _onSaveShare() {
            this.props.onGrant();
            // const { sectionEditStore } = this.props.instance;
            // const { formOptions, formData } = sectionEditStore;
            // formOptions.resolve(formData);
            // sectionEditStore.hide();
        }

        _onHidePanel() {
            this.props.onCancel();
            // const { sectionEditStore } = this.props.instance;
            // const { formOptions } = sectionEditStore;
            // formOptions.resolve();
            // sectionEditStore.hide();
        }
    }
);