import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { PanelType } from 'office-ui-fabric-react/lib/Panel';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { ObservableSeparator} from '@baseComponents/ObservableSeparator';
import { ObservablePanel } from '@baseComponents/ObservablePanel';
import { PrincipalPicker } from '@identity/containers/PrincipalPicker';
import { AsyncPicker} from '@baseComponents/asyncPicker/AsyncPicker';

export const ExtendedSearchPanel = inject('filterBarStore')(observer(
    class ExtendedSearchPanel extends Component {
        constructor(props) {
            super(props);
            props.filterBarStore.loadPortfolios()
        }

        get isReady() {
            return true;
        }

        onItemPicked = (field, item) => {
            const { onFiltersChange } = this.props;
            debugger
            if (item) {
                item.checked = true;
                if  (!item.id) item.id = item.key;
                if (!field.items) field.items = [];
                field.items.push(item);
            }
            if (onFiltersChange) onFiltersChange();
        }

        onCheckBoxChange = (field, item) => {
            const {  onFiltersChange } = this.props;
            field.items.find(I => I.key === item.key).checked = item.selected;
            if (onFiltersChange) onFiltersChange()
        }

        onPersonaChange = (field, element, checked) => {
            const { onFiltersChange } = this.props;
            if (element) {
                element.checked = true;
                if  (!element.id) element.id = element.key;
                if (!field.items) field.items = [];
                field.items.push(element);
            }
            if (onFiltersChange) onFiltersChange();
        }

        onAddItem = (user, field) => {
            const { filterBarStore, onFiltersChange } = this.props;
            const selectedField = filterBarStore.filters.find(F => F.name === field);
            if (user) {
                user.checked = true;
                if  (!user.id) user.id = user.key;
                selectedField.items.push(user);
            }
            if (onFiltersChange) onFiltersChange(filterBarStore.selectedFilters); 
        }

        getField(field) {
            const { onFiltersChange } = this.props;
            // console.log(' -> field', field.fieldName, field)
            switch (field.fieldType) {
                case 'text':
                    return (
                        <SearchBox
                            dataTest={field.fieldName}
                            value={field.value}
                            placeholder={field.placeholder}
                            styles={{ root: { height: '32px', minWidth: '270px', marginTop: '5px' }}}
                            onSearch={(value) => {field.value = value; onFiltersChange()}}
                            onClear={() => { field.value = ""; onFiltersChange()}}
                        />
                    );
                case 'personPicker':
                    return  (
                        <>
                            {field.placeholder}
                            <PrincipalPicker 
                                placeholder={''}
                                onSelected={(user) => this.onPersonaChange(field, user, true)}
                                selected={field.items ? field.items : []}
                                multiple={true}
                            />
                        </>
                    );
                case 'dropdown':
                    field.items = field && field.items ? field.items.map(I => { return {
                        key: I.key,
                        text: I.text,
                        checked: I.checked || (field.selectedItems.map(I => I.toLowerCase()).includes(I.key.toLowerCase()))
                    }}) : []
                    return (
                        <Dropdown
                            placeholder="Select options"
                            label={field.placeholder}
                            selectedKeys={field.items.filter(i=>i.checked).map(i=>i.key)}
                            multiSelect
                            options={field.items}
                            styles={{ root: { marginBottom: '5px' }}}
                            onChange = {(element, item) => this.onCheckBoxChange(field, item)}
                        />
                    );

                case 'asyncPicker':
                    // console.log('field selectedItems', field.selectedItems)
                    return <>
                        {field.placeholder}
                        <AsyncPicker 
                            placeholder={field ? field.placeholder : ''}
                            onItemPicked={(item) => this.onItemPicked(field, item)}
                            selected={field.items}
                            api={field.searchApi}
                            idField={field.idField || 'id'}
                            textField={field.textField || 'name'}
                            pickedField={field.pickedField}
                            preTextField={field.preTextField}
                        />
                        </>
                default:
                    return null
            }
        }

        render () {
            const { filterBarStore, visible, title, onClosePanel, filters, fields, keywordPlaceHolder, onKeywordsSearch, onTextFieldClear, onFiltersChange } = this.props;
            
            const keywordsField = filterBarStore.filters.find(F => F.name === 'keywords');
            const ageField = filterBarStore.filters.find(F => F.name === 'age');
            const assessmentStatusField = filterBarStore.filters.find(F => F.name === 'assessmentStatus');
            const statusField = filterBarStore.filters.find(F => F.name === 'status');
            const initialComplianceLevelField = filterBarStore.filters.find(F => F.name === 'initialComplianceLevel');
            const finalComplianceLevelField = filterBarStore.filters.find(F => F.name === 'finalComplianceLevel');
            const portfolioField = filterBarStore.filters.find(F => F.name === 'portfolio');
            const assessmentField = filterBarStore.filters.find(F => F.name === 'assessment');
            const controlField = filterBarStore.filters.find(F => F.name === 'controls');
            // console.log('extended fields', fields);

            const items = [];
            if (fields && filters) {
                filters.forEach(F => {
                    const element = fields.find(field => field.fieldName === F);
                    if (element) {
                        items.push(this.getField(element));
                    }
                })
            }
            // console.log(items);
            return (
                <>
                    <ObservablePanel
                        hidden={!visible}
                        pageType={this.isReady ? `Search ${title}` : 'Loading'}
                        panelType={PanelType.smallFixedFar}
                        onClosePanel={onClosePanel}
                        onRenderFooter={this._renderFooter}
                    >
                        <div data-test="extendedFilterPanel">
                            <r-grid columns="1">
                                <r-cell>
                                    <ObservableSeparator>Available filters</ObservableSeparator>
                                </r-cell>
                            </r-grid>

                        </div>

                        {items.map((I,idx) => {
                            return <div key={idx}>{I}</div>
                        })}

                        {/* {filters.includes('keywords') && <SearchBox
                            dataTest="panelFilterBarSearchBox"
                            value={keywordsField.value}
                            placeholder={keywordPlaceHolder ? keywordPlaceHolder : "Search by title"}
                            label="Search by title"
                            styles={{ root: { height: '32px', minWidth: '270px', marginTop: '5px', marginBottom: '5px' }}}
                            onSearch={(value) => {onKeywordsSearch(value)}}
                            onClear={() => onTextFieldClear('keywords')}
                        />}

                        {(filters.includes('assessment')) && <AsyncPicker 
                                placeholder={assessmentField.placeholder}
                                onItemPicked={(item) => {this.onAddItem(item, 'assessment')}}
                                selected={filterBarStore.filters.find(F => F.name === 'assessment')}
                                label={'Assessment'}
                                api={async (keyword) => { return await filterBarStore.searchAssessments(keyword) }}
                                idField="code"
                                textField="title"
                            />
                        }
                        { filters.includes('controls') && <AsyncPicker 
                                placeholder={controlField.placeholder}
                                onItemPicked={(item) => {this.onAddItem(item, 'controls')}}
                                selected={filterBarStore.filters.find(F => F.name === 'controls')}
                                label={'Controls'}
                                api={async (keyword) => { return await filterBarStore.searchControls(keyword) }}
                                idField="id"
                                textField="name"
                            /> 
                        }
                        {filters.includes('portfolio') && <Dropdown
                            styles={{ root: { marginBottom: '5px' }}}
                            // placeholder="Portfolio"
                            label="Portfolio"
                            selectedKeys={portfolioField.items.filter(i=>i.checked).map(i=>i.key)}
                            onChange={(element, item) => this.onCheckBoxChange('portfolio', item)}
                            multiSelect
                            options={portfolioField.items}
                        />}
                        {filters.includes('finalComplianceLevel') && <Dropdown
                            label="Final Compliance"
                            styles={{ root: { marginBottom: '5px' }}}
                            // placeholder="Final Compliance Level"
                            selectedKeys={finalComplianceLevelField.items.filter(i=>i.checked).map(i=>i.key)}
                            onChange={(element, item) => this.onCheckBoxChange('finalComplianceLevel', item)}
                            multiSelect
                            options={finalComplianceLevelField.items}
                        />}
                        {filters.includes('initialComplianceLevel') && <Dropdown
                            label="Initial Compliance"
                            styles={{ root: { marginBottom: '5px' }}}
                            // placeholder="Initial Compliance"
                            selectedKeys={initialComplianceLevelField.items.filter(i=>i.checked).map(i=>i.key)}
                            onChange={(element, item) => this.onCheckBoxChange('initialComplianceLevel', item)}
                            multiSelect
                            options={initialComplianceLevelField.items}
                        />}
                        {filters.includes('status') && <Dropdown
                            styles={{ root: { marginBottom: '5px' }}}
                            placeholder="Status"
                            label="Status"
                            selectedKeys={statusField.items.filter(i=>i.checked).map(i=>i.key)}
                            onChange={(element, item) => this.onCheckBoxChange('status', item)}
                            multiSelect
                            options={statusField.items}
                        />}
                        {filters.includes('assessmentStatus') && <Dropdown
                            styles={{ root: { marginBottom: '5px' }}}
                            placeholder="Assessment Status"
                            label="Assessment Status"
                            selectedKeys={assessmentStatusField.items.filter(i=>i.checked).map(i=>i.key)}
                            onChange={(element, item) => this.onCheckBoxChange('assessmentStatus', item)}
                            multiSelect
                            options={assessmentStatusField.items}
                        />} 
                        {filters.includes('age') && <Dropdown
                            styles={{ root: { marginBottom: '5px' }}}
                            placeholder="Age"
                            label="Age"
                            selectedKeys={ageField.items.filter(i=>i.checked).map(i=>i.key)}
                            onChange={(element, item) => this.onCheckBoxChange('age', item)}
                            multiSelect
                            options={ageField.items}
                        />}
                        {filters.includes('assignedTo') &&<PrincipalPicker 
                            styles={{ root: { marginBottom: '5px' }}}
                            placeholder={'Assigned To'}
                            onSelected={(user) => {this.onAddItem(user, 'assignedTo')}}
                            selected={null}
                        />} */}
                        
                    </ObservablePanel>
                </>
            );
        }
        _renderFooter = () => {
            const { onClosePanel, onFiltersChange } = this.props;
            return (
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <PrimaryButton onClick={onFiltersChange} iconProps={{iconName: 'Save'}} text="Search" />
                    <DefaultButton onClick={onClosePanel} iconProps={{iconName: 'Cancel'}} text="Close" />
                </Stack>
            );
        }
    }
));