import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks/useStores";

import { JsonQueryString } from "@modules/utils/JsonQueryString";
import { ObservablePagination } from "@modules/base/components/ObservablePagination";

import { AssetGridView } from "@modules/assets/components/core/AssetGridView";
import { AssetCommandBar } from "@modules/assets/components/core/AssetCommandBar";

export const EntityAssetsTab = observer(({ entity }) => {
    const { assetStore, pageContext, routing } = useStores();

    const { browseStore, lifecycleStore } = assetStore;
    const { loading, query, pagination, assets } = browseStore;

    useEffect(() => {
        if (entity) {
            pageContext.updateQueryStringParams(routing.location.search);
            browseStore.loadAssets({
                page: pageContext.queryStringParams.p || 1,
                query: {
                    ...JsonQueryString.decode(pageContext.queryStringParams.query),
                    primarySupplier: [{ id: entity.id }],
                },
            });
        }
        return () => browseStore.clear();
    }, [entity ? entity.id : null, pageContext]);

    return (
        <>
            <AssetCommandBar
                filterQuery={query}
                filterContext={{
                    processing: loading,
                    resultCount: pagination.totalItemCount,
                }}
                onFilterChange={(q, f) => {
                    const { primarySupplier, ...rest } = q;
                    routing.push(JsonQueryString.build(`/entities/browse/${entity.id}/assets`, rest));
                }}
                hideAgentActions={!lifecycleStore.showAgentActions}
            />

            <AssetGridView
                assets={assets}
                loading={loading}
                query={query}
                onAssetClick={(asset) => routing.push(`/assets/browse/${asset.id}`)}
                onWatchlistChange={(item, add) => browseStore.toggleWatchlist(item, add)}
            />

            <ObservablePagination
                {...pagination}
                onPageChange={(index) => {
                    const { primarySupplier, ...rest } = query;
                    routing.push(JsonQueryString.build(`/entities/browse/${entity.id}/assets`, rest, index + 1));
                }}
            />
        </>
    );
});
